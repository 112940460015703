import React from 'react'
import styled from 'styled-components'
import { fontRegular } from 'fonts'

import { Option } from 'shared/types'
import { SectionPropsBase } from 'shared/questionnaires/types'
import { UPDATE_OPTION_VALUE } from 'shared/questionnaires/actionTypes'
import { useQuestionnaireState } from 'questionnaires/QuestionnaireState'

import { Row, Column, Spacer, TextareaInput } from 'common/ui'
import { Container, Label, RadioGroup, InlineRadioGroup } from 'common/RadioGroup'
import { RadioButton } from 'session/common/RadioButton'
import { RichText } from 'session/common/RichText'
import { Field } from 'common/Field'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'

interface Props extends SectionPropsBase {
  property: 'question_sections'
}

const yesNoOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
]

const cadetTerminologyOptions: Option[] = [
  { label: `Not at all`, value: '0' },
  { label: `A little bit`, value: '1' },
  { label: 'Quite a bit', value: '2' },
  { label: `A moderate amount`, value: '3' },
  { label: 'A lot', value: '4' },
  { label: `Very much`, value: '5' },
]

export const QuestionSection: React.FC<Props> = ({ section }) => {
  const { type, question_options, score_range, start_from = 1, terminology } = section
  const { state: userData, previewing, userDataStatus, dispatch, questionnaireData } = useQuestionnaireState()
  const key = `panel_${section._owner_id}_${type}_${section.id}`
  const currentResponse = (userData.responses || []).find(res => res.unique_key === key)
  const readOnly = userDataStatus !== 'ready'

  const handleChange = (value: number | null, modifiedKey?: string, comment?: string) => {
    const response = modifiedKey ? (userData.responses || []).find(res => res.unique_key === modifiedKey) : undefined
    if (previewing) return
    if (type === 'observational') {
      dispatch({
        type: UPDATE_OPTION_VALUE,
        updateObservationalCodeScore: !!questionnaireData && questionnaireData.type === 'observational-code',
        data: {
          unique_key: modifiedKey || key,
          value: comment !== undefined ? comment : response ? response.value : null,
          score: comment === undefined ? value : response ? response.score : null,
          total: 2, // 3 - 1
          non_scoring: false,
        },
      })
    } else {
      dispatch({
        type: UPDATE_OPTION_VALUE,
        data: {
          unique_key: modifiedKey || key,
          value: type === 'score' ? (response ? response.value : null) : value,
          score: type === 'score' ? value : undefined,
          total: type === 'score' ? score_range - 1 : undefined,
          non_scoring: type !== 'score',
        },
      })
    }
  }

  return (
    <QuestionWrapper>
      <RichText text={section.text} />
      <Spacer />
      {type === 'score' && (
        <>
          <table>
            <thead>
              {score_range === 3 ? (
                <tr>
                  <th />
                  <th className="w-3">Not true (0)</th>
                  <th className="w-3">Sometimes True (1)</th>
                  <th className="w-3">Mostly True (2)</th>
                </tr>
              ) : (
                score_range === 5 && (
                  <tr>
                    <th />
                    <th className="w-5">Never (0)</th>
                    <th className="w-5">Rarely (1)</th>
                    <th className="w-5">Sometimes (2)</th>
                    <th className="w-5">Often (3)</th>
                    <th className="w-5">Always (4)</th>
                  </tr>
                )
              )}
            </thead>
            <tbody>
              {question_options.map((option, idx) => (
                <tr key={idx}>
                  <td style={{ minWidth: 260 }}>
                    <strong>{start_from + idx}</strong>.&nbsp;&nbsp;{option.title}
                  </td>
                  {[...Array(score_range)].map((_, i) => {
                    const value = option.reverse_scored ? score_range - 1 - i : i
                    const uniqueKey = `${key}_option_${option.id}`
                    const response = (userData.responses || []).find(res => res.unique_key === uniqueKey)
                    return (
                      <td key={i}>
                        <Row justifyContent="center">
                          <RadioButton
                            value={response?.score === value || false}
                            size="s"
                            onSelect={val => handleChange(value, uniqueKey)}
                            disabled={readOnly}
                          />
                        </Row>
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <Row>
        {type === 'choice' && terminology === 'cadet' && (
          <Column justifyContent="space-between" flex="none" paddingBottom={10} paddingRight={5}>
            <img src={require('../assets/face_sad.svg')?.default} width={36} height={36} alt="Not at all" />
            <img src={require('../assets/face_happy.svg')?.default} width={36} height={36} alt="A lot" />
          </Column>
        )}
        {(type === 'polar' || type === 'choice') && (
          <RadioWrapper>
            <RadioGroup<{ value: string }>
              name=""
              hideOuterDividers
              hideInnerDividers
              preventLabelOverflow
              size="s"
              value={currentResponse?.value?.toString() || ''}
              showIndex={type === 'choice'}
              options={
                type === 'polar'
                  ? yesNoOptions
                  : terminology === 'cadet'
                  ? cadetTerminologyOptions
                  : [
                      { label: `Not at all ${terminology}`, value: '0' },
                      { label: `Slightly ${terminology}`, value: '1' },
                      { label: `Quite ${terminology}`, value: '2' },
                      { label: `Moderately ${terminology}`, value: '3' },
                      { label: `Somewhat ${terminology}`, value: '4' },
                      { label: `Very ${terminology}`, value: '5' },
                    ]
              }
              onChange={value => handleChange(parseInt(value))}
              disabled={readOnly}
            />
          </RadioWrapper>
        )}
      </Row>
      {type === 'observational' && (
        <>
          {question_options.map((option, i) => {
            // const value = option.reverse_scored ? 3 - i : i
            const uniqueKey = `${key}_option_${option.id}`
            const response = (userData.responses || []).find(res => res.unique_key === uniqueKey)
            const comment = response && typeof response.value === 'string' ? response.value : ''
            const options = [
              ...(option.optionLabels || ['1', '2', '3']).map((label, i) => ({
                label,
                value: String(option.reverse_scored ? 2 - i : i),
              })),
              { label: 'N/A', value: '' },
            ]
            return (
              <div className="question-bounds" key={i}>
                <strong>{start_from + i}</strong>.&nbsp;&nbsp;{option.title}
                <Spacer size="s" />
                <RadioWrapper style={{ padding: '0 20px' }}>
                  <InlineRadioGroup<{ value: string }>
                    name=""
                    hideOuterDividers
                    hideInnerDividers
                    size="s"
                    value={response && typeof response.score === 'number' ? String(response.score) : ''}
                    stackLabel
                    options={options}
                    onChange={value => handleChange(value === '' ? null : parseInt(value), uniqueKey)}
                    disabled={readOnly}
                  />
                </RadioWrapper>
                <div style={{ paddingLeft: 30 }}>
                  <Field label="Comments:">
                    <DebouncedInputComponent<string>
                      value={comment}
                      onChange={val => handleChange(response?.score || null, uniqueKey, val)}
                      children={({ value, onChange }) => (
                        <TextareaInput
                          value={value}
                          onChange={e => onChange(String(e.target.value || ''))}
                          rows={2}
                          maxLength={500}
                        />
                      )}
                    />
                  </Field>
                </div>
                <Spacer size="m" />
              </div>
            )
          })}
        </>
      )}
    </QuestionWrapper>
  )
}

const RadioWrapper = styled.div`
  ${Label} {
    ${fontRegular};
    font-weight: 400;
  }

  ${Container} {
    margin-bottom: 10px;
  }
`

export const QuestionWrapper = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;

    tbody tr:nth-child(odd) {
      background-color: #edf2fa;
    }

    td,
    th {
      ${fontRegular};
      font-weight: 400;
      padding: 15px 10px;
    }

    th {
      text-align: center;
    }

    .w-5 {
      width: 100px;
      white-space: nowrap;
      overflow: visible;
      padding: 15px 5px;
    }

    .w-3 {
      width: 135px;
    }

    thead th {
      position: sticky;
      top: 0px;
      z-index: 1;
      background-color: white;
      padding: 15px 0;
      font-size: 16px;
      box-shadow: 0 -14px 6px -10px rgb(0, 0, 0, 0.2) inset;
    }
  }
`
