import styled from 'styled-components'
import { fontRegular } from 'fonts'
import { Column, Button, P } from 'common/ui'
import { CircleButton } from 'session/common/CircleButton'

export const MentorPanel = styled(Column)`
  box-sizing: border-box;
  max-width: 500px;
  padding: 15px;
  margin: 5px;
  border-radius: 10px;
  max-height: 100%;
  min-width: 300px;
  background: #fff;
  border: 1px solid #cdd2e4;
  overflow: hidden;

  ${Button}:not(${CircleButton}) {
    ${fontRegular};
    background: linear-gradient(180deg, #001741 0%, #254785 100%);
    border: 3px solid #072b6b;
    box-shadow: 0px 3px 0px #011a46, 0px 3px 3px rgba(0, 0, 0, 0.6);

    &:after {
      border-color: #072b6b;
    }
  }

  ${P} {
    margin-top: 5px;
  }
`
