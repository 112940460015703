import { ActionTypes } from './actionTypes'
import { IdField, UidField, OrderField, TitleField, OwnerOccupierFields } from '../types'
import {
  BlockSection,
  DownloadResourceSection,
  EmbeddedActivitySection,
  GroupedSection,
  MediaAsset,
  MediaGridSection,
  MediaRatio,
  NotificationSection,
  TextSection,
} from '../session/types'

export interface DrupalTrainingCourse {
  start_course_deadline: string
  end_course_deadline: string | null
  start_course: null | string
  complete_course: null | string
  course_status: 'Pending' | 'In progress' | 'In review' | 'Complete' | 'Expired'
  course_type: 'facilitator' | 'assistant' | 'senior facilitator'
}

export interface TrainingState {
  unitUid: string | null
  currentStepUid: string | null
  steps: TrainingStateStep[]
}

export interface TrainingStateStep {
  uid: string
  panels: TrainingStatePanel[]
  notes: string
}

export interface TrainingStatePanel {
  uid: string
  answer: any // TODO: this really should be a type
  correct: boolean
  requiresValidation: boolean
}

export interface TrainingUnitValues {
  id: number
  uid: string
  unit_uid: string
  state: TrainingState
  started: string
  completed: string
}

export interface TrainingStateHookObject {
  state: TrainingState
  trainingData: false | TrainingData
  setTrainingData: React.Dispatch<React.SetStateAction<this['trainingData']>>
  dispatch: (action: ActionTypes) => void
  previewing: boolean
  setPreviewing: React.Dispatch<React.SetStateAction<this['previewing']>>
  previewState: PreviewState
  setPreviewState: React.Dispatch<React.SetStateAction<this['previewState']>>
}

export interface PreviewState {
  stepIndex: number
}

export interface TrainingData {
  unit: TrainingUnit
}

/** CakePHP Model: TrainingCourse */
export interface TrainingCourse extends IdField, UidField {
  title: string
  public_title?: string
  training_units: TrainingUnit[]
  type: string
  start_content: string
  unit_label: string
  show_contents_on_start: boolean
  is_drupal: boolean
  enabled: boolean
  image: MediaAsset
  end_screen_image: MediaAsset
}

// Training enrollment endpoint
export interface TrainingEnrolment extends TrainingCourse {
  drupalData?: DrupalTrainingCourse
}

/** CakePHP Model: TrainingUnit */
export interface TrainingUnit extends IdField, UidField, OrderField {
  title?: string
  training_steps: TrainingStep[]
}

/** CakePHP Model: TrainingStep */
export interface TrainingStep extends IdField, UidField, OrderField {
  title?: string
  section?: string
  panels: TrainingPanel[]
  unlock_facman: boolean
  unlock_digi_hq: boolean
  allow_notes: boolean
}

/** CakePHP Model: TrainingPanel **/
export interface TrainingPanel extends OwnerOccupierFields, OrderField, TitleField, SectionContainer {
  theme: null | 'no-panel' | 'purple' | 'purple-header' | 'non-compulsory'
  uid: string
  title: string
  hint_correct: string
  hint_incorrect: string
  content_type: 'question' | 'static'
  type: QuestionType
  input_label: string
  placeholder: string
  media: MediaAsset
  media_type: 'video' | 'image'
  media_ratio?: MediaRatio
  text: string
  randomize_options: boolean
  question: string
  video_url: string
  question_options: QuestionOption[]
  adventure_validation: boolean
  show_correct_results: boolean
}

/** CakePHP Model: QuestionOption **/
export interface QuestionOption extends IdField, OrderField {
  media: MediaAsset
  video_url: string
  media_type: TrainingPanel['media_type']
  media_ratio?: MediaRatio
  title: string
  text: string
  correct: boolean
  order: number
  placeholder: string
  label: string
}

export type QuestionType =
  | 'choice'
  | 'multi-select'
  | 'orderable'
  | 'click-drag'
  | 'input-reveal'
  | 'select-reveal'
  | 'upload'
  | 'card-flip'
  | 'questionnaire-choice'
  | 'questionnaire-input'

/**
 * Section content types
 */
export interface StreamedVideoGridSection extends Section {
  columns: number
  items: StreamedVideoItem[]
}

export interface StreamedVideoItem extends IdField, OwnerOccupierFields, OrderField {
  media_ratio: string
  video_url: string
}

export interface StreamedVideoBlockSection extends Section {
  title: string
  video_url: string
  content_side?: 'left' | 'right'
  text: string
  images: StreamedVideoBlockImage[]
}

export interface StreamedVideoBlockImage {
  id: number
  image: MediaAsset
}

export const sectionProperties = [
  'text_sections',
  'notification_sections',
  'media_grid_sections',
  'block_sections',
  'streamed_video_grid_sections',
  'streamed_video_block_sections',
  'grouped_sections',
  'embedded_activity_sections',
  'download_resource_sections',
] as const

export type SectionProperty = typeof sectionProperties[number]

export interface SectionObject {
  property: SectionProperty
  section: SectionContainer[this['property']][0]
  index: number
}

export interface SectionPropsBase extends SectionObject {}

type SectionContainerShape = { [key in SectionProperty]: Section[] }
export interface SectionContainer extends SectionContainerShape {
  text_sections: TextSection[]
  media_grid_sections: MediaGridSection[]
  block_sections: BlockSection[]
  streamed_video_grid_sections: StreamedVideoGridSection[]
  streamed_video_block_sections: StreamedVideoBlockSection[]
  notification_sections: NotificationSection[]
  grouped_sections: GroupedSection[]
  embedded_activity_sections: EmbeddedActivitySection[]
  download_resource_sections: DownloadResourceSection[]
}

export type ContentSection =
  | TextSection
  | MediaGridSection
  | BlockSection
  | StreamedVideoGridSection
  | StreamedVideoBlockSection
  | NotificationSection
  | GroupedSection
  | EmbeddedActivitySection
  | DownloadResourceSection

export interface Section extends IdField, OwnerOccupierFields, OrderField, TitleField {}
