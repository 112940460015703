import React, { CSSProperties } from 'react'
import { StyledComponent } from 'styled-components'

export interface SVGProps {
  size?: number
  width?: number
  height?: number
  viewboxSize?: number
  viewboxWidth?: number
  viewboxHeight?: number
  style?: CSSProperties
  SvgWrapper?: StyledComponent<'svg', any, {}, never> | keyof JSX.IntrinsicElements
}

export const SVG: React.FC<SVGProps> = ({
  children,
  viewboxSize = 30,
  viewboxWidth,
  viewboxHeight,
  size = 32,
  width,
  height,
  style,
  SvgWrapper = 'svg',
}) => {
  return (
    <SvgWrapper
      xmlns="http://www.w3.org/2000/svgWrapper"
      viewBox={`0 0 ${viewboxWidth || viewboxSize} ${viewboxHeight || viewboxSize}`}
      width={width || size}
      height={height || size}
      style={style}
      children={children}
    />
  )
}
