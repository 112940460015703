import React from 'react'
import { useInputContext } from 'session/InputContext'
import { isDevEnv } from 'utils/envUtils'

export const DebugPre: React.FC<{ data: any }> = ({ data }) => {
  if (!isDevEnv()) return null
  return <pre style={{ fontSize: 10 }} children={JSON.stringify(data, null, 2)} />
}

export const DebugInputContext: React.FC = () => {
  const inputContext = useInputContext()
  return <DebugPre data={inputContext} />
}
