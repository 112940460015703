import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { fontBold } from 'fonts'

import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { BoardGameEncounter as Encounter } from 'shared/session/types'
import { IconButton } from 'common/ui'

interface DeckCardModalProps {
  isOpen: boolean
  encounter: Encounter | null
  isFacilitator: boolean
  onClose: () => void
}

export const EncounterModal: React.FC<DeckCardModalProps> = ({
  isOpen: _isOpen,
  onClose,
  encounter,
  isFacilitator,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(_isOpen)
  const closeScheduled = useRef<boolean>(false)
  useEffect(() => {
    setIsOpen(_isOpen)
    if (_isOpen) closeScheduled.current = false
  }, [_isOpen])
  const delayedOnClose = () => {
    if (closeScheduled.current) return
    setIsOpen(false)
    closeScheduled.current = true
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }
  return (
    <MobileModalPanel
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      shouldCloseOnOverlayClick={isFacilitator}
      panelStyle={{ width: '50vw', maxWidth: 800, minWidth: 550, borderBottom: 'none', overflow: 'visible' }}>
      {encounter && (
        <>
          <EncounterImage src={encounter.image} />
          <TextArea style={encounter.textArea}>
            <span>{encounter.text}</span>
          </TextArea>
          {isFacilitator && <CloseButton theme="white" onClick={delayedOnClose} children="×" />}
        </>
      )}
    </MobileModalPanel>
  )
}

const EncounterImage = styled.img`
  border: none;
  width: 100%;
  height: auto;
`
const TextArea = styled.div`
  position: absolute;
  top: 5%;
  right: 5%;
  bottom: 5%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${fontBold}
  text-align: center;
  font-size: 1.5em;
  color: white;
`
const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  transform: translate(50%, -50%) !important;
  color: #0079c1 !important;
  font-family: sans-serif;
  font-weight: 100;
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  &::before,
  &::after {
    content: none;
  }
`
