import { Hr, Row, P } from 'common/ui'
import { fontBold, fontLight } from 'fonts'
import React from 'react'
import { getImageUrl } from 'session/utils/assetUtils'
import { SectionPropsBase } from 'shared/fac-man/types'
import styled from 'styled-components'

interface Props extends SectionPropsBase {
  property: 'heading_sections'
}

export const HeadingSection: React.FC<Props> = ({ section }) => (
  <Wrapper>
    <Row alignItems="center">
      {section.image && <TitleImg imageUrl={getImageUrl(section.image, { preset: '320w-4-3' })} />}
      <Title>{section.title}</Title>
    </Row>
    <Hr margin="m" />
    <Heading>{section.heading}</Heading>
    <Subheading>{section.subheading}</Subheading>
  </Wrapper>
)

const Wrapper = styled.div`
  ${Hr} {
    border-color: #6737b5;
  }
`

const TitleImg = styled.div<{ imageUrl: string }>`
  width: 70px;
  height: 70px;
  background-image: url(${p => p.imageUrl});
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin-right: 15px;
`

const Title = styled(P)`
  ${fontBold};
  margin: 0;
  color: #6737b5;
  font-size: 1.2rem;
  line-height: 130%;
  letter-spacing: 0.03em;
`

const Heading = styled(P)`
  ${fontLight};
  font-size: 1.2rem;
  color: #011a46;
  line-height: 123%;
  letter-spacing: 0.03em;
  margin-top: 0;
  margin-bottom: 5px;
`

const Subheading = styled(P)`
  ${fontLight};
  font-size: 1rem;
  color: #011a46;
  line-height: 123%;
  letter-spacing: 0.03em;
  margin-top: 0;
`
