import React, { useContext, useRef, useEffect, useCallback } from 'react'
import qs from 'qs'
import { useDeferredEndpoint } from 'dashboards/utils/endpointHooks'
import {
  FacilitatorEntity,
  ProviderEntity,
  ProviderLinkRequest,
  GroupEntity,
  SessionEntity,
  ProductKey,
} from 'shared/dashboard/types'

function useProviderFacDashState() {
  const facilitator = useDeferredEndpoint<FacilitatorEntity>(`/api/v1/facilitator`)
  const providers = useDeferredEndpoint<ProviderEntity[]>(`/api/v1/providers`, [])
  const providerLinkRequests = useDeferredEndpoint<ProviderLinkRequest[]>(`/api/v1/providers/links`, [])
  const groups = useDeferredEndpoint<GroupEntity[]>(`/api/v1/groups`, [])
  const orphanedGroupsCount = useDeferredEndpoint<number>(`/api/v1/groups/orphan/list_count`, 0)
  const sessions = useDeferredEndpoint<SessionEntity[]>(`/api/v1/sessions`, [])
  const productKeys = useDeferredEndpoint<ProductKey[]>(`/api/v1/product_keys`, [])
  const productKeysCount = useDeferredEndpoint<number>(`/api/v1/product_keys/count`, 0)
  const orphanedQuestionnairesCount = useDeferredEndpoint<number>(
    providers.value && providers.value.length > 0
      ? `/api/v1/questionnaires/orphaned_count?${qs.stringify(
          { provider_uid: providers.value.map(({ id }) => id) },
          { encodeValuesOnly: true }
        )}`
      : null,
    0
  )

  return {
    facilitator,
    providers,
    providerLinkRequests,
    groups,
    orphanedGroupsCount,
    sessions,
    productKeys,
    productKeysCount,
    orphanedQuestionnairesCount,
  }
}

export function useFacDashData<Key extends keyof ReturnType<typeof useProviderFacDashState>>(
  key: Key,
  defaultValue: Exclude<ReturnType<typeof useProviderFacDashState>[Key]['value'], null>
): [
  Exclude<ReturnType<typeof useProviderFacDashState>[Key]['value'], null> | typeof defaultValue,
  boolean,
  () => void
] {
  const loaded = useRef<boolean>(false)
  const notReadyToLoad = useRef<boolean>(false)
  const facDashState = useFacDashState() as ReturnType<typeof useProviderFacDashState>
  const { value, loading, fetchUrl, fetch: fetchFacDashData } = facDashState[key]
  const valueNotFetched = value === null

  const reload = useCallback(() => {
    loaded.current = false
    fetchFacDashData()
      .then(() => {
        loaded.current = true
      })
      .catch(() => {})
  }, [fetchFacDashData])

  // trigger loading on render
  useEffect(() => {
    if (!loading && !loaded.current) {
      fetchFacDashData()
        .then((...args) => {
          // ensure we don't say it's loaded if its fetchUrl is null (in the case of conditional fetches)
          // moreover promise returning doesn't guarantee that data was actually fetched :')
          if (fetchUrl) {
            loaded.current = true
          } else {
            notReadyToLoad.current = true
          }
        })
        .catch(() => {
          notReadyToLoad.current = true
        })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (fetchUrl !== null && valueNotFetched && !loading && notReadyToLoad.current) {
      notReadyToLoad.current = false
      // ew but a useEffect hasn't fired yet in the useDeferredEndpoint hook so gotta wait a bit
      setTimeout(() => {
        reload()
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl, loading, valueNotFetched, reload])

  return [value !== null ? (value as typeof defaultValue) : defaultValue, facDashState[key].loading, reload]
}

function noop(): any {}

const emptyEndpointObj = {
  value: null,
  fetch: noop,
  loaded: false,
  loading: false,
  errorLoading: false,
  errorMessage: null,
  fetchUrl: null,
} satisfies ReturnType<typeof useDeferredEndpoint>

export const FacDashStateContext = React.createContext<ReturnType<typeof useProviderFacDashState>>({
  facilitator: emptyEndpointObj,
  providers: emptyEndpointObj,
  providerLinkRequests: emptyEndpointObj,
  groups: emptyEndpointObj,
  orphanedGroupsCount: emptyEndpointObj,
  sessions: emptyEndpointObj,
  productKeys: emptyEndpointObj,
  productKeysCount: emptyEndpointObj,
  orphanedQuestionnairesCount: emptyEndpointObj,
})

export const FacDashStateProvider: React.FC = ({ children }) => {
  const state = useProviderFacDashState()
  return <FacDashStateContext.Provider value={state}>{children}</FacDashStateContext.Provider>
}

export function useFacDashState() {
  return useContext(FacDashStateContext)
}
