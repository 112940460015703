import React, { useState, ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useFetchedFormData, UseFetchedFormDataReturn } from 'dashboards/utils/formDataHooks'
import { toJson } from 'utils/apiUtils'
import styled from 'styled-components'
import { FieldWrapper } from 'common/Field'

export type ValidationErrorShape = { [key: string]: ValidationErrorShape | string }

export interface RenderProps<FormDataType> extends UseFetchedFormDataReturn<FormDataType> {
  submitting: boolean
  errorSubmitting: boolean | string
  validationErrors: ValidationErrorShape
  submitForm: (overrideData?: FormDataType) => void
}

export interface Props<FormDataType, PartialFormDataType> {
  initialDataUrl?: string
  initialDataRequestOptions?: RequestInit
  defaultValue?: PartialFormDataType
  submitUrl: string
  submitRequestOptions?: RequestInit
  onSubmitSuccess: (data: FormDataType) => void
  onBeforeSubmit?: () => void
  onInitialDataLoaded?: (data: PartialFormDataType) => void
  children: (props: RenderProps<PartialFormDataType>) => ReactNode
}

export const Form = <FormDataType extends object, PartialFormDataType extends object>({
  children,
  defaultValue = {} as PartialFormDataType,
  initialDataUrl,
  initialDataRequestOptions,
  submitUrl,
  submitRequestOptions = {},
  onSubmitSuccess,
  onBeforeSubmit,
  onInitialDataLoaded,
}: Props<FormDataType, PartialFormDataType>) => {
  const fetchedFormData = useFetchedFormData<PartialFormDataType>(
    initialDataUrl,
    defaultValue,
    initialDataRequestOptions
  )
  const [submitting, setSubmitting] = useState<RenderProps<FormDataType>['submitting']>(false)
  const [errorSubmitting, setErrorSubmitting] = useState<RenderProps<FormDataType>['errorSubmitting']>(false)
  const [validationErrors, setValidationErrors] = useState<ValidationErrorShape>({})

  const submitForm = (overrideData?: PartialFormDataType) => {
    console.log('submit pls')
    onBeforeSubmit?.()
    ReactDOM.unstable_batchedUpdates(() => {
      setSubmitting(true)
      setErrorSubmitting(false)
      setValidationErrors({})
    })
    fetch(submitUrl, {
      method: 'POST',
      body: JSON.stringify(overrideData ? overrideData : fetchedFormData.formData),
      ...submitRequestOptions,
      headers: {
        'content-type': 'application/json',
        ...(submitRequestOptions.headers || ''),
      },
    })
      .then(toJson)
      .then((data: FormDataType) => {
        console.log('Submitted! Got back:', data)
        ReactDOM.unstable_batchedUpdates(() => {
          setSubmitting(false)
          setErrorSubmitting(false)
          setValidationErrors({})
          fetchedFormData.setUnsavedChanges(false)
        })
        onSubmitSuccess(data)
      })
      .catch((response: any) => {
        if (response?.validationErrors) {
          const validationErrors: ValidationErrorShape = response.validationErrors
          console.log('validation errors', validationErrors)
          ReactDOM.unstable_batchedUpdates(() => {
            setSubmitting(false)
            setErrorSubmitting(true)
            setValidationErrors(validationErrors) // HMMMM
          })
        } else {
          console.log('Error submitting', response)
          ReactDOM.unstable_batchedUpdates(() => {
            setSubmitting(false)
            setErrorSubmitting(response.message || true)
            fetchedFormData.setUnsavedChanges(false)
          })
        }
      })
  }

  useEffect(() => {
    if (!fetchedFormData.loading && fetchedFormData.formData) {
      // console.log('Initial data has loaded', fetchedFormData.formData)
      if (onInitialDataLoaded) onInitialDataLoaded(fetchedFormData.formData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedFormData.loading, onInitialDataLoaded])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    submitForm()
    return false
  }

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      children={children({
        submitting,
        errorSubmitting,
        validationErrors,
        ...fetchedFormData,
        submitForm,
      })}
    />
  )
}

const FormWrapper = styled.form`
  & ${FieldWrapper} {
    & .error-list {
      margin-top: 5px;
      font-size: 12px;
    }
  }
`
