import { fontBold, fontLight, fontRegular } from 'fonts'
import React from 'react'
import { SectionPropsBase, TableSection as TableSectionType } from 'shared/fac-man/types'
import styled from 'styled-components'

interface Props extends SectionPropsBase {
  property: 'table_sections'
}

export const TableSection: React.FC<Props> = ({ section }) => <BasicTableSection {...section} />

export const BasicTableSection: React.FC<Pick<
  TableSectionType,
  'description' | 'column1_title' | 'column2_title' | 'content'
>> = ({ description, column1_title, column2_title, content }) => (
  <TableWrapper>
    <Description>{description}</Description>
    <table>
      <thead>
        <tr>
          <th>
            <div>{column1_title}</div>
          </th>
          <th>
            <div>{column2_title}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {(content || ['', '']).map((rowContent, idx) => (
          <tr key={idx}>
            <td>
              <div>{rowContent[0]}</div>
            </td>
            <td>
              <div>{rowContent[1]}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </TableWrapper>
)

const TableWrapper = styled.div`
  table {
    width: calc(100% + 12px);
    border-spacing: 6px;
    margin: 0 -6px;

    th,
    td {
      text-align: left;
      padding: 20px;
      font-size: 1rem;
    }

    th {
      ${fontRegular};
      background: rgba(129, 58, 186, 0.8);
      color: #fff;
    }

    td {
      ${fontLight};
      background: rgba(129, 58, 186, 0.5);

      div {
        min-height: 60px;
        display: flex;
        align-items: center;
        line-height: 1;
      }
    }
  }
`

const Description = styled.p`
  ${fontBold};
  font-size: 1rem;
  line-height: 130%;
  letter-spacing: 0.03em;
  margin-top: 0;
`
