import React, { CSSProperties, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import styled, { css } from 'styled-components'
import { DragItemType } from './DraggableOption'

export type DroppableAreaProps = {
  style?: CSSProperties
  type: string
  onDrop: (dragItem: DragItemType) => void
}

export const DroppableArea: React.FC<DroppableAreaProps> = ({ onDrop, type, children, style }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ itemIsOver, itemBeingDragged }, drop] = useDrop({
    accept: type,
    drop: (item, monitor) => {
      onDrop(item as DragItemType)
    },
    collect: monitor => ({
      itemIsOver: monitor.isOver(),
      itemBeingDragged: monitor.canDrop(),
    }),
  })
  const [, drag] = useDrag({
    canDrag: () => false,
    item: { type: type, from: 'list' } as DragItemType,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })
  drag(drop(ref))

  return (
    <div style={{ ...style, width: '100%' }} ref={ref}>
      <DroppableHighlightWrapper
        ref={drop}
        style={itemBeingDragged ? { zIndex: 99 } : {}}
        {...{ itemIsOver, itemBeingDragged }}>
        {children}
      </DroppableHighlightWrapper>
    </div>
  )
}

interface DroppableHighlightWrapperProps {
  itemIsOver?: boolean
  itemBeingDragged?: boolean
  disableInteraction?: boolean
}

export const DroppableHighlightWrapper = styled.div<DroppableHighlightWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${p =>
    !p.disableInteraction
      ? ''
      : css`
          pointer-events: none;
        `}
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    border-radius: 10px;
    ${p =>
      p.itemBeingDragged
        ? css`
            ${p.itemIsOver
              ? css`
                  box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 12px 0 rgba(0, 0, 0, 0.5);
                `
              : ''}
          `
        : ''}
  }
`
