export let defaultWorkerRegistration: ServiceWorkerRegistration | null = null
export let pushNotificationWorkerRegistration: ServiceWorkerRegistration | null = null

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

export function register(config?: Config) {
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL((process as { env: { [key: string]: string } }).env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    window.addEventListener('load', () => {
      /*
      if(process.env.NODE_ENV === 'production') {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
        if (isLocalhost) {
          // This is running on localhost. Let's check if a service worker still exists or not.
          checkValidServiceWorker(swUrl, config)

          // Add some additional logging to localhost, pointing developers to the
          // service worker/PWA documentation.
          navigator.serviceWorker.ready.then(registration => {
            defaultWorkerRegistration = registration
            console.log(
              'This web app is being served cache-first by a service ' +
                'worker. To learn more, visit https://bit.ly/CRA-PWA'
            )
          })
        } else {
          // Is not localhost. Just register service worker
          registerValidSW(swUrl, config)
        }
      }
      */

      // if (isPushNotificationSupported()) {
      // const pushNotificationSW = `${process.env.PUBLIC_URL}/pushNotification.worker.js`
      const pushNotificationSW = `${process.env.PUBLIC_URL}/service-worker.js`
      // NOTE: In order to edit the service-worker.js script that is generated by CRA there is some BS involved, check the status of this: https://github.com/facebook/create-react-app/pull/5369
      // This may be a better approach: https://karannagupta.com/using-custom-workbox-service-workers-with-create-react-app/

      if (isLocalhost) {
        checkValidServiceWorker(pushNotificationSW, config)
        navigator.serviceWorker.ready.then((registration) => {
          defaultWorkerRegistration = registration
          // pushNotificationWorkerRegistration = registration
          console.log('Service worker for push notification already running', registration)
          navigator.serviceWorker.controller?.postMessage({
            type: 'APP_STARTED',
            source: 'client',
            baseUrl: window.location.origin,
          })
          if (registration.pushManager) {
            registration.pushManager
              .getSubscription()
              .then((subscription) => console.log('subscribed', JSON.stringify(subscription)))
          } else console.warn('pushManager not available via web standard')
        })
      } else {
        console.log('Starting service worker for push notifications...')
        registerValidSW(pushNotificationSW, {
          onUpdate: (registration) => {
            defaultWorkerRegistration = registration
            // pushNotificationWorkerRegistration = registration
            console.log('UPDATED', registration)
          },
          onSuccess: (registration) => {
            defaultWorkerRegistration = registration
            // pushNotificationWorkerRegistration = registration
            console.log('STARTED', registration)
            if (registration.pushManager) {
              registration.pushManager.getSubscription().then((subscription) => console.log('subscribed', subscription))
            } else console.warn('pushManager not available via web standard')
          },
        })
      }
      // } else {
      //   console.log('PushManager not supported in this browser')
      // }
    })
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker == null) {
          return
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated pre-cached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
              )

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              // At this point, everything has been pre-cached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              // console.log('Content is cached for offline use.')

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error)
    })
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.')
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}
