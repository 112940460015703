import ReactDOM from 'react-dom'
import { useUserState } from './UserState'
import { useInterval } from 'utils/useInterval'
import { refreshAccessToken } from 'api'
import { decodeJWT } from 'utils/decodeJWT'

const INTERVAL = 60000

export function useRefreshAccessToken() {
  const { authProvider, accessToken, refreshToken, setAccessToken, setRefreshToken } = useUserState()
  const disableInterval = authProvider !== 'sas' || !refreshToken
  const payload = decodeJWT(accessToken)

  useInterval(
    () => {
      // refresh access token if it's more than half expired (eg. 30 mins in 1 hr expiry)
      if (payload.iat + (payload.exp - payload.iat) / 2 < Date.now() / 1000) {
        refreshAccessToken(refreshToken)
          .then(data => {
            ReactDOM.unstable_batchedUpdates(() => {
              setAccessToken(data.access_token)
              setRefreshToken(data.refresh_token)
            })
          })
          .catch((err: any) => {
            // ignore errors if they are offline or have some kind of connection issue.
            // logout user if the refresh token request was explicitly denied.
            if (err && err.success === false) {
              ReactDOM.unstable_batchedUpdates(() => {
                setAccessToken('')
                setRefreshToken('')
              })
            }
          })
      }
    },
    disableInterval ? null : INTERVAL
  )
}
