import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { Page } from 'dashboards/common/Page'
import { CadetProgressTable } from 'dashboards/common/CadetProgressTable'
import { useMentorDashData } from './MentorDashboardState'
import { ProfileProgress } from 'api'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { SpinnerWithLabel } from 'common/Spinner'
import { SessionEntity } from 'shared/dashboard/types'
import { P } from 'common/ui'

export const Progress: React.FC<NavRouteProps> = ({ route }) => {
  const [mentor] = useMentorDashData('mentor')
  const [primaryMentor] = useMentorDashData('primaryMentor')
  const [group] = useMentorDashData('group')

  const [sessions] = useEndpoint<SessionEntity[]>(
    group && primaryMentor?.group_id ? `/api/v1/groups/${group.uid}/sessions?includeExtraGroups=1` : null
  )
  const [externalSessions] = useEndpoint<SessionEntity[]>(
    mentor && primaryMentor && primaryMentor.group_id
      ? `/api/v1/sessions/external/${primaryMentor.uid}?mentor_uid=${mentor.uid}`
      : null
  )
  const [profileProgress, { loading }] = useEndpoint<ProfileProgress>(
    primaryMentor ? `/api/v1/profiles/${primaryMentor.cadet_profile_id}/progress` : null
  )
  const mentors =
    primaryMentor && group
      ? [...group.primary_mentors, ...group.mentors].filter(
          g => g.id === primaryMentor.id || g.primary_mentor_id === primaryMentor.id
        )
      : null

  return (
    <Page route={route} title="Progress">
      {primaryMentor && !primaryMentor.group_id ? (
        <P>This Cadet does not belong to a group, so there is no Progress information to display here.</P>
      ) : !loading && !profileProgress ? (
        <P>
          Once this Cadet has entered SAS Digital HQ for the first time to set up their new agent profile, the progress
          screen will display.
        </P>
      ) : !profileProgress || !sessions || !mentors || !group ? (
        <SpinnerWithLabel color="#858cac" label="One moment please..." />
      ) : (
        <CadetProgressTable
          group={group}
          profileProgress={profileProgress}
          mentors={mentors}
          sessions={sessions}
          externalSessions={externalSessions || undefined}
        />
      )}
    </Page>
  )
}
