import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { useDrag } from 'react-dnd'
import { ScavengerHuntCard as Card } from 'shared/session/types'
import { images } from './assets'

export const DRAG_TYPE_DECODER_CARD = 'DECODER_CARD'

export type CardProps = Partial<StaticCardStyledProps> & {
  card: Card
  style?: CSSProperties
  onSelect?: () => void
}

export const StaticCard: React.FC<CardProps> = ({ card, width = 60, dragging, disabled, style, onSelect }) => {
  return (
    <StaticCardStyled width={width} dragging={dragging} disabled={disabled} style={style} onClick={onSelect}>
      <img src={images[card.id]} alt={`The letter ${card.letter.toUpperCase()}`} />
    </StaticCardStyled>
  )
}

interface StaticCardStyledProps {
  width: number
  dragging?: boolean
  disabled?: boolean
}

const StaticCardStyled = styled.div<StaticCardStyledProps>`
  position: relative;
  width: ${p => p.width}px;
  height: ${p => p.width * 1.5}px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  cursor: pointer;
  ${p => (p.disabled ? 'pointer-events: none;' : '')}
  opacity: ${p => (p.disabled ? 0.25 : 1)};
  transition: opacity 250ms linear;
  user-select: none;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`

export type DraggableCardProps = CardProps & {
  onMove: (index: number) => void
}

export interface CardDragItem {
  type: typeof DRAG_TYPE_DECODER_CARD
  from: 'origin' | 'destination'
  props: CardProps
}

export const DraggableCard: React.FC<DraggableCardProps> = ({ onMove, ...props }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: DRAG_TYPE_DECODER_CARD, from: 'origin', props } as CardDragItem,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })
  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <StaticCard {...props} />
    </div>
  )
}
