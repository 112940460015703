import React from 'react'
import { ParticipantState, sectionProperties, SectionProperty, SectionPropsBase } from 'shared/session/types'
import { InvisibleInkFacilitator, InvisibleInkScreen } from './custom/InvisibleInk'
import { StressBallActivity } from './custom/StressBallActivity'
import { IdCardActivity } from './custom/IdCardActivity'
import { ScavengerHunt, ScavengerHuntFacilitator } from './custom/scavenger-hunt/ScavengerHunt'
import { DetectionExpression, DetectionExpressionFacilitator } from './custom/DetectionExpression'
import { FriendshipFormulaActivity } from './custom/FriendshipFormulaActivity'
import { ThoughtTracker, ThoughtTrackerFacilitator } from './custom/thought-tracker/ThoughtTracker'
import { BionicPowersActivity } from './custom/BionicPowersActivity'
import {
  SecretAgentBodySignalsActivity,
  SecretAgentBodySignalsActivityFacilitator,
} from './custom/SecretAgentBodySignals'
import { EmotionometerActivity, EmotionometerActivityFacilitator } from './custom/emotionometer/EmotionometerActivity'
import { FriendometerActivity } from './custom/FriendometerActivity'
import { ClubRules } from './custom/ClubRules'
import {
  RelaxationGadgetsActivity,
  RelaxationGadgetsActivityFacilitator,
} from './custom/relaxation-gadgets/RelaxationGadgetsActivity'
import { SecretTransmission, SecretTransmissionFacilitator } from './custom/SecretTransmission'
import { UnderConstruction } from 'session/common/UnderConstruction'
import { HelpfulThoughts } from './custom/helpful-thoughts/HelpfulThoughts'
import { HelpfulThoughtsFacilitator } from './custom/helpful-thoughts/HelpfulThoughtsFacilitator'
import { BrainCell, BrainCellFacilitator } from './custom/brain-cell/BrainCell'
import { FactFileActivity } from './custom/FactFileActivity'
import { BoardGame } from './custom/board-game/BoardGame'
import { DamageControl, DamageControlFacilitator } from './custom/DamageControl'
import { BullyGuard, BullyGuardFacilitator } from './custom/BullyGuard'
import { GraduationMedalActivity } from './custom/GraduationMedal'
import { SectionPropsBase as TrainingSectionPropsBase } from 'shared/training/types'
import { useGenericUser } from 'app/useGenericUser'
import { SessionStateProvider } from 'session/SessionState'
import { InputContextProvider } from 'session/InputContext'
import { UserInputStateProvider } from 'session/UserInputState'
import { P, Panel } from 'common/ui'

interface Props extends SectionPropsBase {
  property: 'embedded_activity_sections'
}

export const EmbeddedActivitySection: React.FC<Props> = ({ section, panel, property, index }) => {
  switch (section.activity) {
    case 'id-card':
      return <IdCardActivity section={section} />
    case 'stress-ball':
      return <StressBallActivity section={section} />
    case 'friendship-formula':
      return <FriendshipFormulaActivity />
    case 'invisible-ink':
      return <InvisibleInkScreen section={section} />
    case 'scavenger-hunt':
      return <ScavengerHunt section={section} property={property} />
    case 'detection-expression':
      return <DetectionExpression {...{ section, panel, index }} />
    case 'thought-tracker':
      return <ThoughtTracker {...{ section, panel, property, index }} width={'100%'} height={600} />
    case 'bionic-powers':
      return <BionicPowersActivity />
    case 'fact-files':
      return <FactFileActivity />
    case 'medal':
      return <GraduationMedalActivity />
    case 'helpful-thoughts':
      return <HelpfulThoughts {...{ property, section, panel, index }} />
    case 'friendometer':
      return <FriendometerActivity />
    case 'body-signals':
      return <SecretAgentBodySignalsActivity {...{ section, panel, index }} />
    case 'emotionometer':
      return <EmotionometerActivity {...{ section }} />
    case 'relaxation-gadgets':
      return <RelaxationGadgetsActivity {...{ section, panel, index }} />
    case 'club-rules':
      return <ClubRules section={section} property={property} />
    case 'secret-transmission':
      return <SecretTransmission {...{ section, panel, index }} />
    case 'brain-cell':
      return <BrainCell {...{ section, panel, property, index }} />
    case 'board-game':
      return <BoardGame {...{ section, panel, property, index }} />
    case 'damage-control':
      return <DamageControl {...{ section, panel, property, index }} />
    case 'bully-guard':
      return <BullyGuard {...{ section, panel, index, property }} />
    default:
      return (
        <UnderConstruction>
          <div style={{ height: 400 }} />
        </UnderConstruction>
      )
  }
}

export const EmbeddedActivitySectionFacilitator: React.FC<Props> = props => {
  const { activity } = props.section
  /**
   * Gadget pack activities that have facilitator controls should be exempt from this list i.e.
   *  - relaxation gadgets
   *  - emotionometer
   */
  switch (activity) {
    case 'id-card':
    case 'friendship-formula':
    case 'bionic-powers':
    case 'fact-files':
    case 'friendometer': {
      return (
        <Panel padding="s">
          <P>Please select a participant's tab to view the activity ({activity.replace('-', ' ')}).</P>
        </Panel>
      )
    }
  }

  switch (props.section.activity) {
    case 'scavenger-hunt':
      return <ScavengerHuntFacilitator {...props} />
    case 'detection-expression':
      return <DetectionExpressionFacilitator {...props} />
    case 'emotionometer':
      return <EmotionometerActivityFacilitator {...props} />
    case 'relaxation-gadgets':
      return <RelaxationGadgetsActivityFacilitator {...props} />
    case 'secret-transmission':
      return <SecretTransmissionFacilitator {...props} />
    case 'bully-guard':
      return <BullyGuardFacilitator {...props} />
    case 'body-signals':
      return <SecretAgentBodySignalsActivityFacilitator />
    case 'thought-tracker':
      return <ThoughtTrackerFacilitator {...props} />
    case 'helpful-thoughts':
      return <HelpfulThoughtsFacilitator {...props} />
    case 'brain-cell':
      return <BrainCellFacilitator {...props} />
    case 'invisible-ink':
      return <InvisibleInkFacilitator />
    case 'damage-control':
      return <DamageControlFacilitator {...props} />
    default:
      return <EmbeddedActivitySection {...props} />
  }
}

interface TrainingProps extends TrainingSectionPropsBase {
  property: 'embedded_activity_sections'
}

export const TrainingEmbeddedActivitySection: React.FC<TrainingProps> = props => {
  const user = useGenericUser()
  const participantState: ParticipantState = {
    profile: user,
    status: 'connected',
    currentSlideUid: null,
    locked: false,
    tokens: 0,
    inputValues: [],
    gadgetTrayOpen: false,
    trayType: null,
  }
  let Component = null

  switch (props.section.activity) {
    case 'invisible-ink':
      Component = <InvisibleInkScreen {...props} />
      break
    case 'scavenger-hunt':
      Component = <ScavengerHunt {...props} trainingState={participantState} />
      break
    case 'thought-tracker':
      Component = <ThoughtTracker {...props} width={'100%'} height={600} trainingState={participantState} />
      break
    case 'body-signals':
      Component = <SecretAgentBodySignalsActivity {...props} trainingState={participantState} />
      break
    case 'club-rules':
      Component = <ClubRules {...props} trainingState={participantState} />
      break
    case 'brain-cell':
      Component = (
        <BrainCell
          {...props}
          panel={{
            theme: null,
            uid: '',
            step_id: 0,
            _owner: props.section._owner,
            _owner_id: props.section._owner_id,
            order: 0,
            ...sectionProperties.reduce(
              (obj, property) => ({ ...obj, [property]: [] }),
              {} as { [key in SectionProperty]: [] }
            ),
          }}
          trainingState={participantState}
        />
      )
      break
    case 'board-game':
      Component = <BoardGame {...props} trainingState={participantState} />
      break
  }
  return (
    <SessionStateProvider>
      <UserInputStateProvider>
        <InputContextProvider>{Component}</InputContextProvider>
      </UserInputStateProvider>
    </SessionStateProvider>
  )
}
