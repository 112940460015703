import _find from 'lodash/find'

export function updateOrAppend<
  Entity extends { [key in Key | Keys[number]]: Entity[key] },
  Key extends keyof Entity,
  Keys extends Array<Key>
>(items: Entity[], item: Entity, keys: Keys | Key): Entity[] {
  const matchKeys = keys instanceof Array ? (keys as Keys) : ([keys as Key] as Keys)
  const matchObj = matchKeys.reduce(
    (obj, key: Keys[number]) => ({ ...obj, [key]: item[key] }),
    {} as { [k in Keys[number]]: Entity[Keys[number]] }
  )
  const existingItem = _find(items, matchObj)
  if (existingItem) {
    return items.map(test => (test === existingItem ? item : test))
  } else {
    return [...items, item]
  }
}

export function updateIfExists<
  Entity extends { [key in Key | Keys[number]]: Entity[key] },
  Key extends keyof Entity,
  Keys extends Array<Key>
>(items: Entity[], item: Entity, keys: Keys | Key): Entity[] {
  const matchKeys = keys instanceof Array ? (keys as Keys) : ([keys as Key] as Keys)
  const matchObj = matchKeys.reduce(
    (obj, key: Keys[number]) => ({ ...obj, [key]: item[key] }),
    {} as { [k in Keys[number]]: Entity[Keys[number]] }
  )
  const existingItem = _find(items, matchObj)
  return items.map(test => (existingItem && test === existingItem ? { ...test, ...item } : test))
}

export function updateOrAppendMultiple<Entity extends { [key in Key]: Entity[key] }, Key extends keyof Entity>(
  items: Entity[],
  inputItems: Entity[],
  key: Key
): Entity[] {
  const itemKeys = inputItems.map(newItem => newItem[key])
  const updatedItemKeys: Entity[Key][] = []
  const oldAndUpdatedItems = items.map(item => {
    if (itemKeys.indexOf(item[key]) >= 0) {
      const updatedItem = inputItems.find(test => test[key] === item[key])
      if (updatedItem) {
        updatedItemKeys.push(item[key])
        return { ...item, ...updatedItem }
      }
    }
    return item
  })
  const newItems = inputItems.filter(test => updatedItemKeys.indexOf(test[key]) < 0)
  return [...oldAndUpdatedItems, ...newItems]
}

export function updateMultiple<Entity extends { [key in Key]: Entity[key] }, Key extends keyof Entity>(
  items: Entity[],
  key: Key,
  keys: Entity[Key][],
  data: Partial<Entity>
): Entity[] {
  return items.map(item => (keys.indexOf(item[key]) >= 0 ? { ...item, ...data } : item))
}
