import React, { CSSProperties } from 'react'
import {
  Panel,
  Padding,
  Spacer,
  H1,
  Button,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  TAB_B_S,
  GRILL_TL,
  GRILL_TR,
  PanelProps,
} from 'common/ui'
import { useScreenScaler } from './ScreenScaler'

interface OverviewPanelBaseProps {
  flex?: boolean | string
  shadow?: PanelProps['shadow']
  shadowColor?: PanelProps['shadowColor']
  style?: CSSProperties
  usingScreenScaler?: boolean
}

interface OverviewPanelProps {
  title?: string
  continueDisabled?: boolean
  buttonText?: string
  onContinue: () => void
}

export const OverviewPanel: React.FC<OverviewPanelProps & OverviewPanelBaseProps> = props => {
  const { title, children, onContinue, continueDisabled = false, buttonText = 'Continue' } = props
  return (
    <OverviewPanelBase {...props}>
      <Padding size="s" style={{ overflow: 'auto' }}>
        {title && (
          <H1 marginRight="30px" style={{ flex: 'none' }}>
            {title}
          </H1>
        )}
        {children}
      </Padding>
      <Spacer flex />
      <Button onClick={onContinue} id="continue" disabled={continueDisabled}>
        {buttonText}
      </Button>
    </OverviewPanelBase>
  )
}

export const OverviewPanelBase: React.FC<OverviewPanelBaseProps> = ({
  shadow,
  shadowColor,
  flex = '0 0 400px',
  usingScreenScaler,
  style = {},
  children,
}) => {
  const { zoom } = useScreenScaler()
  const panelStyle: CSSProperties = { maxHeight: `calc(${100 * (usingScreenScaler ? zoom : 1)}vh - 50px)`, ...style }
  return (
    <Panel
      shadow={shadow}
      shadowColor={shadowColor}
      flex={flex}
      padding="60px 30px 35px 30px"
      flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR, TAB_B_S]}
      style={panelStyle}>
      {children}
    </Panel>
  )
}
