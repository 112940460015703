import React, { useState } from 'react'
import { characters } from './characters'
import { BoardGameCharacterId as CharacterId } from 'shared/session/types'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { Field } from 'common/Field'
import { Grid } from 'common/Grid'
import { CharacterAvatar } from './CharacterAvatar'
import { Button, Row } from 'common/ui'
import { SymbolSelectModal } from 'common/SymbolSelectModal'
import { boardGamePlayerEmojis } from 'constants/emojiSets'

export interface EditPlayerModalProps {
  isOpen: boolean
  characterId: CharacterId | null
  usedCharacters: CharacterId[]
  onSelect: (characterID: CharacterId, customEmoji?: string) => void
}

const emojis = boardGamePlayerEmojis

export const EditPlayerModal: React.FC<EditPlayerModalProps> = ({ isOpen, characterId, usedCharacters, onSelect }) => {
  const [selected, setSelected] = useState<CharacterId | null>(characterId)
  const [emojiModalOpen, setEmojiModalOpen] = useState<boolean>(false)

  const handleSelected = (id: CharacterId) => {
    setSelected(id)
    onSelect(id)
  }

  return (
    <>
      <MobileMessageModal isOpen={isOpen}>
        <Field label="Choose Character:">
          <Grid columns={4}>
            {characters.map((character) => {
              if (character.id === 'custom') return null
              return (
                <CharacterAvatar
                  key={character.id}
                  character={character}
                  selected={character.id === selected}
                  disabled={usedCharacters.indexOf(character.id) >= 0}
                  onSelect={() => {
                    handleSelected(character.id)
                  }}
                  customEmoji={null}
                />
              )
            })}
          </Grid>
          <Row justifyContent="center">
            <Button
              size="s"
              children="Add Custom"
              style={{ marginTop: 20, cursor: 'pointer' }}
              onClick={() => setEmojiModalOpen(true)}
            />
          </Row>
        </Field>
      </MobileMessageModal>
      <SymbolSelectModal
        isOpen={emojiModalOpen}
        symbols={emojis}
        value={null}
        onChange={(symbol) => {
          onSelect('custom', symbol)
          setEmojiModalOpen(false)
        }}
        onClose={() => {
          setEmojiModalOpen(false)
        }}
      />
    </>
  )
}
