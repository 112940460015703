/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useRef, useEffect, useState } from 'react'
import { useDeferredEndpoint } from 'dashboards/utils/endpointHooks'
import { GroupEntity, SessionEntity, MentorEntity } from 'shared/dashboard/types'
import { useLocalStorage } from 'utils/useStorage'

function useProviderMentorDashState() {
  const [mentorUid, setMentorUid] = useLocalStorage<MentorEntity['uid'] | null>('mentorUid', null)
  const [cadetPrimaryMentorUid, setCadetPrimaryMentorUid] = useLocalStorage<MentorEntity['uid'] | null>(
    'cadetPrimaryMentorUid',
    null
  )
  const mentor = useDeferredEndpoint<MentorEntity>(mentorUid ? `/api/v1/mentors/${mentorUid}` : null)
  const primaryMentor = useDeferredEndpoint<MentorEntity>(
    cadetPrimaryMentorUid ? `/api/v1/mentors/${cadetPrimaryMentorUid}` : null
  )
  const group = useDeferredEndpoint<GroupEntity>(mentorUid ? `/api/v1/mentors/${mentorUid}/group` : null)
  const sessions = useDeferredEndpoint<SessionEntity[]>(
    mentorUid ? `/api/v1/sessions?mentor_uid=${mentorUid}` : null,
    []
  )
  useEffect(() => {
    if (mentorUid) mentor.fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorUid])
  useEffect(() => {
    if (cadetPrimaryMentorUid) primaryMentor.fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cadetPrimaryMentorUid])
  return {
    mentorUid,
    setMentorUid,
    cadetPrimaryMentorUid,
    setCadetPrimaryMentorUid,
    endpoints: {
      mentor,
      primaryMentor,
      group,
      sessions,
    },
  }
}

export function useMentorDashData<Key extends keyof ReturnType<typeof useProviderMentorDashState>['endpoints']>(
  key: Key,
  defaultValue?: Exclude<ReturnType<typeof useProviderMentorDashState>['endpoints'][Key]['value'], null>
): [
  Exclude<ReturnType<typeof useProviderMentorDashState>['endpoints'][Key]['value'], null> | typeof defaultValue,
  boolean,
  () => void
] {
  const loaded = useRef<boolean>(false)
  const mentorDashState = useMentorDashState() as ReturnType<typeof useProviderMentorDashState>

  useEffect(() => {
    if (!mentorDashState.endpoints[key].loading && !loaded.current) {
      mentorDashState.endpoints[key]
        .fetch()
        .then(() => (loaded.current = true))
        .catch(() => {})
    }
    // eslint-disable-next-line
  }, [])

  const value = mentorDashState.endpoints[key].value
  const reload = () => {
    loaded.current = false
    mentorDashState.endpoints[key]
      .fetch()
      .then(() => (loaded.current = true))
      .catch(() => {})
  }
  return [
    value !== null ? (value as typeof defaultValue) : defaultValue,
    mentorDashState.endpoints[key].loading,
    reload,
  ]
}

function noop(): any {}

const emptyEndpointObj = {
  value: null,
  fetch: noop,
  loaded: false,
  loading: false,
  errorLoading: false,
  errorMessage: null,
  fetchUrl: null,
} satisfies ReturnType<typeof useDeferredEndpoint>

export const MentorDashStateContext = React.createContext<ReturnType<typeof useProviderMentorDashState>>({
  mentorUid: null,
  setMentorUid: noop,
  cadetPrimaryMentorUid: null,
  setCadetPrimaryMentorUid: noop,
  endpoints: {
    mentor: emptyEndpointObj,
    primaryMentor: emptyEndpointObj,
    group: emptyEndpointObj,
    sessions: emptyEndpointObj,
  },
})

export const MentorDashStateProvider: React.FC = ({ children }) => {
  const state = useProviderMentorDashState()
  return <MentorDashStateContext.Provider value={state}>{children}</MentorDashStateContext.Provider>
}

export function useMentorDashState() {
  return useContext(MentorDashStateContext)
}
