import React from 'react'

import { Row, Button } from 'common/ui'

import { IntroTemplate } from './IntroTemplate'

export const AgentSelectPage: React.FC<{ onNewAgent: () => void; onExistingAgent: () => void }> = ({
  onNewAgent,
  onExistingAgent,
}) => {
  return (
    <IntroTemplate title="Welcome">
      <Row justifyContent="space-around">
        <Button onClick={onNewAgent}>New Agent</Button>
        <Button onClick={onExistingAgent}>Existing Agent</Button>
      </Row>
    </IntroTemplate>
  )
}
