import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { P, Button, Spacer, CollapseMargin, Form } from 'common/ui'
import { fontRegular, fontBold } from 'fonts'
import { useUserState } from 'app/UserState'
import { ThemeRadioGroup } from 'app/ThemeRadioGroup'

import { IntroTemplate } from './IntroTemplate'
import { useGlobalState } from 'app/GlobalState'
import { THEMES } from 'themes'
import { updateProfile, createProfile } from 'api'
import { getInputProps } from 'utils/virtualKeyboard'

function usePersistThemeChange() {
  const { profileId, accessToken } = useUserState()
  const { themeIndex } = useGlobalState()
  useEffect(() => {
    updateProfile({ id: profileId, theme_name: THEMES[themeIndex].name }, accessToken).catch(console.log)
  }, [themeIndex, profileId, accessToken])
}

export const ExistingAgentPage: React.FC<{ onComplete: () => void; onContinue: () => void }> = ({
  onComplete,
  onContinue,
}) => {
  const {
    profiles,
    setProfiles,
    setProfileId,
    setProfileName,
    profileName,
    setOpenAccess,
    accessToken,
  } = useUserState()
  const { themeIndex } = useGlobalState()
  const [agentName, setAgentName] = useState(profileName)
  const [error, setError] = useState('')
  const [busy, setBusy] = useState(false)

  // TODO: move this elsewhere/re-factor?? this could be potentially changing
  // default profile theme instead of just for open_access profile
  usePersistThemeChange()

  const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()

    const openAccess = agentName.toLowerCase() === 'open_access'
    const profile = profiles.find(p => p.profile_name === agentName)

    if (profile) {
      setProfileId(profile.id)
      setProfileName(profile.profile_name || '')
      setOpenAccess(openAccess)
      onComplete()
    } else if (openAccess) {
      setBusy(true)
      createProfile({ profile_name: agentName, theme_name: THEMES[themeIndex].name }, accessToken)
        .then(profile => {
          ReactDOM.unstable_batchedUpdates(() => {
            setProfiles(profiles => profiles.concat([profile]))
            setProfileId(profile.id)
            setProfileName(profile.profile_name || '')
            setOpenAccess(openAccess)
          })
          onComplete()
        })
        .catch(() => {
          setError('Could not create profile. Please try again.')
          setBusy(false)
        })
    } else {
      setError('I’m sorry, but we do not have a record of that name.')
    }
  }

  function onReset() {
    setAgentName(profileName)
    setError('')
  }

  return (
    <IntroTemplate title="Existing Agent">
      <CollapseMargin>
        <P align="center">Enter your Agent Name</P>
      </CollapseMargin>
      <Spacer />
      <Form onSubmit={onSubmit}>
        <TextInput
          error={!!error}
          readOnly={busy || !!error}
          autoFocus
          value={agentName}
          onChange={e => {
            setAgentName(e.target.value)
            setError('')
          }}
          {...getInputProps()}
        />
        {error && (
          <>
            <Spacer />
            <FormError>{error}</FormError>
          </>
        )}

        {!error && (
          <>
            <Spacer />
            <FormLabel>Select your theme</FormLabel>
            <Spacer size="xs" />
            <ThemeRadioGroup size="s" />
          </>
        )}

        <Spacer size="l" />

        {!error && (
          <Button type="submit" disabled={busy || agentName === ''}>
            Play
          </Button>
        )}
      </Form>

      {error && (
        <Button type="button" onClick={!profileName ? onContinue : onReset}>
          Continue
        </Button>
      )}
    </IntroTemplate>
  )
}

const TextInput = styled.input<{ error?: boolean }>`
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  outline: none;

  border: none;
  border-top: 3px solid #d5d9ed;
  box-sizing: border-box;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  text-align: center;
  ${fontBold}
  font-size: 22px;
  line-height: 1;
  padding: 10px 0;
  color: #001947;

  position: relative;

  ${p => (p.error ? ':enabled,' : '')} :focus {
    box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 0 0 3px ${p => (p.error ? 'red' : p.theme.buttonBorderTopColor)};
    border: 0;
    margin-top: 3px;
  }
`

const FormError = styled.div`
  ${fontRegular}
  color: #ab0303;
  padding: 10px;
  border-radius: 10px;
`

const FormLabel = styled.label`
  ${fontBold}
  font-size: 16px;
  color: #001947;
  padding-left: 2px;
`
