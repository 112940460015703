import { GameObjects, Math as PhaserMath, Input, Geom } from 'phaser'
import SceneBuilderScene from './SceneBuilderScene'
import AssetSprite from './AssetSprite'
import { getSquareImageKey, getCircleImageKey } from 'phaser/shapeTextureGenerator'
const { POINTER_DOWN } = Input.Events

export type HandleRole = 'resize' | 'rotate'
export type HandleDirection = 'TL' | 'TR' | 'BL' | 'BR'
const HANDLE_SIZE = 14 // px

export default class DragHandle extends GameObjects.Image {
  public role: HandleRole
  public direction: HandleDirection
  public preRotateAngle: number = 0
  public rotateOffsetDist: number = 20
  public resizeOffsetDist: number = 0 // this likely won't change
  constructor(scene: SceneBuilderScene, role: HandleRole, direction: HandleDirection) {
    super(
      scene,
      0,
      0,
      role === 'resize'
        ? getSquareImageKey(scene, { size: HANDLE_SIZE * 2, fill: 0xffffff, stroke: 0xaaaaaa, strokeThickness: 2 })
        : getCircleImageKey(scene, { size: HANDLE_SIZE * 2, fill: 0xffffff, stroke: 0xaaaaaa, strokeThickness: 2 })
    )

    scene.add.existing(this)
    this.role = role
    this.direction = direction
    this.rotateOffsetDist = 20 * scene.screenScalerZoom
    this.setScale(0.5 * scene.screenScalerZoom)
    if (role === 'resize') {
      this.setInteractive(
        new Geom.Rectangle((-HANDLE_SIZE * 1.5) / 2, (-HANDLE_SIZE * 1.5) / 2, HANDLE_SIZE * 3.5, HANDLE_SIZE * 3.5),
        Geom.Rectangle.Contains
      )
      this.input.cursor = 'pointer'
    } else if (role === 'rotate') {
      this.setInteractive(
        new Geom.Circle((HANDLE_SIZE * 1.5) / 2, (HANDLE_SIZE * 1.5) / 2, HANDLE_SIZE * 1.75),
        Geom.Circle.Contains
      )
      this.input.cursor = 'alias'
    }
    this.input.draggable = true

    this.on(POINTER_DOWN, (p: Phaser.Input.Pointer, x: number, y: number, e: Phaser.Types.Input.EventData) => {
      if (scene.disableInteraction) return

      if (role === 'resize') {
        e.stopPropagation()
        scene.startResize(this)
      }
      if (role === 'rotate') {
        e.stopPropagation()
        scene.startRotate(this)
        if (scene.state.selectedSprite) {
          this.preRotateAngle = PhaserMath.Angle.BetweenPoints(scene.state.selectedSprite, this)
        }
      }
    })
  }
  updateCursor(sprite: AssetSprite) {
    const angle = Math.atan2(sprite.y - this.y, sprite.x - this.x)
    if (angle >= 0 && angle < Math.PI / 2) this.input.cursor = 'nw-resize'
    if (angle >= Math.PI / 2 && angle < Math.PI) this.input.cursor = 'ne-resize'
    if (angle <= 0 && angle > -Math.PI / 2) this.input.cursor = 'sw-resize'
    if (angle <= -Math.PI / 2 && angle > -Math.PI) this.input.cursor = 'se-resize'
  }
  updatePosition(sprite: AssetSprite) {
    if (this.role === 'resize' || this.role === 'rotate') {
      const hypotDist = sprite.hypot2 + (this.role === 'rotate' ? this.rotateOffsetDist : this.resizeOffsetDist)
      switch (this.direction) {
        case 'TL':
          return this.setPosition(
            sprite.x - Math.cos(sprite.rotation + sprite.hypotAngle) * hypotDist,
            sprite.y - Math.sin(sprite.rotation + sprite.hypotAngle) * hypotDist
          )
        case 'TR':
          return this.setPosition(
            sprite.x + Math.cos(sprite.rotation - sprite.hypotAngle) * hypotDist,
            sprite.y + Math.sin(sprite.rotation - sprite.hypotAngle) * hypotDist
          )
        case 'BL':
          return this.setPosition(
            sprite.x - Math.cos(sprite.rotation - sprite.hypotAngle) * hypotDist,
            sprite.y - Math.sin(sprite.rotation - sprite.hypotAngle) * hypotDist
          )
        case 'BR':
          return this.setPosition(
            sprite.x + Math.cos(sprite.rotation + sprite.hypotAngle) * hypotDist,
            sprite.y + Math.sin(sprite.rotation + sprite.hypotAngle) * hypotDist
          )
      }
    }
  }
}
