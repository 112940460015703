import React, { CSSProperties, useEffect, useRef, ReactNode } from 'react'
import Modal from 'app/Modal'
import { Panel, Padding, Row, Spacer, Button, P, Hr, ButtonProps } from 'common/ui'
import { useHotkey } from 'utils/useHotkey'
import { purple, gray, Theme } from 'themes'

export interface PromptModalProps {
  isOpen: boolean
  onSubmit: (value: boolean) => void
  title?: ReactNode
  label: ReactNode
  confirmText?: string
  cancelText?: string
  confirmTheme?: Theme
  cancelTheme?: Theme
  disableSubmit?: boolean
  buttonSize?: ButtonProps['size']
  width?: number | string
  style?: CSSProperties
  forceInput?: boolean
  reverseButtons?: boolean
  labelBelow?: boolean
  enableScaling?: boolean
}

export const PromptModal: React.FC<PromptModalProps> = ({
  isOpen,
  onSubmit,
  title,
  label,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmTheme = purple,
  cancelTheme = gray,
  disableSubmit = false,
  buttonSize = 'm',
  forceInput = false,
  width = 460,
  style = {},
  reverseButtons,
  labelBelow,
  enableScaling,
  children,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement | null>(null)

  const titleNode = typeof title === 'string' ? <strong children={title} /> : title
  const labelNode =
    typeof label === 'string' ? <P style={{ whiteSpace: 'pre-wrap', margin: 0 }} children={label} /> : label

  const handleClose = (cancelPressed: boolean = false) => {
    if (!cancelPressed && forceInput) return
    onSubmit(false)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!disableSubmit) onSubmit(true)
    return false
  }

  useHotkey('esc', () => handleClose(false), [])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isOpen) setTimeout(() => submitButtonRef.current && submitButtonRef.current.focus(), 50)
  }, [isOpen])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Modal isOpen={isOpen} disableScaling={!enableScaling} onRequestClose={() => handleClose(false)}>
      <Panel
        shadow={false}
        style={{ maxHeight: 'calc(100vh - 50px)', width, backgroundColor: 'white', borderRadius: 5, ...style }}>
        {title && (
          <>
            <Padding size="s">
              <div>{titleNode}</div>
            </Padding>
            <Hr />
          </>
        )}
        <Padding size="m" style={{ overflow: 'auto' }}>
          {!labelBelow && (
            <>
              {labelNode}
              <Spacer size="s" />
            </>
          )}
          <form onSubmit={handleSubmit}>
            {children}
            <Row justifyContent="flex-start" reverse={reverseButtons} marginTop="s" marginBottom="s">
              <Button
                ref={submitButtonRef}
                id="submit"
                type="submit"
                size={buttonSize}
                disabled={disableSubmit}
                children={confirmText}
                theme={confirmTheme}
              />
              <Spacer size="s" />
              <Button
                id="cancel"
                size={buttonSize}
                theme={cancelTheme}
                onClick={() => handleClose(true)}
                children={cancelText}
              />
            </Row>
          </form>
          {labelBelow && (
            <>
              <Spacer size="s" />
              {labelNode}
            </>
          )}
        </Padding>
      </Panel>
    </Modal>
  )
}
