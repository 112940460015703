import { EventEmitter } from 'events'

export default class StopWatch extends EventEmitter {
  private _paused: boolean = true
  private alarmInterval: number
  private timerStartTime = Date.now()
  private seconds: number = 0
  private secondsPauseValue: number = 0

  private interval?: number

  constructor(alarmInterval: number = 0) {
    super()
    this.alarmInterval = alarmInterval
  }

  public get paused() {
    return this._paused
  }

  public start() {
    if (this._paused) {
      this.timerStartTime = Date.now()
      if(!this.interval) this.interval = window.setInterval(() => this.tick(), 250)
      this._paused = false
      this.emit('running', true)
    }
  }

  public pause() {
    this._paused = true
    this.secondsPauseValue = this.seconds
    this.emit('running', false)
  }

  public reset() {
    this.pause()
    this.seconds = 0
    this.secondsPauseValue = 0
    this.emit('timeupdate', this.seconds)
  }

  public destroy() {
    if(this.interval) window.clearTimeout(this.interval)
  }

  public toggleStartStop() {
    if (this._paused) this.start()
    else this.pause()
  }

  private tick() {
    const now = Date.now()
    this.seconds = Math.max(0, this.secondsPauseValue + (now - this.timerStartTime) / 1000)

    if(!this._paused) {
      this.emit('timeupdate', this.seconds)
      if(this.alarmInterval > 0) {
        if(Math.floor(this.seconds % this.alarmInterval) === 0) {
          this.emit('alarm')
        }
      }
    }
  }
}
