import React from 'react'

import ExitButton from 'common/ExitButton'
import PDA from 'common/PDA'
import { useArdon } from 'utils/useArdon'
import { usePda } from 'utils/usePda'
import { useSound } from 'utils/useSound'
import { Row, Column, Spacer, P, AppBackground, Padding, CollapseMargin } from 'common/ui'
import { OverviewPanel } from 'common/OverviewPanel'
import { GameLevel } from 'types'
import { ShortcutMenu } from 'common/ShortcutMenu'

interface Props {
  level: GameLevel
  onComplete: () => void
}

export const Promotion: React.FC<Props> = ({ level, onComplete }) => {
  useArdon(
    useSound(level === 1 ? require('./assets/audio/promotion_1572.mp3') : require('./assets/audio/promotion_2471.mp3'))
  )
  usePda()
  return (
    <AppBackground>
      <Padding>
        <Row flex>
          <Column flex="1 1 0px" />
          <Column flex="0 0 480px">
            <Spacer flex />
            <OverviewPanel title="Promotion!" onContinue={onComplete}>
              {level === 1 && (
                <CollapseMargin top={false}>
                  <P>
                    You have now successfully completed Level 1 of the program, and have been awarded a promotion in
                    ranking to Police Officer.
                  </P>
                  <P>This is an excellent achievement!!</P>
                  <P>To continue with your training, please repeat Quiz 1, and proceed to Level 2.</P>
                </CollapseMargin>
              )}
              {level === 2 && (
                <CollapseMargin top={false}>
                  <P>
                    You have now successfully completed Level 2 of training and been promoted to the rank of Detective.
                  </P>
                  <P>
                    After repeating the Level 2 Quiz, take the lift to Level 3 where you will complete your most
                    challenging missions yet!
                  </P>
                </CollapseMargin>
              )}
            </OverviewPanel>
            <Spacer flex />
          </Column>
          <Row flex="1 1 0px">
            <Spacer flex />
            <Column>
              <ExitButton shadowColor="theme" />
              <Spacer flex />
              <PDA />
            </Column>
          </Row>
        </Row>
      </Padding>
      <ShortcutMenu />
    </AppBackground>
  )
}
