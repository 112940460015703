import { Spacer } from 'common/ui'
import React, { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import reactDom from 'react-dom'
import { useInputContext } from 'session/InputContext'
import { useSessionState } from 'session/SessionState'
import { ACTIVATE_INPUT_LOCK, DEACTIVATE_INPUT_LOCK } from 'shared/session/actionTypes'
import { EditingState, InputValueContext } from 'shared/session/types'
import { Switch } from 'common/Switch'
import styled, { css } from 'styled-components'
import { SVG } from 'common/SVG'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { tilt } from './UserInputIndicator'

interface RenderHandlerProps {
  onChange: (value: string) => void
  onFocus: () => void
  onBlur: () => void
}

interface RenderStateProps {
  disabled: boolean
  readOnly: boolean
}

export interface InputLockProps {
  disregard?: boolean
  fieldUid?: string
  facilitatorOnly?: boolean
  verticalPlacementCadet?: number
  horizontalPlacementCadet?: number
  verticalPlacementFacilitator?: number
  horizontalPlacementFacilitator?: number
  hasLabel?: boolean
  hideIcon?: boolean
  hideToggle?: boolean
  children: (props: RenderHandlerProps, state: RenderStateProps) => ReactNode
}

const getInputUid = (inputContext: InputValueContext, fieldUid?: string): string =>
  fieldUid
    ? fieldUid
    : `${inputContext.owner}___${inputContext.owner_id}___${inputContext.name}__${inputContext.participant_uid}`

const TIMEOUT_LENGTH = 10000
const EDIT_TIMEOUT_LENGTH = 5000

export const InputLockComponent: React.FC<InputLockProps> = ({
  disregard,
  fieldUid,
  facilitatorOnly = false,
  verticalPlacementCadet = 0,
  horizontalPlacementCadet = 0,
  verticalPlacementFacilitator = 0,
  horizontalPlacementFacilitator = 0,
  hideIcon = false,
  hideToggle = false,
  hasLabel = false,
  children,
}) => {
  const {
    dispatch,
    getBaseAction,
    isFacilitator,
    sessionProfile,
    state: { editingState, globalLock },
  } = useSessionState()
  const participantState = useFocusedParticipantState()
  const inheritedInputContext = useInputContext()
  const inputUid = useRef<string>(getInputUid(inheritedInputContext, fieldUid))
  const existingLock = useRef<EditingState>(editingState[inputUid.current])
  const [expiryTime, setExpiryTime] = useState<number>(0)
  // const [, setFocused] = useState<boolean>(false)

  // ? NOTE: Commenting out this state change - maybe need it later
  const handleFocus = useCallback(() => {
    // if (isFacilitator) {
    //   setFocused(true)
    // }
  }, [])

  // ? NOTE: Commenting out this state change - maybe need it later
  const handleBlur = useCallback(() => {
    // if (isFacilitator) {
    //   setFocused(false)
    // }
  }, [])

  const handleLockClick = useCallback(() => {
    if (isFacilitator) {
      if (!!existingLock.current) {
        reactDom.unstable_batchedUpdates(() => {
          dispatch({
            ...getBaseAction(),
            type: DEACTIVATE_INPUT_LOCK,
            inputUid: inputUid.current,
          })
          setExpiryTime(0)
        })
      } else {
        reactDom.unstable_batchedUpdates(() => {
          dispatch({
            ...getBaseAction(),
            type: ACTIVATE_INPUT_LOCK,
            inputUid: inputUid.current,
          })
          setExpiryTime(Date.now() + TIMEOUT_LENGTH)
        })
      }
    }
  }, [isFacilitator, dispatch, getBaseAction])

  const handleInputChange = useCallback(() => {
    if (isFacilitator) {
      setExpiryTime(Date.now() + EDIT_TIMEOUT_LENGTH)
    }
  }, [isFacilitator])

  // ? Set our refs for use in cleanup and effects
  useEffect(() => {
    inputUid.current = getInputUid(inheritedInputContext, fieldUid)
    existingLock.current = editingState[inputUid.current]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inheritedInputContext, fieldUid])

  // ? Watch our timeout and clear lock if we're past the expiry time
  useEffect(() => {
    let timeout: number = 0
    if (expiryTime > 0) {
      timeout = setTimeout(() => {
        dispatch({
          ...getBaseAction(),
          type: DEACTIVATE_INPUT_LOCK,
          inputUid: inputUid.current,
        })
        setExpiryTime(0)
      }, expiryTime - Date.now())
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTime])

  // ? Cleanup any stray locks when unmounted
  useEffect(
    () => () => {
      if (isFacilitator && existingLock.current && existingLock.current.facilitator_uid === sessionProfile.uid) {
        dispatch({
          ...getBaseAction(),
          type: DEACTIVATE_INPUT_LOCK,
          inputUid: inputUid.current,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (typeof children !== 'function') return null

  if (disregard || facilitatorOnly) {
    return (
      <>
        {children(
          {
            onBlur: () => {},
            onFocus: () => {},
            onChange: () => {},
          },
          {
            disabled: false,
            readOnly: false,
          }
        )}
      </>
    )
  }

  if (isFacilitator) {
    const lock = editingState[inputUid.current]
    const isLockOwner = lock && lock.facilitator_uid === sessionProfile.uid
    const cadetScreenLocked = globalLock || (participantState ? participantState.locked : false)
    return (
      <div
        className="facilitator-only-padding"
        style={{ paddingTop: hasLabel ? 0 : 30, width: '100%', height: '100%' }}>
        {!hideToggle && (
          <TogglePlacement
            visible={true}
            verticalPlacement={verticalPlacementFacilitator}
            horizontalPlacement={horizontalPlacementFacilitator}>
            <LockSymbol
              locked={!!lock || isLockOwner || cadetScreenLocked}
              isFacilitator
              wrapperStyles={{ marginTop: -2 }}
            />
            <Spacer size="xs" />
            <Switch
              disabled={lock && !isLockOwner && !cadetScreenLocked}
              size="xs"
              value={!!lock || cadetScreenLocked}
              onChange={cadetScreenLocked ? () => {} : handleLockClick}
            />
          </TogglePlacement>
        )}
        {children(
          { onBlur: handleBlur, onFocus: handleFocus, onChange: handleInputChange },
          {
            disabled: cadetScreenLocked ? false : !isLockOwner,
            readOnly: cadetScreenLocked ? false : !isLockOwner,
          }
        )}
      </div>
    )
  } else {
    const lock = editingState[inputUid.current]
    return (
      <>
        {!hideIcon && lock && (
          <TogglePlacement
            visible
            verticalPlacement={verticalPlacementCadet}
            horizontalPlacement={horizontalPlacementCadet}>
            <LockSymbol locked />
          </TogglePlacement>
        )}
        {children(
          { onBlur: handleBlur, onFocus: handleFocus, onChange: () => {} },
          { disabled: !!lock, readOnly: !!lock }
        )}
      </>
    )
  }
}

export const LockSymbol: React.FC<{ locked: boolean; isFacilitator?: boolean; wrapperStyles?: CSSProperties }> = ({
  locked,
  isFacilitator = false,
  wrapperStyles,
}) => (
  <IconWrapper active={!isFacilitator} style={wrapperStyles}>
    <SVG size={16} viewboxSize={24}>
      <path
        fill={locked && isFacilitator ? '#4EBE40' : isFacilitator ? '#afafaf' : '#666'}
        d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
      />
    </SVG>
  </IconWrapper>
)

const TogglePlacement = styled('div')<{ visible: boolean; verticalPlacement: number; horizontalPlacement: number }>`
  position: absolute;
  right: ${props => `${props.horizontalPlacement}px`};
  top: ${props => `${props.verticalPlacement}px`};
  display: flex;
  align-items: center;

  ${p =>
    p.visible
      ? css`
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        `}
`

const IconWrapper = styled.div<{ active?: boolean }>`
  animation: ${p =>
    p.active
      ? css`
          ${tilt} 0.35s linear infinite alternate
        `
      : 'none'};
`
