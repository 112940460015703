import _uniq from 'lodash/uniq'
import _isEqual from 'lodash/isEqual'

export const objectValuesChanged = <ValueType extends object>(
  obj1: ValueType,
  obj2: ValueType,
  keys?: (keyof ValueType)[]
) => {
  keys = keys
    ? keys
    : _uniq([
        ...((Object.keys(obj1) as unknown) as (keyof ValueType)[]),
        ...((Object.keys(obj2) as unknown) as (keyof ValueType)[]),
      ])
  for (let key of keys) {
    if (!(key in obj1) || !(key in obj2)) {
      console.log(`[objectValuesChanged] ${String(key)} is missing from one of the objects`)
      return true
    }
    if (typeof obj1[key] !== typeof obj2[key]) {
      console.log(`[objectValuesChanged] value typeof ${String(key)} changed`)
      return true
    }
    if (typeof obj1[key] === 'object') {
      if (!_isEqual(obj1[key], obj2[key])) {
        console.log(`[objectValuesChanged] lodash isEqual returns false for ${String(key)}`)
        return true
      }
    } else {
      if (obj1[key] !== obj2[key]) {
        console.log(`[objectValuesChanged] strict comparison of ${String(key)} returns false`)
        return true
      }
    }
  }
  return false
}
