import { CUT_BL_DARK, CUT_BR_DARK, CUT_TL_DARK, CUT_TR_DARK, TAB_B_DARK, TAB_T_DARK } from 'common/ui'
import React from 'react'
import { SectionsPanelStyled } from 'session/common/SectionsPanel'
import { Block, BlockBody, BlockHeader, Blocks, BlocksColumn } from 'session/sections/Block'
import styled from 'styled-components'
import { Title } from './ConversationEquations'

const gridItems = [
  {
    order: 1,
    type: 'image',
    content: 'Use your relaxation gadgets to stay calm. It’s OK for people to think differently about things.',
    imagePath: require('session/assets/negotiation-tactics/1.png'),
  },
  {
    order: 2,
    type: 'image',
    content:
      'Ask questions about any parts of the idea that you don’t understand or aren’t sure about. This shows you’re listening and you care about what they have to say.',
    imagePath: require('session/assets/negotiation-tactics/2.png'),
  },
  {
    order: 3,
    type: 'image',
    content: 'Ask if you can talk about the problem (if you aren’t already doing that).',
    imagePath: require('session/assets/negotiation-tactics/3.png'),
  },
  {
    order: 4,
    type: 'image',
    content: 'Suggest your idea as a question (don’t boss people around or tell them what to do).',
    imagePath: require('session/assets/negotiation-tactics/4.png'),
  },
  {
    order: 5,
    type: 'image',
    content: 'Ask the other person or group what they think about the problem (if they aren’t already telling you).',
    imagePath: require('session/assets/negotiation-tactics/5.png'),
  },
  {
    order: 6,
    type: 'image',
    content:
      'Explain why you think your idea is good (maximum 3 sentences). Focus on how your solution will be better. If it isn’t, they’re not likely to agree to it!',
    imagePath: require('session/assets/negotiation-tactics/6.png'),
  },
  {
    order: 7,
    type: 'image',
    content:
      'Quietly listen to what they have to say. Try to look at their face(s) at least half of the time to show them you are listening.',
    imagePath: require('session/assets/negotiation-tactics/7.png'),
  },
  {
    order: 8,
    type: 'image',
    content:
      "Try to agree on a solution that involves doing a bit of what they want and a bit of what you want. Sometimes to be a good friend, it’s worth trying someone else's idea, even if you don’t think it is best.",
    imagePath: require('session/assets/negotiation-tactics/8.png'),
  },
  {
    order: 9,
    type: 'image',
    content: 'Try to agree with part of their idea or say something that you like about it.',
    imagePath: require('session/assets/negotiation-tactics/9.png'),
  },
  {
    order: 10,
    type: 'image',
    content: 'If you’re finding it hard to use these steps, ask an adult for help (e.g. teacher, parent).',
    imagePath: require('session/assets/negotiation-tactics/10.png'),
  },
]

export const NegotiationTactics: React.FC = () => (
  <>
    <Title panelTheme="red">
      TOP 10 NEGOTIATION TACTICS <span>THE D.E.C.O.D.E.R PROBLEM SOLVING FORMULA</span>
    </Title>
    <SectionsPanelStyled
      panelTheme={'red'}
      flex={'1 0 auto'}
      flair={[CUT_TR_DARK, CUT_TL_DARK, CUT_BL_DARK, CUT_BL_DARK, CUT_BR_DARK, TAB_B_DARK, TAB_T_DARK]}
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <div style={{ padding: '30px 20px' }}>
        <Blocks>
          {gridItems.map((item, i) => (
            <BlocksColumn key={i} columns={2}>
              <Block display={'inline'} blockTheme={undefined} style={{ height: 225 }}>
                <BlockHeader style={{ flex: '0 0 45%' }}>
                  <BlockImage path={item.imagePath} />
                </BlockHeader>
                <BlockText children={item.content} />
              </Block>
            </BlocksColumn>
          ))}
        </Blocks>
      </div>
    </SectionsPanelStyled>
  </>
)

const BlockText = styled(BlockBody)`
  background-color: #2c2c2c !important;
  background-image: none !important;
  font-size: 0.7em;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px #4d4d4d, inset 0px 1px 1px #4d4d4d;
  display: flex;
  align-items: center;
`

const BlockImage = styled.div<{ path: string }>`
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  background-image: url(${p => p.path});
  background-size: cover;
  background-position: center;
`
