import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { blue, orange, parentGreen, purple, Theme } from 'themes'
import { FacManStateProvider, useFacManState } from './FacManState'
import { FacManStep } from './FacManStep'
import { useParams } from 'react-router-dom'
import { useSocket } from 'utils/useSocket'
import { Container, Spacer } from 'common/ui'
import { FacManSectionHeader } from './FacManModule'

const themeOptions: { [key: string]: Theme } = {
  cadet: blue,
  parent: parentGreen,
  teacher: orange,
  default: purple,
}

export const FacManPreview: React.FC = () => {
  return (
    <FacManStateProvider>
      <FacManPreviewInner />
    </FacManStateProvider>
  )
}

const FacManPreviewInner: React.FC = () => {
  const { previewUid } = useParams<{ previewUid?: string }>()
  const [initedPreviewData, setInitedPreviewData] = useState<boolean>(false)
  const { facManData, setFacManData, previewing, setPreviewing, previewState, setPreviewState } = useFacManState()
  const socket = useSocket(process.env.REACT_APP_PREVIEW_SOCKET_URL)
  useEffect(() => {
    if (!socket || !previewUid) return
    if (!socket.connected) {
      console.log('Preview socket listeners being created...')
      socket.on('connect', () => {
        console.log('Preview socket connection established')
        socket.emit('REGISTER_VIEWER', previewUid)
      })
      socket.on('disconnect', () => {
        console.log('Preview socket disconnected')
      })
      socket.on('VIEWER_DATA_UPDATED', (facManData: any) => {
        console.log('Received viewer data from preview socket', facManData)
        setFacManData(facManData)
        setInitedPreviewData(true)
      })
      // TODO: move action processor elsewhere
      socket.on('DISPATCH', (action: any) => {
        console.log('Received action from preview socket server', action)
        switch (action.type) {
          case 'UPDATE_PREVIEW_STATE':
            console.log('Updating preview state', action.state)
            setPreviewState(action.state)
            break
        }
      })
      socket.connect()
    } else {
      console.log('Socket already connected')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, previewUid])

  useEffect(() => {
    if (!previewing) setPreviewing(true)
  }, [previewing, setPreviewing])

  if (!initedPreviewData || !facManData || !previewUid) return <h1>Please wait</h1>

  const step = facManData.module.manual_steps.find((value, index) => index === previewState.stepIndex)

  return (
    <ThemeProvider theme={themeOptions[facManData.module.type] || themeOptions.default}>
      <Container>
        <Background />
        <h1>Facilitator Manual Step Preview</h1>
        {step && step.show_section_header && (
          <>
            <FacManSectionHeader children={step.section_header_text} />
            <Spacer size="m" />
          </>
        )}
        {step ? <FacManStep step={step} /> : <p>step not found :(</p>}
      </Container>
    </ThemeProvider>
  )
}

const Background = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(209.57deg, #955df2 0.61%, #813aba 44.09%);
`
