import React from 'react'
import { HomeCadet } from './HomeCadet'
import { HomeMentor } from './HomeMentor'
import { NotFoundPage } from './NotFoundPage'
import { getCurrentDomainEnv } from 'app/RequireDomain'

export const Home: React.FC = () => {
  const env = getCurrentDomainEnv()
  console.log('ENV:', env)
  if (env === 'cadet') return <HomeCadet />
  if (env === 'mentor') return <HomeMentor />
  return <NotFoundPage />
}
