import { usePortraitWidthTrigger } from 'app/OrientationPrompt'
import { useUserState } from 'app/UserState'
import { useGenericUser } from 'app/useGenericUser'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { LinkButton } from 'common/LinkButton'
import { Logo } from 'common/Logo'
import {
  AppBackground,
  Button,
  Column,
  Container,
  H1,
  H2,
  H3,
  Hr,
  P,
  Padding,
  Row,
  Spacer,
  TextareaInput,
} from 'common/ui'
import { Footer } from 'dashboards/common/Footer'
import { Header } from 'dashboards/common/LayoutUI'
import { ResourceLoadingStatusPanel } from 'dashboards/common/ResourceLoadingStatusPanel'
import { checkTrainingAccess, courseTypeMap } from 'dashboards/facilitator/TrainingFacilitator'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { fontBold } from 'fonts'
import uniqBy from 'lodash/uniqBy'
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  ProgressionEventsStateProvider,
  useProgressionEventExists,
  useProgressionEventsState,
} from 'session/ProgressionEventsState'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'
import { Media } from 'session/common/Media'
import { UNLOCK_DASHBOARD_NAV_ITEM } from 'shared/progressionEvents'
import { INIT_STATE, UPDATE_CURRENT_STEP, UPDATE_NOTES } from 'shared/training/actionTypes'
import { TrainingCourse, TrainingUnit as TrainingUnitType, TrainingUnitValues } from 'shared/training/types'
import styled, { ThemeProvider } from 'styled-components'
import { trainingPurple } from 'themes'
import { postJson } from 'utils/apiUtils'
import { baseUrl } from '../dashboards/facilitator/FacilitatorDashboard'
import { TrainingStateProvider, getInitialState, useTrainingState } from './TrainingState'
import { TrainingStep } from './TrainingStep'

interface UrlParams {
  type: string
  unitUid: string
}

export const TrainingUnit: React.FC = () => {
  const { accessToken } = useUserState()
  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)
  const [creatingUnit, setCreatingUnit] = useState<boolean>(true)
  const wasLoading = useRef<boolean>(true)
  const { unitUid, type } = useParams<UrlParams>()
  const [trainingUnit, trainingEndpoint] = useEndpoint<TrainingUnitType | null>(`/api/v1/training_units/${unitUid}`)
  const [trainingUnitData, trainingDataEndpoint] = useEndpoint<TrainingUnitValues | null>(
    `/api/v1/training_unit_values/${unitUid}`
  )
  const [course, courseEndpoint] = useEndpoint<TrainingCourse | null>(`/api/v1/training_courses/${type}`, null)
  const loading = !trainingEndpoint.loaded && !trainingDataEndpoint.loaded && !courseEndpoint.loaded
  const error = trainingDataEndpoint.errorMessage || trainingEndpoint.errorMessage || courseEndpoint.errorMessage

  usePortraitWidthTrigger(720)

  if (!loading && wasLoading.current) {
    wasLoading.current = false

    if (!trainingUnitData || (trainingUnitData && !trainingUnitData.started)) {
      postJson(`/api/v1/training_unit_values/${unitUid}/started`, {}, getAuthRequestParams(accessToken))
        .then(res => {
          setCreatingUnit(false)
          // prettier-ignore
          setTimeout(() => { setFinishedDelay(true) }, 1000)
        })
        .catch(e => {})
    } else {
      setCreatingUnit(false)
      // prettier-ignore
      setTimeout(() => { setFinishedDelay(true) }, 1000)
    }
  }

  return (
    <TrainingStateProvider>
      <ProgressionEventsStateProvider>
        <ThemeProvider theme={trainingPurple}>
          <AppBackground>
            {error ? (
              <Column flex="auto" alignItems="center" justifyContent="center">
                <Container size="s">
                  <PlainPanel justifyContent="center">
                    <Column alignItems="center">
                      <P>An Error has occurred or you do not have access to this unit</P>
                      <LinkButton size="s" to={`${baseUrl}/training/${type}`} children="Exit" />
                    </Column>
                  </PlainPanel>
                </Container>
              </Column>
            ) : trainingUnit && course && finishedDelay && !creatingUnit ? (
              <Column style={{ width: '100%' }}>
                <TrainingUnitInner course={course} trainingUnit={trainingUnit} trainingUnitData={trainingUnitData} />
              </Column>
            ) : (
              <Column flex="auto" alignItems="center" justifyContent="center">
                <ResourceLoadingStatusPanel
                  title="Loading training data..."
                  resources={[
                    { label: 'Course Details', endpoint: courseEndpoint },
                    { label: 'Unit Content', endpoint: trainingEndpoint },
                    { label: 'Unit Data', endpoint: trainingDataEndpoint },
                  ]}
                />
              </Column>
            )}
          </AppBackground>
        </ThemeProvider>
      </ProgressionEventsStateProvider>
    </TrainingStateProvider>
  )
}

const TrainingUnitInner: React.FC<{
  course: TrainingCourse
  trainingUnit: TrainingUnitType
  trainingUnitData: TrainingUnitValues | null
}> = ({ course, trainingUnit, trainingUnitData }) => {
  const { addProgressionEvent } = useProgressionEventsState()
  const {
    state,
    state: { currentStepUid },
    dispatch,
  } = useTrainingState()
  const { accessToken, drupalProfile } = useUserState()
  const user = useGenericUser()
  const history = useHistory()
  const steps = trainingUnit.training_steps || []
  const progressDots = useRef(steps.map(() => createRef<HTMLDivElement>()))
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const [canProceed, setCanProceed] = useState<boolean>(false)
  const finalStep = currentStepIndex + 1 === steps.length
  const [forceRender, setForceRender] = useState<number>(0)
  const reviewMode = !!trainingUnitData?.completed || false
  const unitSections = uniqBy(
    steps.filter(item => !!item.section),
    'section'
  )
  const fullAccess = drupalProfile ? checkTrainingAccess(drupalProfile, courseTypeMap[course.type], course) : false
  const [showIndex, setShowIndex] = useState<boolean>(
    !!((reviewMode || fullAccess || course.show_contents_on_start) && unitSections.length > 0)
  )
  const [showEndScreen, setShowEndScreen] = useState<boolean>(false)

  const hasFacman = useProgressionEventExists({
    type: UNLOCK_DASHBOARD_NAV_ITEM,
    category: 'training',
    subcategory: 'facman',
    event_key: null,
    event_value: null,
  })

  const hasDigiHQ = useProgressionEventExists({
    type: UNLOCK_DASHBOARD_NAV_ITEM,
    category: 'training',
    subcategory: 'digiHq',
    event_key: null,
    event_value: null,
  })

  const incrementStep = () => {
    window.scrollTo(0, 0)
    dispatch({
      type: UPDATE_CURRENT_STEP,
      stepUid: steps[currentStepIndex + 1].uid,
    })
  }

  const decrementStep = () => {
    window.scrollTo(0, 0)
    dispatch({
      type: UPDATE_CURRENT_STEP,
      stepUid: steps[currentStepIndex - 1].uid,
    })
  }

  const jumpToStep = (uid: string) => {
    window.scrollTo(0, 0)
    dispatch({
      type: UPDATE_CURRENT_STEP,
      stepUid: uid,
    })
    setShowIndex(false)
  }

  const getStepIndex = () => {
    return steps.reduce((acc, step, i) => {
      if (step.uid === state.currentStepUid) {
        acc = i
      }
      return acc
    }, 0)
  }

  const updateNotes = useCallback(
    (val: string, stepUid: string) => {
      dispatch({
        type: UPDATE_NOTES,
        stepUid: stepUid,
        notes: val,
      })
    },
    [dispatch]
  )

  const endUnit = () => {
    postJson(`/api/v1/training_unit_values/${trainingUnit.uid}/complete`, {}, getAuthRequestParams(accessToken))
      .then(() => {
        history.push(`${baseUrl}/training/${course.type}`)
      })
      .catch(e => {})
  }

  useEffect(() => {
    setCurrentStepIndex(getStepIndex())
    if (!hasFacman && steps[getStepIndex()].unlock_facman) {
      addProgressionEvent({
        participant_uid: user.uid,
        type: UNLOCK_DASHBOARD_NAV_ITEM,
        category: 'training',
        subcategory: 'facman',
        event_key: null,
        event_value: null,
      })
    }
    if (!hasDigiHQ && steps[getStepIndex()].unlock_digi_hq) {
      addProgressionEvent({
        participant_uid: user.uid,
        type: UNLOCK_DASHBOARD_NAV_ITEM,
        category: 'training',
        subcategory: 'digiHq',
        event_key: null,
        event_value: null,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepUid])

  useEffect(() => {
    if (state && !!state.steps.length) {
      const step = state.steps[getStepIndex()]
      const validated = step
        ? state.steps[getStepIndex()].panels.reduce((acc, panel) => {
            if (panel.requiresValidation && !panel.correct) {
              acc = false
            }
            return acc
          }, true)
        : true
      setCanProceed(validated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    setForceRender(1)
    if (!state.steps.length) {
      dispatch({
        type: INIT_STATE,
        state:
          trainingUnitData && trainingUnitData.state
            ? getInitialState({ unit: trainingUnit }, trainingUnitData.state)
            : getInitialState({ unit: trainingUnit }),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!steps.length)
    return (
      <Column flex="auto" alignItems="center" justifyContent="center">
        <Container size="xs">
          <PlainPanel justifyContent="center">
            <Column alignItems="center">
              <P>This Unit has no steps!!</P>
              <LinkButton size="s" to={`${baseUrl}/training/${course.type}`} children="Exit" />
            </Column>
          </PlainPanel>
        </Container>
      </Column>
    )

  return (
    <>
      <Header style={{ backgroundColor: '#EDF2FA' }}>
        <TrainingBody>
          <Row style={{ height: '100%' }}>
            <HeaderLeft justifyContent="space-between" alignItems="center">
              <Logo fill="#7D41DF" />
              <Row>
                {(reviewMode || fullAccess) && (
                  <>
                    <Button size="s" children="Contents" onClick={() => setShowIndex(!showIndex)} pressed={showIndex} />
                    <Spacer size="s" />
                  </>
                )}
                <LinkButton
                  size="s"
                  to={`${baseUrl}/training/${course.type}`}
                  children={reviewMode ? 'Exit' : 'Save & Quit'}
                />
              </Row>
            </HeaderLeft>
          </Row>
        </TrainingBody>
      </Header>
      <TrainingBody key={forceRender}>
        <Spacer />
        {showIndex ? (
          <IndexPanel>
            <Column>
              <Padding style={{ textAlign: 'center' }}>
                <H3>{trainingUnit.title}</H3>
                <Spacer size="s" />
                <H2>Contents</H2>
              </Padding>
            </Column>
            <Hr />
            <Column>
              {unitSections.map((section, i) => (
                <Row key={i} justifyContent="space-between" alignItems="center" onClick={() => jumpToStep(section.uid)}>
                  <P>{section.section}</P>
                  {'>'}
                </Row>
              ))}
              <Spacer size="s" />
            </Column>
          </IndexPanel>
        ) : (
          <>
            <H1 style={{ color: '#fff' }}>{course.title}</H1>
            <P color="#fff" style={{ fontSize: '1.2rem', margin: '10px 0' }}>
              Unit {trainingUnit.order + 1} of {course.training_units.length} - {trainingUnit.title} (
              {steps[currentStepIndex].order + 1} of {steps.length})
            </P>
            <ProgressBar
              justifyContent="space-between"
              width={progressDots.current[currentStepIndex].current?.offsetLeft || 0}
              currentIndex={currentStepIndex}>
              {steps.map((step, i) => (
                <ProgressDot ref={progressDots.current[i]} key={i} visited={showEndScreen || currentStepIndex > i} />
              ))}
            </ProgressBar>
            <Spacer />
            <hr />
            <Spacer />
            {!showEndScreen && <TrainingStep step={steps[currentStepIndex]} reviewMode={reviewMode} />}
            {steps[currentStepIndex].allow_notes && !showEndScreen && course.is_drupal && (
              <NotesComponent
                update={updateNotes}
                stepIndex={currentStepIndex}
                stepUid={steps[currentStepIndex].uid}
                value={state.steps.find(step => step.uid === state.currentStepUid)?.notes || ''}
                disabled={reviewMode}
              />
            )}
            {!showEndScreen && (
              <PlainPanel justifyContent="center">
                <Button children="Previous" disabled={currentStepIndex === 0} onClick={decrementStep} />
                <Spacer />
                <Button
                  children="Next"
                  disabled={(!fullAccess && !reviewMode && !canProceed) || (reviewMode && finalStep)}
                  onClick={() => (finalStep ? setShowEndScreen(true) : incrementStep())}
                />
              </PlainPanel>
            )}
            {showEndScreen && (
              <PlainPanel style={{ flexDirection: 'column' }} alignItems="center">
                {course.end_screen_image ? (
                  <Media image={course.end_screen_image} type="image" widthPreset="954w" ratio="auto" />
                ) : (
                  <img src={require('./assets/unitEnd.png')} alt="" />
                )}
                <Spacer />
                <Button
                  style={{ width: 'auto' }}
                  children="Finish & Exit"
                  onClick={() => (!fullAccess ? endUnit() : history.push(`${baseUrl}/training/${course.type}`))}
                />
              </PlainPanel>
            )}
          </>
        )}
        <Spacer />
        <Footer />
      </TrainingBody>
    </>
  )
}

const NotesComponent: React.FC<{
  stepIndex: number
  stepUid: string
  update: (val: string, uid: string) => void
  value?: string
  disabled?: boolean
}> = ({ stepIndex, stepUid, value, update, disabled }) => {
  const currentStepUid = useRef<string>(stepUid)
  const [pendingSaveValue, setPendingSaveValue] = useState<string | null>(null)

  // On unmount, save the pending value
  useEffect(() => {
    const uid = currentStepUid.current
    if (pendingSaveValue !== null) update(pendingSaveValue, uid)
    setPendingSaveValue(null)
    currentStepUid.current = stepUid
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepUid])

  return (
    <>
      <NotesPanel>
        <P color="#6737B5">My Notes</P>
        <Hr />

        <DebouncedInputComponent<string>
          key={stepIndex}
          value={value}
          debounce={2000}
          maxWait={10000}
          onChange={value => {
            update(value, stepUid)
            setPendingSaveValue(null)
          }}
          children={({ value, onChange }) => (
            <AutosizeTextareaInput
              value={value}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                onChange(e.target.value || '')
                setPendingSaveValue(e.target.value || '')
              }}
              placeholder={'Enter Text Here'}
              disabled={disabled}
              rows={6}
            />
          )}
        />
      </NotesPanel>
      <Spacer />
    </>
  )
}

const HeaderLeft = styled(Row)`
  width: 100%;
  box-sizing: border-box;
  height: 100%;

  ${Button} {
    cursor: pointer;
  }
`

const TrainingBody = styled.div`
  box-sizing: border-box;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  hr {
    border: 1px solid #6737b5;
  }

  ${Button} {
    cursor: pointer;
  }

  ${LinkButton} {
    color: #fff !important;
  }

  a {
    color: #001947 !important;
  }
`

const PlainPanel = styled(Row)`
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 20px;

  img {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #abb4db;
  }

  ${Button} {
    width: 145px;
  }
`

const NotesPanel = styled(PlainPanel)`
  flex-direction: column;
  background: #d4c6ec;

  ${P} {
    ${fontBold};
    font-size: 22px;
    line-height: 1;
    margin: 0;
  }

  ${Hr} {
    border-bottom-color: #6737b5;
    opacity: 0.8;
    margin: 20px 0;
  }

  ${TextareaInput} {
    border: 1px solid #abb4db;
    background: #fff;
  }
`

const IndexPanel = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  ${Row} {
    transition: 0.3s;
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }

    ${P} {
      margin: 0;
    }
  }

  ${H2}, ${H3} {
    color: #7d41df;
    font-size: 1.2rem;
  }

  ${H3} {
    text-transform: initial;
  }
`

const ProgressDot = styled.div<{ visited: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${p =>
    p.visited
      ? 'linear-gradient(180deg, #8D57E6 0%, #6C3BBC 100%)'
      : 'linear-gradient(180deg, #e4edf8 0%, #bdc3e0 100%)'};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
`

const ProgressBar = styled(PlainPanel)<{ width: number; currentIndex: number }>`
  position: relative;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 0px -4px 4px rgba(255, 255, 255, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #381b68;
    width: ${p => (p.currentIndex === 0 ? '30px' : `${p.width + 25}px`)};
    border-radius: 20px;
    transition: 0.3s;
    z-index: 1;
  }
`
