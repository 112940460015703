import React, { useState } from 'react'

import { Skill } from 'skill-tracker/types'
import { modules } from './constants/modules'

import { Column, Padding, Spacer, Button, P } from 'common/ui'
import { Navigation } from './common/Navigation'
import { SkillSlab } from './common/SkillSlab'
import { SkillEditModal } from './modals/SkillEditModal'
import { SkillTrackerPanel } from './common/SkillTrackerPanel'

import { AddUpdateSkillAction, ADD_UPDATE_SKILL } from './actionTypes'
import { validateAddUpdateSkill } from './actionValidators'

import { useUserState } from 'app/UserState'
import { useSkillTrackerState } from './SkillTrackerState'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { getModuleSkills, getConfirmedModuleSkills } from './reducers'

export const SkillsScreen: React.FC<{}> = () => {
  const { state, dispatch } = useSkillTrackerState()
  const { getUserScopes, getBaseAction } = useUserState()
  const [editSkill, setEditSkill] = useState<Skill | null>(null)

  const module = modules.find(({ id }) => id === state.activeModuleId)
  const isAdult = getUserScopes().indexOf('mentor') >= 0
  const skills = isAdult
    ? getConfirmedModuleSkills(state.activeModuleId, state)
    : getModuleSkills(state.activeModuleId, state)

  const handleNewSkill = () => {
    if (!state.activeModuleId) return
    const skill: Skill = {
      id: `skill${state.skills.length}`,
      text: '',
      symbol: '',
      dailyTarget: 3,
      moduleId: state.activeModuleId,
    }
    setEditSkill(skill)
  }

  const handleSkillSave = (skill: Skill) => {
    const action: AddUpdateSkillAction = { ...getBaseAction(), type: ADD_UPDATE_SKILL, skill }
    if (validateAddUpdateSkill(action, state)) dispatch(action)
    else console.error(`Unable to validate update skill action`, action, state)
    setEditSkill(null)
  }

  return (
    <>
      <SkillTrackerPanel>
        <Navigation
          title={`${isAdult ? 'Edit Target Skills' : 'My Target Skills'}${module ? ` for Module ${module.id}` : ''}`}
        />
        <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
          <Padding size="s">
            {!skills.length && !isAdult && (
              <P>
                You currently don't have any target skills added, talk to your parent / supervisor to set these up
                together.
              </P>
            )}
            {!skills.length && isAdult && (
              <P>There are currently no target skills set up, do this with your student / child.</P>
            )}
            {intersperseSpacers(
              skills.map((skill, i) => (
                <SkillSlab key={i} skill={skill} label={`Target skill ${i + 1}:`} onEdit={() => setEditSkill(skill)} />
              )),
              's'
            )}
            {isAdult && (
              <div>
                <Spacer size="m" />
                <Button type="button" size="xs" onClick={() => handleNewSkill()}>
                  Add Skill
                </Button>
              </div>
            )}
          </Padding>
        </Column>
      </SkillTrackerPanel>
      {editSkill && (
        <SkillEditModal initialValue={editSkill} onClose={() => setEditSkill(null)} onSave={handleSkillSave} />
      )}
    </>
  )
}
