import _find from 'lodash/find'
import { BaseAction } from 'shared/types'
import { SkillUse } from './types'
import { SKILL_MIN_TARGET, SKILL_MAX_TARGET } from './constants/constants'
import { State } from './SkillTrackerState'
import {
  UPDATE_STATE,
  UpdateWorkflowAction,
  UPDATE_WORKFLOW,
  UpdateActiveModuleAction,
  UPDATE_ACTIVE_MODULE,
  AddUpdateModuleAction,
  ADD_UPDATE_MODULE,
  AddUpdateSkillAction,
  ADD_UPDATE_SKILL,
  AddUpdateRewardAction,
  ADD_UPDATE_REWARD,
  AddUpdateSkillUseAction,
  ADD_UPDATE_SKILL_USE,
  RemoveSkillUseAction,
  REMOVE_SKILL_USE,
  SetModuleCompletedAction,
  SET_MODULE_COMPLETED,
  RemoveSkillAction,
  REMOVE_SKILL,
  UPDATE_SKILLS,
  UpdateSkillsAction,
  UPDATE_ROLE_STATE_VALUES,
  REMOVE_REWARD,
  RemoveRewardAction,
} from './actionTypes'

type AnyObj = { [key: string]: any }

/**
 * Validation utils
 */

export const anyValuesDifferent = <ObjType extends AnyObj>(
  obj1: ObjType,
  obj2: ObjType,
  keys: (keyof ObjType)[]
): boolean => {
  for (let key of keys) if (obj1[key] !== obj2[key]) return true
  return false
}

export const anyValuesModified = <ObjType extends AnyObj>(
  newObj: ObjType,
  oldObj: ObjType | undefined,
  keys: (keyof ObjType)[]
): boolean => {
  if (!oldObj) return false
  for (let key of keys) if (oldObj[key] !== undefined && newObj[key] !== oldObj[key]) return true
  return false
}

export const allValuesExist = <ObjType extends AnyObj>(obj: ObjType, keys: (keyof ObjType)[]): boolean => {
  for (let key of keys) if (obj[key] === undefined) return false
  return true
}

export const allValuesNotEmpty = <ObjType extends AnyObj>(obj: ObjType, keys: (keyof ObjType)[]): boolean => {
  for (let key of keys) if (!obj[key]) return false
  return true
}

export const anyValuesExist = <ObjType extends AnyObj>(obj: ObjType, keys: (keyof ObjType)[]): boolean => {
  for (let key of keys) if (obj[key] !== undefined) return true
  return false
}

/**
 * Action validation functions
 */

export const noValidate = (action: BaseAction, state: State) => true

export const validateMentorOnly = (action: BaseAction, state: State) => {
  if (action.role !== 'mentor') {
    console.log(`agent: only mentors can do this`)
    return false
  }
  return true
}

export const validateAgentOnly = (action: BaseAction, state: State) => {
  if (action.role !== 'agent') {
    console.log(`mentor: only agents can do this`)
    return false
  }
  return true
}

export function validateUpdateWorkflow(action: UpdateWorkflowAction, state: State): boolean {
  if (action.role !== 'mentor') return false
  return true
}

export function validateUpdateActiveModule(action: UpdateActiveModuleAction, state: State): boolean {
  if (!validateMentorOnly(action, state)) return false

  const nextModule = _find(state.modules, { id: action.id })
  if (nextModule && nextModule.completed) {
    console.log(`mentor: cannot re-open completed modules`)
    return false
  }

  return true
}

export const validateAddUpdateModule = (action: AddUpdateModuleAction, state: State): boolean => {
  if (!validateMentorOnly(action, state)) return false
  if (action.module.type === 'custom') {
    if (!action.module.title) {
      console.log(`mentor: custom modules must have a title`)
      return false
    }
  }
  if (!action.module.startDate) {
    console.log(`mentor: modules must have a start date`)
    return false
  }
  return true
}

export const validateSetModuleCompleted = (action: SetModuleCompletedAction, state: State): boolean => {
  if (!validateMentorOnly(action, state)) return false
  const currentModule = _find(state.modules, { id: action.moduleId })
  if (currentModule && currentModule.completed === true) {
    console.log(`mentor: module is already marked as completed`)
    return false
  }
  return true
}

export const validateAddUpdateSkill = (action: AddUpdateSkillAction, state: State): boolean => {
  if (action.role === 'mentor') {
    if (!action.skill.text) {
      console.log('mentor: text must be specified')
      return false
    }
    if (!action.skill.symbol) {
      console.log('mentor: symbol must be specified')
      return false
    }
    if (!action.skill.dailyTarget) {
      console.log('mentor: dailyTarget must be specified')
      return false
    }
    if (action.skill.dailyTarget < SKILL_MIN_TARGET || action.skill.dailyTarget > SKILL_MAX_TARGET) {
      console.log(`mentor: dailyTarget is outside of range`)
      return false
    }
  } else {
    const currentSkill = _find(state.skills, { id: action.skill.id })
    if (!currentSkill) {
      console.log(`agent: cannot find original skill to update symbol`)
      return false
    }
    if (anyValuesDifferent(currentSkill, action.skill, ['id', 'text', 'dailyTarget', 'moduleId', 'moduleId'])) {
      console.log(`agent: not allowed to update any skill field other than 'symbol'`)
      return false
    }
  }
  return true
}

export const validateUpdateSkills = (action: UpdateSkillsAction, state: State): boolean => {
  if (!validateMentorOnly(action, state)) return false
  if (!action.skillIds.length) {
    console.log('mentor: no skill ids specified to update')
    return false
  }
  if (!Object.keys(action.data).length) {
    console.log('mentor: no data provided to update skills with')
    return false
  }
  return true
}

export const validateRemoveSkill = (action: RemoveSkillAction, state: State): boolean => {
  if (!validateMentorOnly(action, state)) return false
  const skillUseSkillIds = state.skillUses.map(({ skillId }) => skillId)
  if (skillUseSkillIds.indexOf(action.skill.id) >= 0) {
    console.log(`mentor: cannot remove skill because it's already in use`)
    return false
  }
  const existing = _find(state.skills, { id: action.skill.id })
  if (existing && existing.removed) {
    console.log(`mentor: cannot remove skill because already marked as removed`)
    return false
  }
  return true
}

export function validateAddUpdateReward(action: AddUpdateRewardAction, state: State): boolean {
  if (!validateMentorOnly(action, state)) return false
  if (!allValuesNotEmpty(action.reward, ['text', 'symbol', 'frequency'])) {
    console.log(`mentor: text, symbol or frequency missing`)
    return false
  }
  return true
}

export function validateRemoveReward(action: RemoveRewardAction, state: State): boolean {
  return validateMentorOnly(action, state)
}

export function validateAddUpdateSkillUse(action: AddUpdateSkillUseAction, state: State): boolean {
  const currentSkillUse = _find(state.skillUses, { id: action.skillUse.id })

  const newSkillUse: SkillUse = { ...(currentSkillUse || {}), ...action.skillUse }

  if (currentSkillUse?.approved) {
    // ensure content updates aren't allowed if skill use has already been approved/declined by mentor
    // if (anyValuesModified(newSkillUse, currentSkillUse, ['text', 'scene'])) {
    //   console.log(`mentor/agent: text or scene cannot be changed once approval status is set`)
    //   return false
    // }
  }

  if (action.role === 'mentor') {
    // ensure required values exist
    if (!allValuesExist(action.skillUse, ['location'])) {
      console.log(`mentor: location missing`)
      return false
    }
    // ensure adult cannot change approval status afterwards
    if (anyValuesModified(newSkillUse, currentSkillUse, ['approved'])) {
      console.log(`mentor: approval state cannot be changed after the fact`)
      return false
    }
  } else {
    if (!action.skillUse.location) {
      console.log(`agent: no location specified`)
      return false
    }
  }

  return true
}

export function validateRemoveSkillUse(action: RemoveSkillUseAction, state: State): boolean {
  if (!validateMentorOnly(action, state)) return false
  const currentSkillUse = _find(state.skillUses, { id: action.skillUse.id })
  if (!currentSkillUse) {
    console.log(`mentor: no existing skill use in state so nothing to remove`)
    return false
  }
  return true
}

/**
 * Action type validation function map
 */

export const actionTypeValidators = {
  [UPDATE_STATE]: noValidate,
  [UPDATE_WORKFLOW]: validateUpdateWorkflow,
  [UPDATE_ACTIVE_MODULE]: validateUpdateActiveModule,
  [ADD_UPDATE_MODULE]: validateAddUpdateModule,
  [SET_MODULE_COMPLETED]: validateSetModuleCompleted,
  [ADD_UPDATE_SKILL]: validateAddUpdateSkill,
  [UPDATE_SKILLS]: validateUpdateSkills,
  [REMOVE_SKILL]: validateRemoveSkill,
  [ADD_UPDATE_REWARD]: validateAddUpdateReward,
  [REMOVE_REWARD]: validateRemoveReward,
  [ADD_UPDATE_SKILL_USE]: validateAddUpdateSkillUse,
  [REMOVE_SKILL_USE]: validateRemoveSkillUse,
  [UPDATE_ROLE_STATE_VALUES]: noValidate,
}
