import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { useGlobalState } from './GlobalState'
import {
  AppBackground,
  Panel,
  Absolute,
  Center,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  GRILL_TL,
  GRILL_TR,
  TAB_B_S,
  PanelProps,
} from 'common/ui'
import { GameModeIndicator } from './GameModeIndicator'
import styled, { keyframes } from 'styled-components'
import { ScreenScaler } from 'common/ScreenScaler'
import { useDisableOrientationLock } from './OrientationPrompt'
import { useMedia } from 'utils/useMedia'

export const AutoLoadingScreen = () => {
  const [loadingVisible, setLoadingVisible] = useState(true)
  const { loadingPercent, setLoadingPercent } = useGlobalState()
  useEffect(() => {
    if (loadingPercent === 1) {
      setTimeout(() => {
        setLoadingVisible(false)
        setLoadingPercent(0)
      }, 500)
    } else if (!loadingVisible && loadingPercent !== 0) {
      setLoadingVisible(true)
    }
  }, [loadingVisible, loadingPercent, setLoadingPercent])
  if (loadingPercent === 0 || (loadingPercent === 1 && !loadingVisible)) {
    return null
  }
  return (
    <Absolute cover zIndex={1000} style={{ position: 'fixed' }}>
      <LoadingScreen percent={loadingPercent} />
    </Absolute>
  )
}

export const LoadingScreen: React.FC<{ percent?: number }> = ({ percent }) => {
  useDisableOrientationLock()
  return (
    <ScreenScaler>
      <AppBackground>
        <Center flex>
          <LoadingPanel percent={percent} />
        </Center>
        <GameModeIndicator />
      </AppBackground>
    </ScreenScaler>
  )
}

export const LoadingPanel: React.FC<{ percent?: number; text1?: string; text2?: string } & PanelProps> = ({
  percent,
  text1 = 'LOADING',
  text2 = 'SIMULATION',
  children,
  ...panelProps
}) => {
  const indeterminate = percent === undefined
  const spring = useSpring<{ percent: number }>({
    percent: percent === undefined ? 1 : percent,
    from: { percent: 0 },
    config: { tension: 170, friction: 75 },
  })
  const compact = useMedia('(max-width: 500px)')
  return (
    <Panel
      {...panelProps}
      userSelectNone
      padding={compact ? true : ['50px', '100px']}
      flair={compact ? [] : [CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR, TAB_B_S]}>
      <svg viewBox="-150 -150 300 300" strokeLinecap="square" width="300" height="300">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#edf2fa" stopOpacity="1" />
            <stop offset="100%" stopColor="#dce3f2" stopOpacity="1" />
          </linearGradient>
        </defs>
        <circle cy="1" r="135" strokeWidth="1" stroke="#fff" fill="none"></circle>
        <circle r="135" strokeWidth="1" stroke="#c5cbe7" fill="none"></circle>
        <circle r="105" fill="url(#grad1)"></circle>
        {indeterminate ? (
          <IndeterminateCircle r="120" strokeWidth="20" stroke="#39e055" strokeLinecap="butt" fill="none" />
        ) : (
          <animated.circle
            r="120"
            style={{ transform: 'rotate(-90deg)' }}
            strokeWidth="20"
            stroke="#39e055"
            strokeDasharray="756"
            strokeDashoffset={spring.percent.interpolate(x => (1 - x) * 756)}
            strokeLinecap="butt"
            fill="none"></animated.circle>
        )}
        <text
          x="0"
          y="-10"
          fontFamily="eurostile, sans-serif"
          fontWeight="800"
          letterSpacing="1.2"
          textAnchor="middle"
          fontSize="16px"
          fill="#1a345d">
          {text1}
        </text>
        <text
          x="0"
          y="10"
          fontFamily="eurostile, sans-serif"
          fontWeight="800"
          letterSpacing="1.2"
          textAnchor="middle"
          fontSize="16px"
          fill="#1a345d">
          {text2}
        </text>
      </svg>
      {children}
    </Panel>
  )
}

const flash = keyframes`
  0% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
`

const IndeterminateCircle = styled.circle`
  opacity: 1;
  animation: ${flash} 1s ease-in-out alternate infinite;
  animation-delay: 1s;
`
