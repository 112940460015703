import React, { CSSProperties, useState, useEffect, useRef } from 'react'
import { Row, Button } from 'common/ui'
import styled, { keyframes, css } from 'styled-components'
import { DiceValue } from 'shared/session/types'
import { fontBold } from 'fonts'

const roll = () => (Math.floor(Math.random() * 6) + 1) as DiceValue

const rollSpeed = 2500 // ms

export const BoardDice: React.FC<{
  highlighted?: boolean
  onRoll?: () => void
  dice: {
    value: DiceValue
    increment: number
  }
  isInteractive: boolean
  superDice: boolean
}> = ({ highlighted, onRoll, dice, isInteractive, superDice }) => {
  const isMounted = useRef<boolean>(false)
  const [tempValue, setTempValue] = useState<DiceValue | null>(null)
  const [rolling, setRolling] = useState<boolean>(false)

  const triggerRoll = () => {
    if (!rolling) {
      if (onRoll) onRoll()
      setTempValue(roll())
    }
  }

  useEffect(() => {
    isMounted.current ? setRolling(true) : (isMounted.current = true)
  }, [dice.increment])

  useEffect(() => {
    let intervalRef = 0
    let preDoneTimeRef = 0
    let doneTimeRef = 0
    if (rolling) {
      intervalRef = setInterval(() => setTempValue(roll()), 100)
      preDoneTimeRef = setTimeout(() => {
        clearInterval(intervalRef)
        setTempValue(null)
      }, rollSpeed - rollSpeed * 0.6)
      doneTimeRef = setTimeout(() => {
        setRolling(false)
      }, rollSpeed)
    }
    return () => {
      clearTimeout(preDoneTimeRef)
      clearTimeout(doneTimeRef)
      clearInterval(intervalRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolling])
  return (
    <>
      <Row justifyContent="center" alignItems="center">
        <DiceDisplay>
          {superDice && <SuperDice children="x2" />}
          <Dice value={tempValue || dice.value} rolling={rolling} superDice={superDice} />
        </DiceDisplay>
      </Row>
      <Row justifyContent="center" alignItems="center">
        <Button
          size="s"
          onClick={triggerRoll}
          children="Roll Dice"
          flashing={highlighted && !rolling}
          disabled={!isInteractive}
        />
      </Row>
    </>
  )
}

export const Dice: React.FC<{ value: DiceValue; rolling?: boolean; style?: CSSProperties; superDice: boolean }> = ({
  value,
  rolling,
  style,
  superDice,
}) => {
  const superDiceValue = superDice ? value : value * 2
  return (
    <>
      {!superDice && (
        <DiceBody style={style} rolling={rolling}>
          {[2, 3, 4, 5, 6].indexOf(value) >= 0 && <Dot style={{ top: '25%', left: '25%' }} />}
          {[4, 5, 6].indexOf(value) >= 0 && <Dot style={{ top: '25%', left: '75%' }} />}
          {[4, 5, 6].indexOf(value) >= 0 && <Dot style={{ top: '75%', left: '25%' }} />}
          {[2, 3, 4, 5, 6].indexOf(value) >= 0 && <Dot style={{ top: '75%', left: '75%' }} />}
          {[1, 3, 5].indexOf(value) >= 0 && <Dot style={{ top: '50%', left: '50%' }} />}
          {value === 6 && <Dot style={{ top: '25%', left: '50%' }} />}
          {value === 6 && <Dot style={{ top: '75%', left: '50%' }} />}
        </DiceBody>
      )}
      {superDice && (
        <DiceBody style={style} rolling={rolling}>
          {[4, 6, 8, 10, 12].indexOf(superDiceValue) >= 0 && <Dot style={{ top: '25%', left: '25%' }} />}
          {[8, 10, 12].indexOf(superDiceValue) >= 0 && <Dot style={{ top: '25%', left: '75%' }} />}
          {[8, 10, 12].indexOf(superDiceValue) >= 0 && <Dot style={{ top: '75%', left: '25%' }} />}
          {[4, 6, 8, 10, 12].indexOf(superDiceValue) >= 0 && <Dot style={{ top: '75%', left: '75%' }} />}
          {[2, 6, 10].indexOf(superDiceValue) >= 0 && <Dot style={{ top: '50%', left: '50%' }} />}
          {superDiceValue === 12 && <Dot style={{ top: '25%', left: '50%' }} />}
          {superDiceValue === 12 && <Dot style={{ top: '75%', left: '50%' }} />}
        </DiceBody>
      )}
    </>
  )
}

const spinnySpin = keyframes`
  0% {
      transform: rotate(1080deg);
      filter: blur(4px);
  }
  100% {
    transform: rotate(0deg);
    filter: blur(0);
  }
`

const DiceBody = styled.div<{ rolling?: boolean }>`
  position: relative;
  background: linear-gradient(0deg, #fff 0%, #c2c8cc 100%);
  border-radius: 0.3em;
  width: 45%;
  height: 45%;
  box-shadow: inset 0 0 0 3px #bbb;
  ${p =>
    p.rolling
      ? css`
          animation: ${spinnySpin} ${rollSpeed}ms cubic-bezier(0.3, 0.6, 0.11, 1) 0s forwards 1;
        `
      : ''}
`

const Dot = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  border-radius: 100%;
  background: radial-gradient(circle, #55666b 0%, #2e383b 100%);
  background-position: 30% 70%;
  background-size: 120% 120%;
`

const DiceDisplay = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6em;
  height: 6em;
  max-width: 12vh;
  max-height: 12vh;
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 100%;
`

const SuperDice = styled.p`
  ${fontBold};
  color: yellow;
  position: absolute;
  top: -6px;
  right: -12px;
  margin: 0;
  font-size: 1rem;
`
