import React from 'react'
import styled from 'styled-components'
import { Gadget } from 'shared/e-telligence/types'
import { Spacer, OutlineButton } from 'common/ui'
import { fontBold } from 'fonts'

interface Props {
  gadget?: Gadget
  placeholder?: string
  active?: boolean
  onEdit?: () => void
  onRemove?: () => void
}

export const GadgetTile: React.FC<Props> = ({ gadget, placeholder, active, onEdit, onRemove }) => {
  return (
    <>
      <TileDiv empty={!gadget} preset={gadget?.isPreset} active={active} onClick={onEdit}>
        {!gadget ? (
          <figcaption>{placeholder || 'Add Relaxation Gadget +'}</figcaption>
        ) : gadget.isPreset ? (
          <>
            <CoverImage style={{ backgroundImage: `url('${require(`../assets/gadgets/blank_cover.png`)}')` }} />
            <GadgetImage style={{ backgroundImage: `url('${require(`../assets/gadgets/${gadget.id}.png`)}')` }} />
            <CustomLabel>{gadget.title}</CustomLabel>
          </>
        ) : (
          <>
            <CoverImage style={{ backgroundImage: `url('${require(`../assets/gadgets/blank_cover.png`)}')` }} />
            {gadget.symbol && <CustomSymbol children={gadget.symbol} />}
            <CustomLabel>{gadget.title}</CustomLabel>
          </>
        )}
      </TileDiv>
      {gadget && onRemove && (
        <>
          <Spacer size="xs" />
          <OutlineButton size="xs" onClick={onRemove}>
            Delete
          </OutlineButton>
        </>
      )}
    </>
  )
}

const TileDiv = styled.div<{ empty?: boolean; preset?: boolean; active?: boolean }>`
  position: relative;
  height: 150px;
  width: 96px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: 1px solid
    ${p =>
      p.active
        ? p.theme.buttonBorderTopColor
        : p.empty
        ? p.theme.thinOutlineColor
        : p.preset
        ? 'white'
        : p.theme.thinOutlineActiveColor};
  border-radius: 5px;
  ${p => (p.empty ? '' : `cursor: pointer;`)}
  background-color: white;
  ${p => (!p.active ? '' : `box-shadow: 0 0 10px 0 ${p.theme.buttonBorderTopColor};`)}

  text-align: center;
  font-size: 0.8rem;

  ${p =>
    p.empty
      ? `& figcaption {
    max-width: 90%;
    font-size: inherit;
    line-height: 1.5;
    color: ${p.theme.thinOutlineColor};
  }`
      : ''}
`
const CoverImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: cover;
  border-radius: 5px;
`

const GadgetImage = styled.div`
  position: relative;
  display: inline-block;
  width: 80%;
  padding-top: 80%;

  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: cover;
`

const CustomLabel = styled.figcaption`
  ${fontBold}
  position: absolute;
  bottom: 10px;
  left: 5px;
  right: 5px;
  font-size: 12px;
  line-height: 1 !important;
  color: #00aef0 !important;
`
const CustomSymbol = styled.span`
  position: relative;
  display: inline-block;
  font-size: 42px;
`
