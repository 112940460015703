import React, { useContext } from 'react'
import { useSessionState } from './SessionState'

// default the context to the root
const ModalContext = React.createContext<HTMLElement | null>(document.querySelector('#root'))

export const ModalContextProvider: React.FC = ({ children }) => {
  const { isFacilitator } = useSessionState()
  const DomElement: HTMLElement | null = isFacilitator
    ? document.querySelector('#ParticipantView')
    : document.querySelector('#root')
  return <ModalContext.Provider value={DomElement}>{children}</ModalContext.Provider>
}

export function useModalContext() {
  return useContext(ModalContext)
}
