// import { images } from './assets'
import {
  BoardGameTileDefinition as TileDefinition,
  BoardGameTileType as TileType,
  BoardGameTileColor as TileColor,
} from '../../../types'

export const tileColors: { [key in TileColor]: string[] } = {
  green: ['#74C768', '#A4D99B'],
  yellow: ['#FFEB4A', '#FFF19C'],
  blue: ['#007CC5'],
  black: ['#1A171B', '#51514F'],
  orange: ['#FF9535', '#FFC17C'],
  red: ['#FF4126', '#FF8669'],
  purple: ['#8658A1', '#B392A2'],
  pink: ['#FA5CAB', '#FC94C6'],
}

const tileTypes: { [key in TileType]: { type: TileType; color: TileColor /*; image?: any*/ } } = {
  BULLYING_AND_TEASING: { type: 'BULLYING_AND_TEASING', color: 'purple' },
  SAS_CHALLENGE: {
    type: 'SAS_CHALLENGE',
    color: 'black',
    // image: require('./assets/deck2_tile.jpg'), // TODO: replace in fronend code
  },
  CHANGE_AND_UNCERTAINTY: { type: 'CHANGE_AND_UNCERTAINTY', color: 'pink' },
  MEETING_NEW_PEOPLE: { type: 'MEETING_NEW_PEOPLE', color: 'green' },
  MISTAKES_AND_BLUNDERS: { type: 'MISTAKES_AND_BLUNDERS', color: 'red' },
  PLAYING_WITH_OTHERS: { type: 'PLAYING_WITH_OTHERS', color: 'orange' },
  TALKING_TO_OTHERS: { type: 'TALKING_TO_OTHERS', color: 'yellow' },
  ENCOUNTER: { type: 'ENCOUNTER', color: 'blue' },
  BLANK: { type: 'BLANK', color: 'blue' },
}

export const getTileRange = (
  side: TileDefinition['side'] | TileDefinition['side'][],
  until?: TileDefinition['side'],
  includeEndPoint?: boolean
) => {
  const sides = side instanceof Array ? side : [side]
  const tileRange: TileDefinition[] = []
  let sideIndex = 0
  let lastSide: TileDefinition['side'] | null = null
  for (let i = 0; i < tiles.length; i++) {
    const index = sides.indexOf(tiles[i].side)
    if (sides.length > 1 && sideIndex < sides.length && lastSide && tiles[i].side !== lastSide) {
      if (index === sideIndex + 1) sideIndex++
      else continue
    }
    if (index === sideIndex) {
      tileRange.push(tiles[i])
      lastSide = tiles[i].side
    }
    if (until && i < tiles.length - 1 && tiles[i + 1].side === until) {
      if (includeEndPoint) tileRange.push(tiles[i + 1])
      break
    }
  }
  return tileRange
}

export const tiles: TileDefinition[] = [
  { tileNumber: 1, index: 1, side: 'b', size: 1, ...tileTypes['MEETING_NEW_PEOPLE'] },
  { tileNumber: 2, index: 2, side: 'b', size: 1, ...tileTypes['TALKING_TO_OTHERS'] },
  {
    tileNumber: 3,
    index: 3,
    side: 'b',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile3_4,
    hasImage: true,
    encounterId: 'tile3_4',
  },
  { tileNumber: 4, index: 5, side: 'bl', size: 1, ...tileTypes['SAS_CHALLENGE'] },

  { tileNumber: 5, index: 6, side: 'l', size: 1, ...tileTypes['PLAYING_WITH_OTHERS'] },
  { tileNumber: 6, index: 7, side: 'l', size: 1, ...tileTypes['BLANK'] },
  { tileNumber: 7, index: 8, side: 'l', size: 1, ...tileTypes['MISTAKES_AND_BLUNDERS'] },
  { tileNumber: 8, index: 9, side: 'l', size: 1, ...tileTypes['BLANK'] },
  { tileNumber: 9, index: 10, side: 'l', size: 1, ...tileTypes['MEETING_NEW_PEOPLE'] },
  {
    tileNumber: 10,
    index: 11,
    side: 'l',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile11_12,
    hasImage: true,
    encounterId: 'tile11_12',
  },
  {
    tileNumber: 11,
    index: 13,
    side: 'l',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile13_14,
    hasImage: true,
    encounterId: 'tile13_14',
  },
  { tileNumber: 12, index: 15, side: 'l', size: 1, ...tileTypes['TALKING_TO_OTHERS'] },
  { tileNumber: 13, index: 16, side: 'l', size: 1, ...tileTypes['SAS_CHALLENGE'] },
  { tileNumber: 14, index: 17, side: 'tl', size: 1, ...tileTypes['BLANK'] },

  { tileNumber: 15, index: 18, side: 't', size: 1, ...tileTypes['PLAYING_WITH_OTHERS'] },
  {
    tileNumber: 16,
    index: 19,
    side: 't',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile19_20,
    hasImage: true,
    encounterId: 'tile19_20',
  },
  { tileNumber: 17, index: 21, side: 't', size: 1, ...tileTypes['MISTAKES_AND_BLUNDERS'] },
  { tileNumber: 18, index: 22, side: 't', size: 1, ...tileTypes['SAS_CHALLENGE'] },
  { tileNumber: 19, index: 23, side: 't', size: 1, ...tileTypes['BLANK'] },
  { tileNumber: 20, index: 24, side: 't', size: 1, ...tileTypes['MEETING_NEW_PEOPLE'] },
  { tileNumber: 21, index: 25, side: 't', size: 1, ...tileTypes['BLANK'] },
  { tileNumber: 22, index: 26, side: 't', size: 1, ...tileTypes['BULLYING_AND_TEASING'] },
  {
    tileNumber: 23,
    index: 27,
    side: 't',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile27_28,
    hasImage: true,
    encounterId: 'tile27_28',
  },
  { tileNumber: 24, index: 29, side: 't', size: 1, ...tileTypes['TALKING_TO_OTHERS'] },
  {
    tileNumber: 25,
    index: 30,
    side: 't',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile30_31,
    hasImage: true,
    encounterId: 'tile30_31',
  },
  { tileNumber: 26, index: 32, side: 't', size: 1, ...tileTypes['BULLYING_AND_TEASING'] },
  { tileNumber: 27, index: 33, side: 't', size: 1, ...tileTypes['SAS_CHALLENGE'] },
  { tileNumber: 28, index: 34, side: 't', size: 1, ...tileTypes['BLANK'] },
  { tileNumber: 29, index: 35, side: 'tr', size: 1, ...tileTypes['MISTAKES_AND_BLUNDERS'] },

  {
    tileNumber: 30,
    index: 36,
    side: 'r',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile36_37,
    hasImage: true,
    encounterId: 'tile36_37',
  },
  { tileNumber: 31, index: 38, side: 'r', size: 1, ...tileTypes['BULLYING_AND_TEASING'] },
  { tileNumber: 32, index: 39, side: 'r', size: 1, ...tileTypes['PLAYING_WITH_OTHERS'] },
  { tileNumber: 33, index: 40, side: 'r', size: 1, ...tileTypes['BLANK'] },
  {
    tileNumber: 34,
    index: 41,
    side: 'r',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile41_42,
    hasImage: true,
    encounterId: 'tile41_42',
  },
  { tileNumber: 35, index: 43, side: 'r', size: 1, ...tileTypes['SAS_CHALLENGE'] },
  { tileNumber: 36, index: 44, side: 'r', size: 1, ...tileTypes['CHANGE_AND_UNCERTAINTY'] },
  { tileNumber: 37, index: 45, side: 'r', size: 1, ...tileTypes['BLANK'] },
  { tileNumber: 38, index: 46, side: 'r', size: 1, ...tileTypes['CHANGE_AND_UNCERTAINTY'] },
  { tileNumber: 39, index: 47, side: 'br', size: 1, ...tileTypes['BLANK'] },

  { tileNumber: 40, index: 48, side: 'b', size: 1, ...tileTypes['SAS_CHALLENGE'] },
  {
    tileNumber: 41,
    index: 49,
    side: 'b',
    size: 2,
    ...tileTypes['ENCOUNTER'],
    // image: images.tile49_50,
    hasImage: true,
    encounterId: 'tile49_50',
  },
  { tileNumber: 42, index: 51, side: 'b', size: 1, ...tileTypes['CHANGE_AND_UNCERTAINTY'] },
]
