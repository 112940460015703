import Phaser, { Scene, GameObjects, Game } from 'phaser'
import SceneLoader, { SpriteObj, SoundObj, VideoObj } from 'phaser/SceneLoader'
import { getPixelRatio } from './screenUtils'

export const getGameConfig = () => ({
  type: Phaser.AUTO,
  // width: 1024,
  // height: 697,
  // resolution: 1, // getPixelRatio(),
  audio: {
    noAudio: true,
  },
  render: {
    antialias: true,
    antialiasGL: true,
    transparent: true, // this prevents display bugs in older android devices
    // clearBeforeRender: true,
    // premultipliedAlpha: true,
    // failIfMajorPerformanceCaveat: false,
    powerPreference: 'high-performance',
  },
  fps: { target: 60 },
  physics: {
    default: 'arcade',
    arcade: {
      // debug: true,
    },
  },
  input: {
    activePointers: 4,
  },
  scale: {
    // mode: Phaser.Scale.RESIZE
    // autoCenter: Phaser.Scale.NO_CENTER,
    mode: Phaser.Scale.NONE,
    width: 1024 * getPixelRatio(),
    height: 697 * getPixelRatio(),
    zoom: 1 / getPixelRatio(),
  },
})

export type LoadAssetsObject = {
  images?: { [key: string]: string }
  sounds?: { [key: string]: SoundObj }
  sprites?: { [key: string]: SpriteObj }
  videos?: { [key: string]: VideoObj }
}

export const generateGame = (
  elem: HTMLElement,
  scenes: typeof Scene[] | typeof Scene,
  firstSceneTrigger: string,
  assets?: LoadAssetsObject,
  config?: Phaser.Types.Core.GameConfig,
  setLoadingPercent?: (value: number) => void
) => {
  return new Phaser.Game({
    ...getGameConfig(),
    parent: elem,
    scene: [SceneLoader, ...(scenes instanceof Array ? scenes : [scenes])],
    ...(config ? config : {}),
    callbacks: {
      preBoot: () => {
        setLoadingPercent && setLoadingPercent(0.01)
      },
      postBoot: game => {
        if (config && config.callbacks && config.callbacks.postBoot) config.callbacks.postBoot(game)
        const loaderScene: SceneLoader = game.scene.scenes[0]
        loaderScene.sceneTrigger = firstSceneTrigger
        if (assets) {
          if (setLoadingPercent) loaderScene.setLoadingCallback(setLoadingPercent)
          if (assets.images) loaderScene.registerImages(assets.images)
          if (assets.videos) loaderScene.registerVideos(assets.videos)
          if (assets.sounds) loaderScene.registerSounds(assets.sounds)
          if (assets.sprites) loaderScene.registerSprites(assets.sprites)
          loaderScene.initLoad()

          // store assets object in all scenes (via SceneScaler -- weird place for but it's basically used globally)
          game.scene.scenes.forEach(scene => {
            if ('assets' in scene) scene.assets = assets
          })
        }
      },
    },
  })
}

export const dereferenceSceneGameObjects = <T extends Scene>(scene: T) => {
  for (let key in scene) {
    if (typeof scene[key] !== 'object') continue
    if (
      scene[key] instanceof GameObjects.GameObject ||
      // @ts-ignore
      (scene[key] instanceof Array && scene[key].length && scene[key][0] instanceof GameObjects.GameObject)
    ) {
      // @ts-ignore
      scene[key] = undefined
    }
  }
}

export const destroyGame = <T extends Game>(game: T) => {
  // flag game for destroy
  console.log('flagging game for destroy')
  game.destroy(true, false)

  /*
  // manually clear any animationframes or timeouts because we're also manually calling runDestroy last
  if (game.loop?.raf?.timeOutID) {
    console.log('game look raf has timeOutID')
    if (game.loop.raf.isSetTimeOut) {
      console.log('clearing timeout')
      clearTimeout(game.loop.raf.timeOutID)
      console.log('timeout cleared')
    } else {
      console.log('clearing animationframe')
      window.cancelAnimationFrame(game.loop.raf.timeOutID)
      console.log('animationframe cleared')
    }
  }
  */

  // Potentialy memory leak fix for Safari
  // https://stackoverflow.com/questions/52532614/total-canvas-memory-use-exceeds-the-maximum-limit-safari-12
  console.log('performing safari memory leak fix')
  game.canvas.setAttribute('width', '0')
  game.canvas.setAttribute('height', '0')

  /*
  // this is function that actually gets called to destroy a game
  // console.log('running final game destroy function')
  // @ts-ignore
  // game.runDestroy()
  console.log('game should now be destroyed')
  */
}
