import { ScavengerHuntCard as Card } from '../../../types'

export const cards: Card[] = [
  {
    id: 'card1',
    letter: 'd',
    label: `Detect the problem and define it.`,
  },
  {
    id: 'card2',
    letter: 'e',
    label: `Explore possible solutions to the problem.`,
  },
  {
    id: 'card3',
    letter: 'c',
    label: `Consider the consequences and choose a solution.`,
  },
  {
    id: 'card4',
    letter: 'o',
    label: `Organise a plan.`,
  },
  {
    id: 'card5',
    letter: 'd',
    label: `Do it!`,
  },
  {
    id: 'card6',
    letter: 'e',
    label: `Evaluate how it went.`,
  },
  {
    id: 'card7',
    letter: 'r',
    label: `Reward yourself for trying your best!`,
  },
]
