import React, { useEffect, useRef, useCallback, forwardRef, Ref, useState, ReactNode, Fragment } from 'react'
import styled, { css } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { fontBold } from 'fonts'

import { getSlides, getSlideByUid } from './reducers'
import { useSessionState } from './SessionState'
import { useGenericUser } from 'app/useGenericUser'

import {
  UPDATE_CURRENT_SLIDE,
  UPDATE_PREVIEW_SLIDE,
  ADD_SUPPLEMENTARY_SLIDE,
  UPDATE_SUPPLEMENTARY_SLIDE,
  REMOVE_SUPPLEMENTARY_SLIDE,
  SHARE_SUPPLEMENTARY_SLIDE,
} from 'shared/session/actionTypes'
import {
  ModuleStep,
  SupplementarySlide,
  MODULE_SLIDE,
  SUPPLEMENTARY_SLIDE,
  HOLDING_SLIDE,
  HydratedSupplementarySlideRef,
  SlideType,
  ParticipantState,
  END_SLIDE,
  HydratedModuleSlideRef,
} from 'shared/session/types'

import { Row, P, Button, Spacer } from 'common/ui'
import { CircleButton } from './common/CircleButton'
import { SVG } from 'common/SVG'
import {
  EditSupplementarySlideModal,
  getInitialSupplementarySlideData,
  defaultTitle,
  slideLabelMap,
} from './EditSupplementarySlideModal'
import { useSavedInputValues, useInputContext, useInputInterface, useSavedInputValueObjects } from './InputContext'

export const FacilitatorSlideNav: React.FC = () => {
  const slideContainerRef = useRef<HTMLDivElement | null>(null)
  const slideRefs = useRef<HTMLDivElement[]>([])
  const slideContainerScrollRef = useRef<number>(0)
  const registerSlideRef = useCallback(slide => slide && slideRefs.current.push(slide), [])
  const [editSupplementarySlide, setEditSupplementarySlide] = useState<SupplementarySlide | null>(null)

  const user = useGenericUser()
  const { sessionData, state, dispatch, previewing, getBaseAction, isAssistant, pastMode } = useSessionState()

  const supplementarySlides = useSavedInputValues<SupplementarySlide>({
    participant_uid: 'shared',
    owner: 'supplementary_slides',
    owner_id: null,
    name: undefined,
  })

  const skippedSlides = useSavedInputValueObjects<boolean>({
    participant_uid: 'shared',
    owner: 'skipped_slides',
    owner_id: null,
    name: undefined,
  })

  const viewedSlides = useSavedInputValueObjects<boolean>({
    participant_uid: 'shared',
    owner: 'viewed_slides',
    owner_id: null,
    name: undefined,
  })

  const facilitatorState = state.facilitators.find(({ profile }) => profile.uid === user.uid)
  const { currentSlideUid: _currentSlideUid, currentPreviewSlideUid } = facilitatorState || {
    currentSlideUid: 'PREVIEW_STEP',
    currentPreviewSlideUid: null,
  }

  const slides = getSlides(sessionData, state, supplementarySlides)
  const currentSlideUid = previewing ? 'PREVIEW_STEP' : _currentSlideUid
  const prevSlideUid = useRef<string>(currentSlideUid || '')
  const currentSlide =
    sessionData && currentSlideUid ? getSlideByUid(sessionData, state, supplementarySlides, currentSlideUid) : undefined
  const currentPreviewSlide =
    sessionData && currentPreviewSlideUid
      ? getSlideByUid(sessionData, state, supplementarySlides, currentPreviewSlideUid)
      : undefined

  const handleAddSupplementarySlide = (order: number) => {
    handleEditSupplementarySlide(getInitialSupplementarySlideData(order))
  }

  const handleEditSupplementarySlide = (slide: SupplementarySlide | null) => {
    const savedSlide = slide ? supplementarySlides.filter(({ uid }) => uid === slide.uid) : []
    setEditSupplementarySlide(savedSlide.length > 0 ? savedSlide[savedSlide.length - 1] : slide)
  }

  const handleSupplementarySlideSave = (slide: SupplementarySlide) => {
    const existingSlide = state.slides.find(test => test.type === SUPPLEMENTARY_SLIDE && test.uid === slide.uid)
    if (!existingSlide) {
      const slideRef: HydratedSupplementarySlideRef = {
        type: SUPPLEMENTARY_SLIDE,
        order: slide.order,
        uid: slide.uid,
        supplementarySlide: {
          ...slide,
          title: slide.title === defaultTitle ? (slide.type && slideLabelMap[slide.type]) || defaultTitle : slide.title,
        },
      }
      dispatch({ ...getBaseAction(), type: ADD_SUPPLEMENTARY_SLIDE, slideRef })
    } else {
      dispatch({ ...getBaseAction(), type: UPDATE_SUPPLEMENTARY_SLIDE, uid: slide.uid, slide })
    }

    setEditSupplementarySlide(null)
  }

  const handleSupplementarySlideShare = (slide: SupplementarySlide) => {
    dispatch({ ...getBaseAction(), type: SHARE_SUPPLEMENTARY_SLIDE, uid: slide.uid, slide })
    setEditSupplementarySlide(null)
  }

  const handleSupplementarySlideDelete = (slide: SupplementarySlide) => {
    const existingSlide = state.slides.find(test => test.type === SUPPLEMENTARY_SLIDE && test.uid === slide.uid)
    if (!existingSlide) return
    dispatch({ ...getBaseAction(), type: REMOVE_SUPPLEMENTARY_SLIDE, uid: slide.uid })
    setEditSupplementarySlide(null)
  }

  const handleSlideSelected = (slideType: SlideType, slide?: ModuleStep | SupplementarySlide) => {
    if (!slide) {
      if (slideType === HOLDING_SLIDE && !isAssistant) {
        dispatch({
          ...getBaseAction(),
          type: UPDATE_CURRENT_SLIDE,
          slideUid: null,
          activateGroupLock: sessionData && sessionData.module.type === 'cadet',
        })
      }
      if (slideType === END_SLIDE)
        if (!isAssistant) {
          dispatch({
            ...getBaseAction(),
            type: UPDATE_CURRENT_SLIDE,
            slideUid: 'SESSION_END',
            activateGroupLock: sessionData && sessionData.module.type === 'cadet',
          })
        } else {
          if (!facilitatorState) return
          dispatch({
            ...getBaseAction(),
            type: UPDATE_PREVIEW_SLIDE,
            facilitatorUid: facilitatorState.profile.uid,
            slideUid: 'SESSION_END',
          })
        }
      return
    }
    // past mode we ignore nav buttons so go straight to slide
    if (pastMode) {
      const isSkipped = !!skippedSlides.find(({ name, value }) => name === currentPreviewSlideUid && value === true)
      if (!isSkipped && !isAssistant) {
        if (slideContainerRef.current) slideContainerScrollRef.current = slideContainerRef.current.scrollTop
        dispatch({
          ...getBaseAction(),
          type: UPDATE_CURRENT_SLIDE,
          slideUid: slide.uid,
          activateGroupLock: sessionData && sessionData.module.type === 'cadet',
        })
      }
      return
    }
    if (slide.uid === currentSlideUid) {
      // set previewing slide to null if going back to 'previewing' current slide
      if (currentPreviewSlideUid !== null) {
        if (!facilitatorState) return
        dispatch({
          ...getBaseAction(),
          type: UPDATE_PREVIEW_SLIDE,
          facilitatorUid: facilitatorState.profile.uid,
          slideUid: null,
        })
      }
    } else if (slide.uid === currentPreviewSlideUid) {
      if (slideType === MODULE_SLIDE) {
        // ensure that a skipped slide cannot be activated accidentally
        const isSkipped = !!skippedSlides.find(({ name, value }) => name === currentPreviewSlideUid && value === true)
        if (!isSkipped && !isAssistant) {
          if (slideContainerRef.current) slideContainerScrollRef.current = slideContainerRef.current.scrollTop
          dispatch({
            ...getBaseAction(),
            type: UPDATE_CURRENT_SLIDE,
            slideUid: slide.uid,
            activateGroupLock: sessionData && sessionData.module.type === 'cadet',
          })
        }
      } else if (slideType === SUPPLEMENTARY_SLIDE) {
        handleEditSupplementarySlide(slide as SupplementarySlide)
      }
    } else {
      if (!facilitatorState) return
      dispatch({
        ...getBaseAction(),
        type: UPDATE_PREVIEW_SLIDE,
        facilitatorUid: facilitatorState.profile.uid,
        slideUid: slide.uid,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [springProps, setSpringValues] = useSpring(() => ({
    config: { tension: 210 },
    scrollTop: 0,
  }))

  useEffect(() => {
    if (!slideContainerRef.current || !sessionData || !currentSlide) return
    if (
      currentSlide.type !== HOLDING_SLIDE &&
      currentSlide.type !== END_SLIDE &&
      prevSlideUid.current !== currentSlide.uid
    ) {
      prevSlideUid.current = currentSlide.uid
      const refIndex = currentSlide.order + 1
      if (slideRefs.current && slideRefs.current[refIndex]) {
        const containerRect = slideContainerRef.current.getBoundingClientRect()
        const slideRect = slideRefs.current[refIndex].getBoundingClientRect()
        const nextScrollTop = slideContainerScrollRef.current + (slideRect.top - containerRect.top) - 10
        setSpringValues({ reset: true, from: { scrollTop: slideContainerScrollRef.current }, scrollTop: nextScrollTop })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide])

  return (
    <>
      <SidebarBody
        ref={slideContainerRef}
        previewing={previewing || undefined}
        // {...springProps}
      >
        {slides.map((slide, i) => {
          const active = !!(
            currentSlide &&
            slide.type === currentSlide.type &&
            slide.type !== HOLDING_SLIDE &&
            currentSlide.type !== HOLDING_SLIDE &&
            slide.type !== END_SLIDE &&
            currentSlide.type !== END_SLIDE &&
            currentSlide.uid === slide.uid
          )
          const previewing = !!(
            currentPreviewSlide &&
            slide.type === currentPreviewSlide.type &&
            slide.type !== HOLDING_SLIDE &&
            currentPreviewSlide.type !== HOLDING_SLIDE &&
            slide.type !== END_SLIDE &&
            currentPreviewSlide.type !== END_SLIDE &&
            currentPreviewSlide.uid === slide.uid
          )

          let node: ReactNode = null
          if (slide.type === HOLDING_SLIDE && !pastMode)
            node = (
              <SupplementarySlideCell
                key={i}
                ref={registerSlideRef}
                supplementarySlide={{
                  type: 'holding',
                  title: 'Holding Screen',
                  uid: 'holding',
                  order: -1,
                  participantUids: [],
                }}
                previewing={false}
                active={currentSlideUid === null}
                onSelect={() => handleSlideSelected(HOLDING_SLIDE)}
                participants={state.participants.filter(({ currentSlideUid }) => currentSlideUid === null)}
              />
            )

          if (slide.type === MODULE_SLIDE) {
            const slideParticipants = state.participants.filter(({ currentSlideUid }) => currentSlideUid === slide.uid)
            const skipped = !!skippedSlides.find(({ name, value }) => name === slide.uid && value === true)
            const viewed = !!viewedSlides.find(({ name, value }) => name === slide.uid && value === true)
            node = (
              <RelativeDiv>
                <StepSlideCell
                  key={i}
                  ref={registerSlideRef}
                  step={slide.step}
                  active={active}
                  previewing={previewing}
                  skipped={skipped}
                  viewed={viewed}
                  past={pastMode}
                  onSelect={() => handleSlideSelected(slide.type, slide.step)}
                  participants={slideParticipants.length < state.participants.length ? slideParticipants : []}
                />
                {previewing && !isAssistant && (
                  <SideBarButtonGroup>
                    <ModuleSlideButtons slide={slide} onSelected={() => handleSlideSelected(slide.type, slide.step)} />
                  </SideBarButtonGroup>
                )}
              </RelativeDiv>
            )
          }
          if (slide.type === SUPPLEMENTARY_SLIDE) {
            const supplementarySlide = supplementarySlides.find(({ uid }) => uid === slide.uid)
            if (supplementarySlide)
              node = (
                <RelativeDiv>
                  <SupplementarySlideCell
                    key={i}
                    ref={registerSlideRef}
                    supplementarySlide={supplementarySlide}
                    active={active}
                    previewing={previewing}
                    onSelect={() => handleSlideSelected(slide.type, slide.supplementarySlide)}
                    participants={state.participants.filter(
                      ({ currentSlideUid }) => currentSlideUid === slide.supplementarySlide.uid
                    )}
                  />
                  {previewing && (
                    <SideBarButtonGroup>
                      <Button
                        size="xs"
                        children="Edit / Share"
                        style={{ marginRight: 5 }}
                        onClick={() => handleSlideSelected(slide.type, slide.supplementarySlide)}
                      />
                    </SideBarButtonGroup>
                  )}
                </RelativeDiv>
              )
          }
          if (slide.type === END_SLIDE && !pastMode)
            node = (
              <SupplementarySlideCell
                key={i}
                ref={registerSlideRef}
                supplementarySlide={{
                  type: 'end',
                  title: 'End Meeting',
                  uid: 'end',
                  order: slides.length + 1,
                  participantUids: [],
                }}
                previewing={false}
                active={currentSlideUid === 'SESSION_END'}
                onSelect={() => handleSlideSelected(END_SLIDE)}
                participants={state.participants.filter(({ currentSlideUid }) => currentSlideUid === 'SESSION_END')}
              />
            )

          const nextSlide = slides[i + 1]
          const supplementarySlideOrder = nextSlide
            ? slide.order + (nextSlide.order - slide.order) / 2
            : slide.order + 1
          return (
            <Fragment key={i}>
              {node}
              {!pastMode && i < slides.length - 1 && (
                <AddSupplementarySlide
                  key={`add${i}`}
                  onSelect={() => handleAddSupplementarySlide(supplementarySlideOrder)}
                />
              )}
              {pastMode && <Spacer />}
            </Fragment>
          )
        })}
      </SidebarBody>
      {editSupplementarySlide && (
        <EditSupplementarySlideModal
          slide={editSupplementarySlide}
          isNew={supplementarySlides.map(({ uid }) => uid).indexOf(editSupplementarySlide.uid) < 0}
          onClose={() => setEditSupplementarySlide(null)}
          onSave={handleSupplementarySlideSave}
          onDelete={handleSupplementarySlideDelete}
          onShare={handleSupplementarySlideShare}
        />
      )}
    </>
  )
}

interface SkipButtonProps {
  slide: HydratedModuleSlideRef
  onSelected: () => void
}

const ModuleSlideButtons: React.FC<SkipButtonProps> = ({ slide, onSelected }) => {
  const inputContext = useInputContext()
  const [skipped, setSkipped] = useInputInterface<boolean>({
    name: slide.uid,
    contextOverride: {
      ...inputContext,
      participant_uid: 'shared',
      owner: 'skipped_slides',
      owner_id: null,
    },
    defaultValue: false,
    socketDebounce: 0,
    databaseDebounce: 500,
  })
  const [viewed, setViewed] = useInputInterface<boolean>({
    name: slide.uid,
    contextOverride: {
      ...inputContext,
      participant_uid: 'shared',
      owner: 'viewed_slides',
      owner_id: null,
    },
    defaultValue: false,
    socketDebounce: 0,
    databaseDebounce: 500,
  })
  const handleSelect = () => {
    if (!viewed) setViewed(true)
    setTimeout(() => onSelected())
  }
  return (
    <>
      {!skipped && <Button size="xs" children="Share" style={{ marginRight: 5 }} onClick={() => handleSelect()} />}
      <Button size="xs" children={skipped ? 'Enable' : 'Skip'} onClick={() => setSkipped(!skipped)} />
    </>
  )
}

interface SlideCellProps {
  active: boolean
  previewing: boolean
  onSelect: () => void
}

interface StepSlideCellProps extends SlideCellProps {
  step: ModuleStep
  skipped: boolean
  viewed: boolean
  participants: ParticipantState[]
  past?: boolean
}

interface SupplementarySlideCellProps extends SlideCellProps {
  supplementarySlide: SupplementarySlide
  participants: ParticipantState[]
}

const StepSlideCell = forwardRef((props: StepSlideCellProps, ref: Ref<HTMLDivElement>) => {
  const { step, active, previewing, skipped, viewed, participants, past, onSelect } = props
  return (
    <SlideDiv
      ref={ref}
      active={active}
      previewing={previewing || undefined}
      skipped={skipped}
      past={past}
      onClick={onSelect}>
      <div style={{ margin: 10 }}>
        <P>
          {step.title}
          {step.estimated_time && (
            <>
              <br />
              <small>{step.estimated_time}</small>
            </>
          )}
        </P>
        {participants.length > 0 && (
          <ParticipantBubbleContainer>
            {participants.map(({ profile }) => (
              <ParticipantBubble key={profile.uid} text={profile.displayName.substr(0, 3)} />
            ))}
          </ParticipantBubbleContainer>
        )}
      </div>
      {viewed && (
        <SVG
          viewboxWidth={24}
          viewboxHeight={15}
          width={16}
          height={10}
          style={{ position: 'absolute', top: 12, right: 10 }}>
          <path
            d="M2.425 7.025l.745.667-.745-.667zM2 7.5l-.745-.667-.597.667.597.667L2 7.5zm10.11-6.496l-.032 1h.066l-.033-1zm9.687 6.021l-.746.667.746-.667zm.425.475l.745.667.596-.667-.596-.667-.745.667zm-.425.475l-.746-.667.746.667zM12.359 14v-1 1zm-.248-.004l.033-1h-.066l.033 1zm-.249.004v1-1zM2.425 7.975l-.745.667.745-.667zM1.68 6.358l-.425.475 1.49 1.334.425-.475-1.49-1.334zM11.862 0C8.653 0 5.273 2.341 1.68 6.358l1.49 1.334C6.724 3.719 9.585 2 11.862 2V0zm.282.005A8.575 8.575 0 0011.862 0v2c.073 0 .145.001.216.004l.066-2zM12.36 0a8.57 8.57 0 00-.282.005l.066 1.999C12.215 2 12.287 2 12.36 2V0zm10.182 6.358C18.948 2.341 15.568 0 12.359 0v2c2.277 0 5.138 1.72 8.692 5.692l1.49-1.334zm.425.475l-.425-.475-1.49 1.334.424.475 1.49-1.334zm-.425 1.809l.425-.475-1.49-1.334-.426.475 1.49 1.334zM12.359 15c3.21 0 6.59-2.341 10.183-6.358l-1.49-1.334C17.496 11.281 14.635 13 12.36 13v2zm-.281-.005c.093.004.187.005.282.005v-2c-.073 0-.145-.001-.216-.004l-.066 2zm-.216.005c.094 0 .188-.002.282-.005l-.066-1.998a6.706 6.706 0 01-.216.003v2zM1.68 8.642C5.273 12.659 8.653 15 11.862 15v-2c-2.277 0-5.138-1.72-8.692-5.692L1.68 8.642zm-.425-.475l.425.475 1.49-1.334-.425-.475-1.49 1.334z"
            fill="#BDC3E0"
          />
          <circle cx="12.167" cy="7.167" r="2.167" fill="#BDC3E0" />
        </SVG>
      )}
    </SlideDiv>
  )
})

const SupplementarySlideCell = forwardRef((props: SupplementarySlideCellProps, ref: Ref<HTMLDivElement>) => {
  const { supplementarySlide, active, previewing, participants, onSelect } = props
  const iconPath = `./assets/supplementary-icons/${supplementarySlide.type}.svg`
  return (
    <SupplementarySlideDiv
      ref={ref}
      active={active}
      previewing={previewing || undefined}
      participants={participants}
      onClick={onSelect}>
      <div style={{ margin: 10 }}>
        <Row alignItems="center">
          {supplementarySlide.type !== 'holding' && supplementarySlide.type !== 'end' && (
            <>
              <img src={require(`${iconPath}`)} alt="" />
              <Spacer size="xs" />
            </>
          )}
          <P>{supplementarySlide.title}</P>
        </Row>
        {participants.length > 0 && (
          <ParticipantBubbleContainer>
            {participants.map(({ profile }) => (
              <ParticipantBubble key={profile.uid} text={profile.displayName.substr(0, 3)} />
            ))}
          </ParticipantBubbleContainer>
        )}
      </div>
    </SupplementarySlideDiv>
  )
})

const RelativeDiv = styled.div`
  position: relative;
`

const ParticipantBubble = styled.div<{ text: string }>`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #fff;
  &::after {
    content: '${p => p.text}';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #7D41DF;
    font-size: 12px;
    text-transform: uppercase;
  }
`

const ParticipantBubbleContainer = styled.div`
  & ${ParticipantBubble} {
    margin-right: 7px;
    margin-top: 5px;
  }
`

const SidebarBody = styled(animated.div)<{ previewing?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: #fff;
  padding: 10px;
  overflow: auto;
  pointer-events: ${p => (p.previewing ? 'none' : 'auto')};
  &::after {
    content: ${p => (p.previewing ? `'Slide selection disabled when in preview mode'` : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    text-align: center;
    padding: 42px 15px;
  }
`

const SideBarButtonGroup = styled.div`
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(70, 75, 97, 0.85);
  border-radius: 7px;

  ${Button} {
    cursor: pointer;
  }
`

const AddSupplementarySlide: React.FC<{ onSelect: () => void }> = ({ onSelect }) => (
  <Row justifyContent="center" paddingTop="12px" paddingBottom="15px">
    <CircleButton size="s" theme="purple" children="+" onClick={onSelect} style={{ fontSize: 20 }} />
  </Row>
)

const BaseSlideDiv = styled.div`
  position: relative;
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  cursor: pointer;
  border: 3px solid #cdd2e4;
  box-sizing: border-box;
  & ${P} {
    margin: 0;
    color: inherit;
  }
`

interface SlideDivProps {
  active?: boolean
  previewing?: boolean
  skipped?: boolean
  past?: boolean
}

const SlideDiv = styled(BaseSlideDiv)<SlideDivProps>`
  background-color: ${p => (p.past ? '#f3c1a4' : '#edf2fa')};
  border-color: ${p => (p.active ? '#4EBE40' : p.past ? '#FF8A00' : p.previewing ? '#FF8A00' : '#CDD2E4')};
  ${p =>
    p.previewing
      ? css`
          box-shadow: 0 0 5px rgba(125, 65, 223, 0.2);
        `
      : ''}
  &::after {
    content: ${p => (p.skipped ? `'Skipped'` : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    overflow: hidden;
    background-color: rgba(70, 75, 97, 0.85);
    color: #fff;
    text-align: center;
    padding: 40px 0;
    font-size: 20px;
    line-height: 0.5;
    font-weight: 500;
    ${fontBold}
  }
`

interface SupplementarySlideDivProps {
  active?: boolean
  previewing?: boolean
  participants: StepSlideCellProps['participants']
}

const SupplementarySlideDiv = styled(BaseSlideDiv)<SupplementarySlideDivProps>`
  color: #fff;
  background-color: #7d41df;
  border-color: ${p => (p.active ? '#4EBE40' : p.previewing ? '#FF8A00' : '#6B3BBB')};

  ${p =>
    p.previewing
      ? css`
          box-shadow: 0 0 5px rgba(125, 65, 223, 0.2);
        `
      : ''}

  ${p =>
    p.participants.length
      ? css`
          border-color: #4ebe40;
        `
      : ''}
`
