import React from 'react'

import { NavRouteProps } from 'dashboards/types'

import { P, Spacer, CollapseMargin } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { Cells } from 'dashboards/common/Cell'
import { LinkButton } from 'common/LinkButton'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { GroupCell } from 'dashboards/common/GroupCell'
import { SpinnerWithLabel } from 'common/Spinner'

import { useFacDashData } from './FacilitatorDashboardState'
import { useUserState } from 'app/UserState'
import { sortByKey } from 'utils/sortUtils'

export const Groups: React.FC<NavRouteProps> = ({ route }) => {
  const { drupalProfile } = useUserState()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groups, loadingGroups, fetchGroups] = useFacDashData('groups', [])
  const myGroups = drupalProfile
    ? groups.filter(({ facilitator_id }) => drupalProfile.user_id === String(facilitator_id))
    : []
  return (
    <Page route={route}>
      <Spacer />
      <CollapseMargin>
        <P>Here you can review your existing SAS groups or establish a new group.</P>
      </CollapseMargin>

      <Spacer size="l" />
      <HeaderHr
        children="My Groups"
        tooltip={
          <>
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>Tips for creating a group:</span>
            <ul style={{ minWidth: 400 }}>
              <li>SAS Facilitators can create group of up to 4 Cadets in any one software group.</li>
              <li>Groups can be empty.</li>
              <li>
                If you and another SAS Facilitator are running two software groups to create a combined group of 4-6
                Cadets with two facilitators, the second group is best created in the other SAS Facilitator's Dashboard.
              </li>
              <li>
                The Lead Facilitator for any group can be changed from with the one SAS Provider network through the
                "Unassign" button and then "Unassigned Groups" menu tab.
              </li>
              <li>
                If you have a co-facilitator or SAS Assistant joining you for any individual group meeting, they are
                added later when you schedule your group meetings and can be changed at any time before the meeting
                starts.
              </li>
              <li>
                <strong>Senior SAS Facilitators</strong> - When Senior SAS Facilitators are creating a group there is an
                option to add a co-facilitator or SAS Assistant at the overall group level to unlock a maximum of 6
                Cadets in one software group.
              </li>
            </ul>
          </>
        }
        tooltipPlacement="bottomRight"
      />

      {loadingGroups ? (
        <SpinnerWithLabel label="Loading groups..." color="#925BED" />
      ) : myGroups.length > 0 ? (
        <Cells>
          {myGroups.sort(sortByKey('created', 'descending')).map(group => (
            <GroupCell key={group.id} group={group} callback={fetchGroups} />
          ))}
        </Cells>
      ) : (
        <P>You haven't created a group yet.</P>
      )}
      <Spacer size="l" />
      <P>
        <LinkButton size="m" children="Create a group" to={route.path + '/new'} />
      </P>
    </Page>
  )
}
