import { ActionTypes } from './actionTypes'
import { IdField, UidField, OrderField, TitleField, OwnerOccupierFields } from '../types'
import { AnyModuleCode } from '../dashboard/moduleCodes'
import {
  DividerSection,
  ListSection,
  MediaGridSection,
  TextSection,
  MediaAsset,
  DownloadResourceSection,
} from '../session/types'

export interface FacManState {
  currentStepUid: string | null
}

export interface FacManStateHookObject {
  state: FacManState
  facManData: false | FacManData
  setFacManData: React.Dispatch<React.SetStateAction<this['facManData']>>
  dispatch: (action: ActionTypes) => void
  previewing: boolean
  setPreviewing: React.Dispatch<React.SetStateAction<this['previewing']>>
  previewState: PreviewState
  setPreviewState: React.Dispatch<React.SetStateAction<this['previewState']>>
}

export interface PreviewState {
  stepIndex: number
}

export interface FacManData {
  module: FacManModule
}

/** CakePHP Model: ManualModules */
export interface FacManModule extends IdField, UidField {
  title: string
  public_title?: string
  manual_steps: FacManModuleStep[]
  type: string
  module_code: AnyModuleCode
}

/** CakePHP Model: ManualSteps */
export interface FacManModuleStep extends IdField, UidField, OrderField, SectionContainer {
  title?: string
  subheading?: string
  estimated_time?: string
  hide_header: boolean
  show_section_header: boolean
  section_header_text?: string
}

/** CakePHP Model: QuoteSections */
export interface QuoteSection extends Section {
  title: string
  text: string
}

/** CakePHP Model: TableSections */
export interface TableSection extends Section {
  description: string
  column1_title: string
  column2_title: string
  content: [string, string][]
}

/** CakePHP Model: HeadingSections */
export interface HeadingSection extends Section {
  title: string
  heading: string
  subheading: string
  image: MediaAsset
}

/** CakePHP Model: PanelSections */
export interface PanelSection
  extends Section,
    IdField,
    OrderField,
    TitleField,
    Exclude<SectionContainer, 'panel_sections'> {
  theme: 'purple' | 'purple-gradient' | 'purple-dashed' | 'blue' | 'green' | 'material'
}

/**
 * Section content types
 */

export const sectionProperties = [
  'divider_sections',
  'list_sections',
  'media_grid_sections',
  'text_sections',
  'heading_sections',
  'quote_sections',
  'table_sections',
  'panel_sections',
  'download_resource_sections',
] as const

export type SectionProperty = typeof sectionProperties[number]

export interface SectionObject {
  property: SectionProperty
  section: SectionContainer[this['property']][0]
  index: number
}

export interface SectionPropsBase extends SectionObject {}

type SectionContainerShape = { [key in SectionProperty]: Section[] }
export interface SectionContainer extends SectionContainerShape {
  divider_sections: DividerSection[]
  list_sections: ListSection[]
  media_grid_sections: MediaGridSection[]
  text_sections: TextSection[]
  heading_sections: HeadingSection[]
  quote_sections: QuoteSection[]
  table_sections: TableSection[]
  panel_sections: PanelSection[]
  download_resource_sections: DownloadResourceSection[]
}

export type ContentSection =
  | DividerSection
  | ListSection
  | MediaGridSection
  | TextSection
  | HeadingSection
  | QuoteSection
  | TableSection
  | PanelSection
  | DownloadResourceSection

export interface Section extends IdField, OwnerOccupierFields, OrderField, TitleField {}
