import React from 'react'
import styled from 'styled-components'

import { GenericUser } from 'shared/types'
import { FacilitatorLookupEntity, MentorEntity, MentorEntityNew } from 'shared/dashboard/types'
import { Tipsheet as TipsheetType, TipsheetStep as TipsheetStepType } from 'shared/tipsheets/types'
import { TipsheetPrintButtonProps } from './TipsheetPrintButton'

import { Container, Spacer } from 'common/ui'
import { TipsheetStep } from './TipsheetStep'
import { TipsheetHeader } from './sections/TipsheetHeader'
import { TipsheetFooter } from './sections/TipsheetFooter'

interface Props {
  tipsheetModule: TipsheetType
  facilitatorEntity?: FacilitatorLookupEntity
  facilitator?: GenericUser
  primaryMentor?: MentorEntity | MentorEntityNew
  onConfirm: () => void
  confirmRead: boolean
  viewOnly?: boolean
  printMode?: boolean
  printButtonProps?: TipsheetPrintButtonProps
}

export const Tipsheet: React.FC<Props> = ({
  tipsheetModule,
  facilitatorEntity,
  facilitator,
  primaryMentor,
  onConfirm,
  confirmRead,
  viewOnly,
  printMode,
  printButtonProps,
}) => {
  return (
    <TipsheetWrapper>
      <Container>
        <TipsheetHeader
          moduleTitle={tipsheetModule.title}
          name={primaryMentor ? `${primaryMentor.cadet_first_name} ${primaryMentor.cadet_last_name}` : ''}
        />
        <Spacer size="m" />
        {tipsheetModule.tipsheet_steps.map((stepDetails: TipsheetStepType, idx: number) => {
          return (
            <div key={idx} className="panel-wrapper">
              <Spacer size="m" />
              <TipsheetStep step={stepDetails} viewOnly={viewOnly} printMode={printMode} />
            </div>
          )
        })}
        <TipsheetFooter
          facilitatorName={facilitatorEntity ? facilitatorEntity.name : facilitator ? facilitator.displayName : ''}
          onSelect={onConfirm}
          confirmRead={confirmRead}
          printMode={printMode}
          printButtonProps={printButtonProps}
        />
        <Spacer size="m" />
      </Container>
    </TipsheetWrapper>
  )
}

export const TipsheetWrapper = styled.div`
  background-color: ${p => p.theme.buttonBorderTopColor};
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
`
