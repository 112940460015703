/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { fontBold } from 'fonts'

import { Emotionometer } from 'shared/e-telligence/types'
import { EmotionometerColors } from 'common/Emotionometer'
import { emotionRangeDistribution, emotionometerColors } from 'e-telligence/constants/typeValueMaps'

import { Spacer, OutlineButton, Column, Padding } from 'common/ui'
import { EmotionometerBar } from './EmotionometerBar'

import { capitalizeFirstLetter } from 'utils/stringUtils'

interface Props {
  emotionometer?: Emotionometer
  placeholder?: string
  onSelect: () => void
  onEdit?: () => void
}

export const EmotionometerTile: React.FC<Props> = ({ emotionometer, placeholder, onSelect, onEdit }) => {
  const colors: EmotionometerColors | undefined = emotionometer ? emotionometerColors[emotionometer.type] : undefined
  const color = colors ? colors[colors.length - 1] : undefined
  return (
    <>
      <TileDiv empty={!emotionometer} onClick={emotionometer ? onSelect : onEdit} color={color}>
        {!emotionometer ? (
          <label>{placeholder || 'Create Emotionometer +'}</label>
        ) : (
          <Column style={{ width: '100%' }}>
            <label>{capitalizeFirstLetter(emotionometer.type)} Emotionometer</label>
            <Padding size="s">
              <EmotionometerBar
                type={emotionometer.type}
                value={[0, 1, 2]}
                distribution={emotionRangeDistribution}
                labels={[]}
              />
            </Padding>
          </Column>
        )}
      </TileDiv>
      {/*emotionometer && onEdit && (
        <>
          <Spacer size="xs" />
          <OutlineButton size="xs" onClick={onEdit}>
            Edit Emotionometer
          </OutlineButton>
        </>
      )*/}
    </>
  )
}

const TileDiv = styled.div<{ empty?: boolean; color?: string }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 105px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${p => (p.empty ? p.theme.thinOutlineColor : p.theme.thinOutlineActiveColor)};
  border-radius: 5px;
  ${p => (p.empty ? '' : `cursor: pointer;`)}
  ${p => (p.empty ? '' : `border-left: 10px solid ${p.color || 'white'};`)}

  color: ${p => (p.empty ? 'inherit' : 'white')};
  background-color: ${p => (p.empty ? 'white' : 'black')};

  text-align: center;
  font-size: 1rem;

  & label {
    ${fontBold}
    color: ${p => (p.empty ? p.theme.thinOutlineColor : 'inherit')};
    pointer-events: none;
  }
`
