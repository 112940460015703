import { GameObjects, Tweens, Math as PhaserMath, Input } from 'phaser'
import { Vector } from 'types'
import { getDist } from 'utils/pointUtils'
import { ActivityThoughtTrackerStateValue, ThoughtTrackerUFOType as UFOType } from 'shared/session/types'
import ThoughtTrackerScene from './ThoughtTrackerScene'

export const defaultState: ActivityThoughtTrackerStateValue = {
  cut: false,
  value: '',
  cutPosition: null,
  finishedExiting: false,
}

export class UFO extends GameObjects.Image {
  private cutCursorPosition: Vector = { x: 0, y: 0 }
  private aimPosition: Vector = { x: 0, y: 0 }
  private originPosition: Vector = { x: 0, y: 0 }
  private exitPosition: Vector
  private homingTween: Tweens.Tween | null = null
  private exitTween: Tweens.Tween | null = null
  private floatingTween: Tweens.Tween | null = null
  private rope1: GameObjects.Image
  private rope2: GameObjects.Image
  private delay: number
  private previousPosition: Vector
  private ufoState: ActivityThoughtTrackerStateValue = defaultState
  private index: number
  scene: ThoughtTrackerScene

  constructor(
    scene: ThoughtTrackerScene,
    origin: Vector,
    exitPosition: Vector,
    type: UFOType,
    tint: number | null,
    scale: number,
    delay: number,
    index: number
  ) {
    super(scene, origin.x, origin.y, type)
    this.scene = scene
    this.setDistance(scale, scale)
    this.originPosition = origin
    this.exitPosition = exitPosition
    this.aimPosition = { x: this.originPosition.x, y: this.originPosition.y - 30 }
    this.previousPosition = { x: this.x, y: this.y }
    this.delay = delay
    this.index = index
    if (tint !== null) this.setTint(tint)

    new GameObjects.Image(scene, 0, 0, 'string')

    this.rope1 = new GameObjects.Image(scene, 0, 0, 'string').setOrigin(0, 0.5)
    this.rope2 = new GameObjects.Image(scene, 0, 0, 'string').setOrigin(0, 0.5)

    this.rope2.width = 1000
    this.rope2.displayWidth = 1000

    this.rope1.addListener('pointerdown', (pointer: Input.Pointer) => {
      if (!this.ufoState.cut) {
        this.cutCursorPosition = { x: pointer.x, y: pointer.y }
        this.scene.dispatchProfileUfoState({ ...this.scene.profileState.thoughts[this.index], cut: true }, this.index)
      }
    })

    this.rope2.addListener('pointerdown', (pointer: Input.Pointer) => {
      if (!this.ufoState.cut) {
        this.cutCursorPosition = { x: pointer.x, y: pointer.y }
        this.scene.dispatchProfileUfoState({ ...this.scene.profileState.thoughts[this.index], cut: true }, this.index)
      }
    })

    scene.add.existing(this)
    scene.add.existing(this.rope1)
    scene.add.existing(this.rope2)

    this.start(type)
  }

  updateState(state: ActivityThoughtTrackerStateValue) {
    if (!this.ufoState.cut && state.cut) {
      this.resizeRope(this.cutCursorPosition.y === 0 ? this.rope1.y : this.cutCursorPosition.y, this.rope1.y)
      this.cutRope()
    }
    this.ufoState = state
  }

  start = (texture: UFOType) => {
    this.reset()

    this.setTexture(texture)
    if (texture === 'plane') {
      this.setOrigin(0.45, 0.36)
    } else {
      this.setOrigin(0.5)
    }

    this.moveToPosition(this.originPosition.x, this.originPosition.y, this.delay, () => {
      if (!this.ufoState.cut) {
        this.homingTween?.stop()
        this.homingTween = null
        this.floatingTween = this.scene.tweens.add({
          targets: this,
          x: this.aimPosition.x,
          y: this.aimPosition.y,
          duration: 2500,
          ease: PhaserMath.Easing.Quadratic.InOut,
          yoyo: true,
          delay: this.delay,
          repeat: 10000,
        })
      }
    })
  }

  disableHomingTween = () => {
    this.homingTween = null
  }

  moveToPosition = (
    x: number,
    y: number,
    delay: number = 0,
    callback: () => void = () => {
      this.homingTween = null
    }
  ) => {
    const distanceFromTarget = getDist(this.x, this.y, x, y)
    this.homingTween = this.scene.tweens.add({
      targets: this,
      x: x,
      y: y,
      delay: delay,
      ease: PhaserMath.Easing.Quadratic.InOut,
      duration: (distanceFromTarget / 100) * 500,
      onComplete: callback,
    })
  }

  setDistance(scale: number, depth: number) {
    this.setScale(scale)
    this.setDepth(depth)
  }

  getRopeRotation(elm: GameObjects.Image, delta: number) {
    return PhaserMath.Angle.BetweenPoints(elm, {
      x: this.x - 50 + Math.sin(delta / 1000) * 20,
      y: 2000,
    })
  }

  resizeRope(clickPositionY: number, ropeY: number) {
    this.rope1.displayWidth = clickPositionY - ropeY
    this.rope1.width = clickPositionY - ropeY
  }

  setRope2Position() {
    this.rope2.setPosition(
      this.rope1.x + Math.cos(this.rope1.rotation) * this.rope1.width,
      this.rope1.y + Math.sin(this.rope1.rotation) * this.rope1.width
    )
  }

  dropRope() {
    this.setRope2Position()
    this.exitTween = this.scene.tweens.add({
      targets: this.rope2,
      x: this.rope2.x + 200,
      y: 3000,
      rotation: -2,
      duration: 2000,
      ease: PhaserMath.Easing.Quadratic.InOut,
    })
  }

  cutRope() {
    this.dropRope()
    this.floatingTween?.stop()
    this.moveToPosition(this.exitPosition.x, this.exitPosition.y, 0, () => {
      this.scene.dispatchProfileUfoState(
        { ...this.scene.profileState.thoughts[this.index], finishedExiting: true },
        this.index
      )
      this.homingTween = null
      this.reset()
    })
  }

  setEditMode(editMode: boolean) {
    if (editMode) {
      this.rope1.removeInteractive()
      this.rope2.removeInteractive()
    } else {
      this.rope1.setInteractive({
        cursor: `url(${require('./assets/scissor.png')}), pointer`,
      })
      this.rope2.setInteractive({
        cursor: `url(${require('./assets/scissor.png')}), pointer`,
      })
    }
  }

  getPosition = () => ({
    x: this.x + 20,
    y: this.y - 10,
    scale: this.scale,
    rotation: this.rotation,
  })

  reset() {
    this.homingTween?.stop()
    this.homingTween = null
    this.exitTween?.stop()
    this.exitTween = null
    this.floatingTween?.stop()
    this.floatingTween = null
    this.rotation = 0
    this.x = this.originPosition.x - 1000
    this.y = this.originPosition.y + 500
  }

  update(delta: number) {
    this.rope1.setPosition(this.x + 180 * this.scale, this.y + 140 * this.scale)
    if (this.ufoState.cut) {
      const ufoPositionDiff = Math.max(-30, Math.min(this.x - this.previousPosition.x, 30)) // limit to +-30px
      const aimRotation = Math.PI / 2 + ufoPositionDiff / 10
      this.rope1.rotation += (aimRotation - this.rope1.rotation) / 10
    } else {
      this.rope1.rotation = this.getRopeRotation(this.rope1, delta)
    }

    if (!this.ufoState.cut) {
      this.setRope2Position()
      this.rope2.rotation = this.getRopeRotation(this.rope2, delta)
    }

    if (this.ufoState.cut && this.rotation >= -0.15) {
      this.rotation -= 0.001
    }

    this.previousPosition = { x: this.x, y: this.y }
  }
}
