import { DrupalProfile } from 'api'
import { GroupEntity, MentorEntity, MentorEntityNew } from 'shared/dashboard/types'
import { GenericUser } from 'shared/types'
import sub from 'date-fns/sub'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

export const getGroupCapacity = (group: GroupEntity, user: GenericUser): number => {
  const isSeniorFacilitator = user.roles.indexOf('sas-sg senior facilitator') >= 0
  return isSeniorFacilitator && !!group.cofacilitator_id ? 6 : 4
}

export const groupAtCapacity = (group: GroupEntity, user: GenericUser): boolean => {
  return group.primary_mentors.length >= getGroupCapacity(group, user)
}

export const getMentorIndex = (primaryMentors: MentorEntity[], mentor: MentorEntity | MentorEntityNew): number => {
  return primaryMentors.reduce((n, test, i) => (test.uid === mentor?.uid ? i : n), -1)
}

export const getMentorStatusLabel = (mentor: MentorEntity | MentorEntityNew) => getMentorStatus(mentor).label

export const getMentorStatusColor = (mentor: MentorEntity | MentorEntityNew) => getMentorStatus(mentor).color

export const getMentorStatus = (
  mentor: MentorEntity | MentorEntityNew
): { color?: 'red' | 'orange' | 'green'; label: string } => {
  if (!mentor.id) return { color: undefined, label: '' }
  if (mentor.drupal_user_id) {
    if (mentor.primary_licence_holder) {
      if (mentor.activated) return { color: 'green', label: `Mentor Portal is set up, Cadet Place activated.` } // prettier-ignore 
      else return { color: 'orange', label: `Mentor Portal is set up, awaiting Cadet Place activation.` } // prettier-ignore
    } else {
      return { color: 'green', label: `Mentor Portal is set up.` } // prettier-ignore
    }
  } else {
    if (mentor.activation_email_sent) {
      return { color: 'orange', label: `Invitation email sent. Awaiting account set up.` } // prettier-ignore 
    } else
      return { color: 'orange', label: `Mentor Portal invitation email not sent yet.`} // prettier-ignore
  }
}

export type ExpiryStatus = 'active' | 'warning' | 'due' | 'expired'

export const getMentorExpiryStatus = (mentor: MentorEntity | MentorEntityNew): null | ExpiryStatus => {
  if (!mentor.product_key_expiry) return null
  if (mentor.product_key_expired) return 'expired'

  const today = new Date()
  const expiryDate = new Date(mentor.product_key_expiry)
  const warningPeriodStart = sub(expiryDate, { months: 3 })
  const renewalPeriodStart = sub(expiryDate, { months: 1 })

  // if expiry is between 3 months and 1 month from now, show as expiring
  if (isAfter(today, warningPeriodStart) && isBefore(today, renewalPeriodStart)) return 'warning'

  // if expiry is within 1 month from now, show as due
  if (isAfter(today, renewalPeriodStart)) return 'due'

  return 'active'
}

export const getGroupExpiryLabel = (group: GroupEntity): ExpiryStatus => {
  if (!group.primary_mentors.length) return 'active'

  return group.primary_mentors
    .map(mentor => getMentorExpiryStatus(mentor))
    .reduce((acc, val) => {
      if (acc === 'expired' || val === 'expired') {
        acc = 'expired'
      } else if (acc === 'due' || val === 'due') {
        acc = 'due'
      } else if (acc === 'warning' || val === 'warning') {
        acc = 'warning'
      } else {
        acc = 'active'
      }
      return acc
    }, 'active') as ExpiryStatus
}

export const hasGroupFacilitatorAccess = (profile: DrupalProfile, group: GroupEntity): boolean => {
  if (
    group.cofacilitator_id === +profile.user_id &&
    (profile.roles.indexOf('sas-sg facilitator') >= 0 || profile.roles.indexOf('sas-sg senior facilitator') >= 0)
  ) {
    return [group.facilitator_id, group.cofacilitator_id].map(String).indexOf(profile.user_id) >= 0
  }
  return group.facilitator_id.toString().indexOf(profile.user_id) >= 0
}
