import React, { useState } from 'react'
import styled from 'styled-components'
import { CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L, Button, H1, Row, Column, Spacer } from 'common/ui'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import { GadgetTile } from 'e-telligence/common/GadgetTile'
import { findGadgetById } from 'e-telligence/stateUtils'
import { RelaxationGadgetsIndexInner } from 'e-telligence/RelaxationGadgetsIndex'
import { RelaxationGadgetViewInner } from 'e-telligence/RelaxationGadgetView'
import { unstable_batchedUpdates } from 'react-dom'
import { RelaxationGadgetEditInner } from 'e-telligence/RelaxationGadgetEdit'
import uniq from 'lodash/uniq'

type ScreenType = 'top5' | 'index' | 'view' | 'custom'

const padArray = (length: number, current: string[]) => {
  if (current.length >= 5) {
    return current.slice(Math.max(current.length - 5, 0))
  }
  return [...current, ...Array(length).fill(undefined)]
}

export const RelaxationGadgetsModal: React.FC<{ onClose: () => void; mentorMode?: boolean; readOnly?: boolean }> = ({
  onClose,
  mentorMode,
  readOnly,
}) => {
  const { state, dispatch } = useGadgetPackState()
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [currentScreen, setCurrentScreen] = useState<ScreenType>('top5')
  const [selectedGadget, setSelectedGadget] = useState<string | undefined>(undefined)
  const dedupedGadgetIds = uniq(state.gadgetIds)

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  const handleGadgetSelect = (id: string) => {
    if (id) {
      unstable_batchedUpdates(() => {
        setSelectedGadget(id)
        setCurrentScreen('view')
      })
    } else setCurrentScreen('index')
  }

  const handleGadgetIndexSelect = (id: string | undefined) => {
    if (id) {
      unstable_batchedUpdates(() => {
        setCurrentScreen('view')
        setSelectedGadget(id)
      })
    } else {
      setCurrentScreen('custom')
    }
  }

  const handleGadgetViewSelect = () =>
    unstable_batchedUpdates(() => {
      setCurrentScreen('top5')
      setSelectedGadget(undefined)
    })

  const handleGadgetEditClick = (id: string) => {
    setCurrentScreen('custom')
    setSelectedGadget(id)
  }

  const handleBackClick = () => {
    let target: ScreenType = 'top5'

    switch (currentScreen) {
      case 'custom':
        target = 'index'
        break
      case 'view':
        target = 'top5'
        break
      case 'index':
        target = 'top5'
        break
    }

    setCurrentScreen(target)
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={{
        flair:
          currentScreen !== 'view' && currentScreen !== 'custom'
            ? [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L]
            : [],
      }}
      panelStyle={
        mentorMode
          ? { minWidth: '100vw', minHeight: '100vh' }
          : currentScreen === 'view' || currentScreen === 'custom'
          ? { background: 'none', boxShadow: 'none', borderBottom: 'none' }
          : {}
      }>
      <RelaxationGadgetsContainer currentScreen={currentScreen} mentorMode={mentorMode}>
        <Row justifyContent="space-between" alignItems="center" marginBottom={20}>
          <H1>My Relaxation Gadgets</H1>
          {currentScreen === 'top5' && <Button size="s" onClick={delayedOnClose} children="Exit" />}
          {currentScreen !== 'top5' && <Button size="s" onClick={handleBackClick} children="Back" />}
        </Row>
        <Row justifyContent={mentorMode ? 'flex-start' : 'space-between'} flexWrap={mentorMode}>
          {currentScreen === 'top5' &&
            padArray(5 - dedupedGadgetIds.length, dedupedGadgetIds).map((id, i) => {
              return (
                <Row key={i} style={{ marginBottom: mentorMode ? 10 : 0 }}>
                  <GadgetTile gadget={findGadgetById(id, state)} onEdit={() => handleGadgetSelect(id)} />
                  {mentorMode && <Spacer size="s" />}
                </Row>
              )
            })}
          {currentScreen === 'index' && (
            <RelaxationGadgetsIndexInner
              baseUrl={''}
              state={state}
              dispatch={dispatch}
              overrideRoute={handleGadgetIndexSelect}
              hideCustom
            />
          )}
          {currentScreen === 'view' && (
            <RelaxationCustomContainer>
              <RelaxationGadgetViewInner
                hideBackButton
                baseUrl={''}
                state={state}
                dispatch={dispatch}
                gadgetId={selectedGadget}
                buttonText={{ add: 'Add To Top 5', remove: 'Remove from top 5' }}
                overrideButtonClick={handleGadgetViewSelect}
                overrideEditClick={handleGadgetEditClick}
                readOnly={readOnly}
              />
            </RelaxationCustomContainer>
          )}
          {currentScreen === 'custom' && (
            <RelaxationCustomContainer>
              <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
                <RelaxationGadgetEditInner
                  baseUrl={''}
                  state={state}
                  dispatch={dispatch}
                  overrideButtonClick={handleGadgetViewSelect}
                  gadgetId={selectedGadget}
                />
              </Column>
            </RelaxationCustomContainer>
          )}
        </Row>
      </RelaxationGadgetsContainer>
    </MobileModalPanel>
  )
}

const modalStyles = {
  top5: {
    modalWidth: 600,
    titleColour: '#001947',
  },
  index: { modalWidth: 1000, titleColour: '#001947' },
  view: { modalWidth: 1000, titleColour: '#fff' },
  custom: { modalWidth: 1000, titleColour: '#fff' },
}

const getModalStyles = (type: ScreenType) => {
  return modalStyles[type]
}

const RelaxationGadgetsContainer = styled.div<{ currentScreen: ScreenType; mentorMode?: boolean }>`
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  min-width: ${p => (p.mentorMode ? 'auto' : '600px')};

  ${H1} {
    font-size: 1rem;
    color: ${p => (p.mentorMode ? '#001947' : getModalStyles(p.currentScreen).titleColour)};
  }
`

const RelaxationCustomContainer = styled.div`
  background: #fff;
  width: 100%;
  border-radius: 12px;
  border-bottom: 5px solid #c4cae6;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
`
