import { SectionProperty, SectionObject, SectionContainer, sectionProperties } from 'shared/fac-man/types'

export const getSections = <T extends { [key in SectionProperty]: SectionContainer[key] }>(
  sectionsObj: T
): SectionObject[] =>
  sectionProperties
    .reduce((acc, property) => {
      return acc.concat(
        //@ts-ignore
        (property in sectionsObj ? sectionsObj[property] : []).map((section, index) => ({ property, section, index }))
      )
    }, [] as SectionObject[])
    //@ts-ignore
    .sort((a, b) => a.section.order - b.section.order)
