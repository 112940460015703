import React, { CSSProperties, ReactNode, useState, useEffect, useCallback, useRef, ComponentProps } from 'react'
import ReactDOM from 'react-dom'
import { Panel, Padding } from 'common/ui'
import { FlexModal, Props as ModalProps } from 'app/FlexModal'
import styled, { keyframes, css } from 'styled-components'

export const MODAL_FADE_IN_TIME = 600
export const MODAL_FADE_OUT_TIME = 280

export interface ReturnProps {
  requestClose: () => void
  isOpen: boolean
}

export const TransitionModal: React.FC<
  Omit<ModalProps, 'children'> & {
    ignoreFade?: boolean
    children: (props: ReturnProps) => ReactNode
  }
> = ({ children, ignoreFade, ...props }) => {
  const [fadeOut, setFadeOut] = useState<boolean>(!props.isOpen)
  const [realIsOpen, setRealIsOpen] = useState<boolean>(props.isOpen)
  const ignoreFadeIn = useRef<boolean>(false)
  const { isOpen, onRequestClose } = props
  const delayedOnClose = useCallback(
    (force: boolean = false) => {
      if (!onRequestClose && !force) return
      ignoreFadeIn.current = true
      setFadeOut(true)
      setTimeout(() => {
        ignoreFadeIn.current = false
        setRealIsOpen(false)
        if (onRequestClose) onRequestClose({} as React.MouseEvent)
      }, MODAL_FADE_OUT_TIME - 50)
    },
    [onRequestClose]
  )
  useEffect(() => {
    if (!isOpen && !fadeOut) {
      delayedOnClose(true)
    } else if (isOpen && fadeOut && !ignoreFadeIn.current) {
      ReactDOM.unstable_batchedUpdates(() => {
        setFadeOut(false)
        setRealIsOpen(true)
      })
    }
  }, [isOpen, fadeOut, delayedOnClose, onRequestClose])
  const renderProps = { isOpen: realIsOpen, requestClose: delayedOnClose }
  return (
    <FlexModal
      {...props}
      isOpen={realIsOpen}
      onRequestClose={() => delayedOnClose()}
      center
      style={{
        ...(props.style ? props.style : {}),
        overlay: {
          background: 'rgba(0, 0, 0, 0.3)',
          ...(props.style && props.style.overlay ? props.style.overlay : {}),
        },
        content: {
          pointerEvents: 'none',
          overflow: 'hidden',
          ...(props.style && props.style.content ? props.style.content : {}),
        },
      }}>
      {ignoreFade ? children(renderProps) : <FadeWrapper fadeOut={fadeOut} children={children(renderProps)} />}
    </FlexModal>
  )
}

export type MobileModalPanelProps = Omit<ModalProps, 'children'> & {
  panelStyle?: CSSProperties
  panelProps?: Partial<ComponentProps<typeof Panel>>
  panelName?: string
}

export const MobileModalPanel: React.FC<MobileModalPanelProps> = ({
  children,
  panelStyle = {},
  panelProps = {},
  panelName = 'GenericPanel',
  ...props
}) => {
  return (
    <TransitionModal {...props}>
      {() => (
        <Panel
          {...panelProps}
          style={{
            maxHeight: 'calc(100vh - 20px)',
            maxWidth: 'calc(100vw - 20px)',
            height: '100%',
            overflow: 'auto',
            borderRadius: 7,
            pointerEvents: 'auto',
            ...panelStyle,
          }}>
          {children}
        </Panel>
      )}
    </TransitionModal>
  )
}

export const fadeIn = keyframes`
  from { 
    opacity: 0; 
    transform: translateY(50vh);
  }
  to { 
    opacity: 1; 
    transform: translateY(0);
  }
`
export const fadeOut = keyframes`
  from { 
    opacity: 1; 
    transform: translateY(0);
  }
  to { 
    opacity: 0; 
    transform: translateY(-50vh);
  }
`

const FadeWrapper = styled.div<{ fadeOut?: boolean }>`
  pointer-events: auto;
  animation: ${(p) =>
    p.fadeOut
      ? css`
          ${fadeOut} ${MODAL_FADE_OUT_TIME - 50}ms cubic-bezier(.4,-0.4,.8,.5) 1 normal forwards
        `
      : css`
          ${fadeIn} ${MODAL_FADE_IN_TIME}ms cubic-bezier(0.3, 0.6, 0.2, 1) 1 normal forwards
        `};
`

export const MobileMessageModal: React.FC<
  Omit<ModalProps, 'children'> & {
    panelStyle?: CSSProperties
    panelName?: string
  }
> = ({ children, ...props }) => (
  <MobileModalPanel {...props}>
    <Padding size="m" style={{ paddingTop: 25, paddingBottom: 25 }}>
      <div style={{ textAlign: 'center' }}>{children}</div>
    </Padding>
  </MobileModalPanel>
)
