import { useEffect } from 'react'

import Sound from 'app/Sound'
import { useSoundPlaying } from './useSoundPlaying'
import { useGlobalState } from 'app/GlobalState'
import { useSound } from './useSound'

let lastPlayingSound: Sound

export function useArdon(sound: Sound, repeatable: boolean = true): Sound {
  const { muted, setArdonSpeaking, setRepeatAvailable, repeatRequested, setRepeatRequested } = useGlobalState()
  const speaking = useSoundPlaying(sound)

  useEffect(() => {
    setArdonSpeaking(speaking)
    if (speaking) {
      lastPlayingSound = sound
    }
    return () => {
      if (sound === lastPlayingSound) {
        setArdonSpeaking(false)
      }
    }
  }, [sound, speaking, repeatable, setArdonSpeaking])

  useEffect(() => {
    sound.mute(muted)
  }, [sound, muted])

  useEffect(() => {
    if (sound === lastPlayingSound) {
      setRepeatAvailable(!speaking && repeatable)
    }
    return () => {
      if (sound === lastPlayingSound) {
        setRepeatAvailable(false)
      }
    }
  }, [sound, speaking, repeatable, setRepeatAvailable])

  useEffect(() => {
    if (repeatRequested && sound === lastPlayingSound) {
      sound.play()
      setRepeatRequested(false)
    }
  }, [sound, repeatRequested, setRepeatRequested])

  return sound
}

export const Ardon: React.FC<{ sound: Sound; repeatable?: boolean }> = ({ sound, repeatable }) => {
  useArdon(sound, repeatable)
  return null
}

export const ArdonNarrate: React.FC<{ src: string; repeatable?: boolean }> = ({ src, repeatable }) => {
  useArdon(useSound(src), repeatable)
  return null
}
