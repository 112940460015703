import React, { useState, useEffect, useRef, CSSProperties } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import TouchBackend from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import { blue } from 'themes'

import {
  EmbeddedActivitySection,
  SectionPropsBase,
  ActivityScavengerHuntState,
  ScavengerHuntCard,
  ScavengerHuntSceneObjectKey as SceneObjectKey,
  ParticipantState,
} from 'shared/session/types'
import { UPDATE_SECTION_STATE_DANGEROUS, DISPATCH_SECTION_STATE } from 'shared/session/actionTypes'
import {
  ActionTypes as ScavengerHuntActionTypes,
  ADD_ATTEMPT,
  NEXT_CLUE,
  NEXT_SCREEN,
  PLACE_CARD,
  UPDATE_FEEDBACK_TEXT,
  CORRECT_CARD_SELECTED,
  UPDATE_FLIPPED_CARD,
  SHOW_RESULT_MODAL,
  HIDE_RESULT_MODAL,
  UPDATE_ACTIVE_PARTICIPANT,
  UPDATE_SCENE_OBJECT_HOVER,
} from 'shared/session/sections/custom/scavenger-hunt/actionTypes'
import { images, thumbnails } from './assets'
import { clues } from 'shared/session/sections/custom/scavenger-hunt/clues'
import { cards } from 'shared/session/sections/custom/scavenger-hunt/cards'
import { sceneObjectKeys, sceneObjects } from './sceneObjects'

import { Container, Column, Row, Spacer, Button, Padding, H3, P } from 'common/ui'
import { MobileMessageModal, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { InteractiveSceneObject } from './SceneObject'
import { StaticCard, DraggableCard, CardProps } from './Card'
import { DroppableCardArea } from './CardArea'
import { CustomDragLayer } from './CustomDragLayer'

import { preloadImage } from 'utils/preloadUtils'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { getTouchBackendOptions } from 'utils/touchUtils'
import { CardModal } from './CardModal'

import { useSessionState } from 'session/SessionState'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { useSectionState, useSectionStateWithFallback } from 'session/hooks/useSectionState'

import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { RadioButton } from 'session/common/RadioButton'
import { getInitialState } from '../../../../shared/session/sections/custom/scavenger-hunt/reducer'
import { SVG } from 'common/SVG'
import { fontBold } from 'fonts'
import { Notification } from '../../../common/Notification'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'

interface Props extends SectionPropsBase {
  property: 'embedded_activity_sections'
}

interface SectionProps {
  section: Props['section']
  property: Props['property']
  trainingState?: ParticipantState
}

const incorrectMessages = ['Almost!', 'Keep looking!', 'So close!', 'Have another go!', 'Not quite!']

export const ScavengerHunt: React.FC<SectionProps> = ({ section, trainingState }) => {
  const focusedParticipantState = useFocusedParticipantState()
  const participantState = trainingState ? trainingState : focusedParticipantState

  const { dispatch, isFacilitator, getBaseAction } = useSessionState()
  const initedState = useRef<boolean>(false)

  let sectionState = useSectionState('activity_scavenger_hunt', section)

  if (!sectionState) {
    sectionState = getInitialState()
    if (!initedState.current) {
      initedState.current = true
      dispatch({
        ...getBaseAction(),
        type: UPDATE_SECTION_STATE_DANGEROUS, // is this dangerous???
        property: 'activity_scavenger_hunt',
        section_id: section.id,
        state: sectionState,
        initial: true,
      })
    }
  }

  const userActive = participantState && sectionState.activeProfileUid === participantState.profile.uid
  const interactive = isFacilitator || userActive || !!trainingState

  const wrapperStyle: CSSProperties = {
    alignSelf: 'center',
    flex: 'none',
    width: '100%',
  }

  return (
    <ScavengerHuntWrapper interactive={interactive} style={wrapperStyle}>
      <DndProvider backend={TouchBackend} options={getTouchBackendOptions()}>
        <ThemeProvider theme={blue}>
          {sectionState.showScavenger && (
            <ScavengerHuntBackground>
              <ScavengerHuntApp
                section={section}
                sectionState={sectionState}
                interactive={interactive}
                isFacilitator={trainingState ? true : isFacilitator}
              />
            </ScavengerHuntBackground>
          )}
          {!sectionState.showScavenger && (
            <DecoderAppBackground>
              <DecoderApp
                section={section}
                sectionState={sectionState}
                interactive={interactive}
                isFacilitator={trainingState ? true : isFacilitator}
              />
            </DecoderAppBackground>
          )}
        </ThemeProvider>
      </DndProvider>
    </ScavengerHuntWrapper>
  )
}

const ScavengerHuntApp: React.FC<{
  section: EmbeddedActivitySection
  sectionState: ActivityScavengerHuntState
  interactive: boolean
  isFacilitator: boolean
}> = ({ section, sectionState, interactive, isFacilitator }) => {
  const [, forceRender] = useState(0)
  const [showCompletedModal, setShowCompletedModal] = useState<boolean>(false)
  const [showFullScene, setShowFullScene] = useState<boolean>(false)

  const { dispatch, getBaseAction, pastMode } = useSessionState()

  const clue = clues[Math.min(sectionState.clueIndex, clues.length - 1)]
  const clueAttempts = sectionState.attempts.filter(({ clueIndex }) => clueIndex === sectionState.clueIndex)

  const incorrectCountRef = useRef(0)

  // Force re-render after initial render because UI that relies on refs have to display
  useEffect(() => forceRender(i => i + 1), [])

  useEffect(() => {
    Promise.all(Object.keys(images).map(key => preloadImage(images[key as keyof typeof images]))).catch(() => {})
  }, [])

  useEffect(() => {
    if (sectionState.moveStep === 'completed') {
      setShowFullScene(true)
      setTimeout(() => {
        setShowCompletedModal(true)
      }, 3200)
    }
  }, [sectionState.moveStep])

  const dispatchSectionState = (action: ScavengerHuntActionTypes) =>
    dispatch({
      ...getBaseAction(),
      type: DISPATCH_SECTION_STATE,
      property: 'activity_scavenger_hunt',
      section_id: section.id,
      action,
    })

  const handleObjectSelected = (objectKey: SceneObjectKey) => {
    const correct = clue.solution === objectKey
    dispatchSectionState({
      type: ADD_ATTEMPT,
      clueIndex: sectionState.clueIndex,
      attempt: clueAttempts.length,
      objectKey,
      correct,
    })

    if (!correct) {
      incorrectCountRef.current++
      dispatchSectionState({
        type: UPDATE_FEEDBACK_TEXT,
        feedbackText: incorrectMessages[incorrectCountRef.current % incorrectMessages.length],
      })
    } else {
      dispatchSectionState({
        type: CORRECT_CARD_SELECTED,
        correctCard: cards[sectionState.jumbledCardIndexes[sectionState.clueIndex]],
      })
    }
  }

  const handleSceneObjectHover = (objectKey: SceneObjectKey | null) => {
    dispatchSectionState({
      type: UPDATE_SCENE_OBJECT_HOVER,
      sceneObject: objectKey,
    })
  }

  const handleCorrectConfirmed = () => dispatchSectionState({ type: NEXT_CLUE })

  const handleConfirmCompleted = () => {
    setShowCompletedModal(false)
    dispatchSectionState({ type: NEXT_SCREEN })
  }

  return (
    <>
      <Column flex="auto" style={{ maxWidth: '100%' }}>
        <div style={{ flex: 'none', background: 'white' }}>
          <Container size="l">
            <Padding size="s">
              <Row style={{ width: '100%' }} alignItems="center">
                <Column flex="1 1 auto">
                  {!isFacilitator && <TurnIndicator section={section} userActive={interactive} />}
                </Column>
              </Row>
            </Padding>
          </Container>
        </div>
        <Column flex="1 1 auto" alignItems="center">
          <SceneContainer style={{ width: '100%', paddingTop: '56.25%', height: 0 }}>
            <SceneFullSizeImage imageUrl={images.sceneBW} />
            <SceneFullSizeImage imageUrl={images.sceneColor} style={{ opacity: showFullScene ? 1 : 0 }} />
            {sceneObjectKeys.map(key => (
              <InteractiveSceneObject
                key={key}
                interactive={interactive && !pastMode}
                active={!!sectionState.attempts.find(attempt => attempt.correct && attempt.objectKey === key)}
                sceneObject={sceneObjects[key]}
                onSelect={() => handleObjectSelected(key)}
                onHover={exit => handleSceneObjectHover(!exit ? key : null)}
                isHovered={sectionState.hoveredSceneItem === key}
              />
            ))}
            <Notification
              feedbackText={sectionState.feedbackText}
              timeout={3000}
              style={{ width: 'auto', paddingLeft: 48 }}
            />
          </SceneContainer>
        </Column>
        <div style={{ flex: 'none', background: 'white' }}>
          <Container size="l">
            <Padding size="s">
              <Row style={{ width: '100%' }} alignItems="center">
                <ClueBox
                  flex="1 1 auto"
                  justifyContent="center"
                  style={{ padding: '0 20px', opacity: sectionState.moveStep === 'completed' ? 0 : 1 }}>
                  <H3>{clue && clue.title}</H3>
                  <P>{clue && clue.description}</P>
                </ClueBox>
                {[...Array(7)].map((_, i) => (
                  <React.Fragment key={i}>
                    <Spacer size="s" />
                    <ClueBox active={sectionState.clueIndex === i && sectionState.moveStep !== 'completed'}>
                      {sectionState.clueIndex > i && (
                        <img
                          src={thumbnails[`card${sectionState.jumbledCardIndexes[i] + 1}` as 'card1']}
                          alt=""
                          width="100%"
                        />
                      )}
                    </ClueBox>
                  </React.Fragment>
                ))}
              </Row>
            </Padding>
          </Container>
        </div>
      </Column>
      {/* Modal gets rendered on both props changing -- causes adverse effects on display of user who initiated - but is fine on shared users */}
      {sectionState.showCorrectModal && (
        <CorrectClueModal
          isOpen={sectionState.showCorrectModal}
          onClose={() => {}}
          onConfirm={handleCorrectConfirmed}
          card={sectionState.correctCard}
          isInteractive={interactive}
        />
      )}
      {showCompletedModal && (
        <CompletedModal isOpen={showCompletedModal} onClose={handleConfirmCompleted} isInteractive={isFacilitator} />
      )}
    </>
  )
}

const TurnIndicator: React.FC<{ userActive: boolean; section: SectionProps['section'] }> = ({
  userActive,
  section,
}) => {
  return (
    <>
      {!userActive && (
        <TurnIndicatorWrapper color="#CF1110">
          <SVG width={22} height={28} viewboxWidth={22} viewboxHeight={28}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.09091 6.81818V10.9091H2.72727C1.27043 10.9091 0 11.9678 0 13.4091V24.7727C0 26.214 1.27043 27.2727 2.72727 27.2727H19.0909C20.5478 27.2727 21.8182 26.214 21.8182 24.7727V13.4091C21.8182 11.9678 20.5478 10.9091 19.0909 10.9091H17.7273V6.81818C17.7273 3.0526 14.6747 0 10.9091 0C7.14351 0 4.09091 3.0526 4.09091 6.81818ZM15 6.81818V10.9091H6.81818V6.81818C6.81818 4.55884 8.64974 2.72727 10.9091 2.72727C13.1684 2.72727 15 4.55884 15 6.81818ZM2.72727 24.5447V13.6357H19.0909V24.5447H2.72727ZM12.2727 19.0909C12.2727 19.844 11.6622 20.4545 10.9091 20.4545C10.156 20.4545 9.54545 19.844 9.54545 19.0909C9.54545 18.3378 10.156 17.7273 10.9091 17.7273C11.6622 17.7273 12.2727 18.3378 12.2727 19.0909Z"
              fill="#CF1110"
            />
          </SVG>
          {section.scavenger_hunt_wait_text || 'Please wait your turn.'}
        </TurnIndicatorWrapper>
      )}
      {userActive && (
        <TurnIndicatorWrapper color="#4EBE40">
          <SVG width={22} height={30} viewboxWidth={22} viewboxHeight={30}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.09091 13.6364V6.81818C4.09091 3.0526 7.14351 0 10.9091 0C14.6747 0 17.7273 3.0526 17.7273 6.81818V9.54545H15V6.81818C15 4.55884 13.1684 2.72727 10.9091 2.72727C8.64974 2.72727 6.81818 4.55884 6.81818 6.81818V13.6364H19.0909C20.5971 13.6364 21.8182 14.8574 21.8182 16.3636V27.2727C21.8182 28.779 20.5971 30 19.0909 30H2.72727C1.22104 30 0 28.779 0 27.2727V16.3636C0 14.8574 1.22104 13.6364 2.72727 13.6364H4.09091ZM2.72732 27.2724V16.3633H19.091V27.2724H2.72732ZM12.2727 21.8182C12.2727 22.5713 11.6622 23.1818 10.9091 23.1818C10.156 23.1818 9.54545 22.5713 9.54545 21.8182C9.54545 21.0651 10.156 20.4545 10.9091 20.4545C11.6622 20.4545 12.2727 21.0651 12.2727 21.8182Z"
              fill="#4EBE40"
            />
          </SVG>
          {section.scavenger_hunt_ready_text || 'Your turn!'}
        </TurnIndicatorWrapper>
      )}
    </>
  )
}

const DecoderApp: React.FC<{
  section: EmbeddedActivitySection
  sectionState: ActivityScavengerHuntState
  interactive: boolean
  isFacilitator: boolean
}> = ({ section, sectionState, interactive, isFacilitator }) => {
  const { dispatch, getBaseAction, pastMode } = useSessionState()
  const incorrectCountRef = useRef(0)

  if (!sectionState) return null // throw new Error('No sectionState in DecoderApp')
  if (!sectionState.cardIndexes) return null //throw new Error('No sectionState.cardIndexes')

  const usedCardIndexes = Object.values(sectionState.cardIndexes).filter(v => v !== null)
  const currentWord = Object.values(sectionState.cardIndexes)
    .map(cardIndex => (cardIndex === null ? '_' : cards[cardIndex].letter))
    .join('')

  const isCorrectWord = currentWord === 'decoder'
  const wrongLetterOrder =
    isCorrectWord &&
    usedCardIndexes.length === 7 &&
    ((sectionState.cardIndexes[0] === 4 && sectionState.cardIndexes[4] === 0) ||
      (sectionState.cardIndexes[1] === 5 && sectionState.cardIndexes[5] === 1))

  const dispatchSectionState = (action: ScavengerHuntActionTypes) =>
    dispatch({
      ...getBaseAction(),
      type: DISPATCH_SECTION_STATE,
      property: 'activity_scavenger_hunt',
      section_id: section.id,
      action,
    })

  const displayFlippedCard = (card: ScavengerHuntCard) =>
    dispatchSectionState({
      type: UPDATE_FLIPPED_CARD,
      flippedCard: card,
    })

  const handleCardDrop = (index: number, item: any) =>
    dispatchSectionState({
      type: PLACE_CARD,
      index,
      cardId: (item.props as CardProps).card.id,
    })

  const handleSubmit = () => {
    if (isCorrectWord && !wrongLetterOrder) {
      dispatchSectionState({ type: SHOW_RESULT_MODAL, correct: true })
    } else if (wrongLetterOrder || currentWord === 'recoded') {
      dispatchSectionState({ type: SHOW_RESULT_MODAL, correct: false })
    } else {
      incorrectCountRef.current++
      dispatchSectionState({
        type: UPDATE_FEEDBACK_TEXT,
        feedbackText: incorrectMessages[incorrectCountRef.current % incorrectMessages.length],
      })
    }
  }

  const handleCardDismiss = () => dispatchSectionState({ type: UPDATE_FLIPPED_CARD, flippedCard: null })

  const handleIncorrectSubmit = () => dispatchSectionState({ type: HIDE_RESULT_MODAL, correct: false })

  const handleCorrectSubmit = () => dispatchSectionState({ type: HIDE_RESULT_MODAL, correct: true })

  return (
    <>
      <CustomDragLayer />
      <Column flex="auto" style={{ maxWidth: '100%' }}>
        <div style={{ flex: 'none' }}>
          <Container size="l">
            <Padding size="m">
              <Row style={{ width: '100%' }} alignItems="flex-start">
                <Column flex="1 1 auto">
                  {!isFacilitator && <TurnIndicator section={section} userActive={interactive && !pastMode} />}
                  <P>
                    Click and drag cards into place.
                    <br />
                    Click card to see the back.
                  </P>
                </Column>
              </Row>
            </Padding>
          </Container>
        </div>
        <Column flex="1 1 auto" alignItems="center" justifyContent="center">
          <Notification feedbackText={sectionState.feedbackText} timeout={3000} />
          <Row>
            {intersperseSpacers(
              [...Array(7)].map((_, i) => {
                const cardIndex = sectionState.jumbledCardIndexes[i]
                const card = cards[cardIndex]
                return (
                  <DraggableCard
                    key={i}
                    card={card}
                    onMove={() => {}}
                    disabled={usedCardIndexes.indexOf(cardIndex) >= 0}
                    onSelect={() => displayFlippedCard(card)}
                  />
                )
              }),
              'm'
            )}
          </Row>
          <Spacer size="l" />
          <Row>
            {intersperseSpacers(
              [...Array(7)].map((_, i) => {
                const cardIndex = sectionState.cardIndexes[i]
                const card = cardIndex !== null ? cards[cardIndex] : undefined
                return (
                  <DroppableCardArea
                    key={i}
                    onDrop={item => handleCardDrop(i, item)}
                    card={card}
                    highlight={card && sectionState.flippedCard !== null && card.id === sectionState.flippedCard.id}
                    onSelect={card ? () => displayFlippedCard(card) : undefined}
                  />
                )
              }),
              'm'
            )}
          </Row>
          <Spacer size="l" />
          <Row justifyContent="center">
            <Button
              size="m"
              onClick={handleSubmit}
              disabled={!usedCardIndexes.length}
              flashing={usedCardIndexes.length === 7 && !sectionState.showCompleteModal}
              style={{ width: 180 }}
              children="Submit"
            />
          </Row>
        </Column>
        <div style={{ flex: 'none', height: 120 }} />
      </Column>
      <CardModal
        isOpen={sectionState.flippedCard !== null}
        onClose={handleCardDismiss}
        card={sectionState.flippedCard}
        interactive={interactive}
      />
      <CorrectCodeModal
        isInteractive={isFacilitator}
        isOpen={sectionState.showCompleteModal}
        onClose={() => (isFacilitator ? handleCorrectSubmit() : () => {})}
      />
      <IncorrectModal
        isOpen={sectionState.showIncorrectModal}
        onClose={handleIncorrectSubmit}
        message={
          wrongLetterOrder
            ? `So close! The word has been solved but check the order of the D's and E's...`
            : currentWord === 'recoded'
            ? `Nice! you found the only anagram of the actual answer ;)`
            : undefined
        }
      />
    </>
  )
}

interface BaseModalProps {
  isOpen: boolean
  onClose: () => void
  isInteractive?: boolean
}

const IncorrectModal: React.FC<BaseModalProps & { message?: string }> = ({ isOpen, onClose, message = 'Almost!' }) => (
  <MobileMessageModal isOpen={isOpen} onRequestClose={onClose}>
    <P style={{ margin: 0, fontSize: '1.2em', textAlign: 'center' }}>{message}</P>
    <Spacer size="m" />
    <Button onClick={onClose} children="Try Again" />
  </MobileMessageModal>
)

const CorrectClueModal: React.FC<BaseModalProps & { card: ScavengerHuntCard | null; onConfirm: () => void }> = ({
  isOpen: _isOpen,
  onClose,
  onConfirm,
  isInteractive,
  card,
}) => {
  const cardRef = useRef<ScavengerHuntCard | null>(card)
  useEffect(() => {
    if (card && cardRef.current?.id !== card.id) cardRef.current = card
  }, [card])

  /** Unsure below */
  const [isOpen, setIsOpen] = useState<boolean>(_isOpen)
  const closeScheduled = useRef<boolean>(false)
  useEffect(() => {
    setIsOpen(_isOpen)
    if (_isOpen) closeScheduled.current = false
  }, [_isOpen])
  const delayedOnClose = () => {
    if (closeScheduled.current) return
    setIsOpen(false)
    closeScheduled.current = true
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  /** Unsure above */
  const handleConfirm = () => {
    onConfirm()
    delayedOnClose()
  }

  return (
    <MobileMessageModal isOpen={isOpen} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={false}>
      <P style={{ margin: 0, textAlign: 'center', fontSize: '1.2em' }}>You found a card!</P>
      <Spacer size="m" />
      {cardRef.current && (
        <>
          <StaticCard card={cardRef.current} width={110} style={{ display: 'inline-block' }} />
          <Spacer size="m" />
        </>
      )}
      <Row justifyContent="center">
        {isInteractive && <Button onClick={handleConfirm} children="Add to Collection" />}
      </Row>
    </MobileMessageModal>
  )
}

const CorrectCodeModal: React.FC<BaseModalProps> = ({ isOpen, onClose, isInteractive }) => (
  <MobileMessageModal
    isOpen={isOpen}
    onRequestClose={onClose}
    panelStyle={{ width: 420 }}
    shouldCloseOnOverlayClick={isInteractive}
    shouldCloseOnEsc={isInteractive}>
    <P style={{ margin: 0, fontSize: '1.2em', textAlign: 'center' }}>
      Well done! D.E.C.O.D.E.R. is a formula to help you solve social problems.
    </P>
    <Spacer size="m" />
    {isInteractive && <Button onClick={onClose} children="Close" />}
  </MobileMessageModal>
)

const CompletedModal: React.FC<BaseModalProps> = ({ isOpen, onClose, isInteractive }) => (
  <MobileMessageModal
    isOpen={isOpen}
    onRequestClose={onClose}
    panelStyle={{ width: 420 }}
    shouldCloseOnOverlayClick={isInteractive}
    shouldCloseOnEsc={isInteractive}>
    <P style={{ margin: 0, fontSize: '1.2em', textAlign: 'center' }}>
      Congratulations cadets, you have found all the cards. Now, reorder the letters below to decipher the secret code.
    </P>
    <Spacer size="m" />
    {isInteractive && <Button onClick={onClose} children="Start" />}
  </MobileMessageModal>
)

export const ScavengerHuntFacilitator: React.FC<Props> = ({ property, section, index }) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback('activity_scavenger_hunt', section, getInitialState)

  const dispatchSectionState = (action: ScavengerHuntActionTypes) =>
    dispatch({
      ...getBaseAction(),
      type: DISPATCH_SECTION_STATE,
      property: 'activity_scavenger_hunt',
      section_id: section.id,
      action,
    })

  return (
    <>
      <ActivityInteractionIndicator type="shared" />
      <FacilitatorControls title="Turn Controls">
        <Button
          style={{ position: 'absolute', right: 20, top: 10 }}
          size="s"
          onClick={() =>
            dispatch({
              ...getBaseAction(),
              type: UPDATE_SECTION_STATE_DANGEROUS,
              property: 'activity_scavenger_hunt',
              section_id: section.id,
              state: getInitialState(),
            })
          }
          children="RESET"
        />
        <Spacer />
        <FacilitatorControlsTable>
          <thead>
            <tr>
              <th>Facilitator Only</th>
              {state.participants.map((participant, i) => (
                <th key={i}>{participant.profile.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '10px 0' }}>
                <RadioButton
                  value={sectionState.activeProfileUid === null}
                  onSelect={() => dispatchSectionState({ type: UPDATE_ACTIVE_PARTICIPANT, activeProfileUid: null })}
                />
              </td>
              {state.participants.map((participant, i) => (
                <td key={i} style={{ padding: '10px' }}>
                  <RadioButton
                    value={sectionState.activeProfileUid === participant.profile.uid}
                    onSelect={() =>
                      dispatchSectionState({
                        type: UPDATE_ACTIVE_PARTICIPANT,
                        activeProfileUid: participant.profile.uid,
                      })
                    }
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </FacilitatorControlsTable>
      </FacilitatorControls>
      <ScavengerHunt section={section} property={property} />
    </>
  )
}

const ScavengerHuntWrapper = styled.div<{ interactive: boolean }>`
  flex: 1 0 auto;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #cdd2e4;
  pointer-events: ${p => (p.interactive ? 'auto' : 'none')};
  > div {
    width: 100%;
  }
`

const TurnIndicatorWrapper = styled.p<{ color: string }>`
  ${fontBold}
  color: ${p => p.color};
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 0;

  svg {
    margin: 0 15px;
  }
`

const ScavengerHuntBackground = styled.div`
  flex: auto;
  display: flex;
  background-color: #fff;
`

const SceneContainer = styled.div`
  position: relative;
  height: 100%;
`

const SceneFullSizeImage = styled.div<{ imageUrl: any }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('${p => p.imageUrl}');
  background-size: auto 100%;
  background-position: center top;
  background-repeat: no-repeat;
  transition: opacity 1.5s linear;
`

const ClueBox = styled(Column)<{ active?: boolean }>`
  height: 90px;
  width: 60px;
  border-radius: 10px;
  overflow: hidden;
  transition: opacity 0.35s linear;

  & ${H3}, ${P} {
    margin: 0;
  }

  & ${H3} {
    font-size: 16px;
  }

  & ${P} {
    font-size: 14px;
    line-height: 1;
    @media (max-width: 840px) {
      font-size: 12px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: 10px;
    box-shadow: inset 0 0 0 ${p => (p.active ? 5 : 1)}px #22acf3;
  }
`

const DecoderAppBackground = styled.div`
  flex: auto;
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
  background-image: url('${images.background}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
`
