import React from 'react'
import { SectionProperty, SectionPropsBase, FacManModuleStep } from '../shared/fac-man/types'
import { getSections } from './reducers'
import { GenericDividerSection } from '../session/sections/Divider'
import { GenericListSection, ListContainer } from '../session/sections/List'
import { GenericGridSection } from '../session/sections/MediaGrid'
import { GenericTextSection } from '../session/sections/Text'
import { GenericDownloadResourceSection } from 'session/sections/DownloadResourceSection'
import { QuoteSection } from 'fac-man/sections/QuoteSection'
import { TableSection } from 'fac-man/sections/TableSection'
import { HeadingSection } from 'fac-man/sections/HeadingSection'
import { PanelSection } from './sections/PanelSection'
import styled from 'styled-components'
import { Container, H1 } from 'common/ui'
import { fontBold, fontRegular } from 'fonts'
import { RichTextContainer } from 'session/common/RichText'

export const sectionComponents: {
  [key in SectionProperty]: React.FC<SectionPropsBase & { property: key }>
} = {
  divider_sections: GenericDividerSection,
  list_sections: GenericListSection,
  media_grid_sections: GenericGridSection,
  text_sections: GenericTextSection,
  heading_sections: HeadingSection,
  quote_sections: QuoteSection,
  table_sections: TableSection,
  panel_sections: PanelSection,
  download_resource_sections: GenericDownloadResourceSection,
}

export const FacManStep: React.FC<{ step: FacManModuleStep }> = ({ step }) => {
  const sections = getSections(step)
  return (
    <Wrapper style={{ position: 'relative' }}>
      {step.title && !step.hide_header && (
        <Heading>
          <H1>{step.title}</H1>
          {step.estimated_time && <Time>{step.estimated_time.toUpperCase()}</Time>}
        </Heading>
      )}
      {step.subheading && <SubHeading>{step.subheading}</SubHeading>}
      {sections
        .map((sectionObj, i) => {
          const Component = sectionComponents[sectionObj.property]
          // @ts-ignore
          return <Component key={i} {...sectionObj} />
        })
        .map((component, idx) => {
          return (
            <Container
              marginBottom={component.props.property === 'heading_sections' ? 0 : 15}
              key={idx}
              children={component}
            />
          )
        })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border-left: 10px solid ${p => p.theme.buttonBorderTopColor};

  ${ListContainer}, ${RichTextContainer} {
    ol {
      padding-left: 15px;
      li {
        list-style: decimal;
        padding-left: 5px;

        &:before {
          display: none;
        }
      }
    }
    ul li {
      &::before {
        top: 4px;
        height: 1.15em;
        background: url(${require('session/assets/list-bullet-purple.svg')?.default}) no-repeat right top;
      }
    }
  }

  ${Container}:last-of-type {
    margin-bottom: 0;
  }
`

const Heading = styled.div`
  color: #fff;
  background: #925bed;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;

  ${H1} {
    color: #fff;
    font-size: 1.5rem;
  }
`

const SubHeading = styled.p`
  ${fontBold};
  margin: 0 0 15px;
  color: #813aba;
`

const Time = styled.p`
  ${fontRegular};
  color: #fff;
  font-size: 1.2rem;
  margin: 0;
`
