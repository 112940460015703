import React, { ReactNode, CSSProperties } from 'react'
import styled from 'styled-components'

import { Panel, Row, Spacer, H2 } from 'common/ui'
import { SVG } from 'common/SVG'
import { fontBold } from 'fonts'

export const interactionTypes = ['individual', 'group', 'shared'] as const
export type InteractionType = (typeof interactionTypes)[number]

const interactionOptions: {
  [key in InteractionType]: { label: string; iconSize: [number, number]; icon: ReactNode }
} = {
  individual: {
    label: 'Individual Activity',
    iconSize: [22, 22],
    icon: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22ZM18.3995 16.1246C19.4086 14.6703 20 12.9042 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 12.9042 2.59138 14.6703 3.6005 16.1246C4.72595 14.6381 7.3706 14 11 14C14.6294 14 17.274 14.6381 18.3995 16.1246ZM16.9647 17.7398C16.672 16.6874 14.5694 16 11 16C7.43062 16 5.328 16.6874 5.03532 17.7398C6.6233 19.1462 8.71194 20 11 20C13.2881 20 15.3767 19.1462 16.9647 17.7398ZM11 14C8.76086 14 7 12.4274 7 9C7 6.75576 8.5791 5 11 5C13.4142 5 15 6.92158 15 9.2C15 12.4796 13.2181 14 11 14ZM9 9C9 11.2693 9.81821 12 11 12C12.1777 12 13 11.2984 13 9.2C13 7.95042 12.2157 7 11 7C9.73374 7 9 7.81582 9 9Z"
        fill="#011A46"
      />
    ),
  },
  group: {
    label: 'Group Activity',
    iconSize: [38, 22],
    icon: (
      <>
        <circle cx="11" cy="11" r="9" fill="#EAE0FC" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22ZM18.3995 16.1246C19.4086 14.6703 20 12.9042 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 12.9042 2.59138 14.6703 3.6005 16.1246C4.72595 14.6381 7.3706 14 11 14C14.6294 14 17.274 14.6381 18.3995 16.1246ZM16.9647 17.7398C16.672 16.6874 14.5694 16 11 16C7.43062 16 5.328 16.6874 5.03532 17.7398C6.6233 19.1462 8.71194 20 11 20C13.2881 20 15.3767 19.1462 16.9647 17.7398ZM11 14C8.76086 14 7 12.4274 7 9C7 6.75576 8.5791 5 11 5C13.4142 5 15 6.92158 15 9.2C15 12.4796 13.2181 14 11 14ZM9 9C9 11.2693 9.81821 12 11 12C12.1777 12 13 11.2984 13 9.2C13 7.95042 12.2157 7 11 7C9.73374 7 9 7.81582 9 9Z"
          fill="#011A46"
        />
        <circle cx="27" cy="11" r="9" fill="#EAE0FC" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 22C20.9249 22 16 17.0751 16 11C16 4.92487 20.9249 0 27 0C33.0751 0 38 4.92487 38 11C38 17.0751 33.0751 22 27 22ZM34.3995 16.1246C35.4086 14.6703 36 12.9042 36 11C36 6.02944 31.9706 2 27 2C22.0294 2 18 6.02944 18 11C18 12.9042 18.5914 14.6703 19.6005 16.1246C20.726 14.6381 23.3706 14 27 14C30.6294 14 33.274 14.6381 34.3995 16.1246ZM32.9647 17.7398C32.672 16.6874 30.5694 16 27 16C23.4306 16 21.328 16.6874 21.0353 17.7398C22.6233 19.1462 24.7119 20 27 20C29.2881 20 31.3767 19.1462 32.9647 17.7398ZM27 14C24.7609 14 23 12.4274 23 9C23 6.75576 24.5791 5 27 5C29.4142 5 31 6.92158 31 9.2C31 12.4796 29.2181 14 27 14ZM25 9C25 11.2693 25.8182 12 27 12C28.1777 12 29 11.2984 29 9.2C29 7.95042 28.2157 7 27 7C25.7337 7 25 7.81582 25 9Z"
          fill="#011A46"
        />
      </>
    ),
  },
  shared: {
    label: 'Shared Activity',
    iconSize: [64, 24],
    icon: (
      <>
        <circle cx="11" cy="12" r="9" fill="#EAE0FC" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 23C4.92487 23 0 18.0751 0 12C0 5.92487 4.92487 1 11 1C17.0751 1 22 5.92487 22 12C22 18.0751 17.0751 23 11 23ZM18.3995 17.1246C19.4086 15.6703 20 13.9042 20 12C20 7.02944 15.9706 3 11 3C6.02944 3 2 7.02944 2 12C2 13.9042 2.59138 15.6703 3.6005 17.1246C4.72595 15.6381 7.3706 15 11 15C14.6294 15 17.274 15.6381 18.3995 17.1246ZM16.9647 18.7398C16.672 17.6874 14.5694 17 11 17C7.43062 17 5.328 17.6874 5.03532 18.7398C6.6233 20.1462 8.71194 21 11 21C13.2881 21 15.3767 20.1462 16.9647 18.7398ZM11 15C8.76086 15 7 13.4274 7 10C7 7.75576 8.5791 6 11 6C13.4142 6 15 7.92158 15 10.2C15 13.4796 13.2181 15 11 15ZM9 10C9 12.2693 9.81821 13 11 13C12.1777 13 13 12.2984 13 10.2C13 8.95042 12.2157 8 11 8C9.73374 8 9 8.81582 9 10Z"
          fill="#011A46"
        />
        <circle cx="27" cy="12" r="9" fill="#EAE0FC" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 23C20.9249 23 16 18.0751 16 12C16 5.92487 20.9249 1 27 1C33.0751 1 38 5.92487 38 12C38 18.0751 33.0751 23 27 23ZM34.3995 17.1246C35.4086 15.6703 36 13.9042 36 12C36 7.02944 31.9706 3 27 3C22.0294 3 18 7.02944 18 12C18 13.9042 18.5914 15.6703 19.6005 17.1246C20.726 15.6381 23.3706 15 27 15C30.6294 15 33.274 15.6381 34.3995 17.1246ZM32.9647 18.7398C32.672 17.6874 30.5694 17 27 17C23.4306 17 21.328 17.6874 21.0353 18.7398C22.6233 20.1462 24.7119 21 27 21C29.2881 21 31.3767 20.1462 32.9647 18.7398ZM27 15C24.7609 15 23 13.4274 23 10C23 7.75576 24.5791 6 27 6C29.4142 6 31 7.92158 31 10.2C31 13.4796 29.2181 15 27 15ZM25 10C25 12.2693 25.8182 13 27 13C28.1777 13 29 12.2984 29 10.2C29 8.95042 28.2157 8 27 8C25.7337 8 25 8.81582 25 10Z"
          fill="#011A46"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49 19V20H47V22H57V20H55V19H61C62.1046 19 63 18.1046 63 17V4C63 2.89543 62.1046 2 61 2H43C41.8954 2 41 2.89543 41 4V17C41 18.1046 41.8954 19 43 19H49ZM61 17H43V4H61V17Z"
          fill="#011A46"
        />
      </>
    ),
  },
}

export const ActivityInteractionIndicator: React.FC<{ type: InteractionType; style?: CSSProperties }> = ({
  type,
  style,
}) => {
  const option = interactionOptions[type]
  return (
    <ActivityInteractionIndicatorPanel style={style}>
      <Row alignItems="center" justifyContent="center">
        <SVG
          width={option.iconSize[0]}
          height={option.iconSize[1]}
          viewboxWidth={option.iconSize[0]}
          viewboxHeight={option.iconSize[1]}
          children={option.icon}
        />
        <Spacer size="s" />
        <H2>{option.label}</H2>
      </Row>
    </ActivityInteractionIndicatorPanel>
  )
}

export const ActivityInteractionIndicatorPanel = styled(Panel)`
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid #7d41df;
  background-color: #e8dbfa;
  box-shadow: 0px 1px 0 0 white;

  & ${H2} {
    ${fontBold}
    letter-spacing: 0.03em;
  }
`
