import React, { useState } from 'react'
import styled from 'styled-components'
import { IdScreen, BigRedText } from 'gadget-pack/ID'
import { Column, Padding, CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L, RowWrapPortrait, Button } from 'common/ui'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { useProgressionEventsState } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK, objShapeMatches } from 'shared/progressionEvents'

export const IdCardModal: React.FC<{ onClose: () => void; mentorMode?: boolean; readOnly?: boolean }> = ({
  onClose,
  mentorMode,
  readOnly,
}) => {
  const { progressionEvents } = useProgressionEventsState()
  const [isOpen, setIsOpen] = useState<boolean>(true)
  let cardFlipUnlocked = false

  for (let testProgressionEvent of progressionEvents) {
    if (
      objShapeMatches(
        {
          type: GADGET_PACK_UNLOCK,
          category: 'id-card',
          subcategory: 'enable-flip',
          event_key: null,
          event_value: null,
        },
        testProgressionEvent
      )
    ) {
      cardFlipUnlocked = true
    }
  }

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}
      panelStyle={mentorMode ? { minWidth: '100vw', minHeight: '100vh' } : {}}>
      <IdCardActivityContainer>
        <IdScreen
          hideTitle
          hideMediaButtons
          flippable={cardFlipUnlocked}
          overrideExit={delayedOnClose}
          readOnly={mentorMode ? true : readOnly}
        />
      </IdCardActivityContainer>
    </MobileModalPanel>
  )
}

const IdCardActivityContainer = styled.div`
  position: relative;
  padding: 30px;
  text-align: center;

  & > ${Padding} > ${Column} {
    max-width: 100%;
  }

  & ${BigRedText} {
    font-size: 2em;
  }

  & ${RowWrapPortrait} {
    justify-content: flex-end;
  }

  & > ${Button} {
    margin-bottom: 30px;
  }
`
