import React, { useState } from 'react'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { FactFileActivity } from 'session/sections/custom/FactFileActivity'

export const FactFilesModal: React.FC<{ onClose: () => void; mentorMode?: boolean; readOnly?: boolean }> = ({
  onClose,
  mentorMode,
  readOnly,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelStyle={
        mentorMode
          ? { paddingTop: 30, minWidth: '100vw', minHeight: '100vh' }
          : { background: 'none', boxShadow: 'none', borderBottom: 'none' }
      }>
      <FactFileActivity overrideExit={delayedOnClose} readOnly={readOnly} />
    </MobileModalPanel>
  )
}
