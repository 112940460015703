import { useEffect, useState } from 'react'

export function useWindowSize() {
  const [state, setState] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect((): (() => void) | void => {
    const handler = () => {
      setState({
        width: window.innerWidth,
        height: getViewportHeight() || window.innerHeight,
      })
    }

    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return state
}

function getViewportHeight(): number | undefined {
  const meta = document.querySelector<HTMLMetaElement>('meta[name="viewport"]')
  if (!meta) return undefined
  const matches = meta.content.match(/height=([\d.]+)px/)
  if (!matches) return undefined
  return Number(matches[1])
}
