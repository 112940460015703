import { Column, P } from 'common/ui'
import React from 'react'
import { Media } from 'session/common/Media'
import { RichText, RichTextContainer } from 'session/common/RichText'
import { Block, BlockHeader, BlockMedia, BlockBody } from 'session/sections/Block'
import { SectionPropsBase } from 'shared/training/types'
import styled from 'styled-components'

interface Props extends SectionPropsBase {
  property: 'streamed_video_block_sections'
}

export const StreamedVideoBlockSection: React.FC<Props> = ({ section }) => {
  const { title, video_url, content_side, text, images } = section
  return (
    <BlockWrapper>
      <Block display="inline" alignment={content_side} blockTheme={undefined}>
        <BlockHeader>
          <BlockMedia>
            <div className="videoWrapper" style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
              <iframe
                title="stream"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                src={`https://www.sst-institute.net/embed?url=${video_url}`}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                width="100%"></iframe>
            </div>
          </BlockMedia>
        </BlockHeader>
        <BlockBody>
          <P className="header" color="#fff">
            {title}
          </P>
          <RichText text={text} />
          <ImageWrapper>
            {images &&
              images.map(asset => (
                <Media key={asset.id} type="image" image={asset.image} widthPreset="656w" ratio="auto" />
              ))}
          </ImageWrapper>
        </BlockBody>
      </Block>
    </BlockWrapper>
  )
}

const BlockWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.8);
  border-radius: 10px;

  ${Block} {
    margin: 0;

    ${BlockHeader} {
      flex: 0 0 50%;
    }

    ${BlockMedia} {
      border-radius: 10px 0 0 10px !important;
      display: flex;
      align-items: center;
      background: #222;
      border: none !important;

      .videoWrapper {
        width: 100%;
      }
    }

    ${BlockBody} {
      background: #fff;
      padding: 0 !important;
      border: none;

      .header {
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 0;
        font-size: 1.3rem;
        color: #fff !important;
        background-color: #6737b5;
        padding: 15px;
        line-height: 130%;
        letter-spacing: 0.03em;
        margin-bottom: 0;
      }

      ${RichTextContainer} {
        padding: 20px;
      }
    }
  }
`

const ImageWrapper = styled(Column)`
  padding: 15px;

  img {
    width: 100%;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
