/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSessionState } from './SessionState'
import { PARTICIPANT_HEADER_HEIGHT } from './ParticipantUi'
import { preloadVideo } from 'utils/preloadUtils'
import { PreloadStrategy } from 'utils/VideoPool'

export const TokenNotification: React.FC = () => {
  const { sessionProfile, state } = useSessionState()
  const participant = state.participants.find(participant => participant.profile.uid === sessionProfile.uid)
  const [active, setActive] = useState<boolean>(false)
  const lastTokenCount = useRef<number>(participant ? participant.tokens : 0)
  const [currentSourceIndex, setCurrentSourceIndex] = useState<number>(0)
  const VideoRef = useRef<HTMLVideoElement>(null)

  const tokens: string[] = [
    require('./assets/TokenAssetsAqua.mp4'),
    require('./assets/TokenAssetsBlue.mp4'),
    require('./assets/TokenAssetsPurple.mp4'),
    require('./assets/TokenAssetsWhite.mp4'),
    require('./assets/TokenAssetsYellow.mp4'),
  ]

  let participantTokens = 0
  if (participant) {
    participantTokens = participant.tokens
  }

  const playVideo = () => {
    if (VideoRef.current !== null) {
      VideoRef.current.setAttribute('muted', '1')
      VideoRef.current.play()
    }
  }

  useEffect(() => {
    let activeTimeout: number
    const resetActive = () => {
      setActive(false)
    }
    if (participant && participant.tokens > 0 && participant.tokens !== lastTokenCount.current) {
      setCurrentSourceIndex((currentSourceIndex + 1) % tokens.length)
      lastTokenCount.current = participant.tokens
      setActive(true)
      activeTimeout = setTimeout(resetActive, 3500)
      setTimeout(() => {
        playVideo()
      }, 1000)
    }
    return () => {
      if (activeTimeout) {
        clearTimeout(activeTimeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantTokens])

  useEffect(() => {
    tokens.forEach(token => {
      preloadVideo(token, PreloadStrategy.PlayMuted)
    })
  })

  return (
    <Notification active={active} onClick={playVideo}>
      <video key={currentSourceIndex} ref={VideoRef} muted>
        <source src={tokens[currentSourceIndex]} />
      </video>
    </Notification>
  )
}

const animationCurve = `cubic-bezier(0.3, 0.6, 0.2, 1)`

const Notification = styled.div<{ active?: boolean }>`
  background-image: url(${require('./assets/TokenBG.png')});
  background-size: contain;
  background-position: bottom;
  position: fixed;
  top: ${PARTICIPANT_HEADER_HEIGHT}px;
  left: 0;
  width: 250px;
  height: 120px;
  color: white;
  padding-top: 50px;
  text-align: center;
  transform: translateY(${p => (p.active ? '-50px' : '-100%')});
  opacity: ${p => (p.active ? 1 : 0)};
  transition: transform 0.75s ${animationCurve}, opacity 0.75s ${animationCurve};
  & video {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`
