import React from 'react'
import { useTimeout } from 'utils/useTimeout'

interface Props {
  ms: number
}

const DelayVisible: React.FC<Props> = ({ ms, children }) => {
  const ready = useTimeout(ms)
  if (!ready) return null
  return <>{children}</>
}

export default DelayVisible
