import { ETelligenceState as State, Gadget, CodeCard } from 'shared/e-telligence/types'
import { presetGadgets } from './constants/gadgets'
import { codeCards as presetCodeCards } from './constants/codeCards'

export function findGadgetById(id: Gadget['id'] | undefined, state: State): Gadget | undefined {
  if (id === undefined) return undefined
  const customGadget = state.customGadgets.find(gadget => gadget.id === id)
  if (customGadget) return customGadget
  const presetGadget = presetGadgets.find(gadget => gadget.id === id)
  if (presetGadget) return presetGadget
  return undefined
}

export function findCodeCardById(id: CodeCard['id'] | undefined, state: State): CodeCard | undefined {
  if (id === undefined) return undefined
  const customCodeCard = state.customCodeCards.find(gadget => gadget.id === id)
  if (customCodeCard) return customCodeCard
  const presetCodeCard = presetCodeCards.find(gadget => gadget.id === id)
  if (presetCodeCard) return presetCodeCard
  return undefined
}
