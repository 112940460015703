import React, { useRef, useEffect, useState, useCallback, createRef } from 'react'
import styled from 'styled-components'
import { Game } from 'phaser'
import { fontBold } from 'fonts'

import { DISPATCH_SECTION_STATE } from 'shared/session/actionTypes'
import {
  ActivityThoughtTrackerState,
  EmbeddedActivitySection,
  ParticipantState,
  SectionPanel,
  SectionPropsBase,
  ThoughtTrackerUFOType as UFOType,
} from 'shared/session/types'
import {
  ActionTypes as ThoughtTrackerActionTypes,
  UPDATE_PROFILE_STATE,
  TOGGLE_EDIT_MODE,
  UpdateProfileStateAction,
  UPDATE_THOUGHT_TEXT,
  UFO_SELECTED,
  UFO_CUT,
} from 'shared/session/sections/custom/thought-tracker/actionTypes'

import { Absolute, CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L, Panel, Button, Row } from 'common/ui'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { TextareaInputField } from 'common/FieldInputs'
import { DisabledOverlay } from 'session/common/DisabledOverlay'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'

import ThoughtTrackerScene from './ThoughtTrackerScene'

import { useMeasure } from 'utils/useMeasure'
import { getPixelRatio } from 'utils/screenUtils'
import { getGameConfig, destroyGame } from 'utils/gameUtils'
import { defaultProfileState, getInitialState } from 'shared/session/sections/custom/thought-tracker/reducer'
import { useSessionState } from 'session/SessionState'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { InputLockComponent, LockSymbol } from 'session/common/InputLockComponent'

interface Props extends Omit<SectionPropsBase, 'panel' | 'index'> {
  property: 'embedded_activity_sections'
  width?: number | string
  height?: number | string
  trainingState?: ParticipantState
}

export type InputState = {
  x: number
  y: number
  scale: number
  rotation: number
}

export type State = InputState[]

const inputDefault: InputState = { x: 0, y: -500, scale: 1, rotation: 0 }

const scaleAdjustments = [2, 1.5, 2]

export const ThoughtTracker: React.FC<Props> = ({ width, height, section, trainingState }) => {
  const focusedParticipantState = useFocusedParticipantState()
  const participantState = trainingState ? trainingState : focusedParticipantState
  const sectionState = useSectionStateWithFallback('activity_thought_tracker', section, () => ({}))
  const sectionProfileState = (participantState && sectionState[participantState.profile.uid]) || {
    ...defaultProfileState,
  }
  const { selectingPlayer, gameOver, editMode } = sectionProfileState

  const inputRefs = useRef([...Array(3)].map(() => createRef<HTMLInputElement>()))
  const positions = useRef<State>([inputDefault, inputDefault, inputDefault])

  const { dispatch, isFacilitator, getBaseAction } = useSessionState()

  const [scene, setScene] = useState<ThoughtTrackerScene | null>(null)
  const phaserDiv = useRef<HTMLDivElement>(null)
  const [measureRef, { width: containerWidth, height: containerHeight }] = useMeasure()

  const dispatchSectionState = useCallback(
    (action: ThoughtTrackerActionTypes) =>
      dispatch({
        ...getBaseAction(),
        type: DISPATCH_SECTION_STATE,
        property: 'activity_thought_tracker',
        section_id: section.id,
        action,
      }),
    [dispatch, getBaseAction, section.id]
  )

  const updateProfileState = useCallback(
    (newParticipantState: ActivityThoughtTrackerState[string]) => {
      if (!participantState) return
      dispatchSectionState({
        type: UPDATE_PROFILE_STATE,
        participantUid: participantState.profile.uid,
        participantState: newParticipantState,
      } as UpdateProfileStateAction)
    },
    [dispatchSectionState, participantState]
  )

  const handlePlayerSelected = useCallback(
    (ufoType: UFOType) => {
      if (!participantState) return
      dispatchSectionState({ type: UFO_SELECTED, participantUid: participantState.profile.uid, ufoType })
    },
    [dispatchSectionState, participantState]
  )

  const handleEditButtonClick = () => {
    if (!participantState) return
    dispatchSectionState({ type: TOGGLE_EDIT_MODE, participantUid: participantState.profile.uid })
  }

  const updateInputVal = useCallback(
    (index: number, value: string) => {
      if (!participantState) return
      dispatchSectionState({ type: UPDATE_THOUGHT_TEXT, participantUid: participantState.profile.uid, index, value })
    },
    [dispatchSectionState, participantState]
  )

  const resetGame = useCallback(() => {
    updateProfileState({ ...defaultProfileState })
    positions.current = [inputDefault, inputDefault, inputDefault]
  }, [updateProfileState])

  useEffect(() => {
    if (participantState && scene) {
      scene.updateState(sectionState[participantState.profile.uid], updateProfileState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionState, participantState])

  useEffect(() => {
    if (!phaserDiv.current) return
    const rect = phaserDiv.current.getBoundingClientRect()
    const game = new Game({
      ...getGameConfig(),
      render: {
        antialias: true,
        transparent: true,
      },
      resolution: getPixelRatio(),
      scale: {
        mode: Phaser.Scale.NONE,
        width: rect.width,
        height: rect.height,
      },
      input: {
        mouse: {
          capture: false,
        },
        touch: {
          capture: false,
        },
      },
      parent: phaserDiv.current,
      scene: [ThoughtTrackerScene],
      callbacks: {
        postBoot: game => {
          const scene = game.scene.scenes[0] as ThoughtTrackerScene
          setScene(scene)
          scene.updateInputState = (newPositions: State) => (positions.current = newPositions)
          scene.updateState(sectionProfileState, updateProfileState)
        },
      },
    })
    return () => destroyGame(game)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (containerWidth && scene) {
      scene.scale.resize(containerWidth, containerHeight)
    }
  }, [containerWidth, containerHeight, scene])

  useEffect(() => {
    let raf: number = -1
    const animationLoop = () => {
      inputRefs.current.forEach((inputRef, index) => {
        if (inputRef.current) {
          inputRef.current.style.top = positions.current[index].y + 'px'
          inputRef.current.style.left = positions.current[index].x + 'px'
          const transform = `translate(-50%, -50%) scale(${positions.current[index].scale *
            scaleAdjustments[index]}) rotate(${(positions.current[index].rotation * 180) / Math.PI}deg)`
          inputRef.current.style.transform = transform
          inputRef.current.style.webkitTransform = transform
        }
      })
      raf = window.requestAnimationFrame(animationLoop)
    }
    animationLoop()
    return () => {
      if (raf >= 0) window.cancelAnimationFrame(raf)
    }
  }, [])

  const disableButton = sectionProfileState
    ? sectionProfileState.thoughts.reduce((bool, { value }) => (bool ? bool : !value), false)
    : false

  return (
    <DisabledOverlay active={isFacilitator} message="Facilitator should use group view to see participant activity">
      <Container ref={measureRef} style={{ width, height: 845 }}>
        {selectingPlayer && (
          <Menu>
            <Panel flair={[CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L]} padding={[30, 20]} theme="white">
              <p>Choose your thought tracker:</p>
              <Flex>
                <ImagePanel onClick={() => handlePlayerSelected('blimp')}>
                  <img src={require('./assets/blimp.png')} alt="blimp" />
                </ImagePanel>
                <ImagePanel onClick={() => handlePlayerSelected('plane')}>
                  <img src={require('./assets/plane.png')} alt="plane" />
                </ImagePanel>
              </Flex>
            </Panel>
          </Menu>
        )}
        {gameOver && (
          <Menu>
            <Panel flair={[CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L]} padding={[30, 20]} theme="white">
              <MenuText>
                All your unhelpful <br /> thoughts are now gone!
              </MenuText>
              <Button onClick={resetGame}>Reset</Button>
            </Panel>
          </Menu>
        )}
        <CanvasContainer ref={phaserDiv} cover />
        {!selectingPlayer && !gameOver && (
          <>
            {[...Array(3)].map((_, index) => (
              <DebouncedInputComponent<string>
                key={index}
                onChange={value => updateInputVal(index, value)}
                value={sectionProfileState?.thoughts[index].value || ''}>
                {props => (
                  <InputLockComponent
                    key={index}
                    fieldUid={`${participantState ? participantState.profile.uid : 'shared'}-${index}`}
                    hideIcon
                    hideToggle>
                    {(lockProps, lockState) => (
                      <div ref={inputRefs.current[index]} style={{ position: 'absolute', zIndex: 2 }}>
                        {lockState.disabled && !isFacilitator && (
                          <LockSymbol locked={true} wrapperStyles={{ position: 'absolute', right: 0, top: -30 }} />
                        )}
                        <InputField
                          {...props}
                          {...lockProps}
                          {...lockState}
                          onChange={e => {
                            props.onChange(e.target.value || '')
                            lockProps.onChange(e.target.value || '')
                          }}
                          placeholder={'Enter thought here'}
                          disabled={!sectionProfileState.editMode || lockState.disabled}
                        />
                      </div>
                    )}
                  </InputLockComponent>
                )}
              </DebouncedInputComponent>
            ))}
            {editMode && (
              <Button theme="white" disabled={disableButton} onClick={handleEditButtonClick}>
                Release Any <br /> Unhelpful Thought
              </Button>
            )}
            {!editMode && (
              <Button theme="white" disabled={disableButton} onClick={handleEditButtonClick}>
                Edit
              </Button>
            )}
          </>
        )}
      </Container>
    </DisabledOverlay>
  )
}

interface FacilitatorProps {
  section: EmbeddedActivitySection
  panel: SectionPanel
  index: number
}

export const ThoughtTrackerFacilitator: React.FC<FacilitatorProps> = ({ section, panel, index }) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback('activity_thought_tracker', section, () =>
    getInitialState(state.participants)
  )

  const dispatchSectionState = useCallback(
    (action: ThoughtTrackerActionTypes) =>
      dispatch({
        ...getBaseAction(),
        type: DISPATCH_SECTION_STATE,
        property: 'activity_thought_tracker',
        section_id: section.id,
        action,
      }),
    [dispatch, getBaseAction, section.id]
  )

  return (
    <>
      <ActivityInteractionIndicator type="individual" />
      <FacilitatorControls title="Thought Tracker">
        <FacilitatorControlsTable>
          <tbody>
            {state.participants.map((participant, i) => {
              const participantSectionState = sectionState[participant.profile.uid] || defaultProfileState
              return (
                <tr key={i}>
                  <td style={{ height: 35, padding: '15px 5px' }}>
                    <span>{participant.profile.displayName}</span>
                    {participantSectionState && participantSectionState.selectingPlayer && (
                      <div style={{ fontSize: 12 }}>[Selecting thought tracker]</div>
                    )}
                  </td>
                  {[...Array(3)].map((_, index) => (
                    <td key={index} style={{ minWidth: 200 }}>
                      <Row alignItems="center" style={{ padding: '25px 5px' }}>
                        <DebouncedInputComponent<string>
                          onChange={(value: string) => {
                            dispatchSectionState({
                              type: UPDATE_THOUGHT_TEXT,
                              participantUid: participant.profile.uid,
                              index,
                              value,
                            })
                          }}
                          value={participantSectionState.thoughts[index].value || ''}>
                          {props => (
                            <InputLockComponent
                              key={index}
                              fieldUid={`${participant.profile.uid}-${index}`}
                              hasLabel
                              verticalPlacementFacilitator={4}
                              horizontalPlacementFacilitator={43}>
                              {(lockProps, lockState) => {
                                return (
                                  <TextareaInputField
                                    {...props}
                                    {...lockProps}
                                    {...lockState}
                                    style={{ minWidth: '100px', fontSize: '0.8rem', height: 30, padding: '5px 10px' }}
                                    disabled={
                                      lockState.disabled ||
                                      (participantSectionState
                                        ? participantSectionState.thoughts[index].cut ||
                                          participantSectionState.selectingPlayer
                                        : false)
                                    }
                                    onChange={(val: string) => {
                                      props.onChange(val || '')
                                      lockProps.onChange(val || '')
                                    }}
                                    placeholder={'Cadet Thought'}
                                  />
                                )
                              }}
                            </InputLockComponent>
                          )}
                        </DebouncedInputComponent>

                        <Button
                          size={'xs'}
                          children={'Cut'}
                          disabled={participantSectionState ? participantSectionState.thoughts[index].cut : false}
                          style={{ padding: '4px', marginLeft: 5, maxHeight: 30 }}
                          onClick={() =>
                            dispatchSectionState({
                              type: UFO_CUT,
                              participantUid: participant.profile.uid,
                              index,
                            })
                          }
                        />
                      </Row>
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </FacilitatorControlsTable>
      </FacilitatorControls>
    </>
  )
}

const Container = styled.div`
  flex: auto;
  position: relative;
  overflow: hidden;

  & ${Button} {
    position: absolute;
    left: 30px;
    top: 30px;
    cursor: pointer;
    text-transform: initial;
    padding: 5px 20px 10px 20px;
    z-index: 5;

    &:disabled {
      opacity: 0;
    }
  }
`

const CanvasContainer = styled(Absolute)`
  background: linear-gradient(180deg, #67c7df 0%, #e2f6fe 100%);
  border-radius: 10px;
  > canvas {
    border-radius: 10px;
  }
`

const MenuText = styled.p`
  text-align: center;
  text-transform: capitalize;
`

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  & > ${Panel} {
    width: 50%;
  }

  & p {
    ${fontBold}
    margin: 0;
    font-size: 1.2rem;
    color: #2eadf0;
  }

  & ${Button} {
    position: relative;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 110px;
    margin: 15px auto;
    text-transform: uppercase;
  }

  & ${MenuText} {
    color: #011a46;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`

const ImagePanel = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #cdd2e4;
  width: 48%;
  padding: 30px 10px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  & img {
    width: 100%;
  }
`

const InputField = styled.input`
  border-radius: 10px;
  box-shadow: 0px 1px 0px #fff;
  border: 1px solid #cdd2e4;
  padding: 20px 30px;
  font-size: 17px;

  &:disabled {
    background: #f1f1f1;
  }
`
