import React, { useContext, useEffect, useReducer, useRef } from 'react'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'
import { updateOrAppend } from 'utils/reducerUtils'
import { useSessionState } from './SessionState'
import { InputValue, InputState, InputValueContext, SavedInputValue, SectionStates } from 'shared/session/types'
import {
  ADD_UPDATE_INPUT_VALUE,
  InputActionTypes,
  ADD_UPDATE_SAVED_INPUT_VALUE,
  SET_SAVED_USER_INPUT_VALUES,
  AddUpdateValueAction,
  DISPATCH_INPUT_VALUE_ACTION,
  DELETE_SAVED_INPUT_VALUE,
} from 'shared/session/actionTypes'
import { sortByKey } from 'utils/sortUtils'
import { SectionStateActions } from 'shared/session/sections/sectionStateActionTypes'
import { sectionStateReducers } from 'shared/session/sections/sectionStateReducers'

const getInitialState = (): InputState => {
  return {
    actionCache: {},
    state: {},
    savedState: {},
  }
}

function reducer(inputState: InputState, action: InputActionTypes): InputState {
  switch (action.type) {
    case ADD_UPDATE_INPUT_VALUE:
      return {
        ...inputState,
        actionCache: {
          ...inputState.actionCache,
          [action.context.participant_uid]: [
            ...(inputState.actionCache[action.context.participant_uid] || []),
            action,
          ].slice(-10),
        },
        state: {
          ...inputState.state,
          [action.context.participant_uid]: updateOrAppend(
            inputState.state[action.context.participant_uid] || [],
            { ...action.context, value: action.value, modified: action.timestamp },
            ['participant_uid', 'session_uid', 'module_id', 'owner', 'owner_id', 'name']
          ),
        },
      }
    case SET_SAVED_USER_INPUT_VALUES:
      return action.inputValues.reduce((newState, inputValueObj) => {
        const valueObjects = newState.state[inputValueObj.participant_uid] || []
        const savedValueObjects = newState.savedState[inputValueObj.participant_uid] || []
        return {
          ...newState,
          state: {
            ...newState.state,
            [inputValueObj.participant_uid]: [
              ...valueObjects,
              {
                session_uid: inputValueObj.session_uid,
                module_id: inputValueObj.module_id,
                participant_uid: inputValueObj.participant_uid,
                owner: inputValueObj.owner,
                owner_id: inputValueObj.owner_id,
                name: inputValueObj.name,
                value: inputValueObj.value,
                modified: action.timestamp,
              },
            ],
          },
          savedState: {
            ...inputState.savedState,
            [inputValueObj.participant_uid]: [
              ...savedValueObjects.filter(({ id }) => id !== inputValueObj.id),
              inputValueObj,
            ],
          },
        }
      }, inputState)
    case ADD_UPDATE_SAVED_INPUT_VALUE:
      return {
        ...inputState,
        savedState: {
          ...inputState.savedState,
          [action.payload.participant_uid]: updateOrAppend(
            inputState.savedState[action.payload.participant_uid] || [],
            action.payload,
            ['participant_uid', 'module_id', 'session_uid', 'owner', 'owner_id', 'name']
          ),
        },
      }
    case DELETE_SAVED_INPUT_VALUE: {
      const savedInputValue = inputState.savedState[action.participant_uid].find(({ id }) => id === action.id)
      if (!savedInputValue) return inputState
      return {
        ...inputState,
        savedState: {
          ...inputState.savedState,
          [action.participant_uid]: inputState.savedState[action.participant_uid].filter(({ id }) => id !== action.id),
        },
        state: {
          ...inputState.state,
          [action.participant_uid]: inputState.state[action.participant_uid].filter(
            test => !_isEqual(test.value, savedInputValue.value)
          ),
        },
      }
    }
    case DISPATCH_INPUT_VALUE_ACTION: {
      const { property } = action
      const { participant_uid, session_uid, module_id, owner, owner_id, name } = action.context
      const currentInputValueObj = _find(inputState.state[action.context.participant_uid] || [], {
        participant_uid,
        session_uid,
        module_id,
        owner,
        owner_id,
        name,
      })
      const sectionStateReducer = sectionStateReducers[property] as (
        state: SectionStates[typeof property],
        action: SectionStateActions[typeof property]
      ) => typeof state
      const newValue = sectionStateReducer(currentInputValueObj?.value, action.action) as SectionStates[typeof property]
      const fakeAction: AddUpdateValueAction = {
        type: ADD_UPDATE_INPUT_VALUE,
        user_uid: action.user_uid,
        role: action.role,
        timestamp: action.timestamp,
        context: action.context,
        value: newValue,
        priorityDelivery: true,
      }
      return reducer(inputState, fakeAction)
    }
  }
  return inputState
}

function useProviderUserInputState() {
  const { socket } = useSessionState()
  const [inputState, dispatch] = useReducer(reducer, getInitialState())
  const pendingActions = useRef<InputActionTypes[]>([])

  useEffect(() => {
    if (!socket) return
    socket.on('connect', () => {
      console.log('ℹ️ 📱 UserInputState latched onto socket connection event')
      socket.on('DISPATCH_INPUT_RETURN', (action: InputActionTypes) => {
        console.log('👉📱 Executing input action from socket server', action)
        dispatch(action)
      })
      // Gotta wait a bit so that participant can be initialized on socket server before populating
      setTimeout(() => {
        pendingActions.current.forEach(action => {
          console.log(`📱👉 Dispatching queued input action ${action.type}`)
          socket.emit('DISPATCH_INPUT', action)
        })
      }, 500)
    })
  }, [socket])

  const preDispatch = (action: InputActionTypes) => {
    if (socket && socket.connected) {
      console.log('📱👉 Sending action to socket', action.type)
      socket.emit('DISPATCH_INPUT', action)
      dispatch(action)
    } else {
      console.log(
        `ℹ️ 📱 Cannot dispatch input action ${action.type} to socket server -- ${
          socket ? 'socket not connected' : 'socket not defined'
        }! Putting in queue`
      )
      pendingActions.current.push(action)
      dispatch(action)
    }
  }

  const getInputValues = <ValueType extends any>(
    participant_uid: InputValue<ValueType>['participant_uid'],
    match: Partial<InputValueContext> = {}
  ): InputValue<ValueType>[] => {
    const inputValues =
      (_filter(inputState.state[participant_uid] || [], { ...match, participant_uid }) as InputValue<ValueType>[]) || []
    return inputValues.sort(sortByKey('modified', 'ascending'))
  }

  const getInputActions = (
    participant_uid: InputValueContext['participant_uid'],
    match: Partial<InputValueContext> = {}
  ): AddUpdateValueAction[] => {
    return (inputState.actionCache[participant_uid] || []).filter(action => {
      ;((Object.keys(match) as unknown) as (keyof typeof match)[]).forEach(key => {
        if (!(key in action.context) || action.context[key] !== match[key]) return false
      })
      return true
    })
  }

  const getLatestInputAction = (
    participant_uid: InputValueContext['participant_uid'],
    match: Partial<InputValueContext> = {}
  ): AddUpdateValueAction | false => {
    const actions = getInputActions(participant_uid, match)
    return actions.length ? actions[actions.length - 1] : false
  }

  const getSavedInputValues = <ValueType extends any>(
    participant_uid: InputValue<ValueType>['participant_uid'],
    match: Partial<InputValueContext> = {}
  ): SavedInputValue<ValueType>[] => {
    const savedInputValues =
      (_filter(inputState.savedState[participant_uid] || [], {
        ...match,
        participant_uid,
      }) as SavedInputValue<ValueType>[]) || []
    return savedInputValues
  }

  const getSavedInputValue = <ValueType extends any>(
    participant_uid: InputValue<ValueType>['participant_uid'],
    match: Partial<InputValueContext> = {}
  ): SavedInputValue<ValueType> | null => {
    const savedInputValues = getSavedInputValues<ValueType>(participant_uid, match)
    return !savedInputValues.length
      ? null
      : savedInputValues.sort(sortByKey('updated_at', 'ascending'))[savedInputValues.length - 1]
  }

  const lastInteractions = Object.keys(inputState.state).reduce((obj, key, i) => {
    const modifiedDates = (inputState.state[key] || []).map(inputValueObj => inputValueObj.modified).sort()
    return {
      ...obj,
      [key]: modifiedDates.length > 0 ? modifiedDates[modifiedDates.length - 1] : undefined,
    }
  }, {} as { [key: string]: number | undefined })

  return {
    state: inputState,
    lastInteractions,
    dispatch: preDispatch,
    getInputValues,
    getInputActions,
    getLatestInputAction,
    getSavedInputValue,
    getSavedInputValues,
  }
}

type UserInputState = ReturnType<typeof useProviderUserInputState>

function noop(): any {}

export const UserInputStateContext = React.createContext<UserInputState>({
  state: { ...getInitialState() },
  lastInteractions: {},
  dispatch: noop,
  getInputValues: noop,
  getInputActions: noop,
  getLatestInputAction: noop,
  getSavedInputValue: noop,
  getSavedInputValues: noop,
})

export const UserInputStateProvider: React.FC = ({ children }) => {
  const state = useProviderUserInputState()
  return <UserInputStateContext.Provider value={state}>{children}</UserInputStateContext.Provider>
}

export function useUserInputState() {
  return useContext(UserInputStateContext)
}
