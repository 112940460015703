import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { PdfStatus, PdfModal } from 'common/PdfModal'
import { Button, ButtonProps } from 'common/ui'

import { createTrainingCertificate } from 'api'
import { useUserState } from 'app/UserState'
// import { DrupalTrainingCourse } from 'shared/training/types'

export interface TrainingCertificateButtonProps {
  courseType: string
}

export const TrainingCertificateButton: React.FC<ButtonProps & TrainingCertificateButtonProps> = ({
  courseType,
  ...buttonProps
}) => {
  const { accessToken } = useUserState()
  const [pdfStatus, setPdfStatus] = useState<PdfStatus>('ready')
  const [pdfUrl, setPdfUrl] = useState('')

  const handlePdfGeneration = () => {
    setPdfStatus('busy')
    createTrainingCertificate(courseType, accessToken)
      .then(response => {
        ReactDOM.unstable_batchedUpdates(() => {
          setPdfUrl(response.url)
          setPdfStatus('success')
        })
      })
      .catch(() => setPdfStatus('error'))
  }

  return (
    <>
      <Button
        {...buttonProps}
        children="Certificate"
        onClick={() => handlePdfGeneration()}
        disabled={pdfStatus === 'busy'}
      />
      <PdfModal
        status={pdfStatus}
        url={pdfUrl}
        onClose={() => setPdfStatus('ready')}
        text1={'Generating'}
        text2={'Certificate'}
      />
    </>
  )
}
