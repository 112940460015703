import React, { useState } from 'react'
import styled from 'styled-components'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { FriendometerScreen } from 'gadget-pack/Friendometer'
import { Container, CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L } from 'common/ui'

export const FriendometerModal: React.FC<{ onClose: () => void; mentorMode?: boolean }> = ({ onClose, mentorMode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={mentorMode ? { flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] } : {}}
      panelStyle={
        mentorMode
          ? { minWidth: '100vw', minHeight: '100vh', padding: '30px 20px' }
          : { background: 'none', boxShadow: 'none', borderBottom: 'none' }
      }>
      <FriendometerContainer mentorMode={mentorMode}>
        <FriendometerScreen overrideExit={delayedOnClose} readonly />
      </FriendometerContainer>
    </MobileModalPanel>
  )
}

const FriendometerContainer = styled.div<{ mentorMode?: boolean }>`
  h1 {
    color: ${p => (p.mentorMode ? '#001947' : '#fff')};
  }
  ${Container} {
    max-width: 1024px;
    width: 100%;
  }
`
