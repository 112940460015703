import React from 'react'
import { SectionPropsBase } from 'shared/session/types'
import { SectionPropsBase as TrainingSectionPropsBase } from 'shared/training/types'
import { SectionTitle } from 'session/common/SectionTitle'
import styled, { css } from 'styled-components'
import { SVG } from 'common/SVG'
import { MultilineText } from 'session/common/MultilineText'
import { fontBold, fontLight, fontRegular } from 'fonts'

interface Props extends SectionPropsBase {
  property: 'notification_sections'
}

const notificationColors: { [key in Exclude<Props['section']['type'], 'ardon'>]: string } = {
  info: '#4ebe40',
  warning: '#ff8a00',
  error: '#ff2928',
  dark: '#2c2c2c',
}

export const NotificationSection: React.FC<Props> = ({ section }) => <NotificationInner {...section} />

interface GenericProps extends TrainingSectionPropsBase {
  property: 'notification_sections'
}

export const GenericNotificationSection: React.FC<GenericProps> = ({ section }) => <NotificationInner {...section} />

export const NotificationSectionFacilitator = NotificationSection

export const NotificationInner: React.FC<{
  title?: string
  type: 'info' | 'warning' | 'error' | 'ardon' | 'dark'
  text: string
}> = ({ title, type, text }) => {
  return (
    <>
      {title && <SectionTitle children={title} />}
      {type === 'ardon' ? (
        <Pullout style={{ marginBottom: 0 }}>
          <PulloutInner>
            <PulloutImage>
              <video
                controls={false}
                autoPlay
                loop
                muted
                playsInline
                poster={require('common/assets/image/ardon-small.png')}>
                <source src={require('common/assets/video/1.0.0.1_agent_idle.mp4')} type="video/mp4" />
              </video>
            </PulloutImage>
            <PulloutBody>
              <div>
                <MultilineText text={text} />
              </div>
            </PulloutBody>
          </PulloutInner>
        </Pullout>
      ) : (
        <Notification type={type}>
          <PulloutInner className="notification-inner">
            {type !== 'info' && type !== 'dark' && (
              <NotificationIcon>
                <SVG size={38} viewboxSize={37}>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.54175 18.5003C1.54175 27.8662 9.13425 35.4587 18.5001 35.4587C27.8659 35.4587 35.4584 27.8662 35.4584 18.5003C35.4584 9.1345 27.8659 1.54199 18.5001 1.54199C9.13425 1.54199 1.54175 9.1345 1.54175 18.5003ZM32.3751 18.5003C32.3751 26.1633 26.163 32.3753 18.5001 32.3753C10.8371 32.3753 4.62508 26.1633 4.62508 18.5003C4.62508 10.8374 10.8371 4.62533 18.5001 4.62533C26.163 4.62533 32.3751 10.8374 32.3751 18.5003ZM18.5006 27.7477C19.3523 27.7477 20.0428 27.0575 20.0428 26.2061C20.0428 25.3546 19.3523 24.6644 18.5006 24.6644C17.6489 24.6644 16.9584 25.3546 16.9584 26.2061C16.9584 27.0575 17.6489 27.7477 18.5006 27.7477ZM20.0473 9.24775H16.963V21.5811H20.0473V9.24775Z"
                    fill="currentColor"
                  />
                </SVG>
              </NotificationIcon>
            )}
            <NotificationBody>
              {type === 'info' && <NotificationTitle>Instructions:</NotificationTitle>}
              <MultilineText text={text} />
            </NotificationBody>
          </PulloutInner>
        </Notification>
      )}
    </>
  )
}

const Pullout = styled.div`
  margin-bottom: 24px;
  padding: 10px;
  border-radius: 12px;
  background-color: #41475f;
  // prettier-ignore
  box-shadow: 
    0 -5px 5px 0 hsla(0, 0%, 100%, 0.25), 
    0 4px 10px 0 rgba(0, 0, 0, 0.9), 
    0 4px 0 0 #1c223a,
    inset 0 -1px 2px 0 rgba(0, 0, 0, 0.38), 
    inset 0 1px 2px 0 hsla(0, 0%, 100%, 0.7);
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0.03px;
`
export const PulloutInner = styled.div`
  ${fontRegular};
  display: flex;
  color: #011a46;
`

const PulloutImage = styled.div`
  flex: 0 0 130px;
  width: 130px;
  & video {
    display: block;
    width: 100%;
    background-color: #010101;
    border-radius: 15px;
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.25);
  }
`
const PulloutBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin-left: 25px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  &::before {
    content: '';
    position: absolute;
    width :21px;
    height: 25px;
    top: 35px;
    left: 2px;
    transform: translateX(-100%);
    background-image: url('${require('../assets/pullout-arrow.svg')?.default}');
    background-repeat: no-repeat;
  }
`

export const NotificationIcon = styled.div`
  flex: none;
  padding-right: 20px;
  margin-top: calc(-1 * (38px - 1.3em) / 2);
  & img,
  & svg {
    width: 38px;
  }
`

export const Notification = styled.div<{ type: Exclude<Props['section']['type'], 'ardon'> }>`
  margin: 25px 0;
  padding: 20px 45px 20px 20px;
  border: ${p => (p.type === 'info' ? '' : `4px solid ${notificationColors[p.type]}`)};
  border-radius: 10px;
  background-color: ${p => (p.type === 'info' ? '' : '#fff')};
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.05em;

  ${p =>
    p.type === 'info'
      ? css`
          border-radius: 0;
          padding: 0;
          padding-left: 10px;
          border-left: 4px solid ${notificationColors[p.type]};
        `
      : ''}

  ${p =>
    p.type === 'dark'
      ? css`
          border-color: #fff;
          border-width: 1px;
          background: ${notificationColors[p.type]};

          ${PulloutInner} {
            color: #fff;
          }
        `
      : ''}

  & ${NotificationIcon} svg path {
    fill: ${p => notificationColors[p.type]};
  }
`
export const NotificationBody = styled.div`
  ${fontLight};
  flex: 1 1 auto;
`

export const NotificationTitle = styled.h3`
  ${fontBold};
  color: ${notificationColors['info']};
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 0;
  line-height: 0.7;
`
