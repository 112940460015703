import { RouteProps, matchPath } from 'react-router-dom'

let ignorePromptCounter = 1

export const getPromptCount = () => ignorePromptCounter
export const getNextPromptCount = () => {
  ignorePromptCounter++
  return ignorePromptCounter
}

export const cleanRouteState = (state: any) => {
  if (typeof state !== 'object') return state
  if ('ignoreRouterPrompt' in state) {
    if (state.ignoreRouterPrompt === ignorePromptCounter) ignorePromptCounter++
    delete state['ignoreRouterPrompt']
  }
  return state
}

/**
 * Constructs a URL based on a react-router path and params object.
 *
 * @param path The path containing params eg. /something/:id
 * @param params Params object eg. { id: '4' }
 */
export function buildUrl(path: string, params: { [key: string]: string }) {
  return path.replace(/:([^/]+)/g, (_match, param: string) => params[param])
}

export const getMatchingRouteParams = <ParamKeys extends string>(
  pathname: string,
  paths: RouteProps[],
  defaultValue: Partial<{ [key in ParamKeys]: string }> = {}
) => {
  for (let path of paths) {
    const match = matchPath<{ [key in ParamKeys]: string | undefined }>(pathname, path)
    if (match) return { ...defaultValue, ...match.params }
  }
  return defaultValue
}
