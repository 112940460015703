import React, { Fragment } from 'react'
import { Spacer } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { NavRouteProps } from 'dashboards/types'
import { Cells } from 'dashboards/common/Cell'
import { ResourceCell } from 'dashboards/common/ResourceCell'
import { handoutResources } from '../../constant/resources'
import { HeaderHr } from 'dashboards/common/HeaderHr'

export const Handouts: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route}>
      {handoutResources.map((materialSet, n) => (
        <Fragment key={n}>
          <HeaderHr children={materialSet.title} />
          <Cells>
            {materialSet.content.map((item, i) => (
              <ResourceCell
                key={i}
                title={item.title}
                asset={item.asset}
                asset2={item.asset2}
                buttonLabel={item.buttonLabel}
                button2Label={item.button2Label}
              />
            ))}
          </Cells>
          <Spacer size="l" />
        </Fragment>
      ))}
    </Page>
  )
}
