import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fontLight } from 'fonts'

import {
  ActivityBodySignalsState,
  EmbeddedActivitySection,
  SessionProfile,
  BodySignalsClue as Clue,
  BodySignalsCustomClue as CustomClue,
  ParticipantState,
} from 'shared/session/types'

import {
  Button,
  Column,
  CUT_TL,
  CUT_TR,
  CUT_BR,
  CUT_BL,
  TAB_B_L,
  TAB_T_L,
  Padding,
  Panel,
  Row,
  Spacer,
  P,
} from 'common/ui'
import { Emoji } from 'common/Emoji'
import { MobileModalPanel, TransitionModal } from 'app/MobileMessageModal'
import { TextSymbolInput, TextSymbolValue } from 'common/TextSymbolInput'
import { FacilitatorControls } from 'session/common/FacilitatorControlsSection'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'

import { presetStickers } from 'e-telligence/constants/stickers'

import { getImageUrl } from 'session/utils/assetUtils'
import { useSessionState } from 'session/SessionState'
import { useFocusedParticipantState, useParticipantProfileUid } from 'session/hooks/useProfileState'
import { InputContext, useInputInterface } from 'session/InputContext'
import { useGenericUser } from 'app/useGenericUser'
import { secretAgentBodySignalEmojis } from 'constants/emojiSets'

const emojis = secretAgentBodySignalEmojis

type ClueType = 'anger' | 'anxiety'

const EmotionColours: { anger: string; anxiety: string } = { anger: '#ff2928', anxiety: '#8a4d9e' }

const getBodyClues = (type: ClueType) => {
  return presetStickers
    .filter((obj) => {
      return obj.emotion === type
    })
    .map((obj) => {
      return {
        id: obj.id,
        text: obj.title,
        image: require(`e-telligence/assets/stickers/bodyClues/${obj.emotion}_${obj.id}_icon.png`),
      }
    })
}

const getInitialState = (
  participants: ParticipantState[],
  emotion: EmbeddedActivitySection['body_signals_emotion']
) => {
  let returnObj: ActivityBodySignalsState = {}
  participants.forEach((participant) => {
    returnObj[participant.profile.uid] = { clues: [...Array(12)], clueOptions: getBodyClues(emotion) }
  })
  return returnObj
}

interface Props {
  section: EmbeddedActivitySection
  trainingState?: ParticipantState
}

export const SecretAgentBodySignalsActivity: React.FC<Props> = (props) => {
  const { state } = useSessionState()
  const participantUid = useParticipantProfileUid()
  const { uid } = useGenericUser()

  return (
    <InputContext.Provider
      value={{
        session_uid: props.trainingState ? `Training_${props.trainingState.profile.uid}` : state.sessionUid,
        participant_uid: participantUid ? participantUid : uid,
        module_id: 0,
        owner: props.trainingState
          ? `training_body_signals_${props.section.body_signals_emotion}`
          : props.section._owner,
        owner_id: props.section._owner_id,
        name: props.trainingState ? 'training_body_signals_state' : 'body_signals_state',
      }}>
      <SecretAgentBodySignalsActivityInner {...props} />
    </InputContext.Provider>
  )
}

export const SecretAgentBodySignalsActivityInner: React.FC<Props> = ({ section, trainingState }) => {
  const {
    body_signals_emotion: emotion,
    body_signals_background_image: backgroundImage,
    body_signals_image_size: backgroundSize,
    body_signals_placeholder_text: placeholderText,
  } = section

  const { state } = useSessionState()
  const focusedParticipantState = useFocusedParticipantState()
  const participantState = trainingState ? trainingState : focusedParticipantState
  const [sectionState, onValueChange] = useInputInterface<ActivityBodySignalsState>({
    name: 'body_signals_state',
    defaultValue: getInitialState(state.participants, emotion),
  })

  const [clueSelector, setShowSelectorModal] = useState<boolean>(false)
  const [clueView, setShowViewModal] = useState<boolean>(false)
  const [currentSlot, setCurrentSlot] = useState<number>(0)

  const updateSectionState = (state: ActivityBodySignalsState) => onValueChange(state)

  const chooseClue = (index: number) => {
    if (!participantState) return
    setCurrentSlot(index)
    if (!sectionState[participantState.profile.uid].clues[index]) {
      setShowSelectorModal(true)
    } else {
      setShowViewModal(true)
    }
  }

  if (participantState && sectionState[participantState.profile.uid] === undefined) {
    updateSectionState({
      ...sectionState,
      [participantState.profile.uid]: { clues: [...Array(12)], clueOptions: getBodyClues(emotion) },
    })
  }

  if (!participantState || !sectionState[participantState.profile.uid]) return null

  return (
    <>
      <Title style={{ marginTop: 0, marginBottom: 25 }} emotion={emotion}>
        {emotion} Body Clues
      </Title>
      <Wrapper>
        <Padding size="l" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <BodyContainer
            background={backgroundImage ? getImageUrl(backgroundImage, { preset: '960w-auto' }) : undefined}
            imageCoverProperty={backgroundSize}>
            <Column>
              {[...Array(6)].map((_, i) => (
                <Slot
                  key={i}
                  value={sectionState[participantState.profile.uid].clues[i] || null}
                  onSelect={() => chooseClue(i)}
                  style={{ marginRight: 50 }}
                  placeholder={placeholderText}
                />
              ))}
            </Column>
            <Column>
              {[...Array(6)].map((_, i) => (
                <Slot
                  key={i}
                  value={sectionState[participantState.profile.uid].clues[i + 6] || null}
                  onSelect={() => chooseClue(i + 6)}
                  style={{ marginLeft: 50 }}
                  placeholder={placeholderText}
                />
              ))}
            </Column>
          </BodyContainer>
        </Padding>
      </Wrapper>
      <ClueSelectModal
        emotion={emotion}
        activityState={sectionState}
        profile={participantState.profile}
        isOpen={clueSelector}
        onAdd={(customClue) => {
          sectionState[participantState.profile.uid].clueOptions.push(customClue)
          updateSectionState(sectionState)
        }}
        onAfterClose={() => {
          setShowSelectorModal(false)
        }}
        onRequestClose={() => {
          setShowSelectorModal(false)
        }}
        onSelect={(clue) => {
          if (currentSlot !== null) {
            sectionState[participantState.profile.uid].clues[currentSlot] = clue
            updateSectionState(sectionState)
          }
          setShowSelectorModal(false)
        }}
      />
      <ClueViewModal
        isOpen={clueView}
        clue={sectionState[participantState.profile.uid].clues[currentSlot] || null}
        onRequestClose={() => {
          setShowViewModal(false)
        }}
        onRemove={() => {
          sectionState[participantState.profile.uid].clues[currentSlot] = null
          updateSectionState(sectionState)

          setShowViewModal(false)
        }}
      />
    </>
  )
}

const Slot: React.FC<{
  value: Clue | CustomClue | null
  onSelect: () => void
  style: React.CSSProperties
  placeholder: string
}> = ({ value, onSelect, style, placeholder }) => {
  return (
    <SlotDiv onClick={onSelect} style={style}>
      {!value && <p>{placeholder ? placeholder : `Add`}</p>}
      {value && (
        <ClueDiv>
          {'image' in value ? (
            <img src={value.image} alt={value.text} />
          ) : (
            <Row justifyContent="center" alignItems="center" style={{ fontSize: 42 }}>
              <Emoji children={value.symbol} />
            </Row>
          )}
          <Label
            style={{
              fontSize: '0.5em',
              lineHeight: 1,
              backgroundColor: '#000',
            }}>
            <span style={{ width: '80%' }}>{value.text}</span>
          </Label>
        </ClueDiv>
      )}
    </SlotDiv>
  )
}

const ClueSelectModal: React.FC<
  ReactModal.Props & {
    onSelect: (clue: Clue | CustomClue) => void
    onAdd: (clue: Clue | CustomClue) => void
    activityState: ActivityBodySignalsState
    emotion: ClueType
    profile: SessionProfile
  }
> = ({ isOpen, onRequestClose, onSelect, activityState, emotion, profile, onAfterClose, onAdd }) => {
  const [selectedClue, setSelectedClue] = useState<Clue | CustomClue | null>(null)
  const [showCustomModal, setShowCustomModal] = useState<boolean>(false)
  return (
    <>
      <MobileModalPanel
        isOpen={isOpen}
        onAfterClose={onAfterClose}
        onRequestClose={() => {
          setSelectedClue(null)
          return onRequestClose
        }}
        panelStyle={{ border: 'none', borderRadius: 10, width: 1000, boxShadow: 'none' }}>
        <Panel flair={[CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L]} padding={[30, 20]} theme="white">
          <Column flex="none" paddingLeft="m" paddingRight="m">
            <Title emotion={emotion}>{emotion} Body Clues</Title>
            <Spacer size="s" />
          </Column>
          <Row
            flex="1 1 20%"
            style={{
              overflowY: 'auto',
              flexWrap: 'wrap',
              flex: '1 1 20%',
            }}
            paddingLeft="s"
            paddingRight="s"
            paddingBottom="s"
            paddingTop="s">
            {activityState[profile.uid].clueOptions.map((clue, i) => (
              <ClueDiv
                key={i}
                onClick={() => setSelectedClue(selectedClue === clue ? null : clue)}
                selected={selectedClue === clue}>
                {'image' in clue ? (
                  <img src={clue.image} alt={clue.text} />
                ) : (
                  <Row justifyContent="center" alignItems="center" style={{ fontSize: 56, height: 100 }}>
                    <Emoji children={clue.symbol} />
                  </Row>
                )}
                <Label
                  style={{
                    fontSize: '1em',
                    padding: 10,
                    lineHeight: 1.2,
                    backgroundColor: '#000',
                  }}>
                  <span style={{ width: '80%' }}>{clue.text}</span>
                </Label>
              </ClueDiv>
            ))}
            <ClueDiv onClick={() => setShowCustomModal(true)} style={{ background: 'none' }}>
              <Padding size="xs">
                <label style={{ lineHeight: 1.5, fontSize: 20, color: '#9195a5' }}>
                  Make <br /> your <br /> own
                </label>
              </Padding>
            </ClueDiv>
          </Row>
          <Column padding="m">
            <Row justifyContent="center">
              <Button size="s" theme="white" children="Cancel" onClick={onRequestClose} style={{ cursor: 'pointer' }} />
              <Spacer size="m" />
              <Button
                size="s"
                disabled={!selectedClue}
                children="Add"
                onClick={() => (selectedClue !== null ? onSelect(selectedClue) : {})}
                style={{ cursor: 'pointer' }}
              />
            </Row>
          </Column>
        </Panel>
      </MobileModalPanel>
      <CustomClueModal
        isOpen={showCustomModal}
        onRequestClose={() => {
          setShowCustomModal(false)
        }}
        onAdd={(customClue) => {
          onAdd(customClue)
          setShowCustomModal(false)
        }}
        emotion={emotion}
      />
    </>
  )
}

const ClueViewModal: React.FC<
  ReactModal.Props & {
    clue: Clue | CustomClue | null
    onRemove?: () => void
  }
> = ({ clue, onRemove, ...props }) => {
  return (
    <TransitionModal {...props}>
      {({ requestClose }) => (
        <>
          {clue && (
            <Column>
              <ClueDiv style={{ width: 250, height: 250, borderColor: '#22ACF3' }} onClick={requestClose}>
                <Padding size="m" style={{ height: 'auto' }}>
                  {'image' in clue ? (
                    <img src={clue.image} alt={clue.text} />
                  ) : (
                    <Row justifyContent="center" alignItems="center" style={{ fontSize: 56, height: 100 }}>
                      <Emoji children={clue.symbol} />
                    </Row>
                  )}
                </Padding>
                <Label
                  style={{
                    fontSize: clue.text.length < 23 ? '1em' : '0.65em',
                    padding: 15,
                    minHeight: 50,
                    lineHeight: 1.2,
                    backgroundColor: '#2B2E34',
                  }}>
                  <span style={{ width: '80%' }}>{clue.text}</span>
                </Label>
              </ClueDiv>
              {onRemove && (
                <>
                  <Spacer size="m" />
                  <Button
                    size="s"
                    onClick={() => {
                      onRemove()
                      requestClose()
                    }}
                    children="Delete"
                    style={{ cursor: 'pointer' }}
                  />
                </>
              )}
            </Column>
          )}
        </>
      )}
    </TransitionModal>
  )
}

const CustomClueModal: React.FC<ReactModal.Props & { onAdd: (clue: CustomClue) => void; emotion: ClueType }> = ({
  isOpen,
  onRequestClose,
  onAdd,
  emotion,
}) => {
  const [value, setValue] = useState<TextSymbolValue>({})

  useEffect(() => {
    if (!isOpen && (value.text || value.symbol)) setValue({})
  }, [isOpen, value.text, value.symbol])

  return (
    <MobileModalPanel
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      panelStyle={{ border: 'none', borderRadius: 10, width: 600, boxShadow: 'none' }}>
      <Panel flair={[CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L]} theme="white">
        <Column style={{ maxHeight: 'calc(100vh - 25px)', overflow: 'hidden' }} padding="m" paddingBottom="l">
          <Title emotion={emotion}>Make your own:</Title>
          <Spacer size="m" />
          <P>Body Clue:</P>
          <TextSymbolInput
            value={value}
            onChange={(value) => setValue(value)}
            inputProps={{ inputSize: 'l' }}
            symbols={emojis}
          />
          <Spacer size="m" />
          <Row justifyContent="center">
            <Button children="Cancel" theme="white" onClick={onRequestClose} style={{ cursor: 'pointer' }} />
            <Spacer size="m" />
            <Button
              disabled={!value.text || !value.symbol}
              onClick={() => onAdd({ id: value.text || '', text: value.text || '', symbol: value.symbol || '' })}
              children="Create"
              style={{ cursor: 'pointer' }}
            />
          </Row>
        </Column>
      </Panel>
    </MobileModalPanel>
  )
}

export const SecretAgentBodySignalsActivityFacilitator: React.FC = () => {
  return (
    <>
      <ActivityInteractionIndicator type="individual" />
      <FacilitatorControls title="Secret Agent Body Signals">
        <P>View participant tabs to see activity</P>
      </FacilitatorControls>
    </>
  )
}

const Wrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  border: 1px solid #cdd2e4;
`

const BodyContainer = styled.div<{ background?: string; imageCoverProperty?: string }>`
  position: relative;
  height: 600px; // TODO: NEED to set this up better
  width: 100%;
  background-image: ${(p) => (p.background ? `url(${p.background})` : '')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(p) => p.imageCoverProperty};
  padding: 15px;
  display: flex;
  align-items: center;

  ${Column} {
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
`

const ClueDiv = styled.div<{ selected?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 150px;
  width: 150px;
  border: 1px solid ${(p) => (p.selected ? '#22ACF3' : '#BDC3E0')};
  margin-bottom: 10px;
  margin-right: 4px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 0 2px #22acf3;
    border-radius: 10px;
    opacity: ${(p) => (p.selected ? 1 : 0)};
  }

  & img {
    width: 100%;
  }

  & label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    cursor: pointer;
  }

  & > ${Row} {
    font-size: 32px;
    @media print {
      font-size: 56px;
    }
  }
`

const Label = styled.label`
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  padding: 7px;
  font-size: 12px;
  text-align: center;
`

const SlotDiv = styled.div`
  ${fontLight}
  position: relative;
  width: 80px;
  min-height: 80px;
  color: #9195a5;
  border: 1px solid #cdd2e4;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  p {
    margin: 0;
    line-height: 1;
    font-size: 18px;
  }

  ${ClueDiv} {
    border: none;
    margin: 0;

    img {
      border-radius: 10px 10px 0 0;
    }
  }
`

const Title = styled.h4<{ emotion: ClueType }>`
  color: ${(p) => EmotionColours[p.emotion]};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  text-transform: capitalize;
`
