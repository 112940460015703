import React from 'react'

import { P, CollapseMargin, Center, Spacer } from 'common/ui'
import { IntroTemplate } from 'home/IntroTemplate'
import { LinkButton } from 'common/LinkButton'

export const NotFoundPage: React.FC = () => {
  // TODO: register page not found error with analytics or sentry
  return (
    <IntroTemplate title="Not Found">
      <CollapseMargin>
        <P align="center">The page you were looking for could not be found.</P>
      </CollapseMargin>
      <Spacer size="l" />
      <Center>
        <LinkButton to="/">Login</LinkButton>
      </Center>
    </IntroTemplate>
  )
}
