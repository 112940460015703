import { useState, useEffect } from 'react'
import { isOnline } from 'utils/deviceUtils'

export function useOnline(): boolean {
  const [online, setOnline] = useState<boolean>(isOnline())
  useEffect(() => {
    const updateStatus = () => setOnline(isOnline())
    window.addEventListener('online', updateStatus)
    window.addEventListener('offline', updateStatus)
    return () => {
      window.removeEventListener('online', updateStatus)
      window.removeEventListener('offline', updateStatus)
    }
  }, [setOnline])
  return online
}
