import styled, { css } from 'styled-components'

interface DroppableStickerHighlightWrapperProps {
  itemIsOver?: boolean
  highlight?: boolean
  disabled?: boolean
  itemBeingDragged?: boolean
  disableInteraction?: boolean
}

export const DroppableStickerHighlightWrapper = styled.div<DroppableStickerHighlightWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${p =>
    !p.disableInteraction
      ? ''
      : css`
          pointer-events: none;
        `}
  ${p =>
    p.disabled
      ? css`
          opacity: 0.5;
        `
      : ''}
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    border-radius: 5px;
    ${p =>
      p.itemBeingDragged && !p.disabled
        ? css`
            ${p.itemIsOver
              ? css`
                  box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 12px 0 rgba(0, 0, 0, 0.75);
                `
              : p.highlight
              ? css`
                  box-shadow: 0 0 12px 0 yellow;
                `
              : css`
                  box-shadow: inset 0 0 0 2px #ddd;
                `}
          `
        : ''}
  }
`
