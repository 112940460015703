import React from 'react'
import { P, Row } from 'common/ui'
import styled from 'styled-components'
import { fontBold } from 'fonts'

type AlertType = 'sad' | 'embarrassed' | 'confused' | 'bored' | 'anger'

type AlertContent = {
  image: string
  text: string
}

export interface AlertSectionType {
  type: AlertType[]
  title: string
  content: AlertContent[]
}

const AlertColours: { [key in AlertType]: string } = {
  sad: '#2EADF0',
  embarrassed: '#4EBE40',
  confused: '#FFF051',
  bored: '#FF8A00',
  anger: '#FF2928',
}

const AlertText: { [key in AlertType]: string } = {
  sad: 'sadness',
  embarrassed: 'embarrassment',
  confused: 'confused',
  bored: 'boredom',
  anger: 'anger',
}

const AlertSvg: React.FC<{ alert: AlertType; x: number }> = ({ alert, x }) => (
  <AlertSvgWrapper x={x}>
    <svg width="336" height="336" viewBox="0 0 336 336" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M286.733 212.374C286.731 215.509 285.484 218.515 283.266 220.73L220.58 283.335C218.362 285.55 215.355 286.793 212.22 286.791L123.626 286.734C120.491 286.732 117.486 285.485 115.27 283.266L52.666 220.581C50.4506 218.363 49.2072 215.355 49.2093 212.22L49.2666 123.627C49.2686 120.492 50.516 117.486 52.7342 115.271L115.42 52.6664C117.638 50.4511 120.645 49.2077 123.78 49.2097L212.374 49.2671C215.509 49.2691 218.514 50.5164 220.73 52.7346L283.334 115.42C285.549 117.638 286.793 120.646 286.791 123.781L286.733 212.374Z"
          fill={AlertColours[alert]}
        />
        <path
          d="M285.256 212.373C285.254 215.116 284.163 217.746 282.222 219.685L219.536 282.289C217.595 284.227 214.964 285.315 212.221 285.314L123.627 285.256C120.884 285.255 118.254 284.163 116.316 282.222L53.7114 219.537C51.773 217.596 50.685 214.964 50.6868 212.221L50.7442 123.628C50.746 120.885 51.8373 118.255 53.7783 116.316L116.464 53.7119C118.405 51.7735 121.036 50.6855 123.779 50.6873L212.373 50.7446C215.116 50.7464 217.746 51.8378 219.684 53.7787L282.289 116.464C284.227 118.405 285.315 121.037 285.313 123.78L285.256 212.373Z"
          stroke="white"
          strokeWidth="2.95511"
        />
      </g>
      <path
        d="M274.337 209.065C274.336 211.025 273.557 212.903 272.17 214.288L214.154 272.229C212.767 273.614 210.888 274.391 208.928 274.389L126.934 274.336C124.975 274.335 123.096 273.556 121.711 272.169L63.77 214.153C62.3855 212.766 61.6083 210.887 61.6096 208.927L61.6627 126.933C61.664 124.974 62.4436 123.095 63.8299 121.71L121.846 63.7691C123.233 62.3845 125.112 61.6073 127.072 61.6086L209.066 61.6617C211.026 61.663 212.904 62.4426 214.289 63.8289L272.23 121.845C273.615 123.232 274.392 125.111 274.39 127.071L274.337 209.065Z"
        fill={AlertColours[alert]}
        stroke="white"
        strokeWidth="2.95511"
      />
      <defs>
        <filter
          id="filter0_d"
          x="19.6582"
          y="31.4793"
          width="296.684"
          height="296.684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="11.8204" />
          <feGaussianBlur stdDeviation="14.7755" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
    <AlertSvgText colour={alert === 'confused' ? '#011A46' : '#fff'}>
      <p style={{ fontSize: alert === 'embarrassed' ? '0.7em' : '1em' }}>{AlertText[alert]}</p>
      <p>Alert</p>
    </AlertSvgText>
  </AlertSvgWrapper>
)

export const AlertSection: React.FC<AlertSectionType> = ({ type, content, title }) => {
  const feelingColour = AlertColours[type[0]]
  const fontColour = type[0] === 'confused' ? '#011A46' : '#fff'

  return (
    <AlertWrapper backgroundColour={feelingColour}>
      <Row>
        {type.map((item, idx) => (
          <AlertSvg key={idx} alert={item} x={idx === 0 ? -15 : 90 * idx} />
        ))}
        <AlertTitle colour={fontColour} children={title} leftMargin={type.length * 110} />
      </Row>
      <Row flexWrap>
        {content.map((item, idx) => (
          <AlertContentItem key={idx} colour={fontColour}>
            <AlertImage>
              <img src={item.image} alt="" />
            </AlertImage>
            <P children={item.text} />
          </AlertContentItem>
        ))}
      </Row>
    </AlertWrapper>
  )
}

const AlertWrapper = styled.div<{ backgroundColour: string }>`
  padding: 0 15px 10px;
  border-radius: 10px;
  margin-top: 65px;
  background-color: ${p => p.backgroundColour};
`

const AlertSvgWrapper = styled.div<{ x: number }>`
  position: absolute;
  width: 130px;
  height: 130px;
  top: -65px;
  left: ${p => p.x}px;

  svg {
    width: 100%;
    height: 100%;
  }
`

const AlertSvgText = styled.div<{ colour: string }>`
  position: absolute;
  ${fontBold};
  color: ${p => p.colour};
  text-transform: uppercase;
  text-align: center;
  font-size: 0.5em;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    margin-bottom: 5px;
    margin-top: 0;
    line-height: 1;

    &:last-of-type {
      font-size: 1.4em;
    }
  }
`

const AlertTitle = styled.h2<{ colour: string; leftMargin: number }>`
  ${fontBold};
  font-size: 0.8em;
  text-transform: uppercase;
  color: ${p => p.colour};
  margin-left: ${p => p.leftMargin}px;
`

const AlertContentItem = styled.div<{ colour: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: calc(100% / 6 - 10px);
  margin-right: 10px;
  min-width: 120px;

  &:last-of-type {
    margin-right: 0;
  }

  p {
    margin-top: 5px;
    color: ${p => p.colour};
  }
`

const AlertImage = styled.div`
  position: relative;
  height: 0;
  padding-top: 100%;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
