import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { MentorEntityNew, MentorEntity } from 'shared/dashboard/types'
import { red } from 'themes'
import { MobileModalPanel, MobileModalPanelProps } from 'app/MobileMessageModal'
import { CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L, Padding, P, Spacer, Row, Button, Input } from 'common/ui'

/*
export const MentorRemoveModal: React.FC<Omit<PromptModalProps, 'label' | 'title'> & {
  mentor?: MentorEntityNew | MentorEntity
}> = ({ mentor, ...props }) => {
  let label = `ARE YOU SURE?\nYou will remove the Cadet Place from the SAS Small Group Program.\n`
  if (!mentor || !!mentor.drupal_user_id)
    label += `This will also remove mentor access to this cadet and their past data.\n`
  if (!mentor || mentor.activated)
    label += `The child's access to SAS Digital HQ will remain as long as their product key is valid.`
  return (
    <PromptModal
      title={`Remove Cadet Place${mentor ? `: ${mentor.cadet_first_name} ${mentor.cadet_last_name}` : ''}`}
      label={label}
      confirmText="Remove"
      confirmTheme={red}
      {...props}
    />
  )
}
*/

export const MentorRemoveModal: React.FC<
  Omit<MobileModalPanelProps, 'label' | 'title'> & {
    mentor?: MentorEntityNew | MentorEntity
    onConfirmDelete: (mentor?: MentorEntityNew | MentorEntity) => void
  }
> = ({ mentor, onConfirmDelete, ...props }) => {
  const [inputValue, setInputValue] = useState<string>('')
  return (
    <MobileModalPanel
      {...props}
      panelProps={{ ...(props.panelProps || {}), flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}>
      <Padding size="l" style={{ maxWidth: 600 }}>
        <P>Are you sure you want to REMOVE this cadet from your SAS Provider account?</P>
        <P>The child and mentor's access to SAS Digital HQ will remain as long as their product key is valid.</P>
        <P>
          Type "permanently remove" and click "Remove" to permanently remove this cadet's personal information from your
          dashboard.
        </P>
        <P>Click "Take me back" if you clicked this button in error.</P>
        <Spacer />
        <Input
          placeholder="permanently remove"
          inputSize="m"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value.toLowerCase())}
        />
        <Spacer size="l" />
        <Row justifyContent="center">
          <Button
            disabled={inputValue !== 'permanently remove'}
            style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
            children="Remove"
            onClick={() => onConfirmDelete(mentor)}
            size="s"
            theme={red}
          />
          <Spacer />
          <Button
            style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
            children="Take me back"
            onClick={(e) => {
              ReactDOM.unstable_batchedUpdates(() => {
                setInputValue('')
                if (props.onRequestClose) props.onRequestClose(e)
              })
            }}
            size="s"
          />
        </Row>
        <Spacer />
      </Padding>
    </MobileModalPanel>
  )
}
