import React, { useState } from 'react'
import styled from 'styled-components'
import { fontBold, fontBlack, fontLight } from 'fonts'

import { UPDATE_SECTION_STATE_DANGEROUS } from 'shared/session/actionTypes'
import {
  ActivityDamageControlState,
  DamageControlState,
  DamageControlStep,
  DamageControlSteps,
  SectionPropsBase,
} from 'shared/session/types'

import { Button, CUT_BL_DARK, CUT_BR_DARK, TAB_B_DARK, Panel, Row, Spacer } from 'common/ui'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { ConfirmModal } from 'common/ConfirmModal'
import { RadioButton } from 'session/common/RadioButton'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'
import { NotificationSection } from '../Notification'

import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { useSessionState } from 'session/SessionState'

interface Props extends SectionPropsBase {
  property: 'embedded_activity_sections'
}

interface SectionProps {
  section: Props['section']
  property: Props['property']
  panel: Props['panel']
}

interface StepContent {
  title: string
  subtitle: string
}

interface StepContentShape {
  [key: string]: StepContent
}

const stepContent: StepContentShape = {
  'Step 1': {
    title: 'Step 1',
    subtitle: 'After things haven’t gone to plan, use your relaxation gadgets to calm down.',
  },
  'Step 2A': {
    title: 'Step 2',
    subtitle: 'Decide on the best thing to do now (A, B or C):',
  },
  'Step 2B': {
    title: 'Step 2',
    subtitle: 'Decide on the best thing to do now (A, B or C):',
  },
  'Step 2C': {
    title: 'Step 2',
    subtitle: 'Decide on the best thing to do now (A, B or C):',
  },
  'Step 3': {
    title: 'Step 3',
    subtitle: 'Try to learn from what happened so that you can do better next time.',
  },
}

const sectionProperty = 'activity_damage_control'

const initialUserState: DamageControlState = {
  active: false,
  currentStep: 'Step 1',
  'Step 1': null,
  'Step 2A': null,
  'Step 2B': null,
  'Step 2C': null,
  'Step 3': null,
}

const images = [
  { path: require('session/assets/damage_control_1.png'), width: '25%' },
  { path: require('session/assets/damage_control_2.png'), width: '25%' },
  { path: require('session/assets/damage_control_3.png'), width: '25%' },
  { path: require('session/assets/damage_control_4.png'), width: '25%' },
  { path: require('session/assets/damage_control_5.png'), width: '75%' },
  { path: require('session/assets/damage_control_6.png'), width: '25%' },
]

export const DamageControl: React.FC<SectionProps> = ({ section, panel }) => {
  const participantState = useFocusedParticipantState()

  const { dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback(sectionProperty, section, () => ({}))

  const currentUserState =
    participantState && sectionState[participantState.profile.uid]
      ? sectionState[participantState.profile.uid]
      : initialUserState
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const updateSectionState = (state: ActivityDamageControlState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: sectionProperty,
      section_id: section.id,
      state: state,
    })
  }

  const handleBtnClick = () => {
    setModalOpen(true)
  }

  const handleImageSelect = (image: string) => {
    if (!participantState) return
    updateSectionState({
      ...sectionState,
      [participantState.profile.uid]: { ...currentUserState, [currentUserState.currentStep]: image },
    })
    setModalOpen(false)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const checkImageSelected = (path: string) => {
    let isSelected = false
    DamageControlSteps.forEach(key => {
      if (currentUserState[key] === path) isSelected = true
    })
    return isSelected
  }

  return (
    <>
      <Panel
        theme="white"
        flair={[CUT_BR_DARK, CUT_BL_DARK, TAB_B_DARK]}
        paddingTop={0}
        style={{ overflow: 'hidden', backgroundColor: '#2C2C2C', borderBottomColor: '#111' }}>
        <Header>The Damage Control Code</Header>
        <Wrapper>
          <StepSection
            step={currentUserState.currentStep}
            image={currentUserState[currentUserState.currentStep]}
            onAdd={handleBtnClick}
            active={currentUserState.active}
          />
          {currentUserState.currentStep === 'Step 2C' && (
            <NotificationSection
              panel={panel}
              property={'notification_sections'}
              section={{
                id: 0,
                _owner: section._owner,
                _owner_id: section._owner_id,
                type: 'warning',
                text: "If the person won't talk to you, consider writing them a sorry letter, message or email",
                order: 0,
              }}
              index={1}
            />
          )}
        </Wrapper>
      </Panel>
      <ImageSelectModal
        isOpen={modalOpen}
        onSelect={handleImageSelect}
        onClose={handleModalClose}
        checkDisabled={checkImageSelected}
        currentStepState={currentUserState[currentUserState.currentStep]}
      />
    </>
  )
}

const StepSection: React.FC<{ step: DamageControlStep; image: string | null; onAdd: () => void; active: boolean }> = ({
  step,
  image,
  onAdd,
  active,
}) => {
  return (
    <>
      <Title>{stepContent[step].title}</Title>
      <StepSubtitle>{stepContent[step].subtitle}</StepSubtitle>
      <Row>
        <ImageContainer
          onClick={onAdd}
          backgroundImage={image}
          width="300px"
          height="300px"
          style={{ pointerEvents: active ? 'auto' : 'none', cursor: 'pointer', minWidth: 300 }}>
          {!image && <Button children={'Add Image'} onClick={onAdd} disabled={!active} style={{ cursor: 'pointer' }} />}
        </ImageContainer>
        {step === 'Step 2A' && (
          <SideContent>
            <Title>A:</Title>
            <BoldedSubtitle>Try to fix what went wrong.</BoldedSubtitle>
          </SideContent>
        )}
        {step === 'Step 2B' && (
          <SideContent>
            <Title>B:</Title>
            <BoldedSubtitle>Politely ask someone you trust for help.</BoldedSubtitle>
            <TextBlock>
              WAIT: until there’s a pause in what the person is doing or saying. <br />
              (raise your hand in class). <br />
              ASK: When the person looks at you, ask for help calmly and politely. <br />
              THANK: the person for helping you.
            </TextBlock>
          </SideContent>
        )}
        {step === 'Step 2C' && (
          <SideContent>
            <Title>C:</Title>
            <BoldedSubtitle>Say sorry if your words or actions upset someone else.</BoldedSubtitle>
            <TextBlock>
              Saying sorry shows the person that you care about them, and that you didn’t mean to upset them. If you
              detect clues that someone is feeling sad, angry or embarrassed:
            </TextBlock>
            <List>
              <ListItem>
                Say “I’m sorry” with a serious face and voice.{' '}
                <TextWhite>This shows the person that you mean what you’re saying.</TextWhite>
              </ListItem>
              <ListItem>
                Describe what you’re sorry for in 2 sentences or less if you know what this is.{' '}
                <TextWhite>If you don’t know, ask.</TextWhite>
              </ListItem>
              <ListItem>
                No excuses!{' '}
                <TextWhite>
                  Saying “I’m sorry, but...” or “I’m sorry if...” and making excuses for what went wrong or blaming
                  someone else is likely to upset them. They are likely to think that you’re not really sorry for what
                  you did.
                </TextWhite>
              </ListItem>
              <ListItem>
                Offer to make things better <TextWhite>(if possible and appropriate).</TextWhite>
              </ListItem>
            </List>
          </SideContent>
        )}
      </Row>
    </>
  )
}

const ImageSelectModal: React.FC<{
  isOpen: boolean
  onSelect: (image: string) => void
  onClose: () => void
  checkDisabled: (path: string) => boolean
  currentStepState: string | null
}> = ({ isOpen, onSelect, onClose, currentStepState, checkDisabled }) => {
  return (
    <MobileMessageModal
      isOpen={isOpen}
      onRequestClose={onClose}
      panelStyle={{
        backgroundColor: '#2C2C2C',
        borderBottomColor: '#111',
        boxShadow: '0px -4px 10px rgba(255, 255, 255, 0.44)',
      }}>
      <ImageWrapper>
        {images.map((obj, idx) => (
          <ImageContainer
            key={idx}
            onClick={() => {
              onSelect(obj.path)
            }}
            backgroundImage={obj.path}
            width={obj.width}
            height="150px"
            margin={10}
            highlight={currentStepState === obj.path}
            disabled={checkDisabled(obj.path)}
          />
        ))}
      </ImageWrapper>
    </MobileMessageModal>
  )
}

export const DamageControlFacilitator: React.FC<SectionProps> = ({ section, panel }) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback(sectionProperty, section, () => ({}))
  const [confirmResetModalOpen, setConfirmResetModalOpen] = useState<boolean>(false)

  const updateSectionState = (state: ActivityDamageControlState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: sectionProperty,
      section_id: section.id,
      state: state,
    })
  }

  const getUserState = (uid: string) => {
    return sectionState[uid] ? sectionState[uid] : initialUserState
  }

  const handleConfirmReset = (confirmed: boolean) => {
    setConfirmResetModalOpen(false)

    if (confirmed) {
      updateSectionState(
        Object.keys(sectionState).reduce((acc, participantUid) => {
          acc[participantUid] = initialUserState
          return acc
        }, {} as ActivityDamageControlState)
      )
    }
  }

  return (
    <>
      <ActivityInteractionIndicator type="group" />
      <FacilitatorControls title="Activity Controls">
        <FacilitatorControlsTable>
          <thead>
            <tr>
              <th></th>
              {state.participants.map((participant, i) => (
                <th key={i}>{participant.profile.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <RowLocked>
              <td style={{ textAlign: 'left', paddingLeft: 20, paddingRight: 40 }}>Locked</td>
              {state.participants.map((participant, i) => (
                <td key={i} style={{ padding: '10px' }}>
                  <RadioButton
                    value={sectionState[participant.profile.uid] ? !sectionState[participant.profile.uid].active : true}
                    onSelect={() => {
                      const currentUserState = getUserState(participant.profile.uid)
                      updateSectionState({
                        ...sectionState,
                        [participant.profile.uid]: { ...currentUserState, active: false },
                      })
                    }}
                  />
                </td>
              ))}
            </RowLocked>
            <RowUnlocked>
              <td style={{ textAlign: 'left', paddingLeft: 20, paddingRight: 40 }}>Active</td>
              {state.participants.map((participant, i) => (
                <td key={i} style={{ padding: '10px' }}>
                  <RadioButton
                    value={sectionState[participant.profile.uid] ? sectionState[participant.profile.uid].active : false}
                    onSelect={() => {
                      const currentUserState = getUserState(participant.profile.uid)
                      updateSectionState({
                        ...sectionState,
                        [participant.profile.uid]: { ...currentUserState, active: true },
                      })
                    }}
                  />
                </td>
              ))}
            </RowUnlocked>
          </tbody>
        </FacilitatorControlsTable>
      </FacilitatorControls>
      <FacilitatorControls title="Card Controls">
        <Button
          style={{ position: 'absolute', right: 20, top: 10 }}
          theme="purple"
          size="s"
          onClick={() => setConfirmResetModalOpen(true)}
          children="Reset"
        />
        <Spacer />
        <FacilitatorControlsTable>
          <thead>
            <tr>
              <th></th>
              {state.participants.map((participant, i) => (
                <th key={i}>{participant.profile.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {DamageControlSteps.map((stepName, idx) => (
              <RowCard key={idx}>
                <td style={{ textAlign: 'left', paddingLeft: 20, paddingRight: 40 }}>{stepName}</td>
                {state.participants.map((participant, i) => (
                  <td key={i} style={{ padding: '10px' }}>
                    <RadioButton
                      value={
                        sectionState[participant.profile.uid]
                          ? sectionState[participant.profile.uid].currentStep === stepName
                          : stepName === 'Step 1'
                      }
                      onSelect={() => {
                        const currentUserState = getUserState(participant.profile.uid)
                        updateSectionState({
                          ...sectionState,
                          [participant.profile.uid]: { ...currentUserState, currentStep: stepName },
                        })
                      }}
                    />
                  </td>
                ))}
              </RowCard>
            ))}
          </tbody>
        </FacilitatorControlsTable>
      </FacilitatorControls>
      <ConfirmModal
        isOpen={confirmResetModalOpen}
        onSubmit={handleConfirmReset}
        label="Are you sure you want to reset the activity, this will remove all cadet selections?"
      />
    </>
  )
}

const Header = styled.p`
  ${fontBold}
  text-transform: uppercase;
  font-style: italic;
  color: #fff;
  background-color: #4ebe40;
  margin: 0;
  padding: 20px;
`

const Wrapper = styled.div`
  padding: 20px;
`

const Title = styled.h2`
  ${fontBlack}
  color: #4ebe40;
  margin: 0;
  padding-bottom: 10px;
  font-size: 22px;
`

const Subtitle = styled.p`
  color: #fff;
  margin: 0;
  padding-bottom: 20px;
`

const StepSubtitle = styled(Subtitle)`
  ${fontLight}
`

const BoldedSubtitle = styled(Subtitle)`
  ${fontBold}
`

const TextBlock = styled.p`
  ${fontLight}
  color: #fff;
  font-size: 1.2rem;
  margin: 0;
  padding-bottom: 20px;
`

const List = styled.ul`
  margin-left: 0;
  margin-top: 0;
  padding-left: 20px;
`

const ListItem = styled.li`
  ${fontLight}
  color: #4ebe40;
  font-size: 1.2rem;
  margin: 0;
  padding-bottom: 20px;
`

const TextWhite = styled.span`
  color: #fff;
`

const ImageContainer = styled.div<{
  backgroundImage: string | null
  width: string
  height: string
  margin?: number
  highlight?: boolean
  disabled?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => (p.margin ? `calc(${p.width} - ${p.margin * 2}px)` : p.width)};
  height: ${p => (p.margin ? `calc(${p.height} - ${p.margin * 2}px)` : p.height)};
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px #fff;
  border-radius: 10px;
  background-image: url(${p => (p.backgroundImage ? p.backgroundImage : '')});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: ${p => (p.highlight ? '3px solid #4EBE40' : '1px solid #cdd2e4')};
  box-shadow: ${p => (p.highlight ? '0px 0px 14px #4EBE40' : 'none')};
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  opacity: ${p => (p.disabled && !p.highlight ? 0.5 : 1)};
  margin: ${p => (p.margin ? `${p.margin}px` : '0 0 25px 0')};
`

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 600px;
`

const SideContent = styled.div`
  margin-left: 20px;
`

const RowLocked = styled.tr`
  label {
    input:checked + span {
      background: linear-gradient(180deg, #ff2928 0%, #ff7373 100%) !important;
    }
  }
`

const RowUnlocked = styled.tr`
  label {
    input:checked + span {
      background: linear-gradient(180deg, #58b74b 0%, #60c753 100%) !important;
    }
  }
`

const RowCard = styled.tr`
  label {
    input:checked + span {
      background: linear-gradient(180deg, #6c3bbc 0%, #8d57e6 100%) !important;
    }
  }
`
