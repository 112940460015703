import { useState, useEffect, useCallback, useRef } from 'react'

import { Draft, DraftData } from './types'
import { useUserState } from 'app/UserState'
import { useLocalStorage } from 'utils/useStorage'
import debounce from 'lodash/debounce'

export const getDraftKey = (profileId: number, openAccess: boolean, storageKey: string, activityKey?: string) =>
  `${profileId}${openAccess ? '_open_access' : ''}_${storageKey}_${activityKey || ''}_draft`

export function useDraftData<DataType extends DraftData>(
  storageKey: string,
  activityKey?: string
): [DataType, React.Dispatch<React.SetStateAction<DataType>>, () => void] {
  const { openAccess, profileId } = useUserState()
  const draftKey = getDraftKey(profileId, openAccess, storageKey, activityKey)
  const [draft, setDraft] = useLocalStorage<Draft<DataType> | null>(draftKey, null)
  const [data, setData] = useState<DataType>(() => draft?.data || ({} as DataType))
  const mounted = useRef<boolean>(true)
  const debouncedSetDraft = useCallback(
    () =>
      debounce((draft: Draft<DataType> | null) => {
        if (mounted.current) setDraft(draft)
      }, 1000),
    [setDraft]
  )

  useEffect(() => {
    mounted.current = true
    debouncedSetDraft()({ timestamp: Math.floor(Date.now() / 1000), data })
    return () => {
      mounted.current = false
    }
  }, [data, debouncedSetDraft])

  function clearDraft() {
    try {
      window.localStorage.removeItem(draftKey)
    } catch (e) {}
  }

  return [data, setData, clearDraft]
}
