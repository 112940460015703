import React from 'react'
import { Row } from 'common/ui'
import { MediaAsset, SectionPropsBase } from 'shared/session/types'
import { AnchorButton } from 'common/LinkButton'
import { getAssetUrl } from 'session/utils/assetUtils'
import { SectionPropsBase as TipsheetsSectionPropsBase } from 'shared/tipsheets/types'

interface Props extends SectionPropsBase {
  property: 'download_resource_sections'
  printMode?: boolean
}

export const DownloadResourceSection: React.FC<Props> = ({ section, printMode }) => {
  return <DownloadResourceInner asset={section.asset} label={printMode ? 'View Online to Download' : undefined} />
}

export const DownloadResourceInner: React.FC<{ asset: MediaAsset; label?: string }> = ({
  asset,
  label = 'Download',
}) => {
  if (!asset) return null
  return (
    <Row justifyContent="center" paddingTop="l" paddingBottom="l">
      <AnchorButton size="s" target="_blank" href={getAssetUrl(asset)} download={asset.filename} children={label} />
    </Row>
  )
}

export const DownloadResourceSectionFacilitator: React.FC<Props> = props => {
  return <DownloadResourceSection {...props} />
}

interface GenericProps extends TipsheetsSectionPropsBase {
  property: 'download_resource_sections'
}

export const GenericDownloadResourceSection: React.FC<GenericProps> = ({ section }) => (
  <DownloadResourceInner asset={section.asset} />
)
