import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'
import { parentGreen } from 'themes'

import { SessionEntityHydrated, GroupEntity } from 'shared/dashboard/types'
import { SessionModule, ParticipantState } from 'shared/session/types'
import { ParentPrepModuleCode } from 'shared/dashboard/moduleCodes'
import { SessionStateProvider, useSessionState } from 'session/SessionState'
import { UserInputStateProvider } from 'session/UserInputState'
import { ProgressionEventsStateProvider } from 'session/ProgressionEventsState'
import { InputContextProvider } from 'session/InputContext'
import { UPDATE_STATE } from 'shared/session/actionTypes'

import { ModalContextProvider } from 'session/ModalContext'
import { Column, AppBackground } from 'common/ui'
import { ParticipantPlaybackUi } from './ParticipantPlaybackUi'
import { PastSessionSlideHandler } from './PastSessionSlide'
import { ResourceLoadingStatusPanel } from 'dashboards/common/ResourceLoadingStatusPanel'

import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useGenericUser } from 'app/useGenericUser'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'

export const MentorPreparationModule: React.FC = () => {
  useDisableOrientationLock()
  return (
    <ProgressionEventsStateProvider>
      <SessionStateProvider>
        <UserInputStateProvider>
          <InputContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={parentGreen}>
                <MentorPreparationModuleInner />
              </ThemeProvider>
            </ModalContextProvider>
          </InputContextProvider>
        </UserInputStateProvider>
      </SessionStateProvider>
    </ProgressionEventsStateProvider>
  )
}

const MentorPreparationModuleInner: React.FC = () => {
  const { moduleUid, mentorUid } = useParams<{ moduleUid: ParentPrepModuleCode; mentorUid: string }>()
  const { hash } = useLocation()
  const user = useGenericUser()
  const { dispatch: dispatchSessionState, getBaseAction } = useSessionState()
  const { setSessionData, setSessionProfile, setSessionUserType, setPastMode, state } = useSessionState()

  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)
  const wasLoading = useRef<boolean>(true)
  const [module, moduleEndpoint] = useEndpoint<SessionModule>(
    `/api/v1/modules/parent-prep/${moduleUid}?mentor_uid=${mentorUid}`
  )

  const [group, groupEndpoint] = useEndpoint<GroupEntity>(`/api/v1/mentors/${mentorUid}/group`)

  const session: SessionEntityHydrated | null =
    module && group
      ? ({
          id: -1,
          group_id: group.id,
          type: 'parent',
          module_code: moduleUid,
          name: getModuleTitle(moduleUid as ParentPrepModuleCode),
          start_date: '',
          start_time: '',
          start_datetime: Date.now(),
          timezone: '',
          concluded: false,
          catch_up_active: false,
          finalized: false,
          uid: moduleUid,
          deleted: false,
          extra_groups: [],
          session_assistants: [],
          group,
          module,
        } as SessionEntityHydrated)
      : null

  const loading = !moduleEndpoint.loaded || !groupEndpoint.loaded

  useEffect(() => {
    if (!loading && wasLoading.current === true /* everything after this should be inferred... */ && session !== null) {
      wasLoading.current = false

      const startingSlide = hash ? hash.replace('#', '') : null
      window.history.replaceState({}, document.title, window.location.pathname)

      const newParticipantState: ParticipantState = {
        profile: { ...user, userType: 'mentor' },
        status: 'connected',
        locked: true, // important
        tokens: 0,
        inputValues: [],
        currentSlideUid: startingSlide,
        gadgetTrayOpen: false,
        trayType: null,
      }

      ReactDOM.unstable_batchedUpdates(() => {
        if (session) setSessionData(session)
        setPastMode(true)
        setSessionUserType('mentor')
        setSessionProfile({ ...user, userType: 'mentor' })

        // Initialize session state!
        dispatchSessionState({
          ...getBaseAction(),
          type: UPDATE_STATE,
          state: { ...state, sessionUid: session.uid, participants: [newParticipantState] },
        })

        console.log('\n✅ All data received, now processing....\n\n')
      })

      setTimeout(() => {
        console.log('\n✅ Launching session....\n\n')
        setFinishedDelay(true)
      }, 1000)
    }
  })

  if (loading || !finishedDelay)
    return (
      <AppBackground>
        <Column flex="auto" alignItems="center" justifyContent="center">
          <ResourceLoadingStatusPanel
            title="Loading data..."
            resources={[
              { label: 'Identifying user', endpoint: groupEndpoint },
              { label: 'Module content', endpoint: moduleEndpoint },
            ]}
          />
        </Column>
      </AppBackground>
    )

  return (
    <ParticipantPlaybackUi>
      <PastSessionSlideHandler />
    </ParticipantPlaybackUi>
  )
}
