import React from 'react'

import { P, CollapseMargin, Center, Spacer } from 'common/ui'
import { IntroTemplate } from 'home/IntroTemplate'
import { LinkButton } from 'common/LinkButton'

export const ForbiddenPage: React.FC = () => {
  return (
    <IntroTemplate title="Forbidden">
      <CollapseMargin>
        <P align="center">You don't have permission to access this page.</P>
      </CollapseMargin>
      <Spacer size="l" />
      <Center>
        <LinkButton to="/">Continue</LinkButton>
      </Center>
    </IntroTemplate>
  )
}
