import parse from 'date-fns/parse'
import isToday from 'date-fns/isToday'
import { SessionEntity } from 'shared/dashboard/types'

export const getSessionStartDate = <T extends SessionEntity>(session: T): Date | null =>
  session.start_datetime
    ? new Date(session.start_datetime * 1000)
    : session.start_date && session.start_time
    ? parse(`${session.start_date} ${session.start_time}`, 'yyyy-LL-dd HH:mm', Date.now())
    : null

export const sessionIsToday = <T extends SessionEntity>(session: T): boolean => {
  const startDate = getSessionStartDate(session)
  return startDate ? isToday(startDate) : false
}
