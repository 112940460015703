import React, { useEffect, useRef } from 'react'
import { useTransition, animated } from 'react-spring'
import { useMedia } from 'utils/useMedia'
import { touchInputUnavailable } from 'utils/touchUtils'
import { useGlobalState } from './GlobalState'

let instanceCount = 0
export function useDisableOrientationLock(disable: boolean = true) {
  const { orientationLock, setOrientationLock } = useGlobalState()
  const originalOrientationLock = useRef(orientationLock)
  useEffect(() => {
    // only set orientation lock if first instance test
    if (disable && !instanceCount++) setOrientationLock(false)
    if (!disable && !orientationLock) {
      originalOrientationLock.current = orientationLock
      setOrientationLock(true)
    }
    return () => {
      // only reset orientation lock if last remaining instance
      if (disable && !--instanceCount) setOrientationLock(true)
      if (!disable && orientationLock) setOrientationLock(originalOrientationLock.current)
    }
  }, [setOrientationLock, disable, orientationLock])
}

export function usePortraitWidthTrigger(width: number = 1024) {
  const { portraitWidthTrigger, setPortraitWidthTrigger } = useGlobalState()
  const originalPortraitWidthTrigger = useRef(portraitWidthTrigger)
  useEffect(() => {
    if (width !== portraitWidthTrigger) {
      originalPortraitWidthTrigger.current = portraitWidthTrigger
      setPortraitWidthTrigger(width)
    }
    return () => {
      if (originalPortraitWidthTrigger.current && originalPortraitWidthTrigger.current !== portraitWidthTrigger) {
        setPortraitWidthTrigger(originalPortraitWidthTrigger.current)
      }
    }
  }, [width, portraitWidthTrigger, setPortraitWidthTrigger])
}

export const OrientationPrompt: React.FC = () => {
  const { orientationLock, portraitWidthTrigger } = useGlobalState()
  const isSlimPortrait = useMedia(`(max-width:${portraitWidthTrigger}px) and (orientation:portrait)`)
  const show = orientationLock && !touchInputUnavailable && isSlimPortrait
  const transitions = useTransition(show, null, {
    from: { opacity: 0, pointerEvents: 'none' },
    enter: { opacity: 1, pointerEvents: 'auto' },
    leave: { opacity: 0, pointerEvents: 'none' },
  })
  return (
    <>
      {transitions.map(
        ({ item, props, key }) =>
          item && (
            <animated.div
              key={key}
              style={{
                ...props,
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 1000,
                backgroundColor: 'rgba(0,0,0,0.9)',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211 176" style={{ maxWidth: 210 }}>
                  <path
                    fill="none"
                    stroke="#fff"
                    strokeMiterlimit={10}
                    strokeWidth={1.7}
                    d="M37 154H7a7 7 0 01-6-6V7a7 7 0 016-6h89a7 7 0 017 6v52"
                  />
                  <rect
                    width="101.8"
                    height="161.5"
                    x="78"
                    y="43"
                    fill="none"
                    stroke="#fff"
                    strokeMiterlimit="10"
                    strokeWidth="1.7"
                    rx="12"
                    ry="12"
                    transform="rotate(90 129 124)"
                  />
                  <path
                    fill="none"
                    stroke="#fff"
                    strokeMiterlimit="10"
                    strokeWidth="1.7"
                    d="M138 53l6 6 5-5M73 93h111v62H73zM184 104H73"
                  />
                  <path
                    fill="none"
                    stroke="#fff"
                    strokeMiterlimit="10"
                    strokeWidth="1.7"
                    d="M118 27c14 0 26 8 26 26v6"
                  />
                </svg>
                <p style={{ fontSize: 24 }}>Please rotate device</p>
              </div>
            </animated.div>
          )
      )}
    </>
  )
}
