import React, { useRef, useReducer, useState, useContext } from 'react'
import { ActionTypes, UPDATE_STATE, UPDATE_TIPSHEET_PAGE } from 'shared/tipsheets/actionTypes'
import { TipsheetData, TipsheetState, PreviewState, TipsheetStateHookObject } from 'shared/tipsheets/types'

function reducer(state: TipsheetState, action: ActionTypes): TipsheetState {
  switch (action.type) {
    case UPDATE_STATE:
      return action.state

    /* Trigger these actions for perceived snappy performance */
    case UPDATE_TIPSHEET_PAGE:
      return {
        ...state,
        currentStepUid: action.stepUid,
      }
  }
}

const initialState: TipsheetState = {
  currentStepUid: null,
}

const getInitialState = (tipsheetData: TipsheetData | false) => {
  const newState: TipsheetState = { ...initialState }
  return newState
}

function useProviderTipsheetState() {
  const [tipsheetData, setTipsheetData] = useState<TipsheetData | false>(false)
  const [previewing, setPreviewing] = useState<boolean>(false)
  const [previewState, setPreviewState] = useState<PreviewState>({ stepIndex: 0 })
  const [state, dispatch] = useReducer(reducer, getInitialState(tipsheetData))

  const persistentState = useRef(state)

  const preDispatch = (action: ActionTypes) => {
    console.log('DISPATCHING ACTION', action)
    persistentState.current = reducer(persistentState.current, action)
    dispatch(action)
  }

  return {
    state,
    tipsheetData: tipsheetData,
    setTipsheetData: setTipsheetData,
    dispatch: preDispatch,
    previewing,
    setPreviewing,
    previewState,
    setPreviewState,
  }
}

function noop(): any {}

export const TipsheetStateContext = React.createContext<TipsheetStateHookObject>({
  state: initialState,
  tipsheetData: false,
  setTipsheetData: noop,
  dispatch: noop,
  previewing: false,
  setPreviewing: noop,
  previewState: { stepIndex: 0 },
  setPreviewState: noop,
})

export const TipsheetStateProvider: React.FC = ({ children }) => {
  const state = useProviderTipsheetState()
  return <TipsheetStateContext.Provider value={state}>{children}</TipsheetStateContext.Provider>
}

export function useTipsheetState() {
  return useContext(TipsheetStateContext)
}
