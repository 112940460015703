import React, { forwardRef, PropsWithChildren, CSSProperties } from 'react'
import styled from 'styled-components'
import { useDrop } from 'react-dnd'
import { fontBlack } from 'fonts'
import { CharacterAvatarDragItem, DRAG_TYPE_PLAYER_PIECE } from './CharacterAvatar'
import { buttonFlash } from 'common/ui'

type StartAreaProps = PropsWithChildren<{}>
export const StartArea = forwardRef<HTMLDivElement, PropsWithChildren<StartAreaProps>>(({ children }, ref) => {
  return <StartAreaStyled ref={ref}>{children}</StartAreaStyled>
})

type FinishAreaProps = PropsWithChildren<{ highlight?: boolean }>
export const FinishArea = forwardRef<HTMLDivElement, FinishAreaProps>(({ children, highlight }, ref) => {
  return (
    <FinishAreaStyled ref={ref} highlight={highlight}>
      {children}
    </FinishAreaStyled>
  )
})

const StartFinishBase = styled.div`
  position: relative;
  padding: 0.5em;

  & label {
    position: absolute;
    display: inline-block;
    top: 50%;
    ${fontBlack}
    line-height: 1;
    padding-top: 0.5em;
    border-top: 1px solid currentColor;
    transform-origin: 50% 0%;
  }
`

const StartAreaStyled = styled(StartFinishBase)`
  background-color: #3c3e42;
  color: white;

  & label {
    right: 0;
    transform: rotate(-90deg);
  }
`

const FinishAreaStyled = styled(StartFinishBase)<{ highlight?: boolean }>`
  background-color: #edf2fa;

  & label {
    left: 0;
    transform: rotate(90deg);
  }

  &::before {
    content: ${p => (p.highlight ? '""' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 0px 5px 5px ${p => p.theme.highlightColor};
    animation: ${buttonFlash} 0.5s linear infinite alternate;
    z-index: 1;
  }
`

export type DroppableFinishAreaProps = FinishAreaProps & { onDrop: (item: CharacterAvatarDragItem) => void }

export const DroppableFinishArea = forwardRef<HTMLDivElement, DroppableFinishAreaProps>(
  ({ onDrop, children, ...props }, ref) => {
    const [{ itemIsOver, itemBeingDragged }, drop] = useDrop({
      accept: DRAG_TYPE_PLAYER_PIECE,
      drop: onDrop,
      collect: monitor => ({
        itemIsOver: monitor.isOver(),
        itemBeingDragged: monitor.canDrop(),
      }),
    })
    const style: CSSProperties = {
      marginLeft: '-0.5em',
      marginTop: '-0.5em',
      width: 'calc(100% + 1em)',
      height: 'calc(100% + 1em)',
      boxSizing: 'border-box',
      border: itemIsOver ? '3px solid white' : 'none', //itemBeingDragged ? '2px dashed white' : 'none',
      boxShadow: itemIsOver ? 'inset 0 0 8px 0 rgba(0,0,0,0.5)' : undefined,
    }
    if (itemBeingDragged) {
      style.position = 'relative'
      style.zIndex = 99
    }
    return (
      <FinishArea ref={ref} {...props}>
        {children}
        <div ref={drop} style={style} />
      </FinishArea>
    )
  }
)
