import React from 'react'
import styled from 'styled-components'
import { IdScreen, ContainerRow, BigRedText, IdPanel } from 'gadget-pack/ID'
import { Column, Padding } from 'common/ui'
import { EmbeddedActivitySection } from 'shared/session/types'
import { AutoAddProgressionEvent, useProgressionEventsState } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK, objShapeMatches } from 'shared/progressionEvents'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'
import { useSessionState } from 'session/SessionState'

export const IdCardActivity: React.FC<{ section: EmbeddedActivitySection }> = ({ section }) => {
  const { addProgressionEvent, progressionEvents } = useProgressionEventsState()
  const participantUid = useParticipantProfileUid()
  const { pastMode } = useSessionState()
  let cardFlipUnlocked = false

  for (let testProgressionEvent of progressionEvents) {
    if (
      objShapeMatches(
        {
          type: GADGET_PACK_UNLOCK,
          category: 'id-card',
          subcategory: 'enable-flip',
          event_key: null,
          event_value: null,
        },
        testProgressionEvent
      )
    ) {
      cardFlipUnlocked = true
    }
  }

  if (section.id_card_flippable && participantUid && !cardFlipUnlocked) {
    addProgressionEvent({
      participant_uid: participantUid,
      type: GADGET_PACK_UNLOCK,
      category: 'id-card',
      subcategory: 'enable-flip',
      event_key: null,
      event_value: null,
    })
  }

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${participantUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'id-card',
          subcategory: null,
          event_key: null,
          event_value: null,
        }}
      />
      <IdCardActivityContainer frontMarginTop={section.id_card_flippable ? -90 : -35} backMarginTop={-50}>
        <IdScreen
          hideTitle
          hideExitButton
          hideMediaButtons
          flippable={section.id_card_flippable}
          readOnly={pastMode}
          inputLockProps={{
            fieldUid: `${participantUid}_id_card`,
          }}
        />
      </IdCardActivityContainer>
    </>
  )
}

const IdCardActivityContainer = styled.div<{ frontMarginTop: number; backMarginTop: number }>`
  position: relative;
  background-color: #fff;
  border: 1px solid #cdd2e4;
  border-radius: 10px;

  & > ${Padding} > ${Column} {
    max-width: 100%;
  }
  & ${ContainerRow} {
    min-height: 650px;
    & > ${IdPanel} {
      &.front-side {
        margin-top: ${p => p.frontMarginTop}px;
      }
      &.back-side {
        margin-top: ${p => p.backMarginTop}px;
      }
    }
  }
  & ${BigRedText} {
    font-size: 2em;
  }
`
