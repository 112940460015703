import React, { useEffect, useState } from 'react'
import { useVideo } from 'utils/useVideo'
import { isDevEnv } from 'utils/envUtils'
import { useVideoEnded } from 'utils/useVideoEnded'
import { Absolute } from './ui'
import { VideoContainer } from 'app/VideoContainer'
import { FadeFromBlack } from './FadeTransitions'
import { ShortcutMenu } from './ShortcutMenu'
import { AspectRatio } from './AspectRatio'
import ExitButton from './ExitButton'
import { useVideoPlaying } from 'utils/useVideoPlaying'

interface Props {
  onComplete?: () => void
  src: string
  fadeIn?: boolean
  exitButton?: boolean
  confirmExit?: boolean
  preloadAssets?: () => void
}

export const AutoProgressVideo: React.FC<Props> = ({
  src,
  onComplete = () => {},
  fadeIn = false,
  exitButton = true,
  confirmExit = true,
  preloadAssets,
}) => {
  const video = useVideo(src)
  const playing = useVideoPlaying(video)
  const [preloaded, setPreloaded] = useState<boolean>(false)
  useVideoEnded(video, onComplete)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (playing && !preloaded && preloadAssets) {
      console.log('preloading next screen audio')
      preloadAssets()
      setPreloaded(true)
    }
  }, [playing])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <>
      <Absolute cover style={{ backgroundColor: 'black' }}>
        <AspectRatio ratio={16 / 9}>
          <VideoContainer ratio={16 / 9} video={video} />
        </AspectRatio>
        {exitButton && (
          <Absolute style={{ top: 30, right: 30 }}>
            <ExitButton confirm={confirmExit} />
          </Absolute>
        )}
        {(isDevEnv() || window.location.host === 'dev.sas') && (
          <Absolute style={{ right: 0, width: 100, height: 100 }} onClick={onComplete}></Absolute>
        )}
      </Absolute>
      {fadeIn && <FadeFromBlack />}
      <ShortcutMenu />
    </>
  )
}
