import React, { useState, useRef, Fragment, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { useParams, Switch, Route, useRouteMatch } from 'react-router-dom'
import { blue } from 'themes'

import { SessionEntityHydrated } from 'shared/dashboard/types'
import { SessionProfile, SessionModule, SavedInputValue } from 'shared/session/types'
import { SessionStateProvider, useSessionState } from 'session/SessionState'
import { UserInputStateProvider, useUserInputState } from 'session/UserInputState'
import { ProgressionEventsStateProvider } from 'session/ProgressionEventsState'
import { InputContextProvider } from 'session/InputContext'
import { SET_SAVED_USER_INPUT_VALUES, REGISTER_PARTICIPANTS } from 'shared/session/actionTypes'

import { ModalContextProvider } from 'session/ModalContext'
import { Panel, Column, H2, P, AppBackground } from 'common/ui'
import { RouteLeavePrompt } from 'common/RouteLeavePrompt'
import { NotFoundPage } from 'home/NotFoundPage'
import { Spinner, SpinnerIndeterminate } from 'common/Spinner'
import { SessionSlideHandler } from 'session/SessionSlide'
import { OfflineMessage } from 'session/OfflineMessage'
import { FacilitatorUi } from 'session/FacilitatorUi'

import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useGenericUser } from 'app/useGenericUser'
import { FacManModule } from 'shared/fac-man/types'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { processErrorList } from 'dashboards/utils/reducers'
import { useSessionStorage } from 'utils/useStorage'

export const FacilitatorMeeting: React.FC = () => {
  useDisableOrientationLock()
  return (
    <ProgressionEventsStateProvider>
      <SessionStateProvider>
        <UserInputStateProvider>
          <InputContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={blue}>
                <FacilitatorMeetingInner />
                <OfflineMessage />
              </ThemeProvider>
            </ModalContextProvider>
          </InputContextProvider>
        </UserInputStateProvider>
      </SessionStateProvider>
    </ProgressionEventsStateProvider>
  )
}

const FacilitatorMeetingInner: React.FC = () => {
  const { url } = useRouteMatch() || {}
  const { sessionUid } = useParams<{ sessionUid: string }>()
  const user = useGenericUser()
  const { dispatch: dispatchInputState } = useUserInputState()
  const [, setLastAccessedGroup] = useSessionStorage('lastGroup')
  const {
    pastMode,
    previewMode,
    presentationMode,
    sessionData,
    socket,
    dispatch: dispatchSessionState,
    getBaseAction,
    setSessionData,
    setSessionProfile,
    setSessionUserType,
    setManual,
    isAssistant,
  } = useSessionState()

  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)
  const wasLoading = useRef<boolean>(true)
  const [
    session,
    {
      loaded: loadedSessionData,
      loading: loadingSessionData,
      errorLoading: errorLoadingSessionData,
      errorMessage: errorMessageSessionData,
    },
  ] = useEndpoint<SessionEntityHydrated & { module: SessionModule }>(`/api/v1/sessions/${sessionUid}`)
  const [
    inputValues,
    {
      loaded: loadedInputValues,
      loading: loadingInputValues,
      errorLoading: errorLoadingInputValues,
      errorMessage: errorMessageInputValues,
    },
  ] = useEndpoint<SavedInputValue<any>[]>(`/api/v1/session_input_values?session_uid=${sessionUid}`)
  const [
    facManual,
    {
      loaded: loadedFacManual,
      loading: loadingFacManual,
      errorLoading: errorLoadingFacManual,
      errorMessage: errorMessageFacManual,
    },
  ] = useEndpoint<FacManModule>(
    session && !isAssistant ? `/api/v1/manual_modules/${session.type}/${session.module_code}` : null
  )
  const isCadetSession = session ? session.type === 'cadet' : false
  const [
    cadets,
    {
      loaded: loadedCadets,
      loading: loadingCadets,
      errorLoading: errorLoadingCadets,
      errorMessage: errorMessageCadets,
    },
  ] = useEndpoint<SessionProfile[]>(
    session && session.type === 'cadet' && !isAssistant ? `/api/v1/sessions/${session.uid}/cadets` : null
  )

  const loading =
    !loadedSessionData ||
    !loadedInputValues ||
    !(isAssistant ? true : loadedFacManual) ||
    !(!isCadetSession || isAssistant ? true : loadedCadets)
  const errorMessages = processErrorList([
    errorMessageSessionData,
    errorMessageInputValues,
    errorMessageFacManual,
    errorMessageCadets,
  ])

  useEffect(() => {
    if (
      !loading &&
      wasLoading.current === true /* everything after this should be inferred... */ &&
      (isCadetSession && !isAssistant ? cadets !== null : true) &&
      inputValues !== null &&
      session !== null
    ) {
      wasLoading.current = false

      // Update static state data
      ReactDOM.unstable_batchedUpdates(() => {
        setSessionUserType('facilitator')
        setSessionProfile({ ...user, userType: 'facilitator' })
        setLastAccessedGroup(session.group_id)
        if (facManual) setManual(facManual)
        if (session) setSessionData(session)
      })

      const dispatchSocketActions = () => {
        console.log('😭😭😭 Dispatching init socket actions')
        if (cadets) {
          dispatchSessionState({
            ...getBaseAction(),
            type: REGISTER_PARTICIPANTS,
            sessionUid: session.uid,
            participantProfiles: cadets,
          })
        }

        dispatchInputState({
          user_uid: user.uid,
          role: user.userType,
          timestamp: Date.now(),
          type: SET_SAVED_USER_INPUT_VALUES,
          inputValues,
        })
      }

      if (socket) {
        if (!socket.connected) {
          console.log('😭😭😭 Socket not connected yet, waiting...')
          socket.on('connect', () => setTimeout(() => dispatchSocketActions()))
        } else {
          console.log('🙃🙃🙃 Socket is ready and waiting!')
          dispatchSocketActions()
        }
      } else {
        console.log('😭😭😭 Socket has not been created....')
      }

      // prettier-ignore
      setTimeout(() => { setFinishedDelay(true) }, 1000)
    }
  })

  if (loading || !finishedDelay)
    return (
      <AppBackground theme="purple">
        <Column flex="auto" alignItems="center" justifyContent="center">
          <Panel
            padding="l"
            style={{
              transition: 'opacity 1s linear',
              opacity: loading ? 1 : 0,
              minWidth: 300,
            }}>
            <H2 marginBottom="m">Loading meeting data...</H2>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 30, height: 30 }}>
                    {loadingSessionData && <Spinner thickness={0.2} />}
                    {loadedSessionData && (
                      <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />
                    )}
                    {errorLoadingSessionData && (
                      <img src={require('reporting/assets/images/dash-circle.svg')?.default} alt="" />
                    )}
                  </td>
                  <td>Meeting content</td>
                </tr>
                <tr>
                  <td style={{ width: 30, height: 30 }}>
                    {loadingInputValues && <Spinner thickness={0.2} />}
                    {loadedInputValues && (
                      <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />
                    )}
                    {errorLoadingInputValues && (
                      <img src={require('reporting/assets/images/dash-circle.svg')?.default} alt="" />
                    )}
                  </td>
                  <td>Input and drawings</td>
                </tr>
                {!isAssistant && (
                  <tr>
                    <td style={{ width: 30, height: 30 }}>
                      {!session && !loadingFacManual && <SpinnerIndeterminate />}
                      {loadingFacManual && <Spinner thickness={0.2} />}
                      {loadedFacManual && (
                        <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />
                      )}
                      {errorLoadingFacManual && (
                        <img src={require('reporting/assets/images/dash-circle.svg')?.default} alt="" />
                      )}
                    </td>
                    <td>Facilitator manual content</td>
                  </tr>
                )}
                {isCadetSession && !isAssistant && (
                  <tr>
                    <td style={{ width: 30, height: 30 }}>
                      {!session && !loadingCadets && <SpinnerIndeterminate />}
                      {loadingCadets && <Spinner thickness={0.2} />}
                      {loadedCadets && <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />}
                      {errorLoadingCadets && (
                        <img src={require('reporting/assets/images/dash-circle.svg')?.default} alt="" />
                      )}
                    </td>
                    <td>Loading cadet list</td>
                  </tr>
                )}
              </tbody>
            </table>
            {errorMessages.length > 0 && (
              <>
                <H2 marginTop={30} marginBottom={0}>
                  Error
                </H2>

                <P>
                  {errorMessages.map((str, i) => (
                    <Fragment key={i}>
                      {str}
                      <br />
                    </Fragment>
                  ))}
                </P>
              </>
            )}
          </Panel>
        </Column>
      </AppBackground>
    )

  return (
    <>
      <FacilitatorUi>
        <Switch>
          <Route path={`${url}`} exact>
            <SessionSlideHandler />
          </Route>
          <Route path={`${url}/slide/:slideUid`} exact>
            <SessionSlideHandler />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </FacilitatorUi>
      {sessionData && (
        <RouteLeavePrompt
          when={
            !previewMode &&
            !pastMode &&
            !presentationMode &&
            !sessionData.concluded &&
            !window.location.hostname.match(/^localhost/)
          }
          message={
            sessionData.catch_up_active
              ? `Warning: Even though this is a catch-up session you should still mark attendance before leaving the meeting.\nLeave anyway?`
              : `Warning: Leaving this meeting before marking attendance on the end-screen will require you to come back and do it at a later time.\nLeave anyway?`
          }
        />
      )}
    </>
  )
}
