import React, { useRef, useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import styled, { ThemeProvider } from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import { blue, orange, parentGreen, purple, Theme } from 'themes'
import { fontRegular } from 'fonts'

import { NavRouteProps } from 'dashboards/types'
import { SessionModule } from 'shared/session/types'
import { FacManStep } from './FacManStep'
import { Column, Container, H1, H2, H3, Row, Spacer } from 'common/ui'
import { FacManModuleStep, FacManModule } from 'shared/fac-man/types'
import { CircleButton } from 'session/common/CircleButton'
import { SpinnerWithLabel } from 'common/Spinner'
import { RouteNotFound } from 'dashboards/common/RouteNotFound'

import { getManualModuleByCode } from 'api'
import { useUserState } from 'app/UserState'
import { useFacilitatorState } from 'session/hooks/useProfileState'
import { baseUrl } from 'dashboards/facilitator/FacilitatorDashboard'

const themeOptions: { [key: string]: Theme } = {
  cadet: blue,
  parent: parentGreen,
  teacher: orange,
  facilitator: purple,
  default: purple,
}

interface UrlParams {
  moduleType: SessionModule['type']
  moduleCode: string
}

interface Props extends NavRouteProps {
  hideHeader?: boolean
}

export const FacManModuleView: React.FC<Props> = ({ hideHeader = false }) => {
  const { accessToken } = useUserState()
  const { moduleType, moduleCode } = useParams<UrlParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [facManModule, setFacManModule] = useState<FacManModule | null>(null)

  useEffect(() => {
    getManualModuleByCode(moduleType, moduleCode, accessToken)
      .then(manualModule => {
        ReactDOM.unstable_batchedUpdates(() => {
          setFacManModule(manualModule)
          setLoading(false)
        })
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {facManModule ? (
        <FacManual hideHeader={hideHeader} facManModule={facManModule} />
      ) : loading ? (
        <SpinnerWithLabel color="#925BED" label="One moment please..." />
      ) : (
        <RouteNotFound />
      )}
    </>
  )
}

export const FacManTrayView: React.FC<{ hideHeader?: boolean; facManModule: FacManModule }> = props => {
  const facilitatorState = useFacilitatorState()
  if (!facilitatorState) return null
  const { currentManualStepUid } = facilitatorState
  return <FacManual {...props} targetStep={currentManualStepUid || undefined} />
}

const FacManual: React.FC<{ hideHeader?: boolean; facManModule: FacManModule; targetStep?: string }> = ({
  hideHeader = false,
  facManModule,
  targetStep,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const theme = facManModule ? themeOptions[facManModule.type] : themeOptions.default
  const anchorRefs = useRef<{ [key: string]: HTMLDivElement }>({})
  const containerRef = useRef<HTMLDivElement>(null)
  const [nextAnchor, setNextAnchor] = useState<HTMLDivElement | null>(null)
  const [animating, setAnimating] = useState<boolean>(false)

  const [springProps, setSpringValues] = useSpring(() => ({
    immediate: false,
    config: { tension: 210 },
    scrollTop: 0,
    onRest: () => {
      setAnimating(false)
    },
  }))

  const scrollToStep = useCallback(
    (key: string) => {
      ReactDOM.unstable_batchedUpdates(() => {
        if (anchorRefs.current) setNextAnchor(anchorRefs.current[key])
        if (!hideHeader) setExpanded(false)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideHeader]
  )

  const handleMouseWheel = () => {
    const container = containerRef.current
    if (animating && container) {
      ReactDOM.unstable_batchedUpdates(() => {
        setSpringValues({
          immediate: true,
          scrollTop: container.scrollTop,
        })
        setAnimating(false)
      })
    }
  }

  useEffect(() => {
    if (targetStep) {
      scrollToStep(targetStep)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetStep])

  useEffect(() => {
    const container = containerRef.current
    if (nextAnchor && container) {
      ReactDOM.unstable_batchedUpdates(() => {
        setAnimating(true)
        // this set prevents spring from jumping to previous location before animation
        setSpringValues({
          immediate: true,
          scrollTop: container.scrollTop,
        })
        setSpringValues({
          immediate: false,
          scrollTop: nextAnchor.offsetTop - (!hideHeader ? 150 : 30),
        })
        setNextAnchor(null)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextAnchor])

  return (
    <ThemeProvider theme={theme}>
      <FacManWrapper>
        {!hideHeader && (
          <HeaderWrapper background={theme.buttonBorderTopColor}>
            <Container style={{ position: 'relative' }}>
              <Column alignItems="flex-end">
                <Link to={`${baseUrl}/manual`} style={{ color: '#fff' }} children={'< Back'} />
                <Spacer />
              </Column>
              <Header>
                <H1>{facManModule.title}</H1>
                <Row>
                  <ButtonLabel>Contents</ButtonLabel>
                  <Spacer size="m" />
                  <CircleButton size="s" children={expanded ? '▲' : '▼'} onClick={() => setExpanded(!expanded)} />
                </Row>
              </Header>
              {expanded && (
                <Dropdown>
                  <ul>
                    {facManModule.manual_steps.map((stepDetails: FacManModuleStep, idx: number) => {
                      return (
                        <li key={idx}>
                          <div onClick={() => scrollToStep(stepDetails.uid)}>
                            <span>{stepDetails.title}</span>
                            <span>‣</span>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </Dropdown>
              )}
            </Container>
          </HeaderWrapper>
        )}
        <Wrapper
          ref={containerRef}
          {...springProps}
          background="#925BED"
          fixedPosition={!hideHeader}
          onWheel={handleMouseWheel}>
          {facManModule.manual_steps.map((stepDetails: FacManModuleStep, idx: number) => {
            return (
              <Container ref={el => (el !== null ? (anchorRefs.current[stepDetails.uid] = el) : '')} key={idx}>
                {stepDetails.show_section_header && (
                  <>
                    <FacManSectionHeader children={stepDetails.section_header_text} />
                    <Spacer size="m" />
                  </>
                )}
                <FacManStep step={stepDetails} />
                <Spacer size="m" />
              </Container>
            )
          })}
        </Wrapper>
      </FacManWrapper>
    </ThemeProvider>
  )
}

const FacManWrapper = styled.div`
  position: relative;
  height: 100%;
  flex: auto;

  ${H1}, ${H2}, ${H3} {
    text-transform: uppercase;
  }
`

const WrapperStyle = styled.div<{ background: string; fixedPosition: boolean }>`
  background-color: ${p => p.background};
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  padding-top: ${p => (p.fixedPosition ? '175px' : '30px')};
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
`

const Wrapper = animated(WrapperStyle)

const HeaderWrapper = styled.div<{ background: string }>`
  background-color: ${p => p.background};
  padding: 15px 30px 30px 30px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;

  ${H1} {
    color: ${p => p.theme.buttonBorderTopColor};
    line-height: 30px;
  }
`

const ButtonLabel = styled(H1)`
  font-size: 0.8rem !important;
`

const Dropdown = styled.div`
  position: absolute;
  z-index: 9;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #464b61;
  padding-top: 15px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    &:last-of-type {
      div {
        border-bottom: none;
      }
    }

    &:first-of-type {
      div {
        padding-top: 15px;
      }
    }

    div {
      ${fontRegular};
      color: #fff;
      padding: 10px 15px;
      border-bottom: 1px solid #535667;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: #393c4e;
      }

      span:last-of-type {
        font-size: 2rem;
        margin-right: 20px;
        line-height: 0;
      }
    }
  }
`

export const FacManSectionHeader = styled(H1)`
  background-color: ${p => p.theme.buttonBorderTopColor};
  color: #fff;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
`
