import { GameObjects } from 'phaser'
import { sortByKey } from './sortUtils'
import { SortType } from 'types'

type GameObjectsWithDepth = GameObjects.Image | GameObjects.Graphics

export const getNextHighestDepth = (objects: GameObjectsWithDepth[] = [], baseDepth: number = 0): number => {
  if (!objects.length) return baseDepth
  return Math.max(...objects.map(({ depth }) => depth)) + 1
}

export const getNextLowestDepth = (objects: GameObjectsWithDepth[] = [], baseDepth: number = 0): number => {
  if (!objects.length) return baseDepth
  return Math.min(...objects.map(({ depth }) => depth)) - 1
}

export const sortObjectsByDepth = <T extends GameObjectsWithDepth>(
  objects: T[],
  direction: SortType = 'ascending'
): T[] => objects.sort(sortByKey<T>('depth', direction))

export function getObjectAbove<T extends GameObjectsWithDepth>(object: T, allObjects: T[]): T | false {
  const objects = sortObjectsByDepth(allObjects.filter(_object => _object !== object && _object.depth > object.depth))
  if (!objects.length) return false
  return objects[0]
}

export function getObjectBelow<T extends GameObjectsWithDepth>(object: T, allObjects: T[]): T | false {
  const objects = sortObjectsByDepth(
    allObjects.filter(_object => _object !== object && _object.depth < object.depth),
    'descending'
  )
  if (!objects.length) return false
  return objects[0]
}

export function swapDepths(object1: GameObjectsWithDepth, object2: GameObjectsWithDepth): void {
  const object1depth = object1.depth
  object1.depth = object2.depth
  object2.depth = object1depth
}
