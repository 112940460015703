import { ActivitySecretTransmissionState } from '../../../types'
import { ActionTypes, FLIP_CARD, NEXT_ROUND, SELECT_CARD } from './actionTypes'

const getUserState = (
  state: ActivitySecretTransmissionState,
  participantUid: keyof ActivitySecretTransmissionState
) => {
  return (
    state[participantUid] || {
      selection: null,
      previousCards: [],
      flippedCards: {},
    }
  )
}

export function reducer(state: ActivitySecretTransmissionState, action: ActionTypes): typeof state {
  if (!state) state = {}

  switch (action.type) {
    case FLIP_CARD:
      const currentUserState = getUserState(state, action.participantUid)

      return {
        ...state,
        [action.participantUid]: {
          ...currentUserState,
          flippedCards: {
            ...currentUserState.flippedCards,
            [action.cardUid]: currentUserState.flippedCards[action.cardUid]
              ? !currentUserState.flippedCards[action.cardUid]
              : true,
          },
        },
      }

    case SELECT_CARD:
      const currentParticipantState = getUserState(state, action.participantUid)
      let a = {
        ...state,
        [action.participantUid]: {
          ...currentParticipantState,
          selection: currentParticipantState.selection === action.cardUid ? null : action.cardUid,
        },
      }
      return a

    case NEXT_ROUND:
      return action.participants.reduce((acc, participant) => {
        const currentParticipantState = getUserState(state, participant.profile.uid)

        acc[participant.profile.uid] = {
          ...currentParticipantState,
          previousCards: [...currentParticipantState.previousCards, action.deckState[participant.profile.uid].card_uid],
        }
        return acc
      }, {} as ActivitySecretTransmissionState)

    default:
      return state
  }
}
