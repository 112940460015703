import React from 'react'
import styled from 'styled-components'
import { Button, Row, Spacer, H2 } from 'common/ui'
import { SIDEBAR_WIDTH } from './FacilitatorUi'
import { useSessionState } from './SessionState'

export const PREVIEW_HEADER_HEIGHT = 50

export const PreviewHeader: React.FC<{}> = () => {
  const { previewMode, setPreviewMode } = useSessionState()
  return (
    <PreviewHeaderContainer alignItems="center">
      <div style={{ width: SIDEBAR_WIDTH, textAlign: 'center' }}>
        <H2 style={{ color: 'white' }}>Preview mode</H2>
      </div>
      <Row flex="1 1 auto" justifyContent="center">
        <Button
          size="xs"
          theme={previewMode === 'participant' ? 'blue' : 'white'}
          children="Participant"
          onClick={() => previewMode !== 'participant' && setPreviewMode('participant')}
        />
        <Spacer size="s" />
        <Button
          size="xs"
          theme={previewMode === 'facilitator' ? 'blue' : 'white'}
          children="Facilitator"
          onClick={() => previewMode !== 'facilitator' && setPreviewMode('facilitator')}
        />
      </Row>
    </PreviewHeaderContainer>
  )
}

const PreviewHeaderContainer = styled(Row)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: ${PREVIEW_HEADER_HEIGHT}px;
  background-color: #222;
`
