import { MentorEntity, MentorEntityNew } from 'shared/dashboard/types'

export const getPrimaryMentorFromAnyMentor = (mentor: MentorEntity | MentorEntityNew) => {
  if (mentor.primary_licence_holder === true) return mentor
  if (mentor.primary_mentor) return mentor.primary_mentor
  return null
}

const permissionsLabelMap: { [key in keyof Partial<MentorEntity>]: string } = {
  permission_add_mentors: 'Add Other Mentors',
  permission_skill_tracker: 'Skill Tracker',
  permission_gadget_pack: 'Cadet Gadget Pack',
  permission_tip_sheets: 'Teacher Tip Sheets',
  permission_teacher_questionnaires: 'Teacher Questionnaires',
  permission_parent_questionnaires: 'Parent Questionnaires',
  permission_live_parent_sessions: 'Parent Group Meetings & Reminders',
  permission_past_parent_sessions: 'Parent Meeting Notes',
  permission_past_cadet_sessions: 'Cadet Club Journal',
  permission_reporting: 'Cadet Progress & Club Meeting Reminders',
  //   permission_game_code: '',
}

export const getMentorPermissionListLabels = (mentor: MentorEntity): string[] => {
  const labels = (Object.keys(permissionsLabelMap) as Array<keyof typeof permissionsLabelMap>).map(key =>
    mentor[key] === true ? permissionsLabelMap[key] : false
  ) as (string | false)[]
  return labels.filter(val => val) as string[]
}
