import React from 'react'
import styled from 'styled-components'

export const UnderConstruction: React.FC = ({ children }) => {
  return <Wrapper children={children} />
}

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url('${require('../assets/under-construction.gif')}');
        background-repeat: repeat-x;
        background-position: center;
        background-size: 100% auto;
        transform: rotate(22.5deg) scale(1.2);
        pointer-events: none;
    }
`
