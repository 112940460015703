import { useSessionState } from 'session/SessionState'
import { ParticipantState, FacilitatorState } from 'shared/session/types'
import { useRef, useEffect, useState } from 'react'

export function useFocusedParticipantState(): ParticipantState | false {
  const sessionState = useSessionState()
  if (!sessionState) return false
  const { sessionProfile, isFacilitator, state } = sessionState
  if (isFacilitator) {
    const facilitatorState = state.facilitators.find(({ profile }) => profile.uid === sessionProfile.uid)
    if (!facilitatorState) {
      // throw new Error(`facilitator state not found for ${sessionProfile.uid}`)
      console.log(`WARNING: facilitator state not found for ${sessionProfile.uid}`)
      return false
    }
    if (facilitatorState.focusedParticipantUid) {
      const participantState = state.participants.find(
        ({ profile }) => profile.uid === facilitatorState.focusedParticipantUid
      )
      if (!participantState) {
        // throw new Error(`facilitator previewing participant state not found for ${facilitatorState.focusedParticipantUid}`)
        console.log(
          `WARNING: facilitator previewing participant state not found for ${facilitatorState.focusedParticipantUid}`
        )
        return false
      }
      return participantState
    } else {
      return false
    }
  } else {
    const participantState = state.participants.find(({ profile }) => profile.uid === sessionProfile.uid)
    if (!participantState) {
      if (sessionProfile.uid)
        console.log(
          `WARNING: participant state not found for ${sessionProfile.uid}`,
          state.participants.map(({ profile }) => profile.uid)
        )
      return false
    }
    return participantState
  }
}

export function useParticipantProfileUid() {
  const [, reRender] = useState<number>(0)
  const participantState = useFocusedParticipantState()
  const participantUid = useRef<string | null>(participantState ? participantState.profile.uid : null)
  useEffect(() => {
    if (participantState) {
      if (participantState.profile.uid !== participantUid.current) {
        participantUid.current = participantState.profile.uid
        reRender(v => v + 1)
      }
    } else {
      participantUid.current = null
      reRender(v => v + 1)
    }
  }, [participantState])
  return participantUid.current
}

export function useFacilitatorState(): FacilitatorState | false {
  const { sessionProfile, isFacilitator, state } = useSessionState()
  if (!isFacilitator) return false

  const facilitatorState = state.facilitators.find(({ profile }) => profile.uid === sessionProfile.uid)
  if (!facilitatorState) {
    // throw new Error(`facilitator state not found for ${sessionProfile.uid}`)
    console.warn(`facilitator state not found for ${sessionProfile.uid}`)
    return false
  }
  return facilitatorState
}
