export const mapRange = (num: number, in_min: number, in_max: number, out_min: number, out_max: number): number =>
  ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min

export const clamp = (num: number, min: number, max: number) => Math.max(min, Math.min(max, num))

export function sum(acc: number, num: number): number {
  return acc + num
}

export function roundToDecimal(value: number, decimalPlaces: number = 1) {
  const multiplier = Math.pow(10, decimalPlaces)
  return Math.round(value * multiplier) / multiplier
}
