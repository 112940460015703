import React, { useRef, useEffect } from 'react'
import { useGesture } from 'react-use-gesture'
import styled from 'styled-components'

export const switchSizes = {
  xs: {
    width: 35,
    height: 18,
  },
  s: {
    width: 52,
    height: 24,
  },
  m: {
    width: 65,
    height: 30,
  },
  l: {
    width: 91,
    height: 42,
  },
  xl: {
    width: 130,
    height: 60,
  },
}

export const switchBorders = {
  xs: 1,
  s: 2,
  m: 2,
  l: 3,
  xl: 4,
}

export const SwitchButton = styled.button<{ size?: Props['size'] }>`
  box-sizing: border-box;
  outline: none;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  overflow: visible;

  border: 0;
  border-radius: ${p => switchSizes[p.size || 'm'].height / 2}px;
  border-top: ${p => switchBorders[p.size || 'm']}px solid #abb4db;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.4);

  width: ${p => switchSizes[p.size || 'm'].width}px;
  height: ${p => switchSizes[p.size || 'm'].height}px;
  background: #e7eaf5;
  transition: background 0.2s ease-in-out;

  ::after {
    content: '';
    position: absolute;
    box-sizing: content-box;
    top: -${p => switchBorders[p.size || 'm']}px;
    left: 0px;
    width: ${p => switchSizes[p.size || 'm'].height - switchBorders[p.size || 'm'] * 3}px;
    height: ${p => switchSizes[p.size || 'm'].height - switchBorders[p.size || 'm'] * 3}px;
    background: linear-gradient(180deg, #eaecf6 0%, white 100%);
    border-radius: 50%;
    border: ${p => switchBorders[p.size || 'm']}px solid white;
    box-shadow: 0px ${p => switchBorders[p.size || 'm']}px 0px 0px #abb4db;
    transition: left 0.2s ease-in-out;
  }

  ::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -10px;
    right: -10px;
    bottom: -15px;
  }

  :active,
  :focus {
    ::after {
      background: linear-gradient(180deg, #d5d9ed 0%, white 100%);
    }
  }

  &[aria-checked='true'] {
    background: #39e055;

    ::after {
      left: ${p =>
        switchSizes[p.size || 'm'].width - (switchSizes[p.size || 'm'].height - switchBorders[p.size || 'm'])}px;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`

interface Props {
  value: boolean
  onChange: (value: boolean) => void
  labelId?: string
  size?: keyof typeof switchSizes
  disabled?: boolean
}

export const Switch: React.FC<Props> = ({ value, onChange, labelId, size, disabled }) => {
  const dragDelta = useRef(0)
  const valueRef = useRef(value)
  valueRef.current = value
  // fix for ios safari
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.addEventListener('touchmove', e => e.preventDefault(), { passive: false })
    }
  }, [])
  const bind = useGesture({
    onDrag: ({ movement: [x, y], first, last, timeStamp }) => {
      if (first) {
        dragDelta.current = timeStamp
      } else if (last) {
        // if they haven't moved much it's probably a click, so we set to zero
        if (x < 10 && y < 10) {
          dragDelta.current = 0
        } else {
          dragDelta.current = timeStamp - dragDelta.current
        }
      }
      if (x < 0 && value) {
        onChange(false)
      } else if (x > 0 && !value) {
        onChange(true)
      }
    },
    onClick: e => {
      e.stopPropagation()
      if (dragDelta.current < 100) {
        onChange(!valueRef.current)
      } else {
        dragDelta.current = 0
      }
    },
    // fix for iOS Safari
    onTouchStart: () => {
      dragDelta.current = 0
    },
    onTouchEnd: e => {
      if (dragDelta.current > 100) {
        e.preventDefault()
      }
    },
  })
  return (
    <SwitchButton
      ref={buttonRef}
      role="switch"
      aria-labelledby={labelId}
      aria-checked={value}
      size={size}
      disabled={disabled}
      {...bind()}
    />
  )
}
