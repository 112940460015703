import React from 'react'
import styled from 'styled-components'
import { fontHeavy } from 'fonts'
import { Button, Column, Row, Spacer } from 'common/ui'
import { Gadget } from 'shared/e-telligence/types'

interface GadgetTileProps {
  gadget: Gadget
  requested: boolean
  added: boolean
  disableAdd: boolean
  onView: (gadget: Gadget) => void
  onEdit: (gadget: Gadget) => void
  onToggleRequested: (gadget: Gadget) => void
  staticMode: boolean
}
export const GadgetTile: React.FC<GadgetTileProps> = ({
  gadget,
  added,
  disableAdd,
  requested,
  onView,
  onEdit,
  onToggleRequested,
  staticMode,
}) => {
  if (!gadget.isPreset && staticMode) {
    return null
  }
  return (
    <GadgetTileStyled active={!staticMode && (!gadget.isPreset || added)}>
      <GadgetTileHeader>
        <label>{gadget.title}</label>
      </GadgetTileHeader>
      <GadgetTileInner>
        <Row flex="1 1 auto" justifyContent="center" alignItems="center">
          {gadget.isPreset ? (
            <img
              src={require('e-telligence/assets/gadgets/' + gadget.id + '.png')}
              alt={gadget.title}
              style={{ width: '100%', maxWidth: 144 }}
            />
          ) : (
            gadget.symbol && <span style={{ fontSize: 69 }}>{gadget.symbol}</span>
          )}
        </Row>
        <Row justifyContent="center" marginTop="s">
          {gadget.isPreset && !staticMode && (
            <>
              <Button
                size="xs"
                theme={added ? 'red' : 'blue'}
                children={!added ? 'Add' : 'Remove'}
                disabled={!added && disableAdd}
                onClick={() => onToggleRequested(gadget)}
              />
              <Spacer size="s" />
            </>
          )}
          <Button size="xs" children="View" onClick={() => onView(gadget)} />
          {!gadget.isPreset && (
            <>
              <Spacer size="s" />
              <Button size="xs" children="Edit" onClick={() => onEdit(gadget)} />
            </>
          )}
        </Row>
      </GadgetTileInner>
    </GadgetTileStyled>
  )
}

export const GadgetTileHeader = styled(Row)`
  flex: none;
  align-items: center;
  padding: 8px;
  min-height: 30px;

  color: #ffffff;
  background-color: #2eadf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  & label:first-child {
    flex: 1 1 auto;
    padding-right: 10px;
    text-transform: uppercase;
    line-height: 1.2;
    ${fontHeavy}
    font-style: italic;
    font-size: 12px;
  }
`

export const GadgetTileInner = styled(Column)`
  flex: 1 1 auto;
  background-color: #ffffff;
  padding: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #cdd2e4;
  border-top: none;
  text-align: center;
`

export const GadgetTileStyled = styled(Column)<{ active?: boolean }>`
  margin: 10px;
  flex: 1 1 auto;
  user-select: none;
  & ${GadgetTileHeader} {
    background-color: ${p => (p.active ? '#2eadf0' : '#999')};
  }
`
