import { GameObjects, Math as PhaserMath, Input } from 'phaser'
import { KeyBindMulti, Vector } from 'types'
import { EventEmitter } from 'phaser-types'
import { THEMES, Theme } from 'themes'

import SceneScaler, { STAGE_RESIZED, StageResizeCallback } from 'phaser/SceneScaler'
import DragHandle, { HandleDirection } from './DragHandle'
import AssetSprite from './AssetSprite'
import ActionText from './ActionText'

import { SceneAsset, getAssetByKey } from '../constants/assets'
import { SerializedScene, AssetCategory } from '../types'
import { assetCategories } from '../SceneBuilder'
import { sortByKey } from 'utils/sortUtils'
// import { addUpdateTestDot } from 'phaser/testDots'
import { getPointerPosition } from 'utils/pointerUtils'
import { getPixelRatio } from 'utils/screenUtils'
import { getNextHighestDepth, getObjectBelow, swapDepths, getNextLowestDepth, getObjectAbove } from 'utils/depthUtils'
import {
  SPRITE_SELECTED,
  SPRITE_DESELECTED,
  BG_CHANGED,
  DRAG_STARTED,
  DRAG_ENDED,
  HIDE_TRAY,
  EDIT_TEXT,
  SCENE_CHANGED,
} from '../constants/SceneBuilderEvents'
import { SCENE_INITED } from 'constants/events'
import { getRectImage } from 'phaser/shapeTextureGenerator'
import { clamp } from 'utils/mathUtils'
import { dereferenceSceneGameObjects } from 'utils/gameUtils'
import { isDevEnv } from 'utils/envUtils'

type HandleObject = { [key in HandleDirection]: DragHandle }
type Axis = 'horizontal' | 'vertical'

export const BACKGROUND_WIDTH = 799
export const BACKGROUND_HEIGHT = 463
export const BACKGROUND_RATIO = BACKGROUND_WIDTH / BACKGROUND_HEIGHT
const SPRITE_MIN_SCALE = 0.1
const SPRITE_MAX_SCALE = 3
const SPRITES_MAX_DEPTH = 10000 // int
const UI_MIN_DEPTH = 20000
const HANDLE_DIRECTIONS: HandleDirection[] = ['TL', 'TR', 'BL', 'BR']
const ROTATE_LOCK_ANGLE = 45 // degrees (must be a factor of 360)
const ROTATE_LOCK_RADIAN = (ROTATE_LOCK_ANGLE / 360) * (Math.PI * 2)
const { POINTER_UP, POINTER_DOWN, POINTER_MOVE, DRAG } = Input.Events

interface State {
  selectedSprite: AssetSprite | null
  selectedHandle: DragHandle | null
  resizing: boolean
  rotating: boolean
  draggingSprite: boolean
  pressedSprites: AssetSprite[]
  pressedPointerIds: number[]
  firstTouchPointerId: number
  secondTouchPointerId: number
  dragAxisLock: Axis | null
  theme: Theme
}

export default class SceneBuilderScene extends SceneScaler {
  keyState: KeyBindMulti = {}
  container: GameObjects.Container = {} as GameObjects.Container
  letterboxRects: GameObjects.GameObject[] = []
  background?: GameObjects.Image
  sprites: AssetSprite[] = []
  actionTexts: ActionText[] = []
  spriteGroup: GameObjects.Group
  UI_group: GameObjects.Group
  UI_selectBox: GameObjects.Graphics = {} as GameObjects.Graphics
  UI_selectResizeHandles: HandleObject = {} as HandleObject
  UI_selectRotateHandles: HandleObject = {} as HandleObject
  preTouchRotateAngle: number = 0
  preTouchResizeDist: number = 0
  pointerStartPositions: { [key: number]: Vector } = {}
  dragStartObjectPositions: { [key: number]: Vector } = {}

  private _screenScalerZoom: number = 1
  private _screenScalerOffsetX: number = 0
  private _disableInteraction: boolean = false
  private silenceUpdateEvents: boolean = false

  state: State

  handleStageResize?: StageResizeCallback

  sceneData?: SerializedScene
  inited = false

  constructor() {
    super('SceneBuilderScene')
    this.spriteGroup = new GameObjects.Group(this)
    this.UI_group = new GameObjects.Group(this)
    this.state = this.getInitialState()
  }

  public get screenScalerZoom(): number { return this._screenScalerZoom } // prettier-ignore
  public set screenScalerZoom(value: number) {
    this._screenScalerZoom = value
    if (this.container instanceof GameObjects.Container) this.container.setScale(value)
    ;[...Object.values(this.UI_selectResizeHandles), ...Object.values(this.UI_selectRotateHandles)].forEach(
      dragHandle => {
        dragHandle.setScale(0.5 * value)
      }
    )
    Object.values(this.UI_selectRotateHandles).forEach(dragHandle => {
      dragHandle.rotateOffsetDist = 20 * value
    })
  }

  public get screenScalerOffsetX(): number { return this._screenScalerOffsetX } // prettier-ignore
  public set screenScalerOffsetX(value: number) { this._screenScalerOffsetX = value } // prettier-ignore

  public get disableInteraction(): boolean { return this._disableInteraction } // prettier-ignore
  public set disableInteraction(disabled: boolean) {
    this._disableInteraction = disabled
    this.input.manager.keyboard.preventDefault = !disabled
  }

  init() {
    super.init()

    this.container = new GameObjects.Container(this)
    this.container.setScale(this.screenScalerZoom)

    const getLbImg = () => getRectImage(this, { width: BACKGROUND_WIDTH, height: BACKGROUND_HEIGHT }).setOrigin(0, 0)
    this.letterboxRects.push(this.add.existing(getLbImg().setPosition(-BACKGROUND_WIDTH, -BACKGROUND_HEIGHT).setScale(3, 1))) // prettier-ignore
    this.letterboxRects.push(this.add.existing(getLbImg().setPosition(-BACKGROUND_WIDTH, BACKGROUND_HEIGHT).setScale(3, 1))) // prettier-ignore
    this.letterboxRects.push(this.add.existing(getLbImg().setPosition(-BACKGROUND_WIDTH, -BACKGROUND_HEIGHT).setScale(1, 3))) // prettier-ignore
    this.letterboxRects.push(this.add.existing(getLbImg().setPosition(BACKGROUND_WIDTH, -BACKGROUND_HEIGHT).setScale(1, 3))) // prettier-ignore

    /**
     * Add drag handles
     */
    HANDLE_DIRECTIONS.forEach(dir => (this.UI_selectResizeHandles[dir] = new DragHandle(this, 'resize', dir)))
    HANDLE_DIRECTIONS.forEach(dir => (this.UI_selectRotateHandles[dir] = new DragHandle(this, 'rotate', dir)))
    this.UI_group.add((this.UI_selectBox = this.add.graphics()))
    this.UI_group.addMultiple(Object.values(this.UI_selectResizeHandles))
    this.UI_group.addMultiple(Object.values(this.UI_selectRotateHandles))

    /**
     * Add UI elements to master container
     */
    this.container.add([
      this.UI_selectBox,
      ...Object.values(this.UI_selectResizeHandles),
      ...Object.values(this.UI_selectRotateHandles),
    ])
    this.add.existing(this.container)

    /**
     * Bind pointer events
     */
    this.input.on(DRAG, this.handleDrag, this)
    this.input.on(POINTER_DOWN, this.handlePointerDown, this)
    this.input.on(POINTER_UP, this.handlePointerUp, this)
    this.input.on(POINTER_MOVE, this.handlePointerMove, this)

    /**
     * Bind keyboard states
     */
    this.keyState = this.input.keyboard.addKeys({
      up: Input.Keyboard.KeyCodes.UP,
      down: Input.Keyboard.KeyCodes.DOWN,
      left: Input.Keyboard.KeyCodes.LEFT,
      right: Input.Keyboard.KeyCodes.RIGHT,
      shift: Input.Keyboard.KeyCodes.SHIFT,
      esc: Input.Keyboard.KeyCodes.ESC,
      del: Input.Keyboard.KeyCodes.DELETE,
    }) as KeyBindMulti

    this.keyState.del.on('down', () => this.handleDelete())
    this.keyState.esc.on('down', () => this.setActiveSprite(null))

    /**
     * Create and bind resize handler
     */
    this.handleStageResize = ({ width, height }) => {
      const containerWidth = BACKGROUND_WIDTH * this.container.scaleX
      const containerHeight = BACKGROUND_HEIGHT * this.container.scaleY
      // wider
      if (this.stageRatio > BACKGROUND_RATIO)
        this.container.setPosition((this.stageWidth * (this.screenScaler || 1) - containerWidth) / 2, 0)
      // taller
      if (this.stageRatio < BACKGROUND_RATIO) this.container.setPosition(0, (this.stageHeight - containerHeight) / 2)
    }
    ;(this.events as EventEmitter).on<StageResizeCallback>(STAGE_RESIZED, this.handleStageResize)
    this.handleStageResize(this.stageSize)

    /**
     * load serialized data if we have it after init. we need to delay until
     * next frame for some reason
     */
    setTimeout(() => {
      this.inited = true
      if (this.sceneData) {
        if (isDevEnv()) console.log('scene has inited and queued scene data will now be inited')
        this.loadSerialized(this.sceneData)
        delete this.sceneData
      }
    })

    /**
     * Temp nonsense
     */
    ;(window as any).scene = this
    this.events.emit(SCENE_INITED)
  }

  destroy() {
    super.destroy()

    this.handleStageResize &&
      (this.events as EventEmitter).off<StageResizeCallback>(STAGE_RESIZED, this.handleStageResize)

    this.input.off(DRAG, this.handleDrag, this)
    this.input.off(POINTER_DOWN, this.handlePointerDown, this)
    this.input.off(POINTER_UP, this.handlePointerUp, this)
    this.input.on(POINTER_MOVE, this.handlePointerMove, this)

    dereferenceSceneGameObjects(this)
  }

  private getInitialState = (): State => ({
    selectedSprite: null,
    selectedHandle: null,
    resizing: false,
    rotating: false,
    draggingSprite: false,
    pressedSprites: [],
    pressedPointerIds: [],
    firstTouchPointerId: -1,
    secondTouchPointerId: -1,
    dragAxisLock: null,
    theme: THEMES[0],
  })

  public updateTheme(theme: Theme) {
    this.state.theme = theme
    this.redrawSelectionOutline()
  }

  public handleAddAsset(asset: SceneAsset, category: AssetCategory): AssetSprite | GameObjects.Image | false {
    if (assetCategories.indexOf(category) < 0) return false
    if (category === 'scenes') return this.handleSceneChange(asset)

    let assetKey = asset.key
    if (!this.textures.exists(assetKey)) return false
    if (isDevEnv()) console.log('asset added: ' + assetKey)

    const loadedHQ = this.textures.exists(assetKey + '_HQ')
    if (loadedHQ) assetKey = asset.key + '_HQ'

    const spriteScale = asset.scale ? asset.scale : 0.5
    const sprite = new AssetSprite(this, assetKey, category, {
      position: {
        x: BACKGROUND_WIDTH / 2 + PhaserMath.Between(-50, 50),
        y: BACKGROUND_HEIGHT / 2 + PhaserMath.Between(-50, 50),
      },
      center: asset.center || { x: 0.5, y: 0.5 },
      baseScale: spriteScale,
      hitArea: asset.hitArea,
    })
    sprite.setDepth(getNextHighestDepth(this.sprites, SPRITES_MAX_DEPTH / 2))
    this.container.add(sprite)
    this.sprites.push(sprite)
    this.spriteGroup.add(sprite)

    if (!loadedHQ) this.loadTextureHQ(sprite, asset)

    this.redrawDepths()

    if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)

    return sprite
  }

  public handleAssetDoubleTap(asset: AssetSprite, pointer: Phaser.Input.Pointer) {
    if (isDevEnv()) console.log('sprite double tap/click')
    if (asset.category === 'actions') {
      this.events.emit(DRAG_ENDED, asset)
      this.events.emit(EDIT_TEXT)
      this.state.pressedSprites = []
      this.state.firstTouchPointerId = -1
      this.state.secondTouchPointerId = -1
    }
  }

  public handleSceneChange(asset: SceneAsset): GameObjects.Image | false {
    let assetKey = asset.key
    if (!this.textures.exists(assetKey)) return false
    if (isDevEnv()) console.log('scene changed')

    const loadedHQ = this.textures.exists(assetKey + '_HQ')
    if (loadedHQ) assetKey = asset.key + '_HQ'

    if (this.background) {
      this.background.setTexture(assetKey)
    } else {
      this.background = this.add.image(0, 0, assetKey)
      this.container.add(this.background)
      this.background
        .setOrigin(0, 0)
        .setDepth(0)
        .setScale(0.5)
      this.redrawDepths()
    }
    this.events.emit(BG_CHANGED, asset.key)

    if (!loadedHQ) {
      this.background.setScale(0.5)
      this.loadTextureHQ(this.background, asset)
    }

    if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)

    return this.background
  }

  public handleTextureSwap(asset: SceneAsset, sprite: AssetSprite | null = this.state.selectedSprite) {
    if (!sprite) return

    let assetKey = asset.key
    if (!this.textures.exists(assetKey)) return false

    if (isDevEnv()) console.log('swapping in HQ asset')

    if (sprite.cancelLoadHQ) {
      sprite.cancelLoadHQ()
      sprite.cancelLoadHQ = undefined
    }

    const scaleDiff = sprite.scaleX / sprite.baseScale
    const loadedHQ = this.textures.exists(assetKey + '_HQ')
    if (loadedHQ) assetKey = asset.key + '_HQ'
    sprite.setTexture(assetKey)
    sprite.baseScale = asset.scale || 0.5
    sprite.setScale(sprite.baseScale * scaleDiff)

    if (loadedHQ) {
      sprite.origWidth = asset.dimensions.width
      sprite.origHeight = asset.dimensions.height
      sprite.origHypot2 = PhaserMath.Distance.Between(0, 0, asset.dimensions.width / 2, asset.dimensions.height / 2)
      this.redrawSelectionOutline()
    } else {
      sprite.origWidth = sprite.width
      sprite.origHeight = sprite.height
      sprite.origHypot2 = PhaserMath.Distance.Between(0, 0, sprite.width / 2, sprite.height / 2)
      this.loadTextureHQ(sprite, asset)
    }

    this.setActiveSprite(sprite)

    if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
  }

  private loadTextureHQ(sprite: GameObjects.Image | AssetSprite, asset: SceneAsset) {
    if (isDevEnv()) console.log('loading HQ asset')
    const origScaleX = sprite.scaleX
    const origScaleY = sprite.scaleX
    sprite.scaleX = (asset.dimensions.width * (asset.scale || 0.5)) / sprite.width
    sprite.scaleY = (asset.dimensions.height * (asset.scale || 0.5)) / sprite.height
    if (this.state.selectedSprite === sprite) this.redrawSelectionOutline()
    const handleSpriteLoad = () => {
      if (sprite instanceof AssetSprite) {
        sprite.setScale(origScaleX, origScaleY)
        sprite.origWidth = asset.dimensions.width
        sprite.origHeight = asset.dimensions.height
        sprite.origHypot2 = PhaserMath.Distance.Between(0, 0, asset.dimensions.width / 2, asset.dimensions.height / 2)
        if (this.state.selectedSprite === sprite) this.redrawSelectionOutline()
      }
    }
    if (sprite instanceof AssetSprite) sprite.cancelLoadHQ = handleSpriteLoad
    this.load.image(asset.key + '_HQ', asset.url)
    this.load.once('complete', () => {
      sprite.setTexture(asset.key + '_HQ')
      if (sprite instanceof AssetSprite) {
        handleSpriteLoad()
        sprite.cancelLoadHQ = undefined
      } else {
        sprite.setScale(origScaleX, origScaleY)
      }
    })
    this.load.start()
  }

  public handleClearScene() {
    if (isDevEnv()) console.log('whole scene being cleared')
    if (this.background) {
      this.background.destroy(true)
      this.background = undefined
    }
    this.sprites.forEach(this.handleDelete.bind(this))

    if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
  }

  public getSerialized(): SerializedScene {
    if (isDevEnv()) console.log('getting serialized data')
    return {
      thumbnail: '',
      backgroundKey: this.background ? this.background.texture.key.replace(/_HQ$/, '') : null,
      assets: [
        ...this.sprites.map(sprite => ({
          key: sprite.texture.key.replace(/_HQ$/, ''),
          category: sprite.category,
          text: sprite.text,
          rotation: sprite.rotation,
          scale: sprite.scaleX,
          flipX: sprite.flipX,
          flipY: sprite.flipY,
          depth: sprite.depth,
          position: { x: sprite.x, y: sprite.y },
        })),
      ],
    }
  }

  public loadSerialized(serialized: SerializedScene) {
    // if not yet inited, save for later
    if (!this.inited) {
      if (isDevEnv()) console.log('deferring scene load until after scene has inited')
      this.sceneData = serialized
      return
    }

    if (isDevEnv()) console.log('loading serialized data')

    if (!serialized.backgroundKey && (!serialized.assets || !serialized.assets.length)) {
      if (isDevEnv()) console.warn('not loading serialized data because it is empty')
      return
    }

    this.silenceUpdateEvents = true

    // reset and destroy current sprites
    this.state = this.getInitialState()

    if (this.sprites.length) {
      this.sprites.forEach(sprite => sprite.destroy(true))
      this.sprites = []
    }

    if (this.actionTexts.length) {
      this.actionTexts.forEach(sprite => sprite.destroy(true))
      this.actionTexts = []
    }

    if (this.background) {
      this.background.destroy(true)
      this.background = undefined
    }

    // add background
    if (serialized.backgroundKey) {
      const bgAsset = getAssetByKey(serialized.backgroundKey)
      if (bgAsset) {
        this.handleSceneChange(bgAsset)
      }
    }

    // add remaining assets
    serialized.assets.forEach(objectAsset => {
      const asset = getAssetByKey(objectAsset.key)
      if (!asset) return

      // set scale before we add the asset otherwise the HQ loaded callback
      // will reset our scale incorrectly
      const scaledAsset = { ...asset, scale: objectAsset.scale }
      const sprite = this.handleAddAsset(scaledAsset, objectAsset.category)
      if (!sprite) return

      sprite
        .setPosition(objectAsset.position.x, objectAsset.position.y)
        .setRotation(objectAsset.rotation)
        .setDepth(objectAsset.depth)

      if (sprite instanceof AssetSprite) {
        if (objectAsset.flipX) this.handleFlipH(sprite)
        if (objectAsset.flipY) this.handleFlipV(sprite)
      } else {
        sprite.setFlip(objectAsset.flipX, objectAsset.flipY)
      }

      if (objectAsset.text) {
        this.handleEditText(objectAsset.text, sprite as AssetSprite)
      }
    })

    this.silenceUpdateEvents = false
  }

  public screenshot() {
    if (isDevEnv()) console.log('generating screenshot')

    this.setActiveSprite(null)

    const width = this.game.renderer.width
    const height = this.game.renderer.height
    const containerRatio = width / height
    const contentRatio = BACKGROUND_RATIO
    let contentWidth, contentHeight
    if (contentRatio > containerRatio) {
      contentWidth = width
      contentHeight = (1 / contentRatio) * width
    } else {
      contentHeight = height
      contentWidth = contentRatio * height
    }

    const x = Math.round((width - contentWidth) / 2)
    const y = Math.round((height - contentHeight) / 2)
    const w = Math.round(contentWidth)
    const h = Math.round(contentHeight)

    return new Promise<string>(resolve => {
      this.game.renderer.snapshotArea(
        x,
        y,
        w,
        h,
        img => {
          if (isDevEnv()) console.log('screenshot generated')
          resolve((img as HTMLImageElement).src)
        },
        'image/jpeg',
        0.8
      )
    })
  }

  public setActiveSprite(sprite: AssetSprite | null) {
    const { selectedSprite } = this.state
    if (sprite === selectedSprite) {
      if (isDevEnv() && !!sprite) console.warn('Not selecting sprite because already selected')
      return
    }

    // just some sanity -- clear up interaction states when either adding or switching active sprite
    if (selectedSprite !== null) {
      if (isDevEnv()) console.log('selecting sprite ' + selectedSprite.texture.key)
      if (selectedSprite.pressedPointerId >= 0) {
        if (this.state.firstTouchPointerId === selectedSprite.pressedPointerId) {
          if (isDevEnv()) console.log('resetting firstTouchPointerId')
          this.state.firstTouchPointerId = -1
        }
        if (this.state.secondTouchPointerId === selectedSprite.pressedPointerId) {
          if (isDevEnv()) console.log('resetting secondTouchPointerId')
          this.state.secondTouchPointerId = -1
        }
        this.state.pressedPointerIds = this.state.pressedPointerIds.filter(
          pointerId => pointerId !== selectedSprite.pressedPointerId
        )
      }
      selectedSprite.pressedPointerId = -1
      this.state.pressedSprites = this.state.pressedSprites.filter(_sprite => _sprite !== selectedSprite)
    }

    if (isDevEnv()) console.log('setting state.selectedSprite to ' + sprite?.texture.key)
    this.state.selectedSprite = sprite
    this.state.resizing = false
    this.state.rotating = false

    if (sprite === null) {
      if (isDevEnv()) console.log('emitting sprite deselection event')
      this.events.emit(SPRITE_DESELECTED)
    } else {
      this.redrawSelectionOutline()
      console.log('emitting sprite selected event')
      this.events.emit(SPRITE_SELECTED, sprite.category, sprite.texture.key)
    }
  }

  public redrawSelectionOutline() {
    if (isDevEnv()) console.log('redrawing selection outline')
    if (!this.state.selectedSprite) {
      if (isDevEnv()) console.warn('cannot redraw outline because no sprite is selected')
      return
    }

    const { realWidth, realHeight } = this.state.selectedSprite
    const lineColor = parseInt('0x' + this.state.theme.buttonBorderTopColor.replace('#', ''))
    this.UI_selectBox.clear()
      .lineStyle(1, lineColor, 1)
      .strokeRect(-realWidth / 2, -realHeight / 2, realWidth, realHeight)
  }

  public handleMoveBackward(sprite: AssetSprite | null = this.state.selectedSprite) {
    if (!sprite) return
    if (isDevEnv()) console.log('moving sprite backwards ' + sprite.texture.key)
    const spriteBelow = getObjectBelow(sprite, this.sprites)
    if (spriteBelow) swapDepths(sprite, spriteBelow)
    else sprite.setDepth(getNextLowestDepth(this.sprites, SPRITES_MAX_DEPTH / 2))
    this.redrawDepths()
    if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
  }

  public handleMoveForward(sprite: AssetSprite | null = this.state.selectedSprite) {
    if (!sprite) return
    if (isDevEnv()) console.log('moving sprite forwards ' + sprite.texture.key)
    const spriteAbove = getObjectAbove(sprite, this.sprites)
    if (spriteAbove) swapDepths(sprite, spriteAbove)
    else sprite.setDepth(getNextHighestDepth(this.sprites, SPRITES_MAX_DEPTH / 2))
    this.redrawDepths()
    if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
  }

  /**
   * This function removes all items from the stage container and re-adds them in the order of depth
   * This has to happen because once items are added to a container they lose native depth controls
   * But we still set and use the native depth values for ease of use
   */
  public redrawDepths() {
    if (isDevEnv())
      console.log('redrawing depths A.K.A removing all sprites from container and re-adding in the right order')
    this.container.removeAll()
    if (this.background) this.container.addAt(this.background, 0)
    this.actionTexts.forEach(actionText => (actionText.depth = actionText.owner.depth + 0.5))
    const gameObjects = [...this.sprites, ...this.actionTexts].sort(sortByKey('depth'))
    gameObjects.forEach(object => this.container.addAt(object, object.depth))
    this.letterboxRects.forEach((sprite, i) => this.container.addAt(sprite, UI_MIN_DEPTH + i))
    ;(this.UI_group.getChildren() as DragHandle[]).forEach((object, i) =>
      this.container.addAt(object, UI_MIN_DEPTH + 6 + i)
    )
  }

  public handleDelete(sprite: AssetSprite | null = this.state.selectedSprite) {
    if (!sprite) return
    if (isDevEnv()) console.log('removing sprite ' + sprite.texture.key)
    this.sprites = this.sprites.filter(_sprite => _sprite !== sprite)
    if (sprite.category === 'actions') {
      const actionText = this.actionTexts.find(actionText => actionText.owner === sprite)
      if (actionText) {
        this.actionTexts = this.actionTexts.filter(_actionText => _actionText !== actionText)
        actionText.destroy()
      }
    }
    sprite.destroy()
    this.setActiveSprite(null)
    this.redrawDepths()
    if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
  }

  public handleFlipH(sprite: AssetSprite | null = this.state.selectedSprite) {
    if (sprite) {
      if (isDevEnv()) console.log('horizontally flipping sprite ' + sprite.texture.key)
      sprite.handleFlipH()
      if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
    }
  }

  public handleFlipV(sprite: AssetSprite | null = this.state.selectedSprite) {
    if (sprite) {
      if (isDevEnv()) console.log('vertically flipping sprite ' + sprite.texture.key)
      sprite.handleFlipV()
      if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
    }
  }

  public handleEditText(text: string = '', sprite: AssetSprite | null = this.state.selectedSprite) {
    if (!sprite) return
    if (isDevEnv()) console.log('updating action text')
    sprite.text = text
    const oldActionText = this.actionTexts.find(({ owner }) => owner === sprite)
    if (oldActionText) {
      this.actionTexts = this.actionTexts.filter(actionText => actionText !== oldActionText)
      oldActionText.destroy(true)
    }
    const actionText = new ActionText(this, sprite)
    this.container.add(actionText)
    this.actionTexts.push(actionText)
    this.redrawDepths()
    if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
  }

  public startResize(handle: DragHandle) {
    if (isDevEnv()) console.log('starting resize')
    if (!this.state.selectedSprite) {
      if (isDevEnv()) console.warn('cannot start resize because no selected sprite')
      return
    }
    this.state.selectedSprite.setPreResizeValues()
    this.state.selectedHandle = handle
    this.state.resizing = true
  }

  public startRotate(handle: DragHandle) {
    if (isDevEnv()) console.log('starting rotate')
    if (!this.state.selectedSprite) {
      if (isDevEnv()) console.warn('cannot start resize because no selected sprite')
      return
    }
    this.state.selectedSprite.setPreRotateValues()
    this.state.selectedHandle = handle
    this.state.rotating = true
  }

  public addPressedSprite(sprite: AssetSprite, pointer: Phaser.Input.Pointer) {
    if (isDevEnv()) console.log('adding pressed sprite ' + sprite.texture.key)
    if (this.state.pressedSprites.indexOf(sprite) < 0) {
      sprite.pressedPointerId = pointer.id
      this.state.pressedSprites.push(sprite)
      this.state.pressedPointerIds.push(pointer.id)
      this.dragStartObjectPositions[pointer.id] = { x: sprite.x, y: sprite.y }
      this.pointerStartPositions[pointer.id] = getPointerPosition(pointer.event, pointer.id, this._screenScalerZoom, {
        x: -this._screenScalerOffsetX,
        y: 0,
      })
      this.events.emit(DRAG_STARTED, sprite)
    } else {
      if (isDevEnv()) console.warn('cannot add pressed sprite it is already pressed: ' + sprite.texture.key)
    }
  }

  public removePressedSprite(sprite: AssetSprite, pointer: Phaser.Input.Pointer) {
    if (this.state.pressedSprites.indexOf(sprite) >= 0) {
      if (isDevEnv()) console.log('unpressing sprite ' + sprite.texture.key)
      sprite.pressedPointerId = -1
      this.state.pressedSprites = this.state.pressedSprites.filter(_sprite => _sprite !== sprite)
      if (pointer.id in this.pointerStartPositions) delete this.pointerStartPositions[pointer.id]
      if (pointer.id in this.dragStartObjectPositions) delete this.dragStartObjectPositions[pointer.id]
      if (pointer.id === this.state.firstTouchPointerId) this.state.firstTouchPointerId = -1
      if (pointer.id === this.state.secondTouchPointerId) this.state.secondTouchPointerId = -1
      this.events.emit(DRAG_ENDED, sprite)
    } else {
      if (isDevEnv()) console.warn('cannot unpress sprite because sprite is not currently pressed')
    }
  }

  private handleDrag(
    pointer: Phaser.Input.Pointer,
    gameObject: AssetSprite | DragHandle,
    dragX: number,
    dragY: number
  ) {
    if (this.disableInteraction) return
    const pointerPosition: Vector = getPointerPosition(pointer.event, pointer.id, this._screenScalerZoom, {
      x: -this._screenScalerOffsetX,
      y: 0,
    })
    // addUpdateTestDot({ key: `handle${pointer.id}`, color: 0xff0000, scene: this, position: pointerPosition })
    if (gameObject instanceof AssetSprite && this.sprites.indexOf(gameObject) >= 0) {
      if (isDevEnv()) console.log('asset drag event')
      const { firstTouchPointerId, secondTouchPointerId, resizing, rotating, dragAxisLock } = this.state
      const isThirdFinger = firstTouchPointerId !== pointer.id && secondTouchPointerId !== pointer.id
      const canDragAsset = isThirdFinger || (!resizing && !rotating)
      if (canDragAsset) {
        const coordScale = getPixelRatio() / this.cameras.main.zoom / this.container.scaleX
        const startPos = this.pointerStartPositions[pointer.id]
        const diff: Vector = {
          x: pointerPosition.x - (startPos ? startPos.x : 0),
          y: pointerPosition.y - (startPos ? startPos.y : 0),
        }
        if (Math.abs(diff.x) > 5 || Math.abs(diff.y) > 5) this.state.draggingSprite = true
        dragX = this.dragStartObjectPositions[pointer.id].x + diff.x * coordScale
        dragY = this.dragStartObjectPositions[pointer.id].y + diff.y * coordScale
        if (this.keyState.shift.isDown) {
          if (!dragAxisLock) {
            this.state.dragAxisLock =
              Math.abs(pointer.velocity.x) > Math.abs(pointer.velocity.y) ? 'horizontal' : 'vertical'
          }
          if (this.state.dragAxisLock === 'horizontal') dragY = gameObject.y
          if (this.state.dragAxisLock === 'vertical') dragX = gameObject.x
        } else if (dragAxisLock !== null) {
          this.state.dragAxisLock = null
        }
        gameObject.setPosition(dragX, dragY)
        if (!this.state.selectedSprite) {
          this.setActiveSprite(gameObject)
        }
      }
    }
    const { selectedSprite } = this.state
    if (gameObject instanceof DragHandle && selectedSprite !== null) {
      if (isDevEnv()) console.log(gameObject.role + ' handle drag event')
      const containerPointerPosition: Vector = {
        x: (pointerPosition.x * this.stageScale - this.container.x) / this.container.scaleX,
        y: (pointerPosition.y * this.stageScale - this.container.y) / this.container.scaleY,
      }

      /*
      addUpdateTestDot({ key: 'handle1', color: 0xff0000, scene: this, position: pointerPosition })
      addUpdateTestDot({
        key: 'handle2',
        color: 0x00ff00,
        scene: this,
        position: { x: pointerPosition.x * this.stageScale, y: pointerPosition.y * this.stageScale },
      })
      addUpdateTestDot({
        key: 'handle3',
        color: 0x0000ff,
        scene: this,
        parent: this.container,
        position: containerPointerPosition,
      })
      addUpdateTestDot({
        key: 'handle4',
        color: 0xffff00,
        scene: this,
        parent: this.container,
        position: { x: selectedSprite.x, y: selectedSprite.y },
      })
      */

      if (gameObject.role === 'resize') {
        const dist = PhaserMath.Distance.Between(
          selectedSprite.x,
          selectedSprite.y,
          containerPointerPosition.x,
          containerPointerPosition.y
        )
        // const diff = dist / (selectedSprite.origHypot2 * selectedSprite.baseScale)
        // selectedSprite.setScale(diff * selectedSprite.baseScale)
        // lol https://www.symbolab.com/solver/simplify-calculator/%5Cleft(%5Cfrac%7Bd%7D%7Bh%20%5Ccdot%20s%7D%5Ccdot%20s%5Cright)

        selectedSprite.setScale(clamp(dist / selectedSprite.origHypot2, SPRITE_MIN_SCALE, SPRITE_MAX_SCALE))
        this.redrawSelectionOutline()
      } else if (gameObject.role === 'rotate') {
        const angle = PhaserMath.Angle.BetweenPoints(selectedSprite, containerPointerPosition)
        let spriteAngle = selectedSprite.preRotateAngle + angle - gameObject.preRotateAngle
        if (this.keyState.shift.isDown) {
          spriteAngle =
            selectedSprite.preRotateAngle +
            Math.round(angle / ROTATE_LOCK_RADIAN) * ROTATE_LOCK_RADIAN -
            gameObject.preRotateAngle
        }
        selectedSprite.setRotation(spriteAngle)
      }
    }
  }

  private handlePointerDown(pointer: Phaser.Input.Pointer, x: number, y: number, e: Phaser.Types.Input.EventData) {
    if (this.disableInteraction) return
    if (isDevEnv()) console.log('scene pointer down')
    const { selectedSprite, firstTouchPointerId, secondTouchPointerId } = this.state
    // if there is a selected sprite it means this pointer down event is on a blank area
    if (selectedSprite) {
      /**
       * if there isn't already a touch registered it means the asset should be deselected, but we don't want to do that
       * until pointer up event so for now just store first touch pointer id and set some state variables to false for safety
       **/
      if (firstTouchPointerId < 0) {
        this.state.firstTouchPointerId = pointer.id
        this.state.resizing = false
        this.state.rotating = false

        /**
         * However, if there is already a touch point registered it means it's time for a pinch resize/rotate so pre-rotate
         * and pre-resize values need to be cached
         */
      } else if (secondTouchPointerId < 0) {
        selectedSprite.setPreResizeValues()
        selectedSprite.setPreRotateValues()
        this.state.secondTouchPointerId = pointer.id
        this.state.resizing = true
        this.state.rotating = true
        const firstPointer = this.input.manager.pointers.find(({ id }) => id === firstTouchPointerId)
        if (firstPointer) {
          this.preTouchRotateAngle = PhaserMath.Angle.BetweenPoints(firstPointer, pointer)
          this.preTouchResizeDist = PhaserMath.Distance.Between(firstPointer.x, firstPointer.y, pointer.x, pointer.y)
        }
      }
    }
  }

  private handlePointerUp(pointer: Phaser.Input.Pointer, x: number, y: number, e: Phaser.Types.Input.EventData) {
    if (this.disableInteraction) return
    const {
      resizing,
      rotating,
      draggingSprite,
      firstTouchPointerId,
      secondTouchPointerId,
      // pressedPointerIds,
    } = this.state
    if (isDevEnv()) console.log('scene pointer up')
    if (this.state.selectedHandle || resizing || rotating || draggingSprite) {
      if (!this.silenceUpdateEvents) this.events.emit(SCENE_CHANGED)
    }
    this.state.selectedHandle = null
    this.state.dragAxisLock = null
    this.state.draggingSprite = false
    if (!this.state.selectedSprite) this.events.emit(HIDE_TRAY)

    /**
     * if the finger that was lifted was the first finger (or mouse) used for a resize/zoom then simply empty the pressedSprites
     * array so that no other manipulations can occur until all fingers are lifted
     * this is literally just to reset pressed state of sprites for multitouch movement
     **/

    if (pointer.id === firstTouchPointerId && (resizing || rotating)) this.state.pressedSprites = []

    /**
     * If this is the first finger being lifted (or mouse) and there was no resize/rotate manipulation occuring then it means that
     * the pointer was pressed on a blank space so deselct the active sprite
     */
    if (pointer.id === firstTouchPointerId && !resizing && !rotating) this.setActiveSprite(null)

    /**
     * If the pointer released matches the first (or mouse) or second finger to be placed then reset resize and rotate state
     */
    if (pointer.id === firstTouchPointerId || pointer.id === secondTouchPointerId) {
      this.state.resizing = false
      this.state.rotating = false
    }

    /**
     * Reset state of pointer id where event pointer id matches
     */
    if (pointer.id === firstTouchPointerId) this.state.firstTouchPointerId = -1
    if (pointer.id === secondTouchPointerId) this.state.secondTouchPointerId = -1

    /**
     * Ensuring any sprites that were being manipulated (moved) with this event's pointer id have their pressed states reset
     */
    // if (pressedPointerIds.indexOf(pointer.id) >= 0) {
    this.sprites
      .filter(({ pressedPointerId }) => pressedPointerId === pointer.id)
      .forEach(sprite => this.removePressedSprite(sprite, pointer))
    // }
  }

  // This just handles multi-touch scaling/rotation, see handleDrag function for the same functionality with click handles
  private handlePointerMove(pointer: Phaser.Input.Pointer, x: number, y: number, e: Phaser.Types.Input.EventData) {
    if (this.disableInteraction) return
    const { selectedSprite, firstTouchPointerId, secondTouchPointerId } = this.state
    if (selectedSprite && pointer.id === secondTouchPointerId) {
      if (isDevEnv()) console.log('scene pinch drag')
      const firstPointer = this.input.manager.pointers.find(({ id }) => id === firstTouchPointerId)
      const secondPointer = this.input.manager.pointers.find(({ id }) => id === secondTouchPointerId)
      if (!firstPointer || !secondPointer) return

      const dist = PhaserMath.Distance.Between(firstPointer.x, firstPointer.y, secondPointer.x, secondPointer.y)
      const angle = PhaserMath.Angle.BetweenPoints(firstPointer, secondPointer)
      const scaleDiff = (dist - this.preTouchResizeDist) / selectedSprite.origHypot2

      selectedSprite.setScale(clamp(selectedSprite.preResizeScale + scaleDiff, SPRITE_MIN_SCALE, SPRITE_MAX_SCALE))
      selectedSprite.setRotation(selectedSprite.preRotateAngle + (angle - this.preTouchRotateAngle))
      this.redrawSelectionOutline()
    }
  }

  update(time: number) {
    const { selectedSprite, selectedHandle, pressedSprites } = this.state

      // Set visibility and drag state of handles
    ;(this.UI_group.getChildren() as DragHandle[]).forEach(child => {
      child.setVisible(!!this.state.selectedSprite)
      if (child instanceof DragHandle) {
        if (child === selectedHandle)
          child.setTint(parseInt('0x' + this.state.theme.buttonBorderTopColor.replace('#', '')))
        else child.setTint(0xffffff)
      }
    })

    if (selectedSprite !== null) {
      // Check movement keys
      if (!this.disableInteraction) {
        const moveSpeed = this.keyState.shift.isDown ? 10 : 1
        if (this.keyState.left.isDown && selectedSprite.x > 0) selectedSprite.x -= moveSpeed
        else if (this.keyState.right.isDown && selectedSprite.x < 9999) selectedSprite.x += moveSpeed
        if (this.keyState.up.isDown && selectedSprite.y > 0) selectedSprite.y -= moveSpeed
        else if (this.keyState.down.isDown && selectedSprite.y < 99999) selectedSprite.y += moveSpeed
      }

      // Resposition handles
      this.UI_selectBox.setPosition(selectedSprite.x, selectedSprite.y).setRotation(selectedSprite.rotation)
      Object.values(this.UI_selectRotateHandles).forEach(handle => handle.updatePosition(selectedSprite))
      Object.values(this.UI_selectResizeHandles).forEach(handle => {
        handle.updatePosition(selectedSprite)
        handle.updateCursor(selectedSprite)
      })
    }

    // Set pressed state of sprites
    for (let sprite of this.sprites) {
      if (pressedSprites.indexOf(sprite) >= 0) {
        const tintColor = parseInt('0x' + this.state.theme.appBackgroundBottomColor.replace('#', ''))
        sprite.setTint(0xffffff, 0xffffff, tintColor, tintColor)
      } else sprite.setTint(0xffffff)
    }

    for (let actionText of this.actionTexts) {
      actionText.positionToOwner()
    }
  }
}
