import React from 'react'

import { NavRouteProps } from 'dashboards/types'

import { P } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { Cells } from 'dashboards/common/Cell'
import { SessionCell } from 'dashboards/common/SessionCell'
import { SpinnerWithLabel } from 'common/Spinner'
import { ForbiddenPage } from 'home/ForbiddenPage'

import { useMentorDashData, useMentorDashState } from './MentorDashboardState'
import { sessionsFilterType, sessionsFilterPast } from 'dashboards/utils/reducers'

export const ClubJournal: React.FC<NavRouteProps> = ({ route }) => {
  const {
    mentorUid,
    endpoints: {
      mentor: { value: mentor, loading: loadingMentor },
      primaryMentor: { value: primaryMentor, loading: loadingPrimaryMentor },
      group: { value: group, loading: loadingGroup },
    },
  } = useMentorDashState()
  const [sessions, loadingSessions] = useMentorDashData('sessions')
  const clubJournals = sessions ? sessionsFilterPast(sessionsFilterType(sessions, 'cadet')) : []
  if (loadingMentor || loadingPrimaryMentor)
    return <Page route={route} children={<SpinnerWithLabel label="Verifying mentor..." />} />
  if (mentor && !mentor.permission_past_cadet_sessions) return <ForbiddenPage />
  return (
    <Page route={route}>
      {loadingSessions || loadingGroup ? (
        <SpinnerWithLabel label="Loading club journals..." />
      ) : (
        <>
          {clubJournals.length > 0 ? (
            <Cells>
              {clubJournals.map(session => (
                <SessionCell
                  key={session.uid}
                  session={session}
                  group={group || undefined}
                  context="past"
                  userType="mentor"
                  mentorUid={mentorUid || undefined}
                />
              ))}
            </Cells>
          ) : (
            <P>{primaryMentor?.cadet_first_name || 'Cadet'} hasn't completed any sessions yet.</P>
          )}
        </>
      )}
    </Page>
  )
}
