import React from 'react'
import styled, { css } from 'styled-components'
import padStart from 'lodash/padStart'
import { fontLight, fontRegular } from 'fonts'
import { useSessionState } from './SessionState'

import { SupplementarySlide } from 'shared/session/types'
import { PreviewGadgetPackStateProvider } from './PreviewGadgetPackStateProvider'
import { PREVIEW_HEADER_HEIGHT } from './PreviewHeader'
import { TOGGLE_PARTICIPANT_HELP, UPDATE_PARTICIPANT_TRAY_OPEN } from 'shared/session/actionTypes'

import { Container, H2, Row, Button, Spacer } from 'common/ui'
import { NavColumn } from './common/NavColumn'
import { CircleButton } from './common/CircleButton'
import { Logo } from '../common/Logo'
import { GadgetTray } from './gadget-tray/GadgetTray'

import { useFocusedParticipantState } from './hooks/useProfileState'
import { useUserInputState } from './UserInputState'
import { SVG } from 'common/SVG'

export const PARTICIPANT_HEADER_HEIGHT = 65

const preventDefault = (e: any) => {
  e.preventDefault()
}

export const ParticipantUi: React.FC = ({ children }) => {
  const {
    dispatch,
    previewing,
    sessionProfile,
    sessionUserType,
    sessionData,
    getBaseAction,
    state: { participants, tokenTarget, globalLock },
  } = useSessionState()
  const participantState = useFocusedParticipantState()
  const { getInputValues } = useUserInputState()

  const supplementarySlides = getInputValues<SupplementarySlide>('shared', { owner: 'supplementary_slides' }).map(
    ({ value }) => value
  )

  const participant = participants.find(participant => participant.profile.uid === sessionProfile.uid)
  const isCadetModule = sessionData && sessionData.module.type === 'cadet'
  const tokenMissionDisabled = !participant || !participant.tokenMission

  const isSupplementarySlide = participantState
    ? !!supplementarySlides.find(supp => supp.uid === participantState.currentSlideUid) || false
    : false
  const isEndScreen = participantState ? participantState.currentSlideUid === 'SESSION_END' : false

  let screenLocked = true
  if (isEndScreen) {
    screenLocked = false
  } else if (isSupplementarySlide) {
    screenLocked = globalLock ? false : !globalLock && participant?.locked ? true : false
  } else {
    screenLocked = participant?.locked || globalLock ? true : false
  }

  const toggleTray = (trayType: null | 'gadgets' | 'mission') => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_PARTICIPANT_TRAY_OPEN,
      open: trayType !== null,
      trayType: trayType,
      participant: sessionProfile,
    })
  }

  const toggleHelp = () => {
    dispatch({
      ...getBaseAction(),
      type: TOGGLE_PARTICIPANT_HELP,
      participant: sessionProfile,
    })
  }

  return (
    <PreviewGadgetPackStateProvider>
      <Row
        flex
        onKeyDown={screenLocked ? preventDefault : undefined}
        onMouseDown={screenLocked ? preventDefault : undefined}>
        {screenLocked && <LockOverlay />}
        <HeaderContainer previewing={previewing}>
          <Header>
            <Container size="l" style={{ height: '100%' }}>
              <Row style={{ height: '100%' }}>
                <Row flex="1 1 auto" justifyContent="flex-start" alignItems="center">
                  <Logo style={{ marginLeft: 15 }} />
                  <Spacer size="s" />
                  <CircleButton
                    onClick={toggleHelp}
                    pressed={participantState && participantState.requiresHelp}
                    theme={participantState && participantState.requiresHelp ? 'parentGreen' : 'blue'}
                    size="m"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 10,
                      zIndex: 9999,
                    }}>
                    <SVG width={15} height={21} viewboxWidth={17} viewboxHeight={23} style={{ fill: 'none' }}>
                      <path
                        d="M4 14.3636V11.0227C4 10.2319 3.3285 9.59091 2.5 9.59091C1.6715 9.59091 1 10.2319 1 11.0227V14.8409C1 18.7946 4.358 22 8.5 22C12.642 22 16 18.7946 16 14.8409V7.20455C16 6.4137 15.3285 5.77273 14.5 5.77273C13.6715 5.77273 13 6.4137 13 7.20455V11.5V4.34091C13 3.55007 12.3285 2.90909 11.5 2.90909C10.6715 2.90909 10 3.55007 10 4.34091V10.5455V9.59091V2.43182C10 1.64098 9.3285 1 8.5 1C7.6715 1 7 1.64098 7 2.43182V9.59091V10.5455V3.38636C7 2.59552 6.3285 1.95455 5.5 1.95455C4.6715 1.95455 4 2.59552 4 3.38636V10.5455"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </SVG>
                  </CircleButton>
                </Row>
                <Row alignItems="center">
                  <HeaderNavColumn>
                    {screenLocked && (
                      <LockWrapper>
                        <img src={require('common/assets/image/lock-no-shadow@2x.png')} alt="Locked By Facilitator" />
                      </LockWrapper>
                    )}
                    <Button
                      size="s"
                      onClick={() =>
                        toggleTray(participantState && participantState.trayType === 'gadgets' ? null : 'gadgets')
                      }
                      children="Gadgets"
                      pressed={participantState && participantState.trayType === 'gadgets'}
                      disabled={screenLocked}
                    />
                  </HeaderNavColumn>
                  {isCadetModule && (
                    <>
                      <HeaderNavColumn>
                        My tokens:{' '}
                        <H2 style={{ display: 'inline-block' }}>
                          {participant ? padStart(String(participant.tokens), 2, '0') : '00'}
                        </H2>
                      </HeaderNavColumn>
                      <HeaderNavColumn>
                        Token Target:{' '}
                        <H2 style={{ display: 'inline-block' }}>{padStart(String(tokenTarget), 2, '0')}</H2>
                      </HeaderNavColumn>
                      <HeaderNavColumn
                        onClick={
                          tokenMissionDisabled
                            ? undefined
                            : () =>
                                toggleTray(
                                  participantState && participantState.trayType === 'mission' ? null : 'mission'
                                )
                        }
                        style={{ cursor: tokenMissionDisabled ? 'auto' : 'pointer' }}
                        active={participantState && participantState.trayType === 'mission'}
                        disabled={tokenMissionDisabled}>
                        My Token Mission{' '}
                        <CircleButton
                          size="s"
                          children={participantState && participantState.trayType === 'mission' ? '▲' : '▼'}
                          pressed={participantState && participantState.trayType === 'mission'}
                          disabled={screenLocked}
                        />
                      </HeaderNavColumn>
                    </>
                  )}
                </Row>
              </Row>
            </Container>
          </Header>
          {participant && participantState && participantState.trayType === 'mission' && participant.tokenMission && (
            <TokenMissions>
              <Container size="l">
                <TokenMission>
                  <span>{participant.tokenMission}</span>
                </TokenMission>
              </Container>
            </TokenMissions>
          )}
          {participantState && participantState.trayType === 'gadgets' && (
            <HeaderTray>
              <GadgetTray readOnly={sessionUserType !== 'agent'} />
            </HeaderTray>
          )}
          {participantState && participantState.trayType !== null && <TrayCatcher onClick={() => toggleTray(null)} />}
        </HeaderContainer>
        {children}
      </Row>
    </PreviewGadgetPackStateProvider>
  )
}

export const HeaderContainer = styled.section<{ previewing?: boolean }>`
  position: fixed;
  z-index: 10;
  top: ${p => (p.previewing ? PREVIEW_HEADER_HEIGHT : 0)}px;
  left: 0;
  right: 0;
`
export const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${PARTICIPANT_HEADER_HEIGHT}px;
  background-color: #edf2fa;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`

export const HeaderNavColumn = styled(NavColumn)`
  ${fontLight}
  height: 100%;
  padding: 0 15px;
  white-space: nowrap;
  background: transparent;
  ${p =>
    p.active
      ? css`
          background-color: #d9e0ec;
        `
      : ''}
  &:first-child {
    border-left: none;
  }
  & h2,
  & button {
    margin-left: 10px;
  }
  & h2 {
    font-size: 18px;
    line-height: 1;
    color: ${p => p.theme.buttonBorderTopColor};
  }
`

const TokenMission = styled.div`
  ${fontRegular}
  font-size: 22px;
  text-align: right;
  padding-right: 15px;
`
export const HeaderTray = styled.div`
  position: absolute;
  z-index: -1;
  top: 65px;
  left: 0;
  right: 0;
  color: #fff;
  background-color: #464b61;
  padding: 15px 0;
`

const TokenMissions = styled(HeaderTray)`
  & ${TokenMission} + ${TokenMission} {
    margin-top: 10px;
  }
`

export const TrayCatcher = styled(HeaderTray)`
  z-index: -2;
  height: calc(100vh - 65px);
  opacity: 0;
`

export const LockOverlay = styled.div`
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
`

const LockWrapper = styled.div`
  width: 25px;
  display: flex;
  align-items: center;
  margin-right: 5px;

  img {
    width: 100%;
  }
`
