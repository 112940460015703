/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useReducer, useRef, useEffect, useContext, useCallback } from 'react'
import _debounce from 'lodash/debounce'
import _isEqual from 'lodash/isEqual'
import { useLocalStorage } from 'utils/useStorage'
import { useUserState, UserState } from 'app/UserState'
import { getGadgetPack, getGadgetPackActions, saveGadgetPack } from 'api'

import {
  GadgetPackState,
  friendshipStrengths,
  friendometerValues,
  FriendometerState,
  GadgetPackStateHookObject,
} from 'shared/gadget-pack/types'
import { initialState as eTelligenceInitialState, reducer as eTelligenceReducer } from 'e-telligence/ETelligenceState'

import { GADGET_PACK_ACTION_TRIGGERED, GadgetPackActionTriggeredAction } from 'shared/session/actionTypes'
import {
  ActionTypes,
  UPDATE_STATE,
  UPDATE_ID_CARD,
  ADD_ID_STRENGTH,
  UPDATE_ID_STRENGTH,
  UPDATE_STRESS_BALL,
  UPDATE_FRIENDSHIP_FORMULA,
  ADD_FACT_FILE,
  UPDATE_FACT_FILE,
  REMOVE_FACT_FILE,
  UPDATE_FRIENDOMETER,
  UPDATE_BIONIC_POWERS_SLOT,
  ADD_CUSTOM_BIONIC_POWER,
  UPDATE_CUSTOM_BIONIC_POWER,
  REMOVE_CUSTOM_BIONIC_POWER,
  ADD_FACT_FILE_WITH_ID,
} from 'shared/gadget-pack/actionTypes'

import {
  ADD_RELAXATION_GADGET,
  REMOVE_RELAXATION_GADGET,
  ADD_UPDATE_CUSTOM_RELAXATION_GADGET,
  ADD_UPDATE_EMOTIONOMETER,
  ADD_EMOTIONOMETER_STICKER,
  REMOVE_EMOTIONOMETER_STICKER,
  ADD_UPDATE_CUSTOM_STICKER,
  ADD_CODE_CARD,
  REMOVE_CODE_CARD,
  ADD_UPDATE_CUSTOM_CODE_CARD,
} from 'shared/e-telligence/actionTypes'
import { useSessionState } from 'session/SessionState'
import { useOnline } from 'utils/useOnline'

export function reducer<T extends GadgetPackState>(state: T, action: ActionTypes): GadgetPackState {
  switch (action.type) {
    // GENERAL ACTIONS
    case UPDATE_STATE:
      console.warn('🦾 GP global state update occurred, should only happen on init')
      return action.state

    // GADGET PACK ONLY ACTIONS
    case UPDATE_ID_CARD:
      return { ...state, idCard: { ...state.idCard, ...action.data } }
    case ADD_ID_STRENGTH:
      return { ...state, idCard: { ...state.idCard, strengths: [...state.idCard.strengths, action.strength] } }
    case UPDATE_ID_STRENGTH:
      return {
        ...state,
        idCard: {
          ...state.idCard,
          strengths: state.idCard.strengths.map((strength, index) =>
            index === action.index ? { ...strength, ...action.data } : strength
          ),
        },
      }

    case UPDATE_STRESS_BALL:
      return { ...state, stressBall: { ...state.stressBall, ...action.data } }

    case UPDATE_FRIENDSHIP_FORMULA:
      return {
        ...state,
        friendshipFormula: {
          ...state.friendshipFormula,
          formulae: {
            ...state.friendshipFormula.formulae,
            [action.index]: { ...(state.friendshipFormula.formulae[action.index] || {}), ...action.data },
          },
        },
      }

    case ADD_FACT_FILE:
      return {
        ...state,
        factFiles: [...state.factFiles, { ...action.factFile, id: String(state.factFiles.length + 1) }],
      }
    case ADD_FACT_FILE_WITH_ID:
      return {
        ...state,
        factFiles: [...state.factFiles, { ...action.factFile }],
      }
    case UPDATE_FACT_FILE:
      return {
        ...state,
        factFiles: state.factFiles.map(factFile =>
          factFile.id === action.id ? { ...factFile, ...action.data } : factFile
        ),
      }
    case REMOVE_FACT_FILE:
      return {
        ...state,
        factFiles: state.factFiles.filter(factFile => factFile.id !== action.id),
      }

    case UPDATE_FRIENDOMETER:
      return {
        ...state,
        friendometer: {
          ...state.friendometer,
          [action.friendship]: {
            ...(state.friendometer[action.friendship] || {}),
            [action.key]: action.value,
          },
        },
      }

    case UPDATE_BIONIC_POWERS_SLOT:
      return {
        ...state,
        bionicPowers: {
          ...state.bionicPowers,
          slots: {
            ...state.bionicPowers.slots,
            [action.index]: action.id,
          },
        },
      }
    case ADD_CUSTOM_BIONIC_POWER:
      return {
        ...state,
        bionicPowers: {
          ...state.bionicPowers,
          customBionicPowers: [
            ...state.bionicPowers.customBionicPowers,
            { id: `custom${state.bionicPowers.customBionicPowers.length}`, ...action.bionicPower },
          ],
        },
      }
    case UPDATE_CUSTOM_BIONIC_POWER:
      // TODO: this action wasn't accounted for in original reducer??
      return state
    case REMOVE_CUSTOM_BIONIC_POWER:
      return {
        ...state,
        bionicPowers: {
          customBionicPowers: state.bionicPowers.customBionicPowers.filter(({ id }) => id !== action.id),
        },
      }

    // E-TELLIGENCE ACTIONS
    case ADD_RELAXATION_GADGET:
    case REMOVE_RELAXATION_GADGET:
    case ADD_UPDATE_CUSTOM_RELAXATION_GADGET:
    case ADD_UPDATE_EMOTIONOMETER:
    case ADD_EMOTIONOMETER_STICKER:
    case REMOVE_EMOTIONOMETER_STICKER:
    case ADD_UPDATE_CUSTOM_STICKER:
    case ADD_CODE_CARD:
    case REMOVE_CODE_CARD:
    case ADD_UPDATE_CUSTOM_CODE_CARD:
      // Actually don't think I need to individualize e-tell actions
      // because they modify top level state directly instead of storing
      // things in individual state objects per activity the way GP does
      return { ...state, ...eTelligenceReducer(state, action) }
  }
  return state
}

interface SaveStateObj {
  profileId: number
  accessToken: string
  state: GadgetPackState
  actions: ActionTypes[]
  handleSuccess: (state: GadgetPackState, lastUpdated: number) => void
  handleError: () => void
}

type ActionQueue = {
  [profileId: number]: ActionTypes[]
}

const _saveState = ({ profileId, accessToken, state, actions, handleSuccess, handleError }: SaveStateObj) => {
  console.info(`🦾 Debounced save request now running...`)
  saveGadgetPack(profileId, accessToken, state, actions)
    .then(({ success, updated_at }) => {
      console.log(`🦾 Successfully saved state and new action(s)`)
      handleSuccess(state, updated_at)
    })
    .catch(err => {
      console.warn(`🦾 Failed to saved state and new action(s)`, err)
      handleError()
    })
}

export const initialState: GadgetPackState = {
  ...eTelligenceInitialState,
  idCard: {
    name: '',
    symbol: '',
    strengths: [{ text: '', symbol: '' }],
  },
  stressBall: { colorIndex: 0, mode: 'stress' },
  friendshipFormula: { formulae: {} },
  factFiles: [],
  friendometer: friendshipStrengths.reduce(
    (obj, type) => ({ ...obj, [type]: friendometerValues.reduce((valObj, val) => ({ ...valObj, [val]: '' }), {}) }),
    {} as FriendometerState
  ),
  bionicPowers: {
    slots: {},
    customBionicPowers: [],
  },
}

function initState(state: GadgetPackState): GadgetPackState {
  return { ...initialState, ...state }
}

export const getLocalStorageKey = (state: Pick<UserState, 'profileId' | 'getUserScopes' | 'openAccess'>): string => {
  const isAdult = state.getUserScopes().indexOf('mentor') >= 0
  return `${state.profileId}_${isAdult ? 'mentor' : 'student'}${state.openAccess ? '_open_access' : ''}`
}

const saveDebounce = 3000

function useProviderGadgetPackState() {
  const online = useOnline()
  const userState = useUserState()
  const {
    profileId,
    drupalProfile,
    openAccess,
    accessToken,
    getBaseAction: getUserBaseAction,
    getUserScopes,
  } = userState
  const prefix = getLocalStorageKey({ profileId, getUserScopes, openAccess })

  // NOTE: this will not return anything outside of session context
  const {
    socket,
    dispatch: sessionDispatch,
    sessionUserType,
    getBaseAction: getSessionUserBaseAction,
    isFacilitator,
  } = useSessionState()

  const [lastServerState, setLastServerState] = useLocalStorage<GadgetPackState>(`${prefix}_GP_state`, initialState)
  const [remoteLastUpdated, setRemoteLastUpdated] = useLocalStorage<number | null>(
    `${prefix}_GP_remoteLastUpdated`,
    null
  )
  const [offlineActions, setOfflineActions] = useLocalStorage<ActionTypes[]>(`${prefix}_GP_actions`, [])
  const [facilitatorActionQueue, setFacilitatorActionQueue] = useLocalStorage<ActionQueue>(
    `${drupalProfile ? drupalProfile.user_id : 'IGNORE'}_GP_facilitatorActionQueue`,
    {}
  )
  const [actionsToSave, setActionsToSave] = useLocalStorage<ActionTypes[]>(`${prefix}_GP_actionsToSave`, [])
  const [loadingInitialState, setLoadingInitialState] = useState<boolean>(false)
  // const [saveDebounce, setSaveDebounce] = useState<number>(3000)

  const waitingForUserGeneratedDebounce = useRef<boolean>(false)
  const debouncedSaveStateRef = useRef(_debounce(_saveState, saveDebounce, { trailing: true }))
  const [state, dispatch] = useReducer(reducer, lastServerState, initState)

  const appendActionToFacilitatorQueue = useCallback(
    (profileId: number, action: ActionTypes) => {
      setFacilitatorActionQueue(facilitatorActionQueue => {
        const queue = facilitatorActionQueue[profileId] || []
        return { ...facilitatorActionQueue, [profileId]: [...queue, action] }
      })
    },
    [setFacilitatorActionQueue]
  )

  const clearQueuedActionsForProfile = useCallback(
    (profileId: number) => {
      setFacilitatorActionQueue(facilitatorActionQueue => ({ ...facilitatorActionQueue, [profileId]: [] }))
    },
    [setFacilitatorActionQueue]
  )

  const getQueuedActionsForProfile = useCallback(
    (profileId: number, afterTimestamp?: number) => {
      const queue = facilitatorActionQueue[profileId] || []
      if (afterTimestamp)
        return queue.filter(({ timestamp }) => {
          const adjustedTimestamp = Math.floor(timestamp / 1000)
          return adjustedTimestamp > afterTimestamp
        })
      return queue
    },
    [facilitatorActionQueue]
  )

  const generic_handleSaveSuccess = useCallback(
    (profileId: number, savedState: GadgetPackState, serverLastUpdate: number) => {
      console.info(`🦾 Gadget pack state saved to server! Now updating local caches`)
      waitingForUserGeneratedDebounce.current = false
      setLastServerState(savedState)
      setRemoteLastUpdated(serverLastUpdate)
      setActionsToSave([])
      setOfflineActions([])
    },
    [setActionsToSave, setLastServerState, setOfflineActions, setRemoteLastUpdated]
  )

  const generic_commitNewState = useCallback(
    (
      newState: GadgetPackState,
      newActionsToSave: ActionTypes[],
      { overrideLocalState }: { overrideLocalState: boolean }
    ) => {
      console.info(`🦾 New gadget pack state has been committed`)

      // set local state to inbound state (this must skip the preDispatch step as this update doesn't need to be emitted)
      if (overrideLocalState) {
        console.log('🦾 Overriding local state')
        dispatch({ ...getUserBaseAction(), type: UPDATE_STATE, state: newState })
      }

      // append new actions to any existing actions waiting to be saved
      const updatedActionsToSave = [...actionsToSave, ...newActionsToSave]
      setActionsToSave(updatedActionsToSave)

      // initiate save debounce
      console.log('🦾 Entering save queue')
      waitingForUserGeneratedDebounce.current = true
      debouncedSaveStateRef.current({
        profileId,
        accessToken,
        state: newState,
        actions: updatedActionsToSave,
        handleSuccess: (state: GadgetPackState, lastUpdated: number) =>
          generic_handleSaveSuccess(profileId, state, lastUpdated),
        handleError: () => {
          waitingForUserGeneratedDebounce.current = false
          console.warn(`🦾 Failed to save state to server!`)
        },
      })
    },
    [accessToken, actionsToSave, generic_handleSaveSuccess, getUserBaseAction, profileId, setActionsToSave]
  )

  const preDispatch = useCallback(
    (action: ActionTypes) => {
      console.info('--------------------')
      console.info(`🦾 ${action.type} action dispatched`)
      if (!action.role || !action.timestamp) {
        console.warn('🦾 MALFORMED ACTION! putting in bin', action)
        return
      }

      const jitState = reducer(state, action)
      if (!online) {
        console.log(`🦾 Storing action in offline cache`)
        dispatch(action)
        setOfflineActions(offlineActions => [...offlineActions, action])
      } else {
        if (socket && socket.connected) {
          console.log(`🦾 Emitting action to session socket`)
          sessionDispatch({
            ...getUserBaseAction(),
            type: GADGET_PACK_ACTION_TRIGGERED,
            profileId,
            action,
          })
        }

        dispatch(action)
        generic_commitNewState(jitState, [action], { overrideLocalState: false })
      }
    },
    [generic_commitNewState, getUserBaseAction, online, profileId, sessionDispatch, setOfflineActions, socket, state]
  )

  const cadet_handleInitState = useCallback(
    (newState: GadgetPackState, newRemoteLastUpdated: number | null) => {
      if (offlineActions.length > 0) {
        console.info(`🦾 Cadet has offline actions to process`)
        if (!newRemoteLastUpdated || !remoteLastUpdated || remoteLastUpdated < newRemoteLastUpdated) {
          console.info(`🦾 Need to consolidate offline actions with server actions because state is out of sync`)
          getGadgetPackActions(profileId, accessToken)
            .then(rawActions => {
              console.info(`🦾 Past gadget pack actions loaded from server, consolidating...`)
              const actions = (rawActions.map(({ id, payload, created_at, committed_at }) => ({
                ...payload,
                id,
                timestamp: committed_at * 1000,
              })) as unknown) as ActionTypes[]
              // merge with offline actions ordered by timestamp,
              const allActions = [...actions, ...offlineActions].sort((a, b) => a.timestamp - b.timestamp) // TODO: check that a & b are right way around
              // generate new state from all actions from the beginning of time to now
              let jitState = { ...initialState }
              allActions.forEach(action => (jitState = reducer(jitState, action)))
              // commit new state and new actions to server
              generic_commitNewState(jitState, offlineActions, { overrideLocalState: true })
            })
            .catch(err => {
              // TODO: Handle this?
              console.warn(`🦾 Failed to load user actions from server!`)
            })
        } else {
          console.info(`🦾 Server state had not changed since offline actions were created, all good to append`)
          let jitState: GadgetPackState = newState
          offlineActions.forEach(action => (jitState = reducer(jitState, action)))
          setLastServerState(newState)
          setRemoteLastUpdated(newRemoteLastUpdated)
          // commit new state and new actions to server
          generic_commitNewState(jitState, offlineActions, { overrideLocalState: true })
        }
      } else {
        setLastServerState(newState)
        setRemoteLastUpdated(newRemoteLastUpdated)
        // set local state to inbound state (this must skip the preDispatch step as this update doesn't need to be emitted)
        dispatch({ ...getUserBaseAction(), type: UPDATE_STATE, state: newState })
      }
    },
    [
      accessToken,
      generic_commitNewState,
      getUserBaseAction,
      offlineActions,
      profileId,
      remoteLastUpdated,
      setLastServerState,
      setRemoteLastUpdated,
    ]
  )

  const facilitator_handleInitState = useCallback(
    (newState: GadgetPackState, newRemoteLastUpdated: number, lastUserCommitTimestamp?: number) => {
      let jitState: GadgetPackState = newState
      console.log('🦾', { lastUserCommitTimestamp, newRemoteLastUpdated })
      const queuedActions = getQueuedActionsForProfile(profileId)
      const queuedActionsToApply = getQueuedActionsForProfile(
        profileId,
        lastUserCommitTimestamp || newRemoteLastUpdated
      )
      if (queuedActionsToApply.length > 0) {
        console.log(`🦾 Applying queued actions for profile ${profileId}:`, queuedActionsToApply)
        queuedActionsToApply.forEach(action => (jitState = reducer(jitState, action)))
        console.log(`🦾 Clearing action queue for profile ${profileId}`)
        clearQueuedActionsForProfile(profileId)
      } else {
        console.log(`🦾 There are no queued actions for profile ${profileId}`)
        if (queuedActions.length > 0) {
          console.log(`🦾 Clearing action queue for profile ${profileId}`)
          clearQueuedActionsForProfile(profileId)
        }
      }

      // note: not sure if there would be side effects for setting local server state to jit state instead of received state
      // it makes sense to store jit state because if re-initializing later we'd want to have latest calculated state
      // and then just compare the last update timestamp to fetch a newer state if necessary
      setLastServerState(jitState)
      setRemoteLastUpdated(newRemoteLastUpdated)
      // set local state to inbound state (this must skip the preDispatch step as this update doesn't need to be emitted)
      dispatch({ ...getUserBaseAction(), type: UPDATE_STATE, state: jitState })
    },
    [
      clearQueuedActionsForProfile,
      getQueuedActionsForProfile,
      getUserBaseAction,
      profileId,
      setLastServerState,
      setRemoteLastUpdated,
    ]
  )

  // Effect to run only on GP init (or if user context changes in the case of facilitator switching cadet tab)
  useEffect(() => {
    if (profileId === 0) {
      console.warn(`🦾 Gadget pack state will initialize once a cadet tab is focused`)
      return
    }
    console.log('------------------')
    console.info(`🦾 Gadget pack state initializing for profile ID ${profileId}`)
    setLoadingInitialState(true)
    console.info(`🦾 Fetching latest gadget pack state from server...`)
    getGadgetPack(profileId, accessToken, remoteLastUpdated)
      .then(({ response_code, state: serverState, updated_at, last_user_commit }) => {
        // Status 204 indicates that the local last change date matches the server's last change date
        // Ergo server did not send back a new state
        if (response_code === 204) {
          console.log(`🦾 Server reports that local user state is the latest`)
          const existingState = lastServerState || initialState
          if (isFacilitator) facilitator_handleInitState(existingState, remoteLastUpdated as number)
          else cadet_handleInitState(existingState, remoteLastUpdated as number)
        } else {
          if (!serverState) {
            console.log(`🦾 User has no state saved on server`)
            if (isFacilitator) facilitator_handleInitState(initialState, updated_at, last_user_commit)
            else cadet_handleInitState(initialState, updated_at)
          } else {
            console.log(`🦾 User received fresh state from server`)
            if (isFacilitator) facilitator_handleInitState(serverState, updated_at, last_user_commit)
            else cadet_handleInitState(serverState, updated_at)
          }
        }
        setLoadingInitialState(false)
      })
      .catch(err => {
        // Nothing happens here, falls back to local state, otherwise initial state
        console.warn('🦾 Gadget pack server state request failed', err)
        setLoadingInitialState(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId])

  // Effect to only run if profileId or socket state changes
  useEffect(() => {
    if (!socket) return
    // const userUid = getUserUid({ authProvider: 'sas', profileId })

    const handleExternalGadgetPackAction = (action: GadgetPackActionTriggeredAction) => {
      if (action.profileId === profileId) {
        console.log(`🦾 Received new gadget pack state from socket for ${action.profileId} -- you`)
        if (isFacilitator && loadingInitialState) {
          console.warn(`🦾 adding to facilitator's GP action queue for ${action.profileId} (currently focused)`)
          appendActionToFacilitatorQueue(action.profileId, action.action)
        } else {
          if (waitingForUserGeneratedDebounce.current) {
            console.log(`🦾 Canceling queued (debounced) save because GP action sender will be saving a newer version`)
            debouncedSaveStateRef.current.cancel()
            waitingForUserGeneratedDebounce.current = false
          }
          console.log(`🦾 Dispatching remotely acquired GP action to local state`)
          console.log(action.action)
          dispatch(action.action)
        }
      } else {
        if (isFacilitator) {
          console.warn(`🦾 adding to facilitator's GP action queue for ${action.profileId} (not currently focused)`)
          appendActionToFacilitatorQueue(action.profileId, action.action)
        } else {
          console.warn(
            `🦾 Received new gadget pack state from socket for ${action.profileId} -- not you (${profileId}) -- this should never happen`
          )
        }
      }
    }

    // Rest assured this only gets dispatched to sockets that are registered as facilitators
    // The fact it's able to match user_uid
    socket.on(GADGET_PACK_ACTION_TRIGGERED, handleExternalGadgetPackAction)
    return () => {
      if (socket) socket.off(GADGET_PACK_ACTION_TRIGGERED, handleExternalGadgetPackAction)
    }
  }, [socket, profileId, isFacilitator, loadingInitialState, appendActionToFacilitatorQueue])

  return {
    state,
    dispatch: preDispatch,
    loadingInitialState,
  }
}

function noop(): any {}

export const GadgetPackStateContext = React.createContext<GadgetPackStateHookObject>({
  state: initialState,
  // actions: [],
  // revision: 0,
  dispatch: noop,
  loadingInitialState: true,
  // storagePrefix: '',
  // resetLocalState: noop,
  // saveDebounce: 3000,
  // setSaveDebounce: noop,
})

export const GadgetPackStateProvider: React.FC = ({ children }) => {
  const state = useProviderGadgetPackState()
  return <GadgetPackStateContext.Provider value={state}>{children}</GadgetPackStateContext.Provider>
}

export function useGadgetPackState() {
  return useContext(GadgetPackStateContext)
}
