import React from 'react'
import styled from 'styled-components'
import { BionicPowersScreen } from 'gadget-pack/BionicPowers'
import { Container } from 'common/ui'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'

export const BionicPowersActivity: React.FC = () => {
  const profileUid = useParticipantProfileUid()

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${profileUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'bionic-powers',
          subcategory: null,
          event_key: null,
          event_value: null,
        }}
      />
      <BionicPowersContainer>
        <BionicPowersScreen hideSave hideExit hideDownload bgImage={require('session/assets/bionic-powers-bg.png')} />
      </BionicPowersContainer>
    </>
  )
}

const BionicPowersContainer = styled.div`
  ${Container} {
    width: 100%;
  }

  .bionic-suit-img {
    min-height: calc(100vh - 420px);
  }
`
