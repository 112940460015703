export function postJson(url: string, data: any, config: RequestInit = {}, retry?: Partial<RetryConfig>) {
  return retryFetch(
    url,
    {
      method: 'POST',
      body: JSON.stringify(data),
      ...config,
      headers: {
        'content-type': 'application/json',
        ...(config.headers || ''),
      },
    },
    retry
  ).then(toJson)
}

/**
 * Catches HTTP errors (status codes) and JSON decode errors (invalid JSON).
 * Used as a promise-based interceptor for fetch() requests.
 *
 * eg. fetch(url).then(toJson).then(data => console.log('data', data)).catch(response => console.log('error', response))
 */
export function toJson(response: Response) {
  return response
    .json()
    .catch(error => {
      throw response
    })
    .then(json => {
      if (!response.ok) {
        if (typeof json === 'object' && !('code' in json)) json.code = response.status
        throw json
      }
      return json
    })
}

export interface RetryConfig {
  retries: number
  timeout: number
  factor: number
}

const defaultRetryConfig: RetryConfig = {
  retries: 3,
  timeout: 50,
  factor: 5,
}
// 0, +50, +250, +1250

export function retryFetch(input: RequestInfo, init?: RequestInit, retry?: Partial<RetryConfig>) {
  const retryConfig: RetryConfig = { ...defaultRetryConfig, ...retry }
  const isDevApi = window.location.host === 'localhost:5000' && typeof input === 'string' && input.indexOf('/api') === 0

  return fetch((isDevApi ? 'http://dev.sas-api' : '') + input, init)
    .catch(err => err)
    .then(res => {
      if (retryConfig.retries > 0 && (res instanceof Error || (res.status >= 500 && res.status < 600))) {
        return new Promise<Response>((resolve, reject) => {
          window.setTimeout(
            () =>
              retryFetch(input, init, {
                ...retryConfig,
                retries: retryConfig.retries - 1,
                timeout: retryConfig.timeout * retryConfig.factor,
              }).then(resolve, reject),
            retryConfig.timeout
          )
        })
      } else if (res instanceof Error) {
        throw res
      }
      return res
    })
}

export function retry<T>(func: () => Promise<T>, config: RetryConfig = defaultRetryConfig) {
  return func().catch(err => {
    if (config.retries > 0) {
      return new Promise<T>((resolve, reject) => {
        window.setTimeout(() => {
          retry(func, {
            ...config,
            retries: config.retries - 1,
            timeout: config.timeout * config.factor,
          }).then(resolve, reject)
        }, config.timeout)
      })
    }
    throw err
  })
}
