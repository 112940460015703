/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useOnline } from 'utils/useOnline'
import { isOnline } from 'utils/deviceUtils'
import { H2, P } from 'common/ui'
import { SVG } from 'common/SVG'
import { useSessionState } from './SessionState'

export const OfflineMessage: React.FC = () => {
  const online = useOnline()
  const { socket, sessionData } = useSessionState()
  const [dismissed, setDismissed] = useState(true)
  const [disconnected, setDisconnected] = useState((socket && socket.disconnected) || !online)
  const [reconnectAttempts, setReconnectAttempts] = useState(0)
  const previousOnline = useRef(isOnline())

  useEffect(() => {
    if (previousOnline.current !== online) {
      if (online) setTimeout(() => setDismissed(true), 1500)
      setDismissed(false)
      previousOnline.current = online
    }
  }, [online])

  const handleDisconnect = useCallback(
    (attempt?: number) => {
      if (!sessionData || !sessionData.uid) return
      if (attempt) setReconnectAttempts(attempt)
      setDisconnected(true)
      setDismissed(false)
    },
    [sessionData]
  )

  const handleReconnect = useCallback(() => {
    if (!sessionData || !sessionData.uid) return
    setDisconnected(false)
    setReconnectAttempts(0)
    setTimeout(() => setDismissed(true), 1500)
  }, [sessionData])

  useEffect(() => {
    if (!socket) return
    socket.on('disconnect', handleDisconnect)
    socket.on('reconnect_attempt', handleDisconnect)
    socket.on('reconnect', handleReconnect)
  }, [handleDisconnect, handleReconnect, socket])

  return (
    <OfflineCover visible={!dismissed}>
      <div>
        <SVG size={48} viewboxSize={24}>
          {disconnected ? (
            <path d="M15.9 5c-.17 0-.32.09-.41.23l-.07.15-5.18 11.65c-.16.29-.26.61-.26.96 0 1.11.9 2.01 2.01 2.01.96 0 1.77-.68 1.96-1.59l.01-.03L16.4 5.5c0-.28-.22-.5-.5-.5zM1 9l2 2c2.88-2.88 6.79-4.08 10.53-3.62l1.19-2.68C9.89 3.84 4.74 5.27 1 9zm20 2l2-2c-1.64-1.64-3.55-2.82-5.59-3.57l-.53 2.82c1.5.62 2.9 1.53 4.12 2.75zm-4 4l2-2c-.8-.8-1.7-1.42-2.66-1.89l-.55 2.92c.42.27.83.59 1.21.97zM5 13l2 2c1.13-1.13 2.56-1.79 4.03-2l1.28-2.88c-2.63-.08-5.3.87-7.31 2.88z" />
          ) : online ? (
            <path d="M12.01 21.49L23.64 7c-.45-.34-4.93-4-11.64-4C5.28 3 .81 6.66.36 7l11.63 14.49.01.01.01-.01z" />
          ) : (
            <path d="M23.64 7c-.45-.34-4.93-4-11.64-4-1.5 0-2.89.19-4.15.48L18.18 13.8 23.64 7zm-6.6 8.22L3.27 1.44 2 2.72l2.05 2.06C1.91 5.76.59 6.82.36 7l11.63 14.49.01.01.01-.01 3.9-4.86 3.32 3.32 1.27-1.27-3.46-3.46z" />
          )}
        </SVG>
        <H2>
          {disconnected ? (
            `Unable to connect to server.`
          ) : online ? (
            <>
              You're back online!{' '}
              <span style={{ position: 'absolute', fontSize: 24, marginLeft: 5, marginTop: -5 }}>✓</span>
            </>
          ) : (
            `You've gone offline.`
          )}
        </H2>
        <P>
          {disconnected ? (
            <>
              Refresh this page if this message stays for more than 10 seconds.
              <br />
              {reconnectAttempts && `Reconnect attempts: ${reconnectAttempts}`}
            </>
          ) : online ? (
            <>&nbsp;</>
          ) : (
            `Please reconnect to the internet to resume your session.`
          )}
        </P>
      </div>
    </OfflineCover>
  )
}

const OfflineCover = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${p => (p.visible ? 1 : 0)};
  pointer-events: ${p => (p.visible ? 'auto' : 'none')};
  transition: opacity ease-in-out 0.75s;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  & ${P}, & ${H2} {
    color: #fff;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  & svg {
    fill: #fff;
    display: inline-block;
  }
`
