import React from 'react'
import { SpacerSize, Spacer } from 'common/ui'
import { intersperse } from './intersperse'

export function intersperseSpacers<T>(elems: T[], size: SpacerSize = 'm') {
  return elems.reduce(
    intersperse(i => <Spacer key={`spacer${i}`} size={size} />),
    []
  )
}

export function conditionallyIntersperseSpacers<T>(
  data: T[],
  mapper: (item: T, index: number) => JSX.Element | null,
  condition: (a: T, b: T) => boolean,
  size: SpacerSize = 'm'
): JSX.Element[] {
  return data.reduce((acc, item, i) => {
    const elems = [mapper(item, i)]
    if (i > 0 && condition(data[i - 1], data[i]))
      elems.unshift(<Spacer key={`spacer${i}`} size={size} style={{ display: 'inline-block' }} />)
    return acc.concat(elems.filter(_ => _) as JSX.Element[])
  }, [] as JSX.Element[])
}
