import MobileDetect from 'mobile-detect'
const md = new MobileDetect(window.navigator.userAgent)

export const isPhone = () => Boolean(md.phone())

export const isMobileOrTablet = () => Boolean(md.mobile() || md.phone() || md.tablet())

export const isIOS = () => window.navigator.vendor.toLowerCase().indexOf('apple') >= 0

export const isAndroid = () => window.navigator.userAgent.toLowerCase().indexOf('android') >= 0

export const isIE11 = () => window.navigator.userAgent.toLowerCase().indexOf('trident/7.0') >= 0

export const isChromium = () => !!(window as any).chrome

export const isPushNotificationSupported = () => 'serviceWorker' in navigator && 'PushManager' in window

export const isRunningStandalone = () => !!window.matchMedia('(display-mode: standalone)').matches

export const isOnline = (): boolean => (window.navigator.onLine === undefined ? true : window.navigator.onLine)

/**
 *  Adapted from https://github.com/danalloway/detect-emoji-support/blob/master/lib/index.js
 */
let supportsEmojisCached: boolean | undefined = undefined
export const supportsEmojis = () => {
  if (supportsEmojisCached !== undefined) return supportsEmojisCached

  const ua = window.navigator.userAgent
  const unsupportedUaParts = [
    'Windows NT 6.1', // Windows 7
    'Windows NT 6.0', // Windows Vista
    'Windows NT 5.1', // Windows XP
    'Windows NT 5.0', // Windows 2000
    'Trident/7.', // IE11
    'MSIE 10.', // IE10
    'MSIE 9.', // IE9
    'Mac OS X 10_6', // Snow Leopard
    'Mac OS X 10_5', // Leopard
    'Mac OS X 10_4', // Tiger
    'Android 4.0',
    'Android 3.',
    'Android 2.',
  ]
  if (unsupportedUaParts.filter(v => ua.indexOf(v) >= 0).length > 0) {
    supportsEmojisCached = false
    return supportsEmojisCached
  }

  const ctx = document.createElement('canvas').getContext('2d')
  if (ctx === null) return false

  const backingStoreRatio =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1
  const offset = 12 * backingStoreRatio

  ctx.fillStyle = '#f00'
  ctx.textBaseline = 'top'
  ctx.font = '32px Arial'
  ctx.fillText('\ud83d\udc28', 0, 0) // U+1F428 KOALA

  supportsEmojisCached = ctx.getImageData(offset, offset, 1, 1).data[0] !== 0
  return supportsEmojisCached
}
;(window as any).supportsEmojis = supportsEmojis

/**
 * Adapted from https://stackoverflow.com/a/40511297
 */
let supportsWebMAlphaCached: boolean | undefined = undefined

export const supportsWebMAlpha = (callback: (val: boolean) => void) => {
  if (supportsWebMAlphaCached !== undefined) {
    callback(supportsWebMAlphaCached)
    return
  }

  if (isIE11()) {
    supportsWebMAlphaCached = false
    callback(supportsWebMAlphaCached)
  }

  const vid = document.createElement('video')
  vid.autoplay = false
  vid.loop = false
  vid.style.display = 'none'

  const fireCallback = (supported: boolean) => () => {
    if (vid) {
      try { document.body.removeChild(vid) } catch (error) { } // prettier-ignore
    }
    supportsWebMAlphaCached = supported
    callback(supported)
  }

  vid.addEventListener(
    'loadeddata',
    () => {
      document.body.removeChild(vid)
      const canvas = document.createElement('canvas')
      if (!(canvas.getContext && canvas.getContext('2d'))) return callback(false)
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        fireCallback(false)()
        return
      }
      ctx.drawImage(vid, 0, 0)
      if (ctx.getImageData(0, 0, 1, 1).data[3] === 0) {
        fireCallback(true)()
      } else {
        fireCallback(false)()
      }
    },
    false
  )

  vid.addEventListener('error', fireCallback(false))
  vid.addEventListener('stalled', fireCallback(false))
  vid.addEventListener('abort', fireCallback(false))

  const source = document.createElement('source')
  source.src =
    'data:video/webm;base64,GkXfowEAAAAAAAAfQoaBAUL3gQFC8oEEQvOBCEKChHdlYm1Ch4ECQoWBAhhTgGcBAAAAAAACBRFNm3RALE27i1OrhBVJqWZTrIHlTbuMU6uEFlSua1OsggEjTbuMU6uEHFO7a1OsggHo7AEAAAAAAACqAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVSalmAQAAAAAAADIq17GDD0JATYCNTGF2ZjU3LjU3LjEwMFdBjUxhdmY1Ny41Ny4xMDBEiYhARAAAAAAAABZUrmsBAAAAAAAARq4BAAAAAAAAPdeBAXPFgQGcgQAitZyDdW5khoVWX1ZQOYOBASPjg4QCYloA4AEAAAAAAAARsIFAuoFAmoECU8CBAVSygQQfQ7Z1AQAAAAAAAGfngQCgAQAAAAAAAFuhooEAAACCSYNCAAPwA/YAOCQcGFQAADBgAABnP///NXgndmB1oQEAAAAAAAAtpgEAAAAAAAAk7oEBpZ+CSYNCAAPwA/YAOCQcGFQAADBgAABnP///Vttk7swAHFO7awEAAAAAAAARu4+zgQC3iveBAfGCAXXwgQM='
  vid.appendChild(source)
  document.body.appendChild(vid) // this is required for IE
}
