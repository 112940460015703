import React, { useState } from 'react'
import { useGlobalState } from 'app/GlobalState'
import Modal from 'app/Modal'
import { Panel, Row, Button, Spacer } from './ui'
import PlaybackRate from './PlaybackRate'
import { intersperse } from 'utils/intersperse'
import { GameModeSelector } from 'app/GameModeSelector'

export const ShortcutMenu: React.FC = () => {
  const { setSettingsVisible, shortcutButtons, debug } = useGlobalState()
  const [open, setOpen] = useState(false)
  if (!debug) return null
  return (
    <>
      <div
        id="show-shortcut-menu"
        onClick={() => setOpen(true)}
        style={{ position: 'absolute', top: 0, left: 0, width: 100, height: 100 }}></div>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
        <Panel padding="s" shadow={false}>
          {shortcutButtons
            .map((ref, i) => (
              <Row key={i} style={{ overflowX: 'auto' }}>
                {ref.current
                  .map((button, i) => (
                    <Button key={i} flex size="s" {...button}>
                      {button.label}
                    </Button>
                  ))
                  .reduce(
                    intersperse(i => <Spacer key={`s${i}`} size="xs" />),
                    []
                  )}
              </Row>
            ))
            .reduce(
              intersperse(i => <Spacer key={`s${i}`} size="xs" />),
              []
            )}
          <Spacer size="xs" />
          <PlaybackRate />
          <Spacer size="xs" />
          <GameModeSelector />
          <Spacer size="xs" />
          <Button
            size="s"
            onClick={() => {
              setOpen(false)
              setSettingsVisible(true)
            }}>
            Settings
          </Button>
        </Panel>
      </Modal>
    </>
  )
}
