import { BaseAction } from '../types'
import { TrainingPanel, TrainingState as State, TrainingUnit } from './types'

export const UPDATE_STATE = 'UPDATE_TRAINING_STATE'
export const INIT_STATE = 'INIT_STATE'
export const UPDATE_TRAINING_PAGE = 'UPDATE_TRAINING_PAGE'
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP'
export const ATTEMPT_QUESTION = 'ATTEMPT_QUESTION'
export const CREATE_QUESTION = 'CREATE_QUESTION'
export const UPDATE_NOTES = 'UPDATE_NOTES'

export interface TrainingBaseAction {}

export interface InitStateAction extends TrainingBaseAction {
  type: typeof INIT_STATE
  state: State
}
export interface UpdateCurrentStepAction extends TrainingBaseAction {
  type: typeof UPDATE_CURRENT_STEP
  stepUid: TrainingUnit['uid']
}

export interface AttemptQuestionAction extends TrainingBaseAction {
  type: typeof ATTEMPT_QUESTION
  stepUid: string
  panelUid: string
  value: any
  correct: boolean
}

export interface CreateQuestionAction extends TrainingBaseAction {
  type: typeof CREATE_QUESTION
  stepUid: string
  panel: TrainingPanel
}

export interface UpdateNotesAction extends TrainingBaseAction {
  type: typeof UPDATE_NOTES
  stepUid: string
  notes: string
}

export interface UpdateStateAction extends BaseAction {
  type: typeof UPDATE_STATE
  state: State
}

export interface UpdateTrainingPageAction extends BaseAction {
  type: typeof UPDATE_TRAINING_PAGE
  stepUid: string
}

export type ActionTypes =
  | InitStateAction
  | UpdateStateAction
  | UpdateTrainingPageAction
  | UpdateCurrentStepAction
  | AttemptQuestionAction
  | CreateQuestionAction
  | UpdateNotesAction
