export const tempSessionUids = [
  'lr',
  'ses',
  'bigfish',
  'sst',
  'links',
  // 'demo1',
  // 'demo2',
  // 'demo3',
  // 'demo4',
  // 'demo5',
  // 'demo6',
  // 'demo7',
  // 'demo8',
  // 'demo9',
  // 'demo10',
  // 'demo11',
  // 'demo12',
  // 'demo13',
  // 'demo14',
] as const
export type TempSessionUid = typeof tempSessionUids[number]
export const tempSessionMap: {
  [key in TempSessionUid]: { [key in 'agent' | 'mentor']: { [key: string]: boolean | 'active' } }
} = {
  // canada
  lr: {
    agent: {
      '1': true,
      '2': true,
      '3': true,
      '4': true,
      '5': true,
      '6': 'active',
      '7': 'active',
      '8': 'active',
      '9': 'active',
    },
    mentor: {
      'parent-intro': true,
      'parent-1': true,
      'parent-2': true,
      'parent-3': true,
      'parent-4': true, // pls
      'parent-5': true,
      'parent-6': 'active',
      'parent-7': 'active',
      'parent-8': 'active',
      'parent-9': 'active',
    },
  },
  // sydney
  ses: {
    agent: {
      'booster-1': true,
      'booster-2': true,
      'booster-3': true,
      'booster-4': 'active',
    },
    mentor: {},
  },
  links: {
    agent: {
      '1': 'active',
      '2': 'active',
      '3a': 'active',
    },
    mentor: {
      'parent-intro': 'active',
      'parent-1': 'active',
      'parent-2': 'active',
    },
  },
  /*
  demo1: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo2: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo3: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo4: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo5: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo6: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo7: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo8: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo9: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo10: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo11: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo12: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo13: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  demo14: {
    agent: {
      // '1': 'active',
      november: 'active',
    },
    mentor: {},
  },
  */

  bigfish: {
    agent: {
      'dev-demo': 'active',
      '1': 'active',
      '2': 'active',
      '3': 'active',
      '4': 'active',
      '5': 'active',
      '6': 'active',
      '7': 'active',
      '8': 'active',
      '9': 'active',
      '1a': 'active',
      '1b': 'active',
      '2a': 'active',
      '2b': 'active',
      '3a': 'active',
      '3b': 'active',
      '4a': 'active',
      '4b': 'active',
      '5a': 'active',
      '5b': 'active',
      '6a': 'active',
      '6b': 'active',
      '7a': 'active',
      '7b': 'active',
      '8a': 'active',
      '8b': 'active',
      '9a': 'active',
      '9b': 'active',
    },
    mentor: {
      'parent-intro': 'active',
      'parent-1': 'active',
      'parent-2': 'active',
      'parent-3': 'active',
      'parent-4': 'active',
      'parent-5': 'active',
      'parent-6': 'active',
      'parent-7': 'active',
      'parent-8': 'active',
      'parent-9': 'active',
      'parent-1a': 'active',
      'parent-1b': 'active',
      'parent-2a': 'active',
      'parent-2b': 'active',
      'parent-3a': 'active',
      'parent-3b': 'active',
      'parent-4a': 'active',
      'parent-4b': 'active',
      'parent-5a': 'active',
      'parent-5b': 'active',
      'parent-6a': 'active',
      'parent-6b': 'active',
      'parent-7a': 'active',
      'parent-7b': 'active',
      'parent-8a': 'active',
      'parent-8b': 'active',
      'parent-9a': 'active',
      'parent-9b': 'active',
    },
  },
  sst: {
    agent: {
      'dev-demo': 'active',
      '1': 'active',
      '2': 'active',
      '3': 'active',
      november: 'active',
      '5': 'active',
      '6': 'active',
      '7': 'active',
      '8': 'active',
      '9': 'active',
      '1a': 'active',
      '1b': 'active',
      '2a': 'active',
      '2b': 'active',
      '3a': 'active',
      '3b': 'active',
      '4a': 'active',
      '4b': 'active',
      '5a': 'active',
      '5b': 'active',
      '6a': 'active',
      '6b': 'active',
      '7a': 'active',
      '7b': 'active',
      '8a': 'active',
      '8b': 'active',
      '9a': 'active',
      '9b': 'active',
    },
    mentor: {
      'parent-intro': 'active',
      'parent-1': 'active',
      'parent-2': 'active',
      'parent-3': 'active',
      'parent-4': 'active',
      'parent-5': 'active',
      'parent-6': 'active',
      'parent-7': 'active',
      'parent-8': 'active',
      'parent-9': 'active',
      'parent-1a': 'active',
      'parent-1b': 'active',
      'parent-2a': 'active',
      'parent-2b': 'active',
      'parent-3a': 'active',
      'parent-3b': 'active',
      'parent-4a': 'active',
      'parent-4b': 'active',
      'parent-5a': 'active',
      'parent-5b': 'active',
      'parent-6a': 'active',
      'parent-6b': 'active',
      'parent-7a': 'active',
      'parent-7b': 'active',
      'parent-8a': 'active',
      'parent-8b': 'active',
      'parent-9a': 'active',
      'parent-9b': 'active',
    },
  },
}
