import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { P, Button, Spacer, CollapseMargin, Form, TextInput as _TextInput } from 'common/ui'

import { fontRegular, fontBold } from 'fonts'
import { useUserState } from '../app/UserState'
import { ThemeRadioGroup } from '../app/ThemeRadioGroup'
import { createProfile, updateProfile } from 'api'
import { IntroTemplate } from './IntroTemplate'
import { useGlobalState } from 'app/GlobalState'
import { THEMES } from 'themes'
import { getInputProps } from 'utils/virtualKeyboard'

function errorMessageForCode(errorCode: string) {
  switch (errorCode) {
    case 'NO-PROFILE':
      return 'Your Game Play Code is incorrect. If you have forgotten your code please ask an adult to help.'
    case 'LICENCE-EXPIRED':
      return 'Your Game Play Code has expired.'
    case 'ALL-PROFILES-USED':
      return 'All profiles for this licence have been created.'
    default:
      return 'An unexpected error occurred. Please try again.'
  }
}

export const NewAgentPage: React.FC<{ onComplete: (openAccess: boolean) => void; onContinue: () => void }> = ({
  onComplete,
  onContinue,
}) => {
  const {
    profiles,
    profileName,
    setProfiles,
    profileId,
    setProfileId,
    setProfileName,
    setOpenAccess,
    accessToken,
  } = useUserState()
  const { themeIndex } = useGlobalState()
  const [agentName, setAgentName] = useState('')
  const [error, setError] = useState('')
  const [warning, setWarning] = useState('')
  const [busy, setBusy] = useState(false)

  const openAccess = agentName.toLowerCase() === 'open_access'

  const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    setBusy(true)

    const profile = profiles.find(p => p.profile_name === agentName)

    if (profile) {
      setProfileId(profile.id)
      setProfileName(profile.profile_name || '')
      setOpenAccess(openAccess)
      onComplete(openAccess)
    } else if (profileName === '' && !openAccess) {
      updateProfile(
        {
          id: profileId,
          profile_name: agentName,
          theme_name: THEMES[themeIndex].name,
        },
        accessToken
      )
        .then(() => {
          setProfileName(agentName)
          onComplete(openAccess)
        })
        .catch(err => {
          setError(errorMessageForCode(err.message))
          setBusy(false)
        })
    } else {
      createProfile(
        {
          profile_name: agentName,
          theme_name: THEMES[themeIndex].name,
        },
        accessToken
      )
        .then(profile => {
          ReactDOM.unstable_batchedUpdates(() => {
            setProfiles(profiles => profiles.concat([profile]))
            setProfileId(profile.id)
            setProfileName(profile.profile_name || '')
            setOpenAccess(openAccess)
            onComplete(openAccess)
          })
        })
        .catch(err => {
          setError(errorMessageForCode(err.message))
          setBusy(false)
        })
    }
  }

  return (
    <IntroTemplate title="New Agent">
      <CollapseMargin>
        <P align="center">Enter your Agent Name</P>
      </CollapseMargin>
      <Spacer />
      <Form onSubmit={onSubmit}>
        <TextInput
          error={!!error}
          readOnly={busy || !!error}
          autoFocus
          value={agentName}
          onChange={e => {
            const hasInvalidSymbol = e.target.value.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu)
            if (hasInvalidSymbol) {
              setWarning('Please only use letters and numbers for your Agent Name')
            } else {
              setAgentName(e.target.value)
              setWarning('')
              setError('')
            }
          }}
          {...getInputProps()}
        />

        {warning && (
          <>
            <Spacer />
            <FormWarning>{warning}</FormWarning>
          </>
        )}

        {error && (
          <>
            <Spacer />
            <FormError>{error}</FormError>
          </>
        )}

        {!error && (
          <>
            <Spacer />
            <FormLabel>Select your theme</FormLabel>
            <Spacer size="xs" />
            <ThemeRadioGroup size="s" />
          </>
        )}

        <Spacer size="l" />

        {!error && (
          <Button type="submit" disabled={busy || agentName === ''}>
            Play
          </Button>
        )}
      </Form>

      {error && (
        <Button type="button" onClick={onContinue}>
          Continue
        </Button>
      )}
      <Spacer />
    </IntroTemplate>
  )
}

const TextInput = styled(_TextInput)`
  text-align: center;
  ${fontBold}
  font-size: 22px;
`

const FormError = styled.div`
  ${fontRegular}
  color: #ab0303;
  padding: 10px;
  border-radius: 10px;
`

const FormWarning = styled.div`
  ${fontRegular}
  color: #1c8cde;
  padding: 10px;
  border-radius: 10px;
`

const FormLabel = styled.label`
  ${fontBold}
  font-size: 16px;
  color: #001947;
  padding-left: 2px;
`
