import { Vector as Coordinate, UploadResponse } from '../../../types'
import { DrawingSectionState } from '../../types'

export const SET_DRAW_ACTION = 'SET_DRAW_ACTION'
export const SET_ERASE_ACTION = 'SET_ERASE_ACTION'
export const SET_CLEAR_ACTION = 'SET_CLEAR_ACTION'
export const UPDATE_DRAWING_STATE = 'UPDATE_DRAWING_STATE'

interface BaseDrawAction {
  index?: number
  participant_uid: string
}
interface SetDrawAction extends BaseDrawAction {
  type: typeof SET_DRAW_ACTION
  coordinates: Coordinate[]
  lineWidth: number
  colour: string
}
interface SetEraseAction extends BaseDrawAction {
  type: typeof SET_ERASE_ACTION
  coordinates: Coordinate[]
  lineWidth: number
}
interface SetClearAction extends BaseDrawAction {
  type: typeof SET_CLEAR_ACTION
}
interface SetStateAction extends BaseDrawAction {
  type: typeof UPDATE_DRAWING_STATE
  state: Partial<DrawingSectionState>
}

export type DrawingActionTypes = SetDrawAction | SetEraseAction | SetStateAction | SetClearAction

export const ADD_DRAWING_ACTION = 'ADD_DRAWING_ACTION'
export const TOGGLE_CANVAS_LOCK = 'TOGGLE_CANVAS_LOCK'
export const ADD_UNDO_ACTION = 'ADD_UNDO_ACTION'
export const ADD_REDO_ACTION = 'ADD_REDO_ACTION'
export const UPDATE_THUMBNAIL_URL = 'UPDATE_THUMBNAIL_URL'

interface AddDrawingActionAction {
  type: typeof ADD_DRAWING_ACTION
  action: DrawingActionTypes
}
interface ToggleCanvasLockAction {
  type: typeof TOGGLE_CANVAS_LOCK
  action: DrawingActionTypes
}
interface AddUndoActionAction {
  type: typeof ADD_UNDO_ACTION
}
interface AddRedoActionAction {
  type: typeof ADD_REDO_ACTION
}
interface UpdateThumbnailUrlAction {
  type: typeof UPDATE_THUMBNAIL_URL
  thumbnail: UploadResponse
}

export type ActionTypes =
  | AddDrawingActionAction
  | ToggleCanvasLockAction
  | AddUndoActionAction
  | AddRedoActionAction
  | UpdateThumbnailUrlAction
