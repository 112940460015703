/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Column } from 'common/ui'
import { useUserState } from 'app/UserState'
import { getFlashParams, FlashParams } from 'api'
import { useHistory } from 'react-router'
import { FlashInstructions } from './FlashInstructions'
import { useDisableOrientationLock } from 'app/OrientationPrompt'

function isFlashInstalled() {
  try {
    return Boolean(new ((window as unknown) as any).ActiveXObject('ShockwaveFlash.ShockwaveFlash'))
  } catch (e) {
    return navigator.mimeTypes.namedItem('application/x-shockwave-flash')?.enabledPlugin
  }
}

const FlashGame: React.FC = () => {
  useDisableOrientationLock()
  const { accessToken } = useUserState()
  const [flashParams, setFlashParams] = useState<FlashParams | null>(null)
  const history = useHistory()

  const flashInstalled = isFlashInstalled()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!flashParams) {
      getFlashParams(accessToken)
        .then(setFlashParams)
        .catch(() => {
          history.replace('/')
        })
    }
  }, [accessToken, flashParams])
  /* eslint-enable react-hooks/exhaustive-deps */

  if (!flashParams) {
    return null
  }

  return (
    <Column flex style={{ background: 'black' }}>
      <NavBar>
        <Container>
          <a href={`${process.env.REACT_APP_SST_BASE_URL}/`}>
            <img
              src={require('./assets/Computergame_Logo_WHITE-600.png')}
              style={{ height: 44, width: 'auto', margin: '8px 0' }}
              alt="Secret Agent Society Computer Game"
            />
          </a>
        </Container>
      </NavBar>

      {flashInstalled ? (
        <Container style={{ marginTop: 40 }}>
          <object
            type="application/x-shockwave-flash"
            id="SecretAgentSocietyOnline"
            data={`${process.env.REACT_APP_FLASH_BASE_URL}/bin_online/SecretAgentSocietyOnline.swf`}
            width="1024"
            height="576">
            <param name="menu" value="false" />
            <param name="scale" value="noScale" />
            <param name="allowFullscreen" value="true" />
            <param name="allowScriptAccess" value="always" />
            <param name="bgcolor" value="" />
            <param name="wmode" value="direct" />
            <param name="base" value={process.env.REACT_APP_FLASH_BASE_URL} />
            <param
              name="flashvars"
              value={`productKey=${flashParams.product_key}&firstRun=${
                flashParams.profile_name === '' ? 'true' : 'false'
              }&profileId=${flashParams.profile_id}&profileName=${flashParams.profile_name}&token=${
                flashParams.session_token
              }&apiUrl=${process.env.REACT_APP_FLASH_API_URL}`}
            />
          </object>
        </Container>
      ) : (
        <Container>
          <FlashInstructions />
        </Container>
      )}
      <Container>
        <P>
          <A
            href={`${process.env.REACT_APP_SST_BASE_URL}/sites/default/files/SAS_Computer_Game_Instruction_Manual_20Jan.pdf?playsas`}>
            Download Instruction Manual
          </A>{' '}
          | <A href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}>Request Technical Support</A>
        </P>
        <P>
          <small>
            Copyright © Social Science Translated Pty Ltd 2022. All rights reserved.{' '}
            <A href={`${process.env.REACT_APP_SST_BASE_URL}/terms`}>Terms of use</A> |{' '}
            <A href={`${process.env.REACT_APP_SST_BASE_URL}/privacy`}>Privacy Policy</A> |{' '}
            <A href={`${process.env.REACT_APP_SST_BASE_URL}/returns-policy`}>Returns & exchanges Policy</A> |{' '}
            <A href={`${process.env.REACT_APP_SST_BASE_URL}/contact-us`}>General Enquiries</A> |{' '}
            <A href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}>Technical Enquiries</A>
          </small>
        </P>
      </Container>
    </Column>
  )
}

export default FlashGame

const NavBar = styled.div`
  background: #222;
`

const Container = styled.div`
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 890px;
  }
  @media (min-width: 1200px) {
    width: 1030px;
  }
`

const P = styled.p`
  color: white;
  -webkit-font-smoothing: initial;
`

const A = styled.a`
  color: #428bca;
  text-decoration: none;

  :hover,
  :focus {
    color: #2a6496;
    text-decoration: underline;
  }
`
