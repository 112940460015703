import { Scene } from 'phaser'
import throttle from 'lodash/throttle'
import { Cancelable } from 'lodash'
import { getPixelRatio } from 'utils/screenUtils'
import { LoadAssetsObject } from 'utils/gameUtils'
import { Size } from 'types'
import { STAGE_WIDTH, STAGE_HEIGHT } from 'constants/phaserValues'
import { SCENE_FADED_IN } from 'constants/events'

export const STAGE_RESIZED = 'STAGE_RESIZED'
const throttleMs = 1000 / 30

export type StageResizeCallback = (size: Size) => void

export default class SceneScaler extends Scene {
  private appContainerElem?: HTMLElement
  private lastWidth: number = STAGE_WIDTH
  private lastHeight: number = STAGE_HEIGHT
  private _physicalStageWidth: number = STAGE_WIDTH
  private _physicalStageHeight: number = STAGE_HEIGHT
  private _virtualStageWidth: number = STAGE_WIDTH
  private _virtualStageHeight: number = STAGE_HEIGHT
  private throttledHandleResize?: ((e?: UIEvent) => void) & Cancelable
  private _assets: LoadAssetsObject = {}

  private STAGE_WIDTH: number = STAGE_WIDTH
  private STAGE_HEIGHT: number = STAGE_HEIGHT
  private STAGE_RATIO: number = STAGE_HEIGHT / STAGE_WIDTH

  public overridePixelRatio?: number
  public screenScaler?: number

  get assets() { return this._assets } // prettier-ignore
  set assets(assets: LoadAssetsObject) { this._assets = assets } // prettier-ignore

  private get pixelRatio() {
    return this.overridePixelRatio ? this.overridePixelRatio : getPixelRatio()
  }

  init() {
    const appContainerElem = document.getElementById('APP_CONTAINER')
    if (appContainerElem) this.appContainerElem = appContainerElem
    else console.warn('No APP_CONTAINER element found')

    this.STAGE_WIDTH = (this.game.config.width as number) / this.pixelRatio
    this.STAGE_HEIGHT = (this.game.config.height as number) / this.pixelRatio
    this.STAGE_RATIO = this.STAGE_HEIGHT / this.STAGE_WIDTH

    this.throttledHandleResize = throttle(this.handleResize.bind(this), throttleMs, { trailing: true })
    window.addEventListener('resize', this.throttledHandleResize)
    this.handleResize()
    this.cameras.main.fadeIn(300, 0, 0, 0, (context: any, percent: number) => {
      if (percent === 1) this.events.emit(SCENE_FADED_IN)
    })

    this.events.once('destroy', () => this.destroy())
  }

  destroy() {
    if (this.throttledHandleResize) window.removeEventListener('resize', this.throttledHandleResize)
  }

  handleResize(e?: UIEvent, forceResize?: boolean) {
    if (!this || !this.scene || !this.scene.isActive) return
    if (!this.appContainerElem) return

    const appRect = this.appContainerElem.getBoundingClientRect()
    const newReverseScaledWidth = appRect.width * (this.screenScaler || 1)
    const newReverseScaledHeight = appRect.height * (this.screenScaler || 1)
    const newWidth = Math.round(appRect.width)
    const newHeight = Math.round(appRect.height)

    if (forceResize === true || newWidth !== this.lastWidth || newHeight !== this.lastHeight) {
      const pixelRatioScale = this.pixelRatio
      const newRatio = newHeight / newWidth
      const cameraViewport = {
        x: 0,
        y: 0,
        w: newReverseScaledWidth * pixelRatioScale,
        h: newReverseScaledHeight * pixelRatioScale,
      }
      let stageScale = 1

      if (newRatio > this.STAGE_RATIO) {
        // taller
        stageScale = this.STAGE_WIDTH / newWidth
        this._physicalStageWidth = newWidth
        this._physicalStageHeight = this.STAGE_HEIGHT / stageScale
        this._virtualStageWidth = this.STAGE_WIDTH
        this._virtualStageHeight = (newHeight / this._physicalStageHeight) * this.STAGE_HEIGHT
      } else if (newRatio < this.STAGE_RATIO) {
        // wider
        stageScale = this.STAGE_HEIGHT / newHeight
        this._physicalStageWidth = this.STAGE_WIDTH / stageScale
        this._physicalStageHeight = newHeight
        this._virtualStageHeight = this.STAGE_HEIGHT
        this._virtualStageWidth = (newWidth / this._physicalStageWidth) * this.STAGE_WIDTH
      }

      this.game.scale.resize(newReverseScaledWidth * pixelRatioScale, newReverseScaledHeight * pixelRatioScale)
      if (this.cameras.main) {
        this.cameras.main.setViewport(cameraViewport.x, cameraViewport.y, cameraViewport.w, cameraViewport.h)
        this.cameras.main.setOrigin(0, 0)
        this.cameras.main.setZoom(pixelRatioScale / stageScale)
      }

      this.events.emit(STAGE_RESIZED, this.stageSize)

      this.lastWidth = newWidth
      this.lastHeight = newHeight
    }
  }

  get physicalStageWidth(): number {
    return this._physicalStageWidth
  }

  get physicalStageHeight(): number {
    return this._physicalStageHeight
  }

  get physicalSize(): Size {
    return { width: this._physicalStageWidth, height: this._physicalStageHeight }
  }

  get physicalStageRatio(): number {
    return this.physicalStageWidth / this.physicalStageHeight
  }

  get stageWidth(): number {
    return this._virtualStageWidth
  }

  get stageHeight(): number {
    return this._virtualStageHeight
  }

  get stageScale(): number {
    if (this.physicalStageRatio > this.STAGE_RATIO) {
      return this.STAGE_WIDTH / this._physicalStageWidth
    } else {
      return this.STAGE_HEIGHT / this._physicalStageHeight
    }
  }

  get stageSize(): Size {
    return { width: this._virtualStageWidth, height: this._virtualStageHeight }
  }

  get stageRatio(): number {
    return this._virtualStageWidth / this._virtualStageHeight
  }
}
