import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { Row, Button, Spacer, Column, Padding } from 'common/ui'
import { Reward } from 'skill-tracker/types'
import { RewardInput } from 'skill-tracker/common/RewardInput'
import { ValidationErrorList } from 'common/ValidationErrorList'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'

export const validate = (data: Partial<Reward>, errors: string[] = []) => {
  if (!data.text) errors.push(`No skill description entered`)
  if (!data.symbol) errors.push(`No symbol selected`)
  if (!data.frequency) errors.push(`Need to specify reward type`)
  if (data.frequency === 'bonus') {
    if (!data.bonusTarget) errors.push(`Need to specify a bonus target`)
    else if (data.bonusTarget <= 0) errors.push(`Bonus target needs to be at least 1`)
  }
  return errors
}

export const isEmpty = (reward: Reward): boolean =>
  !reward.text && !reward.symbol && (reward.frequency === 'bonus' ? !reward.bonusTarget : true)

interface Props {
  initialValue: Reward
  minTarget?: number
  onClose: () => void
  onSave: (reward: Reward) => void
}

export const RewardEditModal: React.FC<Props> = ({ initialValue, minTarget, onClose, onSave }) => {
  const [reward, setReward] = useState<Reward>(() => {
    if (initialValue.frequency === 'bonus' && !initialValue.bonusTarget && minTarget)
      return { ...initialValue, bonusTarget: minTarget }
    return initialValue
  })
  const [saveAttempted, setSaveAttempted] = useState<boolean>(false)
  const [edited, setEdited] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  const validationErrors = validate(reward)
  if (reward.frequency === 'bonus' && reward.bonusTarget && minTarget && reward.bonusTarget < minTarget)
    validationErrors.push(
      `Bonus target needs to be at least ${minTarget} because your cadet has already earned ${minTarget -
        1} bonus credits.`
    )
  const allowSave = validationErrors.length === 0
  const empty = isEmpty(reward)

  const handleUpdate = (data: Partial<Reward>) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setReward({ ...reward, ...data })
      setEdited(true)
    })
  }

  const handleSave = () => {
    console.log({ reward, allowSave })
    if (allowSave) {
      delayedOnClose()
      setEdited(false)
      onSave(reward)
    } else if (!allowSave && !saveAttempted) {
      setSaveAttempted(true)
    }
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSave()
    return false
  }

  return (
    <MobileModalPanel isOpen={isOpen} onRequestClose={delayedOnClose} panelName="RewardEditModal">
      <Padding size="m" style={{ paddingTop: 15, paddingBottom: 15 }}>
        <form onSubmit={handleFormSubmit}>
          <RewardInput reward={reward} onChange={(id, data) => handleUpdate(data)} minTarget={minTarget} />
          <Spacer size="s" />
          <Row>
            <Column flex="1 1 auto">
              {saveAttempted && (
                <ValidationErrorList
                  errors={validationErrors}
                  style={{
                    // marginTop: 15,
                    // textAlign: 'right',
                    // float: 'right',
                    color: 'inherit',
                    border: '1px solid orange',
                    padding: 10,
                  }}
                />
              )}
            </Column>
            <Spacer size="m" />
            <Column>
              <Row justifyContent="flex-end">
                <Button type="button" size="xs" theme="white" onClick={delayedOnClose}>
                  Cancel
                </Button>
                <Spacer size="xs" />
                <Button
                  type="submit"
                  size="xs"
                  disabled={!edited || (saveAttempted && !allowSave)}
                  onClick={() => handleSave()}>
                  {edited || empty ? 'Save' : 'Saved'}
                </Button>
              </Row>
            </Column>
          </Row>
        </form>
      </Padding>
    </MobileModalPanel>
  )
}
