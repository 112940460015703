import React from 'react'
import styled from 'styled-components'
import uniq from 'lodash/uniq'

import { Column, Row, Spacer, Panel, P } from 'common/ui'
import { JournalMission } from './common/JournalMission'
import { useMedia } from 'utils/useMedia'
import { subscriptToSmall } from 'utils/subscriptToSmall'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { fontBold } from 'fonts'
import { SpinnerWithLabel } from 'common/Spinner'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { SessionEntity } from 'shared/dashboard/types'
import { LinkButton } from 'common/LinkButton'
import { getGroupPublicName } from 'utils/titleUtils'

interface Props {
  onComplete: () => void
}

const description = `Each of your SAS Club Meetings will be recorded here in your Club Journal. You can review them at any time here to remind yourself how to stay calm and get along with others.\n\nWhen completing your missions and Mission Journal, you may like to look here to see the mission planning you did with your SAS Facilitator.`

export const ClubMeetingMenu: React.FC<Props> = ({ onComplete }) => {
  const compact = useMedia('(max-width: 680px)')
  useDisableOrientationLock()
  const [pastSessions, { loaded, loading, errorLoading, errorMessage }] = useEndpoint<SessionEntity[]>(
    `/api/v1/sessions/past`
  )
  const groupsCount = loaded && pastSessions ? uniq(pastSessions.map(s => s.group?.id).filter(g => g)).length : 0
  return (
    <JournalMission title="Club Journal" description={description} compact={compact} onExit={onComplete} showNav>
      <Spacer />
      {loading ? (
        <SpinnerWithLabel label="Loading club journals..." labelStyle={{ color: 'white' }} />
      ) : errorLoading ? (
        <>
          <P color="white">{errorMessage ? `Error: ${errorMessage}` : 'Error fetching club journals.'}</P>
        </>
      ) : !pastSessions || !pastSessions.length ? (
        <>
          <P color="white">You don't have any club journals available yet.</P>
        </>
      ) : (
        <Row flexWrap marginTop={-15} marginLeft={-15} userSelectNone>
          {pastSessions.map((session, i) => (
            <MenuItemBase key={i} style={!session.concluded ? { pointerEvents: 'none', opacity: 0.5 } : undefined}>
              <JournalInner flex>
                {groupsCount > 1 && session.group && <GroupName>{getGroupPublicName(session.group)}</GroupName>}
                <Title>{subscriptToSmall(`Module ${session.module_code}`)}</Title>
                <Spacer flex />
                <LinkButton to={`/club-journals/${session.uid}`} children="View" />
              </JournalInner>
            </MenuItemBase>
          ))}
          <Column marginTop marginLeft flex="1 1 230px" />
          <Column marginTop marginLeft flex="1 1 230px" />
          <Column marginTop marginLeft flex="1 1 230px" />
          <Column marginTop marginLeft flex="1 1 230px" />
          <Column marginTop marginLeft flex="1 1 230px" />
          <Column marginTop marginLeft flex="1 1 230px" />
          <Column marginTop marginLeft flex="1 1 230px" />
          <Column marginTop marginLeft flex="1 1 230px" />
          <Column marginTop marginLeft flex="1 1 230px" />
        </Row>
      )}
    </JournalMission>
  )
}

const MenuItemBase = styled(Panel)`
  flex: 1 1 230px;
  margin: 15px 0 0 15px;
  box-shadow: 0px -4px 10px rgba(255, 255, 255, 0.44), 0px 4px 0px #bdc3e0, 0px 10px 10px rgba(0, 0, 0, 0.2),
    inset 0px -2px 2px rgba(189, 195, 224, 0.5), inset 0px 2px 2px #ffffff;
`

const JournalInner = styled(Column)`
  padding: 15px;
  padding-top: 20px;
  min-height: 150px;
`

const GroupName = styled(P)`
  margin: 0;
  font-size: 13px;
`

const Title = styled(P)`
  ${fontBold};
  text-transform: uppercase;
  margin: 0;
  padding-right: 5px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #011a46;
`
