import React from 'react'

import { ProfileProgress } from 'api'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useParams } from 'react-router-dom'
import { GroupEntity, MentorEntity, SessionEntity } from 'shared/dashboard/types'
import { CadetProgressTable } from 'dashboards/common/CadetProgressTable'
import { P } from 'common/ui'

export const ProgressDashboard: React.FC = () => {
  const { groupUid, primaryMentorUid } = useParams<{ groupUid: string; primaryMentorUid: string }>()
  const [group] = useEndpoint<GroupEntity>(`/api/v1/groups/${groupUid}`)
  const [profiles] = useEndpoint<ProfileProgress[]>(`/api/v1/groups/${groupUid}/progress`)
  const [sessions] = useEndpoint<SessionEntity[]>(`/api/v1/groups/${groupUid}/sessions?includeExtraGroups=1`)
  const [cadetExternalSessions] = useEndpoint<SessionEntity[]>(
    primaryMentorUid ? `/api/v1/sessions/external/${primaryMentorUid}` : null
  )

  if (!profiles?.length || !sessions || !group) {
    return null
  }

  const primaryMentor = group.primary_mentors.find(mentor => mentor.uid === primaryMentorUid)
  const allMentors = [
    primaryMentor,
    ...group.mentors.filter(mentor => mentor.primary_mentor_id === primaryMentor?.id),
  ].filter(Boolean) as MentorEntity[]

  const profileProgress = primaryMentor?.cadet_profile_id
    ? profiles.find(profile => String(profile.id) === String(primaryMentor.cadet_profile_id))
    : undefined

  if (!profileProgress) {
    return (
      <P>
        Once this Cadet has entered SAS Digital HQ for the first time to set up their new agent profile, the progress
        screen will display
      </P>
    )
  }

  return (
    <CadetProgressTable
      group={group}
      profileProgress={profileProgress}
      sessions={sessions}
      mentors={allMentors}
      externalSessions={cadetExternalSessions || undefined}
    />
  )
}
