import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'

import { Activity, GameLevel } from 'types'
import { AppBackground, Column, Row, CUT_TR_S, Spacer, Button, Fixed, Absolute, CUT_BL_S, H1 } from 'common/ui'
import PDA from 'common/PDA'
import { usePda } from 'utils/usePda'
import { ShortcutMenu } from 'common/ShortcutMenu'
import { useUserState } from 'app/UserState'
import { fontRegular, fontBlack } from 'fonts'
import { useMeasure } from 'utils/useMeasure'
import { intersperse } from 'utils/intersperse'
import { arrayPad } from 'utils/arrayPad'
import { GameModeIndicator } from 'app/GameModeIndicator'
import { Elevator } from 'menu/Elevator'
import { subscriptToSmall } from 'utils/subscriptToSmall'
import { useSessionStorage } from 'utils/useStorage'
import { useShortcutButtons } from 'app/GlobalState'
import { ScreenScaler } from 'common/ScreenScaler'
import { ArdonNarrate } from 'utils/useArdon'
import { cleanRouteState } from 'utils/routeUtils'
import { isJournalActivity } from 'utils/activityUtils'

interface Props {
  level: Activity
  activities: Activity[]
  onComplete: () => void
}

function roundEven(value: number) {
  return 2 * Math.round(value / 2)
}

function getIntroVo(level: GameLevel): string | undefined {
  switch (level) {
    case 1:
      return require('./assets/audio/0.3.1.1.mp3')
    case 2:
      return require('./assets/audio/0.3.2.1.mp3')
    case 3:
      return require('./assets/audio/0.3.3.1.mp3')
    case 4:
      return require('./assets/audio/0.3.4.1.mp3')
  }
}

type ElevatorPosition = 'up' | 'down'

export const LevelMenu: React.FC<Props> = ({ level, activities, onComplete }) => {
  const [elevatorPosition, setElevatorPosition] = useSessionStorage<ElevatorPosition>('elevatorPosition', 'down', true)
  const [elevatorAnimate, setElevatorAnimate] = useState(elevatorPosition === 'down')
  const [elevatorVisible, setElevatorVisible] = useState(elevatorPosition === 'down')
  const elevatorDirection = elevatorPosition === 'up' ? 'down' : 'up'

  const history = useHistory()
  const location = useLocation()
  const { hasCompleted, hasPermissionFor, addActivityAttempt } = useUserState()
  const [measureRef, { width }] = useMeasure()
  usePda()

  useShortcutButtons([
    {
      label: `Complete Level ${level.level}`,
      onClick: () => activities.filter(a => !hasCompleted(a)).forEach(a => addActivityAttempt(a, null, 'success')),
    },
  ])

  const numColumns = width > 900 ? 3 : 2
  const chunkedActivities = chunk(activities, numColumns)
  const totalGapPercent = 0.15
  const columnGap = roundEven((width * totalGapPercent) / (numColumns - 1))
  const columnWidth = roundEven((width * (1 - totalGapPercent)) / numColumns)
  const rowHeight = 200
  const rowGap = roundEven(columnGap * 1)
  const totalWidth = numColumns * columnWidth + (numColumns - 1) * columnGap
  const totalHeight = roundEven(chunkedActivities.length * rowHeight + (chunkedActivities.length - 1) * rowGap)

  function returnToControlRoom() {
    setElevatorVisible(true)
    setElevatorAnimate(true)
  }

  const shouldReturnToControlRoom = !!(location.state && location.state.returnToControlRoom)

  useEffect(() => {
    if (shouldReturnToControlRoom) returnToControlRoom()
  }, [shouldReturnToControlRoom])

  const introVo = !elevatorVisible && !hasCompleted(activities[0]) && getIntroVo(level.level as GameLevel)

  return (
    <ScreenScaler>
      <AppBackground style={{ WebkitTouchCallout: 'none', WebkitUserSelect: 'none' }}>
        <Row flex>
          <PillarLeft />
          <Column flex />
          <Column flex="0 0 230px" style={{ zIndex: 10, pointerEvents: 'none' }}>
            <Spacer />
            <Button onClick={returnToControlRoom} shadowColor="theme" style={{ pointerEvents: 'all' }}>
              Lift to
              <br />
              Control Room
            </Button>
            <Spacer flex />
            <Row style={{ pointerEvents: 'all' }}>
              <PDA />
            </Row>
            <Spacer />
          </Column>
          <Spacer />
          <PillarRight />
        </Row>
        <Fixed cover style={{ overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
          <MenuPadding style={{ flexDirection: 'column' }}>
            {width > 0 && (
              <MenuItemBase style={{ width: columnWidth, flex: 'none' }}>
                <LevelInner flex padding>
                  <H1 align="center">{level.title}</H1>
                </LevelInner>
              </MenuItemBase>
            )}
            <Spacer flex={`0 0 ${rowGap}px`} />
            <Column flex ref={measureRef}>
              {width > 0 && (
                <>
                  <Absolute zIndex={10} style={{ pointerEvents: 'none' }}>
                    <Lines
                      connections={createConnections(chunkedActivities, activity => !hasPermissionFor(activity))}
                      width={totalWidth}
                      height={totalHeight}
                      columnGap={columnGap}
                      columnWidth={columnWidth}
                      rowHeight={rowHeight}
                      rowGap={rowGap}
                      numColumns={numColumns}
                    />
                  </Absolute>
                  <Absolute style={{ width: totalWidth, height: totalHeight }}>
                    {chunkedActivities
                      .map((chunk, i) => {
                        return (
                          <Row key={i} style={{ height: rowHeight }}>
                            {chunk
                              .map((activity, j) => {
                                const locked = !hasPermissionFor(activity)
                                const completed = hasCompleted(activity)
                                const highlight = !locked && !completed
                                const onSelect = () =>
                                  activity.path && history.push(activity.path, cleanRouteState(location.state))
                                if (isJournalActivity(activity)) {
                                  return (
                                    <Journal
                                      key={j}
                                      title={activity.title}
                                      locked={locked}
                                      onSelect={onSelect}
                                      highlight={highlight}
                                      completed={completed}
                                    />
                                  )
                                }
                                const buttonLabel = activity.id.match(/quiz/i) ? 'Start' : 'Play'
                                return (
                                  <Game
                                    key={j}
                                    title={activity.title}
                                    locked={locked}
                                    onSelect={onSelect}
                                    highlight={highlight}
                                    completed={completed}
                                    buttonLabel={buttonLabel}
                                  />
                                )
                              })
                              .reduce(
                                arrayPad(numColumns, i => <Spacer key={i} flex="1 1 0px" />),
                                []
                              )
                              .reduce(
                                intersperse(i => <Spacer key={`s${i}`} style={{ width: columnGap }} />),
                                []
                              )}
                          </Row>
                        )
                      })
                      .reduce(
                        intersperse(i => <Spacer key={`s${i}`} style={{ height: rowGap }} />),
                        []
                      )}
                    <Spacer size="l" />
                  </Absolute>
                </>
              )}
            </Column>
          </MenuPadding>
        </Fixed>
        <ShortcutMenu />
        <GameModeIndicator style={{ zIndex: 20, color: '#001947', bottom: 6, left: 8, lineHeight: '0px' }} />
        {introVo && <ArdonNarrate src={introVo} />}
      </AppBackground>

      {elevatorVisible && (
        <Absolute cover style={{ zIndex: 10, bottom: -2, overflow: 'hidden' }}>
          <Elevator
            direction={elevatorDirection}
            animate={elevatorAnimate}
            onComplete={
              elevatorDirection === 'down'
                ? () => {
                    setElevatorPosition('down')
                    onComplete()
                  }
                : () => {
                    setElevatorVisible(false)
                    setElevatorPosition('up')
                    setElevatorAnimate(false)
                  }
            }
          />
        </Absolute>
      )}
    </ScreenScaler>
  )
}

function chunk(activities: Activity[], columns: number): Activity[][] {
  const chunks: Activity[][] = []
  let chunk: Activity[] = []
  for (let i = 0; i < activities.length; i++) {
    const activity = activities[i]
    const nextNextActivity = activities[i + 2]
    const forceNewRow =
      nextNextActivity && nextNextActivity.prerequisite && nextNextActivity.prerequisite.id === activity.id
    chunk.push(activity)
    if (chunk.length === columns || forceNewRow) {
      chunks.push(chunk)
      chunk = []
    }
  }
  if (chunk.length) {
    chunks.push(chunk)
  }
  return chunks
}

function createConnections(
  chunkedActivities: Activity[][],
  isLocked: (activity: Activity) => boolean
): MenuConnection[] {
  const connections: MenuConnection[] = []
  for (let row = 0; row < chunkedActivities.length; row++) {
    for (let column = 0; column < chunkedActivities[row].length; column++) {
      const activity = chunkedActivities[row][column]
      if (!activity.prerequisite) continue
      loop: for (let row2 = row; row2 >= 0; row2--) {
        for (let column2 = chunkedActivities[row2].length; column2 >= 0; column2--) {
          if (row === row2 && column2 >= column) continue
          const activity2 = chunkedActivities[row2][column2]
          if (!activity2) continue
          if (activity.prerequisite.id === activity2.id) {
            connections.push({
              from: { row: row2, column: column2 },
              to: { row, column },
              locked: isLocked(activity),
            })
            break loop
          }
        }
      }
    }
  }
  return connections
}

interface MenuItemPosition {
  row: number
  column: number
}

interface MenuConnection {
  from: MenuItemPosition
  to: MenuItemPosition
  locked?: boolean
}

const Lines: React.FC<{
  connections: MenuConnection[]
  width: number
  height: number
  columnGap: number
  columnWidth: number
  rowHeight: number
  rowGap: number
  numColumns: number
}> = ({ width, height, columnGap, columnWidth, rowHeight, rowGap, numColumns, connections }) => {
  const boxSize = 6
  const boxGap = 8
  const cornerRadius = 18

  const arcDownLeft = `a${cornerRadius},${cornerRadius} 0 0 1 -${cornerRadius},${cornerRadius}`
  const arcDownRight = `a${cornerRadius},${cornerRadius} 0 0 0 ${cornerRadius},${cornerRadius}`
  const arcRightDown = `a${cornerRadius},${cornerRadius} 0 0 1 ${cornerRadius},${cornerRadius}`
  const arcLeftDown = `a${cornerRadius},${cornerRadius} 0 0 0 -${cornerRadius},${cornerRadius}`

  const lineDownHalfGap = `l 0 ${rowGap / 2 - boxSize - boxGap - cornerRadius}`

  function getX(position: MenuItemPosition, edge: 'top' | 'bottom' | 'left' | 'right'): number {
    const offset = (columnWidth + columnGap) * position.column
    switch (edge) {
      case 'top':
      case 'bottom':
        return offset + columnWidth / 2
      case 'left':
        return offset - boxGap
      case 'right':
        return offset + columnWidth + boxGap
    }
  }

  function getY(position: MenuItemPosition, edge: 'top' | 'bottom' | 'left' | 'right'): number {
    const offset = (rowHeight + rowGap) * position.row
    switch (edge) {
      case 'top':
        return offset - boxGap
      case 'bottom':
        return offset + rowHeight + boxGap + 1
      case 'left':
      case 'right':
        return offset + rowHeight / 2
    }
  }

  function createBoxes({ from, to }: MenuConnection, i: number) {
    const fromEdge = from.row === to.row ? 'right' : 'bottom'
    const toEdge = from.row === to.row ? 'left' : 'top'
    return (
      <g key={i}>
        <use xlinkHref="#box" x={getX(from, fromEdge)} y={getY(from, fromEdge)} />
        <use xlinkHref="#box" x={getX(to, toEdge)} y={getY(to, toEdge)} />
      </g>
    )
  }

  function createLine({ from, to }: MenuConnection, i: number) {
    // horizontal line
    if (from.row === to.row) {
      return (
        <line
          key={i}
          x1={getX(from, 'right') + boxSize}
          y1={getY(from, 'right')}
          x2={getX(to, 'left') - boxSize}
          y2={getY(to, 'left')}
        />
      )
    }
    // vertical line
    if (from.row === to.row - 1 && from.column === to.column) {
      return (
        <line
          key={i}
          x1={getX(from, 'bottom')}
          y1={getY(from, 'bottom') + boxSize}
          x2={getX(to, 'top')}
          y2={getY(to, 'top') - boxSize}
        />
      )
    }
    // down and to the left
    if (from.row === to.row - 1 && from.column > to.column) {
      const fromX = getX(from, 'bottom')
      const fromY = getY(from, 'bottom')
      const toX = getX(to, 'top')
      return (
        <path
          key={i}
          d={`
              M ${fromX} ${fromY + boxSize}
              ${lineDownHalfGap}
              ${arcDownLeft}
              l ${-(fromX - toX - cornerRadius * 2)} 0
              ${arcLeftDown}
              ${lineDownHalfGap}
            `}
        />
      )
    }
    // down and to the right
    if (from.row === to.row - 1 && from.column < to.column) {
      const fromX = getX(from, 'bottom')
      const fromY = getY(from, 'bottom')
      const toX = getX(to, 'top')
      return (
        <path
          key={i}
          d={`
              M ${fromX} ${fromY + boxSize}
              ${lineDownHalfGap}
              ${arcDownRight}
              l ${toX - fromX - cornerRadius * 2} 0
              ${arcRightDown}
              ${lineDownHalfGap}
            `}
        />
      )
    }
    // down over multiple lines, below
    if (to.row - from.row > 1 && from.column === to.column && from.column < numColumns - 1) {
      const fromX = getX(from, 'bottom')
      const fromY = getY(from, 'bottom')
      return (
        <path
          key={i}
          d={`
              M ${fromX} ${fromY + boxSize}
              ${lineDownHalfGap}
              ${arcDownRight}
              
              l ${columnWidth / 2 + columnGap / 2 - cornerRadius * 2} 0
              
              ${arcRightDown}

              l 0 ${rowHeight + rowGap - cornerRadius * 2} 

              ${arcDownLeft}
              l ${-(columnWidth / 2 + columnGap / 2 - cornerRadius * 2)} 0
              ${arcLeftDown}
              ${lineDownHalfGap}
            `}
        />
      )
    }
    // down over multiple lines, to the right
    if (to.row - from.row > 1 && from.column < to.column) {
      const fromX = getX(from, 'bottom')
      const fromY = getY(from, 'bottom')
      return (
        <path
          key={i}
          d={`
              M ${fromX} ${fromY + boxSize}
              ${lineDownHalfGap}
              ${arcDownRight}
              
              l ${columnWidth / 2 + columnGap / 2 - cornerRadius * 2} 0
              
              ${arcRightDown}
              l 0 ${rowGap + rowHeight - cornerRadius * 2}

              ${arcDownRight}
              
              l ${(to.column - from.column) * (columnWidth + columnGap) -
                cornerRadius * 2 -
                columnWidth / 2 -
                columnGap / 2} 0

              ${arcRightDown}
              ${lineDownHalfGap}
            `}
        />
      )
    }
    // down over multiple lines, to the left
    if (to.row - from.row > 1 && from.column > to.column) {
      const fromX = getX(from, 'bottom')
      const fromY = getY(from, 'bottom')
      return (
        <path
          key={i}
          d={`
              M ${fromX} ${fromY + boxSize}
              ${lineDownHalfGap}
              ${arcDownLeft}
      
              l ${-(
                columnWidth / 2 +
                columnGap / 2 -
                cornerRadius * 2 +
                (from.column - to.column - 1) * (columnWidth + columnGap)
              )} 0
              
              ${arcLeftDown}
              
              l 0 ${(to.row - from.row - 2) * (rowHeight + rowGap) + rowGap + rowHeight - cornerRadius * 2}

              ${arcDownLeft}
              
              l ${-(columnWidth / 2 + columnGap / 2 - cornerRadius * 2)} 0

              ${arcLeftDown}
              ${lineDownHalfGap}
            `}
        />
      )
    }
    // down over multiple lines, below, but wrapped to the left
    if (to.row - from.row > 1 && from.column === to.column && from.column === numColumns - 1) {
      const fromX = getX(from, 'bottom')
      const fromY = getY(from, 'bottom')
      return (
        <path
          key={i}
          d={`
              M ${fromX} ${fromY + boxSize}
              ${lineDownHalfGap}
              ${arcDownLeft}
      
              l ${-(
                columnWidth / 2 +
                columnGap / 2 -
                cornerRadius * 2 +
                (from.column - to.column) * (columnWidth + columnGap)
              )} 0
              
              ${arcLeftDown}

              l 0 ${rowGap + rowHeight - cornerRadius * 2}

              ${arcDownRight}
              
              l ${columnWidth / 2 +
                columnGap / 2 -
                cornerRadius * 2 +
                (from.column - to.column) * (columnWidth + columnGap)} 0

              ${arcRightDown}
              ${lineDownHalfGap}
            `}
        />
      )
    }
    return <g key={i} />
  }

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      strokeLinecap="square"
      strokeWidth={3}
      stroke="#fff"
      fill="none"
      width={width}
      height={height}>
      <defs>
        <path
          id="box"
          d={`
            M ${-(boxSize / 2)} ${-(boxSize / 2)}
            l ${boxSize} 0
            l 0 ${boxSize}
            l ${-boxSize} 0
            z
          `}
        />
      </defs>
      <g transform="translate(-0.5,-0.5)">
        <g opacity="0.5">{connections.filter(c => c.locked).map(createBoxes)}</g>
        <g>{connections.filter(c => !c.locked).map(createBoxes)}</g>
        <g opacity="0.5">{connections.filter(c => c.locked).map(createLine)}</g>
        <g>{connections.filter(c => !c.locked).map(createLine)}</g>
      </g>
    </svg>
  )
}

const Game: React.FC<{
  title: string
  onSelect: () => void
  locked: boolean
  highlight: boolean
  completed: boolean
  buttonLabel: string
}> = ({ title, onSelect, locked, highlight, completed, buttonLabel }) => {
  return (
    <MenuItemBase locked={locked} highlight={highlight}>
      <GameInner flex="1 1 0px" completed={completed} userSelectNone>
        <GameTitle>{title}</GameTitle>
        <Spacer flex />
        {locked ? <LockedButton /> : <Button onClick={onSelect}>{buttonLabel}</Button>}
      </GameInner>
    </MenuItemBase>
  )
}

const Journal: React.FC<{
  title: string
  onSelect: () => void
  locked: boolean
  highlight: boolean
  completed: boolean
}> = ({ title, onSelect, locked, highlight, completed }) => {
  return (
    <MenuItemBase locked={locked} highlight={highlight}>
      <JournalInner
        flex="1 1 0px"
        alignItems="center"
        justifyContent="space-between"
        completed={completed}
        userSelectNone>
        {locked ? (
          <>
            <LockedJournalHeader>
              Mission
              <br />
              Journal
            </LockedJournalHeader>
          </>
        ) : (
          <>
            <JournalHeader>
              Mission
              <br />
              Journal
            </JournalHeader>
            <JournalTitle>{subscriptToSmall(title)}</JournalTitle>
            <Button size="s" onClick={onSelect}>
              Enter
            </Button>
          </>
        )}
      </JournalInner>
    </MenuItemBase>
  )
}

const LockedButton: React.FC = () => {
  return (
    <LockedButtonRoot disabled>
      <LockedButtonInner>
        <Spacer flex="2 1 0px" />
        <LockedButtonIcon />
        <Spacer flex="1 1 0px" />
        Locked
        <Spacer flex="2.5 1 0px" />
      </LockedButtonInner>
    </LockedButtonRoot>
  )
}

const LockedButtonRoot = styled(Button)`
  opacity: 1 !important;
  padding-left: 0;
  padding-right: 0;
`

const LockedButtonInner = styled.span`
  display: flex;
  align-items: center;
`

const LockedButtonIcon = styled.span`
  position: relative;
  height: 0;
  width: 23px;

  ::after {
    content: '';
    position: absolute;
    top: -17px;
    left: 0;
    width: 23px;
    height: 34px;
    background: url(${require('common/assets/image/lock-blue-shadow@2x.png')}) no-repeat center center / cover;
  }
`

const MenuItemBase = styled.div<{ locked?: boolean; highlight?: boolean }>`
  background: ${p => p.theme.panelBg};
  display: flex;
  position: relative;
  flex: 1 1 0px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4)
    ${p => (p.highlight ? `, 0 0 30px 15px ${p.theme.appBackgroundTopColor}` : '')};
  border-radius: 10px;
  opacity: ${p => (p.locked ? 0.5 : 1)};
`

const LevelInner = styled(Column)`
  background: ${CUT_TR_S} no-repeat, ${CUT_BL_S} no-repeat;
`

const GameInner = styled(Column)<{ completed: boolean }>`
  padding: 24px 12px 12px 12px;
  background: ${p =>
      p.completed
        ? `url(${require('common/assets/image/tick@2x.png')}) no-repeat right 18px top 20px / 24px 24px,`
        : ''}
    ${CUT_TR_S} no-repeat;
`

const GameTitle = styled.div`
  ${fontBlack}
  padding-left: 4px;
  padding-right: 40px;
  font-size: 14px;
  color: #001947;
  line-height: 1.1;
  white-space: pre-wrap;
`

const JournalInner = styled(Column)<{ completed: boolean }>`
  margin: 4px 16px;
  padding: 12px 20px;
  background: ${p =>
      p.completed
        ? `url(${require('common/assets/image/tick-transparent@2x.png')}) no-repeat right 8px top 7px / 24px 24px,`
        : ''}
    ${p => p.theme.darkBg};
`

const JournalHeader = styled.div`
  ${fontBlack}
  font-size: 10px;
  color: #f2f2f2;
  line-height: 1;
  text-align: center;
`

const LockedJournalHeader = styled.div`
  ${fontBlack}
  font-size: 18px;
  color: #575f7c;
  line-height: 1;
  text-align: center;
  margin: auto;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    bottom: -5px;
    background: url(${require('common/assets/image/lock-no-shadow@2x.png')}) no-repeat center center / contain;
  }
`

const JournalTitle = styled.div`
  ${fontRegular}
  font-size: 16px;
  color: #f2f2f2;
  line-height: 1.1;
  margin-top: -6px; /* offset ascender height */
  text-align: center;
  width: 100%;
  white-space: pre-wrap;
`

const MenuPadding = styled.div`
  display: flex;
  flex: 1 1 0px;
  padding: 15px 370px 15px 125px;
`

const PillarLeft = styled.div`
  pointer-events: none;
  z-index: 10;
  flex: 0 0 91px;
  position: relative;

  background: url(${require('./assets/image/sidebar_tl.png')}) no-repeat left top / 100px 155px,
    url(${require('./assets/image/sidebar_bl.png')}) no-repeat left bottom / 100px 155px;

  ::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 155px;
    bottom: 155px;
    background: url(${require('./assets/image/sidebar_l.png')}) no-repeat left center / 12px 155px,
      url(${require('./assets/image/sidebar_l_repeat.png')}) repeat-y left center / 100px 15px;
  }
`

const PillarRight = styled.div`
  pointer-events: none;
  flex: 0 0 91px;
  position: relative;

  background: url(${require('./assets/image/sidebar_tr.png')}) no-repeat right top / 100px 155px,
    url(${require('./assets/image/sidebar_br.png')}) no-repeat right bottom / 100px 155px;

  ::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 155px;
    bottom: 155px;
    background: url(${require('./assets/image/sidebar_r.png')}) no-repeat right center / 12px 155px,
      url(${require('./assets/image/sidebar_r_repeat.png')}) repeat-y right center / 100px 15px;
  }
`
