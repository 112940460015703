import React, { useEffect, useState } from 'react'

import { Row, Button, P, CollapseMargin, Spacer, Text, Center } from 'common/ui'
import { blue, THEMES } from 'themes'
import { IntroTemplate } from './IntroTemplate'
import { useUserState } from 'app/UserState'
import {
  getDeviceAuthToken,
  getHashedGameCode,
  setDeviceAuthInProgress,
  setDeviceAuthToken,
  setDeviceToken,
} from 'auth/deviceAuth'
import { registerDevice } from 'api'
import { CircleButton } from 'session/common/CircleButton'
import { Well } from 'reporting/common/ui'
import { useGlobalState } from 'app/GlobalState'

export const DeviceAuthorisationPage: React.FC<{ onContinue: () => void }> = ({ onContinue }) => {
  const { themeIndex } = useGlobalState()
  const theme = THEMES[themeIndex]

  return (
    <IntroTemplate title="Security Mission">
      <CollapseMargin>
        <P align="center">One more step.</P>
        <P align="center">Before you can play, you will need an adult to complete a security mission.</P>
        <P align="center">They will let you know when their mission is complete!</P>
      </CollapseMargin>
      <Spacer size="l" />
      <Center>
        <img
          src={require('./assets/image/device-authorization.png')}
          alt="Can you help me please?"
          style={{ width: 160, borderRadius: 15, border: `1px solid #bdc5e4` }}
        />
      </Center>
      <Spacer size="l" />
      <Row justifyContent="space-around">
        <Button onClick={onContinue} theme={theme}>
          Complete Mission
        </Button>
      </Row>
      <Spacer size="l" />
      <CollapsibleWell label="Adult Mission Instructions">
        <CollapseMargin>
          <P>
            The adult SECURITY MISSION is to provide additional protection for the child’s use of this device and
            browser. Adults will be redirected to login into their adult accounts to authorise this device/browser for
            the child. This adult authorisation will be requested every 30 days, or on new device/browsers.
          </P>
          <P>
            To complete this mission, you must be an SAS Mentor of the SAS Small Group Program for this child, their SAS
            Facilitator, or the consenting adult who activated the SAS Product Key from the SAS Computer Game Pack.{' '}
          </P>
          <P>Please click the Complete Mission button to proceed.</P>
        </CollapseMargin>
      </CollapsibleWell>
    </IntroTemplate>
  )
}

export const DeviceAuthorisationReturnPage: React.FC<{ onContinue: () => void; onError: () => void }> = ({
  onContinue,
  onError,
}) => {
  const { accessToken } = useUserState()
  useEffect(() => {
    const drupalAccessToken = getDeviceAuthToken()
    const hashedGameplayCode = getHashedGameCode()
    if (drupalAccessToken && hashedGameplayCode) {
      registerDevice(accessToken, drupalAccessToken)
        .then(data => {
          setDeviceAuthInProgress(false)
          setDeviceAuthToken('')
          setDeviceToken(hashedGameplayCode, data.token_id)
          onContinue()
        })
        .catch(onError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])
  return null
}

export const DeviceAuthorisationSuccessPage: React.FC<{ onContinue: () => void }> = ({ onContinue }) => {
  return (
    <IntroTemplate title="Security Mission">
      <Text font="heavy" size="l" align="center">
        Mission Success!
      </Text>
      <Spacer size="l" />
      <CollapseMargin>
        <P align="center">Please thank your SAS Mentor for completing the SECURITY MISSION.</P>
        <P align="center">This device is ready to go.</P>
        <P align="center">Cadet, please go to SAS Digital HQ</P>
      </CollapseMargin>
      <Spacer size="l" />
      <Row justifyContent="space-around">
        <Button onClick={onContinue} theme={blue}>
          Go
        </Button>
      </Row>
      <Spacer size="l" />
      <CollapsibleWell label="Adult Next Steps">
        <CollapseMargin>
          <P>
            To ensure secure data protection for the cadet account, you may be required to complete this process every
            30 days or on new devices.
          </P>
        </CollapseMargin>
      </CollapsibleWell>
    </IntroTemplate>
  )
}

export const DeviceAuthorisationFailedPage: React.FC<{ onContinue: () => void }> = ({ onContinue }) => {
  const [busy, setBusy] = useState(false)
  const { themeIndex } = useGlobalState()
  const theme = THEMES[themeIndex]

  const handleContinue = () => {
    setBusy(true)
    onContinue()
  }
  return (
    <IntroTemplate title="Security Mission">
      <CollapseMargin>
        <Text font="heavy" size="l" align="center">
          Uh oh!
        </Text>
        <P align="center">Something went wrong.</P>
        <P align="center">
          Before you can play, you will <strong>need an adult</strong> to complete a security mission.
        </P>
        <Text font="heavy" size="l" align="center">
          What do I do?
        </Text>
        <Text>
          <ol>
            <li>Ask an adult to help you.</li>
            <li>Wait patiently while they complete their Security Mission.</li>
            <li>
              If something doesn’t go to plan, you may need to wait longer while the adult also asks for help from
              secret agent headquarters.
            </li>
            <li>When the Security Mission is complete, please thank them for their efforts.</li>
          </ol>
        </Text>
      </CollapseMargin>
      <Spacer size="l" />
      <Row justifyContent="space-around">
        <Button onClick={handleContinue} theme={theme} disabled={busy}>
          {busy ? 'Please wait...' : 'Complete Mission'}
        </Button>
      </Row>
      <Spacer size="l" />
      <CollapsibleWell label="Adult Mission Instructions">
        <CollapseMargin>
          <P>
            The adult SECURITY MISSION is to provide additional protection for the child’s use of this device and
            browser.
          </P>
          <P>
            To complete this mission, you must be an SAS Mentor of the SAS Small Group Program for this child, their SAS
            Facilitator, or the consenting adult who activated the SAS Product Key from an SAS Computer Game Pack.
          </P>
          <P>
            Adults will be redirected to login into their adult accounts to authorise this device/browser for the child.
            This adult authorisation will be requested every 30 days, or on new device/browsers.
          </P>
          <P>
            Please click the Complete Mission button to proceed or contact the{' '}
            <a
              href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}
              target="_blank"
              rel="noopener noreferrer">
              Social Science Translated
            </a>{' '}
            if this page is repeatedly presented (if you have repeated difficulty authorising the cadet’s access).
          </P>
        </CollapseMargin>
      </CollapsibleWell>
    </IntroTemplate>
  )
}

const CollapsibleWell: React.FC<{ label: React.ReactNode }> = ({ label, children }) => {
  const [open, setOpen] = useState(false)
  return (
    <Well padding>
      <Row
        alignItems="center"
        justifyContent="space-between"
        style={{ cursor: 'pointer' }}
        onClick={() => setOpen(open => !open)}>
        <Text font="bold">{label}</Text>
        <CircleButton size="xs" children={open ? '▲' : '▼'} style={{ lineHeight: 'initial', cursor: 'pointer' }} />
      </Row>
      {open && <Spacer />}
      {open && children}
    </Well>
  )
}
