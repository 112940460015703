import React from 'react'

import { UserStateContext, useUserState } from 'app/UserState'
import { useFacilitatorState } from 'session/hooks/useProfileState'
import { QuestionnaireStateProvider } from 'questionnaires/QuestionnaireState'

import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { GetProfile } from 'api'
import { useParams } from 'react-router-dom'

export const PreviewQuestionnaireStateProvider: React.FC = ({ children }) => {
  const facilitatorState = useFacilitatorState()
  const userState = useUserState()
  const profileId =
    facilitatorState && facilitatorState.focusedParticipantUid
      ? parseProfileId(facilitatorState.focusedParticipantUid)
      : userState.profileId

  let userContext = { ...userState, profileId }

  const { mentorUid } = useParams<{ mentorUid?: string }>()
  const [cadetProfile] = useEndpoint<GetProfile>(mentorUid ? `/api/v1/mentors/${mentorUid}/cadet` : null)
  if (mentorUid && cadetProfile) {
    userContext = {
      ...userState,
      profileId: cadetProfile.id,
      profiles: [cadetProfile],
      profileName: cadetProfile.profile_name || '',
    }
  }

  return (
    <UserStateContext.Provider value={{ ...userContext, authProvider: 'sas' }}>
      <QuestionnaireStateProvider>{children}</QuestionnaireStateProvider>
    </UserStateContext.Provider>
  )
}

// I don't really like having to do this
function parseProfileId(uid: string): number {
  const matches = uid.match(/^sas-(\d+)$/)
  if (!matches) return 0
  return parseInt(matches[1])
}
