/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { JournalBackground, Panel, Button, Column, Row, Spacer, H1, P } from 'common/ui'
import { MeetingJoinButton } from './MeetingJoinButton'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useHistory } from 'react-router-dom'
import { useUserState } from 'app/UserState'
import { useOnline } from 'utils/useOnline'

interface Props {}

const controlRoomLogoWhite = require('./assets/image/control-room-logo-white.png')

export const ControlRoomPhone: React.FC<Props> = () => {
  const history = useHistory()
  const { enableSkillTracker, enableETelligence, enableGadgetPack, enableSessions, openAccess } = useUserState()
  const online = useOnline()
  useDisableOrientationLock()
  return (
    <JournalBackground>
      <Column flex>
        <Row padding="l" paddingBottom="s">
          <img
            src={controlRoomLogoWhite}
            style={{ width: '100%', maxWidth: 400, margin: 'auto' }}
            alt="Secret Agent Society Computer Game"
          />
        </Row>
        <Panel flex margin padding>
          <Spacer size="m" />
          <H1 align="center">
            Mobile <br />
            Control Room
          </H1>
          <Spacer size="l" />
          {!online && (
            <>
              <P>Note: you're currently offline so some resources are unavailable</P>
              <Spacer />
            </>
          )}
          <Column flex>
            <Button onClick={() => history.push('/journal')} disabled={!online}>
              Mission Journal
            </Button>
            {enableETelligence && (
              <>
                <Spacer />
                <Button onClick={() => history.push('/e-telligence')}>E-Telligence</Button>
              </>
            )}
            {enableGadgetPack && (
              <>
                <Spacer />
                <Button onClick={() => history.push('/gadget-pack')}>Gadget Pack</Button>
              </>
            )}
            {enableSkillTracker && (
              <>
                <Spacer />
                <Button onClick={() => history.push('/skill-tracker')} disabled={openAccess}>
                  Skill Tracker
                </Button>
              </>
            )}
            {/*enableSessions && (
              <>
                <Spacer />
                <MeetingJoinButton style={{ fontSize: 14 }} />
              </>
            )*/}
            <Spacer />
            <Button onClick={() => history.push('/scene-generator')} disabled={!online}>
              Scene Generator
            </Button>
            <Spacer />
            <Spacer flex />
            <Button size="s" onClick={() => history.push('/')}>
              Quit
            </Button>
          </Column>
        </Panel>
      </Column>
    </JournalBackground>
  )
}
