import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Reward, RewardFrequency } from 'skill-tracker/types'

import { Padding, Column, Spacer, Button, P, Row, Hr, IconOutlineButton } from 'common/ui'
import { Navigation, baseUrl } from './common/Navigation'
import { RewardSlab } from './common/RewardSlab'
import { SkillTrackerPanel } from './common/SkillTrackerPanel'
import { RewardEditModal } from './modals/RewardEditModal'

import {
  AddUpdateRewardAction,
  ADD_UPDATE_REWARD,
  UpdateRoleStateValuesAction,
  UPDATE_ROLE_STATE_VALUES,
  RemoveRewardAction,
  REMOVE_REWARD,
} from './actionTypes'
import { validateAddUpdateReward, validateRemoveReward } from './actionValidators'

import { useUserState } from 'app/UserState'
import { useSkillTrackerState } from './SkillTrackerState'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { sortByKey } from 'utils/sortUtils'
import { rewardFrequencies } from './constants/rewardFrequencies'
import { rewardsMenuHint } from './constants/hints'

export const skipRewardsKey = 'ST_skipRewards'

export const RewardsScreen: React.FC = () => {
  const history = useHistory()
  const { getUserScopes, getBaseAction } = useUserState()
  const { state, dispatch } = useSkillTrackerState()

  const [view, setView] = useState<RewardFrequency>('daily')
  const [editReward, setEditReward] = useState<Reward | null>(null)

  const isAdult = getUserScopes().indexOf('mentor') >= 0
  const rewards = state.rewards.filter(({ frequency }) => frequency === view)

  const activeModule = state.activeModuleId ? state.modules.find(({ id }) => id === state.activeModuleId) : undefined
  const bonusSkillUses = state.skillUses.filter(({ bonus }) => bonus)

  const setSkipRewards = (skipRewardsScreen: boolean) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_ROLE_STATE_VALUES,
      role: 'mentor',
      data: { skipRewardsScreen },
    } as UpdateRoleStateValuesAction)
  }

  const handleNewReward = (data: Partial<Reward> = {}) => {
    const newReward: Reward = { id: `reward${state.rewards.length}`, text: '', symbol: '', frequency: 'daily', ...data }
    setEditReward(newReward)
  }

  const handleRewardSave = (reward: Reward) => {
    const action: AddUpdateRewardAction = { ...getBaseAction(), type: ADD_UPDATE_REWARD, reward }
    if (validateAddUpdateReward(action, state)) {
      dispatch(action)
      setSkipRewards(true)
    } else {
      console.error(`Unable to validate update reward action`, action, state)
    }
  }

  const handleRemoveReward = (reward: Reward) => {
    if (
      !window.confirm(
        `Are you sure you want to remove this ${reward.frequency} reward?\n${reward.symbol} ${reward.text}`
      )
    )
      return
    const action: RemoveRewardAction = {
      ...getBaseAction(),
      type: REMOVE_REWARD,
      reward,
    }
    if (validateRemoveReward(action, state)) dispatch(action)
    else console.error(`Unable to validate remove reward action`, action, state)
  }

  const handleProceed = () => {
    setSkipRewards(true)
    setTimeout(() => history.push(baseUrl + '/module'))
  }

  return (
    <>
      <SkillTrackerPanel>
        <Navigation
          title={isAdult ? 'Set Up Rewards' : 'Your Rewards'}
          titleHint={isAdult ? rewardsMenuHint : undefined}
          hideMenu={isAdult && !state.activeModuleId && !state.rewards.length}
        />
        <Column flex="none">
          <ViewToggleButtonGroup>
            <Button
              type="button"
              size="s"
              flex="1 1 auto"
              theme={view === 'daily' ? 'blue' : 'gray'}
              onClick={() => setView('daily')}>
              Daily Rewards
            </Button>
            <Button
              type="button"
              size="s"
              flex="1 1 auto"
              theme={view === 'bonus' ? 'blue' : 'gray'}
              onClick={() => setView('bonus')}>
              Bonus Rewards
            </Button>
          </ViewToggleButtonGroup>
          <Hr />
        </Column>
        <Column flex="1 1 auto" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <Padding size="s">
            {!state.rewards.length && !isAdult && (
              <P>
                {isAdult
                  ? `There are currently no rewards set up, do this with your student / child.`
                  : `Talk with your SAS Mentor if you would like a digital Rewards Menu for your Skill Tracker`}
              </P>
            )}
            {isAdult && (
              <>
                <Row>
                  <Button type="button" size="s" onClick={() => handleNewReward({ frequency: view })}>
                    Add {rewardFrequencies[view]} Reward +
                  </Button>
                </Row>
                <Spacer size="m" />
              </>
            )}
            {intersperseSpacers(
              [...rewards].sort(sortByKey('frequency', 'descending')).map((reward, i) => (
                <Row key={i} alignItems="center" style={{ width: '100%' }}>
                  <RewardSlab key={i} reward={reward} onEdit={isAdult ? () => setEditReward(reward) : undefined} />
                  {isAdult && (
                    <>
                      <Spacer size="s" />
                      <IconOutlineButton
                        size="s"
                        theme="gray"
                        style={{ fontSize: 20, width: 32, height: 32, lineHeight: '1em' }}
                        onClick={() => handleRemoveReward(reward)}
                        children="×"
                      />
                    </>
                  )}
                </Row>
              )),
              's'
            )}
          </Padding>
        </Column>
        {isAdult && (
          <Column flex="none">
            <Hr />
            <Padding size="s" style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {!activeModule && (
                <Button type="button" size="s" onClick={() => handleProceed()}>
                  Skip / Proceed to Module Setup
                </Button>
              )}
              {activeModule && !activeModule.started && (
                <Button type="button" size="s" onClick={() => handleProceed()}>
                  Proceed to Module Setup
                </Button>
              )}
              {activeModule && activeModule.started && (
                <Button type="button" size="s" onClick={() => history.push(baseUrl + '/practise')}>
                  Proceed to Practise Log
                </Button>
              )}
            </Padding>
          </Column>
        )}
      </SkillTrackerPanel>
      {editReward && (
        <RewardEditModal
          initialValue={editReward}
          onClose={() => setEditReward(null)}
          onSave={handleRewardSave}
          minTarget={editReward?.frequency === 'bonus' ? bonusSkillUses.length + 1 : undefined}
        />
      )}
    </>
  )
}

export const ViewToggleButtonGroup = styled(Row)`
  & button {
    border-radius: 0;
    border: none;
    &::after {
      display: none;
    }
  }
`
