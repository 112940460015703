import React, { useState } from 'react'
import styled from 'styled-components'
import { Column, Padding, CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L } from 'common/ui'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { FriendshipFormulaScreen } from 'gadget-pack/FriendshipFormula'

export const FriendshipFormulaModal: React.FC<{ onClose: () => void; mentorMode?: boolean }> = ({
  onClose,
  mentorMode,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}
      panelStyle={mentorMode ? { minWidth: '100vw', minHeight: '100vh' } : {}}>
      <FriendshipFormulaContainer>
        <FriendshipFormulaScreen overrideExit={delayedOnClose} readonly />
      </FriendshipFormulaContainer>
    </MobileModalPanel>
  )
}

const FriendshipFormulaContainer = styled.div`
  position: relative;
  padding: 30px;

  & > ${Padding} > ${Column} {
    max-width: 100%;
  }

  & .activity-panel {
    box-shadow: none;
    border-bottom: none;
  }
`
