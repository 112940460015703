import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { THEMES } from 'themes'
import { useGlobalState } from 'app/GlobalState'
import { Row } from 'common/ui'

type Size = 'm' | 's'

interface Props {
  size?: Size
}

export const ThemeRadioGroup: React.FC<Props> = ({ size = 'm' }) => {
  const { themeIndex, setThemeIndex } = useGlobalState()
  return (
    <Row flexWrap>
      {THEMES.map((theme, i) => (
        <ThemeProvider key={i} theme={theme}>
          <ThemeInput size={size} selected={themeIndex === i} onSelect={() => setThemeIndex(i)} />
        </ThemeProvider>
      ))}
    </Row>
  )
}

const ThemeInput: React.FC<{ size: Size; selected: boolean; onSelect: () => void }> = ({
  size,
  selected,
  onSelect,
}) => (
  <Container size={size}>
    <DecoyInput type="radio" name="theme" checked={selected} onChange={onSelect} />
    <Indicator size={size} />
  </Container>
)

const Container = styled.label<{ size: Size }>`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 10px 0;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`

const DecoyInput = styled.input`
  opacity: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
`

const indicatorSize = { m: '60px', s: '50px' }
const indicatorMargin = { m: '0 20px 0 5px', s: '0 10px 0 5px' }

const Indicator = styled.span<{ size: Size }>`
  width: ${p => indicatorSize[p.size]};
  height: ${p => indicatorSize[p.size]};
  background: ${p =>
    `linear-gradient(180deg, ${p.theme.appBackgroundTopColor} 0%, ${p.theme.appBackgroundBottomColor} 100%)`};
  border: 3px solid #abb5d8;
  border-radius: 50%;
  margin: ${p => indicatorMargin[p.size]};

  ${Container}:last-child & {
    margin-right: 0;
  }

  ${DecoyInput}:active:enabled + & {
    transform: scale(0.9);
  }

  ${DecoyInput}:checked + & {
    border-color: white;
    transform: scale(1.2);
    box-shadow: 0px 2px 8px 1px #adb2da;
  }

  ${DecoyInput}:checked:focus + & {
    box-shadow: 0px 3px 8px 2px #adb2da;
  }
`
