import _padStart from 'lodash/padStart'

export const dayMs = 24 * 60 * 60 * 1000

export const getTime = (options: { date?: Date; amPm?: boolean; leadingZeros?: boolean } = {}): string => {
  const date = options.date ? options.date : new Date()
  if (options.amPm === undefined) options.amPm = true
  if (options.leadingZeros === undefined) options.leadingZeros = true
  let hours: string = (options.amPm ? date.getHours() % 12 || 12 : date.getHours()).toString()
  if (options.leadingZeros) hours = _padStart(hours, 2, '0')
  const minutes = _padStart(date.getMinutes().toString(), 2, '0')
  const suffix = options.amPm ? (date.getHours() > 12 ? 'pm' : 'am') : ''
  return `${hours}:${minutes}${suffix}`
}

export const getDate = (options: { date?: Date; delimiter?: string; leadingZeros?: boolean } = {}): string => {
  const date = options.date ? options.date : new Date()
  if (options.delimiter === undefined) options.delimiter = '-'
  let month = (date.getMonth() + 1).toString()
  let monthDay = date.getDate().toString()
  if (options.leadingZeros) {
    month = _padStart(month, 2, '0')
    monthDay = _padStart(monthDay, 2, '0')
  }
  return `${date.getFullYear()}${options.delimiter}${month}${options.delimiter}${monthDay}`
}

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone || ''
