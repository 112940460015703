import React from 'react'
import styled from 'styled-components'

import Modal from './Modal'
import { useGlobalState } from './GlobalState'
import {
  H1,
  H2,
  Panel,
  Hr,
  Button,
  Row,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  GRILL_TL,
  GRILL_TR,
  Center,
  Spacer,
} from 'common/ui'
import { Switch } from 'common/Switch'
import { ThemeRadioGroup } from 'app/ThemeRadioGroup'
import { fontRegular } from 'fonts'
import { useLabelId } from 'utils/useLabelId'
import { useMedia } from 'utils/useMedia'

export const SettingsModal: React.FC = () => {
  const { settingsVisible, setSettingsVisible, muted, setMuted, sfxMuted, setSfxMuted } = useGlobalState()
  const compact = useMedia('(max-width: 600px)')

  if (!settingsVisible) {
    return null
  }

  return (
    <Modal isOpen onRequestClose={() => setSettingsVisible(false)} width={compact ? '90vw' : 590}>
      <Panel
        flex="1 1 90vw"
        padding={compact ? true : '25px 30px 35px 30px'}
        flair={compact ? [] : [CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR]}
        shadow={false}>
        <H1 align="center">Settings</H1>
        <Hr margin />
        <H2 marginBottom>Theme Colour:</H2>
        <ThemeRadioGroup size={compact ? 's' : 'm'} />
        <Hr margin />
        <H2>Sound Options:</H2>
        <Spacer />
        <SwitchRow label="Agent Ardon" value={!muted} onChange={value => setMuted(!value)} />
        <Spacer />
        <SwitchRow label="Sound Effects" value={!sfxMuted} onChange={value => setSfxMuted(!value)} />
        <Hr margin />
        <Center>
          <Button onClick={() => setSettingsVisible(false)}>Close</Button>
        </Center>
      </Panel>
    </Modal>
  )
}

const SwitchRow: React.FC<{ label: string; value: boolean; onChange: (value: boolean) => void }> = ({
  label,
  value,
  onChange,
}) => {
  const labelId = useLabelId()
  return (
    <Row style={{ alignItems: 'center' }}>
      <Label id={labelId}>{label}</Label>
      <Spacer flex />
      <Label>{value ? 'On' : 'Off'}</Label>
      <Spacer />
      <Switch labelId={labelId} value={value} onChange={onChange} />
    </Row>
  )
}

const Label = styled.label`
  ${fontRegular}
  color: #001947;
  font-size: 16px;
  min-width: 1.5em;
`
