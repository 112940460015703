import { Vector } from 'types'

export function getPointerPosition(
  e: MouseEvent | TouchEvent,
  pointerId?: number,
  zoomMultiplier: number = 1,
  offset: Vector = { x: 0, y: 0 }
): Vector {
  if (pointerId && e instanceof TouchEvent && e.touches && e.touches[pointerId - 1]) {
    return {
      x: e.touches[pointerId - 1].clientX * zoomMultiplier + offset.x,
      y: e.touches[pointerId - 1].clientY * zoomMultiplier + offset.y,
    }
  } else if (e instanceof MouseEvent) {
    return {
      x: e.clientX * zoomMultiplier + offset.x,
      y: e.clientY * zoomMultiplier + offset.y,
    }
  } else {
    return { x: 0, y: 0 }
  }
}
