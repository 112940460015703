import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { fontRegular } from 'fonts'

import { SectionPropsBase as QuestionnaireSectionPropsBase } from 'shared/questionnaires/types'
import { UPDATE_TEXT_VALUE } from 'shared/questionnaires/actionTypes'
import { useQuestionnaireState } from 'questionnaires/QuestionnaireState'
import { useSessionState } from 'session/SessionState'

import { Spacer, TextareaInput, TextInput } from 'common/ui'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { RichTextContainer } from 'session/common/RichText'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'
import { InputLockComponent } from 'session/common/InputLockComponent'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'

interface Props extends QuestionnaireSectionPropsBase {
  property: 'input_sections'
}

export const InputSection: React.FC<Props> = ({ section }) => {
  const { label, placeholder, lines, type, maxLength } = section
  const { state: userData, previewing, userDataStatus, dispatch } = useQuestionnaireState()
  const sessionState = useSessionState()
  const participantUid = useParticipantProfileUid()
  const key = `panel_${section._owner_id}_input_${section.id}`
  const currentResponse = (userData.responses || []).find(res => res.unique_key === key)
  const readOnly = userDataStatus !== 'ready'

  const handleChange = (value: string) => {
    if (!previewing)
      dispatch({
        type: UPDATE_TEXT_VALUE,
        unique_key: key,
        value,
      })
  }

  return (
    <InputQuestionWrapper>
      <RichTextContainer children={label} />
      <Spacer size="s" />
      {type !== 'textarea' ? (
        <DebouncedInputComponent<string>
          debounce={500}
          value={(currentResponse?.value as string) || ''}
          onChange={handleChange}
          children={({ value, onChange }) => (
            <InputLockComponent
              disregard={!sessionState || !participantUid}
              fieldUid={`${participantUid}_questionnaire_input_${key}`}
              verticalPlacementCadet={5}
              verticalPlacementFacilitator={5}>
              {(lockProps, lockState) => (
                <TextInput
                  {...lockProps}
                  {...lockState}
                  value={value}
                  maxLength={maxLength}
                  onChange={e => {
                    onChange(e.target.value || '')
                    lockProps.onChange(e.target.value || '')
                  }}
                  placeholder={placeholder || 'Enter here'}
                  disabled={lockState.disabled || readOnly}
                />
              )}
            </InputLockComponent>
          )}
        />
      ) : (
        <DebouncedInputComponent<string>
          debounce={500}
          value={(currentResponse?.value as string) || ''}
          onChange={handleChange}
          children={({ value, onChange }) => (
            <InputLockComponent
              // key={key}
              disregard={!sessionState}
              fieldUid={`questionnaire_${key}`}
              verticalPlacementCadet={5}
              verticalPlacementFacilitator={5}>
              {(lockProps, lockState) => (
                <AutosizeTextareaInput
                  {...lockProps}
                  {...lockState}
                  value={value}
                  maxLength={maxLength}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    let value = e.target.value
                    if (value && value.length > 20000) value = value.substr(0, 20000)
                    onChange(value)
                    lockProps.onChange(value)
                  }}
                  placeholder={placeholder || 'Enter here'}
                  rows={lines || 1}
                  disabled={lockState.disabled || readOnly}
                />
              )}
            </InputLockComponent>
          )}
        />
      )}
    </InputQuestionWrapper>
  )
}

export const InputQuestionWrapper = styled.div`
  position: relative;
  & .facilitator-only-padding {
    padding-top: 0 !important;
  }
  ${TextareaInput}, ${TextInput} {
    ${fontRegular};
    border: 1px solid #cdd2e4;
    box-shadow: none;
    width: 100%;
    font-size: 18px;
  }
`
