import React, { useContext, useEffect, useState, useRef, PropsWithChildren, useCallback } from 'react'
import ReactDOM from 'react-dom'
import _isEqual from 'lodash/isEqual'

import { PROGRESSION_EVENTS_UPDATED } from 'shared/session/actionTypes'
import { ProgressionEvent, SavedProgressionEvent } from 'shared/session/types'
import { progressionEventExists, getProgressionEventPrerequisites } from 'shared/progressionEvents'

import { getProgressionEvents, createProgressionEvent, batchCreateProgressionEvents } from 'api'
import { useUserState } from 'app/UserState'
import { useGenericUser } from 'app/useGenericUser'
import { useSessionState } from './SessionState'
import { useFocusedParticipantState } from './hooks/useProfileState'

function useProviderProgressionEventsState() {
  const participantState = useFocusedParticipantState()
  const { uid } = useGenericUser()
  const { accessToken } = useUserState()
  const { socket, dispatch: sessionDispatch, getBaseAction, previewing, pastMode, isFacilitator } = useSessionState()
  const participant_uid = (isFacilitator && participantState ? participantState.profile.uid : false) || uid
  const [progressionEvents, updateProgressionEvents] = useState<SavedProgressionEvent<any>[]>([])
  const [loadingProgressionEvents, setLoadingProgressionEvents] = useState<boolean>(false)

  // TODO: Ensure that referencing this outside of a session context doesn't have negative side effects
  // THIS ISN'T USED GOD DAMN IT
  const dispatchToSocket = (progressionEvents: SavedProgressionEvent<any>[]) => {
    if (socket && socket.connected) {
      sessionDispatch({
        ...getBaseAction(),
        type: PROGRESSION_EVENTS_UPDATED,
        progressionEvents,
      })
    }
  }

  const fetchLatestProgressionEvents = () => {
    setLoadingProgressionEvents(true)
    updateProgressionEvents([])
    getProgressionEvents(accessToken, participant_uid)
      .then(progressionEvents => {
        dispatchToSocket(progressionEvents)
        ReactDOM.unstable_batchedUpdates(() => {
          updateProgressionEvents(progressionEvents)
          setLoadingProgressionEvents(false)
        })
      })
      .catch(e => {
        console.warn('Unable to fetch progression events for user UID', participant_uid, e)
        setLoadingProgressionEvents(false)
      })
  }

  useEffect(() => {
    fetchLatestProgressionEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant_uid])

  useEffect(() => {
    if (!socket) return
    // socket.on(PROGRESSION_EVENTS_UPDATED), (action: ProgressionEventsUpdatedAction) => {
    //  do something
    // })
  }, [socket])

  // TODO: Add queueing functionality for when progression events are still being loaded
  // (This will prevent duplicate progression events from being made)
  const addProgressionEvent = <ValueType extends any>(
    progressionEvent: ProgressionEvent<ValueType>,
    includePrerequisiteEvents: boolean = true
  ) => {
    // Prevent events being saved if just previewing from CMS or reviewing content
    if (previewing || pastMode) return null

    if (includePrerequisiteEvents) {
      let prerequisiteEvents = getProgressionEventPrerequisites(progressionEvent).map(prerequisiteEvent => ({
        ...prerequisiteEvent,
        participant_uid,
      }))
      // prerequisiteEvents = prerequisiteEvents.map(prerequisiteEvent => ({ ...prerequisiteEvents, participant_uid }))
      if (prerequisiteEvents.length) console.log('🍒 Checking preqrequisite events:', prerequisiteEvents)
      prerequisiteEvents = prerequisiteEvents.filter(
        prerequisiteEvent => !progressionEventExists(prerequisiteEvent, progressionEvents)
      )
      if (prerequisiteEvents.length > 0) {
        console.log('🍒 Need to save these preqrequisite events:', prerequisiteEvents)
        batchCreateProgressionEvents(
          prerequisiteEvents.map(prerequisiteEvent => ({ ...prerequisiteEvent, participant_uid })),
          accessToken
        )
          .then(savedProgressionEvents => {
            updateProgressionEvents(progressionEvents => {
              dispatchToSocket([...progressionEvents, ...savedProgressionEvents])
              return [...progressionEvents, ...savedProgressionEvents]
            })
          })
          .catch(e => {
            console.warn('Unable to save progression events', e)
          })
      } else {
        console.log('🍒 all prerequest events already exist')
      }
    }

    if (!progressionEventExists(progressionEvent, progressionEvents)) {
      console.log('Creating progression event...', progressionEvent)
      createProgressionEvent(progressionEvent, accessToken)
        .then(savedProgressionEvent => {
          updateProgressionEvents(progressionEvents => {
            dispatchToSocket(progressionEvents)
            return [...progressionEvents, savedProgressionEvent]
          })
        })
        .catch(e => {
          console.warn('Unable to save progression event', e)
        })
    }
  }

  return {
    loadingProgressionEvents,
    progressionEvents,
    addProgressionEvent,
  }
}

type ProgressionEventsState = ReturnType<typeof useProviderProgressionEventsState>

function noop(): any {}

export const ProgressionEventsStateContext = React.createContext<ProgressionEventsState>({
  loadingProgressionEvents: false,
  progressionEvents: [],
  addProgressionEvent: noop,
})

export const ProgressionEventsStateProvider: React.FC = ({ children }) => {
  const state = useProviderProgressionEventsState()
  return <ProgressionEventsStateContext.Provider value={state}>{children}</ProgressionEventsStateContext.Provider>
}

export function useProgressionEventsState() {
  return useContext(ProgressionEventsStateContext)
}

export interface ProgressionEventContext<ValueType extends any>
  extends Omit<ProgressionEvent<ValueType>, 'participant_uid'> {}

export function useAutoAddProgressionEvent<ValueType extends any>(
  progressionEvent: ProgressionEventContext<ValueType>
) {
  const lastProgressionEvent = useRef<typeof progressionEvent>()
  const { uid: participant_uid } = useGenericUser()
  const { addProgressionEvent } = useProgressionEventsState()

  const triggerProgressionEvent = useCallback(
    (progressionEvent: ProgressionEventContext<ValueType>) => {
      addProgressionEvent({ participant_uid, ...progressionEvent })
    },
    [addProgressionEvent, participant_uid]
  )

  // need to trigger on initial mount
  useEffect(() => {
    if (!lastProgressionEvent.current) {
      console.log('🎤 User progression events context initialized')
      lastProgressionEvent.current = progressionEvent
      triggerProgressionEvent(progressionEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // also need to trigger if progression event changes
  useEffect(() => {
    if (!_isEqual(lastProgressionEvent.current, progressionEvent)) {
      console.log('[CHANGE]')
      triggerProgressionEvent(progressionEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressionEvent])
}

export function useProgressionEventExists<ValueType extends any>(
  progressionEvent: ProgressionEventContext<ValueType>
): boolean {
  const participantState = useFocusedParticipantState()
  const { uid } = useGenericUser()
  const { isFacilitator } = useSessionState()
  const participant_uid = (isFacilitator && participantState ? participantState.profile.uid : false) || uid
  const { progressionEvents } = useProgressionEventsState()
  return progressionEventExists({ ...progressionEvent, participant_uid }, progressionEvents)
}

export const AutoAddProgressionEvent = <ValueType extends any>({
  progressionEvent,
}: PropsWithChildren<{ progressionEvent: ProgressionEventContext<ValueType> }>) => {
  useAutoAddProgressionEvent(progressionEvent)
  return <></>
}
