import React, { ChangeEvent, useRef, useState } from 'react'
import { Button, Column, Input, Label, P, Row, Spacer } from 'common/ui'
import { dataURItoBlob } from 'utils/uriUtils'
import { saveFile } from 'api'
import { useUserState } from 'app/UserState'
import { getQuestionState, useTrainingState } from 'training/TrainingState'
import { AnchorButton } from 'common/LinkButton'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { SpinnerWithLabel } from 'common/Spinner'

export const UploadQuestion: React.FC<{
  uid: string
  input_label?: string
  placeholder?: string
  reviewMode: boolean
  onSubmit: (
    correct: boolean,
    value: { fileName: string; url: string } | null,
    response?: string,
    skipValidation?: boolean
  ) => void
}> = ({ uid, input_label, placeholder, reviewMode, onSubmit }) => {
  const { accessToken } = useUserState()
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)
  const uploadRef = useRef<HTMLInputElement | null>(null)
  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [submitted, setSubmitted] = useState<boolean>(questionState ? questionState.answer : false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const hasFile = uploadFile || questionState?.answer

  const handleCancel = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setUploadFile(null)
      setSubmitted(false)
      setShowSuccess(false)
      onSubmit(false, null, '', true)
    })
  }

  const handleSubmit = () => {
    if (uploadFile) {
      ReactDOM.unstable_batchedUpdates(() => {
        setError(false)
        setLoading(true)
      })
      fileToDataUri(uploadFile)
        .then((dataUri: string) => {
          return dataURItoBlob(dataUri)
        })
        .then(blob => {
          return saveFile(blob, accessToken)
        })
        .then(res => {
          ReactDOM.unstable_batchedUpdates(() => {
            onSubmit(true, { fileName: uploadFile.name, url: res.url }, '', true)
            setSubmitted(true)
            setShowSuccess(true)
            setLoading(false)
          })
        })
        .catch(e => {
          ReactDOM.unstable_batchedUpdates(() => {
            setError(true)
            setLoading(false)
          })
        })
    }
  }

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    setUploadFile(file)
  }

  const fileToDataUri = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => {
        if (event.target && event.target.result && typeof event.target.result === 'string') {
          resolve(event.target.result)
        }
      }
      reader.readAsDataURL(file)
    })

  return (
    <Wrapper>
      {input_label && (
        <>
          <Label children={input_label} />
          <Spacer size="s" />
        </>
      )}
      <Row justifyContent="space-between">
        <Column flex="1 1 auto">
          <Input
            type="text"
            placeholder={placeholder}
            value={
              questionState && questionState.answer ? questionState.answer.fileName : uploadFile ? uploadFile.name : ''
            }
            readOnly
          />
        </Column>
        <Spacer size="s" />
        <input
          ref={uploadRef}
          onChange={handleUpload}
          type="file"
          id="fileElem"
          accept="application/pdf,video/mp4,application/mp4,audio/mp4"
          multiple
          style={{ display: 'none' }}
        />
        {!reviewMode && (
          <>
            {hasFile && (
              <>
                <Button size="s" children={'Cancel'} onClick={handleCancel} disabled={loading} />
                <Spacer size="s" />
              </>
            )}
            {hasFile && !submitted && <Button size="s" children={'Submit'} onClick={handleSubmit} disabled={loading} />}
            {!hasFile && <Button size="s" children={'browse'} onClick={() => uploadRef.current?.click()} />}
          </>
        )}
        {submitted && <AnchorButton href={questionState?.answer.url} size="s" children="View" target="_blank" />}
      </Row>
      <Row>
        {showSuccess && <P color="#4EBE40">{`File successfully submitted. THANK YOU!`}</P>}
        {error && <P color="#ED1C24">{`Sorry something went wrong while uploading please try again.`}</P>}
        {loading && <SpinnerWithLabel color="#925BED" label="Uploading File..." />}
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${AnchorButton} {
    color: #fff !important;
  }
`
