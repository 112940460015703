import React, { useState, useEffect, ComponentProps, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { fontBold } from 'fonts'
import { Switch, Route, matchPath, generatePath, useLocation, useHistory, useParams } from 'react-router-dom'

import { GroupEntity } from 'shared/dashboard/types'
import { NavRouteProps, NavRoute } from 'dashboards/types'
import { getMatchingRouteParams } from 'utils/routeUtils'
import { getGroupPublicName } from 'utils/titleUtils'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useUserState } from 'app/UserState'
import { baseUrl } from './FacilitatorDashboard'

import { Row, Spacer, P, Button } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Tabs, Tab } from 'dashboards/common/Tab'
import { LinkButton } from 'common/LinkButton'

import { MentorsEdit } from './groupOverview/MentorsEdit'
import { ProgressDashboard } from './groupOverview/ProgressDashboard'
import { RadioButtonGroup } from 'common/RadioButtonGroup'
import { SpinnerWithLabel } from 'common/Spinner'
import { AssessmentDashboard } from './groupOverview/Assessment'
import { PrintButton } from 'dashboards/common/PrintButton'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { purple, red } from 'themes'
import { InfoTooltip, TooltipInfoProps } from 'common/Tooltip'
import { getMentorExpiryStatus } from 'dashboards/utils/facilitatorUtils'
import { StatusIcon } from 'common/StatusIcon'
import { message } from 'dashboards/common/MentorProfileCell'

type Screen = 'progress-dashboard' | 'edit' | 'digi-hq-progress' | 'skill-tracker-progress' | 'assessment-materials'

export interface GroupCadetNavRoute extends Omit<NavRoute, 'Page'> {
  Page: React.FC<GroupCadetNavRouteProps>
  key: Screen
}
export interface GroupCadetNavRouteProps {
  route: GroupCadetNavRoute
  refetchGroup: () => void
  editingCallback?: (val: boolean) => void
}

export const screenRoutes: GroupCadetNavRoute[] = [
  {
    title: 'Progress',
    path: `/:primaryMentorUid/progress-dashboard`,
    key: 'progress-dashboard',
    Page: ProgressDashboard,
  },
  /*
  {
    title: 'Digital HQ',
    path: `/:primaryMentorUid/digi-hq-progress`,
    key: 'digi-hq-progress',  
    Page: DummyRoute,
  },
  */
  {
    title: 'Assessment',
    path: `/:primaryMentorUid/assessment-materials`,
    key: 'assessment-materials',
    Page: AssessmentDashboard,
  },
  {
    title: 'Mentors',
    path: `/:primaryMentorUid/edit`,
    key: 'edit',
    Page: MentorsEdit,
  },
]

export const GroupCadetOverview: React.FC<NavRouteProps> = ({ route }) => {
  const location = useLocation()
  const history = useHistory()
  const { drupalProfile } = useUserState()
  const { groupUid, primaryMentorUid } = getMatchingRouteParams<'groupUid' | 'primaryMentorUid'>(
    location.pathname,
    screenRoutes.map(({ path }) => ({ path: route.path + path, exact: true })),
    useParams()
  )
  const [group, { loading, errorLoading, fetch: refetchGroup }] = useEndpoint<GroupEntity>(`/api/v1/groups/${groupUid}`)
  const [selectedPrimaryMentorUid, setSelectedPrimaryMentorUid] = useState(
    primaryMentorUid || group?.primary_mentors?.[0]?.uid
  )
  const [editing, setEditing] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [nextMentorUid, setNextMentorUid] = useState<string | undefined>(
    primaryMentorUid || group?.primary_mentors?.[0]?.uid
  )

  let selectedScreen: Screen | null = null
  let selectedScreenRoute: typeof screenRoutes[number] | null = null
  for (let screenRoute of screenRoutes) {
    if (matchPath(location.pathname, { path: route.path + screenRoute.path, exact: true })) {
      selectedScreen = screenRoute.key || null
      selectedScreenRoute = screenRoute
    }
  }

  const handleEditingCallback = (val: boolean) => setEditing(val)

  const changeCadet = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedPrimaryMentorUid(nextMentorUid)
      selectedScreenRoute &&
        history.push(
          generatePath(route.path + selectedScreenRoute.path, {
            groupUid,
            primaryMentorUid: nextMentorUid,
          })
        )
      setShowModal(false)
    })
  }

  useEffect(() => {
    if (!loading && !!group) {
      if (selectedPrimaryMentorUid) {
        if (!group.primary_mentors.find(({ uid }) => uid === primaryMentorUid))
          history.replace(`${baseUrl}/groups/${groupUid}/overview`)
      } else {
        const selectableMentors = group.primary_mentors || [] //.filter(mentor => mentor.activated)
        if (selectableMentors.length) setSelectedPrimaryMentorUid(selectableMentors[0].uid)
        else setSelectedPrimaryMentorUid(undefined)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, group])

  const selectedPrimaryMentorExists = Boolean(
    group?.primary_mentors.find(({ uid }) => uid === selectedPrimaryMentorUid)
  )

  // navigate to first user screen on load
  useEffect(() => {
    if (!selectedScreen && selectedPrimaryMentorExists) {
      history.replace(
        generatePath(route.path + screenRoutes[0].path, {
          groupUid,
          primaryMentorUid: selectedPrimaryMentorUid,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScreen, selectedPrimaryMentorUid, selectedPrimaryMentorExists])

  if (errorLoading)
    return (
      <Page route={route}>
        <HeaderHr
          children={<span style={{ color: 'red' }}>There was an error fetching the group</span>}
          sideRoute={`${baseUrl}/groups/groupUid`}
          sideLabel=" < Back to Group"
        />
      </Page>
    )

  if (loading || !group)
    return (
      <Page route={route}>
        <SpinnerWithLabel color="#925BED" label="One moment please..." />
      </Page>
    )

  const { primary_mentors = [] } = group

  const selectedPrimaryMentor = primary_mentors.find(({ uid }) => uid === selectedPrimaryMentorUid)

  return (
    <>
      <Page
        route={route}
        title={
          <span>
            {group.name}{' '}
            <LinkButton to={`${baseUrl}/groups/${groupUid}/edit`} size="xs" marginLeft="s" children="Edit" />
            <br />
            <small style={{ fontSize: '0.75em', fontWeight: 600 }}>{getGroupPublicName(group)}</small>
          </span>
        }
        sideFragment={<PrintButton />}>
        <HeaderHr
          children={`Cadet Overview`}
          tooltip="Select a cadet tab to access an overview of each cadet's program progress, assessment scores, and mentors list."
        />
        {primary_mentors.length > 0 ? (
          <Tabs style={{ zIndex: 1111 }}>
            {primary_mentors.map((mentor, i) => {
              const mentorExpiryLabel = getMentorExpiryStatus(mentor)
              return (
                <CadetTab
                  key={mentor.id}
                  active={selectedPrimaryMentorUid === mentor.uid}
                  // disabled={!mentor.activated}
                  onClick={() => {
                    if (editing) {
                      ReactDOM.unstable_batchedUpdates(() => {
                        setNextMentorUid(mentor.uid)
                        setShowModal(true)
                      })
                    } else {
                      setSelectedPrimaryMentorUid(mentor.uid)
                      selectedScreenRoute &&
                        history.push(
                          generatePath(route.path + selectedScreenRoute.path, {
                            groupUid,
                            primaryMentorUid: mentor.uid,
                          })
                        )
                    }
                  }}>
                  <Row alignItems="center">
                    {mentorExpiryLabel && mentorExpiryLabel !== 'active' && (
                      <>
                        <InfoTooltip
                          placement="bottomRight"
                          content={message(mentorExpiryLabel)}
                          tooltipStyle={{
                            width: 350,
                            pointerEvents: selectedPrimaryMentorUid === mentor.uid ? 'auto' : 'none',
                          }}
                          containerStyle={{
                            marginLeft: 0,
                            height: 24,
                            cursor: 'pointer',
                            pointerEvents: selectedPrimaryMentorUid === mentor.uid ? 'auto' : 'none',
                          }}>
                          {StatusIcon(mentorExpiryLabel)}
                        </InfoTooltip>
                        <Spacer size="xs" />
                      </>
                    )}
                    <div>
                      {mentor.cadet_first_name ? (
                        <>
                          {mentor.cadet_first_name}
                          <br />
                          {mentor.cadet_last_name}
                        </>
                      ) : (
                        <i>
                          {mentor.first_name}
                          <br />
                          {mentor.last_name}
                        </i>
                      )}
                    </div>
                  </Row>
                </CadetTab>
              )
            })}
          </Tabs>
        ) : (
          <P>This group is currently empty.</P>
        )}
        {selectedPrimaryMentor && (
          <ScreenSelector>
            <RadioButtonGroup<string, ComponentProps<typeof LinkButton> & { value: string; label: ReactNode }>
              size="s"
              Component={LinkButton}
              options={screenRoutes.map(screenRoute => {
                const active = selectedScreen === screenRoute.key
                const disabled = selectedPrimaryMentorUid || active
                let label: ReactNode = screenRoute.title
                let content: ReactNode = ''
                let placement: TooltipInfoProps['placement'] = 'bottom'

                switch (screenRoute.key) {
                  case 'progress-dashboard':
                    placement = 'bottomRight'
                    content = (
                      <span>
                        Participation progress is shown below including meeting attendance, SAS Digital HQ play, Teacher
                        Tip Sheet read status, and Skill Tracker use.
                      </span>
                    )
                    break
                  case 'assessment-materials':
                    content = (
                      <span>
                        Use the Assessment Summary Table below to assign unlinked questionnaires, view completed
                        questionnaires and their scores, add custom tracking data, export scores, and monitor assessment
                        progress over time. <br />
                        <br />
                        If a questionnaire is not visible, use the “+” button to check the unlinked submissions list, or
                        manually enter the score.
                        <br />
                        <br />
                        Refer to the 'Assessment' unit of "SAS Software Navigation” or the Introductory Chapters of the
                        Facilitator Manual for detailed instructions.
                      </span>
                    )
                    break
                  case 'edit':
                    placement = 'bottomLeft'
                    content = (
                      <>
                        <span>
                          Use the Mentor area to add, edit or delete Mentors for each Cadet. Mentor permissions can be
                          changed at any time and there is no limit on the total number of adult Mentors that can be
                          linked to a Cadet. This area also allows for moving a Cadet to another group and the removal
                          of a cadet from the SAS Small Group Program software.
                        </span>
                        <br />
                        <br />
                        <span>
                          <i>*Please only move or delete a Cadet with caution and consideration.</i>
                        </span>
                      </>
                    )
                    break
                }

                label = (
                  <>
                    {screenRoute.key === 'edit' && group.cofacilitator_id?.toString() === drupalProfile?.user_id
                      ? 'Mentors'
                      : screenRoute.title}
                    <InfoTooltip
                      iconSize={14}
                      iconBackground={active ? '#fff' : undefined}
                      iconColor={active ? '#705ef0' : undefined}
                      placement={placement}
                      containerStyle={{ zIndex: 99 }}
                      content={content}
                    />
                  </>
                )

                return {
                  value: screenRoute.key,
                  label,
                  style: { flex: '1 1 auto' },
                  to: generatePath(route.path + screenRoute.path, {
                    groupUid,
                    primaryMentorUid: selectedPrimaryMentorUid,
                    disabled,
                  }),
                }
              })}
              value={selectedScreen}
              onChange={value => {}}
            />
          </ScreenSelector>
        )}

        <Spacer size="m" />

        <Switch
          children={screenRoutes.map(screenRoute => (
            <Route path={route.path + screenRoute.path}>
              <screenRoute.Page
                key={selectedPrimaryMentorUid}
                route={screenRoute}
                refetchGroup={refetchGroup}
                editingCallback={handleEditingCallback}
              />
            </Route>
          ))}
        />
      </Page>
      <MobileMessageModal panelStyle={{ maxWidth: 600 }} isOpen={showModal}>
        <P>
          Are you sure you want to stop? Your new mentor has not been saved. Press 'Leave' to stop editing or 'Cancel'
          to return to your new mentor.
        </P>
        <Spacer />
        <Row justifyContent="center">
          <Button style={{ cursor: 'pointer' }} size="s" theme={purple} children="Leave" onClick={changeCadet} />
          <Spacer size="s" />
          <Button
            style={{ cursor: 'pointer' }}
            size="s"
            theme={red}
            children="Cancel"
            onClick={() => setShowModal(false)}
          />
        </Row>
      </MobileMessageModal>
    </>
  )
}

export const CadetTab = styled(Tab)<{ active?: boolean; highlighted?: boolean }>`
  ${fontBold}
  overflow: visible;
  font-size: 14px;
  padding: 8px;
  line-height: 1.1;
  ${p =>
    p.highlighted
      ? css`
          background: #adb6fb;
        `
      : ''}
`

const ScreenSelector = styled(Row)`
  background-color: #fff;
  padding: 15px;
  border: 1px solid #cdd2e4;
  box-shadow: 0 1px #fff;
  border-radius: 8px;
  border-top-left-radius: 0;
`

export const ScreenPanel = styled.div`
  background-color: #fff;
  border: 1px solid #cdd2e4;
  padding: 0;
  box-shadow: 0 1px #fff;
  border-radius: 8px;
`
