import { parseToHsl, toColorString, guard, parseToRgb, rgba } from './polishedLibUtils'

export function lighten(amount: number | string, color: string): string {
  if (color === 'transparent') return color
  const hslColor = parseToHsl(color)
  return toColorString({
    ...hslColor,
    lightness: guard(0, 1, hslColor.lightness + (typeof amount === 'number' ? amount : parseFloat(amount))),
  })
}

export function darken(amount: number | string, color: string): string {
  if (color === 'transparent') return color
  const hslColor = parseToHsl(color)
  return toColorString({
    ...hslColor,
    lightness: guard(0, 1, hslColor.lightness - (typeof amount === 'number' ? amount : parseFloat(amount))),
  })
}

export function saturate(amount: number | string, color: string): string {
  if (color === 'transparent') return color
  const hslColor = parseToHsl(color)
  return toColorString({
    ...hslColor,
    saturation: guard(0, 1, hslColor.saturation + (typeof amount === 'number' ? amount : parseFloat(amount))),
  })
}

export function mix(weight: number | string, color: string, otherColor: string): string {
  if (color === 'transparent') return otherColor
  if (otherColor === 'transparent') return color
  if (weight === 0) return otherColor
  const parsedColor1 = parseToRgb(color)
  const color1 = {
    ...parsedColor1,
    alpha: 'alpha' in parsedColor1 && typeof parsedColor1.alpha === 'number' ? parsedColor1.alpha : 1,
  }

  const parsedColor2 = parseToRgb(otherColor)
  const color2 = {
    ...parsedColor2,
    alpha: 'alpha' in parsedColor2 && typeof parsedColor2.alpha === 'number' ? parsedColor2.alpha : 1,
  }

  // The formula is copied from the original Sass implementation:
  // http://sass-lang.com/documentation/Sass/Script/Functions.html#mix-instance_method
  const alphaDelta = color1.alpha - color2.alpha
  const x = (typeof weight === 'number' ? weight : parseFloat(weight)) * 2 - 1
  const y = x * alphaDelta === -1 ? x : x + alphaDelta
  const z = 1 + x * alphaDelta
  const weight1 = (y / z + 1) / 2.0
  const weight2 = 1 - weight1

  const mixedColor = {
    red: Math.floor(color1.red * weight1 + color2.red * weight2),
    green: Math.floor(color1.green * weight1 + color2.green * weight2),
    blue: Math.floor(color1.blue * weight1 + color2.blue * weight2),
    alpha:
      color1.alpha * ((typeof weight === 'number' ? weight : parseFloat(weight)) / 1.0) +
      color2.alpha * (1 - (typeof weight === 'number' ? weight : parseFloat(weight)) / 1.0),
  }

  return rgba(mixedColor)
}

export function shade(percentage: number | string, color: string): string {
  if (color === 'transparent') return color
  return mix(typeof percentage === 'number' ? percentage : parseFloat(percentage), 'rgb(0, 0, 0)', color)
}

export function opacify(amount: number | string, color: string): string {
  if (color === 'transparent') return color
  const parsedColor = parseToRgb(color)
  const alpha: number = 'alpha' in parsedColor && typeof parsedColor.alpha === 'number' ? parsedColor.alpha : 1
  const colorWithAlpha = {
    ...parsedColor,
    alpha: guard(0, 1, (alpha * 100 + (typeof amount === 'number' ? amount : parseFloat(amount)) * 100) / 100),
  }
  return rgba(colorWithAlpha)
}

export function transparentize(amount: number | string, color: string): string {
  if (color === 'transparent') return color
  const parsedColor = parseToRgb(color)
  const alpha: number = 'alpha' in parsedColor && typeof parsedColor.alpha === 'number' ? parsedColor.alpha : 1
  const colorWithAlpha = {
    ...parsedColor,
    alpha: guard(0, 1, (alpha * 100 - (typeof amount === 'number' ? amount : parseFloat(amount)) * 100) / 100),
  }
  return rgba(colorWithAlpha)
}

export function tint(percentage: number | string, color: string): string {
  if (color === 'transparent') return color
  return mix(typeof percentage === 'number' ? percentage : parseFloat(percentage), 'rgb(255, 255, 255)', color)
}

export function invert(color: string): string {
  if (color === 'transparent') return color
  // parse color string to rgb
  const value = parseToRgb(color)
  return toColorString({
    ...value,
    red: 255 - value.red,
    green: 255 - value.green,
    blue: 255 - value.blue,
  })
}

export function desaturate(amount: number | string, color: string): string {
  if (color === 'transparent') return color
  const hslColor = parseToHsl(color)
  return toColorString({
    ...hslColor,
    saturation: guard(0, 1, hslColor.saturation - (typeof amount === 'number' ? amount : parseFloat(amount))),
  })
}

export function grayscale(color: string): string {
  if (color === 'transparent') return color
  return toColorString({
    ...parseToHsl(color),
    saturation: 0,
  })
}

export function complement(color: string): string {
  if (color === 'transparent') return color
  const hslColor = parseToHsl(color)
  return toColorString({
    ...hslColor,
    hue: (hslColor.hue + 180) % 360,
  })
}
