import React from 'react'
import {
  Row,
  Spacer,
  Column,
  CUT_BL,
  CUT_BR,
  CUT_TL,
  CUT_TR,
  GRILL_TL,
  GRILL_TR,
  TAB_B_S,
  TAB_T_S,
  GRILL_BR_S,
  GRILL_BL_S,
  Panel,
  H1,
  P,
} from 'common/ui'
import { useMedia } from 'utils/useMedia'
import { AnchorButton } from 'common/LinkButton'

export const Header: React.FC<{ title: string; description: string }> = ({ title, description }) => {
  const compact = useMedia('(max-width: 500px)')

  return (
    <>
      <Row margin="xs" marginBottom justifyContent="center">
        <Column style={{ maxWidth: '1000px', width: '100%' }}>
          <img
            src={require('../assets/image/info-page-logo.png')}
            alt="Secret Agent Society Computer Game"
            style={{ width: '100%' }}
          />
        </Column>
      </Row>
      <Spacer size="l" />
      <Row margin="xs" marginBottom justifyContent="center">
        <Column style={{ maxWidth: '600px', width: '100%' }}>
          <Spacer flex="1 1 0px" />
          <Panel
            padding={compact ? true : '13px 30px 35px 30px'}
            flair={
              compact
                ? []
                : [CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR, GRILL_BR_S, GRILL_BL_S, TAB_T_S, TAB_B_S]
            }>
            <Spacer />
            <H1
              marginTop="l"
              marginBottom="s"
              marginLeft={!compact && 'l'}
              marginRight={!compact && 'l'}
              align="center">
              {title}
            </H1>
            <P align="center" style={{ margin: 0 }}>
              {description}
            </P>
            <Spacer />
          </Panel>
          <Spacer size="l" />
          <AnchorButton
            withChevron
            ghostMode
            href={`${process.env.REACT_APP_SST_BASE_URL}/SAS-SG`}
            target="_blank"
            flex="1 1 0px"
            size="s"
            style={{ textAlign: 'left', fontSize: 15 }}>
            Learn more about SAS small group
          </AnchorButton>
        </Column>
      </Row>
    </>
  )
}
