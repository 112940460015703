import React from 'react'
import { intersperse } from 'utils/intersperse'
import { Spacer } from 'common/ui'

export const MultilineText: React.FC<{ text: string }> = ({ text }) => {
  const lines = (text || '').split(/[\r\n]+/)
  return (
    <>
      {lines.reduce(
        intersperse(i => <Spacer key={i} />),
        []
      )}
    </>
  )
}
