import React, { useEffect, useState } from 'react'

import { MobileMessageModal, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { Button, H2, P, Row, Spacer } from 'common/ui'
import { useUserState } from 'app/UserState'
import { useSkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { Emoji } from 'common/Emoji'
import { Reward } from 'skill-tracker/types'
import { RewardSlab } from 'skill-tracker/common/RewardSlab'
import { getBonusSkillUses } from 'skill-tracker/reducers'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { UpdateRoleStateValuesAction, UPDATE_ROLE_STATE_VALUES } from 'skill-tracker/actionTypes'

export const BonusRewardUnlockedModal: React.FC<{ onConfirm: () => void; bonusRewards: Reward[] }> = ({
  onConfirm,
  bonusRewards,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnConfirm = () => {
    setIsOpen(false)
    setTimeout(onConfirm, MODAL_FADE_OUT_TIME)
  }
  return (
    <MobileMessageModal isOpen={isOpen} onRequestClose={delayedOnConfirm}>
      <H2 style={{ textAlign: 'center' }}>
        <Emoji children={'⭐️'} />
        &nbsp;&nbsp;New bonus reward unlocked!&nbsp;&nbsp;
        <Emoji children={'⭐️'} />
      </H2>
      <P>Congratulations! You've earned enough Bonus Credits to receive:</P>
      {intersperseSpacers(
        bonusRewards.map((reward, i) => <RewardSlab key={i} reward={reward} />),
        's'
      )}
      <Spacer size="m" />
      <Row justifyContent="center">
        <Button type="button" size="s" onClick={delayedOnConfirm}>
          Close
        </Button>
      </Row>
    </MobileMessageModal>
  )
}

export const AutoBonusRewardUnlockedModal: React.FC = () => {
  const { getUserScopes, getBaseAction } = useUserState()
  const { state, roleState, dispatch } = useSkillTrackerState()
  const { seenBonusRewardIds } = roleState
  const isAdult = getUserScopes().indexOf('mentor') >= 0

  const [showModal, setShowModal] = useState<boolean>(false)
  const totalBonusSkillPoints = getBonusSkillUses(state.skillUses).length
  const unseenBonusRewards = state.rewards.filter(
    ({ id, frequency, bonusTarget }) =>
      frequency === 'bonus' && seenBonusRewardIds.indexOf(id) < 0 && bonusTarget && totalBonusSkillPoints >= bonusTarget
  )

  useEffect(() => {
    if (unseenBonusRewards.length > 0) setShowModal(true)
  }, [unseenBonusRewards])

  const handleClose = () => {
    setShowModal(false)
    dispatch({
      ...getBaseAction(),
      type: UPDATE_ROLE_STATE_VALUES,
      role: isAdult ? 'mentor' : 'agent',
      data: { seenBonusRewardIds: [...seenBonusRewardIds, ...unseenBonusRewards.map(({ id }) => id)] },
    } as UpdateRoleStateValuesAction)
  }

  return (
    <>
      {!isAdult && showModal && <BonusRewardUnlockedModal onConfirm={handleClose} bonusRewards={unseenBonusRewards} />}
    </>
  )
}
