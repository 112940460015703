import ReactDOM from 'react-dom'
import { useState, useEffect, useRef } from 'react'
import { retryFetch, toJson } from 'utils/apiUtils'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _unset from 'lodash/unset'
import { ValidationErrorShape } from 'dashboards/common/Form'

export interface UseFormDataReturn<PartialDataType> {
  formData: PartialDataType
  updateFormValue: <Key extends keyof PartialDataType>(key: Key, value: PartialDataType[Key]) => void
  setFormData: React.Dispatch<React.SetStateAction<PartialDataType>>
}

export function useFormData<PartialDataType extends object>(
  defaultValue: PartialDataType
): UseFormDataReturn<PartialDataType> {
  const [formData, setFormData] = useState<PartialDataType>(defaultValue)
  const updateFormValue = <Key extends keyof PartialDataType>(key: Key, value: PartialDataType[Key]) =>
    setFormData({ ...formData, [key]: value })
  return { formData, updateFormValue, setFormData }
}

export interface UseFetchedFormDataReturn<PartialDataType> extends UseFormDataReturn<PartialDataType> {
  loading: boolean
  errorLoading: boolean
  unsavedChanges: boolean
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
  reloadInitialData: () => void
}

export function useFetchedFormData<PartialDataType extends object>(
  fetchUrl: string | undefined,
  defaultValue: PartialDataType,
  fetchConfig?: RequestInit
): UseFetchedFormDataReturn<PartialDataType> {
  const [loading, setLoading] = useState<boolean>(!!fetchUrl)
  const [errorLoading, setErrorLoading] = useState<boolean>(false)
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)
  const { formData, updateFormValue, setFormData } = useFormData<PartialDataType>(defaultValue)
  const loadInitialData = useRef<(reloading?: boolean) => void>()
  useEffect(() => {
    if (!fetchUrl) return
    loadInitialData.current = (reloading: boolean = false) => {
      if (!fetchUrl) return
      ReactDOM.unstable_batchedUpdates(() => {
        if (!reloading) setFormData(defaultValue)
        setLoading(true)
        setErrorLoading(false)
        setUnsavedChanges(false)
      })
      retryFetch(fetchUrl, fetchConfig)
        .then(toJson)
        .then((data: PartialDataType) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setErrorLoading(false)
            setLoading(false)
            setFormData(reloading ? data : { ...data, ...defaultValue })
          })
        })
        .catch(() => {
          ReactDOM.unstable_batchedUpdates(() => {
            setErrorLoading(true)
            setLoading(false)
          })
        })
    }
    loadInitialData.current()
    return () => {
      loadInitialData.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl])
  return {
    formData,
    loading,
    reloadInitialData: loadInitialData.current
      ? () => loadInitialData.current?.(true)
      : () => console.warn('Form data fetcher not created yet'),
    errorLoading,
    unsavedChanges,
    setUnsavedChanges,
    updateFormValue: (...args) => {
      setUnsavedChanges(true)
      updateFormValue(...args)
    },
    setFormData: (...args) => {
      setUnsavedChanges(true)
      setFormData(...args)
    },
  }
}

export interface FormDataSubsetProps<PartialDataType extends object> {
  formData: PartialDataType
  validationErrors: ValidationErrorShape
  setFormData: (data: PartialDataType) => void
  select: string | (string | number)[]
}

export interface FormDataSubsetReturn<PartialSubsetDataType> {
  formData: PartialSubsetDataType
  validationErrors: ValidationErrorShape
  updateFormValue: <Key extends keyof PartialSubsetDataType>(key: Key, value: PartialSubsetDataType[Key]) => void
  setFormData: (dataSubset: PartialSubsetDataType) => void
  deleteItem: () => void
}

export function useFormDataSubset<RootDataType extends object, SubsetDataType extends object>(
  formDataSubsetProps: FormDataSubsetProps<RootDataType>,
  defaultValue: Partial<SubsetDataType>
): FormDataSubsetReturn<SubsetDataType> {
  const {
    formData: rootFormData,
    validationErrors: rootValidationErrors,
    setFormData: setRootFormData,
    select: _select,
  } = formDataSubsetProps
  const select = typeof _select === 'string' ? [_select] : _select
  const formData: SubsetDataType = _get(rootFormData, select)
  const validationErrors: ValidationErrorShape = _get(rootValidationErrors, select, {})

  const setFormData = (dataSubset: Partial<SubsetDataType>) => {
    const newRootFormData = { ...rootFormData }
    _set(newRootFormData, select, dataSubset)
    setRootFormData(newRootFormData)
  }

  const updateFormValue = <Key extends keyof SubsetDataType>(key: Key, value: SubsetDataType[Key]) => {
    const newFormData = { ...formData, [key]: value }
    setFormData(newFormData)
  }

  const deleteItem = () => {
    const newRootFormData = { ...rootFormData }
    const lastSelectKey = select.slice(-1)[0]
    if (typeof lastSelectKey === 'number') {
      const array: SubsetDataType[] = _get(newRootFormData, select.slice(0, -1))
      if (!(array instanceof Array)) {
        return console.warn('Expecting parent level to be an array but did not find that')
      }
      _set(
        newRootFormData,
        select.slice(0, -1),
        array.filter((_, i) => i !== lastSelectKey)
      )
    } else {
      _unset(newRootFormData, select)
    }
    setRootFormData(newRootFormData)
  }

  return { formData, validationErrors, updateFormValue, setFormData, deleteItem }
}
