import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { useDrag } from 'react-dnd'
import { buttonFlash } from 'common/ui'
import { BoardGameCharacter as Character, BoardGameTileKey as TileKey } from 'shared/session/types'

export const DRAG_TYPE_PLAYER_PIECE = 'PLAYER_PIECE'

export type CharacterAvatarProps = Omit<CharacterAvatarStyledProps, 'image'> & {
  character: Character
  onSelect?: () => void
  style?: CSSProperties
  customEmoji: string | null
}

export const CharacterAvatar: React.FC<CharacterAvatarProps> = ({
  character,
  interactive = true,
  onSelect,
  ...props
}) => {
  return (
    <CharacterAvatarStyled
      type="button"
      image={character.avatar}
      interactive={!props.disabled && interactive}
      onClick={!props.disabled && interactive ? onSelect : undefined}
      {...props}>
      {props.customEmoji ? props.customEmoji : ''}
    </CharacterAvatarStyled>
  )
}

interface CharacterAvatarStyledProps {
  image: any
  selected?: boolean
  disabled?: boolean
  interactive?: boolean
  highlighted?: boolean
  size?: number | string
}

const CharacterAvatarStyled = styled.button<CharacterAvatarStyledProps>`
  position: relative;
  display: block;
  outline: none;
  appearance: none;
  font-size: inherit;
  width: ${p => (typeof p.size === 'string' ? p.size : `${p.size || 80}px`)};
  height: ${p => (typeof p.size === 'string' ? p.size : `${p.size || 80}px`)};
  appearance: none;
  background-image: url('${p => p.image}');
  background-size: 100% 100%;
  border: 1px solid #ccc;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.15) ${p => (p.selected ? `, inset 0 0 0 5px ${p.theme.buttonBorderTopColor}` : '')};
  ${p => (p.interactive ? 'cursor: pointer;' : '')}
  ${p => (p.disabled ? 'opacity: 0.5;' : '')}
  &:active {
    border-style: none;
  }
  &:focus {
    border: 1px solid ${p => p.theme.buttonBorderTopColor};
  }
  &::before {
    content: ${p => (p.highlighted ? '""' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    box-shadow: 0px 0px 5px 5px ${p => p.theme.highlightColor};
    animation: ${buttonFlash} 0.5s linear infinite alternate;
  }
`

export type DraggableCharacterAvatarProps = CharacterAvatarProps & {
  playerUid: string
  onMove: (tileKey: TileKey) => void
  allowDrag: boolean
}

export interface CharacterAvatarDragItem {
  type: typeof DRAG_TYPE_PLAYER_PIECE
  playerUid: DraggableCharacterAvatarProps['playerUid']
  props: CharacterAvatarProps
}

export const DraggableCharacterAvatar: React.FC<DraggableCharacterAvatarProps> = ({
  playerUid,
  onMove,
  allowDrag,
  ...props
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: DRAG_TYPE_PLAYER_PIECE, playerUid, props } as CharacterAvatarDragItem,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })
  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, pointerEvents: allowDrag ? 'auto' : 'none' }}>
      <CharacterAvatar {...props} highlighted={isDragging ? false : props.highlighted} />
    </div>
  )
}
