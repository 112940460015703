import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import styled, { keyframes, css } from 'styled-components'

import { Row, Column, Spacer, Button, Absolute, H2, Hr, H3 } from 'common/ui'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { HeaderTemplate } from './common/HeaderTemplate'
import { Switch, SwitchButton } from 'common/Switch'

import { StressBallState } from 'shared/gadget-pack/types'
import { UPDATE_STRESS_BALL } from 'shared/gadget-pack/actionTypes'
import { baseUrl } from './GadgetPack'
import { useSound } from 'utils/useSound'
import { useUserState } from 'app/UserState'
import { useGadgetPackState } from './GadgetPackState'
import { useSfxMuted } from 'utils/useMuted'
import { useGlobalState } from 'app/GlobalState'
import { anxiousColors, happyColors } from 'common/Emotionometer'

const gradients = [
  ['#cde6f4', '#22acf3', '#1b8bdc', '#1773b5'],
  ['#F4F0CD', '#FDDC4E', '#D9B332', '#CCAF0C'],
  ['#DBF4CD', '#4AF322', '#38C617', '#15A220'],
  ['#EECDF4', '#EB22F3', '#A11BDC', '#7E17B5'],
  ['#F7E2F7', '#F6B7F8', '#BE77DD', '#B54DB5'],
  ['#F3F4CD', '#FDBA4E', '#D96C32', '#CC5D0C'],
  ['#CDE6F4', '#22F3F3', '#1BDCD5', '#17B5AF'],
  ['#CECECE', '#9D9D9D', '#777777', '#3C3C3C'],
]

const circleVw = 25
const portraitCircleVw = 60
const radiateDuration = 2000
const radiateFadeDuration = 1000

const breathInterval = 6000 // ms

const breathCycles = 10

export const StressBallScreen: React.FC<{
  hideExitButton?: boolean
  overrideMode?: StressBallState['mode']
  overrideExit?: () => void
}> = ({ hideExitButton, overrideMode, overrideExit }) => {
  const { getBaseAction } = useUserState()
  const {
    state: {
      stressBall: { colorIndex = 0, mode: _mode },
    },
    dispatch,
  } = useGadgetPackState()
  const mode = overrideMode || _mode
  const history = useHistory()
  const stressBallSound = useSfxMuted(useSound(require('./assets/gadgets_stressBall_sound04.mp3'), false))
  const breathingSound = useSfxMuted(useSound(require('./assets/gadgets_stressBall_loop.mp3'), false))
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const { sfxMuted, setSfxMuted } = useGlobalState()
  const [pressed, setPressed] = useState(false)
  const [breathing, setBreathing] = useState(false)
  const [breatheIn, setBreatheIn] = useState(true)
  const [emanating, setEmanating] = useState(false)
  const [animateRipples, setAnimateRipples] = useState(false)
  const breatheIntervalRef = useRef<number>(0)
  const emanatingTimeoutRef = useRef<number>(0)
  const animateRipplesTimeoutRef = useRef<number>(0)
  const breathCounterRef = useRef<number>(0)

  useEffect(() => {
    breathingSound.on('end', soundId => {
      if (breathCounterRef.current < breathCycles) {
        breathingSound.play(soundId)
        breathCounterRef.current = breathCounterRef.current + 1
      } else {
        if (breathingSound.playing()) breathingSound.fadeOutAndStop(1000)
        ReactDOM.unstable_batchedUpdates(() => {
          setBreathing(false)
          setBreatheIn(true)
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (breathing) {
      if (!breatheIntervalRef.current) {
        breatheIntervalRef.current = setInterval(() => setBreatheIn(val => !val), breathInterval)
      }
    } else {
      if (breatheIntervalRef.current) {
        // console.log('clearing breath interval')
        clearInterval(breatheIntervalRef.current)
        breatheIntervalRef.current = 0
      }
    }
  }, [breathing])

  const handleSqueeze = () => {
    if (mode === 'stress') {
      if (breathingSound.playing()) breathingSound.fadeOutAndStop()
      stressBallSound.volume(0.7)
      stressBallSound.play()
      ReactDOM.unstable_batchedUpdates(() => {
        setEmanating(true)
        setAnimateRipples(true)
      })
      if (emanatingTimeoutRef.current) clearTimeout(emanatingTimeoutRef.current)
      if (animateRipplesTimeoutRef.current) clearTimeout(animateRipplesTimeoutRef.current)
      emanatingTimeoutRef.current = setTimeout(() => {
        setEmanating(false)
        emanatingTimeoutRef.current = 0
      }, radiateDuration)
      animateRipplesTimeoutRef.current = setTimeout(() => {
        setAnimateRipples(false)
        animateRipplesTimeoutRef.current = 0
      }, radiateDuration + radiateFadeDuration)
    } else {
      if (stressBallSound.playing()) stressBallSound.fadeOutAndStop(1000)
      if (breathing) {
        if (breathingSound.playing()) breathingSound.fadeOutAndStop(1000)
        ReactDOM.unstable_batchedUpdates(() => {
          setBreathing(false)
          setBreatheIn(true)
        })
        // breatheTimeoutRef.current = setTimeout(() => setBreatheIn(false), breathInterval)
      } else {
        if (!breathingSound.playing()) {
          breathingSound.volume(0)
          breathingSound.play()
        }
        breathingSound.fadeIn(0.5)
        setBreathing(true)
        breathCounterRef.current = 1
      }
    }
  }

  const toggleMode = () => {
    const newMode = mode === 'breathe' ? 'stress' : 'breathe'
    if (newMode === 'stress' && breathing) {
      setBreathing(false)
      setBreatheIn(true)
      if (breathingSound.playing()) breathingSound.fadeOutAndStop(1000)
    }
    dispatch({
      ...getBaseAction(),
      type: UPDATE_STRESS_BALL,
      data: { mode: newMode },
    })
  }

  return (
    <>
      <Bg cover mode={mode} pressed={pressed || (mode === 'breathe' && breathing && breatheIn)}>
        <HeaderTemplate
          title="Stress Ball"
          hideExitButton={hideExitButton}
          onExit={() => (overrideExit ? overrideExit() : history.push(baseUrl))}
          Buttons={
            <>
              <Button children="Settings" onClick={() => setShowSettingsModal(true)} />
            </>
          }>
          <Column justifyContent="center" alignItems="center" style={{ height: '100%', overflow: 'visible' }}>
            <ModeSwitchContainer justifyContent="center" alignItems="center" flexWrap>
              <H2 style={{ width: 200, textAlign: 'right' }}>Stress Ball Mode</H2>
              <Spacer size="m" />
              <Column justifyContent="center">
                {/* <H2 style={{ textAlign: 'center' }}>Mode</H2>
                <Spacer size="m" /> */}
                <Switch size="xl" value={mode === 'breathe'} onChange={() => toggleMode()} />
              </Column>
              <Spacer size="m" />
              <H2 style={{ width: 200, textAlign: 'left' }}>Breathe Mode</H2>
            </ModeSwitchContainer>
            <CirclesContainer active={emanating}>
              {[...Array(5)].map((_, i) => (
                <Circle
                  key={i}
                  color={gradients[colorIndex][1]}
                  animate={animateRipples}
                  style={{ animationDelay: `${i * 0.2}s` }}
                />
              ))}
            </CirclesContainer>
            <StressBall
              colorIndex={colorIndex}
              pressed={pressed}
              mode={mode}
              breathing={breathing}
              breatheIn={breatheIn}
              onClick={handleSqueeze}
              onTouchStart={() => setPressed(true)}
              onTouchCancel={() => setPressed(false)}
              onTouchEnd={() => setPressed(false)}
              onMouseDown={() => setPressed(true)}
              onMouseUp={() => setPressed(false)}
              onMouseLeave={() => setPressed(false)}>
              <img src={require('./assets/gadgets_stressBall_logo.png')} alt="Secret Agent Society" />
            </StressBall>
          </Column>
        </HeaderTemplate>
      </Bg>
      <MobileMessageModal
        zIndex={1300}
        isOpen={showSettingsModal}
        onRequestClose={() => setShowSettingsModal(false)}
        panelStyle={{ width: 320 }}>
        <H2 margin={0}>Settings</H2>
        <Hr margin="s" />
        <Row alignItems="center">
          <H3>Sound Effects:</H3>
          <Spacer flex="1 1 auto" />
          <Switch value={!sfxMuted} onChange={() => setSfxMuted(!sfxMuted)} />
        </Row>
        <Hr margin="s" />
        <H3>Colour:</H3>
        <Row flexWrap justifyContent="space-between">
          {gradients.map((colors, i) => (
            <div key={i} style={{ marginTop: 15 }}>
              <ColorRadioBox
                colors={[colors[0], colors[2]]}
                size={65}
                active={i === colorIndex}
                onClick={() => dispatch({ ...getBaseAction(), type: UPDATE_STRESS_BALL, data: { colorIndex: i } })}
              />
            </div>
          ))}
        </Row>
      </MobileMessageModal>
    </>
  )
}

const Bg = styled(Absolute)<{ mode: 'breathe' | 'stress'; pressed?: boolean }>`
  background: radial-gradient(circle at center, ${p => p.theme.panelBg}, ${p => p.theme.thinOutlineColor});
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${p => p.theme.panelBg};
    opacity: ${p => (p.pressed ? 0 : 1)};
    transition: opacity ${p => (p.mode === 'breathe' ? breathInterval + 'ms' : '0.25s')} linear;
  }
  & > div {
    z-index: 1;
  }
`

export const StressBall = styled.div<{
  pressed?: boolean
  mode: 'breathe' | 'stress'
  breathing?: boolean
  breatheIn?: boolean
  colorIndex: number
}>`
  position: relative;
  display: inline-block;
  width: ${circleVw}vw;
  border-radius: 100%;
  background: radial-gradient(
    circle at 31% 28%,
    ${p => gradients[p.colorIndex][0]},
    ${p => gradients[p.colorIndex][1]} 38%,
    ${p => gradients[p.colorIndex][2]} 81%,
    ${p => gradients[p.colorIndex][3]}
  );
  overflow: hidden;
  cursor: pointer;
  ${p => css`
    transition: width 0.25s cubic-bezier(0.45, 0.08, 0.41, 2.25),
      transform ${p.breathing ? breathInterval + 'ms ' : '450ms '}
        ${p.mode === 'breathe' ? 'ease-in-out' : 'cubic-bezier(0.45, 0.08, 0.41, 2.25)'},
      box-shadow 250ms ease-out;
  `}
  transform: ${p =>
    p.mode === 'breathe'
      ? p.breathing
        ? p.breatheIn
          ? 'scale(1.5, 1.5)'
          : 'scale(0.9, 0.9)'
        : 'scale(1, 1)'
      : p.pressed
      ? `scale(0.75, 1.15);`
      : 'scale(1, 1)'};
  user-select: none;
  &::after {
    content: '';
    display: block;
    margin-top: 100%;
  }
  &:hover {
    ${p => (p.breathing ? '' : `width: calc(${circleVw}vw + 20px);`)}
    box-shadow: 10px 10px 15px 5px rgba(0, 0, 0, 0.2);
  }
  & img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    height: auto;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  @media (orientation: portrait) {
    width: ${portraitCircleVw}vw;
    &:hover {
      width: calc(${portraitCircleVw}vw + 20px);
    }
  }
`

const ColorRadioBox = styled.button<{ active?: boolean; size?: number; colors: [string, string] }>`
  appearance: none;
  outline: none;
  position: relative;
  width: ${p => p.size || 70}px;
  height: ${p => p.size || 70}px;
  border-radius: 100%;
  border: 6px solid white;
  background-color: ${p => p.color};
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, ${p => (p.active ? 0.5 : 0.12)});
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: linear-gradient(to bottom, ${p => p.colors[0]}, ${p => p.colors[1]});
  }
  &:active::after {
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }
`

const scaleIn = keyframes`
    from {
      transform: scale(1, 1);
      opacity: 0.5;
    }
    to {
      transform: scale(2.2,2.2);
      opacity: 0;
    }
  `

const CirclesContainer = styled.div<{ active?: boolean }>`
  opacity: ${p => (p.active ? 1 : 0)};
  transition: opacity ${radiateFadeDuration}ms ease-in-out;
`

export const Circle = styled.div<{ animate?: boolean; color: string }>`
  position: absolute;
  width: ${circleVw}vw;
  height: ${circleVw}vw;
  top: calc(50% - ${circleVw / 2}vw);
  left: calc(50% - ${circleVw / 2}vw);
  border-radius: 50%;
  background-color: ${p => p.color};
  opacity: 0;
  ${p =>
    p.animate
      ? css`
          animation: ${scaleIn} ${radiateDuration - 500}ms infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
        `
      : ''}

  @media (orientation: portrait) {
    width: ${portraitCircleVw}vw;
    height: ${portraitCircleVw}vw;
    top: calc(50% - ${portraitCircleVw / 2}vw);
    left: calc(50% - ${portraitCircleVw / 2}vw);
  }
`

export const ModeSwitchContainer = styled(Row)`
  position: absolute;
  top: 0;
  & ${SwitchButton} {
    &:not([aria-checked='true']) {
      background: ${anxiousColors[2]};
    }
    &[aria-checked='true'] {
      background: ${happyColors[1]};
    }
  }
  @media (max-width: 640px) {
    display: block;
    width: 100%;
    & ${SwitchButton} {
      left: 0;
      transform: translateX(-35%) translateY(-12%) rotate(90deg) scale(0.5);
    }
    & ${Spacer} {
      display: none;
    }
    & h2 {
      position: absolute;
      left: 50px;
      text-align: left !important;
      &:last-child {
        top: 30px;
      }
    }
  }
`
