import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react'
import Modal from 'app/Modal'
import { Panel, PanelProps, Padding, Row, Spacer, Button, P, CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_B_S } from 'common/ui'
import { useHotkey } from 'utils/useHotkey'

interface Props {
  isOpen: boolean
  onSubmit: (value: boolean) => void
  label: ReactNode | string
  confirmText?: string
  cancelText?: string
  width?: number | string
  shadow?: PanelProps['shadow']
  shadowColor?: PanelProps['shadowColor']
  style?: CSSProperties
  forceInput?: boolean
  reverseButtons?: boolean
  labelBelow?: boolean
}

export const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onSubmit,
  label,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  forceInput = false,
  width = 460,
  shadow,
  shadowColor,
  style = {},
  reverseButtons,
  labelBelow,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement | null>(null)

  const handleClose = (cancelPressed: boolean = false) => {
    if (!cancelPressed && forceInput) return
    onSubmit(false)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(true)
    return false
  }

  useHotkey('esc', () => handleClose(false), [])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isOpen) setTimeout(() => submitButtonRef.current && submitButtonRef.current.focus(), 50)
  }, [isOpen])
  /* eslint-enable react-hooks/exhaustive-deps */

  const labelNode = typeof label === 'string' ? <P style={{ whiteSpace: 'pre-wrap' }}>{label}</P> : label

  return (
    <Modal isOpen={isOpen} onRequestClose={() => handleClose(false)}>
      <Panel
        shadow={shadow}
        shadowColor={shadowColor}
        padding="20px 30px 35px 30px"
        flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_B_S]}
        style={{ maxHeight: 'calc(100vh - 50px)', width, ...style }}>
        <Padding size="s" style={{ overflow: 'auto' }}>
          {!labelBelow && labelNode}
          {labelBelow && <Spacer />}
          <form onSubmit={handleSubmit}>
            <Row justifyContent="center" reverse={reverseButtons}>
              <Button ref={submitButtonRef} id="submit" type="submit" size="s">
                {confirmText}
              </Button>
              <Spacer size="s" />
              <Button id="cancel" size="s" theme="grey" onClick={() => handleClose(true)}>
                {cancelText}
              </Button>
            </Row>
          </form>
          {labelBelow && labelNode}
        </Padding>
      </Panel>
    </Modal>
  )
}
