/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import _shuffle from 'lodash/shuffle'
import { fontBold } from 'fonts'

import { Button, Column, H1, P, Row, Spacer } from 'common/ui'
import { Checkbox } from 'common/Checkbox'
import { ConfirmModal } from 'common/ConfirmModal'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { HighlightableArea } from 'session/common/HighlightableArea'
import { CircleButton } from 'session/common/CircleButton'

import { GadgetTile, GadgetTileStyled, GadgetTileHeader, GadgetTileInner } from './GadgetTile'
import { CustomGadgetModal } from './CustomGadgetModal'
import { GadgetViewModal } from './GadgetViewModal'

import { Gadget } from 'shared/e-telligence/types'
import { presetGadgets } from 'e-telligence/constants/gadgets'
import {
  AddRelaxationGadgetAction,
  ADD_RELAXATION_GADGET,
  RemoveRelaxationGadgetAction,
  REMOVE_RELAXATION_GADGET,
} from 'shared/e-telligence/actionTypes'

import { UPDATE_SECTION_STATE_DANGEROUS } from 'shared/session/actionTypes'
import {
  EmbeddedActivitySection,
  ActivityRelaxationGadgetsState,
  SectionPanel,
  ParticipantState,
  ParticipantGadgetIdObj,
} from 'shared/session/types'
import { useSessionState } from 'session/SessionState'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import { removeDuplicates } from 'utils/removeDuplicates'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { useFocusedParticipantState, useParticipantProfileUid } from 'session/hooks/useProfileState'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { useUserState } from 'app/UserState'

interface Props {
  section: EmbeddedActivitySection
  panel: SectionPanel
  index: number
}

const getInitialState = (): ActivityRelaxationGadgetsState => ({
  visibleGadgetIds: {},
  requestedGadgetIds: {},
})

export const RelaxationGadgetsActivity: React.FC<Props> = ({ section, panel, index }) => {
  const { getBaseAction } = useUserState()
  const { state: gadgetPackState, dispatch: gadgetPackDispatch } = useGadgetPackState()
  const { dispatch, getBaseAction: getBaseSessionAction } = useSessionState()
  const [viewGadget, setViewGadget] = useState<Gadget | null>(null)
  const [editGadget, setEditGadget] = useState<Gadget | null>(null)
  const [showEditCustomGadgetModal, setShowEditCustomGadgetModal] = useState<boolean>(false)

  const profileUid = useParticipantProfileUid()
  const participantState = useFocusedParticipantState()
  const sectionState = useSectionStateWithFallback('activity_relaxation_gadgets', section, getInitialState)

  const visibleGadgetIds = (participantState && sectionState.visibleGadgetIds[participantState.profile.uid]) || []
  const requestedGadgetIds = (participantState && sectionState.requestedGadgetIds[participantState.profile.uid]) || []
  const baseHighlightKey = `step${panel.step_id}_activity_relaxation_gadgets_${index}`

  const allGadgets = [
    ...presetGadgets.filter(
      ({ id }) => visibleGadgetIds.indexOf(id) >= 0 || gadgetPackState.gadgetIds.indexOf(id) >= 0
    ),
    ...gadgetPackState.customGadgets,
  ]

  const updateSectionState = (state: ActivityRelaxationGadgetsState) => {
    dispatch({
      ...getBaseSessionAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: 'activity_relaxation_gadgets',
      section_id: section.id,
      state: state,
    })
  }

  const toggleRequestGadget = (gadget: Gadget, ignoreGadgetPackStateUpdate: boolean = false) => {
    if (!participantState) return
    const removing = requestedGadgetIds.indexOf(gadget.id) >= 0
    if (gadgetPackState.gadgetIds.length === 5 && !removing) return

    updateSectionState({
      ...sectionState,
      requestedGadgetIds: {
        ...sectionState.requestedGadgetIds,
        [participantState.profile.uid]: removing
          ? requestedGadgetIds.filter(id => id !== gadget.id)
          : [...requestedGadgetIds, gadget.id],
      },
    })
    if (!ignoreGadgetPackStateUpdate) {
      if (removing) {
        gadgetPackDispatch({
          ...getBaseAction(),
          type: REMOVE_RELAXATION_GADGET,
          gadgetId: gadget.id,
        } as RemoveRelaxationGadgetAction)
      } else {
        const gadgetIndex = gadgetPackState.gadgetIds.length
        gadgetPackDispatch({
          ...getBaseAction(),
          type: ADD_RELAXATION_GADGET,
          gadgetId: gadget.id,
        } as AddRelaxationGadgetAction)
      }
    }
  }

  const handleAddCustomGadget = () =>
    ReactDOM.unstable_batchedUpdates(() => {
      setEditGadget(null)
      setShowEditCustomGadgetModal(true)
    })

  const handleEditCustomGadget = (gadget: Gadget) =>
    ReactDOM.unstable_batchedUpdates(() => {
      setEditGadget(gadget)
      setShowEditCustomGadgetModal(true)
    })

  useEffect(() => {
    if (participantState) {
      updateSectionState({
        ...sectionState,
        requestedGadgetIds: {
          ...sectionState.requestedGadgetIds,
          [participantState.profile.uid]: [...gadgetPackState.gadgetIds].filter(removeDuplicates),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gadgetPackState.gadgetIds])

  useEffect(() => {}, [])

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${profileUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'relaxation-gadgets',
          subcategory: null,
          event_key: null,
          event_value: null,
        }}
      />
      <Heading marginBottom="s">Select Gadget{allGadgets.length === 1 ? '' : 's'}:</Heading>
      <Container>
        <ContainerInner>
          {allGadgets.map(gadget => (
            <GadgetTileContainer key={gadget.id}>
              <HighlightableArea
                id={`${baseHighlightKey}_${gadget.id}`}
                style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                <GadgetTile
                  gadget={gadget}
                  requested={requestedGadgetIds.indexOf(gadget.id) >= 0}
                  added={gadgetPackState.gadgetIds.indexOf(gadget.id) >= 0}
                  onView={gadget => setViewGadget(gadget)}
                  onEdit={gadget => handleEditCustomGadget(gadget)}
                  onToggleRequested={toggleRequestGadget}
                  staticMode={section.relaxation_gadgets_static === 'static'}
                  disableAdd={gadgetPackState.gadgetIds.length === 5}
                />
              </HighlightableArea>
            </GadgetTileContainer>
          ))}
          {!section.relaxation_gadgets_static && (
            <GadgetTileContainer>
              <GadgetTileStyled active>
                <GadgetTileHeader />
                <GadgetTileInner justifyContent="center" alignItems="center">
                  <div>
                    <Button size="xs" children="Create Custom Card" onClick={() => handleAddCustomGadget()} />
                  </div>
                </GadgetTileInner>
              </GadgetTileStyled>
            </GadgetTileContainer>
          )}
          {[...Array(4)].map((_, i) => (
            <GadgetTileContainer key={i}>
              <GadgetTileStyled style={{ margin: 0 }} />
            </GadgetTileContainer>
          ))}
        </ContainerInner>
      </Container>
      <GadgetViewModal isOpen={viewGadget !== null} onRequestClose={() => setViewGadget(null)} gadget={viewGadget} />
      <CustomGadgetModal
        isOpen={showEditCustomGadgetModal}
        onRequestClose={() => setShowEditCustomGadgetModal(false)}
        state={gadgetPackState}
        dispatch={gadgetPackDispatch}
        gadgetId={editGadget ? editGadget.id : undefined}
      />
    </>
  )
}

const Heading = styled(H1)`
  ${fontBold}
  text-transform: none;
  color: #2eadf0;
`

const GadgetTileContainer = styled(Column)`
  flex: 1 1 25%;
  min-width: 205px;
`

const ContainerInner = styled(Row)`
  margin: -10px;
  flex-wrap: wrap;
`

const Container = styled.div`
  border: 1px solid #cdd2e4;
  background-color: #ffffff;
  border-radius: ${p => p.theme.borderRadius};
  padding: 25px 20px;
`

export const RelaxationGadgetsActivityFacilitator: React.FC<Props> = ({ section, panel, index }) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const [confirmRandomizeModalOpen, setConfirmRandomizeModalOpen] = useState<boolean>(false)
  const [confirmAllModalOpen, setConfirmAllModalOpen] = useState<boolean>(false)
  const sectionState = useSectionStateWithFallback('activity_relaxation_gadgets', section, getInitialState)

  const hasEmptyState =
    Object.keys(sectionState.visibleGadgetIds).reduce(
      (ids, participantUid) => [...ids, ...(sectionState.visibleGadgetIds[participantUid] || [])],
      [] as Gadget['id'][]
    ).length === 0

  const updateSectionState = (state: ActivityRelaxationGadgetsState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: 'activity_relaxation_gadgets',
      section_id: section.id,
      state: state,
    })
  }

  const toggleVisible = (participant: ParticipantState, gadget: Gadget) => {
    const participantVisibleGadgetIds = sectionState.visibleGadgetIds[participant.profile.uid] || []
    const participantRequestedGadgetIds = sectionState.requestedGadgetIds[participant.profile.uid] || []
    const removing = participantVisibleGadgetIds.indexOf(gadget.id) >= 0
    updateSectionState({
      ...sectionState,
      visibleGadgetIds: {
        ...sectionState.visibleGadgetIds,
        [participant.profile.uid]: removing
          ? participantVisibleGadgetIds.filter(id => id !== gadget.id)
          : [...participantVisibleGadgetIds, gadget.id],
      },
      requestedGadgetIds: !removing
        ? sectionState.requestedGadgetIds
        : {
            ...sectionState.requestedGadgetIds,
            [participant.profile.uid]: participantRequestedGadgetIds.filter(id => id !== gadget.id),
          },
    })
  }

  const randomizeSelection = (override: boolean = false) => {
    if (!override && !hasEmptyState && !confirmRandomizeModalOpen) {
      setConfirmRandomizeModalOpen(true)
      return
    }
    updateSectionState({
      ...sectionState,
      visibleGadgetIds: state.participants.reduce(
        (obj, participant) => ({
          ...obj,
          [participant.profile.uid]: _shuffle([...Array(presetGadgets.length)].map((_, i) => i))
            .slice(0, 7)
            .map(v => presetGadgets[v].id),
        }),
        {} as ParticipantGadgetIdObj
      ),
    })
  }

  const handleConfirmRandomize = (proceed: boolean) => {
    if (proceed) randomizeSelection(true)
    setConfirmRandomizeModalOpen(false)
  }

  const selectAll = (override: boolean = false) => {
    if (!override && !confirmAllModalOpen) {
      setConfirmAllModalOpen(true)
      return
    }
    updateSectionState({
      ...sectionState,
      visibleGadgetIds: state.participants.reduce(
        (obj, participant) => ({
          ...obj,
          [participant.profile.uid]: presetGadgets.map(gadget => gadget.id),
        }),
        {} as ParticipantGadgetIdObj
      ),
    })
  }

  const handleConfirmAll = (proceed: boolean) => {
    if (proceed) selectAll(true)
    setConfirmAllModalOpen(false)
  }

  const clearSelection = (index: number) => {
    const participant = state.participants[index]
    updateSectionState({
      ...sectionState,
      visibleGadgetIds: {
        ...sectionState.visibleGadgetIds,
        [participant.profile.uid]: [],
      },
      requestedGadgetIds: {
        ...sectionState.requestedGadgetIds,
        [participant.profile.uid]: [],
      },
    })
  }

  const baseHighlightKey = `step${panel.step_id}_activity_relaxation_gadgets_${index}`

  return (
    <>
      <FacilitatorControls title="Relaxation Gadget Controls">
        {!section.relaxation_gadgets_static && (
          <P>Note: Check cadet tabs to remove cards from gadget pack and see any custom cards</P>
        )}
        {section.relaxation_gadgets_static && <Spacer size="l" />}
        <Row justifyContent="flex-end" style={{ position: 'absolute', top: 20, right: 25 }}>
          <Button theme="purple" size="s" children="Select All" onClick={() => selectAll()} />
          <Spacer />
          <Button theme="purple" size="s" children="Random" onClick={() => randomizeSelection()} />
        </Row>
        <FacilitatorControlsTable>
          <thead>
            <tr>
              <th></th>
              {state.participants.map((participant, i) => (
                <th key={i}>
                  <Row alignItems="center" justifyContent="center">
                    {participant.profile.displayName}
                    <Spacer size="s" />
                    <Button theme="purple" size="xs" children="Reset" onClick={() => clearSelection(i)} />
                  </Row>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {presetGadgets.map(gadget => (
              <tr key={gadget.id}>
                <th>
                  <HighlightableArea
                    id={`${baseHighlightKey}_${gadget.id}`}
                    style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                    <Row alignItems="center">
                      <div style={{ flex: 'none', width: 30 }}>
                        <img
                          src={require('e-telligence/assets/gadgets/' + gadget.id + '.png')}
                          style={{ height: '1em', width: 'auto', transform: 'translateY(0.2em) scale(1.5)' }}
                          alt={gadget.title}
                        />
                      </div>
                      <div style={{ flex: '1 1 auto', maxWidth: 220 }}>{gadget.title}</div>
                    </Row>
                  </HighlightableArea>
                </th>
                {state.participants.map((participant, i) => {
                  const participantVisibleGadgetIds = sectionState.visibleGadgetIds[participant.profile.uid] || []
                  const participantRequestedGadgetIds = sectionState.requestedGadgetIds[participant.profile.uid] || []
                  const visible = participantVisibleGadgetIds.indexOf(gadget.id) >= 0
                  const requested = participantRequestedGadgetIds.indexOf(gadget.id) >= 0
                  return (
                    <td key={`participant${i}`} style={{ padding: '5px' }}>
                      <Checkbox
                        size="xs"
                        disabled={requested}
                        checked={requested || visible}
                        onChange={() => toggleVisible(participant, gadget)}
                      />
                      {requested && (
                        <div style={{ display: 'inline-block', verticalAlign: 'bottom', marginLeft: 10 }}>
                          <CircleButton
                            size="xs"
                            children={<span style={{ fontSize: 16 }} children="✓" />}
                            style={{ pointerEvents: 'none' }}
                            onClick={() => {}}
                          />
                        </div>
                      )}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </FacilitatorControlsTable>
      </FacilitatorControls>
      <ConfirmModal
        isOpen={confirmRandomizeModalOpen}
        onSubmit={handleConfirmRandomize}
        label="Are you sure you want to create a random selection? Your current selections will be reset if you proceed."
      />
      <ConfirmModal
        isOpen={confirmAllModalOpen}
        onSubmit={handleConfirmAll}
        label="Are you sure you want to select all gadgets? Your current selections will be reset if you proceed."
      />
    </>
  )
}
