import React from 'react'

import { Spacer, Hr, H3, Row } from 'common/ui'
import { InfoTooltip } from 'common/Tooltip'
import { Cell } from 'dashboards/common/Cell'
import { SVG } from 'common/SVG'

import { useEndpoint } from 'dashboards/utils/endpointHooks'

export const ProviderPlacesCount: React.FC<{
  providerUid: string
  showNegativeOne?: boolean
}> = ({ providerUid, showNegativeOne }) => {
  const [productKeyCount, { loading: loadingProductKeyCount }] = useEndpoint<number>(
    `/api/v1/product_keys/count?provider_id=${providerUid}&status=available`,
    0
  )
  return (
    <Cell style={{ minHeight: 'initial' }}>
      <H3 style={{ color: '#6737B5', fontSize: 12, fontWeight: 500, margin: '2px 5px' }}>Available Cadet Places</H3>
      <Hr />
      <Row alignItems="center" justifyContent="center">
        {!loadingProductKeyCount && productKeyCount !== null && productKeyCount < 6 && (
          <>
            <InfoTooltip content="This provider is running low on Cadet Places" placement="left">
              <SVG size={14} viewboxSize={37} style={{ display: 'block' }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.54175 18.5003C1.54175 27.8662 9.13425 35.4587 18.5001 35.4587C27.8659 35.4587 35.4584 27.8662 35.4584 18.5003C35.4584 9.1345 27.8659 1.54199 18.5001 1.54199C9.13425 1.54199 1.54175 9.1345 1.54175 18.5003ZM32.3751 18.5003C32.3751 26.1633 26.163 32.3753 18.5001 32.3753C10.8371 32.3753 4.62508 26.1633 4.62508 18.5003C4.62508 10.8374 10.8371 4.62533 18.5001 4.62533C26.163 4.62533 32.3751 10.8374 32.3751 18.5003ZM18.5006 27.7477C19.3523 27.7477 20.0428 27.0575 20.0428 26.2061C20.0428 25.3546 19.3523 24.6644 18.5006 24.6644C17.6489 24.6644 16.9584 25.3546 16.9584 26.2061C16.9584 27.0575 17.6489 27.7477 18.5006 27.7477ZM20.0473 9.24775H16.963V21.5811H20.0473V9.24775Z"
                  fill="currentColor"
                />
              </SVG>
            </InfoTooltip>
            <Spacer size="xs" />
          </>
        )}
        <div style={{ fontSize: 14, fontWeight: 600 }}>
          {loadingProductKeyCount || productKeyCount === null
            ? '---'
            : /*showNegativeOne ? (
            <>
              <del children={String(productKeyCount).padStart(3, '0')} />
              {' → '}
              <span style={{ opacity: 0.5 }}>{String(productKeyCount - 1).padStart(3, '0')}</span>
            </>
          ) : */ String(
                productKeyCount
              ).padStart(3, '0')}
        </div>
      </Row>
    </Cell>
  )
}
