import React from 'react'
import { intersperse } from './intersperse'
import styled from 'styled-components'

export function subscriptToSmall(text: string) {
  const parts = text.split('\u2082')
  if (parts.length > 1) {
    return parts.reduce(
      intersperse(i => <Subscript key={i}>2</Subscript>),
      []
    )
  }
  return text
}

const Subscript = styled.small`
  transform: translateY(10%);
  display: inline-block;
  font-size: 0.7em;
`
