import React from 'react'
import { AppBackground, Row, Spacer, Column, Center } from 'common/ui'
import { GameModeIndicator } from 'app/GameModeIndicator'
import { ShortcutMenu } from 'common/ShortcutMenu'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { Footer } from '../IntroTemplate'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { Header } from './Header'
import { SpinnerWithLabel } from 'common/Spinner'
import { Items } from './Items'
import {
  DividerSection,
  DownloadResourceSection,
  ListSection,
  MediaAsset,
  MediaGridSection,
  TextSection,
} from 'shared/session/types'
import { TableSection } from 'shared/fac-man/types'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { gray } from 'themes'
import { AnchorButton } from 'common/LinkButton'

export interface ContentItem {
  enabled: boolean
  order: number
  image: MediaAsset
  title: string
  divider_sections: DividerSection[]
  download_resource_sections: DownloadResourceSection[]
  list_sections: ListSection[]
  media_grid_sections: MediaGridSection[]
  table_sections: TableSection[]
  text_sections: TextSection[]
}

interface PageShape {
  data: {
    title: string
    public_title: string
    description: string
    content_items: ContentItem[]
  }
}

export const ContentPage: React.FC = () => {
  const location = useLocation()
  const [page, { loading, errorLoading }] = useEndpoint<PageShape | null>(
    `/api/v1/content_pages${location.pathname}`,
    null
  )

  useDisableOrientationLock()

  if (loading) {
    return (
      <AppBackground style={{ flexDirection: 'column', justifyContent: 'center' }}>
        <Center>
          <SpinnerWithLabel label="Loading..." />
        </Center>
      </AppBackground>
    )
  }

  if (errorLoading || (!loading && !page) || page === null) {
    return (
      <AppBackground style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
        <Header
          title="Ooops"
          description="Looks something went wrong. Try loading the page again, if the error persists please contact support"
        />
      </AppBackground>
    )
  }

  return (
    <AppBackground style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
      <Header title={page.data.public_title || page.data.title} description={page.data.description} />
      <Spacer size="l" />

      <Row justifyContent="center">
        <AnchorButton
          disabled={location.pathname === '/info-consentingadult'}
          size="s"
          href="/info-consentingadult"
          children="I'm the consenting adult & SAS Mentor"
        />
        <Spacer />
        <AnchorButton
          disabled={location.pathname === '/info-sasmentor'}
          size="s"
          href="/info-sasmentor"
          children="I'm a SAS Mentor only"
        />
      </Row>

      <Spacer size="l" />
      <ThemeProvider theme={gray}>
        <Items items={page.data.content_items} />
      </ThemeProvider>
      <Row margin="xs" marginBottom justifyContent="center">
        <Column style={{ maxWidth: '600px', width: '100%' }}>
          <Footer />
        </Column>
      </Row>
      <ShortcutMenu />
      {GameModeIndicator && <GameModeIndicator />}
    </AppBackground>
  )
}
