/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'

import { ActivityDetectionOfExpressionState, EmbeddedActivitySection, SectionPanel } from 'shared/session/types'
import { UPDATE_SECTION_STATE_DANGEROUS } from 'shared/session/actionTypes'

import { Button, Row, Spacer } from 'common/ui'
import { DisabledOverlay } from 'session/common/DisabledOverlay'
import { ConfirmModal } from 'common/ConfirmModal'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'
import {
  TurnTakingSection,
  TurnTakingSectionFacilitator,
  getInitialSectionState as getInitialTurnTakingState,
} from '../TurnTaking'
import {
  CardDeckSection,
  CardDeckSectionFacilitator,
  getInitialSectionState as getInitialCardDeckState,
} from '../CardDeck'

import { useSessionState } from 'session/SessionState'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'

interface Props {
  section: EmbeddedActivitySection
  panel: SectionPanel
  index: number
}

export const getInitialSectionState = () => ({})

export const DetectionExpression: React.FC<Props> = ({ section, panel, index }) => {
  const participantState = useFocusedParticipantState()

  const { detection_turn_taking_section, detection_card_deck_section } = section
  if (!detection_turn_taking_section || !detection_card_deck_section) throw new Error('Missing Section!')
  const turnTakingSectionState = useSectionStateWithFallback(
    'turn_taking_sections',
    detection_turn_taking_section,
    getInitialTurnTakingState
  )

  const activeProfileUid =
    participantState &&
    participantState.profile.uid in turnTakingSectionState &&
    (turnTakingSectionState[participantState.profile.uid] === 'active' ||
      turnTakingSectionState[participantState.profile.uid] === 'prepare')
      ? participantState.profile.uid
      : undefined

  return (
    <>
      <TurnTakingSection
        property="turn_taking_sections"
        index={index}
        panel={panel}
        section={detection_turn_taking_section}
      />
      <Spacer size="m" />
      <CardDeckSection
        activeProfileId={activeProfileUid}
        property="card_deck_sections"
        index={index}
        panel={panel}
        section={detection_card_deck_section}
      />
    </>
  )
}

export const DetectionExpressionFacilitator: React.FC<Props> = ({ section, panel, index }) => {
  const { detection_turn_taking_section, detection_card_deck_section } = section
  if (!detection_turn_taking_section || !detection_card_deck_section) throw new Error('Missing Section!')
  const { state, dispatch, getBaseAction } = useSessionState()

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [resetControls, setResetControls] = useState<number>(0)

  const sectionState = useSectionStateWithFallback('activity_detection_of_expression', section, getInitialSectionState)

  const getInitialState = () => getInitialCardDeckState(state.participants)

  const cardDeckSectionState = useSectionStateWithFallback(
    'card_deck_sections',
    detection_card_deck_section,
    getInitialState
  )

  const assignedParticipants = Object.keys(cardDeckSectionState).filter(uid => !!cardDeckSectionState[uid].card_uid)

  const disableTurnTaking = state.participants
    .map(({ profile }) => profile.uid)
    .reduce((bool, uid, i) => bool || assignedParticipants.indexOf(uid) < 0, false)

  const updateSectionState = (state: ActivityDetectionOfExpressionState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: 'activity_detection_of_expression',
      section_id: section.id,
      state,
    })
  }

  const nextRound = (confirmed: boolean) => {
    setShowConfirm(false)

    if (confirmed) {
      updateSectionState(
        state.participants.reduce((acc, participantState) => {
          const userState = sectionState[participantState.profile.uid] || { previousCards: [] }
          acc[participantState.profile.uid] = {
            previousCards: [...userState.previousCards, cardDeckSectionState[participantState.profile.uid].card_uid],
          }
          return acc
        }, {} as ActivityDetectionOfExpressionState)
      )

      setResetControls(resetControls + 1)
    }
  }

  return (
    <>
      <ActivityInteractionIndicator type="group" />
      {detection_turn_taking_section && (
        <DisabledOverlay active={disableTurnTaking} message="Assign everyone a card to enable turn-taking controls">
          <TurnTakingSectionFacilitator
            hideInteractionIndicator
            property="turn_taking_sections"
            section={detection_turn_taking_section}
            {...{ panel, index }}
            refresh={resetControls}>
            <Row justifyContent="flex-end">
              <Button
                theme="purple"
                size="s"
                style={{ marginTop: 20 }}
                onClick={() => setShowConfirm(true)}
                children="Next Round"
              />
            </Row>
          </TurnTakingSectionFacilitator>
        </DisabledOverlay>
      )}
      {detection_card_deck_section && (
        <CardDeckSectionFacilitator
          hideActiveInputs
          property="card_deck_sections"
          section={detection_card_deck_section}
          {...{ panel, index }}
          activityState={sectionState}
          refresh={resetControls}
        />
      )}
      <ConfirmModal
        isOpen={showConfirm}
        onSubmit={nextRound}
        label='Are you sure? This will set all participants to "Wait" and save current selections'
      />
    </>
  )
}
