import React, { Suspense, useState } from 'react'
import { BrowserRouter, Route, Redirect, Switch, useHistory } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import { ThemeProvider } from 'styled-components'

import { UserStateProvider, useUserState } from 'app/UserState'
import { GlobalStateProvider, useGlobalState } from './app/GlobalState'
import { getTouchBackendOptions } from './utils/touchUtils'
import { gameModes } from './activities'
import { ErrorPage } from 'app/ErrorPage'
import { THEMES } from 'themes'
import { useHeartbeat } from 'app/useHeartbeat'
import { SettingsModal } from 'app/SettingsModal'
import { LoadingScreen, AutoLoadingScreen } from 'app/LoadingScreen'
import { AppGlobalStyle } from 'common/ui'
import { InventoryModal } from 'app/InventoryModal'
import { OrientationPrompt } from 'app/OrientationPrompt'
import { OfflineNotification } from 'app/OfflineNotification'
import { HowlerCrashPrompt } from 'app/HowlerCrashPrompt'
import { JournalMenu } from 'journal/JournalMenu'
import { LevelMenu } from 'menu/LevelMenu'
import { ControlRoom } from 'menu/ControlRoom'
import { ActivityContext } from 'app/ActivityContext'
import { Home } from 'home/Home'
import FlashGame from 'legacy/FlashGame'
import { Activity, GameLevel } from 'types'
import { Promotion } from 'menu/Promotion'
import { ErrorBoundary } from 'app/ErrorBoundary'
import VideoTest from 'ui-test/VideoTest'
import { retry } from 'utils/apiUtils'
import { RequireUser } from 'app/RequireUser'
import { ForbiddenPage } from 'home/ForbiddenPage'
import { GameInfoPage } from 'home/GameInfoPage'
import { NotFoundPage } from 'home/NotFoundPage'
import { getNextPromptCount } from 'utils/routeUtils'
import { GoogleAnalytics } from 'app/GoogleAnalytics'
import { SkillTracker } from 'skill-tracker/SkillTracker'
import { MentorPage } from 'home/MentorPage'
import { MentorLoginPage } from 'home/MentorLoginPage'

import { baseUrl as skillTrackerBaseUrl } from 'skill-tracker/common/Navigation'
import { baseUrl as eTelligenceBaseUrl } from 'e-telligence/common/Navigation'
import { baseUrl as gadgetPackBaseUrl } from 'gadget-pack/GadgetPack'
import {
  FacilitatorDashboard,
  baseUrl as facilitatorDashboardBaseUrl,
} from 'dashboards/facilitator/FacilitatorDashboard'
import { MentorDashboard, baseUrl as mentorDashboardBaseUrl } from 'dashboards/mentor/MentorDashboard'
import { ETelligence } from 'e-telligence/ETelligence'
import { useRefreshAccessToken } from 'app/useRefreshAccessToken'
import { isJournalActivity } from 'utils/activityUtils'
import { GadgetPack } from 'gadget-pack/GadgetPack'
import { AuthReturn } from 'auth/AuthReturn'
import { useValidateDrupalProfile } from 'app/useValidateDrupalProfile'
import { SessionAppPreview } from 'session/Session'
import { MentorDashboard as MentorDashboardLogin } from 'home/MentorDashboard'
import { VersionCheckerPrompt } from 'app/VersionCheckerPrompt'
import { FacManPreview } from 'fac-man/FacManPreview'
import { RequireDomain } from 'app/RequireDomain'
import { TipsheetPreview } from 'tipsheets/TipsheetPreview'
import { FacilitatorChecklistPrint } from 'session/printable/FacilitatorChecklistPrint'
import { MentorChecklistPrint } from 'session/printable/MentorChecklistPrint'
import { MentorLinkAccount, MentorLinkAccountAuthRedirect } from 'dashboards/mentor/MentorLinkAccount'
import { FacilitatorMeeting } from 'session/FacilitatorMeeting'
import { MentorMeeting } from 'session/MentorMeeting'
import { CadetMeeting } from 'session/CadetMeeting'
import { ClubMeetingMenu } from 'journal/ClubMeetingMenu'
import { FacilitatorPracticeSession } from 'session/FacilitatorPracticeSession'
import { MentorDrupalAuth } from 'auth/MentorDrupalAuth'
import { MentorPastMeeting } from 'session/MentorPastMeeting'
import { CadetPastMeeting } from 'session/CadetPastMeeting'
import { MentorPreparationModule } from 'session/MentorPreparationModule'
import { FacilitatorMeetingPresentationMode } from 'session/FacilitatorMeetingPresentationMode'
import { TrainingPreview } from 'training/TrainingPreview'
import { MentorGadgetPack } from 'dashboards/mentor/MentorGadgetPack'
import { TipsheetPrint } from 'tipsheets/TipsheetPrint'
import { TrainingUnit } from 'training/TrainingUnit'
import { FacilitatorPastMeeting } from 'session/FacilitatorPastMeeting'
import { QuestionnairePreview } from 'questionnaires/QuestionnairePreview'
import { PublicQuestionnaire } from 'dashboards/public/PublicQuestionnaire'
import { ContentPage } from 'home/content-pages/ContentPage'
import { ContentPagePreview } from 'home/content-pages/ContentPagePreview'

const SceneBuilder = React.lazy(() => retry(() => import(/* webpackChunkName: "SceneBuilder" */ 'scene-builder/SceneBuilder'))) // prettier-ignore
const Reporting = React.lazy(() => retry(() => import(/* webpackChunkName: "Reporting" */ 'reporting/Reporting')))
const IndividualReportPrint = React.lazy(() => retry(() => import(/* webpackChunkName: "IndividualReportPrint" */ 'reporting/IndividualReportPrint'))) // prettier-ignore

const App: React.FC = () => {
  return (
    <ErrorBoundary basic>
      <AppGlobalStyle />
      <DndProvider backend={TouchBackend} options={getTouchBackendOptions()}>
        <BrowserRouter>
          <UserStateProvider>
            <GlobalStateProvider>
              <AppTheme>
                <ErrorBoundary>
                  <Suspense fallback={<LoadingScreen />}>
                    <Routes />
                    <SettingsModal />
                    <InventoryModal />
                    <AutoLoadingScreen />
                    <OrientationPrompt />
                    <HowlerCrashPrompt />
                    <VersionCheckerPrompt />
                  </Suspense>
                </ErrorBoundary>
              </AppTheme>
            </GlobalStateProvider>
            <GoogleAnalytics />
          </UserStateProvider>
        </BrowserRouter>
      </DndProvider>
    </ErrorBoundary>
  )
}

const Routes: React.FC = () => {
  useRefreshAccessToken()
  useHeartbeat()
  useValidateDrupalProfile()
  const {
    gameMode,
    hasPermissionFor,
    hasCompleted,
    openAccess,
    enableSkillTracker,
    enableETelligence,
    enableGadgetPack,
  } = useUserState()
  const { exitUrl, setExitUrl } = useGlobalState()
  const activities = gameModes[gameMode].activities
  const levels = gameModes[gameMode].levels
  const history = useHistory()
  const [promotionLevel, setPromotionLevel] = useState<GameLevel | null>(null)

  function shouldReturnToControlRoom(activity: Activity) {
    const currentActivityIndex = activities.findIndex((a) => a.id === activity.id)
    const nextActivity = activities[currentActivityIndex + 1]
    return (
      !nextActivity || (nextActivity.level !== activity.level && hasCompleted(activity) && !hasCompleted(nextActivity))
    )
  }

  function shouldShowPromotion(activity: Activity) {
    if (openAccess || (activity.level || 0) > 2) return false
    const currentActivityIndex = activities.findIndex((a) => a.id === activity.id)
    const nextActivity = activities[currentActivityIndex + 1]
    const nextNextActivity = activities[currentActivityIndex + 2]
    if (!nextNextActivity || hasCompleted(nextActivity) || !hasCompleted(activity)) return false
    return activity.level === nextActivity.level && nextActivity.level !== nextNextActivity.level
  }

  if (promotionLevel) {
    return (
      <Promotion
        level={promotionLevel}
        onComplete={() => {
          setPromotionLevel(null)
          history.replace(`/game/${promotionLevel}`)
        }}
      />
    )
  }

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/info" component={GameInfoPage} />
      <Route exact path="/mentor" component={MentorPage} />

      {/* CMS content pages */}
      <Route exact path="/info-consentingadult" component={ContentPage} />
      <Route exact path="/info-sasmentor" component={ContentPage} />

      {/* Legacy route */}
      <Route exact path="/skill-tracker/mentor">
        <MentorLoginPage redirectUri={'/skill-tracker'} />
      </Route>
      <Route exact path="/mentor/login">
        <MentorLoginPage redirectUri={'/mentor/dashboard'} />
      </Route>
      {/* New route for Drupal */}
      <Route exact path="/mentor/skill-tracker/:licenceUuid">
        <RequireUser authProvider="sst">
          <MentorDrupalAuth redirectUri={'/skill-tracker'} />
        </RequireUser>
      </Route>
      <Route exact path="/mentor/dashboard">
        <RequireUser scopes={['mentor']}>
          <MentorDashboardLogin />
        </RequireUser>
      </Route>
      <Route exact path="/game">
        <RequireUser scopes={['agent']}>
          <ControlRoom levels={levels} activities={activities} />
        </RequireUser>
        <OfflineNotification
          offlineMessage={
            <>
              You’ve gone offline.
              <br />
              Some activities will be unavailable.
            </>
          }
        />
      </Route>
      {levels.map((level, i) => {
        if (!hasPermissionFor(level)) {
          return <Redirect key={i} path={level.path} to="/game" />
        }
        return (
          <Route key={i} exact path={level.path}>
            {() => (
              <RequireUser scopes={['agent']}>
                <LevelMenu
                  level={level}
                  activities={activities.filter((a) => a.level === level.level)}
                  onComplete={() => history.replace('/game', { fromElevator: true })}
                />
              </RequireUser>
            )}
          </Route>
        )
      })}
      {activities.map((activity, i) => {
        if (!hasPermissionFor(activity)) {
          return <Redirect key={i} path={activity.path} to="/game" />
        }

        function onComplete(routeState?: { [key: string]: any }) {
          // ignore routeState objects that look like events,
          // since they will cause issues when being serialized
          if (routeState && routeState.currentTarget) routeState = undefined
          if (exitUrl) {
            setExitUrl('')
            history.replace(exitUrl, routeState)
          } else if (activity.level && shouldShowPromotion(activity)) {
            setPromotionLevel(activity.level)
          } else {
            history.replace(
              `/game/${activity.level}`,
              shouldReturnToControlRoom(activity) ? { returnToControlRoom: true, ...(routeState || {}) } : routeState
            )
          }
        }

        return (
          <Route key={i} path={activity.path}>
            <RequireUser scopes={['agent']}>
              <ActivityContext.Provider value={{ activity, onComplete }}>
                {activity.component && <activity.component activity={activity} onComplete={onComplete} />}
              </ActivityContext.Provider>
            </RequireUser>
          </Route>
        )
      })}
      {activities
        .filter((a) => a.printComponent)
        .map((activity, i) => {
          return (
            <Route key={`p${i}`} exact path={`/print/${activity.id}`}>
              {activity.printComponent && <activity.printComponent activity={activity} />}
            </Route>
          )
        })}
      <Route exact path="/journal">
        {() => (
          <RequireUser scopes={['agent']}>
            <JournalMenu
              activities={activities.filter(isJournalActivity)}
              onComplete={() => history.replace('/game')}
            />
          </RequireUser>
        )}
      </Route>
      <Route exact path="/club-meeting">
        {() => (
          <RequireUser scopes={['agent']}>
            <ClubMeetingMenu onComplete={() => history.replace('/game')} />
          </RequireUser>
        )}
      </Route>
      <Redirect exact path="/scene-builder" to="/scene-generator" />
      <Route exact path="/scene-generator">
        <RequireUser>
          <SceneBuilder
            onComplete={() => history.replace('/game', { ignoreRouterPrompt: getNextPromptCount() })}
            standalone
          />
        </RequireUser>
      </Route>
      <Route path="/report">
        <RequireUser authProvider="sst">
          <Reporting />
        </RequireUser>
      </Route>
      <Route path="/print/individual-report">
        <IndividualReportPrint />
      </Route>

      <Route path="/tipsheets/preview/:previewUid">
        <TipsheetPreview />
      </Route>

      <Route path="/print/tipsheet">
        <TipsheetPrint />
      </Route>

      <Route path="/facilitator-manual/preview/:previewUid">
        <FacManPreview />
      </Route>

      <Route path="/training/preview/:previewUid">
        <TrainingPreview />
      </Route>

      <Route path="/questionnaire/preview/:previewUid">
        <QuestionnairePreview />
      </Route>

      <Route path="/content_page/preview/:previewUid">
        <ContentPagePreview />
      </Route>

      <Route path="/public/questionnaire/:questionnaireType/:booklet/">
        <RequireDomain env="mentor">
          <PublicQuestionnaire />
        </RequireDomain>
      </Route>

      <Route path="/session/preview/:mode(participant|facilitator)/:previewUid">
        <SessionAppPreview />
      </Route>

      <Route path="/facilitator/meetings/live/presentation-mode/:sessionUid" exact>
        <RequireDomain env="mentor">
          <RequireUser
            authProvider="sst"
            rolesAny={['sas-sg facilitator', 'sas-sg facilitator beta', 'sas-sg senior facilitator']}>
            <FacilitatorMeetingPresentationMode />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/facilitator/meetings/live/:sessionUid">
        <RequireDomain env="mentor">
          <RequireUser
            authProvider="sst"
            rolesAny={[
              'sas-sg assistant',
              'sas-sg assistant trainee',
              'sas-sg facilitator',
              'sas-sg facilitator beta',
              'sas-sg facilitator trainee',
              'sas-sg senior facilitator',
              'sas-sg senior facilitator trainee',
            ]}>
            <FacilitatorMeeting />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/facilitator/meetings/past/:sessionUid">
        <RequireDomain env="mentor">
          <RequireUser
            authProvider="sst"
            rolesAny={[
              'sas-sg assistant',
              'sas-sg assistant trainee',
              'sas-sg facilitator',
              'sas-sg facilitator beta',
              'sas-sg facilitator trainee',
              'sas-sg senior facilitator',
              'sas-sg senior facilitator trainee',
            ]}>
            <FacilitatorPastMeeting />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/facilitator/practice/:mode(cadet|parent)">
        <RequireDomain env="mentor">
          <RequireUser
            authProvider="sst"
            rolesAny={[
              'sas-sg assistant',
              'sas-sg assistant trainee',
              'sas-sg facilitator',
              'sas-sg facilitator beta',
              'sas-sg facilitator trainee',
              'sas-sg senior facilitator',
              'sas-sg senior facilitator trainee',
            ]}>
            <FacilitatorPracticeSession />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/facilitator/training/:type/:unitUid">
        <RequireDomain env="mentor">
          <RequireUser
            authProvider="sst"
            rolesAny={[
              'sas-sg assistant',
              'sas-sg assistant trainee',
              'sas-sg facilitator',
              'sas-sg facilitator beta',
              'sas-sg facilitator trainee',
              'sas-sg senior facilitator',
              'sas-sg senior facilitator trainee',
            ]}>
            <TrainingUnit />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/mentor/:mentorUid/meetings/live/:sessionUid">
        <RequireDomain env="mentor">
          <RequireUser authProvider="sst" rolesAny={['mentor']}>
            <MentorMeeting />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/mentor/:mentorUid/meetings/past/:sessionUid">
        <RequireDomain env="mentor">
          <RequireUser authProvider="sst" rolesAny={['mentor']}>
            <MentorPastMeeting />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/mentor/:mentorUid/prepare/:moduleUid">
        <RequireDomain env="mentor">
          <RequireUser authProvider="sst" rolesAny={['mentor']}>
            <MentorPreparationModule />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/mentor/:mentorUid/club-journals/:sessionUid">
        <RequireDomain env="mentor">
          <RequireUser authProvider="sst" rolesAny={['mentor']}>
            <CadetPastMeeting />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/club-journals/:sessionUid">
        <RequireDomain env="cadet">
          <RequireUser authProvider="sas" scopes={['agent']}>
            <CadetPastMeeting />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/meetings/:sessionUid">
        <RequireDomain env="cadet">
          <RequireUser authProvider="sas" scopes={['agent']}>
            <CadetMeeting />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path="/print/session/checklist">
        <FacilitatorChecklistPrint />
      </Route>

      <Route path="/print/session/mentor-checklist">
        <MentorChecklistPrint />
      </Route>

      <Route path="/mentor/group-invitation/:mentorUid" exact>
        <MentorLinkAccount />
      </Route>

      <Route path="/mentor/group-invitation/:mentorUid/auth" exact>
        <RequireUser authProvider="sst">
          <MentorLinkAccountAuthRedirect />
        </RequireUser>
      </Route>

      <Route path="/mentor/gadget-pack">
        <RequireDomain env="mentor">
          <RequireUser authProvider="sst" roles={['mentor']}>
            <MentorGadgetPack />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path={facilitatorDashboardBaseUrl}>
        <RequireDomain env="mentor">
          <RequireUser
            authProvider="sst"
            rolesAny={[
              'sas-sg assistant',
              'sas-sg assistant trainee',
              'sas-sg facilitator',
              'sas-sg facilitator beta',
              'sas-sg facilitator trainee',
              'sas-sg senior facilitator',
              'sas-sg senior facilitator trainee',
            ]}>
            <FacilitatorDashboard />
          </RequireUser>
        </RequireDomain>
      </Route>

      <Route path={mentorDashboardBaseUrl}>
        <RequireDomain env="mentor">
          <RequireUser authProvider="sst" roles={['mentor']}>
            <MentorDashboard />
          </RequireUser>
        </RequireDomain>
      </Route>

      {enableSkillTracker && (
        <Route path={skillTrackerBaseUrl}>
          <RequireUser>
            <SkillTracker />
          </RequireUser>
        </Route>
      )}
      {enableETelligence && (
        <Route path={eTelligenceBaseUrl}>
          <RequireUser>
            <ETelligence activities={activities} />
          </RequireUser>
        </Route>
      )}
      {enableGadgetPack && (
        <Route path={gadgetPackBaseUrl}>
          <RequireUser>
            <GadgetPack />
          </RequireUser>
        </Route>
      )}
      <Route path="/error/:error" component={ErrorPage} />
      <Route path="/flash">
        <RequireUser scopes={['legacy-flash']}>
          <FlashGame />
        </RequireUser>
      </Route>
      {/* testing routes */}
      <Route exact path="/video-test" component={VideoTest} />
      <Route exact path="/loading" component={LoadingScreen} />
      <Route exact path="/forbidden" component={ForbiddenPage} />

      <Route exact path="/activate">
        {() => {
          window.location.replace('https://www.sst-institute.net/activate')
          return null
        }}
      </Route>
      <Route exact path="/authreturn" component={AuthReturn} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}

const AppTheme: React.FC = ({ children }: any) => {
  const { themeIndex } = useGlobalState()
  return <ThemeProvider theme={THEMES[themeIndex]}>{children}</ThemeProvider>
}

export default App
