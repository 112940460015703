import React, { useState, useEffect } from 'react'
import { useVideo } from 'utils/useVideo'
import { Column, Button, Row, Spacer } from 'common/ui'
import { VideoContainer } from 'app/VideoContainer'
import { getVideoPool, PreloadStrategy } from 'utils/VideoPool'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useSessionStorage, useLocalStorage } from 'utils/useStorage'

const video1 = require('level3/crime-at-the-cathedral/assets/videos/0.1_Intro.mp4')
const video2 = require('level3/crime-at-the-cathedral/assets/videos/1.1_Teleport.mp4')

type Screen = 'preload' | 'video' | 'video2'

const VideoTest: React.FC = () => {
  const [suffix, setSuffix] = useState(0)
  const [time, setTime] = useSessionStorage(`time${suffix}`, () => Date.now())
  const [data, setData] = useLocalStorage<number[]>('blah', [])
  const [screen, setScreen] = useState<Screen>(() => {
    if (window.location.hash) {
      return window.location.hash.replace('#', '') as Screen
    }
    return 'preload'
  })

  useDisableOrientationLock()

  console.log('VideoTest render')

  useEffect(() => {
    window.location.hash = `#${screen}`
  }, [screen])

  return (
    <Column flex>
      <Row padding>
        <Button onClick={() => setScreen('preload')} pressed={screen === 'preload'}>
          Preloader
        </Button>
        <Spacer />
        <Button onClick={() => setScreen('video')} pressed={screen === 'video'}>
          Video 1
        </Button>
        <Spacer />
        <Button onClick={() => setScreen('video2')} pressed={screen === 'video2'}>
          Video 2
        </Button>
      </Row>
      {screen === 'preload' && (
        <Row padding>
          <Button onClick={() => getVideoPool().preload()}>Preload Video</Button>
          <Spacer />
          <Button onClick={() => setTimeout(() => getVideoPool().reserveVideo(video2, PreloadStrategy.PlayMuted), 200)}>
            Preload Video 2
          </Button>
        </Row>
      )}
      {screen === 'video' && <VideoScreen />}
      {screen === 'video2' && <VideoScreen2 />}
      <Button onClick={() => setSuffix(suffix => (suffix + 1) % 5)}>Switch</Button>
      <label>
        {suffix}: {time}
      </label>
      <Button onClick={() => setTime(Date.now())}>Set Time</Button>
      <Button onClick={() => setData(o => o.concat([Date.now()]))}>Append</Button>
      <pre>{JSON.stringify(data)}</pre>
    </Column>
  )
}

const VideoScreen: React.FC = () => {
  const [visible, setVisible] = useState(false)
  const video = useVideo(video1)

  return (
    <Column flex>
      <Row padding>
        <Button onClick={() => setVisible(true)}>Show Video</Button>
      </Row>
      {visible && <VideoContainer video={video} />}
    </Column>
  )
}

const VideoScreen2: React.FC = () => {
  const video = useVideo(video2)
  console.log('VideoScreen2 render')
  return (
    <Column flex>
      <VideoContainer video={video} />
    </Column>
  )
}

export default VideoTest
