export function getChildren<ContainerChildType extends HTMLElement, ContainerElemType extends HTMLElement>(
  container: ContainerElemType,
  filterer?: (element: ContainerChildType) => boolean
): ContainerChildType[] {
  const children = container.children
  const elements: ContainerChildType[] = []
  for (let i = 0; i < children.length; i++) {
    const child = children[i] as ContainerChildType
    if ('tile' in child.dataset) elements.push(child)
  }

  if (!filterer) return elements
  return elements.filter(filterer)
}

export function visibleWithinParent<ParentType extends HTMLElement, ChildType extends HTMLElement>(
  parentElement: ParentType,
  childElement: ChildType,
  partiallyVisible: boolean = true
): boolean {
  const parentRect = parentElement.getBoundingClientRect()
  const childRect = childElement.getBoundingClientRect()
  return (
    childRect.top - parentRect.top + (partiallyVisible ? childRect.height : 0) >= 0 && // top
    childRect.left + (partiallyVisible ? 0 : childRect.width) <= parentRect.left + parentRect.width && // right
    childRect.top + (partiallyVisible ? 0 : childRect.height) <= parentRect.top + parentRect.height && // bottom
    childRect.left - parentRect.left + (partiallyVisible ? childRect.width : 0) >= 0 // left
  )
}

export const getWindowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
export const getWindowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
