import React, { useState, useRef } from 'react'
import _find from 'lodash/find'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import addDays from 'date-fns/addDays'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import isTomorrow from 'date-fns/isTomorrow'
import { useHistory } from 'react-router-dom'

import { Option } from 'types'
import { WorkflowType, Module, Skill, Reward } from './types'
import {
  UpdateWorkflowAction,
  UPDATE_WORKFLOW,
  AddUpdateSkillAction,
  ADD_UPDATE_SKILL,
  UpdateActiveModuleAction,
  UPDATE_ACTIVE_MODULE,
  AddUpdateModuleAction,
  ADD_UPDATE_MODULE,
  AddUpdateRewardAction,
  ADD_UPDATE_REWARD,
  RemoveSkillAction,
  REMOVE_SKILL,
  SetModuleCompletedAction,
  SET_MODULE_COMPLETED,
  UPDATE_SKILLS,
  UpdateSkillsAction,
  UpdateRoleStateValuesAction,
  UPDATE_ROLE_STATE_VALUES,
} from './actionTypes'
import {
  validateUpdateWorkflow,
  validateUpdateActiveModule,
  validateAddUpdateModule,
  validateAddUpdateSkill,
  validateAddUpdateReward,
  validateRemoveSkill,
  validateSetModuleCompleted,
  validateUpdateSkills,
} from './actionValidators'

import { modules as presetModules } from './constants/modules'
import { Row, Padding, Column, Spacer, Button, Hr, OutlineButton, IconOutlineButton } from 'common/ui'
import { Field } from 'common/Field'
import { Select } from 'common/Select'
import { RadioButtonGroupField, SelectField } from 'common/FieldInputs'
import { Navigation, baseUrl } from './common/Navigation'
import { ValidationErrorList } from '../common/ValidationErrorList'
import { SkillSlab } from './common/SkillSlab'
import { SkillEditModal, validate as validateSkill } from './modals/SkillEditModal'
import { RewardEditModal } from './modals/RewardEditModal'
import { RewardSelectModal } from './modals/RewardSelectModal'
import { CustomModuleEditModal } from './modals/CustomModuleEditModal'

import { getDate } from 'utils/dateTimeUtils'
import { useUserState } from 'app/UserState'
import { useSkillTrackerState } from './SkillTrackerState'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { getWorkflowModules, getModuleSkills, getLastCompletedModule, skillBeingUsed, getModuleTitle } from './reducers'
import { SkillTrackerPanel } from './common/SkillTrackerPanel'
import { HintWrapper } from 'common/Hint'
import { PopoverMessage } from './PopoverMessage'
import { useMedia } from 'utils/useMedia'
import { rewardsMenuHint } from './constants/hints'

type ModuleOption = { value: string; label?: string; disabled?: boolean }
type WorkflowOption = { value: WorkflowType; label?: string }

export const firstModuleActivatedKey = 'ST_firstModuleActivated'

const workflowTypes: WorkflowOption[] = [
  { value: 'whole', label: 'Whole Module' },
  { value: 'split', label: 'Split Module' },
]

const durations = [...Array(21)].map((x, i) => i + 1)

const earliestTime = 6 // 6am
const latestTime = 22 // 10pm
const timeDivisions = 2 // half hour
const times = [...Array((latestTime - earliestTime) * timeDivisions + 1)].map((x, i) => {
  const hour = earliestTime + Math.floor(i / timeDivisions)
  const minutes = Math.round((i % timeDivisions) * (60 / timeDivisions))
  return `${hour % 12 || 12}:${String(minutes).padEnd(2, '0')} ${hour >= 12 ? 'pm' : 'am'}`
})

function validate(module: Module | undefined, skills: Skill[]): string[] {
  const errors: string[] = []
  if (!module) {
    errors.push(`You must select (or create) a module to proceed`)
  } else if (!module.completed) {
    if (!module.startDate) errors.push(`You must specify a start date`)
    if (!module.duration) errors.push(`You must specify the expected length of this module (you can extend it later)`)
    if (!module.checkInTime) errors.push(`You must specify a daily check-in time`)
    if (!skills.length) errors.push(`You must add at least one target skill`)
    else skills.forEach((skill, i) => errors.push(...validateSkill(skill).map(str => `Skill ${i + 1}: ${str}`)))
  }
  return errors
}

function createDurationOptions(startDate?: string): Option[] {
  if (!startDate) return []
  return durations.map(value => {
    const date = addDays(parse(startDate, 'y-MM-dd', Date.now()), value - 1)
    return { value: String(value), label: `${value} Days (${format(date, 'E d MMM')})` }
  })
}

function getRelativeDateLabel(date: Date): string {
  if (isToday(date)) return 'Today'
  if (isYesterday(date)) return 'Yesterday'
  if (isTomorrow(date)) return 'Tomorrow'
  return ''
}

function createStartDateOptions(selectedDate?: string): Option[] {
  const now = new Date()
  const parsedDate = parse(selectedDate || '', 'y-MM-dd', now)
  const earliest = addDays(parsedDate < now ? parsedDate : now, -7)
  const latest = addDays(now, 7)
  const options = []
  for (let d = earliest; d < latest; d = addDays(d, 1)) {
    const relativeLabel = getRelativeDateLabel(d)
    options.push({
      value: format(d, 'y-MM-dd'),
      label: `${format(d, 'E d MMM')}${relativeLabel ? ` (${relativeLabel})` : ''}`,
    })
  }
  return options
}

export const ModuleScreen: React.FC = () => {
  const history = useHistory()
  const { state, roleState, dispatch } = useSkillTrackerState()
  const { getBaseAction } = useUserState()

  const popoverMessageTimeout = useRef<number>(0)
  const [popoverMessage, setPopoverMessage] = useState<string | null>(null)
  const [editingWorkflow, setEditingWorkflow] = useState(false)
  const [editSkill, setEditSkill] = useState<Skill | null>(null)
  const [editReward, setEditReward] = useState<Reward | null>(null)
  const [showRewardsMenu, setShowRewardsMenu] = useState<boolean>(false)
  const [editCustomModule, setEditCustomModule] = useState<Module | null>(null)

  const portrait = useMedia('(orientation: portrait) and (max-width: 420px)')
  const today = getDate({ leadingZeros: true })

  const workflowPresetModules = getWorkflowModules(presetModules, state.workflow)
  const customModules = state.modules.filter(({ type }) => type === 'custom')
  const activeModule = state.modules.find(({ id }) => id === state.activeModuleId)
  const skills = getModuleSkills(state.activeModuleId, state)
  const unconfirmedSkills = skills.filter(({ confirmed }) => !confirmed)

  const validationErrors = validate(activeModule, skills)

  const moduleOptions: ModuleOption[] = [
    ...workflowPresetModules.map(({ id, module, submodule }) => {
      const completed = !!_find(state.modules, { id, completed: true })
      return {
        value: id,
        label: `Module ${module}${submodule || ''}${completed ? ' ✓' : ''}${completed ? ' [Completed]' : ''}`,
        disabled: completed,
      }
    }),
    ...customModules.map(({ id, title, completed }) => ({
      value: id,
      label: `${title}${completed ? ' [Completed]' : ''}`,
      disabled: completed,
    })),
    { value: 'ADD_CUSTOM', label: 'Add Custom...' },
  ]

  const startDateOptions: Option[] = createStartDateOptions(activeModule?.startDate)

  const durationOptions: Option[] = createDurationOptions(activeModule?.startDate)

  const timeOptions: Option[] = activeModule ? times.map(value => ({ value, label: value })) : []

  const updateWorkflow = (workflow: WorkflowType | null | undefined) => {
    if (!workflow) return
    const action: UpdateWorkflowAction = { ...getBaseAction(), type: UPDATE_WORKFLOW, workflow }
    if (validateUpdateWorkflow(action, state)) dispatch(action)
    else console.error(`Couldn't validate workflow update action`)
  }

  const handleModuleSelected = (id: string | undefined) => {
    if (!id) return
    if (id === 'ADD_CUSTOM') {
      // TODO
      const prevCompletedModule = getLastCompletedModule(state.modules)
      const duration = prevCompletedModule?.duration || 7
      const checkInTime = prevCompletedModule?.checkInTime || undefined
      let startDate = format(Date.now(), 'y-MM-dd')
      if (prevCompletedModule?.startDate && prevCompletedModule.duration) {
        const prevDate = parse(prevCompletedModule.startDate, 'y-MM-dd', Date.now())
        startDate = format(addDays(prevDate, prevCompletedModule.duration + 1), 'y-MM-dd')
      }
      setEditCustomModule({
        id: 'custom' + Date.now(),
        type: 'preset',
        startDate,
        duration,
        checkInTime,
      })
    } else {
      const existingModule = _find(state.modules, { id })
      const presetModule = _find(presetModules, { id })
      if (!existingModule && !presetModule) return

      // Restore existing module or create a new one aliasing the preset module
      const prevCompletedModule = getLastCompletedModule(state.modules)
      const duration = prevCompletedModule?.duration || 7
      const checkInTime = prevCompletedModule?.checkInTime || undefined
      let startDate = format(Date.now(), 'y-MM-dd')
      if (prevCompletedModule?.startDate && prevCompletedModule.duration) {
        const prevDate = parse(prevCompletedModule.startDate, 'y-MM-dd', Date.now())
        startDate = format(addDays(prevDate, prevCompletedModule.duration + 1), 'y-MM-dd')
      }
      const module: Module = existingModule
        ? existingModule
        : {
            id,
            presetModuleId: id,
            type: 'preset',
            startDate,
            duration,
            checkInTime,
          }

      // Create skills from preset module if this is the first time activating the module
      let skills: Skill[] = []
      if (!existingModule && presetModule) {
        skills = [...skills, ...presetModule.skills]
        if (state.workflow === 'whole') {
          const secondaryPresetModule = _find(presetModules, { module: presetModule.module, submodule: 'b' })
          if (secondaryPresetModule) skills = [...skills, ...secondaryPresetModule.skills]
        }
        skills = skills.filter(skill => !_find(state.skills, { id: skill.id, moduleId: skill.moduleId }))
      }

      const action: UpdateActiveModuleAction = { ...getBaseAction(), type: UPDATE_ACTIVE_MODULE, id, module, skills }
      if (validateUpdateActiveModule(action, state)) dispatch(action)
      else console.error(`Unable to validate update active module action`, action, state)
    }
  }

  const updateModule = (data: Partial<Omit<Module, 'id'>>) => {
    if (!activeModule) return
    const module = { ...activeModule, ...data }
    const action: AddUpdateModuleAction = { ...getBaseAction(), type: ADD_UPDATE_MODULE, module }
    if (validateAddUpdateModule(action, state)) {
      dispatch(action)
      setPopoverMessage('Saved')
      if (!roleState.firstModuleActivated)
        dispatch({
          ...getBaseAction(),
          type: UPDATE_ROLE_STATE_VALUES,
          role: 'mentor',
          data: { firstModuleActivated: true },
        } as UpdateRoleStateValuesAction)
    } else {
      console.error(`Unable to validate update module data action`, action, state)
      setPopoverMessage('Unable to save')
    }

    if (popoverMessageTimeout.current) window.clearTimeout(popoverMessageTimeout.current)
    popoverMessageTimeout.current = setTimeout(() => setPopoverMessage(null), 2400)
  }

  const handleCustomModuleSave = (module: Module) => {
    const action: AddUpdateModuleAction = { ...getBaseAction(), type: ADD_UPDATE_MODULE, module, setActive: true }
    if (validateAddUpdateModule(action, state)) dispatch(action)
    else console.error(`Unable to validate add custom module action`, action, state)
  }

  const handleNewSkill = () => {
    if (!state.activeModuleId) return
    const skill: Skill = {
      id: `skill${state.skills.length}`,
      text: '',
      symbol: null,
      dailyTarget: null,
      moduleId: state.activeModuleId,
    }
    setEditSkill(skill)
  }

  const handleRemoveSkill = (skill: Skill) => {
    if (!window.confirm('Are you sure you want to remove this skill?')) return
    const action: RemoveSkillAction = {
      ...getBaseAction(),
      type: REMOVE_SKILL,
      skill,
    }
    if (validateRemoveSkill(action, state)) dispatch(action)
    else console.error(`Unable to validate remove skill action`, action, state)
  }

  const handleSkillSave = (skill: Skill) => {
    const action: AddUpdateSkillAction = { ...getBaseAction(), type: ADD_UPDATE_SKILL, skill }
    if (validateAddUpdateSkill(action, state)) dispatch(action)
    else console.error(`Unable to validate update skill action`, action, state)
    setEditSkill(null)
  }

  const handleRewardSave = (reward: Reward) => {
    const action: AddUpdateRewardAction = { ...getBaseAction(), type: ADD_UPDATE_REWARD, reward }
    if (validateAddUpdateReward(action, state)) dispatch(action)
    else console.error(`Unable to validate update reward action`, action, state)
    setEditReward(null)
  }

  const handleStartModule = () => {
    const activeModules = state.modules.filter(module => module.started && !module.completed)
    const ignoreModuleSwitch = activeModules.length === 1 && activeModules[0].id === state.activeModuleId
    if (!ignoreModuleSwitch && activeModules.length > 0) {
      const moduleTitles = activeModules.map(module => getModuleTitle(module, state.workflow)).join(', ')
      const promptText = `Commencing this module will conclude the currently running module:\n${moduleTitles}\n\nProceed?`
      if (window.confirm(promptText)) {
        for (let module of activeModules) {
          const action: SetModuleCompletedAction = {
            ...getBaseAction(),
            type: SET_MODULE_COMPLETED,
            moduleId: module.id,
          }
          if (validateSetModuleCompleted(action, state)) dispatch(action)
          else console.error(`Unable to validate update module data action`, action, state)
        }
      } else {
        return
      }
    }

    const action: UpdateSkillsAction = {
      ...getBaseAction(),
      type: UPDATE_SKILLS,
      skillIds: skills.map(({ id }) => id),
      data: { confirmed: true },
    }
    if (validateUpdateSkills(action, state)) dispatch(action)

    dispatch({
      ...getBaseAction(),
      type: UPDATE_ROLE_STATE_VALUES,
      role: 'mentor',
      data: { firstModuleActivated: true },
    } as UpdateRoleStateValuesAction)

    updateModule({ started: true })
  }

  return (
    <>
      <SkillTrackerPanel>
        <Navigation title="Edit Skill Tracker" />
        <Row flex="1 1 auto" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <Padding size="s">
            <Column style={{ width: '100%' }}>
              <Field label={`View or Edit Rewards Menu`} hint={rewardsMenuHint}>
                <div>
                  <Button type="button" size={portrait ? 's' : 'm'} onClick={() => setShowRewardsMenu(true)}>
                    Rewards Menu
                  </Button>
                </div>
              </Field>
              <Spacer />
              <Hr />
              {(!state.workflow || editingWorkflow) && (
                <>
                  <Spacer />
                  <Field
                    label="Select your module type:"
                    hint={`Ask your SAS Facilitator if your SAS Small Group Program is using Whole or Split modules.`}>
                    <RadioButtonGroupField<WorkflowType | null, WorkflowOption>
                      size="m"
                      options={workflowTypes}
                      value={state.workflow}
                      onChange={updateWorkflow}
                      Component={OutlineButton}
                      propsMapper={props => ({ ...props, selected: false, filled: !!props.selected, theme: 'blue' })}
                    />
                  </Field>
                </>
              )}
              {state.workflow && (
                <>
                  <Spacer />
                  <Field
                    label="Select your module:"
                    hint={`Select your current module. If you've completed all the prepared modules and would like to continue using Skill Tracker select "Add custom".`}
                    labelRight={
                      <OutlineButton
                        size="xs"
                        theme="blue"
                        onClick={() => setEditingWorkflow(!editingWorkflow)}
                        filled={editingWorkflow}>
                        Change Module Type
                      </OutlineButton>
                    }>
                    <SelectField<string, ModuleOption>
                      options={moduleOptions}
                      value={state.activeModuleId || ''}
                      onChange={handleModuleSelected}
                    />
                  </Field>
                  {state.activeModuleId && (
                    <>
                      <Spacer />
                      <Row responsive={666}>
                        <Column flex=" 1 1 auto" style={{ minWidth: 160 }}>
                          <Field
                            label="Enter your start date:"
                            hint={`The day you expect your Cadet to start this module's skill practise. It can be today or a date in the future, or yesterday if you're playing catch-up!`}>
                            <Select
                              options={startDateOptions}
                              value={activeModule?.startDate || today}
                              onChange={startDate => updateModule({ startDate })}
                              disabled={activeModule?.completed}
                            />
                          </Field>
                        </Column>
                        <Spacer />
                        <Column flex=" 1 1 auto" style={{ minWidth: 160 }}>
                          <Field
                            label="Skill tracker duration"
                            hint={`The period of time your Cadet will be practising the skills for this module. If you need to, you can change the duration later or you can add extra days on the Practise Log screen.`}>
                            <Select
                              options={durationOptions}
                              onChange={value => updateModule({ duration: value ? parseInt(value) : undefined })}
                              value={String(activeModule?.duration)}
                              disabled={!activeModule || !activeModule.startDate || activeModule.completed}
                            />
                          </Field>
                        </Column>
                        <Spacer />
                        <Column flex="1 1 auto" style={{ minWidth: 160 }}>
                          <Field
                            label="Enter your daily tally time:"
                            hint={`The planned time to meet with your cadet and review their Skill Tracker Practise Log. Calendar reminders for this time can be added later too.`}>
                            <Select
                              options={timeOptions}
                              onChange={value => updateModule({ checkInTime: value || undefined })}
                              value={String(activeModule?.checkInTime)}
                              disabled={!activeModule || activeModule.completed}
                            />
                          </Field>
                        </Column>
                      </Row>
                      <Hr margin />
                      <Field
                        label="Add Target Skills"
                        hint={`View, edit or enter your Cadet's Target Skill/s for this module. Each Target Skill also requires you to select a symbol and a Daily Symbol Target. Be sure to set realistic daily targets!`}>
                        {intersperseSpacers(
                          skills.map((skill, i) => {
                            const locked = activeModule?.completed || skillBeingUsed(skill, state.skillUses)
                            return (
                              <Row key={i} alignItems="center">
                                <SkillSlab
                                  skill={skill}
                                  label={`Target skill ${i + 1}:`}
                                  onEdit={() => (activeModule?.completed ? undefined : setEditSkill(skill))}
                                  confirmed={activeModule?.started ? undefined : true}
                                />
                                <Spacer size="s" />
                                <HintWrapper
                                  text="You cannot remove this skill because your cadet has already logged uses of it."
                                  disabled={!locked}>
                                  <IconOutlineButton
                                    size="s"
                                    theme="gray"
                                    style={{ fontSize: 20, width: 32, height: 32, lineHeight: '1em' }}
                                    onClick={() => handleRemoveSkill(skill)}
                                    disabled={locked}
                                    children="×"
                                  />
                                </HintWrapper>
                              </Row>
                            )
                          }),
                          's'
                        )}
                        {/* <div> */}
                        <Spacer size="m" />
                        <OutlineButton
                          type="button"
                          size="m"
                          theme="blue"
                          onClick={() => handleNewSkill()}
                          disabled={activeModule?.completed}>
                          Add Skill +
                        </OutlineButton>
                        {/* </div> */}
                      </Field>
                      <Hr margin />
                      {validationErrors.length > 0 && (
                        <>
                          <Row>
                            <ValidationErrorList
                              errors={validationErrors}
                              style={{
                                color: 'inherit',
                                border: '1px solid orange',
                                padding: 10,
                                fontSize: 12,
                              }}
                            />
                          </Row>
                          <Spacer size="s" />
                        </>
                      )}
                      <Row>
                        <Button
                          type="button"
                          size={portrait ? 's' : 'm'}
                          theme="blue"
                          disabled={
                            (!unconfirmedSkills.length || !activeModule?.started) &&
                            (validationErrors.length > 0 || activeModule?.started || activeModule?.completed)
                          }
                          onClick={() => handleStartModule()}>
                          {activeModule?.started
                            ? activeModule.completed
                              ? 'Module Concluded'
                              : unconfirmedSkills.length > 0
                              ? `Activate New Skill${unconfirmedSkills.length > 1 ? 's' : ''}`
                              : 'Saved'
                            : 'Save and Activate Module'}
                        </Button>
                        {activeModule?.started && (
                          <>
                            <Spacer size="s" />
                            <Button
                              type="button"
                              size={portrait ? 's' : 'm'}
                              theme="white"
                              onClick={() => history.push(baseUrl + '/practise')}>
                              Go to Practise Log
                            </Button>
                          </>
                        )}
                      </Row>
                      <Spacer size="s" />
                    </>
                  )}
                </>
              )}
            </Column>
          </Padding>
        </Row>
      </SkillTrackerPanel>
      {editCustomModule && (
        <CustomModuleEditModal
          initialValue={editCustomModule}
          onClose={() => setEditCustomModule(null)}
          onSave={handleCustomModuleSave}
        />
      )}
      {editSkill && (
        <SkillEditModal initialValue={editSkill} onClose={() => setEditSkill(null)} onSave={handleSkillSave} />
      )}
      {editReward && (
        <RewardEditModal initialValue={editReward} onClose={() => setEditReward(null)} onSave={handleRewardSave} />
      )}
      {showRewardsMenu && <RewardSelectModal isOpen onClose={() => setShowRewardsMenu(false)} />}
      <PopoverMessage text={popoverMessage} />
    </>
  )
}
