import React, { CSSProperties } from 'react'
import { P } from 'common/ui'
import { XYCoord, useDragLayer } from 'react-dnd'
import { DragItemType } from './DraggableOption'
import { DragItem } from '../ClickDragQuestion'
import { ReorderQuestionCell } from '../ReorderQuestion'

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  cursor: 'move',
}

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties {
  if (!initialOffset || !currentOffset) return { display: 'none' }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

export const CustomDragLayer: React.FC<{
  size: number
}> = ({ size }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem() as DragItemType,
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))
  function renderItem() {
    switch (itemType) {
      case 'DRAG_ITEM':
        return (
          <div style={{ width: size, pointerEvents: 'none' }}>
            <DragItem>
              <P>{item.item.title}</P>
            </DragItem>
          </div>
        )
      case 'REORDER_ITEM':
        return (
          <div style={{ width: size, pointerEvents: 'none' }}>
            <ReorderQuestionCell numberLabel={0} option={item.item} />
          </div>
        )
      default:
        return null
    }
  }
  if (!isDragging) return null
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  )
}
