import React, { useRef, useState, useLayoutEffect, CSSProperties } from 'react'
import styled from 'styled-components'

import Modal from 'app/Modal'
import { fontBlack } from 'fonts'

const ARROW_WIDTH = 16
const ARROW_HEIGHT = 7
const ARROW_GAP = 5
const GAP = 15

const MODAL_STYLE: ReactModal.Styles = {
  overlay: { background: 'rgba(0, 0, 0, 0.3)' },
  content: { top: 0, left: 0 },
}

export const Hint: React.FC<{ text?: string; onClick?: () => void; style?: CSSProperties }> = ({
  text,
  onClick,
  style,
}) => {
  const hintIconRef = useRef<HTMLDivElement>(null)
  const [showHint, setShowHint] = useState(false)
  const [hintIconRect, setHintIconRect] = useState<DOMRect | null>(null)

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (text) {
      setShowHint(true)
      if (hintIconRef.current) setHintIconRect(hintIconRef.current.getBoundingClientRect())
    }
  }

  return (
    <>
      <HintIcon ref={hintIconRef} children="i" onClick={handleClick} style={style} />
      {showHint && hintIconRect && (
        <Modal isOpen onRequestClose={() => setShowHint(false)} minViewportHeight={320} style={MODAL_STYLE}>
          <HintMessage hintIconRect={hintIconRect}>{text}</HintMessage>
        </Modal>
      )}
    </>
  )
}

export const HintWrapper: React.FC<{
  text: string
  onClick?: () => void
  disabled?: boolean
  wrapperStyle?: CSSProperties
}> = ({ children, text, onClick, disabled, wrapperStyle = {} }) => {
  const hintWrapperRef = useRef<HTMLDivElement>(null)
  const [showHint, setShowHint] = useState(false)
  const [hintIconRect, setHintIconRect] = useState<DOMRect | null>(null)

  const handleClick = () => {
    if (disabled) return
    if (onClick) {
      onClick()
    } else {
      setShowHint(true)
      if (hintWrapperRef.current) setHintIconRect(hintWrapperRef.current.getBoundingClientRect())
    }
  }

  return (
    <>
      <div ref={hintWrapperRef} style={wrapperStyle} onClick={handleClick} children={children} />
      {!disabled && showHint && hintIconRect && (
        <Modal isOpen onRequestClose={() => setShowHint(false)} minViewportHeight={320} style={MODAL_STYLE}>
          <HintMessage hintIconRect={hintIconRect}>{text}</HintMessage>
        </Modal>
      )}
    </>
  )
}

const HintMessage: React.FC<{ hintIconRect: DOMRect }> = ({ hintIconRect, children }) => {
  const hintModalTextRef = useRef<HTMLDivElement>(null)
  const hintArrowRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!hintModalTextRef.current || !hintArrowRef.current) return

    const rect = hintModalTextRef.current.getBoundingClientRect()
    let x = hintIconRect.x + hintIconRect.width / 2 - rect.width / 2
    let y = hintIconRect.bottom + ARROW_HEIGHT + ARROW_GAP
    let invert = false

    // offset content
    if (x < GAP) {
      x = GAP
    } else if (x + rect.width > window.innerWidth) {
      x = window.innerWidth - GAP - rect.width
    }
    if (y + rect.height > window.innerHeight) {
      y = hintIconRect.y - rect.height - ARROW_HEIGHT - ARROW_GAP
      invert = true
    }
    hintModalTextRef.current.style.transform = `translate(${x}px, ${y}px)`

    // offset arrow
    const arrowX = hintIconRect.x + hintIconRect.width / 2 - x - ARROW_WIDTH / 2
    const arrowY = invert ? rect.height : -ARROW_HEIGHT
    hintArrowRef.current.style.transform = `translate(${arrowX}px, ${arrowY}px) ${invert ? ' scale(-1)' : ''}`
  }, [hintIconRect, hintModalTextRef, hintArrowRef])

  return (
    <HintModalText ref={hintModalTextRef}>
      {children}
      <HintArrow ref={hintArrowRef} />
    </HintModalText>
  )
}

const HintIcon = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #abb4db;
  color: white;
  text-align: center;
  cursor: pointer;
  ${fontBlack}
  font-size: 10px;
  text-transform: initial;
  vertical-align: middle;
  transition: background-color 0.15s linear;
  &:hover {
    background-color: #7886c1;
  }
`

const HintModalText = styled.div<{
  position?: 'above' | 'below'
  offsetX?: number
}>`
  position: absolute;
  ${p => (p.position === 'above' ? `bottom: 0;` : `top: 0;`)}
  height: auto;
  background-color: white;
  border-radius: 7px;
  color: black;
  font-size: 12px;
  box-sizing: border-box;
  padding: 10px;
  width: 20rem;
  max-width: 80vw;
`

const HintArrow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 ${ARROW_WIDTH / 2}px ${ARROW_HEIGHT}px ${ARROW_WIDTH / 2}px;
  border-color: transparent transparent white transparent;
`
