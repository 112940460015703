import React, { PropsWithChildren, forwardRef, ComponentPropsWithoutRef, Ref } from 'react'
import { StyledComponent } from 'styled-components'
import { ThemeName } from 'themes'
import { Option } from 'types'
import { Select, SelectProps } from './Select'
import { Indicator } from './Checkbox'
import { FieldInputProps, FieldInputMultiProps } from './Field'
import { TextInput, ButtonSize, Button, SpacerSize, TextareaInput, Row, ButtonProps } from './ui'
import { intersperseSpacers } from 'utils/intersperseSpacers'

/**
 * NOTE:
 * forwardRef to component with props types that rely on a generic is currently broken: https://stackoverflow.com/a/51898192
 * So while you /should/ have to specify <TextInputField<string> {...} /> , for example, it currently complains that the result of forwardRef has 0 generics
 * That said apparently the issue linked had been fixed: https://github.com/microsoft/TypeScript/pull/30215
 * Maybe I'm just missing something obvious...
 */

export const TextInputField = forwardRef(
  <ValueType extends string>(
    props: PropsWithChildren<FieldInputProps<ValueType> & ComponentPropsWithoutRef<typeof TextInput>>,
    ref: Ref<HTMLInputElement>
  ) => (
    <TextInput
      {...props}
      ref={ref}
      value={props.value || ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        props.onChange(e.target.value ? (e.target.value as ValueType) : undefined)
      }
    />
  )
)

export const TextareaInputField = forwardRef(
  <ValueType extends string>(
    props: PropsWithChildren<FieldInputProps<ValueType> & ComponentPropsWithoutRef<typeof TextareaInput>>,
    ref: Ref<HTMLTextAreaElement>
  ) => (
    <TextareaInput
      {...props}
      ref={ref}
      value={props.value || ''}
      onChange={e => props.onChange(e.target.value ? (e.target.value as ValueType) : undefined)}
    />
  )
)

export interface RadioButtonGroupProps<OptionType> {
  size?: ButtonSize
  spacerSize?: SpacerSize
  theme?: ThemeName
  selectedTheme?: ThemeName
  options: OptionType[]
  Component?: StyledComponent<'button', any, ButtonProps, never>
  propsMapper?: (props: { [key: string]: any }) => { [key: string]: any }
}

export const RadioButtonGroupField = <ValueType extends any, OptionType extends { value: ValueType; label?: string }>({
  value,
  onChange,
  disabled,
  options,
  size = 'xs',
  theme = 'white',
  selectedTheme = 'blue',
  spacerSize = 'xs',
  Component = Button,
  propsMapper = p => p,
}: PropsWithChildren<FieldInputProps<ValueType> & RadioButtonGroupProps<OptionType>>) => {
  return (
    <Row>
      {intersperseSpacers(
        options.map((option, i) => {
          const props = propsMapper({
            selected: value === option.value,
            theme: value === option.value ? selectedTheme : theme,
            size,
            disabled,
          })
          return (
            <Component key={i} {...props} onClick={() => onChange(option.value)}>
              {option.label || option.value}
            </Component>
          )
        }),
        spacerSize
      )}
    </Row>
  )
}

export const CheckboxButtonGroupField = <ValueType extends string, OptionType extends Option & { value: ValueType }>({
  value = [],
  onChange,
  disabled,
  options,
  size = 'xs',
  spacerSize = 'xs',
  theme = 'white',
  selectedTheme = 'blue',
  Component = Button,
  propsMapper = p => p,
}: PropsWithChildren<FieldInputMultiProps<ValueType> & RadioButtonGroupProps<OptionType>>) => {
  return (
    <Row>
      {intersperseSpacers(
        options.map((option: OptionType, i) => {
          const selected = value.indexOf(option.value) >= 0
          const props = propsMapper({
            selected,
            theme: selected ? selectedTheme : theme,
            size,
            disabled,
          })
          return (
            <Component
              key={i}
              {...props}
              onClick={() =>
                onChange(selected ? value.filter(test => test !== option.value) : [...value, option.value])
              }>
              <Indicator
                checked={selected}
                size="xs"
                style={{ backgroundColor: 'transparent', display: 'inline-block', verticalAlign: 'sub' }}
              />
              {option.label || option.value}
            </Component>
          )
        }),
        spacerSize
      )}
    </Row>
  )
}

export type SelectFieldProps<OptionType, ValueType> = SelectProps<OptionType, ValueType>

export const SelectField = <ValueType extends string, OptionType extends { value: ValueType; label?: string }>({
  value,
  onChange,
  options,
  disabled,
  empty,
}: PropsWithChildren<SelectFieldProps<OptionType, ValueType>>) => {
  return (
    <Select<OptionType, ValueType>
      empty={empty}
      options={options}
      value={value || ''}
      onChange={(value, option) => onChange(value)}
      disabled={disabled}
    />
  )
}
