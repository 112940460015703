/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled, { ThemeProvider, css } from 'styled-components'
import _filter from 'lodash/filter'
import { useParams, Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { blue } from 'themes'

import { GenericUser } from 'shared/types'
import { FacManModule } from 'shared/fac-man/types'
import { SessionEntityHydrated } from 'shared/dashboard/types'
import { SessionProfile, SessionModule, SavedInputValue, JSONValueType, ParticipantState } from 'shared/session/types'
import { UPDATE_PREVIEW_MODE, SET_SAVED_USER_INPUT_VALUES, UPDATE_STATE } from 'shared/session/actionTypes'

import { SessionStateProvider, useSessionState } from './SessionState'
import { UserInputStateProvider, useUserInputState } from './UserInputState'
import { ProgressionEventsStateProvider } from './ProgressionEventsState'
import { InputContextProvider } from './InputContext'
import { ModalContextProvider } from './ModalContext'

import { TextInput, Button, Panel, Padding, Column, Spacer, H2, P, Row, Hr, H3, AppBackground } from 'common/ui'
import { Field } from 'common/Field'
import { RouteLeavePrompt } from 'common/RouteLeavePrompt'
import { NotFoundPage } from 'home/NotFoundPage'
import { SessionSlideHandler } from './SessionSlide'
import { OfflineMessage } from './OfflineMessage'
import { PreviewHeader } from './PreviewHeader'
import { FacilitatorUi } from './FacilitatorUi'
import { ParticipantUi } from './ParticipantUi'
import { ParticipantPlaybackUi } from './ParticipantPlaybackUi'
import { PastSessionSlideHandler } from './PastSessionSlide'
import { GameModeIndicator } from 'app/GameModeIndicator'

import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useSocket } from 'utils/useSocket'
import { useUserState } from 'app/UserState'
import { useLocalStorage, useSessionStorage_IN_DEV } from 'utils/useStorage'
import { useGenericUser } from 'app/useGenericUser'
import { getSessionInputValues, getSession, getModulesList, getManualModuleByCode } from 'api'
import { getNextPromptCount } from 'utils/routeUtils'
import { tempSessionMap, TempSessionUid, tempSessionUids } from './temp_session_map'

interface Props {}

export const SessionApp: React.FC<Props> = props => {
  useDisableOrientationLock()
  return (
    <ProgressionEventsStateProvider>
      <SessionStateProvider>
        <UserInputStateProvider>
          <InputContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={blue}>
                <SessionAppInner />
                <OfflineMessage />
                {/* TODO: Add logic to disable if on module endScreen */}
                <RouteLeavePrompt
                  when={window.location.hostname !== 'localhost'}
                  message="Are you sure you want to close your session window?"
                />
              </ThemeProvider>
            </ModalContextProvider>
          </InputContextProvider>
        </UserInputStateProvider>
      </SessionStateProvider>
    </ProgressionEventsStateProvider>
  )
}

export const PastSessionApp: React.FC = () => {
  useDisableOrientationLock()
  return (
    <ProgressionEventsStateProvider>
      <SessionStateProvider>
        <UserInputStateProvider>
          <InputContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={blue}>
                <PastSessionAppInner />
                <OfflineMessage />
                {/* TODO: Add logic to disable if on module endScreen */}
                <RouteLeavePrompt
                  when={window.location.hostname !== 'localhost'}
                  message="Are you sure you want to close your session window?"
                />
              </ThemeProvider>
            </ModalContextProvider>
          </InputContextProvider>
        </UserInputStateProvider>
      </SessionStateProvider>
    </ProgressionEventsStateProvider>
  )
}

export const SessionAppPreview: React.FC = () => {
  useDisableOrientationLock()
  return (
    <ProgressionEventsStateProvider>
      <SessionStateProvider>
        <UserInputStateProvider>
          <InputContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={blue}>
                <SessionAppPreviewInner />
              </ThemeProvider>
            </ModalContextProvider>
          </InputContextProvider>
        </UserInputStateProvider>
      </SessionStateProvider>
    </ProgressionEventsStateProvider>
  )
}

const SessionAppInner: React.FC = () => {
  const { url } = useRouteMatch() || {}
  const { dispatch: dispatchInputState } = useUserInputState()
  const { sessionData, setSessionData, sessionProfile, setSessionProfile, isFacilitator, setManual } = useSessionState()
  const handleLogin: LoginProps['onLogin'] = (sessionData, inputValues, profile, facManual) => {
    setSessionProfile(profile)
    setSessionData(sessionData)
    facManual && setManual(facManual)
    dispatchInputState({
      user_uid: profile.uid,
      role: profile.userType,
      timestamp: Date.now(),
      type: SET_SAVED_USER_INPUT_VALUES,
      inputValues,
    })
  }
  if (!sessionData || !sessionProfile)
    return (
      <Switch>
        <Route path={`${url}`} exact>
          <EnterSessionCode />
        </Route>
        <Route path={`${url}/manual/1234`}>
          <EnterSessionCode />
        </Route>
        <Route path={`${url}/:sessionUid/auto`}>
          <Login autoJoin onLogin={handleLogin} />
        </Route>
        <Route path={`${url}/:sessionUid`}>
          <Login onLogin={handleLogin} />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    )
  const WrappingUi = isFacilitator ? FacilitatorUi : ParticipantUi
  return (
    <WrappingUi>
      <Routes />
    </WrappingUi>
  )
}

const PastSessionAppInner: React.FC = () => {
  const { url } = useRouteMatch() || {}
  const { isFacilitator } = useSessionState()
  if (isFacilitator)
    return (
      <Column flex justifyContent="center" alignItems="center">
        <Panel style={{ width: 420 }}>
          <Padding size="s">
            <P>Facilitators currently cannot view past sessions</P>
          </Padding>
        </Panel>
      </Column>
    )
  return (
    <Switch>
      <Route path={`${url}`} exact>
        <EnterSessionCode />
      </Route>
      <Route path={`${url}/:sessionUid`} exact>
        <PastSessionIndex />
      </Route>
      <Route path={`${url}/:sessionUid/:moduleUid`} exact>
        <ParticipantPlaybackUi>
          <PastSessionSlideHandler />
        </ParticipantPlaybackUi>
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

type ListModule = Pick<SessionModule, 'id' | 'title' | 'public_title' | 'type' | 'uid'>

const PastSessionIndex: React.FC = () => {
  const [moduleList, setModuleList] = useState<ListModule[]>([])
  const [loadingModuleList, setLoadingModuleList] = useState(false)
  const [loadingFullModule, setLoadingFullModule] = useState<string | false>(false)
  const [, setLocalStorageModuleUid] = useLocalStorage('session_demo_last_code', '')

  const history = useHistory()
  const { url } = useRouteMatch() || {}
  const { sessionUid } = useParams<{ sessionUid?: string }>()

  const user = useGenericUser()
  const { accessToken } = useUserState()
  const { dispatch: dispatchInputState } = useUserInputState()
  const { dispatch, state, setSessionData, getBaseAction } = useSessionState()

  if (!sessionUid) return <P>No session uid, go back to {url}</P>
  if (user.userType === 'facilitator') return <P>Past sessions not available to facilitators at present.</P>
  if (!(sessionUid in tempSessionMap)) return <P>{sessionUid} not recognized as a current test group session code.</P>

  const moduleMap = tempSessionMap[sessionUid as TempSessionUid][user.userType]
  const activeModuleUid = Object.keys(moduleMap).reduce(
    (moduleUid, key) => (moduleUid ? moduleUid : moduleMap[key] === 'active' ? key : moduleUid),
    null as null | string
  )
  const activeModule = activeModuleUid ? moduleList.find(({ uid }) => uid === activeModuleUid) : undefined

  const loadModuleList = () => {
    setLoadingModuleList(true)
    getModulesList(Object.keys(moduleMap), accessToken)
      .then(modules => {
        ReactDOM.unstable_batchedUpdates(() => {
          setModuleList(modules)
          setLoadingModuleList(false)
        })
      })
      .catch(() => {})
  }

  if (!loadingModuleList && !moduleList.length) loadModuleList()

  const handleSelect = (moduleUid: string) => {
    const activeModule = moduleMap[moduleUid] === 'active'
    if (activeModule) {
      setLocalStorageModuleUid(moduleUid)
      setTimeout(() => {
        history.push(`/session/manual/${user.userType === 'mentor' ? 'mentor/' : ''}${sessionUid}/auto`, {
          ignoreRouterPrompt: getNextPromptCount(),
        })
      }, 250)
    } else {
      setLoadingFullModule(moduleUid)
      getSession(moduleUid, accessToken)
        .then((sessionData: SessionEntityHydrated) => {
          console.log('loaded session data!')
          if (user.userType !== 'facilitator' && userTypeAccessMap[user.userType] !== sessionData.module.type) {
            throw new Error(
              `The requested module '${moduleUid}' is intended for a ${sessionData.module.type} but you're currently logged in as a ${user.userType}`
            )
          }
          return getSessionInputValues(
            {
              session_uid: sessionUid,
              participant_uid: user.userType === 'facilitator' ? undefined : user.uid, // exclude participant_uid if facilitator so that all are fetched
            },
            accessToken
          )
            .then(inputValues => {
              console.log('loaded user input values!')
              setLoadingFullModule(false)
              setSessionData(sessionData)
              dispatchInputState({
                user_uid: user.uid,
                role: user.userType,
                type: SET_SAVED_USER_INPUT_VALUES,
                inputValues: inputValues as SavedInputValue<JSONValueType>[],
                timestamp: Date.now(),
              })
              const newParticipantState: ParticipantState = {
                profile: { ...user },
                status: 'connected',
                locked: true, // important
                tokens: 0,
                inputValues: [],
                currentSlideUid: null,
                gadgetTrayOpen: false,
                trayType: null,
              }
              dispatch({
                ...getBaseAction(),
                type: UPDATE_STATE,
                state: { ...state, sessionUid: moduleUid, participants: [newParticipantState] },
              })
              history.push((url || window.location.href) + `/${moduleUid}`, {
                ignoreRouterPrompt: getNextPromptCount(),
              })
            })
            .catch(e => setLoadingFullModule(false))
        })
        .catch(({ message, statusText, url }) => setLoadingFullModule(false))
    }
  }

  return (
    <AppBackground>
      <Column flex justifyContent="center" alignItems="center">
        <Panel style={{ width: 420 }}>
          <Padding size="s">
            <H2>SG Sessions Beta</H2>
            <div style={{ margin: '1em 0', fontSize: 12 }}>
              Currently logged in as a{' '}
              <span style={{ backgroundColor: userTypeColors[user.userType], color: 'white', padding: 3 }}>
                {user.userType}
              </span>
            </div>
            <P>
              Select 'View' to view a previously completed session.
              <br />
              Select 'Join' to enter a live session.
            </P>
            {activeModule && (
              <>
                <H3 marginBottom="s">Current Module:</H3>
                <ModuleBlock>
                  <Column flex="1 1 auto" justifyContent="center">
                    {activeModule.public_title || activeModule.title}
                  </Column>
                  <Column>
                    <Button size="s" onClick={() => handleSelect(activeModule.uid)} children={'Join'} />
                  </Column>
                </ModuleBlock>
                <Hr margin="m" />
              </>
            )}
            <H3 marginBottom="s">All Modules:</H3>
            <div style={{ maxHeight: 280, overflow: 'auto' }}>
              {Object.keys(moduleMap).length === 0 && (
                <ModuleBlock locked style={{ fontSize: 13 }}>
                  There are no '{user.userType}' modules available for '{sessionUid}' session
                </ModuleBlock>
              )}
              {Object.keys(moduleMap).map(moduleUid => {
                const module = moduleList.find(({ uid }) => uid === moduleUid)
                const missing = moduleList.length > 0 && !module
                const active = moduleMap[moduleUid] === 'active'
                const locked = !moduleMap[moduleUid] || missing // || active
                return (
                  <ModuleBlock key={moduleUid} locked={locked}>
                    <Column flex="1 1 auto" justifyContent="center">
                      {missing && '⚠︎ not available ⚠︎  - '}
                      {module ? module.public_title || module.title : moduleUid}
                    </Column>
                    <Column>
                      <Button
                        size="s"
                        onClick={() => handleSelect(moduleUid)}
                        disabled={loadingFullModule !== false}
                        children={loadingFullModule === moduleUid ? 'Loading...' : active ? 'Join' : 'View ❭'}
                      />
                    </Column>
                  </ModuleBlock>
                )
              })}
            </div>
          </Padding>
        </Panel>
      </Column>
      <GameModeIndicator />
    </AppBackground>
  )
}

const ModuleBlock = styled(Row)<{ locked?: boolean }>`
  padding: 5px;
  border: 1px solid grey;
  margin-bottom: 10px;
  ${p =>
    !p.locked
      ? ''
      : css`
          opacity: 0.5;
          pointer-events: none;
        `}
`

const EnterSessionCode: React.FC = () => {
  const history = useHistory()
  const { url } = useRouteMatch() || {}
  const [sessionUid, setSessionUid] = useLocalStorage('session_demo_last_session_uid', '')
  const navigate = () =>
    history.push((url || window.location.href).replace(/(\/1234|\/)$/, '') + `/${sessionUid}`, {
      ignoreRouterPrompt: getNextPromptCount(),
    })
  return (
    <AppBackground>
      <Column flex justifyContent="center" alignItems="center">
        <Panel style={{ width: 420 }}>
          <Padding size="s">
            <H2>SG Sessions Beta</H2>
            <P>Please enter your session code, e.g. lr, ses, demo1, etc.</P>
            <form onSubmit={() => navigate()}>
              <Field label="Session Code">
                <TextInput
                  value={sessionUid}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSessionUid(e.target.value || '')}
                />
              </Field>
              <Spacer size="m" />
              <Button
                type="submit"
                size="s"
                children="Submit"
                onClick={e => {
                  e.preventDefault()
                  navigate()
                }}
              />
            </form>
          </Padding>
        </Panel>
      </Column>
      <GameModeIndicator />
    </AppBackground>
  )
}

const SessionAppPreviewInner: React.FC = () => {
  const { previewUid } = useParams<{ previewUid?: string }>()
  const [initedPreviewData, setInitedPreviewData] = useState<boolean>(false)
  const {
    state,
    dispatch,
    sessionData,
    setSessionData,
    sessionProfile,
    setSessionProfile,
    isFacilitator,
    previewing,
    setPreviewing,
    previewMode,
    setPreviewMode,
    previewState,
    setPreviewState,
    getBaseAction,
  } = useSessionState()
  const socket = useSocket(process.env.REACT_APP_PREVIEW_SOCKET_URL)
  useEffect(() => {
    if (!socket || !previewUid) return
    if (!socket.connected) {
      console.log('Preview socket listeners being created...')
      socket.on('connect', () => {
        console.log('Preview socket connection established')
        socket.emit('REGISTER_VIEWER', previewUid)
      })
      socket.on('disconnect', () => {
        console.log('Preview socket disconnected')
      })
      socket.on('VIEWER_DATA_UPDATED', (sessionData: any) => {
        console.log('Received viewer data from preview socket', sessionData)
        setSessionData(sessionData)
        setInitedPreviewData(true)
      })
      // TODO: move action processor elsewhere
      socket.on('DISPATCH', (action: any) => {
        console.log('Received action from preview socket server', action)
        switch (action.type) {
          case 'UPDATE_PREVIEW_STATE':
            console.log('Updating preview state', action.state)
            setPreviewState(action.state)
            break
          case 'UPDATE_PREVIEW_MODE':
            setPreviewMode(action.mode)
            break
        }
      })
      socket.connect()
    } else {
      console.log('Socket already connected')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, previewUid])

  useEffect(() => {
    if (!previewing) {
      setPreviewing(true)
      dispatch({ ...getBaseAction(), type: UPDATE_STATE, state: { ...state, sessionActive: true } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewing, setPreviewing])

  useEffect(() => {
    if (!socket) return
    socket.emit('DISPATCH', { type: UPDATE_PREVIEW_MODE, mode: previewMode })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewMode])

  if (!initedPreviewData || !sessionData || !sessionProfile || !previewUid) return <h1>Please wait</h1>
  const WrappingUi = isFacilitator ? FacilitatorUi : ParticipantUi
  return (
    <>
      <PreviewHeader />
      <WrappingUi>
        <SessionSlideHandler />
      </WrappingUi>
    </>
  )
}

const Routes: React.FC = () => {
  const { url } = useRouteMatch() || {}
  return (
    <Switch>
      <Route path={`${url}/:sessionUid/slide/:slideUid`} exact>
        <SessionSlideHandler />
      </Route>
      <Route path={`${url}/:sessionUid`} exact>
        <SessionSlideHandler />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

const userTypeColors: { [key in GenericUser['userType']]: string } = {
  agent: '#177cd2',
  mentor: '#169200',
  facilitator: '#dd45ae',
}

// i set sas-cms module type to 'parent' not 'mentor and didn't want to update that and db entries
// that said there could be different types of mentor modules in future so may as well keep the option
// actually there will be --- mentors as 'facilitators in training' what is life
export const userTypeAccessMap: { [key in GenericUser['userType']]: SessionModule['type'] } = {
  agent: 'cadet',
  facilitator: 'facilitator',
  mentor: 'parent',
}

interface LoginProps {
  autoJoin?: boolean
  onLogin: (
    session: SessionEntityHydrated,
    inputValues: SavedInputValue<JSONValueType>[],
    profile: Omit<SessionProfile, 'id'>,
    facManual?: FacManModule
  ) => void
}

const Login: React.FC<LoginProps> = ({ onLogin, autoJoin }) => {
  const history = useHistory()
  const user = useGenericUser()
  const { url } = useRouteMatch() || {}
  const { sessionUid } = useParams<{ sessionUid?: string }>()
  const { profileName, accessToken } = useUserState()
  const [moduleUid, setModuleUid] = useLocalStorage('session_demo_last_code', '')
  const [name, setName] = useSessionStorage_IN_DEV('session_demo_last_name', user?.displayName || profileName)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const handleSubmit = () => {
    if (!sessionUid) return
    setErrorMessage(null)
    getSession(moduleUid, accessToken)
      .then((sessionData: SessionEntityHydrated) => {
        if (user.userType !== 'facilitator' && userTypeAccessMap[user.userType] !== sessionData.module.type) {
          throw new Error(
            `The requested module '${moduleUid}' is intended for a ${sessionData.module.type} but you're currently logged in as a ${user.userType}`
          )
        }
        return Promise.all([
          getSessionInputValues(
            {
              session_uid: sessionUid,
              participant_uid: user.userType === 'facilitator' ? undefined : user.uid, // exclude participant_uid if facilitator so that all are fetched
            },
            accessToken
          ).catch(e => {
            console.warn('Unable to fetch input values for user', e)
            return undefined
          }),
          getManualModuleByCode(sessionData.module.type, sessionData.module.module_code, accessToken).catch(e => {
            console.warn(
              `Unable to locate facilitator manual for ${sessionData.module.type}-${sessionData.module.module_code}`,
              e
            )
            return undefined
          }),
        ]).then(res => {
          if (res[0] === undefined) return
          return onLogin(
            {
              ...sessionData,
              uid: sessionUid, // TODO: Temporarily need to overwrite this until sessions have their own UIDs
            },
            res[0] as SavedInputValue<JSONValueType>[],
            { ...user, displayName: name || user.displayName },
            res[1]
          )
        })
      })
      .catch(({ message, statusText, url }) => setErrorMessage(message || statusText))
    return false
  }
  useEffect(() => {
    if (autoJoin && !!(name || user.displayName)) {
      handleSubmit()
      history.replace((url || window.location.href).replace('/auto', ''), {
        ignoreRouterPrompt: getNextPromptCount(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AppBackground>
      <Column flex justifyContent="center" alignItems="center">
        <Panel style={{ width: 420 }}>
          <Padding size="s">
            <H2>SG Sessions Beta</H2>
            <div style={{ margin: '1em 0', fontSize: 12 }}>
              Currently logged in as a{' '}
              <span style={{ backgroundColor: userTypeColors[user.userType], color: 'white', padding: 3 }}>
                {user.userType}
              </span>
            </div>
            <form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
                handleSubmit()
              }}>
              <Field label="Module Code">
                <TextInput
                  value={moduleUid}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModuleUid(e.target.value || '')}
                />
              </Field>
              <Spacer />
              <Field label="Your Name">
                <TextInput
                  value={name || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value || '')}
                />
              </Field>
              {errorMessage && (
                <>
                  <Spacer />
                  <div style={{ fontSize: 12, color: '#990000' }} children={errorMessage} />
                </>
              )}
              <Spacer />
              <Button type="submit" size="m" children="Enter Session" />
              {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
            </form>
          </Padding>
        </Panel>
      </Column>
      <GameModeIndicator />
    </AppBackground>
  )
}

export const GenerateSessionUrls: React.FC = () => {
  const [sessionCode, setSessionCode] = useLocalStorage('session_demo_code', '')
  return (
    <Column flex justifyContent="center" alignItems="center">
      <Panel style={{ width: 420 }}>
        <Padding size="s">
          <H2>Generate session URLs</H2>
          <Spacer />
          <Field label="Enter the session code you want to use">
            <TextInput
              value={sessionCode || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSessionCode((e.target.value || '').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase())
              }
              placeholder="e.g. my-sas-session-name, sydney-group1, a_b-c-d1230"
            />
          </Field>
          {sessionCode && (
            <>
              <Spacer />
              <H3 marginBottom="s">
                Module List URLs:
                <br />
                <span style={{ fontWeight: 300, textTransform: 'initial' }}>
                  NOTE: Will only work with pre-determined test groups: <br />
                  {tempSessionUids.join(', ')}
                </span>
              </H3>
              <Field label="Cadet URL">
                <TextInput value={`${window.location.origin}/session/${sessionCode}`} readOnly />
              </Field>
              <Field label="Mentor URL">
                <TextInput value={`${window.location.origin}/session/mentor/${sessionCode}`} readOnly />
              </Field>
              <Field label="Facilitator URL">
                <TextInput value={`${window.location.origin}/session/facilitator/${sessionCode}`} readOnly />
              </Field>
              <Spacer size="s" />
              <Hr margin="m" />
              <H3 marginBottom="s">Manual Join URLs:</H3>
              <Field label="Cadet URL">
                <TextInput value={`${window.location.origin}/session/manual/${sessionCode}`} readOnly />
              </Field>
              <Field label="Mentor URL">
                <TextInput value={`${window.location.origin}/session/manual/mentor/${sessionCode}`} readOnly />
              </Field>
              <Field label="Facilitator URL">
                <TextInput value={`${window.location.origin}/session/facilitator/${sessionCode}`} readOnly />
              </Field>
            </>
          )}
        </Padding>
      </Panel>
    </Column>
  )
}
