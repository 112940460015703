import { Checkbox } from 'common/Checkbox'
import { CollapseMargin, H1, H2, Hr, P, Row, Spacer } from 'common/ui'
import { fontBold } from 'fonts'
import { AccordionRight } from 'session/common/Accordion'
import { CircleButton } from 'session/common/CircleButton'
import { ShowText } from 'session/sections/Grouped'
import { TodoListItem } from 'shared/session/types'
import styled from 'styled-components'
import { nl2br } from 'utils/intersperse'

export const TodoListItemRender = ({
  item,
  prevItem,
  nextItem,
  index,
  items,
  collapsed,
  printMode,
  onCollapseToggle,
}: {
  item: TodoListItem
  prevItem?: TodoListItem
  nextItem?: TodoListItem
  index: number
  items: TodoListItem[]
  collapsed?: boolean
  printMode?: boolean
  onCollapseToggle?: (uid: string) => void
}) => {
  return (
    <>
      {item.type === 'h1' && (
        <>
          <Spacer size="l" />
          {prevItem && prevItem.type === 'h1' && (
            <>
              <Hr />
              <Spacer size="s" />
            </>
          )}
          <Row justifyContent="space-between">
            <H1 style={{ fontFamily: 'eurostile, sans-serif' }}>{item.text}</H1>
            {onCollapseToggle && nextItem && nextItem.type !== 'h1' && (
              <>
                <Spacer />
                <AccordionRight onClick={() => onCollapseToggle(item.uid)}>
                  <ShowText>{collapsed ? 'SHOW' : 'HIDE'}</ShowText>
                  <CircleButton size="s" children={collapsed ? '▼' : '▲'} />
                </AccordionRight>
              </>
            )}
          </Row>
        </>
      )}
      {item.type === 'h2' && (
        <>
          <Spacer />
          {prevItem && (
            <>
              <Hr />
              <Spacer size="s" />
            </>
          )}
          <Row justifyContent="space-between">
            <ListHeading>{item.text}</ListHeading>
            {onCollapseToggle && nextItem?.type === 'li' && (
              <>
                <Spacer />
                <AccordionRight onClick={() => onCollapseToggle(item.uid)}>
                  <ShowText>{collapsed ? 'SHOW' : 'HIDE'}</ShowText>
                  <CircleButton size="s" children={collapsed ? '▼' : '▲'} />
                </AccordionRight>
              </>
            )}
          </Row>
        </>
      )}

      {item.type === 'li' && (
        <Row className="checklist-item">
          {printMode ? (
            <Checkbox disabled size="xs" checked={false} onChange={() => {}} />
          ) : (
            <img
              src={require('../../session/assets/list-bullet-parentGreen.svg')?.default}
              style={{ transform: 'translateY(calc(4px + 0.12em))' }}
              width={16}
              height={16}
              alt="•"
            />
          )}
          <Spacer />
          <CollapseMargin>
            <P style={{ paddingTop: 4 }}>{nl2br(item.text)}</P>
          </CollapseMargin>
        </Row>
      )}
      {!nextItem && (
        <>
          <Spacer />
          <Hr />
        </>
      )}
    </>
  )
}

export const ListHeading = styled(H2)`
  ${fontBold}
  font-size: 22px;
  text-transform: none;
`
