import React, { useRef, useReducer, useState, useContext } from 'react'
import { ActionTypes, UPDATE_STATE, UPDATE_FACMAN_PAGE } from 'shared/fac-man/actionTypes'
import { FacManData, FacManState, PreviewState, FacManStateHookObject } from 'shared/fac-man/types'

function reducer(state: FacManState, action: ActionTypes): FacManState {
  switch (action.type) {
    case UPDATE_STATE:
      return action.state

    /* Trigger these actions for perceived snappy performance */
    case UPDATE_FACMAN_PAGE:
      return {
        ...state,
        currentStepUid: action.stepUid,
      }
  }
}

const initialState: FacManState = {
  currentStepUid: null,
}

const getInitialState = (facManData: FacManData | false) => {
  const newState: FacManState = { ...initialState }
  return newState
}

function useProviderFacManState() {
  const [facManData, setFacManData] = useState<FacManData | false>(false)
  const [previewing, setPreviewing] = useState<boolean>(false)
  const [previewState, setPreviewState] = useState<PreviewState>({ stepIndex: 0 })
  const [state, dispatch] = useReducer(reducer, getInitialState(facManData))

  const persistentState = useRef(state)

  const preDispatch = (action: ActionTypes) => {
    console.log('DISPATCHING ACTION', action)
    persistentState.current = reducer(persistentState.current, action)
    dispatch(action)
  }

  return {
    state,
    facManData: facManData,
    setFacManData: setFacManData,
    dispatch: preDispatch,
    previewing,
    setPreviewing,
    previewState,
    setPreviewState,
  }
}

function noop(): any {}

export const FacManStateContext = React.createContext<FacManStateHookObject>({
  state: initialState,
  facManData: false,
  setFacManData: noop,
  dispatch: noop,
  previewing: false,
  setPreviewing: noop,
  previewState: { stepIndex: 0 },
  setPreviewState: noop,
})

export const FacManStateProvider: React.FC = ({ children }) => {
  const state = useProviderFacManState()
  return <FacManStateContext.Provider value={state}>{children}</FacManStateContext.Provider>
}

export function useFacManState() {
  return useContext(FacManStateContext)
}
