/* eslint-disable no-mixed-operators */

// Adapted to TS from http://www.webtoolkit.info/javascript-sha1.html#.XucBh2ozaFx

/**
 *
 *  Secure Hash Algorithm (SHA1)
 *  http://www.webtoolkit.info/
 *
 **/

const rotateLeft = (n: number, s: number): number => (n << s) | (n >>> (32 - s))

const cvtHex = (val: number): string => {
  let str = ''
  for (let i = 7; i >= 0; i--) str += ((val >>> (i * 4)) & 0x0f).toString(16)
  return str
}

const Utf8Encode = (string: string): string => {
  string = string.replace(/\r\n/g, '\n')
  let utfText = ''
  for (let n = 0; n < string.length; n++) {
    const c = string.charCodeAt(n)
    if (c < 128) {
      utfText += String.fromCharCode(c)
    } else if (c > 127 && c < 2048) {
      utfText += String.fromCharCode((c >> 6) | 192)
      utfText += String.fromCharCode((c & 63) | 128)
    } else {
      utfText += String.fromCharCode((c >> 12) | 224)
      utfText += String.fromCharCode(((c >> 6) & 63) | 128)
      utfText += String.fromCharCode((c & 63) | 128)
    }
  }
  return utfText
}

export function SHA1(msg: string) {
  const W = new Array(80)
  let H0: number = 0x67452301,
    H1: number = 0xefcdab89,
    H2: number = 0x98badcfe,
    H3: number = 0x10325476,
    H4: number = 0xc3d2e1f0
  let A: number, B: number, C: number, D: number, E: number

  msg = Utf8Encode(msg)

  const msgLen = msg.length

  const wordArray = []
  for (let i = 0; i < msgLen - 3; i += 4) {
    wordArray.push(
      (msg.charCodeAt(i) << 24) | (msg.charCodeAt(i + 1) << 16) | (msg.charCodeAt(i + 2) << 8) | msg.charCodeAt(i + 3)
    )
  }

  switch (msgLen % 4) {
    case 0:
      wordArray.push(0x080000000)
      break
    case 1:
      wordArray.push((msg.charCodeAt(msgLen - 1) << 24) | 0x0800000)
      break
    case 2:
      wordArray.push((msg.charCodeAt(msgLen - 2) << 24) | (msg.charCodeAt(msgLen - 1) << 16) | 0x08000)
      break
    case 3:
      wordArray.push(
        (msg.charCodeAt(msgLen - 3) << 24) |
          (msg.charCodeAt(msgLen - 2) << 16) |
          (msg.charCodeAt(msgLen - 1) << 8) |
          0x80
      )
      break
  }

  while (wordArray.length % 16 !== 14) wordArray.push(0)
  wordArray.push(msgLen >>> 29)
  wordArray.push((msgLen << 3) & 0x0ffffffff)

  for (let blockStart = 0; blockStart < wordArray.length; blockStart += 16) {
    for (let i = 0; i < 16; i++) W[i] = wordArray[blockStart + i]
    for (let i = 16; i <= 79; i++) W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1)

    A = H0
    B = H1
    C = H2
    D = H3
    E = H4

    for (let i = 0; i <= 19; i++) {
      const tmp = (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) & 0x0ffffffff
      E = D
      D = C
      C = rotateLeft(B, 30)
      B = A
      A = tmp
    }

    for (let i = 20; i <= 39; i++) {
      const tmp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) & 0x0ffffffff
      E = D
      D = C
      C = rotateLeft(B, 30)
      B = A
      A = tmp
    }

    for (let i = 40; i <= 59; i++) {
      const tmp = (rotateLeft(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8f1bbcdc) & 0x0ffffffff
      E = D
      D = C
      C = rotateLeft(B, 30)
      B = A
      A = tmp
    }

    for (let i = 60; i <= 79; i++) {
      const tmp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) & 0x0ffffffff
      E = D
      D = C
      C = rotateLeft(B, 30)
      B = A
      A = tmp
    }

    H0 = (H0 + A) & 0x0ffffffff
    H1 = (H1 + B) & 0x0ffffffff
    H2 = (H2 + C) & 0x0ffffffff
    H3 = (H3 + D) & 0x0ffffffff
    H4 = (H4 + E) & 0x0ffffffff
  }

  return (cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4)).toLowerCase()
}
