import {
  ActivityHelpfulThoughtsState,
  HelpfulThoughtsProfileState,
  HelpfulThoughtsThoughtCloud,
  SessionProfile,
  Thought,
} from '../../../types'
import { ActionTypes } from './actionTypes'
import { shuffle } from 'lodash'
import { THOUGHTS } from './thoughts'

// STATE REDUCER
export function stateReducer(
  sectionState: ActivityHelpfulThoughtsState,
  action: ActionTypes
): ActivityHelpfulThoughtsState {
  const currentSectionState = sectionState || getInitialSectionState([])
  let state = getInitialProfileState(THOUGHTS)
  if (action.type !== 'RESET_STATE' && action.type !== 'SELECT_ALL' && action.type !== 'TOGGLE_GROUP_PLAY') {
    state = currentSectionState.participants[action.participantUid] || getInitialProfileState(THOUGHTS)
  }
  switch (action.type) {
    case 'SET_SCREEN':
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: { ...state, screen: action.screen },
        },
      }
    case 'CHANGE_LEVEL': {
      const newCloud: HelpfulThoughtsThoughtCloud = currentSectionState.groupPlay
        ? state.clouds[state.currentCloud.index]
        : state.clouds[
            action.direction === 1
              ? (state.currentCloud.index + action.direction) % state.clouds.length
              : (state.currentCloud.index + state.clouds.length + action.direction) % state.clouds.length
          ]
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: {
            ...state,
            currentCloud: newCloud,
            screen: state.hud.score === state.clouds.length ? 'FINISHED' : state.screen,
            thoughtControls: {
              customOption: '',
              optionIndex: 0,
            },
            hud: {
              ...state.hud,
              targeted: true,
              showLazer: false,
            },
            clouds: state.clouds.map(cloudState =>
              cloudState.index === newCloud.index
                ? { ...cloudState, targeted: true }
                : { ...cloudState, targeted: false }
            ),
          },
        },
      }
    }
    case 'ZAP_CLOUD':
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: {
            ...state,
            clouds: state.clouds.map(cloudState =>
              cloudState.index === state.currentCloud.index
                ? {
                    ...cloudState,
                    answers: [...cloudState.answers, action.answer],
                    custom:
                      state.currentCloud.options[state.thoughtControls.optionIndex] === 'custom'
                        ? state.thoughtControls.customOption
                        : cloudState.custom,
                  }
                : cloudState
            ),
            hud: {
              ...state.hud,
              score: state.hud.score + (action.answer === true ? 1 : 0),
              showLazer: true,
              zapCounter: state.hud.zapCounter + 1,
              targeted: action.answer === true ? false : true,
            },
          },
        },
      }
    case 'WRONG_ANSWER':
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: { ...state, hud: { ...state.hud, showLazer: false } },
        },
      }
    case 'UPDATE_HUD':
      return {
        ...currentSectionState,
        participants: { ...currentSectionState.participants, [action.participantUid]: { ...state, hud: action.hud } },
      }
    case 'UPDATE_SCORE':
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: { ...state, hud: { ...state.hud, score: state.hud.score + 1 } },
        },
      }
    case 'CHANGE_OPTION': {
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: {
            ...state,
            thoughtControls: {
              ...state.thoughtControls,
              optionIndex:
                action.direction === 1
                  ? (state.thoughtControls.optionIndex + action.direction) % state.currentCloud.options.length
                  : (state.thoughtControls.optionIndex + state.currentCloud.options.length + action.direction) %
                    state.currentCloud.options.length,
            },
          },
        },
      }
    }
    case 'CHOOSE_OPTION':
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: {
            ...state,
            thoughtControls: {
              ...state.thoughtControls,
              optionIndex: action.optionIndex,
              customOption: action.clearCustom ? '' : state.thoughtControls.customOption,
            },
            clouds: state.clouds.map(cloudState =>
              cloudState.index === state.currentCloud.index
                ? {
                    ...cloudState,
                    custom: action.clearCustom ? null : cloudState.custom,
                  }
                : cloudState
            ),
          },
        },
      }
    case 'SET_CUSTOM_OPTION':
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: {
            ...state,
            thoughtControls: { ...state.thoughtControls, customOption: action.customOption },
            clouds: state.clouds.map(cloudState =>
              cloudState.index === state.currentCloud.index
                ? {
                    ...cloudState,
                    custom: action.customOption,
                  }
                : cloudState
            ),
          },
        },
      }
    case 'UPDATE_STATE':
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: { ...state, ...action.state },
        },
      }

    case 'RESET_STATE':
      return {
        groupPlay: false,
        participants: Object.keys(currentSectionState).reduce((acc, key) => {
          acc[key] = getInitialProfileState(THOUGHTS)
          return acc
        }, {} as ActivityHelpfulThoughtsState['participants']),
      }

    case 'SELECT_ALL':
      return {
        ...currentSectionState,
        participants: Object.keys(currentSectionState.participants).reduce((acc, key) => {
          const participantState = currentSectionState.participants[key] || getInitialProfileState(THOUGHTS)
          const newCloud: HelpfulThoughtsThoughtCloud = participantState.clouds[action.rowIndex]
          acc[key] = {
            ...participantState,
            currentCloud: newCloud,
            thoughtControls: { customOption: '', optionIndex: 0 },
            hud: { ...participantState.hud, targeted: true, showLazer: false },
            clouds: participantState.clouds.map(cloudState =>
              cloudState.index === newCloud.index
                ? { ...cloudState, targeted: true }
                : { ...cloudState, targeted: false }
            ),
          }
          return acc
        }, {} as ActivityHelpfulThoughtsState['participants']),
      }

    case 'TOGGLE_GROUP_PLAY':
      return {
        ...currentSectionState,
        groupPlay: !currentSectionState.groupPlay,
      }

    case 'FOCUS_THOUGHT':
      const newCloud: HelpfulThoughtsThoughtCloud = state.clouds[action.cloudIndex]
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: {
            ...state,
            currentCloud: newCloud,
            hud: {
              ...state.hud,
              targeted: true,
              showLazer: false,
            },
            thoughtControls: {
              optionIndex: 0,
              customOption: '',
            },
            clouds: state.clouds.map(cloudState =>
              cloudState.index === newCloud.index
                ? { ...cloudState, targeted: true }
                : { ...cloudState, targeted: false }
            ),
          },
        },
      }

    case 'APPROVE_CUSTOM_THOUGHT':
      return {
        ...currentSectionState,
        participants: {
          ...currentSectionState.participants,
          [action.participantUid]: {
            ...state,
            clouds: state.clouds.map((cloud, i) => {
              return action.cloudIndex !== i ? cloud : { ...cloud, approved: true }
            }),
          },
        },
      }

    default:
      return currentSectionState
  }
}

// INITIAL STATE
export const getInitialSectionState = (thoughts: Thought[], profile?: SessionProfile): ActivityHelpfulThoughtsState =>
  !profile
    ? {
        participants: {},
        groupPlay: false,
      }
    : {
        participants: {
          [profile.uid]: getInitialProfileState(thoughts),
        },
        groupPlay: false,
      }

export const getInitialProfileState = (thoughts: Thought[]): HelpfulThoughtsProfileState => {
  const clouds = seedThoughtClouds(thoughts)
  return {
    screen: 'WAITING',
    clouds,
    currentCloud: clouds[0],
    hud: {
      targeted: false,
      showLazer: false,
      zapCounter: 0,
      score: 0,
    },
    thoughtControls: {
      optionIndex: -1,
      customOption: '',
    },
  }
}

// SEED THOUGHTS
const seedThoughtClouds = (thoughts: Thought[]) => {
  const cloudAnswers = thoughts.map(({ answer }) => answer)

  return thoughts.map((cloud: { text: string; answer: string }, index) => {
    let optionsArray: string[] = []

    const min = Math.ceil(1)
    const max = Math.floor(cloudAnswers.length)

    while (optionsArray.length < 2) {
      let randomThoughtIndex = Math.floor(Math.random() * (max - min) + min)
      if (
        cloudAnswers[randomThoughtIndex] !== cloud.answer &&
        !optionsArray.includes(cloudAnswers[randomThoughtIndex])
      ) {
        optionsArray.push(cloudAnswers[randomThoughtIndex])
      }
    }

    return {
      index: index,
      targeted: false,
      answers: [],
      text: cloud.text,
      answer: cloud.answer,
      options: [...shuffle([...optionsArray, cloud.answer]), 'custom'],
      custom: null,
      approved: false,
    }
  })
}
