import React from 'react'
import { CollapseMargin, Center, Hr, P, Row, Spacer } from 'common/ui'
import { IntroTemplate } from 'home/IntroTemplate'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import { useMedia } from 'utils/useMedia'

export const MentorPage: React.FC = () => {
  const compact = useMedia('(max-width: 500px)')

  return (
    <IntroTemplate title="SAS Mentor Resources" plainLogo>
      <CollapseMargin>
        <Center>
          <LinkButton to="/mentor/login">Mentor Login</LinkButton>
        </Center>
        <Hr margin />
        <Center>
          <P align="center">Skill Tracker access when using physical SAS Family Kit</P>
        </Center>
      </CollapseMargin>
      <Hr margin />
      <Row style={{ flexDirection: compact ? 'column' : undefined }}>
        <LinkButton to="/" size="s">
          Back to SAS Digital HQ
        </LinkButton>
        <Spacer />
        <AnchorButton href="http://www.secretagentsociety.com" flex="1 1 0px" size="s">
          What is SAS?
        </AnchorButton>
      </Row>
    </IntroTemplate>
  )
}
