import { ProgressionEvent, gadgetPackKeys, GadgetPackKey, unlockableRelaxationGadgets } from './session/types'
import { codeCardIds, emotionometerDevices, EmotionometerType } from './e-telligence/types'

export const GADGET_PACK_UNLOCK = 'GADGET_PACK_UNLOCK'
export const MODULE_EVENT = 'MODULE_EVENT'
export const TIPSHEET_CONFIRM_READ = 'TIPSHEET_CONFIRM_READ'
export const UNLOCK_DASHBOARD_NAV_ITEM = 'UNLOCK_DASHBOARD_NAV_ITEM'

export type ProgressEventType =
  | typeof GADGET_PACK_UNLOCK
  | typeof MODULE_EVENT
  | typeof TIPSHEET_CONFIRM_READ
  | typeof UNLOCK_DASHBOARD_NAV_ITEM

interface PrerequisiteEvent<ValueType extends any> {
  context: Partial<Pick<ProgressionEvent<ValueType>, 'type' | 'category' | 'subcategory' | 'event_key' | 'event_value'>>
  events: Exclude<this['context'], 'type' | 'category'>[]
}

const removeUndefinedValues = <ObjType extends object>(obj: ObjType): ObjType => {
  // @ts-ignore
  // prettier-ignore
  return Object.keys(obj).reduce((newObj, key) => (obj[key] !== undefined ? { ...newObj, [key]: obj[key] } : newObj), {})
}

const getGadgetPackCategoryAndSubcategory = (gadgetPackKey: GadgetPackKey) => {
  const subcategorySplit = gadgetPackKey.split('/')
  return { subcategory: subcategorySplit.length > 1 ? subcategorySplit[1] : null, category: subcategorySplit[0] }
}

const sessionEmotionometerEmotions: EmotionometerType[] = ['anger', 'anxiety']

export const prerequisiteEvents: PrerequisiteEvent<any>[] = [
  /** Gadget pack activity orders */
  ...gadgetPackKeys.reduce((arr, gadgetPackKey, index) => {
    const { category, subcategory } = getGadgetPackCategoryAndSubcategory(gadgetPackKey)
    if (index === 0) return arr
    const priorValues = getGadgetPackCategoryAndSubcategory(gadgetPackKeys[index - 1])
    return [
      ...arr,
      {
        context: removeUndefinedValues({
          type: GADGET_PACK_UNLOCK,
          category,
          subcategory,
          event_key: null,
          event_value: null,
        }),
        events: [removeUndefinedValues({ ...priorValues, event_key: null, event_value: null })],
      },
    ]
  }, [] as PrerequisiteEvent<any>[]),

  /** Emotionometer device orders */
  ...emotionometerDevices.reduce(
    (arr, emotionometerDevice, deviceIndex) => [
      ...arr,
      ...sessionEmotionometerEmotions.reduce(
        (arr2, subcategory, emotionIndex) => [
          ...arr2,
          {
            context: {
              type: GADGET_PACK_UNLOCK,
              category: 'emotionometer',
              subcategory,
              event_key: 'device',
              event_value: emotionometerDevice,
            },
            events:
              deviceIndex === 0
                ? [{ event_key: null, event_value: null }]
                : [{ event_value: emotionometerDevices[deviceIndex - 1] }],
          },
        ],
        [] as PrerequisiteEvent<any>[]
      ),
    ],
    [] as PrerequisiteEvent<any>[]
  ),

  /** Code card orders */
  ...codeCardIds.reduce(
    (arr, codeCardId, index) =>
      index === 0
        ? arr
        : [
            ...arr,
            {
              context: {
                type: GADGET_PACK_UNLOCK,
                category: 'code-cards' as GadgetPackKey,
                subcategory: codeCardId,
                event_key: null,
                event_value: null,
              },
              events: [{ subcategory: codeCardIds[index - 1] }],
            },
          ],
    [] as PrerequisiteEvent<any>[]
  ),

  ...unlockableRelaxationGadgets.reduce(
    (arr, relaxationGadgetId, index) =>
      index === 0
        ? arr
        : [
            ...arr,
            {
              context: {
                type: GADGET_PACK_UNLOCK,
                category: 'relaxation-gadgets',
                subcategory: relaxationGadgetId,
                event_key: null,
                event_value: null,
              },
              events: [{ subcategory: unlockableRelaxationGadgets[index - 1] }],
            },
          ],
    [] as PrerequisiteEvent<any>[]
  ),
]

export const objShapeMatches = <ValueType extends object>(
  testShape: Partial<ValueType>,
  objectValue: ValueType
): boolean => {
  for (let key of Object.keys(testShape) as (keyof typeof testShape)[]) {
    const testValue = testShape[key]
    if (testValue !== undefined) {
      if (!(key in objectValue)) return false
      if (objectValue[key] !== testValue) return false
    }
  }
  return true
}

const generateProgressionEventsFromPrerequisite = <ValueType extends any>({
  context,
  events,
}: PrerequisiteEvent<ValueType>): ProgressionEvent<any>[] => {
  return events.map(
    eventChanges =>
      ({
        ...context,
        ...eventChanges,
      } as ProgressionEvent<any>)
  )
}

export const getProgressionEventPrerequisites = <ValueType extends any>(
  progressionEvent: Omit<ProgressionEvent<ValueType>, 'participant_uid'>
): ProgressionEvent<any>[] => {
  const prereqEvents = prerequisiteEvents.reduce((arr, prereqEvent, index) => {
    if (objShapeMatches(prereqEvent.context, progressionEvent)) {
      const newEvents = generateProgressionEventsFromPrerequisite(prereqEvent)
      if (newEvents.length > 0) return [...arr, ...newEvents]
    }
    return arr
  }, [] as ProgressionEvent<any>[])
  return prereqEvents.reduce(
    (arr, prereqEvent) => [...arr, ...getProgressionEventPrerequisites(prereqEvent)],
    prereqEvents
  )
}

export const progressionEventExists = <ValueType extends any>(
  progressionEvent: ProgressionEvent<ValueType>,
  progressionEvents: ProgressionEvent<any>[]
): boolean => {
  for (let testProgressionEvent of progressionEvents) {
    if (objShapeMatches(progressionEvent, testProgressionEvent)) return true
  }
  return false
}
