import React, { useState, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { useHistory } from 'react-router-dom'

import { Row, Column, Spacer, Button, Panel, Padding } from 'common/ui'
import { TextSymbolInput } from 'common/TextSymbolInput'
import { HeaderTemplate } from './common/HeaderTemplate'

import { FriendshipFormulaState } from 'shared/gadget-pack/types'
import { UPDATE_FRIENDSHIP_FORMULA } from 'shared/gadget-pack/actionTypes'
import { baseUrl } from './GadgetPack'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { useUserState } from 'app/UserState'
import { useGadgetPackState } from './GadgetPackState'
import { InputLockProps } from 'session/common/InputLockComponent'
import { friendshipFormulaEmojis } from 'constants/emojiSets'

const colors: [string, string][] = [
  ['#DBE61E', '#B5C923'],
  ['#E8317E', '#C92369'],
  ['#13F29C', '#33BA77'],
  ['#FF8300', '#FF6C00'],
  ['#A556DF', '#8323C9'],
]

export const emojis = friendshipFormulaEmojis

// TODO: Add a load screen for previously saved formulae

export const FriendshipFormulaScreen: React.FC<{
  hideExitButton?: boolean
  hideTitle?: boolean
  overrideExit?: () => void
  readonly?: boolean
  inputOnClick?: (index: number) => void
  inputLockProps?: Omit<InputLockProps, 'children'>
}> = ({ hideExitButton, hideTitle, overrideExit, readonly, inputOnClick, inputLockProps = {} }) => {
  const { getBaseAction } = useUserState()
  const {
    state: {
      friendshipFormula: { formulae },
    },
    dispatch,
  } = useGadgetPackState()
  const history = useHistory()
  const [shake, setShake] = useState<boolean>(false)
  const shakeItUp = () => {
    if (!shake) {
      setShake(true)
      setTimeout(() => setShake(false), 1000)
    }
  }
  const segments = Object.values(formulae)
    .map((val, index) => ({ ...val, index }))
    .filter(({ text }) => !!text)
  return (
    <HeaderTemplate
      title={hideTitle ? '' : 'Friendship Formula'}
      onExit={() => (overrideExit ? overrideExit() : history.push(baseUrl))}
      hideExitButton={hideExitButton}>
      <Panel className="activity-panel">
        <Padding size="m" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <ContainerRow className="friendship-container" alignItems="center">
            <Column className="friendship-flask">
              <Flask shake={shake} segments={segments} />
            </Column>
            <Spacer size="l" />
            <Column className="friendship-input-block">
              {intersperseSpacers(
                [...Array(5)].map((_, index) => (
                  <Row key={index}>
                    <Column flex="none">
                      <Bubble index={index} />
                    </Column>
                    <Spacer size="s" />
                    <Row className="friendship-input-row" flex="1 1 auto" alignItems="center">
                      <TextSymbolInput
                        debounce
                        readonly={readonly}
                        symbols={emojis}
                        value={formulae[index] || {}}
                        onClick={inputOnClick ? () => inputOnClick(index) : undefined}
                        onChange={(data) =>
                          dispatch({ ...getBaseAction(), type: UPDATE_FRIENDSHIP_FORMULA, index, data })
                        }
                        fieldProps={{ style: { maxWidth: '100%' }, disabled: readonly || false }}
                        inputProps={{ inputSize: 's', disabled: readonly || index > segments.length }}
                        symbolInputProps={{ disabled: readonly || false }}
                        inputLockProps={
                          inputLockProps
                            ? {
                                hasLabel: true,
                                verticalPlacementFacilitator: -24,
                                horizontalPlacementFacilitator: 62,
                                verticalPlacementCadet: -26,
                                horizontalPlacementCadet: 62,
                                ...inputLockProps,
                                fieldUid: `${inputLockProps.fieldUid}_${index}`,
                              }
                            : undefined
                        }
                      />
                    </Row>
                  </Row>
                )),
                'm'
              )}
              <Spacer size="m" />
              <Button className="friendship-button" size="s" onClick={shakeItUp} children="Shake!" />
            </Column>
          </ContainerRow>
        </Padding>
      </Panel>
    </HeaderTemplate>
  )
}

const Flask: React.FC<{ shake?: boolean; segments: (FriendshipFormulaState['formulae'][0] & { index: number })[] }> = ({
  shake,
  segments,
}) => {
  const [shakeIndex, setShakeIndex] = useState<number>(0)
  useEffect(() => {
    if (shake) setShakeIndex(Math.floor(Math.random() * 5))
  }, [shake])
  return (
    <>
      <FlaskContainer shake={shake}>
        <img src={require('./assets/gadgets_friendshipFormula_flask.svg')?.default} alt="" />
        <LiquidContainer>
          {segments.map((segment, i) => (
            <LiquidBlock key={i} index={segment.index} shake={shake} shakeIndex={shakeIndex} />
          ))}
        </LiquidContainer>
        <BubblesContainer segments={segments.length}>
          {[...Array(3)].map((_, i) => (
            <img key={i} src={require('./assets/gadgets_friendshipFormula_bubbles.svg')?.default} alt="" />
          ))}
        </BubblesContainer>
        <FlaskDecalImg src={require('./assets/gadgets_friendshipFormula_text.png')} alt="SAS Friendship Formula" />
        <img src={require('./assets/gadgets_friendshipFormula_flaskShine.svg')?.default} alt="" />
      </FlaskContainer>
    </>
  )
}

const bubbleRise = keyframes`
  from { 
    transform: translateY(0);
  }
  to { 
    transform: translateY(-100%);
  }
`

const shake = keyframes`
  10%, 90% {
    transform: rotateZ(-1deg);
  }
  
  20%, 80% {
    transform: rotateZ(2deg);
  }

  30%, 50%, 70% {
    transform: rotateZ(-4deg);
  }

  40%, 60% {
    transform: rotateZ(4deg);
  }
`

const shakeShadow = keyframes`
  10%, 90% {
    box-shadow: inset 8px 0 0 0 rgba(255,0,255,0.5);
  }
  
  20%, 80% {
    box-shadow: inset -16px 0 0 0 rgba(0,255,255,0.5);
  }

  30%, 50%, 70% {
    box-shadow: inset 32px 0 0 0 rgba(255,255,0,0.5);
  }

  40%, 60% {
    box-shadow: inset -32px 0 0 0 rgba(255,0,0,0.5);
  }
`

const liquidBlockFill = keyframes`
  from { 
    height: 0%;
  }
  to { 
    height: 20%;
  }
`

const ContainerRow = styled(Row)`
  @media (min-height: 420px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (orientation: portrait) and (max-width: 640px) {
    & > ${Column} {
      &:first-child {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &:last-child {
        min-height: 440px;
        justify-content: center;
        max-width: 100%;
      }
      & input:disabled,
      & input:disabled + ${Spacer} + input {
        opacity: 1;
        background-color: #eee !important;
      }
    }
    & > ${Spacer} {
      display: none;
    }
  }
`

const FlaskMask = styled.div`
  position: absolute;
  top: 2%;
  left: 8.5%;
  right: 7.5%;
  bottom: 3%;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  overflow: hidden;
`

const BubblesContainer = styled(FlaskMask)<{ segments: number }>`
  top: initial;
  height: ${(p) => ((100 - 5) / 5) * p.segments}%;
  transition: height 0.75s ease-in-out;
  & > img {
    width: 100%;
    height: auto;
    animation: ${bubbleRise} 3s linear infinite normal forwards;
    &:nth-child(2) {
      animation-timing-function: cubic-bezier(0.45, 0.125, 0.65, 0.825);
    }
  }
`

const LiquidBlock = styled.div<{ index: number; shake?: boolean; shakeIndex?: number }>`
  position: relative;
  width: 100%;
  height: 20%;
  animation: ${liquidBlockFill} 0.75s ease-in-out 1 normal forwards;
  background: linear-gradient(
    90deg,
    ${(p) => colors[p.index][1]} 0%,
    ${(p) => colors[p.index][0]} 50%,
    ${(p) => colors[p.index][1]} 100%
  );

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: ${(p) => (p.shake ? 1 : 0)};
    transition: opacity 0.5s linear;
    ${(p) =>
      p.shakeIndex !== undefined
        ? `background: linear-gradient(90deg,${colors[p.shakeIndex][1]} 0%, ${colors[p.shakeIndex][0]} 50%, ${
            colors[p.shakeIndex][1]
          } 100%);`
        : ''}
  }
`

const LiquidContainer = styled(FlaskMask)`
  display: flex;
  flex-direction: column-reverse;
  & ${LiquidBlock} {
  }
`

const Bubble = styled.div<{ index: number }>`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: radial-gradient(circle at 65% 35%, ${(p) => colors[p.index][0]}, ${(p) => colors[p.index][1]});
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  &::after {
    content: '';
    position: absolute;
    top: 17%;
    right: 11%;
    width: 42%;
    height: 15%;
    border-radius: 100%;
    background-color: white;
    opacity: 0.32;
    transform-origin: 50% 0;
    transform: rotateZ(29deg);
  }
`

const FlaskDecalImg = styled.img`
  left: 50% !important;
  top: 5% !important;
  height: 85%;
  width: auto;
  transform: translateX(-50%);
  opacity: 0.5;
`

const FlaskContainer = styled.div<{ shake?: boolean }>`
  position: relative;
  width: 109px;
  padding-top: 453.21%;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  ${(p) =>
    p.shake
      ? css`
          animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 1 normal both;
        `
      : ''}
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  & ${FlaskMask} {
    ${(p) =>
      p.shake
        ? css`
            animation: ${shakeShadow} 1.1s cubic-bezier(0.36, 0.07, 0.19, 0.97) 1 normal both;
          `
        : ''}
  }
`
