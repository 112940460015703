import { getPastSharedSessionInputValues } from 'api'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { useUserState } from 'app/UserState'
import { ConfirmModal } from 'common/ConfirmModal'
import { Button, buttonFlash, Column, H1, P, Panel, Row, Spacer } from 'common/ui'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import ReactDOM from 'react-dom'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { InputContext, useInputInterface, useSavedInputValueObjects } from 'session/InputContext'
import { useSessionState } from 'session/SessionState'
import {
  ActionTypes,
  CLEAR_ENCOUNTER,
  CREATE_PLAYER,
  DISCARD_DECK_CARD,
  MOVE_PLAYER,
  MOVE_TO_DECK_TILE,
  MOVE_TO_ENCOUNTER,
  NEXT_GAME_PHASE,
  PREVIOUS_DECK_CARD,
  REORDER_PLAYER,
  ROLL_DICE,
  SET_ENCOUNTER,
  SET_FINISHED_PLAYER,
  SET_SELECTED_POSTER,
  SKIP_DECK_CARD,
  SORT_PLAYERS,
  TOGGLE_POSTERS,
  TOGGLE_SHARE_CARD,
  TOGGLE_SUPER_DICE,
  UPDATE_ACTIVE_DECK,
  UPDATE_PLAYER_TURN,
} from 'shared/session/sections/custom/board-game/actionTypes'
import { decks } from 'shared/session/sections/custom/board-game/decks'
import { getInitialState, getPlayerKeyByIndex, reducer } from 'shared/session/sections/custom/board-game/reducer'
import { getTileRange, tiles } from 'shared/session/sections/custom/board-game/tiles'
import {
  ActivityBoardGameState,
  BoardGameCharacterId as CharacterId,
  BoardGameDeckType as DeckType,
  BoardGameTileDefinition as TileDefinition,
  BoardGameTileKey as TileKey,
  boardGameTileKeys as tileKeys,
  BOARD_GAME_BLANK,
  BOARD_GAME_ENCOUNTER,
  DiceValue,
  MediaAsset,
  ParticipantState,
  SectionPropsBase,
} from 'shared/session/types'
import styled, { ThemeProvider } from 'styled-components'
import { blue } from 'themes'
import { preloadImage } from 'utils/preloadUtils'
import { getTouchBackendOptions } from 'utils/touchUtils'
import { images } from './assets'
import { CharacterAvatarDragItem } from './CharacterAvatar'
import { CongratulationsModal } from './CongratulationsModal'
import { CustomDragLayer } from './CustomDragLayer'
import { DroppableFinishArea, StartArea } from './CustomTiles'
import { DeckCardModal } from './DeckCardModal'
import { BoardDice } from './Dice'
import { EditPlayerModal } from './EditPlayerModal'
import { EncounterModal } from './EncounterModal'
import { encounters } from './encounters'
import { DraggablePlayerSlot } from './PlayerSlot'
import { DroppableTile, TileWrapper } from './Tile'
import { TilePieceArea } from './TilePieceArea'

interface Props extends Omit<SectionPropsBase, 'panel' | 'index'> {
  property: 'embedded_activity_sections'
}

interface SectionProps {
  section: Props['section']
  property: Props['property']
  trainingState?: ParticipantState
}

export const BoardGame: React.FC<SectionProps> = ({ section, trainingState }) => {
  const { state } = useSessionState()

  return (
    <DndProvider backend={TouchBackend} options={getTouchBackendOptions()}>
      <ThemeProvider theme={blue}>
        <InputContext.Provider
          value={{
            session_uid: trainingState ? `Training_${trainingState.profile.uid}` : state.sessionUid,
            participant_uid: 'shared',
            module_id: 0,
            owner: trainingState ? 'training_board_game' : 'board_game',
            owner_id: null,
            name: trainingState ? 'training_board_game_state' : 'board_game_state',
          }}>
          <BoardGameApp section={section} trainingState={trainingState} />
        </InputContext.Provider>
      </ThemeProvider>
    </DndProvider>
  )
}

const BoardGameApp: React.FC<{ section: Props['section']; trainingState?: ParticipantState }> = ({ trainingState }) => {
  const boardRef = useRef<HTMLDivElement | null>(null)
  const [boardWidth, setBoardWidth] = useState<number>(0)
  const tileRefs = useRef(tileKeys.map(() => createRef<HTMLDivElement>()))
  const [forcedRender, forceRender] = useState(0)

  // GLOBAL STATE
  const { accessToken } = useUserState()
  const { state, isFacilitator, pastMode } = useSessionState()
  const focusedParticipantState = useFocusedParticipantState()
  const participantState = trainingState ? trainingState : focusedParticipantState
  const showForFacilitator = trainingState ? true : isFacilitator
  const existingSavedSectionStates = useSavedInputValueObjects<ActivityBoardGameState>()
  const [sectionState, onValueChange] = useInputInterface<ActivityBoardGameState>({
    name: 'board_game_state',
    defaultValue: getInitialState(),
  })

  // Past board game data loading
  useEffect(() => {
    if (showForFacilitator && !existingSavedSectionStates.length && isEqual(sectionState, getInitialState())) {
      console.log('🥺 Gotta go get past shared data')
      getPastSharedSessionInputValues<ActivityBoardGameState>(
        { session_uid: state.sessionUid, owner: 'board_game', name: 'board_game_state' },
        accessToken
      )
        .then(data => {
          console.log('🥺 Got past shared data', data)
          if (data.length > 0) onValueChange(data[0].value)
        })
        .catch(e => {
          console.log('🥺 Failed to get past shared data', e)
          // TODO: Sentry alert
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSectionState = (reducerAction: ActionTypes) => onValueChange(reducer(sectionState, reducerAction))
  const {
    players,
    activePlayerIndex,
    gameStep,
    moveStep,
    activeDeck,
    deckCardOrders,
    deckCardIndexes,
    currentDeckCard,
    encounter,
    dice,
    finishedPlayerUid,
    superDice,
  } = sectionState

  // LOCAL STATE
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showPlayerSelectConfirm, setShowPlayerSelectConfirm] = useState<boolean>(false)
  const [showSaved, setShowSaved] = useState<boolean>(false)
  const [showDeckCard, setShowDeckCard] = useState<boolean>(false)
  const [targetTile, setTargetTile] = useState<TileKey | null>(null)
  const [allowDiceAnimation, setAllowDiceAnimation] = useState<boolean>(true)
  const [selectedCharacters, setSelectedCharacters] = useState<CharacterId[]>(
    Object.values(players).map(({ characterId }) => characterId as CharacterId)
  )
  const [disablePhaseBtn, setDisablePhaseBtn] = useState<boolean>(false)

  // check if player exists -- if not show select player modal
  const playerCreated: boolean = participantState && !!players[participantState.profile.uid]
  const playerCharacter =
    participantState && players[participantState.profile.uid] ? players[participantState.profile.uid].characterId : null

  const activePlayer = find(players, player => player.index === activePlayerIndex)
  const activePlayerUid = Object.keys(players)[activePlayerIndex]

  const isInteractive =
    (!pastMode && showForFacilitator) ||
    (!pastMode &&
      participantState &&
      players[participantState.profile.uid] &&
      players[participantState.profile.uid].index === activePlayerIndex &&
      gameStep === 'gameplay')

  const userUidStillInGroup = (uid: string): boolean => {
    return !!state.participants.find(({ profile }) => profile.uid === uid)
  }

  const checkUserOnline = (uid: string): boolean => {
    const user = find(state.participants, participant => participant.profile.uid === uid)
    return !user || user.status === 'offline' ? false : true
  }

  const tileAreas = Object.keys(players)
    .filter(userUidStillInGroup)
    .reduce(
      (obj, playerUid, index) => ({
        ...obj,
        [players[playerUid].currentTile]: [...(obj[players[playerUid].currentTile] || []), playerUid],
      }),
      {} as { [key in TileKey]: string[] }
    )

  const currentDeckIndex =
    deckCardOrders[activeDeck || 'BULLYING_AND_TEASING'][deckCardIndexes[activeDeck || 'BULLYING_AND_TEASING']]
  const currentDeckIndexes = deckCardOrders[activeDeck || 'BULLYING_AND_TEASING']

  useEffect(() => {
    Promise.all(
      Object.keys(images).map(key => {
        const elem = images[key as keyof typeof images]
        if (typeof elem === 'string') {
          return preloadImage(elem)
        } else {
          // it is most likely a media asset
          const imagePath = `${elem.filename}.${elem.extension}`
          return preloadImage(require(`${imagePath}`))
        }
      })
    ).catch(() => {})
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () =>
      setBoardWidth(boardRef.current ? boardRef.current.getBoundingClientRect().width : 0)
    )
  }, [])

  useEffect(() => {
    setBoardWidth(boardRef.current ? boardRef.current.getBoundingClientRect().width : 0)
  }, [boardRef])

  // Force re-render after initial render because UI that relies on refs have to display
  useEffect(() => forceRender(i => i + 1), [])
  useEffect(() => {
    if (boardRef.current) setBoardWidth(boardRef.current.getBoundingClientRect().width)
  }, [forcedRender])

  useEffect(() => {
    const target = getTargetTile()
    if (moveStep === 'move') {
      if (target === 43) {
        setTimeout(() => {
          setTargetTile('FINISH')
        }, 2500)
      } else if (target > 43) {
        setTargetTile(null)
        updateSectionState({ type: UPDATE_PLAYER_TURN })
      } else {
        setTimeout(() => {
          setTargetTile(target)
        }, 2500)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dice.increment])

  useEffect(() => {
    if (moveStep !== 'move') setTargetTile(null)
  }, [moveStep])

  useEffect(() => {
    setShowDeckCard(!!currentDeckCard ? true : false)
  }, [currentDeckCard])

  useEffect(() => {
    if (gameStep === 'generate') {
      setTimeout(() => {
        updateSectionState({ type: SORT_PLAYERS })
        setAllowDiceAnimation(false)
        setDisablePhaseBtn(false)
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStep])

  useEffect(() => {
    // Note: we don't filter out users who aren't in the group anymore here because we want to still reserve their avatar in case they one day return
    setSelectedCharacters(Object.values(players).map(({ characterId }) => characterId as CharacterId))
  }, [players])

  // Extra checks when player turn index is appended
  useEffect(() => {
    const playerKey = getPlayerKeyByIndex(sectionState, activePlayerIndex)
    /**
     * If the player who's turn it is now is either
     * - already at the finish lin
     * - has been removed from the group
     * then immediately skip to the player after them
     */
    if (
      playerKey &&
      gameStep !== 'end' &&
      (players[playerKey].currentTile === 'FINISH' || !userUidStillInGroup(playerKey))
    ) {
      updateSectionState({ type: UPDATE_PLAYER_TURN })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlayerIndex])

  const handlePlayerReorder = (currentIndex: number, newIndex: number) => {
    updateSectionState({ type: REORDER_PLAYER, currentIndex, newIndex })
  }

  const handleRoll = () => {
    const value = (Math.floor(Math.random() * 6) + 1) as DiceValue
    updateSectionState({ type: ROLL_DICE, diceIndex: value })
  }

  const handleDeckDraw = (deckType: DeckType) => {
    const index = deckCardOrders[deckType][deckCardIndexes[deckType]]
    const deckCard = decks[deckType].cards[index]
    updateSectionState({ type: UPDATE_ACTIVE_DECK, deckType, deckCard })
  }

  const handleDeckCardNext = () => {
    updateSectionState({ type: SKIP_DECK_CARD })
  }

  const handleDeckCardPrevious = () => {
    updateSectionState({ type: PREVIOUS_DECK_CARD })
  }

  const handleDeckCardClose = () => {
    if (activeDeck) {
      updateSectionState({ type: DISCARD_DECK_CARD, deckType: activeDeck })
    }
  }

  const handlePosterClick = () => {
    updateSectionState({ type: TOGGLE_POSTERS })
  }

  const handlePosterSelect = (id: 0 | 1 | 2 | 3 | null) => {
    updateSectionState({ type: SET_SELECTED_POSTER, posterID: id })
  }

  const handleCardShare = () => {
    updateSectionState({ type: TOGGLE_SHARE_CARD })
  }

  const handleActivateEncounter = (tile: TileDefinition, playerUid?: string) => {
    if (!tile.encounterId || encounter !== null) return
    const currentEncounter = encounters[tile.encounterId]
    if (currentEncounter) {
      if (playerUid !== undefined) {
        updateSectionState({
          type: MOVE_TO_ENCOUNTER,
          playerUid,
          tileKey: tile.index,
          encounter: currentEncounter,
        })
      } else {
        updateSectionState({ type: SET_ENCOUNTER, encounter: currentEncounter })
      }
    }
  }

  const handleEncounterClose = () => {
    if (moveStep === 'discard') {
      if (encounter?.moveToTileIndex) {
        updateSectionState({
          type: MOVE_PLAYER,
          playerUid: activePlayerUid,
          tileKey: encounter.moveToTileIndex,
          incrementTurn: true,
        })
      } else {
        updateSectionState({ type: UPDATE_PLAYER_TURN })
      }
    } else {
      updateSectionState({ type: CLEAR_ENCOUNTER })
    }
  }

  const handleDismissFinishPlayer = () => {
    updateSectionState({ type: SET_FINISHED_PLAYER, playerUid: null })
    let gameOver = true
    Object.keys(players).forEach(playerKey => {
      if (players[playerKey].currentTile !== 'FINISH') gameOver = false
    })
    if (gameOver) {
      updateSectionState({ type: NEXT_GAME_PHASE, phase: 'end', moveStep: 'setup' })
    }
  }

  const handleDropOnTile = (tileKey: TileKey, { playerUid }: CharacterAvatarDragItem) => {
    if (tileKey === 'FINISH' && (moveStep === 'move' || showForFacilitator)) {
      return updateSectionState({ type: MOVE_PLAYER, playerUid, tileKey })
    }

    const tile = tiles.find(({ index }) => index === tileKey)

    if (!tile) return
    if (showForFacilitator && moveStep !== 'move') {
      return updateSectionState({ type: MOVE_PLAYER, playerUid, tileKey })
    } else if (tile.type === BOARD_GAME_BLANK) {
      if (moveStep === 'move') return updateSectionState({ type: MOVE_PLAYER, playerUid, tileKey, incrementTurn: true })
    } else if (tile.type === BOARD_GAME_ENCOUNTER) {
      return handleActivateEncounter(tile, playerUid)
    } else {
      if (moveStep === 'move')
        return updateSectionState({ type: MOVE_TO_DECK_TILE, playerUid, tileKey, deckType: tile.type })
    }
  }

  const handleGenerateOrder = (confirmed: boolean) => {
    setShowPlayerSelectConfirm(false)

    if (confirmed) {
      handlePhaseClick()
    }
  }

  const handlePhaseClick = () => {
    switch (gameStep) {
      case 'ready':
        setDisablePhaseBtn(true)
        setAllowDiceAnimation(true)
        updateSectionState({ type: NEXT_GAME_PHASE, phase: 'generate' })
        break
      case 'generate':
        updateSectionState({ type: NEXT_GAME_PHASE, phase: 'gameplay', moveStep: 'roll' })
        break
      case 'gameplay':
        updateSectionState({ type: NEXT_GAME_PHASE, phase: 'end', moveStep: 'setup' })
        break
      default:
        updateSectionState({ type: NEXT_GAME_PHASE, phase: 'ready' })
    }
  }

  const handleCharacterSelected = (characterId: CharacterId, customEmoji?: string) => {
    if (!participantState) return
    const name = participantState.profile.displayName.split(' ')
    const displayName = name.length > 1 ? `${name[0]} ${name[1].charAt(0)}` : name[0]

    updateSectionState({
      type: CREATE_PLAYER,
      uid: participantState.profile.uid,
      player: {
        initialised: true,
        index: Object.keys(players).length,
        name: displayName,
        characterId: characterId,
        currentTile: 'START' as TileKey,
        customEmoji: customEmoji || null,
      },
    })
  }

  const resetGame = (confirmed: boolean) => {
    setShowConfirm(false)
    if (confirmed) {
      ReactDOM.unstable_batchedUpdates(() => {
        onValueChange(getInitialState())
        setShowDeckCard(false)
        setAllowDiceAnimation(true)
        setTargetTile(null)
        setAllowDiceAnimation(true)
      })
    } else {
      setShowSaved(true)
    }
  }

  const getTargetTile = () => {
    if (activePlayer) {
      if (activePlayer.currentTile === 'START') {
        return dice.value
      } else if (activePlayer.currentTile === 'FINISH') {
        return 0
      } else {
        const tile = tiles.find(({ index }) => index === activePlayer.currentTile)
        const a = tile !== undefined ? tile.tileNumber : 0
        return a + dice.value
      }
    }
    return 0
  }

  const skipPlayer = () => {
    updateSectionState({ type: UPDATE_PLAYER_TURN })
  }

  const toggleSuperDice = () => {
    updateSectionState({ type: TOGGLE_SUPER_DICE })
  }

  const showStartControls = gameStep === 'ready' || gameStep === 'generate'

  return (
    <>
      <GameContainer isInteractive={isInteractive}>
        <Column flex="auto" style={{ maxWidth: '100%' }} alignItems="center" justifyContent="center">
          {showForFacilitator && (
            <>
              <ActivityInteractionIndicator type="shared" style={{ width: '100%' }} />
              <Spacer size="s" />
            </>
          )}
          <Board ref={boardRef} isFacilitator={showForFacilitator} width={boardWidth}>
            <CustomDragLayer />
            <BoardArrows />
            <BoardTitle>
              <Row justifyContent="space-between">
                <H1>Challenger Board Game</H1>
                {showForFacilitator && (
                  <>
                    <Column alignItems="flex-end" style={{ marginBottom: 25, marginLeft: 10 }}>
                      <Panel padding={[10, 10]}>
                        <Row justifyContent="center">
                          {showStartControls && (
                            <>
                              <Button
                                size="xs"
                                children="Generate Play Order"
                                onClick={() => {
                                  setShowPlayerSelectConfirm(true)
                                }}
                                disabled={gameStep !== 'ready' || disablePhaseBtn}
                              />
                              <Spacer />
                              <Button
                                size="xs"
                                children="Begin Game"
                                onClick={handlePhaseClick}
                                disabled={gameStep !== 'generate' || disablePhaseBtn}
                              />
                            </>
                          )}
                          {!showStartControls && (
                            <>
                              <Button
                                size="xs"
                                children="Skip Turn"
                                onClick={skipPlayer}
                                disabled={gameStep !== 'gameplay'}
                              />
                              <Spacer />
                              <Button
                                size="xs"
                                pressed={sectionState.superDice ? true : false}
                                children={'Double Dice'}
                                onClick={toggleSuperDice}
                                disabled={gameStep !== 'gameplay'}
                              />
                              <Spacer />
                              <Button theme="orange" size="xs" children="Stop" onClick={() => setShowConfirm(true)} />
                            </>
                          )}
                        </Row>
                      </Panel>
                    </Column>
                  </>
                )}
              </Row>
            </BoardTitle>
            <BoardCenter>
              <BoardCenterDecks>
                {(Object.keys(decks) as DeckType[]).map((deckType, i) => {
                  const deck = decks[deckType]
                  // @ts-ignore
                  const deckImage = images[`deck${deck.id}`] as MediaAsset | undefined
                  const deckImagePath = deckImage ? `${deckImage.filename}.${deckImage.extension}` : undefined
                  return (
                    <DeckImage
                      key={i}
                      image={deckImagePath ? require(`${deckImagePath}`) : undefined}
                      highlighted={moveStep === 'draw' && deckType === activeDeck}
                      onClick={() =>
                        showForFacilitator || (moveStep === 'draw' && deckType === activeDeck)
                          ? handleDeckDraw(deckType)
                          : {}
                      }
                    />
                  )
                })}
                <DiceArea>
                  <BoardDice
                    highlighted={moveStep === 'roll' && isInteractive}
                    onRoll={handleRoll}
                    dice={dice}
                    isInteractive={sectionState.moveStep === 'roll'}
                    superDice={superDice}
                  />
                </DiceArea>
              </BoardCenterDecks>
              <BoardCenterPlayers
                style={{ pointerEvents: showForFacilitator && gameStep === 'generate' ? 'auto' : 'none' }}>
                {Object.keys(players)
                  .sort((a, b) => players[a].index - players[b].index)
                  .map((uid, i) => {
                    const player = players[uid]
                    if (!userUidStillInGroup(uid)) return null
                    return (
                      <DraggablePlayerSlot
                        key={`${player.name || i}_${player.characterId || '_'}`}
                        index={player.index}
                        player={player}
                        active={moveStep !== 'setup' && i === activePlayerIndex}
                        highlighted={false}
                        finished={player.currentTile === 'FINISH'}
                        onMove={handlePlayerReorder}
                        disabled={!checkUserOnline(uid) && !trainingState}
                        randomPosition={player.randomValue || 1}
                        showRandomDice={gameStep === 'generate'}
                        allowDiceAnimation={allowDiceAnimation}
                        showDragHandle={showForFacilitator && gameStep === 'generate'}
                      />
                    )
                  })}
              </BoardCenterPlayers>
            </BoardCenter>
            <BottomLeftRow>
              {getTileRange('b', 'bl')
                .reverse()
                .map(tile => (
                  <DroppableTile
                    key={tile.index}
                    ref={tileRefs.current[tile.index]}
                    tile={tile}
                    isFacilitator={showForFacilitator}
                    targeted={targetTile === tile.tileNumber}
                    style={{ gridColumnStart: `span ${tile.size}` }}
                    onDrop={dropItem => handleDropOnTile(tile.index, dropItem)}
                    onClick={
                      tile.type === BOARD_GAME_ENCOUNTER && showForFacilitator
                        ? () => handleActivateEncounter(tile)
                        : undefined
                    }
                  />
                ))}
            </BottomLeftRow>
            <BottomCenter>
              <StartArea ref={tileRefs.current[0]}>
                <label>START</label>
              </StartArea>
              <DroppableFinishArea
                ref={tileRefs.current[tileKeys.length - 1]}
                highlight={targetTile === 'FINISH'}
                onDrop={dropItem => handleDropOnTile('FINISH', dropItem)}>
                <label>FINISH</label>
              </DroppableFinishArea>
            </BottomCenter>
            <BottomRightRow>
              {getTileRange(['br', 'b'])
                .slice(1)
                .reverse()
                .map(tile => (
                  <DroppableTile
                    key={tile.index}
                    ref={tileRefs.current[tile.index]}
                    tile={tile}
                    isFacilitator={showForFacilitator}
                    targeted={targetTile === tile.tileNumber}
                    style={{ gridColumnStart: `span ${tile.size}` }}
                    onDrop={dropItem => handleDropOnTile(tile.index, dropItem)}
                    onClick={
                      tile.type === BOARD_GAME_ENCOUNTER && showForFacilitator
                        ? () => handleActivateEncounter(tile)
                        : undefined
                    }
                  />
                ))}
            </BottomRightRow>
            <LeftRow>
              {getTileRange(['bl', 'l'], 'tl', true)
                .reverse()
                .map(tile => (
                  <DroppableTile
                    key={tile.index}
                    ref={tileRefs.current[tile.index]}
                    tile={tile}
                    isFacilitator={showForFacilitator}
                    targeted={targetTile === tile.tileNumber}
                    style={{ gridRowStart: `span ${tile.size}` }}
                    onDrop={dropItem => handleDropOnTile(tile.index, dropItem)}
                    onClick={
                      tile.type === BOARD_GAME_ENCOUNTER && showForFacilitator
                        ? () => handleActivateEncounter(tile)
                        : undefined
                    }
                  />
                ))}
            </LeftRow>
            <TopRow>
              {getTileRange('t', 'tr').map(tile => (
                <DroppableTile
                  key={tile.index}
                  ref={tileRefs.current[tile.index]}
                  tile={tile}
                  isFacilitator={showForFacilitator}
                  targeted={targetTile === tile.tileNumber}
                  style={{ gridColumnStart: `span ${tile.size}` }}
                  onDrop={dropItem => handleDropOnTile(tile.index, dropItem)}
                  onClick={
                    tile.type === BOARD_GAME_ENCOUNTER && showForFacilitator
                      ? () => handleActivateEncounter(tile)
                      : undefined
                  }
                />
              ))}
            </TopRow>
            <RightRow>
              {getTileRange(['tr', 'r'], 'br', true).map(tile => (
                <DroppableTile
                  key={tile.index}
                  ref={tileRefs.current[tile.index]}
                  tile={tile}
                  isFacilitator={showForFacilitator}
                  targeted={targetTile === tile.tileNumber}
                  style={{ gridRowStart: `span ${tile.size}` }}
                  onDrop={dropItem => handleDropOnTile(tile.index, dropItem)}
                  onClick={
                    tile.type === BOARD_GAME_ENCOUNTER && showForFacilitator
                      ? () => handleActivateEncounter(tile)
                      : undefined
                  }
                />
              ))}
            </RightRow>
            {(Object.keys(tileAreas) as TileKey[]).map(tileKey => {
              const refIndex = tileKey === 'START' ? 0 : tileKey === 'FINISH' ? tileRefs.current.length - 1 : tileKey
              const tileRef = tileRefs.current[refIndex]

              if (!tileRef || !tileRef.current || !boardRef.current) return null

              const boardRect = boardRef.current.getBoundingClientRect()
              const rect = tileRef.current.getBoundingClientRect()

              return (
                <TilePieceArea
                  key={tileKey}
                  tileKey={tileKey}
                  playerPositions={tileAreas[tileKey]}
                  players={players}
                  activePlayerIndex={activePlayerIndex}
                  allowHighlight={moveStep === 'move'}
                  isFacilitator={showForFacilitator}
                  isInteractive={isInteractive}
                  style={{
                    top: `${((rect.top - boardRect.top) / boardRect.height) * 100}%`,
                    left: `${((rect.left - boardRect.left) / boardRect.width) * 100}%`,
                    width: `${(rect.width / boardRect.width) * 100}%`,
                    height: `${(rect.height / boardRect.height) * 100}%`,
                    pointerEvents: isInteractive ? 'auto' : 'none',
                  }}
                />
              )
            })}
          </Board>
        </Column>
        {(!showForFacilitator || trainingState) && (
          <EditPlayerModal
            isOpen={!playerCreated}
            characterId={playerCharacter}
            usedCharacters={selectedCharacters}
            onSelect={handleCharacterSelected}
          />
        )}
        <DeckCardModal
          isOpen={showDeckCard}
          deckCard={currentDeckCard}
          deck={activeDeck ? decks[activeDeck] : null}
          isFacilitator={showForFacilitator}
          onNext={handleDeckCardNext}
          onClose={handleDeckCardClose}
          onPrevious={handleDeckCardPrevious}
          onPoster={handlePosterClick}
          setPoster={handlePosterSelect}
          onShare={handleCardShare}
          disableNext={currentDeckIndex === currentDeckIndexes[currentDeckIndexes.length - 1]}
          disablePrevious={currentDeckIndex === currentDeckIndexes[0]}
          sharing={sectionState.sharingCard}
          postersOpen={sectionState.postersOpen}
          selectedPoster={sectionState.selectedPoster}
        />
        <EncounterModal
          isOpen={!!encounter}
          isFacilitator={showForFacilitator}
          encounter={encounter}
          onClose={handleEncounterClose}
        />
        <CongratulationsModal
          isOpen={finishedPlayerUid !== null}
          isFacilitator={showForFacilitator}
          playerName={finishedPlayerUid ? players[finishedPlayerUid].name : ''}
          onClose={handleDismissFinishPlayer}
        />
        <ConfirmModal
          isOpen={showConfirm}
          onSubmit={resetGame}
          label="If you reset the game all progress will be lost"
          confirmText="Reset Game"
          cancelText="Finish & Save"
          reverseButtons
          labelBelow
          style={{ textAlign: 'center' }}
        />
        <ConfirmModal
          isOpen={showPlayerSelectConfirm}
          onSubmit={handleGenerateOrder}
          label="Have all Cadets selected their character?"
          confirmText="Yes"
          cancelText="No"
          style={{ textAlign: 'center' }}
        />
        <MobileMessageModal isOpen={showSaved} panelStyle={{ minWidth: 300 }}>
          <P>Game Saved!</P>
          <Button children="Close" size="s" onClick={() => setShowSaved(false)} />
        </MobileMessageModal>
      </GameContainer>
    </>
  )
}

const COLS = 19
const ROWS = 13

const GameContainer = styled.div<{ isInteractive: boolean }>`
  pointer-events: ${p => (p.isInteractive ? 'auto' : 'none')};
`

const Board = styled.div<{ width: number | undefined; isFacilitator: boolean }>`
  position: relative;
  width: 100% !important;
  height: ${p => (p.width ? (p.width / 4) * 3 : 1260)}px !important;
  display: grid;
  grid-template-columns: repeat(${COLS}, 1fr);
  grid-template-rows: repeat(${ROWS}, 1fr);
  grid-gap: 2px;
  background-color: #504f52;
  border: 1px solid #504f52;
  font-size: 1em;
  box-shadow: 5px 5px 20px 20px rgba(0, 0, 0, 0.25);

  @media (max-width: 1679px), (max-height: 1259px) {
    font-size: 0.85em;
  }

  @media (max-width: 1279px), (max-height: 959px) {
    font-size: 0.68em;
  }

  @media (max-width: 1023px), (max-height: 767px) {
    font-size: 0.5em;
  }
`

const BoardArrows = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="917"
      height="650"
      viewBox="0 0 917 650"
      preserveAspectRatio="none"
      style={{
        position: 'absolute',
        top: `${100 / ROWS}%`,
        left: `${100 / COLS}%`,
        width: `${100 - (100 / COLS) * 2}%`,
        height: `${100 - (100 / ROWS) * 2}%`,
        pointerEvents: 'none',
      }}>
      <path fill="none" stroke="#ff5538" d="M56 649.6v-20.1H18.8v-67H1.3"></path>
      <path
        fill="none"
        stroke="#ff452b"
        d="M5 444.4h13.4V297.9H1M.3 164h17.1V14.6L3.6 4.6M109.7.2v17.5h188V3.6M406 4.7v13h139.7V.2M914.3 647.7c-5-5.4-16.2-16.5-16.2-16.5H808v17.5M703 .2v17.5h188.5V3.6M917 53.7h-17.5V266h14.1M917 354.4h-17.5v204.7h14.1"
      />
      <path
        fill="#ff3f25"
        d="M916 559.5l-7 3.5v-7zM915.8 650.1l-7.3-3 5.3-4.6zM1 562.5l7-3.5v7zM1 444.5l7-3.5v7zM0 0l7.4 2.5-4.9 4.9zM297.5 1l3.5 7h-7zM406.5 1l3.5 7h-7zM891.5 1l3.5 7h-7zM916 265.5l-7 3.5v-7z"
      />
    </svg>
  )
}

const BoardTitle = styled.div`
  grid-column: 3 / ${COLS - 1};
  grid-row: 3;

  & h1 {
    font-size: 110%;
    color: white;
  }
`

const BoardCenter = styled.div`
  grid-column: 3 / ${COLS - 1};
  grid-row: 4 / ${ROWS - 2};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  column-gap: 2em;
`

const BoardCenterDecks = styled.div`
  grid-column-start: span 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 1.5em;
  /* row-gap: 1.5em; */
`

const DiceArea = styled.div`
  grid-column-start: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5em;
`

const BoardCenterPlayers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 1fr);
  row-gap: 10px;
`

const BoardRow = styled.div`
  display: grid;
  gap: 2px;

  ${TileWrapper} {
    width: auto;
    height: auto;
  }
`

const HorizontalRow = styled(BoardRow)`
  grid-template-columns: repeat(${COLS - 2}, 1fr);
`

const VerticalRow = styled(BoardRow)`
  grid-template-rows: repeat(${ROWS}, 1fr);
`

const BottomLeftRow = styled(HorizontalRow)`
  grid-template-columns: repeat(4, 1fr);
  grid-column: 2 / ${2 + 4};
  grid-row: ${ROWS};
`

const BottomCenter = styled(BoardRow)`
  grid-row: ${ROWS - 1} / ${ROWS + 1};
  grid-column-start: span ${COLS - (2 + 4 * 2)};
  grid-template-columns: 1fr 1fr;
`

const BottomRightRow = styled(HorizontalRow)`
  grid-template-columns: repeat(4, 1fr);
  grid-column: ${COLS - 4} / ${COLS};
  grid-row: ${ROWS};
`

const LeftRow = styled(VerticalRow)`
  grid-column: 1;
  grid-row: 1 / ${ROWS + 1};
`

const TopRow = styled(HorizontalRow)`
  grid-column: 2 / ${COLS};
  grid-row: 1;
`

const RightRow = styled(VerticalRow)`
  grid-column: ${COLS};
  grid-row: 1 / ${ROWS + 1};
`

const DeckImage = styled.div<{ image: any; highlighted?: boolean }>`
  position: relative;
  width: auto;
  height: 0;
  padding-top: 69%;
  background-image: url('${p => p.image}');
  background-size: cover;
  cursor: pointer;
  
  &::before {
    content: ${p => (p.highlighted ? '""' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 0px 5px 5px ${p => p.theme.highlightColor};
    animation: ${buttonFlash} 0.5s linear infinite alternate;
  }
`
