import { getPastSharedSessionInputValues } from 'api'
import { useUserState } from 'app/UserState'
import { PromptModal } from 'common/PromptModal'
import { SpinnerWithLabel } from 'common/Spinner'
import { Button, H1, P, Spacer } from 'common/ui'
import { fontLight } from 'fonts'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { InputContext, useInputInterface, useSavedInputValueObjects } from 'session/InputContext'
import { useSessionState } from 'session/SessionState'
import { ActivityClubRulesState, ParticipantState, SectionPropsBase } from 'shared/session/types'
import styled, { css } from 'styled-components'

interface Props extends SectionPropsBase {
  property: 'embedded_activity_sections'
}

interface SectionProps {
  section: Props['section']
  property: Props['property']
  trainingState?: ParticipantState
}

const getInitialState = (): ActivityClubRulesState => ({
  rules: [
    'Keep comments brief and on topic',
    'Listen quietly to other members',
    'Use a friendly face, voice and words',
    'Keep your hands and feet to yourself (don’t touch others)',
  ],
})

export const ClubRules: React.FC<SectionProps> = ({ trainingState }) => {
  const { state } = useSessionState()
  return (
    <InputContext.Provider
      value={{
        session_uid: trainingState ? `Training_${trainingState.profile.uid}` : state.sessionUid,
        participant_uid: 'shared',
        module_id: 0,
        owner: trainingState ? 'training_club_rules' : 'club_rules',
        owner_id: null,
        name: trainingState ? 'training_club_rules_state' : 'club_rules_state',
      }}>
      <ClubRulesApp training={!!trainingState} />
    </InputContext.Provider>
  )
}

export const ClubRulesApp: React.FC<{ training?: boolean }> = ({ training }) => {
  const { accessToken } = useUserState()
  const { state, isFacilitator, pastMode } = useSessionState()
  const participantState = useFocusedParticipantState()
  const showFacilitator = training ? true : isFacilitator && !participantState

  const existingSavedSectionStates = useSavedInputValueObjects<ActivityClubRulesState>()
  const [sectionState, onValueChange] = useInputInterface<ActivityClubRulesState>({
    name: 'club_rules_state',
    defaultValue: getInitialState(),
  })

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [currentRule, setCurrentRule] = useState<number>(0)

  const [loadingPastData, setLoadingPastData] = useState<boolean>(false)
  useEffect(() => {
    if (showFacilitator && !existingSavedSectionStates.length && isEqual(sectionState, getInitialState())) {
      console.log('🥺 Gotta go get past shared data')
      setLoadingPastData(true)
      getPastSharedSessionInputValues<ActivityClubRulesState>(
        { session_uid: state.sessionUid, owner: 'club_rules', name: 'club_rules_state' },
        accessToken
      )
        .then(data => {
          console.log('🥺 Got past shared data', data)
          setLoadingPastData(false)
          if (data.length > 0) onValueChange(data[0].value)
        })
        .catch(e => {
          console.log('🥺 Failed to get past shared data', e)
          // TODO: Sentry alert
          setLoadingPastData(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBtnClick = () => setShowModal(true)

  const handleRuleClick = (ruleIndex: number) => {
    if (!pastMode) {
      setCurrentRule(ruleIndex)
      setShowEditModal(true)
    }
  }

  const handleNewRule = (value: string | null) => {
    setShowModal(false)
    if (!value) return
    onValueChange({
      ...sectionState,
      rules: [...sectionState.rules, value],
    })
  }

  const handleEditRule = (value: string | null) => {
    setShowEditModal(false)
    if (!value) return
    sectionState.rules[currentRule] = value
    onValueChange({
      ...sectionState,
      rules: sectionState.rules.map((rule, i) => (i === currentRule ? value : rule)),
    })
  }

  const handleDelete = (event: React.MouseEvent<HTMLElement>, deleteRuleIndex: number) => {
    event.stopPropagation()
    if (!pastMode) {
      setCurrentRule(deleteRuleIndex)
      onValueChange({
        ...sectionState,
        rules: sectionState.rules.filter((str, i) => i !== deleteRuleIndex),
      })
    }
  }

  return (
    <>
      {showFacilitator && (
        <>
          <ActivityInteractionIndicator type="shared" />
          <Spacer size="l" />
        </>
      )}
      <Wrapper>
        <img
          src={require('../../assets/club-rules-logo.svg')?.default}
          alt="Secret Agent Society"
          style={{ marginTop: 20, marginBottom: 50 }}
        />
        <H1>Club Rules</H1>
        <P>To help you to have fun and learn new things at SAS Club, try to follow the club rules:</P>
      </Wrapper>
      <MainWrapper>
        {loadingPastData ? (
          <SpinnerWithLabel
            label="Loading past club rules..."
            size={36}
            thickness={0.15}
            color={'#18A0FB'}
            labelStyle={{ fontSize: 16 }}
          />
        ) : (
          <>
            {sectionState.rules.map((str, i) => (
              <Rule key={i} onClick={() => handleRuleClick(i)} interactive={showFacilitator}>
                <span>{str}</span>
                {showFacilitator && <Cross onClick={e => handleDelete(e, i)}>&#10005;</Cross>}
              </Rule>
            ))}
          </>
        )}
        {showFacilitator && <Button children={'Add Rule'} onClick={handleBtnClick} />}
      </MainWrapper>
      <IMGWrapper>
        <img src={require('session/assets/rules-base.png')} alt="Master Spy Signature" />
      </IMGWrapper>
      <PromptModal
        isOpen={showModal}
        onSubmit={handleNewRule}
        label={'Add A New Rule'}
        placeholder={'Type in a rule'}
        multiline
        charLimit={255}
      />
      <PromptModal
        isOpen={showEditModal}
        onSubmit={handleEditRule}
        label={'Edit Rule'}
        defaultValue={sectionState.rules[currentRule]}
        multiline
        charLimit={255}
      />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 25px;

  ${P} {
    ${fontLight}
    font-size: 26px;
    max-width: 550px;
    margin-top: 15px;
  }

  &:after {
    content: '';
    width: 100%;
    border-bottom: 1px solid #cdd2e4;
    box-shadow: 0px 1px 0px #fff;
  }
`

const MainWrapper = styled.div`
  text-align: center;
  margin-bottom: 25px;
  position: relative;

  ${Button} {
    cursor: pointer;
  }
`

const IMGWrapper = styled.div`
  img {
    border: 1px solid #cdd2e4;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
`

const Rule = styled.div<{ interactive?: boolean }>`
  ${fontLight}
  font-size: 18px;
  background: #fff;
  border: 1px solid #cdd2e4;
  box-shadow: 0px 1px 0px #fff;
  border-radius: 10px;
  text-align: left;
  padding: 20px 20px 20px 50px;
  line-height: 1;
  margin-bottom: 10px;
  pointer-events: none;

  ${p =>
    p.interactive
      ? css`
          cursor: pointer;
          pointer-events: auto;
        `
      : ''}

  &:before {
    content: url(${require('../../assets/rule-arrow.svg')?.default});
    position: absolute;
    left: 20px;
    margin-right: 15px;
  }

  &:last-of-type {
    margin-bottom: 25px;
  }
`

const Cross = styled.span`
  position: absolute;
  right: 20px;
  color: #e2545a;
  font-weight: 600;
`
