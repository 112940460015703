import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Row, P, Column, Spacer } from 'common/ui'
import { QuestionOption } from 'shared/training/types'
import styled, { css } from 'styled-components'
import { CardFlip } from 'common/CardFlip'
import remove from 'lodash/remove'
import { RichText } from 'session/common/RichText'
import { Media } from 'session/common/Media'
import { getQuestionState, useTrainingState } from 'training/TrainingState'

const getImageHeight = (ratio: string, width: number) => {
  const ratioVals = ratio.split(':')
  return (width / +ratioVals[0]) * +ratioVals[1]
}

export const CardQuestion: React.FC<{
  uid: string
  question_options: QuestionOption[]
  onSubmit: (correct: boolean, value: number[], response?: string, skipValidation?: boolean) => void
}> = ({ uid, question_options, onSubmit }) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)
  const [flippedIndexes, setFlippedIndexes] = useState<number[]>(questionState?.answer || [])
  const cardRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const [cardHeight, setCardHeight] = useState<number>(0)
  const [forceRender, setForceRender] = useState<number>(0)

  const toggleFlipped = (i: number) => {
    if (flippedIndexes.includes(i)) {
      setFlippedIndexes(remove(flippedIndexes, cur => cur !== i))
    } else {
      setFlippedIndexes([...flippedIndexes, i])
    }
  }

  useEffect(() => {
    const correct = flippedIndexes.length === question_options.length
    if (!questionState?.correct && correct) {
      onSubmit(correct, flippedIndexes, '', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flippedIndexes])

  useEffect(() => {
    const contentHeight = Object.entries(cardRefs.current).reduce((acc, ref) => {
      if (ref && ref[1] && ref[1].clientHeight > acc) {
        return ref[1].clientHeight
      }
      return acc
    }, 0)

    setCardHeight(contentHeight + 50)
  }, [forceRender])

  useEffect(() => {
    setForceRender(1)
  }, [])

  return (
    <Flipper count={question_options.length}>
      {question_options.map((option, i) => {
        const splitTitle = (option.title || '').match(/[^\r\n]+/g) || ['']

        return (
          <Fragment key={i}>
            <CardFlip
              isFlipped={flippedIndexes.includes(i)}
              cardStyles={{
                back: { minHeight: 350, height: cardHeight !== 0 ? cardHeight : 'auto' },
                front: { minHeight: 350, height: cardHeight !== 0 ? cardHeight : 'auto' },
              }}
              children={[
                <Card
                  onClick={() => toggleFlipped(i)}
                  justifyContent={option.media && option.text ? 'flex-start' : 'center'}>
                  <Media image={option.media} type="image" widthPreset="656w" ratio={option.media_ratio || 'auto'} />
                  <P className="front-text">
                    {splitTitle.map((splitTitleElem, i) => (
                      <Fragment key={i}>
                        {splitTitleElem} <br />
                      </Fragment>
                    ))}
                  </P>
                </Card>,
                <Card
                  onClick={() => toggleFlipped(i)}
                  justifyContent={option.media && option.text ? 'flex-start' : 'center'}>
                  <div ref={el => (cardRefs.current[option.id] = el)}>
                    <div
                      style={{
                        height: option.media
                          ? option.media_ratio
                            ? getImageHeight(option.media_ratio, cardRefs.current[option.id]?.clientWidth || 0)
                            : ((option.media.width || 0) / (option.media.height || 0)) * 100 + 30
                          : 0,
                      }}>
                      <Media
                        image={option.media}
                        type="image"
                        widthPreset="656w"
                        ratio={option.media_ratio || 'auto'}
                      />
                    </div>
                    <RichText text={option.text} />
                    <Spacer />
                  </div>
                </Card>,
              ]}
            />
          </Fragment>
        )
      })}
    </Flipper>
  )
}

const BackgroundStyle = css`
  background: #edf2fa;
  border: 1px solid #bdc3e0;
  border-radius: 10px;
`

const Flipper = styled(Row)<{ count: number }>`
  .front-text {
    font-size: 22px;
  }

  .react-card-flip {
    width: ${p => `calc(${100 / p.count}% - 15px)`};
    margin: 0 10px;

    &:last-of-type {
      margin-right: 0;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
`

const Card = styled(Column)`
  ${BackgroundStyle};
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
  cursor: pointer;
  text-align: center;

  img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
`
