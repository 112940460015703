import React, { CSSProperties, forwardRef, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useDrop } from 'react-dnd'

import { BoardGameTileDefinition as TileDefinition } from 'shared/session/types'
import { tileColors } from 'shared/session/sections/custom/board-game/tiles'
import { DRAG_TYPE_PLAYER_PIECE, CharacterAvatarDragItem } from './CharacterAvatar'
import { buttonFlash } from 'common/ui'
import { images } from './assets'

export interface TileProps {
  tile: TileDefinition
  style?: CSSProperties
  onClick?: () => void
  targeted?: boolean
}

export const Tile = forwardRef<HTMLDivElement, PropsWithChildren<TileProps>>(
  ({ tile, children, style = {}, onClick, targeted }, ref) => {
    const imageKey = tile.hasImage
      ? `tile${tile.index}${tile.size > 1 ? `_${tile.index + (tile.size - 1)}` : ''}`
      : undefined
    // @ts-ignore
    let tileImage = imageKey && imageKey in images ? images[imageKey] : undefined
    if (tile.type === 'SAS_CHALLENGE') {
      const tileImagePath = `${images.deck2_tile.filename}.${images.deck2_tile.extension}`
      tileImage = require(`${tileImagePath}`)
    }

    return (
      <TileWrapper
        ref={ref}
        colors={tileColors[tile.color]}
        targeted={targeted}
        image={tileImage}
        style={{ ...style, cursor: onClick ? 'zoom-in' : 'auto' }}
        children={children}
        onClick={onClick}
      />
    )
  }
)

export const TileWrapper = styled.div<{ colors: string[]; image?: any; targeted?: boolean }>`
    position: relative;
    width: 60px;
    height: 55px;
    ${p => (p.colors.length === 1 ? `background-color: ${p.colors[0]};` : '')}
    ${p => (p.colors.length === 2 ? `background: radial-gradient(${p.colors[1]}, ${p.colors[0]});` : '')}
    ${p => (p.image ? `background-image: url('${p.image}');` : '')}
    background-size: cover;
    background-position: center center;

    &::before {
      content: ${p => (p.targeted ? '""' : 'none')};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0px 0px 5px 5px ${p => p.theme.highlightColor};
      animation: ${buttonFlash} 0.5s linear infinite alternate;
      z-index: 1;
  }
`

export type DroppableTileProps = TileProps & { onDrop: (item: CharacterAvatarDragItem) => void; isFacilitator: boolean }

export const DroppableTile = forwardRef<HTMLDivElement, DroppableTileProps>(
  ({ onDrop, targeted, isFacilitator = false, ...props }, ref) => {
    const [{ itemIsOver, itemBeingDragged }, drop] = useDrop({
      accept: DRAG_TYPE_PLAYER_PIECE,
      drop: targeted || isFacilitator ? onDrop : undefined,
      collect: monitor => ({
        itemIsOver: monitor.isOver(),
        itemBeingDragged: monitor.canDrop(),
      }),
    })
    const style: CSSProperties = {
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      border: itemIsOver ? '3px solid white' : 'none', //itemBeingDragged ? '2px dashed white' : 'none',
      boxShadow: itemIsOver ? 'inset 0 0 8px 0 rgba(0,0,0,0.5)' : undefined,
    }
    if (itemBeingDragged) {
      style.position = 'relative'
      style.zIndex = 99
    }
    return (
      <Tile ref={ref} {...props} targeted={targeted}>
        <div ref={drop} style={style} />
      </Tile>
    )
  }
)
