import React from 'react'
import { Row, Column, H2, H3, P } from 'common/ui'
import styled from 'styled-components'
import { MediaAsset, MediaRatio } from 'shared/session/types'
import { Media } from 'session/common/Media'

interface Props {
  requiredImage?: string
  image?: MediaAsset
  ratio?: MediaRatio
  widthPreset?: string
}

export const InlineCard: React.FC<Props> = ({
  children,
  image,
  requiredImage,
  ratio = '2:1',
  widthPreset = '720w',
}) => {
  return (
    <InlineCardWrapper>
      {image && (
        <Column flex="1 0 50%" style={{ maxWidth: 360, backgroundColor: '#BDC3E0' }}>
          <Media type="image" image={image} widthPreset={widthPreset} ratio={ratio} />
        </Column>
      )}
      {requiredImage && (
        <img
          style={{ width: '40%', height: '100%', maxWidth: 360, backgroundColor: '#BDC3E0' }}
          alt=""
          src={requiredImage}
        />
      )}
      <InlineCardInner>{children}</InlineCardInner>
    </InlineCardWrapper>
  )
}

export const InlineCardWrapper = styled(Row)`
  box-sizing: border-box;
  min-height: 70px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #cdd2e4;
  box-shadow: 0 1px #fff;
  overflow: hidden;
`

export const InlineCardInner = styled(Column)`
  flex: 1 1 auto;
  margin: 10px 12px;
  & ${H2}, ${H3} {
    color: #925bed;
  }
  ${P} {
    font-size: 14px;
  }
`

export const InlineCards = styled.div`
  & > ${InlineCardWrapper} {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`
