export function getPixelRatio(): number {
  // const ctx = document.createElement('canvas').getContext('2d')
  const dpr = window.devicePixelRatio || 1
  return dpr
  // const bsr =
  //   (ctx
  //     ? ctx.webkitBackingStorePixelRatio ||
  //       ctx.mozBackingStorePixelRatio ||
  //       ctx.msBackingStorePixelRatio ||
  //       ctx.oBackingStorePixelRatio ||
  //       ctx.backingStorePixelRatio
  //     : 1) || 1
  // return dpr / bsr
}

const body = document.getElementsByTagName('body')[0]
export const getWindowWidth = (): number =>
  window.innerWidth || document.documentElement.clientWidth || body.clientWidth
export const getWindowHeight = (): number =>
  window.innerHeight || document.documentElement.clientHeight || body.clientHeight
