import React from 'react'
import styled from 'styled-components'
import { fontBold, fontRegular } from 'fonts'
import { SectionPropsBase } from 'shared/session/types'
import { SectionTitle } from 'session/common/SectionTitle'
import { TextInput, Label, Column, TextareaInput, Row, Spacer } from 'common/ui'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { InputComponent } from 'session/common/InputComponent'
import { SymbolInput } from 'common/SymbolInput'
import { useFacilitatorState, useFocusedParticipantState } from 'session/hooks/useProfileState'
import { SectionPropsBase as TipsheetsSectionPropsBase } from 'shared/tipsheets/types'
import { InputLockComponent } from 'session/common/InputLockComponent'
import { useSessionState } from 'session/SessionState'
import { emojiInputOptions } from 'constants/emojiSets'

const emojis = emojiInputOptions
interface Props extends SectionPropsBase {
  property: 'input_sections'
}

export const InputSection: React.FC<Props> = ({ property, section, index }) => {
  const facilitatorState = useFacilitatorState()
  const participantState = useFocusedParticipantState()
  const { isFacilitator } = useSessionState()
  const { title, type, usage, size, label, placeholder: _placeholder, lines } = section

  const placeholder =
    usage === 'individual' && facilitatorState && !participantState
      ? '[Select participant tab to view/edit]'
      : usage === 'facilitator'
        ? `[Only ${participantState ? 'the facilitator' : 'you'} can write here]`
        : _placeholder
  const disabled =
    (usage === 'individual' && facilitatorState && !participantState) || (usage === 'facilitator' && !facilitatorState)

  return (
    <>
      {title && <SectionTitle children={title} />}
      <div style={{ padding: '10px 0', position: 'relative' }}>
        <FormField>
          {label && <Label>{label}</Label>}
          {(type === 'textarea' || type === 'text') && (
            <InputComponent<string>
              name="input"
              defaultValue={''}
              contextOverride={usage === 'facilitator' ? { participant_uid: 'shared' } : undefined}>
              {(inputProps) => (
                <InputLockComponent
                  key={participantState ? participantState.profile.uid : index}
                  facilitatorOnly={usage === 'facilitator'}
                  hasLabel={!!label}>
                  {(lockProps, lockState) => {
                    const combinedLockProps = usage === 'facilitator' ? {} : { ...lockProps, ...lockState }
                    return (
                      <>
                        {type !== 'textarea' ? (
                          <TextInput
                            {...inputProps}
                            {...combinedLockProps}
                            value={inputProps.value || ''}
                            onChange={(e) => {
                              inputProps.onChange(e.target.value || '')
                              lockProps.onChange(e.target.value || '')
                            }}
                            placeholder={placeholder}
                            disabled={disabled || lockState.disabled}
                            inputSize={size || 's'}
                          />
                        ) : (
                          <AutosizeTextareaInput
                            {...inputProps}
                            {...combinedLockProps}
                            value={inputProps.value || ''}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                              inputProps.onChange(e.target.value || '')
                              lockProps.onChange(e.target.value || '')
                            }}
                            placeholder={placeholder}
                            disabled={disabled || lockState.disabled}
                            rows={lines}
                            inputSize={size || 's'}
                          />
                        )}
                      </>
                    )
                  }}
                </InputLockComponent>
              )}
            </InputComponent>
          )}
          {(type === 'text_symbol' || type === 'symbol') && (
            <Row>
              {type === 'text_symbol' && (
                <>
                  <Column flex="1 1 auto">
                    <InputComponent<string>
                      name="input"
                      defaultValue={''}
                      contextOverride={usage === 'facilitator' ? { participant_uid: 'shared' } : undefined}>
                      {(inputProps) => (
                        <InputLockComponent
                          key={`${
                            usage === 'facilitator' ? 'shared' : participantState ? participantState.profile.uid : 'hmm'
                          }_section.id`}
                          facilitatorOnly={usage === 'facilitator'}
                          verticalPlacementCadet={-30}
                          verticalPlacementFacilitator={label ? -30 : 0}
                          hasLabel={!!label}>
                          {(lockProps, lockState) => {
                            const combinedLockProps = usage === 'facilitator' ? {} : { ...lockProps, ...lockState }
                            return (
                              <TextInput
                                {...inputProps}
                                {...combinedLockProps}
                                value={inputProps.value || ''}
                                onChange={(e) => {
                                  inputProps.onChange(e.target.value || '')
                                  lockProps.onChange(e.target.value || '')
                                }}
                                placeholder={placeholder}
                                disabled={disabled || lockState.disabled}
                                inputSize={size || 's'}
                              />
                            )
                          }}
                        </InputLockComponent>
                      )}
                    </InputComponent>
                  </Column>
                  <Spacer size="s" flex="none" />
                </>
              )}
              <Column style={{ paddingTop: !label && isFacilitator ? 30 : 0 }}>
                <InputComponent<string>
                  name="input_symbol"
                  defaultValue={''}
                  contextOverride={usage === 'facilitator' ? { participant_uid: 'shared' } : undefined}>
                  {(props) => (
                    <SymbolInput
                      {...props}
                      symbols={emojis}
                      disabled={disabled}
                      inputProps={{ inputSize: section.multiple_symbols ? 'l' : size || 's' }}
                      multipleSymbols={section.multiple_symbols}
                    />
                  )}
                </InputComponent>
              </Column>
            </Row>
          )}
        </FormField>
      </div>
    </>
  )
}

export const InputSectionFacilitator: React.FC<Props> = (props) => {
  if (props.section.usage === 'facilitator') return <InputSection {...props} />
  const { section } = props
  const { title, type, label, lines } = section
  const placeholder = `[View a participant tab to view input text]`
  return (
    <>
      {title && <SectionTitle children={title} />}
      <div style={{ padding: '10px 0' }}>
        <FormField>
          {label && <Label>{label}</Label>}
          {type !== 'textarea' && <TextInput disabled placeholder={placeholder} />}
          {type === 'textarea' && <AutosizeTextareaInput disabled placeholder={placeholder} rows={lines} />}
        </FormField>
      </div>
    </>
  )
}

interface GenericProps extends TipsheetsSectionPropsBase {
  property: 'input_sections'
}

export const GenericInputSection: React.FC<GenericProps> = ({ section }) => {
  const { title, type, size, label, placeholder, lines } = section
  return (
    <>
      {title && <SectionTitle children={title} />}
      <div style={{ padding: '10px 0' }}>
        <FormField>
          {label && <Label>{label}</Label>}
          {(type === 'textarea' || type === 'text') && (
            <InputComponent<string> name="input" defaultValue={''}>
              {(props) => (
                <>
                  {type !== 'textarea' ? (
                    <TextInput
                      {...props}
                      value={props.value || ''}
                      onChange={(e) => props.onChange(e.target.value || '')}
                      placeholder={placeholder}
                      inputSize={size || 's'}
                    />
                  ) : (
                    <AutosizeTextareaInput
                      {...props}
                      value={props.value || ''}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => props.onChange(e.target.value || '')}
                      placeholder={placeholder}
                      rows={lines}
                      inputSize={size || 's'}
                    />
                  )}
                </>
              )}
            </InputComponent>
          )}
        </FormField>
      </div>
    </>
  )
}

export const FormField = styled(Column)`
  & ${Label} {
    ${fontBold}
    color: #011a46;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.03em;
    margin-bottom: 0.5em;
  }
  & ${TextInput}, & ${TextareaInput} {
    ${fontRegular}
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.03em;
    color: #011a46;
    border-top: none;
    box-shadow:
      inset 0px 0px 0px 1px #cdd2e4,
      0px 1px 0px #ffffff;
    width: 100%;
    &:focus {
      box-shadow:
        inset 0px 0px 0px 1px ${(p) => p.theme.buttonFocusBorderTopColor},
        0px 1px 0px #ffffff;
    }
    &::placeholder {
      color: #bdc3e0;
    }

    @media print {
      &::placeholder {
        color: transparent;
      }
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }
`
