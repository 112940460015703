import React from 'react'
import { P, Spacer, Row, Column } from 'common/ui'

export const Footer: React.FC = () => {
  return (
    <>
      <Spacer size="l" />
      <P align="center">
        <small>
          Copyright © Social Science Translated Pty Ltd 2022. All rights reserved. <br />
          <a href={`${process.env.REACT_APP_SST_BASE_URL}/terms`}>Terms of Use</a> |{' '}
          <a href={`${process.env.REACT_APP_SST_BASE_URL}/privacy`}>Privacy Policy</a> |{' '}
          <a href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}>Technical Enquiries</a>
          {/* <a href={`${process.env.REACT_APP_SST_BASE_URL}/sas-provider-terms-and-conditions`}>SAS Provider Terms</a> */}
        </small>
      </P>
    </>
  )
}

export const SocialSkillsFooter: React.FC = () => {
  return (
    <>
      <Spacer size="l" />
      <Row alignItems="center">
        <Column>
          <img src={require('../assets/socialSkillsLogo.svg')?.default} width={200} alt="Social Science Translated" />
        </Column>
        <Spacer size="l" />
        <div>
          <P style={{ margin: 0, fontSize: 12 }}>
            © 2021 Renae Beaumont Enterprises Pty Ltd. <br />
            Published and distributed by Social Science Translated Pty Ltd (www.sst-institute.net). <br />
            Limited copies may be made to facilitate the delivery of the SAS program. <br />
            Not to be copied or reproduced for any other purpose without specific written permission.
          </P>
        </div>
      </Row>
    </>
  )
}
