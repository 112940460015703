import React, { ReactNode, useState } from 'react'
import { H3, Spacer } from 'common/ui'
import styled from 'styled-components'

export const EmbeddedVideo: React.FC<{ title: ReactNode; source: string }> = ({ title, source }) => {
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false)
  return (
    <VideoContainer>
      {!videoPlaying && (
        <Poster onClick={() => setVideoPlaying(true)}>
          <H3>
            {title} <br />
            <Spacer />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="50"
              viewBox="0 0 24 24"
              width="50"
              fill="#fff">
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M9.5,16.5v-9l7,4.5L9.5,16.5z" />
              </g>
            </svg>
          </H3>
        </Poster>
      )}
      <iframe
        title="embedded yt video"
        src={videoPlaying ? source : ''}
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </VideoContainer>
  )
}

const VideoContainer = styled.div`
  box-sizing: border-box;
  width: 33.3%;
  height: 300px;
  padding-top: 0;
  padding: 7.5px;
  position: relative;

  @media (max-width: 1280px) {
    width: 50%;
    height: 250px;
  }

  @media (max-width: 948px) {
    width: 100%;
    height: 300px;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(180deg, #9466df, #654ed5);
  }
`

const Poster = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
  background: linear-gradient(180deg, #9466df, #654ed5);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  padding: 15px;

  &:hover {
    opacity: 0.5;
  }

  ${H3} {
    color: #fff !important;
    font-size: 1.4rem;
    text-align: center;
  }
`
