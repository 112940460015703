import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import sortBy from 'lodash/sortBy'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import { useHistory, useParams } from 'react-router-dom'

import { DrupalRole } from 'shared/types'
import { NavRouteProps } from 'dashboards/types'
import { FacilitatorLookupEntity, GroupEntity } from 'shared/dashboard/types'
import { useFacDashData } from './FacilitatorDashboardState'
import { SessionEntity, SessionEntityNew } from 'shared/dashboard/types'

import { Row, P, Spacer, Button, H3, Column, OutlineButton } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { CompactCells, Cell, CellInner } from 'dashboards/common/Cell'
import { Form, Props as FormProps, RenderProps as FormRenderProps } from 'dashboards/common/Form'
import { FormField, FormFieldDataProps } from 'dashboards/common/FormField'
import { Select } from 'common/Select'
import { Checkbox } from 'common/Checkbox'
import { SpinnerWithLabel } from 'common/Spinner'
import { InfoTooltip } from 'common/Tooltip'
import { DatePicker } from 'dashboards/common/DatePicker'
import { TimePicker } from 'dashboards/common/TimePicker'
import { UserCell } from 'dashboards/common/UserCell'

import { useUserState } from 'app/UserState'
import {
  AnyModuleCode,
  cadetModuleVisibility,
  parentModuleVisibility,
  parentModuleCodes,
} from 'shared/dashboard/moduleCodes'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'
import { baseUrl } from './FacilitatorDashboard'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { isProdEnv } from 'utils/envUtils'

const sessionTypeOptions: { value: SessionEntity['type']; label: string }[] = [
  { value: 'cadet', label: 'Cadet Club Meeting' },
  { value: 'parent', label: 'Parent Group Meeting' },
]

const extractPrimaryRole = (roles: DrupalRole[]) =>
  roles.reduce((current, value) => {
    if (value === 'sas-sg facilitator') current = 'Facilitator'
    if (value === 'sas-sg assistant' && current !== 'sas-sg facilitator') current = 'Assistant'
    return current
  }, '')

export const MeetingAdd: React.FC<NavRouteProps> = ({ route }) => <MeetingAddInner route={route} />

export const MeetingAddInner: React.FC<NavRouteProps & {
  editMode?: boolean
  formProps?: Partial<FormProps<SessionEntity, SessionEntityNew>>
}> = ({ route, editMode, formProps = {} }) => {
  const { groupUid } = useParams<{ groupUid: string }>()
  const { accessToken, drupalProfile } = useUserState()
  const history = useHistory()

  const [showExtraGroups, setShowExtraGroups] = useState<boolean>(false)
  const [currentGroupUid, setCurrentGroupUid] = useState<string | null>(groupUid ? groupUid : null)
  const [use24hr, setUse24hr] = useState<boolean>(false)

  const [groups, loadingGroups] = useFacDashData('groups', [])
  const myGroups = drupalProfile
    ? groups.filter(({ facilitator_id }) => drupalProfile.user_id === String(facilitator_id))
    : []

  const [sessionProviderUid, setSessionProviderUid] = useState<string | null>(null)
  const [assistants] = useEndpoint<FacilitatorLookupEntity[]>(
    sessionProviderUid ? `/api/v1/facilitators/provider/${sessionProviderUid}` : null,
    []
  )

  const [potentialExtraGroups] = useEndpoint<GroupEntity[]>(
    sessionProviderUid ? `/api/v1/groups/provider/${sessionProviderUid}` : null,
    []
  )

  const [sessions, { loading: loadingSessions }] = useEndpoint<SessionEntity[]>(
    currentGroupUid ? `/api/v1/groups/${currentGroupUid}/sessions?includeExtraGroups=1` : null
  )

  const groupOptions = loadingGroups
    ? []
    : // if in editMode the field is readonly and so we want a cofacilitator to see the group name
      // but if creating a new session we only want to provide options for group that the user owns
      (editMode ? groups : myGroups).map(({ id, name, primary_mentors = [] }) => ({
        value: String(id),
        label: `${name} ${!primary_mentors.length ? '[Empty group]' : ''}`,
      }))

  const today = format(new Date(), 'yyyy-LL-dd')
  const updateFormValueRef = useRef<FormRenderProps<SessionEntityNew>['updateFormValue'] | null>(null)

  // Session gets pulled in without Group association -- this just links it up
  useEffect(() => {
    // We are intentionally using groups over myGroups
    if (!loadingGroups && groups.length > 0) {
      if (!!groupUid && !editMode) {
        const linkedGroup = groups.find(({ uid }) => uid === groupUid)
        if (linkedGroup) updateFormValueRef.current?.('group_id', linkedGroup.id)
        else history.replace(`${baseUrl}/meetings/new`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingGroups])

  const [deleting, setDeleting] = useState(false)
  const handleDelete = (sessionUid: string, groupUid: string) => {
    if (!window.confirm(`Are you sure you want to delete this meeting?`)) return
    setDeleting(true)
    fetch(`/api/v1/sessions/${sessionUid}`, { method: 'DELETE', ...getAuthRequestParams(accessToken) })
      .then(() => {
        history.push(`${baseUrl}/meetings`)
        setDeleting(false)
      })
      .catch(() => {
        setDeleting(false)
      })
  }

  const filterAvailableModules = (type: SessionEntityNew['type']) => {
    const visibility = type === 'parent' ? parentModuleVisibility : cadetModuleVisibility
    const defaultSessions = type === 'parent' ? ['parent-intro', 'parent-info-1'] : ['1', '1a']

    if (!sessions || sessions.length === 0 || type === undefined) {
      return defaultSessions.map(value => ({
        value: value,
        label: getModuleTitle(value as AnyModuleCode),
      }))
    }

    const groupSessions = sessions.filter(session => session.type === type).map(session => session.module_code)
    const availableToSchedule = [
      ...defaultSessions,
      ...uniq(flatten(groupSessions.map(session => visibility[session]))),
    ]

    if (type === 'parent') {
      return parentModuleCodes
        .filter(code => availableToSchedule.includes(code))
        .map(value => ({
          value: value,
          label: getModuleTitle(value as AnyModuleCode),
        }))
    }

    return availableToSchedule.sort().map(value => ({
      value: value,
      label: getModuleTitle(value as AnyModuleCode),
    }))
  }

  return (
    <Page route={route}>
      <Form<SessionEntity, SessionEntityNew>
        initialDataUrl={`/api/v1/sessions/new`}
        initialDataRequestOptions={getAuthRequestParams(accessToken)}
        submitUrl={`/api/v1/sessions/save`}
        submitRequestOptions={{ ...getAuthRequestParams(accessToken), method: 'POST' }}
        onSubmitSuccess={() => history.replace(`${baseUrl}/meetings`)}
        {...formProps}
        children={({ loading, submitting, formData, updateFormValue, setFormData, validationErrors }) => {
          updateFormValueRef.current = updateFormValue
          if (loading || loadingGroups) return <SpinnerWithLabel color="#925BED" label="One moment please..." />
          const formFieldDataProps: FormFieldDataProps<SessionEntityNew> = { formData, validationErrors, setFormData }
          const { uid, type, group_id, start_date, start_time, extra_groups = [], session_assistants = [] } = formData
          const moduleCodeOptions = filterAvailableModules(type)
          const group = (!!group_id && groups.find(({ id }) => id === group_id)) || undefined

          // this is causing react to have render error in console not sure on the way around it though
          if (group && group.provider_uid && !sessionProviderUid) {
            setSessionProviderUid(group.provider_uid)
            setCurrentGroupUid(group.uid)
          }
          const extraGroupIds = extra_groups.map(({ id }) => id)
          const extraGroupOptions = !group
            ? []
            : (potentialExtraGroups || [])
                .filter(({ id, provider_uid }) => id !== group_id && provider_uid === group.provider_uid)
                .map(({ id, name }) => ({
                  value: String(id),
                  label: name,
                  disabled: extraGroupIds.indexOf(id) >= 0,
                }))

          const assistantIds = session_assistants.map(({ user_id }) => String(user_id))
          const assistantOptions = assistants
            ? sortBy(
                assistants
                  .filter(
                    assistant =>
                      +assistant.id !== group?.cofacilitator_id &&
                      +assistant.id !== group?.assistant_id &&
                      +assistant.id !== group?.facilitator_id
                  )
                  .map(({ id, name, roles }) => ({
                    value: id,
                    label: `${name}  (${extractPrimaryRole(roles)})`,
                    disabled: assistantIds.indexOf(id) >= 0,
                  })),
                val => val.label.toLowerCase()
              )
            : []

          const updateUnixTime = (changedValues: Pick<typeof formData, 'start_time' | 'start_date'>) => {
            const date = changedValues.start_date || start_date
            const time = changedValues.start_time || start_time
            if (date && time) {
              const localDateTime = parse(`${date} ${time}`, 'yyyy-LL-dd HH:mm', Date.now())
              setFormData({
                ...formData,
                ...changedValues,
                timezone: format(localDateTime, 'xxx'),
                start_datetime: localDateTime.getTime() / 1000,
              })
            } else {
              setFormData({ ...formData, ...changedValues })
            }
          }

          return (
            <>
              <HeaderHr
                children={editMode ? 'Meeting Type' : 'Select meeting type *'}
                sideRoute={`${baseUrl}/meetings`}
                sideLabel={'< Back to My Meetings'}
              />
              <FormField<SessionEntityNew, string>
                {...formFieldDataProps}
                select="type"
                defaultValue={''}
                children={({ value, onChange }) => (
                  <Select
                    options={sessionTypeOptions}
                    value={value}
                    onChange={onChange}
                    disabled={editMode && !!type}
                  />
                )}
              />
              <Spacer size="l" />

              <HeaderHr children={editMode ? 'Meeting group' : 'Select meeting group *'} />
              <FormField<SessionEntityNew, number>
                {...formFieldDataProps}
                select="group_id"
                defaultValue={0}
                children={({ value, onChange }) => (
                  <Select
                    options={groupOptions}
                    empty={loadingGroups ? 'Loading groups...' : undefined}
                    value={String(value)}
                    onChange={str =>
                      ReactDOM.unstable_batchedUpdates(() => {
                        const groupId = parseInt(str)
                        const group = groups.find(({ id }) => id === groupId)
                        setCurrentGroupUid(group ? group.uid : null)
                        if (group && group.provider_uid && !sessionProviderUid)
                          setSessionProviderUid(group.provider_uid)
                        onChange(groupId)
                      })
                    }
                    disabled={(editMode && !!group_id) || (!editMode && !!group_id && !!groupUid)}
                  />
                )}
              />
              {type === 'parent' && !!group_id && (
                <>
                  {extra_groups.length > 0 || showExtraGroups ? (
                    <>
                      {extra_groups.length > 0 && (
                        <CompactCells style={{ marginTop: 10, marginBottom: 10 }}>
                          {extra_groups.map(group => (
                            <Cell key={group.uid}>
                              <CellInner>
                                <Column style={{ fontSize: 14 }}>{group.name}</Column>
                                <Spacer size="m" flex="1 1 auto" />
                                <Column>
                                  <Button
                                    type="button"
                                    size="xs"
                                    theme="red"
                                    children="Remove"
                                    onClick={() =>
                                      updateFormValue(
                                        'extra_groups',
                                        extra_groups.filter(({ id }) => id !== group.id)
                                      )
                                    }
                                  />
                                </Column>
                              </CellInner>
                            </Cell>
                          ))}
                        </CompactCells>
                      )}
                      {extraGroupOptions.filter(({ disabled }) => !disabled).length > 0 && (
                        <>
                          <Spacer size="s" />
                          <Select
                            options={extraGroupOptions}
                            value={''}
                            empty="Select extra group..."
                            onChange={groupId => {
                              if (!groupId) return
                              const selectedGroup = (potentialExtraGroups || []).find(
                                ({ id }) => id === parseInt(groupId)
                              )
                              if (selectedGroup) updateFormValue('extra_groups', [...extra_groups, selectedGroup])
                            }}
                          />
                        </>
                      )}
                      {!extra_groups.length && !extraGroupOptions.length && (
                        <P>There are currently no other groups available to add.</P>
                      )}
                    </>
                  ) : (
                    <OutlineButton
                      size="s"
                      children="+ Offer combined parent group option..."
                      marginTop="s"
                      onClick={() => setShowExtraGroups(true)}
                    />
                  )}
                </>
              )}

              <Spacer size="l" />

              <HeaderHr children={editMode ? 'Module' : 'Select a Module *'} />
              <FormField<SessionEntityNew, string>
                {...formFieldDataProps}
                select="module_code"
                defaultValue={''}
                children={({ value, onChange }) => (
                  <Select
                    options={moduleCodeOptions}
                    value={value}
                    disabled={!type || !group_id || loadingSessions}
                    onChange={onChange}
                    empty={!type || !group_id ? 'Please select a meeting type and group' : undefined}
                  />
                )}
              />
              <Spacer size="l" />

              <HeaderHr children="Select a Date *" />
              <DatePicker
                value={start_date}
                onChange={value => updateUnixTime({ start_date: value })}
                dayPickerProps={{
                  disabledDays: (check: Date) => {
                    const checkDate = format(check, 'yyyy-LL-dd')
                    return checkDate < today && (!editMode || (editMode && checkDate !== start_date))
                  },
                }}
              />

              <Spacer size="l" />

              <HeaderHr children="Select a Time *" />
              <TimePicker
                value={start_time || ''}
                onChange={value => updateUnixTime({ start_time: value })}
                earliestHour={use24hr ? 0 : 6}
                latestHour={use24hr ? 23.75 : 22}
                timeDivisions={isProdEnv() ? 4 : 20}
              />
              <Row alignItems="center" marginTop="xs">
                <H3>
                  <span style={{ fontWeight: 500 }}>Time Zone: </span>
                  {format(new Date(), 'OOO')}
                  <InfoTooltip
                    iconSize={16}
                    containerStyle={{ verticalAlign: 'bottom' }}
                    content={`The time you select should be relative to your computer's current time zone (indicated here).`}
                  />
                </H3>
                <Spacer size="l" />
                <div>
                  <Checkbox
                    size="xs"
                    checked={use24hr}
                    onChange={() => setUse24hr(!use24hr)}
                    labelProps={{ style: { fontWeight: 'normal' } }}>
                    Enable 24-hour scheduling
                  </Checkbox>
                </div>
              </Row>
              <Spacer size="l" />

              <HeaderHr children="Add Co-Facilitator / SAS Assistant *" />
              {group ? (
                <>
                  {(session_assistants.length > 0 || group.cofacilitator_id || group.assistant_id) && (
                    <CompactCells style={{ marginBottom: 10 }}>
                      {group.cofacilitator_id && (
                        <UserCell
                          key={group.cofacilitator_id}
                          fetchUrl={`/api/v1/groups/${group.uid}/user/${group.cofacilitator_id}`}
                        />
                      )}
                      {group.assistant_id && (
                        <UserCell
                          key={group.assistant_id}
                          fetchUrl={`/api/v1/groups/${group.uid}/user/${group.assistant_id}`}
                        />
                      )}
                      {session_assistants.map(assistantJoinData => (
                        <UserCell
                          key={assistantJoinData.user_id}
                          fetchUrl={`/api/v1/groups/${group.uid}/user/${assistantJoinData.user_id}`}
                          children={user => (
                            <>
                              <Spacer size="m" flex="1 1 auto" />
                              <Column>
                                <Button
                                  type="button"
                                  size="xs"
                                  theme="red"
                                  children="Remove"
                                  onClick={() =>
                                    updateFormValue(
                                      'session_assistants',
                                      session_assistants.filter(({ user_id }) => String(user_id) !== user.id)
                                    )
                                  }
                                />
                              </Column>
                            </>
                          )}
                        />
                      ))}
                    </CompactCells>
                  )}
                  {assistantOptions.filter(({ disabled }) => !disabled).length > 0 && (
                    <Select
                      options={assistantOptions}
                      value={''}
                      empty="Choose ..."
                      onChange={assistantId => {
                        if (!assistantId) return
                        updateFormValue('session_assistants', [
                          ...session_assistants,
                          { user_id: parseInt(assistantId) },
                        ])
                      }}
                    />
                  )}
                  {!session_assistants.length && !assistantOptions.length && (
                    <P>There are currently no assistants available to add.</P>
                  )}
                </>
              ) : (
                <P>{loadingGroups ? `Waiting for groups to load...` : `Please select a group first`}</P>
              )}
              <Spacer size="l" />

              <HeaderHr children="Save Meeting" />
              {Object.values(validationErrors).length > 0 && (
                <P style={{ color: 'red' }}>There are validation errors, please check the fields above.</P>
              )}
              <Row>
                <div>
                  <Button type="submit" size="m" children={submitting ? 'Submitting...' : 'Save & Exit'} />
                </div>
                <Spacer flex="1 1 auto" />
                {editMode && uid && group && (
                  <div>
                    <Button
                      type="button"
                      size="s"
                      theme="red"
                      disabled={deleting}
                      children={deleting ? 'Deleting...' : 'Delete Meeting'}
                      onClick={() => handleDelete(uid, group.uid)}
                    />
                  </div>
                )}
              </Row>
            </>
          )
        }}
      />
    </Page>
  )
}
