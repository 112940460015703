import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router'

import { Button, Panel, P, Row, Spacer, CollapseMargin, CUT_TR_S, CUT_BL_S, ButtonProps } from 'common/ui'
import Modal from 'app/Modal'
import { ActivityContext } from 'app/ActivityContext'
import { getNextPromptCount } from 'utils/routeUtils'

interface Props {
  shadowColor?: ButtonProps['shadowColor']
  size?: ButtonProps['size']
  confirm?: boolean
}

const ExitButton: React.FC<Props> = ({
  shadowColor = 'black',
  size,
  confirm = true,
  children = 'Exit to Level Menu',
}) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const history = useHistory()
  const { activity, onComplete } = useContext(ActivityContext)

  function onConfirm() {
    const nextPromptCount = getNextPromptCount()
    if (activity) {
      onComplete({ ignoreRouterPrompt: nextPromptCount })
    } else {
      history.replace('/game', { ignoreRouterPrompt: nextPromptCount })
    }
  }

  return (
    <>
      <Button
        size={size}
        shadowColor={shadowColor}
        onClick={confirm ? () => setShowConfirm(true) : onConfirm}
        id="exit">
        {children}
      </Button>
      {showConfirm && (
        <Modal isOpen onRequestClose={() => setShowConfirm(false)}>
          <Panel flair={[CUT_TR_S, CUT_BL_S]} padding="25px 15px" shadow={false}>
            <CollapseMargin>
              <P align="center">
                If you exit the game now,
                <br />
                your answers will be lost.
                <br />
                Are you sure you want to exit?
              </P>
            </CollapseMargin>
            <Spacer />
            <Row reverse>
              <Button onClick={onConfirm} id="confirm" autoFocus>
                Exit
              </Button>
              <Spacer />
              <Button onClick={() => setShowConfirm(false)} id="cancel">
                Don't Exit
              </Button>
            </Row>
          </Panel>
        </Modal>
      )}
    </>
  )
}

export default ExitButton
