import React, { Fragment } from 'react'
import { ThemeProvider } from 'styled-components'
import { parentGreen } from 'themes'
import uniq from 'lodash/uniq'

import { NavRouteProps } from 'dashboards/types'
import { baseUrl } from './MentorDashboard'

import { QuestionnaireSummarySaved, questionnaireTypeLabels } from 'shared/questionnaires/types'
import { QuestionnaireModuleCode } from 'shared/dashboard/moduleCodes'
import { assessmentMaterialsResources, AssessmentResourceGroup } from 'dashboards/constant/resources'

import { Button, Column, H3, Row, Spacer, P } from 'common/ui'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'
import { SpinnerWithLabel } from 'common/Spinner'
import { LinkButton } from 'common/LinkButton'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { CheckboxLabel } from 'common/Checkbox'
import { Page } from 'dashboards/common/Page'
import { NotAuthorizedPage } from 'home/NotAuthorizedPage'

import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useMentorDashState } from './MentorDashboardState'
import { getThemeFromResourceType } from 'dashboards/facilitator/AssessmentMaterials'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { SessionEntity } from 'shared/dashboard/types'

const bookletMap: {
  [key in QuestionnaireModuleCode]: Exclude<QuestionnaireSummarySaved['questionnaire_booklet'], undefined>
} = {
  'parent-intro': 1,
  'parent-info-1': 1,
  '9': 2,
  '9b': 2,
  'parent-info-4': 2,
  'follow-up-1': 3,
  'follow-up-1b': 3,
  'follow-up-2': 4,
  'follow-up-2b': 4,
}

export const Questionnaires: React.FC<NavRouteProps> = ({ route }) => {
  const {
    endpoints: {
      mentor: { value: mentor, loading: loadingMentor },
      primaryMentor: { value: primaryMentor, loading: loadingPrimaryMentor },
    },
  } = useMentorDashState()
  const [sessions, { loading: loadingSessions, fetch: refetchSessions }] = useEndpoint<SessionEntity[]>(
    mentor && mentor.uid ? `/api/v1/sessions?mentor_uid=${mentor.uid}` : null,
    [],
    { refetchDespiteCachedValue: true }
  )
  const [questionnaires, { loading: loadingQuestionnaires, fetch: refetchQuestionnaires }] = useEndpoint<
    QuestionnaireSummarySaved[]
  >(mentor && primaryMentor ? `/api/v1/mentors/${mentor.uid}/questionnaires` : null, undefined, {
    refetchDespiteCachedValue: true,
  })
  if (loadingMentor || loadingPrimaryMentor)
    return <Page route={route} children={<SpinnerWithLabel label="Verifying mentor..." />} />

  if (loadingSessions || loadingQuestionnaires)
    return <Page route={route} children={<SpinnerWithLabel label="Loading data..." />} />

  if (mentor && !mentor.permission_parent_questionnaires && !mentor.permission_teacher_questionnaires)
    return <NotAuthorizedPage />

  const unlockedBooklets = sessions
    ? uniq(
        sessions.reduce(
          (acc, session) =>
            session.type === 'parent' && session.concluded && Object.keys(bookletMap).includes(session.module_code)
              ? [...acc, bookletMap[session.module_code as QuestionnaireModuleCode]]
              : acc,
          [] as number[]
        )
      )
    : []

  const unlockedResourceGroups = assessmentMaterialsResources.filter(
    ({ booklet }) => booklet === 1 || unlockedBooklets.includes(booklet)
  )
  const hasParentAccess = mentor?.permission_parent_questionnaires || false
  const hasTeacherAccess = mentor?.permission_teacher_questionnaires || false

  return (
    <Page route={route}>
      <P style={{ marginTop: 0 }}>
        The SAS Small Group Program includes assessment tools to assist the SAS Facilitator to adapt the program to meet
        individual Cadet needs and also to monitor and report progress over time.
      </P>
      <P>
        At 4 points across the program, 4 Questionnaire Booklets will be unlocked below. Your SAS Facilitator may prompt
        you to complete each booklet in an individual consultation, while in Parent Group Meetings or via email
        notification. If you have already completed a booklet, you will be able to view your previous entries. You will
        not need to repeat questionnaires if you receive an additional reminder notification email.
      </P>
      <P>If you are ever unsure, please contact your SAS Facilitator.</P>
      <br />
      {unlockedResourceGroups.map(resourceGroup => (
        <Fragment key={resourceGroup.booklet}>
          <QuestionnaireSet
            resourceGroup={resourceGroup}
            overviews={questionnaires || []}
            hasParentAccess={hasParentAccess}
            hasTeacherAccess={hasTeacherAccess}
          />
          <Spacer size="l" />
        </Fragment>
      ))}
      <Button
        size="xs"
        theme="grey"
        children="Refresh"
        onClick={() => {
          refetchSessions()
          refetchQuestionnaires()
        }}
      />
    </Page>
  )
}

interface QuestionnaireSetProps {
  resourceGroup: AssessmentResourceGroup
  overviews: QuestionnaireSummarySaved[]
  hasTeacherAccess: boolean
  hasParentAccess: boolean
}

const QuestionnaireSet: React.FC<QuestionnaireSetProps> = ({
  resourceGroup,
  overviews,
  hasParentAccess,
  hasTeacherAccess,
}) => {
  const { title, booklet } = resourceGroup
  const availableResources = resourceGroup.resources.filter(
    ({ type }) => (type === 'teacher' && hasTeacherAccess) || (type === 'parent' && hasParentAccess)
  )
  return (
    <>
      <HeaderHr children={title} />
      <Cells>
        {availableResources.map(({ title, type, questionnaireTypes }) => {
          const theme = getThemeFromResourceType(type)
          const questionnaireTypeOverviews = questionnaireTypes.map(questionnaireType => ({
            questionnaireType,
            overview: overviews.find(
              overview =>
                overview.questionnaire_type === questionnaireType && overview.questionnaire_booklet === booklet
            ),
          }))
          const started = overviews.filter(overview => overview.questionnaire_booklet === booklet).length > 0
          const completed =
            questionnaireTypeOverviews.filter(({ overview }) => !!overview).length === questionnaireTypes.length
          const resumeQuestionnaireType =
            !started || completed
              ? questionnaireTypes[0]
              : questionnaireTypeOverviews.find(({ overview }) => !overview)?.questionnaireType || questionnaireTypes[0]

          return (
            <Cell key={`${booklet}-${type}`} accent={theme}>
              <CellInner>
                <Column flex="1 1 auto">
                  <H3>{title}</H3>
                </Column>
                <Row flex="none" alignItems="center">
                  <ThemeProvider theme={parentGreen}>
                    {intersperseSpacers(
                      questionnaireTypeOverviews.map(({ questionnaireType, overview }) => (
                        <CheckboxLabel
                          key={questionnaireType}
                          size="xs"
                          style={{ opacity: 0.5, fontSize: 12, marginBottom: 1, marginLeft: -4, paddingRight: 10 }}
                          children={
                            <>
                              {!!overview && <span style={{ color: '#4db900', paddingRight: 5 }} children="✔︎" />}
                              {questionnaireTypeLabels[questionnaireType].replace(/\s\([\w\s]+\)$/, '')}
                            </>
                          }
                        />
                      )),
                      's'
                    )}
                  </ThemeProvider>
                  <Spacer size="s" />

                  <LinkButton
                    theme={completed ? 'grey' : theme}
                    size="s"
                    children={completed ? 'View' : started ? 'Resume' : 'Begin'}
                    to={`${baseUrl}/questionnaires/${booklet}/${type}/${resumeQuestionnaireType}`}
                  />
                </Row>
              </CellInner>
            </Cell>
          )
        })}
      </Cells>
    </>
  )
}
