import React from 'react'
import { useGlobalState } from 'app/GlobalState'
import { Button, Spacer, Row } from './ui'

const PlaybackRate: React.FC = () => {
  const { playbackRate, setPlaybackRate } = useGlobalState()
  return (
    <Row>
      <Button size="s" flex pressed={playbackRate === 1} onClick={() => setPlaybackRate(1)}>
        1x
      </Button>
      <Spacer size="xs" />
      <Button size="s" flex pressed={playbackRate === 2} onClick={() => setPlaybackRate(2)}>
        2x
      </Button>
      <Spacer size="xs" />
      <Button size="s" flex pressed={playbackRate === 3} onClick={() => setPlaybackRate(3)}>
        3x
      </Button>
      <Spacer size="xs" />
      <Button id="set-playback-rate-4" size="s" flex pressed={playbackRate === 4} onClick={() => setPlaybackRate(4)}>
        4x
      </Button>
    </Row>
  )
}

export default PlaybackRate
