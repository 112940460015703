import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

type ScrollPosition = { x: number; y: number }

export function getScrollPosition<T extends HTMLElement>(elem?: Window | T): ScrollPosition {
  if (elem instanceof HTMLElement) return { x: elem.scrollLeft, y: elem.scrollTop }
  if (elem === window) return { x: elem.pageXOffset, y: elem.pageYOffset }
  return { x: window.pageXOffset, y: window.pageYOffset }
}

export function useScrollPosition<T extends HTMLElement>(target: Window | T = window, throttleMs: number = 1000 / 60) {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>(getScrollPosition())
  useEffect(() => {
    const handleScroll = (e: Event) => setScrollPosition(getScrollPosition(target))
    const thottledHandleScroll = throttle(handleScroll, throttleMs)
    target.addEventListener('scroll', thottledHandleScroll)
    return () => target.removeEventListener('scroll', thottledHandleScroll)
  }, [target, throttleMs])
  return scrollPosition
}
