import React from 'react'
import styled from 'styled-components'

import { useMeasure } from 'utils/useMeasure'

export const AspectRatio: React.FC<{ ratio: number }> = ({ ratio: contentRatio, children }) => {
  const [measureContainer, { width, height }] = useMeasure()
  const containerRatio = width / height
  let contentWidth, contentHeight
  if (contentRatio > containerRatio) {
    contentWidth = width
    contentHeight = (1 / contentRatio) * width
  } else {
    contentHeight = height
    contentWidth = contentRatio * height
  }
  return (
    <Container ref={measureContainer}>
      {width > 0 && height > 0 && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            left: (width - contentWidth) / 2,
            top: (height - contentHeight) / 2,
            width: contentWidth,
            height: contentHeight,
          }}>
          {children}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
