import React, { ReactNode, useCallback, useMemo } from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { Field } from 'common/Field'
import { ValidationErrorShape } from './Form'
import { FormDataSubsetReturn } from 'dashboards/utils/formDataHooks'

export interface FormFieldDataProps<FormDataType>
  extends Pick<FormDataSubsetReturn<FormDataType>, 'formData' | 'setFormData' | 'validationErrors'> {}

interface Props<FormDataType, ValueType> extends FormFieldDataProps<FormDataType> {
  label?: ReactNode
  defaultValue: ValueType
  select: keyof FormDataType | [keyof FormDataType, ...(string | number)[]]
  children: (props: RenderProps<FormDataType, ValueType>) => ReactNode
  beforeOnChange?: (value: ValueType, select: keyof FormDataType) => boolean
}

interface RenderProps<FormDataType, ValueType> {
  value: ValueType
  onChange: (value: ValueType) => void
  validationErrors: ValidationErrorShape
  select: [keyof FormDataType] | [keyof FormDataType, ...(string | number)[]]
  lastSelectKey: keyof FormDataType | (string | number)
}

export const FormField = <FormDataType extends object, ValueType extends any>({
  label,
  defaultValue,
  children,
  select: _select,
  beforeOnChange,
  formData,
  setFormData,
  validationErrors,
}: Props<FormDataType, ValueType>) => {
  const select = useMemo(
    () =>
      typeof _select === 'string'
        ? ([_select] as [keyof FormDataType])
        : (_select as [keyof FormDataType, ...(string | number)[]]),
    [_select]
  )
  const value: ValueType = _get(formData, select, defaultValue)
  const valueValidationErrors: ValidationErrorShape = _get(validationErrors, select, {})
  const errors = (Object.values(valueValidationErrors).filter((v) => typeof v === 'string') as string[]).map(
    (string) => '^ ' + string
  )
  const onChange = useCallback(
    (value: ValueType) => {
      if (beforeOnChange && !beforeOnChange(value, select[0])) return
      const newFormData = { ...formData }
      _set(newFormData, select, value)
      setFormData(newFormData)
    },
    [beforeOnChange, formData, setFormData, select]
  )
  return (
    <Field label={label} errors={errors}>
      {children({
        value,
        validationErrors: valueValidationErrors,
        onChange,
        select,
        lastSelectKey: select[select.length - 1],
      })}
    </Field>
  )
}
