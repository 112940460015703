import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router'

import { useShortcutButtons, useGlobalState } from 'app/GlobalState'
import { useUserState } from 'app/UserState'
import { AutoProgressVideo } from 'common/AutoProgressVideo'
import { decodeJWT } from 'utils/decodeJWT'
import { getProfiles, getActivityAttempts, validateDeviceToken } from 'api'
import { THEMES } from 'themes'
import { preloadVideo } from 'utils/preloadUtils'
import { PreloadStrategy } from 'utils/VideoPool'
import { isPhone } from 'utils/deviceUtils'

import { ExistingAgentPage } from './ExistingAgentPage'
import { LoginPage, LoginPageProps } from './LoginPage'
import { NewAgentPage } from './NewAgentPage'
import { AgentSelectPage } from './AgentSelectPage'
import { ProfileNotFoundPage } from './ProfileNotFoundPage'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { gtagLogin } from 'app/GoogleAnalytics'
import {
  DeviceAuthorisationFailedPage,
  DeviceAuthorisationPage,
  DeviceAuthorisationReturnPage,
  DeviceAuthorisationSuccessPage,
} from './DeviceAuthorisationPage'
import { authorize } from 'auth/oauth2'
import {
  isDeviceAuthInProgress,
  getDeviceToken,
  setDeviceAuthInProgress,
  setHashedGameCode,
  getHashedGameCode,
  getDeviceAuthToken,
  gameModesRequiringDeviceAuth,
} from 'auth/deviceAuth'
import { logoutDrupalUser } from 'auth/logoutDrupalUser'
import { GameModeSlug } from 'shared/types'

type Screen =
  | 'login'
  | 'device-authorization'
  | 'device-authorization-success'
  | 'device-authorization-failed'
  | 'device-authorization-return'
  | 'splash'
  | 'agent-select'
  | 'intro'
  | 'new-agent'
  | 'existing-agent'
  | 'orientation'
  | 'orientation-2'
  | 'profile-not-found'

const screens: Screen[] = [
  'login',
  'device-authorization',
  'device-authorization-success',
  'device-authorization-failed',
  'splash',
  'agent-select',
  'intro',
  'new-agent',
  'existing-agent',
  'orientation',
  'profile-not-found',
]

const splashVideo = require('./assets/video/0011.mp4')

export const HomeCadet: React.FC = () => {
  const {
    accessToken,
    setProfiles,
    setActivityAttempts,
    profileName,
    setProfileName,
    setProfileId,
    setGameMode,
    setOfflineMode,
    setOfflineMentor,
  } = useUserState()
  const { setThemeIndex } = useGlobalState()
  const [screen, setScreen] = useState<Screen>(() =>
    isDeviceAuthInProgress() && getDeviceAuthToken() ? 'device-authorization-return' : 'login'
  )
  const history = useHistory()
  useDisableOrientationLock()

  useEffect(() => {
    !isPhone() && preloadVideo(splashVideo, PreloadStrategy.PlayMuted)
  }, [])

  useShortcutButtons(
    screens.map(s => ({ label: s.replace(/-/g, ' '), onClick: () => setScreen(s), pressed: screen === s }))
  )

  const afterLogin = (accessToken: string, hashedGameCode: string, redirectUri?: string) => {
    const payload = decodeJWT(accessToken)

    if (payload.scopes.indexOf('legacy-flash') !== -1) {
      history.push('/flash')
      return
    }

    getProfiles(accessToken)
      .then(profiles => {
        // store profiles in local storage with the key being the hashed game code
        localStorage.setItem(hashedGameCode, JSON.stringify(profiles))

        // prefer non-open_access, but if they only have open_access profile, then use that
        const profile = profiles.find(
          p => (p.profile_name || '').toLowerCase() !== 'open_access' || profiles.length === 1
        )
        if (!profile) throw new Error('No profile found')

        gtagLogin(payload.scopes.join(' '), profile.allowed_from, profile.intervention_type)

        return getActivityAttempts(profile.id, accessToken).then(attempts => {
          ReactDOM.unstable_batchedUpdates(() => {
            setOfflineMode(false)
            setOfflineMentor(false)
            setProfiles(profiles)
            setProfileId(profile.id)
            setProfileName(profile.profile_name || '')
            if (profile.intervention_type) setGameMode(profile.intervention_type)
            if (profile.theme_name) {
              const themeIndex = THEMES.findIndex(({ name }) => name === profile.theme_name)
              if (themeIndex !== -1) setThemeIndex(themeIndex || 0)
            }
          })
          // must be called after setProfileId has rendered, otherwise setActivityAttempts won't work properly
          setActivityAttempts(attempts)
          return profile
        })
      })
      .then(profile => {
        if (redirectUri) {
          history.push(redirectUri)
        } else {
          if (!profile.profile_name && isPhone()) {
            setScreen('profile-not-found')
          } else if (isPhone()) {
            setScreen('existing-agent')
          } else {
            setScreen('splash')
          }
        }
      })
      .catch(console.log)
  }

  const onOfflineLogin: LoginPageProps['onOfflineLogin'] = (offlineProfiles, redirectUri) => {
    // prefer non-open_access, but if they only have open_access profile, then use that
    const profile = offlineProfiles.find(
      p => (p.profile_name || '').toLowerCase() !== 'open_access' || offlineProfiles.length === 1
    )
    if (!profile) throw new Error('No profile found')

    ReactDOM.unstable_batchedUpdates(() => {
      setOfflineMode(true)
      setOfflineMentor(false)
      setProfiles(offlineProfiles)
      setProfileId(profile.id)
      setProfileName(profile.profile_name || '')
      if (profile.intervention_type) setGameMode(profile.intervention_type)
      if (profile.theme_name) {
        const themeIndex = THEMES.findIndex(({ name }) => name === profile.theme_name)
        if (themeIndex !== -1) setThemeIndex(themeIndex || 0)
      }
    })

    if (redirectUri) {
      history.push(redirectUri)
    } else {
      if (!profile.profile_name && isPhone()) {
        setScreen('profile-not-found')
      } else if (isPhone()) {
        setScreen('existing-agent')
      } else {
        setScreen('splash')
      }
    }
  }

  const onLogin: LoginPageProps['onLogin'] = async (hashedGameCode, accessToken, redirectUri) => {
    const payload = decodeJWT(accessToken)

    if (payload.scopes.indexOf('legacy-flash') !== -1) {
      history.push('/flash')
      return
    }

    if (
      process.env.REACT_APP_REQUIRE_DEVICE_AUTH === '1' &&
      // only require device auth for specific game modes.
      // the intervention type is added to the access token scopes
      // so we can check this easily without fetching profiles
      payload.scopes.find(scope => gameModesRequiringDeviceAuth.includes(scope as GameModeSlug)) !== undefined
    ) {
      // authorize device using stored device token
      setHashedGameCode(hashedGameCode)
      const deviceToken = getDeviceToken(hashedGameCode) || ''
      try {
        if (!deviceToken) throw new Error()
        await validateDeviceToken(accessToken, deviceToken)
      } catch (e) {
        setScreen('device-authorization')
        return
      }
    }

    afterLogin(accessToken, hashedGameCode, redirectUri)
  }

  const beginAuthorise = () => {
    if (isDeviceAuthInProgress()) {
      logoutDrupalUser(authorize)
    } else {
      setDeviceAuthInProgress(true)
      authorize()
    }
  }

  return (
    <>
      {screen === 'login' && <LoginPage onLogin={onLogin} onOfflineLogin={onOfflineLogin} />}
      {screen === 'device-authorization' && <DeviceAuthorisationPage onContinue={beginAuthorise} />}
      {screen === 'device-authorization-return' && (
        <DeviceAuthorisationReturnPage
          onContinue={() => setScreen('device-authorization-success')}
          onError={() => setScreen('device-authorization-failed')}
        />
      )}
      {screen === 'device-authorization-success' && (
        <DeviceAuthorisationSuccessPage
          onContinue={() => {
            logoutDrupalUser(() => {
              afterLogin(accessToken, getHashedGameCode() || '')
            })
          }}
        />
      )}
      {screen === 'device-authorization-failed' && <DeviceAuthorisationFailedPage onContinue={beginAuthorise} />}
      {screen === 'agent-select' && (
        <AgentSelectPage onNewAgent={() => setScreen('intro')} onExistingAgent={() => setScreen('existing-agent')} />
      )}
      {screen === 'new-agent' && (
        <NewAgentPage
          onComplete={openAccess => (openAccess ? history.push('/game') : setScreen('orientation'))}
          onContinue={() => setScreen('agent-select')}
        />
      )}
      {screen === 'existing-agent' && (
        <ExistingAgentPage onComplete={() => history.push('/game')} onContinue={() => setScreen('agent-select')} />
      )}
      {screen === 'splash' && (
        <AutoProgressVideo
          src={splashVideo}
          onComplete={() => setScreen(profileName ? 'existing-agent' : 'agent-select')}
          exitButton={false}
        />
      )}
      {screen === 'intro' && (
        <AutoProgressVideo
          src={require('./assets/video/0013.mp4')}
          onComplete={() => setScreen('new-agent')}
          exitButton={false}
        />
      )}
      {screen === 'orientation' && (
        <AutoProgressVideo
          src={require('./assets/video/0201.mp4')}
          onComplete={() => setScreen('orientation-2')}
          exitButton={false}
        />
      )}
      {screen === 'orientation-2' && (
        <AutoProgressVideo
          src={require('./assets/video/0202.mp4')}
          onComplete={() => history.push('/game')}
          exitButton={false}
        />
      )}
      {screen === 'profile-not-found' && <ProfileNotFoundPage />}
    </>
  )
}
