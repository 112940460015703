import { editCadetName } from 'api'
import { useUserState } from 'app/UserState'
import { Field } from 'common/Field'
import { SVG } from 'common/SVG'
import { InfoTooltip } from 'common/Tooltip'
import { Button, H2, Hr, Input, P, Row, Spacer } from 'common/ui'
import { MentorPanel as Panel } from 'dashboards/common/MentorPanel'
import { fontLight } from 'fonts'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { CircleButton } from 'session/common/CircleButton'
import { MentorEntity, ProductKey } from 'shared/dashboard/types'
import styled from 'styled-components'
import { CadetNameAndStatus } from './CadetNameAndStatus'

export const LicenseDetails: React.FC<{
  productKey: ProductKey
  type: 'facilitator' | 'mentor'
  mentor?: MentorEntity
}> = ({ productKey, type, mentor }) => {
  const [showReset, setShowReset] = useState<boolean>(false)
  const [editName, setEditName] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>(mentor?.primary_licence_holder ? mentor?.cadet_first_name : '')
  const [lastName, setLastName] = useState<string>(mentor?.primary_licence_holder ? mentor?.cadet_last_name : '')
  const [currentName, setCurrentName] = useState<string>(`${firstName} ${lastName}`)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [editVisible, setEditVisible] = useState<boolean>(false)
  const { accessToken } = useUserState()

  const handleSubmit = () => {
    setSubmitting(true)
    if (mentor)
      editCadetName(mentor.uid, firstName, lastName, accessToken).then(res => {
        if (res.success) {
          ReactDOM.unstable_batchedUpdates(() => {
            setSubmitting(false)
            setEditName(false)
            setCurrentName(`${firstName} ${lastName}`)
          })
        }
      })
  }

  return (
    <>
      {type === 'mentor' && (
        <>
          <Spacer />
          <P>Each Cadet Place provides 12 months access to the SAS Small Group Program.</P>
          <P style={{ maxWidth: 750 }}>
            SAS Digital HQ is the Cadet's access to SAS Small Group Program software for each Club Meeting and often
            daily between sessions to play the 4 levels, view their Gadget Pack items, and to use their Mission Journal
            and Skill Tracker.
          </P>
        </>
      )}
      <Panel style={{ width: '100%', maxWidth: 650, overflow: 'visible', marginLeft: 0 }}>
        <Wrapper>
          <Row alignItems="center">
            <P style={{ marginBottom: 0 }}>
              <strong>{type === 'mentor' ? 'Cadet Place Product Key: ' : 'Product Key: '}</strong>
              {productKey.product_key}
            </P>
            {type === 'facilitator' && (
              <InfoTooltip
                iconSize={14}
                placement="right"
                content={
                  "Your SAS Facilitator Product Key is valid and active when you are completing the SAS Facilitator Training Course and also when you are linked to a subscribed SAS Provider service. If you change SAS Provider's your SAS Product Key will reactivate upon linking with the new provider."
                }
              />
            )}
          </Row>

          {type === 'mentor' && mentor?.primary_licence_holder && (
            <>
              <Row alignItems="center">
                <P
                  style={{ marginBottom: 0 }}
                  onMouseEnter={() => setEditVisible(true)}
                  onMouseLeave={() => setEditVisible(false)}>
                  <strong>Cadet Name: </strong>
                  {currentName}
                  &nbsp;
                  <UnderlineButton visible={editVisible} theme="purple" onClick={() => setEditName(!editName)}>
                    <SVG size={12} viewboxSize={24}>
                      <path
                        fill="#7f8dff"
                        d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                      />
                    </SVG>
                  </UnderlineButton>
                </P>
              </Row>
              {editName && (
                <>
                  <Spacer />
                  <EditContainer>
                    <Row justifyContent="flex-end">
                      <CircleButton
                        children="&times;"
                        onClick={() => setEditName(false)}
                        size="xs"
                        style={{ fontSize: 15, cursor: 'pointer' }}
                      />
                    </Row>
                    <Spacer size="s" />
                    <P>
                      If you made a mistake, this will edit the child's name. The child's name is visible to
                      facilitators, and to mentors who receive Teacher Tip Sheets. This is <strong>not</strong> the
                      child's "Agent Name" that they see when they log into SAS Digital HQ.
                    </P>
                    <Row>
                      <Field label="First Name" fieldProps={{ style: { width: 'calc(50% - 7.5px)' } }}>
                        <Input
                          disabled={submitting}
                          inputSize="s"
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </Field>
                      <Spacer />
                      <Field label="Last Name" fieldProps={{ style: { width: 'calc(50% - 7.5px)' } }}>
                        <Input
                          disabled={submitting}
                          inputSize="s"
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                        />
                      </Field>
                    </Row>
                    <Spacer />
                    <Row>
                      <Button
                        disabled={submitting}
                        size="s"
                        children={submitting ? 'Processing...' : 'submit'}
                        onClick={handleSubmit}
                      />
                    </Row>
                  </EditContainer>
                  <Spacer />
                </>
              )}
            </>
          )}
          {type === 'mentor' && (
            <Row alignItems="center" alignContent="center">
              <P style={{ marginBottom: 0 }}>
                <strong>Expiry: </strong>
                {productKey.expiry ? new Date(parseInt(productKey.expiry) * 1000).toLocaleDateString() : ''}
              </P>
              <Spacer size="s" />
              {mentor && (
                <div style={{ marginTop: 7 }}>
                  <CadetNameAndStatus primaryMentor={mentor} hideName />
                </div>
              )}
            </Row>
          )}
          <P style={{ marginBottom: 0 }}>
            <strong>Game Play Code: </strong>
            {productKey.game_play_code ? (
              productKey.game_play_code
            ) : (
              <a
                href={`${process.env.REACT_APP_SST_BASE_URL}/activate?licence_uuid=${productKey.licence_uuid}`}
                rel="noopener noreferrer"
                target="_blank">
                Activate product key
              </a>
            )}
          </P>
          {productKey && (
            <P style={{ marginBottom: 0 }}>
              <strong>To enter SAS Digital HQ: </strong>
              <a href={`${process.env.REACT_APP_CADET_URL}`} rel="noopener noreferrer" target="_blank">
                www.playsas.net
              </a>
            </P>
          )}
          {type === 'facilitator' && (
            <P>
              <strong>To enter your demonstration Skill Tracker: </strong>
              <a
                href={`${process.env.REACT_APP_CADET_URL}/skill-tracker/mentor`}
                rel="noopener noreferrer"
                target="_blank">
                www.playsas.net<small>/</small>skill-tracker<small>/</small>mentor
              </a>
            </P>
          )}
        </Wrapper>
      </Panel>
      <Spacer />
      {type === 'mentor' && (
        <>
          <Spacer size="xl" />
          <Row style={{ maxWidth: 750 }}>
            <img height={80} src={require('../assets/shield.png')} alt="Shield" />
            <Spacer />
            <P style={{ marginTop: 0 }}>
              To access their special agent profile, they enter the 5-digit Game Play Code which is provided after a
              Cadet Place product key is activated. An adult Security Mission is required every 30 days or when the
              child changes the device or browser they are using to access SAS software.
            </P>
          </Row>
        </>
      )}
      {productKey.game_play_code && (
        <Panel style={{ width: '100%', maxWidth: 650, marginLeft: 0 }}>
          <Wrapper>
            <Row
              alignItems="center"
              justifyContent="space-between"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowReset(!showReset)}>
              <P style={{ margin: 0 }}>
                <strong>
                  {type === 'mentor' ? 'Reset Game Play Code or renew Cadet Place' : 'Reset Game Play Code'}
                </strong>
              </P>
              <CircleButton
                size="xs"
                children={showReset ? '▲' : '▼'}
                style={{ lineHeight: 'initial', cursor: 'pointer' }}
              />
            </Row>
            {showReset && (
              <>
                <Spacer />
                <P>
                  If you need to reset your Game Play Code (e.g. if it has been compromised), you can do so without
                  losing your or your child's saved data.
                </P>
                <H2>Step 1</H2>
                <P>Select "Reset Game Play Code"</P>
                <H2>Step 2</H2>
                <P>Return to this portal and refresh the page (or log out and then log in)</P>
                <H2>Step 3</H2>
                <P>View the new Game Play Code above</P>
                <P>
                  <a
                    href={`${process.env.REACT_APP_SST_BASE_URL}/mysas/keys`}
                    rel="noopener noreferrer"
                    target="_blank">
                    Reset Game Play Code
                  </a>
                </P>
                {type === 'mentor' && (
                  <>
                    <Hr margin="l" />
                    <P>
                      If you need to renew your Cadet Place for a further 12 months, you can do so before the expiry
                      date.
                    </P>
                    <H2>Step 1</H2>
                    <P>Select "Renew expiring Cadet Place"</P>
                    <H2>Step 2</H2>
                    <P>Follow the prompts to make payment for the renewal.</P>
                    <P>
                      <a
                        href={`${process.env.REACT_APP_SST_BASE_URL}/mysas/keys`}
                        rel="noopener noreferrer"
                        target="_blank">
                        Renew expiring Cadet Place
                      </a>
                    </P>
                    <P>
                      Please note: Cadet Places can only be renewed from one month before their expiry date. If your
                      Cadet Place has already expired, please contact your SAS Facilitator or{' '}
                      <a href="mailto:support@sst-institute.net">support@sst-institute.net</a> to discuss.
                    </P>
                  </>
                )}
              </>
            )}
          </Wrapper>
        </Panel>
      )}
    </>
  )
}

const Wrapper = styled.div`
  ${P} {
    ${fontLight};
    font-size: 1.1rem;
  }

  ${Row} span {
    ${fontLight};
    color: #011a46;
  }

  ${Row} a {
    color: ${p => p.theme.buttonBackgroundTopColor};
  }
`

const UnderlineButton = styled.span<{ visible: boolean }>`
  text-decoration: underline;
  cursor: pointer;
  color: #7f8dff !important;
  opacity: ${p => (p.visible ? 1 : 0)};
  transition: 0.3s;
`

const EditContainer = styled.div`
  background: #f7f7f7;
  border: 1px solid #cdd2e4;
  padding: 15px;
  border-radius: 10px;
`
