import React from 'react'

import { P, CollapseMargin, Center, Spacer, Button } from 'common/ui'
import { IntroTemplate } from 'home/IntroTemplate'

export const NotAuthorizedPage: React.FC<{ message?: string }> = ({ message, children }) => {
  return (
    <IntroTemplate title="Not Authorized">
      <CollapseMargin>
        <P align="center">You are not authorized to access the requested page.</P>
        {message && <P align="center" children={message} />}
      </CollapseMargin>
      {children}
      <Spacer size="l" />
      <Center>
        <Button size="s" onClick={() => window.history.back()}>
          Back
        </Button>
      </Center>
    </IntroTemplate>
  )
}
