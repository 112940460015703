import React from 'react'
import styled from 'styled-components'

import { Column, Row, Spacer, Button, Panel, P } from 'common/ui'
import { JournalMission } from './common/JournalMission'
import { Activity } from 'types'
import { useMedia } from 'utils/useMedia'
import { useHistory, useLocation } from 'react-router'
import { useUserState } from 'app/UserState'
import { useGlobalState } from 'app/GlobalState'
import { subscriptToSmall } from 'utils/subscriptToSmall'
import { cleanRouteState } from 'utils/routeUtils'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { fontBold } from 'fonts'

interface Props {
  activities: Activity[]
  onComplete: () => void
}

const description = `Each of the home missions in this journal is designed to help young people learn how to feel happier and calmer
and to make friends. These skills are taught in the Secret Agent Society group program. If you aren’t a member of
a Secret Agent Society group, please refer to the resources in your Computer Game Pack for a description of the
social skills or ‘codes’ that young people learn. To successfully complete the journal home missions, you will
need to understand and practise these code steps. Try to look at your SAS Code Cards and Emotionometers in secret
to remind yourself how to stay calm and make friends!`

const digiSasDescription = `Each of the missions in this journal are designed to help club members to feel happier, calmer and braver and to get along with others. These skills are taught in the Club Meetings of the SAS Small Group Program. When completing your missions, try to refer to your SAS Code Cards and Emotionometers in private to remind yourself how to stay calm and talk or play with others.`

export const JournalMenu: React.FC<Props> = ({ activities, onComplete }) => {
  const { hasPermissionFor, hasCompleted } = useUserState()
  const { setExitUrl } = useGlobalState()
  const location = useLocation()
  const history = useHistory()
  const compact = useMedia('(max-width: 680px)')
  useDisableOrientationLock()
  const { gameMode, openAccess } = useUserState()
  const enabledActivities = activities.filter(hasPermissionFor)
  return (
    <JournalMission
      title="Mission Journal"
      description={gameMode === 'SAS-SG-D' ? digiSasDescription : description}
      compact={compact}
      onExit={onComplete}
      showNav={gameMode === 'SAS-SG-D' && !openAccess}>
      {!enabledActivities.length && (
        <P color="white">Your mission journal will unlock as you progress through the levels of game play.</P>
      )}
      <Spacer />
      <Row flexWrap marginTop={-15} marginLeft={-15} userSelectNone>
        {enabledActivities.map((activity, i) => {
          return (
            <MenuItemBase key={i}>
              <JournalInner flex completed={hasCompleted(activity)}>
                <Icon>
                  <img src={require(`./common/assets/image/${activity.id}.png`)} alt="" />
                </Icon>
                <ActivityTitle>{subscriptToSmall(activity.title)}</ActivityTitle>
                <Spacer flex />
                <Button
                  onClick={() => {
                    setExitUrl(window.location.pathname)
                    history.push(activity.path || '', cleanRouteState(location.state))
                  }}>
                  Start
                </Button>
              </JournalInner>
            </MenuItemBase>
          )
        })}
        <Column marginTop marginLeft flex="1 1 230px" />
        <Column marginTop marginLeft flex="1 1 230px" />
        <Column marginTop marginLeft flex="1 1 230px" />
        <Column marginTop marginLeft flex="1 1 230px" />
        <Column marginTop marginLeft flex="1 1 230px" />
        <Column marginTop marginLeft flex="1 1 230px" />
        <Column marginTop marginLeft flex="1 1 230px" />
        <Column marginTop marginLeft flex="1 1 230px" />
        <Column marginTop marginLeft flex="1 1 230px" />
      </Row>
    </JournalMission>
  )
}

const MenuItemBase = styled(Panel)`
  flex: 1 1 230px;
  margin: 15px 0 0 15px;
  box-shadow: 0px -4px 10px rgba(255, 255, 255, 0.44), 0px 4px 0px #bdc3e0, 0px 10px 10px rgba(0, 0, 0, 0.2),
    inset 0px -2px 2px rgba(189, 195, 224, 0.5), inset 0px 2px 2px #ffffff;
`

const JournalInner = styled(Column)<{ completed: boolean }>`
  padding: 15px;
  padding-top: 20px;
  min-height: 150px;
  background: ${p =>
      p.completed
        ? `url(${require('common/assets/image/tick@2x.png')}) no-repeat right 12px top 15px / 24px 24px,`
        : ''}
    no-repeat;
`

const ActivityTitle = styled.p`
  ${fontBold};
  margin: 0;
  padding-right: 5px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #011a46;
`

const Icon = styled.div`
  margin-left: -3px;
  margin-top: -5px;
  margin-bottom: 5px;
  border-radius: 10px;
  overflow: hidden;
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
  }
`
