type RgbObject = { red: number; green: number; blue: number }

export function componentToHex(c: number): string {
  var hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export function rgbToHex(rgb: RgbObject, prefix: string = '#'): string {
  return prefix + componentToHex(rgb.red) + componentToHex(rgb.green) + componentToHex(rgb.blue)
}

export function hexToRgb(hex: string): RgbObject {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result) throw new Error('Invalid hex value')

  return {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16),
  }
}

export function luminance(rgb: RgbObject): number {
  const C = [rgb.red / 255, rgb.green / 255, rgb.blue / 255].map(val =>
    val < 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4)
  )
  return 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2]
}

export function luminanceHex(hexColor: string): number {
  return luminance(hexToRgb(hexColor))
}

export function blackOrWhite(bgColorHex: string, threshold: number = 105): '#000000' | '#FFFFFF' {
  const { red, green, blue } = hexToRgb(bgColorHex)
  const bgDelta = red * 0.299 + green * 0.587 + blue * 0.114
  return 255 - bgDelta < threshold ? '#000000' : '#FFFFFF'
}
