import React from 'react'
import { MediaGrid, MediaGridColumn, MediaGridInner } from 'session/sections/MediaGrid'
import { SectionPropsBase } from 'shared/training/types'

interface Props extends SectionPropsBase {
  property: 'streamed_video_grid_sections'
}

export const StreamedVideoGridSection: React.FC<Props> = ({ section }) => {
  const { columns, items } = section
  return (
    <MediaGrid>
      {(items || []).map((item, i) => (
        <MediaGridColumn key={i} columns={columns}>
          <MediaGridInner>
            <div className="videoWrapper" style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
              <iframe
                title="stream"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                src={`https://www.sst-institute.net/embed?url=${item.video_url}`}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                width="100%"></iframe>
            </div>
          </MediaGridInner>
        </MediaGridColumn>
      ))}
    </MediaGrid>
  )
}
