import React from 'react'
import { useUserState } from 'app/UserState'
import { gameModes } from 'activities'
import { Row, Button, Spacer } from 'common/ui'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { GameModeSlug } from 'shared/types'

export const GameModeSelector: React.FC = () => {
  const { gameMode: selected, setGameMode, openAccess, setOpenAccess } = useUserState()
  return (
    <Row>
      {intersperseSpacers(
        (Object.keys(gameModes) as GameModeSlug[]).map(gameMode => (
          <Button key={gameMode} size="s" flex pressed={selected === gameMode} onClick={() => setGameMode(gameMode)}>
            {gameMode}
          </Button>
        )),
        'xs'
      )}
      <Spacer size="xs" />
      <Button size="s" flex pressed={openAccess} onClick={() => setOpenAccess(!openAccess)}>
        Open Access
      </Button>
    </Row>
  )
}
