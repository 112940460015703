import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { FactFile } from 'shared/gadget-pack/types'
import { ADD_FACT_FILE_WITH_ID } from 'shared/gadget-pack/actionTypes'
import { FactFileScreen, FactFilesScreen } from 'gadget-pack/FactFiles'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'

import { Container, RowWrapPortrait } from 'common/ui'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { useUserState } from 'app/UserState'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'

const emptyFactFile: FactFile = {
  id: '',
  name: '',
  friendshipStrength: -1,
  why: {},
  family: {},
  interests: {},
  other: {},
}

export const FactFileActivity: React.FC<{ overrideExit?: () => void; readOnly?: boolean }> = ({
  overrideExit,
  readOnly,
}) => {
  const profileUid = useParticipantProfileUid()

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${profileUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'fact-files',
          subcategory: null,
          event_key: null,
          event_value: null,
        }}
      />
      <FactFileComponent overrideExit={overrideExit} readOnly={readOnly} />
    </>
  )
}

const FactFileComponent: React.FC<{ overrideExit?: () => void; readOnly?: boolean }> = ({ overrideExit, readOnly }) => {
  const { getBaseAction } = useUserState()
  const profileUid = useParticipantProfileUid()
  const {
    state: { factFiles },
    dispatch,
  } = useGadgetPackState()

  const [showFile, setShowFile] = useState<boolean>(false)
  const [fileID, setFileId] = useState<string | null>(null)

  const handleCreateNew = () => {
    const fileID = String(factFiles.length + 1)
    ReactDOM.unstable_batchedUpdates(() => {
      dispatch({
        ...getBaseAction(),
        type: ADD_FACT_FILE_WITH_ID,
        factFile: { ...emptyFactFile, id: fileID },
      })
      setFileId(fileID)
      setShowFile(true)
    })
  }

  const handleModifyExisting = (id: string) =>
    ReactDOM.unstable_batchedUpdates(() => {
      setFileId(id)
      setShowFile(true)
    })

  const handleBackClick = () =>
    ReactDOM.unstable_batchedUpdates(() => {
      setFileId('')
      setShowFile(false)
    })

  return (
    <FactFileWrapper>
      {!showFile && (
        <FactFilesScreen
          hideExitButton={overrideExit ? false : true}
          overrideExit={overrideExit}
          disableRouting
          createNewOverride={handleCreateNew}
          openExistingOverride={handleModifyExisting}
          readOnly={readOnly}
        />
      )}

      {showFile && fileID !== null && (
        <FactFileScreen
          hideDownloadButton
          hidePrintButton
          disableRouting
          fileID={fileID}
          backToFilesOverride={handleBackClick}
          readOnly={readOnly}
          inputLockProps={{
            hasLabel: true,
            fieldUid: `${profileUid}_fact_file_${fileID}`,
            verticalPlacementFacilitator: -27,
            horizontalPlacementFacilitator: 85,
            verticalPlacementCadet: -27,
            horizontalPlacementCadet: 85,
          }}
        />
      )}
    </FactFileWrapper>
  )
}

const FactFileWrapper = styled.div`
  .gadget-header-container {
    width: 100%;
  }

  .gadget-header-spacer {
    min-width: auto;
  }

  & ${RowWrapPortrait} {
    justify-content: flex-end;
  }

  ${Container} {
    width: 100%;
  }
`
