import React, { CSSProperties, ReactNode, useState, useContext } from 'react'
import styled, { css, keyframes, StyledComponent, ThemeContext } from 'styled-components'
import { SVG } from './SVG'

/**
 * Loosely adapted from react-simple-tooltip
 * https://github.com/cedricdelpoux/react-simple-tooltip.git
 * @ v2.4.0
 */

const Container = styled.span`
  position: relative;
  display: inline-block;
`

export interface TooltipProps {
  arrow?: number
  content: ReactNode
  border?: string
  fadeDuration?: number
  fadeEasing?: CSSProperties['animationTimingFunction']
  fixed?: boolean
  offset?: number
  placement?: 'left' | 'top' | 'right' | 'bottom' | 'bottomLeft' | 'bottomRight'
  radius?: number
  tooltipStyle?: CSSProperties
  containerStyle?: CSSProperties
  theme?: 'light' | 'dark'
}

const getDefaultTooltipStyle = (theme: TooltipProps['theme']): CSSProperties => ({
  fontFamily: 'inherit',
  fontWeight: 400,
  lineHeight: 1.3,
  textTransform: 'none',
  fontSize: '14px',
  padding: '14px 18px',
  zIndex: 10,
  background: theme === 'dark' ? '#464B61' : '#fff',
  color: theme === 'dark' ? '#fff' : '#011A46',
})

export const Tooltip: React.FC<TooltipProps> = ({
  arrow = 8,
  children,
  content,
  border = 'transparent',
  fadeDuration = 100,
  fadeEasing = 'linear',
  fixed = false,
  offset = 0,
  placement = 'top',
  theme = 'dark',
  radius = 8,
  tooltipStyle: _tooltipStyle,
  containerStyle,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const hasTrigger = children !== undefined && children !== null
  const tooltipStyle = { ...getDefaultTooltipStyle(theme), ..._tooltipStyle }
  const tooltipElement = (
    <TooltipElement
      open={!hasTrigger || fixed ? true : open}
      placement={placement}
      offset={offset + arrow}
      fadeEasing={fadeEasing}
      fadeDuration={fadeDuration}>
      <Bubble radius={radius} style={tooltipStyle} border={border}>
        <Arrow width={arrow} border={border} placement={placement} />
        <span style={{ whiteSpace: 'pre-wrap', color: tooltipStyle.color }}>{content}</span>
      </Bubble>
    </TooltipElement>
  )
  return hasTrigger ? (
    <Container
      role="tooltip"
      tabIndex={0}
      style={containerStyle}
      onFocus={!fixed ? () => setOpen(true) : undefined}
      onBlur={!fixed ? () => setOpen(false) : undefined}
      onMouseEnter={!fixed ? () => setOpen(true) : undefined}
      onMouseLeave={!fixed ? () => setOpen(false) : undefined}>
      {children}
      {tooltipElement}
    </Container>
  ) : (
    <Container style={containerStyle}>{tooltipElement}</Container>
  )
}

export interface TooltipInfoProps extends TooltipProps {
  iconSize?: number
  iconColor?: string
  iconBackground?: string
  svgStyle?: CSSProperties
}

export const InfoTooltip: React.FC<TooltipInfoProps> = ({
  iconSize = 18,
  iconColor = '#fff',
  iconBackground,
  svgStyle = {},
  children,
  ...tooltipProps
}) => {
  const theme = useContext(ThemeContext)
  const backgroundColor = iconBackground || theme.buttonFocusBorderBottomColor
  return (
    <Tooltip
      {...tooltipProps}
      placement={tooltipProps.placement || 'right'}
      tooltipStyle={{ minWidth: 250, ...(tooltipProps.tooltipStyle || {}) }}
      containerStyle={{ marginLeft: '0.5em', verticalAlign: 'middle', ...(tooltipProps.containerStyle || {}) }}
      children={
        children ? (
          children
        ) : (
          <SVG
            size={iconSize}
            viewboxSize={24}
            style={{ display: 'block', backgroundColor, ...svgStyle }}
            SvgWrapper={InfoSvgWrapper}
            children={
              <>
                <path d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" fill={backgroundColor} />
                <path d="M11,8.71V7.1H13V8.71ZM13,9.8v7.1H11V9.8Z" fill={iconColor} />
              </>
            }
          />
        )
      }
    />
  )
}

const InfoSvgWrapper = styled.svg<{ borderColor: string }>`
  cursor: help;
  border-radius: 100%;
  border: 2px solid ${p => p.theme.buttonBackgroundTopColor};
  box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.18);
  &:hover {
    border-color: ${p => p.theme.buttonFocusBackgroundBottomColor};
  }
`

const fadeAnimation = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`

const animation = (props: TooltipElementBaseProps) => css`
  animation: ${props.fadeDuration}ms ${props.fadeEasing} 0s 1 ${fadeAnimation};
`

interface TooltipElementBaseProps {
  fadeDuration: number
  fadeEasing: CSSProperties['animationTimingFunction']
  zIndex: CSSProperties['zIndex']
  offset: number
}

const TooltipElementBase = styled.div<TooltipElementBaseProps>`
  position: absolute;
  ${p => (p.fadeDuration && p.fadeDuration > 0 ? animation(p) : '')}
  ${p => (typeof p.zIndex === 'number' ? `z-index: ${p.zIndex};` : '')};
`

const TooltipElementTop = styled(TooltipElementBase)`
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: ${p => p.offset}px;
`

const TooltipElementBottom = styled(TooltipElementBase)`
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: ${p => p.offset}px;
`

const TooltipElementLeft = styled(TooltipElementBase)`
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  margin-right: ${p => p.offset}px;
`

const TooltipElementRight = styled(TooltipElementBase)`
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: ${p => p.offset}px;
`

const TooltipElementBottomLeft = styled(TooltipElementBase)`
  top: 100%;
  right: 50%;
  transform: translateX(20px);
  margin-top: ${p => p.offset}px;
`

const TooltipElementBottomRight = styled(TooltipElementBase)`
  top: 100%;
  left: 50%;
  transform: translateX(-20px);
  margin-top: ${p => p.offset}px;
`

const tooltips = {
  left: TooltipElementLeft,
  top: TooltipElementTop,
  right: TooltipElementRight,
  bottom: TooltipElementBottom,
  bottomLeft: TooltipElementBottomLeft,
  bottomRight: TooltipElementBottomRight,
}

interface TooltipElementProps
  extends Pick<TooltipProps, 'offset' | 'placement' | 'fadeDuration' | 'fadeEasing' | 'tooltipStyle'> {
  open: boolean
  placement: Exclude<TooltipProps['placement'], undefined>
}

const TooltipElement: React.FC<TooltipElementProps> = ({
  children,
  offset,
  open,
  placement,
  fadeDuration,
  fadeEasing,
  tooltipStyle,
}) => {
  const Component: StyledComponent<'div', any, TooltipElementBaseProps, never> = tooltips[placement] || tooltips.top
  if (!open) return null
  return (
    <Component
      offset={offset || 16}
      zIndex={(tooltipStyle && tooltipStyle.zIndex) || 10}
      fadeDuration={fadeDuration || 0}
      fadeEasing={fadeEasing}>
      {children}
    </Component>
  )
}

interface ArrowBaseProps {
  width: number
  border: string
}

const ArrowBase = styled.div<ArrowBaseProps>`
  position: absolute;
  width: ${p => p.width}px;
  height: ${p => p.width}px;
  background: inherit;
`

const ArrowUp = styled(ArrowBase)`
  transform: translateX(-50%) translateY(50%) rotateZ(45deg);
  bottom: 100%;
  left: 50%;
  border-left: 1px solid ${p => p.border};
  border-top: 1px solid ${p => p.border};
`
const ArrowDown = styled(ArrowBase)`
  transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  top: 100%;
  left: 50%;
  border-right: 1px solid ${p => p.border};
  border-bottom: 1px solid ${p => p.border};
`
const ArrowLeft = styled(ArrowBase)`
  transform: translateX(50%) translateY(-50%) rotateZ(45deg);
  right: 100%;
  top: 50%;
  border-left: 1px solid ${p => p.border};
  border-bottom: 1px solid ${p => p.border};
`

const ArrowRight = styled(ArrowBase)`
  transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  left: 100%;
  top: 50%;
  border-right: 1px solid ${p => p.border};
  border-top: 1px solid ${p => p.border};
`

const ArrowUpLeft = styled(ArrowBase)`
  transform: translateX(-50%) translateY(50%) rotateZ(45deg);
  bottom: 100%;
  left: 20px;
  border-left: 1px solid ${p => p.border};
  border-top: 1px solid ${p => p.border};
`

const ArrowUpRight = styled(ArrowBase)`
  transform: translateX(50%) translateY(50%) rotateZ(45deg);
  bottom: 100%;
  right: 20px;
  border-left: 1px solid ${p => p.border};
  border-top: 1px solid ${p => p.border};
`

const arrows = {
  left: ArrowRight,
  top: ArrowDown,
  right: ArrowLeft,
  bottom: ArrowUp,
  bottomLeft: ArrowUpRight,
  bottomRight: ArrowUpLeft,
}

interface ArrowProps extends ArrowBaseProps {
  placement: Exclude<TooltipProps['placement'], undefined>
  style?: CSSProperties
}

const Arrow: React.FC<ArrowProps> = ({ border, placement, width, style }) => {
  const Component: StyledComponent<'div', any, ArrowBaseProps, never> = arrows[placement] || arrows.top
  if (!width) return null
  return <Component border={border} width={width} style={style} />
}

interface BubbleProps {
  style?: CSSProperties
  radius: number
  border: string
}

const Bubble = styled.div<BubbleProps>`
  border-radius: ${p => (p.radius ? `${p.radius}px` : 0)};
  border: 1px solid ${p => p.border};
  & > span {
    color: inherit;
    & > span {
      color: inherit;
    }
  }
`
