import { useUserState } from 'app/UserState'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { retryFetch, toJson } from 'utils/apiUtils'
import { getAuthRequestParams } from './authUtils'

let endpointCache: { [key: string]: any } = {}

export function useDeferredEndpoint<ValueType extends any>(
  fetchUrl: string | null,
  defaultValue?: ValueType,
  options?: { disregardCachedValue?: boolean; avoidCaching?: boolean }
) {
  const { accessToken } = useUserState()
  const [value, setValue] = useState<ValueType | null>(
    !options?.disregardCachedValue && fetchUrl && fetchUrl in endpointCache
      ? endpointCache[fetchUrl]
      : defaultValue || null
  )
  const [loaded, setFetched] = useState<boolean>(false)
  const [loading, setFetching] = useState<boolean>(false)
  const [errorLoading, setErrorFetching] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const latestFetchUrl = useRef<typeof fetchUrl>(fetchUrl)
  useEffect(() => {
    latestFetchUrl.current = fetchUrl
    if (fetchUrl) {
      if (!options?.disregardCachedValue && fetchUrl in endpointCache) setValue(endpointCache[fetchUrl])
      else setValue(defaultValue || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl])
  const fetchValue = useRef(() => {
    if (!latestFetchUrl.current) return new Promise((resolve, reject) => resolve(defaultValue))
    ReactDOM.unstable_batchedUpdates(() => {
      setFetching(true)
      setFetched(false)
      setErrorFetching(false)
      setErrorMessage(null)
    })
    return retryFetch(latestFetchUrl.current, getAuthRequestParams(accessToken))
      .then(toJson)
      .then((value: ValueType) => {
        if (!options?.avoidCaching && latestFetchUrl.current) endpointCache[latestFetchUrl.current] = value
        ReactDOM.unstable_batchedUpdates(() => {
          setFetching(false)
          setFetched(true)
          setValue(value)
        })
      })
      .catch((e) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setFetching(false)
          setFetched(false)
          setErrorFetching(true)
          setErrorMessage(e.message || 'Error fetching')
        })
      })
  })
  return { value, fetch: fetchValue.current, loaded, loading, errorLoading, errorMessage, fetchUrl }
}

export function clearEndpointCache() {
  endpointCache = {}
}

export function useEndpoint<ValueType extends any>(
  fetchUrl: string | null,
  defaultValue?: ValueType,
  options?: { refetchDespiteCachedValue?: boolean; disregardCachedValue?: boolean; avoidCaching?: boolean }
): [
  ValueType | null,
  Pick<ReturnType<typeof useDeferredEndpoint>, 'loaded' | 'loading' | 'errorLoading' | 'errorMessage' | 'fetch'>,
] {
  const { value, fetch, loaded, loading, errorLoading, errorMessage } = useDeferredEndpoint(fetchUrl, defaultValue, {
    disregardCachedValue: options?.disregardCachedValue,
    avoidCaching: options?.avoidCaching,
  })
  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl])
  return [
    (loading || errorLoading) && options?.refetchDespiteCachedValue ? null : value,
    {
      loaded,
      loading: fetchUrl && !options?.refetchDespiteCachedValue && fetchUrl in endpointCache ? false : loading,
      errorLoading,
      errorMessage,
      fetch,
    },
  ]
}

type ApiEndpointReturn<T> =
  | [null, { loaded: false; loading: true; errorLoading: false }]
  | [null, { loaded: false; loading: false; errorLoading: true }]
  | [T, { loaded: true; loading: false; errorLoading: false }]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useApiEndpoint<ApiFunction extends <T>(...args: any[]) => ReturnType<ApiFunction>>(
  func: ApiFunction,
  enabled: boolean,
  ...args: Parameters<ApiFunction>
): ApiEndpointReturn<Awaited<ReturnType<ApiFunction>>> {
  const [value, setValue] = useState<Awaited<ReturnType<ApiFunction>> | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [errorLoading, setErrorLoading] = useState<boolean>(false)
  useEffect(() => {
    if (enabled) {
      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(true)
        setLoaded(false)
        setErrorLoading(false)
      })

      const maybePromise = func(...args)
      if (maybePromise instanceof Promise) {
        maybePromise
          .then((value: Awaited<ReturnType<ApiFunction>>) => {
            ReactDOM.unstable_batchedUpdates(() => {
              setValue(value)
              setLoading(false)
              setLoaded(true)
              setErrorLoading(false)
            })
          })
          .catch((e) => {
            console.error(e)

            ReactDOM.unstable_batchedUpdates(() => {
              setLoading(false)
              setLoaded(false)
              setErrorLoading(true)
            })
          })
      } else {
        console.error('Function did not return a promise')
        ReactDOM.unstable_batchedUpdates(() => {
          setLoading(false)
          setLoaded(false)
          setErrorLoading(true)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled])

  return [value, { loaded, loading, errorLoading }] as ApiEndpointReturn<Awaited<ReturnType<ApiFunction>>>
}
