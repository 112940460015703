import { css } from 'styled-components'

export const fontLight = css`
  font-family: eurostile, sans-serif;
  font-weight: 400;
  font-style: normal;
`

export const fontRegular = css`
  font-family: eurostile, sans-serif;
  font-weight: 500;
  font-style: normal;
`

export const fontBold = css`
  font-family: eurostile, sans-serif;
  font-weight: 700;
  font-style: normal;
`

export const fontHeavy = css`
  font-family: eurostile, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

export const fontBlack = css`
  font-family: eurostile-extended, sans-serif;
  font-weight: 900;
  font-style: normal;
  text-transform: uppercase;
`
