import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Reward, RewardFrequency } from 'skill-tracker/types'
import { AddUpdateRewardAction, ADD_UPDATE_REWARD } from 'skill-tracker/actionTypes'

import { Button, Row, Column, Padding, H2, Hr, Slab, Spacer } from 'common/ui'
import { Field } from 'common/Field'

import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { RewardEditModal } from './RewardEditModal'
import { ViewToggleButtonGroup } from 'skill-tracker/RewardsScreen'

import { useUserState } from 'app/UserState'
import { useSkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { validateAddUpdateReward } from 'skill-tracker/actionValidators'
import { RewardSlab } from 'skill-tracker/common/RewardSlab'
import { Hint } from 'common/Hint'
import { rewardsMenuHint } from 'skill-tracker/constants/hints'

interface Props {
  isOpen: boolean
  value?: Reward['id']
  disabledIds?: Reward['id'][]
  onSelect?: (reward: Reward) => void
  onClose: () => void
}

export const RewardSelectModal: React.FC<Props> = ({ isOpen: _isOpen, value, disabledIds = [], onClose, onSelect }) => {
  const { getBaseAction } = useUserState()
  const { state, dispatch } = useSkillTrackerState()
  const [view, setView] = useState<RewardFrequency>('daily')
  const [editReward, setEditReward] = useState<Reward | null>(null)

  const [isOpen, setIsOpen] = useState<boolean>(_isOpen)
  useEffect(() => setIsOpen(_isOpen), [_isOpen])
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  const rewards = state.rewards.filter(({ frequency }) => frequency === view)
  const activeModule = state.modules.find(({ id }) => id === state.activeModuleId)

  const handleRewardSelect = (reward: Reward) => {
    if (onSelect) onSelect(reward)
    delayedOnClose()
  }

  const handleNewReward = () => {
    const newReward: Reward = { id: `reward${state.rewards.length}`, text: '', symbol: '', frequency: view }
    setEditReward(newReward)
  }

  const handleRewardSave = (reward: Reward) => {
    const action: AddUpdateRewardAction = { ...getBaseAction(), type: ADD_UPDATE_REWARD, reward }
    if (validateAddUpdateReward(action, state)) dispatch(action)
    else console.error(`Unable to validate update reward action`, action, state)
  }

  return (
    <>
      <MobileModalPanel
        isOpen={isOpen}
        onRequestClose={delayedOnClose}
        panelStyle={{ maxHeight: `calc(100vh - 40px)`, width: `calc(100vw - 40px)` }}>
        <Column flex="none">
          <Row flex="none">
            <Row flex="1 1 auto" alignItems="center">
              <Padding size="s">
                <Row alignItems="center">
                  <H2 style={{ lineHeight: 0.95, paddingTop: 2 }}>Rewards Menu</H2>
                  <Hint text={rewardsMenuHint} style={{ marginLeft: 10 }} />
                </Row>
              </Padding>
            </Row>
            <Column>
              <Padding size="xs">
                <Button size="xs" onClick={delayedOnClose}>
                  ×
                </Button>
              </Padding>
            </Column>
          </Row>
          <Hr />
          <ViewToggleButtonGroup>
            <Button
              type="button"
              size="s"
              flex="1 1 auto"
              theme={view === 'daily' ? 'blue' : 'gray'}
              onClick={() => setView('daily')}>
              Daily Rewards
            </Button>
            <Button
              type="button"
              size="s"
              flex="1 1 auto"
              theme={view === 'bonus' ? 'blue' : 'gray'}
              onClick={() => setView('bonus')}>
              Bonus Rewards
            </Button>
          </ViewToggleButtonGroup>
          <Hr />
        </Column>
        <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
          <Row>
            <Column flex="1 1 auto">
              <Padding size="s">
                <Field label={`Create a ${view} reward`}>
                  <div>
                    <Button type="button" size="s" theme="blue" onClick={handleNewReward}>
                      Create
                    </Button>
                  </div>
                </Field>
                {rewards.length > 0 && (
                  <>
                    <Spacer size="m" />
                    <Field label={`Edit an existing one`}>
                      {rewards.map((reward, i) => (
                        <Row key={i} alignItems="center" paddingBottom={10}>
                          <Column flex="1 1 auto">
                            <RewardSlab Component={RewardButton} reward={reward} onEdit={() => setEditReward(reward)} />
                          </Column>
                          {activeModule && onSelect && (
                            <Column flex="none" paddingLeft={10}>
                              <Button
                                type="button"
                                size="s"
                                theme="blue"
                                disabled={disabledIds.indexOf(reward.id) >= 0}
                                onClick={() => handleRewardSelect(reward)}>
                                {disabledIds.indexOf(reward.id) >= 0 ? 'Added' : 'Select'}
                              </Button>
                            </Column>
                          )}
                        </Row>
                      ))}
                    </Field>
                  </>
                )}
                <div>
                  <Button size="s" marginTop={15} onClick={delayedOnClose} theme="white">
                    Close
                  </Button>
                </div>
              </Padding>
            </Column>
          </Row>
        </Column>
      </MobileModalPanel>
      {editReward && (
        <RewardEditModal initialValue={editReward} onClose={() => setEditReward(null)} onSave={handleRewardSave} />
      )}
    </>
  )
}

const RewardButton = styled(Slab)<{ active?: boolean }>`
  background: rgba(255, 255, 255, ${p => (p.active ? 1 : 0.5)});
  border: 1px solid ${p => (p.active ? p.theme.thinOutlineActiveColor : p.theme.thinOutlineColor)};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
