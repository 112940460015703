import React from 'react'
import styled, { css } from 'styled-components'
import padStart from 'lodash/padStart'

import { SupplementarySlide, ParticipantState } from 'shared/session/types'

import { Label, H3, Row } from 'common/ui'
import { SVG } from 'common/SVG'
import { CircleButton } from './common/CircleButton'
import { UserInputIndicator } from './common/UserInputIndicator'

import { useUserInputState } from './UserInputState'

export interface ParticipantTabProps {
  index?: number
  participant: ParticipantState
  active: boolean
  tokenMissionOpen: boolean
  useTokens?: boolean
  onSelect: () => void
  onAddToken: () => void
  onLockSelect: () => void
  onTokenMissionEdit: () => void
  globalLock: boolean
  pastMode?: boolean
  noLongerInGroup?: boolean
}

export const shortenDisplayName = (displayName: string) => {
  const words = displayName.split(/(-|\s+)/)
  const initials = (words.length > 1 ? words.slice(1) : []).map(word => (word.length > 0 ? word[0].toUpperCase() : ''))
  return `${words[0]}${initials.length > 0 ? ` ${initials.join('')}` : ''}`
}

export const ParticipantTab: React.FC<ParticipantTabProps> = ({
  index = 0,
  participant,
  active,
  tokenMissionOpen,
  useTokens,
  onSelect,
  onAddToken,
  onLockSelect,
  onTokenMissionEdit,
  globalLock,
  pastMode,
  noLongerInGroup,
}) => {
  const { getInputValues } = useUserInputState()
  const supplementarySlides = getInputValues<SupplementarySlide>('shared', { owner: 'supplementary_slides' }).map(
    ({ value }) => value
  )
  const isSupplementarySlide = participant
    ? !!supplementarySlides.find(supp => supp.uid === participant.currentSlideUid)
    : false
  let participantLocked = true
  if (isSupplementarySlide) {
    participantLocked = globalLock ? false : !globalLock && participant.locked ? true : false
  } else {
    participantLocked = participant.locked || globalLock ? true : false
  }
  return (
    <Tab
      active={active}
      onClick={() => onSelect()}
      help={participant.requiresHelp}
      pastMode={pastMode}
      noLongerInGroup={noLongerInGroup}>
      {!pastMode && <TabStatusBar status={participant.status} gadgetTrayOpen={participant.gadgetTrayOpen} />}
      <TabRow>
        <H3 style={{ margin: 0, marginLeft: 10 }}>
          {participant.profile.displayName
            ? shortenDisplayName(participant.profile.displayName)
            : `Participant ${index + 1}`}
        </H3>
        <UserInputIndicator key={participant.profile.uid} participant={participant} />
        {useTokens && (
          <div onClick={active ? () => onTokenMissionEdit() : undefined}>
            <SVG viewboxWidth={12} viewboxHeight={10} size={12} style={{ opacity: active ? 1 : 0.25 }}>
              {tokenMissionOpen ? (
                <path
                  d="M5.15 1.366a1 1 0 011.7 0l4.262 6.856a1 1 0 01-.85 1.528H1.738a1 1 0 01-.85-1.528l4.264-6.856z"
                  fill={!!participant.tokenMission ? '#007eff' : '#8c93a3'}
                />
              ) : (
                <path
                  d="M6.85 8.634a1 1 0 01-1.7 0L.889 1.778A1 1 0 011.738.25h8.525a1 1 0 01.85 1.528L6.848 8.634z"
                  fill={!!participant.tokenMission ? '#007eff' : '#8c93a3'}
                />
              )}
            </SVG>
          </div>
        )}
        {useTokens && (
          <TokenWrapper>
            <Label>{padStart(String(participant.tokens), 2, '0')}</Label>
            <CircleButton
              theme="blue"
              size="xs"
              style={{ fontSize: 16 }}
              children="+"
              onClick={e => {
                e.stopPropagation()
                onAddToken()
              }}
              disabled={pastMode}
            />
          </TokenWrapper>
        )}
        <LockWrapper
          style={{ marginLeft: 0, opacity: pastMode ? 0.5 : 1 }}
          onClick={e => {
            if (!pastMode) {
              e.stopPropagation()
              onLockSelect()
            }
          }}>
          <SVG viewboxWidth={22} viewboxHeight={32} width={15} height={18}>
            {participantLocked && (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.09091 6.81818V10.9091H2.72727C1.27043 10.9091 0 11.9678 0 13.4091V24.7727C0 26.214 1.27043 27.2727 2.72727 27.2727H19.0909C20.5478 27.2727 21.8182 26.214 21.8182 24.7727V13.4091C21.8182 11.9678 20.5478 10.9091 19.0909 10.9091H17.7273V6.81818C17.7273 3.0526 14.6747 0 10.9091 0C7.14351 0 4.09091 3.0526 4.09091 6.81818ZM15 6.81818V10.9091H6.81818V6.81818C6.81818 4.55884 8.64974 2.72727 10.9091 2.72727C13.1684 2.72727 15 4.55884 15 6.81818ZM2.72727 24.5447V13.6357H19.0909V24.5447H2.72727ZM12.2727 19.0909C12.2727 19.844 11.6622 20.4545 10.9091 20.4545C10.156 20.4545 9.54545 19.844 9.54545 19.0909C9.54545 18.3378 10.156 17.7273 10.9091 17.7273C11.6622 17.7273 12.2727 18.3378 12.2727 19.0909Z"
                fill="#CF1110"
              />
            )}
            {!participantLocked && (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.09091 13.6364V6.81818C4.09091 3.0526 7.14351 0 10.9091 0C14.6747 0 17.7273 3.0526 17.7273 6.81818V9.54545H15V6.81818C15 4.55884 13.1684 2.72727 10.9091 2.72727C8.64974 2.72727 6.81818 4.55884 6.81818 6.81818V13.6364H19.0909C20.5971 13.6364 21.8182 14.8574 21.8182 16.3636V27.2727C21.8182 28.779 20.5971 30 19.0909 30H2.72727C1.22104 30 0 28.779 0 27.2727V16.3636C0 14.8574 1.22104 13.6364 2.72727 13.6364H4.09091ZM2.72732 27.2724V16.3633H19.091V27.2724H2.72732ZM12.2727 21.8182C12.2727 22.5713 11.6622 23.1818 10.9091 23.1818C10.156 23.1818 9.54545 22.5713 9.54545 21.8182C9.54545 21.0651 10.156 20.4545 10.9091 20.4545C11.6622 20.4545 12.2727 21.0651 12.2727 21.8182Z"
                fill="#011A46"
              />
            )}
          </SVG>
        </LockWrapper>
      </TabRow>
    </Tab>
  )
}

const statusColors: { [key in ParticipantState['status']]: string } = {
  connected: '#4EBE40',
  idle: '#FCB419',
  offline: '#F96A73',
}

export const TabRow = styled(Row)`
  align-items: center;
`

export const TabStatusBar = styled.div<{ status: ParticipantState['status']; gadgetTrayOpen: boolean }>`
  width: 100%;
  height: 4px;
  background-color: ${p =>
    p.status === 'connected' ? (p.gadgetTrayOpen ? '#007eff' : statusColors[p.status]) : statusColors[p.status]};
`

export const TokenWrapper = styled(Row)`
  align-items: center;
  border: 1px solid #bdc3e0;
  background-color: #e4edf8;
  padding: 5px;
  margin: 5px 10px;
  border-radius: 8px;
  & ${Label} {
    margin: 0 5px 0 0;
  }
  & ${CircleButton} {
    cursor: copy;
  }
`

export const LockWrapper = styled(TokenWrapper)``

export const Tab = styled.div<{ active?: boolean; help?: boolean; pastMode?: boolean; noLongerInGroup?: boolean }>`
  flex: none;
  min-width: 100px;
  min-height: 46px;
  background-color: ${p => (p.help ? '#4EBE40' : `rgba(255, 255, 255, ${p.active ? 1 : 0.7})`)};
  color: #011a46;
  border: 1px solid #cbd2e8;
  border-bottom: none;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  ${p =>
    p.pastMode
      ? css`
          padding-top: 2px;
          & ${LockWrapper} {
            display: none;
          }
        `
      : ''}
  ${p =>
    p.noLongerInGroup
      ? css`
          background-image: linear-gradient(
            45deg,
            transparent 40%,
            rgba(120, 0, 0, 0.1) 40%,
            rgba(120, 0, 0, 0.1) 50%,
            transparent 50%,
            transparent 90%,
            rgba(120, 0, 0, 0.1) 90%,
            rgba(120, 0, 0, 0.1) 100%
          );
          background-size: 14.14px 14.14px;
        `
      : ''}

  h3 {
    color: ${p => (p.help ? '#fff' : '#011a46')};
  }

  &:first-child {
    border-top-left-radius: 10px;
  }
  &:not(:first-child) {
    margin-left: -1px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
  & ${TabStatusBar} {
    opacity: ${p => (p.active ? 1 : 0.7)};
  }
`
