import React, { useState } from 'react'

import {
  EmotionometerType,
  EmotionometerDevice,
  EmotionometerSticker,
  EmotionometerRange,
  EmotionometerStickerBase,
  ETelligenceState,
} from 'shared/e-telligence/types'
import { ActionTypes, AddUpdateCustomStickerAction, ADD_UPDATE_CUSTOM_STICKER } from 'shared/e-telligence/actionTypes'

import { presetStickers } from '../constants/stickers'
import { emotionometerDeviceLabels } from 'e-telligence/constants/typeValueMaps'

import { Row, Column, Padding } from 'common/ui'
import { Navigation } from '../common/Navigation'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { StickerEditModal } from './StickerEditModal'
import { StickerTile } from './StickerTile'

import { useUserState } from 'app/UserState'
import { useMedia } from 'utils/useMedia'

const emotionRangeMap: { [k in EmotionometerRange]: string } = {
  '1-3': 'low',
  '4-7': 'medium',
  '8-10': 'high',
}

export interface Props {
  emotion: EmotionometerType
  emotionRange: EmotionometerRange
  device: EmotionometerDevice
  index: number
  onClose: () => void
  onSelect: (sticker: EmotionometerSticker<EmotionometerDevice>) => void

  // these need to be passed through from parent component because we don't know whether to look at etell or gadget pack
  state: ETelligenceState
  dispatch: (action: ActionTypes) => void
}

export const StickerSelectModal: React.FC<Props> = ({
  emotion,
  emotionRange,
  device,
  index,
  onClose,
  onSelect,
  state,
  dispatch,
}) => {
  const { getBaseAction } = useUserState()
  const portrait = useMedia('(orientation: portrait)')

  const [editSticker, setEditSticker] = useState<Partial<EmotionometerSticker<EmotionometerDevice>> | undefined>(
    undefined
  )

  const existingStickers: EmotionometerStickerBase[] =
    state.emotionometers.find(({ type }) => type === emotion)?.[device] || []
  const disabledIds = existingStickers
    .filter(sticker => sticker.type === device && sticker.emotionRange === emotionRange)
    .map(({ id }) => id)

  const allStickers = [
    ...state.customStickers.filter(
      sticker => sticker.type === device && (device === 'bodyClues' ? sticker.emotion === emotion : true)
    ),
    ...presetStickers.filter(
      sticker => sticker.type === device && (device === 'bodyClues' ? sticker.emotion === emotion : true)
    ),
  ]

  const addNewSticker = (data: EmotionometerSticker<typeof device>) => {
    const sticker: EmotionometerSticker<typeof device> = {
      ...data,
      id: data.id || 'sticker' + Date.now(),
      type: data.type || device,
      emotion: data.emotion || emotion,
      emotionRange: emotionRange || undefined,
      index,
    }
    const action: AddUpdateCustomStickerAction = {
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_STICKER,
      sticker,
      emotionometerType: emotion,
      emotionometerDevice: device,
      emotionometerRange: emotionRange,
      disableAutoAdd: true,
    }
    dispatch(action)
    setEditSticker(undefined)
  }

  const handleStickerSelect = (sticker: EmotionometerSticker<typeof device> | undefined) => {
    if (sticker === undefined) {
      setEditSticker({})
    } else {
      onSelect({ ...sticker, emotionRange, index })
    }
  }

  return (
    <>
      <MobileModalPanel
        isOpen
        onRequestClose={onClose}
        panelStyle={{ maxWidth: 'calc(100vw - 40px)', maxHeight: `calc(${portrait ? '100%' : '100vh'} - 40px)` }}
        style={!portrait ? {} : { overlay: { bottom: 'initial', height: '100%' } }}>
        <Navigation
          title={`Select a ${emotionometerDeviceLabels[device].toLowerCase()} sticker for when you feel ${
            emotionRangeMap[emotionRange]
          } levels of ${emotion}`}
          titleSize={portrait ? 12 : undefined}
          buttonLabel="Back"
          buttonPress={onClose}
        />
        <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
          <Padding size="s">
            <Row flexWrap justifyContent="flex-start" marginRight={-10}>
              {[undefined, ...allStickers].map((sticker, i) => (
                <Column key={i} flex="1 1 96px" paddingBottom="s" paddingRight={10}>
                  <StickerTile<typeof device>
                    sticker={sticker}
                    disabled={sticker && disabledIds.indexOf(sticker.id) >= 0}
                    placeholder={`Make your own +`}
                    onEdit={() => handleStickerSelect(sticker)}
                  />
                </Column>
              ))}
              {[...Array(20)].map((_, i) => (
                <Column key={`s${i}`} flex="1 1 96px" paddingRight={10} />
              ))}
            </Row>
          </Padding>
        </Column>
      </MobileModalPanel>
      {!!editSticker && (
        <StickerEditModal
          initialValue={editSticker}
          onClose={() => setEditSticker(undefined)}
          onSave={addNewSticker}
          device={device}
        />
      )}
    </>
  )
}
