import React, { useState } from 'react'
import styled from 'styled-components'

import { PreviewGadgetPackStateProvider } from './PreviewGadgetPackStateProvider'
import { UPDATE_CURRENT_SLIDE } from 'shared/session/actionTypes'
import { HydratedSlide, HOLDING_SLIDE, MODULE_SLIDE, SUPPLEMENTARY_SLIDE, END_SLIDE } from 'shared/session/types'

import { Container, Row, Column, Button, H2, AppBackground, Spacer } from 'common/ui'
import { HeaderContainer, Header, HeaderNavColumn, HeaderTray, TrayCatcher } from './ParticipantUi'
import { Logo } from '../common/Logo'
import { AnchorButton } from 'common/LinkButton'
import { GadgetTray } from './gadget-tray/GadgetTray'

import { useSessionState } from './SessionState'
import { useSavedInputValueObjects } from './InputContext'
import { useFocusedParticipantState } from './hooks/useProfileState'
import { getSlides, getSlideByUid } from './reducers'
import { baseUrl } from 'dashboards/mentor/MentorDashboard'

export const PARTICIPANT_HEADER_HEIGHT = 65

export const ParticipantPlaybackUi: React.FC = ({ children }) => {
  const { dispatch, previewing, getBaseAction, sessionData, state, sessionUserType } = useSessionState()
  const participantState = useFocusedParticipantState()

  const skippedSlides = useSavedInputValueObjects<boolean>({
    participant_uid: 'shared',
    owner: 'skipped_slides',
    owner_id: null,
    name: undefined,
  })
  const skippedSlideUids = skippedSlides.filter(({ name, value }) => value && !!name).map(({ name }) => name)

  const [trayType, setShowTray] = useState<null | 'gadgets' | 'mission'>(null)

  const slides = getSlides(sessionData, state, [] /*supplementarySlides*/).filter(slide =>
    slide.type === 'MODULE_SLIDE' ? skippedSlideUids.indexOf(slide.uid) < 0 : true
  )
  const realSlideUid = participantState ? participantState.currentSlideUid : null
  const currentSlide: HydratedSlide = getSlideByUid(sessionData, state, [] /*supplementarySlides*/, realSlideUid) || {
    type: HOLDING_SLIDE,
    order: -1,
  }
  const currentSlideIndex =
    currentSlide.type === HOLDING_SLIDE
      ? 0
      : currentSlide.type === END_SLIDE
      ? slides.length - 1
      : slides.reduce((value, slide, index) => {
          if (value === 0) {
            if (slide.type !== HOLDING_SLIDE && slide.type === currentSlide.type && slide.uid === currentSlide.uid) {
              return index
            }
          }
          return value
        }, 0)

  const prevDisabled = currentSlide.order <= 0 || currentSlide.type === HOLDING_SLIDE
  const nextDisabled = currentSlideIndex >= slides.length - 1

  const navigationDisabled = !sessionData || !slides.length

  const navigateTo = (dir: number) => {
    if (!dir) return
    if (dir < 0 && prevDisabled) return
    if (dir > 0 && nextDisabled) return
    if (!participantState) return
    const newIndex = currentSlideIndex + dir
    const newSlide = slides[newIndex]
    if (newSlide) {
      const newSlideUid =
        newSlide.type === MODULE_SLIDE || newSlide.type === SUPPLEMENTARY_SLIDE || newSlide.type === END_SLIDE
          ? newSlide.uid
          : null
      dispatch({
        ...getBaseAction(),
        type: UPDATE_CURRENT_SLIDE,
        slideUid: newSlideUid,
        activateGroupLock: false,
      })
    }
  }

  return (
    <PreviewGadgetPackStateProvider>
      <HeaderContainer previewing={previewing} style={{ zIndex: 1002 }}>
        <Header>
          <Container size="l" style={{ height: '100%' }}>
            <Row style={{ height: '100%' }}>
              <Column flex="none" justifyContent="center" alignItems="flex-start">
                <Logo style={{ marginLeft: 15 }} />
              </Column>
              <Column flex="1 1 auto" justifyContent="center" alignItems="center">
                {sessionData && <H2>{sessionData.module.public_title || sessionData.module.title}</H2>}
              </Column>
              <Row alignItems="center">
                <HeaderNavColumn>
                  <Button
                    size="s"
                    onClick={() => setShowTray(trayType === 'gadgets' ? null : 'gadgets')}
                    children="Gadgets"
                    pressed={trayType === 'gadgets'}
                  />
                  {sessionData !== false && (
                    <>
                      <Spacer size="s" />
                      <AnchorButton
                        size="s"
                        href={
                          sessionUserType === 'agent'
                            ? `${process.env.REACT_APP_CADET_URL}/club-meeting`
                            : `${process.env.REACT_APP_MENTOR_URL}${baseUrl}/${
                                sessionData.type === 'cadet' ? 'club-journal' : 'meeting-notes'
                              }`
                        }
                        children="Exit"
                      />
                    </>
                  )}
                </HeaderNavColumn>
              </Row>
            </Row>
          </Container>
        </Header>
        {trayType === 'gadgets' && (
          <HeaderTray>
            <GadgetTray readOnly={sessionUserType !== 'agent'} />
          </HeaderTray>
        )}
        {trayType !== null && <TrayCatcher onClick={() => setShowTray(null)} />}
      </HeaderContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
      <FooterContainer style={{ zIndex: 1001 }}>
        <Footer>
          <Container size="l" style={{ height: '100%', padding: '0 15px' }}>
            <Row style={{ height: '100%' }}>
              <Column flex="none" justifyContent="center">
                <Button
                  size="s"
                  disabled={navigationDisabled || prevDisabled}
                  onClick={() => navigateTo(-1)}
                  children="〈 Previous"
                />
              </Column>
              <Column flex="1 1 auto" justifyContent="center" />
              <Column flex="none" justifyContent="center">
                <Button
                  size="s"
                  disabled={navigationDisabled || nextDisabled}
                  onClick={() => navigateTo(1)}
                  children="Next 〉"
                />
              </Column>
            </Row>
          </Container>
        </Footer>
      </FooterContainer>
    </PreviewGadgetPackStateProvider>
  )
}

const FooterContainer = styled.section`
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
`
const Footer = styled.header`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${PARTICIPANT_HEADER_HEIGHT}px;
  background-color: #edf2fa;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
`
const ChildrenContainer = styled.div`
  width: 100%;
  & ${AppBackground} {
    min-height: calc(100vh - ${PARTICIPANT_HEADER_HEIGHT}px);
  }
`
