import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Game } from 'phaser'

import { Absolute, Button, Panel, CUT_TL, CUT_TR, CUT_BR, CUT_BL, H1, H2 } from 'common/ui'
import { Controls } from './Controls'
import { THOUGHTS } from 'shared/session/sections/custom/helpful-thoughts/thoughts'
import { OptionModal } from './OptionModal'

import HelpfulThoughtsScene from './HelpfulThoughtsScene'

import { DISPATCH_SECTION_STATE } from 'shared/session/actionTypes'
import { SectionPropsBase, HelpfulThoughtsThoughtCloud } from 'shared/session/types'
import { useSessionState } from 'session/SessionState'
import { useMeasure } from 'utils/useMeasure'
import { getGameConfig, destroyGame } from 'utils/gameUtils'
import { getPixelRatio } from 'utils/screenUtils'
import { FlexModal } from 'app/FlexModal'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { getInitialProfileState, getInitialSectionState } from 'shared/session/sections/custom/helpful-thoughts/reducer'
import { ActionTypes } from 'shared/session/sections/custom/helpful-thoughts/actionTypes'
import { useDeepCompareProp } from 'session/hooks/useDeepCompareProp'

// Height
const height = 500

// Props
interface Props extends SectionPropsBase {
  property: 'embedded_activity_sections'
}

// Component
export const HelpfulThoughts: React.FC<Props> = ({ section }) => {
  const { state, dispatch, getBaseAction } = useSessionState()

  const participantState = useFocusedParticipantState()
  const sectionState = useSectionStateWithFallback('activity_helpful_thoughts', section, () =>
    getInitialSectionState(THOUGHTS, participantState ? participantState.profile : undefined)
  )

  const profileState = useDeepCompareProp(
    (participantState && sectionState.participants[participantState.profile.uid]) || getInitialProfileState(THOUGHTS)
  )

  const phaserDiv = useRef<HTMLDivElement>(null)
  const sceneRef = useRef<HelpfulThoughtsScene | null>(null)
  const [measureContainerRef, { width }] = useMeasure()

  const [showError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isGlowing, setIsGlowing] = useState(false)
  const [selectedOption, setSelectedOption] = useState<null | number>(null)

  const dispatchSectionState = useCallback(
    (action: Partial<ActionTypes>) => {
      if (!participantState) return
      dispatch({
        ...getBaseAction(),
        type: DISPATCH_SECTION_STATE,
        property: 'activity_helpful_thoughts',
        section_id: section.id,
        action: { ...action, participantUid: participantState.profile.uid },
      })
    },
    [dispatch, getBaseAction, participantState, section.id]
  )

  // Change levels
  const prevLevel = useCallback(() => {
    setSelectedOption(null)
    if (!profileState.hud.showLazer) dispatchSectionState({ type: 'CHANGE_LEVEL', direction: -1 })
  }, [dispatchSectionState, profileState.hud.showLazer])

  const nextLevel = useCallback(() => {
    setSelectedOption(null)
    if (!profileState.hud.showLazer) dispatchSectionState({ type: 'CHANGE_LEVEL', direction: 1 })
  }, [dispatchSectionState, profileState.hud.showLazer])

  // Handle chosen thought
  const handleSubmittedOption = useCallback(
    (value: number) => {
      // if (!profileState.hud.showLazer) {
      dispatchSectionState({ type: 'CHOOSE_OPTION', optionIndex: value, clearCustom: value === 3 ? false : true })
      setIsModalOpen(false)
      // }
    },
    [dispatchSectionState]
  )

  // Handle TextArea
  const handleCustomHelpfulThought = useCallback(
    (value: string) => {
      dispatchSectionState({ type: 'SET_CUSTOM_OPTION', customOption: value })
    },
    [dispatchSectionState]
  )

  // Resize on viewport change
  useEffect(() => {
    if (width && sceneRef.current) sceneRef.current.scale.resize(width, height)
  }, [width])

  // Pass state into Phaser
  useEffect(() => {
    sceneRef.current && sceneRef.current.updateState(profileState, dispatchSectionState)
  }, [state, sectionState, profileState, dispatchSectionState])

  // Initiate Phaser
  useEffect(() => {
    if (!phaserDiv.current) return
    const rect = phaserDiv.current.getBoundingClientRect()
    const game = new Game({
      ...getGameConfig(),
      render: {
        antialias: true,
        transparent: true,
      },
      resolution: getPixelRatio(),
      scale: {
        mode: Phaser.Scale.NONE,
        width: rect.width,
        height: height,
      },
      input: {
        mouse: {
          capture: false,
        },
        touch: {
          capture: false,
        },
      },
      parent: phaserDiv.current,
      scene: [HelpfulThoughtsScene],
      callbacks: {
        postBoot: game => {
          const scene = game.scene.scenes[0] as HelpfulThoughtsScene
          sceneRef.current = scene
          scene.returnToScanning = nextLevel
          scene.updateState(profileState, dispatchSectionState)
          scene.initialise()
          // scene.openModal = setIsModalOpen(true)
        },
      },
    })
    return () => destroyGame(game)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Start game
  const playGame = () => {
    dispatchSectionState({ type: 'SET_SCREEN', screen: 'SCANNING' })

    setTimeout(() => {
      dispatchSectionState({
        type: 'UPDATE_STATE',
        state: {
          screen: 'READY',
          hud: {
            targeted: true,
            showLazer: false,
            zapCounter: 0,
            score: 0,
          },
          clouds: profileState.clouds.map((cloudState: HelpfulThoughtsThoughtCloud, i: number) =>
            i === 0 ? { ...cloudState, targeted: true } : cloudState
          ),
        },
      })
    }, 2000)
  }

  // Show error popup
  // ? Not sure why this isn't used anymore
  // const showErrorPopup = () => {
  //   setShowError(true)
  //   setTimeout(() => {
  //     setShowError(false)
  //   }, 2000)
  // }

  // Zap thought
  const zapThought = () => {
    if (profileState.currentCloud.answers.indexOf(true) >= 0) return
    if (profileState.hud.showLazer) return
    const { currentCloud, thoughtControls } = profileState
    const answer = currentCloud.options[thoughtControls.optionIndex]
    setIsGlowing(false)
    if (answer !== 'custom' || thoughtControls.customOption !== '') {
      // check if answer is correct or if answer is custom
      const isCorrect = answer === currentCloud.answer || answer === 'custom'
      dispatchSectionState({ type: 'ZAP_CLOUD', answer: isCorrect ? true : answer })
      setSelectedOption(null)

      if (!isCorrect) {
        dispatchSectionState({ type: 'ZAP_CLOUD', answer })
        setIsGlowing(false)
      }
    }
  }

  return (
    <Container>
      {/* Phaser Canvas */}
      <CanvasContainer ref={phaserDiv} />

      {/* Canvas Hud */}
      <CanvasHud disabledState={profileState.hud.targeted} ref={measureContainerRef}>
        {/* Target State Corners */}
        {(profileState.hud.targeted || profileState.hud.showLazer) && (
          <TargetCorners
            initiated={
              profileState.currentCloud.answers.indexOf(true) >= 0 ||
              (profileState.hud.showLazer &&
                profileState.currentCloud.answer ===
                  profileState.currentCloud.options[profileState.thoughtControls.optionIndex])
            }
          />
        )}

        {/* HUD Text */}
        <GameTextContainer>
          {/* Targeted */}
          {profileState &&
            (profileState.hud.targeted || profileState.hud.showLazer) &&
            !(profileState.currentCloud.answers.indexOf(true) >= 0) && (
              <H2 className="left" as="h4">
                Unhelpful thought targeted
              </H2>
            )}

          {/* Zapping */}
          {profileState && profileState.currentCloud.answers.indexOf(true) >= 0 && (
            <H2 className="left" as="h4">
              Helpful thought initiated
            </H2>
          )}

          {/* Empty for flex */}
          {!profileState.hud.targeted && !profileState.hud.showLazer && (
            <H2 className="left" as="h4">
              {' '}
            </H2>
          )}

          {/* Scanning */}
          {profileState.screen === 'SCANNING' && <H1 as="h3">Scanning thoughts...</H1>}

          {profileState.screen === 'READY' && (
            <H1 as="h3" style={{ fontSize: 15 }}>
              {!profileState.hud.showLazer && 'CLICK TO REPLACE WITH A HELPFUL THOUGHT'}
              {profileState.hud.showLazer &&
                !(
                  profileState.currentCloud.answer ===
                  profileState.currentCloud.options[profileState.thoughtControls.optionIndex]
                ) &&
                'Try another helpful thought'}
            </H1>
          )}

          {/* Game finished */}
          {profileState.screen === 'FINISHED' && (
            <H1 style={{ color: '#42FF00', textShadow: '0px 0px 14.4981px rgba(0, 0, 0, 0.65)' }} as="h3">
              HELPFUL THOUGHTS COMPLETE
            </H1>
          )}

          {/* Score */}
          <H2 className="right" as="h4">
            Unhelpful thoughts: <br />
            {profileState.hud.score} of {profileState.clouds.length}
          </H2>
        </GameTextContainer>

        {/* Start Menu */}
        <FlexModal
          isOpen={profileState.screen === 'WAITING'}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          style={{ overlay: { background: 'rgba(1, 26, 70, .8)' } }}>
          <ModalInner>
            <Panel flair={[CUT_TL, CUT_TR, CUT_BR, CUT_BL]} padding={[30, 20]} theme="white">
              <p>Use your arrow buttons to scan for an unhelpful thought</p>
              <Button size="m" onClick={playGame}>
                Scan
              </Button>
            </Panel>
          </ModalInner>
        </FlexModal>

        {profileState.screen === 'READY' &&
          !(profileState.currentCloud.answers.indexOf(true) >= 0) &&
          !profileState.hud.showLazer && <ClickableArea onClick={() => setIsModalOpen(true)} />}
      </CanvasHud>

      {/* Controls */}
      <Controls
        zapThought={zapThought}
        nextLevel={nextLevel}
        prevLevel={prevLevel}
        disabledState={
          showError ||
          profileState.screen === 'WAITING' ||
          profileState.screen === 'SCANNING' ||
          profileState.hud.showLazer
        }
        disabledButtonState={
          (profileState.currentCloud.options[profileState.thoughtControls.optionIndex] === 'custom' &&
            profileState.thoughtControls.customOption === '') ||
          selectedOption === null ||
          showError
        }
        glowingButtonState={isGlowing}
        isGroup={sectionState.groupPlay}
      />

      {/* Choose Thoughts Modal */}
      {isModalOpen && (
        <OptionModal
          profileState={profileState}
          isOpen
          handleSubmittedOption={() => selectedOption !== null && handleSubmittedOption(selectedOption)}
          handleSelectedOption={value => setSelectedOption(value)}
          handleCustomHelpfulThought={handleCustomHelpfulThought}
          selectedOption={selectedOption}
          isGlowing={value => setIsGlowing(value)}
          inputKeyPrefix={`${participantState ? participantState.profile.uid : ''}_helpful_thoughts`}
        />
      )}
    </Container>
  )
}

// Styled Components
const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0 
  }

  15%, 85% {
    opacity: 1 
  }
`

const Container = styled.div`
  flex: auto;
  position: relative;
  overflow: hidden;
`

const CanvasHud = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  height: ${height}px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: 
    url(${require('./assets/HUDSmall.svg')?.default}), 
    /* url(${require('./assets/HUD.svg')?.default}),  */
    radial-gradient(circle at center left, rgba(255,255,255,.7) -10%,   rgba(0,0,0,0) 30%),
    radial-gradient(circle at center right, rgba(255,255,255,.7) -10%,    rgba(0,0,0,0) 30%)
  ;

  &::before {
    content: '';
    opacity: ${(p: { disabledState: boolean }) => (p.disabledState ? '.3' : '.6')};
    transition: opacity .2s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${require('./assets/HUDCentre.svg')?.default});
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    bottom: -130px;
    width: 100%;
    height: 263px;
    background-size: contain;
    background-position: left bottom, right bottom;
    background-repeat: no-repeat;
    background-image: url(${require('./assets/jd-left.png')}), url(${require('./assets/lisa-right.png')});
  }

   ${Panel} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 533px;
    margin: 0 auto;
    padding: 40px 65px;
    align-items: center;
    font-family: 'Eurostile';
    font-size: 26px;
    text-align: center;

    ${Button} {
      cursor: pointer;
      margin-top: 16px;
    }

    &.try-again {
      animation: ${fadeInOut} 1.9s ease forwards;
    }
  }
`

const ModalInner = styled.div`
  ${Panel} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 533px;
    margin: 0 auto;
    padding: 40px 65px;
    align-items: center;
    font-family: 'Eurostile';
    font-size: 26px;
    text-align: center;

    ${Button} {
      cursor: pointer;
      margin-top: 16px;
    }

    &.try-again {
      animation: ${fadeInOut} 1.9s ease forwards;
    }
  }
`

const ClickableArea = styled.button`
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: block;
  opacity: 0;
  width: 400px;
  height: 250px;
  background: red;
  border: none;
  border-radius: none;
`

const TargetCorners = styled.div<{ initiated: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 661px;
  height: 257px;
  background-image: ${({ initiated }) =>
    initiated
      ? `url(${require('./assets/target-corners-green.svg')?.default})`
      : `url(${require('./assets/target-corners-red.svg')?.default})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

const CanvasContainer = styled(Absolute)`
  width: 100%;
  max-width: 1024px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  > canvas {
    border-radius: 10px;
  }
`

const GameTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 68px;

  ${H2} {
    width: 130px;
    color: #fff;
    text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.65);

    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }

  ${H1} {
    color: #ffffff;
    text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.65);
    text-align: center;
  }
`
