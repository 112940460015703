import React from 'react'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'

import { NavRouteProps } from 'dashboards/types'
import { CadetModuleCode } from 'shared/dashboard/moduleCodes'

import { Column, H3, Row, Spacer } from 'common/ui'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'
import { SpinnerWithLabel } from 'common/Spinner'
import { LinkButton } from 'common/LinkButton'
import { TipSheetCell } from 'dashboards/common/TipSheetCell'
// import { TipsheetPrintButton } from 'tipsheets/TipsheetPrint'

import { Page } from 'dashboards/common/Page'
import { NotAuthorizedPage } from 'home/NotAuthorizedPage'

import { useMentorDashData, useMentorDashState } from './MentorDashboardState'
import { baseUrl } from './MentorDashboard'

export const TeacherTipSheets: React.FC<NavRouteProps> = ({ route }) => {
  const {
    mentorUid,
    endpoints: {
      mentor: { value: mentor, loading: loadingMentor },
    },
  } = useMentorDashState()
  const [sessions, loadingSessions] = useMentorDashData('sessions', [])

  if (loadingMentor) return <Page route={route} children={<SpinnerWithLabel label="Verifying mentor..." />} />
  if (mentor && !mentor.permission_tip_sheets) return <NotAuthorizedPage />
  if (loadingSessions || !sessions) return null

  const validSessions = sortBy(
    uniqBy(
      sessions.filter(({ type, concluded }) => type === 'cadet' && concluded),
      'module_code'
    ),
    'module_code'
  )

  return (
    <Page route={route}>
      <Cells>
        <Cell accent="orange">
          <CellInner>
            <Column flex="1 1 auto">
              <H3>Teacher Introductory Letter</H3>
            </Column>
            <Row flex="none">
              {/* <TipsheetPrintButton
                size="s"
                theme="orange"
                context="mentor"
                mentorUid={mentorUid || undefined}
                groupId={mentor ? mentor.group_id : undefined}
                moduleCode="tipsheet-intro"
              /> */}
              <Spacer size="xs" />
              <LinkButton size="s" children="View" theme="orange" to={`${baseUrl}/teacher-tip-sheets/tipsheet-intro`} />
            </Row>
          </CellInner>
        </Cell>
        {validSessions.map(sessionDetail => (
          <TipSheetCell
            baseUrl={baseUrl}
            key={sessionDetail.module_code}
            context="mentor"
            mentorUid={mentorUid || undefined}
            groupId={mentor ? mentor.group_id : undefined}
            moduleCode={sessionDetail.module_code as CadetModuleCode}
          />
        ))}
      </Cells>
    </Page>
  )
}
