import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { Panel, Button, Row, Spacer, H2 } from 'common/ui'
import { HeaderTemplate } from '../common/HeaderTemplate'

import { useGadgetPackState } from '../GadgetPackState'
import { EmotionometerViewInner, RouteParams } from 'e-telligence/EmotionometerView'
import { baseUrl as gadgetPackBaseUrl } from '../GadgetPack'
import { useUserState } from 'app/UserState'
import { EmotionometerActivityInner } from 'session/sections/custom/emotionometer/EmotionometerActivity'
import { ProgressionEventsStateProvider, useProgressionEventExists } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'

export const EmotionometerView: React.FC<{ baseUrl: string; editMode?: boolean }> = ({ baseUrl, editMode }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useGadgetPackState()
  const { gameMode } = useUserState()

  const { emotion } = useParams<RouteParams>() as RouteParams
  const emotionometer = state.emotionometers.find(({ type }) => type === emotion)
  // if (editMode && !emotionometer) return null

  const realBaseUrl = (location.state || {}).skipCardHolder ? gadgetPackBaseUrl : baseUrl

  return (
    <HeaderTemplate
      title={`${editMode ? 'Edit ' : ''}${emotionometer?.title || ''} Emotionometer`}
      onExit={() => history.push(realBaseUrl, location.state)}
      exitLabel="Back"
      Buttons={
        <>
          <Button
            onClick={() =>
              history.push(`${baseUrl}/emotionometer/${emotion}${editMode ? '' : '/edit'}`, location.state)
            }
            children="Edit"
            pressed={!!editMode}
          />
        </>
      }>
      {gameMode === 'SAS-SG-D' ? (
        <ProgressionEventsStateProvider>
          <SessionEmotionometer
            editMode={editMode || false}
            emotion={emotion !== 'anger' && emotion !== 'anxiety' ? 'anger' : emotion}
          />
        </ProgressionEventsStateProvider>
      ) : (
        <DarkPanel>
          <EmotionometerViewInner editMode={editMode} state={state} dispatch={dispatch} baseUrl={baseUrl} />
        </DarkPanel>
      )}
    </HeaderTemplate>
  )
}

const SessionEmotionometer: React.FC<{ editMode: boolean; emotion: 'anger' | 'anxiety' }> = ({ editMode, emotion }) => {
  const [device, setDevice] = useState<'situations' | 'bodyClues' | 'relaxationGadgets' | undefined>('situations')
  const unlockedBodyClues = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'emotionometer',
    subcategory: emotion,
    event_key: 'device',
    event_value: 'bodyClues',
  })
  const unlockedGadgets = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'emotionometer',
    subcategory: emotion,
    event_key: 'device',
    event_value: 'relaxationGadgets',
  })

  return (
    <>
      {(unlockedBodyClues || unlockedGadgets) && editMode && (
        <>
          <Row>
            <H2>Select Device</H2>
          </Row>
          <Spacer />
          <Row>
            <Button
              pressed={device === 'situations'}
              size="s"
              children="Situations"
              onClick={() => setDevice('situations')}
            />
            <Spacer />
            {unlockedBodyClues && (
              <>
                <Button
                  pressed={device === 'bodyClues'}
                  size="s"
                  children="Body Clues"
                  onClick={() => setDevice('bodyClues')}
                />
                <Spacer />
              </>
            )}
            {unlockedGadgets && (
              <Button
                pressed={device === 'relaxationGadgets'}
                size="s"
                children="Relaxation Gadgets"
                onClick={() => setDevice('relaxationGadgets')}
              />
            )}
          </Row>
          <Spacer />
        </>
      )}
      <EmotionometerActivityInner
        viewAllDevices={!editMode}
        hideStickerTray={!editMode}
        readonly={!editMode}
        activityTitle={emotion}
        emotion={emotion}
        device={!editMode ? undefined : device}
        activeEmotionRangeIndexes={[true, true, true]}
      />
    </>
  )
}

const DarkPanel = styled(Panel)`
  background-color: #222222;
  border-bottom-color: #000000;
  color: white;
  & h1,
  & h2,
  & h3,
  & h4 {
    color: white;
  }
  & > *:first-child {
    border-bottom-color: #575758 !important;
  }
`
