import React, { useEffect, useRef, useState, useContext } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { Row } from 'common/ui'
import { useSound } from 'utils/useSound'
import { FadeToBlack, FadeFromBlack } from 'common/FadeTransitions'
import { useSfxMuted } from 'utils/useMuted'
import { ScreenScalerContext } from 'common/ScreenScaler'
import { preloadAudio, preloadImage } from 'utils/preloadUtils'

interface Props {
  direction: 'down' | 'up'
  onComplete: () => void
  animate: boolean
}

const UP_Y = 0
const DOWN_Y = -800
const DOOR_DURATION = 600
const LIFT_DURATION = 3000

const windSoundSrc: string = require('./assets/audio/elevator_wind.mp3')
const doorImgSrc: string = require('./assets/image/ElevatorDoor.png')

export function preloadElevatorAssets() {
  return Promise.all([preloadAudio(windSoundSrc), preloadImage(doorImgSrc)])
}

export const Elevator: React.FC<Props> = ({ direction, onComplete, animate }) => {
  const { zoom } = useContext(ScreenScalerContext)
  const elevatorWindSound = useSfxMuted(useSound(windSoundSrc, false))
  const [{ y }, setY] = useSpring<{ y: number }>(() => ({ y: direction === 'up' ? DOWN_Y : UP_Y }))
  const [{ x }, setX] = useSpring<{ x: number }>(() => ({ x: direction === 'up' ? 0 : 1 }))
  const [{ opacity }, setBgOpacity] = useSpring(() => ({ opacity: 1 }))
  const [fadeToBlack, setFadeToBlack] = useState(false)

  const onCompleteRef = useRef(onComplete)
  onCompleteRef.current = onComplete

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (animate) {
      if (direction === 'up') {
        setTimeout(() => elevatorWindSound.play(), 100)
        setY({ y: UP_Y, config: { duration: LIFT_DURATION, easing: Phaser.Math.Easing.Sine.InOut } })
        setX({
          delay: LIFT_DURATION,
          x: 1,
          config: { duration: DOOR_DURATION, easing: Phaser.Math.Easing.Sine.InOut },
          onRest: onCompleteRef.current,
        })
      } else {
        setTimeout(() => elevatorWindSound.play(), DOOR_DURATION + 200)
        setX({ x: 0, config: { duration: DOOR_DURATION, easing: Phaser.Math.Easing.Sine.InOut } })
        setY({
          delay: DOOR_DURATION,
          y: DOWN_Y,
          config: { duration: LIFT_DURATION, easing: Phaser.Math.Easing.Sine.InOut },
          onRest: () => setFadeToBlack(true),
        })
      }
    }
  }, [animate])
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleSkip = () => {
    if (animate) {
      setX({
        x: 1,
        config: { duration: DOOR_DURATION, easing: Phaser.Math.Easing.Sine.InOut },
        onRest: onCompleteRef.current,
      })
      if (direction === 'up') {
        setBgOpacity({ opacity: 0, config: { duration: DOOR_DURATION / 2 } })
      } else {
        setFadeToBlack(true)
      }
    }
  }

  return (
    <Row flex style={{ overflow: 'hidden' }} onClick={handleSkip}>
      <ElevatorBackground style={{ transform: y.interpolate(y => `scale(${zoom}) translateY(${y}vh)`), opacity }} />
      <ElevatorDoorLeft style={{ transform: x.interpolate(x => `translateX(${-x * 100}%)`) }} />
      <ElevatorDoorRight style={{ transform: x.interpolate(x => `translateX(${x * 100}%) scaleX(-1)`) }} />
      {direction === 'up' && <FadeFromBlack transitionTime={250} />}
      {direction === 'down' && <FadeToBlack wait={!fadeToBlack} transitionTime={250} onComplete={onComplete} />}
    </Row>
  )
}

const ElevatorBackground = styled(animated.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 900vh;
  background: linear-gradient(
    180deg,
    transparent 0vh,
    transparent 100vh,
    black 200vh,
    black 250vh,
    white 300vh,
    black 350vh,
    black 400vh,
    white 450vh,
    black 500vh,
    black 550vh,
    white 600vh,
    black 650vh,
    black 700vh,
    white 750vh,
    black 800vh,
    black 900vh
  );
  transform-origin: 0 0;
`

const ElevatorDoorLeft = styled(animated.div)`
  flex: auto;
  background: url(${doorImgSrc}) no-repeat center top / 100% auto;
`

const ElevatorDoorRight = styled(animated.div)`
  flex: auto;
  background: url(${doorImgSrc}) no-repeat center top / 100% auto;
  // fix background showing through crack of doors slightly
  margin-left: -1px;
`
