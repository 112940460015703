import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Button, Container, CUT_BL, CUT_BR, CUT_TL, CUT_TR, H1, Row, TAB_B_L, TAB_T_L } from 'common/ui'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { codeCards } from 'e-telligence/constants/codeCards'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import { CodeCardViewInner } from 'e-telligence/CodeCardView'
import { fontBold } from 'fonts'
import { CodeCardId } from 'shared/e-telligence/types'
import { CodeCardTypes, ProgressionEvent } from 'shared/session/types'
import { objShapeMatches, GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { useProgressionEventsState } from 'session/ProgressionEventsState'

export const codeCardMapping: { [key in CodeCardId]: CodeCardTypes } = {
  decoder: 'decoder_formula',
  conversation: 'conversation_code',
  play: 'play_code',
  'damage-control': 'damage_control_code',
  jokes: 'difference_code',
  'bully-guard-body-armour': 'bully_guard_body_armour',
  confusion: 'confusion_code',
}

export const CodeCardModal: React.FC<{ onClose: () => void; mentorMode?: boolean }> = ({ onClose, mentorMode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [activeCardId, setActiveCardId] = useState<string | null>(null)

  const { state, dispatch } = useGadgetPackState()

  const { progressionEvents } = useProgressionEventsState()

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(() => {
      onClose()
      setActiveCardId(null)
    }, MODAL_FADE_OUT_TIME)
  }

  const handleCodeSelect = (id: string) => {
    setActiveCardId(id)
  }

  const handleBackBtn = () => {
    if (activeCardId) setActiveCardId(null)
    else delayedOnClose()
  }

  const checkUnlocked = (eventStructure: Partial<ProgressionEvent<Object>>) => {
    let returnVal = false
    for (let testProgressionEvent of progressionEvents) {
      if (objShapeMatches(eventStructure, testProgressionEvent)) {
        returnVal = true
      }
    }
    return returnVal
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}
      panelStyle={mentorMode ? { minWidth: '100vw', minHeight: '100vh', padding: '30px 20px' } : { maxHeight: '80vh' }}>
      <CodeCardsContainer style={{ height: !mentorMode && activeCardId ? '80vh' : 'auto' }} mentorMode={mentorMode}>
        <ScrollContainer>
          <Row justifyContent={activeCardId ? 'flex-end' : 'space-between'} alignItems="center">
            {!activeCardId && (
              <H1>
                Code <br /> Cards
              </H1>
            )}
            <Button children={activeCardId ? 'Close' : 'Exit'} onClick={handleBackBtn} />
          </Row>
          {!activeCardId && (
            <Container>
              {[...state.customCodeCards, ...codeCards].map((codeCard, i) => {
                const unlocked = codeCard.isPreset
                  ? checkUnlocked({
                      type: GADGET_PACK_UNLOCK,
                      category: 'code-cards',
                      subcategory: codeCardMapping[codeCard.id as CodeCardId],
                    })
                  : true

                return (
                  <Tile
                    key={i}
                    locked={!unlocked}
                    title={codeCard.title}
                    backgroundImg={codeCard.cover || require(`e-telligence/assets/code-cards/CodeCard-Blank-white.png`)}
                    onClick={() => handleCodeSelect(codeCard.id)}
                    mentorMode={mentorMode}
                    children={!codeCard.isPreset && <CustomLabel>{codeCard.title}</CustomLabel>}
                  />
                )
              })}
            </Container>
          )}
          {activeCardId && (
            <CodeCardWrapper>
              <CodeCardViewInner
                baseUrl=""
                state={state}
                dispatch={dispatch}
                cardId={activeCardId}
                hideActionButton
                hideBackButton
              />
            </CodeCardWrapper>
          )}
        </ScrollContainer>
      </CodeCardsContainer>
    </MobileModalPanel>
  )
}

const CodeCardsContainer = styled.div<{ mentorMode?: boolean }>`
  box-sizing: border-box;
  padding: ${p => (p.mentorMode ? '0' : '30px')};
  min-width: ${p => (p.mentorMode ? '100%' : '700px')};
  max-width: 700px;
  overflow: hidden;
  ${Container} {
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  ${Button} {
    margin-bottom: 20px;
  }
`

const Tile = styled.div<{ backgroundImg: string; locked: boolean; mentorMode?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-image: url(${p => p.backgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 165px;
  width: ${p => (p.mentorMode ? '110px' : 'calc(20% - 20px)')};
  margin: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;

  ${p =>
    p.locked
      ? css`
          opacity: 0.4;
          pointer-events: none;
        `
      : ''}

  &:hover {
    opacity: 0.5;
  }
`

export const CodeCardWrapper = styled.div`
  background: #2c2c2c;
  border-radius: 12px;

  > div {
    width: 100%;
  }
`

const CustomLabel = styled.figcaption`
  ${fontBold}
  font-size: 12px;
  color: white !important;
  z-index: 1;
`

const ScrollContainer = styled.div`
  height: 100%;
  overflow: auto;
  border-radius: 10px;
`
