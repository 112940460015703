import qs from 'qs'
import { toJson, postJson, retryFetch } from 'utils/apiUtils'

import { Attempt } from 'types'
import { State as SkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { BaseAction, GameModeSlug, UploadResponse } from 'shared/types'
import { ETelligenceState } from 'shared/e-telligence/types'
import { GadgetPackState } from 'shared/gadget-pack/types'
import {
  InputValue,
  SavedInputValue,
  ProgressionEvent,
  SavedProgressionEvent,
  SessionModule,
} from 'shared/session/types'
import { FacManModule } from 'shared/fac-man/types'
import { Tipsheet } from 'shared/tipsheets/types'
import { SessionEntity, SessionEntityHydrated } from 'shared/dashboard/types'
import { TrainingState } from 'shared/training/types'
import { QuestionnaireSummary, QuestionnaireSummarySaved } from 'shared/questionnaires/types'
import { ActionTypes as GadgetPackActionType } from 'shared/gadget-pack/actionTypes'
import { sortByKey } from 'utils/sortUtils'
import { AnyModuleCode } from 'shared/dashboard/moduleCodes'

export interface DrupalProfile {
  profile_id: string
  first_name: string
  last_name: string
  username: string
  user_id: string
  roles: string
}

export interface GetProfile {
  id: number
  profile_name: string | null
  theme_name: string | null
  age: number | null
  country_code: string | null
  gender_id: string | null
  intervention_type: GameModeSlug
  allowed_from: string
}

interface CreateProfile {
  profile_name: string
  theme_name?: string
}

interface UpdateProfile {
  id: number
  profile_name?: string
  theme_name?: string
}

interface Pdf<T> {
  id: number
  profile_id: number
  template_url: string
  payload: T
  url: string
  checksum: string
  bytes: number
  created_at: number
}

export interface FlashParams {
  profile_id: number
  profile_name: string
  product_key: string
  session_token: string
}

interface SuccessResponse {
  success: boolean
}

interface AccessTokenResponse {
  token_type: 'Bearer'
  expires_in: number
  access_token: string
  refresh_token: string
}

export interface SkillTrackerResponse {
  profile_id: number
  state: SkillTrackerState | null
  revision: number
}

export interface ETelligenceResponse {
  profile_id: number
  state: ETelligenceState | null
  revision: number
}

export interface GadgetPackResponse {
  profile_id: number
  state: GadgetPackState | null
  revision: number
}

export interface GadgetPackResponse2 {
  profile_id: number
  state: GadgetPackState | null
  updated_at: number
  last_user_commit: number
  response_code: number
}

export interface GadgetPackActionLog {
  id: number
  type: GadgetPackActionType['type']
  payload: GadgetPackActionType
  created_at: number
  committed_at: number
}

export type GadgetPackActionsResponse = GadgetPackActionLog[]

export interface ProfileProgress extends GetProfile {
  skill_tracker: SkillTrackerState | null
  activity_attempts: Attempt[]
  progression_events: ProgressionEvent<unknown>[]
}

interface SessionInputValueSearchParams {
  session_uid: string
  participant_uid?: string
  owner?: string
  owner_id?: number
  name?: string
}

interface TrainingCertificateResponse extends SuccessResponse {
  url: string
}

interface RegisterDeviceResponse {
  token_id: string
}

function auth(accessToken: string) {
  return { headers: { Authorization: `Bearer ${accessToken}` } }
}

/**
 * Game API
 */

export function getProfiles(accessToken: string): Promise<GetProfile[]> {
  return retryFetch('/api/v1/profiles', auth(accessToken)).then(toJson)
}

export function getActivityAttempts(profileId: number, accessToken: string): Promise<Attempt[]> {
  return retryFetch(`/api/v1/activity_attempts/${profileId}`, auth(accessToken)).then(toJson)
}

export function createProfile(profile: CreateProfile, accessToken: string): Promise<GetProfile> {
  return postJson(`/api/v1/profiles`, profile, auth(accessToken))
}

export function getProfile(profileId: number, accessToken: string): Promise<GetProfile> {
  return retryFetch(`/api/v1/profiles/${profileId}`, auth(accessToken)).then(toJson)
}

export function updateProfile(profile: UpdateProfile, accessToken: string): Promise<SuccessResponse> {
  return postJson(`/api/v1/profiles/${profile.id}`, profile, { method: 'PATCH', ...auth(accessToken) })
}

export function getFlashParams(accessToken: string): Promise<FlashParams> {
  return retryFetch('/api/v1/flash-params', auth(accessToken)).then(toJson)
}

export function saveActivityAttempt(attempt: Attempt, accessToken: string): Promise<SuccessResponse> {
  return postJson('/api/v1/activity_attempts', attempt, auth(accessToken))
}

/**
 * Authentication API
 */

export function sendHeartbeat(accessToken: string, debugParams: any) {
  return postJson(`/api/v1/heartbeat?${qs.stringify(debugParams)}`, {}, auth(accessToken), { retries: 0 })
}

export function login(gamePlayCode: string, scope: string): Promise<AccessTokenResponse> {
  const body = new FormData()
  body.append('grant_type', 'password')
  body.append('client_id', process.env.REACT_APP_OAUTH2_CLIENT_ID || '')
  body.append('scope', scope)
  body.append('username', gamePlayCode)
  body.append('password', scope)
  return retryFetch('/api/v1/access-token', { method: 'POST', body }).then(toJson)
}

export function refreshAccessToken(refreshToken: string): Promise<AccessTokenResponse> {
  const body = new FormData()
  body.append('grant_type', 'refresh_token')
  body.append('client_id', process.env.REACT_APP_OAUTH2_CLIENT_ID || '')
  body.append('refresh_token', refreshToken)
  return retryFetch('/api/v1/access-token', { method: 'POST', body }).then(toJson)
}

// this is for skill tracker login, authenticated via drupal oauth access token
export function drupalLicenceLogin(licenceUuid: string, accessToken: string): Promise<AccessTokenResponse> {
  const body = new FormData()
  body.append('grant_type', 'drupal')
  body.append('client_id', process.env.REACT_APP_OAUTH2_CLIENT_ID || '')
  body.append('scope', 'mentor')
  body.append('username', licenceUuid)
  body.append('password', accessToken)
  return retryFetch('/api/v1/access-token', { method: 'POST', body }).then(toJson)
}

export function validateDeviceToken(accessToken: string, deviceToken: string): Promise<SuccessResponse> {
  return postJson('/api/v1/devices/validate', { device_token: deviceToken }, auth(accessToken))
}

export function registerDevice(accessToken: string, drupalAccessToken: string): Promise<RegisterDeviceResponse> {
  return postJson('/api/v1/devices/register', { drupal_access_token: drupalAccessToken }, auth(accessToken))
}

/**
 * File saving API
 */

export function saveFile(blob: Blob, accessToken: string): Promise<UploadResponse> {
  const body = new FormData()
  body.append('file', blob)
  return retryFetch('/api/v1/upload', {
    method: 'POST',
    body,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(toJson)
}

export function deleteFile(uid: string, accessToken: string): Promise<unknown> {
  return retryFetch(
    `/api/v1/upload/${uid}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    { retries: 0 }
  ).then(toJson)
}

/**
 * PDF API
 */

export function createPdf<T>(
  templateUrl: string,
  payload: T,
  accessToken: string,
  options: { hideHeaderFooter?: boolean; footerTemplate?: 'footer' | 'footer-mono' } = {}
): Promise<Pdf<T>> {
  const urlParams = new URLSearchParams()
  if (options.hideHeaderFooter) urlParams.append('hideHeaderFooter', '1')
  if (options.footerTemplate) urlParams.append('footerTemplate', options.footerTemplate)
  const paramsStr = urlParams.toString()
  return postJson(
    `/api/v1/pdfs${paramsStr ? `?${paramsStr}` : ''}`,
    { template_url: templateUrl, payload },
    auth(accessToken)
  )
}

export function getPdf<T>(pdfId: number, accessToken: string): Promise<Pdf<T>> {
  return retryFetch(`/api/v1/pdfs/${pdfId}`, auth(accessToken)).then(toJson)
}

/**
 * Skill Tracker API
 */

export function getSkillTracker(profileId: number, accessToken: string): Promise<SkillTrackerResponse> {
  if (!profileId) return Promise.reject(new Error('Profile ID not defined'))
  return retryFetch(`/api/v1/skill-tracker/${profileId}`, auth(accessToken)).then(toJson)
}

export function saveSkillTracker(
  profileId: number,
  state: SkillTrackerState,
  actions: BaseAction[],
  revision: number,
  accessToken: string
): Promise<SuccessResponse> {
  if (!profileId) return Promise.reject(new Error('Profile ID not defined'))
  return postJson(
    `/api/v1/skill-tracker/${profileId}`,
    { state, actions, revision },
    { method: 'PUT', ...auth(accessToken) }
  )
}

/**
 * E-Telligence API
 */

export function getETelligence(profileId: number, accessToken: string): Promise<ETelligenceResponse> {
  if (!profileId) return Promise.reject(new Error('Profile ID not defined'))
  return retryFetch(`/api/v1/etelligence/${profileId}`, auth(accessToken)).then(toJson)
}

export function saveETelligence(
  profileId: number,
  state: ETelligenceState,
  actions: BaseAction[],
  revision: number,
  accessToken: string
): Promise<SuccessResponse> {
  if (!profileId) return Promise.reject(new Error('Profile ID not defined'))
  return postJson(
    `/api/v1/etelligence/${profileId}`,
    { state, actions, revision },
    { method: 'PUT', ...auth(accessToken) }
  )
}

/**
 * Gadget Pack API
 */

export function getGadgetPack(
  profileId: number,
  accessToken: string,
  lastUpdate?: number | null
): Promise<GadgetPackResponse2> {
  if (!profileId) return Promise.reject(new Error('Profile ID not defined'))
  return retryFetch(
    `/api/v1/gadget-pack/${profileId}${lastUpdate ? `?last_update=${lastUpdate}` : ''}`,
    auth(accessToken)
  ).then((response: Response) => {
    if (response.status === 204) return { response_code: response.status }
    return response
      .json()
      .catch((error) => {
        throw response
      })
      .then((json) => {
        if (!response.ok) throw json
        return {
          ...json,
          response_code: response.status,
        }
      })
  })
}

export function getGadgetPackActions(profileId: number, accessToken: string): Promise<GadgetPackActionsResponse> {
  if (!profileId) return Promise.reject(new Error('Profile ID not defined'))
  return retryFetch(`/api/v1/gadget-pack-actions/${profileId}`, auth(accessToken)).then(toJson)
}

export function saveGadgetPack(
  profileId: number,
  accessToken: string,
  state: GadgetPackState,
  actions: BaseAction[]
): Promise<{ success: boolean; updated_at: number }> {
  if (!profileId) return Promise.reject(new Error('Profile ID not defined'))
  return postJson(`/api/v1/gadget-pack/${profileId}`, { state, actions }, { method: 'PUT', ...auth(accessToken) })
}

/**
 * Session & Modules aPI
 */

export function getSession(sessionUid: string, accessToken: string): Promise<SessionEntityHydrated> {
  return retryFetch(`/api/v1/sessions/${sessionUid}`, auth(accessToken)).then(toJson)
}

export function getSessionsList(accessToken: string): Promise<SessionEntity> {
  return retryFetch(`/api/v1/sessions/list`, auth(accessToken)).then(toJson)
}

export function getModulesList(
  moduleUids: string[],
  accessToken: string
): Promise<Pick<SessionModule, 'id' | 'type' | 'title' | 'public_title' | 'uid'>[]> {
  return retryFetch(`/api/v1/modules/list?module_uids=${moduleUids.join(',')}`, auth(accessToken)).then(toJson)
}

export function getManualModulesList(
  manualModuleUids: string[],
  accessToken: string
): Promise<Pick<FacManModule, 'id' | 'uid' | 'title' | 'public_title'>> {
  return retryFetch(
    `/api/v1/manual_modules/list?manual_module_uids=${manualModuleUids.join(',')}`,
    auth(accessToken)
  ).then(toJson)
}

export function getManualModuleByUid(manualModuleUid: string, accessToken: string): Promise<FacManModule> {
  return retryFetch(`/api/v1/manual_modules/${manualModuleUid}`, auth(accessToken)).then(toJson)
}

export function getManualModuleByCode(type: string, code: string, accessToken: string): Promise<FacManModule> {
  return retryFetch(`/api/v1/manual_modules/${type}/${code}`, auth(accessToken)).then(toJson)
}

export function getTipsheetByCode(code: string, accessToken: string): Promise<Tipsheet> {
  return retryFetch(`/api/v1/tipsheets/${code}`, auth(accessToken)).then(toJson)
}

export function getModuleMetadata(
  sessionType: SessionEntity['type'],
  moduleCode: AnyModuleCode,
  accessToken: string
): Promise<{ toc_enabled: boolean; public_title?: string | null }> {
  return retryFetch(`/api/v1/modules/metadata/${sessionType}/${moduleCode}`, auth(accessToken)).then(toJson)
}

/**
 * SessionInputValue API
 */

export function getSessionInputValues<ValueType extends any>(
  params: SessionInputValueSearchParams,
  accessToken: string
): Promise<SavedInputValue<ValueType>[]> {
  return retryFetch(`/api/v1/session_input_values?${qs.stringify(params)}`, auth(accessToken)).then(toJson)
}

export function getSessionInputValue<ValueType extends any>(
  id: number,
  accessToken: string
): Promise<SavedInputValue<ValueType>> {
  return retryFetch(`/api/v1/session_input_values/${id}`, auth(accessToken)).then(toJson)
}

export function createSessionInputValue<ValueType extends any>(
  sessionInputValue: InputValue<ValueType>,
  accessToken: string
): Promise<SavedInputValue<ValueType>> {
  return postJson('/api/v1/session_input_values', sessionInputValue, auth(accessToken))
}

export function saveSessionInputValue(
  id: number,
  value: any,
  revision: number,
  accessToken: string
): Promise<SuccessResponse> {
  return postJson(`/api/v1/session_input_values/${id}`, { value, revision }, { method: 'PATCH', ...auth(accessToken) })
}

export function deleteSessionInputValue(id: number, accessToken: string): Promise<SuccessResponse> {
  return postJson(`/api/v1/session_input_values/${id}`, {}, { method: 'DELETE', ...auth(accessToken) })
}

export function getPastSharedSessionInputValues<ValueType extends any>(
  params: Omit<SessionInputValueSearchParams, 'participant_uid'> & {
    include_current_session?: boolean
    mentor_uid?: string
  },
  accessToken: string
) {
  return postJson(`/api/v1/session_input_values/past_shared/${params.session_uid}`, params, auth(accessToken), {
    retries: 0,
  }).then((data: SavedInputValue<ValueType>[]) => data.sort(sortByKey('created_at', 'descending')))
}

/**
 * UserProgressEvents API
 */

export function getProgressionEvents<ValueType extends any>(
  accessToken: string,
  participantUid: string
): Promise<SavedProgressionEvent<ValueType>[]> {
  return retryFetch(`/api/v1/user_progression_events?participant_uid=${participantUid}`, auth(accessToken)).then(toJson)
}

export function getProgressionEvent<ValueType extends any>(
  id: number,
  accessToken: string
): Promise<SavedProgressionEvent<ValueType>> {
  return retryFetch(`/api/v1/user_progression_events/${id}`, auth(accessToken)).then(toJson)
}

export function createProgressionEvent<ValueType extends any>(
  progressionEvent: ProgressionEvent<ValueType>,
  accessToken: string
): Promise<SavedProgressionEvent<ValueType>> {
  return postJson('/api/v1/user_progression_events', progressionEvent, auth(accessToken))
}

export function batchCreateProgressionEvents(
  progressionEvents: ProgressionEvent<any>[],
  accessToken: string
): Promise<SavedProgressionEvent<any>[]> {
  return postJson('/api/v1/user_progression_events/batch', progressionEvents, auth(accessToken))
}

/**
 * Drupal API
 */

export function getDrupalProfiles(accessToken: string): Promise<DrupalProfile[]> {
  return retryFetch('/api/sst/rest/me', auth(accessToken)).then(toJson)
}

export function logoutDrupalUser(sessionToken: string): Promise<SuccessResponse> {
  return postJson('/api/v1/logout', { sessionToken })
}

export function getDrupalAccessToken(code: string): Promise<AccessTokenResponse> {
  const body = new FormData()
  body.append('grant_type', 'authorization_code')
  body.append('client_id', process.env.REACT_APP_SST_OAUTH2_CLIENT_ID || '')
  body.append('code', code)
  body.append('redirect_uri', `${window.location.origin}/authreturn`)
  return retryFetch('/api/sst/oauth2/token', { method: 'POST', body }).then(toJson)
}

export function refreshDrupalAccessToken(refreshToken: string): Promise<AccessTokenResponse> {
  const body = new FormData()
  body.append('grant_type', 'refresh_token')
  body.append('client_id', process.env.REACT_APP_SST_OAUTH2_CLIENT_ID || '')
  body.append('refresh_token', refreshToken)
  return retryFetch('/api/sst/oauth2/token', { method: 'POST', body }).then(toJson)
}

// Training Data
export function saveTrainingUnitValues(
  unitUid: string,
  state: TrainingState,
  accessToken: string
): Promise<SuccessResponse> {
  return postJson(`/api/v1/training_unit_values/${unitUid}`, state, { method: 'PUT', ...auth(accessToken) })
}

export function createTrainingCertificate(
  courseType: string,
  accessToken: string
): Promise<TrainingCertificateResponse> {
  return postJson(`/api/v1/training_courses/certificate/${courseType}`, undefined, auth(accessToken))
}

export function editCadetName(
  uid: string,
  firstName: string,
  lastName: string,
  accessToken: string
): Promise<SuccessResponse> {
  return postJson(
    `/api/v1/mentors/${uid}/edit_name`,
    {
      first_name: firstName,
      last_name: lastName,
    },
    { method: 'PUT', ...auth(accessToken) }
  )
}

// Questionnaire
export function createQuestionnaire(
  questionnaire: QuestionnaireSummary,
  accessToken: string
): Promise<SuccessResponse> {
  return postJson(`/api/v1/questionnaire_overview`, questionnaire, auth(accessToken))
}

export function updateQuestionnaire(
  questionnaire: QuestionnaireSummarySaved,
  accessToken: string
): Promise<SuccessResponse> {
  return postJson(`/api/v1/questionnaire_overview`, questionnaire, { method: 'PUT', ...auth(accessToken) })
}

export function renameCustomQuestionnaires(
  newName: string,
  oldName: string,
  lookupData: { cadet_mentor_id: QuestionnaireSummarySaved['cadet_mentor_id'] },
  accessToken: string
) {
  return postJson(
    `/api/v1/questionnaires/rename_custom`,
    {
      old_name: oldName,
      new_name: newName,
      ...lookupData,
    },
    auth(accessToken)
  )
}

export function deleteQuestionnaire(
  questionnaire: QuestionnaireSummarySaved,
  accessToken: string
): Promise<SuccessResponse> {
  return postJson(`/api/v1/questionnaire_overview`, questionnaire, { method: 'DELETE', ...auth(accessToken) })
}
