import { Activity } from 'types'
import {
  SpotTheSuspect,
  MurderAtEarnshawManor,
  EyewitnessRecall,
  CrimeAtTheCathedral,
  TheLineUp,
  DetectiveLaboratory,
  VoiceVerification,
  DegreesOfDelightAndDistress,
  SecretAgentViewingPanel,
  DetectiveFlightChallenge,
  SecretOfTheSchoolyardGhost,
} from 'activities'

export interface Gadget {
  title: string
  src: string
  outlineSrc: string
  pdaSrc: string
  requiredFor: Activity
  receivedAfter: Activity
}

export const GADGETS: Gadget[] = [
  {
    title: 'Invisible Ink Reader',
    src: require('./common/assets/image/gadgets/colour/InvisibleInkReader.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/InvisibleInkReader.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/InvisibleInkReader.png'),
    receivedAfter: SpotTheSuspect,
    requiredFor: MurderAtEarnshawManor,
  },
  {
    title: 'Night Vision Contact Lenses',
    src: require('./common/assets/image/gadgets/colour/NightVisionContactLenses.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/NightVisionContactLenses.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/NightVisionContactLenses.png'),
    receivedAfter: EyewitnessRecall,
    requiredFor: CrimeAtTheCathedral,
  },
  {
    title: 'Thought Catcher',
    src: require('./common/assets/image/gadgets/colour/ThoughtCatcher.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/ThoughtCatcher.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/ThoughtCatcher.png'),
    receivedAfter: TheLineUp,
    requiredFor: DetectiveLaboratory,
  },
  {
    title: 'Voice Frequency Amplifier',
    src: require('./common/assets/image/gadgets/colour/VoiceFrequencyAmplifier.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/VoiceFrequencyAmplifier.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/VoiceFrequencyAmplifier.png'),
    receivedAfter: VoiceVerification,
    requiredFor: CrimeAtTheCathedral,
  },
  {
    title: 'Ruby Laser Ring',
    src: require('./common/assets/image/gadgets/colour/RubyLaserRing.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/RubyLaserRing.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/RubyLaserRing.png'),
    receivedAfter: DetectiveLaboratory,
    requiredFor: CrimeAtTheCathedral,
  },
  {
    title: 'Secret Agent Stress Ball',
    src: require('./common/assets/image/gadgets/colour/SecretAgentStressBall.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/SecretAgentStressBall.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/SecretAgentStressBall.png'),
    receivedAfter: DegreesOfDelightAndDistress,
    requiredFor: CrimeAtTheCathedral,
  },
  {
    title: 'Fingerprint Identification Device',
    src: require('./common/assets/image/gadgets/colour/FingerprintIdentificationDevice.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/FingerprintIdentificationDevice.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/FingerprintIdentificationDevice.png'),
    receivedAfter: SecretAgentViewingPanel,
    requiredFor: SecretOfTheSchoolyardGhost, // TODO: find correct activity
  },
  {
    title: 'DVD Recording Glasses',
    src: require('./common/assets/image/gadgets/colour/DVDRecordingGlasses.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/DVDRecordingGlasses.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/DVDRecordingGlasses.png'),
    receivedAfter: CrimeAtTheCathedral,
    requiredFor: SecretOfTheSchoolyardGhost,
  },
  {
    title: 'Voice Recording Attachment',
    src: require('./common/assets/image/gadgets/colour/VoiceRecordingAttachment.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/VoiceRecordingAttachment.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/VoiceRecordingAttachment.png'),
    receivedAfter: DetectiveFlightChallenge,
    requiredFor: SecretOfTheSchoolyardGhost,
  },
  {
    title: 'Night Lenses, Shield and Muted Bullets',
    src: require('./common/assets/image/gadgets/colour/MutedBulletAndShieldAttachments.png'),
    outlineSrc: require('./common/assets/image/gadgets/outline/MutedBulletAndShieldAttachments.png'),
    pdaSrc: require('./common/assets/image/gadgets/pda/MutedBulletAndShieldAttachments.png'),
    receivedAfter: MurderAtEarnshawManor,
    requiredFor: SecretOfTheSchoolyardGhost,
  },
]
