import { isProdEnv } from './envUtils'

export const getTouchBackendOptions = () => ({
  enableMouseEvents: true,
  ignoreContextMenu: true,
  enableHoverOutsideTarget: true,
  getDropTargetElementsAtPoint: !!document.elementsFromPoint
    ? false
    : (document as any).msElementsFromPoint
    ? (document as any).msElementsFromPoint.bind(document)
    : getDropTargetElementsAtPoint,
})

const getDropTargetElementsAtPoint = (x: number, y: number, dropTargets: HTMLElement[]) => {
  return dropTargets.filter(t => {
    const rect = t.getBoundingClientRect()
    return x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
  })
}

export const touchInputUnavailable = !('ontouchstart' in window || navigator.maxTouchPoints) && isProdEnv()
