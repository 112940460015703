import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { getDrupalAccessToken, getDrupalProfiles } from 'api'
import { useUserState } from 'app/UserState'
import { P, Spacer } from 'common/ui'
import { GenericPage } from 'app/GenericPage'
import { parseAuthResponse, validateAuthResponse } from './oauth2'
import { LinkButton } from 'common/LinkButton'
import { isDeviceAuthInProgress, setDeviceAuthToken } from './deviceAuth'

export const AuthReturn: React.FC = () => {
  const { setAccessToken, setRefreshToken, setTokenExpiry, setDrupalProfile, setAuthProvider } = useUserState()
  const location = useLocation()
  const history = useHistory()
  const [error, setError] = useState('')

  useEffect(
    () => {
      const auth = parseAuthResponse(location.search)

      if (!auth) {
        setError('Could not authenticate user. (Invalid URL params)')
        return
      }

      const redirectUrl = validateAuthResponse(auth)

      if (!redirectUrl) {
        setError('Could not authenticate user. (State mismatch)')
        return
      }

      getDrupalAccessToken(auth.code)
        .then(data => {
          if (isDeviceAuthInProgress()) {
            setDeviceAuthToken(data.access_token)
            history.replace(redirectUrl || '')
            return
          }

          return getDrupalProfiles(data.access_token).then(profiles => {
            if (!profiles.length) {
              setError('Your profile was not found. Please contact us for support.')
              return
            }
            ReactDOM.unstable_batchedUpdates(() => {
              setAuthProvider('sst')
              setDrupalProfile(profiles[0])
              setAccessToken(data.access_token)
              setRefreshToken(data.refresh_token)
              setTokenExpiry(Date.now() + data.expires_in * 1000)
            })
            window.setTimeout(() => history.replace(redirectUrl || ''))
          })
        })
        .catch(e => {
          setError(`Could not identify user. ${e.error_description || e.error || e.message || e.code || e}`)
        })
    },
    //eslint-disable-next-line
    []
  )

  // send the error to sentry so we can track what's happening
  useEffect(() => {
    if (error) {
      Sentry.captureMessage(error, Sentry.Severity.Error)
    }
  }, [error])

  if (!error) return null

  return (
    <GenericPage title="Error">
      <P>{error}</P>
      <LinkButton to="/">Continue</LinkButton>
      <Spacer size="s" />
    </GenericPage>
  )
}
