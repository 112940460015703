import React from 'react'

export const FlashInstructions: React.FC = () => (
  <div className="sst-play-help container">
    <link href="/assets/legacy/legacy.css" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css?family=Lato|Titillium+Web:400,600,700" rel="stylesheet" />
    <noscript>
      <div className="alert alert-danger" role="alert">
        <h4>Please enable JavaScript</h4>
        <p>
          In order to play the SAS Game, JavaScript needs to be enabled. Here are{' '}
          <a href="https://www.enable-javascript.com/" target="external">
            instructions to enable JavaScript in your web browser
          </a>
          .
        </p>
      </div>
    </noscript>

    <h1 className="bs-block-inverse">
      Almost there.
      <br />
      Before you can play the SAS Game ...
    </h1>
    <div className="row row-eq-height">
      <div className="col-xs-12 col-sm-4">
        <div className="panel panel-default" style={{ flex: 1 }}>
          <div className="panel-body">
            <div className="step">
              <div className="circle">1</div>
            </div>
            <h3>Install</h3>
            <p>
              <img
                src={require('./assets/Adobe_Flash_Player_v11_icon.png')}
                alt="Adobe Flash icon"
                className="img-responsive center-block"
              />
            </p>
            <p>Ensure Adobe Flash is installed on your device</p>
          </div>
          <div className="panel-footer">
            <a href="https://get.adobe.com/flashplayer" target="external" className="btn btn-primary" role="button">
              {' '}
              Install Adobe Flash{' '}
            </a>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-4">
        <div className="panel panel-default" style={{ flex: 1 }}>
          <div className="panel-body">
            <div className="step">
              <div className="circle">2</div>
            </div>
            <h3>Enable</h3>

            <p>To turn on Adobe Flash, follow the instructions for your browser below:</p>
            <div style={{ textAlign: 'left', margin: '0 10px' }}>
              <p>
                <strong>Preferred</strong>
              </p>
              <p>
                <a href="https://support.google.com/chrome/answer/6258784" target="_blank" rel="noopener noreferrer">
                  <img alt="" style={{ margin: '0 4px 0 0' }} src={require('./assets/chrome_bullet.png')} /> Chrome
                </a>
              </p>
              <p>
                <a
                  href="https://support.mozilla.org/en-US/kb/why-do-i-have-click-activate-plugins"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img alt="" style={{ margin: '0 4px 0 0' }} src={require('./assets/ff_bullet.png')} /> Firefox
                </a>
              </p>
              <p>
                <a
                  href="https://helpx.adobe.com/au/flash-player/kb/enabling-flash-player-safari.html"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img alt="" style={{ margin: '0 4px 0 0' }} src={require('./assets/saf_bullet.png')} /> Safari
                </a>
              </p>
              <p>
                <strong>Other</strong>
              </p>
              <p>
                <a
                  href="https://helpx.adobe.com/flash-player/kb/install-flash-player-windows.html"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img alt="" style={{ margin: '0 4px 0 0' }} src={require('./assets/ie_bullet.png')} /> Internet
                  Explorer
                </a>
              </p>
              <p>
                <a
                  href="https://helpx.adobe.com/flash-player/kb/flash-player-issues-windows-10-ie.html"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img alt="" style={{ margin: '0 4px 0 0' }} src={require('./assets/ie_bullet.png')} /> Internet
                  Explorer on Windows 10
                </a>
              </p>
              <p>
                <a
                  href="https://helpx.adobe.com/au/flash-player/kb/flash-player-issues-windows-10-edge.html"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img alt="" style={{ margin: '0 4px 0 0' }} src={require('./assets/ie_bullet.png')} /> Microsoft Edge
                </a>
              </p>
            </div>
          </div>
          <div className="panel-footer">
            Not sure which browser you are using?
            <a href="https://www.whatsmybrowser.org/" target="external" className="btn btn-primary" role="button">
              Find my browser
            </a>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-4">
        <div className="panel panel-default" style={{ flex: 1 }}>
          <div className="panel-body">
            <div className="step">
              <div className="circle">3</div>
            </div>
            <h3>Play</h3>
            <p className="p-x-lg p-t-md">
              <img
                alt=""
                src={require('./assets/Computergame_Logo_ONSCREEN_CADETS-300.png')}
                className="img-responsive center-block"
              />
            </p>
          </div>
          <div className="panel-footer">
            <a href="/flash" className="btn btn-primary" role="button">
              Play SAS Game
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="row p-y">
      <div className="col-md-6 text-left">
        <div className="panel panel-success panel-pricing">
          <div className="panel-heading">
            <i className="fa fa-check-circle"> &nbsp; </i> <i className="fa fa-desktop"> </i> &nbsp;{' '}
            <i className="fa fa-laptop"> </i>
            <h3>Can Play SAS</h3>
          </div>
          <ul className="list-group ">
            <li className="list-group-item"> On the Internet </li>
            <li className="list-group-item">
              On a laptop or desktop computer with Adobe Flash
              <div className="panel panel-success">
                <div className="panel-heading">
                  <h3>Requirements</h3>
                </div>
                <ul className="list-group ">
                  <li className="list-group-item">512MB RAM (1GB recommended). </li>
                  <li className="list-group-item">
                    Flash Player 18 or higher installed and enabled (
                    <a href="https://helpx.adobe.com/au/flash-player.html" target="external">
                      Instructions
                    </a>
                    ).
                  </li>
                  <li className="list-group-item"> Internet Connection: ADSL or faster.</li>
                  <li className="list-group-item">
                    {' '}
                    <i className="fa fa-apple" aria-hidden="true">
                      {' '}
                    </i>{' '}
                    macOS
                    <ul className="list-group">
                      <li className="list-group-item"> Mac OS X v10.10 or later.</li>
                      <li className="list-group-item"> Intel® Core™ Duo 1.83GHz or faster processor.</li>
                      <li className="list-group-item"> Web browser (Chrome or Safari recommended).</li>
                    </ul>
                  </li>
                  <li className="list-group-item">
                    {' '}
                    <i className="fa fa-windows" aria-hidden="true">
                      {' '}
                    </i>{' '}
                    Windows
                    <ul className="list-group">
                      <li className="list-group-item"> Microsoft® Windows 7 or later.</li>
                      <li className="list-group-item"> 2.33GHz or faster x86-compatible processor.</li>
                      <li className="list-group-item">
                        For netbook class devices: Intel Atom™ 1.6GHz or faster processor.
                      </li>
                      <li className="list-group-item">
                        Web browser (Chrome, Internet Explorer or Edge recommended. Firefox Not Recommended).
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="col-md-6 text-left">
        <div className="panel panel-danger panel-pricing m-b-md">
          <div className="panel-heading">
            <i className="fa fa-times-circle"> </i> &nbsp; <i className="fa fa-mobile"> </i> &nbsp;{' '}
            <i className="fa fa-tablet"> </i>
            <h3>Can't play SAS</h3>
          </div>
          <ul className="list-group">
            <li className="list-group-item"> Without the Internet </li>
            <li className="list-group-item"> On an iPhone, iPad or tablet </li>
            <li className="list-group-item">
              <p>
                No Internet?{' '}
                <a href="mailto:support@sst-institute.net" target="external">
                  {' '}
                  Contact us to find out your options{' '}
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className="m-t-md">
          <a
            className="btn btn-danger btn-block btn-default"
            href="https://www.sst-institute.net/technical-support"
            title="Request technical support">
            Request Technical Support
          </a>
        </div>
      </div>
    </div>
  </div>
)
