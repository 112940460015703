import React from 'react'
import { CadetModuleCode } from 'shared/dashboard/moduleCodes'
import { Cell, CellInner } from './Cell'
import { Row, Column, H3, Spacer } from 'common/ui'
import { LinkButton } from 'common/LinkButton'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'
// import { TipsheetPrintButton } from 'tipsheets/TipsheetPrint'

interface Props {
  moduleCode: CadetModuleCode
  baseUrl: string
  context: 'facilitator' | 'mentor'
  mentorUid?: string
  groupId?: number
}

export const TipSheetCell: React.FC<Props> = ({ context, moduleCode, mentorUid, groupId, baseUrl }) => {
  return (
    <Cell accent="orange">
      <CellInner>
        <Column flex="1 1 auto">
          <H3>Teacher Tip Sheet - {getModuleTitle(moduleCode)}</H3>
        </Column>
        <Row flex="none">
          {/* <TipsheetPrintButton
            size="s"
            theme="orange"
            context={context}
            mentorUid={mentorUid}
            groupId={groupId}
            moduleCode={moduleCode}
          /> */}
          <Spacer size="xs" />
          <LinkButton size="s" children="View" theme="orange" to={`${baseUrl}/teacher-tip-sheets/${moduleCode}`} />
        </Row>
      </CellInner>
    </Cell>
  )
}
