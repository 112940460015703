import { AppBackground } from 'common/ui'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { blue, dark, parentGreen, purple, white, ALL_THEMES, ThemeName, THEMES } from 'themes'
import { PARTICIPANT_HEADER_HEIGHT } from './ParticipantUi'
import { PREVIEW_HEADER_HEIGHT } from './PreviewHeader'
import { useSessionState } from './SessionState'
import { HydratedSlide, SessionModule, MODULE_SLIDE, SUPPLEMENTARY_SLIDE } from 'shared/session/types'
import { useGlobalState } from 'app/GlobalState'

export const StepThemeContainer: React.FC<{
  slide: HydratedSlide
  module?: SessionModule
}> = ({ slide, module, children }) => {
  const { isFacilitator, previewing, presentationMode, sessionProfile, pastMode } = useSessionState()
  const { themeIndex } = useGlobalState()
  let theme = blue

  const [sectionHeight, setSectionHeight] = useState<number>(window.innerHeight)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setSectionHeight(window.innerHeight)
    })
  }, [])

  if (module && module.type === 'parent') {
    theme = parentGreen
  } else if (slide.type === MODULE_SLIDE) {
    switch (slide.step.theme) {
      case 'white': theme = white; break // prettier-ignore
      case 'purple': theme = purple; break // prettier-ignore
      case 'dark': theme = dark; break // prettier-ignore
      default: theme = !isFacilitator && sessionProfile.userType !== 'mentor' ? THEMES[themeIndex] : blue; break // prettier-ignore
    }
  } else if (slide.type === SUPPLEMENTARY_SLIDE) {
    if (slide.supplementarySlide.theme) theme = ALL_THEMES[slide.supplementarySlide.theme]
  } else {
    theme = !isFacilitator && sessionProfile.userType !== 'mentor' ? THEMES[themeIndex] : blue
  }

  const themeString = (Object.keys(ALL_THEMES) as ThemeName[]).reduce(
    (val, key) => (ALL_THEMES[key] === theme ? key : val),
    'blue' as ThemeName
  )

  return (
    <>
      {isFacilitator && !presentationMode && (
        <FacilitatorParticipantView
          $inPreviewMode={previewing}
          theme={themeString}
          $inPastMode={pastMode}
          $pageHeight={sectionHeight}>
          {children}
        </FacilitatorParticipantView>
      )}
      {(!isFacilitator || presentationMode) && (
        <ParticipantView
          $inPreviewMode={previewing}
          $inPresentationMode={presentationMode}
          theme={themeString}
          style={{
            paddingTop: presentationMode ? 0 : PARTICIPANT_HEADER_HEIGHT + (previewing ? PREVIEW_HEADER_HEIGHT : 0),
          }}>
          {children}
        </ParticipantView>
      )}
    </>
  )
}

const ParticipantView = styled(AppBackground)<{ $inPreviewMode: boolean; $inPresentationMode: boolean }>`
  box-sizing: border-box;
  max-width: 100vw;
  height: 100%;

  ${p =>
    p.theme === 'dark'
      ? css`
          background: repeating-linear-gradient(
              90deg,
              transparent 0px,
              transparent 15px,
              rgba(255, 255, 255, 0.05) 15px,
              rgba(255, 255, 255, 0.05) 16px,
              transparent 16px,
              transparent 75px
            ),
            repeating-linear-gradient(
              0deg,
              transparent 0px,
              transparent 15px,
              rgba(255, 255, 255, 0.05) 15px,
              rgba(255, 255, 255, 0.05) 16px,
              transparent 16px,
              transparent 75px
            ),
            linear-gradient(20deg, #011a46 20%, #476498 90%);
        `
      : ''}
`

const FacilitatorParticipantView = styled(AppBackground)<{
  $inPreviewMode?: boolean
  $inPastMode?: boolean
  $pageHeight: number
}>`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  max-width: calc(100vw - 234px);
  max-height: calc(${p => p.$pageHeight}px - ${p => (p.$inPastMode ? 59 : p.$inPreviewMode ? 164 : 114)}px);
  height: 100%;
  overflow: auto;

  > * {
    overflow: auto;
  }

  ${p =>
    p.theme === 'dark'
      ? css`
          background: repeating-linear-gradient(
              90deg,
              transparent 0px,
              transparent 15px,
              rgba(255, 255, 255, 0.05) 15px,
              rgba(255, 255, 255, 0.05) 16px,
              transparent 16px,
              transparent 75px
            ),
            repeating-linear-gradient(
              0deg,
              transparent 0px,
              transparent 15px,
              rgba(255, 255, 255, 0.05) 15px,
              rgba(255, 255, 255, 0.05) 16px,
              transparent 16px,
              transparent 75px
            ),
            linear-gradient(20deg, #011a46 20%, #476498 90%);
        `
      : ''}
`
