// import React from 'react'
import { Row, Column, ThemeProps, H3, getTheme } from 'common/ui'
import styled, { css } from 'styled-components'
import { transparentize } from 'utils/polishedUtils'

interface CellProps extends ThemeProps {
  accent?: Exclude<ThemeProps['theme'], undefined>
  active?: boolean
  selected?: boolean
  highlighted?: boolean
  interactive?: boolean
  error?: boolean
  disabled?: boolean
}

export const Cell = styled(Column)<CellProps>`
  box-sizing: border-box;
  min-height: 70px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #cdd2e4;
  box-shadow: 0 1px #fff;
  cursor: ${p => (p.interactive && !p.disabled ? 'pointer' : 'auto')};
  ${p =>
    p.accent
      ? css`
          border-left: 5px solid ${getTheme({ theme: p.accent }).buttonBorderTopColor};
          ${H3} {
            color: ${getTheme({ theme: p.accent }).buttonBorderTopColor};
            margin-bottom: 5px;
          }
        `
      : ''}
  ${p =>
    p.active
      ? css`
          background-color: #ffe3e3 !important;
          ${H3} {
            color: #ed1c24;
          }
        `
      : p.highlighted
      ? css`
          background-color: #d0e7f3 !important;
          border-color: ${getTheme({ theme: p.accent || 'purple' }).buttonBorderTopColor};
        `
      : ''}
  ${p =>
    p.selected
      ? css`
          border-width: 3px;
          border-color: ${getTheme({ theme: p.accent || 'purple' }).buttonBorderTopColor};
        `
      : ''}
  ${p =>
    p.error
      ? css`
          border-color: #ed1c24;
        `
      : ''}
  ${p =>
    p.disabled
      ? css`
          pointer-events: none;
          opacity: 0.6;
        `
      : ''}
`

export const InteractiveCell = styled(Cell)<CellProps & { clickable?: boolean }>`
  pointer-events: auto;
  user-select: none;
  ${p =>
    !p.disabled && p.clickable
      ? css`
          cursor: pointer;
          &:hover {
            background-color: ${p => transparentize(50, p.theme.thinOutlineColor)};
          }
        `
      : ''}
`

export const CellInner = styled(Row)<{ flex?: string }>`
  flex: ${p => p.flex || '1 1 auto'};
  margin: 10px 12px;
  align-items: center;
`

export const Cells = styled.div`
  & > ${Cell}, & > .cell {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const ColumnCells = styled(Row)`
  flex-wrap: wrap;
  width: 100%;
  & > ${Cell} {
    flex: 1 1 50%;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const CompactCells = styled.div`
  & > ${Cell} {
    min-height: 50px;
    &:first-child:not(:only-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child:not(:only-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:not(:first-child):not(:last-child):not(:only-child) {
      border-radius: 0;
    }
    &:not(:first-child) {
      border-top: none;
    }
  }
`
