import { BaseAction } from '../types'
import { TipsheetState as State } from './types'

export const UPDATE_STATE = 'UPDATE_TIPSHEET_STATE'
export const UPDATE_TIPSHEET_PAGE = 'UPDATE_TIPSHEET_PAGE'

export interface UpdateStateAction extends BaseAction {
  type: typeof UPDATE_STATE
  state: State
}

export interface UpdateTipsheetPageAction extends BaseAction {
  type: typeof UPDATE_TIPSHEET_PAGE
  stepUid: string
}

export type ActionTypes = UpdateStateAction | UpdateTipsheetPageAction
