import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Skill } from './types'

import { Row, Spacer, Button, P, Column, Padding, H2, H3 } from 'common/ui'

import { MenuModal, MenuButton } from './Menu'
import { NavDividerColumn } from './common/NavDividerColumn'

import { getConfirmedModuleSkills, getUsedSymbols } from './reducers'
import { useSkillTrackerState } from './SkillTrackerState'
import { useUserState } from 'app/UserState'
import { baseUrl } from './common/Navigation'
import { SkillSlab } from './common/SkillSlab'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { SymbolSelectModal } from '../common/SymbolSelectModal'
import { skillEmojis } from './common/SkillInput'
import { AddUpdateSkillAction, ADD_UPDATE_SKILL } from './actionTypes'
import { validateAddUpdateSkill } from './actionValidators'
import { SkillTrackerPanel } from './common/SkillTrackerPanel'
import { SkillEditModal } from './modals/SkillEditModal'

export const SkillsRewardsScreen: React.FC<{}> = () => {
  const history = useHistory()
  const { state, dispatch } = useSkillTrackerState()
  const { getUserScopes, getBaseAction } = useUserState()

  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [editSkillId, setEditSkillId] = useState<string | null>(null)

  const navHeight = 50
  const currentModule = state.modules.find(({ id }) => id === state.activeModuleId)
  const skills = getConfirmedModuleSkills(state.activeModuleId, state)
  const editSkill = state.skills.find(({ id }) => id === editSkillId)
  const isAdult = getUserScopes().indexOf('mentor') >= 0

  const handleSymbolChange = (symbol: string, skill: Skill) => {
    const action: AddUpdateSkillAction = { ...getBaseAction(), type: ADD_UPDATE_SKILL, skill: { ...skill, symbol } }
    if (validateAddUpdateSkill(action, state)) dispatch(action)
    else console.error(`Couldn't validate workflow update action`)
  }

  const handleSkillSave = (skill: Skill) => {
    const action: AddUpdateSkillAction = { ...getBaseAction(), type: ADD_UPDATE_SKILL, skill }
    if (validateAddUpdateSkill(action, state)) dispatch(action)
    else console.error(`Unable to validate update skill action`, action, state)
  }

  return (
    <>
      <SkillTrackerPanel>
        <Row flex="none" alignItems="center" style={{ borderBottom: '1px solid #ABB4DB', height: navHeight }}>
          <Column
            flex="1 1 auto"
            style={{ backgroundColor: '#C4CAE6', height: navHeight, borderTopLeftRadius: 7 }}
            paddingLeft="s"
            justifyContent="center">
            <H2 style={{ lineHeight: 0.95 }}>{isAdult ? 'Edit Target Skills' : 'My Target Skills'}</H2>
          </Column>
          <NavDividerColumn height={navHeight} />
          <Column flex="none">
            <Button type="button" size="s" theme="blue" onClick={() => history.push(baseUrl + '/practise')}>
              Flip
            </Button>
          </Column>
          <Column flex="none" paddingRight={5}>
            <MenuButton onClick={() => setMenuOpen(true)}>Menu</MenuButton>
          </Column>
        </Row>
        <Row flex="1 1 auto" style={{ borderTop: '1px solid white', overflow: 'auto' }}>
          <Padding size="s">
            <Column style={{ width: '100%' }}>
              {!skills.length && !isAdult && (
                <P>Whoops! Your Target Skills are missing. Please talk to your SAS Mentor.</P>
              )}
              {!skills.length && isAdult && (
                <P>There are currently no target skills set up for the current skill tracker!</P>
              )}
              {skills.length > 0 && (
                <>
                  {currentModule && currentModule.checkInTime && (
                    <H3 marginBottom="s">Daily Tally Time: {currentModule.checkInTime}</H3>
                  )}
                  {intersperseSpacers(
                    skills.map((skill, i) => (
                      <SkillSlab
                        key={i}
                        skill={skill}
                        label={`Target skill ${i + 1}:`}
                        onEdit={() => setEditSkillId(skill.id)}
                      />
                    )),
                    's'
                  )}
                </>
              )}
              <Spacer size="m" />
              <div style={{ textAlign: 'center' }}>
                <Button type="button" size="s" onClick={() => history.push(baseUrl + '/rewards')}>
                  View Rewards
                </Button>
              </div>
            </Column>
          </Padding>
        </Row>
      </SkillTrackerPanel>
      {editSkill && !isAdult && (
        <SymbolSelectModal
          isOpen
          symbols={skillEmojis}
          disabledSymbols={currentModule ? getUsedSymbols(currentModule, state.skills) : []}
          value={editSkill.symbol}
          title={`Select a new symbol for this skill`}
          onChange={symbol => {
            handleSymbolChange(symbol, editSkill)
            setTimeout(() => setEditSkillId(null), 150)
          }}
          onClose={() => setEditSkillId(null)}
        />
      )}
      {editSkill && isAdult && (
        <SkillEditModal initialValue={editSkill} onClose={() => setEditSkillId(null)} onSave={handleSkillSave} />
      )}
      <MenuModal isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  )
}
