import React from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { Panel } from 'common/ui'
import { HeaderTemplate } from '../common/HeaderTemplate'

import { useGadgetPackState } from '../GadgetPackState'
import { EmotionometerStickerEditInner, RouteParams } from 'e-telligence/EmotionometerStickerEdit'

export const EmotionometerStickerEdit: React.FC<{ baseUrl: string; isAdding?: boolean }> = ({ baseUrl, isAdding }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useGadgetPackState()

  const { emotion } = useParams<RouteParams>() as RouteParams
  const emotionometer = state.emotionometers.find(({ type }) => type === emotion)
  // if (!emotionometer) return null

  return (
    <HeaderTemplate
      title={`Create ${emotionometer?.title || ''} Emotionometer`}
      onExit={() => history.push(baseUrl + '/emotionometer/' + emotion, location.state)}
      exitLabel="Back">
      <Panel>
        <EmotionometerStickerEditInner isAdding={isAdding} state={state} dispatch={dispatch} baseUrl={baseUrl} />
      </Panel>
    </HeaderTemplate>
  )
}
