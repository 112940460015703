import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { GroupEditInner } from './GroupAdd'
import { useUserState } from 'app/UserState'
import { useParams } from 'react-router-dom'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'

export const GroupEdit: React.FC<NavRouteProps> = ({ route }) => {
  const { groupUid } = useParams<{ groupUid: string }>()
  const { accessToken } = useUserState()
  return (
    <GroupEditInner
      editMode
      route={route}
      formProps={{
        initialDataUrl: `/api/v1/groups/${groupUid}`,
        initialDataRequestOptions: getAuthRequestParams(accessToken),
        submitUrl: `/api/v1/groups/${groupUid}`,
        submitRequestOptions: { ...getAuthRequestParams(accessToken), method: 'PUT' },
      }}
    />
  )
}
