import { GameModeSlug } from 'shared/types'

// only require device auth for specific intervention types
export const gameModesRequiringDeviceAuth: GameModeSlug[] = ['SAS-SG', 'SAS-SG-D']

export function getDeviceToken(hashedGameCode: string) {
  return localStorage.getItem(`${hashedGameCode}_deviceToken`)
}

export function setDeviceToken(hashedGameCode: string, token: string) {
  localStorage.setItem(`${hashedGameCode}_deviceToken`, token)
}

export function isDeviceAuthInProgress(): boolean {
  return Boolean(sessionStorage.getItem('deviceAuthInProgress'))
}

export function setDeviceAuthInProgress(value: boolean) {
  if (value) {
    sessionStorage.setItem('deviceAuthInProgress', '1')
  } else {
    sessionStorage.removeItem('deviceAuthInProgress')
  }
}

export function getDeviceAuthToken() {
  return sessionStorage.getItem('deviceAuthToken')
}

export function setDeviceAuthToken(token: string) {
  if (token) {
    sessionStorage.setItem('deviceAuthToken', token)
  } else {
    sessionStorage.removeItem('deviceAuthToken')
  }
}

export function getHashedGameCode() {
  return sessionStorage.getItem('hashedGameCode')
}

export function setHashedGameCode(hashedGameCode: string) {
  sessionStorage.setItem('hashedGameCode', hashedGameCode)
}
