import React from 'react'
import {
  HeadingDividerSection as HeadingDividerSectionType,
  SectionProperty,
  SectionPropsBase,
} from 'shared/tipsheets/types'
import {
  SectionPanel,
  DividerSection,
  ListSection,
  MediaGridSection,
  TextSection,
  InputSection,
  BlockSection,
} from 'shared/session/types'
import { GenericDividerSection } from 'session/sections/Divider'
import { GenericListSection, ListContainer } from 'session/sections/List'
import { GenericGridSection } from 'session/sections/MediaGrid'
import { GenericTextSection } from 'session/sections/Text'
import { GenericInputSection } from 'session/sections/Input'
import { GenericBlockSection } from 'session/sections/Block'
import { GenericDownloadResourceSection } from 'session/sections/DownloadResourceSection'
import flattenDeep from 'lodash/flattenDeep'
import styled, { ThemeProvider } from 'styled-components'
import { fontBold } from 'fonts'
import { Container } from 'common/ui'
import { blue, parentGreen, orange, purple, Theme } from 'themes'
import { HeadingDividerSection } from './HeadingDivider'
import { TipsheetEmbeddedActivitySection } from './TipsheetEmbeddedActivitySection'
import { RichTextContainer } from 'session/common/RichText'
import { getListArrow } from 'tipsheets/TipsheetStep'

interface Props extends SectionPropsBase {
  property: 'bordered_sections'
}

type SectionTypes =
  | DividerSection
  | ListSection
  | MediaGridSection
  | TextSection
  | InputSection
  | BlockSection
  | HeadingDividerSectionType

const sectionComponents: {
  [key in Exclude<SectionProperty, 'bordered_sections'>]: React.FC<
    SectionPropsBase & { property: key; panel: SectionPanel }
  >
} = {
  divider_sections: GenericDividerSection,
  list_sections: GenericListSection,
  media_grid_sections: GenericGridSection,
  text_sections: GenericTextSection,
  input_sections: GenericInputSection,
  block_sections: GenericBlockSection,
  heading_divider_sections: HeadingDividerSection,
  tipsheet_embedded_activity_sections: TipsheetEmbeddedActivitySection,
  download_resource_sections: GenericDownloadResourceSection,
}

const sections: Exclude<SectionProperty, 'bordered_sections'>[] = [
  'divider_sections',
  'list_sections',
  'media_grid_sections',
  'text_sections',
  'input_sections',
  'block_sections',
  'heading_divider_sections',
]

const Themes: {
  [key in Props['section']['theme']]: Theme
} = {
  purple: purple,
  blue: blue,
  green: parentGreen,
  orange: orange,
}

export const BorderedSection: React.FC<Props> = ({ section }) => (
  <ThemeProvider theme={Themes[section.theme] || orange}>
    <Wrapper>
      <div style={{ background: '#fff' }}>
        {section.title && <Title>{section.title}</Title>}
        {flattenDeep(
          sections.map((sectionProperty, i) => {
            const childrenSections: SectionTypes[] = section[sectionProperty] || []
            return childrenSections.map((sectionDetails, idx) => {
              const Component = sectionComponents[sectionProperty]
              // @ts-ignore
              return <Component key={`${sectionProperty}${idx}`} section={sectionDetails} />
            })
          })
        )
          .sort((a, b) => {
            return a.props.section.order - b.props.section.order
          })
          .map((component, idx) => {
            return <Container marginBottom={30} key={idx} children={component} />
          })}
      </div>
    </Wrapper>
  </ThemeProvider>
)

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  border: ${p => `2px solid ${p.theme.buttonBorderTopColor}`};
  border-radius: 10px;
  padding: 30px;
  background: #fff;

  > div {
    > * {
      margin-top: 0;
    }
  }

  ${ListContainer}, ${RichTextContainer} {
    li {
      &::before {
        top: 4px;
        height: 1.15em;
        background: ${p => getListArrow(p.theme.name)} !important;
      }
    }
  }
`

const Title = styled.p`
  position: relative;
  ${fontBold};
  color: ${p => p.theme.buttonBorderTopColor};
  font-size: 1.3rem;
  line-height: 130%;
  letter-spacing: 0.03em;
`
