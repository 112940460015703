import { useRef } from 'react'
import { useUserState } from './UserState'
import { useInterval } from 'utils/useInterval'
import { useLocation, useHistory } from 'react-router'
import { sendHeartbeat } from 'api'
import { decodeJWT } from 'utils/decodeJWT'
import { getNextPromptCount } from 'utils/routeUtils'

const HEARTBEAT_INTERVAL = 60000
const TAB_LOAD_TIME = Date.now()
const TAB_IDENTIFIER = Math.random()
  .toString(36)
  .substring(2, 8)

export function useHeartbeat() {
  const { pathname } = useLocation()
  const history = useHistory()
  const {
    authProvider,
    accessToken,
    setAccessToken,
    setRefreshToken,
    offlineMode,
    offlineMentor,
    profileId,
  } = useUserState()
  const isErrorPage = pathname.indexOf('/error') === 0
  const payload = decodeJWT(accessToken)
  const isNotAgent = payload.scopes.indexOf('agent') === -1
  const failCountRef = useRef(0)
  const disableHeartbeat =
    !accessToken || isErrorPage || isNotAgent || offlineMode || offlineMentor || authProvider !== 'sas'

  useInterval(
    () => {
      const trackingData = {
        profile: profileId,
        tab: TAB_IDENTIFIER,
        failure: failCountRef.current,
        url: window.location.pathname,
        time: Math.round((Date.now() - TAB_LOAD_TIME) / 1000),
      }
      sendHeartbeat(accessToken, trackingData)
        .then(throwIfSuccessFalse)
        .then(() => {
          // reset fail count if there is success
          failCountRef.current = 0
        })
        .catch((error: any) => {
          if (!(error instanceof ApiError) && ++failCountRef.current < 2) {
            console.log('Ignoring potentially transient error')
            return
          }
          // reset fail count if we log out
          failCountRef.current = 0
          setAccessToken('')
          setRefreshToken('')
          if (error instanceof ApiError) {
            history.push(`/error/${error.name.toLowerCase()}`, { ignoreRouterPrompt: getNextPromptCount() })
          } else {
            history.push('/error/heartbeat-failed', { ignoreRouterPrompt: getNextPromptCount() })
          }
        })
    },
    disableHeartbeat ? null : HEARTBEAT_INTERVAL
  )
}

// TODO move this somewhere else

interface ApiResponse {
  success: 0 | 1
  errors: string[]
  message: string
}

function throwIfSuccessFalse(json: ApiResponse) {
  if (!json.success) throw new ApiError(json.errors[0], json.message)
}

class ApiError extends Error {
  constructor(name: string, message: string) {
    super(message)
    this.name = name
  }
}
