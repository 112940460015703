import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Row, Column, Padding } from 'common/ui'
import { ETellPanel } from './common/ETellPanel'
import { Navigation, baseUrl } from './common/Navigation'
import { GadgetTile } from './common/GadgetTile'

import { presetGadgets } from './constants/gadgets'
import { useETelligenceState } from './ETelligenceState'
import { useMeasure } from 'utils/useMeasure'
import { useUserState } from 'app/UserState'
import { RouteProps } from 'shared/e-telligence/types'
import { useProgressionEventExists } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'

export const RelaxationGadgetsIndex: React.FC = () => {
  const history = useHistory()
  const { state, dispatch } = useETelligenceState()
  const { usingIOSvhFix } = useUserState()
  return (
    <ETellPanel style={{ height: usingIOSvhFix ? '100%' : 'calc(100vh - 20px)' }}>
      <Navigation title="Relaxation Gadgets" buttonLabel="Back" buttonPress={() => history.push(baseUrl + '/home')} />
      <RelaxationGadgetsIndexInner baseUrl={baseUrl} state={state} dispatch={dispatch} />
    </ETellPanel>
  )
}

export const RelaxationGadgetsIndexInner: React.FC<RouteProps & {
  overrideRoute?: (id: string | undefined) => void
  hideCustom?: boolean
}> = ({ state, baseUrl, overrideRoute, hideCustom }) => {
  const history = useHistory()
  const location = useLocation()
  const [containerRef, containerRect] = useMeasure()
  const allGadgets = [...state.customGadgets, ...presetGadgets]
  const cardColumns = Math.floor((containerRect.width || 1000) / 106)

  const { gameMode } = useUserState()

  const activityUnlocked = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'relaxation-gadgets',
    subcategory: null,
    event_key: null,
    event_value: null,
  })
  const fireEngineUnlocked = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'relaxation-gadgets',
    subcategory: 'fire-engine',
    event_key: null,
    event_value: null,
  })
  const regulatorUnlocked = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'relaxation-gadgets',
    subcategory: 'o2-regulator',
    event_key: null,
    event_value: null,
  })
  const bodyScanUnlocked = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'relaxation-gadgets',
    subcategory: 'body-scan',
    event_key: null,
    event_value: null,
  })

  let restrictedCardsUnlocked: { [key: string]: boolean } = {
    'fire-engine': fireEngineUnlocked,
    'o2-regulator': regulatorUnlocked,
    'body-scan': bodyScanUnlocked,
  }

  const allGadgetsFiltered = activityUnlocked
    ? allGadgets
    : allGadgets.filter(gadget => {
        if (gameMode !== 'SAS-SG-D') return true
        if (Object.keys(restrictedCardsUnlocked).indexOf(gadget.id) !== -1) {
          return restrictedCardsUnlocked[gadget.id]
        }
        return false
      })

  return (
    <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
      <Padding size="s">
        <Row ref={containerRef} flexWrap marginRight={-10}>
          {(hideCustom ? allGadgetsFiltered : [undefined, ...allGadgetsFiltered]).map((gadget, i) => (
            <Column key={i} flex="1 1 96px" paddingBottom="s" paddingRight={10}>
              <GadgetTile
                placeholder={gadget ? undefined : 'Create Relaxation Gadget +'}
                gadget={gadget}
                active={gadget && state.gadgetIds.indexOf(gadget.id) >= 0}
                onEdit={() =>
                  overrideRoute
                    ? overrideRoute(gadget ? gadget.id : undefined)
                    : history.push(
                        baseUrl + '/relaxation-gadgets/' + (gadget ? 'add/' + gadget.id : 'new'),
                        location.state
                      )
                }
              />
            </Column>
          ))}
          {[...Array(Math.max(0, cardColumns - (allGadgetsFiltered.length % cardColumns)))].map((_, i) => (
            <Column key={`s${i}`} flex="1 1 96px" paddingRight={10} />
          ))}
        </Row>
      </Padding>
    </Column>
  )
}
