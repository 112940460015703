import React, { CSSProperties } from 'react'
import { MediaAsset, MediaType, MediaRatio } from 'shared/session/types'
import { getImageUrl, getAssetUrl } from 'session/utils/assetUtils'

interface Props {
  type: MediaType
  image?: MediaAsset
  video?: MediaAsset
  icon?: MediaAsset
  ratio?: MediaRatio | 'auto'
  widthPreset: string
  alt?: string
  loop?: boolean
  display?: 'inline' | 'block'
  controls?: boolean
  style?: CSSProperties
}

export const Media: React.FC<Props> = ({
  type,
  image,
  video,
  icon,
  ratio = '4:3',
  widthPreset,
  alt = '',
  loop,
  controls = false,
  display,
  style = {},
}) => {
  // if (type === 'image' && image && display === 'inline') {
  //   return (
  //     <div
  //       className="inline-image"
  //       style={{
  //         backgroundImage: `url(${getImageUrl(image, { preset: `${widthPreset}-${ratio.replace(':', '-')}` })})`,
  //       }}
  //     />
  //   )
  // }

  if ((type === 'image' || type === null) && image)
    return (
      <img
        style={{ ...style }}
        src={getImageUrl(image, { preset: `${widthPreset}-${ratio.replace(':', '-')}` })}
        alt={alt}
      />
    )

  // TODO
  if (type === 'icon' && icon)
    return <img src={getImageUrl(icon, { preset: `${widthPreset}-${ratio.replace(':', '-')}` })} alt={alt} />

  if (type === 'video' && video)
    return (
      <video
        controls={controls}
        autoPlay={!controls}
        loop={loop !== undefined ? loop : true}
        muted={controls ? false : true}
        playsInline>
        <source src={getAssetUrl(video)} type={`video/${video.extension ? video.extension.toLowerCase() : 'mp4'}`} />
      </video>
    )

  return null
}
