import React, { CSSProperties } from 'react'
import { XYCoord, useDragLayer } from 'react-dnd'
import { PlayerSlot, DRAG_TYPE_PLAYER_SLOT } from './PlayerSlot'
import { DRAG_TYPE_PLAYER_PIECE, CharacterAvatar } from './CharacterAvatar'

const layerStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  cursor: 'move',
}

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties {
  if (!initialOffset || !currentOffset) return { display: 'none' }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

export const CustomDragLayer: React.FC = (props) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  function renderItem() {
    switch (itemType) {
      case DRAG_TYPE_PLAYER_SLOT:
        return (
          <PlayerSlot
            {...item.props}
            onEdit={undefined}
            style={{ ...(item.props.style || {}), width: item.rect?.width, height: item.rect?.height }}
          />
        )
      case DRAG_TYPE_PLAYER_PIECE:
        return <CharacterAvatar {...item.props} size="2.75em" highlighted={false} />
      default:
        return null
    }
  }

  if (!isDragging) return null

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  )
}
