import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Row, Column, Padding } from 'common/ui'
import { Navigation, baseUrl } from './common/Navigation'
import { CodeCardTile } from './common/CodeCardTile'

import { useETelligenceState } from './ETelligenceState'
import { codeCards } from './constants/codeCards'
import { ETellPanel } from './common/ETellPanel'
import { useUserState } from 'app/UserState'
import { Activity } from 'types'
import { CodeCard, CodeCardId, RouteProps } from 'shared/e-telligence/types'
import { useProgressionEventsState } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK, objShapeMatches } from 'shared/progressionEvents'
import { codeCardMapping } from 'session/gadget-tray/CodeCardsModal'

interface Props {
  activities: Activity[]
}

export const CodeCardsIndex: React.FC<Props> = ({ activities }) => {
  const history = useHistory()
  const { state, dispatch } = useETelligenceState()
  const { usingIOSvhFix } = useUserState()
  return (
    <ETellPanel style={{ height: usingIOSvhFix ? '100%' : 'calc(100vh - 20px)' }}>
      <Navigation
        title="Skill Code Cards"
        hint="Step-by-step guides for talking, hanging out and working with others. They can also help you to solve social problems like making mistakes and bullying."
        buttonLabel="Back"
        buttonPress={() => history.push(baseUrl + '/home')}
      />
      <CodeCardsIndexInner baseUrl={baseUrl} state={state} dispatch={dispatch} activities={activities} />
    </ETellPanel>
  )
}

export const CodeCardsIndexInner: React.FC<RouteProps & Props> = ({ state, activities, baseUrl }) => {
  const history = useHistory()
  const location = useLocation()
  const { progressionEvents } = useProgressionEventsState()
  const { hasPermissionFor, gameMode } = useUserState()

  function isLocked(codeCard: CodeCard): boolean {
    const activity = activities.find(({ id }) => codeCard.prerequisite?.id === id)
    return activity ? !hasPermissionFor(activity) : false
  }

  const checkUnlockEvents = (cardId: CodeCardId) => {
    let returnVal = false
    for (let testProgressionEvent of progressionEvents) {
      if (
        objShapeMatches(
          {
            type: GADGET_PACK_UNLOCK,
            category: 'code-cards',
            subcategory: codeCardMapping[cardId],
          },
          testProgressionEvent
        )
      ) {
        returnVal = true
      }
    }
    return returnVal
  }

  return (
    <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
      <Padding size="s">
        <Row flexWrap marginRight={-10}>
          {[undefined, ...state.customCodeCards, ...codeCards].map((codeCard, i) => {
            const unlocked = !codeCard ? true : codeCard.isPreset ? checkUnlockEvents(codeCard.id as CodeCardId) : true

            return (
              <Column key={i} flex="1 1 99px" paddingBottom="s" paddingRight={5}>
                <CodeCardTile
                  locked={codeCard ? (gameMode === 'SAS-SG-D' ? !unlocked : isLocked(codeCard)) : false}
                  placeholder={codeCard ? undefined : 'Create Skill Code Card +'}
                  codeCard={codeCard}
                  active={codeCard && state.codeCardIds.indexOf(codeCard.id) >= 0}
                  onEdit={() =>
                    history.push(baseUrl + '/code-cards/' + (codeCard ? 'add/' + codeCard.id : 'new'), location.state)
                  }
                />
              </Column>
            )
          })}
          {[...Array(20)].map((_, i) => (
            <Column key={`s${i}`} flex="1 1 96px" paddingRight={10} />
          ))}
        </Row>
      </Padding>
    </Column>
  )
}
