import React, { CSSProperties } from 'react'
import styled, { keyframes } from 'styled-components'
import { transparentize } from 'utils/polishedUtils'

interface Props {
  color: string
  size: number
  thickness: number
  style?: CSSProperties
}

export const Spinner: React.FC<Partial<Props>> = ({ color = '#ffffff', size = 24, thickness = 0.11, style = {} }) => (
  <div
    style={{
      position: 'relative',
      display: 'inline-block',
      width: size,
      height: size,
      boxSizing: 'border-box',
      ...style,
    }}>
    <SpinnerDiv style={{ fontSize: size / 10 }} color={color} thickness={thickness} />
  </div>
)

export const SpinnerWithLabel: React.FC<Partial<Props> & {
    label: string
    labelStyle?: CSSProperties
    containerStyle?: CSSProperties
}> = ({ label, labelStyle, containerStyle, ...props }) => {
  return (
    <SpinnerWithLabelDiv style={containerStyle}>
      <Spinner {...props} />
      <SpinnerLabel style={labelStyle}>{label}</SpinnerLabel>
    </SpinnerWithLabelDiv>
  )
}

const SpinnerWithLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 0;
`

const SpinnerLabel = styled.label`
  margin-left: 10px;
`

export const rotateClockwise = keyframes`
  0% { transform: rotate(0deg); }
  100% {transform: rotate(360deg); }
`

const SpinnerDiv = styled.div<Pick<Props, 'color' | 'thickness'>>`
  position: relative;
  width: 10em;
  height: 10em;
  box-sizing: border-box;
  border-radius: 50%;
  font-size: 10px;
  text-indent: -9999em;
  border-top: ${p => p.thickness * 10}em solid ${p => transparentize(0.8, p.color)};
  border-right: ${p => p.thickness * 10}em solid ${p => transparentize(0.8, p.color)};
  border-bottom: ${p => p.thickness * 10}em solid ${p => transparentize(0.8, p.color)};
  border-left: ${p => p.thickness * 10}em solid ${p => p.color};
  transform: translateZ(0);
  animation: ${rotateClockwise} 1.1s infinite linear;
  &::after {
    width: 10em;
    height: 10em;
    border-radius: 50%;
  }
`

export const SpinnerIndeterminate: React.FC<Partial<Omit<Props, 'thickness'>>> = ({ size = 24, color = '#ffffff' }) => {
  return (
    <div style={{ position: 'relative', width: 24, height: 24, boxSizing: 'border-box' }}>
      <SpinnerIndeterminateDiv style={{ fontSize: size / 10 }} color={color} />
    </div>
  )
}

const pulseSizeAnimation = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`

const SpinnerIndeterminateDiv = styled.div<Pick<Props, 'color'>>`
  position: relative;
  margin-left: 3.5em;
  margin-top: 1.25em;
  color: ${p => p.color};
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  &,
  &::before,
  &::after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: ${pulseSizeAnimation} 1.8s infinite ease-in-out;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
  }
  &::before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  &::after {
    left: 3.5em;
    animation-delay: 0.24s;
  }
`
