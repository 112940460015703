import React from 'react'
import { useLocation } from 'react-router-dom'
import { NotFoundPage } from 'home/NotFoundPage'
import { IntroTemplate } from 'home/IntroTemplate'
import { AnchorButton } from 'common/LinkButton'
import { P } from 'common/ui'

export type DomainEnv = 'cadet' | 'mentor'

export const getCurrentDomainEnv = (): DomainEnv | null => {
  switch (window.location.origin) {
    case process.env.REACT_APP_CADET_URL: return 'cadet' // prettier-ignore
    case process.env.REACT_APP_MENTOR_URL: return 'mentor' // prettier-ignore
    case process.env.REACT_APP_MENTOR_URL.replace('://www.', '://'): return 'mentor' // prettier-ignore
    default: return null // prettier-ignore
  }
}

export const getDomainFromEnv = (env: DomainEnv): string => {
  switch (env) {
    case 'cadet': return process.env.REACT_APP_CADET_URL // prettier-ignore
    case 'mentor': return process.env.REACT_APP_MENTOR_URL // prettier-ignore
  }
}

interface Props {
  env: DomainEnv
}

export const RequireDomain: React.FC<Props> = ({ env, children }) => {
  const location = useLocation()
  if (process.env.REACT_APP_REQUIRE_DOMAIN_CHECK === '1') {
    const currentEnv = getCurrentDomainEnv()
    if (!currentEnv) return <NotFoundPage />
    if (currentEnv !== env) {
      const redirectUrl = `${getDomainFromEnv(env)}${location.pathname}${location.search}`
      window.location.href = redirectUrl
      return (
        <IntroTemplate title="Redirecting...">
          <P>If you are not automatically redirected click the button below:</P>
          <AnchorButton href={redirectUrl} />
        </IntroTemplate>
      )
    }
  }
  return <>{children}</>
}
