import React from 'react'
import { CodeCard } from 'shared/e-telligence/types'
import {
  FearFighter,
  TheUltimateChillOutChallenge,
  DECODERProblemDemolition,
  CooperationChallenge,
  CooperationChallenge2,
  MistakeMania,
  BullyGuardBodyArmour as BullyGuardBodyArmourActivity,
} from 'activities'
import styled from 'styled-components'
import { fontBold } from 'fonts'

const StyledDescription = styled.span<{ color: string }>`
  ${fontBold};
  color: ${p => p.color};
  font-style: italic;
  font-size: 2rem;
  font-weight: 900;
`

const ConfusionCode: CodeCard = {
  id: 'confusion',
  prerequisite: FearFighter,
  isPreset: true,
  title: 'The Confusion Code',
  color: '#FFF151',
  cover: require('../assets/code-cards/ConfusionCover.png'),
  steps: [
    {
      label: 'Step 1',
      description: 'If you’re not sure what to do, use your relaxation gadgets to calm down.',
      imageUrl: require('../assets/code-cards/Confusion1.png'),
    },
    {
      label: 'Step 2',
      description:
        'Try to figure out what you need to do. Re-reading the instructions or watching someone else can help.',
      imageUrl: require('../assets/code-cards/Confusion2.png'),
    },
    {
      label: 'Step 3',
      description: 'Politely ask someone you trust for help.',
      imageUrl: require('../assets/code-cards/Confusion3.png'),
    },
    {
      label: 'Step 4',
      description: 'Try the activity and ask for more help if you need it.',
      imageUrl: require('../assets/code-cards/Confusion4.png'),
    },
  ],
}

const ConversationCode: CodeCard = {
  id: 'conversation',
  isPreset: true,
  title: 'The Conversation Code',
  prerequisite: TheUltimateChillOutChallenge,
  cover: require('../assets/code-cards/ConversationCover.png'),
  color: '#2B90DC',
  steps: [
    {
      label: 'Step 1',
      description: 'Check that it is a good time and place to talk.',
      imageUrl: require('../assets/code-cards/Conversation1.png'),
    },
    {
      label: 'Step 2',
      description: 'Quietly hang out, watch and listen. Use your relaxation gadgets to stay calm.',
      imageUrl: require('../assets/code-cards/Conversation2.png'),
    },
    {
      label: 'Step 3',
      description:
        'Walk up, smile and stand or sit about an arm’s length away. In a calm voice, say something friendly or ask a question.',
      imageUrl: require('../assets/code-cards/Conversation3.png'),
    },
    {
      label: 'Step 4',
      description: 'Listen to what the person or group says without interrupting.',
      imageUrl: require('../assets/code-cards/Conversation4.png'),
    },
    {
      label: 'Step 5',
      description: 'Take turns listening and speaking, and asking and answering questions.',
      imageUrl: require('../assets/code-cards/Conversation5.png'),
    },
    // WARNING symbol: Be on talk overload alert!
    {
      label: 'Step 6',
      description: 'Look, listen and learn.',
      imageUrl: require('../assets/code-cards/Conversation6.png'),
    },
    {
      label: 'Step 7',
      description: 'Watch out for clues that show how other people feel and respond in a helpful way.',
      imageUrl: require('../assets/code-cards/Conversation7.png'),
    },
    {
      label: 'Step 8',
      description:
        'When you want to stop talking, wait for a brief break. Tell them you have to go, and say "Bye". Calmly walk away.',
      imageUrl: require('../assets/code-cards/Conversation8.png'),
    },
    {
      label: 'Step 9',
      description: 'If the person seemed to like talking to you, try to talk to them again soon.',
      imageUrl: require('../assets/code-cards/Conversation9.png'),
    },
  ],
}

const Decoder: CodeCard = {
  id: 'decoder',
  isPreset: true,
  title: 'The D.E.C.O.D.E.R Formula',
  prerequisite: DECODERProblemDemolition,
  color: '#DF5B40',
  cover: require('../assets/code-cards/DecoderCover.png'),
  steps: [
    {
      label: 'D',
      description: (
        <>
          <strong>Detect</strong> the problem and <strong>Define</strong> it.
        </>
      ),
      imageUrl: require('../assets/code-cards/Decoder1.png'),
    },
    {
      label: 'E',
      description: (
        <>
          <strong>Explore</strong> possible solutions to the problem.
        </>
      ),
      imageUrl: require('../assets/code-cards/Decoder2.png'),
    },
    {
      label: 'C',
      description: (
        <>
          <strong>Consider</strong> the <strong>Consequences</strong> and <strong>Choose</strong> a solution.
        </>
      ),
      imageUrl: require('../assets/code-cards/Decoder3.png'),
    },
    {
      label: 'O',
      description: (
        <>
          <strong>Organise</strong> a plan.
        </>
      ),
      imageUrl: require('../assets/code-cards/Decoder4.png'),
    },
    {
      label: 'D',
      description: (
        <>
          <strong>Do</strong> it!
        </>
      ),
      imageUrl: require('../assets/code-cards/Decoder5.png'),
    },
    {
      label: 'E',
      description: (
        <>
          <strong>Evaluate</strong> how it went.
        </>
      ),
      imageUrl: require('../assets/code-cards/Decoder6.png'),
    },
    {
      label: 'R',
      description: (
        <>
          <strong>Reward</strong> yourself for trying your best!
        </>
      ),
      imageUrl: require('../assets/code-cards/Decoder7.png'),
    },
  ],
}

const Jokes: CodeCard = {
  id: 'jokes',
  isPreset: true,
  title: 'The difference between accidents, jokes & nasty deeds',
  prerequisite: CooperationChallenge2,
  color: '#009981',
  cover: require('../assets/code-cards/JokesCover.png'),
  steps: [
    {
      label: 'Clues that someone/a group has played a friendly joke on you, or upset you accidentally:',
      description: '',
      imageUrl: '',
    },
    {
      label: '',
      description: 'They’re nice to you most of the time.',
      imageUrl: require('../assets/code-cards/Jokes1.png'),
    },
    {
      label: '',
      description: 'They only say or do a couple of joking things.',
      imageUrl: require('../assets/code-cards/Jokes2.png'),
    },
    {
      label: '',
      description: 'They quickly say sorry when you tell them to stop.',
      imageUrl: require('../assets/code-cards/Jokes3.png'),
    },
    {
      label: '',
      description: 'They offer to help or make up for it when they realise that you are upset (if possible).',
      imageUrl: require('../assets/code-cards/Jokes4.png'),
    },
    {
      label: 'Clues that they are being mean on purpose:',
      description: '',
      imageUrl: '',
    },
    {
      label: '',
      description:
        'They speak in an angry, sarcastic or loud tone of voice. They might sigh, groan, call you mean names or make noises at you.',
      imageUrl: require('../assets/code-cards/Jokes5.png'),
    },
    {
      label: '',
      description: 'They give you the ‘nasty’ look, whisper to each other, point at you and/or smile or laugh.',
      imageUrl: require('../assets/code-cards/Jokes6.png'),
    },
    {
      label: '',
      description: 'They copy/make fun of what you say or do.',
      imageUrl: require('../assets/code-cards/Jokes7.png'),
    },
    {
      label: '',
      description: 'When you tell them to stop, they smile, laugh or don’t say sorry.',
      imageUrl: require('../assets/code-cards/Jokes8.png'),
    },
    {
      label: '',
      description: 'They keep saying or doing things that upset you after you tell them to stop.',
      imageUrl: require('../assets/code-cards/Jokes9.png'),
    },
    {
      label: '',
      description: 'They’ve been mean to you before,or usually ignore you or leave you out.',
      imageUrl: require('../assets/code-cards/Jokes10.png'),
    },
    {
      label: '',
      description: (
        <StyledDescription color="#009981" style={{ fontStyle: 'initial', fontWeight: 600, fontSize: '1.5rem' }}>
          If you’re not sure whether someone has upset you on purpose, use your relaxation gadgets to calm down and ask
          someone you trust for help.
        </StyledDescription>
      ),
      imageUrl: require('../assets/code-cards/Jokes11.png'),
    },
  ],
}

const PlayCode: CodeCard = {
  id: 'play',
  isPreset: true,
  title: 'The Play Code',
  prerequisite: CooperationChallenge,
  color: '#FB00C3',
  cover: require('../assets/code-cards/PlayCover.png'),
  steps: [
    {
      label: 'Step 1',
      description: 'Check that it is a good time and place to go up to someone.',
      imageUrl: require('../assets/code-cards/Play1.png'),
    },
    {
      label: 'Step 2',
      description: (
        <>
          Watch what others are doing. Find a group to join that are playing an activity that you like and understand
          the rules of <strong>OR...</strong>
        </>
      ),
      imageUrl: require('../assets/code-cards/Play2a.png'),
    },
    {
      label: '',
      description: 'Find a person/people who aren’t really doing much.',
      imageUrl: require('../assets/code-cards/Play2b.png'),
    },
    {
      label: 'Step 3',
      description:
        'Wait for a break in what they are doing and try to get eye contact. If they look friendly, say something nice about the activity or how they are playing it.',
      imageUrl: require('../assets/code-cards/Play3.png'),
    },
    {
      label: 'Step 4',
      description: 'Ask if you can join in the game or if they would like to play with you.',
      imageUrl: require('../assets/code-cards/Play4.png'),
    },
    {
      label: '',
      description: <StyledDescription color="#7EBC76">If they say yes</StyledDescription>,
      imageUrl: '',
    },
    {
      label: 'A',
      description: 'Try to take turns and share.',
      imageUrl: require('../assets/code-cards/PlayYesA.png'),
    },
    {
      label: 'B',
      description: 'Try not to be on Rule Patrol!',
      imageUrl: require('../assets/code-cards/PlayYesB.png'),
    },
    {
      label: 'C',
      description: 'Watch out for clues showing how you and others feel and respond in a helpful way.',
      imageUrl: require('../assets/code-cards/PlayYesC.png'),
    },
    {
      label: 'D',
      description: 'When the game ends or you stop playing, say bye.',
      imageUrl: require('../assets/code-cards/PlayYesD.png'),
    },
    {
      label: 'E',
      description:
        'Try to talk and play with them again a few more times to see if you like hanging out with each other.',
      imageUrl: require('../assets/code-cards/PlayYesE.png'),
    },
    {
      label: '',
      description: <StyledDescription color="#FF8A89">If they say no</StyledDescription>,
      imageUrl: '',
    },
    {
      label: 'A',
      description: 'Use your relaxation gadgets to stay calm.',
      imageUrl: require('../assets/code-cards/PlayNoA.png'),
    },
    {
      label: 'B',
      description: 'With a friendly face and voice, say “Oh, OK. Have fun!” (or something similar). Calmly walk away.',
      imageUrl: require('../assets/code-cards/PlayNoB.png'),
    },
    {
      label: 'C',
      description: 'Ask someone else if you can join in or if they’d like to play with you...',
      imageUrl: require('../assets/code-cards/PlayNoC1.png'),
    },
    {
      label: '',
      description: (
        <>
          <span style={{ marginRight: 10 }}>OR</span> Do a fun activity on your own.
        </>
      ),
      imageUrl: require('../assets/code-cards/PlayNoC2.png'),
    },
    {
      label: 'D',
      description:
        'Ask if you can play with them again at another time. If they say no, find other people to hang out with.',
      imageUrl: require('../assets/code-cards/PlayNoD.png'),
    },
  ],
}

const DamageControlCode: CodeCard = {
  id: 'damage-control',
  isPreset: true,
  title: 'The Damage Control Code',
  prerequisite: MistakeMania,
  color: '#4DBE3F',
  cover: require('../assets/code-cards/DamageCover.png'),
  steps: [
    {
      label: 'Step 1',
      description: 'After things haven’t gone to plan, use your relaxation gadgets to calm down.',
      imageUrl: require('../assets/code-cards/Damage1.png'),
    },
    {
      label: 'Step 2',
      description: 'Decide on the best thing to do now (A, B or C):',
      imageUrl: '',
    },
    {
      label: 'A',
      description: 'Try to fix what went wrong.',
      imageUrl: require('../assets/code-cards/Damage2a.png'),
    },
    {
      label: 'B',
      description: 'Politely ask someone you trust for help.',
      imageUrl: require('../assets/code-cards/Damage2b.png'),
    },
    {
      label: 'C',
      description: 'Say sorry if your words or actions upset someone else.',
      imageUrl: require('../assets/code-cards/Damage2c.png'),
    },
    {
      label: 'Step 3',
      description: 'Try to learn from what happened so that you can do better next time.',
      imageUrl: require('../assets/code-cards/Damage3.png'),
    },
  ],
}

const BullyGuardBodyArmour: CodeCard = {
  id: 'bully-guard-body-armour',
  isPreset: true,
  title: 'Bully-Guard Body Armour',
  prerequisite: BullyGuardBodyArmourActivity,
  color: '#7D41DF',
  labelColor: '#A87EE7',
  cover: require('../assets/code-cards/BullyCover.png'),
  steps: [
    {
      label: '',
      description: '',
      imageUrl: require('../assets/code-cards/BullyPage1.png'),
      layout: 'full',
    },
    {
      label: 'Enemy Obstruction Barrier',
      description: 'Try to ignore the bullies and focus on something else (real or imaginary).',
      imageUrl: require('../assets/code-cards/Bully1.png'),
    },
    {
      label: 'Brave Face Mask and Body Suit',
      description: 'Try to look calm and relaxed.',
      imageUrl: require('../assets/code-cards/Bully2.png'),
    },
    {
      label: 'Clever Comeback Creator',
      description: 'Calmly and confidently say a comeback that signals you don’t care what they do or say.',
      imageUrl: require('../assets/code-cards/Bully3.png'),
    },
    {
      label: 'Cruel Comments Deflector Shield',
      description: 'Imagine you have a shield that protects you from name calling and teasing.',
      imageUrl: require('../assets/code-cards/Bully4.png'),
    },
    {
      label: '',
      description: (
        <StyledDescription color="#FFA5A5" style={{ fontStyle: 'initial' }}>
          If someone bullies you online or physically, go straight to an adult for help!
        </StyledDescription>
      ),
      imageUrl: '',
    },
  ],
}

export const codeCards: CodeCard[] = [
  Decoder,
  ConversationCode,
  PlayCode,
  DamageControlCode,
  Jokes,
  BullyGuardBodyArmour,
  ConfusionCode,
]
