import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { Row, Button, Spacer } from 'common/ui'
import { Skill } from 'skill-tracker/types'
import { SkillInput } from 'skill-tracker/common/SkillInput'
import { ValidationErrorList } from 'common/ValidationErrorList'
import { SKILL_MIN_TARGET, SKILL_MAX_TARGET } from 'skill-tracker/constants/constants'
import { MobileMessageModal, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'

export const validate = (data: Partial<Skill>, errors: string[] = []) => {
  if (!data.text) errors.push(`Please enter a skill description`)
  if (!data.symbol) errors.push(`Please select a Symbol`)
  if (!data.dailyTarget) errors.push(`Please set Daily Target`)
  if (data.dailyTarget && (data.dailyTarget < SKILL_MIN_TARGET || data.dailyTarget > SKILL_MAX_TARGET))
    errors.push(`Daily target needs to be between ${SKILL_MIN_TARGET} and ${SKILL_MAX_TARGET}`)
  return errors
}

export const isEmpty = (skill: Skill): boolean => !skill.text && !skill.symbol && !skill.dailyTarget

interface Props {
  initialValue: Skill
  onClose: () => void
  onSave: (skill: Skill) => void
}

export const SkillEditModal: React.FC<Props> = ({ initialValue, onClose, onSave }) => {
  const [skill, setSkill] = useState<Skill>(initialValue)
  const [saveAttempted, setSaveAttempted] = useState<boolean>(false)
  const [edited, setEdited] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  const validationErrors = validate(skill)
  const allowSave = validationErrors.length === 0
  const empty = isEmpty(skill)

  const handleUpdate = (data: Partial<Skill>) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSkill({ ...skill, ...data })
      setEdited(true)
    })
  }

  const handleSave = () => {
    if (allowSave) {
      setEdited(false)
      onSave(skill)
      delayedOnClose()
    } else if (!allowSave && !saveAttempted) {
      setSaveAttempted(true)
    }
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSave()
    return false
  }

  return (
    <MobileMessageModal isOpen={isOpen} onRequestClose={delayedOnClose} panelName="SkillEditModal">
      <form onSubmit={handleFormSubmit}>
        <SkillInput skill={skill} onChange={(id, data) => handleUpdate(data)} />
        <Spacer size="m" />
        <Row justifyContent="flex-end">
          <Button type="button" size="xs" theme="white" onClick={delayedOnClose}>
            Close
          </Button>
          <Spacer size="xs" />
          <Button type="submit" size="xs" disabled={!edited || (saveAttempted && !allowSave)}>
            {edited || empty ? 'Save' : 'Saved'}
          </Button>
        </Row>

        {saveAttempted && (
          <Row justifyContent="flex-end">
            <ValidationErrorList
              errors={validationErrors}
              style={{
                marginTop: 15,
                textAlign: 'right',
                float: 'right',
                color: 'inherit',
                border: '1px solid orange',
                padding: 10,
              }}
            />
          </Row>
        )}
      </form>
    </MobileMessageModal>
  )
}
