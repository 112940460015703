import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { UserStateContext, useUserState } from 'app/UserState'
import { AuthProvider, UserScope, DrupalRole } from 'shared/types'

// import { ForbiddenPage } from 'home/ForbiddenPage'
import { NotAuthorizedPage } from 'home/NotAuthorizedPage'
import { P, Button } from 'common/ui'

import { decodeJWT } from 'utils/decodeJWT'
import { authorize } from 'auth/oauth2'
import { getRoles } from './useGenericUser'
import { s, createListString } from 'utils/stringUtils'
import { logoutDrupalUser } from 'auth/logoutDrupalUser'

interface Props {
  scopes?: UserScope[]
  roles?: DrupalRole[]
  rolesAny?: DrupalRole[]
  authProvider?: AuthProvider
  NotAuthorizedComponent?: React.FC<{ message?: string }>
}

export const RequireUser: React.FC<Props> = ({
  authProvider = 'sas',
  scopes = [],
  roles = [],
  rolesAny = [],
  NotAuthorizedComponent = NotAuthorizedPage,
  children,
}) => {
  const userState = useUserState()
  const {
    accessToken,
    tokenExpiry,
    drupalProfile,
    offlineMode,
    offlineMentor,
    authProvider: userAuthProvider,
  } = userState
  const location = useLocation()

  const handleSwitchAccount = () => {
    logoutDrupalUser(() => {
      userState.logout()
    })
  }

  if (authProvider === 'sst') {
    if (process.env.REACT_APP_REQUIRE_AUTH === '1') {
      if (!drupalProfile || tokenExpiry < Date.now()) {
        authorize()
        return null
      }
      if (roles.length > 0 || rolesAny.length > 0) {
        const userRoles = getRoles(drupalProfile.roles || '')
        const forbiddenAll =
          roles.length > 0 && roles.reduce((bool, role) => (bool || userRoles.indexOf(role) < 0 ? true : bool), false)
        const forbiddenAny =
          rolesAny.length > 0 &&
          rolesAny.reduce((bool, role) => (!bool ? bool : userRoles.indexOf(role) >= 0 ? false : bool), true as boolean)
        const forbidden = forbiddenAll || forbiddenAny
        if (forbidden)
          return (
            <NotAuthorizedComponent>
              <P align="center">
                The requested page requires{forbiddenAny && ' at least one of'} the following role{s(roles)}:{' '}
                <i>{createListString(roles)}</i>
                <br />
                Your current account does not meet that requirement.
                <br />
                Your role{s(userRoles)}: <i>{createListString(userRoles)}</i>.
              </P>
              <Button type="button" size="s" onClick={() => handleSwitchAccount()}>
                Switch Accounts
              </Button>
            </NotAuthorizedComponent>
          )
      }
    }
  }

  if (authProvider === 'sas') {
    // only check JWT and scopes in production
    if (
      // window.location.host !== 'dev.sas' &&
      !offlineMode &&
      !offlineMentor
    ) {
      const payload = decodeJWT(accessToken)

      if (payload.exp < Date.now() / 1000) {
        return <Redirect to={getLoginUrl(authProvider, scopes) + `?redirect_uri=${location.pathname}`} />
      }

      if (scopes.length > 0 && payload.scopes.filter(scope => scopes.indexOf(scope as UserScope) !== -1).length === 0) {
        // return <ForbiddenPage />
        return <Redirect to={getLoginUrl(authProvider, scopes) + `?redirect_uri=${location.pathname}`} />
      }
    }
  }

  if (userAuthProvider !== authProvider) {
    return <UserStateContext.Provider value={{ ...userState, authProvider }}>{children}</UserStateContext.Provider>
  }

  return <>{children}</>
}

function getLoginUrl(authProvider: AuthProvider, scopes: UserScope[]): string {
  if (authProvider === 'sas' && scopes.indexOf('mentor') >= 0) {
    return '/mentor/login'
  }
  return '/'
}
