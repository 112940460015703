import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { Page } from 'dashboards/common/Page'
import { NavRouteProps } from 'dashboards/types'
import { Button, Column, H3, Spacer } from 'common/ui'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'
import { RadioButtonGroup, RadioButtonTabModifier } from 'common/RadioButtonGroup'
import { AnchorButton } from 'common/LinkButton'
import { checklists } from '../../constant/resources'
import { useSessionStorage } from 'utils/useStorage'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'
import { moduleCodes, AnyModuleCode } from 'shared/dashboard/moduleCodes'

export const SessionChecklists: React.FC<NavRouteProps> = ({ route }) => {
  const [workflow, setWorkflow] = useSessionStorage<'cadet' | 'parent' | 'teacher'>('facDashChecklist', 'cadet')
  const theme = workflow === 'parent' ? 'parentGreen' : workflow === 'teacher' ? 'orange' : 'blue'
  const getPrefix = () => {
    switch (workflow) {
      case 'cadet':
        return 'Cadet Club Meeting -'
      case 'parent':
        return 'Parent Group Meeting -'
      case 'teacher':
        return ''
    }
  }

  return (
    <Page route={route} title="Checklists">
      <RadioButtonTabModifier>
        <RadioButtonGroup<string, ComponentProps<typeof Button> & { value: string; label: string }>
          size="s"
          Component={Button}
          options={[
            { value: 'cadet', label: 'Cadet' },
            { value: 'parent', label: 'Parent' },
            { value: 'teacher', label: 'Teacher' },
          ]}
          value={workflow}
          onChange={setWorkflow}
        />
      </RadioButtonTabModifier>
      <Spacer size="m" />
      <Cells>
        {((moduleCodes[workflow] as unknown) as string[]).map((moduleCode, i) => {
          return (
            <Cell accent={theme} key={i}>
              <CellInner>
                <Column flex="1 1 auto">
                  <H3 style={{ textTransform: 'capitalize' }}>{`${getPrefix()} ${getModuleTitle(
                    moduleCode as AnyModuleCode
                  )}`}</H3>
                </Column>
                <Column flex="none">
                  {checklists[workflow][moduleCode as AnyModuleCode] !== '' ? (
                    <AnchorButton
                      theme={theme}
                      size="s"
                      href={checklists[workflow][moduleCode]}
                      download={`${workflow}-checklist-${getModuleTitle(moduleCode as AnyModuleCode)}`.toLowerCase()}
                      children={'Download'}
                    />
                  ) : (
                    <Disabler>
                      <AnchorButton
                        theme={theme}
                        size="s"
                        href={checklists[workflow][moduleCode]}
                        download={`${workflow}-checklist-${getModuleTitle(moduleCode as AnyModuleCode)}`.toLowerCase()}
                        children={'Download'}
                      />
                    </Disabler>
                  )}
                </Column>
              </CellInner>
            </Cell>
          )
        })}
      </Cells>
    </Page>
  )
}

const Disabler = styled.div`
  ${AnchorButton} {
    opacity: 0.7;
    pointer-events: none;
  }
`
