import React, { Fragment, useRef, useState } from 'react'
import { Row, Column, Spacer, P, Button } from 'common/ui'
import { QuestionOption } from 'shared/training/types'
import styled from 'styled-components'
import remove from 'lodash/remove'
import shuffle from 'lodash/shuffle'
import { isEmpty, xor } from 'lodash'
import { getQuestionState, useTrainingState } from 'training/TrainingState'
import { CustomDragLayer } from './dragComponents/CustomDragLayer'
import { DraggableOption } from './dragComponents/DraggableOption'
import { DroppableArea } from './dragComponents/DroppableArea'
import { MediaAsset } from 'shared/session/types'
import { getImageUrl } from 'session/utils/assetUtils'

// need to see if CMS content has changed - if yes we remove the option - this is ignored for completed units
const stateCheck = (selectedOptions: QuestionOption[], CMSOptions: QuestionOption[]) => {
  return selectedOptions.filter((item, idx) => {
    const found = CMSOptions.find(option => option.title === item.title)
    return !!found
  })
}

export const ClickDragQuestion: React.FC<{
  uid: string
  randomize_options: boolean
  media?: MediaAsset
  question_options: QuestionOption[]
  reviewMode: boolean
  onSubmit: (correct: boolean, value: QuestionOption[], response?: string) => void
}> = ({ uid, randomize_options, question_options, media, reviewMode, onSubmit }) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)
  const [selectedItems, setSelectedItems] = useState<QuestionOption[]>(
    questionState?.answer ? stateCheck(questionState?.answer, question_options) : []
  )
  const correctAnswers = question_options.filter(a => a.correct)

  const items = useRef<QuestionOption[]>(randomize_options ? shuffle(question_options) : question_options)

  const handleSubmit = () => {
    if (isEmpty(xor(correctAnswers, selectedItems))) {
      return onSubmit(true, selectedItems, `The correct answers are "${correctAnswers.map(a => a.title).join(', ')}"`)
    }
    onSubmit(false, selectedItems)
  }

  const addItem = (item: QuestionOption) => {
    if (selectedItems.find(current => current.id === item.id)) return
    setSelectedItems([...selectedItems, item])
  }

  const removeItem = (item: QuestionOption) => {
    setSelectedItems(remove(selectedItems, cur => cur.id !== item.id))
  }

  return (
    <>
      <CustomDragLayer size={300} />
      <Row>
        <Column style={{ width: '30%' }}>
          <DroppableArea type="DRAG_ITEM" onDrop={dragItem => removeItem(dragItem.item)}>
            {items.current.map((option, i) => (
              <Fragment key={i}>
                <DraggableOption
                  type="DRAG_ITEM"
                  item={option}
                  origin="dropZone"
                  disabled={reviewMode || selectedItems.find(item => item.id === option.id) !== undefined}>
                  <DragItem>
                    <P>{option.title}</P>
                  </DragItem>
                </DraggableOption>
                {question_options.length - 1 !== i && <Spacer size="s" />}
              </Fragment>
            ))}
          </DroppableArea>
        </Column>
        <Spacer size="s" />
        <DroppableArea
          style={{ display: 'flex', alignItems: 'stretch' }}
          type="DRAG_ITEM"
          onDrop={dragItem => addItem(dragItem.item)}>
          <DropZone backgroundImage={media}>
            <DragZoneContent justifyContent="center">
              {selectedItems.map((item, i) => (
                <Fragment key={i}>
                  <DraggableOption type="DRAG_ITEM" item={item} origin="dropZone" disabled={reviewMode}>
                    <DragZoneItem>
                      <P>{item.title}</P>
                    </DragZoneItem>
                  </DraggableOption>
                  {selectedItems.length - 1 !== i && <Spacer size="s" />}
                </Fragment>
              ))}
              {selectedItems.length === 0 && (
                <DropInstruction>
                  <P>Drag list item(s) into this area</P>
                </DropInstruction>
              )}
            </DragZoneContent>
          </DropZone>
        </DroppableArea>
      </Row>
      <Spacer />
      {!reviewMode && (
        <Row>
          <Button children="Submit" onClick={handleSubmit} />
        </Row>
      )}
    </>
  )
}

const DropZone = styled.div<{ backgroundImage?: MediaAsset }>`
  background-color: #edf2fa;
  background-image: ${p => (p.backgroundImage ? `url(${getImageUrl(p.backgroundImage)})` : '')};
  background-position: center;
  background-size: cover;
  border: 1px solid #bdc3e0;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 20px;
`

const DragZoneContent = styled(Column)`
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  text-align: center;
`

export const DragItem = styled.div`
  background: #edf2fa;
  border: 1px solid #bdc3e0;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 20px;
  cursor: grab;
`

const DragZoneItem = styled(DragItem)`
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  width: 100%;
`

const DropInstruction = styled.div`
  background: #fff;
  border-radius: 10px;
  border: 1px solid #bdc3e0;
`
