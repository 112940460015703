import { ActivityThoughtTrackerState, ParticipantState } from '../../../types'
import {
  ActionTypes,
  UPDATE_PROFILE_STATE,
  TOGGLE_EDIT_MODE,
  UPDATE_THOUGHT_TEXT,
  UFO_SELECTED,
  UFO_CUT,
} from './actionTypes'

export const defaultProfileState: ActivityThoughtTrackerState[string] = {
  ufoType: 'blimp',
  editMode: true,
  selectingPlayer: true,
  gameOver: false,
  resetCount: 0,
  thoughts: [
    { value: '', cut: false, cutPosition: 0.5, finishedExiting: false },
    { value: '', cut: false, cutPosition: 0.5, finishedExiting: false },
    { value: '', cut: false, cutPosition: 0.5, finishedExiting: false },
  ],
}

export const getInitialState = (participants: ParticipantState[]): ActivityThoughtTrackerState =>
  participants
    .map(({ profile }) => profile.uid)
    .reduce(
      (obj, uid) => ({
        ...obj,
        [uid]: defaultProfileState,
      }),
      {} as ActivityThoughtTrackerState
    )

export function reducer(state: ActivityThoughtTrackerState, action: ActionTypes): typeof state {
  if (!state) state = {}
  switch (action.type) {
    case UPDATE_PROFILE_STATE:
      return { ...state, [action.participantUid]: action.participantState }
    case TOGGLE_EDIT_MODE: {
      const participantState = state[action.participantUid] || { ...defaultProfileState }
      return { ...state, [action.participantUid]: { ...participantState, editMode: !participantState.editMode } }
    }
    case UPDATE_THOUGHT_TEXT: {
      const participantState = state[action.participantUid] || { ...defaultProfileState }
      return {
        ...state,
        [action.participantUid]: {
          ...participantState,
          thoughts: participantState.thoughts.map((stateValue, i) =>
            i === action.index ? { ...stateValue, value: action.value } : stateValue
          ),
        },
      }
    }
    case UFO_CUT: {
      const participantState = state[action.participantUid] || { ...defaultProfileState }
      return {
        ...state,
        [action.participantUid]: {
          ...participantState,
          thoughts: participantState.thoughts.map((stateValue, i) =>
            i === action.index ? { ...stateValue, cut: true } : stateValue
          ),
        },
      }
    }
    case UFO_SELECTED: {
      const participantState = state[action.participantUid] || { ...defaultProfileState }
      return {
        ...state,
        [action.participantUid]: { ...participantState, selectingPlayer: false, ufoType: action.ufoType },
      }
    }
    default:
      return state
  }
}
