import React, { CSSProperties } from 'react'
import { XYCoord, useDragLayer } from 'react-dnd'
import { StickerTileButton } from 'e-telligence/common/StickerTileButton'
import { EmotionometerSticker } from 'shared/e-telligence/types'
import { StickerDragItem, DRAG_TYPE_EMOTIONOMETER_STICKER } from './DraggableSticker'

const layerStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  cursor: 'move',
}

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties {
  if (!initialOffset || !currentOffset) return { display: 'none' }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

export const CustomDragLayer: React.FC<{
  size: number
}> = ({ size }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem() as StickerDragItem,
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))
  function renderItem() {
    switch (itemType) {
      case DRAG_TYPE_EMOTIONOMETER_STICKER:
        return (
          <div style={{ width: size, pointerEvents: 'none' }}>
            <StickerTileButton<typeof item.sticker.type>
              sticker={item.sticker as EmotionometerSticker<typeof item.sticker.type>}
              maxSize={size}
            />
          </div>
        )
      default:
        return null
    }
  }
  if (!isDragging) return null
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  )
}
