import React, { useEffect, useState } from 'react'
import { Button, Column, H2, Hr, P, Row, Spacer } from 'common/ui'
import { NavRouteProps } from 'dashboards/types'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Page } from 'dashboards/common/Page'
import { LinkButton } from 'common/LinkButton'
import { Cells, Cell, CellInner } from 'dashboards/common/Cell'
import { useParams } from 'react-router-dom'
import { DrupalTrainingCourse, TrainingCourse, TrainingEnrolment, TrainingUnitValues } from 'shared/training/types'
import { SpinnerWithLabel } from 'common/Spinner'
import { RouteNotFound } from 'dashboards/common/RouteNotFound'
import { trainingPurple } from 'themes'
import styled, { css } from 'styled-components'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import uniq from 'lodash/uniq'
import { CircleButton } from 'session/common/CircleButton'
import { ShowText } from '../../training/sections/GroupedSection'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { baseUrl } from './FacilitatorDashboard'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useUserState } from 'app/UserState'
import { postJson } from 'utils/apiUtils'
import { RichText, RichTextContainer } from 'session/common/RichText'
import { fontBold, fontLight } from 'fonts'
import ReactDOM from 'react-dom'
import { TrainingCertificateButton } from './TrainingCertificateButton'
import { DrupalProfile } from 'api'

interface UrlParams {
  type: string
}

export const courseTypeMap: { [key: string]: DrupalTrainingCourse['course_type'] } = {
  facilitator: 'facilitator',
  assistant: 'assistant',
  'senior-facilitator': 'senior facilitator',
}

export const checkTrainingAccess = (profile: DrupalProfile, type: string, course: TrainingCourse | null) => {
  // If custom course then we just allow them to view content
  if (!course?.is_drupal) {
    return true
  }

  const roles = profile.roles.split(', ')
  switch (type) {
    case 'assistant':
      return roles.find(role => role === 'sas-sg assistant') !== undefined
    case 'facilitator':
      return roles.find(role => role === 'sas-sg facilitator') !== undefined
    case 'senior facilitator':
      return roles.find(role => role === 'sas-sg senior facilitator') !== undefined
    default:
      return false
  }
}

const cleanTitleString = (str: string) =>
  str.replace(/-/g, ' ').replace(/\b[a-z]/g, matches => matches[0].toUpperCase())

export const TrainingFacilitator: React.FC<NavRouteProps> = ({ route }) => {
  const { accessToken, drupalProfile } = useUserState()
  const { type } = useParams<UrlParams>()
  const [showStartModal, setShowStartModal] = useState<boolean>(false)
  const [showEndModal, setShowEndModal] = useState<boolean>(false)

  const [trainingUnitValues, { loading: unitsLoading }] = useEndpoint<TrainingUnitValues[] | null>(
    `/api/v1/training_unit_values`,
    null
  )

  const [trainingCourse, { loading: trainingCourseLoading }] = useEndpoint<TrainingCourse | null>(
    `/api/v1/training_courses/${type}`,
    null
  )

  const [enrolledCourses, { loading: enrolledLoading, fetch: fetchTrainingCourses }] = useEndpoint<
    TrainingEnrolment[] | null
  >(`/api/v1/training_courses/listEnrolled`, null)

  const fullAccess = drupalProfile ? checkTrainingAccess(drupalProfile, courseTypeMap[type], trainingCourse) : false

  const findCourseByType = (courseType: TrainingEnrolment['type']) => {
    if (enrolledCourses) {
      const course = enrolledCourses.find(course => course.type === courseType)
      if (!course) return false
      return course
    }
    return false
  }

  const getUnitByUid = (uid: string) => {
    if (trainingUnitValues) return trainingUnitValues.find(unit => unit.unit_uid === uid)
  }

  const getUnitColour = (unitData: TrainingUnitValues | undefined) => {
    if (!unitData) return '#fff'
    if (unitData.completed) return 'rgba(78, 190, 64, 0.1)'
    if (unitData.started && fullAccess) return 'rgba(103, 55, 181, 0.1)'
    if (unitData.started) return 'rgba(255, 40, 40, 0.1)'
    return '#fff'
  }

  const getUnitStatus = (unitValue: TrainingUnitValues | undefined, previousUnit: TrainingUnitValues | undefined) => {
    if (fullAccess) {
      if (unitValue?.started) return 'complete'
      return 'unlocked'
    }
    if (!previousUnit) {
      if (unitValue?.completed) return 'complete'
      return 'locked'
    }
    if (previousUnit && previousUnit.completed) {
      if (unitValue?.completed) return 'complete'
      return 'unlocked'
    }
    return 'locked'
  }

  const startCourse = () => {
    postJson(`/api/v1/training_courses/start/${courseTypeMap[type]}`, {}, getAuthRequestParams(accessToken))
      .then(fetchTrainingCourses)
      .then(() => ReactDOM.unstable_batchedUpdates(() => setShowStartModal(false)))
  }

  const checkComplete = (courseType: DrupalTrainingCourse['course_type']) => {
    const course = findCourseByType(courseType)
    return course && course.drupalData?.course_status === 'Complete'
  }

  useEffect(() => {
    const course = findCourseByType(courseTypeMap[type])

    if (course && course.is_drupal && course.drupalData?.course_status === 'Pending' && !fullAccess) {
      setShowStartModal(true)
    }

    if (
      course &&
      trainingCourse &&
      trainingUnitValues &&
      course.is_drupal &&
      course.drupalData &&
      !course.drupalData.complete_course &&
      course.drupalData.course_status !== 'In review' &&
      course.drupalData.course_status !== 'Complete'
    ) {
      const unitsComplete = trainingCourse.training_units.reduce((acc, unit) => {
        if (!acc) return false
        const unitData = getUnitByUid(unit.uid)
        if (unitData && unitData.completed) return true
        return false
      }, true)

      if (unitsComplete) {
        setShowEndModal(true)
        postJson(`/api/v1/training_courses/complete/${courseTypeMap[type]}`, {}, getAuthRequestParams(accessToken))
          .then(fetchTrainingCourses)
          .then(() => setShowEndModal(false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrolledCourses])

  return (
    <>
      <Page
        route={route}
        title="My Training"
        sideFragment={
          checkComplete(courseTypeMap[type]) ? (
            <TrainingCertificateButton theme={trainingPurple} size="s" courseType={courseTypeMap[type]} />
          ) : (
            ''
          )
        }>
        <Spacer />
        <HeaderHr children={trainingCourse?.title} />
        {!showStartModal && (
          <>
            {trainingCourse && trainingUnitValues ? (
              <>
                {checkComplete(courseTypeMap[type]) && (
                  <>
                    <PlainPanel alignItems="center" padding={[15, 15, 0, 15]}>
                      <P color="#4EBE40">Congratulations!</P>
                      <P>You have completed the course :)</P>
                      <img src={require('../assets/TrainingComplete.png')} alt="" />
                    </PlainPanel>
                    <Spacer />
                  </>
                )}
                <Cells>
                  {trainingCourse.training_units.map((trainingUnit, i) => {
                    const previousUnitData = i > 0 ? getUnitByUid(trainingCourse.training_units[i - 1].uid) : undefined
                    const unitData = getUnitByUid(trainingUnit.uid)
                    const status =
                      i === 0 && !unitData?.completed && !(unitData?.started && fullAccess)
                        ? 'unlocked'
                        : getUnitStatus(unitData, previousUnitData)
                    const sectionTitles = uniq(
                      trainingUnit.training_steps.filter(item => !!item.section).map(item => item.section)
                    )
                    const questionCount = trainingUnit.training_steps.reduce(
                      (acc, step) => (acc += step.panels.filter(panel => panel.content_type === 'question').length),
                      0
                    )

                    return (
                      <Cell
                        key={i}
                        disabled={status === 'locked'}
                        style={{
                          backgroundColor: getUnitColour(unitData),
                        }}>
                        <CellInner>
                          <Column flex="none" paddingRight={15}>
                            <Icon status={status}>
                              {status === 'complete' && (
                                <svg width="20" height="20" viewBox="0 0 16 16">
                                  <polyline
                                    points="4 8.43 6.67 11 12 5"
                                    fill="none"
                                    stroke="white"
                                    strokeMiterlimit="10"
                                    strokeWidth="2"
                                  />
                                </svg>
                              )}
                              {status === 'locked' && (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 28" width="13" height="18">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.09091 6.81818V10.9091H2.72727C1.27043 10.9091 0 11.9678 0 13.4091V24.7727C0 26.214 1.27043 27.2727 2.72727 27.2727H19.0909C20.5478 27.2727 21.8182 26.214 21.8182 24.7727V13.4091C21.8182 11.9678 20.5478 10.9091 19.0909 10.9091H17.7273V6.81818C17.7273 3.0526 14.6747 0 10.9091 0C7.14351 0 4.09091 3.0526 4.09091 6.81818ZM15 6.81818V10.9091H6.81818V6.81818C6.81818 4.55884 8.64974 2.72727 10.9091 2.72727C13.1684 2.72727 15 4.55884 15 6.81818ZM2.72727 24.5447V13.6357H19.0909V24.5447H2.72727ZM12.2727 19.0909C12.2727 19.844 11.6622 20.4545 10.9091 20.4545C10.156 20.4545 9.54545 19.844 9.54545 19.0909C9.54545 18.3378 10.156 17.7273 10.9091 17.7273C11.6622 17.7273 12.2727 18.3378 12.2727 19.0909Z"
                                    fill="#011A46"></path>
                                </svg>
                              )}
                            </Icon>
                          </Column>
                          <Column flex="1 1 auto">
                            <P style={{ margin: 0, fontWeight: 600, fontSize: '0.9rem' }} color="#925BED">
                              {trainingCourse.unit_label ||
                                (trainingCourse.is_drupal
                                  ? `${cleanTitleString(type)} Training - ${questionCount} Questions`
                                  : cleanTitleString(trainingCourse.type))}
                            </P>
                            <P style={{ margin: 0, fontWeight: 400, fontSize: '1.1rem' }}>
                              Unit {i + 1} - {trainingUnit.title}
                            </P>
                          </Column>
                          <Column flex="none">
                            <LinkButton
                              theme={trainingPurple}
                              size="s"
                              children={
                                trainingCourse.is_drupal
                                  ? unitData?.started && unitData.completed
                                    ? 'review'
                                    : unitData?.started
                                    ? 'continue'
                                    : 'begin'
                                  : 'view'
                              }
                              to={`/facilitator/training/${type}/${trainingUnit.uid}`}
                            />
                          </Column>
                        </CellInner>
                        {sectionTitles.length > 0 && (
                          <TrainingCellTray>
                            {sectionTitles.map((title, idx) => (
                              <P key={idx} style={{ margin: '0 0 5px 0' }}>
                                {title}
                              </P>
                            ))}
                          </TrainingCellTray>
                        )}
                      </Cell>
                    )
                  })}
                </Cells>
              </>
            ) : trainingCourseLoading || unitsLoading || enrolledLoading ? (
              <SpinnerWithLabel color="#925BED" label="One moment please..." />
            ) : (
              <RouteNotFound />
            )}
          </>
        )}
      </Page>
      <MobileMessageModal
        panelStyle={{ maxWidth: 600 }}
        isOpen={showStartModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}>
        <H2 style={{ color: '#925BED', fontSize: '1.2rem' }}>{trainingCourse ? trainingCourse.title : ''}</H2>
        <Spacer />
        <Hr />
        <Spacer />
        <Column style={{ textAlign: 'left' }}>
          <ContentWrapper>{trainingCourse ? <RichText text={trainingCourse.start_content} /> : ''}</ContentWrapper>
        </Column>
        <Spacer />
        <Row justifyContent="center">
          <Button
            style={{ cursor: 'pointer' }}
            size="s"
            theme={trainingPurple}
            children={'Begin Now'}
            onClick={startCourse}
          />
          <Spacer size="s" />
          <LinkButton
            style={{ minWidth: 135, boxSizing: 'border-box' }}
            size="s"
            to={`${baseUrl}/training`}
            children="Come Back Later"
          />
        </Row>
      </MobileMessageModal>
      <MobileMessageModal
        panelStyle={{ minWidth: 400 }}
        isOpen={showEndModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}>
        <H2 style={{ color: '#925BED', fontSize: '1.2rem' }}>{trainingCourse ? trainingCourse.title : ''}</H2>
        <Spacer />
        <Hr />
        <Spacer />
        <Column alignItems="center">
          <SpinnerWithLabel color="#925BED" label="Finalising Course..." />
        </Column>
      </MobileMessageModal>
    </>
  )
}

const TrainingCellTray: React.FC = ({ children }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <>
      <CellInner
        justifyContent="space-between"
        paddingTop={10}
        paddingLeft={56}
        paddingRight={12}
        marginLeft="0 !important"
        marginRight="0 !important"
        style={{ borderTop: '1px solid #BDC3E0' }}>
        <Column flex="none">
          <P style={{ fontWeight: 700, margin: 0 }}>Contents</P>
        </Column>
        <Row alignItems="center" onClick={() => setExpanded(!expanded)} style={{ cursor: 'pointer' }}>
          <ShowText style={{ marginRight: 10 }}>{expanded ? 'HIDE' : 'SHOW'}</ShowText>
          <CircleButton
            style={{ cursor: 'pointer' }}
            theme={trainingPurple}
            size="xs"
            children={expanded ? '▲' : '▼'}
          />
        </Row>
      </CellInner>
      {expanded && (
        <CellInner paddingLeft={45} marginTop={0}>
          <Column flex="none" paddingRight={15}>
            {children}
          </Column>
        </CellInner>
      )}
    </>
  )
}

const Icon = styled.span<{ status: 'complete' | 'locked' | 'unlocked' }>`
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: 2px solid;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  ${p =>
    p.status === 'complete'
      ? css`
          border-color: #419636;
          background-color: #4ebe40;
        `
      : css`
          border-color: #cdd2e4;
          background-color: #fff;
        `}
`

const ContentWrapper = styled.div`
  ${RichTextContainer} {
    ol,
    ul {
      ol,
      ul {
        margin-top: 8px;
      }
    }

    li {
      margin-bottom: 8px;
    }

    ol {
      padding-left: 0;

      li {
        list-style: decimal;
        padding-left: 0.5em;
        margin-left: 1em;

        &:before {
          display: none;
        }

        &::marker {
          color: #6737b5;
          font-weight: bold;
        }
      }

      ol li {
        padding-left: 25px;
      }
    }

    ul li {
      padding-left: 20px;

      &::before {
        background: url(${require('session/assets/list-bullet-purple.svg')?.default});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 0.8em;
        width: 0.8em;
        top: 6px;
      }

      li::before {
        background: url(${require('session/assets/list-bullet-light-purple.svg')?.default});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 0.8em;
        width: 0.8em;
        top: 6px;
      }
    }

    a {
      color: #6737b5;
    }
  }
`

const PlainPanel = styled(Column)`
  ${fontLight};
  background: #fff;
  border-radius: 10px;
  border: 1px solid #cdd2e4;

  ${P} {
    font-size: 1.5em;
    margin: 0;
    margin-bottom: 15px;

    &:first-of-type {
      ${fontBold};
      font-size: 3em;
    }
  }

  img {
    width: 50%;
  }
`
