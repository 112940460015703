import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { fontRegular } from 'fonts'

import { GadgetPackKey, gadgetPackKeys } from 'shared/session/types'
import { GADGET_PACK_UNLOCK, objShapeMatches } from 'shared/progressionEvents'

import { Column, Container, Row } from 'common/ui'

import { StressBallModal } from './StressBallModal'
import { IdCardModal } from './IdCardModal'
import { FriendshipFormulaModal } from './FriendshipFormulaModal'
import { BionicPowersModal } from './BionicPowersModal'
import { FactFilesModal } from './FactFilesModal'
import { FriendometerModal } from './FriendometerModal'
import { EmotionometerModal } from './EmotionometerModal'
import { RelaxationGadgetsModal } from './RelaxationGadgetsModal'
import { CodeCardModal } from './CodeCardsModal'
import { GraduationMedalModal } from './GraduationMedalModal'

import { useProgressionEventExists, useProgressionEventsState } from 'session/ProgressionEventsState'

export const GadgetTray: React.FC<{ readOnly?: boolean }> = ({ readOnly }) => {
  const [activeModal, setActiveModal] = useState<GadgetPackKey | null>(null)

  const { progressionEvents } = useProgressionEventsState()

  const availableGadgets = gadgetPackKeys.reduce((acc, gadgetKey) => {
    let eventStructure: { type: any; category: string; subcategory?: string } = {
      type: GADGET_PACK_UNLOCK,
      category:
        gadgetKey === 'emotionometer/anger' || gadgetKey === 'emotionometer/anxiety' ? 'emotionometer' : gadgetKey,
    }

    if (gadgetKey === 'emotionometer/anger' || gadgetKey === 'emotionometer/anxiety') {
      eventStructure.subcategory =
        gadgetKey === 'emotionometer/anger' ? 'anger' : gadgetKey === 'emotionometer/anxiety' ? 'anxiety' : undefined
    }

    acc[gadgetKey] = false

    for (let testProgressionEvent of progressionEvents) {
      if (objShapeMatches(eventStructure, testProgressionEvent)) {
        acc[gadgetKey] = true
      }
    }

    return acc
  }, {} as { [key: string]: boolean })

  return (
    <>
      <TrayContainer size="l">
        <Row flexWrap={true} justifyContent="space-between">
          <TrayColumn position="left">
            <Row justifyContent="space-between">
              <TrayItem
                size="small"
                unlocked={availableGadgets['id-card']}
                title="ID Tag"
                backgroundImage={require('../assets/gadget-tray/id-card.png')}
                onClick={() => setActiveModal('id-card')}
              />
              <TrayItem
                size="small"
                unlocked={availableGadgets['bionic-powers']}
                title="Bionic Powers"
                backgroundImage={require('../assets/gadget-tray/bionic-powers.png')}
                onClick={() => setActiveModal('bionic-powers')}
              />
              <TrayItem
                size="small"
                unlocked={availableGadgets['relaxation-gadgets']}
                title="Relaxation Gadgets"
                backgroundImage={require('../assets/gadget-tray/relaxation-gadgets.png')}
                onClick={() => setActiveModal('relaxation-gadgets')}
              />
              <TrayItem
                size="small"
                unlocked={availableGadgets['code-cards']}
                title="Code Cards"
                backgroundImage={require('../assets/gadget-tray/code-cards.png')}
                onClick={() => setActiveModal('code-cards')}
              />
            </Row>
          </TrayColumn>
          <TrayColumn position="right">
            <TrayItem
              size="large"
              unlocked={availableGadgets['emotionometer/anger'] || availableGadgets['emotionometer/anxiety']}
              title="Emotionometers"
              backgroundImage={require('../assets/gadget-tray/emotionometer.png')}
              onClick={() => setActiveModal('emotionometer/anger')}
            />
          </TrayColumn>
        </Row>
        <Row flexWrap={true} justifyContent="space-between">
          <TrayColumn position="left">
            <Row justifyContent="space-between">
              <TrayItem
                size="small"
                unlocked={availableGadgets['stress-ball']}
                title="Stress Ball"
                backgroundImage={require('../assets/gadget-tray/stress-ball.png')}
                onClick={() => setActiveModal('stress-ball')}
              />
              <TrayItem
                size="small"
                unlocked={availableGadgets['friendship-formula']}
                title="Friendship Formula"
                backgroundImage={require('../assets/gadget-tray/friendship-formula.png')}
                onClick={() => setActiveModal('friendship-formula')}
              />
              <TrayItem
                size="small"
                unlocked={availableGadgets['fact-files']}
                title="Fact Files"
                backgroundImage={require('../assets/gadget-tray/fact-files.png')}
                onClick={() => setActiveModal('fact-files')}
              />
              <TrayItem
                size="small"
                unlocked={availableGadgets['medal']}
                title="Graduation Medal"
                backgroundImage={require('../assets/gadget-tray/medal.png')}
                onClick={() => setActiveModal('medal')}
              />
            </Row>
          </TrayColumn>
          <TrayColumn position="right">
            <TrayItem
              size="large"
              unlocked={availableGadgets['friendometer']}
              title="Friendometer"
              backgroundImage={require('../assets/gadget-tray/friendometer.png')}
              onClick={() => setActiveModal('friendometer')}
            />
          </TrayColumn>
        </Row>
      </TrayContainer>
      {activeModal && <GadgetModal gadgetKey={activeModal} onClose={() => setActiveModal(null)} readOnly={readOnly} />}
    </>
  )
}

export const GadgetModal: React.FC<{
  gadgetKey: GadgetPackKey | null
  autoSelectEmotionometer?: boolean
  mentorMode?: boolean
  onClose: () => void
  readOnly?: boolean
}> = ({ gadgetKey, onClose, autoSelectEmotionometer, mentorMode, readOnly }) => {
  const angerEmotionometerUnlocked = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'emotionometer',
    subcategory: 'anger',
    event_key: null,
    event_value: null,
  })

  const anxietyEmotionometerUnlocked = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'emotionometer',
    subcategory: 'anxiety',
    event_key: null,
    event_value: null,
  })

  switch (gadgetKey) {
    case 'stress-ball':
      return <StressBallModal onClose={onClose} mentorMode={mentorMode} />
    case 'id-card':
      return <IdCardModal onClose={onClose} mentorMode={mentorMode} readOnly={readOnly} />
    case 'friendship-formula':
      return <FriendshipFormulaModal onClose={onClose} mentorMode={mentorMode} />
    case 'bionic-powers':
      return <BionicPowersModal onClose={onClose} mentorMode={mentorMode} />
    case 'fact-files':
      return <FactFilesModal onClose={onClose} mentorMode={mentorMode} readOnly={readOnly} />
    case 'friendometer':
      return <FriendometerModal onClose={onClose} mentorMode={mentorMode} />
    case 'emotionometer/anger':
    case 'emotionometer/anxiety':
      return (
        <EmotionometerModal
          onClose={onClose}
          anxietyUnlocked={anxietyEmotionometerUnlocked}
          angerUnlocked={angerEmotionometerUnlocked}
          autoSelect={
            autoSelectEmotionometer ? (gadgetKey.replace('emotionometer/', '') as 'anger' | 'anxiety') : undefined
          }
          mentorMode={mentorMode}
        />
      )
    case 'relaxation-gadgets':
      return <RelaxationGadgetsModal onClose={onClose} mentorMode={mentorMode} readOnly={readOnly} />
    case 'code-cards':
      return <CodeCardModal onClose={onClose} mentorMode={mentorMode} />
    case 'medal':
      return <GraduationMedalModal onClose={onClose} mentorMode={mentorMode} />
    default:
      return <div>Couldn't Find Gadget Item</div>
  }
}

export const TrayContainer = styled(Container)`
  box-sizing: border-box;
  padding: 15px 15px 0 15px;
  width: 100%;
  max-width: 1024px;
  max-height: calc(100vh - 65px);
  overflow: auto;
`

const TrayColumn = styled(Column)<{ position: 'left' | 'right' }>`
  width: calc(50% - 10px);
  margin-bottom: 20px;

  @media (max-width: 680px) {
    width: ${p => (p.position === 'left' ? 'calc(70% - 10px)' : 'calc(30% - 10px)')};
  }
`

interface TrayItemProps {
  size: 'small' | 'large'
  unlocked: boolean
  title: string
  backgroundImage: string
}

export const TrayItem = styled.div<TrayItemProps>`
  position: relative;
  background: rgba(70, 75, 97, 0.81);
  border: 1px solid #333748;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(228, 237, 248, 0.2);
  border-radius: 5px;
  height: 150px;
  width: ${p => (p.size === 'small' ? 'calc(25% - 15px)' : '100%')};
  ${p =>
    p.unlocked
      ? css`
          background-image: url(${p.backgroundImage});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}

  &:before {
    content: '';
    display: ${p => (p.unlocked ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #464b61;
    opacity: 0.8;
    transition: 0.3s;
  }

  &:after {
    ${fontRegular};
    content: ${(p) => (p.unlocked ? `'${p.title}'` : `url(${require('../assets/gadget-lock.svg')?.default})`)};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
  }

  &:hover {
    &:before,
    &:after {
      opacity: ${p => (p.unlocked ? 0 : 1)};
    }
  }
`
