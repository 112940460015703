import React, { CSSProperties, forwardRef, PropsWithChildren, useRef } from 'react'
import styled from 'styled-components'
import { ScavengerHuntCard as Card } from 'shared/session/types'
import { useDrop, useDrag } from 'react-dnd'
import { DRAG_TYPE_DECODER_CARD, CardDragItem } from './Card'
import { images } from './assets'

export interface CardAreaProps {
  style?: CSSProperties
  onSelect?: () => void
  card?: Card
}

export const CardArea = forwardRef<HTMLDivElement, PropsWithChildren<CardAreaProps>>(
  ({ children, style = {}, onSelect, card }, ref) => {
    return (
      <CardAreaWrapper
        ref={ref}
        style={{ ...style, cursor: onSelect ? 'pointer' : 'auto' }}
        children={children}
        onClick={onSelect}
        imageUrl={card ? images[card.id] : undefined}
      />
    )
  }
)

export const CardAreaWrapper = styled.div<{ imageUrl?: any }>`
  position: relative;
  width: 100px;
  height: 150px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${p => p.theme.thinOutlineColor};
  box-shadow: 0 1px 0 0 white;

  ${p => (p.imageUrl ? `background-image: url('${p.imageUrl}');` : '')}
  background-size: cover;
  background-repeat: no-repeat;
`

export type DroppableCardAreaProps = CardAreaProps & {
  onDrop: (item: CardDragItem) => void
  onSelect?: () => void
  highlight?: boolean
}

export const DroppableCardArea: React.FC<DroppableCardAreaProps> = ({ onDrop, highlight, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ itemIsOver, itemBeingDragged }, drop] = useDrop({
    accept: DRAG_TYPE_DECODER_CARD,
    drop: onDrop,
    collect: monitor => ({
      itemIsOver: monitor.isOver(),
      itemBeingDragged: monitor.canDrop(),
    }),
  })
  const [, drag] = useDrag({
    canDrag: () => !!props.card,
    item: { type: DRAG_TYPE_DECODER_CARD, from: 'destination', props } as CardDragItem,
  })

  const style: CSSProperties = {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    borderRadius: 10,
    border: itemIsOver ? '3px solid white' : 'none', //itemBeingDragged ? '2px dashed white' : 'none',
    boxShadow: itemIsOver ? 'inset 0 0 8px 0 rgba(0,0,0,0.5)' : highlight ? '0 0 12px 0 yellow' : undefined,
  }
  if (itemBeingDragged) {
    style.position = 'relative'
    style.zIndex = 99
  }

  drag(drop(ref))

  return (
    <CardArea ref={ref} {...props}>
      <div ref={drop} style={style} />
    </CardArea>
  )
}
