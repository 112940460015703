import React, { ChangeEvent, useRef } from 'react'
import { QuestionOption } from '../../shared/training/types'
import { RadioGroup } from 'common/RadioGroup'
import { getQuestionState, useTrainingState } from 'training/TrainingState'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { Label, Spacer, TextareaInput } from 'common/ui'
import styled from 'styled-components'

export const QuestionnaireRadioQuestion: React.FC<{
  uid: string
  question_options: QuestionOption[]
  reviewMode: boolean
  onSubmit: (correct: boolean, value: string, response?: string, skipValidation?: boolean) => void
}> = ({ question_options, reviewMode, onSubmit, uid }) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)
  const options = useRef<{ value: string }[]>(
    question_options.map(option => {
      return { value: option.title }
    })
  )

  const handleChange = (val: string) => {
    onSubmit(true, val, undefined, true)
  }

  return (
    <>
      <RadioGroup<{ value: string }>
        name=""
        hideOuterDividers
        hideInnerDividers
        size="s"
        value={questionState?.answer}
        options={options.current}
        onChange={handleChange}
        disabled={reviewMode}
      />
    </>
  )
}

export const QuestionnaireInputQuestion: React.FC<{
  uid: string
  input_label?: string
  placeholder?: string
  reviewMode: boolean
  onSubmit: (correct: boolean, value: string, response?: string, skipValidation?: boolean) => void
}> = ({ uid, input_label, placeholder, reviewMode, onSubmit }) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)

  const handleChange = (val: string) => {
    onSubmit(true, val, '', true)
  }

  return (
    <InputQuestionWrapper>
      <Label children={input_label} />
      <Spacer size="s" />
      <AutosizeTextareaInput
        inputSize="s"
        value={questionState?.answer || ''}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e.target.value)}
        placeholder={placeholder}
        disabled={reviewMode}
      />
    </InputQuestionWrapper>
  )
}

const InputQuestionWrapper = styled.div`
  ${TextareaInput} {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #cdd2e4;
    box-shadow: none;
    padding: 13px;
    padding-bottom: 0;
    line-height: 1.2;
  }
`
