import React, { useRef, useState, useEffect, CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { useSessionState } from 'session/SessionState'
import { UPDATE_GROUP_HIGHLIGHT, UPDATE_PARTICIPANT_HIGHLIGHT } from 'shared/session/actionTypes'
import { useFacilitatorState, useFocusedParticipantState } from 'session/hooks/useProfileState'

interface Props {
  id: string
  style?: CSSProperties
}

export const HighlightableArea: React.FC<Props> = ({ children, id, style }) => {
  const facilitatorState = useFacilitatorState()
  const {
    dispatch,
    getBaseAction,
    state: { highlight },
  } = useSessionState()
  const participantState = useFocusedParticipantState()
  const elementRef = useRef<HTMLDivElement | null>(null)
  const [over, setOver] = useState<boolean>(false)
  const [outline, setOutline] = useState<boolean>(false)
  const [individualOutline, setIndividualOutline] = useState<boolean>(false)
  const lastIndividualHighlightKey = useRef<string | null>(null)
  const lastHighlightKey = useRef<string | null>(null)
  const interactive = facilitatorState && facilitatorState.selectedTool === 'highlight'
  const outlineTimeout = useRef<number | undefined>(undefined)
  const individualOutlineTimeout = useRef<number | undefined>(undefined)

  useEffect(() => {
    const resetOutline = () => {
      setOutline(false)
      dispatch({ ...getBaseAction(), type: UPDATE_GROUP_HIGHLIGHT, location: null })
    }
    if (!highlight && lastHighlightKey.current !== null) {
      lastHighlightKey.current = null
      setOutline(false)
    } else if (highlight && highlight.key !== lastHighlightKey.current) {
      lastHighlightKey.current = highlight.key
      if (id === highlight.key) {
        setOutline(true)
        outlineTimeout.current = setTimeout(resetOutline, 5000)
      } else {
        setOutline(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, highlight])

  useEffect(() => {
    if (!participantState) return

    const resetOutline = () => {
      setIndividualOutline(false)
      dispatch({
        ...getBaseAction(),
        type: UPDATE_PARTICIPANT_HIGHLIGHT,
        location: null,
        participantUid: participantState.profile.uid,
      })
    }

    if (!participantState.highlight && lastIndividualHighlightKey.current !== null) {
      lastIndividualHighlightKey.current = null
      setIndividualOutline(false)
    } else if (participantState.highlight && participantState.highlight.key !== lastIndividualHighlightKey.current) {
      lastIndividualHighlightKey.current = participantState.highlight.key
      if (id === participantState.highlight.key) {
        setIndividualOutline(true)
        individualOutlineTimeout.current = setTimeout(resetOutline, 5000)
      } else {
        setIndividualOutline(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, participantState])

  const handleSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!facilitatorState || (facilitatorState && facilitatorState.selectedTool !== 'highlight')) return
    event.stopPropagation()
    event.preventDefault()

    const location = highlight && highlight.key === id ? null : { key: id }

    if (facilitatorState.focusedParticipantUid) {
      if (individualOutlineTimeout.current) clearTimeout(individualOutlineTimeout.current)
      dispatch({
        ...getBaseAction(),
        type: UPDATE_PARTICIPANT_HIGHLIGHT,
        location: location,
        participantUid: facilitatorState.focusedParticipantUid,
      })
    } else {
      if (outlineTimeout.current) clearTimeout(outlineTimeout.current)
      dispatch({
        ...getBaseAction(),
        type: UPDATE_GROUP_HIGHLIGHT,
        location: location,
      })
    }
  }

  return (
    <HighlightArea
      ref={elementRef}
      over={over}
      outline={outline}
      individualOutline={individualOutline}
      individualFocused={facilitatorState && !!facilitatorState.focusedParticipantUid}
      interactive={interactive}
      children={children}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      onClick={handleSelect}
      style={{ cursor: interactive && over ? 'zoom-in' : 'auto', ...(style || {}) }}
    />
  )
}

interface highlightProps {
  over: boolean
  outline: boolean
  individualOutline: boolean
  interactive: boolean
  individualFocused: boolean
}

export const HighlightArea = styled.div<highlightProps>`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    filter: blur(10px);
    border: 10px solid ${p => (p.outline ? 'rgba(255,255,0,1)' : p.individualOutline ? '#8af062' : 'rgba(255,255,0,0)')};
    background-color: ${p =>
      p.interactive && p.over
        ? p.individualFocused
          ? 'rgb(138, 240, 98, 0.2)'
          : 'rgba(255, 255, 0, 0.2)'
        : 'transparent'};
    pointer-events: none;
    cursor: ${p => (p.interactive ? 'zoom-in' : 'auto')};
  }
  ${p =>
    !p.interactive
      ? ''
      : css`
          & *:enabled {
            pointer-events: none;
          }
        `}
`
