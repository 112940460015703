/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { fontRegular } from 'fonts'
import { SVG } from 'common/SVG'
import { Button } from 'common/ui'
import { useUserState } from 'app/UserState'
import { getSkillTracker } from 'api'
import { useHistory } from 'react-router-dom'
import { useOnline } from 'utils/useOnline'
import { isOnline } from 'utils/deviceUtils'

export const OfflineNotification: React.FC = () => {
  const history = useHistory()
  const { profileId, getUserScopes, accessToken } = useUserState()
  const isAdult = getUserScopes().indexOf('mentor') >= 0 && !!accessToken
  const online = useOnline()
  const previousOnline = useRef<boolean>(isOnline())
  const [loggedOut, setLoggedOut] = useState<boolean>(false)
  const [dismissed, setDismissed] = useState(true)
  const [, reRender] = useState(0)
  useEffect(() => {
    if (previousOnline.current !== online) {
      if (online) setTimeout(() => setDismissed(true), 3000)
      setDismissed(false)
      previousOnline.current = online
    }
  }, [online])
  useEffect(() => {
    const authChecker = window.setInterval(() => {
      getSkillTracker(profileId, accessToken)
        .then(() => {})
        .catch(response => {
          if (response.error === 'access_denied') {
            ReactDOM.unstable_batchedUpdates(() => {
              setLoggedOut(true)
              setDismissed(false)
            })
            window.clearInterval(authChecker)
          }
        })
    }, 25000)
    return () => {
      window.clearInterval(authChecker)
    }
  }, [accessToken, profileId])
  return (
    <Notification visible={!dismissed}>
      <NotificationDiv
        onClick={() => {
          console.log(dismissed)
          console.log('dismissed')
          setDismissed(true)
        }}>
        {loggedOut ? (
          <Message>
            You've been automatically logged out. Any changes you make will be stored offline until you sign in again.
          </Message>
        ) : online ? (
          <Message>You're back online!</Message>
        ) : (
          <Message>You’ve gone offline. Any changes you make will be synced once you go online again.</Message>
        )}
        <span style={{ paddingTop: 5, paddingLeft: 15, verticalAlign: 'top' }}>
          <SVG viewboxSize={24} size={14}>
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              fill="white"
            />
          </SVG>
        </span>
        {loggedOut && (
          <Message style={{ display: 'block' }}>
            <Button
              size="xxs"
              onClick={() => history.push(isAdult ? '/skill-tracker/mentor' : '/')}
              style={{ display: 'block', margin: '10px auto' }}>
              Login Again
            </Button>
          </Message>
        )}
      </NotificationDiv>
    </Notification>
  )
}

function useAnimatedNotification(visible: boolean) {
  const props = useSpring({
    config: { tension: 120, clamp: true },
    opacity: visible ? 1 : 0,
    transform: visible ? 'translate3d(0, 0, 100px)' : 'translate3d(0, 150%, 100px)',
  })
  return props
}

const Notification: React.FC<{ visible: boolean }> = ({ visible, children }) => {
  const style = useAnimatedNotification(visible)
  return <NotificationContainer style={style}>{children}</NotificationContainer>
}

const NotificationContainer = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
  overflow: hidden;
`

const NotificationDiv = styled.div`
  ${fontRegular}
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  margin: 10px auto;
  max-width: calc(100vw - 36px);
  padding: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  pointer-events: initial;
`
const Message = styled.div`
  display: inline-block;
  width: 100%;
  padding-left: 30px;
  max-width: calc(100% - 60px);
`
