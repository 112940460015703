import { RequireAtLeastOne } from 'types'
import { MarginSize, margins } from './ui'
import styled from 'styled-components'

interface BaseGridProps {
  gapSize?: MarginSize
  columns?: number
  rows: number
}

type GridProps = RequireAtLeastOne<BaseGridProps, 'columns' | 'rows'>

export const Grid = styled.div<GridProps>`
    display: grid;
    ${p => (p.columns ? `grid-template-columns: repeat(${p.columns}, 1fr);` : '')}
    ${p => (p.rows ? `grid-template-rows: repeat(${p.rows}, 1fr);` : '')}
    gap: ${p => margins[p.gapSize || 'm']};
  `
