import React from 'react'
import { Button, Column } from 'common/ui'
import { SVG } from 'common/SVG'
import { HideForPrint } from 'print/ui-print'

export const PrintButton: React.FC = () => (
  <HideForPrint>
    <Button size="xs" style={{ cursor: 'pointer' }} onClick={() => window.print()}>
      <Column justifyContent="center">
        <SVG viewboxSize={24} size={20} style={{ fill: '#fff' }}>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19 8h-1V3H6v5H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zM8 5h8v3H8V5zm8 12v2H8v-4h8v2zm2-2v-2H6v2H4v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2z" />
          <circle cx="18" cy="11.5" r="1" />
        </SVG>
      </Column>
    </Button>
  </HideForPrint>
)
