import {
  ETelligenceState as State,
  Gadget,
  CodeCard,
  Emotionometer,
  EmotionometerType,
  EmotionometerStickerBase,
  EmotionometerDevice,
  EmotionometerRange,
  EmotionometerSticker,
} from './types'
import { BaseAction } from '../types'

export const UPDATE_STATE = 'UPDATE_STATE'
export const ADD_RELAXATION_GADGET = 'ADD_RELAXATION_GADGET'
export const REMOVE_RELAXATION_GADGET = 'REMOVE_RELAXATION_GADGET'
export const ADD_UPDATE_CUSTOM_RELAXATION_GADGET = 'ADD_UPDATE_CUSTOM_RELAXATION_GADGET'
export const ADD_UPDATE_EMOTIONOMETER = 'ADD_UPDATE_EMOTIONOMETER'
export const ADD_EMOTIONOMETER_STICKER = 'ADD_EMOTIONOMETER_STICKER'
export const REMOVE_EMOTIONOMETER_STICKER = 'REMOVE_EMOTIONOMETER_STICKER'
export const ADD_UPDATE_CUSTOM_STICKER = 'ADD_UPDATE_CUSTOM_STICKER'
export const ADD_CODE_CARD = 'ADD_CODE_CARD'
export const REMOVE_CODE_CARD = 'REMOVE_CODE_CARD'
export const ADD_UPDATE_CUSTOM_CODE_CARD = 'ADD_UPDATE_CUSTOM_CODE_CARD'

export interface UpdateStateAction extends BaseAction {
  type: typeof UPDATE_STATE
  state: State
}

export interface AddRelaxationGadgetAction extends BaseAction {
  type: typeof ADD_RELAXATION_GADGET
  gadgetId: Gadget['id']
}

export interface RemoveRelaxationGadgetAction extends BaseAction {
  type: typeof REMOVE_RELAXATION_GADGET
  gadgetId: Gadget['id']
}

export interface AddUpdateCustomRelaxationAction extends BaseAction {
  type: typeof ADD_UPDATE_CUSTOM_RELAXATION_GADGET
  gadget: Gadget
  addToCardHolder?: boolean
}

export interface AddUpdateEmotionometerAction extends BaseAction {
  type: typeof ADD_UPDATE_EMOTIONOMETER
  emotionometer: Emotionometer
}

export interface AddEmotionometerStickerAction extends BaseAction {
  type: typeof ADD_EMOTIONOMETER_STICKER
  emotionometerType: EmotionometerType
  emotionometerRange: EmotionometerRange
  emotionometerDevice: EmotionometerDevice
  sticker: EmotionometerSticker<this['emotionometerDevice']>
}

export interface RemoveEmotionometerStickerAction extends BaseAction {
  type: typeof REMOVE_EMOTIONOMETER_STICKER
  emotionometerType: EmotionometerType
  emotionometerDevice: EmotionometerDevice
  emotionometerRange: EmotionometerRange
  stickerId: EmotionometerStickerBase['id']
}

export interface AddUpdateCustomStickerAction extends BaseAction {
  type: typeof ADD_UPDATE_CUSTOM_STICKER
  emotionometerType: EmotionometerType
  emotionometerDevice: EmotionometerDevice
  emotionometerRange: EmotionometerRange
  sticker: EmotionometerSticker<this['emotionometerDevice']>
  disableAutoAdd?: boolean
}

export interface AddCodeCardAction extends BaseAction {
  type: typeof ADD_CODE_CARD
  codeCardId: CodeCard['id']
}

export interface RemoveCodeCardAction extends BaseAction {
  type: typeof REMOVE_CODE_CARD
  codeCardId: CodeCard['id']
}

export interface AddUpdateCustomCodeCardAction extends BaseAction {
  type: typeof ADD_UPDATE_CUSTOM_CODE_CARD
  codeCard: CodeCard
  addToCardHolder?: boolean
}

export type ActionTypes =
  | UpdateStateAction
  | AddRelaxationGadgetAction
  | RemoveRelaxationGadgetAction
  | AddUpdateCustomRelaxationAction
  | AddUpdateEmotionometerAction
  | AddEmotionometerStickerAction
  | RemoveEmotionometerStickerAction
  | AddUpdateCustomStickerAction
  | AddCodeCardAction
  | RemoveCodeCardAction
  | AddUpdateCustomCodeCardAction
