import React from 'react'
import { Cell, CellInner } from './Cell'
import { Column, H3, Row, Spacer } from 'common/ui'
import { AnchorButton } from 'common/LinkButton'
import styled from 'styled-components'

interface Props {
  title: string
  asset: any
  asset2?: any
  buttonLabel?: string
  button2Label?: string
}

export const ResourceCell: React.FC<Props> = ({ title, asset, asset2, buttonLabel, button2Label }) => {
  return (
    <Cell>
      <CellInner>
        <Column flex="1 1 auto">
          <H3>{title}</H3>
        </Column>
        <Row alignItems="center">
          {/* Anchor button doesn't become disabled if you pass the prop (bit weird) */}
          {!!asset && (
            <AnchorButton size="s" href={asset} download={title} children={buttonLabel ? buttonLabel : 'Download'} />
          )}
          {!asset && (
            <Disabler>
              <AnchorButton size="s" href={asset} download={title} children={buttonLabel ? buttonLabel : 'Download'} />
            </Disabler>
          )}
          {asset2 !== undefined && (
            <>
              <Spacer size="s" />
              {!!asset2 && (
                <AnchorButton
                  size="s"
                  href={asset2}
                  download={title}
                  children={button2Label ? button2Label : 'Download'}
                />
              )}
              {!asset2 && (
                <Disabler>
                  <AnchorButton
                    size="s"
                    href={asset2}
                    download={title}
                    children={button2Label ? button2Label : 'Download'}
                  />
                </Disabler>
              )}
            </>
          )}
        </Row>
      </CellInner>
    </Cell>
  )
}

const Disabler = styled.div`
  ${AnchorButton} {
    opacity: 0.7;
    pointer-events: none;
  }
`
