import React, { PropsWithChildren, ReactNode } from 'react'
import { Row, Column, Padding, H2, Button, ButtonProps, Spacer } from 'common/ui'
import { Hint } from 'common/Hint'

export const baseUrl = '/e-telligence'

interface Props {
  title?: string
  titleSize?: number
  hint?: string
  Buttons?: ReactNode
  buttonLabel?: string
  buttonSize?: ButtonProps['size']
  buttonPress?: () => void
  buttonProps?: Partial<PropsWithChildren<ButtonProps>>
}

export const Navigation: React.FC<Props> = ({
  title,
  titleSize,
  hint,
  Buttons,
  buttonLabel,
  buttonSize = 's',
  buttonPress,
  buttonProps,
}) => {
  return (
    <Row flex="none" style={{ borderBottom: '1px solid #abb4db' }}>
      <Padding size="xs">
        <Row style={{ width: '100%', alignItems: 'center' }}>
          <Row flex paddingLeft={10} paddingRight={10}>
            {title && <H2 style={{ wordBreak: 'break-word', fontSize: titleSize }}>{title}</H2>}
            <Spacer size="s" />
            {hint && <Hint text={hint} />}
          </Row>
          <Column>
            {Buttons
              ? Buttons
              : (buttonLabel || buttonProps) && (
                  <Button size={buttonSize} onClick={buttonPress} {...(buttonProps || {})}>
                    {buttonLabel}
                  </Button>
                )}
          </Column>
        </Row>
      </Padding>
    </Row>
  )
}
