import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { parse as parseQueryString } from 'qs'

import { Row, P, Button, Hr, Spacer, Form, Column, H2, Panel, Text } from 'common/ui'

import { fontHeavy, fontRegular } from 'fonts'
import { useMedia } from 'utils/useMedia'
import { useUserState } from 'app/UserState'
import { IntroTemplate } from './IntroTemplate'
import { login, GetProfile } from 'api'
import { getVideoPool } from 'utils/VideoPool'
import { useLocation, useHistory } from 'react-router'
import { getInputProps } from 'utils/virtualKeyboard'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import { isOnline } from 'utils/deviceUtils'
import { SHA1 } from 'utils/cryptoUtils'
import { getDomainFromEnv } from 'app/RequireDomain'

function errorMessageForCode(errorCode: string) {
  switch (errorCode) {
    case 'NO-PROFILE':
      return 'Your Game Play Code is incorrect. If you have forgotten your code please ask an adult to help.'
    case 'LICENCE-EXPIRED':
      return 'Your Game Play Code has expired.'
    case 'PRODUCT-KEY':
      return 'You have entered a Product Key. Please enter your 5-character Game Play Code. If you have not yet received a Game Play Code, please activate your Product Key below.'
    default:
      return 'An unexpected error occurred. Please try again.'
  }
}

export interface LoginPageProps {
  onLogin: (gameCode: string, accessToken: string, redirectUri?: string) => void
  onOfflineLogin: (profiles: GetProfile[], redirectUri?: string) => void
}

export const LoginPage: React.FC<LoginPageProps> = ({ onLogin, onOfflineLogin }) => {
  const { setAccessToken, setRefreshToken } = useUserState()
  const compact = useMedia('(max-width: 500px)')
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [busy, setBusy] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const queryParams: { redirect_uri?: string } = parseQueryString(location.search, { ignoreQueryPrefix: true })

  const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    setBusy(true)

    getVideoPool().preload()

    const hashedGameCode = SHA1(code)

    login(code, 'agent')
      .then(data => {
        ReactDOM.unstable_batchedUpdates(() => {
          setAccessToken(data.access_token)
          setRefreshToken(data.refresh_token)
          onLogin(hashedGameCode, data.access_token, queryParams.redirect_uri)
        })
      })
      .catch(err => {
        if (!isOnline()) {
          const hashedCodeProfilesJSON = localStorage.getItem(hashedGameCode)
          if (hashedCodeProfilesJSON) {
            try {
              const hashedCodeProfiles: GetProfile[] = JSON.parse(hashedCodeProfilesJSON)
              onOfflineLogin(hashedCodeProfiles, queryParams.redirect_uri)
            } catch (e) {
              ReactDOM.unstable_batchedUpdates(() => {
                setError('An error occurred while trying to enter offline mode')
                setBusy(false)
              })
            }
            return
          }
        }
        ReactDOM.unstable_batchedUpdates(() => {
          setError(errorMessageForCode(err.error))
          setBusy(false)
        })
      })
  }

  // extract gamePlayCode from querystring
  useEffect(() => {
    const matches = location.search.match(/gamePlayCode=([a-z0-9]+)/i)
    if (matches) {
      setCode(matches[1])
      history.replace(location.pathname)
    }
  }, [location.search, history, location.pathname])

  return (
    <IntroTemplate
      title="Play SAS Game"
      gameModeIndicator={true}
      afterPanel={
        <Column style={{ textAlign: 'center' }}>
          <Panel padding="l">
            <Row>
              <img src={require('./assets/image/shield.png')} alt="Shield" style={{ width: 60, height: 80 }} />
              <Spacer size="l" />
              <Text align="left" size="s">
                <strong>Adult Security Mission</strong> – To provide additional child data protection on this
                device/browser, adult authentication will be required to enter SAS Digital HQ. Enter your Game Play Code
                to commence the quick and easy steps.
              </Text>
            </Row>
          </Panel>
          <Spacer />

          <H2 style={{ color: 'white' }}>SAS Computer Game Pack</H2>
          <P style={{ color: 'white', fontSize: 14, margin: '5px 0' }}>
            Before you can play, your Product Key needs to be activated.
            <br />A Game Play Code will be created during activation.
          </P>
          <Row style={{ flexDirection: compact ? 'column' : undefined }}>
            <AnchorButton
              withChevron
              ghostMode
              href={`${process.env.REACT_APP_SST_BASE_URL}/activate`}
              target="_blank"
              flex="1 1 0px"
              size="s"
              style={{ textTransform: 'initial', textAlign: 'left', fontSize: 15 }}>
              Activate product key
            </AnchorButton>
            <Spacer />
            <AnchorButton
              withChevron
              ghostMode
              href={`${process.env.REACT_APP_SST_BASE_URL}/`}
              target="_blank"
              flex="1 1 0px"
              size="s"
              style={{ textTransform: 'initial', textAlign: 'left', fontSize: 15 }}>
              I do not have a product key
            </AnchorButton>
          </Row>

          <Spacer size="l" />

          <Row>
            <AnchorButton
              withChevron
              size="s"
              href={getDomainFromEnv('mentor')}
              flex="1 1 0px"
              style={{ textTransform: 'initial', textAlign: 'left', fontSize: 15 }}>
              SAS SMALL GROUP MENTOR LOGIN - Digital Edition
            </AnchorButton>
          </Row>

          <Spacer size="s" />

          <Row>
            <LinkButton
              withChevron
              ghostMode
              to="/mentor"
              flex="1 1 0px"
              size="s"
              style={{ textTransform: 'initial', textAlign: 'left', fontSize: 15 }}>
              MENTOR RESOURCES – When using physical SAS Family Kit
            </LinkButton>
          </Row>

          <Spacer size="l" />

          <Row style={{ flexDirection: compact ? 'column' : undefined }}>
            <LinkButton
              withChevron
              ghostMode
              to="/info"
              flex="1 1 0px"
              size="s"
              style={{ textAlign: 'left', fontSize: 15 }}>
              Information
            </LinkButton>
            <Spacer />
            <AnchorButton
              withChevron
              ghostMode
              href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}
              flex="1 1 0px"
              size="s"
              style={{ textAlign: 'left', fontSize: 15 }}>
              Technical Support
            </AnchorButton>
          </Row>
        </Column>
      }>
      <Hr margin="s" style={{ marginTop: -15 }} />
      <P align="left" style={{ margin: '5px 0' }}>
        Enter your Game Play Code:
      </P>
      <Form onSubmit={onSubmit}>
        <Row>
          <Column flex="1 1 auto">
            <TextInput
              name="gamePlayCode"
              error={!!error}
              readOnly={busy}
              autoFocus
              value={code}
              onChange={e => {
                setCode(e.target.value.replace(/[^A-Z0-9]/gi, ''))
                setError('')
              }}
              {...getInputProps()}
              style={{ padding: '6px 0' }}
            />
            {error && (
              <>
                <Spacer />
                <FormError>{error}</FormError>
              </>
            )}
          </Column>
          <Spacer flex="none" />
          <Column>
            <Button type="submit" disabled={busy || code === ''}>
              {busy ? 'Please wait...' : 'Play'}
            </Button>
          </Column>
        </Row>
      </Form>
    </IntroTemplate>
  )
}

const TextInput = styled.input<{ error?: boolean }>`
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  outline: none;

  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  border: none;
  border-top: 3px solid #d5d9ed;
  box-sizing: border-box;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  text-transform: uppercase;
  text-align: center;
  ${fontHeavy}
  font-size: 32px;
  line-height: 1;
  padding: 10px 0;
  letter-spacing: 2px;
  color: #001947;

  position: relative;

  ${p => (p.error ? ':enabled,' : '')} :focus {
    box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 0 0 3px ${p => (p.error ? 'red' : p.theme.buttonBorderTopColor)};
    border: 0;
    margin-top: 3px;
  }
`

const FormError = styled.div`
  ${fontRegular}
  color: #ab0303;
  padding: 10px;
  border-radius: 10px;
`
