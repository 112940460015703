import React from 'react'
import styled from 'styled-components'
import { CodeCard } from 'shared/e-telligence/types'
import { Spacer, OutlineButton } from 'common/ui'
import { fontBold } from 'fonts'

interface Props {
  codeCard?: CodeCard
  placeholder?: string
  active?: boolean
  locked?: boolean
  onEdit?: () => void
  onRemove?: () => void
}

export const CodeCardTile: React.FC<Props> = ({ codeCard, placeholder, active, locked, onEdit, onRemove }) => {
  return (
    <>
      <TileDiv
        empty={!codeCard}
        preset={codeCard?.isPreset}
        active={active}
        locked={locked}
        onClick={!locked ? onEdit : undefined}>
        {!codeCard ? (
          <figcaption>{placeholder || 'Add Skill Code Card +'}</figcaption>
        ) : codeCard.isPreset ? (
          <>
            <CoverImage style={{ backgroundImage: `url('${codeCard.cover}')` }} />
            {locked && <LockOverlay />}
            {locked && <LockIcon />}
          </>
        ) : (
          <>
            <CoverImage
              style={{ backgroundImage: `url('${require(`../assets/code-cards/CodeCard-Blank-white.png`)}')` }}
            />
            {/* {gadget.symbol && <CustomSymbol children={gadget.symbol} />} */}
            <CustomLabel>{codeCard.title}</CustomLabel>
          </>
        )}
      </TileDiv>
      {codeCard && onRemove && (
        <>
          <Spacer size="xs" />
          <OutlineButton size="xs" onClick={onRemove}>
            Delete
          </OutlineButton>
        </>
      )}
    </>
  )
}

const TileDiv = styled.div<{ empty?: boolean; preset?: boolean; active?: boolean; locked?: boolean }>`
  position: relative;
  height: 150px;
  width: 104px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${p => (p.locked ? 'default' : 'pointer')};
  border: 1px solid ${p => (p.active ? 'white' : p.empty ? p.theme.thinOutlineColor : p.preset ? 'white' : 'white')};
  border-radius: 5px;
  background-color: white;
  text-align: center;
  font-size: 0.8rem;

  & figcaption {
    max-width: 75%;
    font-size: inherit;
    line-height: 1.5;
    color: ${p => (p.empty ? p.theme.thinOutlineColor : 'inherit')};
  }
`
const CoverImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 5px;
`

const LockOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  background: ${p => p.theme.darkBg};
`

const LockIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: url(${require('common/assets/image/lock-no-shadow@2x.png')}) no-repeat center center / 23px 34px;
`

const CustomLabel = styled.figcaption`
  ${fontBold}
  font-size: 12px;
  color: white !important;
  z-index: 1;
`
