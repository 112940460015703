import React from 'react'
import { Skill } from 'skill-tracker/types'
import { Row, Column, Padding, Label, P, Slab, Spacer } from 'common/ui'
import { Emoji } from 'common/Emoji'
import { useMedia } from 'utils/useMedia'

export const SkillSlab: React.FC<{
  skill: Skill
  label?: string
  onEdit?: (skill: Skill) => void
  confirmed?: boolean
  Component?: typeof Slab
}> = ({ skill, label = 'Target Skill:', onEdit, confirmed, Component = Slab }) => {
  const portrait = useMedia('(orientation: portrait) and (max-width: 420px)')
  return (
    <Component
      flex="1 1 auto"
      onClick={onEdit ? () => onEdit(skill) : undefined}
      error={!skill.symbol || !skill.dailyTarget}
      disabled={confirmed === false || skill.confirmed === false}>
      <Padding size="s">
        <Row style={{ width: '100%' }} flexWrap={portrait ? true : undefined}>
          <Column flex={portrait ? '1 1 100%' : '1 1 auto'} paddingBottom={portrait ? 15 : 0}>
            <Label>{label}</Label>
            <P style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{skill.text}</P>
          </Column>
          {!portrait && <Spacer flex="none" size="xs" />}
          <Column flex={portrait ? '0 49%' : 'none'} style={{ width: 60 }}>
            <Label>Symbol:</Label>
            <P style={{ fontSize: 28, margin: 0 }}>{skill.symbol ? <Emoji children={skill.symbol} /> : '?'}</P>
          </Column>
          <Spacer flex="0 1 2%" size="xs" />
          <Column flex={portrait ? '0 1 49%' : 'none'} style={{ width: 75 }}>
            <Label>Daily Target:</Label>
            <P style={{ fontSize: 28, margin: 0 }}>{skill.dailyTarget || '?'}</P>
          </Column>
        </Row>
      </Padding>
    </Component>
  )
}
