import React, { useEffect, useState, useRef, CSSProperties } from 'react'
import ReactDOM from 'react-dom'
import { Game, Scene } from 'phaser'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

import { loaderAssets, sceneAssets, SceneAsset } from './constants/assets'
import SceneBuilderScene from './phaser/SceneBuilderScene'

import Modal from 'app/Modal'
// import ExitButton from 'common/ExitButton'
import { OverviewPanel } from 'common/OverviewPanel'
import { PromptModal } from 'common/PromptModal'
import { ConfirmModal } from 'common/ConfirmModal'
import { RouteLeavePrompt } from 'common/RouteLeavePrompt'
import { SVG, SVGProps } from 'common/SVG'
import {
  Padding,
  Row,
  Column,
  Panel,
  Spacer,
  P,
  Button,
  IconButton,
  Absolute,
  ButtonSize,
  buttonBorderRadius,
} from 'common/ui'

import {
  SPRITE_SELECTED,
  SPRITE_DESELECTED,
  BG_CHANGED,
  DRAG_STARTED,
  DRAG_ENDED,
  HIDE_TRAY,
  EDIT_TEXT,
  SCENE_CHANGED,
} from './constants/SceneBuilderEvents'
import { SCENE_INITED } from 'constants/events'
import { SCENE_LOADER_COMPLETE } from 'phaser/SceneLoader'

import { intersperse } from 'utils/intersperse'
import { generateGame, destroyGame } from 'utils/gameUtils'
import { getPixelRatio } from 'utils/screenUtils'
import { useGlobalState } from 'app/GlobalState'
import { usePhaserLoadingCallback } from 'utils/usePhaserLoadingCallback'
import { getChildren, visibleWithinParent } from 'utils/domUtils'
import { THEMES } from 'themes'
import AssetSprite from './phaser/AssetSprite'
import { dataURItoBlob } from 'utils/uriUtils'
import { useUserState } from 'app/UserState'
import { LoadingScreen } from 'app/LoadingScreen'
import { saveFile } from 'api'
import { ScreenScaler, useScreenScaler } from 'common/ScreenScaler'
import { useMeasure } from 'utils/useMeasure'
import { isChromium } from 'utils/deviceUtils'
import { useDraftData } from 'journal/common/useDraftData'
import { useSessionStorage } from 'utils/useStorage'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { SerializedScene, AssetCategory } from 'shared/scene-builder/types'

export const assetCategories: AssetCategory[] = ['scenes', 'objects', 'people', 'actions']
export const SCENE_GENERATOR_STORAGE_KEY = 'scene_generator'

type IconType =
  | 'arrow_left'
  | 'arrow_right'
  | 'delete'
  | 'backwards'
  | 'forwards'
  | 'flip_horizontal'
  | 'flip_vertical'
  | 'text_edit'

export function isEmptyScene(scene: SerializedScene): boolean {
  return scene.assets.length === 0 && !scene.backgroundKey
}

const getAssetsForCategory = (assetCategory: AssetCategory): SceneAsset[] => {
  const assetObjs: SceneAsset[] = []
  if (!(assetCategory in sceneAssets)) return assetObjs

  const assetKeys = Object.keys(sceneAssets[assetCategory])
  if (assetCategory === 'people') {
    assetKeys.forEach(personKey => {
      if ('neutral' in sceneAssets[assetCategory][personKey]) {
        assetObjs.push((sceneAssets[assetCategory][personKey] as { [k: string]: SceneAsset })['neutral'])
      }
    })
  } else {
    assetKeys.forEach(assetKey => {
      assetObjs.push(sceneAssets[assetCategory][assetKey] as SceneAsset)
    })
  }
  return assetObjs
}

const getEmotionAssetsForPerson = (spriteKey: string): SceneAsset[] => {
  const keys = spriteKey.split('_')
  if (!(keys[1] in sceneAssets['people'])) return []
  return Object.values(sceneAssets['people'][keys[1]] as { [k: string]: SceneAsset })
}

const getTileStates = <ChildType extends HTMLElement, ContainerType extends HTMLElement>(container: ContainerType) => {
  const tiles = getChildren(container, (element: ChildType) => 'tile' in element.dataset)
  return tiles
    .map((element, index) => ({
      index,
      element,
      isPrev: false,
      isNext: false,
      visible: visibleWithinParent(container, element),
    }))
    .map((state, index, states) => {
      if (index < states.length - 1 && !state.visible && states[index + 1].visible) state.isPrev = true
      if (index > 0 && !state.visible && states[index - 1].visible) state.isNext = true
      return state
    })
}

interface Props {
  sceneData?: SerializedScene
  onComplete: (sceneData: SerializedScene) => void
  standalone?: boolean
  draftStorageKey?: string
}

const SceneBuilder: React.FC<Props> = ({ onComplete, sceneData, standalone, draftStorageKey }) => {
  useDisableOrientationLock(false)
  const { openAccess, accessToken } = useUserState()
  const gameDivRef = useRef<HTMLDivElement | null>(null)
  const gameRef = useRef<Game | null>(null)
  const sceneRef = useRef<SceneBuilderScene | null>(null)
  const tileContainerRef = useRef<HTMLDivElement | null>(null)
  const tileTrayRef = useRef<HTMLDivElement | null>(null)
  const setLoadingPercent = usePhaserLoadingCallback()
  const [gameLoaded, setGameLoaded] = useState(false)
  const [loadedInitData, setLoadedInitData] = useState(false)
  const [trayVisible, setTrayVisible] = useState(true)
  const [emotionsVisible, setEmotionsVisible] = useState(false)
  const [assetCategory, setAssetCategory] = useState<AssetCategory | null>('scenes')
  const [spriteSelected, setSpriteSelected] = useState(false)
  const [spriteType, setSpriteType] = useState<AssetCategory | null>(null)
  const [spriteKey, setSpriteKey] = useState<string | null>(null)
  const [instructionsShown, setInstructionsShown] = useSessionStorage<boolean>('SceneGenerator_hideInstructions', false)
  const [draftData, setDraftData, clearDraftData] = useDraftData<SerializedScene>(
    SCENE_GENERATOR_STORAGE_KEY,
    draftStorageKey
  )
  const [helpModalVisible, setHelpModalVisible] = useState(() => {
    if (sceneData && sceneData.assets.length > 0) return false
    if (draftData && draftData.assets && draftData.assets.length > 0) return false
    if (instructionsShown) return false
    if (openAccess) return false
    setInstructionsShown(true)
    return true
  })
  const [promptModalVisible, setPromptModalVisible] = useState(false)
  const [promptLabel, setPromptLabel] = useState('')
  const [promptCharLimit, setPromptCharLimit] = useState(0)
  const [defaultPromptText, setDefaultPromptText] = useState('')
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [confirmLabel, setConfirmLabel] = useState('')
  const [sceneKey, setSceneKey] = useState('')
  const [dragging, setDragging] = useState(false)
  const [tileContainerScrollX, setTileContainerScrollX] = useState(0)
  const [showIndeterminateLoadingScreen, setShowIndeterminateLoadingScreen] = useState(false)
  const [showScrollButtons, setShowScrollButtons] = useState(true)
  const [tilesMeasureCallback] = useMeasure()
  const { themeIndex } = useGlobalState()
  const { zoom: screenZoom, scale: screenScale, offsetX: screenOffsetX } = useScreenScaler()

  const currentAssets = assetCategory ? getAssetsForCategory(assetCategory) : []
  const emotionAssets = emotionsVisible && spriteKey ? getEmotionAssetsForPerson(spriteKey) : []

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const scrollDiv = tileContainerRef.current
    const containerDiv = tileTrayRef.current
    if (!scrollDiv || !containerDiv) return
    // we add +1 to clientWidth since Edge calculates scrollWidth or clientWidth wrong
    const needScroll = scrollDiv.scrollWidth > containerDiv.clientWidth + 1
    if (!showScrollButtons && needScroll) {
      setShowScrollButtons(true)
    } else if (showScrollButtons && !needScroll) {
      setShowScrollButtons(false)
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    tilesMeasureCallback(tileContainerRef.current)
    return () => tilesMeasureCallback(null)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.screenScaler = screenZoom
      sceneRef.current.screenScalerZoom = screenZoom
      sceneRef.current.screenScalerOffsetX = screenOffsetX
    }
  }, [screenZoom, screenOffsetX])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let loaderScene: Scene
    let gameScene: SceneBuilderScene
    const handleSceneLoaded = () => setGameLoaded(true)
    const handleSpriteSelected = (spriteType: AssetCategory, spriteKey: string) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setSpriteSelected(true)
        setSpriteType(spriteType)
        setSpriteKey(spriteKey)
        setEmotionsVisible(spriteType === 'people')
      })
    }
    const handleSpriteDeselected = () => {
      ReactDOM.unstable_batchedUpdates(() => {
        setSpriteSelected(false)
        setEmotionsVisible(false)
        setSpriteType(null)
        setSpriteKey(null)
      })
    }
    const handleSceneInited = () => {
      if (sceneRef.current) sceneRef.current.redrawDepths()
    }
    const handleSceneChange = () => {
      if (sceneRef.current) setDraftData(sceneRef.current.getSerialized())
    }
    const handleBgChange = (key: string) => setSceneKey(key)
    const handleDragStart = () => setDragging(true)
    const handleDragEnd = () => setDragging(false)
    const handleHideTray = () => setTrayVisible(false)
    const game = generateGame(
      gameDivRef.current as HTMLElement,
      SceneBuilderScene,
      'SceneBuilderScene',
      loaderAssets,
      {
        backgroundColor: '#ffffff',
        scale: {
          mode: 0, // Phaser.Scale.NONE,
          width: 799 * getPixelRatio(),
          height: 463 * getPixelRatio(),
          zoom: 1 / getPixelRatio(),
        },
        callbacks: {
          postBoot: game => {
            loaderScene = game.scene.scenes[0] as Scene
            gameScene = game.scene.scenes[1] as SceneBuilderScene
            gameRef.current = game
            sceneRef.current = gameScene
            loaderScene.events.on(SCENE_LOADER_COMPLETE, handleSceneLoaded)
            gameScene.events.on(SPRITE_SELECTED, handleSpriteSelected)
            gameScene.events.on(SPRITE_DESELECTED, handleSpriteDeselected)
            gameScene.events.on(BG_CHANGED, handleBgChange)
            gameScene.events.on(DRAG_STARTED, handleDragStart)
            gameScene.events.on(DRAG_ENDED, handleDragEnd)
            gameScene.events.on(HIDE_TRAY, handleHideTray)
            gameScene.events.on(EDIT_TEXT, handleEditText)
            gameScene.events.on(SCENE_INITED, handleSceneInited)
            gameScene.events.on(SCENE_CHANGED, handleSceneChange)
            gameScene.updateTheme(THEMES[themeIndex])
            gameScene.screenScaler = screenZoom
            gameScene.screenScalerZoom = screenZoom
            gameScene.screenScalerOffsetX = screenOffsetX
          },
        },
      },
      setLoadingPercent
    )
    return () => {
      if (loaderScene) loaderScene.events.off(SCENE_LOADER_COMPLETE, handleSceneLoaded)
      if (gameScene) {
        gameScene.events.off(SPRITE_SELECTED, handleSpriteSelected)
        gameScene.events.off(SPRITE_DESELECTED, handleSpriteDeselected)
        gameScene.events.off(BG_CHANGED, handleBgChange)
        gameScene.events.off(DRAG_STARTED, handleDragStart)
        gameScene.events.off(DRAG_ENDED, handleDragEnd)
        gameScene.events.off(HIDE_TRAY, handleHideTray)
        gameScene.events.off(EDIT_TEXT, handleEditText)
        gameScene.events.off(SCENE_INITED, handleSceneInited)
        gameScene.events.off(SCENE_CHANGED, handleSceneChange)
        gameScene.children.removeAll()
        gameScene.destroy()
      }

      destroyGame(game)
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (sceneRef.current) sceneRef.current.updateTheme(THEMES[themeIndex])
  }, [themeIndex])

  useEffect(() => {
    if (!loadedInitData && gameLoaded && sceneRef.current) {
      if (sceneData || (draftData && draftData.assets)) {
        sceneRef.current.loadSerialized(sceneData || draftData)
      }
      setLoadedInitData(true)
    }
  }, [gameLoaded, sceneData, draftData, loadedInitData, setLoadedInitData])

  const handleAddAsset = (asset: SceneAsset) => {
    const category: AssetCategory | null = asset.category || assetCategory
    if (!sceneRef.current || category === null) return
    const sceneAsset = sceneRef.current.handleAddAsset(asset, category)
    if (sceneAsset instanceof AssetSprite) sceneRef.current.setActiveSprite(sceneAsset)
    if (asset.text) sceneRef.current.handleEditText(asset.text)
    if (category === 'actions' && !asset.text) handleEditText()
  }
  const handleAddAssetDrag = (asset: SceneAsset, e?: React.DragEvent<HTMLImageElement>) => {
    handleAddAsset(asset)
    if (e) e.preventDefault()
  }
  const handleMoveBackward = () => sceneRef.current && sceneRef.current.handleMoveBackward()
  const handleMoveForward = () => sceneRef.current && sceneRef.current.handleMoveForward()
  const handleDelete = () => sceneRef.current && sceneRef.current.handleDelete()
  const handleFlipH = () => sceneRef.current && sceneRef.current.handleFlipH()
  const handleFlipV = () => sceneRef.current && sceneRef.current.handleFlipV()
  const handleEmotionChange = (asset: SceneAsset) => {
    if (sceneRef.current) sceneRef.current.handleTextureSwap(asset)
    setSpriteKey(asset.key)
  }

  const changeAssetCategory = (category: AssetCategory) => {
    if (sceneRef.current) sceneRef.current.setActiveSprite(null)
    if (assetCategory === category && !emotionsVisible) setTrayVisible(!trayVisible)
    else {
      setAssetCategory(category)
      if (!trayVisible) setTrayVisible(true)
    }
  }

  const clearScene = () => {
    if (sceneRef.current) sceneRef.current.handleClearScene()
  }

  const handleEditText = () => {
    if (!sceneRef.current) return
    const { selectedSprite } = sceneRef.current.state
    if (!selectedSprite) return
    const existingText = selectedSprite.text || ''
    const spriteKey = selectedSprite.texture.key
    let charLimit = 0
    let promptText = 'Type your text:'
    if (spriteKey.indexOf('thought') >= 0) {
      promptText = 'Type thought:'
      charLimit = 88
    } else if (spriteKey.indexOf('said') >= 0) {
      promptText = 'Type speech:'
      charLimit = 130
    } else if (spriteKey.indexOf('actions') >= 0) {
      promptText = 'Type feelings:'
      charLimit = 24
    }
    sceneRef.current.disableInteraction = true
    ReactDOM.unstable_batchedUpdates(() => {
      setPromptLabel(promptText)
      setDefaultPromptText(existingText.replace('- ', ''))
      setPromptCharLimit(charLimit)
      setPromptModalVisible(true)
    })
  }

  const handleTextSubmitted = (value: string | null) => {
    if (sceneRef.current) {
      if (value !== null) {
        const string = value
          .split(' ')
          .map(word => {
            if (word.length > 20) {
              const splitAmount = Math.floor(word.length / 20)
              let newWord = ''
              for (let i = 0; i < splitAmount; i++) newWord += word.substr(i * 20, 20) + '- '
              newWord += word.substr(20 * splitAmount, word.length - 20 * splitAmount)
              return newWord
            }
            return word
          })
          .join(' ')
        sceneRef.current.handleEditText(string)
      }
      sceneRef.current.disableInteraction = false
    }
    setPromptModalVisible(false)
  }

  const handleClearConfirm = () => {
    if (!sceneRef.current) return
    sceneRef.current.disableInteraction = true
    ReactDOM.unstable_batchedUpdates(() => {
      setConfirmType('clear')
      setConfirmLabel('Are you sure you want to clear the scene?')
      setConfirmModalVisible(true)
    })
  }

  const handleConfirmResponse = (confirmed: boolean) => {
    if (!sceneRef.current) return
    if (confirmType === 'clear') {
      if (confirmed) {
        clearDraftData()
        clearScene()
      }
    } else if (confirmType === 'quit') {
      if (confirmed) saveAndQuit()
    }
    setConfirmModalVisible(false)
    sceneRef.current.disableInteraction = false
  }

  const goToTile = (direction: number) => {
    if (!tileContainerRef.current || direction === 0) return
    const containerElement = tileContainerRef.current
    const tileStates = getTileStates(containerElement)
    let nextTile = tileStates.find(({ isNext, isPrev }) => (direction < 0 ? isPrev : isNext))
    if (!nextTile) return
    const nextTileIndex = tileStates.indexOf(nextTile)
    const aimTileIndex =
      direction < 0
        ? Math.max(0, nextTileIndex + direction + 1)
        : Math.min(tileStates.length - 1, nextTileIndex + direction - 1)
    nextTile = tileStates[aimTileIndex]

    const containerScroll = containerElement.scrollLeft
    const containerRect = containerElement.getBoundingClientRect()
    const elementRect = nextTile.element.getBoundingClientRect()

    const absoluteElementX = elementRect.left - containerRect.left + containerScroll
    const relativeNewTileX = direction < 0 ? 0 : containerRect.width - elementRect.width

    setTileContainerScrollX(absoluteElementX - relativeNewTileX)
  }

  function saveAndQuit() {
    if (!sceneRef.current) return
    const serialized = sceneRef.current.getSerialized()
    if (isEmptyScene(serialized) || standalone) {
      clearDraftData()
      onComplete(serialized)
    } else {
      sceneRef.current.screenshot().then(src => {
        if (accessToken) {
          setShowIndeterminateLoadingScreen(true)
          const blob = dataURItoBlob(src)
          saveFile(blob, accessToken)
            .then(upload => {
              setShowIndeterminateLoadingScreen(false)
              clearDraftData()
              onComplete({ ...serialized, thumbnail: upload.url })
            })
            .catch(err => {
              setShowIndeterminateLoadingScreen(false)
            })
        } else {
          clearDraftData()
          onComplete({ ...serialized, thumbnail: src })
        }
      })
    }
  }

  function handleQuitConfirm() {
    if (standalone) {
      ReactDOM.unstable_batchedUpdates(() => {
        setConfirmType('quit')
        setConfirmLabel(
          'You will not be able to come back to this scene if you leave now. \nAre you sure you want to quit?'
        )
        setConfirmModalVisible(true)
      })
    } else {
      saveAndQuit()
    }
  }

  const toolbarHeight = screenScale * 90
  const canvasHeight = window.innerHeight - toolbarHeight
  const canvasWidth = canvasHeight * (1598 / 926)

  return (
    <>
      <RouteLeavePrompt
        when={() => (sceneRef.current ? sceneRef.current.sprites.length > 0 : true)}
        message={`Are you sure you want to navigate away?\nYou will lose your current scene.`}
      />
      <Absolute cover>
        <ScreenScaler
          maxRatio={
            window.innerHeight < 420 && isChromium() ? canvasWidth / (canvasHeight + toolbarHeight) : undefined
          }>
          <Column flex>
            <Column flex style={{ overflow: 'hidden' }}>
              <Absolute cover id="APP_CONTAINER">
                <div ref={gameDivRef} />
              </Absolute>
              <Absolute top={20} right={20}>
                <Button shadowColor="black" onClick={handleQuitConfirm}>
                  {standalone ? 'Exit' : 'Save and Exit'}
                </Button>
              </Absolute>
              <ActionMenu inactive={dragging} hidden={!spriteSelected}>
                <Padding size="s">
                  <Column flex>
                    {spriteType === 'actions' && (
                      <>
                        <SVGButtonWithLabel label="Edit Text" svgBody={editTextIconMarkup} onClick={handleEditText} />
                        <Spacer size="xs" />
                      </>
                    )}
                    <SVGButtonWithLabel label="Delete" svgBody={deleteIconMarkup} onClick={handleDelete} />
                    <Spacer size="xs" />
                    <SVGButtonWithLabel label="Move Forward" svgBody={forwardsIconMarkup} onClick={handleMoveForward} />
                    <Spacer size="xs" />
                    <SVGButtonWithLabel
                      label="Move Backward"
                      svgBody={backwardsIconMarkup}
                      onClick={handleMoveBackward}
                    />
                    <Spacer size="xs" />
                    <SVGButtonWithLabel
                      label="Flip Horizontally"
                      svgBody={flipHorizontalIconMarkup}
                      onClick={handleFlipH}
                    />
                    <Spacer size="xs" />
                    <SVGButtonWithLabel
                      label="Flip Vertically"
                      svgBody={flipVerticalIconMarkup}
                      onClick={handleFlipV}
                    />
                  </Column>
                </Padding>
              </ActionMenu>
              <Tray visible={trayVisible || emotionsVisible}>
                {(trayVisible || emotionsVisible) && (
                  <CloseButton
                    bottom="100%"
                    right="0"
                    onClick={() => {
                      setTrayVisible(false)
                      setEmotionsVisible(false)
                    }}>
                    ×
                  </CloseButton>
                )}

                <Padding>
                  <Row ref={tileTrayRef} flex>
                    {showScrollButtons && (
                      <Column flex="1 1 75px" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <IconButton size="s" onClick={() => goToTile(-4)}>
                          <SVG size={42} children={arrowLeftIconMarkup} />
                        </IconButton>
                      </Column>
                    )}
                    <Column>
                      <TileContainer
                        ref={tileContainerRef}
                        scrollLeft={tileContainerScrollX}
                        screenZoom={screenZoom}
                        screenOffsetX={screenOffsetX}
                        hasArrows={showScrollButtons}>
                        {emotionsVisible &&
                          emotionAssets
                            .map((asset, i) => (
                              <TileWithLabel key={asset.key} data-tile>
                                <Tile
                                  selected={assetCategory === 'people' && asset.key === spriteKey}
                                  onClick={() => handleEmotionChange(asset)}>
                                  <TileInner>
                                    <TileImage src={asset.thumbnail} sizing={'contain'} zoomTop />
                                  </TileInner>
                                </Tile>
                                <AssetLabel>{asset.label}</AssetLabel>
                              </TileWithLabel>
                            ))
                            .reduce(
                              intersperse(i => <Spacer key={i} size="m" style={{ display: 'inline-block' }} />),
                              []
                            )}
                        {!emotionsVisible &&
                          currentAssets
                            .map((asset, i) => (
                              <TileWithLabel key={asset.key} data-tile>
                                <Tile
                                  selected={assetCategory === 'scenes' && asset.key === sceneKey}
                                  onClick={() => handleAddAsset(asset)}>
                                  <TileInner>
                                    <TileImage
                                      src={asset.thumbnail}
                                      sizing={assetCategory === 'scenes' ? 'cover' : 'contain'}
                                      onDragStart={e => handleAddAssetDrag(asset, e)}
                                    />
                                  </TileInner>
                                </Tile>
                                {assetCategory !== 'people' && <AssetLabel>{asset.label}</AssetLabel>}
                              </TileWithLabel>
                            ))
                            .reduce(
                              intersperse(i => <Spacer key={i} size="m" style={{ display: 'inline-block' }} />),
                              []
                            )}
                      </TileContainer>
                    </Column>
                    {showScrollButtons && (
                      <Column flex="1 1 75px" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <IconButton size="s" onClick={() => goToTile(4)}>
                          <SVG size={42} children={arrowRightIconMarkup} />
                        </IconButton>
                      </Column>
                    )}
                  </Row>
                </Padding>
              </Tray>
            </Column>
            <BottomUi>
              <Padding size="m">
                <Row>
                  {assetCategories
                    .map(category => (
                      <Button
                        key={category}
                        flex
                        pressed={assetCategory === category && !emotionsVisible}
                        onClick={() => changeAssetCategory(category)}>
                        {category === 'scenes' ? 'Scene' : category}
                      </Button>
                    ))
                    .reduce(
                      intersperse(i => <Spacer key={i} size="m" />),
                      []
                    )}
                  <Column flex="1 1 20%" />
                  <Button flex onClick={handleClearConfirm}>
                    Clear
                  </Button>
                  <Spacer />
                  <Button flex onClick={() => setHelpModalVisible(true)}>
                    Help
                  </Button>
                </Row>
              </Padding>
            </BottomUi>
          </Column>

          {showIndeterminateLoadingScreen && (
            <Absolute cover>
              <LoadingScreen />
            </Absolute>
          )}

          {gameLoaded && helpModalVisible && (
            <Modal isOpen onRequestClose={() => setHelpModalVisible(false)}>
              <OverviewPanel
                title="Scene Generator"
                onContinue={() => setHelpModalVisible(false)}
                shadowColor="black"
                flex="0 0 520px"
                usingScreenScaler>
                <P>Choose scenes, people, objects and actions.</P>
                <Panel style={{ backgroundColor: 'white', boxShadow: 'none' }}>
                  <Padding size="s">
                    <Row flex style={{ transform: 'scale(0.8)', pointerEvents: 'none' }}>
                      <Button size="s" role="presentation">
                        Scene
                      </Button>
                      <Spacer size="s" />
                      <Button size="s" role="presentation">
                        Objects
                      </Button>
                      <Spacer size="s" />
                      <Button size="s" role="presentation">
                        People
                      </Button>
                      <Spacer size="s" />
                      <Button size="s" role="presentation">
                        Actions
                      </Button>
                    </Row>
                  </Padding>
                </Panel>
                <P>Click on an item to select it and use the menu to edit it.</P>
                <Panel style={{ backgroundColor: 'white', boxShadow: 'none' }}>
                  <Padding size="s">
                    <Row flex style={{ pointerEvents: 'none' }}>
                      <SVGButtonWithLabel label="Delete" svgBody={deleteIconMarkup} style={{ flex: '1 1 0px' }} />
                      <Spacer size="s" />
                      <SVGButtonWithLabel
                        label="Move Forward"
                        svgBody={forwardsIconMarkup}
                        style={{ flex: '1 1 0px' }}
                      />
                      <Spacer size="s" />
                      <SVGButtonWithLabel
                        label="Move Backward"
                        svgBody={backwardsIconMarkup}
                        style={{ flex: '1 1 0px' }}
                      />
                    </Row>
                    <Spacer size="s" />
                    <Row flex style={{ pointerEvents: 'none' }}>
                      <SVGButtonWithLabel
                        label="Flip Vertically"
                        svgBody={flipVerticalIconMarkup}
                        style={{ flex: '1 1 0px' }}
                      />
                      <Spacer size="s" />
                      <SVGButtonWithLabel
                        label="Flip Horizontally"
                        svgBody={flipHorizontalIconMarkup}
                        style={{ flex: '1 1 0px' }}
                      />
                      <Spacer size="s" />
                      <SVGButtonWithLabel label="Edit Text" svgBody={editTextIconMarkup} style={{ flex: '1 1 0px' }} />
                    </Row>
                  </Padding>
                </Panel>
              </OverviewPanel>
            </Modal>
          )}
          {gameLoaded && (
            <PromptModal
              isOpen={promptModalVisible}
              label={promptLabel}
              multiline={promptCharLimit > 35}
              defaultValue={defaultPromptText}
              charLimit={promptCharLimit}
              onSubmit={handleTextSubmitted}
            />
          )}
          {gameLoaded && (
            <ConfirmModal
              isOpen={confirmModalVisible}
              label={confirmLabel}
              onSubmit={handleConfirmResponse}
              width={370}
            />
          )}
        </ScreenScaler>
      </Absolute>
    </>
  )
}

export default SceneBuilder

const arrowLeftIconMarkup = <polygon points="18.56 19.6 11.44 15 18.56 10.4 18.56 19.6" fill="#fff" />
const arrowRightIconMarkup = <polygon points="11.44 19.6 18.56 15 11.44 10.4 11.44 19.6" fill="#fff" />
const deleteIconMarkup = (
  <>
    <polygon
      points="20.98 23.53 9.02 23.53 7.72 11.02 22.27 11.02 20.98 23.53"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="10"
    />
    <line x1="16.7" y1="12.73" x2="16.7" y2="21.65" fill="none" stroke="#fff" strokeMiterlimit="10" />
    <line x1="13.3" y1="12.73" x2="13.3" y2="21.65" fill="none" stroke="#fff" strokeMiterlimit="10" />
    <line x1="6.84" y1="8.75" x2="23.16" y2="8.75" fill="none" stroke="#fff" strokeMiterlimit="10" />
    <polyline points="18.5 8.67 18.5 6.47 11.5 6.47 11.5 8.67" fill="none" stroke="#fff" strokeMiterlimit="10" />
  </>
)
const backwardsIconMarkup = (
  <>
    <polygon points="11.28 11.28 18.72 11.28 18.72 8.08 8.08 8.08 8.08 18.72 11.28 18.72 11.28 11.28" fill="#fff" />
    <path d="M20.92,12.28v8.64H12.28V12.28h8.64m1-1H11.28V21.92H21.92V11.28Z" fill="#fff" />
  </>
)
const forwardsIconMarkup = (
  <>
    <rect x="11.28" y="11.28" width="10.64" height="10.64" fill="#fff" />
    <path d="M17.72,9.08v2.15l-.65,6.49h-8V9.08h8.64m1-1H8.08V18.72H18l.74-7.44V8.08Z" fill="#fff" />
  </>
)
const flipHorizontalIconMarkup = (
  <>
    <polygon points="24.55 19.43 17.43 14.83 24.55 10.23 24.55 19.43" fill="#fff" />
    <path d="M6.45,12.07l4.27,2.76L6.45,17.6V12.07m-1-1.84v9.2l7.12-4.6-7.12-4.6Z" fill="#fff" />
    <line x1="15" y1="25.67" x2="15" y2="4.33" fill="none" stroke="#fff" strokeMiterlimit="10" strokeDasharray="5 3" />
  </>
)
const flipVerticalIconMarkup = (
  <>
    <polygon points="10.69 24.55 15.29 17.43 19.89 24.55 10.69 24.55" fill="#fff" />
    <path d="M18.05,6.45l-2.76,4.27L12.53,6.45h5.52m1.84-1h-9.2l4.6,7.12,4.6-7.12Z" fill="#fff" />
    <line x1="4.83" y1="15" x2="25.17" y2="15" fill="none" stroke="#fff" strokeMiterlimit="10" strokeDasharray="5 3" />
  </>
)
const editTextIconMarkup = (
  <>
    <line x1="15" y1="9.5" x2="15" y2="20.5" fill="none" stroke="#fff" strokeMiterlimit="10" />
    <rect x="11.75" y="9" width="6.5" height="1" fill="#fff" />
    <rect x="11.75" y="20" width="6.5" height="1" fill="#fff" />
  </>
)

const SVGButtonWithLabel: React.FC<{
  name?: IconType
  label: string
  onClick?: () => void
  size?: ButtonSize
  svgBody?: JSX.Element
  svgProps?: Partial<SVGProps>
  style?: CSSProperties
}> = ({ name, label, onClick = undefined, size = 's', svgBody, svgProps = {}, style = {} }) => {
  return (
    <SVGButtonWithLabelWrapper onClick={onClick} style={style}>
      <IconButton size={size}>
        <SVG {...svgProps} children={svgBody ? svgBody : undefined} />
      </IconButton>
      <Spacer size="s" />
      <label style={{ fontSize: 14, cursor: 'pointer' }}>{label}</label>
    </SVGButtonWithLabelWrapper>
  )
}

const SVGButtonWithLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  border-radius: ${buttonBorderRadius.s};

  :hover,
  :focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const ActionMenu: React.FC<{ inactive?: boolean; hidden?: boolean }> = ({
  inactive = false,
  hidden = false,
  children,
}) => {
  const style = useSpring({
    config: { tension: 440, clamp: true },
    position: 'absolute',
    top: 20,
    left: 20,
    width: 200,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: 10,
    opacity: hidden ? 0 : inactive ? 0.1 : 1,
    pointerEvents: inactive || hidden ? 'none' : 'auto',
  })
  return (
    <animated.div
      style={style}
      onTouchStart={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
      onMouseUp={e => e.stopPropagation()}>
      {children}
    </animated.div>
  )
}

const Tray: React.FC<{ visible?: boolean }> = ({ visible = true, children }) => {
  const style = useSpring({
    config: { tension: 220, clamp: true },
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 180,
    backgroundColor: 'rgba(237, 242, 250, 0.85)',
    transform: `translateY(${visible ? '0' : '100'}%)`,
  })
  return (
    <animated.div
      style={style}
      onTouchStart={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
      onMouseUp={e => e.stopPropagation()}>
      {children}
    </animated.div>
  )
}

const CloseButton = styled(Absolute)`
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  font-weight: 100;
  line-height: 0.85;
  text-align: center;
  background-color: rgba(237, 242, 250, 0.85);
  color: ${p => p.theme.buttonBorderTopColor};
  cursor: pointer;
`

const TileContainer: React.FC<{
  scrollLeft: number
  ref: React.Ref<HTMLDivElement>
  screenZoom: number
  screenOffsetX: number
  hasArrows: boolean
}> = React.forwardRef(({ children, scrollLeft = 0, screenZoom, screenOffsetX, hasArrows }, ref) => {
  const spring = useSpring({
    config: { tension: 210, friction: 20 },
    scroll: scrollLeft,
  })
  const TileScroll = (TileScroller as unknown) as any
  return (
    <TileScroll
      ref={ref}
      scrollLeft={spring.scroll}
      screenZoom={screenZoom}
      screenOffsetX={screenOffsetX}
      hasArrows={hasArrows}>
      {children}
    </TileScroll>
  )
})

const TileScrollerDiv = React.forwardRef(({ screenZoom, screenOffsetX, hasArrows, ...props }: any, ref) => (
  <animated.div ref={ref} {...props} />
))

interface TileScrollerProps {
  screenZoom: number
  screenOffsetX: number
  hasArrows: boolean
}

const TileScroller = styled(TileScrollerDiv)<TileScrollerProps>`
  flex: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: calc(
    ${p => Math.floor(100 * p.screenZoom)}vw - ${p => Math.ceil((p.hasArrows ? 150 : 30) + p.screenOffsetX * 2)}px
  );

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  touch-action: manipulation;
  -ms-touch-action: manipulation; /* IE10  */

  /* WebKit */
  ::-webkit-scrollbar {
    width: 0px;
  }
`

const TileWithLabel = styled.div`
  position: relative;
  display: inline-block;
`

const Tile = styled.div<{ width?: string; height?: string; selected?: boolean }>`
  position: relative;
  width: ${p => p.width || '160px'};
  height: ${p => p.height || '128px'};
  background-color: ${p => (p.selected ? p.theme.buttonBorderTopColor : 'white')};
  border-radius: ${p => p.theme.borderRadius};
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`

const TileInner = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border-radius: calc(${p => p.theme.borderRadius} * 0.75);
  color: white;
  background-color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const TileImage = styled.img<{ sizing: 'contain' | 'cover'; zoomTop?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${p => p.sizing};
  font-family: 'object-fit: ${p => p.sizing};';
  transform-origin: 50% 0%;
  ${p => (p.zoomTop ? 'transform: scale(2.5);' : '')}
`

const AssetLabel = styled.div`
  margin-top: 10px;
  text-align: center;
  color: black;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
`

const BottomUi = styled(Column)`
  background-color: #edf2fa;
  border-top: 1px solid #abb4db;
`
