import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import _throttle from 'lodash/throttle'

import {
  HOLDING_SLIDE,
  MODULE_SLIDE,
  SUPPLEMENTARY_SLIDE,
  END_SLIDE,
  HydratedModuleSlideRef,
  HydratedSlide,
  SessionModule,
  SupplementarySlide,
} from 'shared/session/types'
import { MODULE_EVENT } from 'shared/progressionEvents'

import {
  Container,
  Panel,
  H3,
  H2,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  GRILL_TL_S,
  GRILL_TR_S,
  GRILL_BL_S,
  GRILL_BR_S,
  Row,
  Column,
  Padding,
} from 'common/ui'
import { CorkSection, PanelTitle, SectionsPanel, SectionsPanelStyled } from './common/SectionsPanel'
import { TokenNotification } from './TokenNotification'
import { StepThemeContainer } from './ThemeContainer'
import { HighlightArea } from './common/HighlightableArea'
import { SessionSplash } from './SessionSplash'
import { SupplementarySlideContainer } from './SupplementarySlides'
import { RichTextContainer } from './common/RichText'
import { ListContainer } from './sections/List'
import { CircleButton } from './common/CircleButton'
import { SectionTitle } from './common/SectionTitle'
import { AnchorButton } from 'common/LinkButton'

import { getSlideByUid } from './reducers'
import { useSessionState } from './SessionState'
import { useFacilitatorState, useFocusedParticipantState } from './hooks/useProfileState'
import { useSavedInputValues } from './InputContext'
import { FacilitatorChecklistOverview } from './FacilitatorChecklist'
import { AutoAddProgressionEvent } from './ProgressionEventsState'
import { baseUrl as mentorDashboardBaseUrl } from 'dashboards/mentor/MentorDashboard'
import { UPDATE_FACILITATOR_SLIDE_SCROLL } from 'shared/session/actionTypes'
import { getSlideUid } from './utils/slideUtils'
// import { useDisableGadgetPackSaveDebouncing } from 'gadget-pack/GadgetPackState'

export interface SlideProps {
  slide: HydratedSlide
}

export const SessionSlideHandler: React.FC = () => {
  const { sessionData, state, previewMode, previewState, presentationMode } = useSessionState()
  const participantState = useFocusedParticipantState()
  const facilitatorState = useFacilitatorState()

  const supplementarySlides = useSavedInputValues<SupplementarySlide>({
    participant_uid: 'shared',
    owner: 'supplementary_slides',
    owner_id: null,
    name: undefined,
  })

  const realSlideUid = getSlideUid(facilitatorState, participantState, presentationMode)

  // TODO: Better messaging
  if (!sessionData && previewMode) return <h1 style={{ marginTop: 150 }}>[Preview Mode] No sessionData received yet</h1>
  // TODO: Better messaging
  if (!previewMode && !state.sessionUid) return <h2 style={{ marginTop: 150 }}>Session UID not established yet...</h2>

  const slide: HydratedSlide = !state.sessionActive
    ? { type: HOLDING_SLIDE, order: -1 }
    : realSlideUid === 'SESSION_END'
      ? { type: END_SLIDE, order: -1, uid: 'SESSION_END' }
      : (previewMode && sessionData
          ? sessionData.module?.steps?.[previewState.stepIndex]
            ? ({
                type: MODULE_SLIDE,
                uid: sessionData.module.steps[previewState.stepIndex].uid,
                order: previewState.stepIndex,
                step: sessionData.module.steps[previewState.stepIndex],
              } as HydratedModuleSlideRef)
            : undefined
          : getSlideByUid(sessionData, state, supplementarySlides, realSlideUid)) || { type: HOLDING_SLIDE, order: -1 }

  return <SessionSlideTransitionHandler slide={slide} SlideComponent={SessionSlide} />
}

export const SessionSlideTransitionHandler: React.FC<{
  slide: HydratedSlide
  SlideComponent: React.FC<SlideProps>
}> = ({ slide, SlideComponent }) => {
  const participantState = useFocusedParticipantState()
  const facilitatorState = useFacilitatorState()
  const { previewing, presentationMode, pastMode } = useSessionState()
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const previousSlide = useRef(slide)
  const previousSlideUid = useRef<string | null>(null)

  const [sectionHeight, setSectionHeight] = useState<number>(window.innerHeight)

  // useDisableGadgetPackSaveDebouncing()

  useEffect(() => {
    window.addEventListener('resize', () => {
      setSectionHeight(window.innerHeight)
    })
  }, [])

  useEffect(() => {
    if (previewing) return
    const realSlideUid = getSlideUid(facilitatorState, participantState, presentationMode)

    let slideTimeout: number = 0
    let animationTimeout: number = 0
    if (!isMounted) {
      animationTimeout = setTimeout(() => {
        setIsMounted(true)
      }, 500)
    }

    if (
      realSlideUid !== previousSlideUid.current || // if slide uid has changed since last render
      previousSlideUid.current === null // force transition if previous slide was not set / was holding slide
      // || (realSlideUid === previousSlideUid.current && slide.type === SUPPLEMENTARY_SLIDE) // if facilitator changes supplementary slide type then it needs to force re-render
    ) {
      setIsMounted(false)

      slideTimeout = setTimeout(() => {
        previousSlide.current = slide
        previousSlideUid.current = realSlideUid
      }, 300)

      if (animationTimeout) clearTimeout(animationTimeout)
      animationTimeout = setTimeout(() => {
        setIsMounted(true)
      }, 500)
    }
    return () => {
      if (slideTimeout) clearTimeout(slideTimeout)
      if (animationTimeout) clearTimeout(animationTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide, slide.type, slide.type !== HOLDING_SLIDE && slide.type !== END_SLIDE ? slide.uid : true])

  const disableTransitions = previewing === true || slide.type === SUPPLEMENTARY_SLIDE

  return (
    <DelayedWrapper
      delay={500}
      isMounted={disableTransitions ? true : isMounted}
      inPreview={previewing}
      inPastMode={pastMode}
      pageHeight={sectionHeight}>
      <SlideComponent slide={disableTransitions ? slide : previousSlide.current} />
    </DelayedWrapper>
  )
}

const SessionSlide: React.FC<SlideProps> = ({ slide }) => {
  const facilitatorState = useFacilitatorState()
  const { isFacilitator, sessionData, presentationMode, dispatch, getBaseAction, sessionProfile, pastMode } =
    useSessionState()
  const showHeaderPanel =
    (!isFacilitator || presentationMode) &&
    (slide.type === MODULE_SLIDE ? !slide.step.hide_header : slide.type !== SUPPLEMENTARY_SLIDE ? true : false)
  const singlePanelMode = slide.type === 'MODULE_SLIDE' ? slide.step.single_panel : false

  const [scrollContainerRef, setScrollContainerRef] = useState<HTMLDivElement | null>(null)
  const lastChange = useRef(Date.now())

  // Handles binding/unbinding of (throttled) scroll event whenever container ref or slide changes
  useEffect(() => {
    const handleScroll = _throttle(
      (e: Event) => {
        if (scrollContainerRef) {
          const slideKey = `${facilitatorState && facilitatorState.focusedParticipantUid ? '' : 'fac_'}${
            (slide as HydratedModuleSlideRef).uid
          }`
          dispatch({
            ...getBaseAction(),
            type: UPDATE_FACILITATOR_SLIDE_SCROLL,
            facilitatorUid: sessionProfile.uid,
            slideUid: slideKey,
            scroll: scrollContainerRef.scrollTop,
          })
        }
      },
      750,
      { leading: false, trailing: true }
    )
    const handlePreScroll = (e: Event) => {
      if (Date.now() - lastChange.current > 750) handleScroll(e)
    }
    if (scrollContainerRef) scrollContainerRef.addEventListener('scroll', handlePreScroll)
    return () => {
      if (scrollContainerRef) scrollContainerRef.removeEventListener('scroll', handlePreScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollContainerRef, slide, facilitatorState ? facilitatorState.focusedParticipantUid : false])

  useEffect(() => {
    // console.log('---slide change---')
    lastChange.current = Date.now()
    if (facilitatorState && scrollContainerRef) {
      if (facilitatorState && (slide.type === 'MODULE_SLIDE' || slide.type === 'SUPPLEMENTARY_SLIDE')) {
        const slideKey = `${facilitatorState.focusedParticipantUid ? '' : 'fac_'}${slide.uid}`
        const scrollPosition = facilitatorState.slideScrollPositions[slideKey]
        if (scrollPosition) console.log('restoring scroll position', scrollPosition)
        scrollContainerRef.scrollTo(0, scrollPosition || 0)
        setTimeout(() => scrollContainerRef.scrollTo(0, scrollPosition || 0), 250) // Drawing section why do you do this to me?
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ['uid' in slide ? slide.uid : false, slide.type, facilitatorState ? facilitatorState.focusedParticipantUid : false]) // prettier-ignore

  const supplementarySlides = useSavedInputValues<SupplementarySlide>({
    participant_uid: 'shared',
    owner: 'supplementary_slides',
    owner_id: null,
    name: undefined,
  })

  // Reset participant scroll position on slide change
  useEffect(() => {
    if (!isFacilitator) document.body.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide])

  const supplementarySlide =
    slide.type === SUPPLEMENTARY_SLIDE ? supplementarySlides.find(({ uid }) => uid === slide.uid) : undefined

  if (!sessionData) {
    return (
      <StepThemeContainer module={undefined} slide={slide}>
        <SessionSplash text="Please Wait" />
      </StepThemeContainer>
    )
  }
  if (slide.type === HOLDING_SLIDE) {
    return (
      <>
        <StepThemeContainer module={sessionData.module} slide={slide}>
          {isFacilitator && <FacilitatorChecklistOverview readOnly={pastMode} type={slide.type} />}
          {!isFacilitator && <SessionSplash text="Please Wait" />}
        </StepThemeContainer>
        <AutoAddProgressionEvent
          progressionEvent={{
            type: MODULE_EVENT,
            category: 'started',
            subcategory: `${sessionData.type}-${sessionData.module_code}`,
            event_key: null,
            event_value: null,
          }}
        />
      </>
    )
  }
  if (slide.type === END_SLIDE) {
    return (
      <>
        <StepThemeContainer module={sessionData.module} slide={slide}>
          {isFacilitator && <FacilitatorChecklistOverview readOnly={pastMode} type={slide.type} />}
          {!isFacilitator && (
            <SessionSplash text="Your session is over">
              <AnchorButton
                size="m"
                children="Back"
                style={{ display: 'inline-block' }}
                href={
                  sessionData.type === 'cadet'
                    ? `${process.env.REACT_APP_CADET_URL}/game`
                    : `${process.env.REACT_APP_MENTOR_URL}${mentorDashboardBaseUrl}`
                }
              />
            </SessionSplash>
          )}
        </StepThemeContainer>
      </>
    )
  }
  return (
    <StepThemeContainer module={sessionData.module} slide={slide}>
      <Padding size="l" style={{ maxWidth: 'calc(100% - 40px)' }} ref={(elem) => setScrollContainerRef(elem)}>
        <PanelsContainer size="l" singlePanelMode={singlePanelMode} moduleType={sessionData.module.type}>
          {showHeaderPanel && (
            <Panel
              className="header-panel"
              theme={slide.type === MODULE_SLIDE ? slide.step.theme || undefined : undefined}
              flair={[CUT_TL, GRILL_TL_S, CUT_TR, GRILL_TR_S, CUT_BL, GRILL_BL_S, CUT_BR, GRILL_BR_S]}
              style={{
                padding: '2rem 0',
                textAlign: 'center',
                boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.2)',
              }}>
              <Row>
                <div style={{ flex: '1 1 auto' }} />
                <Column flex="1 1 auto" style={{ maxWidth: 680 }}>
                  <ModuleTitle>{sessionData.module.public_title || sessionData.module.title}</ModuleTitle>
                  {slide.type === MODULE_SLIDE && (
                    <>
                      {slide.step.title && <StepTitle>{slide.step.title}</StepTitle>}
                      {slide.step.subheading && <StepSubheading>{slide.step.subheading}</StepSubheading>}
                    </>
                  )}
                  {slide.type === SUPPLEMENTARY_SLIDE && supplementarySlide && (
                    <>{supplementarySlide.title && <StepTitle>{supplementarySlide.title}</StepTitle>}</>
                  )}
                </Column>
                <div style={{ flex: '1 1 auto' }} />
              </Row>
            </Panel>
          )}
          {slide.type === MODULE_SLIDE && (
            <>
              {slide?.step?.panels
                ?.filter((panel, i) => (singlePanelMode ? i === 0 : true))
                .map((panel) => <SectionsPanel key={panel.uid} panel={panel} fullHeight={slide.step.single_panel} />)}
            </>
          )}
          {slide.type === SUPPLEMENTARY_SLIDE && (
            <SupplementarySlideContainer key={`${slide.supplementarySlide.type}_${slide.uid}`} slide={slide} />
          )}
        </PanelsContainer>
      </Padding>
      {!isFacilitator && <TokenNotification />}
    </StepThemeContainer>
  )
}

export const PanelsContainer = styled(Container)<{ singlePanelMode?: boolean; moduleType?: SessionModule['type'] }>`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 1024px;
  max-width: 100%;
  min-width: 640px;
  & ${Panel} + ${Panel} {
    margin-top: 1.5rem;
  }
  ${(p) =>
    p.singlePanelMode
      ? css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          & > ${SectionsPanelStyled} {
            & > .panel-padding,
            & > .panel-padding > ${HighlightArea}, & > .panel-padding > ${HighlightArea} > ${CorkSection} {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        `
      : css`
          & > .header-panel,
          & ${SectionsPanelStyled} {
            @media (max-height: 800px) and (min-aspect-ratio: 5/4) {
              margin-left: auto;
              margin-right: auto;
              max-width: 800px;
            }
            @media (max-height: 700px) and (min-aspect-ratio: 5/4) {
              max-width: 720px;
            }
          }
          & ${PanelTitle} {
            @media (max-height: 800px) and (min-aspect-ratio: 5/4) {
              max-width: 760px;
            }
            @media (max-height: 700px) and (min-aspect-ratio: 5/4) {
              max-width: 680px;
            }
          }
        `}

  ${(p) =>
    p.moduleType === 'parent'
      ? css`
          ${RichTextContainer} {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #4ebe40;
            }
          }

          ${ListContainer}, ${RichTextContainer} {
            ul > li::before {
              background: url(${require('session/assets/list-bullet-parentGreen.svg')?.default}) no-repeat right top;
            }
          }

          ${CircleButton} {
            background: linear-gradient(180deg, #51ab46 0%, #65d058 100%);
            border-color: #4ebe40;
          }

          ${SectionTitle} {
            color: #4ebe40;
          }
        `
      : ''}
`

export const ModuleTitle = styled(H3)`
  text-transform: uppercase;
  font-size: 1rem;
`

export const StepTitle = styled(H2)`
  font-size: 1.6rem;
`

export const StepSubheading = styled(H2)`
  font-size: 1.5rem;
`

const DelayedWrapper = styled.div<{
  isMounted: boolean
  delay: number
  inPreview: boolean
  inPastMode?: boolean
  pageHeight: number
}>`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  min-height: calc(${(p) => p.pageHeight}px - ${(p) => (p.inPreview ? 164 : 114)}px);
  ${PanelsContainer} {
    transition: ${(p) => p.delay}ms ease;
    opacity: ${(p) => (p.isMounted ? 1 : 0)};
  }
`
