import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'
import { touchInputUnavailable } from './touchUtils'
import { useMountedRef } from './useMountedRef'

type ListenerFunction = (open: boolean) => void

const stripMetaContentValue = (meta: string, key: string, valueRegex: string) =>
  meta.replace(new RegExp(`,?\\s?${key}=${valueRegex}`, 'g'), '')

export const getMetaContentValue = (meta: string, key: string, valueRegex: string): string | null => {
  const matches = new RegExp(`,?\\s?${key}=(${valueRegex})`, 'g').exec(meta)
  if (!matches || matches.length < 2) return null
  return matches[1]
}

export const getMetaTagContentValue = (metaName: string, key: string, valueRegex: string): string | null => {
  const tag = document.querySelector<HTMLMetaElement>(`meta[name="${metaName}"]`)
  if (!tag) return null
  return getMetaContentValue(tag.content, key, valueRegex)
}

let listeners: ListenerFunction[] = []
export const addVirtualKeyboardListener = (func: ListenerFunction) => {
  listeners.push(func)
}

export const removeVirtualKeyboardListener = (func: ListenerFunction) => {
  listeners = listeners.filter(compare => compare !== func)
}

export const getInputProps = <T>() =>
  touchInputUnavailable
    ? {}
    : {
        onFocusCapture: (event: React.FocusEvent<T>) => {
          const meta = document.querySelector<HTMLMetaElement>('meta[name="viewport"]')
          if (!meta) return
          meta.content = stripMetaContentValue(meta.content, 'height', '\\d+px') + `, height=${window.innerHeight}px`
          listeners.forEach(func => func(true))
        },
        onBlurCapture: (event: React.FocusEvent<T>) => {
          const meta = document.querySelector<HTMLMetaElement>('meta[name="viewport"]')
          if (!meta) return
          listeners.forEach(func => func(false))
          setTimeout(() => {
            meta.content = stripMetaContentValue(meta.content, 'height', '\\d+px')
          }, 250)
        },
      }

export function useVirtualKeyboard() {
  const [state, setState] = useState<{
    open: boolean
    preOpenViewportHeight: number
    viewportHeight: number
    keyboardHeight: number
  }>({
    open: false,
    viewportHeight: window.innerHeight,
    preOpenViewportHeight: window.innerHeight,
    keyboardHeight: 0,
  })

  const mountedRef = useMountedRef()

  useEffect(() => {
    const handleVirtualKeyboardChange: ListenerFunction = open => {
      const preOpenViewportHeight = window.innerHeight

      // we must delay the updating of state in order to measure the innerHeight after keyboard has animated onscreen
      // 333ms seems to be enough for this on all tested android devices (250 wasn't quite enough for an older device)
      setTimeout(() => {
        mountedRef.current &&
          setState({
            open,
            viewportHeight: window.innerHeight,
            preOpenViewportHeight,
            keyboardHeight: preOpenViewportHeight - window.innerHeight,
          })
      }, 333)
    }

    addVirtualKeyboardListener(handleVirtualKeyboardChange)

    return () => {
      removeVirtualKeyboardListener(handleVirtualKeyboardChange)
    }
  }, [mountedRef])

  // this handles if a device orientation is changed or the window is blurred on a touch devices while a touch keyboard is open
  useEffect(() => {
    const handleResize = throttle(() => {
      if (state.open) {
        mountedRef.current &&
          setState(state => ({
            ...state,
            viewportHeight: window.innerHeight,
            keyboardHeight: state.preOpenViewportHeight - window.innerHeight,
          }))
      }
    }, 333)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [state.open, mountedRef])

  return state
}
