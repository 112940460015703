import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { TrainingStateProvider, useTrainingState } from './TrainingState'
import { TrainingStep } from './TrainingStep'
import { useParams } from 'react-router-dom'
import { useSocket } from 'utils/useSocket'
import { Container } from 'common/ui'
import { trainingPurple } from 'themes'

export const TrainingPreview: React.FC = () => {
  return (
    <TrainingStateProvider>
      <TrainingPreviewInner />
    </TrainingStateProvider>
  )
}

const TrainingPreviewInner: React.FC = () => {
  const { previewUid } = useParams<{ previewUid?: string }>()
  const [initedPreviewData, setInitedPreviewData] = useState<boolean>(false)
  const { trainingData, setTrainingData, previewing, setPreviewing, previewState, setPreviewState } = useTrainingState()
  const socket = useSocket(process.env.REACT_APP_PREVIEW_SOCKET_URL)
  useEffect(() => {
    if (!socket || !previewUid) return
    if (!socket.connected) {
      console.log('Preview socket listeners being created...')
      socket.on('connect', () => {
        console.log('Preview socket connection established')
        socket.emit('REGISTER_VIEWER', previewUid)
      })
      socket.on('disconnect', () => {
        console.log('Preview socket disconnected')
      })
      socket.on('VIEWER_DATA_UPDATED', (trainingData: any) => {
        console.log('Received viewer data from preview socket', trainingData)
        setTrainingData(trainingData)
        setInitedPreviewData(true)
      })
      socket.on('DISPATCH', (action: any) => {
        console.log('Received action from preview socket server', action)
        switch (action.type) {
          case 'UPDATE_PREVIEW_STATE':
            console.log('Updating preview state', action.state)
            setPreviewState(action.state)
            break
        }
      })
      socket.connect()
    } else {
      console.log('Socket already connected')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, previewUid])

  useEffect(() => {
    if (!previewing) setPreviewing(true)
  }, [previewing, setPreviewing])

  if (!initedPreviewData || !trainingData || !previewUid) return <h1>Please wait</h1>

  const step = trainingData.unit.training_steps.find((value, index) => index === previewState.stepIndex)

  return (
    <ThemeProvider theme={trainingPurple}>
      <Container>
        <Background />
        <h1>Training Step Preview</h1>
        {step ? <TrainingStep step={step} reviewMode={false} /> : <p>step not found :(</p>}
      </Container>
    </ThemeProvider>
  )
}

const Background = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(210.7deg, #a873ff 3.35%, #7d41df 100%);
`
