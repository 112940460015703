import React from 'react'

import { Hr, Row, SpacerSize, Spacer, Column } from './ui'
import { Checkbox } from './Checkbox'
import styled from 'styled-components'

type Size = 'm' | 's'

interface Props<OptionType, ValueType> {
  name: string
  options: OptionType[]
  highlightIndex?: number
  value: ValueType[]
  onChange: (value: ValueType[], options: OptionType[]) => void
  disabled?: boolean
  size?: Size
  spacerSize?: SpacerSize
  autoFocus?: boolean
  flex?: boolean
  hideDividers?: boolean
}

const paddingHeight = { m: 20, s: 8 }

export function CheckboxGroup<
  OptionType extends { value: ValueType; label?: React.ReactNode; disabled?: boolean },
  ValueType extends string = string
>({
  options,
  disabled,
  name,
  value,
  highlightIndex,
  onChange,
  size = 'm',
  autoFocus,
  flex = true,
  hideDividers = false,
}: Props<OptionType, ValueType>) {
  function handleChange(option: OptionType, checked: boolean) {
    const selected = options.filter(opt => {
      if (opt.value === option.value) {
        return checked
      } else {
        return value.indexOf(opt.value) !== -1
      }
    })
    onChange(
      selected.map(({ value }) => value),
      selected
    )
  }
  return (
    <>
      {!hideDividers && <Hr />}
      {options.map((option, i) => {
        return (
          <React.Fragment key={i}>
            {flex ? <div style={{ flex: 'auto', maxHeight: 20 }} /> : <div style={{ height: paddingHeight[size] }} />}
            <Checkbox
              id={`${name}_${i}`}
              name={name}
              checked={value.indexOf(option.value) !== -1}
              onChange={checked => handleChange(option, checked)}
              disabled={disabled || option.disabled}
              autoFocus={autoFocus && i === 0}
              highlight={highlightIndex === i}
              size={size}>
              {option.label || option.value}
            </Checkbox>
            {flex ? <div style={{ flex: 'auto', maxHeight: 20 }} /> : <div style={{ height: paddingHeight[size] }} />}
            {!hideDividers && <Hr />}
          </React.Fragment>
        )
      })}
    </>
  )
}

export function InlineCheckboxGroup<
  OptionType extends { value: ValueType; label?: React.ReactNode; disabled?: boolean },
  ValueType extends string = string
>({
  options,
  disabled,
  name,
  value,
  highlightIndex,
  onChange,
  size = 'm',
  spacerSize = 'l',
  autoFocus,
}: Props<OptionType, ValueType>) {
  function handleChange(option: OptionType, checked: boolean) {
    const selected = options.filter(opt => (opt.value === option.value ? checked : value.indexOf(opt.value) !== -1))
    onChange(
      selected.map(({ value }) => value),
      selected
    )
  }
  return (
    <Row flexWrap>
      {options.map((option, i) => {
        return (
          <React.Fragment key={i}>
            <InlineCheckboxColumn flex="none" marginBottom={spacerSize || size}>
              <Checkbox
                id={`${name}_${i}`}
                name={name}
                checked={value.indexOf(option.value) !== -1}
                onChange={checked => handleChange(option, checked)}
                disabled={disabled || option.disabled}
                autoFocus={autoFocus && i === 0}
                highlight={highlightIndex === i}
                size={size}>
                {option.label || option.value}
              </Checkbox>
            </InlineCheckboxColumn>
            <Spacer size={spacerSize || size} />
            <Spacer size={spacerSize || size} />
          </React.Fragment>
        )
      })}
    </Row>
  )
}

const InlineCheckboxColumn = styled(Column)`
  & label > span {
    margin-left: 0;
  }
`
