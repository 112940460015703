import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Row, Hr, Column, H2 } from 'common/ui'
import { InfoTooltip, TooltipProps } from 'common/Tooltip'

interface Props {
  id?: string
  tooltip?: ReactNode
  tooltipPlacement?: TooltipProps['placement']
  sideRoute?: string
  sideLabel?: string
  sideContent?: ReactNode
}

export const HeaderHr: React.FC<Props> = ({
  id,
  children,
  tooltip,
  sideRoute,
  sideLabel = 'View All',
  sideContent,
  tooltipPlacement,
}) => {
  return (
    <>
      <Row id={id} alignItems="flex-end">
        <Column flex="1 1 auto">
          <H2
            style={{ display: 'flex', alignItems: 'center' }}
            children={
              tooltip ? (
                <>
                  <span>{children}</span>
                  <InfoTooltip
                    iconSize={14}
                    containerStyle={{ zIndex: 1112 }}
                    content={tooltip}
                    placement={tooltipPlacement || 'right'}
                  />
                </>
              ) : (
                children
              )
            }
            color="#011A46"
          />
        </Column>
        {sideRoute && <Link to={sideRoute} style={{ color: '#011A46' }} children={sideLabel} />}
        {sideContent}
      </Row>
      <Hr margin="s" />
    </>
  )
}
