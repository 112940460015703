import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { useParams } from 'react-router-dom'
import { blue } from 'themes'

import { SessionEntityHydrated } from 'shared/dashboard/types'
import { SessionModule, SavedInputValue } from 'shared/session/types'
import { SessionStateProvider, useSessionState } from 'session/SessionState'
import { UserInputStateProvider, useUserInputState } from 'session/UserInputState'
import { ProgressionEventsStateProvider } from 'session/ProgressionEventsState'
import { InputContextProvider } from 'session/InputContext'
import { SET_SAVED_USER_INPUT_VALUES } from 'shared/session/actionTypes'

import { ModalContextProvider } from 'session/ModalContext'
import { Column, AppBackground } from 'common/ui'
import { RouteLeavePrompt } from 'common/RouteLeavePrompt'
import { SessionSlideHandler } from 'session/SessionSlide'
import { OfflineMessage } from 'session/OfflineMessage'
import { FacilitatorPresentationUi } from './FacilitatorPresentationUi'
import { ResourceLoadingStatusPanel } from 'dashboards/common/ResourceLoadingStatusPanel'

import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useGenericUser } from 'app/useGenericUser'
import { useEndpoint } from 'dashboards/utils/endpointHooks'

export const FacilitatorMeetingPresentationMode: React.FC = () => {
  useDisableOrientationLock()
  return (
    <ProgressionEventsStateProvider>
      <SessionStateProvider>
        <UserInputStateProvider>
          <InputContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={blue}>
                <FacilitatorMeetingPresentationModeInner />
                <OfflineMessage />
                {/* TODO: Add logic to disable if on module endScreen */}
                <RouteLeavePrompt
                  when={!window.location.hostname.match(/localhost$/)}
                  message="Are you sure you want to close your session window?"
                />
              </ThemeProvider>
            </ModalContextProvider>
          </InputContextProvider>
        </UserInputStateProvider>
      </SessionStateProvider>
    </ProgressionEventsStateProvider>
  )
}

const FacilitatorMeetingPresentationModeInner: React.FC = () => {
  const { sessionUid } = useParams<{ sessionUid: string }>()
  const user = useGenericUser()
  const { dispatch: dispatchInputState } = useUserInputState()
  const { socket, setSessionData, setSessionProfile, setSessionUserType, setPresentationMode } = useSessionState()

  const [session, sessionEndpoint] = useEndpoint<SessionEntityHydrated & { module: SessionModule }>(
    `/api/v1/sessions/${sessionUid}`
  )
  const [inputValues, inputValuesEndpoint] = useEndpoint<SavedInputValue<any>[]>(
    `/api/v1/session_input_values?session_uid=${sessionUid}`
  )

  const loading = !sessionEndpoint.loaded || !inputValuesEndpoint.loaded

  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)
  const wasLoading = useRef<boolean>(true)

  useEffect(() => {
    if (
      !loading &&
      wasLoading.current === true /* everything after this should be inferred... */ &&
      inputValues !== null &&
      session !== null
    ) {
      wasLoading.current = false

      // Update static state data
      ReactDOM.unstable_batchedUpdates(() => {
        setPresentationMode(true)
        setSessionUserType('facilitator')
        setSessionProfile({ ...user, userType: 'facilitator' })
        if (session) setSessionData(session)
      })

      const dispatchSocketActions = () => {
        console.log('😭😭😭 Dispatching init socket actions')
        dispatchInputState({
          user_uid: user.uid,
          role: user.userType,
          timestamp: Date.now(),
          type: SET_SAVED_USER_INPUT_VALUES,
          inputValues,
        })
      }

      if (socket) {
        if (!socket.connected) {
          console.log('😭😭😭 Socket not connected yet, waiting...')
          socket.on('connect', () => setTimeout(() => dispatchSocketActions()))
        } else {
          console.log('🙃🙃🙃 Socket is ready and waiting!')
          dispatchSocketActions()
        }
      }

      // prettier-ignore
      setTimeout(() => { setFinishedDelay(true) }, 1000)
    }
  })

  if (loading || !finishedDelay)
    return (
      <AppBackground theme="purple">
        <Column flex="auto" alignItems="center" justifyContent="center">
          <ResourceLoadingStatusPanel
            title="Loading meeting data..."
            resources={[
              { label: 'Meeting content', endpoint: sessionEndpoint },
              { label: 'Input and drawings', endpoint: inputValuesEndpoint },
            ]}
          />
        </Column>
      </AppBackground>
    )

  return (
    <FacilitatorPresentationUi>
      <SessionSlideHandler />
    </FacilitatorPresentationUi>
  )
}
