import React from 'react'

import { Row, Spacer, P, CollapseMargin, Panel, Button, CUT_TR, CUT_TL, Center, CUT_BL, CUT_BR } from 'common/ui'
import Modal from 'app/Modal'
import { LoadingPanel } from 'app/LoadingScreen'
import { AnchorButton } from 'common/LinkButton'

export type PdfStatus = 'ready' | 'busy' | 'success' | 'error'

interface PdfModalProps {
  status: PdfStatus
  url: string
  onClose: () => void
  onCancel?: () => void
  text1?: string
  text2?: string
}

export const PdfModal: React.FC<PdfModalProps> = ({
  status,
  url,
  onClose,
  onCancel,
  text1 = 'Generating',
  text2 = 'Report',
}) => {
  if (status === 'ready') return null
  return (
    <Modal isOpen>
      {status === 'busy' ? (
        <LoadingPanel text1={text1.toUpperCase()} text2={text2.toUpperCase()} shadowColor="black">
          {onCancel && (
            <div style={{ textAlign: 'center' }}>
              <Spacer size="m" />
              <Button size="xs" theme="gray" children="Cancel" onClick={() => onCancel()} />
            </div>
          )}
        </LoadingPanel>
      ) : (
        <Panel
          style={{ width: 360 }}
          shadowColor="black"
          padding="40px 30px 45px 30px"
          flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR]}>
          {status === 'success' && (
            <Row>
              <Spacer flex />
              <AnchorButton href={url} target="_blank">
                View PDF
              </AnchorButton>
              <Spacer />
              <Button onClick={onClose}>Close</Button>
              <Spacer flex />
            </Row>
          )}
          {status === 'error' && (
            <>
              <CollapseMargin>
                <P align="center">
                  An unexpected error occurred.
                  <br />
                  Please try again.
                </P>
              </CollapseMargin>
              <Spacer />
              <Center>
                <Button onClick={onClose}>Close</Button>
              </Center>
            </>
          )}
        </Panel>
      )}
    </Modal>
  )
}
