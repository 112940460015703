import React, { CSSProperties } from 'react'
import ReactModal from 'react-modal'
import { useModalContext } from 'session/ModalContext'

const defaultModalStyles: CSSProperties = {
  // override ReactModal defaults
  position: 'relative',
  top: 'initial',
  left: 'initial',
  right: 'initial',
  bottom: 'initial',
  border: 'none',
  padding: 0,
  borderRadius: 0,
  background: 'transparent',

  // custom styles
  flex: 'auto',
  display: 'flex',
  flexDirection: 'column',
}

const defaultModalOverlayStyles: CSSProperties = {
  display: 'flex',
  zIndex: 999,
  background: 'rgba(0, 0, 0, 0.8)',
  position: 'absolute',
}

export interface Props extends ReactModal.Props {
  children: React.ReactNode
  center?: boolean
  zIndex?: number
}

export const FlexModal: React.FC<Props> = props => {
  const style: ReactModal.Styles = {
    content: defaultModalStyles,
    overlay: { ...defaultModalOverlayStyles, zIndex: props.zIndex || defaultModalOverlayStyles.zIndex },
  }

  let DomContainer = useModalContext()
  if (!DomContainer) {
    DomContainer = document.querySelector('#root')
  }

  if (DomContainer && DomContainer.id === 'root') {
    style.overlay = { ...style.overlay, position: 'fixed' }
  }

  if (props.center) style.content = { ...style.content, justifyContent: 'center', alignItems: 'center' }

  if (props.style && props.style.content) style.content = { ...style.content, ...props.style.content }
  if (props.style && props.style.overlay) style.overlay = { ...style.overlay, ...props.style.overlay }
  return <ReactModal {...props} style={style} parentSelector={() => DomContainer as HTMLElement} />
}
