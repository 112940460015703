import {
  GenericUser,
  IdField,
  UidField,
  OwnerOccupierFields,
  OrderField,
  TitleField,
  SlugField,
  TimestampFields,
  BaseAction,
  UploadResponse,
} from '../types'
import { SessionEntityHydrated } from '../dashboard/types'
import { EmotionometerDevice, Gadget } from '../e-telligence/types'
import { StressBallState } from '../gadget-pack/types'
import { ActionTypes, AddUpdateValueAction } from './actionTypes'
import { DrawingActionTypes } from './sections/drawing/actionTypes'
import { FacManModule } from '../fac-man/types'
import { AnyModuleCode } from '../dashboard/moduleCodes'
import { QuestionnaireBooklet, QuestionnaireType } from '../questionnaires/types'

// ------------------------------------------------------------------ //

/**
 * Primary state shapes
 */

export interface SessionState {
  sessionActive: boolean
  sessionUid: string
  facilitators: FacilitatorState[]
  participants: ParticipantState[]
  currentSlideLocked: boolean // TODO: remove not used i don't think
  currentStepStartTime: number
  tokenTarget: number
  highlight: FocusLocation | null
  lockedAreas: FocusLocation[]
  slides: Slide[]
  skippedSlideUids: string[]
  sectionStates: SectionState[]
  globalLock: boolean
  editingState: { [key: string]: EditingState }
}

export type PreviewMode = 'participant' | 'facilitator' | null

export interface SessionStateHookObject {
  state: SessionState
  sessionData: false | SessionEntityHydrated
  setSessionData: React.Dispatch<React.SetStateAction<this['sessionData']>>
  sessionProfile: SessionProfile
  setSessionProfile: React.Dispatch<React.SetStateAction<this['sessionProfile']>>
  sessionUserType: GenericUser['userType']
  setSessionUserType: React.Dispatch<React.SetStateAction<this['sessionUserType']>>
  getBaseAction: () => Pick<BaseAction, 'user_uid' | 'role' | 'timestamp'>
  socket: SocketIOClient.Socket | undefined
  dispatch: (action: ActionTypes) => void
  isFacilitator: boolean
  isAssistant: boolean
  pastMode: boolean
  setPastMode: React.Dispatch<React.SetStateAction<this['pastMode']>>
  previewing: boolean
  setPreviewing: React.Dispatch<React.SetStateAction<this['previewing']>>
  presentationMode: boolean
  setPresentationMode: React.Dispatch<React.SetStateAction<this['presentationMode']>>
  previewMode: PreviewMode
  setPreviewMode: React.Dispatch<React.SetStateAction<this['previewMode']>>
  previewState: PreviewState
  setPreviewState: React.Dispatch<React.SetStateAction<this['previewState']>>
  manual: false | FacManModule
  setManual: React.Dispatch<React.SetStateAction<this['manual']>>
}

export interface PreviewState {
  stepIndex: number
}

export interface ParticipantState {
  profile: SessionProfile // TEMP
  status: 'offline' | 'connected' | 'idle'
  currentSlideUid: string | null
  highlight?: FocusLocation
  // locked: FocusLocation[]
  locked: boolean
  tokens: number
  inputValues: InputValue<JSONValueType>[]
  gadgetTrayOpen: boolean
  trayType: null | 'gadgets' | 'mission'
  tokenMission?: string
  requiresHelp?: boolean
}

export type FacilitatorTool = 'highlight' | 'edit' | 'lock'

export interface FocusLocation {
  // sectionProperty: string
  // id: number
  // domRef?: string
  key: string // TEMP?
}

export interface FacilitatorState {
  profile: SessionProfile // TEMP
  status: 'offline' | 'connected' | 'idle'
  currentSlideUid: string | null
  currentPreviewSlideUid: string | null
  focusedParticipantUid: string | null
  selectedTool: FacilitatorTool | null
  currentManualStepUid: string | null
  slideScrollPositions: { [key: string]: number }
}

export interface EditingState {
  input_uid: string
  facilitator_uid: string
}

// ------------------------------------------------------------------ //

/**
 * Commonly used CakePHP Model types
 */

/** CakePHP Model: Categories */
export interface Category extends IdField, SlugField, TimestampFields {
  title: string
  category_group_id: number
}

/** CakePHP Model: Tank.Assets */
export interface MediaAsset extends IdField, TimestampFields {
  filename: string
  slug?: string
  extension?: string
  bytes?: number
  width?: number
  height?: number
}

export interface Sticker extends IdField, TitleField {
  image: MediaAsset
}

// ------------------------------------------------------------------ //

/**
 ** Main CakePHP Model types
 **/

export interface SessionProfile extends GenericUser {
  socketId?: string
}

// NOTE: Order of array is accurate and relevant
export const gadgetPackKeys = [
  'id-card',
  'bionic-powers',
  'emotionometer/anger',
  'emotionometer/anxiety',
  'stress-ball',
  'relaxation-gadgets',
  'friendship-formula',
  'friendometer',
  'code-cards',
  'fact-files',
  'medal',
] as const
export type GadgetPackKey = (typeof gadgetPackKeys)[number]

/** CakePHP Model: Modules */
export interface SessionModule extends IdField, UidField {
  title: string
  public_title?: string
  type: 'cadet' | 'parent' | 'facilitator'
  module_code: AnyModuleCode
  steps: ModuleStep[]
  toc_enabled?: boolean
  toc_overview?: string // rich text
}

export type TableOfContentsActivity =
  | GadgetPackKey
  | Exclude<EmbeddedActivityKey, 'emotionometer'>
  | Exclude<
      SectionProperty,
      | 'block_sections'
      | 'divider_sections'
      | 'embedded_activity_sections'
      | 'grouped_sections'
      | 'input_sections'
      | 'list_sections'
      | 'media_grid_sections'
      | 'notification_sections'
      | 'text_sections'
      | 'token_target_sections'
      | 'download_resource_sections'
      // TODO: there may be more to exclude?
    >

/** CakePHP Model: Steps */
export interface ModuleStep extends IdField, UidField, OrderField {
  title?: string
  subheading?: string
  estimated_time?: string
  hide_header: boolean
  single_panel: boolean
  theme: 'blue' | 'white' | 'purple' | 'dark' | 'parentGreen'
  panels: SectionPanel[]
  facilitator_steps?: string[]
  manual_step_id: number
  toc_title?: string
  toc_description?: string // rich text
  toc_image?: MediaAsset
  toc_activities?: TableOfContentsActivity[]
}

/** CakePHP Model: Panels */
export interface SectionPanel extends OwnerOccupierFields, OrderField, TitleField, SectionContainer {
  theme:
    | null
    | 'none'
    | 'cork'
    | 'parentGreen'
    | 'relaxation'
    | 'blue'
    | 'red'
    | 'green'
    | 'maroon'
    | 'yellow'
    | 'aqua'
    | 'purple'
  uid: string
  step_id: number
}

// ------------------------------------------------------------------ //

/**
 * Slide types
 */

export const HOLDING_SLIDE = 'HOLDING_SLIDE'
export const MODULE_SLIDE = 'MODULE_SLIDE'
export const SUPPLEMENTARY_SLIDE = 'SUPPLEMENTARY_SLIDE'
export const END_SLIDE = 'END_SLIDE'
export type SlideType = typeof HOLDING_SLIDE | typeof MODULE_SLIDE | typeof SUPPLEMENTARY_SLIDE | typeof END_SLIDE

export const supplementarySlideTypes = [
  'holding',
  'blank',
  'text',
  'draw',
  'text-draw',
  'turn-taking',
  'emotionometer',
  'club-rules',
  'friendship-award',
  'stress-ball',
  'extension-topic',
  'custom-code-card',
  'custom-relaxation-gadget',
  'end',
] as const
export type SupplementarySlideType = (typeof supplementarySlideTypes)[number]

export const extensionTopicTypes = [
  'negotiation-tactics',
  'conversation-equations',
  'feelings-alerts',
  'play-recovery-tactics',
] as const
export type ExtensionTopicType = (typeof extensionTopicTypes)[number]

export interface SupplementarySlide {
  type: SupplementarySlideType | null
  title: string
  uid: string
  order: number
  participantUids: string[]
  panelTheme?: SectionPanel['theme']
  theme?: ModuleStep['theme']

  drawingUsage?: DrawingSection['usage']
  drawingSize?: DrawingSection['size']

  inputUsage?: InputSection['usage']
  inputType?: InputSection['type']
  inputSize?: InputSection['size']
  inputLabel?: InputSection['label']
  inputTextareaLines?: InputSection['lines']

  emotionometerUsage?: EmotionometerSection['usage']
  emotionometerTheme?: EmotionometerSection['theme']

  turnTakingType?: TurnTakingSection['type']
  turnTakingSize?: TurnTakingSection['size']
  turnTakingWaitText?: TurnTakingSection['wait_text']
  turnTakingPrepareText?: TurnTakingSection['prepare_text']
  turnTakingActiveText?: TurnTakingSection['active_text']

  extensionTopicType?: ExtensionTopicType

  friendshipWinner?: string

  stressBallMode?: EmbeddedActivitySection['stress_ball_mode']
}

export interface HoldingSlideRef extends OrderField {
  type: typeof HOLDING_SLIDE
}

export interface EndSlideRef extends OrderField {
  type: typeof END_SLIDE
  uid: string
}

export interface ModuleSlideRef extends OrderField {
  type: typeof MODULE_SLIDE
  uid: string
}

export interface HydratedModuleSlideRef extends ModuleSlideRef {
  step: ModuleStep
}

export interface SupplementarySlideRef extends OrderField {
  type: typeof SUPPLEMENTARY_SLIDE
  uid: string
}

export interface HydratedSupplementarySlideRef extends SupplementarySlideRef {
  supplementarySlide: SupplementarySlide
}

export type Slide = HoldingSlideRef | ModuleSlideRef | SupplementarySlideRef | EndSlideRef

export type HydratedSlide = HoldingSlideRef | HydratedModuleSlideRef | HydratedSupplementarySlideRef | EndSlideRef

// ------------------------------------------------------------------ //

/**
 * Section content types
 */

export const sectionProperties = [
  'block_sections',
  'card_deck_sections',
  'code_card_sections',
  'divider_sections',
  'download_resource_sections',
  'drawing_sections',
  'embedded_activity_sections',
  'emotionometer_sections',
  'grouped_sections',
  'input_sections',
  'list_sections',
  'media_grid_sections',
  'notification_sections',
  'questionnaire_sections',
  'text_sections',
  'todo_list_sections',
  'token_target_sections',
  'turn_taking_sections',
] as const

export type SectionProperty = (typeof sectionProperties)[number]

export interface SectionObject {
  property: SectionProperty
  section: SectionContainer[this['property']][0]
  index: number
}

export interface SectionPropsBase extends SectionObject {
  panel: SectionPanel
}

type SectionContainerShape = { [key in SectionProperty]: Section[] }
export interface SectionContainer extends SectionContainerShape {
  block_sections: BlockSection[]
  card_deck_sections: CardDeckSection[]
  code_card_sections: CodeCardSection[]
  divider_sections: DividerSection[]
  download_resource_sections: DownloadResourceSection[]
  drawing_sections: DrawingSection[]
  embedded_activity_sections: EmbeddedActivitySection[]
  emotionometer_sections: EmotionometerSection[]
  grouped_sections: GroupedSection[]
  input_sections: InputSection[]
  list_sections: ListSection[]
  media_grid_sections: MediaGridSection[]
  notification_sections: NotificationSection[]
  questionnaire_sections: QuestionnaireSection[]
  text_sections: TextSection[]
  todo_list_sections: TodoListSection[]
  token_target_sections: TokenTargetSection[]
  turn_taking_sections: TurnTakingSection[]
}

export type ContentSection =
  | BlockSection
  | CardDeckSection
  | DividerSection
  | DrawingSection
  | EmbeddedActivitySection
  | EmotionometerSection
  | GroupedSection
  | InputSection
  | ListSection
  | MediaGridSection
  | NotificationSection
  | TextSection
  | TodoListSection
  | TokenTargetSection
  | TurnTakingSection
  | DownloadResourceSection
  | QuestionnaireSection

export interface Section extends IdField, OwnerOccupierFields, OrderField, TitleField {}

// ------------------------------------------------------------------ //

/**
 * UserInputValue types
 */

export type JSONValueType = boolean | number | string | null | JsonArray | JsonMap
type JsonArray = JSONValueType[]
type JsonMap = { [key: string]: JSONValueType }

export interface InputValueContext {
  session_uid: string
  participant_uid: string
  module_id: number
  owner: string
  owner_id: number | null
  name: string
}

export interface InputValue<ValueType extends any> extends InputValueContext {
  value: ValueType
  modified: number // This is not used for API at all -- it's just so we can show 'editing status'
}

export interface SavedInputValue<ValueType extends any> extends InputValueContext {
  id: number
  revision: number
  value: ValueType
  created_at: number
  updated_at: number
}

export type InputState = {
  actionCache: {
    [key: string]: AddUpdateValueAction[] // key is participant UID
  }
  state: {
    [key: string]: InputValue<JSONValueType>[] // key is participant UID
  }
  savedState: {
    [key: string]: SavedInputValue<JSONValueType>[] // key is participant UID
  }
}

// ------------------------------------------------------------------ //

/**
 * UserInputValue types
 */

export interface ProgressionEvent<ValueType extends any> {
  participant_uid: string
  mentor_id?: number | null
  type: string // TODO: should be a const union
  category: string
  subcategory: string | null
  event_key: string | null
  event_value: ValueType | null
}

export interface SavedProgressionEvent<ValueType extends any> extends ProgressionEvent<ValueType> {
  id: number
  created_at: number
}

// ------------------------------------------------------------------ //

/**
 * CMS CakePHP model types
 */

export type MediaRatio = '3:1' | '2:1' | '16:9' | '3:2' | '4:3' | '5:4' | '1:1' | '9:16' | '4:5' | '3:4'
export type MediaType = 'image' | 'video' | 'icon'

/** CakePHP Model: BlockSections */
export interface BlockSection extends Section {
  display: 'inline' | 'block'
  columns: number
  media_ratio?: MediaRatio
  items: BlockItem[]
}
/** CakePHP Model: BlockItems */
export interface BlockItem extends OrderField {
  type: 'image' | 'video' | 'icon'
  theme?: 'red' | 'blue'
  media_ratio?: MediaRatio
  image?: MediaAsset
  video?: MediaAsset
  icon?: MediaAsset
  alignment?: 'left' | 'right'
  content: string
}

/** CakePHP Model: CardDeckSections */
export interface CardDeckSection extends Section {
  cards: Card[]
  card_deck?: CardDeck
  image?: MediaAsset
}

export type CodeCardTypes =
  | 'decoder_formula'
  | 'conversation_code'
  | 'play_code'
  | 'damage_control_code'
  | 'difference_code'
  | 'bully_guard_body_armour'
  | 'confusion_code'
  | 'fire-engine'
  | 'o2-regulator'
  | 'body-scan'

export const unlockableRelaxationGadgets = ['fire-engine', 'o2-regulator', 'body-scan'] as const
export type UnlockableRelaxationGadgets = (typeof unlockableRelaxationGadgets)[number]

/** CakePHP Model: CodeCardSections */
export interface CodeCardSection extends Section {
  type: CodeCardTypes | null
  category: 'relaxation-gadgets' | null
}

/** CakePHP Model: CardDecks */
export interface CardDeck extends IdField, UidField {
  title: string
  cards: Card[]
  image?: MediaAsset
}
/** CakePHP Model: Cards */
export interface Card extends IdField, UidField {
  category?: Category
  title: string
  heading?: string
  text?: string
  background_image?: MediaAsset
  left_image?: MediaAsset
  right_image?: MediaAsset
  hint_image?: MediaAsset
}

/** CakePHP Model: DrawingSections */
export interface DrawingSection extends Section {
  usage: 'individual' | 'shared' | 'shared_custom' | 'facilitator'
  size: 'small' | 'medium' | 'large'
  background_image?: MediaAsset
  background_image_placement?: 'foreground' | 'background' | null
  include_text: boolean
  include_pins: boolean
  include_stickers: boolean
  stickers: Sticker[]
  section_title: string
}

/** CakePHP Model: EmbeddedActivitySections */

export type EmbeddedActivityKey =
  | 'id-card'
  | 'bionic-powers'
  | 'emotionometer'
  | 'relaxation-gadgets'
  | 'stress-ball'
  | 'friendship-formula'
  | 'friendometer'
  | 'code-cards'
  | 'fact-files'
  | 'board-game'
  | 'scavenger-hunt'
  | 'brain-cell'
  | 'invisible-ink'
  | 'detection-expression'
  | 'thought-tracker'
  | 'body-signals'
  | 'club-rules'
  | 'helpful-thoughts'
  | 'secret-transmission'
  | 'damage-control'
  | 'bully-guard'
  | 'medal'

export interface EmbeddedActivitySection extends Section {
  activity: EmbeddedActivityKey
  id_card_flippable: boolean
  stress_ball_mode: null | StressBallState['mode']
  invisible_ink_hide_cork: boolean
  scavenger_hunt_wait_text?: string
  scavenger_hunt_ready_text?: string
  detection_turn_taking_section?: TurnTakingSection
  detection_card_deck_section?: CardDeckSection
  emotionometer_emotion?: 'anger' | 'anxiety'
  emotionometer_device?: EmotionometerDevice
  body_signals_background_image?: MediaAsset
  body_signals_emotion: 'anger' | 'anxiety'
  body_signals_image_size: 'fill' | 'cover' | 'contain'
  body_signals_placeholder_text: string
  transmission_turn_taking_section?: TurnTakingSection
  transmission_card_deck_section?: CardDeckSection
  bully_turn_taking_section?: TurnTakingSection
  bully_card_deck_section?: CardDeckSection
  relaxation_gadgets_static?: 'static' | null
  // emotionometer_stickers_category?: Category
  // emotionometer_stickers: Sticker[]
}

/** CakePHP Model: EmotionometerSections */
export interface EmotionometerSection extends Section {
  step: number
  start: number
  end: number
  usage: 'static' | 'individual' | 'facilitator'
  theme: 'white' | 'blue' | 'green' | 'red' | 'purple' | 'yellow'
  labels: string[]
}

/** CakePHP Model: GroupedSections */
export interface GroupedSection extends Section {
  pages: GroupedPage[]
}
/** CakePHP Model: GroupedSectionPages */
export interface GroupedPage extends IdField, OrderField, TitleField, Exclude<SectionContainer, 'grouped_sections'> {
  image?: MediaAsset
}

/** CakePHP Model: InputSections */
export interface InputSection extends Section {
  usage: 'individual' | 'facilitator'
  type: 'text' | 'symbol' | 'text_symbol' | 'textarea'
  size: 's' | 'm' | 'l' | null
  multiple_symbols: boolean
  label?: string
  placeholder?: string
  lines: number
  required: boolean
  maxLength?: number
}

/** CakePHP Model: ListSections */
export interface ListSection extends Section {
  display: 'block' | 'inline' | 'inline_symbols'
  type: 'dot' | 'numeric' | 'alphabetic'
  items: ListItem[]
}
/** CakePHP Model: ListItems */
export interface ListItem extends OrderField {
  symbol?: string
  text?: string
  icon?: MediaAsset
}

/** CakePHP Model: MediaGridSections */
export interface MediaGridSection extends Section {
  columns: number
  items: MediaGridItem[]
}
export interface MediaGridItem extends OrderField {
  type: 'image' | 'video' | 'icon'
  media_ratio?: MediaRatio
  image?: MediaAsset
  video?: MediaAsset
  icon?: MediaAsset
  loop: boolean
  show_controls: boolean
}

/** CakePHP Model: NotificationSections */
export interface NotificationSection extends Section {
  type: 'info' | 'warning' | 'error' | 'ardon' | 'dark'
  text: string
}

/** CakePHP Model: TextSections */
export interface TextSection extends Section {
  text: string
}

/** CakePHP Model: TokenTargetSections */
export interface TokenTargetSection extends Section {}

/** CakePHP Model: TurnTakingSections */
export interface TurnTakingSection extends Section {
  type: 'ball' | 'clapper' | 'radio' | 'star'
  wait_text?: string
  prepare_text?: string
  active_text?: string
  size: null | 'large' | 'medium' | 'small' // null is large
}

/** CakePHP Model: DividerSections */
export interface DividerSection extends Section {}

/** CakePHP Model: DownloadResourceSections */
export interface DownloadResourceSection extends Section {
  asset: MediaAsset
}

/** CakePHP Model: QuestionnaireSections */
export interface QuestionnaireSection extends Section {
  questionnaire_booklet: QuestionnaireBooklet
  questionnaire_type: QuestionnaireType
  holding_text?: string
}

/** CakePHP Model: TodoListSections */
export interface TodoListSection extends Section {
  todo_list_items: TodoListItem[]
}

/** CakePHP Model: TodoListItems */
export interface TodoListItem extends IdField, OrderField, UidField {
  type: 'h1' | 'h2' | 'li'
  text: string
}

// ------------------------------------------------------------------ //

/**
 * Section state types
 */

export const turnStatuses = ['wait', 'prepare', 'active'] as const
export type TurnStatus = (typeof turnStatuses)[number]

export type SectionStateProperty =
  | SectionProperty
  | 'activity_scavenger_hunt'
  | 'activity_club_rules'
  | 'activity_emotionometer'
  | 'activity_relaxation_gadgets'
  | 'activity_secret_transmission'
  | 'activity_helpful_thoughts'
  | 'activity_body_signals'
  | 'activity_thought_tracker'
  | 'activity_brain_cell'
  | 'activity_board_game'
  | 'activity_invisible_ink'
  | 'activity_damage_control'
  | 'activity_bully_guard'
  | 'activity_detection_of_expression'

export type SectionStateShape = { [key in SectionStateProperty]: any }
export interface SectionStates extends SectionStateShape {
  card_deck_sections: CardDeckSectionState
  drawing_sections: DrawingSectionState
  questionnaire_sections: QuestionnaireSectionState
  todo_list_sections: TodoListSectionState
  turn_taking_sections: TurnTakingSectionState
  activity_scavenger_hunt: ActivityScavengerHuntState
  activity_club_rules: ActivityClubRulesState
  activity_emotionometer: ActivityEmotionometerState
  activity_relaxation_gadgets: ActivityRelaxationGadgetsState
  activity_secret_transmission: ActivitySecretTransmissionState
  activity_helpful_thoughts: ActivityHelpfulThoughtsState
  activity_body_signals: ActivityBodySignalsState
  activity_thought_tracker: ActivityThoughtTrackerState
  activity_brain_cell: ActivityBrainCellState
  activity_board_game: ActivityBoardGameState
  // activity_invisible_ink: ActivityInvisibleInkState
  activity_damage_control: ActivityDamageControlState
  activity_bully_guard: ActivityBullyGuardState
  activity_detection_of_expression: ActivityDetectionOfExpressionState
}

export interface SectionState {
  section_id: Section['id']
  property: SectionStateProperty
  state: SectionStates[this['property']]
}

// Turn Taking section state
export type TurnTakingSectionState = { [key: string]: TurnStatus } // TODO key needs to be participant uid

// Card Deck section state
export type CardDeckSectionState = {
  [key: string]: CardDeckSectionParticipantState
}

export interface CardDeckSectionParticipantState {
  active: boolean
  card_uid: string
}

// Questionnaire section state
export type QuestionnaireSectionState = { [key: string]: QuestionnaireSectionParticipantState }

export interface QuestionnaireSectionParticipantState {
  active: boolean
  data: any
}

// Todo list section state
export interface TodoListSectionState {
  slideUid: string
  items: TodoListItem[]
  showDownloadButton: boolean
  pdfPayloadHash: string | null
  pdfUrl: string | null
}

// Secret Transmission activity state
export type ActivitySecretTransmissionState = {
  [key: string]: {
    selection: string | null
    flippedCards: {
      [key: string]: boolean
    }
    previousCards: string[]
  }
}

// Bully Guard activity state
export type ActivityBullyGuardState = {
  [key: string]: {
    // TODO key needs to be participant uid
    selection: string | null
    score: number
    assignedCards: string[]
    previousAnswers: string[]
    flippedCards: {
      [key: string]: boolean
    }
    assignedCardsFlipped: {
      [key: string]: boolean
    }
  }
}

// Detection of Expression activity state
export type ActivityDetectionOfExpressionState = {
  [key: string]: {
    previousCards: string[]
  }
}

// Body Signals activity state
export type ActivityBodySignalsState = {
  [key: string]: ActivityBodyCluesState
}

// Invisible ink activity state
export interface InvisibleInkProfileState {
  showEncoded: boolean
  showViewer: boolean
}

export type ActivityInvisibleInkState = {
  [key: string]: InvisibleInkProfileState
}

// Club Rules activity state
export interface ActivityClubRulesState {
  rules: string[]
}

// Emotionometer activity state
export const emotionRanges = ['low', 'medium', 'high'] as const
export type EmotionRange = (typeof emotionRanges)[number]
export type ActivityEmotionometerState = {
  [key in EmotionRange]: string[] // TODO: string is actually participant uid
}

// Help Thought Zapper activity state
export interface HelpfulThoughtsThoughtCloud {
  index: number
  targeted: boolean
  answers: (string | true)[]
  text: string
  answer: string
  options: string[]
  custom: string | null
  approved: boolean
}
export interface HelpfulThoughtsProfileState {
  screen: 'WAITING' | 'SCANNING' | 'READY' | 'FINISHED'
  clouds: HelpfulThoughtsThoughtCloud[]
  currentCloud: HelpfulThoughtsThoughtCloud
  hud: {
    targeted: boolean
    showLazer: boolean
    zapCounter: number
    score: number
  }
  thoughtControls: {
    optionIndex: number
    customOption: string
  }
}

export type ActivityHelpfulThoughtsState = {
  participants: { [key: string]: HelpfulThoughtsProfileState }
  groupPlay: boolean
}

export interface Thought {
  text: string
  answer: string
}

// Relaxation Gadget activity state
export type ParticipantGadgetIdObj = { [key: string]: Gadget['id'][] } // key is participant uid
export interface ActivityRelaxationGadgetsState {
  visibleGadgetIds: ParticipantGadgetIdObj
  requestedGadgetIds: ParticipantGadgetIdObj
}

// Thought Tracker activity state
export type ThoughtTrackerUFOType = 'plane' | 'blimp'
export type ActivityThoughtTrackerStateValue = {
  value: string
  cut: boolean
  cutPosition: null | number
  finishedExiting: boolean
}
export type ActivityThoughtTrackerState = {
  [key: string]: {
    // TODO key needs to be participant uid
    ufoType: ThoughtTrackerUFOType
    editMode: boolean
    selectingPlayer: boolean
    gameOver: boolean
    resetCount: number
    thoughts: ActivityThoughtTrackerStateValue[]
  }
}

// DECODER Scavenger Hunt activity state
export type ScavengerHuntCardKey = 'card1' | 'card2' | 'card3' | 'card4' | 'card5' | 'card6' | 'card7'
export type ScavengerHuntCardLetter = 'd' | 'e' | 'c' | 'o' | 'r'
export type ScavengerHuntSceneObjectKey = 'beanBag'| 'bear'| 'bed'| 'books1'| 'books2'| 'books3'| 'books4'| 'books5'| 'chair'| 'clock'| 'computer'| 'desk'| 'door'| 'lamp'| 'letterA'| 'letterB'| 'letterC'| 'medal'| 'pencils'| 'rug'| 'shelves'| 'toyCar' // prettier-ignore
export type ScavengerHuntMoveStep = 'find' | 'completed' | 'decode'
export interface ScavengerHuntCard {
  id: ScavengerHuntCardKey
  letter: ScavengerHuntCardLetter
  label: string
}
export interface ScavengerHuntClue {
  title: string
  description: string
  solution: ScavengerHuntSceneObjectKey
}
export interface ScavengerHuntAttempt {
  clueIndex: number
  objectKey: ScavengerHuntSceneObjectKey
  attempt: number
  correct: boolean
}
export interface ActivityScavengerHuntState {
  moveStep: ScavengerHuntMoveStep
  clueIndex: number
  jumbledCode: string
  jumbledCardIndexes: number[]
  cardIndexes: { [key: number]: number | null }
  attempts: ScavengerHuntAttempt[]
  initialized: boolean
  showScavenger: boolean
  activeProfileUid: string | null // TODO key needs to be participant uid
  feedbackText: string | null
  showCompleteModal: boolean
  flippedCard: ScavengerHuntCard | null
  correctCard: ScavengerHuntCard | null
  showCorrectModal: boolean
  showIncorrectModal: boolean
  hoveredSceneItem: ScavengerHuntSceneObjectKey | null
}

// Secret Agent Body Signals activity state
export interface BodySignalsClue {
  id: string
  text: string
  image: string
}
export interface BodySignalsCustomClue {
  id: string
  text: string
  symbol: string
}
export interface ActivityBodyCluesState {
  clueOptions: (BodySignalsClue | BodySignalsCustomClue)[]
  clues: (BodySignalsClue | BodySignalsCustomClue | null)[]
}

// Drawing section state
export interface DrawingSectionState {
  canvasLocked: boolean
  actions: DrawingActionTypes[]
  currentActionIndex: number
  thumbnail?: UploadResponse
}

// DECODER Brain Cell activity state
export interface ActivityBrainCellState {
  activeProfileUid: string | null
  currentAnchor: number
  showDrawModal: boolean
  activeDrawing: string
  selectedOptions: {
    [key: string]: boolean
  }
}

// Damage Control activity state
export const DamageControlSteps = ['Step 1', 'Step 2A', 'Step 2B', 'Step 2C', 'Step 3'] as const

export type DamageControlStep = (typeof DamageControlSteps)[number]

type StepsState = {
  [key in DamageControlStep]: string | null
}

export interface DamageControlState extends StepsState {
  active: boolean
  currentStep: DamageControlStep
}

export interface ActivityDamageControlState {
  [key: string]: DamageControlState
}

// Challenger Board Game
export type DiceValue = 1 | 2 | 3 | 4 | 5 | 6
export type BoardGameTileKey = 'START' | number | 'FINISH'
export type BoardGameMoveStep = 'setup' | 'roll' | 'move' | 'draw' | 'discard'
export type BoardGameGameStep = 'ready' | 'generate' | 'gameplay' | 'end'
export type BoardGameCharacterId =
  | 'boy1'
  | 'boy2'
  | 'boy3'
  | 'boy4'
  | 'boy5'
  | 'boy6'
  | 'girl1'
  | 'girl2'
  | 'girl3'
  | 'girl4'
  | 'girl5'
  | 'girl6'
  | 'custom'

export const boardGameDeckTypes = [
  'BULLYING_AND_TEASING',
  'SAS_CHALLENGE',
  'CHANGE_AND_UNCERTAINTY',
  'MEETING_NEW_PEOPLE',
  'MISTAKES_AND_BLUNDERS',
  'PLAYING_WITH_OTHERS',
  'TALKING_TO_OTHERS',
] as const
export type BoardGameDeckType = (typeof boardGameDeckTypes)[number]

export const boardGameTileKeys: BoardGameTileKey[] = ['START', ...Array(51).map((_, i) => 1 + i), 'FINISH']

export const BOARD_GAME_ENCOUNTER = 'ENCOUNTER'
export const BOARD_GAME_BLANK = 'BLANK'

export type BoardGameTileColor = 'green' | 'yellow' | 'blue' | 'black' | 'orange' | 'red' | 'purple' | 'pink'
export type BoardGameTileType = BoardGameDeckType | typeof BOARD_GAME_ENCOUNTER | typeof BOARD_GAME_BLANK

export interface BoardGamePlayer {
  initialised: boolean
  index: number
  name: string
  characterId: BoardGameCharacterId // TODO: this may need to change to accommodate for emojis
  currentTile: BoardGameTileKey
  randomValue?: DiceValue
  customEmoji: string | null
}

export interface BoardGameCharacter {
  id: BoardGameCharacterId
  avatar: any
}

export interface BoardGameEncounter {
  image: any
  text: string
  textArea?: {
    top?: React.CSSProperties['top']
    right?: React.CSSProperties['right']
    bottom?: React.CSSProperties['bottom']
    left?: React.CSSProperties['left']
  }
  moveToTileIndex?: BoardGameTileDefinition['index']
}

export interface BoardGameTileDefinition {
  tileNumber: number
  index: number
  type: BoardGameTileType
  size: number
  side: 'b' | 'bl' | 'l' | 'tl' | 't' | 'tr' | 'r' | 'br'
  color: BoardGameTileColor
  // image?: any
  hasImage?: boolean
  encounterId?: string
}

export interface BoardGameCard extends Omit<CardDeck['cards'][number], 'background_image'> {}
export interface BoardGameCardDeck extends Omit<CardDeck, 'image' | 'cards'> {
  cards: BoardGameCard[]
}

export interface ActivityBoardGameState {
  seed: number
  started: boolean
  activePlayerIndex: number
  gameStep: BoardGameGameStep
  moveStep: BoardGameMoveStep
  players: { [playerUid: string]: BoardGamePlayer }
  deckCardIndexes: { [key in BoardGameDeckType]: number }
  deckCardOrders: { [key in BoardGameDeckType]: number[] }
  activeDeck: BoardGameDeckType | null
  currentDeckCard: BoardGameCard | null
  encounter: BoardGameEncounter | null
  dice: {
    value: DiceValue
    increment: number
  }
  randomPositions: DiceValue[]
  finishedPlayerUid: string | null
  superDice: boolean
  sharingCard: boolean
  postersOpen: boolean
  selectedPoster: 0 | 1 | 2 | 3 | null
}
