import React, { useEffect, useState, useRef } from 'react'
import { Absolute } from './ui'
import { usePersistent } from 'utils/usePersistent'

interface Props {
  wait?: boolean
  transitionTime?: number
  transitionEase?: string
  onComplete?: () => void
}

export const FadeTo: React.FC<Props & { colorFrom?: string; colorTo: string; reversePointerEvents?: boolean }> = ({
  wait = false,
  reversePointerEvents = false,
  colorFrom = 'transparent',
  colorTo,
  transitionTime = 500,
  transitionEase,
  onComplete,
}) => {
  const inited = useRef(false)
  const [fade, setFade] = useState(false)
  const p = usePersistent({ onComplete, transitionTime })
  useEffect(() => {
    let timeout: number = 0
    let timeout2: number = 0
    if (!wait && !inited.current) {
      inited.current = true
      timeout2 = window.setTimeout(() => setFade(true))
      if (p.onComplete) timeout = window.setTimeout(p.onComplete, p.transitionTime + 100)
    }
    return () => {
      window.clearTimeout(timeout)
      window.clearTimeout(timeout2)
    }
  }, [wait, p])
  return (
    <Absolute
      cover
      style={{
        zIndex: 999,
        backgroundColor: fade ? colorTo : colorFrom,
        transition: `background-color ${transitionTime}ms ${transitionEase || 'linear'}`,
        pointerEvents: (reversePointerEvents ? !fade : fade) ? 'auto' : 'none',
      }}
    />
  )
}

export const FadeToBlack: React.FC<Props> = props => <FadeTo {...props} colorTo="black" />
export const FadeFromBlack: React.FC<Props> = props => (
  <FadeTo {...props} colorFrom="black" colorTo="transparent" reversePointerEvents />
)

export const FadeToWhite: React.FC<Props> = props => <FadeTo {...props} colorTo="white" />
export const FadeFromWhite: React.FC<Props> = props => (
  <FadeTo {...props} colorFrom="white" colorTo="transparent" reversePointerEvents />
)
