import React from 'react'
import { MediaGridSection as MediaGridSectionType, SectionPropsBase } from 'shared/session/types'
import { SectionPropsBase as FacManSectionPropsBase } from 'shared/fac-man/types'
import { SectionTitle } from 'session/common/SectionTitle'
import styled from 'styled-components'
import { Media } from 'session/common/Media'
import { HighlightableArea } from 'session/common/HighlightableArea'

interface Props extends SectionPropsBase {
  property: 'media_grid_sections'
}

export const columnWidthPresets: { [key: number]: string } = {
  6: `${150 * 2}w`,
  5: `${184 * 2}w`,
  4: `${235 * 2}w`,
  3: `${318 * 2}w`,
  2: `${487 * 2}w`,
  1: `${992 * 2}w`,
}

export const MediaGridSection: React.FC<Props> = ({ property, section, index, panel }) => {
  const { title, columns, items } = section
  return (
    <>
      {title && <SectionTitle children={title} />}
      <MediaGrid>
        {(items || []).map((item, i) => {
          const InnerComponent =
            panel.theme === 'cork' && columns === 1 ? ('div' as keyof JSX.IntrinsicElements) : MediaGridInner
          return (
            <MediaGridColumn key={i} columns={columns}>
              <HighlightableArea id={`${item.type}-${i}`}>
                <InnerComponent>
                  <Media
                    {...item}
                    widthPreset={columnWidthPresets[columns >= 1 && columns <= 6 ? columns : 1]}
                    ratio={item.media_ratio || 'auto'}
                    loop={item.loop}
                    controls={item.show_controls}
                  />
                </InnerComponent>
              </HighlightableArea>
            </MediaGridColumn>
          )
        })}
      </MediaGrid>
    </>
  )
}

interface GenericProps extends FacManSectionPropsBase {
  property: 'media_grid_sections'
}

const getHeightPercentage = (ratio?: string, width?: number, height?: number) => {
  if (ratio) {
    const ratios = ratio.split(':')
    return (+ratios[1] / +ratios[0]) * 100
  }
  if (width && height) return (height / width) * 100
  return 0
}

export const GenericGridSection: React.FC<GenericProps> = ({ property, section, index }) => {
  return <BasicGridSection {...section} />
}

export const BasicGridSection: React.FC<Pick<MediaGridSectionType, 'title' | 'columns' | 'items'>> = ({
  title,
  columns,
  items,
}) => {
  return (
    <>
      {title && <SectionTitle children={title} />}
      <MediaGrid>
        {(items || []).map((item, i) => (
          <MediaGridColumn key={i} columns={columns} style={{ padding: 8 }}>
            <MediaGridInner
              style={
                item.video
                  ? {}
                  : {
                      margin: 0,
                      width: '100%',
                      height: 0,
                      paddingTop: `${getHeightPercentage(item.media_ratio, item.image?.width, item.image?.height)}%`,
                      position: 'relative',
                    }
              }>
              <Media
                style={item.video ? {} : { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                {...item}
                widthPreset={columnWidthPresets[columns >= 1 && columns <= 6 ? columns : 1]}
                ratio={item.media_ratio || 'auto'}
                loop={item.loop}
                controls={item.show_controls}
              />
            </MediaGridInner>
          </MediaGridColumn>
        ))}
      </MediaGrid>
    </>
  )
}

export const MediaGridSectionFacilitator = MediaGridSection

export const MediaGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
`

export const MediaGridColumn = styled.div<{ columns: number }>`
  box-sizing: border-box;
  flex: ${p => (100 / p.columns).toFixed(4)}%;
  max-width: ${p => (100 / p.columns).toFixed(4)}%;
  & > * {
    & img,
    & video {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
`

export const MediaGridInner = styled.div`
  box-sizing: border-box;
  margin: 8px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd2e4;
  border-radius: 10px;
  background-color: #fff;
`
