import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { DraggableCharacterAvatar } from './CharacterAvatar'
import { characters } from './characters'
import { BoardGamePlayer as Player, BoardGameTileKey as TileKey } from 'shared/session/types'
import { tiles } from 'shared/session/sections/custom/board-game/tiles'

export interface TilePieceAreaProps {
  activePlayerIndex: number
  tileKey: TileKey
  allowHighlight: boolean
  players: { [userUid: string]: Player }
  playerPositions: string[]
  isFacilitator: boolean
  isInteractive: boolean
  style?: CSSProperties
}

const getAvatarSize = ({
  tileKey,
  count,
}: {
  tileKey: TileKey
  count: number
}): { avatarSize: string; innerStyle: CSSProperties } => {
  const innerStyle: CSSProperties = {}
  let avatarSize = '1em'

  if (tileKey === 'START' || tileKey === 'FINISH') {
    innerStyle.padding = '0.35em'
    avatarSize = '3.1em'
    if (count <= 3) avatarSize = '3.6em'
    if (tileKey === 'START') innerStyle.paddingRight = '20%'
    if (tileKey === 'FINISH') innerStyle.paddingLeft = '20%'
  } else {
    const key = typeof tileKey === 'number' ? tileKey : !isNaN(parseInt(tileKey)) ? parseInt(tileKey) : undefined
    const tile = tiles.find(({ index }) => index === key)
    if (!tile) return { avatarSize, innerStyle }

    const vertical = tile.side === 'l' || tile.side === 'r'
    innerStyle.padding = '0.2em'
    if (count >= 4) innerStyle.padding = '0.15em'
    else if (count === 1) innerStyle.padding = `0.45em ${`${innerStyle.padding} `.repeat(3)}`

    if (tile.size === 2) {
      if (count >= 5) {
        innerStyle[vertical ? 'columnGap' : 'rowGap'] = '0.1em'
        avatarSize = vertical ? '1.3em' : '1.6em'
      } else if (count === 4) {
        avatarSize = '1.6em'
        innerStyle.rowGap = '0.1em'
      } else if (count === 3) {
        avatarSize = '1.9em'
        innerStyle[vertical ? 'rowGap' : 'columnGap'] = '0.1em'
      } else if (count <= 2) {
        avatarSize = '2.75em'
        innerStyle.padding = `0.45em 0.2em 0.2em 0.2em`
      }
    } else {
      if (count >= 5) {
        innerStyle.rowGap = 0
        innerStyle.columnGap = '0.15em'
        avatarSize = '1.1em'
      } else if (count >= 3) {
        avatarSize = '1.3em'
        innerStyle.rowGap = '0.15em'
        innerStyle.columnGap = '0.15em'
      } else if (count === 2) {
        avatarSize = '1.5em'
        innerStyle.rowGap = '0.15em'
      } else if (count === 1) {
        avatarSize = '2.75em'
      }
    }
  }
  return { avatarSize, innerStyle }
}

export const TilePieceArea: React.FC<TilePieceAreaProps> = ({
  activePlayerIndex,
  tileKey,
  playerPositions,
  players,
  allowHighlight,
  isFacilitator,
  isInteractive,
  style = {},
}) => {
  const curPlayers = playerPositions.map(playerUid => {
    return { ...players[playerUid], uid: playerUid }
  })
  const { avatarSize, innerStyle } = getAvatarSize({ tileKey, count: curPlayers.length })

  return (
    <TilePieceAreaStyled style={style}>
      <TilePieceAreaInner style={innerStyle} columnWidth={avatarSize}>
        {curPlayers.map((player, i) => {
          if (!player) return null
          const active = player.index === activePlayerIndex
          const character = characters.find(({ id }) => id === player.characterId)
          if (!character) return null
          return (
            <DraggableCharacterAvatar
              key={i}
              playerUid={player.uid}
              character={character}
              size={avatarSize}
              selected={active}
              highlighted={active && allowHighlight}
              allowDrag={(active && isInteractive) || isFacilitator}
              style={{ cursor: 'move' }}
              onMove={tileKey => console.log(`Dropped over tile ${tileKey}`)}
              customEmoji={player.customEmoji}
            />
          )
        })}
      </TilePieceAreaInner>
    </TilePieceAreaStyled>
  )
}

// note auto-fit vs auto-fill
const TilePieceAreaStyled = styled.div`
  position: absolute;
  /* border: 1px dashed red; */
  display: flex;
  flex-direction: column;
`

const TilePieceAreaInner = styled.div<{ columnWidth: string }>`
  flex: auto;
  display: grid;
  column-gap: 0.3em;
  row-gap: 0.2em;
  grid-template-columns: ${p => `repeat(auto-fill, ${p.columnWidth})`};
  grid-template-rows: ${p => `repeat(auto-fill, ${p.columnWidth})`};
`
