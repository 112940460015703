import React, { useEffect, useRef, useState } from 'react'

import { Button, Hr, Input, OutlineButton, Padding, Row, Spacer } from 'common/ui'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { SymbolSelectModal } from 'common/SymbolSelectModal'
import { ValidationErrorList } from 'common/ValidationErrorList'
import { AutosizeTextarea } from 'common/AutosizeTextarea'
import { Field } from 'common/Field'
import { CheckboxButtonGroupField } from 'common/FieldInputs'
import { InlineCheckboxGroup } from 'common/CheckboxGroup'

import { GadgetPackState } from 'shared/gadget-pack/types'
import { ActionTypes as GadgetPackActionTypes } from 'shared/gadget-pack/actionTypes'
import { EmotionometerType, EmotionOption, Gadget, GadgetUseLocation, GadgetUseOption } from 'shared/e-telligence/types'
import { EmotionometerBar } from 'e-telligence/common/EmotionometerBar'
import { AddUpdateCustomRelaxationAction, ADD_UPDATE_CUSTOM_RELAXATION_GADGET } from 'shared/e-telligence/actionTypes'
import { Navigation } from 'e-telligence/common/Navigation'
import { relaxationGadgetEmojis, validate as validateRelaxationGadget } from 'e-telligence/RelaxationGadgetEdit'
import {
  emotionOptions,
  emotionRangeDistribution,
  emotionRangeOptionsUnordered,
  emotionRangeValues,
  gadgetUseLocationOptions,
} from 'e-telligence/constants/typeValueMaps'

import { getInputProps } from 'utils/virtualKeyboard'
import { useUserState } from 'app/UserState'

interface CustomGadgetModalProps {
  gadgetId?: string
  isOpen: boolean
  onRequestClose: () => void
  state: GadgetPackState
  dispatch: (action: GadgetPackActionTypes) => void
}

export const CustomGadgetModal: React.FC<CustomGadgetModalProps> = ({
  isOpen,
  gadgetId,
  onRequestClose,
  state,
  dispatch,
}) => {
  const { getBaseAction } = useUserState()
  const symbolInputRef = useRef<HTMLInputElement>(null)
  const [emojiModalOpen, setEmojiModalOpen] = useState(false)
  const [gadget, setGadget] = useState<Partial<Gadget>>(() =>
    gadgetId ? state.customGadgets.find(test => test.id === gadgetId && !test.isPreset) || {} : {}
  )

  const errors = validateRelaxationGadget(gadget)

  const updateGadget = (data: Partial<Gadget>) => setGadget({ ...gadget, ...data })

  const handleSave = (addToCardHolder?: boolean) => {
    if (errors.length > 0) return
    const saveGadget = { ...gadget } as Gadget
    if (!saveGadget.id) saveGadget.id = `customGadget${Date.now()}`
    dispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_RELAXATION_GADGET,
      gadget: saveGadget,
      addToCardHolder,
    } as AddUpdateCustomRelaxationAction)
    onRequestClose()
  }

  useEffect(() => {
    setGadget(gadgetId ? state.customGadgets.find(test => test.id === gadgetId && !test.isPreset) || {} : {})
  }, [gadgetId, state.customGadgets])

  return (
    <>
      <MobileModalPanel isOpen={isOpen} onRequestClose={onRequestClose}>
        <Navigation title="Make your own" buttonLabel="Back" buttonPress={() => onRequestClose()} />
        <Padding size="s" style={{ flex: 'none' }}>
          <Field label="Enter a name for your gadget">
            <Input
              autoFocus
              value={gadget.title || ''}
              onChange={e => updateGadget({ title: e.target.value })}
              maxLength={20}
              placeholder="20 characters max"
            />
          </Field>
          <Spacer />
          <Field label="Symbol">
            <Input
              ref={symbolInputRef}
              value={gadget.symbol || ''}
              style={{ fontSize: 28, width: 60, height: 50, padding: gadget.symbol ? 12 : 5, textAlign: 'center' }}
              readOnly
              placeholder="+"
              onClick={() => setEmojiModalOpen(true)}
            />
          </Field>
        </Padding>
        <Hr />
        <Padding size="s" style={{ flex: 'none' }}>
          <Field label="Where to use it">
            <InlineCheckboxGroup<GadgetUseOption>
              name="location"
              size="s"
              spacerSize="s"
              options={gadgetUseLocationOptions.filter(({ selectable }) => selectable)}
              value={gadget.location || []}
              onChange={location => updateGadget({ location: location as GadgetUseLocation[] })}
            />
          </Field>
        </Padding>
        <Hr />
        <Padding size="s" style={{ flex: 'none' }}>
          <Field label="What emotions(s) is it best used for?">
            <CheckboxButtonGroupField<EmotionometerType, EmotionOption>
              size="s"
              options={emotionOptions}
              value={gadget.emotion || []}
              onChange={emotion => updateGadget({ emotion: emotion || [] })}
              Component={OutlineButton}
              propsMapper={props => ({ ...props, selected: false, filled: !!props.selected, theme: 'blue' })}
            />
          </Field>
          <Spacer size="s" />
          <Field label="Select the emotion range(s) that it is useful for:">
            <EmotionometerBar
              type="anger"
              value={(gadget.emotionRange || [])
                .map(rangeVal => emotionRangeValues.indexOf(rangeVal))
                .filter(num => num >= 0)}
              distribution={emotionRangeDistribution}
              labels={emotionRangeOptionsUnordered.map(({ label }) => label)}
              onSelectSegment={indexes => {
                if (indexes.length >= 3) indexes = [indexes[indexes.length - 1], indexes[indexes.length - 2]]
                if (Math.max(...indexes) - Math.min(...indexes) < 2) {
                  updateGadget({
                    emotionRange: indexes.map(num => emotionRangeOptionsUnordered[num].value),
                  })
                }
              }}
            />
          </Field>
        </Padding>
        <Hr />
        <Padding size="s" style={{ flex: 'none' }}>
          <Field label="Explain how it works:" hint="List the steps involved in using this strategy to calm down.">
            <AutosizeTextarea
              placeholder={`e.g. Use my meditation app for 5-10 mins.`}
              value={gadget.description || ''}
              onChange={(e: any) => updateGadget({ description: e.target.value || '' })}
              style={{ minHeight: 80, minWidth: 260 }}
              {...getInputProps()}
            />
          </Field>
        </Padding>

        <Spacer size="s" flex="1 1 auto" />
        <Padding size="s" style={{ flex: 'none' }}>
          <Row>
            <ValidationErrorList errors={errors} style={{ color: '#19345d', border: '1px solid orange', padding: 5 }} />
          </Row>
          <Spacer size="s" flex="1 1 auto" />
          <Row justifyContent="center" padding="s">
            <Button onClick={() => handleSave()} size="s" disabled={errors.length > 0}>
              Save
            </Button>
          </Row>
        </Padding>
      </MobileModalPanel>
      <SymbolSelectModal
        isOpen={emojiModalOpen}
        symbols={relaxationGadgetEmojis}
        // disabledSymbols={state.customGadgets.map(({ symbol }) => symbol).filter(_ => _) as string[]}
        value={gadget.symbol || null}
        onChange={symbol => {
          updateGadget({ symbol })
          setTimeout(() => setEmojiModalOpen(false), 150)
        }}
        onClose={() => {
          setEmojiModalOpen(false)
          setTimeout(() => symbolInputRef.current && symbolInputRef.current.blur())
        }}
      />
    </>
  )
}
