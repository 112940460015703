import { useEffect, useState, useRef } from 'react'

export function useVisibilityChange() {
  const [visible, setVisible] = useState<boolean>(document.visibilityState === 'visible')
  const lastVisible = useRef<boolean>(document.visibilityState === 'visible')
  useEffect(() => {
    const handleChange = () => {
      const newVisible = document.visibilityState === 'visible'
      if (lastVisible.current !== newVisible) {
        lastVisible.current = newVisible
        setVisible(newVisible)
      }
    }
    document.addEventListener('visibilitychange', handleChange)
    return () => {
      document.removeEventListener('visibilitychange', handleChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return visible
}
