import { LinkButton } from 'common/LinkButton'
import { SpinnerWithLabel } from 'common/Spinner'
import { H3, Hr, P, Row } from 'common/ui'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'
import { Page } from 'dashboards/common/Page'
import { SessionCell } from 'dashboards/common/SessionCell'
import { TableOfContents, TableOfContentsResponse } from 'dashboards/common/TableOfContents'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'
import { NavRouteProps } from 'dashboards/types'
import { useApiEndpoint, useEndpoint } from 'dashboards/utils/endpointHooks'
import { sessionsFilterPast, sessionsFilterType } from 'dashboards/utils/reducers'
import { NotAuthorizedPage } from 'home/NotAuthorizedPage'
import React, { Fragment, useState } from 'react'
import { CircleButton } from 'session/common/CircleButton'
import { AnyModuleCode, ParentPrepModuleCode } from 'shared/dashboard/moduleCodes'
import { SessionEntity } from 'shared/dashboard/types'
import { ShowText } from 'training/sections/GroupedSection'
import { useMentorDashData, useMentorDashState } from './MentorDashboardState'
import { getModuleMetadata } from 'api'
import { useUserState } from 'app/UserState'

const parentPrepInfoCodes = [
  'parent-info-1',
  'parent-info-2',
  'parent-info-3',
  'parent-info-4',
] as const satisfies Readonly<AnyModuleCode[]>
type ParentPrepInfoCode = (typeof parentPrepInfoCodes)[number]

export const MeetingNotes: React.FC<NavRouteProps> = ({ route }) => {
  const {
    mentorUid,
    endpoints: {
      mentor: { value: mentor, loading: loadingMentor },
      group: { value: group, loading: loadingGroup },
    },
  } = useMentorDashState()
  const [sessions, loadingSessions] = useMentorDashData('sessions')
  if (loadingMentor) return <Page route={route} children={<SpinnerWithLabel label="Verifying mentor..." />} />
  if (mentor && !mentor.permission_past_parent_sessions) return <NotAuthorizedPage />

  const pastSessions = !loadingSessions && sessions ? sessionsFilterType(sessionsFilterPast(sessions), 'parent') : []

  return (
    <Page route={route}>
      {loadingSessions || loadingGroup ? (
        <SpinnerWithLabel color="#858cac" label="Loading meeting notes..." />
      ) : (
        <>
          {pastSessions.length > 0 ? (
            <Cells>
              {pastSessions.map((session, idx) => {
                const isPrep = parentPrepInfoCodes.includes(session.module_code as ParentPrepInfoCode)
                const nextSession = pastSessions[idx + 1]
                const nextSessionPrep =
                  nextSession && parentPrepInfoCodes.includes(nextSession.module_code as ParentPrepInfoCode)
                return (
                  <Fragment key={session.uid}>
                    {isPrep && <Hr margin="l" />}
                    <SessionCell
                      session={session}
                      group={group || undefined}
                      context="past"
                      userType="mentor"
                      mentorUid={mentorUid || undefined}
                    />
                    {isPrep && (
                      <ParentPrep
                        sessions={sessions}
                        mentorUid={mentorUid}
                        prepCode={session.module_code as ParentPrepInfoCode}
                      />
                    )}
                    {isPrep && nextSession && !nextSessionPrep && <Hr margin="l" />}
                  </Fragment>
                )
              })}
            </Cells>
          ) : (
            <P>Your meeting notes will appear after each Parent Group Meeting.</P>
          )}
        </>
      )}
    </Page>
  )
}

const ParentPrep: React.FC<{
  sessions: SessionEntity[] | undefined
  mentorUid: string | null
  prepCode: ParentPrepInfoCode
}> = ({ sessions, mentorUid, prepCode }) => {
  if (!mentorUid || !sessions) return null

  const validSessions = sessions.filter(({ type, concluded }) => type === 'parent' && concluded)
  const prep1Unlocked = !!validSessions.find(({ module_code }) => module_code === 'parent-info-1')
  const prep2Unlocked = !!validSessions.find(({ module_code }) => module_code === 'parent-info-2')
  const prep3Unlocked = !!validSessions.find(({ module_code }) => module_code === 'parent-info-3')
  const prep4Unlocked = !!validSessions.find(({ module_code }) => module_code === 'parent-info-4')

  const prepModuleCodes /*: { [key: string]: string[] }*/ = {
    'parent-info-1': !prep1Unlocked
      ? []
      : (['parent-prep-1', 'parent-prep-2', 'parent-prep-3'] as ParentPrepModuleCode[]),
    'parent-info-2': !prep2Unlocked
      ? []
      : (['parent-prep-4', 'parent-prep-5', 'parent-prep-6'] as ParentPrepModuleCode[]),
    'parent-info-3': !prep3Unlocked ? [] : (['parent-prep-7', 'parent-prep-8'] as ParentPrepModuleCode[]),
    'parent-info-4': !prep4Unlocked ? [] : (['parent-prep-9'] as ParentPrepModuleCode[]),
  } as const satisfies Record<ParentPrepInfoCode, ParentPrepModuleCode[]>

  return (
    <div style={{ marginLeft: '2rem' }}>
      <H3 style={{ fontSize: '1rem', marginBottom: '1rem' }}>Parent Preparation</H3>
      {(prep1Unlocked || prep2Unlocked || prep3Unlocked) && (
        <Cells>
          {prepModuleCodes[prepCode].map((moduleCode, i) => (
            <ParentPrepCell key={i} moduleCode={moduleCode} mentorUid={mentorUid} />
          ))}
        </Cells>
      )}
    </div>
  )
}

const ParentPrepCell: React.FC<{ moduleCode: AnyModuleCode; mentorUid: string }> = ({ moduleCode, mentorUid }) => {
  const { accessToken } = useUserState()
  const [tocExpanded, setTocExpanded] = useState<boolean>(false)
  const [moduleMetadata, { loading: loadingMetadata }] = useApiEndpoint<typeof getModuleMetadata>(
    getModuleMetadata,
    true,
    'parent',
    moduleCode,
    accessToken
  )
  const [toc, { loading: loadingToc }] = useEndpoint<TableOfContentsResponse>(
    moduleMetadata && moduleMetadata.toc_enabled && tocExpanded ? `/api/v1/modules/parent/${moduleCode}/toc` : null
  )

  return (
    <Cell accent="parentGreen">
      <CellInner flex="1 1 auto" justifyContent="space-between" style={{ minHeight: 50 }}>
        <H3 style={{ color: '#4EBE40', marginBottom: 0 }}>{getModuleTitle(moduleCode as AnyModuleCode)}</H3>
        <LinkButton
          type="button"
          size="s"
          theme="parentGreen"
          children="View"
          to={`/mentor/${mentorUid}/prepare/${moduleCode}`}
        />
      </CellInner>
      {!loadingMetadata && moduleMetadata && moduleMetadata.toc_enabled && (
        <>
          <CellInner
            justifyContent="flex-end"
            paddingTop={10}
            paddingLeft={12}
            paddingRight={12}
            marginLeft="0 !important"
            marginRight="0 !important"
            marginTop="0 !important"
            style={{ borderTop: '1px solid #BDC3E0' }}>
            <Row
              alignItems="center"
              onClick={() => {
                setTocExpanded(!tocExpanded)
              }}
              style={{ cursor: 'pointer' }}>
              <ShowText style={{ marginRight: 10 }}>{tocExpanded ? 'HIDE content' : 'SHOW Contents'}</ShowText>
              <CircleButton
                style={{ cursor: 'pointer' }}
                theme="parentGreen"
                size="xs"
                children={tocExpanded ? '▲' : '▼'}
              />
            </Row>
          </CellInner>
          <TableOfContents
            loading={loadingToc}
            contents={tocExpanded ? toc : null}
            meetingUrl={`/mentor/${mentorUid}/prepare/${moduleCode}`}
          />
        </>
      )}
    </Cell>
  )
}
