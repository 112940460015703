import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Column, CUT_BL, CUT_BR, CUT_TL, CUT_TR, P, Panel, Row, Spacer, TAB_B_L, TAB_T_L } from 'common/ui'
import { EmotionometerDevice, EmotionometerStickerBase, EmotionometerType } from 'shared/e-telligence/types'
import { TextSymbolInput, TextSymbolValue } from 'common/TextSymbolInput'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { stickerEmojis } from 'e-telligence/common/StickerEditModal'
import { emotionPanelThemes } from './EmotionometerActivity'

export const CustomStickerModal: React.FC<ReactModal.Props & {
  onAdd: (sticker: EmotionometerStickerBase) => void
  emotion: EmotionometerType
  type: EmotionometerDevice
}> = ({ isOpen, onRequestClose, onAdd, emotion, type }) => {
  const [value, setValue] = useState<TextSymbolValue>({})
  useEffect(() => {
    if (!isOpen && (value.text || value.symbol)) setValue({})
  }, [isOpen, value.text, value.symbol])
  return (
    <MobileModalPanel
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      panelStyle={{ border: 'none', borderRadius: 10, width: 600, boxShadow: 'none' }}>
      <Panel flair={[CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L]} theme="white">
        <Column style={{ maxHeight: 'calc(100vh - 25px)', overflow: 'hidden' }} padding="m" paddingBottom="l">
          <Title emotion={emotion}>Make your own:</Title>
          <Spacer size="m" />
          <P>Body Clue:</P>
          <TextSymbolInput
            value={value}
            onChange={value => setValue(value)}
            inputProps={{ inputSize: 'l' }}
            symbols={stickerEmojis[type]}
          />
          <Spacer size="m" />
          <Row justifyContent="center">
            <Button children="Cancel" theme="white" onClick={onRequestClose} style={{ cursor: 'pointer' }} />
            <Spacer size="m" />
            <Button
              disabled={!value.text || !value.symbol}
              onClick={() =>
                onAdd({
                  type: type,
                  id: value.text || '',
                  title: value.text || '',
                  symbol: value.symbol || '',
                  isPreset: false,
                })
              }
              children="Create"
              style={{ cursor: 'pointer' }}
            />
          </Row>
        </Column>
      </Panel>
    </MobileModalPanel>
  )
}

const Title = styled.h4<{ emotion: EmotionometerType }>`
  color: ${p => emotionPanelThemes[p.emotion]};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  text-transform: capitalize;
`
