import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Column, Padding, Row } from 'common/ui'
import { FriendshipFormulaScreen } from 'gadget-pack/FriendshipFormula'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { fontBold, fontRegular } from 'fonts'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import { UPDATE_FRIENDSHIP_FORMULA } from 'shared/gadget-pack/actionTypes'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { useSessionState } from 'session/SessionState'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'

const prefillOptions: string[] = [
  'Negotiates',
  'Kind',
  'Gentle',
  'Friendly',
  "Doesn't gossip",
  'Same interests',
  'Talks to you',
  'Hangs with you',
  'Helps you',
  'Not bossy',
  'Liked by others',
  "Doesn't brag",
  'Keeps secrets',
  'Funny',
  'Listens to you',
  'Not annoying',
  'Good hygiene',
  'Plays well',
  "Doesn't tease",
  'Tells the truth',
]

export const FriendshipFormulaActivity: React.FC = () => {
  const participantUid = useParticipantProfileUid()
  const { getBaseAction } = useSessionState()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedInput, setSelectedInput] = useState<number | null>(null)
  const { dispatch } = useGadgetPackState()

  const assignTextBoxValue = (value: string) => {
    setModalOpen(false)

    if (selectedInput !== null) {
      dispatch({
        ...getBaseAction(),
        type: UPDATE_FRIENDSHIP_FORMULA,
        index: selectedInput,
        data: { text: value },
      })
    }
  }

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${participantUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'friendship-formula',
          subcategory: null,
          event_key: null,
          event_value: null,
        }}
      />
      <FriendshipFormulaContainer>
        <FriendshipFormulaScreen
          hideExitButton
          hideTitle
          inputOnClick={index => {
            setModalOpen(true)
            setSelectedInput(index)
          }}
          inputLockProps={{
            fieldUid: `${participantUid}_friendship_formula`,
            horizontalPlacementFacilitator: 2,
            verticalPlacementFacilitator: -17,
          }}
        />
      </FriendshipFormulaContainer>
      <MobileModalPanel
        panelStyle={{ boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)', maxWidth: 1024 }}
        isOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false)
        }}>
        <Padding>
          <ModalTitle>Pick one OR write your own:</ModalTitle>
          <Row className="friendship-prefill-row" flexWrap>
            {prefillOptions.map((title, idx) => (
              <Choice key={idx} onClick={() => assignTextBoxValue(title)}>
                {title}
              </Choice>
            ))}
          </Row>
          <Row justifyContent="center">
            <ModalButton
              theme="white"
              children="Make Your Own"
              onClick={() => {
                setModalOpen(false)
              }}
            />
            <Button
              children="Close"
              onClick={() => {
                setModalOpen(false)
              }}
              style={{ cursor: 'pointer', width: 200, marginTop: 20, marginBottom: 10 }}
            />
          </Row>
        </Padding>
      </MobileModalPanel>
    </>
  )
}

const FriendshipFormulaContainer = styled.div`
  position: relative;

  > ${Padding} > ${Column} {
    max-width: 100%;
  }

  > ${Padding} {
    padding: 0;
  }

  .activity-panel {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }

  .friendship {
    &-container {
      width: 75%;
      justify-content: space-between;
    }

    &-flask {
    }

    &-input-block {
      width: 70%;

      input {
        padding: 15px 12px !important;
      }
    }

    &-input-row {
      margin-bottom: 10px;

      > ${Column} {
        width: 100%;
      }

      & .facilitator-only-padding {
        padding-top: 10px !important;
        transform: translateY(-10px);
      }
    }

    &-button {
      width: 100px;
      margin-left: 60px;
    }

    &-prefill-row {
      width: 100%;
    }
  }

  .emoji-btn {
    height: 49px !important;
  }
`

const ModalTitle = styled.p`
  ${fontBold};
  font-size: 20px;
  color: #011a46;
  text-align: center;
  letter-spacing: 0.05em;
`

const ModalButton = styled(Button)`
  cursor: pointer;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  border: none;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);

  &:after {
    display: none;
  }
`

const Choice = styled.div`
  ${fontRegular};
  box-sizing: border-box;
  border: 1px solid #cdd2e4;
  border-radius: 10px;
  padding: 12px 20px;
  width: calc(25% - 20px);
  margin: 10px;
  text-align: center;
  color: #011a46;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #fff;
  }
`
