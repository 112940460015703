import { Vector } from 'types'
import { AssetCategory } from '../types'
import { InteractionHitArea } from 'phaser-types'
import { Geom } from 'phaser'
import { LoadAssetsObject } from 'utils/gameUtils'

import requireImageSize from '../../macros/requireImageSize.macro.js'
import requireResizedImage from '../../macros/requireResizedImage.macro.js'

// const assetDimensions = requireImageSize('../assets/objects/baseball.png')
// console.log(assetDimensions)
// const resizedAsset = requireResizedImage('../assets/objects/baseball.png', { width: 120, height: 80, fit: 'contain' })
// console.log(resizedAsset)

const tileImageConfig = {
  maxWidth: 300,
  maxHeight: 236,
  fit: 'contain',
}

export type SceneAsset = {
  key: string
  label: string
  url: string
  thumbnail: string
  dimensions: { width: number; height: number }
  scale?: number
  center?: Vector
  category?: AssetCategory
  text?: string
  hitArea?: InteractionHitArea
}

export type SceneAssets = { [k: string]: { [k: string]: SceneAsset | { [k: string]: SceneAsset } } }

export const sceneAssets: SceneAssets = {
  objects: {
    baseball: {
      key: 'objects_baseball',
      label: 'Baseball',
      url: require('../assets/objects/baseball.png'),
      thumbnail: requireResizedImage('../assets/objects/baseball.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/baseball.png'),
      scale: 0.1,
    },
    basketball: {
      key: 'objects_basketball',
      label: 'Basketball',
      url: require('../assets/objects/basketball.png'),
      thumbnail: requireResizedImage('../assets/objects/basketball.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/basketball.png'),
      scale: 0.25,
    },
    bat: {
      key: 'objects_bat',
      label: 'Baseball Bat',
      url: require('../assets/objects/bat.png'),
      thumbnail: requireResizedImage('../assets/objects/bat.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/bat.png'),
      hitArea: new Geom.Polygon(
        [
          { x: 0, y: 0 },
          { x: 32, y: 0 },
          { x: 300, y: 163 },
          { x: 300, y: 184 },
          { x: 277, y: 184 },
          { x: 0, y: 29 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    blocks: {
      key: 'objects_blocks',
      label: 'Construction Set',
      url: require('../assets/objects/blocks.png'),
      thumbnail: requireResizedImage('../assets/objects/blocks.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/blocks.png'),
      scale: 0.25,
      hitArea: new Geom.Polygon(
        [
          { x: 0, y: 140 },
          { x: 66, y: 148 },
          { x: 96, y: 148 },
          { x: 117, y: 133 },
          { x: 118, y: 99 },
          { x: 151, y: 87 },
          { x: 151, y: 127 },
          { x: 195, y: 147 },
          { x: 261, y: 117 },
          { x: 261, y: 38 },
          { x: 235, y: 23 },
          { x: 208, y: 25 },
          { x: 208, y: 0 },
          { x: 160, y: 0 },
          { x: 158, y: 25 },
          { x: 105, y: 21 },
          { x: 74, y: 1 },
          { x: 3, y: 3 },
          { x: 10, y: 73 },
          { x: 0, y: 77 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    boardgame: {
      key: 'objects_boardgame',
      label: 'Board game',
      url: require('../assets/objects/boardgame.png'),
      thumbnail: requireResizedImage('../assets/objects/boardgame.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/boardgame.png'),
    },
    book: {
      key: 'objects_book',
      label: 'Book',
      url: require('../assets/objects/book.png'),
      thumbnail: requireResizedImage('../assets/objects/book.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/book.png'),
      scale: 0.3,
    },
    cat: {
      key: 'objects_cat',
      label: 'Cat',
      url: require('../assets/objects/cat.png'),
      thumbnail: requireResizedImage('../assets/objects/cat.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/cat.png'),
      scale: 0.3,
    },
    chair: {
      key: 'objects_chair',
      label: 'Arm chair',
      url: require('../assets/objects/chair.png'),
      thumbnail: requireResizedImage('../assets/objects/chair.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/chair.png'),
      scale: 0.8,
    },
    controller: {
      key: 'objects_controller',
      label: 'Video game',
      url: require('../assets/objects/controller.png'),
      thumbnail: requireResizedImage('../assets/objects/controller.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/controller.png'),
      scale: 0.2,
    },
    cricketball: {
      key: 'objects_cricketball',
      label: 'Cricket Ball',
      url: require('../assets/objects/cricketball.png'),
      thumbnail: requireResizedImage('../assets/objects/cricketball.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/cricketball.png'),
      scale: 0.14,
    },
    cricketbat: {
      key: 'objects_cricketbat',
      label: 'Cricket Bat',
      url: require('../assets/objects/cricketbat.png'),
      thumbnail: requireResizedImage('../assets/objects/cricketbat.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/cricketbat.png'),
      hitArea: new Geom.Polygon(
        [
          { x: 300, y: 0 },
          { x: 277, y: 0 },
          { x: 184, y: 64 },
          { x: 164, y: 55 },
          { x: 0, y: 171 },
          { x: 0, y: 223 },
          { x: 46, y: 223 },
          { x: 203, y: 114 },
          { x: 210, y: 88 },
          { x: 300, y: 23 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    dog: {
      key: 'objects_dog',
      label: 'Dog',
      url: require('../assets/objects/dog.png'),
      thumbnail: requireResizedImage('../assets/objects/dog.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/dog.png'),
      scale: 0.3,
      hitArea: new Geom.Polygon(
        [
          { x: 3, y: 452 },
          { x: 47, y: 462 },
          { x: 83, y: 443 },
          { x: 101, y: 297 },
          { x: 118, y: 303 },
          { x: 121, y: 418 },
          { x: 114, y: 465 },
          { x: 172, y: 465 },
          { x: 178, y: 284 },
          { x: 200, y: 314 },
          { x: 184, y: 358 },
          { x: 198, y: 382 },
          { x: 300, y: 389 },
          { x: 300, y: 277 },
          { x: 275, y: 141 },
          { x: 227, y: 103 },
          { x: 161, y: 103 },
          { x: 152, y: 88 },
          { x: 165, y: 34 },
          { x: 132, y: 0 },
          { x: 25, y: 0 },
          { x: 0, y: 17 },
          { x: 0, y: 95 },
          { x: 26, y: 107 },
          { x: 13, y: 207 },
          { x: 33, y: 287 },
          { x: 29, y: 404 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    football: {
      key: 'objects_football',
      label: 'Rugby Ball / Football',
      url: require('../assets/objects/football.png'),
      thumbnail: requireResizedImage('../assets/objects/football.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/football.png'),
      scale: 0.3,
    },
    game: {
      key: 'objects_game',
      label: 'Handheld Game',
      url: require('../assets/objects/game.png'),
      thumbnail: requireResizedImage('../assets/objects/game.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/game.png'),
      scale: 0.25,
      hitArea: new Geom.Polygon(
        [
          { x: 0, y: 61 },
          { x: 0, y: 22 },
          { x: 154, y: 0 },
          { x: 300, y: 10 },
          { x: 300, y: 58 },
          { x: 288, y: 101 },
          { x: 223, y: 101 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    instrument: {
      key: 'objects_instrument',
      label: 'Musical Instrument',
      url: require('../assets/objects/instrument.png'),
      thumbnail: requireResizedImage('../assets/objects/instrument.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/instrument.png'),
      hitArea: new Geom.Polygon(
        [
          { x: 0, y: 22 },
          { x: 0, y: 4 },
          { x: 15, y: 0 },
          { x: 39, y: 23 },
          { x: 36, y: 0 },
          { x: 87, y: 0 },
          { x: 134, y: 20 },
          { x: 167, y: 17 },
          { x: 300, y: 65 },
          { x: 300, y: 124 },
          { x: 265, y: 149 },
          { x: 218, y: 147 },
          { x: 233, y: 159 },
          { x: 189, y: 159 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    laptop: {
      key: 'objects_laptop',
      label: 'Laptop',
      url: require('../assets/objects/laptop.png'),
      thumbnail: requireResizedImage('../assets/objects/laptop.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/laptop.png'),
      scale: 0.35,
    },
    mp3: {
      key: 'objects_mp3',
      label: 'MP3 Player',
      url: require('../assets/objects/mp3.png'),
      thumbnail: requireResizedImage('../assets/objects/mp3.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/mp3.png'),
      scale: 0.25,
    },
    paper: {
      key: 'objects_paper',
      label: 'Paper',
      url: require('../assets/objects/paper.png'),
      thumbnail: requireResizedImage('../assets/objects/paper.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/paper.png'),
      scale: 0.35,
    },
    pen: {
      key: 'objects_pen',
      label: 'Pen',
      url: require('../assets/objects/pen.png'),
      thumbnail: requireResizedImage('../assets/objects/pen.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/pen.png'),
      scale: 0.2,
      hitArea: new Geom.Polygon(
        [
          { x: 0, y: 100 },
          { x: 86, y: 100 },
          { x: 300, y: 37 },
          { x: 300, y: 0 },
          { x: 249, y: 0 },
          { x: 0, y: 71 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    puck: {
      key: 'objects_puck',
      label: 'Puck',
      url: require('../assets/objects/puck.png'),
      thumbnail: requireResizedImage('../assets/objects/puck.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/puck.png'),
      scale: 0.12,
    },
    skis: {
      key: 'objects_skis',
      label: 'Skis',
      url: require('../assets/objects/skis.png'),
      thumbnail: requireResizedImage('../assets/objects/skis.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/skis.png'),
      scale: 0.7,
      hitArea: new Geom.Polygon(
        [
          { x: 92, y: 269 },
          { x: 118, y: 269 },
          { x: 91, y: 157 },
          { x: 161, y: 258 },
          { x: 155, y: 209 },
          { x: 211, y: 218 },
          { x: 229, y: 226 },
          { x: 243, y: 184 },
          { x: 247, y: 231 },
          { x: 266, y: 230 },
          { x: 255, y: 159 },
          { x: 281, y: 99 },
          { x: 300, y: 70 },
          { x: 300, y: 39 },
          { x: 269, y: 60 },
          { x: 247, y: 128 },
          { x: 248, y: 82 },
          { x: 228, y: 39 },
          { x: 206, y: 48 },
          { x: 237, y: 161 },
          { x: 214, y: 216 },
          { x: 153, y: 207 },
          { x: 61, y: 87 },
          { x: 39, y: 0 },
          { x: 19, y: 2 },
          { x: -2, y: 36 },
          { x: 41, y: 94 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    soccer: {
      key: 'objects_soccer',
      label: 'Soccer Ball / Football',
      url: require('../assets/objects/soccer.png'),
      thumbnail: requireResizedImage('../assets/objects/soccer.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/soccer.png'),
      scale: 0.25,
    },
    soccernet: {
      key: 'objects_soccernet',
      label: 'Net',
      url: require('../assets/objects/soccernet.png'),
      thumbnail: requireResizedImage('../assets/objects/soccernet.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/soccernet.png'),
      hitArea: new Geom.Polygon(
        [
          { x: 2, y: 455 },
          { x: 21, y: 455 },
          { x: 126, y: 385 },
          { x: 761, y: 385 },
          { x: 890, y: 455 },
          { x: 910, y: 455 },
          { x: 910, y: 0 },
          { x: 2, y: 0 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    stick: {
      key: 'objects_stick',
      label: 'Hockey Stick',
      url: require('../assets/objects/stick.png'),
      thumbnail: requireResizedImage('../assets/objects/stick.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/stick.png'),
      hitArea: new Geom.Polygon(
        [
          { x: 0, y: 350 },
          { x: 43, y: 350 },
          { x: 131, y: 326 },
          { x: 300, y: 37 },
          { x: 300, y: 0 },
          { x: 275, y: 0 },
          { x: 97, y: 277 },
          { x: 0, y: 315 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    stressball: {
      key: 'objects_stressball',
      label: 'Stress Ball',
      url: require('../assets/objects/stressball.png'),
      thumbnail: requireResizedImage('../assets/objects/stressball.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/stressball.png'),
      scale: 0.2,
      hitArea: new Geom.Polygon(
        [
          { x: 0, y: 189 },
          { x: 64, y: 189 },
          { x: 174, y: 145 },
          { x: 248, y: 165 },
          { x: 300, y: 129 },
          { x: 300, y: 27 },
          { x: 279, y: 0 },
          { x: 176, y: 0 },
          { x: 133, y: 43 },
          { x: 147, y: 116 },
          { x: 0, y: 157 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    studychair: {
      key: 'objects_studychair',
      label: 'Chair',
      url: require('../assets/objects/studychair.png'),
      thumbnail: requireResizedImage('../assets/objects/studychair.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/studychair.png'),
      scale: 0.4,
      hitArea: new Geom.Polygon(
        [
          { x: 35, y: 0 },
          { x: 266, y: 0 },
          { x: 269, y: 114 },
          { x: 300, y: 117 },
          { x: 300, y: 163 },
          { x: 286, y: 211 },
          { x: 300, y: 274 },
          { x: 256, y: 290 },
          { x: 257, y: 314 },
          { x: 216, y: 344 },
          { x: 300, y: 420 },
          { x: 300, y: 464 },
          { x: 204, y: 405 },
          { x: 249, y: 536 },
          { x: 202, y: 536 },
          { x: 162, y: 443 },
          { x: 121, y: 430 },
          { x: 44, y: 522 },
          { x: 5, y: 499 },
          { x: 46, y: 431 },
          { x: 37, y: 382 },
          { x: 93, y: 343 },
          { x: 54, y: 331 },
          { x: 46, y: 287 },
          { x: 0, y: 275 },
          { x: 16, y: 218 },
          { x: 0, y: 167 },
          { x: 0, y: 122 },
          { x: 31, y: 120 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
    studydesk: {
      key: 'objects_studydesk',
      label: 'Desk',
      url: require('../assets/objects/studydesk.png'),
      thumbnail: requireResizedImage('../assets/objects/studydesk.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/studydesk.png'),
      scale: 0.7,
    },
    table: {
      key: 'objects_table',
      label: 'Coffee Table',
      url: require('../assets/objects/table.png'),
      thumbnail: requireResizedImage('../assets/objects/table.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/table.png'),
      scale: 0.7,
    },
    tennisball: {
      key: 'objects_tennisball',
      label: 'Tennis Ball',
      url: require('../assets/objects/tennisball.png'),
      thumbnail: requireResizedImage('../assets/objects/tennisball.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/tennisball.png'),
      scale: 0.12,
    },
    tennisracket: {
      key: 'objects_tennisracket',
      label: 'Tennis Racquet',
      url: require('../assets/objects/tennisracket.png'),
      thumbnail: requireResizedImage('../assets/objects/tennisracket.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/tennisracket.png'),
      hitArea: new Geom.Polygon(
        [
          { x: 45, y: 1 },
          { x: 90, y: 1 },
          { x: 135, y: 28 },
          { x: 165, y: 71 },
          { x: 193, y: 134 },
          { x: 221, y: 167 },
          { x: 300, y: 234 },
          { x: 288, y: 249 },
          { x: 205, y: 189 },
          { x: 160, y: 171 },
          { x: 94, y: 157 },
          { x: 34, y: 132 },
          { x: 9, y: 101 },
          { x: 0, y: 62 },
          { x: 14, y: 23 },
        ].map(({ x, y }) => new Geom.Point(x, y))
      ),
    },
  },
  scenes: {
    bedroom: {
      key: 'scenes_bedroom',
      label: 'Bedroom',
      url: require('../assets/scenes/bedroom@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/bedroom@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/bedroom@2x.jpg'),
    },
    classroom: {
      key: 'scenes_classroom',
      label: 'Classroom',
      url: require('../assets/scenes/classroom@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/classroom@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/classroom@2x.jpg'),
    },
    hall: {
      key: 'scenes_hall',
      label: 'Stage',
      url: require('../assets/scenes/hall@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/hall@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/hall@2x.jpg'),
    },
    kitchen: {
      key: 'scenes_kitchen',
      label: 'Kitchen / Restaurant',
      url: require('../assets/scenes/kitchen@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/kitchen@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/kitchen@2x.jpg'),
    },
    library: {
      key: 'scenes_library',
      label: 'Library / Study',
      url: require('../assets/scenes/library@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/library@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/library@2x.jpg'),
    },
    loungeroom: {
      key: 'scenes_loungeroom',
      label: 'Lounge room',
      url: require('../assets/scenes/loungeroom@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/loungeroom@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/loungeroom@2x.jpg'),
    },
    oval: {
      key: 'scenes_oval',
      label: 'Oval / Playing Field',
      url: require('../assets/scenes/oval@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/oval@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/oval@2x.jpg'),
    },
    park: {
      key: 'scenes_park',
      label: 'Park / Playground',
      url: require('../assets/scenes/park@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/park@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/park@2x.jpg'),
    },
    store: {
      key: 'scenes_store',
      label: 'Shop',
      url: require('../assets/scenes/store@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/store@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/store@2x.jpg'),
    },
    underschool: {
      key: 'scenes_underschool',
      label: 'Undercover Area',
      url: require('../assets/scenes/underschool@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/underschool@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/underschool@2x.jpg'),
    },
    winter: {
      key: 'scenes_winter',
      label: 'Winter',
      url: require('../assets/scenes/winter@2x.jpg'),
      thumbnail: requireResizedImage('../assets/scenes/winter@2x.jpg', tileImageConfig),
      dimensions: requireImageSize('../assets/scenes/winter@2x.jpg'),
    },
  },
  actions: {
    /*thoughtleft: {
      key: 'actions_thoughtleft',
      label:'actions_thoughtleft',
      url: require('../assets/actions/thought_left.png'),
      thumbnail: requireResizedImage('../assets/actions/thought_left.png', tileImageConfig),
      dimensions: requireImageSize('../assets/actions/thought_left.png'),
      scale: 0.25,
      center: { x: 0.44, y: 0.53 },
    },
    */
    thoughtright: {
      key: 'actions_thoughtright',
      label: 'Thought',
      url: require('../assets/actions/thought_right.png'),
      thumbnail: requireResizedImage('../assets/actions/thought_right.png', tileImageConfig),
      dimensions: requireImageSize('../assets/actions/thought_right.png'),
      scale: 0.25,
      center: { x: 0.56, y: 0.53 },
    },
    thoughtabove: {
      key: 'actions_thoughtabove',
      label: 'Thought',
      url: require('../assets/actions/thought_above.png'),
      thumbnail: requireResizedImage('../assets/actions/thought_above.png', tileImageConfig),
      dimensions: requireImageSize('../assets/actions/thought_above.png'),
      scale: 0.25,
      center: { x: 0.5, y: 0.4 },
    },
    saidright: {
      key: 'actions_saidright',
      label: 'Said',
      url: require('../assets/actions/said_right.png'),
      thumbnail: requireResizedImage('../assets/actions/said_right.png', tileImageConfig),
      dimensions: requireImageSize('../assets/actions/said_right.png'),
      scale: 0.25,
      center: { x: 0.5, y: 0.5 },
    },
    saidabove: {
      key: 'actions_saidabove',
      label: 'Said',
      url: require('../assets/actions/said_above.png'),
      thumbnail: requireResizedImage('../assets/actions/said_above.png', tileImageConfig),
      dimensions: requireImageSize('../assets/actions/said_above.png'),
      scale: 0.25,
      center: { x: 0.5, y: 0.43 },
    },
    eat: {
      key: 'actions_eat',
      label: 'Eat',
      url: require('../assets/objects/eat.png'),
      thumbnail: requireResizedImage('../assets/objects/eat.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/eat.png'),
      scale: 0.35,
      category: 'objects',
    },
    drink: {
      key: 'actions_drink',
      label: 'Drink',
      url: require('../assets/objects/drink.png'),
      thumbnail: requireResizedImage('../assets/objects/drink.png', tileImageConfig),
      dimensions: requireImageSize('../assets/objects/drink.png'),
      scale: 0.1,
      category: 'objects',
    },
    crying: {
      key: 'actions_crying',
      label: 'Cry',
      url: require('../assets/actions/emotion_cry.png'),
      thumbnail: requireResizedImage('../assets/actions/emotion_cry.png', tileImageConfig),
      dimensions: requireImageSize('../assets/actions/emotion_cry.png'),
      scale: 0.25,
      // text: 'Cry',
      category: 'objects',
    },
    breathingslowly: {
      key: 'actions_breathingslowly',
      label: 'Breathe Slowly',
      url: require('../assets/actions/emotion_breathe_slowly.png'),
      thumbnail: requireResizedImage('../assets/actions/emotion_breathe_slowly.png', tileImageConfig),
      dimensions: requireImageSize('../assets/actions/emotion_breathe_slowly.png'),
      scale: 0.25,
      // text: 'Breathe Slowly',
      category: 'objects',
    },
    customemotion: {
      key: 'actions_customemotion',
      label: 'Enter your own',
      url: require('../assets/actions/emotion.png'),
      thumbnail: requireResizedImage('../assets/actions/emotion.png', tileImageConfig),
      dimensions: requireImageSize('../assets/actions/emotion.png'),
      scale: 0.25,
      text: '   ',
    },
  },
  people: {
    boy1: {
      angry: {
        key: 'people_boy1_angry',
        label: 'Angry',
        scale: 0.5 * 1.08,
        url: require('../assets/people/boy1/angry.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/angry.png'),
      },
      bored: {
        key: 'people_boy1_bored',
        label: 'Bored',
        scale: 0.5 * 1.04,
        url: require('../assets/people/boy1/bored.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/bored.png'),
      },
      confused: {
        key: 'people_boy1_confused',
        label: 'Confused',
        scale: 0.5 * 1.13,
        url: require('../assets/people/boy1/confused.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/confused.png'),
      },
      distrusting: {
        key: 'people_boy1_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.01,
        url: require('../assets/people/boy1/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/distrusting.png'),
      },
      embarrassed: {
        key: 'people_boy1_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 0.83,
        url: require('../assets/people/boy1/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/embarrassed.png'),
      },
      guilty: {
        key: 'people_boy1_guilty',
        label: 'Guilty',
        scale: 0.5 * 0.94,
        url: require('../assets/people/boy1/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/guilty.png'),
      },
      happy: {
        key: 'people_boy1_happy',
        label: 'Happy',
        scale: 0.5 * 1.03,
        url: require('../assets/people/boy1/happy.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/happy.png'),
      },
      jealous: {
        key: 'people_boy1_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.08,
        url: require('../assets/people/boy1/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/jealous.png'),
      },
      neutral: {
        key: 'people_boy1_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/boy1/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/neutral.png'),
      },
      sad: {
        key: 'people_boy1_sad',
        label: 'Sad',
        scale: 0.5 * 0.99,
        url: require('../assets/people/boy1/sad.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/sad.png'),
      },
      teasing: {
        key: 'people_boy1_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.1,
        url: require('../assets/people/boy1/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/teasing.png'),
      },
      worried: {
        key: 'people_boy1_worried',
        label: 'Worried',
        scale: 0.5 * 1.01,
        url: require('../assets/people/boy1/worried.png'),
        thumbnail: requireResizedImage('../assets/people/boy1/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy1/worried.png'),
      },
    },
    boy2: {
      angry: {
        key: 'people_boy2_angry',
        label: 'Angry',
        scale: 0.5 * 1.09,
        url: require('../assets/people/boy2/angry.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/angry.png'),
      },
      bored: {
        key: 'people_boy2_bored',
        label: 'Bored',
        scale: 0.5 * 1.04,
        url: require('../assets/people/boy2/bored.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/bored.png'),
      },
      confused: {
        key: 'people_boy2_confused',
        label: 'Confused',
        scale: 0.5 * 1.15,
        url: require('../assets/people/boy2/confused.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/confused.png'),
      },
      distrusting: {
        key: 'people_boy2_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.01,
        url: require('../assets/people/boy2/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/distrusting.png'),
      },
      embarrassed: {
        key: 'people_boy2_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 0.88,
        url: require('../assets/people/boy2/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/embarrassed.png'),
      },
      guilty: {
        key: 'people_boy2_guilty',
        label: 'Guilty',
        scale: 0.5 * 0.96,
        url: require('../assets/people/boy2/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/guilty.png'),
      },
      happy: {
        key: 'people_boy2_happy',
        label: 'Happy',
        scale: 0.5 * 1.07,
        url: require('../assets/people/boy2/happy.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/happy.png'),
      },
      jealous: {
        key: 'people_boy2_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.1,
        url: require('../assets/people/boy2/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/jealous.png'),
      },
      neutral: {
        key: 'people_boy2_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/boy2/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/neutral.png'),
      },
      sad: {
        key: 'people_boy2_sad',
        label: 'Sad',
        scale: 0.5 * 1.0,
        url: require('../assets/people/boy2/sad.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/sad.png'),
      },
      teasing: {
        key: 'people_boy2_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.13,
        url: require('../assets/people/boy2/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/teasing.png'),
      },
      worried: {
        key: 'people_boy2_worried',
        label: 'Worried',
        scale: 0.5 * 1.0,
        url: require('../assets/people/boy2/worried.png'),
        thumbnail: requireResizedImage('../assets/people/boy2/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy2/worried.png'),
      },
    },
    boy3: {
      angry: {
        key: 'people_boy3_angry',
        label: 'Angry',
        scale: 0.5 * 1.06,
        url: require('../assets/people/boy3/angry.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/angry.png'),
      },
      bored: {
        key: 'people_boy3_bored',
        label: 'Bored',
        scale: 0.5 * 1.02,
        url: require('../assets/people/boy3/bored.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/bored.png'),
      },
      confused: {
        key: 'people_boy3_confused',
        label: 'Confused',
        scale: 0.5 * 1.15,
        url: require('../assets/people/boy3/confused.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/confused.png'),
      },
      distrusting: {
        key: 'people_boy3_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.02,
        url: require('../assets/people/boy3/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/distrusting.png'),
      },
      embarrassed: {
        key: 'people_boy3_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 0.92,
        url: require('../assets/people/boy3/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/embarrassed.png'),
      },
      guilty: {
        key: 'people_boy3_guilty',
        label: 'Guilty',
        scale: 0.5 * 0.98,
        url: require('../assets/people/boy3/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/guilty.png'),
      },
      happy: {
        key: 'people_boy3_happy',
        label: 'Happy',
        scale: 0.5 * 1.09,
        url: require('../assets/people/boy3/happy.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/happy.png'),
      },
      jealous: {
        key: 'people_boy3_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.08,
        url: require('../assets/people/boy3/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/jealous.png'),
      },
      neutral: {
        key: 'people_boy3_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/boy3/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/neutral.png'),
      },
      sad: {
        key: 'people_boy3_sad',
        label: 'Sad',
        scale: 0.5 * 1.01,
        url: require('../assets/people/boy3/sad.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/sad.png'),
      },
      teasing: {
        key: 'people_boy3_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.11,
        url: require('../assets/people/boy3/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/teasing.png'),
      },
      worried: {
        key: 'people_boy3_worried',
        label: 'Worried',
        scale: 0.5 * 1.01,
        url: require('../assets/people/boy3/worried.png'),
        thumbnail: requireResizedImage('../assets/people/boy3/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/boy3/worried.png'),
      },
    },
    girl1: {
      angry: {
        key: 'people_girl1_angry',
        label: 'Angry',
        scale: 0.5 * 1.07,
        url: require('../assets/people/girl1/angry.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/angry.png'),
      },
      bored: {
        key: 'people_girl1_bored',
        label: 'Bored',
        scale: 0.5 * 1.06,
        url: require('../assets/people/girl1/bored.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/bored.png'),
      },
      confused: {
        key: 'people_girl1_confused',
        label: 'Confused',
        scale: 0.5 * 1.35,
        url: require('../assets/people/girl1/confused.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/confused.png'),
      },
      distrusting: {
        key: 'people_girl1_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.06,
        url: require('../assets/people/girl1/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/distrusting.png'),
      },
      embarrassed: {
        key: 'people_girl1_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 1.07,
        url: require('../assets/people/girl1/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/embarrassed.png'),
      },
      guilty: {
        key: 'people_girl1_guilty',
        label: 'Guilty',
        scale: 0.5 * 1.02,
        url: require('../assets/people/girl1/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/guilty.png'),
      },
      happy: {
        key: 'people_girl1_happy',
        label: 'Happy',
        scale: 0.5 * 1.26,
        url: require('../assets/people/girl1/happy.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/happy.png'),
      },
      jealous: {
        key: 'people_girl1_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.1,
        url: require('../assets/people/girl1/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/jealous.png'),
      },
      neutral: {
        key: 'people_girl1_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/girl1/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/neutral.png'),
      },
      sad: {
        key: 'people_girl1_sad',
        label: 'Sad',
        scale: 0.5 * 0.81,
        url: require('../assets/people/girl1/sad.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/sad.png'),
      },
      teasing: {
        key: 'people_girl1_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.17,
        url: require('../assets/people/girl1/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/teasing.png'),
      },
      worried: {
        key: 'people_girl1_worried',
        label: 'Worried',
        scale: 0.5 * 1.16,
        url: require('../assets/people/girl1/worried.png'),
        thumbnail: requireResizedImage('../assets/people/girl1/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl1/worried.png'),
      },
    },
    girl2: {
      angry: {
        key: 'people_girl2_angry',
        label: 'Angry',
        scale: 0.5 * 1.06,
        url: require('../assets/people/girl2/angry.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/angry.png'),
      },
      bored: {
        key: 'people_girl2_bored',
        label: 'Bored',
        scale: 0.5 * 1.07,
        url: require('../assets/people/girl2/bored.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/bored.png'),
      },
      confused: {
        key: 'people_girl2_confused',
        label: 'Confused',
        scale: 0.5 * 1.34,
        url: require('../assets/people/girl2/confused.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/confused.png'),
      },
      distrusting: {
        key: 'people_girl2_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.06,
        url: require('../assets/people/girl2/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/distrusting.png'),
      },
      embarrassed: {
        key: 'people_girl2_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 1.09,
        url: require('../assets/people/girl2/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/embarrassed.png'),
      },
      guilty: {
        key: 'people_girl2_guilty',
        label: 'Guilty',
        scale: 0.5 * 1.03,
        url: require('../assets/people/girl2/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/guilty.png'),
      },
      happy: {
        key: 'people_girl2_happy',
        label: 'Happy',
        scale: 0.5 * 1.26,
        url: require('../assets/people/girl2/happy.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/happy.png'),
      },
      jealous: {
        key: 'people_girl2_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.11,
        url: require('../assets/people/girl2/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/jealous.png'),
      },
      neutral: {
        key: 'people_girl2_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/girl2/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/neutral.png'),
      },
      sad: {
        key: 'people_girl2_sad',
        label: 'Sad',
        scale: 0.5 * 0.83,
        url: require('../assets/people/girl2/sad.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/sad.png'),
      },
      teasing: {
        key: 'people_girl2_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.18,
        url: require('../assets/people/girl2/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/teasing.png'),
      },
      worried: {
        key: 'people_girl2_worried',
        label: 'Worried',
        scale: 0.5 * 1.15,
        url: require('../assets/people/girl2/worried.png'),
        thumbnail: requireResizedImage('../assets/people/girl2/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl2/worried.png'),
      },
    },
    girl3: {
      angry: {
        key: 'people_girl3_angry',
        label: 'Angry',
        scale: 0.5 * 1.06,
        url: require('../assets/people/girl3/angry.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/angry.png'),
      },
      bored: {
        key: 'people_girl3_bored',
        label: 'Bored',
        scale: 0.5 * 1.07,
        url: require('../assets/people/girl3/bored.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/bored.png'),
      },
      confused: {
        key: 'people_girl3_confused',
        label: 'Confused',
        scale: 0.5 * 1.34,
        url: require('../assets/people/girl3/confused.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/confused.png'),
      },
      distrusting: {
        key: 'people_girl3_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.06,
        url: require('../assets/people/girl3/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/distrusting.png'),
      },
      embarrassed: {
        key: 'people_girl3_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 1.09,
        url: require('../assets/people/girl3/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/embarrassed.png'),
      },
      guilty: {
        key: 'people_girl3_guilty',
        label: 'Guilty',
        scale: 0.5 * 1.03,
        url: require('../assets/people/girl3/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/guilty.png'),
      },
      happy: {
        key: 'people_girl3_happy',
        label: 'Happy',
        scale: 0.5 * 1.26,
        url: require('../assets/people/girl3/happy.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/happy.png'),
      },
      jealous: {
        key: 'people_girl3_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.11,
        url: require('../assets/people/girl3/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/jealous.png'),
      },
      neutral: {
        key: 'people_girl3_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/girl3/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/neutral.png'),
      },
      sad: {
        key: 'people_girl3_sad',
        label: 'Sad',
        scale: 0.5 * 0.83,
        url: require('../assets/people/girl3/sad.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/sad.png'),
      },
      teasing: {
        key: 'people_girl3_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.18,
        url: require('../assets/people/girl3/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/teasing.png'),
      },
      worried: {
        key: 'people_girl3_worried',
        label: 'Worried',
        scale: 0.5 * 1.15,
        url: require('../assets/people/girl3/worried.png'),
        thumbnail: requireResizedImage('../assets/people/girl3/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/girl3/worried.png'),
      },
    },
    man1: {
      angry: {
        key: 'people_man1_angry',
        label: 'Angry',
        scale: 0.5 * 1.05,
        url: require('../assets/people/man1/angry.png'),
        thumbnail: requireResizedImage('../assets/people/man1/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/angry.png'),
      },
      bored: {
        key: 'people_man1_bored',
        label: 'Bored',
        scale: 0.5 * 1.03,
        url: require('../assets/people/man1/bored.png'),
        thumbnail: requireResizedImage('../assets/people/man1/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/bored.png'),
      },
      confused: {
        key: 'people_man1_confused',
        label: 'Confused',
        scale: 0.5 * 1.13,
        url: require('../assets/people/man1/confused.png'),
        thumbnail: requireResizedImage('../assets/people/man1/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/confused.png'),
      },
      distrusting: {
        key: 'people_man1_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.02,
        url: require('../assets/people/man1/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/man1/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/distrusting.png'),
      },
      embarrassed: {
        key: 'people_man1_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 0.82,
        url: require('../assets/people/man1/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/man1/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/embarrassed.png'),
      },
      guilty: {
        key: 'people_man1_guilty',
        label: 'Guilty',
        scale: 0.5 * 0.91,
        url: require('../assets/people/man1/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/man1/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/guilty.png'),
      },
      happy: {
        key: 'people_man1_happy',
        label: 'Happy',
        scale: 0.5 * 1.01,
        url: require('../assets/people/man1/happy.png'),
        thumbnail: requireResizedImage('../assets/people/man1/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/happy.png'),
      },
      jealous: {
        key: 'people_man1_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.08,
        url: require('../assets/people/man1/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/man1/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/jealous.png'),
      },
      neutral: {
        key: 'people_man1_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/man1/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/man1/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/neutral.png'),
      },
      sad: {
        key: 'people_man1_sad',
        label: 'Sad',
        scale: 0.5 * 1.0,
        url: require('../assets/people/man1/sad.png'),
        thumbnail: requireResizedImage('../assets/people/man1/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/sad.png'),
      },
      teasing: {
        key: 'people_man1_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.1,
        url: require('../assets/people/man1/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/man1/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/teasing.png'),
      },
      worried: {
        key: 'people_man1_worried',
        label: 'Worried',
        scale: 0.5 * 1.01,
        url: require('../assets/people/man1/worried.png'),
        thumbnail: requireResizedImage('../assets/people/man1/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man1/worried.png'),
      },
    },
    man2: {
      angry: {
        key: 'people_man2_angry',
        label: 'Angry',
        scale: 0.5 * 1.19,
        url: require('../assets/people/man2/angry.png'),
        thumbnail: requireResizedImage('../assets/people/man2/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/angry.png'),
      },
      bored: {
        key: 'people_man2_bored',
        label: 'Bored',
        scale: 0.5 * 1.06,
        url: require('../assets/people/man2/bored.png'),
        thumbnail: requireResizedImage('../assets/people/man2/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/bored.png'),
      },
      confused: {
        key: 'people_man2_confused',
        label: 'Confused',
        scale: 0.5 * 1.19,
        url: require('../assets/people/man2/confused.png'),
        thumbnail: requireResizedImage('../assets/people/man2/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/confused.png'),
      },
      distrusting: {
        key: 'people_man2_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.06,
        url: require('../assets/people/man2/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/man2/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/distrusting.png'),
      },
      embarrassed: {
        key: 'people_man2_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 0.9,
        url: require('../assets/people/man2/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/man2/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/embarrassed.png'),
      },
      guilty: {
        key: 'people_man2_guilty',
        label: 'Guilty',
        scale: 0.5 * 0.96,
        url: require('../assets/people/man2/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/man2/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/guilty.png'),
      },
      happy: {
        key: 'people_man2_happy',
        label: 'Happy',
        scale: 0.5 * 1.06,
        url: require('../assets/people/man2/happy.png'),
        thumbnail: requireResizedImage('../assets/people/man2/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/happy.png'),
      },
      jealous: {
        key: 'people_man2_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.13,
        url: require('../assets/people/man2/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/man2/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/jealous.png'),
      },
      neutral: {
        key: 'people_man2_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/man2/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/man2/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/neutral.png'),
      },
      sad: {
        key: 'people_man2_sad',
        label: 'Sad',
        scale: 0.5 * 1.0,
        url: require('../assets/people/man2/sad.png'),
        thumbnail: requireResizedImage('../assets/people/man2/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/sad.png'),
      },
      teasing: {
        key: 'people_man2_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.13,
        url: require('../assets/people/man2/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/man2/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/teasing.png'),
      },
      worried: {
        key: 'people_man2_worried',
        label: 'Worried',
        scale: 0.5 * 1.02,
        url: require('../assets/people/man2/worried.png'),
        thumbnail: requireResizedImage('../assets/people/man2/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/man2/worried.png'),
      },
    },
    woman1: {
      angry: {
        key: 'people_woman1_angry',
        label: 'Angry',
        scale: 0.5 * 1.1,
        url: require('../assets/people/woman1/angry.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/angry.png'),
      },
      bored: {
        key: 'people_woman1_bored',
        label: 'Bored',
        scale: 0.5 * 1.05,
        url: require('../assets/people/woman1/bored.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/bored.png'),
      },
      confused: {
        key: 'people_woman1_confused',
        label: 'Confused',
        scale: 0.5 * 1.4,
        url: require('../assets/people/woman1/confused.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/confused.png'),
      },
      distrusting: {
        key: 'people_woman1_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 1.04,
        url: require('../assets/people/woman1/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/distrusting.png'),
      },
      embarrassed: {
        key: 'people_woman1_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 1.07,
        url: require('../assets/people/woman1/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/embarrassed.png'),
      },
      guilty: {
        key: 'people_woman1_guilty',
        label: 'Guilty',
        scale: 0.5 * 1.01,
        url: require('../assets/people/woman1/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/guilty.png'),
      },
      happy: {
        key: 'people_woman1_happy',
        label: 'Happy',
        scale: 0.5 * 1.28,
        url: require('../assets/people/woman1/happy.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/happy.png'),
      },
      jealous: {
        key: 'people_woman1_jealous',
        label: 'Jealous',
        scale: 0.5 * 1.11,
        url: require('../assets/people/woman1/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/jealous.png'),
      },
      neutral: {
        key: 'people_woman1_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/woman1/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/neutral.png'),
      },
      sad: {
        key: 'people_woman1_sad',
        label: 'Sad',
        scale: 0.5 * 0.82,
        url: require('../assets/people/woman1/sad.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/sad.png'),
      },
      teasing: {
        key: 'people_woman1_teasing',
        label: 'Teasing',
        scale: 0.5 * 1.19,
        url: require('../assets/people/woman1/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/teasing.png'),
      },
      worried: {
        key: 'people_woman1_worried',
        label: 'Worried',
        scale: 0.5 * 1.21,
        url: require('../assets/people/woman1/worried.png'),
        thumbnail: requireResizedImage('../assets/people/woman1/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman1/worried.png'),
      },
    },
    woman2: {
      angry: {
        key: 'people_woman2_angry',
        label: 'Angry',
        scale: 0.5 * 0.96,
        url: require('../assets/people/woman2/angry.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/angry.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/angry.png'),
      },
      bored: {
        key: 'people_woman2_bored',
        label: 'Bored',
        scale: 0.5 * 0.92,
        url: require('../assets/people/woman2/bored.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/bored.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/bored.png'),
      },
      confused: {
        key: 'people_woman2_confused',
        label: 'Confused',
        scale: 0.5 * 1.27,
        url: require('../assets/people/woman2/confused.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/confused.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/confused.png'),
      },
      distrusting: {
        key: 'people_woman2_distrusting',
        label: 'Distrusting',
        scale: 0.5 * 0.91,
        url: require('../assets/people/woman2/distrusting.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/distrusting.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/distrusting.png'),
      },
      embarrassed: {
        key: 'people_woman2_embarrassed',
        label: 'Embarrassed',
        scale: 0.5 * 0.9,
        url: require('../assets/people/woman2/embarrassed.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/embarrassed.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/embarrassed.png'),
      },
      guilty: {
        key: 'people_woman2_guilty',
        label: 'Guilty',
        scale: 0.5 * 0.81,
        url: require('../assets/people/woman2/guilty.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/guilty.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/guilty.png'),
      },
      happy: {
        key: 'people_woman2_happy',
        label: 'Happy',
        scale: 0.5 * 1.17,
        url: require('../assets/people/woman2/happy.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/happy.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/happy.png'),
      },
      jealous: {
        key: 'people_woman2_jealous',
        label: 'Jealous',
        scale: 0.5 * 0.98,
        url: require('../assets/people/woman2/jealous.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/jealous.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/jealous.png'),
      },
      neutral: {
        key: 'people_woman2_neutral',
        label: 'Neutral',
        scale: 0.5 * 1.0,
        url: require('../assets/people/woman2/neutral.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/neutral.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/neutral.png'),
      },
      sad: {
        key: 'people_woman2_sad',
        label: 'Sad',
        scale: 0.5 * 0.8,
        url: require('../assets/people/woman2/sad.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/sad.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/sad.png'),
      },
      teasing: {
        key: 'people_woman2_teasing',
        label: 'Teasing',
        scale: 0.5 * 0.94,
        url: require('../assets/people/woman2/teasing.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/teasing.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/teasing.png'),
      },
      worried: {
        key: 'people_woman2_worried',
        label: 'Worried',
        scale: 0.5 * 1.09,
        url: require('../assets/people/woman2/worried.png'),
        thumbnail: requireResizedImage('../assets/people/woman2/worried.png', tileImageConfig),
        dimensions: requireImageSize('../assets/people/woman2/worried.png'),
      },
    },
  },
}

export function getAssetByKey(spriteKey: string): SceneAsset | null {
  try {
    return spriteKey.split('_').reduce((acc: any, key: string) => {
      if (!acc[key]) throw new Error(`Asset key not found: ${spriteKey} (${key})`)
      return acc[key]
    }, sceneAssets)
  } catch (e) {
    return null
  }
}

const _assets: LoadAssetsObject = {}
_assets.images = {}
for (let category in sceneAssets) {
  for (let assetName in sceneAssets[category]) {
    if ('url' in sceneAssets[category][assetName]) {
      _assets.images[`${category}_${assetName}`] = sceneAssets[category][assetName].thumbnail as string
    } else {
      for (let emotion in sceneAssets[category][assetName]) {
        _assets.images[`${category}_${assetName}_${emotion}`] = (sceneAssets[category][assetName] as {
          [k: string]: SceneAsset
        })[emotion].thumbnail
      }
    }
  }
}

export const loaderAssets = _assets
