import React, { useContext, useRef, useCallback, useState, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { LoadingIndicator } from 'common/LoadingIndicator'
import { getVideoPool } from 'utils/VideoPool'
import { useGlobalState } from './GlobalState'
import DelayVisible from './DelayVisible'
import { Absolute, Center } from 'common/ui'
import { appendPxToNumeric } from '../utils/appendPxToNumeric'
import { isDevEnv } from 'utils/envUtils'
import { useVideoPlaying } from 'utils/useVideoPlaying'
import { playVideoCatchExceptions } from 'utils/videoUtils'

interface Props {
  video: HTMLVideoElement
  width?: number | string
  height?: number | string
  ratio?: number
  allowToggle?: boolean
  borderRadius?: number | string
  cover?: boolean
  indicator?: boolean
  indicatorColor?: 'white' | 'theme'
  playButton?: boolean
}

export const VideoContainer: React.FC<Props> = ({
  video,
  width,
  height,
  ratio,
  allowToggle = true,
  borderRadius = 0,
  cover = false,
  indicator = true,
  indicatorColor,
  playButton = true,
}) => {
  const { playbackRate } = useGlobalState()
  const theme = useContext(ThemeContext)
  const ref = useRef<HTMLDivElement | null>(null)
  const [autoplayFailed, setAutoplayFailed] = useState(false)
  const [buffering, setBuffering] = useState(() => video.readyState !== 4)
  const canPlayHandler = useCallback(() => setBuffering(false), [setBuffering])
  const waitingHandler = useCallback(
    event => {
      const video = event.target as HTMLVideoElement
      if (video.buffered.length && video.buffered.end(0) === video.duration) {
        // don't set buffering if we actually have the entire video buffered
        return
      }
      setBuffering(true)
    },
    [setBuffering]
  )
  // re-render after inited
  const [, forceRender] = useState(0)

  // re-render when playing state changes
  useVideoPlaying(video)

  // deterministic paused state
  const lastVideo = useRef<HTMLVideoElement | null>(null)
  const paused = video.paused && !video.ended && !buffering && lastVideo.current === video
  lastVideo.current = video

  const setRef = useCallback<(node: HTMLDivElement | null) => void>(
    node => {
      if (ref.current) {
        const oldVideo = ref.current.firstChild as HTMLVideoElement
        if (!oldVideo.paused) {
          oldVideo.pause()
        }
        oldVideo.removeEventListener('canplay', canPlayHandler)
        oldVideo.removeEventListener('waiting', waitingHandler)
        ref.current.removeChild(oldVideo)
      }

      if (node) {
        setBuffering(video.readyState !== 4)
        setAutoplayFailed(false)

        video.addEventListener('waiting', waitingHandler)
        video.addEventListener('canplay', canPlayHandler)

        Promise.resolve(video.play())
          .catch(err => {
            if (err.name === 'NotAllowedError') {
              setAutoplayFailed(true)
            } else if (err.name === 'AbortError') {
              // we can ignore this
              setAutoplayFailed(true)
            } else {
              console.error(err.name)
              console.error(err.message)
            }
          })
          .then(() => forceRender(i => i + 1))
        node.appendChild(video)
      }

      ref.current = node
    },
    [video, canPlayHandler, waitingHandler]
  )

  function togglePause() {
    if (autoplayFailed) {
      getVideoPool().preload()
      playVideoCatchExceptions(video)
      setAutoplayFailed(false)
      return
    }
    if (video.paused) {
      playVideoCatchExceptions(video)
    } else {
      video.pause()
      // Safari needs this otherwise the first time the video is paused,
      // it won't re-render so the play button will not show up
      forceRender(i => i + 1)
    }
  }

  useEffect(() => {
    video.playbackRate = playbackRate
  }, [video, playbackRate])

  return (
    <Container
      borderRadius={borderRadius}
      cover={cover}
      style={ratio ? { width: '100%', paddingTop: `${100 / ratio}%` } : { width, height }}>
      <Absolute cover ref={setRef} onClick={allowToggle && !video.ended ? togglePause : undefined}></Absolute>
      {buffering && indicator && (
        <Absolute cover>
          <DelayVisible ms={350}>
            <LoadingIndicator
              margin="auto"
              color={indicatorColor === 'theme' ? theme.buttonBorderTopColor : undefined}
            />
          </DelayVisible>
        </Absolute>
      )}
      {paused && (allowToggle || autoplayFailed) && playButton && (
        <Absolute cover onClick={togglePause}>
          <Center flex>
            <CircleButton type="button">
              <RightArrowIcon />
            </CircleButton>
          </Center>
        </Absolute>
      )}
      {(isDevEnv() || window.location.host === 'dev.sas') && (
        <Absolute style={{ top: 0, right: 0, width: 100, height: 100 }} onClick={() => (video.currentTime = 99999)} />
      )}
    </Container>
  )
}

const Container = styled.div<{ borderRadius: number | string; cover: boolean }>`
  position: relative;
  flex: auto;

  video {
    width: 100%;
    height: 100%;
    border-radius: ${p => appendPxToNumeric(p.borderRadius)};
    object-fit: ${p => (p.cover ? 'cover' : 'contain')};
    font-family: 'object-fit: ${p => (p.cover ? 'cover' : 'contain')};';
  }
`

export const CircleButton = styled.button`
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  width: 120px;
  height: 120px;
  background: ${p =>
    `linear-gradient(180deg, ${p.theme.appBackgroundTopColor} 0%, ${p.theme.appBackgroundBottomColor} 100%)`};
  border-radius: 50%;
  border: 0;

  :active + & {
    transform: scale(0.9);
  }
`

export const RightArrowIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 48 48">
    <polygon points="20,16 20,32 30,24" fill="white" />
  </svg>
)
