import { EmotionometerSticker, EmotionometerDevice } from 'shared/e-telligence/types'

export const presetStickers: EmotionometerSticker<EmotionometerDevice>[] = [
  { type: 'situations', id: 'routineChange', title: 'A change in routine' },
  { type: 'situations', id: 'bullied', title: 'Being bullied / Teased' },
  { type: 'situations', id: 'competing', title: 'Competing in an event' },
  { type: 'situations', id: 'doingTest', title: 'Doing a test' },
  { type: 'situations', id: 'homework', title: 'Doing homework / School work' },
  { type: 'situations', id: 'fights', title: 'Fights / Disagreements' },
  { type: 'situations', id: 'trouble', title: 'Getting in trouble' },
  { type: 'situations', id: 'schoolCamp', title: 'Going on school camp' },
  { type: 'situations', id: 'birthdayParty', title: 'Going to a birthday party' },
  { type: 'situations', id: 'subTeacher', title: 'Having a fill-in teacher' },
  { type: 'situations', id: 'visitors', title: 'Having visitors' },
  { type: 'situations', id: 'losingGame', title: 'Losing a game' },
  { type: 'situations', id: 'loudNoises', title: 'Loud noises' },
  { type: 'situations', id: 'mistake', title: 'Making a mistake' },
  { type: 'situations', id: 'confusion', title: 'Not understanding  something' },
  { type: 'situations', id: 'performing', title: 'Performing in  front of others' },
  { type: 'situations', id: 'physicalTouch', title: 'Physical touch' },
  { type: 'situations', id: 'playingSport', title: 'Playing sport' },
  { type: 'situations', id: 'playingOthers', title: 'Playing with others' },
  { type: 'situations', id: 'homesick', title: 'Staying away from home' },
  { type: 'situations', id: 'somethingNew', title: 'Trying something new' },

  { type: 'relaxationGadgets', id: 'countName', title: 'Count and name things' },
  { type: 'relaxationGadgets', id: 'physicalActivity', title: 'Do a physical activity' },
  { type: 'relaxationGadgets', id: 'drawPaint', title: 'Draw or paint' },
  { type: 'relaxationGadgets', id: 'bodyScan', title: 'Enviro-body scan' },
  { type: 'relaxationGadgets', id: 'fireEngine', title: 'Fire engine' },
  { type: 'relaxationGadgets', id: 'gaming', title: 'Gaming' },
  { type: 'relaxationGadgets', id: 'bath', title: 'Have a bath / Shower' },
  { type: 'relaxationGadgets', id: 'drink', title: 'Have a drink' },
  { type: 'relaxationGadgets', id: 'imagineRelaxing', title: 'Imagine something relaxing' },
  { type: 'relaxationGadgets', id: 'musicSounds', title: 'Listen to music / Sounds' },
  { type: 'relaxationGadgets', id: 'makeSomething', title: 'Make something' },
  { type: 'relaxationGadgets', id: 'o2Regulator', title: 'O2 regulator (slow breathing)' },
  { type: 'relaxationGadgets', id: 'musicalInstrument', title: 'Play a musical instrument' },
  { type: 'relaxationGadgets', id: 'playPet', title: 'Play with a pet' },
  { type: 'relaxationGadgets', id: 'playToy', title: 'Play with a toy / puzzle' },
  { type: 'relaxationGadgets', id: 'playClay', title: 'Play with clay or play dough' },
  { type: 'relaxationGadgets', id: 'readBook', title: 'Read a book' },
  { type: 'relaxationGadgets', id: 'splashFace', title: 'Splash your face with water' },
  { type: 'relaxationGadgets', id: 'talk', title: 'Talk to someone' },
  { type: 'relaxationGadgets', id: 'tearPaper', title: 'Tear up paper' },
  { type: 'relaxationGadgets', id: 'helpfulThoughts', title: 'Think helpful thoughts' },
  { type: 'relaxationGadgets', id: 'thoughtTracker', title: 'Thought tracker' },
  { type: 'relaxationGadgets', id: 'fidgetToy', title: 'Use a fidget toy' },
  { type: 'relaxationGadgets', id: 'somethingFunny', title: 'Watch / listen to something funny' },
  { type: 'relaxationGadgets', id: 'watchTV', title: 'Watch TV' },

  { type: 'bodyClues', emotion: 'anger', id: 'primal', title: 'Act before thinking' },
  { type: 'bodyClues', emotion: 'anger', id: 'brainFog', title: 'Can’t think clearly' },
  { type: 'bodyClues', emotion: 'anger', id: 'clenchedTeeth', title: 'Clenched teeth' },
  { type: 'bodyClues', emotion: 'anger', id: 'feelEnergetic', title: 'Feel energetic' },
  { type: 'bodyClues', emotion: 'anger', id: 'feelHot', title: 'Feel hot' },
  { type: 'bodyClues', emotion: 'anger', id: 'heartRaces', title: 'Heart races' },
  { type: 'bodyClues', emotion: 'anger', id: 'punch', title: 'Hit / punch' },
  { type: 'bodyClues', emotion: 'anger', id: 'kickThings', title: 'Kick things' },
  { type: 'bodyClues', emotion: 'anger', id: 'shake', title: 'Shake' },
  { type: 'bodyClues', emotion: 'anger', id: 'swear', title: 'Swear / curse' },
  { type: 'bodyClues', emotion: 'anger', id: 'tense', title: 'Tense muscles' },
  { type: 'bodyClues', emotion: 'anger', id: 'throwThings', title: 'Throw things' },
  { type: 'bodyClues', emotion: 'anger', id: 'yell', title: 'Yell / shout' },

  { type: 'bodyClues', emotion: 'anxiety', id: 'breatheQuickly', title: 'Breathe quickly' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'brainFog', title: 'Can’t think clearly' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'dryMouth', title: 'Dry mouth' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'eyesWide', title: 'Eyes open wide' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'feelFaint', title: 'Feel faint' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'feelHot', title: 'Feel hot' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'headache', title: 'Headache' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'heartRaces', title: 'Heart races' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'throatLump', title: 'Lump in throat ' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'toilet', title: 'Need the toilet' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'shake', title: 'Shake' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'sweatyHands', title: 'Sweaty hands' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'tense', title: 'Tense muscles' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'insomnia', title: 'Trouble sleeping' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'upsetStomach', title: 'Upset stomach' },
  { type: 'bodyClues', emotion: 'anxiety', id: 'voiceShakes', title: 'Voice shakes' },
].map(sticker => ({ ...sticker, isPreset: true } as EmotionometerSticker<EmotionometerDevice>))
