import { BoardGameCharacter as Character } from 'shared/session/types'
import { images } from './assets'

export const characters: Character[] = [
  { id: 'boy1', avatar: images.boy1 },
  { id: 'boy2', avatar: images.boy2 },
  { id: 'boy3', avatar: images.boy3 },
  { id: 'boy4', avatar: images.boy4 },
  { id: 'boy5', avatar: images.boy5 },
  { id: 'boy6', avatar: images.boy6 },
  { id: 'girl1', avatar: images.girl1 },
  { id: 'girl2', avatar: images.girl2 },
  { id: 'girl3', avatar: images.girl3 },
  { id: 'girl4', avatar: images.girl4 },
  { id: 'girl5', avatar: images.girl5 },
  { id: 'girl6', avatar: images.girl6 },
  { id: 'custom', avatar: '' },
]
