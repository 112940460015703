/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import styled, { css } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'

import { Row, Column, Spacer, Button, Panel, Padding, P, H2 } from 'common/ui'
import { HeaderTemplate } from './common/HeaderTemplate'

import { TransitionModal, MobileModalPanel, MobileMessageModal } from 'app/MobileMessageModal'
import { useUserState } from 'app/UserState'
import { useGadgetPackState } from './GadgetPackState'
import { BionicPower, CustomBionicPower, BionicPowersState } from 'shared/gadget-pack/types'
import { UPDATE_BIONIC_POWERS_SLOT, ADD_CUSTOM_BIONIC_POWER } from 'shared/gadget-pack/actionTypes'
import { TextSymbolInput, TextSymbolValue } from 'common/TextSymbolInput'
import { Emoji } from 'common/Emoji'
import { useMedia } from 'utils/useMedia'
import { PrintGlobalStyle, PrintPreserveBackgrounds } from 'print/ui-print'
import { fontRegular } from 'fonts'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import { createPdf } from 'api'
import { PdfStatus } from 'common/PdfModal'
import { usePdfPayload } from 'utils/usePdfPayload'
import { isDevEnv } from 'utils/envUtils'
import { baseUrl as gadgetPackBaseUrl } from './GadgetPack'
import { bionicPowersEmojis } from 'constants/emojiSets'

const emojis = bionicPowersEmojis

const bionicPowers: BionicPower[] = [
  {
    id: 'makeFriends',
    text: 'Make friends and keep them',
    image: require('./assets/bionic-powers/make-friends.svg')?.default,
  },
  {
    id: 'feelHappier',
    text: 'Feel happier, calmer or braver',
    image: require('./assets/bionic-powers/feel-happier.svg')?.default,
  },
  {
    id: 'getJob',
    text: 'Get an awesome job',
    image: require('./assets/bionic-powers/get-job.svg')?.default,
  },
  { id: 'goodGrades', text: 'Get good grades', image: require('./assets/bionic-powers/good-grades.svg')?.default },
  {
    id: 'tryIdeas',
    text: 'Get others to try my ideas',
    image: require('./assets/bionic-powers/try-ideas.svg')?.default,
  },
  {
    id: 'beIncluded',
    text: 'Be liked and included',
    image: require('./assets/bionic-powers/be-included.svg')?.default,
  },
  {
    id: 'goodHobbies',
    text: 'Do well at things I care about',
    image: require('./assets/bionic-powers/good-hobbies.svg')?.default,
  },
  { id: 'beAdventurous', text: 'Be adventurous', image: require('./assets/bionic-powers/be-adventurous.svg')?.default },
  {
    id: 'getAlong',
    text: 'Get along well with others',
    image: require('./assets/bionic-powers/get-along.svg')?.default,
  },
  { id: 'beSmarter', text: 'Be smarter', image: require('./assets/bionic-powers/be-smarter.svg')?.default },
  { id: 'haveFun', text: 'Have Fun', image: require('./assets/bionic-powers/have-fun.png') },
]

export const bionicPowersIsEmpty = (state: BionicPowersState): boolean => {
  if (Object.values(state.slots).filter((_) => _).length > 0) return false
  return true
}

const getSlotValues = (
  slots: BionicPowersState['slots'],
  customBionicPowers: BionicPowersState['customBionicPowers']
): { [key: number]: BionicPower | CustomBionicPower } =>
  Object.keys(slots).reduce(
    (obj, key) => ({
      ...obj,
      [key]:
        bionicPowers.find(({ id }) => id === slots[parseInt(key)]) ||
        customBionicPowers.find(({ id }) => id === slots[parseInt(key)]),
    }),
    {} as ReturnType<typeof getSlotValues>
  )

interface BionicPowersPrintPayload {
  slots: BionicPowersState['slots']
  customBionicPowers: BionicPowersState['customBionicPowers']
}

export const BionicPowersScreen: React.FC<{
  baseUrl?: string
  inCardHolder?: boolean
  hideExit?: boolean
  overrideExit?: () => void
  hideDownload?: boolean
  hideSave?: boolean
  bgImage?: string
  readonly?: boolean
}> = ({ baseUrl, inCardHolder, hideExit, hideDownload, hideSave, bgImage, overrideExit, readonly }) => {
  const history = useHistory()
  const location = useLocation()
  const { getBaseAction, accessToken } = useUserState()
  const {
    state: {
      bionicPowers: { slots, customBionicPowers },
    },
    dispatch,
  } = useGadgetPackState()
  const [currentSlotIndex, setCurrentSlotIndex] = useState<number | null>(null)
  const [showPowerSelect, setShowPowerSelect] = useState<boolean>(false)
  const [viewPower, setViewPower] = useState<BionicPower | CustomBionicPower | null>(null)
  const [pdfStatus, setPdfStatus] = useState<PdfStatus>('ready')
  const [pdfUrl, setPdfUrl] = useState('')

  const slotValues = getSlotValues(slots, customBionicPowers)

  const updatePower = (index: number, value: BionicPower | CustomBionicPower | null) => {
    dispatch({ ...getBaseAction(), type: UPDATE_BIONIC_POWERS_SLOT, index, id: value ? value.id : null })
  }

  const choosePower = (index: number) => {
    if (!readonly) {
      const currentValue = slotValues[index]
      setCurrentSlotIndex(index)
      if (currentValue) setViewPower(currentValue)
      else setShowPowerSelect(true)
    }
  }

  const handleDownload = () => {
    const templateUrl = `${window.location.origin}/gadget-pack/bionic-powers/print`
    setPdfStatus('busy')
    createPdf<BionicPowersPrintPayload>(templateUrl, { slots, customBionicPowers }, accessToken)
      .then((pdf) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setPdfUrl(pdf.url)
          setPdfStatus('success')
        })
      })
      .catch(() => setPdfStatus('error'))
  }

  return (
    <>
      <HeaderTemplate
        hideExitButton={hideExit}
        title="Bionic Powers"
        onExit={() =>
          overrideExit
            ? overrideExit()
            : baseUrl !== undefined
              ? history.push((location.state || {}).skipCardHolder ? gadgetPackBaseUrl : baseUrl)
              : undefined
        }
        exitLabel={inCardHolder ? 'Back' : 'Exit'}
        Buttons={
          !hideDownload ? (
            <>
              <Button
                onClick={handleDownload}
                children={pdfStatus === 'busy' ? 'Processing...' : 'Download'}
                disabled={pdfStatus === 'busy'}
              />
              {isDevEnv() && (
                <>
                  <Spacer size="s" />
                  <LinkButton to={`${baseUrl}/bionic-powers/print`} children="Print" />
                </>
              )}
            </>
          ) : !hideSave ? (
            <Button children="Save" />
          ) : (
            ''
          )
        }>
        <Panel
          style={{
            backgroundColor: 'black',
            borderBottomColor: 'black',
            maxHeight: '100%',
            height: '100%',
            minHeight: 550, //TODO: maybe here z
          }}>
          <Padding size="l" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <SuitContainer>
              <SuitImg
                src={bgImage ? bgImage : require('./assets/gadgets_bionicPowers_suit.png')}
                alt=""
                className="bionic-suit-img"
              />
              <SlotColumn side="left">
                {[...Array(6)].map((_, i) => (
                  <Slot readonly={readonly} key={i} value={slotValues[i] || null} onSelect={() => choosePower(i)} />
                ))}
              </SlotColumn>
              <SlotColumn side="right">
                {[...Array(6)].map((_, i) => (
                  <Slot
                    readonly={readonly}
                    key={i}
                    value={slotValues[i + 6] || null}
                    onSelect={() => choosePower(i + 6)}
                  />
                ))}
              </SlotColumn>
              <MiddleSlotColumn side="middle">
                {[...Array(2)].map((_, i) => (
                  <Slot
                    readonly={readonly}
                    key={i}
                    value={slotValues[i + 12] || null}
                    onSelect={() => choosePower(i + 12)}
                  />
                ))}
              </MiddleSlotColumn>
            </SuitContainer>
          </Padding>
        </Panel>
      </HeaderTemplate>
      <PowerSelectModal
        isOpen={showPowerSelect && currentSlotIndex !== null}
        onRequestClose={() => {
          setCurrentSlotIndex(null)
          setShowPowerSelect(false)
        }}
        onSelect={(power) => {
          if (currentSlotIndex !== null) updatePower(currentSlotIndex, power)
          setCurrentSlotIndex(null)
          setShowPowerSelect(false)
        }}
      />
      <PowerViewModal
        power={viewPower}
        isOpen={!!viewPower}
        onRequestClose={() => {
          setCurrentSlotIndex(null)
          setViewPower(null)
        }}
        onRemove={() => {
          if (currentSlotIndex !== null) updatePower(currentSlotIndex, null)
        }}
      />
      <MobileMessageModal isOpen={pdfStatus === 'success' && !!pdfUrl}>
        <H2 style={{ textAlign: 'center' }}>PDF download ready</H2>
        <Spacer size="m" />
        <Row justifyContent="center" style={{ marginBottom: -10 }}>
          <Button theme="white" onClick={() => setPdfUrl('')} children="Cancel" />
          <Spacer size="m" />
          <AnchorButton href={pdfUrl} children="Download" target="_blank" />
        </Row>
      </MobileMessageModal>
    </>
  )
}

export const BionicPowersPrint: React.FC = () => {
  const { state } = useGadgetPackState()
  const payload = usePdfPayload<BionicPowersPrintPayload>()
  const slots = payload ? payload.slots : state.bionicPowers.slots
  const customBionicPowers = payload ? payload.customBionicPowers : state.bionicPowers.customBionicPowers
  const slotValues = getSlotValues(slots, customBionicPowers)
  return (
    <Column style={{ height: '100vh' }} justifyContent="center" alignItems="center">
      <PrintGlobalStyle />
      <PrintPreserveBackgrounds />
      <Panel
        style={{
          backgroundColor: 'transparent',
          borderBottomColor: 'transparent',
          maxHeight: '100%',
          height: '100%',
          boxShadow: 'none',
          minHeight: 550,
        }}>
        <Padding size="l" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <SuitContainer>
            <SuitImg src={require('./assets/gadgets_bionicPowers_suit.png')} alt="" />
            <SlotColumn side="left">
              {[...Array(6)].map((_, i) => (
                <Slot key={i} value={slotValues[i] || null} onSelect={() => {}} />
              ))}
            </SlotColumn>
            <SlotColumn side="right">
              {[...Array(6)].map((_, i) => (
                <Slot key={i} value={slotValues[i + 6] || null} onSelect={() => {}} />
              ))}
            </SlotColumn>
            <MiddleSlotColumn side="middle">
              {[...Array(2)].map((_, i) => (
                <Slot key={i} value={slotValues[i + 12] || null} onSelect={() => {}} />
              ))}
            </MiddleSlotColumn>
          </SuitContainer>
        </Padding>
      </Panel>
    </Column>
  )
}

const PowerSelectModal: React.FC<
  ReactModal.Props & {
    onSelect: (power: BionicPower | CustomBionicPower | null) => void
  }
> = ({ isOpen, onRequestClose, onSelect }) => {
  const { getBaseAction } = useUserState()
  const {
    dispatch,
    state: {
      bionicPowers: { customBionicPowers },
    },
  } = useGadgetPackState()
  const [selectedPower, setSelectedPower] = useState<BionicPower | CustomBionicPower | null>(null)
  const [showCustomModal, setShowCustomModal] = useState(false)
  const isMobile = useMedia('(max-width: 640px)')
  useEffect(() => {
    if (!isOpen) setSelectedPower(null)
  }, [isOpen, setSelectedPower])
  return (
    <>
      <MobileModalPanel
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        panelStyle={{ width: 720, maxWidth: 'calc(100vw - 20px)' }}>
        <Column style={{ maxHeight: 'calc(100vh - 25px)', overflow: 'hidden' }}>
          <Column flex="none" paddingLeft="m" paddingRight="m" paddingTop="s">
            <Spacer size="m" />
            <H2>Choose a power</H2>
            <Spacer size="s" />
          </Column>
          <Column
            flex="1 1 auto"
            style={{ overflowY: 'auto' }}
            paddingLeft="s"
            paddingRight="s"
            paddingBottom="s"
            paddingTop="s">
            <BodyCluesContainer flexWrap flex="1 1 auto">
              <Column>
                <Padding size="xs">
                  <Column
                    onClick={() => setShowCustomModal(true)}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: '1px solid black',
                      backgroundColor: 'white',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    justifyContent="center"
                    alignItems="center">
                    <label>Make your own</label>
                  </Column>
                </Padding>
              </Column>
              {[...customBionicPowers, ...bionicPowers].map((bionicPower, i) => (
                <Column key={i}>
                  <Padding size="xs">
                    <BodyClueDiv
                      interactive
                      onClick={() => setSelectedPower(selectedPower === bionicPower ? null : bionicPower)}
                      onDoubleClick={() => onSelect(bionicPower)}
                      selected={selectedPower === bionicPower}>
                      <Padding size="m">
                        {'image' in bionicPower ? (
                          <img src={bionicPower.image} alt={bionicPower.text} style={{ maxHeight: 80 }} />
                        ) : (
                          <Row
                            justifyContent="center"
                            alignItems="center"
                            style={{ fontSize: isMobile ? 36 : 56, height: '100%' }}>
                            <Emoji children={bionicPower.symbol} />
                          </Row>
                        )}
                      </Padding>
                      <Label style={{ minHeight: 30 }}>
                        <span>{bionicPower.text}</span>
                      </Label>
                    </BodyClueDiv>
                  </Padding>
                </Column>
              ))}
            </BodyCluesContainer>
          </Column>
          <Column flex="none" padding="m" style={{ borderTop: '1px solid #adb4d8' }}>
            <Row justifyContent="center">
              <Row>
                <Button size="s" theme="white" children="Cancel" onClick={onRequestClose} />
                <Spacer size="m" />
                <Button
                  size="s"
                  children="Add Power"
                  disabled={!selectedPower}
                  onClick={() => onSelect(selectedPower)}
                />
              </Row>
            </Row>
          </Column>
        </Column>
      </MobileModalPanel>
      <CustomPowerModal
        isOpen={showCustomModal}
        onRequestClose={() => setShowCustomModal(false)}
        onAdd={(bionicPower) => {
          dispatch({ ...getBaseAction(), type: ADD_CUSTOM_BIONIC_POWER, bionicPower })
          setShowCustomModal(false)
        }}
      />
    </>
  )
}

const CustomPowerModal: React.FC<ReactModal.Props & { onAdd: (power: Omit<CustomBionicPower, 'id'>) => void }> = ({
  isOpen,
  onRequestClose,
  onAdd,
}) => {
  const [value, setValue] = useState<TextSymbolValue>({})
  useEffect(() => {
    if (!isOpen && (value.text || value.symbol)) setValue({})
  }, [isOpen, value.text, value.symbol])
  return (
    <MobileModalPanel
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      panelStyle={{ width: 420, maxWidth: 'calc(100vw - 20px)' }}>
      <Column style={{ maxHeight: 'calc(100vh - 25px)', overflow: 'hidden' }} padding="m" paddingBottom="l">
        <H2 style={{ textAlign: 'center' }}>Create your own power</H2>
        <Spacer size="m" />
        <TextSymbolInput
          textarea
          value={value}
          onChange={(value) => setValue(value)}
          inputProps={{ inputSize: 'l' }}
          symbols={emojis}
        />
        <Spacer size="m" />
        <Row justifyContent="center">
          <Button children="Cancel" theme="white" onClick={onRequestClose} />
          <Spacer size="s" />
          <Button
            disabled={!value.text || !value.symbol}
            onClick={() => onAdd({ text: '', symbol: '', ...value })}
            children="Create"
          />
        </Row>
      </Column>
    </MobileModalPanel>
  )
}

const PowerViewModal: React.FC<
  ReactModal.Props & {
    power: BionicPower | CustomBionicPower | null
    onRemove?: () => void
  }
> = ({ power, onRemove, ...props }) => {
  return (
    <TransitionModal {...props}>
      {({ requestClose }) => (
        <>
          {power && (
            <Column>
              <BodyClueDiv style={{ width: 250, height: 250, borderColor: '#22ACF3' }} onClick={requestClose}>
                <Padding size="m" style={{ height: 'auto' }}>
                  {'image' in power ? (
                    <img src={power.image} alt={power.text} />
                  ) : (
                    <Row justifyContent="center" alignItems="center" style={{ fontSize: 56, height: '100%' }}>
                      <Emoji children={power.symbol} />
                    </Row>
                  )}
                </Padding>
                <Label
                  style={{
                    fontSize: power.text.length < 23 ? '1em' : '0.65em',
                    padding: 15,
                    minHeight: 50,
                    lineHeight: 1.2,
                  }}>
                  <span style={{ width: '80%' }}>{power.text}</span>
                </Label>
              </BodyClueDiv>
              {onRemove && (
                <>
                  <Spacer size="m" />
                  <Button
                    size="s"
                    onClick={() => {
                      onRemove()
                      requestClose()
                    }}
                    children="Delete"
                  />
                </>
              )}
            </Column>
          )}
        </>
      )}
    </TransitionModal>
  )
}

const Slot: React.FC<{ value: BionicPower | CustomBionicPower | null; onSelect: () => void; readonly?: boolean }> = ({
  value,
  onSelect,
  readonly,
}) => {
  return (
    <SlotDiv empty={!value} readonly={readonly} onClick={onSelect}>
      {value && (
        <BodyClueDiv>
          {'image' in value ? (
            <Row flex="1 1 auto" className="image-container">
              <img src={value.image} alt={value.text} />
            </Row>
          ) : (
            <Row
              justifyContent="center"
              alignItems="center"
              className="emoji-container"
              style={{ height: '100%', minHeight: 'initial' }}>
              <Emoji children={value.symbol} />
            </Row>
          )}
          <Label style={{ fontSize: value.text.length >= 23 ? '0.4em' : '0.5em' }}>{value.text}</Label>
        </BodyClueDiv>
      )}
    </SlotDiv>
  )
}

const BodyClueDiv = styled.div<{ selected?: boolean; interactive?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: 1px solid ${(p) => (p.selected ? '#22ACF3' : 'black')};
  background-color: white;
  user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
  ${(p) => (p.interactive ? 'cursor: pointer;' : '')}
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 0 2px #22acf3;
    opacity: ${(p) => (p.selected ? 1 : 0)};
  }
  & > .emoji-container {
    flex: 1 1 auto;
    height: 90px;
    min-height: 90px;
  }
  & > .image-container {
    flex: 1 1 auto;
    & img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  & label {
    flex: 0 1 auto;
  }
  & > ${Row} {
    font-size: 32px;
    @media print {
      font-size: 56px;
    }
  }

  ${Padding} {
    box-sizing: border-box;
    height: 100%;

    & > img {
      max-height: 150px;
    }
  }
`

const Label = styled.label`
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  padding: 7px;
  font-size: 12px;
  text-align: center;
`

const BodyCluesContainer = styled(Row)`
  & > * {
    flex: 1 1 25%;
    max-width: 25%;

    @media (max-width: 640px) {
      flex: 1 1 33.33333%;
      max-width: 33.33333%;
      & ${BodyClueDiv} > ${Padding} {
        height: 30px;
        min-height: 50px;
      }
    }
    @media (max-width: 320px) {
      flex: 1 1 50%;
      max-width: 50%;
    }
  }
`

const SuitContainer = styled.div`
  position: relative;
`

const SuitImg = styled.img`
  height: 100%;
  max-height: 634px;
  min-height: calc(100vh - 185px);
`

const SlotColumn = styled(Column)<{ side: 'left' | 'right' | 'middle' }>`
  position: absolute;
  top: 5%;
  bottom: 5%;
  width: auto;
  justify-content: space-between;
  ${(p) => (p.side === 'left' ? 'right: 100%;' : '')}
  ${(p) => (p.side === 'right' ? 'left: 100%;' : '')}
  @media (max-width: 420px) {
    ${(p) => (p.side === 'left' ? 'left: 0px;' : '')}
    ${(p) => (p.side === 'right' ? 'left: calc(100% - 70px);' : '')}
  }
`

const MiddleSlotColumn = styled(SlotColumn)`
  bottom: initial;
  top: 40%;
  left: 50%;
  width: auto;
  height: 32%;
  transform: translate(-50%, -60%);
`

const SlotDiv = styled.div<{ empty: boolean; readonly?: boolean }>`
  position: relative;
  width: 80px;
  height: 80px;
  color: ${(p) => p.theme.buttonBackgroundBottomColor};
  border: 1px solid currentColor;
  background-color: white;
  cursor: pointer;
  ${(p) =>
    p.empty && !p.readonly
      ? css`
          box-shadow:
            inset 0 0 0px 25px white,
            inset 0 0 0px 26px currentColor;
        `
      : ''};

  &::before,
  &::after {
    ${(p) =>
      p.empty
        ? css`
            content: '';
          `
        : ''}
    position: absolute;
    border-bottom: 1px solid currentColor;
    width: 20%;
    height: 0;
    left: 50%;
    top: 50%;
    transform-origin: 50% 50%;
    display: ${(p) => (p.readonly ? 'none' : 'block')};
  }
  &::before {
    transform: translate(-50%, 0);
  }
  &::after {
    transform: translate(-50%, 0) rotateZ(90deg);
  }

  & ${BodyClueDiv} {
    border: none;
    font-size: 24px;
    & img {
      height: 80%;
      margin: 5px 0;
    }
    & label {
      ${fontRegular}
      padding: 3px 3px 5px 3px;
      line-height: 1;
    }
  }

  @media (max-width: 640px) {
    width: 70px;
    height: 70px;
    ${(p) =>
      p.empty
        ? css`
            box-shadow:
              inset 0 0 0px 18px white,
              inset 0 0 0px 19px currentColor;
          `
        : ''}

    & ${BodyClueDiv} {
      font-size: 15px;
    }
  }

  @media print {
    width: 110px;
    height: 110px;
    box-shadow: none;
    &::before,
    &::after {
      content: none;
    }
    & ${BodyClueDiv} {
      font-size: 32px;
    }
  }
`
