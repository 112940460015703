import React from 'react'

import { NavRouteProps } from 'dashboards/types'
import { moduleCodes } from 'shared/dashboard/moduleCodes'

import { Column, H3, Row, Spacer } from 'common/ui'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'
import { TipSheetCell } from 'dashboards/common/TipSheetCell'
import { LinkButton } from 'common/LinkButton'
import { Page } from 'dashboards/common/Page'

import { baseUrl } from 'dashboards/facilitator/FacilitatorDashboard'
// import { TipsheetPrintButton } from 'tipsheets/TipsheetPrint'

export const TeacherTipSheets: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route}>
      <Cells>
        <Cell accent="orange">
          <CellInner>
            <Column flex="1 1 auto">
              <H3>Teacher Introductory Letter</H3>
            </Column>
            <Row flex="none">
              {/* <TipsheetPrintButton size="s" theme="orange" context="facilitator" moduleCode="tipsheet-intro" /> */}
              <Spacer size="xs" />
              <LinkButton
                size="s"
                children="View"
                theme="orange"
                to={`${baseUrl}/resources/teacher-tip-sheets/tipsheet-intro`}
              />
            </Row>
          </CellInner>
        </Cell>
        {moduleCodes['cadet'].map(moduleCode => (
          <TipSheetCell
            context="facilitator"
            baseUrl={`${baseUrl}/resources`}
            key={moduleCode}
            moduleCode={moduleCode}
          />
        ))}
      </Cells>
    </Page>
  )
}
