import React from 'react'
import { Cell, CellInner } from './Cell'
import { Column, H3 } from 'common/ui'
import { LinkButton } from 'common/LinkButton'
import { baseUrl } from 'dashboards/facilitator/FacilitatorDashboard'
import { ModuleWorkflow } from 'shared/dashboard/types'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'
import { AnyModuleCode } from 'shared/dashboard/moduleCodes'

interface Props {
  moduleCode: AnyModuleCode
  moduleType: ModuleWorkflow
}

const getModuleTheme = (moduleType: ModuleWorkflow) => {
  switch (moduleType) {
    case 'cadet':
      return 'blue'
    case 'parent':
      return 'parentGreen'
    case 'facilitator':
      return 'purple'
    case 'teacher':
      return 'orange'
  }
}

export const FacManCell: React.FC<Props> = ({ moduleCode, moduleType }) => (
  <Cell accent={getModuleTheme(moduleType)}>
    <CellInner>
      <Column flex="1 1 auto">
        <H3>{getModuleTitle(moduleCode)}</H3>
      </Column>
      <Column flex="none">
        <LinkButton
          theme={getModuleTheme(moduleType)}
          size="s"
          children={'view'}
          to={`${baseUrl}/manual/${moduleType}/${moduleCode}`}
        />
      </Column>
    </CellInner>
  </Cell>
)
