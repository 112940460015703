// import { Skill, Reward, SkillUse, WorkflowType, Module, PresetModule } from './types'
import { BaseAction } from '../types'
import { GadgetPackState } from '../gadget-pack/types'
import { ActionTypes as GadgetPackActionTypes } from '../gadget-pack/actionTypes'
import { SectionStateActions } from './sections/sectionStateActionTypes'
import {
  SessionState as State,
  ParticipantState,
  FacilitatorTool,
  FocusLocation,
  ModuleStep,
  SessionProfile,
  SupplementarySlide,
  SectionState,
  SectionStates,
  JSONValueType,
  SavedInputValue,
  HydratedSupplementarySlideRef,
  HydratedModuleSlideRef,
  FacilitatorState,
  InputValueContext,
  SavedProgressionEvent,
} from './types'
import { QuestionnaireSummary, QuestionnaireUserDataStatus, QuestionnaireSummarySaved } from '../questionnaires/types'

export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_CURRENT_SLIDE = 'UPDATE_CURRENT_SLIDE'
export const UPDATE_PREVIEW_SLIDE = 'UPDATE_PREVIEW_SLIDE'
export const PARTICIPANT_CONNECTED = 'PARTICIPANT_CONNECTED'
export const PARTICIPANT_DISCONNECTED = 'PARTICIPANT_DISCONNECTED'
export const REGISTER_PARTICIPANTS = 'REGISTER_PARTICIPANTS'
export const FACILITATOR_CONNECTED = 'FACILITATOR_CONNECTED'
export const FACILITATOR_DISCONNECTED = 'FACILITATOR_DISCONNECTED'
export const UPDATE_FACILITATOR_PARTICIPANT_VIEW = 'UPDATE_FACILITATOR_PARTICIPANT_VIEW'
export const UPDATE_FACILITATOR_SLIDE_SCROLL = 'UPDATE_FACILITATOR_SLIDE_SCROLL'
export const ADD_PARTICIPANT_TOKEN = 'ADD_PARTICIPANT_TOKEN'
export const ADD_TOKEN_EVERYONE = 'ADD_TOKEN_EVERYONE'
export const REMOVE_TOKEN_EVERYONE = 'REMOVE_TOKEN_EVERYONE'
export const UPDATE_PARTICIPANT_TOKEN_MISSION = 'UPDATE_PARTICIPANT_TOKEN_MISSION'
export const UPDATE_PARTICIPANT_STATUS = 'UPDATE_PARTICIPANT_STATUS'
export const UPDATE_FACILITATOR_TOOL = 'UPDATE_FACILITATOR_TOOL'
export const UPDATE_TOKEN_TARGET = 'UPDATE_TOKEN_TARGET'
export const UPDATE_GROUP_HIGHLIGHT = 'UPDATE_GROUP_HIGHLIGHT'
export const TOGGLE_GROUP_LOCK_AREA = 'TOGGLE_GROUP_LOCK_AREA' // TODO: Pretty sure this is deprecated
export const TOGGLE_PARTICIPANT_LOCK_AREA = 'TOGGLE_PARTICIPANT_LOCK_AREA' // TODO: Pretty sure this is deprecated
export const UPDATE_PARTICIPANT_HIGHLIGHT = 'UPDATE_PARTICIPANT_HIGHLIGHT'
export const UPDATE_SECTION_STATE_DANGEROUS = 'UPDATE_SECTION_STATE_DANGEROUS'
export const DISPATCH_SECTION_STATE = 'DISPATCH_SECTION_STATE'
export const TOGGLE_GROUP_SLIDE_LOCK = 'TOGGLE_GROUP_SLIDE_LOCK'
export const UPDATE_PREVIEW_MODE = 'UPDATE_PREVIEW_MODE'
export const OVERWRITE_PARTICIPANTS = 'OVERWRITE_PARTICIPANTS'
export const ADD_SUPPLEMENTARY_SLIDE = 'ADD_SUPPLEMENTARY_SLIDE'
export const REMOVE_SUPPLEMENTARY_SLIDE = 'REMOVE_SUPPLEMENTARY_SLIDE'
export const UPDATE_SUPPLEMENTARY_SLIDE = 'UPDATE_SUPPLEMENTARY_SLIDE'
export const SHARE_SUPPLEMENTARY_SLIDE = 'SHARE_SUPPLEMENTARY_SLIDE'
export const UPDATE_PARTICIPANT_TRAY_OPEN = 'UPDATE_PARTICIPANT_TRAY_OPEN'
export const TOGGLE_PARTICIPANT_HELP = 'TOGGLE_PARTICIPANT_HELP'
export const TOGGLE_PARTICIPANT_LOCK = 'TOGGLE_PARTICIPANT_LOCK'
export const TOGGLE_GLOBAL_LOCK = 'TOGGLE_GLOBAL_LOCK'
export const GADGET_PACK_STATE_UPDATED = 'GADGET_PACK_STATE_UPDATED'
export const GADGET_PACK_ACTION_TRIGGERED = 'GADGET_PACK_ACTION_TRIGGERED'
export const PROGRESSION_EVENTS_UPDATED = 'PROGRESSION_EVENTS_UPDATED'
export const UPDATE_FACILITATOR_MANUAL_STEP = 'UPDATE_FACILITATOR_MANUAL_STEP'
export const QUESTIONNAIRE_STATE_UPDATED = 'QUESTIONNAIRE_STATE_UPDATED'
export const QUESTIONNAIRE_STATE_REQUESTED = 'QUESTIONNAIRE_STATE_REQUESTED'

export const ACTIVATE_INPUT_LOCK = 'ACTIVATE_INPUT_LOCK'
export const DEACTIVATE_INPUT_LOCK = 'DEACTIVATE_INPUT_LOCK'

export interface UpdateStateAction extends BaseAction {
  type: typeof UPDATE_STATE
  state: State
}

export interface UpdateCurrentSlideAction extends BaseAction {
  type: typeof UPDATE_CURRENT_SLIDE
  slideUid: ModuleStep['uid'] | SupplementarySlide['uid'] | null
  activateGroupLock: boolean
}

export interface UpdatePreviewSlideAction extends BaseAction {
  type: typeof UPDATE_PREVIEW_SLIDE
  facilitatorUid: string
  slideUid: ModuleStep['uid'] | SupplementarySlide['uid'] | null
}

export interface RegisterParticipantsAction extends BaseAction {
  type: typeof REGISTER_PARTICIPANTS
  sessionUid: string
  participantProfiles: SessionProfile[]
}

export interface ParticipantConnectedAction extends BaseAction {
  type: typeof PARTICIPANT_CONNECTED
  sessionUid: string
  profile: SessionProfile
}

export interface ParticipantDisconnectedAction extends BaseAction {
  type: typeof PARTICIPANT_DISCONNECTED
  sessionUid: string
  profile: SessionProfile
}

export interface FacilitatorConnectedAction extends BaseAction {
  type: typeof FACILITATOR_CONNECTED
  sessionUid: string
  profile: SessionProfile
}

export interface FacilitatorDisconnectedAction extends BaseAction {
  type: typeof FACILITATOR_DISCONNECTED
  sessionUid: string
  profile: SessionProfile
}

export interface UpdateFacilitatorParticipantViewAction extends BaseAction {
  type: typeof UPDATE_FACILITATOR_PARTICIPANT_VIEW
  facilitatorUid: string
  participant: SessionProfile | null // TEMP -- should be Participant
}

export interface UpdateFacilitatorSlideScrollAction extends BaseAction {
  type: typeof UPDATE_FACILITATOR_SLIDE_SCROLL
  facilitatorUid: string
  slideUid: string
  scroll: number
}

export interface AddParticipantTokenAction extends BaseAction {
  type: typeof ADD_PARTICIPANT_TOKEN
  participant: SessionProfile
}

export interface AddTokenEveryoneAction extends BaseAction {
  type: typeof ADD_TOKEN_EVERYONE
}

export interface RemoveTokenEveryoneAction extends BaseAction {
  type: typeof REMOVE_TOKEN_EVERYONE
}

export interface UpdateParticipantTokenMissionAction extends BaseAction {
  type: typeof UPDATE_PARTICIPANT_TOKEN_MISSION
  participantUid: string
  tokenMission: string
}

export interface UpdateParticipantStatusAction extends BaseAction {
  type: typeof UPDATE_PARTICIPANT_STATUS
  participant: SessionProfile
  status: ParticipantState['status']
}

export interface UpdateParticipantTrayOpen extends BaseAction {
  type: typeof UPDATE_PARTICIPANT_TRAY_OPEN
  participant: SessionProfile
  open: boolean
  trayType: null | 'gadgets' | 'mission'
}

export interface ToggleParticipantHelp extends BaseAction {
  type: typeof TOGGLE_PARTICIPANT_HELP
  participant: SessionProfile
}

export interface UpdateFacilitatorToolAction extends BaseAction {
  type: typeof UPDATE_FACILITATOR_TOOL
  facilitatorUid: string
  tool: FacilitatorTool | null
}

export interface ToggleGroupSlideLockAction extends BaseAction {
  type: typeof TOGGLE_GROUP_SLIDE_LOCK
  locked: boolean
}

export interface UpdateTokenTargetAction extends BaseAction {
  type: typeof UPDATE_TOKEN_TARGET
  tokenTarget: number
}

export interface UpdateGroupHighlightAction extends BaseAction {
  type: typeof UPDATE_GROUP_HIGHLIGHT
  location: FocusLocation | null
}

export interface UpdateParticipantHighlightAction extends BaseAction {
  type: typeof UPDATE_PARTICIPANT_HIGHLIGHT
  location: FocusLocation | null
  participantUid: ParticipantState['profile']['uid']
}

export interface ToggleGroupLockAreaAction extends BaseAction {
  type: typeof TOGGLE_GROUP_LOCK_AREA
  location: FocusLocation
}

// TODO: THIS might be redundant now
export interface ToggleParticipantLockAreaAction extends BaseAction {
  type: typeof TOGGLE_PARTICIPANT_LOCK_AREA
  location: FocusLocation
  profile: SessionProfile
}

export interface ToggleParticipantLockAction extends BaseAction {
  type: typeof TOGGLE_PARTICIPANT_LOCK
  participantUid: ParticipantState['profile']['uid']
}

export interface ToggleGlobalLockAction extends BaseAction {
  type: typeof TOGGLE_GLOBAL_LOCK
}

export interface UpdateSectionStateDangerousAction extends BaseAction {
  type: typeof UPDATE_SECTION_STATE_DANGEROUS
  section_id: SectionState['section_id']
  property: SectionState['property']
  state: SectionStates[this['property']]
  initial?: boolean
}

export interface DispatchSectionStateAction extends BaseAction {
  type: typeof DISPATCH_SECTION_STATE
  section_id: SectionState['section_id']
  property: SectionState['property']
  action: SectionStateActions[this['property']]
}

export interface OverwriteParticipantsAction extends BaseAction {
  type: typeof OVERWRITE_PARTICIPANTS
  participants: ParticipantState[]
  facilitators: FacilitatorState[]
}

export interface AddSupplementarySlideAction extends BaseAction {
  type: typeof ADD_SUPPLEMENTARY_SLIDE
  slideRef: HydratedSupplementarySlideRef
}

export interface RemoveSupplementarySlideAction extends BaseAction {
  type: typeof REMOVE_SUPPLEMENTARY_SLIDE
  uid: HydratedModuleSlideRef['uid']
}

export interface UpdateSupplementarySlideAction extends BaseAction {
  type: typeof UPDATE_SUPPLEMENTARY_SLIDE
  uid: HydratedModuleSlideRef['uid']
  slide: SupplementarySlide
}

export interface ShareSupplementarySlideAction extends BaseAction {
  type: typeof SHARE_SUPPLEMENTARY_SLIDE
  uid: HydratedModuleSlideRef['uid']
  slide: SupplementarySlide
}

export interface GadgetPackStateUpdatedAction extends BaseAction {
  type: typeof GADGET_PACK_STATE_UPDATED
  state: GadgetPackState
  actions: GadgetPackActionTypes[]
  revision: number
}

export interface GadgetPackActionTriggeredAction extends BaseAction {
  type: typeof GADGET_PACK_ACTION_TRIGGERED
  profileId: number
  action: GadgetPackActionTypes
}

export interface ProgressionEventsUpdatedAction extends BaseAction {
  type: typeof PROGRESSION_EVENTS_UPDATED
  progressionEvents: SavedProgressionEvent<any>[]
}

export interface UpdateFacilitatorManualStep extends BaseAction {
  type: typeof UPDATE_FACILITATOR_MANUAL_STEP
  facilitatorUid: FacilitatorState['profile']['uid']
  stepUid: string
}

export interface QuestionnaireStateUpdatedAction extends BaseAction {
  type: typeof QUESTIONNAIRE_STATE_UPDATED
  state: QuestionnaireSummary | QuestionnaireSummarySaved
  userDataStatus?: QuestionnaireUserDataStatus
}

export interface QuestionnaireStateRequestedAction extends BaseAction {
  type: typeof QUESTIONNAIRE_STATE_REQUESTED
  cadetUid: string
}

export interface ActivateInputLockAction extends BaseAction {
  type: typeof ACTIVATE_INPUT_LOCK
  inputUid: string
}

export interface DeactivateInputLockAction extends BaseAction {
  type: typeof DEACTIVATE_INPUT_LOCK
  inputUid: string
}

export type ActionTypes =
  | UpdateStateAction
  | UpdateCurrentSlideAction
  | UpdatePreviewSlideAction
  | ParticipantConnectedAction
  | ParticipantDisconnectedAction
  | RegisterParticipantsAction
  | FacilitatorConnectedAction
  | FacilitatorDisconnectedAction
  | UpdateFacilitatorParticipantViewAction
  | UpdateFacilitatorSlideScrollAction
  | AddParticipantTokenAction
  | AddTokenEveryoneAction
  | RemoveTokenEveryoneAction
  | UpdateParticipantTokenMissionAction
  | UpdateParticipantStatusAction
  | UpdateFacilitatorToolAction
  | ToggleGroupSlideLockAction
  | UpdateTokenTargetAction
  | UpdateGroupHighlightAction
  | ToggleGroupLockAreaAction
  | ToggleParticipantLockAreaAction
  | ToggleParticipantHelp
  | UpdateSectionStateDangerousAction
  | DispatchSectionStateAction
  | OverwriteParticipantsAction
  | AddSupplementarySlideAction
  | RemoveSupplementarySlideAction
  | UpdateSupplementarySlideAction
  | ShareSupplementarySlideAction
  | UpdateParticipantTrayOpen
  | ToggleParticipantLockAction
  | ToggleGlobalLockAction
  | UpdateParticipantHighlightAction
  | GadgetPackStateUpdatedAction
  | GadgetPackActionTriggeredAction
  | ProgressionEventsUpdatedAction
  | UpdateFacilitatorManualStep
  | QuestionnaireStateUpdatedAction
  | QuestionnaireStateRequestedAction
  | ActivateInputLockAction
  | DeactivateInputLockAction

export const ADD_UPDATE_INPUT_VALUE = 'ADD_UPDATE_INPUT_VALUE'
export const ADD_UPDATE_SAVED_INPUT_VALUE = 'ADD_UPDATE_SAVED_INPUT_VALUE'
export const DELETE_SAVED_INPUT_VALUE = 'DELETE_SAVED_INPUT_VALUE'
export const SET_SAVED_USER_INPUT_VALUES = 'SET_SAVED_USER_INPUT_VALUES'
export const DISPATCH_INPUT_VALUE_ACTION = 'DISPATCH_INPUT_VALUE_ACTION'

// tslint:disable-next-line:no-empty-interface
interface BaseInputAction extends BaseAction {}

export interface AddUpdateValueAction extends BaseInputAction {
  type: typeof ADD_UPDATE_INPUT_VALUE
  context: InputValueContext
  value: JSONValueType
  priorityDelivery?: boolean
  // inputValueObj: InputValue<JSONValueType>
}

export interface DispatchInputValueAction extends BaseInputAction {
  type: typeof DISPATCH_INPUT_VALUE_ACTION
  context: InputValueContext
  property: SectionState['property']
  action: SectionStateActions[this['property']]
}

export interface AddUpdateSavedInputValueAction extends BaseInputAction {
  type: typeof ADD_UPDATE_SAVED_INPUT_VALUE
  payload: SavedInputValue<JSONValueType>
}

export interface DeleteSavedInputValueAction extends BaseInputAction {
  type: typeof DELETE_SAVED_INPUT_VALUE
  id: SavedInputValue<JSONValueType>['id']
  participant_uid: SavedInputValue<JSONValueType>['participant_uid']
}

export interface SetSavedUserInputValuesAction extends BaseInputAction {
  type: typeof SET_SAVED_USER_INPUT_VALUES
  inputValues: SavedInputValue<JSONValueType>[]
}

export type InputActionTypes =
  | AddUpdateValueAction
  | DispatchInputValueAction
  | AddUpdateSavedInputValueAction
  | DeleteSavedInputValueAction
  | SetSavedUserInputValuesAction
