/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import {
  EmotionometerRange,
  EmotionometerType,
  EmotionometerDevice,
  EmotionometerSticker,
  EmotionometerStickerBase,
  RouteProps,
} from 'shared/e-telligence/types'

import {
  AddUpdateCustomStickerAction,
  ADD_UPDATE_CUSTOM_STICKER,
  REMOVE_EMOTIONOMETER_STICKER,
  RemoveEmotionometerStickerAction,
  ADD_EMOTIONOMETER_STICKER,
  AddEmotionometerStickerAction,
} from 'shared/e-telligence/actionTypes'

import {
  emotionRangeValues,
  emotionRangeValuesUnordered,
  emotionRangeDistribution,
  emotionometerDeviceLabels,
  emotionRangeOptions,
} from './constants/typeValueMaps'

import { Column, Padding, Spacer, Row, Hr, Button } from 'common/ui'
import { Navigation, baseUrl } from './common/Navigation'
import { ETellPanel } from './common/ETellPanel'
import { EmotionometerBar } from './common/EmotionometerBar'
import { FieldLabel } from 'common/Field'
import { StickerTile } from './common/StickerTile'
import { StickerEditModal } from './common/StickerEditModal'
import { Hint } from 'common/Hint'

import { useUserState } from 'app/UserState'
import { useETelligenceState } from './ETelligenceState'
import { presetStickers } from './constants/stickers'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { useMedia } from 'utils/useMedia'
import { StickerViewModal } from './common/StickerViewModal'

const emotionRangeAdjectives: { [k in EmotionometerRange]: string } = {
  '1-3': 'low',
  '4-7': 'medium',
  '8-10': 'high',
}

export interface RouteParams {
  emotion: EmotionometerType
  device: EmotionometerDevice
  emotionRange?: EmotionometerRange
}

interface Props {
  isAdding?: boolean
}

export const EmotionometerStickerEdit: React.FC<Props> = ({ isAdding }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useETelligenceState()
  const { usingIOSvhFix } = useUserState()

  const { emotion } = useParams<RouteParams>() as RouteParams
  const emotionometer = state.emotionometers.find(({ type }) => type === emotion)
  if (!emotionometer) return null

  return (
    <ETellPanel style={{ height: usingIOSvhFix ? '100%' : 'calc(100vh - 20px)' }}>
      <Navigation
        title={`Create ${emotionometer.title} Emotionometer`}
        buttonLabel={'Card Holder'}
        buttonPress={() => history.push(`${baseUrl}/home`, location.state)}
      />
      <EmotionometerStickerEditInner baseUrl={baseUrl} state={state} dispatch={dispatch} isAdding={isAdding} />
    </ETellPanel>
  )
}

export const EmotionometerStickerEditInner: React.FC<RouteProps & Props> = ({ baseUrl, state, dispatch, isAdding }) => {
  const history = useHistory()
  const location = useLocation()
  const { emotion, device, emotionRange = '8-10' } = useParams<RouteParams>() as RouteParams

  const { getBaseAction } = useUserState()
  const [viewSticker, setViewSticker] = useState<EmotionometerStickerBase | undefined>()
  const portrait = useMedia('(orientation: portrait)')

  const bodyContainerRef = useRef<HTMLDivElement | null>(null)
  const [editSticker, setEditSticker] = useState<Partial<EmotionometerSticker<EmotionometerDevice>> | undefined>(
    undefined
  )

  const emotionRangeOption = emotionRangeOptions.find(({ value }) => value === emotionRange)
  const emotionRangeIndex = emotionRangeOption ? emotionRangeOptions.indexOf(emotionRangeOption) : -1
  const emotionRangeOrder = emotionRangeOption?.index || 0

  const emotionometer = state.emotionometers.find(({ type }) => type === emotion)
  if (!emotionometer) return null

  const selectedStickers = (emotionometer[device] as EmotionometerStickerBase[]).filter(
    sticker => sticker.emotionRange === emotionRange
  )
  const selectedStickerIds = selectedStickers.map(({ id }) => id)

  const selectedSituationStickers = (emotionometer.situations || ([] as EmotionometerStickerBase[])).filter(
    sticker => sticker.emotionRange === emotionRange
  )

  const allStickers = [
    ...state.customStickers.filter(
      sticker => sticker.type === device && (device === 'bodyClues' ? sticker.emotion === emotion : true)
    ),
    ...presetStickers.filter(
      sticker => sticker.type === device && (device === 'bodyClues' ? sticker.emotion === emotion : true)
    ),
  ]

  const maxStickers = emotionRangeDistribution.filter(num => num === emotionRangeOrder).length
  const atCapacity = selectedStickers.length >= maxStickers

  const addNewCustomSticker = (data: EmotionometerSticker<typeof device>) => {
    const sticker: EmotionometerSticker<typeof device> = {
      ...data,
      id: data.id || 'sticker' + Date.now(),
      type: data.type || device,
      emotion: data.emotion || emotion,
      emotionRange: emotionRange || undefined,
    }
    dispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_STICKER,
      sticker,
      emotionometerType: emotion,
      emotionometerRange: emotionRange,
    } as AddUpdateCustomStickerAction)
    setEditSticker(undefined)
  }

  const handleStickerSelect = (
    sticker: EmotionometerSticker<typeof device> | undefined,
    selected: boolean,
    disabled: boolean
  ) => {
    if (!sticker) {
      setEditSticker({})
    } else if (!disabled) {
      if (selected) {
        dispatch({
          ...getBaseAction(),
          type: REMOVE_EMOTIONOMETER_STICKER,
          emotionometerType: emotion,
          emotionometerDevice: device,
          emotionometerRange: emotionRange,
          stickerId: sticker.id,
        } as RemoveEmotionometerStickerAction)
      } else {
        dispatch({
          ...getBaseAction(),
          type: ADD_EMOTIONOMETER_STICKER,
          emotionometerType: emotion,
          emotionometerDevice: device,
          emotionometerRange: emotionRange,
          sticker: { ...sticker, emotionRange },
        } as AddEmotionometerStickerAction)
      }
    }
  }

  const handleUpdateSticker = (data: EmotionometerSticker<EmotionometerDevice>, sticker: EmotionometerStickerBase) => {
    const updatedSticker: EmotionometerSticker<EmotionometerDevice> = {
      ...data,
      id: data.id || 'sticker' + Date.now(),
      type: data.type || sticker.type,
      emotion: emotionometer.type,
      emotionRange: data.emotionRange || sticker.emotionRange,
    }
    dispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_STICKER,
      sticker: updatedSticker,
      emotionometerType: emotionometer.type,
      emotionometerRange: sticker.emotionRange,
    } as AddUpdateCustomStickerAction)
  }

  const handlePrev = () => {
    bodyContainerRef.current?.scrollTo?.(0, 0)
    history.push(
      `${baseUrl}/emotionometer/${emotion}/${device}/${emotionRangeValuesUnordered[emotionRangeIndex - 1]}`,
      location.state
    )
  }

  const handleNext = () => {
    bodyContainerRef.current?.scrollTo?.(0, 0)
    history.push(
      emotionRangeIndex >= emotionRangeValues.length - 1
        ? `${baseUrl}/emotionometer/${emotion}/edit`
        : `${baseUrl}/emotionometer/${emotion}/${device}/${emotionRangeValuesUnordered[emotionRangeIndex + 1]}`,
      location.state
    )
  }

  return (
    <>
      <Column ref={bodyContainerRef} flex="1 1 auto" style={{ overflow: 'auto' }}>
        <Row>
          <Padding size="s">
            <EmotionometerBar
              type={emotion}
              value={[emotionRangeOrder]}
              distribution={emotionRangeDistribution}
              labels={[]}
            />
          </Padding>
        </Row>
        {device === 'bodyClues' && selectedSituationStickers.length > 0 && (
          <>
            <Row>
              <Padding size="s" style={{ paddingTop: 0 }}>
                <Column padding="s" flex style={{ border: '1px solid #adb4d8', borderRadius: 7 }}>
                  <Column flex="1 1 auto">
                    <FieldLabel style={{ fontSize: 14 }}>
                      <Hint />
                      &nbsp;&nbsp;These are the <u>situation(s)</u> you selected for when you feel{' '}
                      <u>{emotionRangeAdjectives[emotionRange]}</u> levels of <u>{emotion}</u>:
                    </FieldLabel>
                  </Column>
                  <Spacer size="xs" />
                  <Row
                    flexWrap
                    justifyContent={
                      emotionRange === '1-3' ? 'flex-start' : emotionRange === '8-10' ? 'flex-end' : 'center'
                    }>
                    {intersperseSpacers(
                      selectedSituationStickers.map((sticker, i) => (
                        <StickerTile<'situations'>
                          key={i}
                          sticker={sticker}
                          size={portrait ? 58 : 72}
                          onEdit={() => setViewSticker(sticker)}
                        />
                      )),
                      's'
                    )}
                  </Row>
                </Column>
              </Padding>
            </Row>
            <Spacer size="xs" />
          </>
        )}
        <Hr />
        <Row>
          <Padding
            style={{
              flexDirection: 'column',
              width: '100%', // for ie11
            }}>
            <FieldLabel style={{ fontSize: 18 }}>
              Choose up to{' '}
              <u>
                {maxStickers} {emotionometerDeviceLabels[device].toLowerCase()} stickers
              </u>{' '}
              for when you feel <u>{emotionRangeAdjectives[emotionRange]}</u> levels of <u>{emotion}</u>:
            </FieldLabel>
            <Spacer size="s" />
            <Row flex flexWrap marginRight={-10}>
              {[undefined, ...allStickers].map((sticker, i) => {
                const selected = (sticker || false) && selectedStickerIds.indexOf(sticker.id) >= 0
                const disabled = !selected && atCapacity
                return (
                  <Column key={i} paddingRight="s" paddingBottom="s">
                    <StickerTile<typeof device>
                      selected={selected}
                      disabled={disabled}
                      sticker={sticker}
                      placeholder={`Create custom ${emotionometerDeviceLabels[device].toLowerCase()} sticker + `}
                      onEdit={() => handleStickerSelect(sticker, selected, disabled)}
                    />
                  </Column>
                )
              })}
            </Row>
          </Padding>
        </Row>
        <Row flex="1 1 auto" />
      </Column>
      <Column flex="none">
        <Hr />
        <Row>
          <Padding size="xs">
            <Row flex justifyContent="center">
              <Button size="s" disabled={emotionRangeIndex <= 0} onClick={handlePrev}>
                Back
              </Button>
              <Spacer size="s" />
              <Button size="s" flashing={atCapacity} onClick={handleNext}>
                {emotionRangeIndex >= emotionRangeValues.length - 1 ? 'Done' : 'Next'}
              </Button>
            </Row>
          </Padding>
        </Row>
      </Column>
      {!!editSticker && (
        <StickerEditModal
          initialValue={editSticker}
          onClose={() => setEditSticker(undefined)}
          onSave={addNewCustomSticker}
          device={device}
        />
      )}
      {!!viewSticker && (
        <StickerViewModal
          sticker={viewSticker}
          editMode={false}
          emotionometerType={emotionometer.type}
          onClose={() => setViewSticker(undefined)}
          onUpdateSticker={data => handleUpdateSticker(data, viewSticker)}
        />
      )}
    </>
  )
}
