import { findKey } from 'lodash'
import {
  ActionTypes,
  REORDER_PLAYER,
  MOVE_PLAYER,
  UPDATE_ACTIVE_DECK,
  UPDATE_PLAYER_TURN,
  DISCARD_DECK_CARD,
  SET_ENCOUNTER,
  CLEAR_ENCOUNTER,
  NEXT_GAME_PHASE,
  ROLL_DICE,
  CREATE_PLAYER,
  MOVE_TO_ENCOUNTER,
  MOVE_TO_DECK_TILE,
  SKIP_DECK_CARD,
  SORT_PLAYERS,
  SET_FINISHED_PLAYER,
  TOGGLE_SUPER_DICE,
  PREVIOUS_DECK_CARD,
  TOGGLE_POSTERS,
  SET_SELECTED_POSTER,
  TOGGLE_SHARE_CARD,
} from './actionTypes'
import {
  ActivityBoardGameState as State,
  BoardGameDeckType as DeckType,
  boardGameDeckTypes as deckTypes,
  DiceValue,
} from '../../../types'

import { decks } from './decks'

export function reducer(state: State, action: ActionTypes): State {
  switch (action.type) {
    case CREATE_PLAYER:
      // we need to get a random position for this player to do the arranging phase
      const randomIndex = Math.floor(Math.random() * state.randomPositions.length + 1)
      const value = state.randomPositions[randomIndex]

      return {
        ...state,
        players: {
          ...state.players,
          [action.uid]: { ...action.player, randomValue: value },
        },
        randomPositions: state.randomPositions.filter(item => item !== value),
      }

    case REORDER_PLAYER: {
      const players = Object.keys(state.players).reduce((obj, key) => {
        const player = state.players[key]
        let index = player.index
        if (index === action.newIndex) index = action.currentIndex
        else if (index === action.currentIndex) index = action.newIndex
        return { ...obj, [key]: { ...player, index } }
      }, {} as State['players'])

      return { ...state, players }
    }

    case MOVE_PLAYER:
      return {
        ...state,
        players: {
          ...state.players,
          [action.playerUid]: { ...state.players[action.playerUid], currentTile: action.tileKey },
        },
        activePlayerIndex: action.incrementTurn
          ? (state.activePlayerIndex + 1) % Object.keys(state.players).length
          : state.activePlayerIndex,
        moveStep: action.incrementTurn ? 'roll' : state.moveStep,
        encounter: null,
        finishedPlayerUid: action.tileKey === 'FINISH' ? action.playerUid : null,
      }

    case UPDATE_ACTIVE_DECK:
      return { ...state, moveStep: 'draw', activeDeck: action.deckType, currentDeckCard: action.deckCard || null }

    case SKIP_DECK_CARD:
      if (!state.activeDeck) return state
      const nextIndex = state.deckCardOrders[state.activeDeck][state.deckCardIndexes[state.activeDeck] + 1]
      const nextDeckCard = decks[state.activeDeck].cards[nextIndex]
      return {
        ...state,
        currentDeckCard: nextDeckCard,
        deckCardIndexes: {
          ...state.deckCardIndexes,
          [state.activeDeck]: state.deckCardIndexes[state.activeDeck] + 1,
        },
      }

    case PREVIOUS_DECK_CARD:
      if (!state.activeDeck) return state
      const previousIndex = state.deckCardOrders[state.activeDeck][state.deckCardIndexes[state.activeDeck] - 1]
      const previousDeckCard = decks[state.activeDeck].cards[previousIndex]
      return {
        ...state,
        currentDeckCard: previousDeckCard,
        deckCardIndexes: {
          ...state.deckCardIndexes,
          [state.activeDeck]: state.deckCardIndexes[state.activeDeck] - 1,
        },
      }

    case DISCARD_DECK_CARD:
      return {
        ...state,
        deckCardIndexes: {
          ...state.deckCardIndexes,
          [action.deckType]:
            (state.deckCardIndexes[action.deckType] + 1) % state.deckCardOrders[action.deckType].length,
        },
        activeDeck: null,
        currentDeckCard: null,
        moveStep: 'roll',
        activePlayerIndex: (state.activePlayerIndex + 1) % Object.keys(state.players).length,
        sharingCard: false,
        postersOpen: false,
        selectedPoster: null,
      }

    case UPDATE_PLAYER_TURN:
      const nextPlayerIndex = getNextPlayerIndex(state)
      // removes the highlight around active player
      return nextPlayerIndex === false
        ? { ...state, moveStep: 'discard', encounter: null }
        : { ...state, moveStep: 'roll', activePlayerIndex: nextPlayerIndex, encounter: null }

    case SET_ENCOUNTER:
      return { ...state, encounter: action.encounter }

    case CLEAR_ENCOUNTER:
      return { ...state, encounter: null }

    case NEXT_GAME_PHASE:
      return { ...state, gameStep: action.phase, moveStep: action.moveStep || state.moveStep, finishedPlayerUid: null }

    case ROLL_DICE: {
      return {
        ...state,
        moveStep: 'move',
        dice: {
          value: state.superDice ? ((action.diceIndex * 2) as DiceValue) : action.diceIndex,
          increment: state.dice.increment += 1,
        },
      }
    }

    case MOVE_TO_ENCOUNTER:
      return {
        ...state,
        players: {
          ...state.players,
          [action.playerUid]: { ...state.players[action.playerUid], currentTile: action.tileKey },
        },
        moveStep: 'discard',
        encounter: action.encounter,
      }

    case MOVE_TO_DECK_TILE:
      return {
        ...state,
        players: {
          ...state.players,
          [action.playerUid]: { ...state.players[action.playerUid], currentTile: action.tileKey },
        },
        moveStep: 'draw',
        activeDeck: action.deckType,
      }

    case SORT_PLAYERS:
      const sortedByRandomNumber = Object.keys(state.players)
        .sort((a, b) => (state.players[a].randomValue || 0) - (state.players[b].randomValue || 0))
        .reverse()

      const newPlayerOrder = sortedByRandomNumber.reduce((acc, current, idx) => {
        acc[current] = { ...state.players[current], index: idx }
        return acc
      }, {} as State['players'])

      return { ...state, players: newPlayerOrder }

    case SET_FINISHED_PLAYER:
      return {
        ...state,
        finishedPlayerUid: action.playerUid,
        activePlayerIndex: (state.activePlayerIndex + 1) % Object.keys(state.players).length,
      }

    case TOGGLE_SUPER_DICE:
      return {
        ...state,
        superDice: !state.superDice,
        dice: {
          value: !state.superDice ? ((state.dice.value * 2) as DiceValue) : ((state.dice.value / 2) as DiceValue),
          increment: state.dice.increment,
        },
      }

    case TOGGLE_POSTERS:
      return { ...state, postersOpen: !state.postersOpen }

    case SET_SELECTED_POSTER:
      return { ...state, selectedPoster: action.posterID }

    case TOGGLE_SHARE_CARD:
      return { ...state, sharingCard: !state.sharingCard }

    default:
      return state
  }
}

const initialState: State = {
  seed: Math.ceil(Math.random() * 1000),
  started: false,
  activePlayerIndex: 0,
  moveStep: 'setup',
  gameStep: 'ready',
  activeDeck: null,
  players: {},
  deckCardIndexes: deckTypes.reduce(
    (obj, deckType) => ({ ...obj, [deckType]: 0 }),
    {} as { [key in DeckType]: number }
  ),
  deckCardOrders: (Object.keys(decks) as DeckType[]).reduce(
    (obj, deckType) => ({ ...obj, [deckType]: decks[deckType].cards.map((_, i) => i) }),
    {} as { [key in DeckType]: number[] }
  ),
  currentDeckCard: null,
  encounter: null,
  dice: {
    value: (Math.floor(Math.random() * 6) + 1) as DiceValue,
    increment: 0,
  },
  randomPositions: [1, 2, 3, 4, 5, 6],
  finishedPlayerUid: null,
  superDice: false,
  sharingCard: false,
  postersOpen: false,
  selectedPoster: null,
}

export function getPlayerKeyByIndex(state: State, index: number) {
  return findKey(state.players, { index })
}

function getNextPlayerIndex(state: State) {
  for (let i = 1; i <= Object.keys(state.players).length + 1; i++) {
    const playerIndex = (state.activePlayerIndex + i) % Object.keys(state.players).length
    const playerKey = getPlayerKeyByIndex(state, playerIndex)
    if (playerKey && state.players[playerKey].currentTile !== 'FINISH') {
      return playerIndex
    }
  }
  return false
}

// tslint:disable-next-line: only-arrow-functions
const seedShuffle = function<T>(array: T[], seed: number = 1): T[] {
  // tslint:disable-next-line: one-variable-per-declaration
  let currentIndex = array.length,
    temporaryValue,
    randomIndex
  const random = () => {
    const x = Math.sin(seed++) * 10000
    return x - Math.floor(x)
  }
  while (0 !== currentIndex) {
    randomIndex = Math.floor(random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}

export const getInitialState = (injectInitial: State = initialState): State => {
  const state = { ...injectInitial }
  state.deckCardOrders = (Object.keys(decks) as DeckType[]).reduce(
    (obj, deckType) => ({
      ...obj,
      [deckType]: seedShuffle(
        decks[deckType].cards.map((_, i) => i),
        state.seed
      ),
    }),
    {} as { [key in DeckType]: number[] }
  )
  return state
}
