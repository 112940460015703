import trim from 'lodash/trim'
import { getSections } from 'questionnaires/reducers'
import { Questionnaire, QuestionnaireSummary, QuestionSection, SectionObject } from 'shared/questionnaires/types'
import { InputSection } from 'shared/session/types'

interface ValidationDetail {
  key: string
  valid: boolean
  message?: string
}

const stripHtml = (html: string) => {
  let string = ''
  try {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    string = doc.body.textContent || ''
  } catch (error) {
    string = html.replace(/<[^>]*>?/gm, '')
  }
  const textarea = document.createElement('textarea')
  textarea.innerHTML = string
  return textarea.value || ''
}

const findResponses = (key: string, userData: QuestionnaireSummary) =>
  (userData.responses || []).filter(({ unique_key }) => unique_key === key)

export const validate = (questionnaire: Questionnaire, userData: QuestionnaireSummary) => {
  const sections: SectionObject[] = (questionnaire.panels || [])
    .reduce((arr, panel, i) => [...arr, ...getSections(panel)], [] as SectionObject[])
    .filter(({ property }) => ['input_sections', 'question_sections'].indexOf(property) >= 0)

  const details: ValidationDetail[] = []

  for (const { property, section } of sections) {
    switch (property) {
      case 'input_sections': {
        const { required, label } = section as InputSection
        const key = `panel_${section._owner_id}_input_${section.id}`
        const responses = findResponses(key, userData).filter(({ value }) =>
          typeof value === 'string' ? !!trim(value) : !!value
        )
        const valid = !required || responses.length > 0
        details.push({ key, valid, message: label ? `A response to "${label}" is required` : undefined })
        break
      }
      case 'question_sections': {
        const { type, text, required, question_options = [] } = section as QuestionSection // idk why this needs to be cast
        const key = `panel_${section._owner_id}_${type}_${section.id}`
        if (type === 'score') {
          for (let option of question_options) {
            const optionKey = `${key}_option_${option.id}`
            const responses = findResponses(optionKey, userData).filter(
              ({ score }) => score !== undefined && score !== null && score >= 0
            )
            const valid = responses.length > 0
            let message = option.title || undefined
            if (message && question_options.length > 5) message = `(${option.order + 1}) ${message}`
            details.push({ key, valid, message })
          }
        } else {
          const responses = findResponses(key, userData).filter(({ value }) => value !== undefined)
          const valid = !required || responses.length > 0
          const message = text ? trim(stripHtml(text)) : undefined
          details.push({ key, valid, message: !!message ? `A response to "${message}" is required` : undefined })
        }
        break
      }
      default: {
        console.log(`don't know what to do with this`, section)
      }
    }
  }

  return details
}
