import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { Page } from 'dashboards/common/Page'
import { LicenseDetails } from 'dashboards/common/License'
import { useMentorDashData } from './MentorDashboardState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { ProductKey } from 'shared/dashboard/types'
import { SpinnerWithLabel } from 'common/Spinner'

export const DigiHQ: React.FC<NavRouteProps> = ({ route }) => {
  const [mentor] = useMentorDashData('mentor')
  const [productKey, { loading }] = useEndpoint<ProductKey>(
    mentor ? `/api/v1/product_keys/${mentor.product_key_uid}` : null
  )

  return (
    <Page route={route} title="SAS Digital HQ">
      {loading && <SpinnerWithLabel label="Loading data..." />}
      {productKey && <LicenseDetails productKey={productKey} type="mentor" mentor={mentor} />}
    </Page>
  )
}
