import { ActivitySecretTransmissionState, CardDeckSectionState, ParticipantState } from '../../../types'

export const FLIP_CARD = 'FLIP_CARD'
export const SELECT_CARD = 'SELECT_CARD'
export const NEXT_ROUND = 'NEXT_ROUND'

interface BaseAction {}

export interface FlipCardAction extends BaseAction {
  type: typeof FLIP_CARD
  participantUid: keyof ActivitySecretTransmissionState
  cardUid: string
}

export interface SelectCardAction extends BaseAction {
  type: typeof SELECT_CARD
  participantUid: keyof ActivitySecretTransmissionState
  cardUid: string
}

export interface NextRoundAction extends BaseAction {
  type: typeof NEXT_ROUND
  participants: ParticipantState[]
  deckState: CardDeckSectionState
}

export type ActionTypes = FlipCardAction | SelectCardAction | NextRoundAction
