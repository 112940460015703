import React, { useEffect, useState } from 'react'
import { toJson } from 'utils/apiUtils'
import { Panel, CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_B_S, H1, P, Button, Column } from 'common/ui'
import Modal from './Modal'

type ManifestShape = {
  commitHash: string
  commitHashShort: string
  files: string[]
  entrypoints: string[]
}

export const VersionCheckerPrompt: React.FC = () => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [clicked, setClicked] = useState<boolean>(false)

  const handleAppReset = () => {
    setClicked(true)
    window.caches
      .keys()
      .then(arr => Promise.all(arr.map(str => caches.delete(str))))
      .then(() => {
        console.log('All cache keys cleared')
        // @ts-expect-error - true arg is probs some backwards compat thing
        window.location.reload(true)
      })
      .catch(e => {
        console.log('failed to clear cache :/', e)
        setShowPrompt(false)
        setClicked(false)
      })
  }

  useEffect(() => {
    // only run once!
    const envCommitHash = process.env.REACT_APP_COMMIT_HASH
    if (!envCommitHash) {
      console.warn('Commit hash not found in ENV!')
      return
    }
    fetch(`/asset-manifest.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then(toJson)
      .then((data: ManifestShape) => {
        if (data.commitHash !== envCommitHash) {
          console.warn(`OUT OF DATE NEED TO CLEAR CACHE`)
          setShowPrompt(true)
        } else {
          console.log(`🤙 You're all up to date!`)
        }
      })
  }, [])

  if (!showPrompt) return null
  return (
    <Modal
      isOpen
      onRequestClose={() => {
        // do nothing, ensure user makes a choice
      }}>
      <Panel flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_B_S]} padding="l">
        <Column alignItems="center" style={{ textAlign: 'center' }}>
          <H1>New version!</H1>
          <P>
            Hey there, you're running an old version of SAS. <br />
            Press the button below to refresh the page and update!
          </P>
          <Button
            theme="blue"
            onClick={handleAppReset}
            disabled={clicked}
            children={clicked ? 'One moment...' : 'Update Now'}
          />
          <P style={{ maxWidth: 250, display: 'block' }}>
            <small>
              Note: if you see this message repeatedly try clearing your cache manually or opening SAS in an incognito
              (private) tab.
            </small>
          </P>
        </Column>
      </Panel>
    </Modal>
  )
}
