import React from 'react'
import { ThemeConsumer, ThemeProvider } from 'styled-components'

export const AppPanelTheme: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
  <ThemeConsumer>
    {theme => (
      <ThemeProvider theme={{ ...theme, panelShadowColor: theme.appPanelShadowColor }}>
        <div {...props} />
      </ThemeProvider>
    )}
  </ThemeConsumer>
)
