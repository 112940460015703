import React from 'react'
import { Checkbox } from 'common/Checkbox'
import { Container, Hr } from 'common/ui'
import { AvoidPageBreak, H1, H2, PrintGlobalStyle, Spacer } from 'print/ui-print'
import { usePdfPayload } from 'utils/usePdfPayload'

export interface FacilitatorChecklistPrintPayload {
  module: string
  date: string
  sections: ChecklistSection[]
}

interface ChecklistSection {
  title: string
  checklist: ChecklistItem[]
}

interface ChecklistItem {
  label: string
  value: boolean
}

// eslint-disable-next-line
const testPayload: FacilitatorChecklistPrintPayload = {
  module: 'Parent - Module 1a',
  date: '01/01/2021',
  sections: [
    {
      title: 'Welcome',
      checklist: [
        { label: 'Outline the activities that will be completed in this club meeting.', value: true },
        { label: 'Outline the activities that will be completed in this club meeting.', value: true },
        { label: 'Outline the activities that will be completed in this club meeting.', value: true },
      ],
    },
    {
      title: 'Another',
      checklist: [
        { label: 'Outline the activities that will be completed in this club meeting.', value: true },
        { label: 'Outline the activities that will be completed in this club meeting.', value: true },
        { label: 'Outline the activities that will be completed in this club meeting.', value: true },
      ],
    },
  ],
}

export const FacilitatorChecklistPrint: React.FC = () => {
  const payload = usePdfPayload<FacilitatorChecklistPrintPayload>()
  // const payload = testPayload

  if (!payload) return null

  return (
    <>
      <PrintGlobalStyle />
      <Container>
        <Spacer size="l" />
        <H1>{payload.module}</H1>
        <H2 style={{ marginTop: 10 }}>{payload.date}</H2>
        {payload.sections.map((section, i) => (
          <React.Fragment key={i}>
            <Spacer size="l" />
            <AvoidPageBreak>
              <H2>{section.title}</H2>
              <Spacer size="s" />
              <Hr />
              <Spacer />
              {section.checklist.map((checklist, idx) => (
                <Checkbox
                  key={idx}
                  size="s"
                  checked={checklist.value}
                  onChange={() => {}}
                  wrapperProps={{ style: { marginBottom: 8 } }}
                  children={checklist.label}
                />
              ))}
            </AvoidPageBreak>
          </React.Fragment>
        ))}
        <Spacer size="l" />
      </Container>
    </>
  )
}
