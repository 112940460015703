export function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    console.log(`Fallback: Copying text command was ${successful ? 'successful' : 'unsuccessful'}`)
  } catch (err) {
    console.warn('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    () => console.log('Async: Copying to clipboard was successful!'),
    err => console.warn('Async: Could not copy text: ', err)
  )
}
