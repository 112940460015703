import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { red } from 'themes'
import { GroupEntity } from 'shared/dashboard/types'
import { LinkButton } from 'common/LinkButton'

import { baseUrl } from 'dashboards/facilitator/FacilitatorDashboard'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useUserState } from 'app/UserState'
import { postJson } from 'utils/apiUtils'
import { getGroupPublicName } from 'utils/titleUtils'

import {
  Column,
  Padding,
  Row,
  Spacer,
  CUT_TL,
  CUT_TR,
  CUT_BR,
  CUT_BL,
  TAB_B_L,
  TAB_T_L,
  P,
  Button,
  Input,
} from 'common/ui'
import { Cell, CellInner } from './Cell'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { ConfirmModal } from 'common/ConfirmModal'
import { InfoTooltip } from 'common/Tooltip'
import { ExpiryStatus, getGroupExpiryLabel } from 'dashboards/utils/facilitatorUtils'
import { StatusIcon } from 'common/StatusIcon'

interface Props {
  group: GroupEntity
  callback?: () => void
  readOnly?: boolean
}

const expiryTooltipContent = (val: ExpiryStatus) => {
  switch (val) {
    case 'expired':
      return 'Expired: this group contains a Cadet Place that has expired. The cadet can no longer join live club meetings or use SAS Digital HQ.\n\nIf this group is finished, you can "Complete" the group to clear details from your Facilitator Dashboard. Remember to download/print any group details needed for your professional files first (e.g. Progress Screen, Assessment, Mentor Details and Past Meeting Lists).'
    case 'due':
      return 'Act now: this group contains a Cadet Place that will expire in less than 1 month. Expired Cadet Places cannot be restored, so please ensure that all remaining meetings are completed before the expiry date. Alternatively, ask the Consenting Adult (Mentor 1) to respond to their renewal email to add an extra 12 months to this Cadet Place.'
    case 'warning':
      return 'Caution: this group contains a Cadet Place that will expire in less than three months. Please ensure that any remaining meetings will be completed before the expiry date or ask the Consenting Adult (Mentor 1) to renew this Cadet Place for a further 12 months.'
    default:
      return ''
  }
}

export const GroupCell: React.FC<Props> = ({ group, callback = () => {}, readOnly }) => {
  const { accessToken, drupalProfile } = useUserState()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showInputModal, setShowInputModal] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [orphaning, setOrphaning] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const isManagingFacilitator = drupalProfile ? group.facilitator_id === +drupalProfile.user_id : false

  const orphanGroup = (confirmed: boolean) => {
    setShowModal(false)
    if (confirmed) {
      setOrphaning(true)
      postJson(`/api/v1/groups/orphan/${group.uid}/${group.provider_uid}`, {}, getAuthRequestParams(accessToken), {
        retries: 0,
      })
        .then(() => {
          setOrphaning(false)
          callback()
        })
        .catch(e => {
          alert('Unable to remove group')
          console.warn(e)
        })
    }
  }

  const handleConfirmDelete = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setShowDeleteModal(false)
      setDeleting(true)
      fetch(`/api/v1/groups/${group.uid}`, { method: 'DELETE', ...getAuthRequestParams(accessToken) })
        .then(() => {
          callback()
          setDeleting(false)
        })
        .catch(() => {
          setDeleting(false)
        })
    })
  }

  const groupExpiryLabel = getGroupExpiryLabel(group)

  return (
    <>
      <Row key={group.id} justifyContent="space-between" style={{ marginBottom: 15 }}>
        <Cell style={{ width: isManagingFacilitator ? 'calc(100% - 298px)' : '100%', boxSizing: 'border-box' }}>
          <CellInner>
            <Row justifyContent="space-between" alignItems="center" flexWrap={true} style={{ width: '100%' }}>
              <Column flex="1 1 auto" margin={[5, 0]}>
                <Row margin={[5, 0]} alignItems="center">
                  {isManagingFacilitator ? group.name : getGroupPublicName(group)}
                  {groupExpiryLabel !== 'active' && (
                    <InfoTooltip
                      placement="right"
                      content={expiryTooltipContent(groupExpiryLabel)}
                      tooltipStyle={{ width: 350 }}
                      containerStyle={{ marginLeft: 5, height: 24, cursor: 'pointer' }}>
                      {StatusIcon(groupExpiryLabel)}
                    </InfoTooltip>
                  )}
                </Row>
                {isManagingFacilitator && <small>{getGroupPublicName(group)}</small>}
              </Column>
              <Row margin={[5, 0]}>
                <LinkButton size="s" children="Cadet Overview" to={`${baseUrl}/groups/${group.uid}/overview`} />
                {!readOnly && (
                  <>
                    <Spacer size="s" />
                    <LinkButton size="s" children="Edit" to={`${baseUrl}/groups/${group.uid}`} />
                  </>
                )}
              </Row>
            </Row>
          </CellInner>
        </Cell>
        {isManagingFacilitator && (
          <Cell style={{ width: 283, boxSizing: 'border-box' }}>
            <CellInner>
              <Row alignItems="center">
                <InfoTooltip
                  iconSize={16}
                  placement="left"
                  containerStyle={{ marginLeft: 0 }}
                  content={`When you have finished your SAS Small Group Program delivery, “COMPLETE” your group. This will remove the group details and past meetings from your dashboard. Please download/print any group information needed for your professionals records prior to completion. You will not be able to restore this group to your dashboard once completed.`}
                />
                <Spacer size="s" />
                <Button
                  size="s"
                  onClick={e => {
                    e.preventDefault()
                    setShowDeleteModal(true)
                  }}
                  style={{ cursor: 'pointer' }}
                  theme="blue"
                  disabled={deleting}
                  children={deleting ? 'Completing...' : 'Complete'}
                />
                <Spacer size="s" />
                <Button
                  size="s"
                  theme="blue"
                  children={orphaning ? 'Processing...' : 'Unassign'}
                  style={{ cursor: 'pointer' }}
                  disabled={orphaning}
                  onClick={e => {
                    e.preventDefault()
                    setShowModal(true)
                  }}
                />
              </Row>
            </CellInner>
          </Cell>
        )}
      </Row>
      <MobileModalPanel
        isOpen={showDeleteModal}
        panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}>
        <Padding size="l" style={{ maxWidth: 600 }}>
          <P>Are you sure you are ready to COMPLETE this group?</P>
          <P>
            <strong>This will remove the group details and past meetings from your Facilitator Dashboard.</strong>
          </P>
          <P>
            Prior to completing ensure you have downloaded/printed any group details needed for your professional files,
            for example:
          </P>
          <ul style={{ paddingLeft: 15, margin: 0 }}>
            <li>
              <P>Cadet Overview - Progress Screen, Assessment, Mentor Details</P>
            </li>
            <li>
              <P>Group Meetings - Past Meetings List</P>
            </li>
          </ul>
          <Spacer />
          <Row justifyContent="center">
            <LinkButton
              style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
              children="Go to cadet overview"
              to={`${baseUrl}/groups/${group.uid}/overview`}
              size="s"
            />
            <Spacer />
            <Button
              style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
              children="Complete"
              onClick={() => {
                ReactDOM.unstable_batchedUpdates(() => {
                  setShowInputModal(true)
                  setShowDeleteModal(false)
                })
              }}
              size="s"
              theme={red}
            />
            <Spacer />
            <Button
              style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
              children="Cancel"
              onClick={() => setShowDeleteModal(false)}
              size="s"
            />
          </Row>
          <Spacer />
        </Padding>
      </MobileModalPanel>

      <MobileModalPanel
        isOpen={showInputModal}
        panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}>
        <Padding size="l" style={{ maxWidth: 600 }}>
          <P>Are you sure you are ready to COMPLETE this group?</P>
          <P>
            Type "permanently remove" and click "Complete" to permanently remove all group information from you
            dashboard.
          </P>
          <P>Click "Take me back" if you clicked this button in error.</P>
          <Spacer />
          <Input
            placeholder="permanently remove"
            inputSize="m"
            value={inputValue}
            onChange={e => setInputValue(e.target.value.toLowerCase())}
          />
          <Spacer size="l" />
          <Row justifyContent="center">
            <Button
              disabled={inputValue !== 'permanently remove'}
              style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
              children="Complete"
              onClick={handleConfirmDelete}
              size="s"
              theme={red}
            />
            <Spacer />
            <Button
              style={{ borderRadius: 10, width: '25%', boxSizing: 'border-box', cursor: 'pointer' }}
              children="Take me back"
              onClick={() => {
                ReactDOM.unstable_batchedUpdates(() => {
                  setShowInputModal(false)
                  setShowDeleteModal(true)
                  setInputValue('')
                })
              }}
              size="s"
            />
          </Row>
          <Spacer />
        </Padding>
      </MobileModalPanel>
      <ConfirmModal
        isOpen={showModal}
        onSubmit={confirmed => {
          orphanGroup(confirmed)
        }}
        label="Are you sure you want to stop being this group's primary/managing SAS Facilitator? The group can be assigned to another team member from their own Facilitator Dashboard"
      />
    </>
  )
}
