import React, { PropsWithChildren, ComponentPropsWithoutRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { ButtonSize, ButtonProps, Button, Row } from './ui'
import { ThemeName } from 'themes'

type BaseOptionType<ValueType> = { value: ValueType; label?: React.ReactNode } & ButtonProps

export interface RadioButtonGroupProps<ValueType, OptionType extends BaseOptionType<ValueType>> {
  size?: ButtonSize
  theme?: ThemeName
  options: OptionType[]
  Component?: StyledComponent<'button', any, ButtonProps, never>
  value: any
  onChange: (value: any) => void
}

export const RadioButtonGroup = <ValueType, OptionType extends BaseOptionType<ValueType> = BaseOptionType<ValueType>>({
  Component = Button,
  options,
  value,
  onChange,
  size,
  theme,
}: PropsWithChildren<RadioButtonGroupProps<ValueType, OptionType>>) => {
  return (
    <RadioButtonGroupContainer Component={Component}>
      {options.map(({ value: optionValue, label, ...props }, i) => {
        const selected = optionValue === value
        return (
          <Component
            key={i}
            {...props}
            size={size}
            theme={theme}
            children={label}
            selected={selected}
            className={selected ? 'selected' : undefined}
            onClick={() => onChange(optionValue)}
          />
        )
      })}
    </RadioButtonGroupContainer>
  )
}

interface RadioButtonGroupContainerProps extends ComponentPropsWithoutRef<typeof Row> {
  Component: StyledComponent<'button', any, ButtonProps, never>
}

export const RadioButtonGroupContainer = styled(Row)<RadioButtonGroupContainerProps>`
  flex: 1 1 auto;
  & ${p => p.Component} {
    display: inline-block;
    &:not(.selected) {
      background: none;
      background-color: transparent;
      color: rgba(1, 26, 70, 0.75) !important;
      border-color: #bdc3e0;
    }
    &:not(.selected)::after {
      border-color: transparent;
    }
    &:not(:first-child),
    &:not(:first-child)::after {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-child),
    &:not(:last-child)::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    & + ${p => p.Component}:not(.selected) {
      border-left: none;
    }
  }
`

export const RadioButtonTabModifier = styled(Row)`
  border-bottom: 1px solid #bdc3e0;

  ${Button} {
    border-radius: 10px 10px 0px 0px !important;
    border: 1px solid #bdc3e0;
    border-bottom: none !important;
    background: none;
    box-shadow: none;
    text-transform: capitalize;
    background: #fff;
    opacity: 0.7;
    color: #011a46;

    &:after {
      display: none !important;
    }

    &.selected {
      color: #011a46 !important;
      opacity: 1;
      transform: translateY(0);
    }
  }
`
