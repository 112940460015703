import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'

import { SkillUse } from 'skill-tracker/types'
import { Row, Spacer, Button, H3, P, Column, Padding, H2, Hr } from 'common/ui'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { InlineRadioGroup } from 'common/RadioGroup'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
// import { validate as validateAgentDaySkill } from './SkillUseEditModal'
import { Sticker } from '../common/Sticker'
import { ValidationErrorList } from '../../common/ValidationErrorList'

import { getInputProps } from 'utils/virtualKeyboard'
import { locations } from 'skill-tracker/constants/locations'
import { useSkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { ScenePreview } from 'journal/common/ScenePreview'
import { BACKGROUND_RATIO } from 'scene-builder/phaser/SceneBuilderScene'

const validate = (data: Partial<SkillUse>, errors: string[] = []) => {
  // errors = validateAgentDaySkill(data, errors)
  if (!data.location) errors.push(`Please enter practise location`)
  return errors
}

export const isEmpty = (skillUse: SkillUse): boolean => !skillUse.text && !skillUse.scene && !skillUse.location

export const SkillUseApprovalModal: React.FC<{
  readOnly?: boolean
  skillUse: SkillUse
  onSave: (skillUse: SkillUse) => void
  onClear: (skillUse: SkillUse) => void
  onClose: () => void
}> = ({ readOnly, skillUse, onSave, onClose, onClear }) => {
  const { state } = useSkillTrackerState()
  const textareaInputRef = useRef<HTMLTextAreaElement | null>(null)
  const [editSkillUse, setEditSkillUse] = useState<SkillUse>(() => skillUse)
  const [saveAttempted, setSaveAttempted] = useState<boolean>(false)
  const [edited, setEdited] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  // if skillUse prop updates then so too should the state
  useEffect(() => {
    if (!_isEqual(skillUse, editSkillUse)) {
      ReactDOM.unstable_batchedUpdates(() => {
        setEditSkillUse(skillUse)
        setEdited(false)
        setSaveAttempted(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillUse])

  if (!skillUse || !editSkillUse) return null

  const skill = _find(state.skills, { id: editSkillUse.skillId })
  const validationErrors = validate(editSkillUse)
  const allowSave = isOpen && validationErrors.length === 0
  // const empty = isEmpty(editSkillUse)

  const handleUpdate = (data: Partial<SkillUse>) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setEditSkillUse({ ...editSkillUse, ...data })
      setEdited(true)
    })
  }

  const handleSave = () => {
    if (allowSave) {
      onSave(editSkillUse)
      delayedOnClose()
    } else if (!allowSave && !saveAttempted) {
      setSaveAttempted(true)
    }
  }

  const handleApprove = () => {
    const skillUse: SkillUse = { ...editSkillUse, approved: true }
    onSave(skillUse)
    delayedOnClose()
  }

  const confirmClear = () => {
    if (
      (editSkillUse.text || editSkillUse.scene) &&
      !window.confirm(
        'Are you sure you want to clear this skill use?\nYou should only use this if the cadet has made a mistake.'
      )
    )
      return
    onClear({ ...editSkillUse })
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (allowSave) handleSave()
    return false
  }

  return (
    <MobileModalPanel isOpen={isOpen} onRequestClose={delayedOnClose}>
      {skill && (
        <>
          <Padding size="s" style={{ paddingBottom: 0 }}>
            <Row>
              <Column flex="1 1 auto">
                <H2 style={{ lineHeight: 0.95 }}>Edit or Confirm</H2>
                <P style={{ margin: 0, marginTop: 5, whiteSpace: 'pre-wrap' }}>{skill.text}</P>
              </Column>
              <Spacer size="s" />
              <Column>
                <Sticker
                  symbol={skill.symbol || '?'}
                  text={skill.text}
                  size={42}
                  active
                  approved={skillUse.bonus || (skillUse.approved === undefined ? true : skillUse.approved)}
                  hideExtraSticker={skillUse.approved === undefined}
                  bonus={editSkillUse.bonus}
                />
              </Column>
              <Spacer size="s" />
            </Row>
          </Padding>
          <Hr margin="s" />
        </>
      )}
      <form onSubmit={handleFormSubmit}>
        {!editSkillUse.bonus && (
          <Row>
            <Padding size="s" style={{ paddingTop: 0, paddingBottom: 0 }}>
              <P style={{ margin: 0 }}>
                Skill logged {editSkillUse.time} on {editSkillUse.date}
              </P>
            </Padding>
          </Row>
        )}
        <Row flexWrap>
          <Column flex="1 1 auto">
            <Padding size="s" style={{ paddingBottom: 0 }}>
              <H3 style={{ marginBottom: 5 }}>Where was the skill practised?</H3>
              <Row>
                <InlineRadioGroup
                  name="location"
                  size="s"
                  spacerSize="s"
                  options={locations}
                  value={editSkillUse.location}
                  onChange={location => handleUpdate({ location: location as SkillUse['location'] })}
                />
              </Row>
            </Padding>
          </Column>
        </Row>
        <Spacer size="s" />
        <Row paddingLeft="m" paddingRight="m" flexWrap>
          <Column flex="1 0 auto">
            <AutosizeTextareaInput
              ref={textareaInputRef}
              placeholder={`Describe how this skill was used...`}
              value={editSkillUse.text || ''}
              onChange={(e: any) => handleUpdate({ text: e.target.value || '' })}
              style={{ minHeight: editSkillUse.scene ? '100%' : 80, minWidth: 260 }}
              {...getInputProps()}
            />
          </Column>
          <Spacer size="xs" />
          <ScenePreview
            storageKey={`skillTracker_${skillUse.id}`}
            value={editSkillUse.scene ? editSkillUse.scene : undefined}
            onChange={scene => handleUpdate({ scene })}
            flex="1 0 auto"
            buttonSize="s"
            disableEditing={editSkillUse.approved}
            style={{
              minWidth: editSkillUse.scene ? 260 : undefined,
              minHeight: editSkillUse.scene ? 260 / BACKGROUND_RATIO : 80,
            }}
          />
        </Row>
        <Row alignItems="flex-end">
          <Padding size="s">
            {skillUse.active && !skillUse.bonus && (
              <Button type="button" size="xs" onClick={confirmClear} theme="orange">
                Clear
              </Button>
            )}
            <Spacer flex="1 1 auto" />
            <Button type="button" size="s" onClick={delayedOnClose} theme="white">
              Close
            </Button>
            <Spacer size="xs" />
            {/*
            <Button
              type="button"
              size="s"
              onClick={handleSave}
              theme="blue"
              disabled={!edited || (saveAttempted && !allowSave)}>
              {edited || empty ? 'Save' : 'Saved'}
            </Button>
            <Spacer size="xs" />
            */}
            <Button
              type="submit"
              size="s"
              theme="blue"
              onClick={e => {
                e.preventDefault()
                handleApprove()
              }}
              disabled={!allowSave || editSkillUse.approved}>
              {editSkillUse.approved ? 'Confirmed' : edited ? 'Save & Confirm' : 'Confirm'}
            </Button>
          </Padding>
        </Row>
        {saveAttempted && !allowSave && (
          <Row justifyContent="flex-end">
            <Padding size="s" style={{ display: 'block' }}>
              <ValidationErrorList
                errors={validationErrors}
                style={{
                  float: 'right',
                  color: 'inherit',
                  border: '1px solid orange',
                  padding: 10,
                }}
              />
            </Padding>
          </Row>
        )}
      </form>
    </MobileModalPanel>
  )
}
