import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Panel } from 'common/ui'
import { HeaderTemplate } from '../common/HeaderTemplate'

import { useGadgetPackState } from '../GadgetPackState'
import { CodeCardViewInner } from 'e-telligence/CodeCardView'

export const CodeCardView: React.FC<{ baseUrl: string; isAdding?: boolean; hideActionButton?: boolean }> = ({
  baseUrl,
  isAdding,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useGadgetPackState()
  return (
    <HeaderTemplate
      title="Code Cards"
      exitLabel="Back"
      onExit={() => history.push(baseUrl + (isAdding ? '/code-cards' : ''), location.state)}>
      <Panel style={{ backgroundColor: '#2c2c2c', borderBottomColor: '#2c2c2c' }}>
        <CodeCardViewInner
          hideActionButton
          hideBackButton
          isAdding={isAdding}
          state={state}
          dispatch={dispatch}
          baseUrl={baseUrl}
        />
      </Panel>
    </HeaderTemplate>
  )
}
