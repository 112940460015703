import {
  ScavengerHuntMoveStep as MoveStep,
  ScavengerHuntAttempt as Attempt,
  ScavengerHuntCard as Card,
  ActivityScavengerHuntState,
  ScavengerHuntSceneObjectKey,
} from '../../../types'

export const ADD_ATTEMPT = 'ADD_ATTEMPT'
export const NEXT_CLUE = 'NEXT_CLUE'
export const PLACE_CARD = 'PLACE_CARD'
export const UPDATE_MOVE_STEP = 'UPDATE_MOVE_STEP'
export const NEXT_SCREEN = 'NEXT_SCREEN'
export const UPDATE_FEEDBACK_TEXT = 'UPDATE_FEEDBACK_TEXT'
export const CORRECT_CARD_SELECTED = 'CORRECT_CARD_SELECTED'
export const UPDATE_FLIPPED_CARD = 'UPDATE_FLIPPED_CARD'
export const SHOW_RESULT_MODAL = 'SHOW_RESULT_MODAL'
export const HIDE_RESULT_MODAL = 'HIDE_RESULT_MODAL'
export const UPDATE_ACTIVE_PARTICIPANT = 'UPDATE_ACTIVE_PARTICIPANT'
export const UPDATE_SCENE_OBJECT_HOVER = 'UPDATE_SCENE_OBJECT_HOVER'

export interface BaseAction {}

export interface AddAttemptAction extends BaseAction, Attempt {
  type: typeof ADD_ATTEMPT
}

export interface NextClueAction extends BaseAction {
  type: typeof NEXT_CLUE
}

export interface PlaceCardAction extends BaseAction {
  type: typeof PLACE_CARD
  index: number
  cardId: Card['id']
}

export interface UpdateMoveStepAction extends BaseAction {
  type: typeof UPDATE_MOVE_STEP
  moveStep: MoveStep
}

export interface UpdateScreenStep extends BaseAction {
  type: typeof NEXT_SCREEN
}

export interface UpdateFeedbackText extends BaseAction {
  type: typeof UPDATE_FEEDBACK_TEXT
  feedbackText: ActivityScavengerHuntState['feedbackText']
}

export interface CorrectCardSelected extends BaseAction {
  type: typeof CORRECT_CARD_SELECTED
  correctCard: Card
}

export interface UpdateFlippedCard extends BaseAction {
  type: typeof UPDATE_FLIPPED_CARD
  flippedCard: Card | null
}

export interface ShowResultModal extends BaseAction {
  type: typeof SHOW_RESULT_MODAL
  correct: boolean
}

export interface HideResultModal extends BaseAction {
  type: typeof HIDE_RESULT_MODAL
  correct: boolean
}

export interface UpdateActiveParticipant extends BaseAction {
  type: typeof UPDATE_ACTIVE_PARTICIPANT
  activeProfileUid: ActivityScavengerHuntState['activeProfileUid'] | null
}

export interface HoveredSceneObject extends BaseAction {
  type: typeof UPDATE_SCENE_OBJECT_HOVER
  sceneObject: ScavengerHuntSceneObjectKey | null
}

export type ActionTypes =
  | AddAttemptAction
  | NextClueAction
  | PlaceCardAction
  | UpdateMoveStepAction
  | UpdateScreenStep
  | UpdateFeedbackText
  | CorrectCardSelected
  | UpdateFlippedCard
  | ShowResultModal
  | HideResultModal
  | UpdateActiveParticipant
  | HoveredSceneObject
