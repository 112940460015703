import React, { useCallback, useState } from 'react'

import { EmbeddedActivitySection, SectionPanel } from 'shared/session/types'
import { THOUGHTS } from 'shared/session/sections/custom/helpful-thoughts/thoughts'
import { DISPATCH_SECTION_STATE } from 'shared/session/actionTypes'

import { Button, Column, Label, P, Row, Spacer } from 'common/ui'
import { TextareaInputField } from 'common/FieldInputs'
import { SVG } from 'common/SVG'
import { Switch } from 'common/Switch'
import { ConfirmModal } from 'common/ConfirmModal'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'

import { getInitialProfileState } from 'shared/session/sections/custom/helpful-thoughts/reducer'
import { useSessionState } from 'session/SessionState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { ActionTypes as HelpfulThoughtsActionTypes } from 'shared/session/sections/custom/helpful-thoughts/actionTypes'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'
import { InputLockComponent } from 'session/common/InputLockComponent'

interface FacilitatorProps {
  section: EmbeddedActivitySection
  panel: SectionPanel
  index: number
}

export const HelpfulThoughtsFacilitator: React.FC<FacilitatorProps> = ({ section, panel, index }) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback('activity_helpful_thoughts', section, () => ({
    groupPlay: false,
    participants: {},
  }))
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const dispatchSectionState = useCallback(
    (action: HelpfulThoughtsActionTypes) =>
      dispatch({
        ...getBaseAction(),
        type: DISPATCH_SECTION_STATE,
        property: 'activity_helpful_thoughts',
        section_id: section.id,
        action,
      }),
    [dispatch, getBaseAction, section.id]
  )

  const resetState = (confirmed: boolean) => {
    setShowConfirm(false)
    if (confirmed) dispatchSectionState({ type: 'RESET_STATE' })
  }

  return (
    <>
      <ActivityInteractionIndicator type={sectionState.groupPlay ? 'group' : 'individual'} />
      <FacilitatorControls title="Helpful Thoughts">
        <Spacer />
        <Row justifyContent="space-between" alignItems="center">
          <Row style={{ alignItems: 'center' }}>
            <Label>Play as group:</Label>
            <Spacer />
            <Switch
              value={sectionState.groupPlay}
              onChange={() => dispatchSectionState({ type: 'TOGGLE_GROUP_PLAY' })}
            />
          </Row>
          <Button theme="purple" children="Reset" size="s" onClick={() => setShowConfirm(true)} />
        </Row>
        <Spacer />
        <FacilitatorControlsTable>
          <thead>
            <tr>
              <td />
              {state.participants.map((participant, i) => {
                return (
                  <th key={i}>
                    {participant.profile.displayName}{' '}
                    {sectionState &&
                    sectionState.participants[participant.profile.uid] &&
                    sectionState.participants[participant.profile.uid].screen === 'FINISHED'
                      ? '[Finished]'
                      : ''}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {THOUGHTS.map(({ text, answer }, thoughtRowIndex) => (
              <tr key={thoughtRowIndex}>
                <td style={{ textAlign: 'left', padding: 10, maxWidth: 200 }}>
                  <P style={{ margin: 0 }}>
                    <strong>UNHELPFUL:</strong> {text}
                  </P>
                  <P style={{ margin: 0, marginTop: 5 }}>
                    <strong>HELPFUL:</strong> {answer}
                  </P>
                  <Button
                    size="xxs"
                    children="select all ->"
                    onClick={() => dispatchSectionState({ type: 'SELECT_ALL', rowIndex: thoughtRowIndex })}
                  />
                </td>
                {state.participants.map((participant, i) => {
                  const participantSectionState =
                    sectionState.participants[participant.profile.uid] || getInitialProfileState(THOUGHTS)
                  const active = text === participantSectionState.currentCloud.text
                  const { clouds } = participantSectionState
                  const correctSelected =
                    clouds[thoughtRowIndex].answers.length > 0 &&
                    clouds[thoughtRowIndex].answers.find(val => val === true)

                  return (
                    <td key={i} style={{ position: 'relative', backgroundColor: active ? '#ffffaa' : 'transparent' }}>
                      {!active && (
                        <div
                          title="Focus Thought"
                          style={{ position: 'absolute', right: 10, top: 10, zIndex: 1, cursor: 'pointer' }}
                          onClick={() =>
                            dispatchSectionState({
                              type: 'FOCUS_THOUGHT',
                              cloudIndex: thoughtRowIndex,
                              participantUid: participant.profile.uid,
                            })
                          }>
                          <SVG height={24} viewboxSize={24} width={24}>
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              fill="#c3c3c3"
                              d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                            />
                          </SVG>
                        </div>
                      )}
                      <Column padding={10} flexWrap alignItems="center">
                        {correctSelected && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="#00aa00"
                            width="30"
                            height="30">
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                        {clouds[thoughtRowIndex].custom !== null && (
                          <>
                            <P>Custom Thought:</P>
                            <div style={{ position: 'relative' }}>
                              <DebouncedInputComponent<string>
                                onChange={(value: string) => {
                                  dispatchSectionState({
                                    type: 'SET_CUSTOM_OPTION',
                                    customOption: value,
                                    participantUid: participant.profile.uid,
                                  })
                                }}
                                value={clouds[thoughtRowIndex].custom || ''}>
                                {props => (
                                  <InputLockComponent
                                    fieldUid={`${participant.profile.uid}_helpful_thoughts_${clouds[thoughtRowIndex].index}`}
                                    hideToggle={!!clouds[thoughtRowIndex].approved}
                                    verticalPlacementFacilitator={5}>
                                    {(lockProps, lockState) => (
                                      <TextareaInputField
                                        {...props}
                                        {...lockProps}
                                        {...lockState}
                                        placeholder={'Custom Thought'}
                                        onChange={(val: string) => {
                                          props.onChange(val || '')
                                          lockProps.onChange(val || '')
                                        }}
                                      />
                                    )}
                                  </InputLockComponent>
                                )}
                              </DebouncedInputComponent>
                            </div>
                            {!clouds[thoughtRowIndex].approved && !correctSelected && (
                              <>
                                <Spacer />
                                <Button
                                  onClick={() =>
                                    dispatchSectionState({
                                      type: 'APPROVE_CUSTOM_THOUGHT',
                                      cloudIndex: thoughtRowIndex,
                                      participantUid: participant.profile.uid,
                                    })
                                  }
                                  size="xs"
                                  children="Approve"
                                />
                              </>
                            )}
                          </>
                        )}
                      </Column>
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </FacilitatorControlsTable>
        <ConfirmModal
          isOpen={showConfirm}
          onSubmit={resetState}
          label="Are you sure? This will reset all cadets to the beginning of the activity"
        />
      </FacilitatorControls>
    </>
  )
}
