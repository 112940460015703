import React from 'react'

import { P, CollapseMargin, Center, Spacer, Row, Hr } from 'common/ui'
import { IntroTemplate } from 'home/IntroTemplate'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import styled from 'styled-components'

export const GameInfoPage: React.FC = () => {
  return (
    <IntroTemplate title={`Information &\u00A0Support`} plainLogo>
      <CollapseMargin>
        <P>SAS Small Group Program, digital edition</P>
        <ul>
          <LI>
            <a
              href={require('./assets/pdf/SAS_Technical_Requirements_Page_Digital_Edition.pdf')}
              target="_blank"
              rel="noopener noreferrer">
              Technical Requirements
            </a>
          </LI>
          <LI>
            <a
              href={require('./assets/pdf/SAS_Guide_for_Consenting_Adult_2024.pdf')}
              target="_blank"
              rel="noopener noreferrer">
              Consenting Adult Guide
            </a>
          </LI>
          <LI>
            <a href="/info-consentingadult" target="_blank" rel="noopener noreferrer">
              Mentor Enrolment Guide
            </a>
          </LI>
        </ul>
      </CollapseMargin>
      <Hr margin />
      <CollapseMargin>
        <P>SAS Digital HQ (released 2020)</P>
        <ul>
          <LI>
            <a
              href={require('./assets/pdf/SAS-SystemRequirements-2020-V2.pdf')}
              target="_blank"
              rel="noopener noreferrer">
              SAS Digital HQ Technical Requirements
            </a>
          </LI>
          <LI>
            <a
              href={require('./assets/pdf/SAS-GameInstructionManual-2020-Web.pdf')}
              target="_blank"
              rel="noopener noreferrer">
              SAS Digital HQ Instruction Manual
            </a>
          </LI>
          <LI>
            <a href={require('./assets/pdf/SAS-GamePackGuide-2020-Web.pdf')} target="_blank" rel="noopener noreferrer">
              SAS Computer Game Pack Guide
            </a>
          </LI>
        </ul>
        <Row>
          <AnchorButton href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`} flex="1 1 0px" size="s">
            Request Support
          </AnchorButton>
          <Spacer />
          <AnchorButton href="http://www.secretagentsociety.com" flex="1 1 0px" size="s">
            What is SAS?
          </AnchorButton>
        </Row>
      </CollapseMargin>
      <Hr margin />
      <Center>
        <LinkButton to="/">Login</LinkButton>
      </Center>
    </IntroTemplate>
  )
}

const LI = styled.li`
  margin: 0.25rem 0;
`
