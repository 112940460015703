import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import { SessionEntity } from 'shared/dashboard/types'
import { getSessionStartDate } from './sessionUtils'
import { sortByKey } from 'utils/sortUtils'

export const sessionsFilterUnfinalized = (sessions: SessionEntity[]) =>
  sessions
    .filter(session => session.concluded && !session.catch_up_active && !session.finalized)
    .sort(sortByKey<SessionEntity>('start_datetime', 'ascending'))

export const sessionsFilterFuture = (sessions: SessionEntity[]) =>
  sessions
    .filter(session => !session.concluded && !session.catch_up_active && !session.finalized)
    .sort(sortByKey<SessionEntity>('start_datetime', 'ascending'))

export const sessionsFilterCatchUp = (sessions: SessionEntity[]) =>
  sessions
    .filter(session => session.catch_up_active && !session.finalized)
    .sort(sortByKey<SessionEntity>('start_datetime', 'ascending'))

export const sessionsFilterPast = (sessions: SessionEntity[]) =>
  sessions
    .filter(session => {
      const startDate = getSessionStartDate(session)
      return (startDate ? isBefore(startDate, new Date()) : false) && session.finalized
    })
    .sort(sortByKey<SessionEntity>('start_datetime', 'descending'))

export const sessionsFilterCurrentUnfinalized = (sessions: SessionEntity[]) =>
  sessions
    .filter(session => {
      const parsedDate = getSessionStartDate(session)
      const hasStarted = parsedDate ? isAfter(new Date(), parsedDate) : false
      return hasStarted && !session.finalized
    })
    .sort(sortByKey<SessionEntity>('start_datetime', 'ascending'))

export const sessionsFilterType = (sessions: SessionEntity[], type: SessionEntity['type']) =>
  sessions.filter(session => session.type === type)

export const processErrorList = (errors: (string | null)[]): string[] =>
  errors.filter(msg => !!msg && !msg.match(/^\{/)).map(msg => msg?.replace(/(: )?\{[\s]+".*/gms, '')) as string[]
