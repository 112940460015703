import { useUserState } from 'app/UserState'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory, useParams } from 'react-router-dom'
import { drupalLicenceLogin, getProfiles } from 'api'

interface Props {
  redirectUri: string
}

interface UrlParams {
  licenceUuid: string
}

export const MentorDrupalAuth: React.FC<Props> = ({ redirectUri }) => {
  const {
    accessToken,
    setAuthProvider,
    setAccessToken,
    setRefreshToken,
    setProfiles,
    setProfileId,
    setGameMode,
  } = useUserState()
  const { licenceUuid } = useParams<UrlParams>()
  const history = useHistory()
  const [error, setError] = useState('')

  useEffect(() => {
    drupalLicenceLogin(licenceUuid, accessToken)
      .then(data => {
        return getProfiles(data.access_token).then(profiles => {
          // prefer non-open_access, but if they only have open_access profile, then use that
          const profile = profiles.find(
            p => (p.profile_name || '').toLowerCase() !== 'open_access' || profiles.length === 1
          )
          if (!profile) throw new Error('No profile found')

          if (profile.intervention_type !== 'SAS-SG' && profile.intervention_type !== 'SAS-SG-D') {
            throw new Error('Skill Tracker is only available for small groups.')
          }

          //   const payload = decodeJWT(data.access_token)
          //   gtagLogin(payload.scopes.join(' '), profile.allowed_from, profile.intervention_type)

          ReactDOM.unstable_batchedUpdates(() => {
            setAuthProvider('sas')
            // setOfflineMode(false)
            // setOfflineMentor(false)
            setProfiles(profiles)
            setAccessToken(data.access_token)
            setRefreshToken(data.refresh_token)
            setProfileId(profile.id)
            setGameMode(profile.intervention_type)
          })

          history.push(redirectUri)
        })
      })
      .catch(err => {
        // if (!isOnline()) {
        //   const hashedCodeProfilesJSON = localStorage.getItem(hashedGameCode)
        //   if (hashedCodeProfilesJSON) {
        //     try {
        //       const hashedCodeProfiles: GetProfile[] = JSON.parse(hashedCodeProfilesJSON)
        //       onOfflineLogin(hashedCodeProfiles)
        //     } catch (e) {
        //       ReactDOM.unstable_batchedUpdates(() => {
        //         setError('An error occurred while trying to enter offline mode')
        //         setBusy(false)
        //       })
        //       return
        //     }
        //     return
        //   }
        // }
        ReactDOM.unstable_batchedUpdates(() => {
          setError(err.message)
        })
      })
  }, [
    accessToken,
    licenceUuid,
    history,
    redirectUri,
    setAccessToken,
    setAuthProvider,
    setGameMode,
    setProfileId,
    setProfiles,
    setRefreshToken,
  ])
  return <div>{error}</div>
}
