import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { useParams } from 'react-router-dom'
import { orange } from 'themes'

import { Tipsheet as TipsheetType } from 'shared/tipsheets/types'
import { UserInputStateProvider } from 'session/UserInputState'

import { NavRouteProps } from 'dashboards/types'
import { RouteNotFound } from 'dashboards/common/RouteNotFound'
import { Tipsheet, TipsheetWrapper } from './Tipsheet'
import { SpinnerWithLabel } from 'common/Spinner'

import { useGenericUser } from 'app/useGenericUser'
import { useUserState } from 'app/UserState'
import { getTipsheetByCode } from 'api'
import { MentorDashStateProvider } from 'dashboards/mentor/MentorDashboardState'

interface UrlParams {
  moduleCode: string
}

export const TipsheetFacilitator: React.FC<NavRouteProps> = props => (
  <ThemeProvider theme={orange}>
    <UserInputStateProvider>
      <TipsheetFacilitatorInner {...props} />
    </UserInputStateProvider>
  </ThemeProvider>
)

const TipsheetFacilitatorInner: React.FC<NavRouteProps> = () => {
  const { accessToken } = useUserState()
  const { moduleCode } = useParams<UrlParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [tipsheetModule, setTipsheetModule] = useState<TipsheetType | null>(null)

  const facilitator = useGenericUser()

  useEffect(() => {
    getTipsheetByCode(moduleCode, accessToken)
      .then(res => {
        ReactDOM.unstable_batchedUpdates(() => {
          setTipsheetModule(res)
          setLoading(false)
        })
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {tipsheetModule ? (
        <MentorDashStateProvider>
          <Tipsheet
            viewOnly
            tipsheetModule={tipsheetModule}
            facilitator={facilitator}
            onConfirm={() => {}}
            confirmRead={false}
            printButtonProps={{ context: 'facilitator', moduleCode: tipsheetModule.module_code }}
          />
        </MentorDashStateProvider>
      ) : (
        <TipsheetWrapper style={{ height: '100%' }}>
          {loading ? (
            <SpinnerWithLabel color="#925BED" label="One moment please..." labelStyle={{ color: 'white' }} />
          ) : (
            <RouteNotFound />
          )}
        </TipsheetWrapper>
      )}
    </>
  )
}
