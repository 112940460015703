import React, { useState } from 'react'
import format from 'date-fns/format'

import { Module } from 'skill-tracker/types'
import { Padding, H3, Spacer, Button, TextInput, Row } from 'common/ui'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'

import { getInputProps } from 'utils/virtualKeyboard'

export const validate = (data: Partial<Module>, errors: string[] = []) => {
  if (!data.title) errors.push(`No title entered`)
  return errors
}

export const CustomModuleEditModal: React.FC<{
  initialValue?: Module
  onSave: (module: Module) => void
  onClose: () => void
}> = ({ initialValue, onSave, onClose }) => {
  const [module, setModule] = useState<Partial<Module>>(() => ({
    ...(initialValue || {}),
    type: 'custom',
    presetModuleId: undefined,
    startDate: initialValue?.startDate ? initialValue.startDate : format(Date.now(), 'y-MM-dd'),
    title: initialValue?.title ? initialValue.title : '',
  }))

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  const validationErrors = validate(module)
  const allowSave = validationErrors.length === 0

  const handleSave = () => {
    onSave(module as Module)
    delayedOnClose()
  }
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (allowSave) handleSave()
    return false
  }

  return (
    <MobileModalPanel
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelStyle={{ minWidth: 532, maxWidth: 'calc(100vw - 20px)' }}>
      <Padding size="m">
        <form onSubmit={handleFormSubmit}>
          <H3>Create a custom module:</H3>
          <Spacer size="s" />
          <TextInput
            type="text"
            autoFocus
            placeholder={`Custom module title...`}
            value={module.title}
            onChange={e => setModule({ ...module, title: e.target.value || '' })}
            {...getInputProps()}
          />
          <Spacer size="m" />
          <Row justifyContent="flex-end">
            <Button type="button" size="s" theme="white" onClick={delayedOnClose}>
              Cancel
            </Button>
            <Spacer size="s" />
            <Button type="submit" size="s" theme="blue" disabled={!allowSave}>
              Save
            </Button>
          </Row>
        </form>
      </Padding>
    </MobileModalPanel>
  )
}
