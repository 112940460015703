import { Thought } from '../../../types'

export const THOUGHTS: Thought[] = [
  {
    text: 'I’M GOING TO\nMESS THIS UP!',
    answer: 'It’s OK if I make mistakes. I’ll get better with practice.',
  },
  {
    text: 'I DON’T KNOW\nWHAT TO DO!',
    answer: 'I’ll try my best and ask for help if I get stuck.',
  },
  {
    text: 'I’VE GOT\nNO FRIENDS.',
    answer: 'I can make new friends if I stay calm and be kind.',
  },
  {
    text: 'I WANT TO\nHIT THEM!',
    answer: 'Fighting won’t help. I need to stay calm and outsmart them.',
  },
  {
    text: 'I’M DUMB.',
    answer: 'I’m good at some things, like maths and computer games.',
  },
  {
    text: 'THEY’RE SO\nMEAN TO ME!',
    answer: 'I’m not going to let their teasing get to me.',
  },
  {
    text: 'I’M GOING\nTO LOSE!',
    answer: 'It doesn’t matter if I lose. Having fun is most important.',
  },
  {
    text: 'I HATE MY TEACHER!',
    answer: 'My teacher is nice to me sometimes.',
  },
  {
    text: 'I’VE HAD ENOUGH!',
    answer: 'It will be over soon. Then I can do something fun!',
  },
  {
    text: 'I JUST WANT\nTO BE LIKE\nEVERYONE ELSE.',
    answer: 'We’re all different. No-one is good at everything.',
  },
  {
    text: 'I CAN’T\nCONTROL MYSELF!',
    answer: 'I can choose to stay calm and in control.',
  },
  {
    text: 'IT’S NOT FAIR.\nIT’S NOT MY FAULT!',
    answer: 'It isn’t my fault but I still need to find a smart solution.',
  },
]
