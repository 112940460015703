import type { FacilitatorState, ParticipantState } from 'shared/session/types'

export const getSlideUid = (
  facilitatorState: FacilitatorState | false,
  participantState: ParticipantState | false,
  presentationMode: boolean = false
): string | null => {
  if (facilitatorState) {
    if (presentationMode) return facilitatorState.currentSlideUid
    else return facilitatorState.currentPreviewSlideUid || facilitatorState.currentSlideUid
  } else if (participantState) return participantState.currentSlideUid
  return null
}
