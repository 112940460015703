import { retry, retryFetch } from './apiUtils'
import { getVideoPool, PreloadStrategy } from './VideoPool'

export function preloadImage(src: string) {
  return retry(() => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = resolve
      img.onerror = reject
    })
  })
}

export function preloadAudio(src: string) {
  return retryFetch(src)
}

export function preloadVideo(str: string, preloadStrategy: PreloadStrategy = PreloadStrategy.Fetch) {
  getVideoPool().reserveVideo(str, preloadStrategy)
}
