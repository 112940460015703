import React, { CSSProperties, useEffect, useState } from 'react'
import { MobileMessageModal, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { IconOutlineButton, Button } from 'common/ui'

interface Props {
  isOpen: boolean
  value: number | null
  onChange: (value: number) => void
  onClose: () => void
  numbers: number[]
  panelStyle?: CSSProperties
}

export const NumberSelectModal: React.FC<Props> = ({
  isOpen: _isOpen,
  value,
  onChange,
  onClose,
  numbers,
  panelStyle = {},
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(_isOpen)
  useEffect(() => setIsOpen(_isOpen), [_isOpen])
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }
  return (
    <MobileMessageModal
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelStyle={{ minWidth: 300, ...panelStyle }}
      panelName="NumberSelectModal">
      {numbers.map(i => (
        <IconOutlineButton
          key={i}
          margin={2}
          style={{ width: 40, height: 42, fontSize: 28, fontFamily: 'sans-serif', cursor: 'pointer' }}
          theme="blue"
          filled={i === value}
          onClick={() => onChange(i)}
          onDoubleClick={() => {
            onChange(i)
            delayedOnClose()
          }}>
          {i}
        </IconOutlineButton>
      ))}
      <br />
      <Button size="s" marginTop={15} onClick={delayedOnClose} theme="white">
        Close
      </Button>
    </MobileMessageModal>
  )
}
