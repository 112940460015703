/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, PropsWithChildren, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import _uniq from 'lodash/uniq'
import _filter from 'lodash/filter'

import {
  EmotionometerType,
  EmotionometerDevice,
  Emotionometer,
  EmotionometerSticker,
  EmotionometerRange,
  EmotionometerStickerBase,
  RouteProps,
} from 'shared/e-telligence/types'
import {
  ADD_UPDATE_EMOTIONOMETER,
  ADD_EMOTIONOMETER_STICKER,
  REMOVE_EMOTIONOMETER_STICKER,
  AddUpdateCustomStickerAction,
  ADD_UPDATE_CUSTOM_STICKER,
} from 'shared/e-telligence/actionTypes'
import {
  emotionLabels,
  emotionAdjectives,
  emotionRangeDistribution,
  emotionRangeValues,
  emotionometerDevicePluralLabels,
  emotionRangeOptions,
} from './constants/typeValueMaps'

import { Column, Row, OutlineButton, Button } from 'common/ui'
import { ETellPanel } from './common/ETellPanel'
import { Navigation, baseUrl } from './common/Navigation'
import { EmotionometerBar } from './common/EmotionometerBar'
import { StickerTileButton } from './common/StickerTileButton'
import { StickerSelectModal, Props as StickerSelectModalProps } from './common/StickerSelectModal'
import { StickerViewModal } from './common/StickerViewModal'

import { useUserState } from 'app/UserState'
import { useETelligenceState } from './ETelligenceState'
import { useMedia } from 'utils/useMedia'

const emotions: EmotionometerType[] = ['anxiety', 'anger']

export interface RouteParams {
  emotion: EmotionometerType
}

interface Props {
  editMode?: boolean
}

export const EmotionometerView: React.FC<Props> = ({ editMode }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useETelligenceState()
  const { usingIOSvhFix } = useUserState()
  const portrait = useMedia('(orientation: portrait)')

  const { emotion } = useParams<RouteParams>() as RouteParams
  const emotionometer = state.emotionometers.find(({ type }) => type === emotion)

  return (
    <DarkPanel
      style={{ height: 'auto', minHeight: portrait ? (usingIOSvhFix ? '100%' : 'calc(100vh - 20px)') : undefined }}>
      <Navigation
        title={`${editMode ? 'Edit ' : ''}${emotionometer?.title ? emotionometer.title + ' ' : ''}Emotionometer`}
        titleSize={portrait ? 12 : undefined}
        Buttons={
          <Row>
            <Button
              size="s"
              onClick={() =>
                history.push(`${baseUrl}/emotionometer/${emotion}${!editMode ? '/edit' : ''}`, location.state)
              }
              children="Edit"
              pressed={editMode}
              marginRight={10}
            />
            <Button size="s" onClick={() => history.push(baseUrl + '/home', location.state)} children="Back" />
          </Row>
        }
      />
      <EmotionometerViewInner baseUrl={baseUrl} state={state} dispatch={dispatch} editMode={editMode} />
    </DarkPanel>
  )
}

export const EmotionometerViewInner: React.FC<RouteProps & Props> = ({
  baseUrl,
  state,
  dispatch,
  editMode = false,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { emotion } = useParams<RouteParams>() as RouteParams

  const { getBaseAction } = useUserState()
  const portrait = useMedia('(orientation: portrait)')
  const [viewSticker, setViewSticker] = useState<EmotionometerStickerBase | undefined>()
  const [selectStickerProps, setSelectStickerProps] = useState<
    Pick<StickerSelectModalProps, 'emotion' | 'emotionRange' | 'device' | 'index'> | undefined
  >()

  const createdMissing = useRef(false)

  const emotionAdjective = emotionAdjectives[emotion]
  const emotionometer = state.emotionometers.find(({ type }) => type === emotion)

  useEffect(() => {
    if (emotionometer && !emotionometer.situations.length)
      history.replace(`${baseUrl}/emotionometer/${emotion}/situations/8-10`, {
        ...(location.state || {}),
        returnToCardHolder: true,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!emotionometer && emotion && emotions.indexOf(emotion) >= 0 && !createdMissing.current) {
    createdMissing.current = true
    setTimeout(() =>
      dispatch({
        ...getBaseAction(),
        type: ADD_UPDATE_EMOTIONOMETER,
        emotionometer: {
          id: emotion,
          type: emotion,
          title: emotionLabels[emotion],
          situations: [],
          bodyClues: [],
          relaxationGadgets: [],
        },
      })
    )
    setTimeout(() =>
      history.replace(`${baseUrl}/emotionometer/${emotion}/situations/8-10`, {
        ...(location.state || {}),
        returnToCardHolder: true,
      })
    )
    return null
  }

  if (!emotionometer) return null

  const handleStickerSelect = (sticker: EmotionometerStickerBase) => {
    setViewSticker(sticker)
  }

  const handleEmptyStickerSelect = (device: EmotionometerDevice, emotionRange: EmotionometerRange, index: number) => {
    setSelectStickerProps({ emotion, emotionRange, device, index })
  }

  const handleAddSticker = (sticker: EmotionometerStickerBase) => {
    if (selectStickerProps)
      dispatch({
        ...getBaseAction(),
        type: ADD_EMOTIONOMETER_STICKER,
        emotionometerType: selectStickerProps.emotion,
        emotionometerDevice: selectStickerProps.device,
        emotionometerRange: selectStickerProps.emotionRange,
        sticker: { ...sticker, emotionRange: selectStickerProps.emotionRange },
      })

    setSelectStickerProps(undefined)
  }

  const handleRemoveSticker = (sticker: EmotionometerStickerBase) => {
    if (!!sticker.emotionRange)
      dispatch({
        ...getBaseAction(),
        type: REMOVE_EMOTIONOMETER_STICKER,
        emotionometerType: emotion,
        emotionometerDevice: sticker.type,
        emotionometerRange: sticker.emotionRange,
        stickerId: sticker.id,
      })
    setViewSticker(undefined)
  }

  const handleUpdateSticker = (data: EmotionometerSticker<EmotionometerDevice>, sticker: EmotionometerStickerBase) => {
    const updatedSticker: EmotionometerSticker<EmotionometerDevice> = {
      ...data,
      id: data.id || 'sticker' + Date.now(),
      type: data.type || sticker.type,
      emotion: emotionometer.type,
      emotionRange: data.emotionRange || sticker.emotionRange,
    }
    dispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_STICKER,
      sticker: updatedSticker,
      emotionometerType: emotionometer.type,
      emotionometerRange: sticker.emotionRange,
    } as AddUpdateCustomStickerAction)
  }

  return (
    <>
      <style>{`#root > div > div > div { overflow: auto; }`}</style>

      <Column flex="1 1 auto" style={{ width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
        <Layout>
          <Row>
            <Column padding={portrait ? 'xs' : 's'} justifyContent="center">
              <span>Relaxation Gadgets</span>
            </Column>
            <Column
              flex="1 1 auto"
              paddingLeft={portrait ? 0 : 's'}
              paddingRight={portrait ? 0 : 's'}
              paddingTop={portrait ? 'xs' : 0}
              paddingBottom={portrait ? 'xs' : 0}>
              <StickerRow
                baseUrl={baseUrl}
                emotionometer={emotionometer}
                type={'relaxationGadgets'}
                portrait={portrait}
                editMode={editMode}
                onSelect={handleStickerSelect}
                onAdd={handleEmptyStickerSelect}
              />
            </Column>
          </Row>
          <Row>
            <Column padding={portrait ? 'xs' : 's'}></Column>
            <Column flex="1 1 auto" padding={portrait ? 'xs' : 's'}>
              <EmotionometerBar
                flex="1 0 auto"
                type={emotion}
                value={[0, 1, 2]}
                distribution={emotionRangeDistribution}
                orientation={portrait ? 'portrait' : 'landscape'}
                labels={[
                  `A little bit ${emotionAdjective}`,
                  `Moderately ${emotionAdjective}`,
                  `Very ${emotionAdjective}`,
                ]}
              />
            </Column>
          </Row>
          <Row>
            <Column padding={portrait ? 'xs' : 's'} justifyContent="center">
              <span>Body Clues</span>
            </Column>
            <Column
              flex="1 1 auto"
              paddingLeft={portrait ? 0 : 's'}
              paddingRight={portrait ? 0 : 's'}
              paddingTop={portrait ? 'xs' : 0}
              paddingBottom={portrait ? 'xs' : 0}>
              <StickerRow
                baseUrl={baseUrl}
                emotionometer={emotionometer}
                type={'bodyClues'}
                portrait={portrait}
                editMode={editMode}
                onSelect={handleStickerSelect}
                onAdd={handleEmptyStickerSelect}
              />
            </Column>
          </Row>
          <Row>
            <Column padding={portrait ? 'xs' : 's'} justifyContent="center">
              <span>Situations</span>
            </Column>
            <Column
              flex="1 1 auto"
              paddingLeft={portrait ? 0 : 's'}
              paddingRight={portrait ? 0 : 's'}
              paddingTop={portrait ? 'xs' : 0}
              paddingBottom={portrait ? 'xs' : 0}>
              <StickerRow
                baseUrl={baseUrl}
                emotionometer={emotionometer}
                type={'situations'}
                portrait={portrait}
                editMode={editMode}
                onSelect={handleStickerSelect}
                onAdd={handleEmptyStickerSelect}
              />
            </Column>
          </Row>
        </Layout>
      </Column>
      {!!selectStickerProps && (
        <StickerSelectModal
          {...selectStickerProps}
          onClose={() => setSelectStickerProps(undefined)}
          onSelect={handleAddSticker}
          // these need to be passed through from parent component because we don't know whether to look at etell or gadget pack
          state={state}
          dispatch={dispatch}
        />
      )}
      {!!viewSticker && (
        <StickerViewModal
          sticker={viewSticker}
          editMode={editMode}
          emotionometerType={emotionometer.type}
          onClose={() => setViewSticker(undefined)}
          onRemove={() => handleRemoveSticker(viewSticker)}
          onUpdateSticker={data => handleUpdateSticker(data, viewSticker)}
        />
      )}
    </>
  )
}

interface StickerRowProps<Type extends EmotionometerDevice> {
  baseUrl: string
  emotionometer: Emotionometer
  type: Type
  portrait?: boolean
  editMode?: boolean
  onSelect: (sticker: EmotionometerSticker<Type>) => void
  onAdd: (type: Type, range: EmotionometerRange, index: number) => void
}

const StickerRow = <DeviceType extends EmotionometerDevice>({
  baseUrl,
  emotionometer,
  type,
  onSelect,
  onAdd,
  portrait = false,
  editMode = false,
}: PropsWithChildren<StickerRowProps<DeviceType>>) => {
  const history = useHistory()
  const location = useLocation()

  const stickers = emotionometer[type] as EmotionometerStickerBase[]
  if (!stickers.length) {
    const prevType: EmotionometerDevice | null =
      type === 'relaxationGadgets' ? 'bodyClues' : type === 'bodyClues' ? 'situations' : null
    const nextType: EmotionometerDevice | null =
      type === 'situations' ? 'bodyClues' : type === 'bodyClues' ? 'relaxationGadgets' : null
    const prevDeviceStickers = !prevType ? [] : emotionometer[prevType] || []
    const nextDeviceStickers = !nextType ? [] : emotionometer[nextType] || []
    const disabled = type !== 'situations' && !prevDeviceStickers.length
    return (
      <StickerRowDiv flex="1 1 auto" justifyContent="center" padding={portrait ? 'xs' : 's'}>
        {editMode && (
          <OutlineButton
            theme="white"
            size={portrait ? 'xs' : 's'}
            disabled={disabled}
            flashing={!disabled && (!nextType || !nextDeviceStickers.length)}
            onClick={() =>
              history.push(baseUrl + '/emotionometer/' + emotionometer.type + '/' + type, {
                ...(location.state || {}),
                returnToCardHolder: false,
              })
            }
            children={`Add ${emotionometerDevicePluralLabels[type]} + `}
          />
        )}
      </StickerRowDiv>
    )
  }

  let availableStickers = [...stickers]
  const renderStickers: (EmotionometerStickerBase | undefined)[] = emotionRangeDistribution.map((rangeIndex, index) => {
    const stickerAtIndex = availableStickers.find(sticker => sticker.index === index)
    if (stickerAtIndex) {
      availableStickers = availableStickers.filter(sticker => sticker !== stickerAtIndex)
      return stickerAtIndex
    }
    const emotionRangeOption = emotionRangeOptions.find(opt => opt.index === rangeIndex)
    const rangeStickers = availableStickers.filter(
      sticker => sticker.emotionRange === emotionRangeOption?.value && sticker.index === undefined
    )
    if (rangeStickers.length > 0) {
      availableStickers = availableStickers.filter(sticker => sticker !== rangeStickers[0])
      return rangeStickers[0]
    }
    return undefined
  })

  return (
    <StickerRowDiv
      flex="1 1 auto"
      justifyContent="space-around"
      alignItems={portrait ? 'center' : undefined}
      marginRight={portrait ? 0 : -2}
      marginBottom={portrait ? -2 : 0}>
      {renderStickers.map((sticker, index) => {
        const startOfNewRange = index > 0 && emotionRangeDistribution[index] !== emotionRangeDistribution[index - 1]
        return (
          <Column
            key={index}
            flex={`1 1 ${100 / emotionRangeDistribution.length}%`}
            style={{
              width: portrait ? '100%' : undefined,
              minHeight: portrait && !sticker ? 50 : undefined,
              [portrait ? 'borderTop' : 'borderLeft']: startOfNewRange ? '1px solid #575758' : 'none',
            }}
            alignItems="center"
            justifyContent="center"
            paddingRight={portrait ? 0 : 3}
            paddingBottom={portrait ? 3 : 's'}
            paddingTop={portrait ? (startOfNewRange ? 3 : 0) : 's'}
            paddingLeft={!portrait && startOfNewRange ? 3 : 0}>
            {sticker ? (
              <div
                style={{
                  width: '100%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: portrait ? 48 : 96,
                }}>
                <StickerTileButton<typeof type>
                  sticker={sticker as EmotionometerSticker<typeof type>}
                  onClick={() => onSelect(sticker as EmotionometerSticker<typeof type>)}
                  maxSize={portrait ? 36 : 64}
                />
              </div>
            ) : editMode ? (
              <OutlineButton
                size="xs"
                theme="white"
                children="+"
                style={{ width: 30, height: 30 }}
                onClick={() => onAdd(type, emotionRangeValues[emotionRangeDistribution[index]], index)}
              />
            ) : null}
          </Column>
        )
      })}
    </StickerRowDiv>
  )
}

const DarkPanel = styled(ETellPanel)`
  background-color: #222222;
  border-bottom-color: #000000;
  color: white;
  & h1,
  & h2,
  & h3,
  & h4 {
    color: white;
  }
  & > *:first-child {
    border-bottom-color: #575758 !important;
  }
`

const Layout = styled(Column)`
  overflow-x: auto;
  & > * {
    border-bottom: 1px solid #575758;
  }
  & > * > *:first-child {
    border-right: 1px solid #575758;
    width: 100px;
  }
  @media (orientation: portrait) {
    flex-direction: row-reverse;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    > * {
      flex-direction: column;
      flex: 1 1 25%;
      height: auto;
      border-bottom: none;
    }
    & > * > *:not(:first-child) {
      border-right: 1px solid #575758;
    }
    & > * > *:first-child {
      border-bottom: 1px solid #575758;
      width: auto;
      height: 40px;
      align-items: center;
      font-size: 12px;
    }
  }
`

const StickerRowDiv = styled(Row)`
  @media (orientation: portrait) {
    flex-direction: column;
  }
`
