/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { fontRegular } from 'fonts'

export const PopoverMessage: React.FC<{ text?: string | null; waitMs?: number }> = ({ text = null, waitMs = 5000 }) => {
  const [dismissed, setDismissed] = useState(true)
  const prevTextValue = useRef<typeof text>(text)
  const prevText = useRef<string>(typeof text === 'string' ? text : '')
  useEffect(() => {
    let timeout = 0
    if (text !== prevTextValue.current) {
      prevTextValue.current = text
      if (text) {
        prevText.current = text
        setDismissed(false)
        timeout = window.setTimeout(() => setDismissed(true), waitMs)
      } else {
        setDismissed(true)
      }
    }
    return () => {
      if (timeout) window.clearTimeout(timeout)
    }
  }, [text, waitMs])
  return (
    <Notification visible={!dismissed}>
      <NotificationDiv onClick={() => setDismissed(true)}>
        <Message>{dismissed ? prevText.current : text}</Message>
      </NotificationDiv>
    </Notification>
  )
}

function useAnimatedNotification(visible: boolean) {
  const props = useSpring({
    config: { tension: 160, clamp: true },
    opacity: visible ? 1 : 0,
    transform: visible ? 'translate3d(0, 0, 100px)' : 'translate3d(0, 150%, 100px)',
  })
  return props
}

const Notification: React.FC<{ visible: boolean }> = ({ visible, children }) => {
  const style = useAnimatedNotification(visible)
  return <NotificationContainer style={style}>{children}</NotificationContainer>
}

const NotificationContainer = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
  overflow: hidden;
`

const NotificationDiv = styled.div`
  ${fontRegular}
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  margin: 10px auto;
  max-width: calc(100vw - 36px);
  padding: 5px 10px 8px 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  pointer-events: initial;
`
const Message = styled.div`
  display: inline-block;
  width: 100%;
`
