import React, { ComponentProps } from 'react'

import { NavRouteProps } from 'dashboards/types'
import { ModuleWorkflow } from 'shared/dashboard/types'
import { Spacer, Button } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { Cells } from 'dashboards/common/Cell'
import { FacManCell } from 'dashboards/common/FacManCell'
import { RadioButtonGroup, RadioButtonTabModifier } from 'common/RadioButtonGroup'

import { useSessionStorage } from 'utils/useStorage'
import { moduleCodes, AnyModuleCode } from 'shared/dashboard/moduleCodes'

export const Manual: React.FC<NavRouteProps> = ({ route }) => {
  const [workflow, setWorkflow] = useSessionStorage<ModuleWorkflow>('facDashWorkflow', 'cadet')

  return (
    <Page route={route}>
      <RadioButtonTabModifier>
        <RadioButtonGroup<string, ComponentProps<typeof Button> & { value: string; label: string }>
          size="s"
          Component={Button}
          options={[
            { value: 'facilitator', label: 'Introductory Chapters' },
            { value: 'parent', label: 'Parent' },
            { value: 'cadet', label: 'Cadet' },
            { value: 'teacher', label: 'School' },
          ]}
          value={workflow}
          onChange={value => {
            setWorkflow(value)
          }}
        />
      </RadioButtonTabModifier>
      <Spacer size="m" />
      <Cells>
        {((moduleCodes[workflow] as unknown) as string[])
          // manually filter out facilitator practise module since it's not a thing
          // TODO: Jacob or Clay to have separate lists for fac man modules and practise modules
          .filter(moduleCode => moduleCode !== 'practise')
          .map(moduleCode => (
            <FacManCell key={moduleCode} moduleCode={moduleCode as AnyModuleCode} moduleType={workflow} />
          ))}
      </Cells>
    </Page>
  )
}
