import { Scene, GameObjects } from 'phaser'

export interface ShapeImageConfig {
  x?: number
  y?: number
  size?: number
  width?: number
  height?: number
  fill?: number
  stroke?: number
  strokeThickness?: number
  alpha?: number
}

export function getSquareImage(scene: Scene, config?: ShapeImageConfig) {
  const { x = 0, y = 0 } = config ? config : {}
  const imageKey = getSquareImageKey(scene, config)
  return new GameObjects.Image(scene, x, y, imageKey)
}

export function getRectImage(scene: Scene, config?: ShapeImageConfig) {
  const { x = 0, y = 0 } = config ? config : {}
  const imageKey = getRectImageKey(scene, config)
  return new GameObjects.Image(scene, x, y, imageKey)
}

export function getCircleImage(scene: Scene, config?: ShapeImageConfig) {
  const { x = 0, y = 0 } = config ? config : {}
  const imageKey = getCircleImageKey(scene, config)
  return new GameObjects.Image(scene, x, y, imageKey)
}

export function getSquareImageKey(scene: Scene, config?: ShapeImageConfig) {
  return getImageKey(scene, 'square', config)
}

export function getRectImageKey(scene: Scene, config?: ShapeImageConfig) {
  return getImageKey(scene, 'rect', config)
}

export function getCircleImageKey(scene: Scene, config?: ShapeImageConfig) {
  return getImageKey(scene, 'circle', config)
}

export function getImageKey(scene: Scene, shape: 'circle' | 'square' | 'rect', config?: ShapeImageConfig) {
  let {
    size = 128,
    width = undefined,
    height = undefined,
    fill = 0x000000,
    alpha = 1,
    stroke = 0xffffff,
    strokeThickness: st = 0,
  } = config ? config : {}
  const imageKey = `${shape}_${size}_${fill}_${stroke}_${st}`
  const st2 = st ? st / 2 : 0
  if (!scene.textures.exists(imageKey)) {
    if (!width) width = size
    if (!height) height = size
    const graphic = scene.add
      .graphics()
      .fillStyle(fill, alpha)
      .lineStyle(st, stroke, alpha)
    if (shape === 'circle') {
      if (st > 0)
        graphic.fillCircle(size / 2, size / 2, size / 2 - st2).strokeCircle(size / 2, size / 2, size / 2 - st2)
      else graphic.fillCircle(size / 2, size / 2, size / 2)
    } else if (shape === 'square') {
      if (st > 0) graphic.fillRect(st2, st2, size - st, size - st).strokeRect(st2, st2, size - st, size - st)
      else graphic.fillRect(0, 0, size, size)
    } else if (shape === 'rect') {
      if (st > 0) graphic.fillRect(st2, st2, width - st, height - st).strokeRect(st2, st2, width - st, height - st)
      else graphic.fillRect(0, 0, width, height)
    }
    graphic.generateTexture(imageKey, width, height).destroy()
  }
  return imageKey
}
