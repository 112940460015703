/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useCallback, useEffect, useState, useRef } from 'react'
import _debounce from 'lodash/debounce'
import _isEqual from 'lodash/isEqual'
import { JSONValueType } from 'shared/session/types'

interface InputProps<ValueType> {
  value: ValueType | undefined
  onChange: (value: ValueType) => void
}
interface Props<ValueType> {
  value?: ValueType
  children: (props: InputProps<ValueType>) => ReactNode | JSX.Element
  onChange: (value: ValueType) => void
  debounce?: number | false
  maxWait?: number
}

export const DebouncedInputComponent = <ValueType extends JSONValueType>({
  children,
  value,
  onChange,
  debounce,
  maxWait = 2000,
}: Props<ValueType>) => {
  const [localValue, setLocalValue] = useState(value || ('' as ValueType))
  const onChangeDebounceQueued = useRef(false)
  const prevValueRef = useRef(value)
  const debouncedOnChange = useRef(
    _debounce(
      (value: ValueType) => {
        onChange(value)
        onChangeDebounceQueued.current = false
      },
      debounce || 250,
      { trailing: true, maxWait }
    )
  )

  useEffect(() => {
    if (onChangeDebounceQueued.current) {
      // console.log('DebouncedInputComponent: Clearing queued onChange')
      debouncedOnChange.current.cancel()
    }
    debouncedOnChange.current = _debounce(
      (value: ValueType) => {
        onChange(value)
        onChangeDebounceQueued.current = false
      },
      debounce || 250,
      { trailing: true, maxWait }
    )
    if (onChangeDebounceQueued.current) {
      // console.log('DebouncedInputComponent: re-queueing onChange after function update')
      debouncedOnChange.current(localValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, debounce, maxWait])

  const handleOnChange = useCallback((value: ValueType) => {
    onChangeDebounceQueued.current = true
    debouncedOnChange.current(value)
    setLocalValue(value)
  }, [])

  useEffect(() => {
    const valuesAreEqual = _isEqual(value, prevValueRef.current)
    // console.log(
    //   'DebouncedInputComponent: received new value prop',
    //   prevValueRef.current,
    //   '__VS__',
    //   value,
    //   '---- same:',
    //   valuesAreEqual
    // )
    if (value !== undefined && !valuesAreEqual) {
      // console.log('DebouncedInputComponent: new prop value is different from prev prop value')
      if (onChangeDebounceQueued.current && typeof value === 'string' && typeof localValue === 'string') {
        if (value !== '' && localValue.indexOf(value) === 0) {
          // console.log(
          //   'DebouncedInputComponent: doing nothing even though values are different because new prop value is a prefix of prev prop value'
          // )
        } else {
          // console.log(
          //   'DebouncedInputComponent: new prop value is not a prefix of current value so we will take it as truth'
          // )
          setLocalValue(value)
          debouncedOnChange.current.cancel()
          onChangeDebounceQueued.current = false
        }
      } else {
        // console.log('DebouncedInputComponent: overwriting local value from:', localValue, ', to new prop value:', value)
        setLocalValue(value)
      }
    } else {
      // console.log(
      //   'DebouncedInputComponent: new value prop is same as prev value prop -- not sure why this useEffect got triggered?'
      // )
    }
    prevValueRef.current = value

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  if (debounce === false) return <>{children({ value, onChange })}</>
  return <>{children({ value: localValue, onChange: handleOnChange })}</>
}
