export const cadetModuleCodes = [
  '1',
  '1a',
  '1b',
  '2',
  '2a',
  '2b',
  '3',
  '3a',
  '3b',
  '4',
  '4a',
  '4b',
  '5',
  '5a',
  '5b',
  '6',
  '6a',
  '6b',
  '7',
  '7a',
  '7b',
  '8',
  '8a',
  '8b',
  '9',
  '9a',
  '9b',
  'follow-up-1',
  'follow-up-1a',
  'follow-up-1b',
  'follow-up-2',
  'follow-up-2a',
  'follow-up-2b',
] as const
export type CadetModuleCode = typeof cadetModuleCodes[number]

export const parentModuleCodes = [
  'parent-intro',
  'parent-info-1',
  '1',
  '1a',
  '1b',
  '2',
  '2a',
  '2b',
  '3',
  '3a',
  '3b',
  'parent-info-2',
  '4',
  '4a',
  '4b',
  '5',
  '5a',
  '5b',
  '6',
  '6a',
  '6b',
  'parent-info-3',
  '7',
  '7a',
  '7b',
  '8',
  '8a',
  '8b',
  '9',
  '9a',
  '9b',
  'parent-info-4',
  'follow-up-1',
  'follow-up-1a',
  'follow-up-1b',
  'follow-up-2',
  'follow-up-2a',
  'follow-up-2b',
] as const
export type ParentModuleCode = typeof parentModuleCodes[number]

export const parentPrepModuleCodes = [
  'parent-prep-1',
  'parent-prep-2',
  'parent-prep-3',
  'parent-prep-4',
  'parent-prep-5',
  'parent-prep-6',
  'parent-prep-7',
  'parent-prep-8',
  'parent-prep-9',
] as const
export type ParentPrepModuleCode = typeof parentPrepModuleCodes[number]

export const facilitatorModuleCodes = [
  'intro-1',
  'intro-2',
  'intro-3',
  'intro-4',
  'intro-5',
  'intro-6',
  'practise',
] as const
export type FacilitatorModuleCode = typeof facilitatorModuleCodes[number]

export type AnyModuleCode = CadetModuleCode | ParentModuleCode | ParentPrepModuleCode | FacilitatorModuleCode

export const moduleCodes = {
  cadet: cadetModuleCodes,
  parent: parentModuleCodes,
  teacher: ['teacher'] as const,
  facilitator: facilitatorModuleCodes,
}

export const cadetModuleVisibility: { [key: string]: CadetModuleCode[] } = {
  '1': ['2', '2a'],
  '1a': ['1b'],
  '1b': ['2', '2a'],
  '2': ['3', '3a'],
  '2a': ['2b'],
  '2b': ['3', '3a'],
  '3': ['4', '4a'],
  '3a': ['3b'],
  '3b': ['4', '4a'],
  '4': ['5', '5a'],
  '4a': ['4b'],
  '4b': ['5', '5a'],
  '5': ['6', '6a'],
  '5a': ['5b'],
  '5b': ['6', '6a'],
  '6': ['7', '7a'],
  '6a': ['6b'],
  '6b': ['7', '7a'],
  '7': ['8', '8a'],
  '7a': ['7b'],
  '7b': ['8', '8a'],
  '8': ['9', '9a'],
  '8a': ['8b'],
  '8b': ['9', '9a'],
  '9': ['follow-up-1', 'follow-up-1a'],
  '9a': ['9b'],
  '9b': ['follow-up-1', 'follow-up-1a'],
  'follow-up-1': ['follow-up-2', 'follow-up-2a'],
  'follow-up-1a': ['follow-up-1b'],
  'follow-up-1b': ['follow-up-2', 'follow-up-2a'],
  'follow-up-2': [],
  'follow-up-2a': ['follow-up-2b'],
  'follow-up-2b': [],
}

export const parentModuleVisibility: { [key: string]: ParentModuleCode[] } = {
  'parent-intro': ['1', '1a'],
  'parent-info-1': ['parent-info-2'],
  'parent-info-2': ['parent-info-3'],
  'parent-info-3': ['parent-info-4'],
  'parent-info-4': ['follow-up-1', 'follow-up-1a'],
  ...cadetModuleVisibility,
  '2': ['3', '3a', 'parent-info-2'],
  '2b': ['3', '3a', 'parent-info-2'],
  '5': ['6', '6a', 'parent-info-3'],
  '5b': ['6', '6a', 'parent-info-3'],
  '8': ['9', '9a', 'parent-info-4'],
  '8b': ['9', '9a', 'parent-info-4'],
}

export const questionnaireModuleCodes: QuestionnaireModuleCode[] = [
  '9',
  '9b',
  'follow-up-1',
  'follow-up-1b',
  'follow-up-2',
  'follow-up-2b',
  'parent-intro',
  'parent-info-1',
  'parent-info-4',
]

export type QuestionnaireModuleCode = Extract<
  AnyModuleCode,
  | '9'
  | '9b'
  | 'follow-up-1'
  | 'follow-up-1b'
  | 'follow-up-2'
  | 'follow-up-2b'
  | 'parent-intro'
  | 'parent-info-1'
  | 'parent-info-4'
>
