import { Row, Spacer } from 'common/ui'
import { fontLight, fontRegular } from 'fonts'
import React, { Fragment } from 'react'
import { RichText } from 'session/common/RichText'
import { SectionPropsBase } from 'shared/fac-man/types'
import styled from 'styled-components'

interface Props extends SectionPropsBase {
  property: 'quote_sections'
}

export const QuoteSection: React.FC<Props> = ({ section }) => {
  const paragraphs = (section.text || '').match(/[^\r\n]+/g) || ['']

  return (
    <>
      <QuoteTitle>{section.title}</QuoteTitle>
      <Row>
        <QuoteText>
          {paragraphs.map((textSection, idx) => (
            <Fragment key={idx}>
              <RichText text={textSection} />
              {idx + 1 !== paragraphs.length && <Spacer size="s" />}
            </Fragment>
          ))}
        </QuoteText>
      </Row>
    </>
  )
}

const QuoteText = styled.div`
  ${fontLight};
  font-style: italic;
  padding-left: 20px;
  margin: 0;
  color: #011a46;
  line-height: 130%;
  letter-spacing: 0.03em;

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: #813aba;
  }

  .font-grey {
    color: #5d5d5d;
  }
`

const QuoteTitle = styled.p`
  ${fontRegular};
  font-style: italic;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #813aba;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 20px;
`
