import React, { useRef } from 'react'

import { useDrag, DragObjectWithType } from 'react-dnd'
import { EmotionometerStickerBase } from 'shared/e-telligence/types'

export const DRAG_TYPE_EMOTIONOMETER_STICKER = 'DRAG_TYPE_EMOTIONOMETER_STICKER'

export interface StickerDragItem extends DragObjectWithType {
  type: typeof DRAG_TYPE_EMOTIONOMETER_STICKER
  from: 'tray' | 'emotionometer'
  sticker: EmotionometerStickerBase
}

export const DraggableSticker: React.FC<{
  sticker: EmotionometerStickerBase
  onMove: () => void
  updateDragItemSize: (size: number) => void
  origin: StickerDragItem['from']
  disabled?: boolean
}> = ({ onMove, sticker, origin, disabled, children, updateDragItemSize }) => {
  const measuringRef = useRef<HTMLDivElement>(null)
  const [{ isDragging }, drag] = useDrag({
    item: { type: DRAG_TYPE_EMOTIONOMETER_STICKER, from: origin, sticker } as StickerDragItem,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
    canDrag: monitor => !disabled,
    begin: monitor => {
      if (measuringRef.current) updateDragItemSize(measuringRef.current.getBoundingClientRect().width)
    },
  })
  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, pointerEvents: isDragging ? 'none' : 'auto' }}>
      <div ref={measuringRef}>{children}</div>
    </div>
  )
}
