import { useSessionState } from 'session/SessionState'
import { Section, SectionStates, SectionStateProperty } from 'shared/session/types'

export function useSectionState<T extends SectionStateProperty>(
  sectionProperty: T,
  section: Pick<Section, 'id'>
): SectionStates[T] | null {
  const { state } = useSessionState()
  const existingSectionState = state.sectionStates.find(
    sectionState => sectionState.property === sectionProperty && sectionState.section_id === section.id
  )
  return existingSectionState ? existingSectionState.state : null
}

export function useSectionStateWithFallback<T extends SectionStateProperty>(
  sectionProperty: T,
  section: Pick<Section, 'id'>,
  getInitialState: () => SectionStates[T]
): SectionStates[T] {
  const sectionState = useSectionState(sectionProperty, section)
  return sectionState === null ? getInitialState() : sectionState
}
