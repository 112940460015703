import styled, { css } from 'styled-components'
import { Row } from 'common/ui'

export const Tabs = styled(Row)`
  max-width: 100%;
  min-height: 46px;
`

export const Tab = styled.div<{ active?: boolean; disabled?: boolean }>`
  flex: none;
  min-width: 100px;
  background-color: white;
  color: #011a46;
  opacity: ${p => (p.active ? 1 : 0.7)};
  border: 1px solid #cbd2e8;
  border-bottom: none;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:not(:first-child) {
    margin-left: -1px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
  ${p =>
    p.active
      ? css`
          box-shadow: 0 1px #fff;
        `
      : ''}

  ${p =>
    p.disabled
      ? css`
          opacity: 0.25;
          pointer-events: none;
        `
      : ''}
`
