import { useEffect, useRef } from 'react'
import PubNub from 'pubnub'

interface PubnubClass {
  new (options: PubNub.PubnubConfig): PubNub
}

declare global {
  interface Window {
    PubNub: PubnubClass
  }
}

export function useSubscribe(profileId: number, revision: number, callback: () => void) {
  const callbackRef = useRef(callback)
  const revisionRef = useRef(revision)
  const pubNubRef = useRef<PubNub | null>(null)

  callbackRef.current = callback
  revisionRef.current = revision

  useEffect(() => {
    const forcePing = () => callbackRef.current()
    window.addEventListener('online', forcePing)
    return () => {
      window.removeEventListener('online', forcePing)
    }
  }, [])

  useEffect(() => {
    if (!window.PubNub) return
    const pubnub = new window.PubNub({
      subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY || '',
      publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY || '',
      ssl: true,
    })
    pubNubRef.current = pubnub
    const channel = `skilltracker-${profileId}`
    const listener: PubNub.ListenerParameters = {
      message: message => {
        if (message.channel !== channel) return
        if (message.message.revision > revisionRef.current) {
          callbackRef.current()
        }
      },
    }
    pubnub.addListener(listener)
    pubnub.subscribe({ channels: [channel] })
    return () => {
      pubnub.unsubscribe({ channels: [channel] })
      pubnub.removeListener(listener)
      pubnub.stop()
    }
  }, [profileId])

  useEffect(() => {
    if (!pubNubRef.current) return
    const channel = `skilltracker-${profileId}`
    pubNubRef.current.publish({ message: { revision }, channel }).catch(console.error)
  }, [profileId, revision])
}
