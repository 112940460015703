import { LinkButton } from 'common/LinkButton'
import { Column, H3, P } from 'common/ui'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Page } from 'dashboards/common/Page'
import { NavRouteProps } from 'dashboards/types'
import React from 'react'
import styled from 'styled-components'

export const PracticeSession: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route}>
      <WidthWrapper>
        <P>
          Practice Sessions provide SAS Facilitators with a simulation of a child meeting and a parent meeting. Follow
          the guidance on the pop-up checklists and practise the different Facilitator controls before using the
          software with families.
        </P>
      </WidthWrapper>
      <HeaderHr children="Choose a session type" />
      <Cells>
        <Cell accent="blue">
          <CellInner>
            <Column flex="1 1 auto">
              <H3>Practise - Cadet Meeting</H3>
            </Column>
            <Column flex="none">
              <LinkButton size="s" children="Begin" theme="blue" to={`/facilitator/practice/cadet`} />
            </Column>
          </CellInner>
        </Cell>
        <Cell accent="parentGreen">
          <CellInner>
            <Column flex="1 1 auto">
              <H3>Practise - Parent Meeting</H3>
            </Column>
            <Column flex="none">
              <LinkButton size="s" children="Begin" theme="parentGreen" to={`/facilitator/practice/parent`} />
            </Column>
          </CellInner>
        </Cell>
      </Cells>
    </Page>
  )
}

export const WidthWrapper = styled.div`
  max-width: 1000px;

  ${P} {
    margin: 10px 0 20px 0;
  }
`
