import { QuestionnaireSectionParticipantState, QuestionnaireSectionState } from '../../types'
import { ActionTypes, UPDATE_PARTICIPANT_DATA, CHANGE_PARTICIPANT_ACCESS } from './actionTypes'

export const getQuestionnaireSectionParticipantState = (
  state: QuestionnaireSectionState,
  participantUid: keyof QuestionnaireSectionState | null
): QuestionnaireSectionParticipantState =>
  (participantUid ? state[participantUid] : false) || { active: false, data: {} }

export function reducer(state: QuestionnaireSectionState, action: ActionTypes): typeof state {
  if (!state) state = {}
  switch (action.type) {
    case UPDATE_PARTICIPANT_DATA: {
      const currentUserState = getQuestionnaireSectionParticipantState(state, action.participantUid)
      return { ...state, [action.participantUid]: { ...currentUserState, data: action.data } }
    }
    case CHANGE_PARTICIPANT_ACCESS: {
      const currentUserState = getQuestionnaireSectionParticipantState(state, action.participantUid)
      return { ...state, [action.participantUid]: { ...currentUserState, active: action.active } }
    }
    default:
      return state
  }
}
