import React, { useRef, useState } from 'react'
import { QuestionOption } from '../../shared/training/types'
import { CheckboxGroup } from 'common/CheckboxGroup'
import { RadioGroup } from 'common/RadioGroup'
import { Button, Spacer } from 'common/ui'
import { isEmpty, xor, shuffle } from 'lodash'
import { getQuestionState, useTrainingState } from 'training/TrainingState'

export const RadioQuestion: React.FC<{
  uid: string
  randomize_options: boolean
  question_options: QuestionOption[]
  reviewMode: boolean
  onSubmit: (correct: boolean, value: string, response?: string) => void
}> = ({ randomize_options, question_options, reviewMode, onSubmit, uid }) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)
  const [selectedOption, setSelectedOption] = useState<string>(questionState?.answer || '')
  const correctAnswer = question_options.find(option => option.correct)
  const options = useRef<{ value: string }[]>(
    (randomize_options ? shuffle(question_options) : question_options).map(option => {
      return { value: option.title }
    })
  )

  const handleChange = (val: string) => {
    setSelectedOption(val)
  }

  const handleSubmit = () => {
    if (correctAnswer && correctAnswer.title === selectedOption) {
      return onSubmit(true, selectedOption, `The correct answer is "${correctAnswer.title}"`)
    }
    onSubmit(false, selectedOption)
  }

  return (
    <>
      <RadioGroup<{ value: string }>
        name=""
        hideOuterDividers
        hideInnerDividers
        size="s"
        value={selectedOption}
        options={options.current}
        onChange={handleChange}
        disabled={reviewMode}
      />
      <Spacer />
      {!reviewMode && <Button size="s" children="Submit" onClick={handleSubmit} />}
    </>
  )
}

export const CheckboxQuestion: React.FC<{
  uid: string
  randomize_options: boolean
  question_options: QuestionOption[]
  reviewMode: boolean
  onSubmit: (correct: boolean, value: string[], response?: string) => void
}> = ({ uid, randomize_options, question_options, reviewMode, onSubmit }) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)
  const [selectedOptions, setSelectedOptions] = useState<string[]>(questionState?.answer || [])
  const correctAnswers = question_options.filter(option => option.correct).map(a => a.title)
  const options = useRef<{ value: string }[]>(
    (randomize_options ? shuffle(question_options) : question_options).map(option => {
      return { value: option.title }
    })
  )

  const handleChange = (value: string[], options: { value: string }[]) => {
    setSelectedOptions(options.map(a => a.value))
  }

  const handleSubmit = () => {
    if (isEmpty(xor(correctAnswers, selectedOptions))) {
      return onSubmit(true, selectedOptions, `The correct answers are "${correctAnswers.join(', ')}"`)
    }
    onSubmit(false, selectedOptions)
  }

  return (
    <>
      <CheckboxGroup<{ value: string }>
        name=""
        size="s"
        hideDividers
        value={selectedOptions}
        options={options.current}
        onChange={handleChange}
        disabled={reviewMode}
      />
      <Spacer />
      {!reviewMode && <Button size="s" children="Submit" onClick={handleSubmit} />}
    </>
  )
}
