import { MediaAsset } from 'shared/session/types'

interface AssetRequestConfig {
  absolute?: boolean
  baseUrl?: string
  preset?: string // TODO
  extension?: string
}

const defaultRequestConfig = {
  absolute: true,
  baseUrl: process.env.REACT_APP_SESSION_API_URL,
}

export const getImageUrl = (asset: MediaAsset, _config: AssetRequestConfig = defaultRequestConfig): string => {
  const { absolute, baseUrl, preset, extension: newExtension } = { ...defaultRequestConfig, ..._config }
  const { filename, slug, extension } = asset
  const ignorePreset = extension === 'svg' || extension === 'gif'
  let url = absolute ? baseUrl : ''
  if (preset && !ignorePreset) {
    url += `/generated/${preset}/${slug}.${newExtension || extension || 'jpg'}`
  } else {
    url += `/uploads/${filename}`
  }
  return url
}

export const getAssetUrl = (
  asset: MediaAsset,
  _config: Omit<AssetRequestConfig, 'preset' | 'extension'> = defaultRequestConfig
) => {
  if ((asset.filename || '').match(/^(http|\/static)/)) return asset.filename
  const { absolute, baseUrl } = { ...defaultRequestConfig, ..._config }
  return `${absolute ? baseUrl : ''}/uploads/${asset.filename}`
}
