import React, { useRef, useState, ComponentProps, CSSProperties } from 'react'
import GrapheneSplitter from 'grapheme-splitter'
import { TextInput, InputSize } from './ui'
import { supportsEmojis } from 'utils/deviceUtils'
import { getEmojiId } from 'utils/emojiUtils'
import { SymbolSelectModal } from './SymbolSelectModal'

type InputProps = ComponentProps<typeof TextInput>

export interface SymbolInputProps {
  symbols: string
  value: string | undefined
  multipleSymbols?: boolean
  inputProps?: Partial<InputProps>
  onChange: (value: string) => void
  disabled?: boolean
}

const splitter = new GrapheneSplitter()

const getSymbolStylesForSize = (size: InputSize, hasSymbol: boolean, symbolCount: number): CSSProperties => {
  switch (size) {
    case 's':
      return {
        fontSize: !hasSymbol || symbolCount > 1 ? 20 : 15,
        width: symbolCount > 1 ? 70 : 50,
        height: Math.max(35, 15 + Math.ceil(symbolCount / 2) * 25),
        paddingTop: hasSymbol ? 14 : 2,
      }
    case 'm':
      return {
        fontSize: !hasSymbol || symbolCount > 1 ? 24 : 20,
        width: symbolCount > 1 ? 100 : 75,
        height: Math.max(40, 15 + Math.ceil(symbolCount / 2) * 30),
        paddingTop: hasSymbol ? 20 : 4,
      }
    case 'l':
      return {
        fontSize: !hasSymbol || symbolCount > 1 ? 24 : 24,
        width: symbolCount > 1 ? 110 : 75,
        height: Math.max(45, 15 + Math.ceil(symbolCount / 2) * 35),
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: hasSymbol ? 16 : 16,
      }
  }
}

export const SymbolInput: React.FC<SymbolInputProps> = ({
  value,
  symbols,
  multipleSymbols,
  onChange,
  inputProps = {},
  disabled,
}) => {
  const symbolInputRef = useRef<HTMLInputElement>(null)
  const [symbolModalOpen, setSymbolModalOpen] = useState(false)

  const emojisSupported = supportsEmojis()
  const valueSymbols = splitter.splitGraphemes(value || '')

  const inputSize: InputSize = inputProps.inputSize || 's'
  const extraInputProps: Partial<InputProps> = { ...inputProps }
  extraInputProps.style = {
    ...getSymbolStylesForSize(inputSize, !!value, valueSymbols.length),
    textAlign: 'center',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    backgroundColor: 'white',
    lineHeight: 1.2,
    ...(multipleSymbols && !!value ? { paddingTop: 2, paddingBottom: 2 } : {}),
    ...(emojisSupported || !value
      ? {}
      : {
          background: multipleSymbols
            ? valueSymbols
                .map((symbol, i) => `url('${require(`common/assets/emoji/${getEmojiId(symbol)}.png`)}')`)
                .join(', ')
            : `url('${require(`common/assets/emoji/${getEmojiId(value)}.png`)}')`,
          backgroundSize: '1em',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }),
    ...(extraInputProps.style || {}),
  }

  return (
    <>
      <TextInput
        ref={symbolInputRef}
        type={multipleSymbols && !!value ? 'submit' : 'text'}
        placeholder="+"
        {...extraInputProps}
        value={multipleSymbols ? valueSymbols.join('\n ') : value || ''}
        readOnly
        disabled={disabled}
        onClick={() => !disabled && setSymbolModalOpen(true)}
      />
      <SymbolSelectModal
        isOpen={symbolModalOpen}
        symbols={symbols}
        multiple={multipleSymbols}
        value={value || null}
        onChange={symbol => {
          onChange(symbol)
          !multipleSymbols && setTimeout(() => setSymbolModalOpen(false), 150)
        }}
        onClose={() => {
          setSymbolModalOpen(false)
          setTimeout(() => symbolInputRef.current && symbolInputRef.current.blur())
        }}
      />
    </>
  )
}
