import React, { useState, useEffect, useRef, useMemo, CSSProperties } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'

import { Column, Button, Center, ButtonProps } from 'common/ui'

import { SCENE_GENERATOR_STORAGE_KEY } from 'scene-builder/SceneBuilder'
import { SerializedScene } from 'scene-builder/types'
import { retry } from 'utils/apiUtils'
import { getDraftKey } from './useDraftData'
import { useUserState } from 'app/UserState'
import { useOnline } from 'utils/useOnline'

const SceneBuilder = React.lazy(() => retry(() => import(/* webpackChunkName: "SceneBuilder" */ 'scene-builder/SceneBuilder'))) // prettier-ignore

export const ScenePreview: React.FC<{
  storageKey?: string
  value?: SerializedScene
  onChange: (value: SerializedScene) => void
  onShowSceneBuilder?: () => void
  flex?: boolean | string
  buttonSize?: ButtonProps['size']
  disableEditing?: boolean
  style?: CSSProperties
}> = ({ storageKey, value, onChange, flex, buttonSize, disableEditing, style, onShowSceneBuilder }) => {
  const online = useOnline()
  const { profileId, openAccess } = useUserState()
  const [showSceneBuilder, setShowSceneBuilder] = useState(false)
  const hasDraft = useMemo<Boolean>(() => {
    try {
      return !!window.localStorage.getItem(getDraftKey(profileId, openAccess, SCENE_GENERATOR_STORAGE_KEY, storageKey))
    } catch (e) {
      return false
    }
  }, [profileId, openAccess, storageKey])
  const hasThumbnail = value && value.thumbnail
  const onShowSceneBuilderRef = useRef(onShowSceneBuilder)

  onShowSceneBuilderRef.current = onShowSceneBuilder
  useEffect(() => {
    showSceneBuilder && onShowSceneBuilderRef.current && onShowSceneBuilderRef.current()
  }, [showSceneBuilder, onShowSceneBuilderRef])
  return (
    <>
      <SceneContainer
        flex={flex}
        onClick={online && !disableEditing && hasThumbnail ? () => setShowSceneBuilder(true) : undefined}
        paddingLeft={!hasThumbnail ? 's' : undefined}
        paddingRight={!hasThumbnail ? 's' : undefined}
        style={{
          backgroundColor: hasThumbnail ? 'black' : undefined,
          backgroundImage: hasThumbnail ? `url(${value && value.thumbnail})` : undefined,
          cursor: hasThumbnail || hasDraft ? 'pointer' : undefined,
          minHeight: '10rem',
          ...style,
        }}>
        {!disableEditing && !hasThumbnail && (
          <Center flex>
            <Button size={buttonSize} onClick={() => setShowSceneBuilder(true)} disabled={!online}>
              {!online ? '[Not Available Offline]' : `${hasDraft ? 'Resume' : 'Begin'} Scene Generator`}
            </Button>
          </Center>
        )}
      </SceneContainer>
      {showSceneBuilder && (
        <ReactModal
          isOpen
          style={{
            overlay: {
              background: 'none',
              zIndex: 999,
            },
            content: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              border: 0,
              display: 'flex',
              padding: 0,
              borderRadius: 0,
              overflow: 'initial',
              background: 'none',
            },
          }}>
          <SceneBuilder
            draftStorageKey={storageKey}
            sceneData={value}
            onComplete={scene => {
              setShowSceneBuilder(false)
              onChange(scene)
            }}
          />
        </ReactModal>
      )}
    </>
  )
}

export const SceneContainer = styled(Column)`
  background: #d5d9ed no-repeat center / contain;
  border: 1px solid #abb4db;
  border-radius: 10px;
  box-shadow: 0px 2px 0px 0px #fff;
`
