import React from 'react'

import { NavRouteProps } from 'dashboards/types'
import { ProviderLinkRequest, ProviderEntity } from 'shared/dashboard/types'

import { H2, P, Spacer } from 'common/ui'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Cells } from 'dashboards/common/Cell'
import { Page } from 'dashboards/common/Page'
import { LinkOutlineButton } from 'common/LinkButton'
import { ProviderCell } from 'dashboards/common/ProviderCell'
import { SpinnerWithLabel } from 'common/Spinner'

import { toJson } from 'utils/apiUtils'
import { useFacDashData } from './FacilitatorDashboardState'
import { useUserState } from 'app/UserState'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
// import { useEndpoint } from 'dashboards/utils/endpointHooks'

const linkRequestToProvider = (request: ProviderLinkRequest): ProviderEntity => ({
  id: request.provider_id,
  name: request.name,
  administrators: [],
})

export const Providers: React.FC<NavRouteProps> = ({ route }) => {
  const { accessToken } = useUserState()
  const [providers, loadingProviders, reloadProviders] = useFacDashData('providers', [])
  const [providerLinkRequests, loadingProviderLinkRequests, reloadProviderLinkRequests] = useFacDashData(
    'providerLinkRequests',
    []
  )
  // const [productKeysCount, { loading: loadingProductKeyCount }] = useEndpoint<number>(`/api/v1/product_keys/count`, 0)
  const revokeProviderLinkRequest = (linkRequest: ProviderLinkRequest) => {
    fetch('/api/v1/providers/revoke', {
      method: 'POST',
      body: JSON.stringify({ provider_link_uid: linkRequest.link_id }),
      headers: {
        ...getAuthRequestParams(accessToken).headers,
        'content-type': 'application/json',
      },
    })
      .then(toJson)
      .then((data: any) => {
        console.log('Submitted! Got back:', data)
        reloadProviders()
        reloadProviderLinkRequests()
      })
      .catch((response: any) => {
        try {
          const message = JSON.stringify(response)
          alert(`Error revoking link request\n\n${message}`)
        } catch (e) {
          alert(`Error revoking link request`)
        }
      })
  }
  const revokeProvider = (provider: ProviderEntity) => {
    const linkRequest = providerLinkRequests.find(req => req.provider_id === provider.id)
    if (!linkRequest) return alert('Error: Unable to find original request')
    revokeProviderLinkRequest(linkRequest)
  }
  return (
    <Page route={route}>
      <Spacer size="m" />
      <H2>SAS Facilitators</H2>
      <P>
        Linking your SAS Facilitator profile to a subscribed SAS Provider service is required to coordinate and deliver
        the SAS Small Group Program, digital edition. Once your service has set up their{' '}
        <a href="https://www.sst-institute.net/new-sas-provider-packages" target="_blank" rel="noopener noreferrer">
          SAS Provider subscription
        </a>
        , you can use the button below to send a request to link to the SAS Provider administrator.
      </P>
      <P>
        Once your request is accepted you can start exploring the SAS Facilitator resources, creating groups, scheduling
        group meetings, and launching live meetings starting with your Introductory Parent Meeting.
      </P>
      <Spacer size="m" />
      <H2>SAS Assistants</H2>
      <P>
        Linking your SAS Assistant profile to a subscribed SAS Provider service is required to join a live meeting to
        assist your lead SAS Facilitator.
      </P>
      <P>
        Once your request is accepted, you can be added by your SAS Facilitator to group meeting and see any scheduled
        sessions from your dashboard. During live meetings you can join and view the facilitator controls and cadet
        tabs.
      </P>
      <Spacer size="m" />
      {/* <P>Total available cadet places: {loadingProductKeyCount ? '---' : String(productKeysCount)}</P> */}
      <HeaderHr children="SAS Provider Details" />
      <Cells>
        {(loadingProviders && !providers.length) || (loadingProviderLinkRequests && !providerLinkRequests.length) ? (
          <SpinnerWithLabel label="Loading providers..." />
        ) : (
          <>
            {providers.map(provider => (
              <ProviderCell
                key={provider.id}
                provider={provider}
                onRevoke={() => revokeProvider(provider)}
                showLicenseCount
              />
            ))}
            {providerLinkRequests
              .filter(providerLinkRequest => providerLinkRequest.status !== 'accepted')
              .map(providerLinkRequest => (
                <ProviderCell
                  key={providerLinkRequest.link_id}
                  provider={linkRequestToProvider(providerLinkRequest)}
                  status={providerLinkRequest.status}
                  onRevoke={() => revokeProviderLinkRequest(providerLinkRequest)}
                  hideDetails
                />
              ))}
          </>
        )}
        <LinkOutlineButton to={route.path + '/request'} style={{ display: 'block' }}>
          + Request to be linked to an SAS Provider
        </LinkOutlineButton>
      </Cells>
    </Page>
  )
}
