import { GameObjects } from 'phaser'
import { PhaserTextStyle } from 'phaser-types'
import { Vector } from 'types'
import SceneBuilderScene from './SceneBuilderScene'
import AssetSprite from './AssetSprite'

import { getPixelRatio } from 'utils/screenUtils'
// import { addUpdateTestDot } from 'phaser/testDots'

export default class ActionText extends GameObjects.Text {
  owner: AssetSprite
  scene: SceneBuilderScene
  constructor(scene: SceneBuilderScene, owner: AssetSprite, text?: string) {
    if (!text) text = owner.text

    const fontStyle: PhaserTextStyle = {
      fontFamily: 'Arial',
      fontSize: '13px',
      fontStyle: '',
      align: 'center',
      color: '#000000',
      stroke: '#fff',
      strokeThickness: 2,
      resolution: getPixelRatio() * 2,
      wordWrap: { width: 150 },
    }

    super(scene, owner.x, owner.y, text, fontStyle)
    scene.add.existing(this)
    this.owner = owner
    this.scene = scene
    this.setOrigin(0.5, 0.5)
  }

  public positionToOwner() {
    const offset: Vector = {
      x: this.owner.realWidth * (this.owner.center.x - 0.5),
      y: this.owner.realHeight * (this.owner.center.y - 0.5),
    }
    if (this.owner.flipX) offset.x = -offset.x
    if (this.owner.flipY) offset.y = -offset.y
    const translatedOffset: Vector = {
      x: Math.cos(this.owner.rotation) * offset.x + Math.cos(this.owner.rotation + Math.PI / 2) * offset.y,
      y: Math.sin(this.owner.rotation) * offset.x + Math.sin(this.owner.rotation + Math.PI / 2) * offset.y,
    }
    this.rotation = this.owner.rotation
    this.x = this.owner.x + translatedOffset.x
    this.y = this.owner.y + translatedOffset.y
    this.setScale(this.owner.scale * 3)
    this.depth = this.owner.depth + 0.5
    this.flipX = this.flipY = this.owner.rotation > Math.PI / 2 || this.owner.rotation < -Math.PI / 2

    /*
    addUpdateTestDot({
      key: 'txt1',
      color: 0xff0000,
      scene: this.scene,
      parent: this.scene.container,
      position: { x: this.owner.x, y: this.owner.y },
    })
    addUpdateTestDot({
      key: 'txt2',
      color: 0x00ff00,
      scene: this.scene,
      parent: this.scene.container,
      position: { x: this.x, y: this.y },
    })
    */
  }
}
