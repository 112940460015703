import { Attempt, Activity } from 'types'

export function completedAttempt({ result }: Attempt) {
  return result === 'success' || result === 'pass' || result === 'complete'
}

export function hasCompleted(activity: Activity, attempts: Attempt[]) {
  return !!attempts.find(a => a.activity === activity.id && completedAttempt(a))
}

export function isGameActivity(activity: Activity) {
  return activity.section === 'game'
}

export function isJournalActivity(activity: Activity) {
  return activity.section === 'journal'
}

export function hasAttempted(activity: Activity, attempts: Attempt[]) {
  return !!attempts.find(a => a.activity === activity.id)
}

export function hasAttemptedAny(activities: Activity[], attempts: Attempt[]) {
  return !!attempts.find(a => activities.find(activity => activity.id === a.activity))
}

export function byActivity(activity: Activity, sub_activity: Attempt['sub_activity'] = null) {
  return (attempt: Attempt) => attempt.activity === activity.id && attempt.sub_activity === sub_activity
}
