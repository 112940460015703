import styled, { css } from 'styled-components'
// import { useSessionState } from 'session/SessionState'
// import { TOGGLE_GROUP_LOCK_AREA, TOGGLE_PARTICIPANT_LOCK_AREA } from 'shared/session/actionTypes'
// import { FocusLocation } from 'shared/session/types'
// import { useFacilitatorState, useFocusedParticipantState } from 'session/hooks/useProfileState'

// export const LockableArea: React.FC<Props> = ({ children, id, style }) => {
//   const facilitatorState = useFacilitatorState()
//   const focusedParticipantState = useFocusedParticipantState()
//   const { dispatch, state, getBaseAction } = useSessionState()
//   const { lockedAreas: globalLockedAreas } = state

//   const elementRef = useRef<HTMLDivElement | null>(null)
//   const [over, setOver] = useState<boolean>(false)
//   const [locked, setLocked] = useState<boolean>(false)

//   const interactive = facilitatorState && facilitatorState.selectedTool === 'lock'
//   const profileLockedAreas: FocusLocation[] = focusedParticipantState ? focusedParticipantState.locked : []

//   useEffect(() => {
//     const lockedAreaKeys = [...globalLockedAreas, ...profileLockedAreas].map(({ key }) => key)
//     if (lockedAreaKeys.indexOf(id) >= 0 && !locked) setLocked(true)
//     else if (lockedAreaKeys.indexOf(id) < 0 && locked) setLocked(false)
//   }, [id, globalLockedAreas, profileLockedAreas, locked])

//   const handleSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
//     if (!facilitatorState || (facilitatorState && facilitatorState.selectedTool !== 'lock')) return
//     event.stopPropagation()
//     event.preventDefault()
//     if (!focusedParticipantState) {
//       dispatch({
//         ...getBaseAction(),
//         type: TOGGLE_GROUP_LOCK_AREA,
//         location: { key: id },
//       })
//     } else {
//       dispatch({
//         ...getBaseAction(),
//         type: TOGGLE_PARTICIPANT_LOCK_AREA,
//         location: { key: id },
//         profile: focusedParticipantState.profile,
//       })
//     }
//   }

//   return (
//     <LockArea
//       ref={elementRef}
//       over={over}
//       locked={locked}
//       interactive={interactive}
//       children={children}
//       onMouseEnter={() => setOver(true)}
//       onMouseLeave={() => setOver(false)}
//       onClick={handleSelect}
//       style={{ cursor: interactive && over ? 'no-drop' : 'auto', ...(style || {}) }}
//     />
//   )
// }

export const LockArea = styled.div<{ over: boolean; locked: boolean; interactive: boolean }>`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s linear;
    border: 4px dashed ${p => (p.interactive && p.over ? 'rgba(0,0,0,0.5)' : 'transparent')};
    border-radius: 10px;
    background-color: ${p =>
      (p.interactive && p.over) || p.locked ? `rgba(0, 0, 0, ${p.locked ? 0.35 : 0.2})` : 'transparent'};
    pointer-events: ${p => (!p.interactive && p.locked ? 'auto' : 'none')};
    cursor: ${p => (p.interactive ? 'no-drop' : 'auto')};
    background-image: ${p => (p.locked ? `url('${require('common/assets/image/lock-no-shadow@2x.png')}')` : 'none')};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 50px;
  }
  ${p =>
    !p.interactive
      ? ''
      : css`
          & *:enabled {
            pointer-events: none;
          }
        `}
`
