import React, { ComponentProps, Fragment, useEffect, useRef, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useHistory, useLocation, useParams } from 'react-router'
import { Theme, blue, orange, parentGreen, purple } from 'themes'
import qs from 'qs'

import {
  Questionnaire,
  QuestionnaireSummary,
  QuestionnaireType,
  questionnaireTypeLabels,
  QuestionnaireUser,
} from 'shared/questionnaires/types'
import { UPDATE_STATE } from 'shared/questionnaires/actionTypes'
import { QuestionnaireStateProvider, useQuestionnaireState } from 'questionnaires/QuestionnaireState'
import { observationalCodeQuestionnaire } from 'dashboards/common/ObservationalCodeQuestionnaire'

import { AppBackground, Panel, H1, P, Row, H2, CollapseMargin, Padding, Container, Column } from 'common/ui'
import { SpinnerWithLabel } from 'common/Spinner'
import { QuestionnaireView as QuestionnaireContent } from 'questionnaires/Questionnaire'
import { LogoSmallGroup } from 'common/LogoSmallGroup'
import { SocialSkillsFooter } from 'dashboards/common/Footer'
import { QuestionWrapper } from 'questionnaires/sections/QuestionSection'

import { postJson } from 'utils/apiUtils'

export type AllowedQuestionnaireTypes = Exclude<QuestionnaireType, 'training-pre' | 'training-post' | 'custom'>
export const questionnaireTypeUserTypeMap: {
  [key in AllowedQuestionnaireTypes]: QuestionnaireUser
} = {
  'planning-teacher': 'teacher',
  'spence-ssq-parent': 'parent',
  'spence-ssq-teacher': 'teacher',
  'erssq-parent': 'parent',
  'erssq-teacher': 'teacher',
  'james-anxiety': 'cadet',
  'dylan-anger': 'cadet',
  'observational-code': 'facilitator',
  'feedback-parent': 'parent',
  'feedback-teacher': 'teacher',
  'feedback-cadet': 'cadet',
}

export const userTypeThemeMap: { [key in QuestionnaireUser]: Theme } = {
  cadet: blue,
  teacher: orange,
  parent: parentGreen,
  facilitator: purple,
}

export const PublicQuestionnaire: React.FC = () => {
  return (
    <QuestionnaireStateProvider>
      <PublicQuestionnaireInner />
    </QuestionnaireStateProvider>
  )
}

type NavStateData = Pick<QuestionnaireSummary, 'name' | 'cadet_name' | 'facilitator_names'>

interface ReturnData {
  questionnaire: Questionnaire
  data: { facilitator_names: string }
}

export const PublicQuestionnaireInner: React.FC = () => {
  const { dispatch, state: userData, setQuestionnaireData, setUserDataStatus } = useQuestionnaireState()
  const history = useHistory()
  const { search, state: navState } = useLocation<NavStateData | undefined>()
  const { questionnaireType, booklet } = useParams<{ questionnaireType: QuestionnaireType; booklet: string }>()
  const queryParams: { facilitator_id?: string; provider_uid?: string; standalone?: string } = qs.parse(
    search.replace(/^\?/, '')
  )
  const { facilitator_id, provider_uid, standalone } = queryParams

  const errorList: string[] = []
  if (!Object.keys(questionnaireTypeLabels).includes(questionnaireType))
    errorList.push('Invalid questionnaire type specified in URL')
  if (!Object.keys(questionnaireTypeUserTypeMap).includes(questionnaireType))
    errorList.push(`Questionnaire type "${questionnaireType}" cannot be done externally`)
  if (!'1234'.split('').includes(booklet)) errorList.push('Invalid questionnaire booklet number specified in URL')
  if (!facilitator_id) errorList.push(`Facilitator's identifier is missing in URL`)
  if (!provider_uid) errorList.push(`Facilitator's provider identifier is missing in URL`)

  const [verified, setVerified] = useState<null | boolean>(null)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const verifying = useRef<boolean>(false)
  useEffect(() => {
    if (!errorList.length && !verifying.current) {
      verifying.current = true
      postJson(`/api/v1/public/questionnaire/verify`, {
        facilitator_id,
        provider_uid,
        questionnaire_type: questionnaireType,
        questionnaire_booklet: booklet,
      })
        .then(({ data, questionnaire }: ReturnData) => {
          setVerified(true)
          setQuestionnaireData(
            (questionnaireType as QuestionnaireType) === 'observational-code'
              ? observationalCodeQuestionnaire
              : questionnaire
          )
          setUserDataStatus('ready')
          dispatch({
            type: UPDATE_STATE,
            state: {
              provider_uid,
              drupal_user_id: parseInt(facilitator_id as string),
              questionnaire_booklet: parseInt(booklet) as 1 | 2 | 3 | 4,
              questionnaire_type: questionnaireType as AllowedQuestionnaireTypes,
              date: new Date().toISOString().split('T')[0],
              name: '',
              facilitator_names: data.facilitator_names || '',
              facilitator_created: false,
              responses: [],
              ...(navState ? navState : {}),
            },
          })
          verifying.current = false
        })
        .catch(err => {
          console.log('err', err)
          setVerified(false)
          if (err.message) setErrorMessage(err.message)
          verifying.current = false
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorList.length, facilitator_id, provider_uid, questionnaireType, booklet])

  useEffect(() => {
    setVerified(null)
  }, [questionnaireType, booklet])

  const handleSubmit: Exclude<ComponentProps<typeof QuestionnaireContent>['onSubmit'], undefined> = data => {
    return postJson(`/api/v1/public/questionnaire`, {
      data,
      context: { facilitator_id, provider_uid },
    })
  }

  const navStateData = userData
    ? { name: userData.name, cadet_name: userData.cadet_name, facilitator_names: userData.facilitator_names }
    : {}

  const handlePrev = (questionnaireType: QuestionnaireType) => {
    history.push(`/public/questionnaire/${questionnaireType}/${booklet}/${search}`, navStateData)
  }

  const handleNext = (questionnaireType: QuestionnaireType) => {
    history.push(`/public/questionnaire/${questionnaireType}/${booklet}/${search}`, navStateData)
  }

  const userType = questionnaireTypeUserTypeMap[questionnaireType as AllowedQuestionnaireTypes]

  if (errorList.length) {
    return (
      <AppBackground theme={userTypeThemeMap[userType]}>
        <Row justifyContent="center" alignItems="center" style={{ width: '100%' }}>
          <Panel padding="m" style={{ maxWidth: 420 }}>
            <H1>Uh oh!</H1>
            <P
              children={errorList.map((str, i) => (
                <Fragment key={i}>
                  {i + 1 + '. ' + str}
                  <br />
                </Fragment>
              ))}
            />
            <P>
              If you manually copied this URL from somewhere else please check that you copied the full URL, otherwise
              contact your facilitator to request a new URL.
            </P>
          </Panel>
        </Row>
      </AppBackground>
    )
  }

  if (!verified) {
    return (
      <AppBackground theme={userTypeThemeMap[userType]}>
        <Row justifyContent="center" alignItems="center" style={{ width: '100%' }}>
          <Panel padding="m" style={{ maxWidth: 420 }}>
            {verified === false ? (
              <CollapseMargin>
                <H2>Unable to verify your access</H2>
                {errorMessage && <P>Reason: {errorMessage}</P>}
              </CollapseMargin>
            ) : (
              <SpinnerWithLabel label={`Verifying, one moment please...`} />
            )}
          </Panel>
        </Row>
      </AppBackground>
    )
  }

  return (
    <ThemeProvider theme={userTypeThemeMap[userType]}>
      <HeaderContainer style={{ zIndex: 1002 }}>
        <Header>
          <Container size="m" style={{ height: '100%' }}>
            <Row style={{ height: '100%' }} paddingLeft="m" paddingRight="m">
              <Column flex="none" justifyContent="center" alignItems="flex-start">
                <LogoSmallGroup style={{ marginLeft: 15 }} fill={userTypeThemeMap[userType].buttonBorderTopColor} />
              </Column>
              <Column flex="1 1 auto" style={{ borderRight: '1px solid #CDD2E4' }} />
              <Column
                flex="none"
                justifyContent="center"
                alignItems="flex-start"
                style={{ borderLeft: '1px solid #ffffff', borderRight: '1px solid #CDD2E4' }}
                padding="m">
                <P>Questionnaires</P>
              </Column>
            </Row>
          </Container>
        </Header>
      </HeaderContainer>
      <ChildrenContainer>
        <AppBackground>
          <Container size="m" style={{ width: 800 }}>
            <Padding size="l" style={{ flexDirection: 'column' }}>
              <QuestionnaireContent
                type={userType}
                booklet={+booklet as 1 | 2 | 3 | 4}
                onSubmit={handleSubmit}
                standalone={!!standalone}
                publicMode
                onNext={handleNext}
                onPrev={handlePrev}
                closeLabel="Close Tab"
                onClose={window.opener?.location.origin === window.location.origin ? () => window.close() : undefined}
              />
              <div className="footer">
                <SocialSkillsFooter />
              </div>
            </Padding>
          </Container>
        </AppBackground>
      </ChildrenContainer>
    </ThemeProvider>
  )
}

const PARTICIPANT_HEADER_HEIGHT = 65

const HeaderContainer = styled.section<{ previewing?: boolean }>`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
`
const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${PARTICIPANT_HEADER_HEIGHT}px;
  background-color: #edf2fa;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`

const ChildrenContainer = styled.div`
  width: 100%;
  padding-top: ${PARTICIPANT_HEADER_HEIGHT}px;
  & ${AppBackground} {
    min-height: calc(100vh - ${PARTICIPANT_HEADER_HEIGHT}px);
  }
  & .footer {
    & p,
    & a {
      color: white;
    }
  }
  ${QuestionWrapper} {
    thead th {
      top: 65px;
    }
  }
`
