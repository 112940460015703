import React, { useRef, useState, useEffect } from 'react'
import { Row, Column, Spacer, Button, TextInput } from 'common/ui'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { Field } from 'common/Field'
import { TextInputField } from 'common/FieldInputs'
import { ValidationErrorList } from 'common/ValidationErrorList'
import { EmotionometerSticker, EmotionometerDevice } from 'shared/e-telligence/types'
import { emotionometerDeviceLabels } from 'e-telligence/constants/typeValueMaps'
import { SymbolSelectModal } from 'common/SymbolSelectModal'
import {
  emotionometerBodyClueEmojis,
  emotionometerRelaxationGadgetEmojis,
  emotionometerSituationEmojis,
} from 'constants/emojiSets'

export const stickerEmojis: { [k in EmotionometerDevice]: string } = {
  situations: emotionometerSituationEmojis,
  bodyClues: emotionometerBodyClueEmojis,
  relaxationGadgets: emotionometerRelaxationGadgetEmojis,
}

export const validate = (data: Partial<EmotionometerSticker<EmotionometerDevice>>, errors: string[] = []) => {
  if (!data.title) errors.push(`No sticker title entered`)
  return errors
}

interface Props {
  initialValue?: Partial<EmotionometerSticker<EmotionometerDevice>>
  device: EmotionometerDevice
  onClose: () => void
  onSave: (sticker: EmotionometerSticker<EmotionometerDevice>) => void
}

export const StickerEditModal: React.FC<Props> = ({ device, initialValue, onSave, onClose }) => {
  const textInputRef = useRef<HTMLInputElement>(null)
  const symbolInputRef = useRef<HTMLInputElement>(null)
  const [emojiModalOpen, setEmojiModalOpen] = useState(false)
  const [sticker, setSticker] = useState<Partial<EmotionometerSticker<EmotionometerDevice>>>(
    () => initialValue || { id: 'sticker' + Date.now(), type: device }
  )

  const validationErrors = validate(sticker)
  const allowSubmit = validationErrors.length === 0

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (allowSubmit) onSave(sticker as EmotionometerSticker<typeof device>)
    return false
  }

  useEffect(() => {
    setTimeout(() => textInputRef.current && textInputRef.current.focus())
  }, [])

  return (
    <>
      <MobileMessageModal isOpen onRequestClose={onClose}>
        <form onSubmit={handleFormSubmit}>
          <Row>
            <Column>
              <Field label={`Enter a name for your ${emotionometerDeviceLabels[device].toLowerCase()}`}>
                <TextInputField //<string>
                  ref={textInputRef}
                  value={sticker.title || ''}
                  onChange={(title: string) => setSticker({ ...sticker, title })}
                  placeholder="50 Characters max."
                />
              </Field>
            </Column>
            <Spacer size="s" />
            <Column alignItems="flex-start">
              <Field label={`Add a symbol (optional)`}>
                <TextInput
                  ref={symbolInputRef}
                  value={sticker.symbol || ''}
                  style={{
                    fontSize: 28,
                    width: 60,
                    height: 50,
                    paddingTop: sticker.symbol ? 12 : 5,
                    textAlign: 'center',
                  }}
                  readOnly
                  placeholder="+"
                  onClick={() => setEmojiModalOpen(true)}
                />
              </Field>
            </Column>
          </Row>
          <Spacer size="m" />
          <Row flex="1 1 auto" />
          <Row justifyContent="flex-end">
            <Button type="button" size="xs" theme="white" onClick={onClose}>
              Cancel
            </Button>
            <Spacer size="xs" />
            <Button type="submit" size="xs" disabled={!allowSubmit}>
              Save
            </Button>
          </Row>
          <ValidationErrorList errors={validationErrors} style={{ textAlign: 'right', marginTop: 15 }} />
        </form>
      </MobileMessageModal>
      <SymbolSelectModal
        isOpen={emojiModalOpen}
        symbols={stickerEmojis[device]}
        // disabledSymbols={currentModule ? getUsedSymbols(currentModule, state.skills) : []}
        value={sticker.symbol || null}
        onChange={(symbol) => {
          setSticker({ ...sticker, symbol })
          setTimeout(() => setEmojiModalOpen(false), 150)
        }}
        onClose={() => {
          setEmojiModalOpen(false)
          setTimeout(() => symbolInputRef.current && symbolInputRef.current.blur())
        }}
      />
    </>
  )
}
