import React from 'react'
import styled from 'styled-components'

export const HeroImage: React.FC = () => {
  return (
    <HeroImageWrapper>
      <img src={require('../assets/home_banner.png')} alt="" />
    </HeroImageWrapper>
  )
}

const HeroImageWrapper = styled.div`
  width: 100%;

  img {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #cdd2e4;
  }
`
