import React, { Fragment } from 'react'
import { Panel, H2, P } from 'common/ui'
import { Spinner } from 'common/Spinner'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { processErrorList } from 'dashboards/utils/reducers'

interface Props {
  title?: string
  resources: { label: string; endpoint: ReturnType<typeof useEndpoint>[1] }[]
  extraErrorMessages?: (string | null)[]
}

export const ResourceLoadingStatusPanel: React.FC<Props> = ({ title, resources, extraErrorMessages = [] }) => {
  const loading = resources.reduce((bool, resource) => (bool ? bool : !resource.endpoint.loaded), false as boolean)
  const errorMessages = processErrorList([
    ...resources.map(({ endpoint }) => endpoint.errorMessage),
    ...extraErrorMessages,
  ])
  return (
    <Panel
      padding="l"
      style={{
        transition: 'opacity 1s linear',
        opacity: loading ? 1 : 0,
        minWidth: 300,
        boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.2)',
      }}>
      <H2 marginBottom="m">{title || 'Loading...'}</H2>
      <table>
        <tbody>
          {resources.map(({ label, endpoint }, i) => (
            <tr key={i}>
              <td style={{ width: 30, height: 30 }}>
                {endpoint.loading && <Spinner thickness={0.2} />}
                {endpoint.loaded && <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />}
                {endpoint.errorLoading && (
                  <img src={require('reporting/assets/images/dash-circle.svg')?.default} alt="" />
                )}
              </td>
              <td>{label}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {errorMessages.length > 0 && (
        <>
          <H2 marginTop={30} marginBottom={0}>
            Error
          </H2>
          <P>
            {errorMessages.map((str, i) => (
              <Fragment key={i}>
                {str}
                <br />
              </Fragment>
            ))}
          </P>
        </>
      )}
    </Panel>
  )
}
