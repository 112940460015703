import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { useParams } from 'react-router-dom'
import { blue, THEMES } from 'themes'

import { SessionEntityHydrated } from 'shared/dashboard/types'
import { SessionModule, SavedInputValue, END_SLIDE } from 'shared/session/types'
import { SessionStateProvider, useSessionState } from 'session/SessionState'
import { UserInputStateProvider, useUserInputState } from 'session/UserInputState'
import { ProgressionEventsStateProvider } from 'session/ProgressionEventsState'
import { InputContextProvider } from 'session/InputContext'
import { SET_SAVED_USER_INPUT_VALUES } from 'shared/session/actionTypes'

import { ModalContextProvider } from 'session/ModalContext'
import { Column, AppBackground } from 'common/ui'
import { RouteLeavePrompt } from 'common/RouteLeavePrompt'
import { SessionSlideHandler } from 'session/SessionSlide'
import { OfflineMessage } from 'session/OfflineMessage'
import { ParticipantUi } from 'session/ParticipantUi'
import { ResourceLoadingStatusPanel } from 'dashboards/common/ResourceLoadingStatusPanel'

import { usePortraitWidthTrigger } from 'app/OrientationPrompt'
import { useGenericUser } from 'app/useGenericUser'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useFocusedParticipantState } from './hooks/useProfileState'
import { useGlobalState } from 'app/GlobalState'

export const CadetMeeting: React.FC = () => {
  usePortraitWidthTrigger(680)
  return (
    <ProgressionEventsStateProvider>
      <SessionStateProvider>
        <UserInputStateProvider>
          <InputContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={blue}>
                <CadetMeetingInner />
                <OfflineMessage />
              </ThemeProvider>
            </ModalContextProvider>
          </InputContextProvider>
        </UserInputStateProvider>
      </SessionStateProvider>
    </ProgressionEventsStateProvider>
  )
}

const CadetMeetingInner: React.FC = () => {
  const { sessionUid } = useParams<{ sessionUid: string }>()
  const user = useGenericUser()
  const { dispatch: dispatchInputState } = useUserInputState()
  const { socket, setSessionData, setSessionProfile, setSessionUserType, isFacilitator } = useSessionState()
  const participantState = useFocusedParticipantState()
  const { themeIndex } = useGlobalState()

  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)
  const wasLoading = useRef<boolean>(true)
  const [session, sessionEndpoint] = useEndpoint<SessionEntityHydrated & { module: SessionModule }>(
    `/api/v1/sessions/${sessionUid}`
  )
  const [inputValues, inputValuesEndpoint] = useEndpoint<SavedInputValue<any>[]>(
    `/api/v1/session_input_values?session_uid=${sessionUid}`
  )
  // TODO: Might need to fetch the appropriate mentor entity via sas-api in order to verify they have the correct permissions to be here

  const invalidSessionType = !!session && session.type !== 'cadet'
  const loading = !sessionEndpoint.loaded || !inputValuesEndpoint.loaded || invalidSessionType

  const extraErrorMessages: (string | null)[] = [
    invalidSessionType ? `You're attempting to join a ${session?.type} meeting while accessing as a cadet.` : null,
  ]

  useEffect(() => {
    if (
      !loading &&
      wasLoading.current === true /* everything after this should be inferred... */ &&
      inputValues !== null &&
      session !== null
    ) {
      wasLoading.current = false

      ReactDOM.unstable_batchedUpdates(() => {
        if (session) setSessionData(session)
        setSessionUserType('agent')
        setSessionProfile({ ...user, userType: 'agent' })
      })

      const dispatchSocketActions = () => {
        dispatchInputState({
          user_uid: user.uid,
          role: user.userType,
          timestamp: Date.now(),
          type: SET_SAVED_USER_INPUT_VALUES,
          inputValues,
        })
      }

      if (socket) {
        if (!socket.connected) {
          console.log('😭😭😭 Socket not connected yet, waiting...')
          socket.on('connect', () => setTimeout(() => dispatchSocketActions()))
        } else {
          console.log('🙃🙃🙃 Socket is ready and waiting!')
          dispatchSocketActions()
        }
      }

      setTimeout(() => {
        console.log('\n✅ Launching session....\n\n')
        setFinishedDelay(true)
      }, 1000)
    }
  })

  if (loading || !finishedDelay)
    return (
      <AppBackground theme={!isFacilitator && user.userType !== 'mentor' ? THEMES[themeIndex] : blue}>
        <Column flex="auto" alignItems="center" justifyContent="center">
          <ResourceLoadingStatusPanel
            title="Loading meeting data..."
            resources={[
              { label: 'Meeting content', endpoint: sessionEndpoint },
              { label: 'Input and drawings', endpoint: inputValuesEndpoint },
            ]}
            extraErrorMessages={extraErrorMessages}
          />
        </Column>
      </AppBackground>
    )

  return (
    <>
      <ParticipantUi>
        <SessionSlideHandler />
      </ParticipantUi>
      {participantState && participantState.currentSlideUid !== END_SLIDE && (
        <RouteLeavePrompt
          when={!window.location.hostname.match(/localhost$/)}
          message={`Are you sure you want to leave this meeting before finishing?\n(Ignore this message if you're refreshing the page)`}
        />
      )}
    </>
  )
}
