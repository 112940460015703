import { Container } from 'common/ui'
import { TodoListItemRender } from 'dashboards/common/TodoListItemRender'
import { H1, H2, PrintGlobalStyle, Spacer } from 'print/ui-print'
import React from 'react'
import { TodoListItem } from 'shared/session/types'
import { createGlobalStyle } from 'styled-components'
import { mapToPrevNextShape } from 'utils/arrayUtils'
import { usePdfPayload } from 'utils/usePdfPayload'

export interface MentorChecklistPrintPayload {
  module: string
  date: string
  items: TodoListItem[]
}

export const mentorChecklistTemplateUrl = `${window.location.origin}/print/session/mentor-checklist`

// eslint-disable-next-line
const testPayload: MentorChecklistPrintPayload = {
  module: 'Parent - Module 1a',
  date: '1 Jan 2049',
  items: [
    { id: 1, uid: '1', order: 0, type: 'h1', text: 'H1 Heading 1' },
    { id: 2, uid: '2', order: 1, type: 'h2', text: 'H2 Heading 1' },
    { id: 3, uid: '3', order: 2, type: 'li', text: 'List Item 1' },
    { id: 4, uid: '4', order: 3, type: 'li', text: 'List Item 2' },
    { id: 5, uid: '5', order: 4, type: 'li', text: 'List Item 3' },
    { id: 6, uid: '6', order: 5, type: 'h1', text: 'H1 Heading 2' },
    { id: 7, uid: '7', order: 6, type: 'h2', text: 'H2 Heading 2' },
    { id: 8, uid: '8', order: 7, type: 'li', text: 'List Item 4' },
    { id: 9, uid: '9', order: 8, type: 'li', text: 'List Item 5' },
    { id: 10, uid: '10', order: 9, type: 'li', text: 'List Item 6' },
  ],
}

export const MentorChecklistPrint: React.FC = () => {
  const payload = usePdfPayload<MentorChecklistPrintPayload>()
  // const payload = testPayload

  if (!payload) return null

  return (
    <>
      <PrintGlobalStyle />
      <BonusGlobalStyle />
      <Container>
        <Spacer size="l" />
        <H1>To Do &mdash; {payload.module}</H1>
        <H2 style={{ marginTop: 10, fontSize: '1.2rem' }}>{payload.date}</H2>
        {payload.items.map(mapToPrevNextShape).map((props, index) => (
          <TodoListItemRender key={index} printMode {...{ ...props, index, items: payload.items }} />
        ))}
        <Spacer size="l" />
      </Container>
    </>
  )
}

const BonusGlobalStyle = createGlobalStyle`
  & .checklist-item {
    & label {
      margin-top: 0.45em;
    }
  }
  & h1 + .checklist-item,
  & h2 + .checklist-item {
    margin-top: 0.45em;
  }
`
