import React, { createContext, CSSProperties } from 'react'
import { useWindowSize } from 'utils/useWindowSize'

export const ScreenScalerContext = createContext({ zoom: 1, offsetX: 0, scale: 1, scaleWidth: 1 })

interface Props {
  minHeight?: number
  maxRatio?: number
  style?: CSSProperties
}

export const MIN_HEIGHT = 680
export const MAX_RATIO = 2.2

export function useScreenScaler(minHeight = MIN_HEIGHT, maxRatio = MAX_RATIO) {
  const window = useWindowSize()
  const scale = Math.min(window.height / minHeight, 1)
  const ratio = window.width / window.height
  const scaleWidth = Math.min(maxRatio / ratio, 1)
  const zoom = 1 / scale
  const offsetX = ((1 - scaleWidth) * zoom * window.width) / 2
  return { zoom, scale, scaleWidth, offsetX }
}

export const ScreenScaler: React.FC<Props> = ({
  minHeight = MIN_HEIGHT,
  maxRatio = MAX_RATIO,
  style = {},
  children,
}) => {
  const { zoom, scale, scaleWidth, offsetX } = useScreenScaler(minHeight, maxRatio)

  return (
    <div
      style={{
        position: 'relative',
        flex: 'auto',
        display: 'flex',
        flexDirection: 'inherit',
        background: 'black',
        overflow: 'hidden',
        ...style,
      }}>
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'inherit',
          width: `${zoom * scaleWidth * 100}%`,
          height: `${zoom * 100}%`,
          transform: `scale(${scale}) translateX(${offsetX}px)`,
          transformOrigin: '0 0',
          overflow: 'hidden',
        }}>
        <ScreenScalerContext.Provider value={{ zoom, offsetX, scale, scaleWidth }}>
          {children}
        </ScreenScalerContext.Provider>
      </div>
    </div>
  )
}
