import React, { CSSProperties } from 'react'
import { CharacterAvatar } from './CharacterAvatar'
import { characters } from './characters'
import { BoardGamePlayer } from 'shared/session/types'

export interface PlayerPieceProps {
  player: BoardGamePlayer
  style?: CSSProperties
}

export const PlayerPiece: React.FC<PlayerPieceProps> = ({ player, style = {} }) => {
  const character = characters.find(({ id }) => id === player.characterId)
  if (!character) return null
  return <CharacterAvatar style={style} character={character} interactive={false} customEmoji={player.customEmoji} />
}
