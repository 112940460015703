import { Hr, Row, P } from 'common/ui'
import { fontBold } from 'fonts'
import React from 'react'
import { SectionPropsBase } from 'shared/tipsheets/types'
import styled from 'styled-components'

interface Props extends SectionPropsBase {
  property: 'heading_divider_sections'
}

export const HeadingDividerSection: React.FC<Props> = ({ section }) => <HeadingDivider heading={section.heading} />

export const HeadingDivider: React.FC<{ heading: string }> = ({ heading }) => (
  <Wrapper>
    <Row alignItems="center">
      <Title>{heading}</Title>
    </Row>
    <Hr margin="m" />
  </Wrapper>
)

const Wrapper = styled.div`
  ${Hr} {
    border-color: ${p => p.theme.buttonBorderTopColor};
    margin-top: 10px;
  }
`

const Title = styled(P)`
  ${fontBold};
  margin: 0;
  color: #011a46;
  font-size: 1.2rem;
  line-height: 130%;
  letter-spacing: 0.03em;
`
