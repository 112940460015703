import { Scene, GameObjects } from 'phaser'

import { Theme } from 'themes'
import { getSquareImage } from '../shapeTextureGenerator'

export default class AudioVisualiser extends Scene {
  private analyser?: AnalyserNode
  private freqData?: Uint8Array

  private barImages: GameObjects.Image[] = []
  private bg?: GameObjects.Image
  private lastWidth: number = 0
  private bars: number = 0 // must be even number

  private connected: boolean = false
  private barWidth: number = 3 // px
  private pitchRangeUsage: number = 0.3 // bottom 30% ie. 4000Hz and below
  private edgeTaperAmount: number = 0.8 // higher is more, 2 is basically exponential
  private colors: number[] = []

  setAudioContext = (
    audioContext: AudioContext,
    audioSourceNode: GainNode | AudioDestinationNode | AudioBufferSourceNode
  ) => {
    if (this.analyser) delete this.analyser
    if (audioContext) {
      this.analyser = audioContext.createAnalyser()
      audioSourceNode.connect(this.analyser)
      this.freqData = new Uint8Array(this.analyser.frequencyBinCount)
    } else {
      this.freqData = new Uint8Array(1024)
    }
  }

  setConnected(connected: boolean) {
    this.connected = connected
  }

  setTheme(theme: Theme) {
    this.colors = [
      parseInt(theme.appBackgroundTopColor.replace('#', '0x')),
      parseInt(theme.appBackgroundBottomColor.replace('#', '0x')),
    ]
  }

  init() {
    this.initBars(this.bars)
  }

  initBars(numBars: number) {
    if (this.bars === numBars) return
    if (this.bars > numBars) {
      this.barImages.splice(numBars).forEach(image => image.destroy(true))
    } else {
      for (let i = this.bars; i < numBars; i++) {
        this.barImages[i] = getSquareImage(this, { size: 128, fill: 0x000000 }).setOrigin(0, 0)
        this.add.existing(this.barImages[i])
      }
    }
    this.bars = numBars
  }

  update() {
    if (!this.freqData) {
      // console.log('no freq data')
      return
    }
    if (this.connected) {
      if (this.analyser) {
        // get real frequency data
        this.analyser.getByteFrequencyData(this.freqData)
      } else {
        // generate fake frequency data
        const len = this.freqData.length
        for (let i = 0; i < len; i++) {
          this.freqData[i] +=
            this.freqData[i] === 0 ? Math.floor(Math.random() * 255) : Math.round(Math.random() * 10 - 5)
        }
      }
    } else {
      // ease out frequency data when audio stops
      const len = this.freqData.length
      for (let i = 0; i < len; i++) {
        this.freqData[i] = Math.floor(this.freqData[i] / 1.08)
      }
    }
    const width = this.game.scale.width
    const height = this.game.scale.height

    if (width !== this.lastWidth) {
      // calculate the bars based on the width, values must be even
      this.initBars(Math.round(width / this.barWidth / 2 / 2) * 2)
      this.lastWidth = width
    }

    const freqsPerBar = Math.floor((this.freqData.length * this.pitchRangeUsage) / (this.bars / 2))
    const barValues: number[] = []
    for (let i = 0; i < this.bars / 2 - 1; i++) {
      barValues[i] =
        this.freqData.slice(i * freqsPerBar, (i + 1) * freqsPerBar).reduce((val, sum) => sum + val) / freqsPerBar
    }

    const barSpacing = width / this.bars
    for (let i = 0; i < this.bars; i++) {
      const xPercent = i / this.bars
      const valuePercent = barValues[i > this.bars / 2 ? i - this.bars / 2 : this.bars / 2 - i] / 255

      let barHeight = valuePercent * height * 0.85
      barHeight *= Math.pow(1 - Math.abs(xPercent - 0.5) * 2, this.edgeTaperAmount)

      this.barImages[i]
        .setPosition(barSpacing * i, (height - barHeight) / 2)
        .setScale(barSpacing / 2 / 128, barHeight / 128)
        .setTintFill(this.colors[0], this.colors[0], this.colors[1], this.colors[1])
    }
  }
}

if (!Uint8Array.prototype.slice) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Uint8Array.prototype, 'slice', {
    value: function(begin?: number, end?: number) {
      return new Uint8Array(Array.prototype.slice.call(this, begin, end))
    },
  })
}

// Production steps of ECMA-262, Edition 5, 15.4.4.21
// Reference: http://es5.github.io/#x15.4.4.21
// https://tc39.github.io/ecma262/#sec-array.prototype.reduce
if (!Uint8Array.prototype.reduce) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Uint8Array.prototype, 'reduce', {
    value: function(callback: any /*, initialValue*/) {
      if (this === null) {
        throw new TypeError('Uint8Array.prototype.reduce called on null or undefined')
      }
      if (typeof callback !== 'function') {
        throw new TypeError(callback + ' is not a function')
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // Steps 3, 4, 5, 6, 7
      var k = 0
      var value

      if (arguments.length >= 2) {
        value = arguments[1]
      } else {
        while (k < len && !(k in o)) {
          k++
        }

        // 3. If len is 0 and initialValue is not present,
        //    throw a TypeError exception.
        if (k >= len) {
          throw new TypeError('Reduce of empty array with no initial value')
        }
        value = o[k++]
      }

      // 8. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kPresent be ? HasProperty(O, Pk).
        // c. If kPresent is true, then
        //    i.  Let kValue be ? Get(O, Pk).
        //    ii. Let accumulator be ? Call(
        //          callbackfn, undefined,
        //          « accumulator, kValue, k, O »).
        if (k in o) {
          value = callback(value, o[k], k, o)
        }

        // d. Increase k by 1.
        k++
      }

      // 9. Return accumulator.
      return value
    },
  })
}
