/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import padStart from 'lodash/padStart'
import { fontLight } from 'fonts'
import { Button, Input, Row, Spacer } from 'common/ui'
import { SVG } from 'common/SVG'
import { useStopWatch } from 'utils/useStopWatch'

export const Timer: React.FC<{ autostart?: boolean; alarmInterval?: number }> = ({
  autostart = false,
  alarmInterval,
}) => {
  const timer = useStopWatch(alarmInterval, autostart)
  const [seconds, setSeconds] = useState<number>(0)
  const [running, setRunning] = useState<boolean>(() => autostart)

  useEffect(() => {
    const tickCallback = (currentSeconds: number) => {
      if (Math.floor(currentSeconds) !== seconds) setSeconds(Math.floor(currentSeconds))
    }
    const handleRunning = (running: boolean) => {
      setRunning(running)
    }
    timer.on('running', handleRunning)
    timer.on('timeupdate', tickCallback)
    return () => {
      timer.off('running', handleRunning)
      timer.off('timeupdate', tickCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer])

  return (
    <Row>
      <TimerDisplay
        disabled
        value={`${padStart(String(Math.floor(seconds / 60)), 2, '0')}:${padStart(String(seconds % 60), 2, '0')}`}
        theme="purple"
        inputSize="s"
      />
      <Spacer size="s" />
      <Button size="s" theme={running ? 'gray' : 'purple'} onClick={() => timer.toggleStartStop()}>
        <SVG viewboxSize={22} size={22}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.91421 2.06067L3.5 0.646454L0 4.14645L1.41421 5.56067L4.91421 2.06067ZM17.9142 0.646454L21.4142 4.14645L20 5.56067L16.5 2.06067L17.9142 0.646454ZM10.7071 21.3536C5.18426 21.3536 0.707107 16.8764 0.707107 11.3536C0.707107 5.83071 5.18426 1.35356 10.7071 1.35356C16.23 1.35356 20.7071 5.83071 20.7071 11.3536C20.7071 16.8764 16.23 21.3536 10.7071 21.3536ZM10.7071 19.3536C15.1254 19.3536 18.7071 15.7718 18.7071 11.3536C18.7071 6.93528 15.1254 3.35356 10.7071 3.35356C6.28883 3.35356 2.70711 6.93528 2.70711 11.3536C2.70711 15.7718 6.28883 19.3536 10.7071 19.3536ZM15.7071 10.3536H11.7071V5.35356H9.70711V12.3536H15.7071V10.3536Z"
            fill="white"
          />
        </SVG>
      </Button>
    </Row>
  )
}

const TimerDisplay = styled(Input)`
  ${fontLight}
  flex: 1 1 auto;
  width: 80px;
  font-size: 18px;
  text-align: center;
`
