import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { locations } from './constants/locations'
import { modules as presetModules } from './constants/modules'

import { Row, Padding, P, H3, Button } from 'common/ui'
import { baseUrl, Navigation } from './common/Navigation'
import { SkillTrackerPanel } from './common/SkillTrackerPanel'

import { useUserState } from 'app/UserState'
import { getConfirmedModuleSkills, getSkillUsesForSkills, hasBonusScore } from './reducers'
import { useSkillTrackerState } from './SkillTrackerState'
import { fontBold } from 'fonts'
import { Emoji } from 'common/Emoji'
import { useMedia } from 'utils/useMedia'
import { sortByKey } from 'utils/sortUtils'

export const ProgressScreen: React.FC = () => {
  const history = useHistory()
  const { state } = useSkillTrackerState()
  const { getUserScopes } = useUserState()

  const portrait = useMedia('(orientation: portrait) and (max-width: 420px)')
  const isAdult = getUserScopes().indexOf('mentor') >= 0
  const showBonusScoreColumn = hasBonusScore(state)
  const bonusSkillUses = state.skillUses.filter(({ bonus }) => bonus)
  const activeModule = state.modules.find(({ id }) => id === state.activeModuleId)
  const modules = state.modules
    .filter(module => module.completed || module.started || module.id === state.activeModuleId)
    .sort(sortByKey('id'))

  return (
    <SkillTrackerPanel>
      <Navigation title={isAdult ? 'View Progress' : 'My Progress'} />
      <Row flex="1 1 auto" style={{ overflow: 'auto' }}>
        <Table cellSpacing={0} cellPadding={2}>
          <thead>
            <tr>
              <th colSpan={3}>
                <Padding size="s">
                  {locations.map((location, index) => (
                    <LegendItem key={index}>
                      <ColorBlock color={location.color} />
                      <span>{location.label}</span>
                    </LegendItem>
                  ))}
                </Padding>
              </th>
              <th style={{ width: portrait ? 40 : 70 }}>
                <H3
                  style={{
                    fontWeight: 500,
                    letterSpacing: portrait ? 0 : undefined,
                    textTransform: 'initial',
                    fontSize: portrait ? 12 : 14,
                  }}>
                  Weekly Score:
                </H3>
              </th>
              {showBonusScoreColumn && (
                <th style={{ width: portrait ? 30 : 60, fontSize: portrait ? 18 : 24 }}>
                  <Emoji children="⭐️" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {modules.map((module, i) => {
              const presetModule = module.presetModuleId
                ? presetModules.find(({ id }) => id === module.presetModuleId)
                : undefined

              const isActiveModule = activeModule ? activeModule.id === module.id && !activeModule.completed : false
              const skills = getConfirmedModuleSkills(module.id, state)
              const allModuleSkillUses = getSkillUsesForSkills(state.skillUses, skills)
              const skillUses = allModuleSkillUses.filter(skillUse => skillUse.approved && !skillUse.bonus)
              const bonusSkillUses = allModuleSkillUses.filter(skillUse => skillUse.bonus)
              const totalDailyTarget = skills.reduce((acc, skill) => acc + (skill.dailyTarget || 0), 0)
              const totalModuleTarget = totalDailyTarget * (module?.duration || 7)
              // const datesUsed = _uniq(skillUses.map(({ date }) => date))
              // const datesUsedTarget = datesUsed.length * totalDailyTarget
              return (
                <tr key={i} style={{ backgroundColor: isActiveModule ? '#c4cae6' : 'transparent' }}>
                  <td>
                    <Padding size={portrait ? 'xs' : 's'}>
                      <P style={{ margin: 0, fontSize: portrait ? 12 : 14 }}>
                        {presetModule
                          ? `Module ${presetModule.title ||
                              (state.workflow === 'whole' ? presetModule.module : presetModule.id)}`
                          : module.title}
                      </P>
                    </Padding>
                  </td>
                  <td
                    style={{ textAlign: 'left', height: isActiveModule ? 50 : 'auto' }}
                    colSpan={isActiveModule || !isAdult ? 2 : 1}>
                    {!isActiveModule ? (
                      <Bar
                        style={{
                          width: '100%', // isActiveModule || skillUses.length > 0 ? `calc(${(datesUsedTarget / totalModuleTarget) * 100}% - 2px)` : '100%',
                          maxWidth: portrait ? 'calc(100vw - 176px)' : undefined,
                        }}>
                        {locations.map((location, i) => {
                          const locationSkillUses = skillUses.filter(skillUse => skillUse.location === location.value)
                          return (
                            <BarSegment
                              key={i}
                              index={i}
                              color={location.color}
                              value={locationSkillUses.length}
                              total={totalModuleTarget}
                              showNumber
                            />
                          )
                        })}
                      </Bar>
                    ) : (
                      <>
                        <H3>In progress..</H3>
                        <Button
                          size="xs"
                          marginTop="xs"
                          onClick={() => history.push(baseUrl + '/practise')}
                          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                          Practise Log
                        </Button>
                      </>
                    )}
                  </td>
                  {isAdult && !isActiveModule && (
                    <td style={{ width: !isActiveModule ? (portrait ? 33 : 60) : 0 }}>
                      <Button
                        size="xs"
                        onClick={() => history.push(`${baseUrl}/practise/${module.id}`)}
                        children={portrait ? '→' : 'View'}
                      />
                    </td>
                  )}
                  <td>
                    <P style={{ margin: 0, fontSize: portrait ? 12 : 16, fontWeight: 800, textAlign: 'center' }}>
                      {skillUses.length || ''}
                      {isActiveModule || !skillUses.length ? '' : `/${totalModuleTarget}`}
                    </P>
                  </td>
                  {showBonusScoreColumn && (
                    <td>
                      <P style={{ margin: 0, fontSize: portrait ? 12 : 16, fontWeight: 800, textAlign: 'center' }}>
                        {bonusSkillUses.length || ''}
                      </P>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
          {showBonusScoreColumn && (
            <tfoot>
              <tr>
                <td colSpan={4} />
                <td>
                  <div style={{ fontWeight: 'bold', fontSize: portrait ? 8 : 10 }}>TOTAL</div>
                  <P style={{ margin: 0, fontSize: 16, fontWeight: 800, textAlign: 'center' }}>
                    {bonusSkillUses.length}
                  </P>
                </td>
              </tr>
            </tfoot>
          )}
        </Table>
      </Row>
    </SkillTrackerPanel>
  )
}

const LegendItem = styled(Row)`
  margin-right: 20px;
  align-items: center;
  font-size: 12px;
  @media (orientation: portrait) and (max-width: 420px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: -5px;
    & div {
      margin-left: 0;
      margin-bottom: 2px;
    }
    & span {
      font-weight: 400;
      font-size: 10px;
    }
  }
`

const ColorBlock = styled.div<{ color: string; borderColor?: string; size?: number }>`
  display: inline-block;
  vertical-align: middle;
  width: ${p => p.size || 20}px;
  height: ${p => p.size || 20}px;
  border: 1px solid ${p => p.borderColor || 'black'};
  background-color: ${p => p.color};
  margin-right: 5px;
`

const Table = styled.table`
  border: 0;
  width: 100%;

  thead th,
  tbody tr:last-child td {
    border-bottom: 1px solid #abb4db;
  }

  thead th,
  tbody tr:first-child td,
  tfoot tr:first-child td {
    border-top: 1px solid white;
  }

  tbody td,
  tfoot td {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  tbody tr:first-child td {
    padding-top: 10px;
  }

  tbody tr:last-child td {
    padding-bottom: 10px;
  }

  tbody tr td:first-child {
    font-size: 14px;
    width: 90px;
  }

  tbody tr td:not(:last-child) {
    border-right: 1px solid white;
  }

  tbody tr td:not(:first-child) {
    border-left: 1px solid #abb4db;
  }

  tbody tr td:nth-child(2) {
    padding-left: 10px;
    padding-right: 10px;
  }

  th,
  td,
  h3 {
    text-align: center;
  }

  @media (orientation: portrait) and (max-width: 420px) {
    tbody tr td:first-child {
      width: 48px;
    }
    tbody tr td:nth-child(2) {
      padding-left: 5px;
      padding-right: 5px;
    }
    tbody tr:first-child td {
      padding-top: 5px;
    }
    tbody tr:last-child td {
      padding-bottom: 5px;
    }
  }
`

const Bar = styled.div`
  display: inline-block;
  border: 1px solid black;
  width: 100%;
  height: 40px;
  vertical-align: middle;
  background-color: white;
`

const BarSegment = styled.div<{ index?: number; color?: string; showNumber?: boolean; value: number; total: number }>`
  position: relative;
  display: inline-block;
  width: ${p => (p.value / p.total) * 100}%;
  height: 100%;
  overflow: hidden;
  background-color: ${p =>
    p.color ||
    (p.index !== undefined ? p.theme.emotionometerColors[p.index % p.theme.emotionometerColors.length] : 'black')};

  &::after {
    ${p => (p.showNumber && p.value / p.total >= 0.05 ? `content: '${p.value}';` : '')}
    position: absolute;
    top: 0;
    right: 2px;
    ${fontBold}
    color: white;
    font-size: 11px;
    line-height: 35px;
  }
`
