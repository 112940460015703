import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Container, CUT_BL, CUT_BR, CUT_TL, CUT_TR, H1, Row, TAB_B_L, TAB_T_L } from 'common/ui'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { EmotionometerActivityInner } from 'session/sections/custom/emotionometer/EmotionometerActivity'

export const EmotionometerModal: React.FC<{
  onClose: () => void
  anxietyUnlocked: boolean
  angerUnlocked: boolean
  autoSelect?: 'anger' | 'anxiety'
  mentorMode?: boolean
}> = ({ onClose, anxietyUnlocked, angerUnlocked, autoSelect, mentorMode }) => {
  const [selectIsOpen, setSelectIsOpen] = useState<boolean>(true)
  const [emotion, setEmotion] = useState<'anger' | 'anxiety' | null>(autoSelect || null)

  const delayedSelectOnClose = () => {
    setSelectIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <>
      <MobileModalPanel
        zIndex={1200}
        isOpen={selectIsOpen}
        onRequestClose={delayedSelectOnClose}
        panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}
        panelStyle={{ opacity: emotion ? 0 : 1 }}>
        <PadContainer>
          <Row justifyContent="space-between" alignItems="center" marginBottom={20}>
            <H1>Emotionometers</H1>
            <Button onClick={delayedSelectOnClose} children="Exit" />
          </Row>
          <Row justifyContent="space-between">
            <ImageOption
              src={require('../assets/gadget-tray/anger-emotionometer.png')}
              alt=""
              interactive={angerUnlocked}
              onClick={() => setEmotion('anger')}
            />
            <ImageOption
              src={require('../assets/gadget-tray/anxiety-emotionometer.png')}
              alt=""
              interactive={anxietyUnlocked}
              onClick={() => setEmotion('anxiety')}
            />
          </Row>
        </PadContainer>
      </MobileModalPanel>
      {emotion && (
        <ActivityModal
          mentorMode={mentorMode}
          emotion={emotion}
          onClose={!!autoSelect ? delayedSelectOnClose : () => setEmotion(null)}
        />
      )}
    </>
  )
}

const ActivityModal: React.FC<{ emotion: 'anger' | 'anxiety'; onClose: () => void; mentorMode?: boolean }> = ({
  emotion,
  onClose,
  mentorMode,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <MobileModalPanel
      zIndex={1300}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={mentorMode ? { flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] } : {}}
      panelStyle={
        mentorMode
          ? { minWidth: '100vw', minHeight: '100vh' }
          : { background: 'none', boxShadow: 'none', borderBottom: 'none' }
      }>
      <PadContainer style={mentorMode ? { padding: '30px 10px' } : {}}>
        <Row justifyContent={mentorMode ? 'flex-end' : 'space-between'} alignItems="center" marginBottom={20}>
          {!mentorMode && <H1 style={{ color: '#fff' }}>Emotionometers</H1>}
          <Button onClick={delayedOnClose} children="Exit" size={mentorMode ? 's' : 'm'} />
        </Row>
        <EmotionometerActivityInner
          activityTitle={emotion}
          viewAllDevices
          hideStickerTray
          emotion={emotion}
          activeEmotionRangeIndexes={[true, true, true]}
          readonly
        />
      </PadContainer>
    </MobileModalPanel>
  )
}

const PadContainer = styled(Container)`
  padding: 30px;

  .emotionometer-top-panel {
    margin-bottom: 30px;
  }
`

const ImageOption = styled.img<{ interactive: boolean }>`
  width: calc(50% - 10px);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 10px;
  pointer-events: ${p => (p.interactive ? 'auto' : 'none')};
  opacity: ${p => (p.interactive ? 1 : 0.5)};

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }
`
