import {
  EmotionometerType,
  EmotionOption,
  GadgetUseOption,
  EmotionRangeOption,
  EmotionometerDevice,
} from 'shared/e-telligence/types'
import { EmotionometerColors, angryColors, anxiousColors, sadColors } from 'common/Emotionometer'

export const emotionLabels: { [k in EmotionometerType]: string } = {
  anger: 'Anger',
  anxiety: 'Anxiety',
  sadness: 'Sadness',
}

export const emotionOptions: EmotionOption[] = [
  { value: 'anger', label: 'Anger' },
  { value: 'anxiety', label: 'Anxiety' },
  { value: 'sadness', label: 'Sadness' },
]

export const emotionAdjectives: { [k in EmotionometerType]: string } = {
  anger: 'angry',
  anxiety: 'anxious',
  sadness: 'sad',
}

export const gadgetUseLocationOptions: GadgetUseOption[] = [
  { value: 'home', label: 'Home', selectable: true },
  { value: 'school', label: 'School', selectable: true },
  { value: 'out', label: 'Out', selectable: true },
  { value: 'out_appropriate', label: 'Out', selectable: false },
  { value: 'anywhere', label: 'Anywhere', selectable: true },
]

/**
 * The order of this array is used to determine the next route to go in EmotionometerStickerEdit
 */
export const emotionRangeOptions: EmotionRangeOption[] = [
  { index: 2, value: '8-10', label: 'Very' },
  { index: 0, value: '1-3', label: 'A little bit' },
  { index: 1, value: '4-7', label: 'Moderately' },
]

export const emotionRangeOptionsUnordered: EmotionRangeOption[] = [...emotionRangeOptions].sort(
  (a, b) => a.index - b.index
)

export const emotionRangeDistribution: number[] = [0, 0, 0, 1, 1, 1, 1, 2, 2, 2]
export const emotionRangeValuesUnordered = emotionRangeOptions.map(({ value }) => value)
export const emotionRangeValues = [...emotionRangeOptions].sort((a, b) => a.index - b.index).map(({ value }) => value)

export const emotionometerDeviceLabels: { [k in EmotionometerDevice]: string } = {
  situations: 'Situation',
  bodyClues: 'Body Clue',
  relaxationGadgets: 'Relaxation Gadget',
}

export const emotionometerDevicePluralLabels: { [k in EmotionometerDevice]: string } = {
  situations: 'Situations',
  bodyClues: 'Body Clues',
  relaxationGadgets: 'Relaxation Gadgets',
}

export const emotionometerTypeValues = Object.keys(emotionometerDeviceLabels) as EmotionometerDevice[]

export const emotionometerColors: { [key in EmotionometerType]: EmotionometerColors } = {
  anger: angryColors,
  anxiety: anxiousColors,
  sadness: sadColors,
}
