import { fontBold } from 'fonts'
import React from 'react'
import styled from 'styled-components'

export const FriendshipAward: React.FC<{ awardWinner: string }> = ({ awardWinner }) => (
  <>
    <CurvedTextWrapper>
      <svg viewBox="0 0 500 200">
        <path id="curve" fill="transparent" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" />
        <text width="500">
          <textPath xlinkHref="#curve">Friendship Award</textPath>
        </text>
      </svg>
      <img src={require('../../assets/friendship-award.png')} alt="" />
      <p>{awardWinner}</p>
    </CurvedTextWrapper>
  </>
)

const CurvedTextWrapper = styled.div`
  text-align: center;
  border: 1px solid #bdc3e0;
  box-shadow: 0px 1px 4px #fff;
  border-radius: 10px;

  svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
    width: 80%;
    margin: 0 auto;
    filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 0px #b8c6fc);
  }

  text {
    ${fontBold};
    fill: #cdd2e4;
    text-transform: uppercase;
    font-size: 1.9em;
  }

  img {
    margin-top: 75px;
  }

  p {
    position: absolute;
    top: 65.3%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
    color: #c9803f;
  }
`
