import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { fontBold } from 'fonts'

import { Panel, Column, Spacer, Padding, H3, RowWrapPortrait, Row } from 'common/ui'
import { HeaderTemplate } from './common/HeaderTemplate'
import { CardHolderInner as ETelligenceCardHolderInner } from 'e-telligence/CardHolder'

import { baseUrl } from './GadgetPack'
import { useGadgetPackState } from './GadgetPackState'
import { friendometerIsEmpty } from './Friendometer'
import { bionicPowersIsEmpty } from './BionicPowers'

export const CardHolderScreen: React.FC = () => {
  const history = useHistory()
  const { state, dispatch } = useGadgetPackState()
  return (
    <HeaderTemplate title="Card Holder" onExit={() => history.push(baseUrl)}>
      <WrapperPanel>
        <ETelligenceCardHolderInner baseUrl={baseUrl + '/card-holder'} state={state} dispatch={dispatch} />
        <CardHolderInner />
      </WrapperPanel>
    </HeaderTemplate>
  )
}

export const CardHolderInner: React.FC = () => {
  const history = useHistory()
  const {
    state: { friendometer, bionicPowers },
  } = useGadgetPackState()
  console.log({ friendometer, bionicPowers })
  return (
    <>
      <Padding size="m" style={{ marginTop: -10, borderTop: '1px solid #abb4db' }}>
        <RowWrapPortrait>
          <Column flex="1 1 0px">
            <H3>Friendometer</H3>
            <Spacer size="s" />
            <CardHolderTile
              empty={friendometerIsEmpty(friendometer)}
              placeholder="Calibrate Friendometer +"
              image={require('./assets/gadgets_friendometer_slab.png')}
              onSelect={() => history.push(baseUrl + '/card-holder/friendometer', { skipCardHolder: false })}
              onEdit={() => history.push(baseUrl + '/card-holder/friendometer', { skipCardHolder: false })}
            />
          </Column>
          <Spacer size="s" flex="none" />
          <Column flex="1 1 0px">
            <H3>Bionic Powers</H3>
            <Spacer size="s" />
            <CardHolderTile
              empty={bionicPowersIsEmpty(bionicPowers)}
              placeholder="Calibrate Bionic Powers +"
              image={require('./assets/gadgets_bionicPowers_slab.png')}
              onSelect={() => history.push(baseUrl + '/card-holder/bionic-powers', { skipCardHolder: false })}
              onEdit={() => history.push(baseUrl + '/card-holder/bionic-powers', { skipCardHolder: false })}
            />
          </Column>
        </RowWrapPortrait>
      </Padding>
    </>
  )
}

export const CardHolderTile: React.FC<{
  empty?: boolean
  image?: any
  placeholder?: string
  onSelect: () => void
  onEdit: () => void
}> = ({ empty, image, placeholder, onSelect, onEdit }) => {
  return (
    <TileDiv empty={empty} onClick={empty ? onSelect : onEdit} image={empty ? undefined : image}>
      {empty && <label>{placeholder || 'Create Emotionometer +'}</label>}
    </TileDiv>
  )
}

const WrapperPanel = styled(Panel)`
  & .cardsRow {
    & > ${Column} > ${Row} > ${Column} {
      padding-bottom: 10px;
    }
  }
`

const TileDiv = styled.div<{ empty?: boolean; image?: any }>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 200px;
  height: 0;
  padding-top: 32%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${p => (p.empty ? p.theme.thinOutlineColor : p.theme.thinOutlineActiveColor)};
  border-radius: 5px;
  cursor: pointer;

  color: ${p => (p.empty ? 'inherit' : 'white')};
  background-color: ${p => (p.empty ? 'white' : 'black')};
  ${p => (p.image ? `background-image: url('${p.image}');` : '')}
  background-size: cover;

  text-align: center;
  font-size: 1rem;

  & label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${fontBold}
    color: ${p => (p.empty ? p.theme.thinOutlineColor : 'inherit')};
    pointer-events: none;
  }
`
