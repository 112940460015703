import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { useHistory, useLocation } from 'react-router'
import { fontBlack, fontHeavy } from 'fonts'

import { Activity } from 'types'

import PDA from 'common/PDA'
import { GameModeIndicator } from 'app/GameModeIndicator'
import { Padding, Absolute, Center, Column, Row, Button, Spacer, P, Panel } from 'common/ui'
import { FadeFromBlack, FadeToBlack } from 'common/FadeTransitions'
import { ScreenScaler } from 'common/ScreenScaler'
import { ShortcutMenu } from 'common/ShortcutMenu'

import { ControlRoomPhone } from './ControlRoomPhone'
import { MeetingJoinButton } from './MeetingJoinButton'
import { ArdonNarrate } from 'utils/useArdon'
import { preloadElevatorAssets } from './Elevator'

import { cleanRouteState } from 'utils/routeUtils'
import { preloadImage } from 'utils/preloadUtils'
import { isPhone } from 'utils/deviceUtils'
import { intersperse } from 'utils/intersperse'
import { useUserState } from 'app/UserState'
import { useMeasure } from 'utils/useMeasure'
import { usePda } from 'utils/usePda'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useOnline } from 'utils/useOnline'

interface Props {
  levels: Activity[]
  activities: Activity[]
}

let preloaded = false

function preloadAssets() {
  if (isPhone()) {
    return Promise.resolve()
  }
  return Promise.all([
    preloadImage(liftOpeningSequence),
    preloadImage(liftOpeningSequence),
    preloadImage(controlRoomBackground),
    preloadImage(controlRoomTop),
    preloadImage(controlRoomBottom),
    preloadImage(screenBackgroundTop),
    preloadImage(screenBackgroundBottom),
    preloadImage(controlRoomLogo),
    preloadElevatorAssets(),
  ])
}

export const ControlRoom: React.FC<Props> = props => {
  if (isPhone()) {
    return <ControlRoomPhone />
  }
  return <ControlRoomGame {...props} />
}

const ControlRoomGame: React.FC<Props> = ({ levels, activities }) => {
  if (!preloaded) {
    preloaded = true
    throw preloadAssets()
  }

  const location = useLocation()
  const history = useHistory()
  const {
    hasPermissionFor,
    hasAttempted,
    openAccess,
    gameMode,
    activityAttempts,
    enableSkillTracker,
    enableETelligence,
    enableGadgetPack,
    enableSessions,
  } = useUserState()
  const [liftOpen, setLiftOpen] = useState(!!(location.state && location.state.fromElevator))
  const [fadeToBlack, setFadeToBlack] = useState(false)
  const online = useOnline()
  usePda()
  useDisableOrientationLock()

  function animateToLevel(level: Activity) {
    setLiftOpen(true)
    setTimeout(() => setFadeToBlack(true), 600)
    setTimeout(
      () => level.path && history.push(level.path, cleanRouteState({ ...(location.state || {}), fadeFromBlack: true })),
      1000
    )
  }

  useEffect(() => {
    setTimeout(() => setLiftOpen(false), 300)
  }, [])

  const nextLevel = getNextLevel()

  function getNextLevel() {
    if (openAccess || gameMode === 'SAS-SA') return
    for (let i = activities.length - 1; i >= 0; i--) {
      const activity = activities[i]
      if (!hasAttempted(activity) && hasPermissionFor(activity)) {
        return activity.level
      }
    }
  }

  const playIntro = !openAccess && activityAttempts.length === 0

  const numButtons = [true, enableETelligence, enableGadgetPack, enableSkillTracker, true].filter(_ => _).length

  return (
    <ScreenScaler>
      <ControlRoomBackground>
        <Lift open={liftOpen} />
        <Padding>
          <Absolute cover>
            <Row flex>
              <Spacer flex="1 1 0px" />
              <Center flex="0 0 485px">
                <Column>
                  <Screen style={{ marginTop: '-7%' }}>
                    <ScreenInner padding paddingLeft="l" paddingRight="l">
                      <Center padding>
                        <img src={controlRoomLogo} width="190" height="33" alt="Secret Agent Society Computer Game" />
                      </Center>
                      <Spacer />
                      {!online && (
                        <>
                          <P>Note: you're currently offline so some resources are unavailable</P>
                          <Spacer />
                        </>
                      )}
                      <Row>
                        {levels
                          .map((level, i) => (
                            <LevelButton
                              key={i}
                              onClick={() => animateToLevel(level)}
                              disabled={!hasPermissionFor(level) || !online}
                              flashing={nextLevel === level.level && online}
                              autoFocus={nextLevel === level.level}>
                              Level <LevelButtonNumber>{level.level}</LevelButtonNumber>
                            </LevelButton>
                          ))
                          .reduce(
                            intersperse(i => <Spacer key={`s${i}`} flex />),
                            []
                          )}
                      </Row>
                      <Spacer />

                      {/*
                      Final order will be:
                      - Mission Journal
                      - Skill Tracker 
                      - E-Telligence
                      - Scene Generator
                      */}
                      <Row flex flexWrap={numButtons > 3} justifyContent="space-between" margin={-5}>
                        <Button
                          flex={`1 1 ${numButtons > 3 ? '47%' : 'auto'}`}
                          margin="xs"
                          size="s"
                          disabled={!online}
                          onClick={() => history.push('/journal', cleanRouteState(location.state))}
                          children={gameMode === 'SAS-SG-D' ? 'cadet journals' : 'Mission Journal'}
                        />
                        {enableETelligence && (
                          <Button
                            flex={`1 1 ${numButtons > 3 ? '47%' : 'auto'}`}
                            margin="xs"
                            size="s"
                            onClick={() => history.push('/e-telligence', cleanRouteState(location.state))}>
                            <span style={{ whiteSpace: 'nowrap' }}>E-Telligence</span>
                          </Button>
                        )}
                        {enableGadgetPack && (
                          <Button
                            flex={`1 1 ${numButtons > 3 ? '47%' : 'auto'}`}
                            margin="xs"
                            size="s"
                            onClick={() => history.push('/gadget-pack', cleanRouteState(location.state))}
                            children="Gadget Pack"
                          />
                        )}
                        {enableSkillTracker && (
                          <Button
                            flex={`1 1 ${numButtons > 3 ? '47%' : 'auto'}`}
                            margin="xs"
                            size="s"
                            disabled={openAccess}
                            onClick={() => history.push('/skill-tracker', cleanRouteState(location.state))}>
                            Skill Tracker
                          </Button>
                        )}
                        <Button
                          flex={`1 1 ${numButtons > 3 ? '47%' : 'auto'}`}
                          margin="xs"
                          size="s"
                          onClick={() => history.push('/scene-generator', cleanRouteState(location.state))}
                          disabled={!online}>
                          Scene Generator
                        </Button>
                      </Row>
                    </ScreenInner>
                  </Screen>
                  {enableSessions && (
                    <>
                      <Spacer size="m" />
                      <Panel padding="m">
                        <MeetingJoinButton />
                      </Panel>
                    </>
                  )}
                </Column>
              </Center>
              <Column flex="1 1 0px" alignItems="flex-end">
                <Padding>
                  <Button onClick={() => history.replace('/')}>Quit</Button>
                  <Spacer flex />
                  <PDA />
                </Padding>
              </Column>
            </Row>
          </Absolute>
        </Padding>
        <GameModeIndicator />
        <ShortcutMenu />
        <FadeToBlack wait={!fadeToBlack} transitionTime={250} />
        {location.state && location.state.fromElevator && <FadeFromBlack transitionTime={250} />}
        {playIntro && <ArdonNarrate src={require('./assets/audio/0.3.0.1.mp3')} />}
      </ControlRoomBackground>
    </ScreenScaler>
  )
}

const BG_RATIO = 2690 / 3507
const LIFT_X = 74 / 3507
const LIFT_Y = 448 / 2690
const LIFT_W = 478 / 3507
const LIFT_H = 641 / 2690

const liftOpeningSequence = require('./assets/image/lift-opening-sequence.png')
const controlRoomBackground = require('./assets/image/Control-Room_v04.jpg')
const controlRoomTop = require('./assets/image/Control-Room_v04-Top.jpg')
const controlRoomBottom = require('./assets/image/Control-Room-Bottom.jpg')
const controlRoomLogo = require('./assets/image/control-room-logo.png')
const screenBackgroundTop = require('./assets/image/level-select-top.png')
const screenBackgroundBottom = require('./assets/image/level-select-bottom.png')

const Lift: React.FC<{ open: boolean }> = ({ open }) => {
  const [measureRef, { width, height }] = useMeasure()
  const bgHeight = (width / height) * BG_RATIO * height
  const y = LIFT_Y * bgHeight + (height - bgHeight) / 2
  const { x } = useSpring<{ x: number }>({ from: { x: open ? 100 : 0 }, x: open ? 100 : 0, config: { duration: 500 } })
  return (
    <Absolute ref={measureRef} cover>
      <animated.div
        style={{
          position: 'absolute',
          left: LIFT_X * width,
          top: isNaN(y) ? undefined : y,
          width: LIFT_W * width,
          height: LIFT_H * (isNaN(bgHeight) ? 0 : bgHeight),
          background: `url(${liftOpeningSequence}) no-repeat left top / auto 100%`,
          backgroundPositionX: x.interpolate(o => `${Math.round(o / 10) * 10}%`),
        }}
      />
    </Absolute>
  )
}

const ControlRoomBackground = styled.div`
  flex: auto;
  display: flex;
  background: url(${controlRoomBackground}) no-repeat center center / 100% auto,
    url(${controlRoomTop}) no-repeat top center / 100% 50%, url(${controlRoomBottom}) no-repeat bottom center / 100% 50%;
`

const Screen = styled.div`
  box-sizing: border-box;
  width: 485px;
  padding: 48px 3px 32px 3px;
  background: url(${screenBackgroundTop}) no-repeat center top / 485px 48px,
    url(${screenBackgroundBottom}) no-repeat center bottom / 485px 32px;
`

const ScreenInner = styled(Column)`
  background: #deedf7;
`

const buttonFlash = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`
const LevelButton = styled.button<{ flashing?: boolean }>`
  ${fontHeavy}
  letter-spacing: 0.5px;
  color: white;
  font-size: 16px;
  color: #19345d;
  text-align: center;

  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 10px;
  border-style: solid;
  border-width: 4px;
  border-color: #edf2fa;

  background: linear-gradient(180deg, #dde4f3 0%, #edf2fa 100%);
  padding: 12px 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  outline: none;
  position: relative;
  transition: opacity 0.2s ease-out;
  opacity: 1;
  overflow: visible;

  ::before {
    content: ${p => (p.flashing ? '""' : 'none')};
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px ${p => p.theme.appBackgroundBottomColor};
    animation: ${buttonFlash} 0.5s linear infinite alternate;
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -4px;
    left: -4px;
    right: -4px;
    border-radius: 10px;
    border-color: #abb4db;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 4px;
  }

  :disabled {
    pointer-events: none;
    opacity: 0.6;
    box-shadow: none;
  }

  :hover,
  :focus {
    background: linear-gradient(180deg, #d2d8e7 0%, #edf2fa 100%);
  }

  :active {
    transform: translateY(1px);
    box-shadow: none;

    ::after {
      border-bottom-width: 3px;
    }
  }
`

const LevelButtonNumber = styled.span`
  ${fontBlack}
  font-size: 48px;
  line-height: 1;
  display: block;
  text-align: center;
`
