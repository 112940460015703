/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { fontBold } from 'fonts'

import { HeaderTemplate } from './common/HeaderTemplate'
import { Row, Column, Spacer, Button, H2, H3, Hr } from 'common/ui'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { Switch } from 'common/Switch'

import { baseUrl } from './GadgetPack'
import { useSound } from 'utils/useSound'
import { useSfxMuted } from 'utils/useMuted'
import { useGlobalState } from 'app/GlobalState'
import { useGadgetPackState } from './GadgetPackState'
import { useUserState } from 'app/UserState'

const medalVh = 50

export const MedalScreen: React.FC<{ hideExit?: boolean; overrideExit?: () => void }> = ({
  hideExit,
  overrideExit,
}) => {
  const history = useHistory()
  const { sfxMuted, setSfxMuted } = useGlobalState()
  const {
    state: { idCard },
  } = useGadgetPackState()
  const { profileName } = useUserState()
  const [flipped, setFlipped] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const cheered = useRef<boolean>(false)
  const flipSound = useSfxMuted(useSound(require('common/assets/audio/sfx/whoosh2.mp3'), false))
  const cheerSound = useSfxMuted(useSound(require('common/assets/audio/Cheer2.mp3'), false))
  const flip = () => {
    setFlipped(!flipped)
    flipSound.volume(0.5)
    flipSound.play()
  }
  useEffect(() => {
    if (!cheered.current) {
      cheerSound.volume(0.3)
      cheerSound.play()
      cheered.current = true
    }
  }, [cheerSound])
  return (
    <>
      <HeaderTemplate
        title="Graduation Medal"
        hideExitButton={hideExit}
        onExit={() => (overrideExit ? overrideExit() : history.push(baseUrl))}
        Buttons={
          <>
            <Button onClick={() => setShowSettingsModal(true)} children="Settings" />
            <Spacer size="s" />
            <Button onClick={flip} children="Flip" />
          </>
        }>
        <Column justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <Row
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%', perspective: 800, perspectiveOrigin: 'center' }}>
            <MedalContainer>
              <MedalSpace>
                <MedalSide active={!flipped} onClick={flip}>
                  <img src={require('./assets/gadgets_medal_front.png')} alt="" />
                </MedalSide>
                <MedalSide active={flipped} onClick={flip} reversed>
                  <img src={require('./assets/gadgets_medal_back.png')} alt="" />
                  <NameTag>
                    <span>{idCard.name || profileName || ''}</span>
                  </NameTag>
                </MedalSide>
              </MedalSpace>
              <CongratsText>
                Congratulations
                <br />
                Secret Agent!
              </CongratsText>
            </MedalContainer>
          </Row>
        </Column>
      </HeaderTemplate>
      <MobileMessageModal
        zIndex={1300}
        isOpen={showSettingsModal}
        onRequestClose={() => setShowSettingsModal(false)}
        panelStyle={{ width: 320 }}>
        <H2 margin={0}>Settings</H2>
        <Hr margin="s" />
        <Row alignItems="center">
          <H3>Sound Effects:</H3>
          <Spacer flex="1 1 auto" />
          <Switch value={!sfxMuted} onChange={() => setSfxMuted(!sfxMuted)} />
        </Row>
      </MobileMessageModal>
    </>
  )
}

export const MedalContainer = styled.div`
  position: absolute;
`

export const MedalSpace = styled.div`
  position: relative;
  width: ${medalVh}vh;
  height: ${medalVh}vh;
`

const MedalSide = styled.div<{ active?: boolean; reversed?: boolean }>`
  position: absolute;
  transform-origin: 50% 50%;
  transform: rotateY(${p => (p.active ? '0deg' : p.reversed ? '-180deg' : '180deg')})
    scale(${p => (p.active ? 1 : 1.35)});
  backface-visibility: hidden;
  border-radius: 100%;
  box-shadow: ${p => (p.active ? '0 23px 36px 0 rgba(0,0,0,0.45)' : '0 0px 18px 0 rgba(0,0,0,-0.6)')};
  z-index: ${p => (p.active ? 1 : 0)};
  pointer-events: ${p => (p.active ? 'auto' : 'none')};
  transition: box-shadow 0.5s linear, transform 0.4s cubic-bezier(0.4, -0.4, 0.8, 0.5);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;

  & img {
    width: ${medalVh}vh;
    height: ${medalVh}vh;
    margin-bottom: -5px;
  }
`

const NameTag = styled.div`
  position: absolute;
  top: 38%;
  left: 15%;
  background: linear-gradient(to bottom, #f9f2e2, #bb802f);
  width: 70%;
  height: 24%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  ${fontBold}
  color: #142657;
  font-size: 42px;
  &::before {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: linear-gradient(to bottom, #ffffff, #dddbdb);
    border-radius: 10px;
  }
  & > span {
    position: relative;
    z-index: 1;
  }
`

export const CongratsText = styled.h2`
  position: relative;
  ${fontBold}
  color: white;
  text-align: center;
  font-size: 36px;
  line-height: 1.1;
`
