import React from 'react'
import styled from 'styled-components'
import { Column } from 'common/ui'
import { useFacilitatorState } from 'session/hooks/useProfileState'

export const FacilitatorPresentationUi: React.FC = ({ children }) => {
  const facilitatorState = useFacilitatorState()
  if (!facilitatorState) return null
  return (
    <Main>
      <Body children={children} />
    </Main>
  )
}

const Main = styled(Column)`
  flex: 1 1 auto;
`

const Body = styled.main`
  position: relative;
  flex: 1 1 auto;
  background-color: #464b61;
  display: flex;
`
