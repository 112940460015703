import React, { useEffect, useRef, forwardRef, Ref, ComponentPropsWithoutRef } from 'react'
import { Textarea } from 'common/ui'

export const AutosizeTextarea = forwardRef(
  (props: ComponentPropsWithoutRef<typeof Textarea>, ref: Ref<HTMLTextAreaElement>) => {
    const localRef = useRef<HTMLTextAreaElement>(null)
    useEffect(() => {
      const elem = ((ref as React.RefObject<HTMLTextAreaElement>) || localRef).current
      if (elem) {
        elem.style.height = 'auto'
        elem.style.height = `${elem.scrollHeight + 2}px`
      }
    })
    return <Textarea {...props} ref={ref || localRef} />
  }
)
