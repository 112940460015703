import React from 'react'
import styled from 'styled-components'
import { StressBallScreen, ModeSwitchContainer, StressBall, Circle } from 'gadget-pack/StressBall'
import { Container } from 'common/ui'
import { ButtonsRow } from 'gadget-pack/common/HeaderTemplate'
import { EmbeddedActivitySection } from 'shared/session/types'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'

export const StressBallActivity: React.FC<{ section: EmbeddedActivitySection }> = ({ section }) => {
  const profileUid = useParticipantProfileUid()

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${profileUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'stress-ball',
          subcategory: null,
          event_key: null,
          event_value: null,
        }}
      />
      <StressBallActivityContainer>
        <StressBallScreen hideExitButton overrideMode={section.stress_ball_mode || 'stress'} />
      </StressBallActivityContainer>
    </>
  )
}

const stressBallWidth = 300 // px
const StressBallActivityContainer = styled.div`
  position: relative;
  border-radius: 10px;
  min-height: 550px;
  overflow: hidden;

  & ${Container} {
    width: 100%;
    padding-bottom: 50px;
    margin-top: -30px;
  }

  & h1,
  & ${ModeSwitchContainer} {
    display: none;
  }

  & ${StressBall} {
    width: ${stressBallWidth}px;
  }

  & ${Circle} {
    width: ${stressBallWidth}px;
    height: ${stressBallWidth}px;
    top: calc(50% - ${stressBallWidth / 2}px);
    left: calc(50% - ${stressBallWidth / 2}px);
  }

  & ${ButtonsRow} {
    display: none;
  }

  div[mode] > div {
    background: #fff;
    border: 1px solid #cdd2e4;
    border-radius: 10px;
  }
`
