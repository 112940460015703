import React, { CSSProperties } from 'react'

export const ValidationErrorList: React.FC<{ errors: string[]; style?: CSSProperties }> = ({ errors, style = {} }) => {
  if (!errors.length) return null
  return (
    <div style={{ fontSize: 10, color: 'orange', ...style }}>
      {errors.map((str, i) => (
        <div key={i}>{str}</div>
      ))}
    </div>
  )
}
