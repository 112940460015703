import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import GrapheneSplitter from 'grapheme-splitter'
import { darkSkillEmojis } from './SkillInput'
import { darkRewardEmojis } from './RewardInput'
import { Emoji } from 'common/Emoji'

interface Props {
  symbol: string
  text?: string
  onEdit?: () => void
  size?: number
  active?: boolean
  approved?: boolean
  bonus?: boolean
  disabled?: boolean
  disabledClick?: () => void
  hideExtraSticker?: boolean
  style?: CSSProperties
}

const splitter = new GrapheneSplitter()
const darkEmojis = splitter.splitGraphemes(darkSkillEmojis + darkRewardEmojis)

export const Sticker: React.FC<Props> = ({
  symbol,
  text,
  active,
  bonus,
  approved,
  disabled,
  disabledClick,
  hideExtraSticker,
  onEdit,
  size = 26,
  style,
}) => (
  <StickerSpan
    role="img"
    aria-label={text}
    title={symbol}
    onClick={disabled ? disabledClick : onEdit}
    size={size}
    interactive={!!onEdit && !disabled}
    approved={approved}
    bonus={bonus}
    active={active}
    disabled={disabled}
    dark={darkEmojis.indexOf(symbol) >= 0}
    style={style}>
    <Emoji children={symbol} />
    {!hideExtraSticker && bonus && (
      <ExtraSticker active approved size={size * 0.65} children={<Emoji children={'⭐️'} />} />
    )}
    {/*!hideExtraSticker && !bonus && approved && (
      <ExtraSticker active approved size={size * 0.35} children={`✅`} />
    ) */}
  </StickerSpan>
)

const StickerSpan = styled.span<
  Pick<Props, 'active' | 'approved' | 'bonus' | 'disabled' | 'size'> & { dark?: boolean; interactive?: boolean }
>`
  position: relative;
  display: inline-block;
  padding: 0 2px;
  font-size: ${p => p.size || 26}px;
  vertical-align: text-top;
  line-height: 1;
  opacity: ${p => (p.active ? 1 : p.disabled ? 0.07 : 0.25)};
  ${p =>
    p.approved === undefined
      ? `filter: grayscale(100%) brightness(${p.disabled ? 0.01 : p.dark ? 1.25 : 0.75}) contrast(2);`
      : ''}
  ${p => (p.approved === false ? 'color: transparent; text-shadow: 0 0 5px red;' : '')}
  cursor: ${p => (p.interactive ? 'pointer' : 'default')};

  ${p => (p.bonus ? `text-shadow: 0 0 7px #ca9500;` : '')}
  ${p =>
    p.approved === undefined && !p.bonus && !p.active && p.interactive
      ? `:hover { text-shadow: 0 0 5px ${p.theme.buttonBorderTopColor}; filter: none; opacity: 0.5;}`
      : ''}

  ${p =>
    p.approved === false
      ? `::after { position: absolute; top: 0; left: ${Math.floor(
          (p.size || 26) / 12
        )}px; content: attr(title); text-shadow: 0 0 0 white; }`
      : ''}
`

const ExtraSticker = styled(StickerSpan)`
  position: absolute;
  bottom: 0%;
  right: -${p => (p.size || 26) * 1.2}%;
  pointer-events: none;
`
