import { useEffect } from 'react'
import { useUserState } from './UserState'
import { useLocation } from 'react-router-dom'

declare global {
  interface Window {
    gtag?: (...args: any) => void
  }
}

export const GoogleAnalytics: React.FC = () => {
  const location = useLocation()
  const { profileId } = useUserState()

  // track logged in user
  useEffect(() => {
    if (profileId && window.gtag) {
      window.gtag('set', { user_id: profileId })
    }
  }, [profileId])

  // trigger page view
  useEffect(() => {
    window.gtag && window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, { page_path: location.pathname })
  }, [location.pathname])

  return null
}

export function gtagEvent(eventName: string, params?: { [key: string]: string | number | boolean }) {
  window.gtag && window.gtag('event', eventName, params)
}

export function gtagException(description: string, fatal: boolean = false) {
  gtagEvent('exception', { description, fatal })
}

export function gtagLogin(scope: string, allowedFrom: string, interventionType: string) {
  gtagEvent('login', { method: `${scope} ${allowedFrom} ${interventionType}` })
}
