import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { MentorEntityNew, MentorEntity, GroupEntity } from 'shared/dashboard/types'

import { P, CollapseMargin } from 'common/ui'
import { PromptModal, PromptModalProps } from 'dashboards/common/PromptModal'
import { Select } from 'common/Select'

import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { postJson } from 'utils/apiUtils'

export const MentorMoveModal: React.FC<Pick<PromptModalProps, 'isOpen' | 'onSubmit'> & {
  mentor?: MentorEntityNew | MentorEntity
  providerUid?: string
  disableGroupUids?: string[]
}> = ({ mentor, providerUid, disableGroupUids = [], onSubmit, ...props }) => {
  const { accessToken } = useUserState()
  const [moving, setMoving] = useState<boolean>(false)
  const [errorMoving, setErrorMoving] = useState<string | null>(null)
  const [moved, setMoved] = useState<boolean>(false)
  const [groupUid, setGroupUid] = useState<string | null>(null)
  const [groups, { loading: loadingGroups, loaded: loadedGroups }] = useEndpoint<
    (GroupEntity & { cadet_capacity: number; cadet_count: number; has_active_session: boolean })[]
  >(
    providerUid && props.isOpen
      ? `/api/v1/groups/provider/${providerUid}?include_capacity=1&check_active_session=1`
      : null,
    [],
    {
      refetchDespiteCachedValue: true,
      disregardCachedValue: true,
    }
  )

  const groupOptions =
    groups && loadedGroups
      ? groups.map(group => {
          const isInDisabledList = disableGroupUids.includes(group.uid) || group.has_active_session
          const isFull = group.cadet_capacity ? group.cadet_count >= group.cadet_capacity : false
          return {
            value: group.uid,
            label: !isInDisabledList && isFull ? `${group.name} [Full]` : group.name,
            disabled: isFull || isInDisabledList,
          }
        })
      : []
  const handleSubmit = (proceed: boolean) => {
    if (!proceed) return onSubmit(false)
    if (!mentor || !mentor.uid || !groupUid) return
    ReactDOM.unstable_batchedUpdates(() => {
      setMoving(true)
      setMoved(false)
      setErrorMoving(null)
    })
    postJson(`/api/v1/mentors/${mentor.uid}/move`, { groupUid }, getAuthRequestParams(accessToken), { retries: 0 })
      .then(response => {
        setMoving(false)
        setMoved(true)
        onSubmit(true)
      })
      .catch(e => {
        ReactDOM.unstable_batchedUpdates(() => {
          setMoving(false)
          setErrorMoving(e.message || 'Failed to move cadet place')
        })
      })
  }
  return (
    <PromptModal
      {...props}
      title={`Move Cadet Place${mentor ? `: ${mentor.cadet_first_name} ${mentor.cadet_last_name}` : ''}`}
      label={
        <CollapseMargin>
          <P>Select a group below to move this Cadet Place to another group within your SAS Provider.</P>
          <P>This will move the Cadet Place and associated consenting adult and mentors.</P>
          <P>
            If your Cadet is currently logged in to their Digital HQ or will need to join a meeting immediately, ask
            them to log out and log back in to Digital HQ after you press the 'move' button.
          </P>
          <P>
            All current meetings in the new group must be finalised (this excludes future sessions) before the group
            will be displayed in the list below.
          </P>
        </CollapseMargin>
      }
      confirmText={moving ? 'Moving...' : 'Move'}
      disableSubmit={!groupUid || !providerUid || loadingGroups || moving || moved}
      onSubmit={handleSubmit}>
      <Select<typeof groupOptions[number], string>
        options={groupOptions}
        empty={loadingGroups ? `Loading groups...` : undefined}
        value={groupUid || ''}
        onChange={value => setGroupUid(value || null)}
        disabled={!providerUid || loadingGroups}
      />
      {errorMoving && <P style={{ color: 'red' }} children={errorMoving} />}
    </PromptModal>
  )
}
