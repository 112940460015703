import React from 'react'

import { SectionPropsBase } from 'shared/tipsheets/types'

import { H2, P } from 'common/ui'
import { RelaxationGadgetsIndexInner } from 'e-telligence/RelaxationGadgetsIndex'
import { PreviewGadgetPackStateProvider } from 'session/PreviewGadgetPackStateProvider'
import { EmotionometerActivityInner } from 'session/sections/custom/emotionometer/EmotionometerActivity'
import { SpinnerWithLabel } from 'common/Spinner'
import { Notification, PulloutInner, NotificationIcon, NotificationBody } from 'session/sections/Notification'
import { MultilineText } from 'session/common/MultilineText'
import { SVG } from 'common/SVG'

import { useTipsheetState } from 'tipsheets/TipsheetState'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import { useMentorDashData } from 'dashboards/mentor/MentorDashboardState'

interface Props extends SectionPropsBase {
  property: 'tipsheet_embedded_activity_sections'
  printMode?: boolean
}

type Emotion = 'anger' | 'anxiety'

export const TipsheetEmbeddedActivitySection: React.FC<Props> = ({ section, printMode }) => {
  const { previewing } = useTipsheetState()
  const isEmotionometer = section.activity.indexOf('emotionometer') !== -1
  const { state, dispatch } = useGadgetPackState()
  const [mentor, loadingMentor] = useMentorDashData('mentor')

  const getEmotionometerType = () => {
    if (isEmotionometer) return section.activity.split('-')[1] as Emotion
    else return 'anger'
  }

  const lacksPermission = !mentor ? true : !mentor.permission_gadget_pack

  return (
    <>
      {section.direction_text && <H2 style={{ marginBottom: 10 }}>{section.direction_text}</H2>}
      {loadingMentor ? (
        <SpinnerWithLabel label="Loading permissions..." color="#FF8A00" />
      ) : lacksPermission ? (
        <Notification type="warning">
          <PulloutInner className="notification-inner">
            <NotificationIcon>
              <SVG size={38} viewboxSize={37}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.54175 18.5003C1.54175 27.8662 9.13425 35.4587 18.5001 35.4587C27.8659 35.4587 35.4584 27.8662 35.4584 18.5003C35.4584 9.1345 27.8659 1.54199 18.5001 1.54199C9.13425 1.54199 1.54175 9.1345 1.54175 18.5003ZM32.3751 18.5003C32.3751 26.1633 26.163 32.3753 18.5001 32.3753C10.8371 32.3753 4.62508 26.1633 4.62508 18.5003C4.62508 10.8374 10.8371 4.62533 18.5001 4.62533C26.163 4.62533 32.3751 10.8374 32.3751 18.5003ZM18.5006 27.7477C19.3523 27.7477 20.0428 27.0575 20.0428 26.2061C20.0428 25.3546 19.3523 24.6644 18.5006 24.6644C17.6489 24.6644 16.9584 25.3546 16.9584 26.2061C16.9584 27.0575 17.6489 27.7477 18.5006 27.7477ZM20.0473 9.24775H16.963V21.5811H20.0473V9.24775Z"
                  fill="currentColor"
                />
              </SVG>
            </NotificationIcon>
            <NotificationBody>
              <MultilineText
                text={
                  printMode
                    ? `View this tip sheet online to access Gadget Pack activities.`
                    : section.unauthorised_text ||
                      `You currently don't have permission to view the cadet's Gadget Pack activities.`
                }
              />
            </NotificationBody>
          </PulloutInner>
        </Notification>
      ) : (
        <PreviewGadgetPackStateProvider>
          {isEmotionometer && !previewing && (
            <EmotionometerActivityInner
              activityTitle={`${getEmotionometerType().toUpperCase()} Emotionometer`}
              viewAllDevices
              hideStickerTray
              emotion={getEmotionometerType()}
              activeEmotionRangeIndexes={[true, true, true]}
              readonly
            />
          )}
          {isEmotionometer && previewing && <P>Emotionometers are not available in preview mode</P>}
          {!isEmotionometer && (
            <RelaxationGadgetsIndexInner
              baseUrl={''}
              state={state}
              dispatch={dispatch}
              overrideRoute={() => {}}
              hideCustom
            />
          )}
        </PreviewGadgetPackStateProvider>
      )}
    </>
  )
}
