import { BoardGameDeckType as DeckType, BoardGameCardDeck } from '../../../types'
// import { images } from './assets'

export const decks: { [key in DeckType]: BoardGameCardDeck } = {
  BULLYING_AND_TEASING: {
    id: 1,
    uid: 'Bullying and Teasing',
    title: 'Bullying and Teasing',
    // image: images?.deck1,
    cards: [
      { title: '', uid: 'deck1_card1', id: 1 /*, background_image: images?.deck1_card1 */ },
      { title: '', uid: 'deck1_card2', id: 2 /*, background_image: images?.deck1_card2 */ },
      { title: '', uid: 'deck1_card3', id: 3 /*, background_image: images?.deck1_card3 */ },
      { title: '', uid: 'deck1_card4', id: 4 /*, background_image: images?.deck1_card4 */ },
      { title: '', uid: 'deck1_card5', id: 5 /*, background_image: images?.deck1_card5 */ },
      { title: '', uid: 'deck1_card6', id: 6 /*, background_image: images?.deck1_card6 */ },
    ],
  },
  SAS_CHALLENGE: {
    id: 2,
    uid: 'SAS_CHALLENGE',
    title: 'SAS_CHALLENGE',
    // image: images?.deck2,
    cards: [
      { title: '', uid: 'deck2_card1', id: 1 /*, background_image: images?.deck2_card1 */ },
      { title: '', uid: 'deck2_card2', id: 2 /*, background_image: images?.deck2_card2 */ },
      { title: '', uid: 'deck2_card3', id: 3 /*, background_image: images?.deck2_card3 */ },
      { title: '', uid: 'deck2_card4', id: 4 /*, background_image: images?.deck2_card4 */ },
      { title: '', uid: 'deck2_card5', id: 5 /*, background_image: images?.deck2_card5 */ },
      { title: '', uid: 'deck2_card6', id: 6 /*, background_image: images?.deck2_card6 */ },
      { title: '', uid: 'deck2_card7', id: 7 /*, background_image: images?.deck2_card7 */ },
      { title: '', uid: 'deck2_card8', id: 8 /*, background_image: images?.deck2_card8 */ },
      { title: '', uid: 'deck2_card9', id: 9 /*, background_image: images?.deck2_card9 */ },
      { title: '', uid: 'deck2_card10', id: 10 /*, background_image: images?.deck2_card10 */ },
      { title: '', uid: 'deck2_card11', id: 11 /*, background_image: images?.deck2_card11 */ },
      { title: '', uid: 'deck2_card12', id: 12 /*, background_image: images?.deck2_card12 */ },
    ],
  },
  CHANGE_AND_UNCERTAINTY: {
    id: 3,
    uid: 'CHANGE_AND_UNCERTAINTY',
    title: 'CHANGE_AND_UNCERTAINTY',
    // image: images?.deck3,
    cards: [
      { title: '', uid: 'deck3_card1', id: 1 /*, background_image: images?.deck3_card1 */ },
      { title: '', uid: 'deck3_card2', id: 2 /*, background_image: images?.deck3_card2 */ },
      { title: '', uid: 'deck3_card3', id: 3 /*, background_image: images?.deck3_card3 */ },
      { title: '', uid: 'deck3_card4', id: 4 /*, background_image: images?.deck3_card4 */ },
      { title: '', uid: 'deck3_card5', id: 5 /*, background_image: images?.deck3_card5 */ },
      { title: '', uid: 'deck3_card6', id: 6 /*, background_image: images?.deck3_card6 */ },
    ],
  },
  MEETING_NEW_PEOPLE: {
    id: 4,
    uid: 'MEETING_NEW_PEOPLE',
    title: 'MEETING_NEW_PEOPLE',
    // image: images?.deck4,
    cards: [
      { title: '', uid: 'deck4_card1', id: 1 /*, background_image: images?.deck4_card1 */ },
      { title: '', uid: 'deck4_card2', id: 2 /*, background_image: images?.deck4_card2 */ },
      { title: '', uid: 'deck4_card3', id: 3 /*, background_image: images?.deck4_card3 */ },
      { title: '', uid: 'deck4_card4', id: 4 /*, background_image: images?.deck4_card4 */ },
      { title: '', uid: 'deck4_card5', id: 5 /*, background_image: images?.deck4_card5 */ },
      { title: '', uid: 'deck4_card6', id: 6 /*, background_image: images?.deck4_card6 */ },
    ],
  },
  MISTAKES_AND_BLUNDERS: {
    id: 5,
    uid: 'MISTAKES_AND_BLUNDERS',
    title: 'MISTAKES_AND_BLUNDERS',
    // image: images?.deck5,
    cards: [
      { title: '', uid: 'deck5_card1', id: 1 /*, background_image: images?.deck5_card1 */ },
      { title: '', uid: 'deck5_card2', id: 2 /*, background_image: images?.deck5_card2 */ },
      { title: '', uid: 'deck5_card3', id: 3 /*, background_image: images?.deck5_card3 */ },
      { title: '', uid: 'deck5_card4', id: 4 /*, background_image: images?.deck5_card4 */ },
      { title: '', uid: 'deck5_card5', id: 5 /*, background_image: images?.deck5_card5 */ },
      { title: '', uid: 'deck5_card6', id: 6 /*, background_image: images?.deck5_card6 */ },
    ],
  },
  PLAYING_WITH_OTHERS: {
    id: 6,
    uid: 'PLAYING_WITH_OTHERS',
    title: 'PLAYING_WITH_OTHERS',
    // image: images?.deck6,
    cards: [
      { title: '', uid: 'deck6_card1', id: 1 /*, background_image: images?.deck6_card1 */ },
      { title: '', uid: 'deck6_card2', id: 2 /*, background_image: images?.deck6_card2 */ },
      { title: '', uid: 'deck6_card3', id: 3 /*, background_image: images?.deck6_card3 */ },
      { title: '', uid: 'deck6_card4', id: 4 /*, background_image: images?.deck6_card4 */ },
      { title: '', uid: 'deck6_card5', id: 5 /*, background_image: images?.deck6_card5 */ },
      { title: '', uid: 'deck6_card6', id: 6 /*, background_image: images?.deck6_card6 */ },
    ],
  },
  TALKING_TO_OTHERS: {
    id: 7,
    uid: 'TALKING_TO_OTHERS',
    title: 'TALKING_TO_OTHERS',
    // image: images?.deck7,
    cards: [
      { title: '', uid: 'deck7_card1', id: 1 /*, background_image: images?.deck7_card1 */ },
      { title: '', uid: 'deck7_card2', id: 2 /*, background_image: images?.deck7_card2 */ },
      { title: '', uid: 'deck7_card3', id: 3 /*, background_image: images?.deck7_card3 */ },
      { title: '', uid: 'deck7_card4', id: 4 /*, background_image: images?.deck7_card4 */ },
      { title: '', uid: 'deck7_card5', id: 5 /*, background_image: images?.deck7_card5 */ },
      { title: '', uid: 'deck7_card6', id: 6 /*, background_image: images?.deck7_card6 */ },
    ],
  },
}
