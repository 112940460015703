import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { H3, Column } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { LinkButton } from 'common/LinkButton'
import { baseUrl } from './FacilitatorDashboard'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'

export const Help: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route}>
      {/* TODO: need to add description to help section */}
      {/* <P style={{ maxWidth: 360, margin: 0 }}></P> */}
      {/* <Spacer size="l" /> */}
      {/* <HeaderHr children="Items" /> */}
      <Cells>
        <Cell>
          <CellInner>
            <Column flex="1 1 auto">
              <H3 children="Video Tutorials" />
            </Column>
            <Column flex="none">
              <LinkButton size="s" to={`${baseUrl}/help/videos`} children="View" style={{ display: 'inline-block' }} />
            </Column>
          </CellInner>
        </Cell>
        <Cell>
          <CellInner>
            <Column flex="1 1 auto">
              <H3 children="FAQs" />
            </Column>
            <Column flex="none">
              <LinkButton size="s" to={`${baseUrl}/help/faqs`} children="View" style={{ display: 'inline-block' }} />
            </Column>
          </CellInner>
        </Cell>
      </Cells>
    </Page>
  )
}
