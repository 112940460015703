import React from 'react'
import styled from 'styled-components'
import { ScavengerHuntCard as Card } from 'shared/session/types'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { IconButton } from 'common/ui'
import { images } from './assets'

interface DeckCardModalProps {
  isOpen: boolean
  card: Card | null
  onClose: () => void
  interactive: boolean
}

export const CardModal: React.FC<DeckCardModalProps> = ({ isOpen, onClose, card, interactive }) => {
  return (
    <MobileModalPanel
      shouldCloseOnOverlayClick={interactive}
      shouldCloseOnEsc={interactive}
      isOpen={isOpen}
      onRequestClose={onClose}
      panelStyle={{ width: '20vw', maxWidth: 300, minWidth: 150, borderBottom: 'none', overflow: 'visible' }}>
      {card && (
        <>
          <img
            // @ts-ignore
            src={images[`${card.id}_back`]}
            alt={card.label}
            style={{ width: '100%', height: 'auto' }}
          />
          {interactive && <CloseButton onClick={onClose} children="×" />}
        </>
      )}
    </MobileModalPanel>
  )
}

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: none;
  transform: translate(50%, -50%) !important;
  font-family: sans-serif;
  font-weight: 100;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
  &::before,
  &::after {
    content: none;
  }
`
