/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useReducer, useRef, useEffect, useState } from 'react'

import { ETelligenceState, EmotionometerStickerBase, ETelligenceStateHookObject } from 'shared/e-telligence/types'
import {
  ActionTypes,
  UPDATE_STATE,
  ADD_RELAXATION_GADGET,
  REMOVE_RELAXATION_GADGET,
  ADD_UPDATE_CUSTOM_RELAXATION_GADGET,
  ADD_UPDATE_EMOTIONOMETER,
  ADD_UPDATE_CUSTOM_STICKER,
  ADD_CODE_CARD,
  REMOVE_CODE_CARD,
  ADD_EMOTIONOMETER_STICKER,
  REMOVE_EMOTIONOMETER_STICKER,
  ADD_UPDATE_CUSTOM_CODE_CARD,
  UpdateStateAction,
} from 'shared/e-telligence/actionTypes'

import { useLocalStorage } from 'utils/useStorage'
import { useUserState, UserState } from 'app/UserState'
import { updateOrAppend, updateIfExists } from 'utils/reducerUtils'
import { saveETelligence, ETelligenceResponse, getETelligence } from 'api'
import { actionTypeValidators } from './actionValidators'

export function reducer<T extends ETelligenceState>(state: T, action: ActionTypes): ETelligenceState {
  switch (action.type) {
    case UPDATE_STATE:
      return action.state
    case ADD_RELAXATION_GADGET:
      return {
        ...state,
        gadgetIds: [...state.gadgetIds, action.gadgetId],
      }
    case REMOVE_RELAXATION_GADGET:
      return {
        ...state,
        gadgetIds: state.gadgetIds.filter(id => id !== action.gadgetId),
      }
    case ADD_UPDATE_CUSTOM_RELAXATION_GADGET:
      return {
        ...state,
        customGadgets: updateOrAppend(state.customGadgets, action.gadget, 'id'),
        gadgetIds:
          state.gadgetIds.indexOf(action.gadget.id) < 0 ? [...state.gadgetIds, action.gadget.id] : state.gadgetIds,
      }
    case ADD_UPDATE_EMOTIONOMETER:
      return { ...state, emotionometers: updateOrAppend(state.emotionometers, action.emotionometer, 'id') }
    case ADD_EMOTIONOMETER_STICKER: {
      const emotionometer = state.emotionometers.find(({ type }) => type === action.emotionometerType)
      if (!emotionometer) return state
      const emotionometerStickers = emotionometer[action.emotionometerDevice] || ([] as typeof action.sticker[])
      return {
        ...state,
        emotionometers: state.emotionometers.map(test =>
          test.id === emotionometer.id
            ? {
                ...emotionometer,
                [action.sticker.type]: updateOrAppend(
                  //@ts-ignore
                  emotionometerStickers,
                  action.sticker,
                  action.emotionometerRange ? ['id', 'emotionRange'] : 'id'
                ),
              }
            : test
        ),
      }
    }
    case REMOVE_EMOTIONOMETER_STICKER: {
      const emotionometer = state.emotionometers.find(({ type }) => type === action.emotionometerType)
      if (!emotionometer) {
        console.warn('could not find emotionometer when removing sticker')
        return state
      }
      const emotionometerStickers = (emotionometer[action.emotionometerDevice] || []) as EmotionometerStickerBase[]
      return {
        ...state,
        emotionometers: state.emotionometers.map(test =>
          test.id === emotionometer.id
            ? {
                ...emotionometer,
                [action.emotionometerDevice]: emotionometerStickers.filter(
                  sticker => !(sticker.id === action.stickerId && sticker.emotionRange === action.emotionometerRange)
                ),
              }
            : test
        ),
      }
    }
    case ADD_UPDATE_CUSTOM_STICKER: {
      const emotionometer = state.emotionometers.find(({ type }) => type === action.emotionometerType)
      if (!emotionometer) {
        console.warn('could not find emotionometer when updating custom sticker')
        return state
      }
      const emotionometerStickers = emotionometer[action.sticker.type] || []
      console.log('existing sticker', emotionometerStickers)
      // disable auto add is to fix issue when adding a sticker without selecting a slot
      return {
        ...state,
        customStickers: updateOrAppend(state.customStickers, action.sticker, 'id'),
        emotionometers: action.disableAutoAdd
          ? state.emotionometers
          : state.emotionometers.map(test =>
              test.id === emotionometer.id
                ? {
                    ...emotionometer,
                    [action.sticker.type]: updateOrAppend(
                      // @ts-ignore
                      emotionometerStickers,
                      action.sticker,
                      action.emotionometerRange ? ['id', 'emotionRange'] : 'id'
                    ),
                  }
                : test
            ),
      }
    }
    case ADD_CODE_CARD:
      return {
        ...state,
        codeCardIds: [...state.codeCardIds, action.codeCardId],
      }
    case REMOVE_CODE_CARD:
      return {
        ...state,
        codeCardIds: state.codeCardIds.filter(id => id !== action.codeCardId),
      }
    case ADD_UPDATE_CUSTOM_CODE_CARD:
      return {
        ...state,
        customCodeCards: updateOrAppend(state.customCodeCards, action.codeCard, 'id'),
        codeCardIds:
          state.codeCardIds.indexOf(action.codeCard.id) < 0
            ? [...state.codeCardIds, action.codeCard.id]
            : state.codeCardIds,
      }
    default:
      return state
  }
}

export const initialState: ETelligenceState = {
  gadgetIds: [],
  customGadgets: [],
  codeCardIds: [],
  customCodeCards: [],
  emotionometers: [],
  customStickers: [],
}

function initState(state: ETelligenceState): ETelligenceState {
  return { ...initialState, ...state }
}

function validateActions(actions: ActionTypes[], state: ETelligenceState): [ActionTypes[], ETelligenceState] {
  let newState = { ...state }
  const newActions: ActionTypes[] = []
  for (let action of actions) {
    const validator = actionTypeValidators[action.type]
    // @ts-ignore
    if (validator(action, state)) {
      newState = reducer(newState, action)
      newActions.push(action)
    } else {
      // mark rejected action as such and save it back to local storage
      newActions.push({ ...action, rejected: true })
    }
  }
  return [newActions, newState]
}

function saveActions(data: {
  actions: ActionTypes[]
  state: ETelligenceState
  revision: number
  profileId: UserState['profileId']
  accessToken: UserState['accessToken']
  storeActionsLocally: (newActions: ActionTypes[]) => void
  updateRevision: (revision: number) => void
  handleSuccess: (state: ETelligenceState) => void
  handleError?: () => void
}) {
  const {
    actions = [],
    state,
    revision,
    profileId,
    accessToken,
    storeActionsLocally,
    updateRevision,
    handleSuccess,
    handleError,
  } = data
  console.log(`attempting to save actions`, actions)
  const newRevision = revision + 1
  saveETelligence(profileId, state, actions, newRevision, accessToken)
    .then(() => {
      console.log(`successfully saved actions`)
      updateRevision(newRevision)
      handleSuccess(state)
    })
    .catch(err => {
      console.log(`failed to saved actions`, err)
      if (err.conflict) {
        console.log(`submitted revision number conflicts with server's records`)
        let { state, revision } = err.conflict as ETelligenceResponse
        if (state === null) state = { ...initialState }
        updateRevision(revision)
        const [newActions, newState] = validateActions(actions, state)
        console.log(`validated pending actions with server state, attempting resubmit`, newActions)
        saveActions({ ...data, revision, actions: newActions, state: newState })
      } else {
        console.log(`saving actions to local storage for future submission to server`)
        // append actions to local storage
        storeActionsLocally(actions)
        if (handleError) handleError()
      }
    })
}

export const getLocalStorageKey = (state: Pick<UserState, 'profileId' | 'getUserScopes' | 'openAccess'>): string => {
  const isAdult = state.getUserScopes().indexOf('mentor') >= 0
  return `${state.profileId}_${isAdult ? 'mentor' : 'student'}${state.openAccess ? '_open_access' : ''}`
}

function useProviderETelligenceState() {
  const { profileId, openAccess, accessToken, getBaseAction, getUserScopes } = useUserState()
  const prefix = getLocalStorageKey({ profileId, getUserScopes, openAccess })

  const [loadingInitialState, setLoadingInitialState] = useState<boolean>(false)
  const [localState, setLocalState] = useLocalStorage<ETelligenceState>(`${prefix}_ET_state`, initialState)
  const [localActions, setLocalActions] = useLocalStorage<ActionTypes[]>(`${prefix}_ET_actions`, [])
  const [localRevision, setLocalRevision] = useLocalStorage<number>(`${prefix}_ET_revision`, 0)

  const [state, dispatch] = useReducer(reducer, localState, initState)

  const persistentState = useRef(state)

  const overwriteState = (state: ETelligenceState) => {
    console.log(`overwriting state`, state)
    persistentState.current = state
    setLocalState(state)
    const stateUpdateAction: UpdateStateAction = { ...getBaseAction(), type: UPDATE_STATE, state }
    dispatch(stateUpdateAction)
  }

  const preDispatch = (action: ActionTypes) => {
    // optimistically update
    console.log(action.type, 'action dispatched')

    persistentState.current = reducer(persistentState.current, action)
    setLocalState(persistentState.current)
    dispatch(action)

    const actions = [...localActions, action]
    setLocalActions([])

    // This prevents SST users masquerading as SAS user from attempting to save data
    if (getUserScopes().length > 0) {
      saveActions({
        actions,
        state: persistentState.current,
        revision: localRevision,
        profileId,
        accessToken,
        storeActionsLocally: newActions => setLocalActions(localActions => [...localActions, ...newActions]),
        updateRevision: setLocalRevision,
        handleSuccess: overwriteState,
      })
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setLoadingInitialState(true)
    const hasOfflineActions = localState && localActions.length > 0
    getETelligence(profileId, accessToken)
      .then(({ state, revision }) => {
        console.log(`got server state`)
        // check if user hasn't used skill tracker before
        if (state === null) {
          console.log(`user has no state saved on server`)
          if (hasOfflineActions && getUserScopes().length > 0) {
            console.log(`but user has offline data to process`)
            saveActions({
              actions: localActions,
              state: localState,
              revision: localRevision,
              profileId,
              accessToken,
              storeActionsLocally: newActions => setLocalActions(localActions => [...localActions, ...newActions]),
              updateRevision: setLocalRevision,
              handleSuccess: newState => {
                overwriteState(newState)
                setLoadingInitialState(false)
              },
              handleError: () => setLoadingInitialState(false),
            })
          } else {
            // do nothing -- initial state will be created upon first action dispatch
            console.log(`do nothing -- initial state will be created upon first action dispatch`)
            setLoadingInitialState(false)
          }
        } else {
          if (hasOfflineActions && getUserScopes().length > 0) {
            console.log(`updating local revision to ${revision}`)
            setLocalRevision(revision)

            console.log(`conflict! have to merge offline actions with latest server state`)
            const [newActions, newState] = validateActions(localActions, state)
            saveActions({
              actions: newActions,
              state: newState,
              revision,
              profileId,
              accessToken,
              storeActionsLocally: newActions => setLocalActions(localActions => [...localActions, ...newActions]),
              updateRevision: setLocalRevision,
              handleSuccess: newState => {
                overwriteState(newState)
                setLoadingInitialState(false)
              },
              handleError: () => setLoadingInitialState(false),
            })
          } else {
            // update local revision number
            console.log(`updating local revision to ${revision}`)
            setLocalRevision(revision)
            overwriteState(state)
            setLoadingInitialState(false)
          }
        }
      })
      .catch(() => {
        // if offline restore local state or init
        if (localState) overwriteState(localState)
        else overwriteState(initialState)
        setLoadingInitialState(false)
      })
  }, [profileId])
  /* eslint-enable react-hooks/exhaustive-deps */

  function resetLocalState() {
    setLocalState(initialState)
    setLocalActions([])
    setLocalRevision(0)
    overwriteState(initialState)
  }

  return {
    state,
    actions: localActions,
    revision: localRevision,
    dispatch: preDispatch,
    loadingInitialState,
    storagePrefix: prefix,
    resetLocalState,
  }
}

function noop(): any {}

export const ETelligenceStateContext = React.createContext<ETelligenceStateHookObject>({
  state: initialState,
  actions: [],
  revision: 0,
  dispatch: noop,
  loadingInitialState: false,
  storagePrefix: '',
  resetLocalState: noop,
})

export const ETelligenceStateProvider: React.FC = ({ children }) => {
  const state = useProviderETelligenceState()
  return <ETelligenceStateContext.Provider value={state}>{children}</ETelligenceStateContext.Provider>
}

export function useETelligenceState() {
  return useContext(ETelligenceStateContext)
}
