import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { Page } from 'dashboards/common/Page'
import { Cells } from 'dashboards/common/Cell'
import { ResourceCell } from 'dashboards/common/ResourceCell'

export const PresentationSlides: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route}>
      <Cells>
        <ResourceCell
          title="School/Teacher Information Session"
          asset={require('./assets/presentationSlides/Teacher_Information_Session.pdf')}
        />
        <ResourceCell title="Community Information Session" asset="" buttonLabel="TBA" />
      </Cells>
    </Page>
  )
}
