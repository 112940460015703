export const images = {
  boy1: require('./assets/boy1.png'),
  boy2: require('./assets/boy2.png'),
  boy3: require('./assets/boy3.png'),
  boy4: require('./assets/boy4.png'),
  boy5: require('./assets/boy5.png'),
  boy6: require('./assets/boy6.png'),
  girl1: require('./assets/girl1.png'),
  girl2: require('./assets/girl2.png'),
  girl3: require('./assets/girl3.png'),
  girl4: require('./assets/girl4.png'),
  girl5: require('./assets/girl5.png'),
  girl6: require('./assets/girl6.png'),

  deck1: { id: 1, created: '', modified: '', filename: './assets/deck1', extension: 'jpg' },
  deck1_card1: { id: 1, created: '', modified: '', filename: './assets/deck1_card1', extension: 'png' },
  deck1_card2: { id: 1, created: '', modified: '', filename: './assets/deck1_card2', extension: 'png' },
  deck1_card3: { id: 1, created: '', modified: '', filename: './assets/deck1_card3', extension: 'png' },
  deck1_card4: { id: 1, created: '', modified: '', filename: './assets/deck1_card4', extension: 'png' },
  deck1_card5: { id: 1, created: '', modified: '', filename: './assets/deck1_card5', extension: 'png' },
  deck1_card6: { id: 1, created: '', modified: '', filename: './assets/deck1_card6', extension: 'png' },

  deck2: { id: 1, created: '', modified: '', filename: './assets/deck2', extension: 'jpg' },
  deck2_tile: { id: 1, created: '', modified: '', filename: './assets/deck2_tile', extension: 'jpg' },
  deck2_card1: { id: 1, created: '', modified: '', filename: './assets/deck2_card1', extension: 'png' },
  deck2_card2: { id: 1, created: '', modified: '', filename: './assets/deck2_card2', extension: 'png' },
  deck2_card3: { id: 1, created: '', modified: '', filename: './assets/deck2_card3', extension: 'png' },
  deck2_card4: { id: 1, created: '', modified: '', filename: './assets/deck2_card4', extension: 'png' },
  deck2_card5: { id: 1, created: '', modified: '', filename: './assets/deck2_card5', extension: 'png' },
  deck2_card6: { id: 1, created: '', modified: '', filename: './assets/deck2_card6', extension: 'png' },
  deck2_card7: { id: 1, created: '', modified: '', filename: './assets/deck2_card7', extension: 'png' },
  deck2_card8: { id: 1, created: '', modified: '', filename: './assets/deck2_card8', extension: 'png' },
  deck2_card9: { id: 1, created: '', modified: '', filename: './assets/deck2_card9', extension: 'png' },
  deck2_card10: { id: 1, created: '', modified: '', filename: './assets/deck2_card10', extension: 'png' },
  deck2_card11: { id: 1, created: '', modified: '', filename: './assets/deck2_card11', extension: 'png' },
  deck2_card12: { id: 1, created: '', modified: '', filename: './assets/deck2_card12', extension: 'png' },

  deck3: { id: 1, created: '', modified: '', filename: './assets/deck3', extension: 'jpg' },
  deck3_card1: { id: 1, created: '', modified: '', filename: './assets/deck3_card1', extension: 'png' },
  deck3_card2: { id: 1, created: '', modified: '', filename: './assets/deck3_card2', extension: 'png' },
  deck3_card3: { id: 1, created: '', modified: '', filename: './assets/deck3_card3', extension: 'png' },
  deck3_card4: { id: 1, created: '', modified: '', filename: './assets/deck3_card4', extension: 'png' },
  deck3_card5: { id: 1, created: '', modified: '', filename: './assets/deck3_card5', extension: 'png' },
  deck3_card6: { id: 1, created: '', modified: '', filename: './assets/deck3_card6', extension: 'png' },

  deck4: { id: 1, created: '', modified: '', filename: './assets/deck4', extension: 'jpg' },
  deck4_card1: { id: 1, created: '', modified: '', filename: './assets/deck4_card1', extension: 'png' },
  deck4_card2: { id: 1, created: '', modified: '', filename: './assets/deck4_card2', extension: 'png' },
  deck4_card3: { id: 1, created: '', modified: '', filename: './assets/deck4_card3', extension: 'png' },
  deck4_card4: { id: 1, created: '', modified: '', filename: './assets/deck4_card4', extension: 'png' },
  deck4_card5: { id: 1, created: '', modified: '', filename: './assets/deck4_card5', extension: 'png' },
  deck4_card6: { id: 1, created: '', modified: '', filename: './assets/deck4_card6', extension: 'png' },

  deck5: { id: 1, created: '', modified: '', filename: './assets/deck5', extension: 'jpg' },
  deck5_card1: { id: 1, created: '', modified: '', filename: './assets/deck5_card1', extension: 'png' },
  deck5_card2: { id: 1, created: '', modified: '', filename: './assets/deck5_card2', extension: 'png' },
  deck5_card3: { id: 1, created: '', modified: '', filename: './assets/deck5_card3', extension: 'png' },
  deck5_card4: { id: 1, created: '', modified: '', filename: './assets/deck5_card4', extension: 'png' },
  deck5_card5: { id: 1, created: '', modified: '', filename: './assets/deck5_card5', extension: 'png' },
  deck5_card6: { id: 1, created: '', modified: '', filename: './assets/deck5_card6', extension: 'png' },

  deck6: { id: 1, created: '', modified: '', filename: './assets/deck6', extension: 'jpg' },
  deck6_card1: { id: 1, created: '', modified: '', filename: './assets/deck6_card1', extension: 'png' },
  deck6_card2: { id: 1, created: '', modified: '', filename: './assets/deck6_card2', extension: 'png' },
  deck6_card3: { id: 1, created: '', modified: '', filename: './assets/deck6_card3', extension: 'png' },
  deck6_card4: { id: 1, created: '', modified: '', filename: './assets/deck6_card4', extension: 'png' },
  deck6_card5: { id: 1, created: '', modified: '', filename: './assets/deck6_card5', extension: 'png' },
  deck6_card6: { id: 1, created: '', modified: '', filename: './assets/deck6_card6', extension: 'png' },

  deck7: { id: 1, created: '', modified: '', filename: './assets/deck7', extension: 'jpg' },
  deck7_card1: { id: 1, created: '', modified: '', filename: './assets/deck7_card1', extension: 'png' },
  deck7_card2: { id: 1, created: '', modified: '', filename: './assets/deck7_card2', extension: 'png' },
  deck7_card3: { id: 1, created: '', modified: '', filename: './assets/deck7_card3', extension: 'png' },
  deck7_card4: { id: 1, created: '', modified: '', filename: './assets/deck7_card4', extension: 'png' },
  deck7_card5: { id: 1, created: '', modified: '', filename: './assets/deck7_card5', extension: 'png' },
  deck7_card6: { id: 1, created: '', modified: '', filename: './assets/deck7_card6', extension: 'png' },

  tile3_4: require('./assets/tile3_4@2x.png'),
  tile11_12: require('./assets/tile11_12@2x.png'),
  tile13_14: require('./assets/tile13_14@2x.png'),
  tile19_20: require('./assets/tile19_20@2x.png'),
  tile27_28: require('./assets/tile27_28@2x.png'),
  tile30_31: require('./assets/tile30_31@2x.png'),
  tile36_37: require('./assets/tile36_37@2x.png'),
  tile41_42: require('./assets/tile41_42@2x.png'),
  tile49_50: require('./assets/tile49_50@2x.png'),

  encounter_tile3_4: require('./assets/encounter_tile3_4.jpg'),
  encounter_tile11_12: require('./assets/encounter_tile11_12.jpg'),
  encounter_tile13_14: require('./assets/encounter_tile13_14.jpg'),
  encounter_tile19_20: require('./assets/encounter_tile19_20.jpg'),
  encounter_tile27_28: require('./assets/encounter_tile27_28.jpg'),
  encounter_tile30_31: require('./assets/encounter_tile30_31.jpg'),
  encounter_tile36_37: require('./assets/encounter_tile36_37.jpg'),
  encounter_tile41_42: require('./assets/encounter_tile41_42.png'),
  encounter_tile49_50: require('./assets/encounter_tile49_50.jpg'),
}
