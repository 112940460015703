import styled, { createGlobalStyle } from 'styled-components'
import { Column } from 'common/ui'
import { fontLight, fontRegular } from 'fonts'

export const ReportingGlobalStyle = createGlobalStyle`
  html {
    display: initial;
  }
  body, #root {
    display: initial;
    flex: initial;
  }

  /**
   * Global override for chart lines since customising
   * these seems too difficult right now.
   */
  .recharts-cartesian-grid-horizontal line {
    &:first-child {
      display: none;
    }
    &:last-child {
      stroke-dasharray: initial;
    }
  }
`

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;

  margin: 0 auto;
  padding: 0 1em;
`

export const HorizontalScroll = styled.div`
  overflow-x: auto;
`

export type TableProps = { striped?: boolean; dashed?: boolean }
export const Table = styled.table<TableProps>`
  width: 100%;
  ${fontRegular}
  border: 1px solid #abb3dc;
  border-left: 0;
  border-spacing: 0;

  td,
  th {
    background: #edf2fa;
    border-left: 1px solid white;
    border-right: 1px solid #abb3dc;
    padding: 10px 10px;

    &:first-child {
      position: sticky;
      left: 0;
      border-left: 1px solid #abb3dc;
    }
    &:last-child {
      border-right: 0;
    }
  }
  td {
    ${fontLight}
    font-size: 16px;
    text-align: center;
  }
  th:nth-child(1),
  td:nth-child(1) {
    z-index: 1;
    text-align: left;
  }
  thead th {
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  tbody th {
    ${fontLight}
    background: linear-gradient(0deg, #fff 0px, #fff 1px, #abb3dc 1px, #abb3dc 2px, transparent 2px),
      linear-gradient(180deg, #abb3dc 0px, #abb3dc 1px, #fff 1px, #fff 2px, transparent 2px),#e0e5f5;
  }

  /* prevent divs from displaying above sticky column */
  div:not([class]) {
    position: inherit;
  }

  ${p =>
    p.striped
      ? `
  tbody tr:nth-child(odd) {
    & td {
      background: #e0e5f5;
    }
  }
  `
      : ''}

  ${p =>
    p.dashed
      ? `
  tbody td, tbody th {
    border-top: 1px dashed #acb3dc;
  }
      `
      : ''}
`

export const Well = styled(Column)`
  box-sizing: border-box;
  border: 1px solid #abb3dc;
  border-radius: 10px;
  background: white;
  overflow: hidden;

  page-break-inside: avoid;
`
