import { SectionStateProperty, SectionStates } from '../types'
import { reducer as boardGameReducer } from './custom/board-game/reducer'
import { reducer as scavengerHuntReducer } from './custom/scavenger-hunt/reducer'
import { reducer as thoughtTrackerReducer } from './custom/thought-tracker/reducer'
import { reducer as drawingSectionReducer } from './drawing/reducer'
import { reducer as questionnaireSectionReducer } from './questionnaire/reducer'
import { reducer as secretTransmissionReducer } from './custom/secret-transmission/reducer'
import { stateReducer as helpfulThoughtsReducer } from './custom/helpful-thoughts/reducer'
import { SectionStateActions } from './sectionStateActionTypes'

function placeholderReducer__<State, Action>(state: State, action: Action): State {
  return state
}

export const sectionStateReducers: {
  [key in SectionStateProperty]: (state: SectionStates[key], action: SectionStateActions[key]) => typeof state
} = {
  turn_taking_sections: placeholderReducer__,
  card_deck_sections: placeholderReducer__,
  code_card_sections: placeholderReducer__,
  drawing_sections: drawingSectionReducer,
  questionnaire_sections: questionnaireSectionReducer,
  activity_scavenger_hunt: scavengerHuntReducer,
  activity_club_rules: placeholderReducer__,
  activity_emotionometer: placeholderReducer__,
  activity_relaxation_gadgets: placeholderReducer__,
  activity_secret_transmission: secretTransmissionReducer,
  activity_helpful_thoughts: helpfulThoughtsReducer,
  activity_body_signals: placeholderReducer__,
  activity_thought_tracker: thoughtTrackerReducer,
  activity_brain_cell: placeholderReducer__,
  activity_board_game: boardGameReducer,
  activity_invisible_ink: placeholderReducer__,
  activity_damage_control: placeholderReducer__,
  activity_bully_guard: placeholderReducer__,
  activity_detection_of_expression: placeholderReducer__,

  // these are just here to keep typescript happy
  block_sections: placeholderReducer__,
  divider_sections: placeholderReducer__,
  download_resource_sections: placeholderReducer__,
  embedded_activity_sections: placeholderReducer__,
  emotionometer_sections: placeholderReducer__,
  grouped_sections: placeholderReducer__,
  input_sections: placeholderReducer__,
  list_sections: placeholderReducer__,
  media_grid_sections: placeholderReducer__,
  notification_sections: placeholderReducer__,
  text_sections: placeholderReducer__,
  todo_list_sections: placeholderReducer__, // todo: actually make it
  token_target_sections: placeholderReducer__,
}
