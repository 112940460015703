import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { blue, orange, parentGreen, trainingPurple } from 'themes'
import { QuestionnaireStateProvider, useQuestionnaireState } from './QuestionnaireState'
import { QuestionnaireView } from './Questionnaire'
import { useParams } from 'react-router-dom'
import { useSocket } from 'utils/useSocket'
import { Button, Container, Row, Spacer } from 'common/ui'

export const QuestionnairePreview: React.FC = () => {
  return (
    <QuestionnaireStateProvider>
      <QuestionnairePreviewInner />
    </QuestionnaireStateProvider>
  )
}

const previewThemes = {
  cadet: blue,
  teacher: orange,
  parent: parentGreen,
  facilitator: trainingPurple,
}

const QuestionnairePreviewInner: React.FC = () => {
  const [type, setType] = useState<keyof typeof previewThemes>('cadet')
  const { previewUid } = useParams<{ previewUid?: string }>()
  const [initedPreviewData, setInitedPreviewData] = useState<boolean>(false)
  const {
    questionnaireData,
    setQuestionnaireData,
    previewing,
    setPreviewing,
    setPreviewState,
  } = useQuestionnaireState()
  const socket = useSocket(process.env.REACT_APP_PREVIEW_SOCKET_URL)
  useEffect(() => {
    if (!socket || !previewUid) return
    if (!socket.connected) {
      console.log('Preview socket listeners being created...')
      socket.on('connect', () => {
        console.log('Preview socket connection established')
        socket.emit('REGISTER_VIEWER', previewUid)
      })
      socket.on('disconnect', () => {
        console.log('Preview socket disconnected')
      })
      socket.on('VIEWER_DATA_UPDATED', (questionnaireData: any) => {
        console.log('Received viewer data from preview socket', questionnaireData)
        setQuestionnaireData(questionnaireData)
        setInitedPreviewData(true)
      })
      socket.on('DISPATCH', (action: any) => {
        console.log('Received action from preview socket server', action)
        switch (action.type) {
          case 'UPDATE_PREVIEW_STATE':
            console.log('Updating preview state', action.state)
            setPreviewState(action.state)
            break
        }
      })
      socket.connect()
    } else {
      console.log('Socket already connected')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, previewUid])

  useEffect(() => {
    if (!previewing) setPreviewing(true)
  }, [previewing, setPreviewing])

  if (!initedPreviewData || !questionnaireData || !previewUid) return <h1>Please wait</h1>

  return (
    <ThemeProvider theme={previewThemes[type]}>
      <Container>
        <Background />
        <Spacer size="l" />
        <Row>
          <Button theme="blue" size="s" children="Cadet" onClick={() => setType('cadet')} pressed={type === 'cadet'} />
          <Spacer />
          <Button
            theme="parentGreen"
            size="s"
            children="Parent"
            onClick={() => setType('parent')}
            pressed={type === 'parent'}
          />
          <Spacer />
          <Button
            theme="orange"
            size="s"
            children="Teacher"
            onClick={() => setType('teacher')}
            pressed={type === 'teacher'}
          />
          <Spacer />
          <Button
            theme={trainingPurple}
            size="s"
            children="Training"
            onClick={() => setType('facilitator')}
            pressed={type === 'facilitator'}
          />
        </Row>
        <Spacer size="l" />
        {questionnaireData ? (
          <QuestionnaireView type={type} booklet={1} standalone />
        ) : (
          <p>questionnaire not found :(</p>
        )}
        <Spacer size="l" />
      </Container>
    </ThemeProvider>
  )
}

const Background = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${p => p.theme.buttonBorderTopColor};
`
