import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Panel } from 'common/ui'
import { HeaderTemplate } from '../common/HeaderTemplate'

import { useGadgetPackState } from '../GadgetPackState'
import { RelaxationGadgetViewInner } from 'e-telligence/RelaxationGadgetView'
// import { baseUrl as gadgetPackBaseUrl } from '../GadgetPack'

export const RelaxationGadgetView: React.FC<{ baseUrl: string; isAdding?: boolean }> = ({ baseUrl, isAdding }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useGadgetPackState()
  console.log('VIEW LOCATION STATE', location.state)
  return (
    <HeaderTemplate
      title="Relaxation Gadgets"
      exitLabel="Back"
      onExit={() => history.push(baseUrl + (isAdding ? '/relaxation-gadgets' : ''), location.state)}>
      <Panel style={{ backgroundColor: 'white' }}>
        <RelaxationGadgetViewInner
          hideBackButton
          isAdding={isAdding}
          state={state}
          dispatch={dispatch}
          baseUrl={baseUrl}
        />
      </Panel>
    </HeaderTemplate>
  )
}
