import React from 'react'

import { NavRouteProps } from 'dashboards/types'
import { FacilitatorLookupEntity } from 'shared/dashboard/types'

import { P, H3, Column, Spacer } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Cells, Cell } from 'dashboards/common/Cell'
import { AnchorButton } from 'common/LinkButton'
import { Spinner } from 'common/Spinner'

import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useMentorDashData } from './MentorDashboardState'

export const Contact: React.FC<NavRouteProps> = ({ route }) => {
  const [primaryMentor] = useMentorDashData('primaryMentor')
  const [facilitator] = useEndpoint<FacilitatorLookupEntity>(
    primaryMentor && primaryMentor.group_id
      ? `/api/v1/mentors/${primaryMentor.uid}/facilitator/${primaryMentor.group_id}`
      : null
  )
  return (
    <Page route={route}>
      <P style={{ maxWidth: 360, margin: 0 }}>
        To assist you with any further secret agent detective work, please find the relevant contact details below.
      </P>
      <Spacer size="l" />
      <HeaderHr children="Contact your SAS Provider" />
      <Cells>
        <Cell>
          <Column padding={20}>
            <H3 style={{ color: '#6737B5' }}>
              Please direct any program participation questions to your SAS Provider service.
            </H3>
            <P style={{ marginBottom: 0 }}>
              Your SAS Facilitator:{' '}
              {facilitator ? (
                <strong>{facilitator.name}</strong>
              ) : primaryMentor && primaryMentor.group_id ? (
                <Spinner color="#858cac" />
              ) : null}
            </P>
          </Column>
        </Cell>
      </Cells>
      <Spacer size="l" />
      <HeaderHr children="Technical Support" />
      <Cells>
        <Cell>
          <Column padding={20}>
            <H3 style={{ color: '#6737B5' }}>Please complete the web form to submit technical issues experienced</H3>
            <P style={{ marginBottom: 0 }}>
              <AnchorButton
                size="xs"
                style={{ display: 'inline-block' }}
                children="Support Web Form"
                target="_blank"
                href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}
              />
              <br />
              <br />
              Email: <a href="mailto:support@sst-institute.net" children="support@sst-institute.net" />
              <P style={{ maxWidth: 650 }}>
                You can also try troubleshooting information on our{' '}
                <a href="https://www.sst-institute.net/help-im-stuck">"Help I'm Stuck"</a> page.
              </P>
            </P>
          </Column>
        </Cell>
      </Cells>
    </Page>
  )
}
