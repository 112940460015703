import React, { MouseEvent, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { fontBold, fontLight, fontRegular } from 'fonts'

import { UPDATE_SECTION_STATE_DANGEROUS } from 'shared/session/actionTypes'
import {
  ActivityBullyGuardState,
  CardDeckSection as CardDeckInterface,
  CardDeckSection,
  EmbeddedActivitySection,
  SectionPanel,
  SectionProperty,
  SessionProfile,
  TurnStatus,
  TurnTakingSection as TurnTakingSectionProps,
  TurnTakingSectionState,
  Card as CardType,
} from 'shared/session/types'

import { Button, Row, Spacer } from 'common/ui'
import { DisabledOverlay } from 'session/common/DisabledOverlay'
import { SVG } from 'common/SVG'
import { ConfirmModal } from 'common/ConfirmModal'
import { CircleButton } from 'session/common/CircleButton'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { Checkbox } from 'common/Checkbox'
import { CardFlip } from 'common/CardFlip'
import {
  TurnTakingSectionFacilitator,
  getInitialSectionState as getInitialTurnTakingState,
  TurnTakingSection,
} from '../TurnTaking'

import { DeckResetButton, getCards } from '../CardDeck'
import { getImageUrl } from 'session/utils/assetUtils'
import { useSessionState } from 'session/SessionState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'

const getInitialSectionState = () => ({})

const defaultUserState = {
  selection: null,
  score: 0,
  assignedCards: [],
  previousAnswers: [],
  flippedCards: {},
  assignedCardsFlipped: {},
}

interface Props {
  section: EmbeddedActivitySection
  panel: SectionPanel
  index: number
  property: SectionProperty
}

export const BullyGuard: React.FC<Props> = ({ section, panel, index }) => {
  const { bully_turn_taking_section, bully_card_deck_section } = section
  if (!bully_turn_taking_section || !bully_card_deck_section) throw new Error('Missing Section!')

  const { dispatch, getBaseAction } = useSessionState()
  const participantState = useFocusedParticipantState()
  const sectionState = useSectionStateWithFallback('activity_bully_guard', section, getInitialSectionState)
  // const [activeCard, setActiveCard] = useState<CardType | null>(null)

  const turnTakingSectionState = useSectionStateWithFallback(
    'turn_taking_sections',
    bully_turn_taking_section,
    getInitialTurnTakingState
  )

  const updateSectionState = (state: ActivityBullyGuardState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: 'activity_bully_guard',
      section_id: section.id,
      state,
    })
  }

  const handleCardClick = (cardUid: string) => {
    if (!participantState) return
    const userState = sectionState[participantState.profile.uid] || defaultUserState

    updateSectionState({
      ...sectionState,
      [participantState.profile.uid]: { ...userState, selection: userState.selection === cardUid ? null : cardUid },
    })
  }

  const activeProfileUid =
    participantState &&
    participantState.profile.uid in turnTakingSectionState &&
    (turnTakingSectionState[participantState.profile.uid] === 'active' ||
      turnTakingSectionState[participantState.profile.uid] === 'prepare')
      ? participantState.profile.uid
      : undefined

  const assignedCards = activeProfileUid ? sectionState[activeProfileUid].assignedCards : []

  const handleCardFlip = (cardUid: string) => {
    if (!participantState) return
    const userState = sectionState[participantState.profile.uid] || defaultUserState

    updateSectionState({
      ...sectionState,
      [participantState.profile.uid]: {
        ...userState,
        flippedCards: {
          ...userState.flippedCards,
          [cardUid]: userState.flippedCards[cardUid] ? !userState.flippedCards[cardUid] : true,
        },
      },
    })
  }

  const handlePlayerTurnCardFlip = (cardUid: string) => {
    if (!participantState) return
    const userState = sectionState[participantState.profile.uid] || defaultUserState

    updateSectionState({
      ...sectionState,
      [participantState.profile.uid]: {
        ...userState,
        assignedCardsFlipped: {
          ...userState.assignedCardsFlipped,
          [cardUid]: userState.assignedCardsFlipped[cardUid] ? !userState.assignedCardsFlipped[cardUid] : true,
        },
      },
    })
  }

  return (
    <>
      <TurnDisplay
        section={section}
        turnTakingSectionState={turnTakingSectionState}
        userScore={
          participantState && sectionState[participantState.profile.uid]
            ? sectionState[participantState.profile.uid].score
            : 0
        }
        profile={participantState ? participantState.profile : null}
        panel={panel}
        index={index}
        handleSelect={handleCardClick}
        sectionState={sectionState}
        handleCardFlip={handleCardFlip}
      />
      <Spacer size="m" />
      {activeProfileUid && (
        <CardSection
          cardSection={bully_card_deck_section}
          assignedCards={assignedCards}
          onFlip={handlePlayerTurnCardFlip}
          flippedState={
            participantState
              ? sectionState[participantState.profile.uid].assignedCardsFlipped
              : defaultUserState.assignedCardsFlipped
          }
        />
      )}
    </>
  )
}

interface SectionProps {
  section: Props['section']
  turnTakingSectionState: TurnTakingSectionState
  profile: SessionProfile | null
  panel: SectionPanel
  index: number
  userScore: number
  handleSelect: (cardUid: string) => void
  sectionState: ActivityBullyGuardState
  handleCardFlip: (cardUid: string) => void
}

const getTurnLabels = (section: TurnTakingSectionProps): { [key in TurnStatus]: string } => ({
  wait: section.wait_text || '',
  prepare: section.prepare_text || '',
  active: section.active_text || '',
})

const TurnDisplay: React.FC<SectionProps> = ({
  section,
  turnTakingSectionState,
  profile,
  panel,
  index,
  handleSelect,
  userScore,
  sectionState,
  handleCardFlip,
}) => {
  const { bully_turn_taking_section, bully_card_deck_section } = section
  const turnKey: TurnStatus =
    profile && profile.uid in turnTakingSectionState ? turnTakingSectionState[profile.uid] : 'wait'

  if (!bully_turn_taking_section || !bully_card_deck_section) {
    return null
  }

  const turnLabels = getTurnLabels(bully_turn_taking_section)

  return (
    <>
      {turnKey === 'wait' && (
        <GuessingSection
          label={turnLabels[turnKey]}
          turnTakingSectionState={turnTakingSectionState}
          cardSection={bully_card_deck_section}
          handleSelect={handleSelect}
          userScore={userScore}
          sectionState={sectionState}
          handleCardFlip={handleCardFlip}
        />
      )}
      {(turnKey === 'prepare' || turnKey === 'active') && (
        <Wrapper>
          <TurnTakingSection
            property="turn_taking_sections"
            index={index}
            panel={panel}
            section={bully_turn_taking_section}
          />
        </Wrapper>
      )}
    </>
  )
}

const GuessingSection: React.FC<{
  label: string
  turnTakingSectionState: TurnTakingSectionState
  sectionState: ActivityBullyGuardState
  cardSection: CardDeckInterface
  handleSelect: (cardUid: string) => void
  handleCardFlip: (cardUid: string) => void
  userScore: number
}> = ({ label, cardSection, turnTakingSectionState, sectionState, handleSelect, userScore, handleCardFlip }) => {
  const participantState = useFocusedParticipantState()

  const { state } = useSessionState()
  const cards = useMemo(() => getCards(cardSection), [cardSection])

  const getUserActive = (uid: string) => {
    return turnTakingSectionState[uid] === 'active'
  }

  return (
    <>
      <GuessingLabel children={label} />
      <GuessingWrapper>
        <ParticipantWrapper>
          {state.participants.map((participant, i, arr) => (
            <Participant key={i} isActive={getUserActive(participant.profile.uid)} width={100 / arr.length}>
              <span>{participant.profile.displayName}</span>
            </Participant>
          ))}
        </ParticipantWrapper>
        <CardWrapper>
          {cards.map((card, i) => (
            <CardContainer
              key={card.id}
              selected={
                participantState && sectionState[participantState.profile.uid]
                  ? sectionState[participantState.profile.uid].selection === card.uid
                  : false
              }
              onClick={() => handleSelect(card.uid)}>
              <FlipCard
                card={card}
                flipped={
                  participantState
                    ? sectionState[participantState.profile.uid]
                      ? sectionState[participantState.profile.uid].flippedCards[card.uid]
                      : false
                    : false
                }
                handleFlip={handleCardFlip}
              />
            </CardContainer>
          ))}
        </CardWrapper>
        <ScoreSection>{userScore !== 0 ? userScore : ''}</ScoreSection>
      </GuessingWrapper>
    </>
  )
}

const FlipCard: React.FC<{ card: CardType; flipped: boolean; handleFlip: (cardUid: string) => void }> = ({
  card,
  flipped,
  handleFlip,
}) => {
  const handleBtnClick = (e: MouseEvent) => {
    e.stopPropagation()
    handleFlip(card.uid)
  }

  return (
    <>
      <CardFlip
        isFlipped={flipped}
        containerStyle={{ height: '100%' }}
        children={[
          <Card>
            <CardTitle>{card.title}</CardTitle>
            <ImageWrapper>
              {card.background_image && <img src={getImageUrl(card.background_image, { preset: '320w-4-3' })} alt="" />}
            </ImageWrapper>
          </Card>,
          <CardBack>
            <TextWrapper>
              <AbsoluteWrapper>
                <CardText>{card.text}</CardText>
              </AbsoluteWrapper>
            </TextWrapper>
          </CardBack>,
        ]}
      />
      <Button size="xs" style={{ padding: '2px', margin: 0 }} onClick={e => handleBtnClick(e)}>
        <SVG width={20} height={18} viewboxHeight={22} viewboxWidth={24}>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
        </SVG>
      </Button>
    </>
  )
}

const CardSection: React.FC<{
  cardSection: CardDeckSection
  assignedCards: string[]
  onFlip: (cardUid: string) => void
  flippedState: {
    [key: string]: boolean
  }
}> = ({ cardSection, assignedCards, onFlip, flippedState }) => {
  const cards = useMemo(() => getCards(cardSection), [cardSection])
  const cardsToRender = cards.filter(card => {
    return assignedCards.find(val => val === card.uid) ? true : false
  })

  return (
    <Container>
      <Row justifyContent="center">
        {cardsToRender.map((card, idx) => (
          <Deck key={idx}>
            <FlipCard
              card={card}
              flipped={flippedState[card.uid] ? flippedState[card.uid] : false}
              handleFlip={onFlip}
            />
          </Deck>
        ))}
      </Row>
    </Container>
  )
}

export const BullyGuardFacilitator: React.FC<Props> = ({ section, panel, index }) => {
  const { bully_turn_taking_section, bully_card_deck_section } = section
  const { state, dispatch, getBaseAction } = useSessionState()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [confirmResetModalOpen, setConfirmResetModalOpen] = useState<boolean>(false)
  const [confirmRandomizeModalOpen, setConfirmRandomizeModalOpen] = useState<boolean>(false)
  const [resetTurnTaking, setResetTurnTaking] = useState<number>(0)

  if (!bully_turn_taking_section || !bully_card_deck_section) throw new Error('Missing section!')

  const cards = getCards(bully_card_deck_section)
  const sectionState = useSectionStateWithFallback('activity_bully_guard', section, () => ({}))

  const updateSectionState = (state: ActivityBullyGuardState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: 'activity_bully_guard',
      section_id: section.id,
      state,
    })
  }

  const disableTurnTaking = state.participants.reduce(
    (acc, participant) =>
      acc ||
      (sectionState[participant.profile.uid] && sectionState[participant.profile.uid].assignedCards.length > 0
        ? false
        : true),
    false
  )

  const nextRound = (confirmed: boolean) => {
    setShowConfirm(false)

    if (confirmed) {
      updateSectionState(
        Object.keys(sectionState).reduce((acc, participantUid) => {
          const userState = sectionState[participantUid] || defaultUserState

          acc[participantUid] = {
            ...sectionState[participantUid],
            previousAnswers: [...userState.previousAnswers, ...userState.assignedCards],
            assignedCards: [],
            selection: null,
          }
          return acc
        }, {} as ActivityBullyGuardState)
      )

      setResetTurnTaking(resetTurnTaking + 1)
    }
  }

  const handleConfirmReset = (confirmed: boolean) => {
    setConfirmResetModalOpen(false)

    if (confirmed) {
      updateSectionState(
        Object.keys(sectionState).reduce((acc, participantUid) => {
          const userState = sectionState[participantUid] || defaultUserState
          acc[participantUid] = {
            ...userState,
            assignedCards: [],
            selection: null,
          }
          return acc
        }, {} as ActivityBullyGuardState)
      )

      setResetTurnTaking(resetTurnTaking + 1)
    }
  }

  const handleConfirmRandomize = (proceed: boolean) => {
    if (proceed) randomizeSelection(true)
    setConfirmRandomizeModalOpen(false)
  }

  const getRandomCardSet = () => {
    let randomSelection: string[] = []

    return [...Array(3)].map((_, idx) => {
      const selectableCards = cards.filter(item => {
        if (idx !== 0) {
          console.log(randomSelection, item.uid)
          return randomSelection.find(a => a === item.uid) ? false : true
        } else {
          return true
        }
      })
      const selection = selectableCards[Math.floor(Math.random() * selectableCards.length)].uid
      randomSelection.push(selection)
      return selection
    })
  }

  const randomizeSelection = (override: boolean = false) => {
    if (!override && !confirmRandomizeModalOpen) {
      setConfirmRandomizeModalOpen(true)
      return
    }
    updateSectionState(
      state.participants.reduce((acc, participant) => {
        const userState = sectionState[participant.profile.uid] || defaultUserState
        acc[participant.profile.uid] = {
          ...userState,
          assignedCards: getRandomCardSet(),
        }
        return acc
      }, {} as typeof sectionState)
    )
  }

  const updateScore = (participantUid: string, increment: boolean) => {
    const userState = sectionState[participantUid] ? sectionState[participantUid] : defaultUserState

    updateSectionState({
      ...sectionState,
      [participantUid]: {
        ...userState,
        score: increment ? userState.score + 1 : userState.score > 0 ? userState.score - 1 : 0,
      },
    })
  }

  return (
    <>
      <ActivityInteractionIndicator type="group" />
      <FacilitatorWrapper>
        {bully_turn_taking_section && (
          <DisabledOverlay active={disableTurnTaking} message="Assign everyone a card to enable turn-taking controls">
            <TurnTakingSectionFacilitator
              hideInteractionIndicator
              property="turn_taking_sections"
              section={bully_turn_taking_section}
              {...{ panel, index }}
              refresh={resetTurnTaking}>
              <Row justifyContent="flex-end">
                <Button
                  theme="purple"
                  size="s"
                  style={{ marginTop: 20 }}
                  onClick={() => setShowConfirm(true)}
                  children="Next Round"
                />
              </Row>
            </TurnTakingSectionFacilitator>
          </DisabledOverlay>
        )}
        <FacilitatorControls title="Assign Cards">
          <Row style={{ position: 'absolute', right: 20, top: 10 }}>
            <Button
              style={{ marginRight: 10 }}
              theme="purple"
              size="s"
              onClick={() => randomizeSelection()}
              children="Randomise Cards"
            />
            <DeckResetButton size="s" onClick={() => setConfirmResetModalOpen(true)} children="Reset Activity" />
          </Row>
          <Spacer />
          <FacilitatorControlsTable>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Cards</th>
                {state.participants.map((participant, i) => (
                  <th key={i}>
                    {participant.profile.displayName}
                    <br />
                    <FacilitatorScore>
                      <span style={{ opacity: 0.4 }}>
                        Score:{' '}
                        {sectionState[participant.profile.uid] ? sectionState[participant.profile.uid].score : '0'}
                      </span>
                      <CircleButton
                        style={{ marginRight: 2 }}
                        theme="purple"
                        size="xs"
                        children="+"
                        onClick={() => {
                          updateScore(participant.profile.uid, true)
                        }}
                      />
                      <CircleButton
                        theme="purple"
                        size="xs"
                        children="&#8722;"
                        onClick={() => {
                          updateScore(participant.profile.uid, false)
                        }}
                      />
                    </FacilitatorScore>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(cards || []).map(card => (
                <CardTableRow key={card.uid}>
                  <th>{card.title || card.heading}</th>
                  {state.participants.map((participant, i) => {
                    const userState = sectionState[participant.profile.uid]
                      ? sectionState[participant.profile.uid]
                      : defaultUserState

                    const tdClass = `${userState.selection === card.uid ? 'highlight' : ''} ${
                      userState.previousAnswers.find(a => a === card.uid) ? 'previous' : ''
                    }`

                    return (
                      <td key={i} className={tdClass}>
                        <Checkbox
                          size="s"
                          checked={userState.assignedCards.find(val => val === card.uid) ? true : false}
                          onChange={() => {
                            const selected = userState.assignedCards.find(val => val === card.uid) ? true : false
                            updateSectionState({
                              ...sectionState,
                              [participant.profile.uid]: {
                                ...userState,
                                assignedCards: !selected
                                  ? [...userState.assignedCards.splice(-2), card.uid]
                                  : userState.assignedCards.filter(str => str !== card.uid),
                                assignedCardsFlipped: {},
                              },
                            })
                          }}
                        />
                      </td>
                    )
                  })}
                </CardTableRow>
              ))}
            </tbody>
          </FacilitatorControlsTable>
        </FacilitatorControls>
      </FacilitatorWrapper>
      <ConfirmModal
        isOpen={showConfirm}
        onSubmit={nextRound}
        label='Are you sure? This will set all participants to "Wait" and save current selections'
      />
      <ConfirmModal
        isOpen={confirmResetModalOpen}
        onSubmit={handleConfirmReset}
        label="Are you sure you want to reset all selections?"
      />
      <ConfirmModal
        isOpen={confirmRandomizeModalOpen}
        onSubmit={handleConfirmRandomize}
        label="Are you sure you want to create a random selection? Your current selections will be reset if you proceed."
      />
    </>
  )
}

const FacilitatorWrapper = styled.div`
  .highlight span {
    box-shadow: inset 0px 0px 0px 3px white, 0px 0px 5px 2px #05ff00 !important;
  }

  .previous {
    background-color: #f1f1f1;
  }

  ${CircleButton} {
    font-size: 0.8em;
    line-height: 0.5;
    cursor: pointer;
  }
`

const FacilitatorScore = styled.div`
  margin-top: 10px;

  span {
    margin-right: 10px;
  }
`

const Wrapper = styled.div`
  div[type='info'] {
    padding-bottom: 10px;
  }
`

const GuessingWrapper = styled.div`
  border: 1px solid #bdc3e0;
  border-radius: 10px;

  ${Button} {
    display: block;
    margin: 20px auto;
    cursor: pointer;
  }
`

const GuessingLabel = styled.p`
  ${fontRegular};
  color: #2eadf0;
  margin-top: 0;
`

const ParticipantWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const Participant = styled.div<{ isActive: boolean; width: number }>`
  ${fontLight};
  font-size: 1rem;
  font-weight: ${p => (p.isActive ? 500 : 400)};
  color: #011a46;
  width: ${p => `${p.width}%`};
  border-right: 1px solid #bdc3e0;
  border-bottom: 1px solid #bdc3e0;
  padding: 15px 25px;

  &:last-of-type {
    border-right: none;
  }

  span {
    opacity: ${p => (p.isActive ? 1 : 0.3)};
  }
`

const AbsoluteWrapper = styled.div`
  position: absolute;
  left: 10%;
  top: 0;
  right: 10%;
  bottom: 0;
`

const Card = styled.div`
  position: relative;
  user-select: none;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
`

const ImageWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 16% 10px;
  width: 100%;

  img {
    width: 100%;
    border-radius: 10px;
    opacity: 1 !important;
  }
`

const CardBack = styled(Card)`
  padding: 28px 16% 10px 16%;
`

const TextWrapper = styled.div`
  height: 0;
  width: 100%;
  padding-top: ${(3 / 4) * 100}%;
  padding-bottom: 5px;

  ${AbsoluteWrapper} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CardTitle = styled.p`
  ${fontBold};
  margin: 0;
  font-size: 14px;
  line-height: 2;
  text-transform: uppercase;
`

const CardText = styled.p`
  ${fontRegular};
  margin: 0;
  font-size: 1rem;
  @media (max-width: 920px) {
    font-size: 0.85rem;
  }
`

const CardWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  padding: 0 15px;
`

const CardContainer = styled.div<{ selected?: boolean }>`
  width: 33%;
  cursor: pointer;
  margin-bottom: 3px;
  position: relative;

  ${Button} {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .react-card-flip {
    margin: 5px;
  }

  .react-card-front,
  .react-card-back {
    border-radius: 10px;
    height: auto !important;
    background-color: #925bed;
    ${p =>
      p.selected
        ? css`
            box-shadow: 0px 0px 5px 5px #05ff00;
          `
        : ''};
  }

  ${Card} {
    img {
      opacity: ${p => (p.selected ? 1 : 0.5)};
    }
  }

  ${Button} {
    position: absolute;
    bottom: 11px;
    right: 0;
    left: auto;
    padding: 2px;
    margin: 0;
    cursor: pointer;

    svg {
      fill: #fff;
    }
  }
`

const Deck = styled(CardContainer)`
  .react-card-front,
  .react-card-back {
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border: 1px solid #bdc3e0;
  }

  img {
    border: 1px solid #d7d1c9;
  }

  ${Card} {
    color: #011a46;
  }

  ${CardBack} {
    padding: 28px 16% 12px 16%;
  }
`

const Container = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #bdc3e0;
`

const CardTableRow = styled.tr`
  label input:checked + span {
    background: linear-gradient(180deg, #6c3bbc 0%, #8d57e6 100%) !important;
  }
`

const ScoreSection = styled.div`
  padding: 10px;
  box-sizing: border-box;
  width: calc(100% - 40px);
  color: #011a46;
  background: #fff;
  border: 1px solid #bdc3e0;
  margin: 20px;
  min-height: 50px;
  border-radius: 10px;
`
