import React from 'react'
import styled from 'styled-components'
import { Container } from 'common/ui'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { MedalScreen, MedalContainer, CongratsText, MedalSpace } from 'gadget-pack/Medal'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'

export const GraduationMedalActivity: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  const profileUid = useParticipantProfileUid()

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${profileUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'medal',
          subcategory: null,
          event_key: null,
          event_value: null,
        }}
      />
      <MedalActivityContainer isGadgetTray>
        <MedalScreen overrideExit={onClose} />
      </MedalActivityContainer>
    </>
  )
}

const MedalActivityContainer = styled.div<{ isGadgetTray: boolean }>`
  ${Container} {
    width: 100%;
  }

  ${MedalContainer} {
    position: relative;
    margin-top: 25px;

    img {
      width: 100%;
      height: auto;
    }
  }

  ${MedalSpace} {
    @media (max-width: 500px) {
      width: 30vh;
      height: 30vh;
    }
  }

  ${CongratsText} {
    color: #011a46;
  }
`
