import React, { ChangeEvent, Fragment, useState } from 'react'
import styled from 'styled-components'
import { Button, Label, Row, Spacer, TextareaInput } from 'common/ui'
import { MediaAsset, MediaRatio } from 'shared/session/types'
import { Media } from 'session/common/Media'
import { RichText } from 'session/common/RichText'
import { Block, BlockHeader, BlockMedia, BlockBody } from 'session/sections/Block'
import { QuestionOption } from 'shared/training/types'
import ReactDOM from 'react-dom'
import { getQuestionState, useTrainingState } from 'training/TrainingState'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { fontRegular } from 'fonts'

interface AnswerRevealProps {
  uid: string
  media?: MediaAsset
  video_url?: string
  text: string
  media_ratio?: MediaRatio
  media_type: 'image' | 'video'
  question_options: QuestionOption[]
  reviewMode: boolean
  onSubmit: (correct: boolean, value: { [key: number]: string }, response?: string, skipValidation?: boolean) => void
}

const validateInput = (inputVals: { [key: number]: string }, options: QuestionOption[]) => {
  if (Object.keys(inputVals).length === options.length) {
    return Object.values(inputVals).reduce((acc, cur) => {
      if (!acc) return false
      if (cur.split(' ').length > 1) {
        return true
      }
      return false
    }, true)
  }

  return false
}

export const AnswerReveal: React.FC<AnswerRevealProps> = (props) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, props.uid)
  const [show, setShow] = useState<boolean>(!!questionState?.answer)
  const [inputVals, setInputVals] = useState<{ [key: number]: string }>(questionState?.answer || {})
  const [allowSubmit, setAllowSubmit] = useState<boolean>(validateInput(inputVals, props.question_options))

  const handleSubmit = () => {
    setShow(true)
    props.onSubmit(true, inputVals, '', true)
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>, idx: number) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setInputVals({ ...inputVals, [idx]: e.target.value })
      setAllowSubmit(validateInput(inputVals, props.question_options))
    })
  }

  return (
    <AnswerRevealWrapper>
      {props.question_options.map((option, idx) => (
        <Fragment key={idx}>
          <Label children={option.label} />
          <Spacer size="s" />
          <Row justifyContent="space-between">
            <AutosizeTextareaInput
              inputSize="s"
              value={inputVals[idx] || ''}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e, idx)}
              placeholder={option.placeholder}
              disabled={props.reviewMode}
            />
          </Row>
          <Spacer />
        </Fragment>
      ))}
      {!props.reviewMode && <Button size="s" children="submit" onClick={handleSubmit} disabled={!allowSubmit} />}
      <Spacer />
      <RevealContent {...props} visible={show} />
    </AnswerRevealWrapper>
  )
}

interface TabRevealProps {
  uid: string
  question_options: QuestionOption[]
  reviewMode: boolean
  adventure_validation: boolean
  onSubmit: (correct: boolean, value: QuestionOption | null, response?: string, skipValidation?: boolean) => void
}

export const TabReveal: React.FC<TabRevealProps> = ({
  uid,
  onSubmit,
  question_options,
  adventure_validation,
  reviewMode,
}) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)
  const [selectedOption, setSelectedOption] = useState<QuestionOption | null>(questionState?.answer || null)
  const [showIndex, setShowIndex] = useState<number | null>(questionState?.answer ? questionState?.answer.id : null)

  const handleSubmit = () => {
    if (!adventure_validation) {
      return onSubmit(true, selectedOption, '', true)
    }
    if (selectedOption && selectedOption.correct) {
      return onSubmit(true, selectedOption, `The correct answer is "${selectedOption.title}"`)
    }
    onSubmit(false, selectedOption)
  }

  const handleSelect = (selection: QuestionOption) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setShowIndex(selection.id)
      setSelectedOption(selection)
    })
  }

  return (
    <AnswerRevealWrapper>
      <Row>
        {question_options.map((option, idx) => (
          <Tab
            key={idx}
            active={showIndex === option.id}
            count={question_options.length}
            children={option.title}
            onClick={() => handleSelect(option)}
          />
        ))}
      </Row>
      <Spacer />
      {question_options.map((option, idx) => (
        <RevealContent key={idx} {...option} visible={showIndex === option.id} />
      ))}
      <Spacer />
      {!reviewMode && (
        <Row>
          <Button size="s" children="Submit" onClick={handleSubmit} />
        </Row>
      )}
    </AnswerRevealWrapper>
  )
}

export const RevealContent: React.FC<{
  text: string
  media_type: 'image' | 'video'
  media?: MediaAsset
  media_ratio?: MediaRatio
  visible: boolean
  video_url?: string
}> = ({ media, media_ratio, media_type, text, visible, video_url }) => (
  <Block display="inline" blockTheme={undefined} style={{ display: visible ? 'flex' : 'none' }}>
    {(media || video_url) && text && (
      <BlockHeader>
        <BlockMedia>
          {media_type === 'image' && (
            <Media type="image" image={media} widthPreset={'656w'} ratio={media_ratio || 'auto'} display="inline" />
          )}
          {media_type === 'video' && (
            <div className="videoWrapper" style={{ paddingBottom: '56.25%', height: 0 }}>
              <iframe
                title="stream"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                src={`https://www.sst-institute.net/embed?url=${video_url}`}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                width="100%"></iframe>
            </div>
          )}
        </BlockMedia>
      </BlockHeader>
    )}
    {text && (
      <BlockBody style={{ borderRadius: media ? '0 10px 10px 0' : 10 }}>
        <RichText text={text} />
      </BlockBody>
    )}
    {(media || video_url) && !text && (
      <FullWidth>
        {media_type === 'image' && (
          <Media type="image" image={media} widthPreset={'656w'} ratio={media_ratio || 'auto'} display="inline" />
        )}
        {media_type === 'video' && (
          <div className="videoWrapper" style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
            <iframe
              title="stream"
              frameBorder="0"
              scrolling="no"
              allowFullScreen
              src={`https://www.sst-institute.net/embed?url=${video_url}`}
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 10 }}
              width="100%"></iframe>
          </div>
        )}
      </FullWidth>
    )}
  </Block>
)

const AnswerRevealWrapper = styled.div`
  width: 100%;

  ${TextareaInput} {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #cdd2e4;
    box-shadow: none;
    padding: 13px;
    padding-bottom: 0;
    line-height: 1.2;
  }

  ${Block} {
    margin: 0;

    ${BlockMedia} {
      border-radius: 10px 0 0 10px !important;
      border-right: 1px solid #bdc3e0;
    }

    ${BlockBody} {
      padding: 15px !important;
      border: 1px solid #cdd2e4 !important;
    }
  }
`

const FullWidth = styled.div`
  width: 100%;

  img {
    width: 100%;
    border-radius: 10px;
  }
`

const Tab = styled.div<{ count: number; active: boolean }>`
  ${fontRegular};
  width: calc(${(p) => 100 / p.count}% - 20px);
  margin: 0 10px;
  background: #edf2fa;
  border: 1px solid #bdc3e0;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  opacity: ${(p) => (p.active ? 0.5 : 1)};

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`
