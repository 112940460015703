import React from 'react'
import { Select } from 'common/Select'

const getTimes = ({
  earliestHour = 0,
  latestHour = 23,
  timeDivisions = 4,
}: Pick<Props, 'earliestHour' | 'latestHour' | 'timeDivisions'>) =>
  [...Array((latestHour - earliestHour) * timeDivisions + 1)].map((x, i) => {
    const hour = earliestHour + Math.floor(i / timeDivisions)
    const minutes = (i * (60 / timeDivisions)) % 60
    return {
      label: `${hour % 12 || 12}:${String(minutes).padStart(2, '0')} ${hour >= 12 ? 'pm' : 'am'}`,
      value: `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`,
    }
  })

interface Props {
  value: string
  onChange: (value: string) => void
  earliestHour?: number
  latestHour?: number
  timeDivisions?: number
}

export const TimePicker: React.FC<Props> = ({ earliestHour, latestHour, timeDivisions, value, onChange }) => {
  const times = getTimes({ earliestHour, latestHour, timeDivisions })
  return <Select options={times} value={value} onChange={onChange} />
}
