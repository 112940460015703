import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useParams } from 'react-router-dom'
import { useSocket } from 'utils/useSocket'
import { Container, CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L } from 'common/ui'
import { gray } from 'themes'
import { AccordionItem, ContentPanel } from './Items'
import { ContentItem } from './ContentPage'

export const ContentPagePreview: React.FC = () => {
  const { previewUid } = useParams<{ previewUid?: string }>()
  const [initedPreviewData, setInitedPreviewData] = useState<boolean>(false)
  const [pageData, setPageData] = useState<ContentItem | null>(null)
  const socket = useSocket(process.env.REACT_APP_PREVIEW_SOCKET_URL)

  useEffect(() => {
    if (!socket || !previewUid) return
    if (!socket.connected) {
      console.log('Preview socket listeners being created...')
      socket.on('connect', () => {
        console.log('Preview socket connection established')
        socket.emit('REGISTER_VIEWER', previewUid)
      })
      socket.on('disconnect', () => {
        console.log('Preview socket disconnected')
      })
      socket.on('VIEWER_DATA_UPDATED', (data: any) => {
        console.log('Received viewer data from preview socket', data)
        setInitedPreviewData(true)
        setPageData(data.content_item[data.module.id])
      })
      socket.connect()
    } else {
      console.log('Socket already connected')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, previewUid])

  if (!initedPreviewData || !pageData || !previewUid) return <h1>Please wait</h1>

  return (
    <ThemeProvider theme={gray}>
      <Container>
        <Background />
        <h1>Previewing....</h1>
        <ContentPanel padding="25px 40px 40px 40px" flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_T_L, TAB_B_L]}>
          <AccordionItem item={pageData} isPreview />
        </ContentPanel>
      </Container>
    </ThemeProvider>
  )
}

const Background = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(210.7deg, #acacac 3.35%, #666666 100%);
`
