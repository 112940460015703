export const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

export const capitalizeSentences = (str: string): string =>
  str
    .split(/\.\s+/gi)
    .map(sentence => capitalizeFirstLetter(sentence))
    .join('. ')

export const createListString = (
  list: string[],
  lastWord: string | false = 'and',
  delimiter: string = ', '
): string => {
  if (list.length <= 1) return list.join('')
  if (lastWord === false) return list.join(delimiter)
  const firstItems = list.slice(0, list.length - 1)
  const lastItem = list[list.length - 1]
  return firstItems.join(delimiter) + ` ${lastWord} ` + lastItem
}

export const s = (arr: any[], plural: string = 's') => (arr.length > 1 ? plural : '')
