import React, { ReactNode } from 'react'

import {
  AppBackground,
  Row,
  Panel,
  H1,
  Spacer,
  Column,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  GRILL_TL,
  GRILL_TR,
  TAB_B_S,
  P,
} from 'common/ui'

import { ShortcutMenu } from 'common/ShortcutMenu'
import { useMedia } from 'utils/useMedia'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { GameModeIndicator } from 'app/GameModeIndicator'
import styled from 'styled-components'

export const IntroTemplate: React.FC<{
  title: string
  gameModeIndicator?: boolean
  plainLogo?: boolean
  afterPanel?: ReactNode
  logoOverride?: string
}> = ({ title, children, gameModeIndicator = true, plainLogo = false, afterPanel, logoOverride }) => {
  const compact = useMedia('(max-width: 500px)')
  useDisableOrientationLock()

  return (
    <AppBackground>
      <Row flex margin="xs" marginBottom>
        <Spacer flex />
        <Column flex="0 1 485px">
          <Spacer flex="1 1 0px" />
          <img
            src={
              logoOverride
                ? logoOverride
                : plainLogo
                ? require('./assets/image/logo-hq-plain.png')
                : require('./assets/image/logo-hq-characters.png')
            }
            style={{ width: '100%', flex: 'none' }}
            alt="Secret Agent Society Computer Game"
          />
          <Panel
            padding={compact ? true : '13px 30px 35px 30px'}
            flair={compact ? [] : [CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR, TAB_B_S]}>
            <H1 margin marginLeft={!compact && 'l'} marginRight={!compact && 'l'} align="center">
              {title}
            </H1>
            <Spacer />
            {children}
            <Spacer />
          </Panel>
          {afterPanel && (
            <>
              <Spacer />
              {afterPanel}
            </>
          )}
          <Spacer />
          <Footer />
          <Spacer flex="2 1 auto" />
        </Column>
        <Spacer flex />
      </Row>
      <ShortcutMenu />
      {gameModeIndicator && <GameModeIndicator />}
    </AppBackground>
  )
}

export const Footer: React.FC = () => {
  return (
    <P align="center" color="white">
      <small>
        Copyright © Social Science Translated Pty Ltd 2022. All rights reserved. <br />
        <FooterLink href={`${process.env.REACT_APP_SST_BASE_URL}/terms`}>Terms of use</FooterLink> |{' '}
        <FooterLink href={`${process.env.REACT_APP_SST_BASE_URL}/privacy`}>Privacy Policy</FooterLink> |{' '}
        <FooterLink href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`}>Technical Enquiries</FooterLink>
      </small>
    </P>
  )
}

const FooterLink = styled.a`
  color: white !important;
  text-decoration: underline;

  :hover,
  :focus {
    color: white;
    text-decoration: none;
  }
`
