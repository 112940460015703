import React from 'react'
import styled from 'styled-components'
import { Spacer, H2 } from 'common/ui'
import { fontRegular } from 'fonts'
import { RadioButtonLabel } from './RadioButton'

interface Props {
  title?: string
}

export const FacilitatorControls: React.FC<Props> = ({ title, children }) => {
  return (
    <FacilitatorControlsSection>
      {title && (
        <>
          <H2>{title}</H2>
          <Spacer size="s" />
        </>
      )}
      {children}
    </FacilitatorControlsSection>
  )
}

export const FacilitatorControlsSection = styled.div`
  position: relative;
  margin: 8px 0;
  overflow: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd2e4;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 20px;

  & ${H2} {
    color: #7d41df;
  }
`

export const FacilitatorControlsTable = styled.table`
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
  border: 1px solid #cdd2e4;
  border-radius: 12px;
  border-spacing: 0;
  overflow: hidden;

  & th:not(:first-child),
  & td:not(:first-child) {
    border-left: 1px solid #cdd2e4;
  }
  & tr:not(:last-child),
  & thead tr {
    & > th,
    & > td {
      border-bottom: 1px solid #cdd2e4;
    }
  }
  & th,
  & td {
    ${fontRegular}
    line-height: 1;
    border: none;
    padding: 0;
  }
  & th {
    padding: 12px 15px;
  }
  & td {
    text-align: center;
    & ${RadioButtonLabel} {
      justify-content: center;
    }
  }
  & thead {
    & th {
      text-align: center;
    }
  }
  & tbody {
    & th {
      text-align: left;
    }
  }
`
