import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { Page } from 'dashboards/common/Page'
import { Row } from 'common/ui'
import { EmbeddedVideo } from 'dashboards/common/EmbeddedVideo'

export const VideoTutorials: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route} title="Video Tutorials">
      <Row flexWrap marginLeft={'-7.5px'} marginRight={'-7.5px'}>
        <EmbeddedVideo
          title="Create a Group"
          source="https://www.youtube.com/embed/N62g3JOMarE?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Activate a Cadet Place <br /> (Existing Account)
            </>
          }
          source="https://www.youtube.com/embed/oYcsJjuLYSs?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Activate a Cadet Place <br /> (New Account)
            </>
          }
          source="https://www.youtube.com/embed/kL4RUmZdte4?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title="Schedule a Group Meeting"
          source="https://www.youtube.com/embed/4M1E6l0RSaI?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title="How to Join a Meeting"
          source="https://www.youtube.com/embed/du4jaJt5Yrg?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title="Basic Session Controls"
          source="https://www.youtube.com/embed/HLNt0EniYW4?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title="Running the Thought Tracker Activity"
          source="https://www.youtube.com/embed/XLDgGabtzBk?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title="Board Game Controls"
          source="https://www.youtube.com/embed/iq-ZnaGBkVY?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title="Advanced Session Controls"
          source="https://www.youtube.com/embed/ROxz0VwmmcI?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Basic Session Troubleshooting <br /> (Cadet)
            </>
          }
          source="https://www.youtube.com/embed/xz845Sm0nRI?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Basic Session Troubleshooting <br /> (Facilitator)
            </>
          }
          source="https://www.youtube.com/embed/TPVoU1AlHdc?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Mentor Portal <br />
              (For Parents)
            </>
          }
          source="https://www.youtube.com/embed/mLG_fvAPc_U?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Mentor Portal <br />
              (For Home & School)
            </>
          }
          source="https://www.youtube.com/embed/_Qx1THWDKGI?autoplay=1&modestbranding=1"
        />
      </Row>
    </Page>
  )
}
