import React, { useEffect, useState } from 'react'
import { MobileMessageModal, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { Button, H2, P, Row, Spacer } from 'common/ui'
import { useSkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { getModuleDatesWithoutSkillUses } from 'skill-tracker/reducers'

export const FinishModulePrompt: React.FC<{ isOpen: boolean; onClose: () => void; onConfirm: () => void }> = ({
  isOpen: _isOpen,
  onClose,
  onConfirm,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(_isOpen)
  useEffect(() => setIsOpen(_isOpen), [_isOpen])
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  const handleConfirm = () => {
    delayedOnClose()
    onConfirm()
  }

  const { state } = useSkillTrackerState()
  const activeModule = state.modules.find(({ id }) => id === state.activeModuleId)
  const unfinishedDates = getModuleDatesWithoutSkillUses(activeModule, state.skillUses)
  const cnt = unfinishedDates.length
  return (
    <MobileMessageModal isOpen={isOpen} onRequestClose={delayedOnClose}>
      <H2 style={{ textAlign: 'center' }}>Finish this tracker?</H2>
      {/* <P style={{ textAlign: 'center' }}>Are you sure you want to finish the current module?</P> */}
      {cnt > 0 && (
        <P style={{ textAlign: 'center' }}>
          There are still {cnt} day{cnt > 1 && 's'} without any confirmed skill uses.
        </P>
      )}
      <Row justifyContent="center">
        <Button type="button" size="s" theme="blue" onClick={handleConfirm}>
          Finish
        </Button>
        <Spacer size="m" />
        <Button type="button" size="s" theme="white" onClick={delayedOnClose}>
          Close
        </Button>
      </Row>
    </MobileMessageModal>
  )
}
