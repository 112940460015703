import React from 'react'

import Modal from './Modal'
import { useGlobalState } from './GlobalState'
import { Inventory } from './Inventory'
import { GadgetIndex } from 'types'

export const InventoryModal: React.FC = () => {
  const { inventoryVisible, setInventoryVisible, inventoryGadgetToActivate, setActiveGadgetIndex } = useGlobalState()

  const activatingGadget = inventoryGadgetToActivate !== GadgetIndex.None

  const handleContinue = () => {
    setInventoryVisible(false)
    setActiveGadgetIndex(GadgetIndex.None)
    // maybe trigger event here instead of relying on useEffect monitoring elsewhere?
  }

  if (!inventoryVisible) {
    return null
  }

  return (
    <Modal isOpen onRequestClose={activatingGadget ? () => {} : () => setInventoryVisible(false)}>
      <Inventory
        activateGadget={inventoryGadgetToActivate}
        buttonLabel={activatingGadget ? 'Continue' : 'Close'}
        onContinue={handleContinue}
      />
    </Modal>
  )
}
