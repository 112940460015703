import { BaseAction } from '../types'
import { ActionTypes as ETelligenceActionTypes } from '../e-telligence/actionTypes'
import {
  GadgetPackState as State,
  IdCardState,
  StressBallState,
  FriendshipFormulaState,
  FactFile,
  FriendometerValue,
  FriendshipStrength,
  BionicPower,
  CustomBionicPower,
} from './types'

export const UPDATE_STATE = 'UPDATE_GADGET_PACK_STATE'
export const UPDATE_ID_CARD = 'UPDATE_ID_CARD'
export const ADD_ID_STRENGTH = 'ADD_ID_STRENGTH'
export const UPDATE_ID_STRENGTH = 'UPDATE_ID_STRENGTH'
export const UPDATE_STRESS_BALL = 'UPDATE_STRESS_BALL'
export const UPDATE_FRIENDSHIP_FORMULA = 'UPDATE_FRIENDSHIP_FORMULA'
export const ADD_FACT_FILE = 'ADD_FACT_FILE'
export const ADD_FACT_FILE_WITH_ID = 'ADD_FACT_FILE_WITH_ID'
export const UPDATE_FACT_FILE = 'UPDATE_FACT_FILE'
export const REMOVE_FACT_FILE = 'REMOVE_FACT_FILE'
export const UPDATE_FRIENDOMETER = 'UPDATE_FRIENDOMETER'
export const UPDATE_BIONIC_POWERS_SLOT = 'UPDATE_BIONIC_POWERS_SLOT'
export const ADD_CUSTOM_BIONIC_POWER = 'ADD_CUSTOM_BIONIC_POWER'
export const UPDATE_CUSTOM_BIONIC_POWER = 'UPDATE_CUSTOM_BIONIC_POWER'
export const REMOVE_CUSTOM_BIONIC_POWER = 'REMOVE_CUSTOM_BIONIC_POWER'

export interface UpdateStateAction extends BaseAction {
  type: typeof UPDATE_STATE
  state: State
}

export interface UpdateIdCardAction extends BaseAction {
  type: typeof UPDATE_ID_CARD
  data: Partial<IdCardState>
}

export interface AddIdStrengthAction extends BaseAction {
  type: typeof ADD_ID_STRENGTH
  strength: IdCardState['strengths'][0]
}

export interface UpdateIdStrengthAction extends BaseAction {
  type: typeof UPDATE_ID_STRENGTH
  index: number
  data: Partial<IdCardState['strengths'][0]>
}

export interface UpdateStressBallAction extends BaseAction {
  type: typeof UPDATE_STRESS_BALL
  data: Partial<StressBallState>
}

export interface UpdateFriendshipFormulaAction extends BaseAction {
  type: typeof UPDATE_FRIENDSHIP_FORMULA
  index: number
  data: Partial<FriendshipFormulaState['formulae'][0]>
}

export interface AddFactFileAction extends BaseAction {
  type: typeof ADD_FACT_FILE
  factFile: Omit<FactFile, 'id'>
}

export interface AddFactFileWithIdAction extends BaseAction {
  type: typeof ADD_FACT_FILE_WITH_ID
  factFile: FactFile
}

export interface UpdateFactFileAction extends BaseAction {
  type: typeof UPDATE_FACT_FILE
  id: FactFile['id']
  data: Partial<Omit<FactFile, 'id'>>
}

export interface RemoveFactFileAction extends BaseAction {
  type: typeof REMOVE_FACT_FILE
  id: FactFile['id']
}

export interface UpdateFriendometerAction extends BaseAction {
  type: typeof UPDATE_FRIENDOMETER
  friendship: FriendshipStrength
  key: FriendometerValue
  value: string
}

export interface UpdateBionicPowersSlotAction extends BaseAction {
  type: typeof UPDATE_BIONIC_POWERS_SLOT
  index: number
  id: BionicPower['id'] | null
}

export interface AddCustomBionicPowerAction extends BaseAction {
  type: typeof ADD_CUSTOM_BIONIC_POWER
  bionicPower: Omit<CustomBionicPower, 'id'>
}

export interface UpdateCustomBionicPowerAction extends BaseAction {
  type: typeof UPDATE_CUSTOM_BIONIC_POWER
  id: CustomBionicPower['id']
  data: Partial<Omit<CustomBionicPower, 'id'>>
}

export interface RemoveCustomBionicPowerAction extends BaseAction {
  type: typeof REMOVE_CUSTOM_BIONIC_POWER
  id: CustomBionicPower['id']
}

export type ActionTypes =
  | UpdateStateAction
  | ETelligenceActionTypes
  | UpdateIdCardAction
  | AddIdStrengthAction
  | UpdateIdStrengthAction
  | UpdateStressBallAction
  | UpdateFriendshipFormulaAction
  | AddFactFileAction
  | AddFactFileWithIdAction
  | UpdateFactFileAction
  | RemoveFactFileAction
  | UpdateFriendometerAction
  | UpdateBionicPowersSlotAction
  | AddCustomBionicPowerAction
  | UpdateCustomBionicPowerAction
  | RemoveCustomBionicPowerAction
