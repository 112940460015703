import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const labelTag: keyof JSX.IntrinsicElements = 'label'

type Size = 'l' | 'm' | 's'

interface Props {
  value?: boolean
  label?: string
  onSelect: (value: boolean) => void
  disabled?: boolean
  size?: Size
  wrapperProps?: ComponentProps<typeof RadioButtonLabel>
  indicatorProps?: ComponentProps<typeof RadioButtonIndicator>
  labelProps?: ComponentProps<typeof labelTag>
}

export const RadioButton: React.FC<Props> = ({
  disabled,
  value,
  label,
  onSelect,
  size = 'm',
  wrapperProps = {},
  indicatorProps = {},
  labelProps = {},
  children,
}) => {
  return (
    <RadioButtonLabel disabled={disabled} size={size} {...wrapperProps}>
      <DecoyInput
        type="radio"
        checked={!!value}
        onClick={() => onSelect(!value)}
        onChange={() => {}}
        disabled={disabled}
      />
      <RadioButtonIndicator checked={!!value} size={size} {...indicatorProps} />
      {children ? children : label && <label {...labelProps}>{label}</label>}
    </RadioButtonLabel>
  )
}

export const RadioButtonLabel = styled.label<{ disabled?: boolean; size: Size }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  opacity: ${p => (p.disabled ? 0.5 : 1)};
  & > label {
    margin-top: -0.1em;
    pointer-events: none;
  }
  &::after {
    content: '';
    position: absolute;
    width: calc(${p => indicatorSizes[p.size]} + 24px);
    height: calc(${p => indicatorSizes[p.size]} + 24px);
    left: calc(50% - 3px);
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    /* border: 1px dashed red; */
  }
`

const DecoyInput = styled.input`
  opacity: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
`

const indicatorSizes: { [key in Size]: string } = { l: '35px', m: '28px', s: '20px' }
const indicatorMargins: { [key in Size]: string } = { l: '0 20px 0 5px', m: '0 15px 0 5px', s: '0 10px 0 5px' }

const RadioButtonIndicator = styled.span<{ checked?: boolean; size: Size }>`
  width: ${p => indicatorSizes[p.size]};
  height: ${p => indicatorSizes[p.size]};
  background: white;
  border: 1px solid #abb5d8;
  border-radius: 50%;
  margin: ${p => indicatorMargins[p.size]};
  flex: none;

  ${DecoyInput}:focus + & {
    box-shadow: 0px 2px 5px 0px #adb2da;
  }

  ${DecoyInput}:active:enabled + & {
    transform: scale(0.9);
  }

  ${DecoyInput}:checked + & {
    background: ${p =>
      `linear-gradient(180deg, ${p.theme.appBackgroundTopColor} 0%, ${p.theme.appBackgroundBottomColor} 100%)`};
    box-shadow: inset 0px 0px 0px 3px white;
    @media print {
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      -ms-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

  ${DecoyInput}:checked:focus + & {
    box-shadow: inset 0px 0px 0px 3px white, 0px 2px 5px 0px #adb2da;
  }
`
