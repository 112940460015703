import React from 'react'
import styled from 'styled-components'
import { fontBold, fontLight, fontRegular } from 'fonts'

import { H1, P, Row, Spacer } from 'common/ui'
import { RadioButton } from 'session/common/RadioButton'
import { HeadingDivider } from './HeadingDivider'
import { TipsheetPrintButton, TipsheetPrintButtonProps } from 'tipsheets/TipsheetPrintButton'

interface Props {
  facilitatorName: string
  facilitatorEmail?: string
  facilitatorPhone?: string
  onSelect: () => void
  confirmRead: boolean
  printMode?: boolean
  printButtonProps?: TipsheetPrintButtonProps
}

const labelStyles = {
  fontFamily: 'Eurostile',
  lineHeight: '124%',
  letterSpacing: '0.03em',
  color: '#011A46',
}

export const TipsheetFooter: React.FC<Props> = ({
  facilitatorName,
  facilitatorEmail,
  facilitatorPhone,
  onSelect,
  confirmRead,
  printMode,
  printButtonProps,
}) => (
  <FooterContainer>
    <Spacer />
    {!printMode && (
      <>
        <TipsheetFooterPanel>
          <HeadingDivider heading="Mark as read" />
          <RadioButton
            labelProps={{ style: labelStyles }}
            size="s"
            label="I have read the tip sheet"
            value={confirmRead}
            onSelect={onSelect}
          />
          {printButtonProps && (
            <>
              <Spacer size="m" />
              <TipsheetPrintButton {...printButtonProps} size="s" children="Download as PDF" />
            </>
          )}
        </TipsheetFooterPanel>
        <Spacer />
      </>
    )}
    <TipsheetFooterPanel>
      <HeadingDivider heading="Your SAS Facilitator" />
      <LabelledDetails>
        <P>SAS Facilitator Name:</P>
        <P>{facilitatorName}</P>
      </LabelledDetails>
      {facilitatorEmail && (
        <>
          <Spacer />
          <LabelledDetails>
            <P>Email:</P>
            <P>{facilitatorEmail}</P>
          </LabelledDetails>
        </>
      )}
      {facilitatorPhone && (
        <>
          <Spacer />
          <LabelledDetails>
            <P>Phone:</P>
            <P>{facilitatorPhone}</P>
          </LabelledDetails>
        </>
      )}
    </TipsheetFooterPanel>
    <Spacer />
    <Footer>
      <Row alignItems="center">
        <div>
          <img src={require('../assets/footer-logo.png')} width={200} alt="" />
        </div>
        <P>
          © 2021 Renae Beaumont Enterprises Pty Ltd. <br />
          Limited copies may be made for school staff supporting the student. <br />
          Not to be copied or reproduced for any other purpose without specific permission
        </P>
      </Row>
    </Footer>
  </FooterContainer>
)

export const FooterContainer = styled.div``

export const TipsheetFooterPanel = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;

  ${H1} {
    color: ${p => p.theme.buttonBorderTopColor};
  }
`

const LabelledDetails = styled.div`
  ${P} {
    ${fontBold};
    margin: 0;
    font-size: 0.8rem;

    &:last-child {
      ${fontRegular};
      font-size: 1.3rem;
    }
  }
`

const Footer = styled(TipsheetFooterPanel)`
  ${Row} div {
    padding-right: 80px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      width: 1px;
      height: 70%;
      background: ${p => p.theme.buttonBorderTopColor};
    }
  }

  ${P} {
    ${fontLight};
    line-height: 124%;
    letter-spacing: 0.03em;
  }
`
