import React from 'react'
import styled from 'styled-components'
import { FriendometerScreen } from 'gadget-pack/Friendometer'
import { Container, Padding } from 'common/ui'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'

export const FriendometerActivity: React.FC = () => {
  const profileUid = useParticipantProfileUid()

  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${profileUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: 'friendometer',
          subcategory: null,
          event_key: null,
          event_value: null,
        }}
      />
      <FriendometerContainer>
        <FriendometerScreen
          hideExit={true}
          hideTitle
          inputLockProps={{
            fieldUid: `${profileUid}_friendometer`,
            verticalPlacementCadet: -30,
          }}
        />
      </FriendometerContainer>
    </>
  )
}

const FriendometerContainer = styled.div`
  ${Container} {
    width: 100%;
  }

  > ${Padding} {
    padding: 0;
  }
`
