import { isIE11 } from './deviceUtils'

declare global {
  interface Document {
    selection?: Selection
  }
}

export const deselectAll = () => {
  if (window.getSelection) {
    const selection = window.getSelection()
    if (selection) {
      // fix for ie11 https://stackoverflow.com/questions/16160996
      if (!isIE11()) {
        selection.removeAllRanges()
      } else if (selection.rangeCount > 0 && selection.getRangeAt(0).getClientRects().length > 0) {
        selection.removeAllRanges()
      }
    }
  } else if (document.selection) {
    document.selection.empty()
  }
}
