import { useRef } from 'react'
import ReactDOM from 'react-dom'

import { useUserState } from './UserState'
import { useInterval } from 'utils/useInterval'
import { getDrupalProfiles, refreshDrupalAccessToken, DrupalProfile } from 'api'
import { authorize } from 'auth/oauth2'

const HEARTBEAT_INTERVAL = 60000 * 15

export function useValidateDrupalProfile() {
  const {
    authProvider,
    accessToken,
    refreshToken,
    drupalProfile,
    setAccessToken,
    setRefreshToken,
    setTokenExpiry,
  } = useUserState()
  const failCountRef = useRef(0)
  const disableHeartbeat = !accessToken || authProvider !== 'sst'
  useInterval(
    () => {
      refreshDrupalAccessToken(refreshToken)
        .then(data => {
          ReactDOM.unstable_batchedUpdates(() => {
            setAccessToken(data.access_token)
            if (data.refresh_token) {
              setRefreshToken(data.refresh_token)
            }
            setTokenExpiry(Date.now() + data.expires_in * 1000)
          })
          return data.access_token
        })
        .then(getDrupalProfiles)
        .then(throwIfProfileMismatch(drupalProfile))
        .catch(() => {
          if (++failCountRef.current < 2) {
            console.log('Ignoring potentially transient error')
            return
          }
          authorize()
        })
    },
    disableHeartbeat ? null : HEARTBEAT_INTERVAL
  )
}

function throwIfProfileMismatch(profile: DrupalProfile | null) {
  return (profiles: DrupalProfile[]) => {
    if (profiles[0]?.profile_id !== profile?.profile_id) {
      throw new Error('Profile mismatch')
    }
  }
}
