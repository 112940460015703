import { useState, useEffect } from 'react'
import Sound from 'app/Sound'

export function useSoundPlaying(sound: Sound): boolean {
  const [state, setState] = useState(() => sound.playing())
  useEffect(() => {
    const playCallback = () => setState(true)
    const pauseCallback = () => setState(false)
    sound.on('play', playCallback)
    sound.on('pause', pauseCallback)
    sound.on('stop', pauseCallback)
    sound.on('end', pauseCallback)
    return () => {
      sound.off('play', playCallback)
      sound.off('pause', pauseCallback)
      sound.off('stop', pauseCallback)
      sound.off('end', pauseCallback)
    }
  }, [sound])

  // trigger setState if sound changes
  useEffect(() => {
    const playing = sound.playing()
    if (state !== playing) {
      setState(playing)
    }
  }, [state, sound])

  return state
}

/**
 * Similar functionality to useSoundPlaying, but supports not actually
 * having a sound, and defaulting to a fallback value in that case
 */
export function useSoundPlayingFallback(sound?: Sound, fallback: boolean = false): boolean {
  const [state, setState] = useState(() => (sound ? sound.playing() : fallback))
  useEffect(() => {
    if (!sound) return
    const playCallback = () => setState(true)
    const pauseCallback = () => setState(false)
    sound.on('play', playCallback)
    sound.on('pause', pauseCallback)
    sound.on('stop', pauseCallback)
    sound.on('end', pauseCallback)
    return () => {
      sound.off('play', playCallback)
      sound.off('pause', pauseCallback)
      sound.off('stop', pauseCallback)
      sound.off('end', pauseCallback)
    }
  }, [sound])

  // trigger setState if sound changes
  useEffect(() => {
    const playing = sound ? sound.playing() : fallback
    if (state !== playing) {
      setState(playing)
    }
  }, [state, sound, fallback])

  return sound ? state : fallback
}
