import { fontRegular } from 'fonts'
import React from 'react'
import styled from 'styled-components'

export const DisabledOverlay: React.FC<{ active: boolean; message?: string }> = ({ active, message, children }) =>
  !active ? <>{children}</> : <DisabledOverlayDiv message={message} children={children} />

const DisabledOverlayDiv = styled.div<{ message?: string }>`
    position: relative;
    & > * {
        opacity: 0.5;
        pointer-events: none;
    }
    &::after {
        content: '${p => p.message || ''}';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 280px;
        text-align: center;
        text-shadow: #fff 0 0 10px;
        ${fontRegular}
        font-size: 18px;
    }
`
