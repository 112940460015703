import { useRef, useState, useEffect, useMemo } from 'react'

// copied from
// https://github.com/alexreardon/use-memo-one

function areInputsEqual(newInputs: any[], lastInputs: any[]) {
  // no checks needed if the inputs length has changed
  if (newInputs.length !== lastInputs.length) {
    return false
  }
  // Using for loop for speed. It generally performs better than array.every
  // https://github.com/alexreardon/memoize-one/pull/59

  for (let i = 0; i < newInputs.length; i++) {
    // using shallow equality check
    if (newInputs[i] !== lastInputs[i]) {
      return false
    }
  }
  return true
}

interface Cache<T> {
  inputs?: any[]
  result: T
}

export function useMemoOne<T>(
  // getResult changes on every call,
  getResult: () => T,
  // the inputs array changes on every call
  inputs?: any[]
): T {
  // using useState to generate initial value as it is lazy
  const initial: Cache<T> = useState(() => ({
    inputs,
    result: getResult(),
  }))[0]

  const committed = useRef<Cache<T>>(initial)

  // persist any uncommitted changes after they have been committed

  const isInputMatch: boolean = Boolean(
    inputs && committed.current.inputs && areInputsEqual(inputs, committed.current.inputs)
  )

  // create a new cache if required
  const cache: Cache<T> = useMemo(
    () =>
      isInputMatch
        ? committed.current
        : {
            inputs,
            result: getResult(),
          },
    [getResult, inputs, isInputMatch]
  )

  // commit the cache
  useEffect(() => {
    committed.current = cache
  }, [cache])

  return cache.result
}
