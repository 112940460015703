import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'
import { Panel } from 'common/ui'
import { animated } from 'react-spring/renderprops'

export const SkillTrackerPanel = styled(Panel)`
  width: 100%;
  // height: 100%;
  max-height: 800px;
  min-width: 50vw;
  max-width: 1200px;
  overflow: none;
  border-radius: 7px;
  @media (max-height: 800px) {
    max-height: 100%;
  }
`

export const RouteTransitionWrapper = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
`

export const RouteTransition = ({ style, Component }: { style: CSSProperties; Component: FC }) => (
  <RouteTransitionWrapper style={{ ...style }}>
    <Component />
  </RouteTransitionWrapper>
)
