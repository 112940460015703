import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L } from 'common/ui'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { BionicPowersScreen } from 'gadget-pack/BionicPowers'

export const BionicPowersModal: React.FC<{ onClose: () => void; mentorMode?: boolean }> = ({ onClose, mentorMode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}
      panelStyle={mentorMode ? { minWidth: '100vw', minHeight: '100vh' } : {}}>
      <BionicPowersContainer mentorMode={mentorMode}>
        <BionicPowersScreen
          readonly
          overrideExit={delayedOnClose}
          hideDownload
          hideSave
          bgImage={require('session/assets/bionic-powers-bg.png')}
        />
      </BionicPowersContainer>
    </MobileModalPanel>
  )
}

const BionicPowersContainer = styled.div<{ mentorMode?: boolean }>`
  padding: ${p => (p.mentorMode ? '30px 0' : '30px')};

  ${Container} {
    min-width: ${p => (p.mentorMode ? '100%' : '700px')};
    width: 100%;
  }

  .bionic-suit-img {
    min-height: calc(100vh - 420px);
  }
`
