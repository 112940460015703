/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import GrapheneSplitter from 'grapheme-splitter'
import { useHistory, Link, useParams } from 'react-router-dom'

import { TextSymbolInput } from 'common/TextSymbolInput'
import {
  Row,
  Column,
  Spacer,
  Button,
  Panel,
  Padding,
  H1,
  H2,
  Absolute,
  TextInput,
  P,
  Container,
  Hr,
  RowWrapPortrait,
  RadioactiveButton,
  IconOutlineButton,
} from 'common/ui'
import { PrintGlobalStyle, PrintPreserveBackgrounds } from 'print/ui-print'
import { HeaderTemplate } from './common/HeaderTemplate'
import { Field } from 'common/Field'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import { Emoji } from 'common/Emoji'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'

import { PdfStatus } from 'common/PdfModal'
import { FactFile } from 'shared/gadget-pack/types'
import { ADD_FACT_FILE, UPDATE_FACT_FILE, REMOVE_FACT_FILE } from 'shared/gadget-pack/actionTypes'
import { baseUrl } from './GadgetPack'

import { intersperseSpacers } from 'utils/intersperseSpacers'
import { useGadgetPackState } from './GadgetPackState'
import { useUserState } from 'app/UserState'
import { createPdf } from 'api'
import { usePdfPayload } from 'utils/usePdfPayload'
import { isDevEnv } from 'utils/envUtils'
import { InputLockComponent, InputLockProps } from 'session/common/InputLockComponent'
import { factFileEmojis } from 'constants/emojiSets'

const emojis = factFileEmojis
const splitter = new GrapheneSplitter()

export const friendshipStrengths = [
  'Strangers',
  'People you have seen around',
  'Kind-of Friends',
  'Friends',
  'Best Friends',
]

export const FactFilesScreen: React.FC<{
  hideExitButton?: boolean
  overrideExit?: () => void
  disableRouting?: boolean
  createNewOverride?: () => void
  openExistingOverride?: (id: FactFile['id']) => void
  readOnly?: boolean
}> = ({
  hideExitButton = false,
  disableRouting = false,
  createNewOverride = () => {},
  openExistingOverride,
  overrideExit,
  readOnly,
}) => {
  const history = useHistory()
  const { getBaseAction } = useUserState()
  const { dispatch } = useGadgetPackState()
  const {
    state: { factFiles },
  } = useGadgetPackState()
  return (
    <HeaderTemplate
      onExit={() => (overrideExit ? overrideExit() : history.push(baseUrl))}
      hideExitButton={hideExitButton}>
      <Column justifyContent="center" alignItems="center" style={{ height: '100%', overflow: 'hidden' }}>
        <div>
          <Panel style={{ width: 640, maxWidth: 'calc(100vw - 30px)' }}>
            <Padding size="m">
              <H1 marginBottom="m">Secret Agent Fact Files</H1>
              {!readOnly && (
                <div>
                  <Button
                    onClick={() => (disableRouting ? createNewOverride() : history.push(baseUrl + '/fact-files/new'))}
                    children="Create New Fact File"
                    marginBottom="m"
                  />
                </div>
              )}
              {factFiles.length > 0 && <H2>Saved Files:</H2>}
              {factFiles.map((factFile, i) => (
                <Row key={i} justifyContent="space-between" alignItems="center" marginTop="s">
                  {!openExistingOverride && (
                    <Link
                      to={`${baseUrl}/fact-files/${factFile.id}`}
                      children={factFile.name || 'Untitled'}
                      style={{ display: 'block', color: '#22ACF3' }}
                    />
                  )}
                  {openExistingOverride && (
                    <span
                      onClick={() => openExistingOverride(factFile.id)}
                      children={factFile.name || 'Untitled'}
                      style={{ display: 'block', color: '#22ACF3', cursor: 'pointer', textDecoration: 'underline' }}
                    />
                  )}
                  <hr
                    style={{
                      flex: '1 1 auto',
                      height: 0,
                      border: 'none',
                      borderBottom: '1px dashed #d3d3d3',
                      margin: '0 15px',
                    }}
                  />
                  {!readOnly && (
                    <IconOutlineButton
                      size="s"
                      children="×"
                      style={{ textAlign: 'center', color: '#22ACF3', width: 21 }}
                      onClick={() =>
                        window.confirm(
                          `Are you sure you want to remove your fact file for ${factFile.name || 'Untitled'}?`
                        ) && dispatch({ ...getBaseAction(), type: REMOVE_FACT_FILE, id: factFile.id })
                      }
                    />
                  )}
                </Row>
              ))}
            </Padding>
          </Panel>
          {/* <Spacer size="m" />
          <img
            src={require('./assets/sas-wide.svg')?.default}
            alt="Secret Agent Society - Solving the mystery of social encounters"
          /> */}
        </div>
      </Column>
    </HeaderTemplate>
  )
}

const emptyFactFile: Omit<FactFile, 'id'> = {
  name: '',
  friendshipStrength: -1,
  why: {},
  family: {},
  interests: {},
  other: {},
}

interface FactFilePrintPayload {
  factFile: FactFile
}

export const FactFileScreen: React.FC<{
  hideDownloadButton?: boolean
  hidePrintButton?: boolean
  disableRouting?: boolean
  fileID?: string
  backToFilesOverride?: () => void
  readOnly?: boolean
  inputLockProps?: Omit<InputLockProps, 'children'>
}> = ({
  hideDownloadButton = false,
  hidePrintButton = false,
  disableRouting = false,
  fileID,
  backToFilesOverride,
  readOnly,
  inputLockProps,
}) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const fileId = fileID || id
  const { getBaseAction, accessToken } = useUserState()
  const [pdfStatus, setPdfStatus] = useState<PdfStatus>('ready')
  const [pdfUrl, setPdfUrl] = useState('')
  const isNew = useRef<boolean>(fileId === 'new')
  const awaitingCreation = useRef<boolean>(false)
  const {
    state: { factFiles },
    dispatch,
  } = useGadgetPackState()

  useEffect(() => {
    if (isNew.current && awaitingCreation.current && !disableRouting)
      history.push(`${baseUrl}/fact-files/${factFiles[factFiles.length - 1].id}`) // TODO:
  }, [disableRouting, factFiles, history])

  const factFile =
    factFiles.find((factFile) => factFile.id === fileId) || (isNew.current ? { ...emptyFactFile } : undefined)
  if (!fileId || !factFile) return <P>Not Found</P>

  const updateValue = <K extends keyof FactFile>(key: K, value: FactFile[K]) => {
    if (isNew.current && !awaitingCreation.current) {
      dispatch({ ...getBaseAction(), type: ADD_FACT_FILE, factFile: { ...emptyFactFile, [key]: value } })
      awaitingCreation.current = true
    } else {
      dispatch({ ...getBaseAction(), type: UPDATE_FACT_FILE, id: fileId, data: { [key]: value } })
    }
    // TODO: Show 'saved' popover?
  }
  const handleDownload = () => {
    const templateUrl = `${window.location.origin}/gadget-pack/fact-files/${id}/print`
    setPdfStatus('busy')
    createPdf<FactFilePrintPayload>(templateUrl, { factFile: factFile as FactFile }, accessToken)
      .then((pdf) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setPdfUrl(pdf.url)
          setPdfStatus('success')
        })
      })
      .catch(() => setPdfStatus('error'))
  }
  return (
    <>
      <HeaderTemplateContainer>
        <HeaderTemplate
          title=" "
          mediaBreakpoint="(max-width: 920px)"
          onExit={() => (backToFilesOverride ? backToFilesOverride() : history.push(baseUrl + '/fact-files'))}
          exitLabel="Back"
          Buttons={
            <>
              {!hideDownloadButton && (
                <Button
                  children={pdfStatus === 'busy' ? 'Processing...' : 'Download'}
                  disabled={pdfStatus === 'busy'}
                  onClick={handleDownload}
                />
              )}
              {isDevEnv() && !hidePrintButton && (
                <>
                  <Spacer size="s" />
                  <LinkButton to={`${baseUrl}/fact-files/${id}/print`} children="Print" />
                </>
              )}
            </>
          }
        />
      </HeaderTemplateContainer>
      <FactFileContainer>
        <Container size="l" style={{ position: 'relative' }}>
          <PanelTab alignItems="center" paddingLeft="m" marginTop="m">
            <H1>Secret Agent Fact File</H1>
          </PanelTab>
          <Panel style={{ pointerEvents: 'auto', zIndex: 2 }}>
            <Padding size="m">
              <Row alignItems="flex-end">
                <Field label="Person's Name" flex="1 1 auto">
                  <DebouncedInputComponent<string>
                    value={factFile.name || ''}
                    onChange={(text) => updateValue('name', text || '')}
                    children={({ value: textValue, onChange: debouncedTextOnChange }) => (
                      <InputLockComponent
                        {...inputLockProps}
                        verticalPlacementCadet={-4}
                        verticalPlacementFacilitator={-4}
                        horizontalPlacementFacilitator={0}
                        horizontalPlacementCadet={0}>
                        {(lockProps, lockState) => (
                          <TextInput
                            {...lockProps}
                            {...lockState}
                            inputSize="l"
                            value={textValue}
                            onChange={(e) => {
                              debouncedTextOnChange(e.target.value || '')
                              lockProps.onChange(e.target.value || '')
                            }}
                            disabled={readOnly || lockState.disabled}
                          />
                        )}
                      </InputLockComponent>
                    )}
                  />
                </Field>
                <Spacer size="m" />
                <img src={require('./assets/gadgets_factFiles_topSecret.png')} alt="Top Secret" width={100} />
              </Row>
            </Padding>
            <Hr />
            <Padding size="m">
              <Field label="Current Friendship Strength:">
                <RowFlipPortrait justifyContent="space-around">
                  {intersperseSpacers(
                    friendshipStrengths.map((friendshipStrength, i) => {
                      const selected = i === factFile.friendshipStrength
                      return (
                        <RadioactiveButton
                          key={i}
                          size="s"
                          children={
                            <div>
                              <span>{friendshipStrength}</span>
                            </div>
                          }
                          lighten={(friendshipStrengths.length - 1 - i) * 0.075}
                          selected={selected}
                          style={{ whiteSpace: 'pre-wrap', fontSize: 16, padding: '12px 20px' }}
                          flex={`1 1 ${100 / friendshipStrengths.length}%`}
                          onClick={() => (readOnly ? {} : updateValue('friendshipStrength', selected ? -1 : i))}
                        />
                      )
                    }),
                    'm'
                  )}
                </RowFlipPortrait>
              </Field>
            </Padding>
            <Hr />
            <Padding size="m">
              <RowWrapPortrait
                flexWrap
                justifyContent="stretch"
                style={{ maxWidth: 'calc(100vw - ' }}
                mediaQuery={'(max-width: 680px)'}>
                <Column style={{ minWidth: '40%' }} flex="1 1 auto">
                  <Field fieldProps={{ marginBottom: 's' }} label="Describe why they make a good friend:">
                    <TextSymbolInput
                      textarea
                      multipleSymbols
                      inputProps={{ inputSize: 'l' }}
                      textInputProps={{ placeholder: 'Add text' }}
                      symbols={emojis}
                      value={factFile.why || {}}
                      onChange={(value) => updateValue('why', value)}
                      readonly={readOnly}
                      debounce
                      inputLockProps={
                        !!inputLockProps ? { ...inputLockProps, fieldUid: `${inputLockProps?.fieldUid}_1` } : {}
                      }
                    />
                  </Field>
                </Column>
                <Spacer size="m" />
                <Column style={{ minWidth: '40%' }} flex="1 1 auto">
                  <Field fieldProps={{ marginBottom: 's' }} label="Describe their family members (including pets):">
                    <TextSymbolInput
                      textarea
                      multipleSymbols
                      fieldProps={{ marginBottom: 's' }}
                      inputProps={{ inputSize: 'l' }}
                      textInputProps={{ placeholder: 'Add text' }}
                      symbols={emojis}
                      value={factFile.family || {}}
                      onChange={(value) => updateValue('family', value)}
                      readonly={readOnly}
                      debounce
                      inputLockProps={
                        !!inputLockProps ? { ...inputLockProps, fieldUid: `${inputLockProps?.fieldUid}_2` } : {}
                      }
                    />
                  </Field>
                </Column>
                <Column style={{ minWidth: '40%' }} flex="1 1 auto">
                  <Field fieldProps={{ marginBottom: 's' }} label="Describe their interests:">
                    <TextSymbolInput
                      textarea
                      multipleSymbols
                      inputProps={{ inputSize: 'l' }}
                      textInputProps={{ placeholder: 'Add text' }}
                      symbols={emojis}
                      value={factFile.interests || {}}
                      onChange={(value) => updateValue('interests', value)}
                      readonly={readOnly}
                      debounce
                      inputLockProps={
                        !!inputLockProps ? { ...inputLockProps, fieldUid: `${inputLockProps?.fieldUid}_3` } : {}
                      }
                    />
                  </Field>
                </Column>
                <Spacer size="m" />
                <Column style={{ minWidth: '40%' }} flex="1 1 auto">
                  <Field fieldProps={{ marginBottom: 's' }} label="Other things you can talk to them about:">
                    <TextSymbolInput
                      textarea
                      multipleSymbols
                      inputProps={{ inputSize: 'l' }}
                      textInputProps={{ placeholder: 'Add text' }}
                      symbols={emojis}
                      value={factFile.other || {}}
                      onChange={(value) => updateValue('other', value)}
                      readonly={readOnly}
                      debounce
                      inputLockProps={
                        !!inputLockProps ? { ...inputLockProps, fieldUid: `${inputLockProps?.fieldUid}_4` } : {}
                      }
                    />
                  </Field>
                </Column>
              </RowWrapPortrait>
            </Padding>
          </Panel>
        </Container>
      </FactFileContainer>
      <MobileMessageModal isOpen={pdfStatus === 'success' && !!pdfUrl}>
        <H2 style={{ textAlign: 'center' }}>PDF download ready</H2>
        <Spacer size="m" />
        <Row justifyContent="center" style={{ marginBottom: -10 }}>
          <Button theme="white" onClick={() => setPdfUrl('')} children="Cancel" />
          <Spacer size="m" />
          <AnchorButton href={pdfUrl} children="Download" target="_blank" />
        </Row>
      </MobileMessageModal>
    </>
  )
}

export const FactFilePrint: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { state } = useGadgetPackState()
  const payload = usePdfPayload<FactFilePrintPayload>()
  const factFile: FactFilePrintPayload['factFile'] | undefined = payload
    ? payload.factFile
    : id
      ? state.factFiles.find((factFile) => factFile.id === id)
      : undefined
  if (!factFile) return <P>Not Found</P>
  return (
    <Column style={{ height: '100vh' }}>
      <PrintGlobalStyle landscape />
      <PrintPreserveBackgrounds />
      <Container size="l" style={{ position: 'relative' }}>
        <FactFileContainer>
          <PanelTab alignItems="center" paddingLeft="m" marginTop="m">
            <H1>Secret Agent Fact File</H1>
          </PanelTab>
          <Panel style={{ pointerEvents: 'auto', zIndex: 2 }}>
            <Padding size="m">
              <Row alignItems="center">
                <Field label="Person's Name" flex="1 1 auto">
                  <H1>{factFile.name}</H1>
                </Field>
                <Spacer size="m" />
                <img src={require('./assets/gadgets_factFiles_topSecret.png')} alt="Top Secret" width={100} />
              </Row>
            </Padding>
            <Hr />
            <Padding size="m">
              <Field label="Current Friendship Strength:">
                <RowFlipPortrait justifyContent="space-around" mediaQuery={'(max-width: 680px)'}>
                  {intersperseSpacers(
                    friendshipStrengths.map((friendshipStrength, i) => (
                      <RadioactiveButton
                        key={i}
                        size="s"
                        children={
                          <div>
                            <span>{friendshipStrength}</span>
                          </div>
                        }
                        disabled={i !== factFile.friendshipStrength}
                        selected={i === factFile.friendshipStrength}
                        style={{ whiteSpace: 'pre-wrap', fontSize: 16, padding: '12px 20px' }}
                        flex={`1 1 ${100 / friendshipStrengths.length}%`}
                      />
                    )),
                    'm'
                  )}
                </RowFlipPortrait>
              </Field>
            </Padding>
            <Hr />
            <Padding size="m">
              <RowWrapPortrait
                flexWrap
                justifyContent="stretch"
                style={{ maxWidth: 'calc(100vw - ' }}
                mediaQuery={'(max-width: 680px)'}>
                <Column style={{ width: '50%' }} flex="1 1 50%">
                  <Field
                    fieldProps={{ marginBottom: 's', paddingRight: 50 }}
                    label="Describe why they make a good friend:">
                    <TextSymbol value={factFile.why} />
                  </Field>
                </Column>
                <Column style={{ width: '50%' }} flex="1 1 50%">
                  <Field
                    fieldProps={{ marginBottom: 's', paddingRight: 50 }}
                    label="Describe their family members (including pets):">
                    <TextSymbol value={factFile.family} />
                  </Field>
                </Column>
                <Column style={{ width: '50%' }} flex="1 1 50%">
                  <Field fieldProps={{ marginBottom: 's', paddingRight: 50 }} label="Describe their interests:">
                    <TextSymbol value={factFile.interests} />
                  </Field>
                </Column>
                <Column style={{ width: '50%' }} flex="1 1 50%">
                  <Field
                    fieldProps={{ marginBottom: 's', paddingRight: 50 }}
                    label="Other things you can talk to them about:">
                    <TextSymbol value={factFile.other} />
                  </Field>
                </Column>
              </RowWrapPortrait>
            </Padding>
          </Panel>
        </FactFileContainer>
      </Container>
    </Column>
  )
}

const TextSymbol: React.FC<{ value: FactFile['why'] }> = ({ value }) => {
  return (
    <Row>
      <Column flex="1 1 auto">
        <P style={{ marginTop: 5 }}>{value.text}</P>
      </Column>
      <Spacer size="m" />
      <Column flex="none" style={{ fontSize: 48, width: 48 }}>
        {splitter.splitGraphemes(value.symbol || '').map((symbol, i) => (
          <Emoji key={i} children={symbol} />
        ))}
      </Column>
    </Row>
  )
}

const HeaderTemplateContainer = styled(Absolute)`
  top: 0;
  left: 0;
  right: 0;
`

export const FactFileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 10px 15px 10px;

  pointer-events: none;
  & ${Panel} {
    border-top-left-radius: 0;
    border-top: 1px solid white;
  }

  @media (max-width: 920px) {
    margin-top: 65px;
  }
`

export const PanelTab = styled(Row)`
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #c4cae6;
  background-color: ${(p) => p.theme.panelBg};
  border-top-left-radius: ${(p) => p.theme.borderRadius};
  width: 50%;
  height: 68px;
  box-shadow: 0px 5px 10px 0px ${(p) => p.theme.panelShadowColor};
  -webkit-print-color-adjust: exact;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 68px 0 0 30px;
    border-color: transparent transparent #c4cae6 ${(p) => p.theme.panelBg};
    box-shadow: 0 1px 0 0 #c4cae6;
  }
  & h1 {
    line-height: 0.9;
  }
  @media (max-width: 920px) {
    width: 65%;
  }
  @media (max-width: 640px) {
    width: 65%;
  }
`

const RowFlipPortrait = styled(Row)<{ mediaQuery?: string }>`
  @media ${(p) => p.mediaQuery || '(orientation: portrait)'} {
    & > ${Spacer} {
      width: 10px;
    }
    & > *:not(${Spacer}) {
      & > div {
        position: relative;
        overflow: visible;
        width: auto;
        height: 90px;
        padding: 0 5px;
        & > span {
          position: absolute;
          display: block;
          width: 90px;
          height: auto;
          top: 50%;
          left: 35%; /* To make up for line height */
          transform-origin: center center;
          transform: translateX(-50%) translateY(-50%) rotateZ(-90deg);
          text-align: center;
          line-height: 0.85;
        }
      }
    }
  }
`
