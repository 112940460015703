import React, { useState, useEffect } from 'react'

import { Padding, Column, Spacer, Button, P, Row, Hr, RowWrapPortrait } from 'common/ui'
import { Navigation, baseUrl } from './common/Navigation'
import { SkillTrackerPanel } from './common/SkillTrackerPanel'

import { useUserState } from 'app/UserState'
import { ViewToggleButtonGroup } from './RewardsScreen'
import { usePushNotification } from './PushNotificationContext'
import { isPushNotificationSupported, isRunningStandalone, isIOS, isAndroid } from 'utils/deviceUtils'
import { useHistory } from 'react-router-dom'
import { useSkillTrackerState } from './SkillTrackerState'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { useMedia } from 'utils/useMedia'
import { useLocalStorage } from 'utils/useStorage'

type SettingsView = 'notifications' | 'homescreen'

const steps = {
  ios: [
    {
      step: 1,
      image: require('./assets/ios-01.png'),
      text: 'Tap share button',
    },
    {
      step: 2,
      image: require('./assets/ios-02.png'),
      text: 'Scroll down',
    },
    {
      step: 3,
      image: require('./assets/ios-03.png'),
      text: 'Tap "Add to Home Screen"',
    },
    {
      step: 4,
      image: require('./assets/ios-04.png'),
      text: 'Tap "Add"',
    },
    {
      step: 5,
      image: require('./assets/ios-05.png'),
      text: '',
    },
  ],
  android: [
    {
      step: 1,
      image: require('./assets/android-01.png'),
      text: 'Tap the " ⋮ " icon (top right)',
    },
    {
      step: 2,
      image: require('./assets/android-02.png'),
      text: 'Tap "Add to Home Screen"',
    },
    {
      step: 3,
      image: require('./assets/android-03.png'),
      text: 'Tap "Add"',
    },
    {
      step: 4,
      image: require('./assets/android-04.png'),
      text: 'Tap "Add automatically"',
    },
    {
      step: 5,
      image: require('./assets/android-05.png'),
      text: '',
    },
  ],
}

export const SettingsScreen: React.FC = () => {
  const history = useHistory()
  const { getUserScopes } = useUserState()
  const { subscribe, unsubscribe, subscribed, errorSubscribing } = usePushNotification()
  const { roleState } = useSkillTrackerState()
  const { skipRewardsScreen } = roleState
  const [enabling, setEnabling] = useState<boolean>(false)
  const [homescreenStep, setHomescreenStep] = useState<number>(0)
  const [view, setView] = useLocalStorage<SettingsView>('ST_settingsView', 'notifications')
  const [platform, setPlatform] = useLocalStorage<'ios' | 'android' | null>('ST_platform', () =>
    isIOS() ? 'ios' : isAndroid() ? 'android' : null
  )

  const portrait = useMedia('(orientation: portrait)')
  const isAdult = getUserScopes().indexOf('mentor') >= 0
  const platformSteps = platform ? steps[platform] : []
  const step = platformSteps.length ? platformSteps[homescreenStep] : null

  useEffect(() => {
    if (enabling && (subscribed || errorSubscribing)) setEnabling(false)
  }, [subscribed, errorSubscribing, enabling])

  const pushSupported = isPushNotificationSupported()
  const standaloneMode = isRunningStandalone()

  const handleContinue = () => history.push(baseUrl + (isAdult && !skipRewardsScreen ? '/rewards' : '/practise'))

  return (
    <SkillTrackerPanel>
      <Navigation title={process.env.REACT_APP_SKILL_TRACKER_WEB_PUSH === '1' ? 'Settings' : 'Add to Homescreen'} />
      {process.env.REACT_APP_SKILL_TRACKER_WEB_PUSH === '1' && (
        <Column flex="none">
          <ViewToggleButtonGroup>
            <Button
              type="button"
              size="s"
              flex="1 1 auto"
              theme={view === 'notifications' ? 'blue' : 'gray'}
              onClick={() => setView('notifications')}>
              Notifications
            </Button>
            <Button
              type="button"
              size="s"
              flex="1 1 auto"
              theme={view === 'homescreen' ? 'blue' : 'gray'}
              onClick={() => setView('homescreen')}>
              Add to Homescreen
            </Button>
          </ViewToggleButtonGroup>
          <Hr />
        </Column>
      )}
      <Column flex="1 1 auto" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Padding size="s">
          {process.env.REACT_APP_SKILL_TRACKER_WEB_PUSH === '1' && view === 'notifications' && !pushSupported && (
            <P>
              Push notifications aren't supported on your current browser.
              <br />
              You can enable push notifications by using a modern browser like Chrome or Firefox or using Skill tracker
              on your phone.
            </P>
          )}
          {process.env.REACT_APP_SKILL_TRACKER_WEB_PUSH === '1' && view === 'notifications' && pushSupported && (
            <>
              {isAdult ? (
                <P>
                  Use the button below to enable/disable notifications for this device.
                  <br />
                  Notifications will only be used for Daily Tally Time reminders and when your cadet has unlocked a new
                  Bonus Reward.
                </P>
              ) : (
                <P>
                  Press ALLOW to be reminded of your Daily Tally Time and to let you know when a Bonus Reward has been
                  unlocked.
                </P>
              )}
              {!errorSubscribing && (
                <Row justifyContent="center">
                  <Button
                    size="s"
                    disabled={subscribed || enabling || errorSubscribing}
                    onClick={e => {
                      setEnabling(true)
                      subscribe(e)
                    }}>
                    {enabling ? 'Enabling...' : subscribed ? '✓ Enabled' : 'Enable'}
                  </Button>
                  <Spacer size="s" />
                  <Button size="s" disabled={!subscribed || errorSubscribing} onClick={unsubscribe}>
                    {subscribed ? 'Disable' : '✓ Disabled'}
                  </Button>
                  {!(errorSubscribing || subscribed) && (
                    <>
                      <Spacer size="s" />
                      <Button theme="white" size="s" onClick={() => handleContinue()}>
                        Skip
                      </Button>
                    </>
                  )}
                </Row>
              )}
              {errorSubscribing && (
                <P style={{ maxWidth: 460, margin: '0 auto', textAlign: 'center' }}>
                  <em>
                    Oops, something went wrong, not your fault though!
                    <br />
                    It's possible that notifications are already blocked for this site, or that your browser doesn't
                    support this feature yet, or that you're running in an incognito window.
                  </em>
                </P>
              )}
            </>
          )}
          {(view === 'homescreen' || process.env.REACT_APP_SKILL_TRACKER_WEB_PUSH !== '1') && standaloneMode && (
            <P>You're already running Skill Tracker from your homescreen, nice one!</P>
          )}
          {(view === 'homescreen' || process.env.REACT_APP_SKILL_TRACKER_WEB_PUSH !== '1') && !standaloneMode && (
            <>
              <RowWrapPortrait>
                <Column flex="1 1 auto">
                  <P>
                    You can access Skill Tracker on your phone!
                    <br />
                    Select the iOS or Android button below to view instructions on saving your Skill Tracker to your
                    home screen.
                  </P>
                  <Row justifyContent="flex-start">
                    <Button
                      size="s"
                      theme={platform === 'ios' ? 'blue' : 'white'}
                      onClick={e => {
                        setPlatform('ios')
                        setHomescreenStep(0)
                      }}>
                      {`${platform === 'ios' ? '✓' : ''} iOS`}
                    </Button>
                    <Spacer size="s" />
                    <Button
                      size="s"
                      theme={platform === 'android' ? 'blue' : 'white'}
                      onClick={e => {
                        setPlatform('android')
                        setHomescreenStep(0)
                      }}>
                      {`${platform === 'android' ? '✓' : ''} Android`}
                    </Button>
                  </Row>
                </Column>
                {step && (
                  <>
                    <Spacer size="m" />
                    <Column flex="none">
                      {portrait && <Spacer size="m" />}
                      <Row>
                        {intersperseSpacers(
                          [...Array(platformSteps.length)].map((_, i) => (
                            <Button
                              key={i}
                              size="xs"
                              onClick={() => setHomescreenStep(i)}
                              children={i + 1}
                              theme={homescreenStep === i ? 'blue' : 'white'}
                            />
                          )),
                          'xs'
                        )}
                      </Row>
                      <Spacer size="s" />
                      <div>
                        <img
                          alt={`Step ${step.step}`}
                          src={step.image}
                          onClick={() => setHomescreenStep((homescreenStep + 1) % platformSteps.length)}
                          style={{
                            cursor: 'pointer',
                            ...(portrait
                              ? { height: '100%', maxHeight: 'calc(100vh - 320px)' }
                              : { width: '100%', maxWidth: 180 }),
                          }}
                        />
                        <P style={{ margin: '5px 0 0 0' }}>{step.text}</P>
                      </div>
                    </Column>
                  </>
                )}
              </RowWrapPortrait>
            </>
          )}
        </Padding>
      </Column>
      <Row flex="none" style={{ borderTop: '1px solid #ABB4DB' }}>
        <Padding size="s" style={{ justifyContent: 'flex-end' }}>
          <Button size="s" onClick={() => handleContinue()}>
            Continue
          </Button>
        </Padding>
      </Row>
    </SkillTrackerPanel>
  )
}
