import { GameModeSlug } from 'shared/types'

export interface Option {
  value: string
  label?: React.ReactNode
  correct?: boolean
}

export interface Cuepoint {
  time: number
  value?: string
  frames?: Frame[]
}

export type Frame = {
  x: number
  y: number
  w: number
  h: number
  content?: { w: number; h: number }
  angle?: 'TR' | 'TL'
}

export enum GadgetIndex {
  None = -1,
  InvisibleInkReader,
  NightVisionContactLenses,
  ThoughtCatcher,
  VoiceFrequencyAmplifier,
  RubyLaserRing,
  SecretAgentStressBall,
  FingerprintIdentificationDevice,
  DVDRecordingGlasses,
  VoiceRecordingAttachment,
  MutedBulletAndShieldAttachments,
}

export interface FrameCuepoint extends Cuepoint {
  frames: Frame[]
}

export interface Activity {
  id: string
  section: 'game' | 'journal'
  title: string
  path?: string
  component?: React.ComponentType<ActivityProps>
  printComponent?: React.ComponentType<PrintActivityProps>
  level?: GameLevel
  prerequisite?: Activity
  canCompleteOffline?: boolean
}

export type AttemptResult = 'success' | 'pass' | 'fail' | 'complete' | 'incomplete'

export interface Attempt {
  profile_id?: number
  section: 'game' | 'journal'
  activity: string
  sub_activity: string | null
  result: AttemptResult | null
  score: string | null
  completed_at: number
  responses: Response[]
}

export interface Response {
  question: string
  sub_question?: string
  response: any
  correct?: boolean
  duration?: number
}

export interface GameMode {
  slug: GameModeSlug
  levels: Activity[]
  activities: Activity[]
}

export interface ActivityProps {
  activity: Activity
  onComplete: (routeState?: { [key: string]: any }) => void
}

export interface PrintActivityProps {
  activity: Activity
}

export interface ThoughtOption {
  value: string
  label: string
  correct?: boolean
  position: Vector
}

export type GameLevel = 1 | 2 | 3 | 4

export type KeyBindMulti = { [key: string]: Phaser.Input.Keyboard.Key }

export type SortType = 'ascending' | 'descending'

export type AnyObject = { [key: string]: any }

export type TouchControlType = 'joystick' | 'button'

export type Vector = { x: number; y: number }

export type Size = { width: number; height: number }

export type DeviceOrientation = 'landscape' | 'portrait'

export interface BaseTouchControlConfig {
  type: TouchControlType
  top?: number
  bottom?: number
  left?: number
  right?: number
  touchAreaScale?: number
}

export interface JoystickTouchControlConfig extends BaseTouchControlConfig {
  type: 'joystick'
  baseSize: number
  baseColor?: number
  nubSize: number
  nubColor?: number
  nubTravel: number
  lineColor?: number
  lineThickness?: number
  disableX?: boolean
  disableY?: boolean
}

export interface ButtonTouchControlConfig extends BaseTouchControlConfig {
  type: 'button'
  baseSize: number
  baseColor?: number
  label?: string
  labelColor?: number
}

export type TouchControlConfig = JoystickTouchControlConfig | ButtonTouchControlConfig

// Thank you more worthy TS gods https://stackoverflow.com/a/49725198/13326984
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]
