import { GenericUser, AuthProvider, UserScope, DrupalRole } from 'shared/types'
import { useUserState, UserState } from './UserState'
import { DrupalProfile } from 'api'
// import { isDevEnv } from 'utils/envUtils'

export function useGenericUser(): GenericUser {
  const { drupalProfile, profileName, profileId, authProvider, getUserScopes } = useUserState()

  if (authProvider !== 'sst' && authProvider !== 'sas') {
    throw new Error(`authProvider "${authProvider}" not supported`)
  }

  return {
    uid: getUserUid({ authProvider, drupalProfile, profileId }),
    userType: getUserType({ authProvider, drupalProfile, userScopes: getUserScopes() }),
    roles: getRoles(drupalProfile?.roles || ''),
    displayName: getUserDisplayName({ authProvider, drupalProfile, profileName }),
  }
}

export const getRoles = (rolesString: string): DrupalRole[] => [
  ...(rolesString.split(/,\W+?/) as DrupalRole[]),
  // ...(isDevEnv() ? (['administrator', 'sas-sg facilitator beta'] as DrupalRole[]) : []),
]

const isFacilitator = (roles: string[]): boolean =>
  roles.indexOf('sas facilitator') >= 0 ||
  roles.indexOf('sas-sg facilitator') >= 0 ||
  roles.indexOf('sas-sg facilitator beta') >= 0 ||
  roles.indexOf('sas-sg facilitator trainee') >= 0 ||
  roles.indexOf('sas-sg senior facilitator') >= 0 ||
  roles.indexOf('sas-sg senior facilitator trainee') >= 0 ||
  false

export const getUserUid = (props: {
  authProvider: AuthProvider
  drupalProfile?: DrupalProfile | null
  profileId: UserState['profileId']
}) => {
  // !This fixes a weird issue where authProvider points at sas when everything else points at SST
  if (props.authProvider === 'sas' && !props.profileId && props.drupalProfile)
    return `sst-${props.drupalProfile?.user_id}`

  if (props.authProvider === 'sst') return `${props.authProvider}-${props.drupalProfile?.user_id}`
  if (props.authProvider === 'sas') return `${props.authProvider}-${props.profileId}`
  console.error('authProvider is not defined', props.authProvider)

  // should not reach here, shouldn't happen but occasionally does, so we guess based on domain
  return `${window.location.origin === process.env.REACT_APP_CADET_URL ? 'sas' : 'sst'}-${props.profileId}`
  // throw new Error(`authProvider "${props.authProvider}" not supported`)
}

export type UserType = 'facilitator' | 'mentor' | 'agent'

export const getUserType = (props: {
  authProvider: AuthProvider
  drupalProfile?: DrupalProfile | null
  userScopes: UserScope[]
}): UserType => {
  const roles = getRoles(props.drupalProfile?.roles || '')
  if (props.authProvider === 'sst') {
    if (isFacilitator(roles)) return 'facilitator'
    return 'mentor'
  }
  // TODO: Remove this and just return 'agent' once mentor login isn't handled with gameplay code
  if (props.authProvider === 'sas') return props.userScopes.indexOf('mentor') < 0 ? 'agent' : 'mentor'

  // should not reach here, shouldn't happen but occasionally does, so we guess based on domain
  return window.location.origin === process.env.REACT_APP_CADET_URL ? 'agent' : 'mentor'
  // throw new Error(`authProvider "${props.authProvider}" not supported`)
}

export const getUserDisplayName = (props: {
  authProvider: AuthProvider
  drupalProfile?: DrupalProfile | null
  profileName: UserState['profileName']
}) => {
  if (props.authProvider === 'sst') {
    if (!props.drupalProfile) return 'SAS User'
    return typeof props.drupalProfile.first_name === 'string'
      ? `${props.drupalProfile.first_name} ${props.drupalProfile.last_name}`
      : props.drupalProfile.username
  }
  if (props.authProvider === 'sas') return props.profileName

  console.error('authProvider is not defined', props.authProvider)
  return props.profileName
  // throw new Error(`authProvider "${props.authProvider}" not supported`)
}
