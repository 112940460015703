import { Scene, Loader } from 'phaser'
import { UFO } from './Blimp'
import {
  ActivityThoughtTrackerState,
  ActivityThoughtTrackerStateValue,
  ThoughtTrackerUFOType as UFOType,
} from 'shared/session/types'
import { State } from './ThoughtTracker'
import { defaultProfileState } from 'shared/session/sections/custom/thought-tracker/reducer'

type DispatchProfileStateFunction = (stateValue: ActivityThoughtTrackerState[string]) => void
type DispatchProfileUfoStateFunction = (stateValue: ActivityThoughtTrackerStateValue, index: number) => void

export default class ThoughtTrackerScene extends Scene {
  private blimps: UFO[] = []
  private ufoType: UFOType = 'blimp'
  public profileState: ActivityThoughtTrackerState[number] = defaultProfileState
  public dispatchProfileState: DispatchProfileStateFunction = () => {}
  public dispatchProfileUfoState: DispatchProfileUfoStateFunction = () => {}
  public updateInputState: (state: State) => void = () => {}
  private initialised: boolean = false
  private exitedBlimpCount: number = 0

  get stageWidth(): number {
    return this.game.config.width as number
  }

  get stageHeight(): number {
    return this.game.config.height as number
  }

  initialise(ufoType: UFOType) {
    this.ufoType = ufoType
    if (!this.initialised) {
      this.loadImages()
      this.initialised = true
    } else {
      this.blimps.forEach(blimp => blimp.start(ufoType))
    }
  }

  updateState(state: ActivityThoughtTrackerState[number], dispatchStateFunction: DispatchProfileStateFunction) {
    if (!state || !state.thoughts.length) return

    const exitedBlimpCount = state.thoughts.filter(({ finishedExiting }) => finishedExiting).length
    if (this.exitedBlimpCount < exitedBlimpCount && exitedBlimpCount === 3) {
      this.dispatchProfileState({ ...this.profileState, gameOver: true })
      return
    }

    this.blimps.forEach((blimp, i) => blimp.updateState(state.thoughts[i]))

    if (state.selectingPlayer !== this.profileState.selectingPlayer) {
      if (!state.selectingPlayer) {
        this.initialise(state.ufoType)
      }
    }

    if (state.editMode !== this.profileState.editMode) {
      this.blimps.forEach((blimp, i) => blimp.setEditMode(state.editMode))
    }

    if (state.gameOver !== this.profileState.gameOver && state.gameOver) {
      this.reset()
    }

    if (state.resetCount !== this.profileState.resetCount) {
      this.reset()
    }

    this.dispatchProfileState = dispatchStateFunction
    this.dispatchProfileUfoState = (ufoState, index) => {
      this.dispatchProfileState({
        ...this.profileState,
        thoughts: this.profileState.thoughts.map((cur, i) => (i === index ? ufoState : cur)),
      })
    }
    this.profileState = state
  }

  onLoaded = () => {
    this.blimps.push(
      new UFO(
        this,
        { x: 200, y: this.stageHeight - 150 },
        { x: this.stageWidth - 200, y: this.stageHeight + 200 },
        this.ufoType,
        0xddf4fc,
        0.3,
        200,
        0
      ),
      new UFO(
        this,
        { x: this.stageWidth / 2, y: this.stageHeight / 2 },
        { x: this.stageWidth + 500, y: this.stageHeight / 2 - 200 },
        this.ufoType,
        null,
        0.7,
        0,
        1
      ),
      new UFO(
        this,
        { x: this.stageWidth - 250, y: 200 },
        { x: this.stageWidth + 300, y: -200 },
        this.ufoType,
        0xe3fcfe,
        0.4,
        500,
        2
      )
    )
  }

  loadImages() {
    this.load.image('blimp', require('./assets/blimp.png'))
    this.load.image('plane', require('./assets/plane.png'))
    this.load.image('string', require('./assets/string.png'))
    this.load.addListener(Loader.Events.COMPLETE, this.onLoaded)
    this.load.start()
  }

  reset() {
    this.blimps.forEach(blimp => blimp.reset())
    this.exitedBlimpCount = 0
  }

  update(delta: number) {
    // let gameEnd = true

    this.blimps.forEach(blimp => blimp.update(delta))

    // if (gameEnd && this.blimps.length >= 3) {
    //   this.endState(true)
    //   this.blimps.forEach(blimp => blimp.reset())
    // }

    if (this.blimps.length === 3) {
      this.updateInputState([this.blimps[0].getPosition(), this.blimps[1].getPosition(), this.blimps[2].getPosition()])
    }
  }
}
