/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useReducer, useRef, useEffect, useState } from 'react'
import format from 'date-fns/format'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'
import _isObject from 'lodash/isObject'
import _transform from 'lodash/transform'

import { Module, Skill, SkillUse, Reward, WorkflowType } from 'skill-tracker/types'
import { HomeScreen } from './SkillTracker'
import { actionTypeValidators } from './actionValidators'
import { getSkillTracker, saveSkillTracker, SkillTrackerResponse } from 'api'
import {
  ActionTypes,
  UpdateStateAction,
  UPDATE_STATE,
  UPDATE_WORKFLOW,
  UPDATE_ACTIVE_MODULE,
  ADD_UPDATE_SKILL,
  REMOVE_SKILL,
  ADD_UPDATE_REWARD,
  REMOVE_REWARD,
  ADD_UPDATE_SKILL_USE,
  REMOVE_SKILL_USE,
  ADD_UPDATE_MODULE,
  SET_MODULE_COMPLETED,
  UPDATE_SKILLS,
  UPDATE_ROLE_STATE_VALUES,
} from './actionTypes'

import { useHistory } from 'react-router-dom'
import { useLocalStorage } from 'utils/useStorage'
import { useUserState, UserState } from 'app/UserState'
import { useSubscribe } from './useSubscribe'
import { updateOrAppend, updateOrAppendMultiple, updateMultiple } from 'utils/reducerUtils'

type SkillTrackerState = ReturnType<typeof useProviderSkillTrackerState>

export interface RoleState {
  screen: HomeScreen
  skipIntro: boolean
  skipRewardsScreen: boolean
  firstModuleActivated: boolean
  seenBonusRewardIds: Reward['id'][]
  lastDownloadCheckInTime: string
}

export interface State {
  workflow: WorkflowType | null
  activeModuleId: string | null
  skills: Skill[]
  skillUses: SkillUse[]
  rewards: Reward[]
  modules: Module[]
  roleState: {
    agent: RoleState
    mentor: RoleState
  }
}

function reducer(state: State, action: ActionTypes): State {
  switch (action.type) {
    case UPDATE_STATE:
      return action.state
    case UPDATE_WORKFLOW:
      return { ...state, workflow: action.workflow }
    case UPDATE_ACTIVE_MODULE:
      return {
        ...state,
        activeModuleId: action.id,
        modules: updateOrAppend(state.modules, action.module, 'id'),
        skills: updateOrAppendMultiple(state.skills, action.skills, 'id'),
      }
    case ADD_UPDATE_MODULE:
      return {
        ...state,
        modules: updateOrAppend(state.modules, action.module, 'id'),
        activeModuleId: action.setActive ? action.module.id : state.activeModuleId,
      }
    case SET_MODULE_COMPLETED:
      return {
        ...state,
        modules: state.modules.map(module =>
          module.id === action.moduleId
            ? { ...module, completed: true, completionDateTime: format(Date.now(), 'y-MM-dd') }
            : module
        ),
      }
    case ADD_UPDATE_SKILL:
      return { ...state, skills: updateOrAppend(state.skills, action.skill, 'id') }
    case UPDATE_SKILLS:
      return { ...state, skills: updateMultiple(state.skills, 'id', action.skillIds, action.data) }
    case REMOVE_SKILL:
      return { ...state, skills: updateOrAppend(state.skills, { ...action.skill, removed: true }, 'id') }
    case ADD_UPDATE_REWARD:
      return { ...state, rewards: updateOrAppend(state.rewards, action.reward, 'id') }
    case REMOVE_REWARD:
      return { ...state, rewards: state.rewards.filter(test => test.id !== action.reward.id) }
    case ADD_UPDATE_SKILL_USE:
      return { ...state, skillUses: updateOrAppend(state.skillUses, action.skillUse, 'id') }
    case REMOVE_SKILL_USE:
      return { ...state, skillUses: state.skillUses.filter(test => test.id !== action.skillUse.id) }
    case UPDATE_ROLE_STATE_VALUES:
      return {
        ...state,
        roleState: {
          ...state.roleState,
          [action.role]: {
            ...state.roleState[action.role],
            ...action.data,
          },
        },
      }
    default:
      return state
  }
}

const initialState: State = {
  workflow: null,
  activeModuleId: null,
  skills: [],
  skillUses: [],
  rewards: [],
  modules: [],
  roleState: {
    agent: {
      screen: 'welcome',
      skipIntro: false,
      skipRewardsScreen: false,
      firstModuleActivated: false,
      seenBonusRewardIds: [],
      lastDownloadCheckInTime: '',
    },
    mentor: {
      screen: 'welcome',
      skipIntro: false,
      skipRewardsScreen: false,
      firstModuleActivated: false,
      seenBonusRewardIds: [],
      lastDownloadCheckInTime: '',
    },
  },
}

function difference(object: any, base: any) {
  return _transform(object, (result: any, value, key) => {
    if (!_isEqual(value, base[key])) {
      result[key] = _isObject(value) && _isObject(base[key]) ? difference(value, base[key]) : value
    }
  })
}

export function verifyState(currentState: State, actions: ActionTypes[]) {
  let state = { ...initialState }
  for (let action of actions) state = reducer(state, action)
  const sameSame = _isEqual(state, currentState)
  if (!sameSame) console.error(`State corrupt! Difference(s):`, difference(state, currentState))
  return sameSame
}

function verifyAndMigrateState<Type extends object>(currentState: Type, defaults: Type): Type {
  for (let key in defaults) {
    if (!(key in currentState)) {
      console.warn(`"${key}" doesn't exist in state... creating`, currentState)
      currentState[key] = defaults[key]
    } else if (_isObject(currentState[key])) {
      currentState[key] = verifyAndMigrateState(currentState[key] as any, defaults[key])
    }
  }
  return currentState
}

function validateActions(actions: ActionTypes[], state: State): [ActionTypes[], State] {
  let newState = { ...state }
  const newActions: ActionTypes[] = []
  for (let action of actions) {
    const validator = actionTypeValidators[action.type]
    // @ts-ignore
    if (validator(action, state)) {
      newState = reducer(newState, action)
      newActions.push(action)
    } else {
      // mark rejected action as such and save it back to local storage
      newActions.push({ ...action, rejected: true })
    }
  }
  return [newActions, newState]
}

function saveActions(data: {
  actions: ActionTypes[]
  state: State
  revision: number
  profileId: UserState['profileId']
  accessToken: UserState['accessToken']
  storeActionsLocally: (newActions: ActionTypes[]) => void
  updateRevision: (revision: number) => void
  handleSuccess: (state: State) => void
  handleError?: () => void
}) {
  const {
    actions = [],
    state,
    revision,
    profileId,
    accessToken,
    storeActionsLocally,
    updateRevision,
    handleSuccess,
    handleError,
  } = data
  console.log(`attempting to save actions`, actions)
  const newRevision = revision + 1
  saveSkillTracker(profileId, state, actions, newRevision, accessToken)
    .then(() => {
      console.log(`successfully saved actions`)
      updateRevision(newRevision)
      handleSuccess(state)
    })
    .catch(err => {
      console.log(`failed to saved actions`, err)
      if (err.conflict) {
        console.log(`submitted revision number conflicts with server's records`)
        let { state, revision } = err.conflict as SkillTrackerResponse
        if (state === null) state = { ...initialState }
        updateRevision(revision)
        const [newActions, newState] = validateActions(actions, state)
        console.log(`validated pending actions with server state, attempting resubmit`, newActions)
        saveActions({ ...data, revision, actions: newActions, state: newState })
      } else {
        console.log(`saving actions to local storage for future submission to server`)
        // append actions to local storage
        storeActionsLocally(actions)
        if (handleError) handleError()
      }
    })
}

export const getLocalStorageKey = (state: Pick<UserState, 'profileId' | 'getUserScopes' | 'openAccess'>): string => {
  const isAdult = state.getUserScopes().indexOf('mentor') >= 0
  return `${state.profileId}_${isAdult ? 'adult' : 'mentor'}${state.openAccess ? '_open_access' : ''}`
}

let firstLaunch = true

function useProviderSkillTrackerState() {
  const history = useHistory()
  const { profileId, openAccess, accessToken, getBaseAction, getUserScopes } = useUserState()
  const prefix = getLocalStorageKey({ profileId, getUserScopes, openAccess })

  const [loadingInitialState, setLoadingInitialState] = useState<boolean>(false)
  const [localState, setLocalState] = useLocalStorage<State>(`${prefix}_ST_state`, initialState)
  const [localActions, setLocalActions] = useLocalStorage<ActionTypes[]>(`${prefix}_ST_actions`, [])
  const [localRevision, setLocalRevision] = useLocalStorage<number>(`${prefix}_ST_revision`, 0)

  const [state, dispatch] = useReducer(reducer, localState)

  const persistentState = useRef(state)

  const isAdult = getUserScopes().indexOf('mentor') >= 0

  if (firstLaunch && !profileId && _isEqual(localState, initialState)) {
    firstLaunch = false
    console.warn('Logged out on first launch! Time to log in again!')
    history.push('/')
  }

  const overwriteState = (state: State) => {
    console.log(`overwriting state`, state)
    state = verifyAndMigrateState(state, initialState)
    persistentState.current = state
    setLocalState(state)
    const stateUpdateAction: UpdateStateAction = { ...getBaseAction(), type: UPDATE_STATE, state }
    dispatch(stateUpdateAction)
  }

  const preDispatch = (action: ActionTypes) => {
    // optimistically update
    console.log(action.type, 'action dispatched')

    persistentState.current = reducer(persistentState.current, action)
    setLocalState(persistentState.current)
    dispatch(action)

    const actions = [...localActions, action]
    setLocalActions([])
    saveActions({
      actions,
      state: persistentState.current,
      revision: localRevision,
      profileId,
      accessToken,
      storeActionsLocally: newActions => setLocalActions(localActions => [...localActions, ...newActions]),
      updateRevision: setLocalRevision,
      handleSuccess: overwriteState,
    })
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setLoadingInitialState(true)
    const hasOfflineActions = localState && localActions.length > 0
    getSkillTracker(profileId, accessToken)
      .then(({ state, revision }) => {
        console.log(`got server state`)
        // check if user hasn't used skill tracker before
        if (state === null) {
          console.log(`user has no state saved on server`)
          if (hasOfflineActions) {
            console.log(`but user has offline data to process`)
            saveActions({
              actions: localActions,
              state: localState,
              revision: localRevision,
              profileId,
              accessToken,
              storeActionsLocally: newActions => setLocalActions(localActions => [...localActions, ...newActions]),
              updateRevision: setLocalRevision,
              handleSuccess: newState => {
                overwriteState(newState)
                setLoadingInitialState(false)
              },
              handleError: () => setLoadingInitialState(false),
            })
          } else {
            // do nothing -- initial state will be created upon first action dispatch
            console.log(`do nothing -- initial state will be created upon first action dispatch`)
            setLoadingInitialState(false)
          }
        } else {
          if (hasOfflineActions) {
            console.log(`updating local revision to ${revision}`)
            setLocalRevision(revision)

            console.log(`conflict! have to merge offline actions with latest server state`)
            const [newActions, newState] = validateActions(localActions, state)
            saveActions({
              actions: newActions,
              state: newState,
              revision,
              profileId,
              accessToken,
              storeActionsLocally: newActions => setLocalActions(localActions => [...localActions, ...newActions]),
              updateRevision: setLocalRevision,
              handleSuccess: newState => {
                overwriteState(newState)
                setLoadingInitialState(false)
              },
              handleError: () => setLoadingInitialState(false),
            })
          } else {
            // update local revision number
            console.log(`updating local revision to ${revision}`)
            setLocalRevision(revision)
            overwriteState(state)
            setLoadingInitialState(false)
          }
        }
      })
      .catch(() => {
        // if offline restore local state or init
        if (localState) overwriteState(localState)
        else overwriteState(initialState)
        setLoadingInitialState(false)
      })
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  useSubscribe(profileId, localRevision, () => {
    console.log('new revision on server!')
    getSkillTracker(profileId, accessToken)
      .then(({ state, revision }) => {
        if (!state) return
        const hasOfflineActions = localState && localActions.length > 0
        if (hasOfflineActions) {
          console.log(`updating local revision to ${revision}`)
          setLocalRevision(revision)

          console.log(`conflict! have to merge offline actions with latest server state`)
          const [newActions, newState] = validateActions(localActions, state)
          saveActions({
            actions: newActions,
            state: newState,
            revision,
            profileId,
            accessToken,
            storeActionsLocally: newActions => setLocalActions(localActions => [...localActions, ...newActions]),
            updateRevision: setLocalRevision,
            handleSuccess: overwriteState,
          })
        } else {
          // update local revision number
          console.log(`updating local revision to ${revision}`)
          setLocalRevision(revision)
          overwriteState(state)
        }
      })
      .catch(() => console.log('Fetching latest revision failed'))
  })

  return {
    state,
    roleState: (isAdult ? state.roleState?.mentor : state.roleState?.agent) || {},
    actions: localActions,
    revision: localRevision,
    dispatch: preDispatch,
    loadingInitialState,
    storagePrefix: prefix,
  }
}

function noop(): any {}

export const SkillTrackerStateContext = React.createContext<SkillTrackerState>({
  state: initialState,
  roleState: initialState.roleState.mentor,
  actions: [],
  revision: 0,
  dispatch: noop,
  loadingInitialState: false,
  storagePrefix: '',
})

export const SkillTrackerStateProvider: React.FC = ({ children }) => {
  const state = useProviderSkillTrackerState()
  return <SkillTrackerStateContext.Provider value={state}>{children}</SkillTrackerStateContext.Provider>
}

export function useSkillTrackerState() {
  return useContext(SkillTrackerStateContext)
}
