import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { fontRegular, fontBold } from 'fonts'

import { HydratedSlide } from 'shared/session/types'
import { Row, Column, Padding, IconButton, Textarea, H3, Hr, P } from 'common/ui'
import { InputContext, useInputContext } from './InputContext'
import { InputComponent } from './common/InputComponent'
import { Checkbox } from 'common/Checkbox'
import { useSessionState } from './SessionState'
import { useFacilitatorState } from './hooks/useProfileState'
import { FacManTrayView } from 'fac-man/FacManModule'
import { UPDATE_FACILITATOR_MANUAL_STEP } from 'shared/session/actionTypes'
import { useLocalStorage } from 'utils/useStorage'
import { SVG } from 'common/SVG'

type FooterTabView = 'checklist' | 'notes' | 'manual'
const footerTabs: { [key in FooterTabView]: string } = {
  checklist: 'Checklist',
  notes: 'Notes',
  manual: 'Manual',
}
export const FacilitatorFooterTabs: React.FC<{ currentSlide?: HydratedSlide; pastMode?: boolean }> = ({
  currentSlide,
  pastMode,
}) => {
  const facilitatorState = useFacilitatorState()
  const inputContext = useInputContext()
  const { dispatch, sessionData, manual, previewing, getBaseAction, isAssistant } = useSessionState()
  const [activeTab, setActiveTab] = useState<undefined | FooterTabView>()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [presentationModeActive, setPresentationModeActive] = useState<boolean>(false)

  const [facilitatorNotes, setFacilitatorNotes] = useLocalStorage<string>(
    `facilitatorNotes-${sessionData ? sessionData.uid : ''}`,
    ''
  )

  const handleTabSelect = (newTab: FooterTabView) => {
    if (newTab === activeTab) {
      setExpanded(!expanded)
    } else {
      ReactDOM.unstable_batchedUpdates(() => {
        setActiveTab(newTab)
        setExpanded(true)
      })
    }
  }

  const presentationWindow = useRef<Window | null>(null)
  const launchPresentationMode = () => {
    if (!sessionData) return
    if (presentationWindow.current) {
      presentationWindow.current.close()
    } else {
      presentationWindow.current = window.open(
        `${window.location.origin}/facilitator/meetings/live/presentation-mode/${sessionData.uid}`,
        'FacilitatorPresentationMode',
        'menubar=0,toolbar=0,location=1,status=0,resizable=1,scrollbars=1',
        // @ts-expect-error no idea why this was here but not going to change it now
        true
      )
      if (presentationWindow.current) {
        setPresentationModeActive(true)
        presentationWindow.current.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
          setPresentationModeActive(false)
          presentationWindow.current = null
        })
      }
    }
  }

  if (!facilitatorState) return null

  if (manual && sessionData && currentSlide?.type === 'MODULE_SLIDE') {
    const manualStep = manual.manual_steps.find(step => step.id === currentSlide.step.manual_step_id)
    if (manualStep && manualStep.uid !== facilitatorState.currentManualStepUid) {
      dispatch({
        ...getBaseAction(),
        type: UPDATE_FACILITATOR_MANUAL_STEP,
        facilitatorUid: facilitatorState.profile.uid,
        stepUid: manualStep.uid,
      })
    }
  }

  return (
    <BodyFooter>
      <FooterTabs>
        {(Object.keys(footerTabs) as FooterTabView[])
          .filter(key => {
            // only facilitators should see the manual
            if (key === 'manual' && isAssistant) {
              return false
            }
            return true
          })
          .map(key => (
            <FooterTab
              key={key}
              active={activeTab === key}
              expanded={expanded}
              onClick={() => handleTabSelect(key)}
              children={footerTabs[key]}
            />
          ))}
        {sessionData && sessionData.module.type === 'parent' && (
          <FooterTabButton active={presentationModeActive} onClick={() => launchPresentationMode()}>
            <SVG
              viewboxSize={20}
              size={24}
              children={
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 2H19V12C19 13.1046 18.1046 14 17 14H11V15.4338L15.5145 18.1425L14.4855 19.8575L11 17.7662V20H9V17.7662L5.5145 19.8575L4.4855 18.1425L9 15.4338V14H3C1.89543 14 1 13.1046 1 12V2H0V0H20V2ZM3 2V12H17V2H3ZM6 10H8V6H6V10ZM11 10H9V4H11V10ZM12 10H14V7H12V10Z"
                  fill="#011A46"
                />
              }
            />
          </FooterTabButton>
        )}
      </FooterTabs>
      <FooterTray expanded={expanded}>
        <FooterTrayCloseButton onClick={() => setExpanded(false)}>
          <span>Close</span>
          <IconButton theme="purple" children="×" />
        </FooterTrayCloseButton>
        {activeTab && (
          <Padding size="s" style={{ height: '100%' }}>
            <H3>{footerTabs[activeTab].toUpperCase()}</H3>
            <Hr margin="s" style={{ borderTopColor: '#7D41DF' }} />
            {activeTab === 'checklist' && (
              <>
                {currentSlide && currentSlide.type === 'SUPPLEMENTARY_SLIDE' && (
                  <P>Supplementary slides don't have facilitator steps</P>
                )}
                {currentSlide &&
                  currentSlide.type === 'MODULE_SLIDE' &&
                  (currentSlide.step.facilitator_steps || []).length === 0 && (
                    <P>This slide doesn't have a facilitator checklist</P>
                  )}
                {currentSlide && currentSlide.type === 'MODULE_SLIDE' && currentSlide.step.facilitator_steps && (
                  <FacilitatorChecklist key={`checklist-${currentSlide.step.uid}`}>
                    {currentSlide.step.facilitator_steps
                      .filter(_ => _)
                      .map((checklistItem, i) => (
                        <InputContext.Provider
                          key={i}
                          value={{
                            ...inputContext,
                            owner: 'Steps',
                            owner_id: currentSlide.step.id,
                            participant_uid: 'shared',
                          }}>
                          <InputComponent<boolean>
                            name={`Facilitator_${sessionData ? sessionData.group_id : ''}_ChecklistItem_${i}`}
                            defaultValue={false}>
                            {({ value, onChange }) => (
                              <Checkbox
                                size="s"
                                checked={value || false}
                                onChange={onChange}
                                wrapperProps={{ style: { marginBottom: 8 } }}
                                children={checklistItem}
                                disabled={pastMode}
                              />
                            )}
                          </InputComponent>
                        </InputContext.Provider>
                      ))}
                  </FacilitatorChecklist>
                )}
              </>
            )}
            {activeTab === 'notes' && sessionData && (
              <Textarea
                style={{ flex: '1 1 auto' }}
                value={facilitatorNotes}
                disabled={pastMode}
                onChange={e => setFacilitatorNotes(e.target.value || '')}
              />
            )}
            {activeTab === 'manual' && (
              <div style={{ height: '100%', overflow: 'auto' }}>
                {manual ? (
                  <FacManTrayView hideHeader facManModule={manual} />
                ) : previewing ? (
                  <P>Facilitator Manual is not available in preview mode</P>
                ) : (
                  <P>Couldn't Locate Facilitator Manual</P>
                )}
              </div>
            )}
          </Padding>
        )}
      </FooterTray>
    </BodyFooter>
  )
}

const FooterTrayCloseButton = styled(Row)`
  ${fontBold}
  position: absolute;
  bottom: 100%;
  right: 0;
  padding: 10px 10px 5px 20px;
  align-items: center;
  background-color: inherit;
  border-top-left-radius: 10px;
  text-transform: uppercase;
  font-size: 10px;
  user-select: none;
  cursor: pointer;

  & ${IconButton} {
    margin-left: 10px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    pointer-events: none;
    &::before,
    &::after {
      content: none;
    }
  }
`

const FooterTray = styled(Column)<{ expanded?: boolean }>`
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  height: calc(50vh - 90px);
  min-height: 200px;
  max-height: 600px;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  opacity: ${p => (p.expanded ? 1 : 0.5)};
  transform: translateY(${p => (p.expanded ? 0 : 100)}%);
  background-color: #edf2fa;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

  & ${FooterTrayCloseButton} {
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.12);
    transition: all 0.35s ease-in-out;
    opacity: ${p => (p.expanded ? 1 : 0)};
  }
`

const BodyFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const FooterTabs = styled(Row)`
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const FooterTab = styled(Column)<{ active?: boolean; expanded?: boolean }>`
  ${fontRegular}
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-top: 1px solid #edf2fa;
  border-bottom: 1px solid #edf2fa;
  background-color: ${p => (p.active ? (p.expanded ? '#7D41DF' : '#EDF2FA') : '#ffffff')};
  color: ${p => (p.active && p.expanded ? '#ffffff' : '#011A46')};
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;

  & + & {
    border-left: 1px solid #edf2fa;
  }
`

const FooterTabButton = styled(FooterTab)<{ active?: boolean }>`
  flex: 0 0 40px;
  width: 40px;
`

const FacilitatorChecklist = styled.div`
  overflow-y: auto;
  padding-bottom: 30px;
`
