import React from 'react'
import styled from 'styled-components'
import { fontRegular } from 'fonts'

export interface SelectProps<OptionType, ValueType> {
  options: OptionType[]
  value: string
  onChange: (value: ValueType, option?: OptionType) => void
  readOnly?: boolean
  disabled?: boolean
  empty?: string | false
  error?: boolean
}

export function Select<
  OptionType extends { value: ValueType; label?: React.ReactNode; disabled?: boolean },
  ValueType extends string = string
>({
  options,
  empty = 'Please select ...',
  value,
  readOnly,
  disabled,
  onChange,
  error = false,
}: SelectProps<OptionType, ValueType>) {
  function handleChange(value: string) {
    const option = options.find(o => o.value === value)
    if (option) {
      onChange(option.value, option)
    } else {
      onChange(('' as unknown) as ValueType)
    }
  }

  return (
    <Container error={error}>
      <StyledSelect
        value={value}
        onChange={event => !readOnly && !disabled && handleChange(event.target.value)}
        error={error}
        disabled={readOnly || disabled}>
        {renderOptions<OptionType, ValueType>(options, empty)}
      </StyledSelect>
    </Container>
  )
}

function renderOptions<
  OptionType extends { value: ValueType; label?: React.ReactNode; disabled?: boolean },
  ValueType extends string = string
>(options: SelectProps<OptionType, ValueType>['options'], empty: SelectProps<OptionType, ValueType>['empty']) {
  const emptyOption = empty !== false && (
    <option key={0} value="">
      {empty}
    </option>
  )
  const valueOptions = options.map(({ label, value, disabled, ...props }, i) => (
    <option {...props} key={i + 1} value={value} disabled={disabled}>
      {label || value}
    </option>
  ))
  return emptyOption ? [emptyOption, ...valueOptions] : valueOptions
}

const Container = styled.label<{ error: boolean }>`
  display: block;
  position: relative;

  ::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid ${p => (p.error ? 'red' : p.theme.buttonFocusBorderBottomColor)};
    content: '';
    height: 0;
    margin-top: -3px;
    pointer-events: none;
    position: absolute;
    right: 1em;
    top: 50%;
    width: 0;
  }
`

const StyledSelect = styled.select<{ error: boolean }>`
  ${fontRegular}

  display: block;
  width: 100%;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d5d7dd;
  border-radius: 0.25em;
  box-shadow: none;
  color: #001947;
  font-size: 16px;
  height: 2.5rem;
  margin: 0;
  outline: 0;
  padding: 0 1em;

  ${p => (p.error ? ':enabled,' : '')} :focus {
    box-shadow: 0 0 0 2px ${p => (p.error ? 'red' : p.theme.buttonBorderTopColor)};
  }

  &:disabled {
    opacity: 0.8;
    background-color: #d5d7dd;
  }
`
