import { logoutDrupalUser as apiCall } from 'api'
import { getDrupalSessionToken } from './oauth2'

export function logoutDrupalUser(callback?: () => void) {
  const sessionToken = getDrupalSessionToken()
  if (!sessionToken) {
    callback?.()
  } else {
    apiCall(sessionToken)
      .then(() => setTimeout(() => callback?.()))
      .catch(() => callback?.())
  }
}
