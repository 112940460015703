import React from 'react'
import styled from 'styled-components'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { Row, Button, Spacer, P } from 'common/ui'
import { BoardGameCard, BoardGameCardDeck, MediaAsset } from 'shared/session/types'
import { images } from './assets'
import { Switch } from 'common/Switch'

interface DeckCardModalProps {
  isOpen: boolean
  deckCard: BoardGameCard | null
  deck: BoardGameCardDeck | null
  isFacilitator: boolean
  onNext: () => void
  onPrevious: () => void
  onClose: () => void
  onPoster: () => void
  setPoster: (id: 0 | 1 | 2 | 3 | null) => void
  onShare: () => void
  disableNext: boolean
  disablePrevious: boolean
  sharing: boolean
  postersOpen: boolean
  selectedPoster: 0 | 1 | 2 | 3 | null
}

export const DeckCardModal: React.FC<DeckCardModalProps> = ({
  isOpen,
  onNext,
  onPrevious,
  onClose,
  onPoster,
  onShare,
  setPoster,
  deckCard,
  deck,
  isFacilitator,
  disableNext,
  disablePrevious,
  sharing,
  postersOpen,
  selectedPoster,
}) => {
  // @ts-ignore
  const deckImage = deckCard && deckCard.uid in images ? images[deckCard.uid] : undefined
  const deckImagePath = deckImage ? `${deckImage.filename}.${deckImage.extension}` : undefined
  // @ts-ignore
  const deckCover = deck ? (images[`deck${deck.id}`] as MediaAsset | undefined) : undefined
  const deckCoverPath = deckCover ? `${deckCover.filename}.${deckCover.extension}` : undefined
  const shownImage = isFacilitator ? deckImagePath : sharing ? deckImagePath : deckCoverPath

  const posters = {
    0: require('./assets/Corner_Code_posters_1.png'),
    1: require('./assets/Corner_Code_posters_2.png'),
    2: require('./assets/Corner_Code_posters_3.png'),
    3: require('./assets/Corner_Code_posters_4.png'),
  }

  const contentVisible = isFacilitator || sharing

  return (
    <MobileMessageModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={isFacilitator}
      style={{ content: { maxHeight: '100%' } }}
      panelStyle={{
        width: '50vw',
        maxWidth: isFacilitator ? 400 : 600,
        minWidth: isFacilitator ? 400 : 550,
        borderBottom: 'none',
      }}>
      {deckCard && (
        <>
          {isFacilitator && (
            <FixedTop>
              <Row justifyContent="space-between" alignItems="center" style={{ marginBottom: 10 }}>
                <Row alignItems="center">
                  <P children="Show Cadets" />
                  <Spacer size="s" />
                  <Switch size="m" value={sharing} onChange={onShare} />
                </Row>
                <Button type="button" size="s" onClick={onClose} children="Close" />
              </Row>
            </FixedTop>
          )}
          <ModalContent>
            {((!isFacilitator && !contentVisible) || !postersOpen) && (
              <DeckCardImage src={shownImage ? require(`${shownImage}`) : undefined} />
            )}
            {postersOpen && selectedPoster === null && contentVisible && (
              <Posters>
                <img
                  src={posters['0']}
                  alt=""
                  onClick={() => {
                    setPoster(0)
                  }}
                />
                <img
                  src={posters['1']}
                  alt=""
                  onClick={() => {
                    setPoster(1)
                  }}
                />
                <img
                  src={posters['2']}
                  alt=""
                  onClick={() => {
                    setPoster(2)
                  }}
                />
                <img
                  src={posters['3']}
                  alt=""
                  onClick={() => {
                    setPoster(3)
                  }}
                />
              </Posters>
            )}
            {selectedPoster !== null && contentVisible && <SinglePoster src={posters[selectedPoster]}></SinglePoster>}
          </ModalContent>
          {isFacilitator && (
            <FixedBottom>
              <Row justifyContent="center" style={{ marginTop: 10 }}>
                {!postersOpen && (
                  <>
                    {!sharing && (
                      <>
                        <Button
                          type="button"
                          size="s"
                          onClick={onPrevious}
                          children="Back"
                          disabled={disablePrevious}
                        />
                        <Spacer />
                      </>
                    )}
                    {(deckCard.uid === 'deck2_card7' || deckCard.uid === 'deck2_card10') && (
                      <>
                        <Button type="button" size="s" onClick={onPoster} children="Posters" />
                        <Spacer />
                      </>
                    )}
                    {!sharing && (
                      <Button type="button" size="s" onClick={onNext} children="Next" disabled={disableNext} />
                    )}
                  </>
                )}
                {postersOpen && (
                  <Button
                    type="button"
                    size="s"
                    onClick={() => {
                      selectedPoster !== null ? setPoster(null) : onPoster()
                    }}
                    children="Back"
                  />
                )}
              </Row>
            </FixedBottom>
          )}
        </>
      )}
    </MobileMessageModal>
  )
}

const ModalContent = styled.div`
  padding: 50px 0;
`

const FixedTop = styled.div`
  position: fixed;
  top: 0;
  left: 10px;
  right: 10px;
  padding-top: 10px;
  background: #edf2fa;
`

const FixedBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 10px;
  right: 10px;
  padding-bottom: 10px;
  background: #edf2fa;
`

const DeckCardImage = styled.img`
  border: none;
  width: 100%;
  height: 100%;
`

const Posters = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  img {
    box-sizing: border-box;
    width: 50%;
    padding: 10px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
  }
`

const SinglePoster = styled.img`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  overflow: hidden;
`
