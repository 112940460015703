import { ActionTypes } from './actionTypes'
import { IdField, UidField, OrderField, TitleField, OwnerOccupierFields, TimestampFields } from '../types'
import { InputSection, JSONValueType, NotificationSection, TextSection } from '../session/types'
import { QuestionOption } from '../training/types'

export type QuestionnaireBooklet = 1 | 2 | 3 | 4

export type QuestionnaireType =
  | 'planning-teacher'
  | 'spence-ssq-parent'
  | 'spence-ssq-teacher'
  | 'erssq-parent'
  | 'erssq-teacher'
  | 'james-anxiety'
  | 'dylan-anger'
  | 'feedback-parent'
  | 'feedback-teacher'
  | 'feedback-cadet'
  | 'observational-code'
  | 'training-pre'
  | 'training-post'
  | 'custom'

export type QuestionnaireUser = 'cadet' | 'teacher' | 'parent' | 'facilitator'

export const questionnaireTypeLabels: { [key in QuestionnaireType]: string } = {
  'planning-teacher': 'Program Planning (Teacher)',
  'spence-ssq-parent': 'SSQ (Parent)',
  'spence-ssq-teacher': 'SSQ (Teacher)',
  'erssq-parent': 'ERSSQ (Parent)',
  'erssq-teacher': 'ERSSQ (Teacher)',
  'james-anxiety': 'James Anxiety',
  'dylan-anger': 'Dylan Anger',
  'feedback-parent': 'Program Satisfaction (Parent)',
  'feedback-teacher': 'Program Satisfaction (Teacher)',
  'feedback-cadet': 'Program Satisfaction (Cadet)',
  'observational-code': 'Observational Tool',
  'training-pre': 'Training (Pre)',
  'training-post': 'Training (Post)',
  custom: 'Custom',
}

export const questionnaireTypeTotalScoreMap: {
  [key in Extract<QuestionnaireType, 'james-anxiety' | 'dylan-anger'>]: number
} = {
  'james-anxiety': 20,
  'dylan-anger': 20,
}

export const scorelessQuestionnaireTypes: QuestionnaireType[] = [
  'feedback-parent',
  'feedback-teacher',
  'feedback-cadet',
]

export interface QuestionnaireSummary {
  mentor_id?: number | null
  cadet_mentor_id?: number | null
  drupal_user_id?: number | null
  provider_uid?: string
  name: string
  cadet_name?: string
  facilitator_names?: string
  questionnaire_type: QuestionnaireType
  questionnaire_booklet?: QuestionnaireBooklet
  manual_score?: number
  observational_code_score?: number
  score?: number
  date?: string
  facilitator_created: boolean
  responses: QuestionnaireResponse[]
}

export interface QuestionnaireSummarySaved extends QuestionnaireSummary, TimestampFields {
  id: number
  total: number | null
}

export interface QuestionnaireResponse {
  unique_key: string
  value: JSONValueType
  score?: number | null
  total?: number
  non_scoring?: boolean
}

export type QuestionnaireUserDataStatus =
  | false
  | 'loading'
  | 'submitted'
  | 'empty'
  | 'ready'
  | 'submitting'
  | 'submission_error'

export interface QuestionnaireStateHookObject {
  state: QuestionnaireSummary
  userDataStatus: QuestionnaireUserDataStatus
  setUserDataStatus: React.Dispatch<React.SetStateAction<this['userDataStatus']>>
  questionnaireData: false | Questionnaire
  setQuestionnaireData: (data: this['questionnaireData']) => void
  dispatch: (action: ActionTypes) => void
  previewing: boolean
  setPreviewing: React.Dispatch<React.SetStateAction<this['previewing']>>
  previewState: PreviewState
  setPreviewState: React.Dispatch<React.SetStateAction<this['previewState']>>
  sessionSectionId: number
  setSessionSectionId: React.Dispatch<React.SetStateAction<this['sessionSectionId']>>
}

export interface PreviewState {
  currentIndex: number
}

export interface Questionnaire extends IdField, UidField {
  title: string
  public_title?: string
  booklet?: QuestionnaireBooklet
  type: QuestionnaireType
  panels: PanelSection[]
}

export const questionnaireBookletLabels: {
  [key in Exclude<QuestionnaireSummary['questionnaire_booklet'], undefined>]: string
} = {
  1: 'Pre-program',
  2: 'Post-program',
  3: 'Follow-up 1',
  4: 'Follow-up 2',
}

/** CakePHP Model: PanelSections */
export interface PanelSection extends Section, Exclude<SectionContainer, 'panel_sections'> {}

/** CakePHP Model: QuestionSections */
export interface QuestionSection extends Section, IdField, UidField, OrderField {
  title: string
  text: string
  type: 'choice' | 'score' | 'polar' | 'observational'
  score_range: number
  start_from?: number
  terminology: string
  question_options: QuestionnaireQuestionOption[]
  required: boolean
}

export interface QuestionnaireQuestionOption
  extends Omit<QuestionOption, 'media' | 'video_url' | 'media_type' | 'media_ratio' | 'correct'> {
  reverse_scored: boolean
  optionLabels?: [string, string, string]
}

/**
 * Section content types
 */

export const sectionProperties = [
  'text_sections',
  'input_sections',
  'question_sections',
  'notification_sections',
] as const

export type SectionProperty = typeof sectionProperties[number]

export interface SectionObject {
  property: SectionProperty
  section: SectionContainer[this['property']][0]
  index: number
}

export interface SectionPropsBase extends SectionObject {}

type SectionContainerShape = { [key in SectionProperty]: Section[] }
export interface SectionContainer extends SectionContainerShape {
  text_sections: TextSection[]
  input_sections: InputSection[]
  question_sections: QuestionSection[]
  notification_sections: NotificationSection[]
}

export type ContentSection = TextSection | InputSection | QuestionSection | NotificationSection

export interface Section extends IdField, OwnerOccupierFields, OrderField, TitleField {}
