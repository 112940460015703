import React from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { IntroTemplate } from 'home/IntroTemplate'
import { Button, P, CollapseMargin, Spacer } from 'common/ui'

export const ErrorPage: React.FC<RouteComponentProps<{ error: string }>> = ({ match }) => {
  const error = match.params.error
  const history = useHistory()
  return (
    <IntroTemplate title="Logged out">
      <ErrorMessage error={error} />
      <Spacer />
      <Button onClick={() => history.replace('/')}>Login</Button>
    </IntroTemplate>
  )
}

const ErrorMessage: React.FC<{ error: string }> = ({ error }) => {
  switch (error) {
    case 'invalid-token':
      return (
        <CollapseMargin>
          <P>The session token is invalid.</P>
        </CollapseMargin>
      )
    case 'logged-out':
      return (
        <CollapseMargin>
          <P>Oops – you have been logged out!</P>
          <P>Another agent has entered your SAS Game Play Code.</P>
          <P>Please log in again or ask an adult to help you.</P>
        </CollapseMargin>
      )
    case 'timed-out':
    case 'session-timeout':
      return (
        <CollapseMargin>
          <P>Your session has timed-out.</P>
          <P>Please log in again to play.</P>
        </CollapseMargin>
      )
    case 'heartbeat-failed':
      return (
        <CollapseMargin>
          <P>This game's heartbeat could not be verified.</P>
        </CollapseMargin>
      )
    default:
      return (
        <CollapseMargin>
          <P>An unknown error occured.</P>
          <P>Please log in again to play.</P>
        </CollapseMargin>
      )
  }
}
