import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isAfter from 'date-fns/isAfter'
import isToday from 'date-fns/isToday'
import { useHistory, useParams } from 'react-router-dom'

import { Skill, SkillUse, Module } from 'skill-tracker/types'
import { Row, Spacer, Button, H3, P, Column, Padding, H2 } from 'common/ui'

import { MenuModal, MenuButton } from './Menu'
import { Sticker } from './common/Sticker'
import { Hint } from 'common/Hint'
import { NavDividerColumn } from './common/NavDividerColumn'
import { SkillsModal } from './modals/SkillsModal'
import { SkillUseEditModal } from './modals/SkillUseEditModal'
import { SkillSelectModal } from './modals/SkillSelectModal'
import { MobileMessageModal } from '../app/MobileMessageModal'
import { SkillUseApprovalModal } from './modals/SkillUseApprovalModal'
import { FinishModulePrompt } from './modals/FinishModulePrompt'
import { SkillTrackerPanel } from './common/SkillTrackerPanel'
import { AnchorButton } from 'common/LinkButton'
import { Emoji } from 'common/Emoji'

import { useUserState } from 'app/UserState'
import { useSkillTrackerState } from './SkillTrackerState'
import { useMedia } from 'utils/useMedia'
import { conditionallyIntersperseSpacers } from 'utils/intersperseSpacers'
import { baseUrl, navHeight } from './common/Navigation'
import { getTimeZone } from 'utils/dateTimeUtils'

import {
  getConfirmedModuleSkills,
  hydrateDaySkillUses,
  hasBonusScore,
  getSkillsValidForBonusForDate,
  checkBonusesAvailableForDaySkills,
  getModuleDates,
  getLatestStartedModule,
  bonusAwardableForDates,
  getModuleTitle,
} from './reducers'

import {
  AddUpdateSkillUseAction,
  ADD_UPDATE_SKILL_USE,
  RemoveSkillUseAction,
  REMOVE_SKILL_USE,
  AddUpdateModuleAction,
  ADD_UPDATE_MODULE,
  SetModuleCompletedAction,
  SET_MODULE_COMPLETED,
  UPDATE_ROLE_STATE_VALUES,
  UpdateRoleStateValuesAction,
} from './actionTypes'

import {
  validateAddUpdateSkillUse,
  validateRemoveSkillUse,
  validateAddUpdateModule,
  validateSetModuleCompleted,
} from './actionValidators'

export const PractiseScreen: React.FC<{}> = () => {
  const history = useHistory()
  const { state, roleState, dispatch } = useSkillTrackerState()
  const { moduleId: previousModuleId } = useParams<{ moduleId: string }>()
  const { profileId, getUserScopes, getBaseAction } = useUserState()
  const moduleId = previousModuleId || state.activeModuleId
  const skills = getConfirmedModuleSkills(moduleId, state)
  const isAdult = getUserScopes().indexOf('mentor') >= 0
  const readOnly = previousModuleId ? previousModuleId !== state.activeModuleId : false
  const portrait = useMedia('(orientation: portrait) and (max-width: 420px)')

  const todayRow = useRef<HTMLTableRowElement>(null)
  const [editSkillUse, setEditSkillUse] = useState<SkillUse | null>(null)
  const [newSkillUseDate, setNewSkillUseDate] = useState<string | null>(null)
  const [showingHelp, setShowingHelp] = useState<boolean>(false)
  const [showSkills, setShowSkills] = useState<boolean>(false)
  const [showFinishPrompt, setShowFinishPrompt] = useState<boolean>(false)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [showFutureSkillUseModal, setShowFutureSkillUseModal] = useState<string | false>(false)

  const mentorActiveModule = state.modules.find(({ id }) => id === moduleId)
  let activeModule = state.modules.find(({ id }) => id === moduleId)
  if (activeModule && !readOnly && (!activeModule.started || activeModule.completed))
    activeModule = getLatestStartedModule(state.modules)
  const dates = activeModule ? getModuleDates(activeModule) : []
  const showBonusScoreColumn = hasBonusScore(state)
  const bonusAwardable = bonusAwardableForDates(dates, moduleId, state)
  const today = new Date()

  const checkInTimeChanged = activeModule && activeModule.checkInTime !== roleState.lastDownloadCheckInTime
  const calendarReminderUrl = `${
    process.env.NODE_ENV !== 'production' ? 'https://st.bigfish.tv' : ''
  }/api/v1/skill-tracker/calendar?timezone=${getTimeZone()}&checkInTime=${
    activeModule ? activeModule.checkInTime : ''
  }&profileId=${profileId}`

  // lol i'm sorry -- it's just the logic down in the render function as a one liner
  const showingMessage =
    (!activeModule && !mentorActiveModule) ||
    (mentorActiveModule &&
      !readOnly &&
      (!activeModule || (activeModule && (mentorActiveModule.id !== activeModule.id || activeModule.completed)))) ||
    (activeModule && !activeModule.started)

  useEffect(() => {
    if (todayRow.current) {
      // TODO: Get position and scroll to it if outside of view
    }
  }, [])

  const handleSkillUseUpdate = (newSkillUse: SkillUse) => {
    if (newSkillUse.bonus) newSkillUse.active = true
    const action: AddUpdateSkillUseAction = { ...getBaseAction(), type: ADD_UPDATE_SKILL_USE, skillUse: newSkillUse }
    if (validateAddUpdateSkillUse(action, state)) dispatch(action)
    else console.error('Unable to validate skill use update')
  }

  const handleSkillUseClear = (skillUse: SkillUse) => {
    setEditSkillUse(null)
    const action: RemoveSkillUseAction = { ...getBaseAction(), type: REMOVE_SKILL_USE, skillUse }
    if (validateRemoveSkillUse(action, state)) dispatch(action)
    else console.error('Unable to validate skill use update')
  }

  const handleBonusSkillAdd = (date: string) => {
    const availableBonusSkills = getSkillsValidForBonusForDate(moduleId, state, date)
    if (availableBonusSkills.length === 1) {
      handleBonusSkillSelected(date, availableBonusSkills[0])
    } else {
      setNewSkillUseDate(date)
    }
  }

  const handleBonusSkillSelected = (date: string, skill: Skill) => {
    const currentDateSkills = state.skillUses.filter(
      skillUse => skillUse.active && skillUse.date === date && skillUse.skillId === skill.id
    )
    const newSkillUse: SkillUse = {
      id: Date.now().toString(),
      date,
      time: format(Date.now(), 'h-mm a'),
      skill,
      skillId: skill.id,
      skillIndex: currentDateSkills.length,
      bonus: true,
      active: false,
    }
    setEditSkillUse(newSkillUse)
  }

  const handleAddDay = () => {
    if (!activeModule || !activeModule.duration) return
    const module: Module = { ...activeModule, duration: activeModule.duration + 1 }
    const action: AddUpdateModuleAction = { ...getBaseAction(), type: ADD_UPDATE_MODULE, module }
    if (validateAddUpdateModule(action, state)) dispatch(action)
    else console.error(`Unable to validate update module data action`, action, state)
  }

  const handleFinishModule = () => {
    if (!activeModule) return
    const action: SetModuleCompletedAction = {
      ...getBaseAction(),
      type: SET_MODULE_COMPLETED,
      moduleId: activeModule.id,
    }
    if (validateSetModuleCompleted(action, state)) dispatch(action)
    else console.error(`Unable to validate update module data action`, action, state)
    history.push(baseUrl + '/module')
  }

  const handleDownloadReminders = () => {
    if (checkInTimeChanged && activeModule) {
      dispatch({
        ...getBaseAction(),
        type: UPDATE_ROLE_STATE_VALUES,
        role: isAdult ? 'mentor' : 'agent',
        data: { lastDownloadCheckInTime: activeModule.checkInTime || '' },
      } as UpdateRoleStateValuesAction)
    }
  }

  return (
    <>
      <SkillTrackerPanel>
        <Row flex="none" alignItems="center" style={{ borderBottom: '1px solid #ABB4DB', height: navHeight }}>
          <Column
            flex="1 1 auto"
            style={{ backgroundColor: '#C4CAE6', height: navHeight, borderTopLeftRadius: 7 }}
            paddingLeft={portrait ? 'xs' : 's'}
            justifyContent="center">
            {isAdult ? (
              <Row style={{ flexDirection: portrait ? 'column' : undefined }}>
                <H2 style={{ lineHeight: readOnly && portrait ? 0.85 : 0.95, maxWidth: portrait ? '94%' : undefined }}>
                  {readOnly && 'Previous '}Practise Log
                </H2>
                <Spacer size={portrait ? 'xxs' : 's'} />
                {activeModule && <H3 style={{ lineHeight: 0.95 }}>{getModuleTitle(activeModule, state.workflow)}</H3>}
              </Row>
            ) : (
              <H2 style={{ lineHeight: 0.95 }}>My Practise Log</H2>
            )}
          </Column>
          <NavDividerColumn height={navHeight} />
          {readOnly && (
            <Column flex="none">
              <Button type="button" size="s" theme="blue" onClick={() => history.push(baseUrl + '/progress')}>
                Back
              </Button>
            </Column>
          )}
          {!readOnly && !showingMessage && (
            <Column flex="none">
              <Button type="button" size="s" theme="blue" onClick={() => history.push(baseUrl + '/skills-rewards')}>
                Flip
              </Button>
            </Column>
          )}
          <Column flex="none" paddingRight={5}>
            <MenuButton onClick={() => setMenuOpen(true)}>Menu</MenuButton>
          </Column>
        </Row>
        <Row flex="1 1 auto" style={{ borderTop: '1px solid white', overflow: 'auto' }}>
          <Column flex="1 1 auto">
            {!activeModule && !mentorActiveModule ? (
              <Padding size="m">
                {isAdult ? (
                  <>
                    <P>You haven't set up the first Skill Tracker yet.</P>
                    <Button size="s" onClick={() => history.push(baseUrl + '/module')}>
                      Set up now
                    </Button>
                  </>
                ) : (
                  <>
                    <P>
                      Whoops! It's not ready yet. <br />
                      Ask your SAS mentor if you will be using the Digital Skill Tracker and when it will start.
                    </P>
                    <ArdonIcon>
                      <img src={require('./assets/ardon.jpg')} alt="Agent Ardon" />
                    </ArdonIcon>
                  </>
                )}
              </Padding>
            ) : mentorActiveModule &&
              !readOnly &&
              (!activeModule ||
                (activeModule && (mentorActiveModule.id !== activeModule.id || activeModule.completed))) ? (
              <Padding size="m">
                <P>
                  {isAdult
                    ? `Skill Tracker Module Complete! `
                    : `Congratulations, you finished a round of skill tracking. Your next target skills are not ready yet. `}
                  {mentorActiveModule.startDate &&
                  isAfter(parse(mentorActiveModule.startDate, 'y-MM-dd', Date.now()), today) ? (
                    <span>
                      Your next tracker is set to start on{' '}
                      {format(parse(mentorActiveModule.startDate, 'y-MM-dd', Date.now()), 'iiii, MMMM do')}
                    </span>
                  ) : isAdult ? (
                    <>
                      <span>When prompted by your SAS Facilitator, please move on to the next module.</span>
                      <br />
                      <br />
                      <Button size="s" onClick={() => history.push(baseUrl + '/module')}>
                        Set up now
                      </Button>
                    </>
                  ) : (
                    <span>Kindly ask your SAS Mentor when your next tracker will start.</span>
                  )}
                </P>
                {!isAdult && (
                  <ArdonIcon>
                    <img src={require('./assets/ardon.jpg')} alt="Agent Ardon" />
                  </ArdonIcon>
                )}
              </Padding>
            ) : activeModule && !activeModule.started ? (
              <Padding size="m">
                <P>
                  Module hasn't commenced yet!
                  {activeModule.startDate && (
                    <span>
                      Check back on {format(parse(activeModule.startDate, 'y-MM-dd', Date.now()), 'iiii, MMMM do')}
                    </span>
                  )}
                </P>
                {!isAdult && (
                  <ArdonIcon>
                    <img src={require('./assets/ardon.jpg')} alt="Agent Ardon" />
                  </ArdonIcon>
                )}
              </Padding>
            ) : (
              <Table cellSpacing={0} cellPadding={2}>
                <thead>
                  <tr style={{ height: 30 }}>
                    <th>
                      <H3 style={{ whiteSpace: 'nowrap', paddingLeft: 5 }}>Day</H3>
                    </th>
                    <th>
                      <Row alignItems="center">
                        <Column flex="none">
                          <H3 style={{ whiteSpace: 'nowrap' }}>Skills</H3>
                        </Column>
                        <Column flex="none" paddingLeft={10}>
                          <Hint onClick={() => setShowSkills(true)} />
                        </Column>
                        <Column flex="1 1 auto" />
                        <Column flex="none" paddingRight={10}>
                          {isAdult && bonusAwardable && (
                            <Hint
                              text={`Select the star button to award your SAS Cadet with Bonus Symbol Credit. This credit accumulates towards their Bonus Rewards (the Bonus Rewards Menu is optional).`}
                            />
                          )}
                        </Column>
                      </Row>
                    </th>
                    <th style={{ width: portrait ? 45 : 95 }}>
                      <H3>Daily Score:</H3>
                    </th>
                    {showBonusScoreColumn && (
                      <th style={{ width: portrait ? 55 : 105 }}>
                        <H3>Bonus Points:</H3>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {dates.map((date, dayIndex) => {
                    const isInFuture = isAfter(date, today)
                    const dateString = format(date, 'y-MM-dd')
                    const skillUses = hydrateDaySkillUses(moduleId, state, dateString)
                    const dayScore = skillUses.filter(({ approved, bonus }) => approved && !bonus).length
                    const dayBonusPoints = skillUses.filter(({ approved, bonus }) => approved && bonus).length
                    const bonusesAvailable = isInFuture ? false : checkBonusesAvailableForDaySkills(skills, skillUses)
                    return (
                      <DayRow key={dayIndex} today={isToday(date)} ref={isToday(date) ? todayRow : undefined}>
                        <th style={{ width: 50 }}>
                          <H3 style={{ paddingLeft: 5 }}>
                            {format(date, 'iii')}
                            {(activeModule?.duration || 0) > 7 && <small>{format(date, ' do')}</small>}
                          </H3>
                        </th>
                        <td>
                          <Row alignItems="center">
                            <div style={portrait ? { letterSpacing: -3, marginLeft: -3 } : {}}>
                              {conditionallyIntersperseSpacers(
                                skillUses,
                                (skillUse, i) => {
                                  const skill = skills.find(({ id }) => id === skillUse.skillId)
                                  if (!skill) return null
                                  return (
                                    <Sticker
                                      key={i}
                                      symbol={skill.symbol || '?'}
                                      text={skill.text}
                                      active={skillUse.active || skillUse.approved}
                                      approved={skillUse.bonus ? true : skillUse.approved}
                                      bonus={skillUse.bonus}
                                      disabled={isInFuture}
                                      disabledClick={() => setShowFutureSkillUseModal(format(date, 'iiii'))}
                                      onEdit={() => setEditSkillUse({ ...skillUse, active: true })}
                                      style={{ margin: '2px 0' }}
                                    />
                                  )
                                },
                                (a, b) => a.skillId !== b.skillId
                              )}
                            </div>
                            {!readOnly && !isInFuture && isAdult && bonusesAvailable && (
                              <>
                                <Spacer size="m" flex="1 0 auto" />
                                <Button
                                  size="xs"
                                  theme="white"
                                  onClick={() => handleBonusSkillAdd(dateString)}
                                  disabled={isInFuture}
                                  style={{ marginLeft: 5 }}>
                                  + <Emoji children="⭐️" verticalAlign="baseline" />
                                </Button>
                              </>
                            )}
                          </Row>
                        </td>
                        <td>{dayScore || ''}</td>
                        {showBonusScoreColumn && <td>{dayBonusPoints ? `+${dayBonusPoints}` : ''}</td>}
                      </DayRow>
                    )
                  })}
                </tbody>
              </Table>
            )}
            {!readOnly && isAdult && activeModule && !activeModule.completed && (
              <Padding size="s" style={{ paddingTop: 0 }}>
                <Row justifyContent="center" flexWrap>
                  {activeModule.duration && (
                    <>
                      <Button
                        type="button"
                        size={portrait ? 'xs' : 's'}
                        style={{ marginTop: 10 }}
                        onClick={handleAddDay}>
                        Add Day +
                      </Button>
                      <Spacer size="m" />
                    </>
                  )}
                  <AnchorButton
                    type="button"
                    size={portrait ? 'xs' : 's'}
                    theme={checkInTimeChanged ? undefined : 'white'}
                    onClick={handleDownloadReminders}
                    style={{ marginTop: 10 }}
                    download
                    href={calendarReminderUrl}>
                    <CalendarIcon size={portrait ? 18 : 24} stroke={checkInTimeChanged ? 'white' : 'black'} />
                    <span style={{ verticalAlign: 'middle', paddingLeft: 10 }}>
                      {!roleState.lastDownloadCheckInTime ? 'Calendar Reminder' : 'Update Reminder'}
                    </span>
                  </AnchorButton>
                  <Spacer size="m" />
                  <Button
                    type="button"
                    size={portrait ? 'xs' : 's'}
                    style={{ marginTop: 10 }}
                    onClick={() => setShowFinishPrompt(true)}>
                    ✓&nbsp;&nbsp;Finish Module
                  </Button>
                </Row>
              </Padding>
            )}
            {!readOnly && !isAdult && activeModule && !activeModule.completed && (
              <Padding size="s">
                <Row justifyContent="center">
                  <AnchorButton
                    type="button"
                    size="s"
                    theme={checkInTimeChanged ? undefined : 'white'}
                    onClick={handleDownloadReminders}
                    download
                    href={calendarReminderUrl}>
                    <CalendarIcon size={portrait ? 18 : 24} stroke={checkInTimeChanged ? 'white' : 'black'} />
                    <span style={{ verticalAlign: 'middle', paddingLeft: 10 }}>
                      {!roleState.lastDownloadCheckInTime ? 'Calendar Reminder' : 'Update Reminder'}
                    </span>
                  </AnchorButton>
                </Row>
              </Padding>
            )}
          </Column>
        </Row>
      </SkillTrackerPanel>
      {!isAdult && editSkillUse && (
        <SkillUseEditModal
          readOnly={readOnly}
          skillUse={editSkillUse}
          onSave={newSkillUse => handleSkillUseUpdate(newSkillUse)}
          onClose={() => setEditSkillUse(null)}
        />
      )}
      {isAdult && editSkillUse && (
        <SkillUseApprovalModal
          readOnly={readOnly}
          skillUse={editSkillUse}
          onSave={newSkillUse => handleSkillUseUpdate(newSkillUse)}
          onClose={() => setEditSkillUse(null)}
          onClear={newSkillUse => handleSkillUseClear(newSkillUse)}
        />
      )}
      {newSkillUseDate && (
        <SkillSelectModal
          title={`Select which skill to award a bonus to`}
          date={newSkillUseDate}
          skills={newSkillUseDate ? getSkillsValidForBonusForDate(moduleId, state, newSkillUseDate) : []}
          onSave={(date, skill) => handleBonusSkillSelected(date, skill)}
          onClose={() => setNewSkillUseDate(null)}
        />
      )}
      {showSkills && <SkillsModal onClose={() => setShowSkills(false)} />}

      <MobileMessageModal
        isOpen={!!showFutureSkillUseModal}
        onRequestClose={() => setShowFutureSkillUseModal(false)}
        panelStyle={{ width: isAdult ? 480 : 300 }}>
        {isAdult ? (
          <P style={{ margin: 0 }}>
            Everything is ready for this module but it isn't {showFutureSkillUseModal} yet.
            <br />
            Return to “Edit Skill Tracker” if you need to change the start date.
          </P>
        ) : (
          <P style={{ margin: 0 }}>
            It isn't {showFutureSkillUseModal} yet.
            <br />
            Kindly ask your SAS Mentor how to enter your skill uses if you're unsure.
          </P>
        )}
        <Spacer size="s" />
        <Button size="s" onClick={() => setShowFutureSkillUseModal(false)} children="Close" />
      </MobileMessageModal>

      <FinishModulePrompt
        isOpen={showFinishPrompt}
        onClose={() => setShowFinishPrompt(false)}
        onConfirm={handleFinishModule}
      />
      <AgentHelpModal isOpen={showingHelp && !isAdult} onClose={() => setShowingHelp(false)} />
      <AdultHelpModal isOpen={showingHelp && isAdult} onClose={() => setShowingHelp(false)} />
      <MenuModal isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  )
}

const Table = styled.table`
  border: 0;
  width: 100%;

  th:not(:last-child),
  td:not(:last-child) {
    border-right: 1px solid #abb4db;
  }
  th:not(:first-child),
  td:not(:first-child) {
    border-left: 1px solid #ffffff;
  }

  th,
  td {
    border-bottom: 1px solid #abb4db;
  }
  thead th,
  tbody td {
    border-top: 1px solid #ffffff;
  }

  tbody td:nth-child(2) {
    padding: 2px 6px;
  }
`

const DayRow = styled.tr<{ today?: boolean }>`
  height: 42px;
  min-height: 42px;
  ${p => (p.today ? 'background-color: white;' : '')}
`

const ArdonIcon = styled.div`
  max-width: 100px;
  border-radius: 12px;
  background-color: black;
  margin: 0 auto;
  & img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
`

export const AgentHelpModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => (
  <MobileMessageModal isOpen={isOpen} onRequestClose={onClose}>
    <P style={{ marginTop: 0 }}>Tap a skill symbol for today or a previous day to record how you used a skill.</P>
    <Button size="xs" onClick={onClose} theme="white">
      Close
    </Button>
  </MobileMessageModal>
)

export const AdultHelpModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => (
  <MobileMessageModal isOpen={isOpen} onRequestClose={onClose}>
    <P style={{ marginTop: 0 }}>
      Tap any coloured skill symbols that aren't glowing to review and approve/decline them.
    </P>
    <P>Or tap any greyed out skill symbols to add skill uses together with your student/child.</P>
    <P>
      Once your student/child has entered the maximum skill uses for a particular day a plus button will appear, press
      the plus button to award a bonus point for any extra skill uses.
    </P>
    <Button size="xs" onClick={onClose} theme="white">
      Close
    </Button>
  </MobileMessageModal>
)

const CalendarIcon: React.FC<{ stroke: string; size?: number }> = ({ stroke, size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" style={{ stroke, verticalAlign: 'middle' }}>
    <rect x="1.5" y="4.5" fill="none" width="21" height="18" />
    <line fill="none" x1="7.5" y1="2" x2="7.5" y2="6" />
    <line fill="none" x1="12" y1="12" x2="12" y2="18.5" />
    <line fill="none" x1="16.5" y1="2" x2="16.5" y2="6" />
    <line fill="none" x1="2.1" y1="8.8" x2="22" y2="8.8" />
    <polyline fill="none" points="9.5,16.4 12,19 14.5,16.4" />
  </svg>
)
