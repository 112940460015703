import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
// import { fontBold } from 'fonts'
import { EmotionometerDevice, EmotionometerSticker } from 'shared/e-telligence/types'
import { StickerImage, StickerLabel } from './StickerTile'

interface Props<StickerType extends EmotionometerDevice> {
  sticker: EmotionometerSticker<StickerType>
  onClick?: () => void
  maxSize?: number
  disabled?: boolean
}

export const StickerTileButton = <Type extends EmotionometerDevice>({
  sticker,
  onClick,
  maxSize,
  disabled,
}: PropsWithChildren<Props<Type>>) => {
  return (
    <TileDiv onClick={onClick} disabled={disabled}>
      {sticker.isPreset ? (
        <StickerImage
          style={{
            backgroundImage: `url("${require(`../assets/stickers/${sticker.type}/${
              sticker.emotion ? sticker.emotion + '_' : ''
            }${sticker.id}_icon.png`)}")`,
          }}
        />
      ) : (
        sticker.symbol && <StickerCustomSymbol children={sticker.symbol} />
      )}
      <StickerLabel size={maxSize}>
        <span>{sticker.title}</span>
      </StickerLabel>
    </TileDiv>
  )
}

export const TileDiv = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;

  border: 1px solid white;
  border-radius: 5px;
  background-color: white;

  font-size: 0.8rem;
  ${p =>
    !p.disabled
      ? ''
      : css`
          pointer-events: none;
          opacity: 0.5;
        `}
`
const StickerCustomSymbol = styled.span`
  position: absolute;
  display: inline-block;
  font-size: 28px;
  left: 50%;
  top: ${50 - 30 / 2}%;
  transform: translate(-50%, -50%);
`
