import React from 'react'
import { Row, Button, P } from 'common/ui'
import { MobileMessageModal } from 'app/MobileMessageModal'

export interface Props {
  isOpen: boolean
  isFacilitator: boolean
  playerName: string
  onClose: () => void
}

export const CongratulationsModal: React.FC<Props> = ({ isOpen, isFacilitator, playerName, onClose }) => {
  return (
    <MobileMessageModal isOpen={isOpen} onRequestClose={onClose} panelStyle={{ width: 360 }}>
      <img src={require('./assets/trophy.svg')?.default} alt="" />
      <P align="center" style={{ fontSize: 26 }}>
        Well done {playerName}!
      </P>
      <Row justifyContent="center">
        {isFacilitator && (
          <Button size="m" onClick={onClose}>
            Close
          </Button>
        )}
      </Row>
    </MobileMessageModal>
  )
}
