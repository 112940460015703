import { SortType, AnyObject } from 'types'
import _get from 'lodash/get'

export const sortByKey = <T extends AnyObject>(key: keyof T, direction: SortType = 'ascending') => {
  return function(a: T, b: T) {
    const valA = a[key]
    const valB = b[key]
    if (valA > valB) return direction === 'ascending' ? 1 : -1
    else if (valA < valB) return direction === 'ascending' ? -1 : 1
    return 0
  }
}

export const sortByKeyPath = <T>(keyPath: string, direction: SortType = 'ascending') => {
  return function(a: T, b: T) {
    const valA = _get(a, keyPath)
    const valB = _get(b, keyPath)
    if (valA > valB) return direction === 'ascending' ? 1 : -1
    else if (valA < valB) return direction === 'ascending' ? -1 : 1
    else return 0
  }
}
