import { PresetModule } from 'skill-tracker/types'

export const modules: PresetModule[] = [
  {
    id: '1a',
    module: 1,
    submodule: 'a',
    skills: [],
  },
  {
    id: '1b',
    module: 1,
    submodule: 'b',
    skills: [
      {
        id: '1b_bodyClues',
        moduleId: '1b',
        modulePreset: true,
        text: `Detect how other people are feeling from face, body and voice clues.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '2a',
    module: 2,
    submodule: 'a',
    skills: [
      {
        id: '2a_bodyClues',
        moduleId: '2a',
        modulePreset: true,
        text: `Detect how other people are feeling from face, body and voice clues.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '2b',
    module: 2,
    submodule: 'b',
    skills: [
      {
        id: '2b_emotionDetection',
        moduleId: '2b',
        modulePreset: true,
        text: `Detect when you're feeling angry or anxious and rate the strength of your emotions (if possible).`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '3a',
    module: 3,
    submodule: 'a',
    skills: [
      {
        id: '3a_relaxationGadgetUse',
        moduleId: '3a',
        modulePreset: true,
        text: `Practise using your relaxation gadgets (like O2 Regulator and Fire Engine), even when calm.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '3b',
    module: 3,
    submodule: 'b',
    skills: [
      {
        id: '3b_relaxationGadgetsCalm',
        moduleId: '3b',
        modulePreset: true,
        text: `Attempt to use your relaxation gadgets to calm down when needed.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '4a',
    module: 4,
    submodule: 'a',
    skills: [],
  },
  {
    id: '4b',
    module: 4,
    submodule: 'b',
    skills: [],
  },
  {
    id: '5a',
    module: 5,
    submodule: 'a',
    skills: [],
  },
  {
    id: '5b',
    module: 5,
    submodule: 'b',
    skills: [
      {
        id: '5b_relaxationGadgetsCalm',
        moduleId: '5b',
        modulePreset: true,
        text: `Attempt to use your relaxation gadgets to calm down when needed.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '6a',
    module: 6,
    submodule: 'a',
    skills: [
      {
        id: '6a_conversationCodeUse',
        moduleId: '6a',
        modulePreset: true,
        text: `Use your Conversation Code steps when talking to others.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '6b',
    module: 6,
    submodule: 'b',
    skills: [
      {
        id: '6b_conversationPlayCodeUse',
        moduleId: '6b',
        modulePreset: true,
        text: `Use your Conversation Code steps and/or Play Code steps when talking and playing with others.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '7a',
    module: 7,
    submodule: 'a',
    skills: [
      {
        id: '7a_damageControlCodeUse',
        moduleId: '7a',
        modulePreset: true,
        text: `Use your Damage Control Code steps when you make a mistake or things don't go your way.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '7b',
    module: 7,
    submodule: 'b',
    skills: [
      {
        id: '7b_allCodeSteps',
        moduleId: '7b',
        modulePreset: true,
        text: `Use your Conversation Code, Play Code and/or Damage Control Code steps.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '8a',
    module: 8,
    submodule: 'a',
    skills: [
      {
        id: '8a_allCodeSteps',
        moduleId: '8a',
        modulePreset: true,
        text: `Use your Conversation Code, Play Code and/or Damage Control Code steps.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '8b',
    module: 8,
    submodule: 'b',
    skills: [
      {
        id: '8b_bullyGuardSteps',
        moduleId: '8b',
        modulePreset: true,
        text: `Use your Bully-Guard Body Armour steps if someone is teasing or bullying you.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '9a',
    module: 9,
    submodule: 'a',
    skills: [
      {
        id: '9a_confusionCodeSteps',
        moduleId: '9a',
        modulePreset: true,
        text: `Use your Confusion Code steps.`,
        symbol: null,
        dailyTarget: null,
      },
    ],
  },
  {
    id: '9b',
    module: 9,
    submodule: 'b',
    skills: [],
  },
]
