import {
  Card,
  DiceValue,
  BoardGamePlayer as Player,
  BoardGameTileKey as TileKey,
  BoardGameMoveStep as MoveStep,
  BoardGameGameStep as GameStep,
  BoardGameDeckType as DeckType,
  BoardGameEncounter as Encounter,
} from '../../.././types'

export const REORDER_PLAYER = 'REORDER_PLAYER'
export const MOVE_PLAYER = 'MOVE_PLAYER'
export const UPDATE_ACTIVE_DECK = 'UPDATE_ACTIVE_DECK'
export const DISCARD_DECK_CARD = 'DISCARD_DECK_CARD'
export const UPDATE_PLAYER_TURN = 'UPDATE_PLAYER_TURN'
export const SET_ENCOUNTER = 'SHOW_ENCOUNTER'
export const CLEAR_ENCOUNTER = 'CLEAR_ENCOUNTER'
export const NEXT_GAME_PHASE = 'NEXT_GAME_PHASE'
export const ROLL_DICE = 'ROLL_DICE'
export const CREATE_PLAYER = 'CREATE_PLAYER'
export const MOVE_TO_ENCOUNTER = 'MOVE_TO_ENCOUNTER'
export const MOVE_TO_DECK_TILE = 'MOVE_TO_DECK_TILE'
export const SKIP_DECK_CARD = 'SKIP_DECK_CARD'
export const PREVIOUS_DECK_CARD = 'PREVIOUS_DECK_CARD'
export const SORT_PLAYERS = 'SORT_PLAYERS'
export const SET_FINISHED_PLAYER = 'SET_FINISHED_PLAYER'
export const TOGGLE_SUPER_DICE = 'TOGGLE_SUPER_DICE'
export const TOGGLE_POSTERS = 'TOGGLE_POSTERS'
export const SET_SELECTED_POSTER = 'SET_SELECTED_POSTER'
export const TOGGLE_SHARE_CARD = 'TOGGLE_SHARE_CARD'

export interface BaseAction {}

export interface ReorderPlayerAction extends BaseAction {
  type: typeof REORDER_PLAYER
  currentIndex: number
  newIndex: number
}

export interface MovePlayerAction extends BaseAction {
  type: typeof MOVE_PLAYER
  playerUid: string
  tileKey: TileKey
  incrementTurn?: boolean
}

export interface UpdateActiveDeckAction extends BaseAction {
  type: typeof UPDATE_ACTIVE_DECK
  deckType: DeckType
  deckCard?: Card
  moveStep?: MoveStep
}

export interface DiscardDeckCardAction extends BaseAction {
  type: typeof DISCARD_DECK_CARD
  deckType: DeckType
}

export interface SkipDeckCard extends BaseAction {
  type: typeof SKIP_DECK_CARD
}

export interface PreviousDeckCard extends BaseAction {
  type: typeof PREVIOUS_DECK_CARD
}

export interface UpdatePlayerTurn extends BaseAction {
  type: typeof UPDATE_PLAYER_TURN
}

export interface ShowEncounter extends BaseAction {
  type: typeof SET_ENCOUNTER
  encounter: Encounter
}

export interface ClearEncounter extends BaseAction {
  type: typeof CLEAR_ENCOUNTER
}

export interface NextPhase extends BaseAction {
  type: typeof NEXT_GAME_PHASE
  phase: GameStep
  moveStep?: MoveStep
}

export interface RollDice extends BaseAction {
  type: typeof ROLL_DICE
  diceIndex: DiceValue
}

export interface CreatePlayer extends BaseAction {
  type: typeof CREATE_PLAYER
  uid: string
  player: Player
}

export interface MoveToEncounter extends BaseAction {
  type: typeof MOVE_TO_ENCOUNTER
  playerUid: string
  tileKey: TileKey
  encounter: Encounter
}

export interface MoveToDeckTile extends BaseAction {
  type: typeof MOVE_TO_DECK_TILE
  playerUid: string
  tileKey: TileKey
  deckType: DeckType
}

export interface SortPlayer extends BaseAction {
  type: typeof SORT_PLAYERS
}

export interface SetFinishedPlayer extends BaseAction {
  type: typeof SET_FINISHED_PLAYER
  playerUid: string | null
}

export interface ToggleSuperDice extends BaseAction {
  type: typeof TOGGLE_SUPER_DICE
}

export interface TogglePoster extends BaseAction {
  type: typeof TOGGLE_POSTERS
}

export interface SetSelectedPoster extends BaseAction {
  type: typeof SET_SELECTED_POSTER
  posterID: 0 | 1 | 2 | 3 | null
}

export interface ToggleShareCard extends BaseAction {
  type: typeof TOGGLE_SHARE_CARD
}

export type ActionTypes =
  | ReorderPlayerAction
  | MovePlayerAction
  | UpdateActiveDeckAction
  | DiscardDeckCardAction
  | UpdatePlayerTurn
  | ShowEncounter
  | ClearEncounter
  | NextPhase
  | RollDice
  | CreatePlayer
  | MoveToEncounter
  | MoveToDeckTile
  | SkipDeckCard
  | SortPlayer
  | SetFinishedPlayer
  | ToggleSuperDice
  | PreviousDeckCard
  | TogglePoster
  | SetSelectedPoster
  | ToggleShareCard
