import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { orange } from 'themes'
import { TipsheetStateProvider, useTipsheetState } from './TipsheetState'
import { TipsheetStep } from './TipsheetStep'
import { useParams } from 'react-router-dom'
import { useSocket } from 'utils/useSocket'
import { Container, Spacer } from 'common/ui'
// import { TipsheetHeader } from './sections/TipsheetHeader'
// import { TipsheetFooter } from './sections/TipsheetFooter'
import { InputContextProvider } from 'session/InputContext'
import { UserInputStateProvider } from 'session/UserInputState'

export const TipsheetPreview: React.FC = () => {
  return (
    <TipsheetStateProvider>
      <UserInputStateProvider>
        <InputContextProvider
          overwriteContext={{
            session_uid: 'Tipsheet',
            participant_uid: '',
            module_id: undefined,
            owner: undefined,
            owner_id: undefined,
            name: 'Tipsheet-Input',
          }}>
          <TipsheetPreviewInner />
        </InputContextProvider>
      </UserInputStateProvider>
    </TipsheetStateProvider>
  )
}

const TipsheetPreviewInner: React.FC = () => {
  const { previewUid } = useParams<{ previewUid?: string }>()
  const [initedPreviewData, setInitedPreviewData] = useState<boolean>(false)
  const { tipsheetData, setTipsheetData, previewing, setPreviewing, previewState, setPreviewState } = useTipsheetState()
  const socket = useSocket(process.env.REACT_APP_PREVIEW_SOCKET_URL)
  useEffect(() => {
    if (!socket || !previewUid) return
    if (!socket.connected) {
      console.log('Preview socket listeners being created...')
      socket.on('connect', () => {
        console.log('Preview socket connection established')
        socket.emit('REGISTER_VIEWER', previewUid)
      })
      socket.on('disconnect', () => {
        console.log('Preview socket disconnected')
      })
      socket.on('VIEWER_DATA_UPDATED', (tipsheetData: any) => {
        console.log('Received viewer data from preview socket', tipsheetData)
        setTipsheetData(tipsheetData)
        setInitedPreviewData(true)
      })
      // TODO: move action processor elsewhere
      socket.on('DISPATCH', (action: any) => {
        console.log('Received action from preview socket server', action)
        switch (action.type) {
          case 'UPDATE_PREVIEW_STATE':
            console.log('Updating preview state', action.state)
            setPreviewState(action.state)
            break
        }
      })
      socket.connect()
    } else {
      console.log('Socket already connected')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, previewUid])

  useEffect(() => {
    if (!previewing) setPreviewing(true)
  }, [previewing, setPreviewing])

  if (!initedPreviewData || !tipsheetData || !previewUid) return <h1>Please wait</h1>

  const step = tipsheetData.module.tipsheet_steps.find((value, index) => index === previewState.stepIndex)

  return (
    <ThemeProvider theme={orange}>
      <Container>
        <Background />
        <h1>Tipsheet Step Preview</h1>
        {/* <TipsheetHeader moduleTitle={tipsheetData.module.title} name="Name Surname" /> */}
        <Spacer size="m" />
        {step ? <TipsheetStep step={step} /> : <p>step not found :(</p>}
        <Spacer size="m" />
        {/* <TipsheetFooter
          facilitatorEmail="name@email.com"
          facilitatorName="Name Surname"
          facilitatorPhone="+61 400 000 000"
        /> */}
        <Spacer size="m" />
      </Container>
    </ThemeProvider>
  )
}

const Background = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${p => p.theme.buttonBorderTopColor};
`
