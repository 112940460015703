import { useState, useEffect } from 'react'
import { getPdf } from 'api'

export function usePdfPayload<T>() {
  const [payload, setPayload] = useState<T | null>(null)
  const matches = window.location.hash.match(/^#(\d+)\/(.+?)$/)

  let pdfId = 0
  let accessToken = ''
  if (matches) {
    pdfId = parseInt(matches[1], 10)
    accessToken = matches[2] as string
  }

  useEffect(() => {
    if (accessToken) {
      getPdf<T>(pdfId, accessToken).then(data => setPayload(data.payload))
    }
  }, [pdfId, accessToken])

  return payload
}
