import React from 'react'
import styled, { css } from 'styled-components'
import { Column, P } from 'common/ui'
import { useSessionState } from './SessionState'

interface Props {
  text: string
}

export const SessionSplash: React.FC<Props> = ({ text, children }) => {
  const { isFacilitator } = useSessionState()
  return (
    <Column flex justifyContent="center" alignItems="center" padding="l">
      <PageContainer style={{ marginTop: isFacilitator ? 65 : 0 }}>
        <AssetContainer>
          <FolderImage src={require('./assets/folder.svg').default} alt="" />
          <Contents>
            <TopSecretImage src={require('./assets/top-secret-stamp.svg').default} alt="Top Secret" />
            <Sentence reduceSize={!!children}>{text}</Sentence>
            {children && <div children={children} />}
          </Contents>
        </AssetContainer>
      </PageContainer>
    </Column>
  )
}

const PageContainer = styled.div`
  position: relative;
  height: 0;
  width: calc(calc(25vw + 25vh) * 0.8);
  height: calc(25vw + 25vh);
`

const AssetContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(0.8) rotateZ(6.6deg);
`

const FolderImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 10px 10px 40px 30px rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.12);
`

const Contents = styled(Column)`
  align-items: center;
  position: absolute;
  width: 100%;
  top: 58%;
  left: 0;
  transform: translateY(-50%);
`

const TopSecretImage = styled.img`
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
`

const Sentence = styled(P)<{ reduceSize: boolean }>`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
  ${p =>
    p.reduceSize
      ? css`
          font-size: 1.6em;
          margin-left: 2rem;
          margin-right: 2rem;
        `
      : css`
          font-size: 2em;
          margin-left: 4rem;
          margin-right: 4rem;
        `}
`
