import { Option, Activity } from '../types'
import { SerializedScene } from '../scene-builder/types'
import { ActionTypes } from './actionTypes'

export type GadgetUseLocation = 'home' | 'school' | 'out' | 'out_appropriate' | 'anywhere'
export type EmotionometerType = 'anxiety' | 'anger' | 'sadness'
export type EmotionometerRange = '1-3' | '4-7' | '8-10'

// NOTE: order is accurate and relevant
export const emotionometerDevices = ['situations', 'bodyClues', 'relaxationGadgets'] as const
export type EmotionometerDevice = typeof emotionometerDevices[number]

export type EmotionOption = Option & { value: EmotionometerType }
export type EmotionRangeOption = Option & { value: EmotionometerRange; label: string; index: number }
export type GadgetUseOption = Option & { value: GadgetUseLocation; selectable?: boolean }

export interface ETelligenceState {
  gadgetIds: Gadget['id'][]
  customGadgets: Gadget[]

  codeCardIds: CodeCard['id'][]
  customCodeCards: CodeCard[]

  emotionometers: Emotionometer[]
  customStickers: EmotionometerSticker<EmotionometerDevice>[]
}

export interface ETelligenceStateHookObject {
  state: ETelligenceState
  actions: ActionTypes[]
  revision: number
  dispatch: (action: ActionTypes) => void
  loadingInitialState: boolean
  storagePrefix: string
  resetLocalState: () => void
}

export interface Gadget {
  id: string
  isPreset: boolean
  title: string
  emotion: EmotionometerType[]
  location: GadgetUseLocation[]
  emotionRange: EmotionometerRange[]
  description?: React.ReactNode
  symbol?: string
}

// NOTE: order of array is accurate and relevant
export const codeCardIds = [
  'decoder',
  'conversation',
  'play',
  'damage-control',
  'jokes',
  'bully-guard-body-armour',
  'confusion',
] as const
export type CodeCardId = typeof codeCardIds[number]

export interface CodeCard {
  id: CodeCardId | string
  isPreset: boolean
  title: string
  prerequisite?: Activity
  color?: string
  labelColor?: string
  cover: string
  steps: CodeCardStep[]
}

export interface CodeCardStep {
  label: string
  description: React.ReactNode
  imageUrl: string
  scene?: SerializedScene
  layout?: 'full'
}

export interface Emotionometer {
  id: string
  type: EmotionometerType
  title: string
  situations: EmotionometerSticker<'situations'>[]
  bodyClues: EmotionometerSticker<'bodyClues'>[]
  relaxationGadgets: EmotionometerSticker<'relaxationGadgets'>[]
}

export type EmotionometerStickerAny = EmotionometerSticker<EmotionometerDevice>
export interface EmotionometerStickerBase {
  id: string
  title: string
  symbol?: string
  isPreset?: boolean
  type: EmotionometerDevice
  emotion?: EmotionometerType
  emotionRange?: EmotionometerRange
  index?: number
}
export interface EmotionometerSticker<Type extends EmotionometerDevice> extends EmotionometerStickerBase {
  type: Type
}

export interface RouteProps {
  baseUrl: string
  state: ETelligenceState
  dispatch: (action: ActionTypes) => void
}
