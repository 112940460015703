import { GameObjects, Math as PhaserMath, Input, Geom } from 'phaser'
import { Vector } from 'types'
import { AssetCategory } from '../types'
import { InteractionHitArea } from 'phaser-types'
import SceneBuilderScene from './SceneBuilderScene'
import { isDevEnv } from 'utils/envUtils'
const { POINTER_UP, POINTER_DOWN } = Input.Events

export type AssetSpriteOptions = {
  position?: Vector
  center?: Vector
  baseScale?: number
  hitArea?: InteractionHitArea
}

export default class AssetSprite extends GameObjects.Image {
  public origWidth: number
  public origHeight: number
  public origHypot2: number
  public baseScale: number = 0.5
  public preResizeScale: number = 1
  public preResizeHypot2: number = 0
  public preRotateAngle: number = 0
  public category: AssetCategory
  public text: string = ''
  public pressedPointerId = -1
  public center: Vector = { x: 0.5, y: 0.5 }
  public cancelLoadHQ?: () => void
  private lastTapTime: number = 0

  constructor(scene: SceneBuilderScene, texture: string, category: AssetCategory, options: AssetSpriteOptions = {}) {
    super(scene, options.position ? options.position.x : 0, options.position ? options.position.y : 0, texture)
    scene.add.existing(this)
    this.origWidth = this.width
    this.origHeight = this.height
    this.origHypot2 = this.hypot2
    this.category = category
    if (options.baseScale) this.baseScale = options.baseScale
    if (options.center) this.center = options.center
    this.setScale(this.baseScale)

    if (options.hitArea) {
      this.setInteractive({ cursor: 'pointer', draggable: true })
      this.setInteractive(options.hitArea, Geom.Polygon.Contains)
      this.input.draggable = true
      this.input.cursor = 'pointer'
      // scene.input.enableDebug(this, 0xff0000)
    } else {
      this.setInteractive({
        cursor: 'pointer',
        draggable: true,
        pixelPerfect: true,
      })
    }

    this.on(POINTER_DOWN, (pointer: Phaser.Input.Pointer, x: number, y: number, e: Phaser.Types.Input.EventData) => {
      if (scene.disableInteraction) return

      // cheeky ie11 fix
      if (pointer.event.target && (pointer.event.target as HTMLElement).nodeName.toUpperCase() !== 'CANVAS') return

      if (isDevEnv()) console.log('sprite pointer down')

      if (!scene.state.resizing && !scene.state.rotating) {
        if (isDevEnv()) console.log('sprite is triggering scene->setActiveSprite')
        scene.setActiveSprite(this)
      } else {
        if (isDevEnv()) console.warn('cannot set active sprite because something is already being rotated / resized')
      }

      if (scene.state.firstTouchPointerId !== pointer.id && scene.state.secondTouchPointerId !== pointer.id) {
        if (isDevEnv()) console.log('sprite is triggering scene->addPressedSprite')
        scene.addPressedSprite(this, pointer)
      }

      if (scene.time.now - this.lastTapTime < 350) scene.handleAssetDoubleTap(this, pointer)
      this.lastTapTime = scene.time.now
    })

    this.on(POINTER_UP, (pointer: Phaser.Input.Pointer, x: number, y: number, e: Phaser.Types.Input.EventData) => {
      if (scene.disableInteraction) return
      if (isDevEnv()) console.log('sprite pointer up')
      scene.removePressedSprite(this, pointer)
    })
  }

  public handleFlipH() {
    this.flipX = !this.flipX
    if (this.input.hitArea instanceof Geom.Polygon)
      this.input.hitArea.setTo(
        this.input.hitArea.points.map(point => new Geom.Point(this.origWidth - point.x, point.y))
      )
  }
  public handleFlipV() {
    this.flipY = !this.flipY
    if (this.input.hitArea instanceof Geom.Polygon)
      this.input.hitArea.setTo(
        this.input.hitArea.points.map(point => new Geom.Point(point.x, this.origHeight - point.y))
      )
  }

  public setPreResizeValues() {
    this.preResizeScale = this.scale
    this.preResizeHypot2 = this.hypot2
  }

  public setPreRotateValues() {
    this.preRotateAngle = this.rotation
  }

  public get hypot2() {
    return PhaserMath.Distance.Between(0, 0, this.realWidth / 2, this.realHeight / 2)
  }

  public get hypotAngle() {
    return Math.atan2(this.realHeight / 2, this.realWidth / 2)
  }

  public get realWidth() {
    return this.origWidth * Math.abs(this.scaleX)
  }

  public get realHeight() {
    return this.origHeight * Math.abs(this.scaleY)
  }

  /**
   * Override this because setting texture doesn't check whether or not scene is still instantiated before accessing this.scene.sys.textures
   */
  public setTexture(key: string, frame?: string | number | undefined): this {
    if (this.scene) {
      this.texture = this.scene.sys.textures.get(key)
      // @ts-ignore
      return this.setFrame(frame)
    }
    return this
  }
}
