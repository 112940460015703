import React, { useEffect, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { fontBlack } from 'fonts'

import { Row, Spacer, Button, Panel, Hr, Padding, OutlineButton, Column, H2, P, H1 } from 'common/ui'
import { PrintGlobalStyle, PrintPreserveBackgrounds } from 'print/ui-print'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { TextSymbolInput, TextSymbolValue } from 'common/TextSymbolInput'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import { Field, FieldLabel } from 'common/Field'
import { Emoji } from 'common/Emoji'

import { HeaderTemplate } from './common/HeaderTemplate'

import { IdCardState } from 'shared/gadget-pack/types'
import { baseUrl } from './GadgetPack'
import { UPDATE_ID_CARD, ADD_ID_STRENGTH, UPDATE_ID_STRENGTH } from 'shared/gadget-pack/actionTypes'
import { PdfStatus } from 'common/PdfModal'
import { createPdf } from 'api'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { useUserState } from 'app/UserState'
import { useGadgetPackState } from './GadgetPackState'
import { usePdfPayload } from 'utils/usePdfPayload'
import { isDevEnv } from 'utils/envUtils'
import { InputLockProps } from 'session/common/InputLockComponent'
import { idCardEmojis } from 'constants/emojiSets'

export const emojis = idCardEmojis

interface IdCardPrintPayload {
  name: IdCardState['name']
  symbol: IdCardState['symbol']
  strengths: IdCardState['strengths']
  flipped: boolean
}

export const IdScreen: React.FC<{
  flippable: boolean
  hideExitButton?: boolean
  hideMediaButtons?: boolean
  hideTitle?: boolean
  overrideExit?: () => void
  overrideFlip?: boolean
  readOnly?: boolean
  inputLockProps?: Omit<InputLockProps, 'children'>
}> = ({
  flippable,
  hideTitle,
  hideExitButton,
  hideMediaButtons,
  overrideExit,
  overrideFlip,
  readOnly,
  inputLockProps,
}) => {
  const { getBaseAction, accessToken } = useUserState()
  const {
    state: {
      idCard: { name, symbol, strengths },
    },
    dispatch,
  } = useGadgetPackState()
  const history = useHistory()
  const [flipped, setFlipped] = useState(false)
  const [pdfStatus, setPdfStatus] = useState<PdfStatus>('ready')
  const [pdfUrl, setPdfUrl] = useState('')

  const handleDownload = () => {
    const templateUrl = `${window.location.origin}/gadget-pack/id/print`
    setPdfStatus('busy')
    createPdf<IdCardPrintPayload>(templateUrl, { name, symbol, strengths, flipped }, accessToken)
      .then((pdf) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setPdfUrl(pdf.url)
          setPdfStatus('success')
        })
      })
      .catch(() => setPdfStatus('error'))
  }

  const handleNameChange = useCallback(
    ({ text, symbol }: TextSymbolValue) =>
      dispatch({ ...getBaseAction(), type: UPDATE_ID_CARD, data: { name: text, symbol } }),
    [dispatch, getBaseAction]
  )

  useEffect(() => {
    if (overrideFlip !== undefined) setFlipped(overrideFlip)
  }, [overrideFlip])

  return (
    <>
      <HeaderTemplate
        title={hideTitle ? undefined : 'ID Tag'}
        hideExitButton={hideExitButton}
        onExit={() => (overrideExit ? overrideExit() : history.push(baseUrl))}
        Buttons={
          <>
            {!hideMediaButtons && (
              <>
                <Button
                  children={pdfStatus === 'busy' ? 'Processing...' : 'Download'}
                  disabled={pdfStatus === 'busy'}
                  onClick={handleDownload}
                />
                {isDevEnv() && (
                  <>
                    <Spacer size="s" />
                    <LinkButton to={`${baseUrl}/id/print`} children="Print" />
                    <Spacer size="s" />
                    <LinkButton to={`${baseUrl}/id/flipped/print`} children="Print Flipped" />
                  </>
                )}
              </>
            )}
            {flippable && (
              <>
                <Spacer size="s" />
                <Button children="Flip" onClick={() => setFlipped(!flipped)} />
              </>
            )}
          </>
        }>
        <ContainerRow justifyContent="center" alignItems="center">
          <IdPanel flex="none" active={!flipped} className="front-side">
            <Padding size="m" style={{ alignItems: 'center' }}>
              <Ring>
                <img src={require('./assets/gadgets_id_ring.svg')?.default} alt="" />
              </Ring>
              <Spacer size="xs" />
              <img src={require('./assets/sas.svg')?.default} width="50%" alt="Secret Agent Society" />
            </Padding>
            <Hr />
            <Padding size="m">
              <TextSymbolInput
                debounce
                label="Name:"
                inputProps={{ inputSize: 'l' }}
                textInputProps={{ placeholder: 'Enter Name' }}
                value={{ text: name, symbol }}
                onChange={handleNameChange}
                symbols={emojis}
                readonly={readOnly}
                inputLockProps={{
                  ...inputLockProps,
                  hasLabel: true,
                  verticalPlacementFacilitator: -28,
                  horizontalPlacementFacilitator: 86,
                  verticalPlacementCadet: -28,
                  horizontalPlacementCadet: 86,
                }}
              />
            </Padding>
            <Hr />
            <Padding size="m">
              <FieldLabel>Security Clearance:</FieldLabel>
              <BigRedText>Top Secret</BigRedText>
              <Spacer size="m" />
              <img src={require('./assets/barcode.svg')?.default} alt="" height={40} style={{ opacity: 0.5 }} />
            </Padding>
          </IdPanel>
          <IdPanel flex="none" active={flipped} reversed className="back-side">
            <Padding size="m" style={{ alignItems: 'center' }}>
              <Ring>
                <img src={require('./assets/gadgets_id_ring.svg')?.default} alt="" />
              </Ring>
              <Spacer size="xs" />
              <img src={require('./assets/sas.svg')?.default} width="50%" alt="Secret Agent Society" />
            </Padding>
            <Hr />
            <Padding size="m">
              <Field label="My Strengths:">
                {intersperseSpacers(
                  strengths.map((strength, index) => (
                    <TextSymbolInput
                      key={index}
                      debounce
                      symbols={emojis}
                      value={strength}
                      textInputProps={{ placeholder: 'Enter Strength' }}
                      onChange={(data) => dispatch({ ...getBaseAction(), type: UPDATE_ID_STRENGTH, index, data })}
                      readonly={readOnly}
                      inputLockProps={
                        !!inputLockProps
                          ? {
                              ...inputLockProps,
                              fieldUid: `${inputLockProps.fieldUid}_${index}`,
                              hasLabel: true,
                              verticalPlacementFacilitator: -28,
                              horizontalPlacementFacilitator: 60,
                              verticalPlacementCadet: -28,
                              horizontalPlacementCadet: 60,
                            }
                          : undefined
                      }
                    />
                  )),
                  !!inputLockProps ? 'l' : 's'
                )}
                <Spacer size="s" />
                {!readOnly && (
                  <OutlineButton
                    size="s"
                    onClick={() =>
                      dispatch({ ...getBaseAction(), type: ADD_ID_STRENGTH, strength: { text: '', symbol: '' } })
                    }
                    children="Add +"
                    disabled={strengths.length >= 10}
                  />
                )}
              </Field>
            </Padding>
          </IdPanel>
        </ContainerRow>
      </HeaderTemplate>
      <MobileMessageModal isOpen={pdfStatus === 'success' && !!pdfUrl}>
        <H2 style={{ textAlign: 'center' }}>PDF download ready</H2>
        <Spacer size="m" />
        <Row justifyContent="center" style={{ marginBottom: -10 }}>
          <Button theme="white" onClick={() => setPdfUrl('')} children="Cancel" />
          <Spacer size="m" />
          <AnchorButton href={pdfUrl} children="Download" target="_blank" />
        </Row>
      </MobileMessageModal>
    </>
  )
}

export const IdPrint: React.FC<{ flipped?: boolean }> = ({ flipped = false }) => {
  const { state } = useGadgetPackState()
  const payload = usePdfPayload<IdCardPrintPayload>()
  const name = payload ? payload.name : state.idCard.name
  const symbol = payload ? payload.symbol : state.idCard.symbol
  const strengths = payload ? payload.strengths : state.idCard.strengths
  flipped = payload ? payload.flipped : flipped
  return (
    <Column style={{ height: '100vh' }}>
      <PrintGlobalStyle />
      <PrintPreserveBackgrounds />
      <Row justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <IdPanel flex="none" active>
          <Padding size="m" style={{ alignItems: 'center' }}>
            <Ring>
              <img src={require('./assets/gadgets_id_ring.svg')?.default} alt="" />
            </Ring>
            <Spacer size="xs" />
            <img src={require('./assets/sas.svg')?.default} width="50%" alt="Secret Agent Society" />
          </Padding>
          <Hr />
          {flipped ? (
            <Padding size="m">
              <Field label="My Strengths:">
                {strengths.map((strength, index) => (
                  <Row key={index} alignItems="center">
                    <Column flex="1 1 auto">
                      <P style={{ margin: 0 }}>{strength.text}</P>
                    </Column>
                    <Spacer flex="1 1 auto" />
                    <Column flex="none" style={{ fontSize: 36 }}>
                      <Emoji children={strength.symbol} />
                    </Column>
                  </Row>
                ))}
              </Field>
            </Padding>
          ) : (
            <>
              <Padding size="m">
                <Field label="Name:">
                  <Row alignItems="center">
                    <Column flex="1 1 auto">
                      <H1 style={{ margin: 0 }}>{name}</H1>
                    </Column>
                    <Spacer flex="1 1 auto" />
                    <Column flex="none" style={{ fontSize: 36, lineHeight: 1 }}>
                      <Emoji children={symbol} />
                    </Column>
                  </Row>
                </Field>
              </Padding>
              <Hr />
              <Padding size="m">
                <FieldLabel>Security Clearance:</FieldLabel>
                <BigRedText>Top Secret</BigRedText>
                <Spacer size="m" />
                <img src={require('./assets/barcode.svg')?.default} alt="" height={40} style={{ opacity: 0.5 }} />
              </Padding>
            </>
          )}
        </IdPanel>
      </Row>
    </Column>
  )
}

export const ContainerRow = styled(Row)`
  height: 100%;
  min-height: 500px;
  transform: rotateY(0deg);
  perspective: 800;
  perspective-origin: center;
  @media (max-width: 640px) {
    align-items: flex-start;
  }
`

export const IdPanel = styled(Panel)<{ active?: boolean; reversed?: boolean }>`
  position: absolute;
  width: 375px;
  max-width: 100%;
  transform-style: preserve-3D;
  transform-origin: 50% 50%;
  transform: rotateY(${(p) => (p.active ? '0deg' : p.reversed ? '-180deg' : '180deg')});
  backface-visibility: hidden;
  transition: transform 0.35s cubic-bezier(0.4, -0.4, 0.8, 0.5);
  z-index: ${(p) => (p.active ? 1 : 0)};
  pointer-events: ${(p) => (p.active ? 'auto' : 'none')};
`

export const BigRedText = styled.div`
  ${fontBlack}
  width: 100%;
  padding: 15px 0;
  line-height: 1;
  text-align: center;
  background-color: white;
  border: 1px solid #b5b5b5;
  border-radius: 15px;
  color: #ff3b00;
  text-transform: uppercase;
  font-size: 2.75em;
`

const Ring = styled.div`
  position: relative;
  width: 15%;
  &::before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border-radius: 100%;
    background-color: ${/*p => p.theme.appBackgroundTopColor*/ '#38BCEC'};
    box-shadow: inset -5px 5px 10px 5px rgba(0, 0, 0, 0.3);
  }
  & img {
    position: relative;
    width: 100%;
    height: auto;
  }
`
