import { ExpiryStatus } from 'dashboards/utils/facilitatorUtils'
import React from 'react'

export const StatusIcon = (status: ExpiryStatus | null) => {
  switch (status) {
    case 'expired':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path
            fill="#4e4e4e"
            d="M12 22q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-1.35-.438-2.6-.437-1.25-1.262-2.3L7.1 18.3q1.05.825 2.3 1.262Q10.65 20 12 20Zm-6.3-3.1L16.9 5.7q-1.05-.825-2.3-1.262Q13.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 1.35.438 2.6.437 1.25 1.262 2.3Z"
          />
        </svg>
      )
    case 'due':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path
            fill="#CE1219"
            d="M12 12.5ZM1 21 12 2l11 19Zm10-6h2v-5h-2Zm1 3q.425 0 .713-.288Q13 17.425 13 17t-.287-.712Q12.425 16 12 16t-.712.288Q11 16.575 11 17t.288.712Q11.575 18 12 18Zm-7.55 1h15.1L12 6Z"
          />
        </svg>
      )
    case 'warning':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path
            fill="#FFAB48"
            d="m15.3 16.7 1.4-1.4-3.7-3.7V7h-2v5.4ZM12 22q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-10Zm0 8q3.325 0 5.663-2.337Q20 15.325 20 12t-2.337-5.663Q15.325 4 12 4T6.338 6.337Q4 8.675 4 12t2.338 5.663Q8.675 20 12 20Z"
          />
        </svg>
      )
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path
            fill="#4db900"
            d="M18 21H7V8l7-7 1.25 1.25q.175.175.288.475.112.3.112.575v.35L14.55 8H21q.8 0 1.4.6.6.6.6 1.4v2q0 .175-.05.375t-.1.375l-3 7.05q-.225.5-.75.85T18 21Zm-9-2h9l3-7v-2h-9l1.35-5.5L9 8.85ZM9 8.85V19ZM7 8v2H4v9h3v2H2V8Z"
          />
        </svg>
      )
  }
}
