import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'

import { MobileMessageModal, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { Button, H2, P, Row, RowWrapPortrait, Spacer, Column } from 'common/ui'
import { Emoji } from 'common/Emoji'

import { useUserState } from 'app/UserState'
import { useSkillTrackerState, getLocalStorageKey } from 'skill-tracker/SkillTrackerState'
import { getSkillUsesForModuleOnDate } from 'skill-tracker/reducers'
import { useLocalStorage } from 'utils/useStorage'
import { useMedia } from 'utils/useMedia'
import { getDate } from 'utils/dateTimeUtils'
import { baseUrl } from 'skill-tracker/common/Navigation'

const hourEmojis: { [key: string]: string } = {
  '1:00': '🕐',
  '2:00': '🕑',
  '3:00': '🕒',
  '4:00': '🕓',
  '5:00': '🕔',
  '6:00': '🕕',
  '7:00': '🕖',
  '8:00': '🕗',
  '9:00': '🕘',
  '10:00': '🕙',
  '11:00': '🕚',
  '12:00': '🕛',
  '1:30': '🕜',
  '2:30': '🕝',
  '3:30': '🕞',
  '4:30': '🕟',
  '5:30': '🕠',
  '6:30': '🕡',
  '7:30': '🕢',
  '8:30': '🕣',
  '9:30': '🕤',
  '10:30': '🕥',
  '11:30': '🕦',
  '12:30': '🕧',
}

const dismissedCheckInKey = 'ST_dismissedCheckIn'

export const TallyTimeModal: React.FC<{ onClose: () => void; onConfirm: () => void }> = ({ onClose, onConfirm }) => {
  const location = useLocation()
  const { state } = useSkillTrackerState()
  const { getUserScopes } = useUserState()
  const portrait = useMedia('(orientation: portrait)')

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }
  const delayedOnConfirm = () => {
    setIsOpen(false)
    setTimeout(onConfirm, MODAL_FADE_OUT_TIME)
  }

  const alreadyOnPractiseLog = matchPath(location.pathname, { path: baseUrl + '/practise', exact: true })

  const today = getDate({ leadingZeros: true })
  const isAdult = getUserScopes().indexOf('mentor') >= 0
  const module = state.modules.find(({ id }) => id === state.activeModuleId)
  const skillsForApproval = module ? getSkillUsesForModuleOnDate(module.id, today, state) : []
  const emoji = module && module.checkInTime ? hourEmojis[module.checkInTime.split(' ')[0]] : '🕒'

  return (
    <MobileMessageModal
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      style={
        {
          /*content: { maxWidth: 650 } */
        }
      }>
      <RowWrapPortrait>
        <Column flex="1 1 2" style={{ maxWidth: 370 }}>
          <H2 style={{ lineHeight: 0.95 }}>
            <Emoji children={emoji} />
            &nbsp;&nbsp;Daily Tally Time!&nbsp;&nbsp;
            <Emoji children={emoji} />
          </H2>
          {isAdult ? (
            <P>
              It’s time to find your cadet and review their symbols and daily target together.
              <br />
              There are {skillsForApproval.length} new entries for you to review and confirm.
              <br />
              Be sure to connect to the internet and open your Skill Trackers to make sure you are both up to date!
            </P>
          ) : (
            <P>
              It’s time to find your SAS Mentor and review your symbols together.
              <br />
              Be sure to connect to the internet and open your Skill Tracker to make sure you are both up to date!
            </P>
          )}
          {portrait && (
            <ModalImageWrapper style={{ maxWidth: 100, margin: '0 auto' }}>
              <img src={require('../assets/tally-time.png')} alt="Daily Tally Time!" />
            </ModalImageWrapper>
          )}
          <Spacer size="m" flex="1 1 auto" />
          <Row justifyContent={portrait ? 'center' : 'flex-start'}>
            <Button type="button" size="s" theme="white" onClick={delayedOnClose}>
              Close
            </Button>
            {!alreadyOnPractiseLog && (
              <>
                <Spacer size="s" />
                <Button type="button" size="s" onClick={delayedOnConfirm}>
                  Go to Practise Log
                </Button>
              </>
            )}
          </Row>
        </Column>
        {!portrait && (
          <>
            <Spacer size="m" flex="none" />
            <Column flex="1 1 1" justifyContent="flex-end" style={{ maxWidth: 200 }}>
              <ModalImageWrapper>
                <img src={require('../assets/tally-time.png')} alt="Daily Tally Time!" />
              </ModalImageWrapper>
            </Column>
          </>
        )}
      </RowWrapPortrait>
    </MobileMessageModal>
  )
}

const ModalImageWrapper = styled.div`
  max-width: 100%;
  border: 1px solid ${p => p.theme.thinOutlineColor};
  padding: 15px;
  background-color: white;
  border-radius: 12px;
  & img {
    width: 100%;
    height: auto;
  }
`

export const AutoTallyTimeModal: React.FC = () => {
  const history = useHistory()
  const { profileId, openAccess, getUserScopes } = useUserState()
  const { state } = useSkillTrackerState()
  const prefix = getLocalStorageKey({ profileId, getUserScopes, openAccess })

  const module = state.modules.find(({ id }) => id === state.activeModuleId)

  const today = getDate({ leadingZeros: true })
  const [dismissedToday, setDismissedToday] = useLocalStorage<boolean>(
    `${prefix}_${dismissedCheckInKey}_${today}`,
    false
  )
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    const checkTime = () => {
      if (module && module.startDate && module.checkInTime) {
        const now = new Date()
        const startDateTime = parse(`${format(now, 'y-MM-dd')} ${module.checkInTime}`, 'y-MM-dd h:mm a', Date.now())
        const diff = differenceInMinutes(startDateTime, now)
        console.log('time diff', diff)
        console.log('todays check in datetime', startDateTime)
        if (!dismissedToday && diff <= -0) setShowModal(true)
      }
    }
    const checkTimeInterval = window.setInterval(checkTime, 60 * 1000)
    checkTime()
    return () => {
      window.clearInterval(checkTimeInterval)
    }
  }, [module, dismissedToday])

  const handleClose = () => {
    setDismissedToday(true)
    setShowModal(false)
  }

  const handleConfirm = () => {
    handleClose()
    setTimeout(() => history.push(baseUrl + '/practise'))
  }

  return (
    <>
      {showModal && module && module.checkInTime && <TallyTimeModal onClose={handleClose} onConfirm={handleConfirm} />}
    </>
  )
}
