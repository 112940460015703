/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ComponentProps, useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import qs from 'qs'

import { MediaAsset, SectionPropsBase } from 'shared/session/types'
import { Questionnaire, QuestionnaireSummarySaved } from 'shared/questionnaires/types'
import { UPDATE_STATE } from 'shared/questionnaires/actionTypes'
import {
  DISPATCH_SECTION_STATE,
  QUESTIONNAIRE_STATE_REQUESTED,
  QuestionnaireStateRequestedAction,
} from 'shared/session/actionTypes'
import {
  ChangeParticipantAccessAction,
  CHANGE_PARTICIPANT_ACCESS,
} from 'shared/session/sections/questionnaire/actionTypes'
import { getQuestionnaireSectionParticipantState } from 'shared/session/sections/questionnaire/reducer'

import { PreviewQuestionnaireStateProvider } from 'session/PreviewQuestionnaireStateProvider'
import { useQuestionnaireState } from 'questionnaires/QuestionnaireState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { useSessionState } from 'session/SessionState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'

import { Button, Column, P, Row, Spacer, Panel } from 'common/ui'
import { SpinnerWithLabel } from 'common/Spinner'
import { Checkbox } from 'common/Checkbox'
import { QuestionnaireView as QuestionnaireContent } from 'questionnaires/Questionnaire'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { useUserState } from 'app/UserState'
import { getUserDisplayName } from 'app/useGenericUser'
import { createQuestionnaire } from 'api'
import { SectionTitle } from 'session/common/SectionTitle'
import { columnWidthPresets, MediaGrid, MediaGridColumn, MediaGridInner } from './MediaGrid'
import { Media } from 'session/common/Media'
import { NotificationInner } from './Notification'
import { SectionsPanelStyled } from 'session/common/SectionsPanel'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'

interface Props extends SectionPropsBase {
  property: 'questionnaire_sections'
}

export const QuestionnaireSection: React.FC<Props> = ({ section, panel }) => {
  return (
    <PreviewQuestionnaireStateProvider>
      <QuestionnaireSectionPreInner section={section} panel={panel} />
    </PreviewQuestionnaireStateProvider>
  )
}

/**
 * This whole component is just to deal with the lifecycle dramas that occur when needing to reset questionnaire state upon cadet tab switch
 * All it does it detect when cadet tab change occurs and prevents rendering until questionnaire state is actually reset (userDataStatus === false)
 * I feel like this shouldn't be necessary but apparently useHook callbacks run after the /next/ render
 */
const QuestionnaireSectionPreInner: React.FC<ComponentProps<typeof QuestionnaireSectionInner>> = props => {
  const { previewing, sessionData } = useSessionState()
  const { userDataStatus, setUserDataStatus } = useQuestionnaireState()
  const participantState = useFocusedParticipantState()
  const cadetMentorId =
    !previewing && sessionData && sessionData.group && participantState
      ? sessionData.group.primary_mentors.find(
          mentor => `sas-${mentor.cadet_profile_id}` === participantState.profile.uid
        )?.id
      : undefined

  const previousCadetMentorId = useRef(cadetMentorId)
  const [preventRender, setPreventRender] = useState<boolean>(false)

  useEffect(() => {
    if (preventRender && userDataStatus !== false) {
      setPreventRender(false)
      console.log('❓ Questionnaire state has been reset. Now allowing component to render')
    }
  }, [userDataStatus, preventRender])

  if (previousCadetMentorId.current !== cadetMentorId) {
    console.log('❓ cadetMentorId changed', previousCadetMentorId.current, '->', cadetMentorId)
    previousCadetMentorId.current = cadetMentorId
    if (!preventRender && userDataStatus !== false) {
      setPreventRender(true)
      setUserDataStatus(false)
      console.log('❓ Preventing render until questionnaire state is reset...')
      return null
    }
  }

  if (preventRender) return null
  return <QuestionnaireSectionInner key={cadetMentorId} {...props} />
}

const QuestionnaireSectionInner: React.FC<{ section: Props['section']; panel: Props['panel'] }> = ({
  section,
  panel,
}) => {
  const { drupalProfile, profileName, accessToken } = useUserState()
  const { isFacilitator, previewing, state, dispatch, sessionData, getBaseAction } = useSessionState()
  const { title, questionnaire_booklet, questionnaire_type, holding_text } = section
  const [questionnaireRefetchCount, setQuestionnaireRefetchCount] = useState<number>(0)
  const [userDataRefetchCount, setUserDataRefetchCount] = useState<number>(0)
  const {
    dispatch: dispatchQuestionnaireState,
    setQuestionnaireData,
    userDataStatus,
    setUserDataStatus,
    sessionSectionId,
    setSessionSectionId,
  } = useQuestionnaireState()

  const sectionState = useSectionStateWithFallback('questionnaire_sections', section, getInitialSectionState)
  const participantState = useFocusedParticipantState()
  const profileState = getQuestionnaireSectionParticipantState(
    sectionState,
    participantState ? participantState.profile.uid : null
  )

  const facilitatorName = isFacilitator
    ? getUserDisplayName({ authProvider: 'sst', drupalProfile, profileName })
    : state.facilitators[0]?.profile.displayName || ''
  const cadetName = participantState ? participantState.profile.displayName : isFacilitator ? '' : profileName
  const cadetMentorId =
    !previewing && sessionData && sessionData.group && participantState
      ? sessionData.group.primary_mentors.find(
          mentor => `sas-${mentor.cadet_profile_id}` === participantState.profile.uid
        )?.id
      : undefined

  const [
    questionnaire,
    { loading: loadingQuestionnaire, errorLoading: errorLoadingQuestionnaire, errorMessage: errorMessageQuestionnaire },
  ] = useEndpoint<Questionnaire>(
    `/api/v1/questionnaires/${questionnaire_booklet}/${questionnaire_type}?cachebust=${questionnaireRefetchCount}`
  )

  const [
    userData,
    { loading: loadingUserData, errorLoading: errorLoadingUserData, errorMessage: errorMessageUserData },
  ] = useEndpoint<QuestionnaireSummarySaved>(
    cadetMentorId && userDataStatus !== 'submitted'
      ? `/api/v1/questionnaires/overview?${qs.stringify({
          user_type: 'cadet',
          type: section.questionnaire_type,
          booklet: section.questionnaire_booklet,
          cadet_mentor_id: cadetMentorId,
          cachebust: userDataRefetchCount,
        })}`
      : null
  )

  const cadetUserDataRequested = useRef<boolean>(false)

  useEffect(() => {
    if (section.id !== sessionSectionId) setSessionSectionId(section.id)
  }, [section.id, sessionSectionId, setSessionSectionId])

  // Runs only once, but this entire component gets re-rendered if cadet tab changes
  useEffect(() => {
    if (
      isFacilitator &&
      participantState &&
      (!userDataStatus || userDataStatus === 'loading') &&
      !cadetUserDataRequested.current
    ) {
      console.info('❓ Requesting cadet questionnaire state via socket...')
      cadetUserDataRequested.current = true
      const action: QuestionnaireStateRequestedAction = {
        ...getBaseAction(),
        type: QUESTIONNAIRE_STATE_REQUESTED,
        cadetUid: participantState.profile.uid,
      }
      dispatch(action)
    } else {
      console.info('❓ Not enough info to request questionnaire state', {
        isFacilitator,
        participantState,
        userDataStatus,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Set initial questionnaire state if not established yet
   */
  useEffect(() => {
    if (!loadingQuestionnaire && questionnaire && !!cadetMentorId) {
      console.log(`❓ Questionnaire CMS data loaded`)
      setQuestionnaireData(questionnaire)
      // don't overwrite userData with initial state if userData has already been loaded
      if (isFacilitator) {
        console.log(
          `❓ Not setting initial questionnaire state data because facilitator should not be doing this, only cadet can`
        )
      } else {
        if (userDataStatus === 'submitted') {
          console.log(`❓ Not setting initial questionnaire state data because user has already submitted`)
        } else if (userDataStatus === 'ready') {
          console.log(`❓ Not setting initial questionnaire state data because it's already been initialized`)
        } else {
          console.info(`❓ Setting questionnaire initial user data, because userDataStatus: ${userDataStatus}`)
          setUserDataStatus('ready')
          dispatchQuestionnaireState({
            type: UPDATE_STATE,
            state: {
              provider_uid: (sessionData && sessionData.group?.provider_uid) || '',
              cadet_mentor_id: cadetMentorId,
              facilitator_names: facilitatorName,
              cadet_name: cadetName,
              name: cadetName,
              questionnaire_booklet,
              questionnaire_type,
              date: new Date().toISOString().split('T')[0],
              facilitator_created: false,
              responses: [],
            },
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire, loadingQuestionnaire, cadetMentorId, userDataStatus])

  useEffect(() => {
    if (!loadingUserData && userData) {
      console.info('❓ Loaded questionnaire user data -- updating state')
      setUserDataStatus('submitted')
      dispatchQuestionnaireState({
        type: UPDATE_STATE,
        state: userData,
        userDataStatus: 'submitted',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUserData, userData])

  const handleSubmit: ComponentProps<typeof QuestionnaireContent>['onSubmit'] = data => {
    if (!isFacilitator) {
      console.warn('❓ Non facilitator tried to submit questionnaire')
      return new Promise((resolve, reject) => reject())
    }
    console.log('❓ submitting from questionnaire section')
    return new Promise((resolve, reject) => {
      createQuestionnaire(data, accessToken)
        .then(() => {
          // TODO: refetch questionnaire overview and dispatch as update
          setUserDataRefetchCount(v => v + 1)
          resolve({ success: true })
        })
        .catch(reject)
    })
  }

  if (profileState.active) {
    return (
      <Row justifyContent="center" paddingTop="l" paddingBottom="l">
        {!cadetMentorId ? (
          <Panel paddingLeft="l" paddingRight="l">
            {previewing ? (
              <P>Unable to dynamically display questionnaire when previewing from CMS, sorry!</P>
            ) : (
              <P>Uh oh -- cannot find cadet's primary mentor!</P>
            )}
          </Panel>
        ) : questionnaire && !loadingQuestionnaire ? (
          <SessionQuestionnaireStyleWrapper panel={panel}>
            <QuestionnaireContent
              key={cadetMentorId}
              type="cadet"
              booklet={questionnaire_booklet}
              onSubmit={handleSubmit}
              standalone
              isEmbeddedInSession
              showManualScoreField={isFacilitator}
              cadetMode={!isFacilitator}
            />
          </SessionQuestionnaireStyleWrapper>
        ) : errorLoadingQuestionnaire ? (
          <>
            <P>Uh oh! Server monkeys couldn't find their banana.</P>
            <Button size="s" onClick={() => setQuestionnaireRefetchCount(v => v + 1)} children="Retry" />
          </>
        ) : (
          <SpinnerWithLabel label="Loading content..." />
        )}
      </Row>
    )
  }

  const video: MediaAsset = {
    id: -1,
    filename: require('session/assets/LogoAnimationNoLoop.mp4'),
    created: '',
    modified: '',
    extension: 'mp4',
  }

  const contentNode = (
    <>
      {title && <SectionTitle children={title} />}
      <MediaGrid>
        <MediaGridColumn columns={1}>
          <MediaGridInner>
            <Media
              type="video"
              video={video}
              widthPreset={columnWidthPresets[1]}
              ratio={'auto'}
              loop={false}
              controls={false}
            />
          </MediaGridInner>
        </MediaGridColumn>
      </MediaGrid>
      <NotificationInner
        type="ardon"
        text={
          holding_text ||
          'Complete the SAS evaluation activity with your club leader to show how much you have learned from the program and to help us to make it better!'
        }
      />
    </>
  )

  if (panel.theme === 'none') {
    return (
      <SectionsPanelStyled panelTheme={null}>
        <div style={{ padding: 20 }} children={contentNode} />
      </SectionsPanelStyled>
    )
  } else {
    return contentNode
  }
}

// I wanted to import Panel from questionnaires/Questionnaire but ran into a circular dependency issue so had to settle for class name instead
const SessionQuestionnaireStyleWrapper = styled(Column)<{ panel: Props['panel'] }>`
  max-width: 100%;
  & .questionnaire-panel {
    ${p =>
      p.panel.theme === 'cork'
        ? css`
            min-width: 420px;
          `
        : css`
            min-width: 640px;
          `}
  }
`

export const getInitialSectionState = () => ({})

export const QuestionnaireSectionFacilitator: React.FC<Props> = props => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback('questionnaire_sections', props.section, getInitialSectionState)

  const { questionnaire_type } = props.section

  const updateParticipantActive = (participantUid: string, active: boolean) => {
    const action: ChangeParticipantAccessAction = { type: CHANGE_PARTICIPANT_ACCESS, participantUid, active }
    dispatch({
      ...getBaseAction(),
      type: DISPATCH_SECTION_STATE,
      property: 'questionnaire_sections',
      section_id: props.section.id,
      action,
    })
  }

  return (
    <FacilitatorControls title="Questionnaire Administration">
      <ActivityInteractionIndicator type="individual" />
      <P>
        <ol style={{ paddingLeft: '2em' }}>
          {questionnaire_type === 'feedback-cadet' ? (
            <>
              <li>Launch satisfaction questionnaire for each cadet using the table below.</li>
              <li>Go to each cadet's tab to view and support their response as needed.</li>
              <li>Please read aloud all questions, and ensure their responses are recorded.</li>
              <li>
                If you are entering responses for a cadet, please lock their screen so that only you can enter text.
              </li>
              <li>Press Submit on each cadet's tab to submit their satisfaction questionnaire.</li>
            </>
          ) : (
            <>
              <li>Launch assessment for each cadet using the table below</li>
              <li>Go to each cadet's tab to view and support their response entry if needed.</li>
              <li>
                Please read aloud all stories and questions in this questionnaire to each child, and ensure their
                responses are recorded.
              </li>
              <li>
                If you are entering responses for a cadet, please lock their screen so that only you can enter text.
              </li>
              <li>
                <strong>Press Submit on each cadet's tab to submit their questionnaire.</strong>
              </li>
            </>
          )}
        </ol>
      </P>
      <Spacer />
      <FacilitatorControlsTable>
        <thead>
          <tr>
            <th>Cadet</th>
            <th>{questionnaire_type === 'feedback-cadet' ? `Launch Q're` : `Launch assessment`}</th>
          </tr>
        </thead>
        <tbody>
          {state.participants.map(({ profile }) => {
            const participantState = sectionState[profile.uid]
            return (
              <tr key={profile.uid}>
                <th>{profile.displayName}</th>
                <td>
                  <Checkbox
                    checked={participantState ? sectionState[profile.uid].active : false}
                    onChange={active => updateParticipantActive(profile.uid, active)}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </FacilitatorControlsTable>
      {questionnaire_type !== 'feedback-cadet' && (
        <P>
          <strong>Scoring: </strong>You can enter final scores discretely during the meeting, the facilitator only score
          box is found on each cadet's tab. Scoring can also be done later through viewing the responses and entering a
          manual score into the facilitator assessment table.
        </P>
      )}
    </FacilitatorControls>
  )
}
