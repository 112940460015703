import React, { useRef, useState, ComponentProps, ChangeEvent, CSSProperties, useEffect } from 'react'
import GrapheneSplitter from 'grapheme-splitter'

import { Field, FieldProps } from './Field'
import { Row, TextInput, Spacer, InputSize } from './ui'
import { SymbolSelectModal } from './SymbolSelectModal'
import { AutosizeTextareaInput } from './AutosizeTextareaInput'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'

import { supportsEmojis } from 'utils/deviceUtils'
import { getEmojiId } from 'utils/emojiUtils'
import { useMeasure } from 'utils/useMeasure'
import { useSessionState } from 'session/SessionState'
import { InputLockComponent, InputLockProps } from 'session/common/InputLockComponent'

export type TextSymbolValue = { text?: string; symbol?: string }
type InputProps = ComponentProps<typeof TextInput>

export interface TextSymbolInputProps {
  symbols: string
  label?: string
  value: TextSymbolValue
  textarea?: boolean
  multipleSymbols?: boolean
  fieldProps?: FieldProps['fieldProps']
  inputProps?: Partial<InputProps>
  textInputProps?: Partial<InputProps>
  symbolInputProps?: Partial<InputProps>
  onChange: ({ text, symbol }: TextSymbolValue) => void
  readonly?: boolean
  debounce?: boolean | number
  onClick?: () => void
  inputLockProps?: Omit<InputLockProps, 'children'>
}

const splitter = new GrapheneSplitter()

const getSymbolStylesForSize = (size: InputSize, hasSymbol: boolean, symbolCount: number): CSSProperties => {
  switch (size) {
    case 's':
      return {
        fontSize: 20,
        width: symbolCount > 2 ? 70 : 50,
        height: 40,
        paddingTop: hasSymbol ? 14 : 5,
      }
    case 'm':
      return {
        fontSize: 24,
        width: symbolCount > 2 ? 100 : 75,
        height: 55,
        paddingTop: hasSymbol ? 20 : 10,
      }
    case 'l':
      return {
        fontSize: 30,
        width: symbolCount > 2 ? 120 : 75,
        height: 60,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: hasSymbol ? 22 : 16,
      }
  }
}

export const TextSymbolInput: React.FC<TextSymbolInputProps> = ({
  label,
  value,
  textarea,
  symbols,
  multipleSymbols,
  onChange,
  fieldProps,
  inputProps = {},
  textInputProps = {},
  symbolInputProps = {},
  readonly,
  debounce,
  onClick,
  inputLockProps,
}) => {
  const textInputRef = useRef<HTMLInputElement>(null)
  const textareaInputRef = useRef<HTMLTextAreaElement>(null)
  const symbolInputRef = useRef<HTMLInputElement>(null)
  const [symbolModalOpen, setSymbolModalOpen] = useState(false)

  const sessionState = useSessionState()
  const [measureCallback, { height }] = useMeasure<HTMLTextAreaElement>()
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // @ts-ignore
    measureCallback(textareaInputRef.current)
    // @ts-ignore
    return () => measureCallback(null)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const emojisSupported = supportsEmojis()
  const valueSymbols = splitter.splitGraphemes(value.symbol || '')

  const extraTextInputProps: Partial<InputProps> = { ...inputProps, ...textInputProps }
  extraTextInputProps.style = { ...(extraTextInputProps.style || {}), flex: '1 1 auto' }

  const inputSize: InputSize = inputProps.inputSize || symbolInputProps.inputSize || 's'
  const extraSymbolInputProps: Partial<InputProps> = { ...inputProps, ...symbolInputProps }
  extraSymbolInputProps.style = {
    ...getSymbolStylesForSize(inputSize, !!value.symbol, valueSymbols.length),
    textAlign: 'center',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    backgroundColor: 'white',
    lineHeight: 1.2,
    ...(multipleSymbols && !!value.symbol ? { paddingTop: 2, paddingBottom: 2 } : {}),
    ...(textarea ? { height: height + 32 } : {}),
    ...(emojisSupported || !value.symbol
      ? {}
      : {
          background: multipleSymbols
            ? splitter
                .splitGraphemes(value.symbol || '')
                .map((symbol, i) => `url('${require(`common/assets/emoji/${getEmojiId(symbol)}.png`)}')`)
                .join(', ')
            : `url('${require(`common/assets/emoji/${getEmojiId(value.symbol)}.png`)}')`,
          backgroundSize: '1em',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }),
    ...(extraSymbolInputProps.style || {}),
  }

  return (
    <>
      <Field label={label} fieldProps={fieldProps}>
        <Row>
          <DebouncedInputComponent<string>
            debounce={debounce ? (typeof debounce === 'number' ? debounce : 750) : false}
            value={value.text || ''}
            onChange={text => onChange({ ...value, text: text || '' })}
            children={({ value: textValue, onChange: debouncedTextOnChange }) => {
              return inputLockProps ? (
                <InputLockComponent key={inputLockProps.fieldUid} disregard={!sessionState} {...inputLockProps}>
                  {(lockProps, lockState) => (
                    <>
                      {textarea ? (
                        <AutosizeTextareaInput
                          ref={textareaInputRef}
                          {...extraTextInputProps}
                          {...lockProps}
                          {...lockState}
                          value={textValue}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            debouncedTextOnChange(e.target.value || '')
                            lockProps.onChange(e.target.value || '')
                          }}
                          disabled={readonly || lockState.disabled}
                        />
                      ) : (
                        <TextInput
                          onClick={value.text === undefined || !value.text.length ? onClick : undefined}
                          ref={textInputRef}
                          {...extraTextInputProps}
                          {...lockProps}
                          {...lockState}
                          value={textValue}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            debouncedTextOnChange(e.target.value || '')
                            lockProps.onChange(e.target.value || '')
                          }}
                          disabled={readonly || lockState.disabled}
                        />
                      )}
                    </>
                  )}
                </InputLockComponent>
              ) : (
                <>
                  {textarea ? (
                    <AutosizeTextareaInput
                      ref={textareaInputRef}
                      {...extraTextInputProps}
                      value={textValue}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => debouncedTextOnChange(e.target.value || '')}
                      disabled={readonly}
                    />
                  ) : (
                    <TextInput
                      onClick={value.text === undefined || !value.text.length ? onClick : undefined}
                      ref={textInputRef}
                      {...extraTextInputProps}
                      value={textValue}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => debouncedTextOnChange(e.target.value || '')}
                      disabled={readonly}
                    />
                  )}
                </>
              )
            }}
          />
          <Spacer size="s" />
          <TextInput
            className="emoji-btn"
            ref={symbolInputRef}
            type={multipleSymbols && !!value.symbol ? 'submit' : 'text'}
            placeholder={readonly ? '' : '+'}
            {...extraSymbolInputProps}
            value={multipleSymbols ? valueSymbols.join('\n ') : value.symbol || ''}
            readOnly
            disabled={readonly}
            onClick={() => setSymbolModalOpen(true)}
          />
        </Row>
      </Field>
      <SymbolSelectModal
        isOpen={symbolModalOpen}
        symbols={symbols}
        multiple={multipleSymbols}
        value={value.symbol || null}
        onChange={symbol => {
          onChange({ ...value, symbol })
          !multipleSymbols && setTimeout(() => setSymbolModalOpen(false), 150)
        }}
        onClose={() => {
          setSymbolModalOpen(false)
          setTimeout(() => symbolInputRef.current && symbolInputRef.current.blur())
        }}
      />
    </>
  )
}
