/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { P, H3, Column, Spacer } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { InlineCards, InlineCard } from 'dashboards/common/InlineCard'
import { LinkButton } from 'common/LinkButton'
import { Cell, CellInner, Cells } from 'dashboards/common/Cell'

export const Resources: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route}>
      <Cells>
        {(route.subpages || []).map((subpage, i) => (
          <Cell key={i}>
            <CellInner>
              <Column flex="1 1 auto">
                <H3 children={subpage.title} />
              </Column>
              <Column flex="none">
                <LinkButton
                  size="s"
                  to={route.path + subpage.path}
                  children="View"
                  style={{ display: 'inline-block' }}
                />
              </Column>
            </CellInner>
          </Cell>
        ))}
      </Cells>
    </Page>
  )
}
