import styled from 'styled-components'
import { Button, ButtonSize } from 'common/ui'

const sizes: { [key in ButtonSize]: number } = {
  xxs: 15,
  xs: 20,
  s: 30,
  m: 40,
  l: 60,
  xl: 80,
}

export const CircleButton = styled(Button)`
  border-radius: 100px;
  padding: 0;
  width: ${p => sizes[p.size || 'm']}px;
  height: ${p => sizes[p.size || 'm']}px;
  font-size: ${p => Math.round(sizes[p.size || 'm'] * 0.35)}px;
  text-align: 'center';
  line-height: 1;
  &::after {
    content: none;
  }
`
