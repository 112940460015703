import React from 'react'
import { HeadingSection, QuoteSection, SectionProperty, SectionPropsBase, TableSection } from 'shared/fac-man/types'
import { SectionPanel, DividerSection, ListSection, MediaGridSection, TextSection } from 'shared/session/types'
import { GenericDividerSection } from 'session/sections/Divider'
import { GenericListSection } from 'session/sections/List'
import { GenericGridSection } from 'session/sections/MediaGrid'
import { GenericTextSection } from 'session/sections/Text'
import { GenericDownloadResourceSection } from 'session/sections/DownloadResourceSection'
import { HeadingSection as HeadingSectionComponent } from './HeadingSection'
import { QuoteSection as QuoteSectionComponent } from './QuoteSection'
import { TableSection as TableSectionComponent } from './TableSection'
import flattenDeep from 'lodash/flattenDeep'
import styled, { css } from 'styled-components'
import { fontBlack, fontBold } from 'fonts'
import { Container } from 'common/ui'

interface Props extends SectionPropsBase {
  property: 'panel_sections'
}

type SectionTypes =
  | DividerSection
  | ListSection
  | MediaGridSection
  | TextSection
  | HeadingSection
  | QuoteSection
  | TableSection

const sectionComponents: {
  [key in Exclude<SectionProperty, 'panel_sections'>]: React.FC<
    SectionPropsBase & { property: key; panel: SectionPanel }
  >
} = {
  divider_sections: GenericDividerSection,
  list_sections: GenericListSection,
  media_grid_sections: GenericGridSection,
  text_sections: GenericTextSection,
  heading_sections: HeadingSectionComponent,
  quote_sections: QuoteSectionComponent,
  table_sections: TableSectionComponent,
  download_resource_sections: GenericDownloadResourceSection,
}

const sections: Exclude<SectionProperty, 'panel_sections'>[] = [
  'divider_sections',
  'heading_sections',
  'list_sections',
  'media_grid_sections',
  'quote_sections',
  'table_sections',
  'text_sections',
]

const borderThemes: {
  [key in Props['section']['theme']]: {
    color: string
    style: 'solid' | 'dashed'
    width: number
  }
} = {
  purple: { color: '#813ABA', style: 'solid', width: 2 },
  'purple-gradient': { color: '#813ABA', style: 'solid', width: 4 },
  'purple-dashed': { color: '#813ABA', style: 'solid', width: 4 },
  blue: { color: '#2EADF0', style: 'solid', width: 4 },
  green: { color: '#4EBE40', style: 'solid', width: 4 },
  material: { color: '#f1f1f1', style: 'solid', width: 4 },
}

export const PanelSection: React.FC<Props> = ({ property, section, index }) => (
  <Wrapper borderTheme={section.theme || 'purple'}>
    <div style={{ background: '#fff' }}>
      {section.title && <Title borderTheme={section.theme || 'purple'}>{section.title}</Title>}
      {flattenDeep(
        sections.map((sectionProperty, i) => {
          const childrenSections: SectionTypes[] = section[sectionProperty] || []
          return childrenSections.map((sectionDetails, idx) => {
            const Component = sectionComponents[sectionProperty]
            // @ts-ignore
            return <Component key={`${sectionProperty}${idx}`} section={sectionDetails} />
          })
        })
      )
        .sort((a, b) => {
          return a.props.section.order - b.props.section.order
        })
        .map((component, idx) => {
          return <Container marginBottom={30} key={idx} children={component} />
        })}
    </div>
  </Wrapper>
)
const getTheme = (themeName: Props['section']['theme']) => {
  return borderThemes[themeName]
}

const Wrapper = styled.div<{ borderTheme: Props['section']['theme'] }>`
  position: relative;
  box-sizing: border-box;
  border: ${p => `${getTheme(p.borderTheme).width}px solid ${getTheme(p.borderTheme).color}`};
  border-radius: 10px;
  padding: 30px;
  background: #fff;

  > div {
    > * {
      margin-top: 0;
    }
  }

  ${p =>
    p.borderTheme === 'purple-gradient'
      ? css`
          background-clip: padding-box;
          border: solid 4px transparent;
          z-index: 2;
          padding: 0;

          > div {
            background: #fff;
            padding: 30px;
            border-radius: 8px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -4px;
            border-radius: inherit;
            background: linear-gradient(
              270deg,
              rgba(125, 65, 223, 0) -5.72%,
              #7d41df 16.07%,
              #813aba 49.51%,
              #7d41df 84.37%,
              rgba(125, 65, 223, 0) 107.34%
            );
          }
        `
      : p.borderTheme === 'purple-dashed'
      ? css`
          border: 4px dashed rgba(129, 58, 186, 0.5);
        `
      : p.borderTheme === 'material'
      ? css`
          border-color: ${p.theme.buttonBorderTopColor};
          border-top-left-radius: 0;
          margin-top: 77px;
        `
      : ''};
`

const Title = styled.p<{ borderTheme: Props['section']['theme'] }>`
  position: relative;
  ${fontBold};
  color: ${p => `${getTheme(p.borderTheme).color}`};
  font-size: 1.3rem;
  line-height: 130%;
  letter-spacing: 0.03em;

  ${p =>
    p.borderTheme === 'purple-dashed'
      ? css`
          ${fontBlack};
        `
      : p.borderTheme === 'material'
      ? css`
          ${fontBlack};
          background-color: ${p.theme.buttonBorderTopColor};
          position: absolute;
          top: -47px;
          left: -4px;
          padding: 10px;
          color: #fff;
          padding-right: 20px;
          &:after {
            content: '';
            position: absolute;
            right: -35px;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 47px 0 0 35px;
            border-color: transparent transparent transparent ${p.theme.buttonBorderTopColor};
          }
        `
      : ''};
`
