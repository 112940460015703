import styled, { createGlobalStyle, InterpolationFunction } from 'styled-components'

import { fontHeavy, fontBold, fontRegular } from 'fonts'

import { SpacerProps, flexLogic } from 'common/ui'

interface TextAlignProps {
  align?: 'left' | 'center'
}

const textAlignLogic: InterpolationFunction<TextAlignProps> = ({ align }) => {
  return `text-align: ${align || 'left'};`
}

interface HeadingProps extends TextAlignProps {}

export const PrintGlobalStyle = createGlobalStyle<{ landscape?: boolean }>`
:root {
    font-size: 14px;
}

html, body, #root {
    all: initial;
}

*, *:before, *:after {
  box-sizing: border-box;
}

${p => (p.landscape ? '@page { size: landscape; }' : '')}
`

export const PrintPreserveBackgrounds = createGlobalStyle`
@media print { 
  body { 
    -webkit-print-color-adjust: exact; 
  } 
}
`

export const H1 = styled.h1<HeadingProps>`
  ${textAlignLogic}
  ${fontHeavy}

  margin: 0;
  line-height: 1;
  font-size: 2rem;
  letter-spacing: 0.05rem;
`

export const H2 = styled.h2<HeadingProps>`
  ${textAlignLogic}
  ${fontBold}

  margin: 0;
  line-height: 1;
  font-size: 1.5rem;
  letter-spacing: 0.01rem;
`

export const P = styled.p`
  ${fontRegular}

  margin: 0;
  line-height: 1.3;
  font-size: 1rem;
`

export const AvoidPageBreak = styled.div`
  page-break-inside: avoid;
`

const spacerSize = { xl: '4rem', l: '2rem', m: '1rem', s: '0.5rem', xs: '0.25rem', xxs: '0.1rem' }

export const Spacer = styled.div<SpacerProps>`
  ${flexLogic}
  width: ${p => spacerSize[p.size || 'm']};
  height: ${p => spacerSize[p.size || 'm']};
`

export const HideForPrint = styled.div`
  @media print {
    display: none;
  }
`
