import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import parse from 'date-fns/parse'
import formatDistance from 'date-fns/formatDistance'

import { ProviderEntity, DrupalAddressObj, ProviderLinkRequest } from 'shared/dashboard/types'

import { Cell } from './Cell'
import { Row, Column, H3, P, Hr, Button, Spacer, H2, SmallCaps } from 'common/ui'
import { SVG } from 'common/SVG'
import { AnchorButton } from 'common/LinkButton'
import { InfoTooltip } from 'common/Tooltip'
import { PromptModal } from 'common/PromptModal'

import { intersperse } from 'utils/intersperse'
import { toJson } from 'utils/apiUtils'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useGenericUser } from 'app/useGenericUser'
import { DrupalRole } from 'shared/types'

interface Props {
  provider: ProviderEntity
  showLicenseCount?: boolean
  selected?: boolean
  hideDetails?: boolean
  status?: ProviderLinkRequest['status']
  onRevoke?: () => void
}

const formatDrupalAddressObj = (address: DrupalAddressObj): string =>
  [
    address.premise,
    address.sub_premise,
    address.locality,
    address.postal_code,
    address.administrative_area,
    address.country,
  ]
    .filter(_ => _)
    .join(', ')

const checkFacilitatorAccess = (roles: DrupalRole[]) => {
  if (roles.find(role => role === 'sas-sg facilitator') !== undefined) return true
  if (roles.find(role => role === 'sas-sg senior facilitator') !== undefined) return true
  return false
}

export const ProviderCell: React.FC<Props> = ({
  provider,
  selected,
  showLicenseCount,
  hideDetails,
  status,
  onRevoke,
}) => {
  const { accessToken, drupalProfile } = useUserState()
  const { roles } = useGenericUser()
  const [revoking, setRevoking] = useState<boolean>(false)
  const [showEmailPrompt, setShowEmailPrompt] = useState<boolean>(false)
  const [emailing, setEmailing] = useState<boolean>(false)
  const [emailed, setEmailed] = useState<boolean | null>(null)
  const [productKeyCount, { loading: loadingProductKeyCount }] = useEndpoint<number>(
    checkFacilitatorAccess(roles) ? `/api/v1/product_keys/count?provider_id=${provider.id}&status=available` : null,
    0
  )
  const emails: string[] = provider.administrators ? provider.administrators.map(({ email }) => email) : []
  const purchaseUrl = `${process.env.REACT_APP_SST_BASE_URL}/products/additional-cadet-places?provider=${provider.id}`
  const isProviderAdmin = !!provider.administrators.find(admin => admin.id === drupalProfile?.user_id)

  const handleEmailRequest = (email: string | null) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setEmailed(null)
      setEmailing(true)
      setShowEmailPrompt(false)
    })
    if (!email) return
    const formData = new FormData()
    formData.append('email', email)
    formData.append('url', purchaseUrl)
    fetch(`/api/v1/providers/${provider.id}/email_purchase_request`, {
      method: 'POST',
      body: formData,
      ...getAuthRequestParams(accessToken),
    })
      .then(toJson)
      .then((response: any) =>
        ReactDOM.unstable_batchedUpdates(() => {
          setEmailing(false)
          setEmailed(true)
        })
      )
      .catch(() =>
        ReactDOM.unstable_batchedUpdates(() => {
          setEmailing(false)
          setEmailed(false)
        })
      )
  }

  return (
    <>
      <Cell selected={selected}>
        <Row padding={20} alignItems={status === 'pending' ? 'center' : 'flex-start'}>
          <Column flex="1 1 auto">
            <H2 style={{ color: '#6737B5' }} children={provider.name} />
            {provider.subscription && (
              <>
                <Spacer size="s" />
                <SmallCaps style={{ textTransform: 'uppercase' }} children={provider.subscription} />
              </>
            )}
            {!hideDetails && (
              <P style={{ marginBottom: 0 }}>
                Service Address: {provider.service_address && formatDrupalAddressObj(provider.service_address)}
                <br />
                {/* Postal Address: {provider.postal_address && formatDrupalAddressObj(provider.postal_address)}
              <br /> */}
                Email: {provider.service_email}
                <br />
                Phone 1: {provider.phone_1}
                <br />
                Phone 2: {provider.phone_2}
                <br />
                Account Administrator:{' '}
                {provider.administrators && provider.administrators.map(({ name }) => name).join(', ')}
                <br />
                Administrator Email:{' '}
                {emails
                  .map((email, i) => <a key={i} href={`mailto:${email}`} children={email} />)
                  .reduce(
                    intersperse(i => <span key={'s' + i} children=", " />),
                    []
                  )}
              </P>
            )}
            {!hideDetails && provider.subscription_end_date && provider.subscription_end_date.match(/\d/) && (
              <P style={{ marginBottom: 0 }}>
                <small>
                  SAS Provider Package renews in{' '}
                  {formatDistance(parse(provider.subscription_end_date, 'yyyy-LL-dd', new Date()), new Date())}
                </small>
              </P>
            )}
            {status && (
              <>
                <Spacer size="s" />
                <SmallCaps>Status: {status}</SmallCaps>
              </>
            )}
            {!hideDetails && isProviderAdmin && (
                <div style={{ marginTop: 25 }}>
                  <AnchorButton
                    size="xs"
                    target="_blank"
                    children="Go To SAS Provider Admin Page"
                    href="https://www.sst-institute.net/sas-provider-admin-page"
                  />
                </div>
            )}
          </Column>
          {onRevoke && (
            <Column justifyContent="center">
              <Button
                size="xs"
                theme="orange"
                children={status === 'pending' ? 'Cancel...' : 'Unlink...'}
                disabled={revoking}
                onClick={() => {
                  if (
                    window.confirm(
                      status === 'pending'
                        ? `Are you sure you want to cancel your request to link with this provider?\nYou will be able to link again later if needed.`
                        : `Are you sure you want unlink yourself from this SAS Provider?\nYou will be able to link again later if needed.`
                    )
                  ) {
                    onRevoke()
                    setRevoking(true)
                  }
                }}
              />
            </Column>
          )}
        </Row>
        {showLicenseCount && checkFacilitatorAccess(roles) && (
          <>
            <Hr />
            <Row>
              <Column flex="1 1 auto" padding={20}>
                <H3 style={{ color: '#6737B5' }}>Available Cadet Places</H3>
                <Row alignItems="center" paddingTop={5}>
                  {!loadingProductKeyCount && productKeyCount !== null && productKeyCount < 6 && (
                    <>
                      <InfoTooltip
                        content="This provider is running low on Cadet Places"
                        placement="right"
                        tooltipStyle={{ minWidth: 250 }}>
                        <SVG size={28} viewboxSize={37}>
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.54175 18.5003C1.54175 27.8662 9.13425 35.4587 18.5001 35.4587C27.8659 35.4587 35.4584 27.8662 35.4584 18.5003C35.4584 9.1345 27.8659 1.54199 18.5001 1.54199C9.13425 1.54199 1.54175 9.1345 1.54175 18.5003ZM32.3751 18.5003C32.3751 26.1633 26.163 32.3753 18.5001 32.3753C10.8371 32.3753 4.62508 26.1633 4.62508 18.5003C4.62508 10.8374 10.8371 4.62533 18.5001 4.62533C26.163 4.62533 32.3751 10.8374 32.3751 18.5003ZM18.5006 27.7477C19.3523 27.7477 20.0428 27.0575 20.0428 26.2061C20.0428 25.3546 19.3523 24.6644 18.5006 24.6644C17.6489 24.6644 16.9584 25.3546 16.9584 26.2061C16.9584 27.0575 17.6489 27.7477 18.5006 27.7477ZM20.0473 9.24775H16.963V21.5811H20.0473V9.24775Z"
                            fill="currentColor"
                          />
                        </SVG>
                      </InfoTooltip>
                      <Spacer size="xs" />
                    </>
                  )}
                  <div style={{ fontSize: 24 }}>
                    {loadingProductKeyCount ? '---' : String(productKeyCount).padStart(3, '0')}
                  </div>
                </Row>
                <Spacer size="m" />
                <H3 style={{ color: '#6737B5' }}>
                  Top Up Inventory
                  <InfoTooltip
                    iconSize={16}
                    content={
                      <>
                        <p style={{ marginTop: 0 }}>
                          Purchase additional Cadet Places now or send a request to an individual to pay for any number
                          of additional Cadet Places through a purchase link sent via email.{' '}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          Once paid, your SAS Provider inventory will top up allowing you to add new cadets to SAS
                          groups.
                        </p>
                      </>
                    }
                  />
                </H3>
                <Row alignItems="center" paddingTop={5}>
                  <Column>
                    <AnchorButton withChevron size="xs" target="_blank" children="Buy Now" href={purchaseUrl} />
                  </Column>
                  <Spacer size="m" />
                  <Button
                    size="xs"
                    children="Send Request..."
                    disabled={emailing}
                    onClick={() => setShowEmailPrompt(true)}
                  />
                  {emailed !== null && (
                    <>
                      <Spacer size="s" />
                      <img
                        src={
                          emailed
                            ? require('reporting/assets/images/tick-circle.svg')?.default
                            : require('reporting/assets/images/cross-circle.svg')?.default
                        }
                        width={20}
                        height={20}
                        alt=""
                      />
                    </>
                  )}
                </Row>
              </Column>
            </Row>
          </>
        )}
      </Cell>
      {showEmailPrompt && (
        <PromptModal
          isOpen
          label={`Enter email:`}
          placeholder="person@domain.com"
          inputType="email"
          onSubmit={handleEmailRequest}
        />
      )}
    </>
  )
}
