import React, { useState } from 'react'
import styled from 'styled-components'

import { Skill } from 'skill-tracker/types'
import { Row, Column, Padding, Spacer, P, Button, H2, Hr } from 'common/ui'
import { Sticker } from '../common/Sticker'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'

import { useSkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { intersperseSpacers } from 'utils/intersperseSpacers'

export const SkillSelectModal: React.FC<{
  date: string | null
  onSave: (date: string, skill: Skill) => void
  onClose: () => void
  skills?: Skill[]
  title?: string
}> = ({ date, onSave, onClose, skills, title = 'Select a skill' }) => {
  const { state } = useSkillTrackerState()
  const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null)
  if (!skills) skills = state.skills

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  const handleSave: typeof onSave = (date, skill) => {
    delayedOnClose()
    onSave(date, skill)
  }

  if (!date) return null
  return (
    <MobileModalPanel isOpen={isOpen} onRequestClose={delayedOnClose}>
      <Padding size="s" style={{ paddingBottom: 0 }}>
        <Row>
          <Column flex="1 1 auto">
            <H2 style={{ lineHeight: 0.95 }}>{title}</H2>
          </Column>
          <Spacer size="s" />
          <Column>
            <Sticker symbol={'⭐️'} size={24} active approved hideExtraSticker />
          </Column>
        </Row>
      </Padding>
      <Hr margin="s" />
      <Row>
        <Padding size="s">
          <Column style={{ width: '100%' }}>
            {intersperseSpacers(
              skills.map(skill => (
                <SkillTile
                  key={skill.id}
                  flex="1 0 0px"
                  userSelectNone
                  onClick={() => setSelectedSkill(skill)}
                  onDoubleClick={() => handleSave(date, skill)}
                  selected={skill === selectedSkill}>
                  <Padding size="s">
                    <Row alignItems="center">
                      <P style={{ margin: 0, fontSize: 14 }}>{skill.text}</P>
                      <Spacer flex="1 1 auto" />
                      <Sticker symbol={skill.symbol || '?'} text={skill.text} size={32} active approved />
                    </Row>
                  </Padding>
                </SkillTile>
              )),
              's'
            )}
          </Column>
        </Padding>
      </Row>
      <Spacer size="m" />
      <Padding size="s">
        <Row justifyContent="flex-end">
          <Button size="s" onClick={delayedOnClose} theme="white">
            Close
          </Button>
          <Spacer size="s" />
          <Button
            size="s"
            theme="blue"
            onClick={() => selectedSkill && handleSave(date, selectedSkill)}
            disabled={!selectedSkill}>
            Continue
          </Button>
        </Row>
      </Padding>
    </MobileModalPanel>
  )
}

const SkillTileBase = styled(Column)`
  width: 100%;
  border: 1px solid transparent;
`

const SkillTile = styled(SkillTileBase)<{ selected?: boolean }>`
  border-color: ${p => p.theme.thinOutlineColor};
  border-radius: ${p => p.theme.borderRadius};
  background-color: ${p => (p.selected ? p.theme.buttonBorderTopColor : 'white')};
  cursor: pointer;

  color: ${p => (p.selected ? 'white' : 'inherit')};
  & p {
    color: ${p => (p.selected ? 'white' : 'inherit')};
  }

  :hover${p => (p.selected ? ':not(:enabled)' : '')} {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${p => p.theme.buttonBorderTopColor};
  }
`
