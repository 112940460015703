import hotkeys, { HotkeysEvent } from 'hotkeys-js'
import { useCallback, useEffect } from 'react'

type CallbackFunction = (event: KeyboardEvent, handler: HotkeysEvent) => void

export function useHotkey(keys: string, callback: CallbackFunction, deps: any[] = []) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoisedCallback = useCallback(callback, deps)
  useEffect(() => {
    hotkeys(keys, memoisedCallback)
    return () => hotkeys.unbind(keys, memoisedCallback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoisedCallback])
}
