import React from 'react'
import { useParams } from 'react-router-dom'

import { NavRouteProps } from 'dashboards/types'
import { GroupEntity } from 'shared/dashboard/types'

import { P, Spacer, Row } from 'common/ui'
import { LinkButton } from 'common/LinkButton'

import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Cells } from 'dashboards/common/Cell'
import { MentorCell } from 'dashboards/common/MentorProfileCell'
import { SpinnerWithLabel } from 'common/Spinner'
import { ProviderPlacesCount } from 'dashboards/common/ProviderPlacesCount'

import { useGenericUser } from 'app/useGenericUser'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { groupAtCapacity } from 'dashboards/utils/facilitatorUtils'
import { baseUrl } from './FacilitatorDashboard'

export const Cadets: React.FC<NavRouteProps> = ({ route }) => {
  const user = useGenericUser()
  const { groupUid } = useParams<{ groupUid: string }>()
  const [group, { loading: loadingGroup, fetch: refetchGroup }] = useEndpoint<GroupEntity>(`/api/v1/groups/${groupUid}`)
  return (
    <Page route={route} title="Create a Group">
      <Spacer size="l" />
      {loadingGroup || !group ? (
        <SpinnerWithLabel label="Loading data..." />
      ) : (
        <>
          <HeaderHr
            children={`Allocate Cadet Places`}
            sideContent={<ProviderPlacesCount providerUid={group.provider_uid} />}
          />
          <P>
            To allocate a Cadet Place to this group an appropriate adult must provide consent for the child’s
            participation. Enter the consenting adult details and send a registration request.
          </P>
          <P>
            Groups can be created with minimum of 1 individual Cadet. SAS Facilitators can create group of up to 4
            Cadet's in any one software group.
          </P>
          <P>
            If you and another SAS Facilitator are running two software groups to create a combined group of 4-6 Cadets
            with two facilitators, the second group is best created in the other SAS Facilitator's Dashboard. The Lead
            Facilitator for any group can be changed from with the one SAS Provider network through the "Unassign"
            button and then "Unassigned Groups" menu tab.
          </P>
          <P>
            If you have a co-facilitator or SAS Assistant joining you for any individual group meeting, they are added
            later when you schedule your group meetings and can be changed at any time before the meeting starts.
          </P>
          <Spacer />
          <P>
            <strong>Senior SAS Facilitators</strong>
          </P>
          <P>
            When Senior SAS Facilitators are creating a group there is an option to add a co-facilitator or SAS
            Assistant at the overall group level to unlock a maximum of 6 Cadets in one software group.
          </P>
          <Cells>
            {group.primary_mentors.map((mentor, i) => (
              <MentorCell
                key={mentor.uid}
                index={i}
                mentor={mentor}
                groupUid={groupUid}
                context="group-edit"
                onUpdate={() => refetchGroup()}
                onReloadRequested={() => refetchGroup()}
              />
            ))}
          </Cells>
          <Spacer size="m" />
          {groupAtCapacity(group, user) && <P>You've reached the maximum allowed cadets for your current group.</P>}
          <Row>
            <LinkButton
              children="+ Add Cadet"
              to={`${baseUrl}/groups/${groupUid}/cadets/new`}
              disabled={groupAtCapacity(group, user)}
            />
            <Spacer size="s" />
            <LinkButton to={`${baseUrl}/groups/${groupUid}`} children="Save &amp; Exit" />
          </Row>
        </>
      )}
    </Page>
  )
}
