import React from 'react'

import {
  AppBackground,
  Row,
  Panel,
  H1,
  Spacer,
  Column,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  GRILL_TL,
  GRILL_TR,
  TAB_B_S,
} from 'common/ui'

import { useMedia } from 'utils/useMedia'

export const GenericPage: React.FC<{ title: string }> = ({ title, children }) => {
  const compact = useMedia('(max-width: 500px)')

  return (
    <AppBackground>
      <Row flex margin="xs" marginBottom>
        <Spacer flex />
        <Column flex="0 1 485px">
          <Spacer flex="1 1 0px" />

          <Panel
            padding={compact ? true : '13px 30px 35px 30px'}
            flair={compact ? [] : [CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR, TAB_B_S]}>
            <H1 margin align="center">
              {title}
            </H1>
            <Spacer />
            {children}
            <Spacer />
          </Panel>
          <Spacer />
          <Spacer flex="2 1 auto" />
        </Column>
        <Spacer flex />
      </Row>
    </AppBackground>
  )
}
