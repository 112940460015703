import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  HydratedSupplementarySlideRef,
  SupplementarySlide,
  Section,
  SectionPanel,
  InputSection as InputSectionType,
  DrawingSection as DrawingSectionType,
  TurnTakingSection as TurnTakingSectionType,
  EmotionometerSection as EmotionometerSectionType,
  EmbeddedActivitySection as EmbeddedActivitySectionType,
  InputValueContext,
} from 'shared/session/types'
import {
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  TAB_B_L,
  TAB_T_L,
  H1,
  CUT_BL_DARK,
  CUT_BR_DARK,
  CUT_TL_DARK,
  CUT_TR_DARK,
  TAB_B_DARK,
  TAB_T_DARK,
  Padding,
  Input,
  Spacer,
  Hr,
  OutlineButton,
  Row,
  Button,
  P,
  Textarea,
  Column,
} from 'common/ui'
import { SectionsPanelStyled } from './common/SectionsPanel'
import { InputSection } from './sections/Input'
import { DrawingSection } from './sections/drawing/Drawing'
import { TurnTakingSection, TurnTakingSectionFacilitator } from './sections/TurnTaking'
import { EmotionometerSection } from './sections/Emotionometer'
import { EmbeddedActivitySection } from './sections/EmbeddedActivity'
import { useInputContext, InputContext } from './InputContext'
import { useFacilitatorState } from './hooks/useProfileState'
import { FriendshipAward } from './sections/custom/FriendshipAward'
import { NegotiationTactics } from './sections/custom/extension-topics/NegotiationTactics'
import { RecoveryTactics } from './sections/custom/extension-topics/RecoveryTactics'
import { ConversationEquations } from './sections/custom/extension-topics/ConversationEquations'
import { FeelingsAlert } from './sections/custom/extension-topics/FeelingsAlert'
import { StressBallActivity } from './sections/custom/StressBallActivity'
import {
  CodeCard,
  CodeCardStep,
  EmotionometerType,
  EmotionOption,
  Gadget,
  GadgetUseLocation,
  GadgetUseOption,
} from 'shared/e-telligence/types'
import { relaxationGadgetEmojis, validate as validateRelaxationGadget } from 'e-telligence/RelaxationGadgetEdit'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import {
  AddUpdateCustomRelaxationAction,
  ADD_UPDATE_CUSTOM_CODE_CARD,
  ADD_UPDATE_CUSTOM_RELAXATION_GADGET,
} from 'shared/e-telligence/actionTypes'
import { Navigation } from 'e-telligence/common/Navigation'
import { Field } from 'common/Field'
import { InlineCheckboxGroup } from 'common/CheckboxGroup'
import {
  emotionOptions,
  emotionRangeDistribution,
  emotionRangeOptionsUnordered,
  emotionRangeValues,
  gadgetUseLocationOptions,
} from 'e-telligence/constants/typeValueMaps'
import { CheckboxButtonGroupField } from 'common/FieldInputs'
import { EmotionometerBar } from 'e-telligence/common/EmotionometerBar'
import { AutosizeTextarea } from 'common/AutosizeTextarea'
import { getInputProps } from 'utils/virtualKeyboard'
import { ValidationErrorList } from 'common/ValidationErrorList'
import { SymbolSelectModal } from 'common/SymbolSelectModal'
import { useUserState } from 'app/UserState'
import { GadgetViewModalInner } from './sections/custom/relaxation-gadgets/GadgetViewModal'
import { sortByKey } from 'utils/sortUtils'
import { emptyCodeCard, prepareStepsForSave, validate as validateCodeCard } from 'e-telligence/CodeCardEdit'
import { intersperse } from 'utils/intersperse'
import { CodeCardViewInner } from 'e-telligence/CodeCardView'
import { CodeCardWrapper } from './gadget-tray/CodeCardsModal'
import ReactDOM from 'react-dom'

interface SupplementarySlideContainerProps {
  slide: HydratedSupplementarySlideRef
}

interface SupplementarySlideProps {
  slide: SupplementarySlide
  sectionBase: Section
}

export const SupplementarySlideContainer: React.FC<SupplementarySlideContainerProps> = ({ slide }) => {
  const inputContext = useInputContext()

  const sectionBase: Section = {
    id: 0,
    _owner: `supplementary_slides_${slide.uid}`,
    _owner_id: 0, // TODO: needs to be uid I guess??
    order: 0,
  }

  const slideProps: SupplementarySlideProps = { sectionBase, slide: slide.supplementarySlide }

  const newInputContext: InputValueContext = { ...inputContext }

  let node: ReactNode | null = null

  switch (slide.supplementarySlide.type) {
    case 'blank':
      node = <BlankSlide {...slideProps} />
      break
    case 'text':
      if (slide.supplementarySlide.inputUsage === 'facilitator') newInputContext.participant_uid = 'shared'
      node = <TextSlide {...slideProps} />
      break
    case 'text-draw':
      // drawing uses the same usage variable as input so don't need to check both
      if (slide.supplementarySlide.inputUsage === 'facilitator') newInputContext.participant_uid = 'shared'
      node = <TextDrawSlide {...slideProps} />
      break
    case 'draw':
      if (slide.supplementarySlide.drawingUsage === 'facilitator') newInputContext.participant_uid = 'shared'
      node = <DrawSlide {...slideProps} />
      break
    case 'turn-taking':
      node = <TurnTakingSlide {...slideProps} />
      break
    case 'emotionometer':
      node = <EmotionometerSlide {...slideProps} />
      break
    case 'club-rules':
      node = <ClubRulesSlide {...slideProps} />
      break
    case 'friendship-award':
      node = <FriendshipAwardSlide {...slideProps} />
      break
    case 'extension-topic':
      node = <ExtensionTopicSlide {...slideProps} />
      break
    case 'stress-ball':
      node = <StressBallSlide {...slideProps} />
      break
    case 'custom-relaxation-gadget':
      node = <CustomGadgetSlide {...slideProps} />
      break
    case 'custom-code-card':
      node = <CustomCodeCardSlide {...slideProps} />
      break
  }
  if (node) {
    return (
      <InputContext.Provider
        children={node}
        value={{
          ...newInputContext,
          owner: sectionBase._owner,
          owner_id: sectionBase._owner_id,
        }}
      />
    )
  } else {
    return <>Supplementary slide type {slide.supplementarySlide.type} not found</>
  }
}

const PanelWrapper: React.FC<{ slide: SupplementarySlide; noPadding?: boolean }> = ({ slide, noPadding, children }) => (
  <SectionsPanelStyled
    theme={slide.theme}
    panelTheme={slide.panelTheme || null}
    flex={'1 0 auto'}
    flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_B_L, TAB_T_L]}>
    <div style={noPadding ? { padding: '30px 0' } : { padding: '30px 20px' }}>{children}</div>
  </SectionsPanelStyled>
)

/// --------------------- ///

const BlankSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  return <BlankSlideDiv />
}

const BlankSlideDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
`

/// --------------------- ///

const TextSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  const section: InputSectionType = {
    ...sectionBase,
    usage: slide.inputUsage || 'individual',
    type: slide.inputType || 'text',
    size: slide.inputSize || 's',
    lines: slide.inputTextareaLines || 1,
    label: slide.inputLabel,
    multiple_symbols: false,
    required: false,
    // placeholder: 'Placeholder',
  }
  return (
    <>
      <PanelWrapper slide={slide}>
        <H1 marginBottom={20}>{slide.title}</H1>
        <InputSection property="input_sections" panel={({} as any) as SectionPanel} index={0} section={section} />
      </PanelWrapper>
    </>
  )
}

/// --------------------- ///

const TextDrawSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  const panel = ({} as any) as SectionPanel
  const inputSection: InputSectionType = {
    ...sectionBase,
    usage: slide.inputUsage || 'individual',
    type: slide.inputType || 'text',
    size: slide.inputSize || 's',
    lines: slide.inputTextareaLines || 1,
    label: slide.inputLabel,
    multiple_symbols: false,
    required: false,
    // placeholder: 'Placeholder',
  }
  const drawingSection: DrawingSectionType = {
    ...sectionBase,
    include_text: false,
    include_pins: false,
    include_stickers: false,
    stickers: [],
    usage: slide.inputUsage || 'individual', // using input usage instead of drawingUsage for a reason
    size: slide.drawingSize || 'large',
    section_title: slide.inputUsage === 'facilitator' ? 'Facilitator drawing' : '',
  }
  return (
    <>
      <PanelWrapper slide={slide}>
        <H1 marginBottom={20}>{slide.title}</H1>
        <InputSection property="input_sections" panel={panel} index={0} section={inputSection} />
        <DrawingSection property="drawing_sections" panel={panel} index={1} section={drawingSection} />
      </PanelWrapper>
    </>
  )
}

/// --------------------- ///

const DrawSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  const panel = ({} as any) as SectionPanel
  const drawingSection: DrawingSectionType = {
    ...sectionBase,
    include_text: false,
    include_pins: false,
    include_stickers: false,
    stickers: [],
    usage: slide.drawingUsage || 'shared', // TODO: Change to individual once input saving bugs are resolved
    size: slide.drawingSize || 'large',
    section_title: 'Draw Below',
  }
  return (
    <>
      <PanelWrapper slide={slide}>
        <H1 marginBottom={20}>{slide.title}</H1>
        <DrawingSection property="drawing_sections" panel={panel} index={1} section={drawingSection} />
      </PanelWrapper>
    </>
  )
}

/// --------------------- ///

const TurnTakingSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  const facilitatorState = useFacilitatorState()
  const facilitatorShowCadetContent =
    facilitatorState &&
    facilitatorState.focusedParticipantUid &&
    slide.participantUids.indexOf(facilitatorState.focusedParticipantUid) >= 0

  const panel = ({} as any) as SectionPanel
  const section: TurnTakingSectionType = {
    ...sectionBase,
    type: slide.turnTakingType || 'ball',
    size: slide.turnTakingSize || 'large',
    wait_text: slide.turnTakingWaitText,
    prepare_text: slide.turnTakingPrepareText,
    active_text: slide.turnTakingActiveText,
  }
  // TODO: Exclude participant ids that aren't on supplementary slide in turn taking facilitator controls
  return (
    <>
      <PanelWrapper slide={slide}>
        <H1 marginBottom={20}>{slide.title}</H1>
        {!!facilitatorState && !facilitatorShowCadetContent ? (
          <TurnTakingSectionFacilitator property="turn_taking_sections" panel={panel} index={1} section={section} />
        ) : (
          <TurnTakingSection property="turn_taking_sections" panel={panel} index={1} section={section} />
        )}
      </PanelWrapper>
    </>
  )
}

/// --------------------- ///

const EmotionometerSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  const panel = ({} as any) as SectionPanel
  const section: EmotionometerSectionType = {
    ...sectionBase,
    step: 1,
    start: 1,
    end: 10,
    labels: ['Low', 'Medium', 'High'],
    usage: slide.emotionometerUsage || 'individual',
    theme: slide.emotionometerTheme || 'blue',
  }
  return (
    <SectionsPanelStyled
      theme={slide.theme}
      panelTheme="blue"
      flex={'1 0 auto'}
      flair={[CUT_TL_DARK, CUT_TR_DARK, CUT_BL_DARK, CUT_BR_DARK, TAB_B_DARK, TAB_T_DARK]}>
      <div style={{ padding: '30px 20px' }}>
        <H1 marginBottom={20} style={{ color: '#fff' }}>
          {slide.title}
        </H1>
        <div style={{ padding: '30px 0' }}>
          <EmotionometerSection property="emotionometer_sections" panel={panel} index={1} section={section} />
        </div>
      </div>
    </SectionsPanelStyled>
  )
}

/// --------------------- ///

const ClubRulesSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  const panel = ({} as any) as SectionPanel
  const section: EmbeddedActivitySectionType = {
    ...sectionBase,
    activity: 'club-rules',
    // thanks typescript
    id_card_flippable: false,
    stress_ball_mode: null,
    invisible_ink_hide_cork: false,
    body_signals_emotion: 'anger',
    body_signals_image_size: 'fill',
    body_signals_placeholder_text: '',
  }
  return (
    <>
      <PanelWrapper slide={slide}>
        <EmbeddedActivitySection property="embedded_activity_sections" panel={panel} index={1} section={section} />
      </PanelWrapper>
    </>
  )
}

/// --------------------- ///

const FriendshipAwardSlide: React.FC<SupplementarySlideProps> = ({ slide }) => (
  <PanelWrapper slide={slide}>
    <FriendshipAward awardWinner={slide.friendshipWinner || ''} />
  </PanelWrapper>
)

/// --------------------- ///

const ExtensionTopicSlide: React.FC<SupplementarySlideProps> = ({ slide }) => (
  <>
    {slide.extensionTopicType === 'conversation-equations' && <ConversationEquations />}
    {slide.extensionTopicType === 'feelings-alerts' && <FeelingsAlert />}
    {slide.extensionTopicType === 'negotiation-tactics' && <NegotiationTactics />}
    {slide.extensionTopicType === 'play-recovery-tactics' && <RecoveryTactics />}
  </>
)

/// --------------------- ///

const StressBallSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => (
  <PanelWrapper slide={slide}>
    <StressBallActivity
      section={{
        ...sectionBase,
        activity: 'stress-ball',
        // thanks typescript
        id_card_flippable: false,
        stress_ball_mode: slide.stressBallMode || 'breathe',
        invisible_ink_hide_cork: false,
        body_signals_emotion: 'anger',
        body_signals_image_size: 'fill',
        body_signals_placeholder_text: '',
      }}
    />
  </PanelWrapper>
)

const CustomGadgetSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  const facilitatorState = useFacilitatorState()
  const facilitatorShowCadetContent =
    facilitatorState &&
    facilitatorState.focusedParticipantUid &&
    slide.participantUids.indexOf(facilitatorState.focusedParticipantUid) >= 0

  const { getBaseAction } = useUserState()
  const { dispatch: gadgetPackDispatch, state: gadgetPackState } = useGadgetPackState()
  const { customGadgets } = gadgetPackState

  const symbolInputRef = useRef<HTMLInputElement>(null)
  const [emojiModalOpen, setEmojiModalOpen] = useState(false)
  const [gadget, setGadget] = useState<Partial<Gadget>>({})
  const [buttonLabel, setButtonLabel] = useState<string>('Save')
  const [initialGadgetLength] = useState<number>(customGadgets.length)
  const [cadetVisibleGadget, setCadetVisibleGadget] = useState<Gadget | null>(null)

  const errors = validateRelaxationGadget(gadget)

  const updateGadget = (data: Partial<Gadget>) => setGadget({ ...gadget, ...data })

  const handleSave = () => {
    setButtonLabel('Saving...')
    if (errors.length > 0) return
    const saveGadget = { ...gadget } as Gadget
    if (!saveGadget.id) saveGadget.id = `customGadget${Date.now()}`
    gadgetPackDispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_RELAXATION_GADGET,
      gadget: saveGadget,
      addToCardHolder: false,
    } as AddUpdateCustomRelaxationAction)
    setButtonLabel('Saved')
  }

  const changedParticipant = facilitatorState ? facilitatorState.focusedParticipantUid : ''

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      setGadget({})
      setButtonLabel('Save')
    })
  }, [changedParticipant])

  useEffect(() => {
    if (customGadgets.length !== initialGadgetLength) {
      setCadetVisibleGadget(customGadgets.sort(sortByKey('id', 'descending'))[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customGadgets])

  const form = (
    <PanelWrapper slide={slide} noPadding>
      <Navigation title="Make your own relaxation gadget" />
      <Padding size="s" style={{ flex: 'none' }}>
        <Field label="Enter a name for your gadget">
          <Input
            autoFocus
            value={gadget.title || ''}
            onChange={e => updateGadget({ title: e.target.value })}
            maxLength={20}
            placeholder="20 characters max"
          />
        </Field>
        <Spacer />
        <Field label="Symbol">
          <Input
            ref={symbolInputRef}
            value={gadget.symbol || ''}
            style={{ fontSize: 28, width: 60, height: 50, padding: gadget.symbol ? 12 : 5, textAlign: 'center' }}
            readOnly
            placeholder="+"
            onClick={() => setEmojiModalOpen(true)}
          />
        </Field>
      </Padding>
      <Hr />
      <Padding size="s" style={{ flex: 'none' }}>
        <Field label="Where to use it">
          <InlineCheckboxGroup<GadgetUseOption>
            name="location"
            size="s"
            spacerSize="s"
            options={gadgetUseLocationOptions.filter(({ selectable }) => selectable)}
            value={gadget.location || []}
            onChange={location => updateGadget({ location: location as GadgetUseLocation[] })}
          />
        </Field>
      </Padding>
      <Hr />
      <Padding size="s" style={{ flex: 'none' }}>
        <Field label="What emotions(s) is it best used for?">
          <CheckboxButtonGroupField<EmotionometerType, EmotionOption>
            size="s"
            options={emotionOptions}
            value={gadget.emotion || []}
            onChange={emotion => updateGadget({ emotion: emotion || [] })}
            Component={OutlineButton}
            propsMapper={props => ({ ...props, selected: false, filled: !!props.selected, theme: 'blue' })}
          />
        </Field>
        <Spacer size="s" />
        <Field label="Select the emotion range(s) that it is useful for:">
          <EmotionometerBar
            type="anger"
            value={(gadget.emotionRange || [])
              .map(rangeVal => emotionRangeValues.indexOf(rangeVal))
              .filter(num => num >= 0)}
            distribution={emotionRangeDistribution}
            labels={emotionRangeOptionsUnordered.map(({ label }) => label)}
            onSelectSegment={indexes => {
              if (indexes.length >= 3) indexes = [indexes[indexes.length - 1], indexes[indexes.length - 2]]
              if (Math.max(...indexes) - Math.min(...indexes) < 2) {
                updateGadget({
                  emotionRange: indexes.map(num => emotionRangeOptionsUnordered[num].value),
                })
              }
            }}
          />
        </Field>
      </Padding>
      <Hr />
      <Padding size="s" style={{ flex: 'none' }}>
        <Field label="Explain how it works:" hint="List the steps involved in using this strategy to calm down.">
          <AutosizeTextarea
            placeholder={`e.g. Use my meditation app for 5-10 mins.`}
            value={gadget.description || ''}
            onChange={(e: any) => updateGadget({ description: e.target.value || '' })}
            style={{ minHeight: 80, minWidth: 260 }}
            {...getInputProps()}
          />
        </Field>
      </Padding>

      <Spacer size="s" flex="1 1 auto" />
      <Padding size="s" style={{ flex: 'none' }}>
        <Row>
          <ValidationErrorList errors={errors} style={{ color: '#19345d', border: '1px solid orange', padding: 5 }} />
        </Row>
        <Spacer size="s" flex="1 1 auto" />
        <Row justifyContent="center" padding="s">
          <Button onClick={() => handleSave()} size="s" disabled={errors.length > 0 || buttonLabel === 'Saved'}>
            {buttonLabel}
          </Button>
        </Row>
      </Padding>
      <SymbolSelectModal
        isOpen={emojiModalOpen}
        symbols={relaxationGadgetEmojis}
        value={gadget.symbol || null}
        onChange={symbol => {
          updateGadget({ symbol })
          setTimeout(() => setEmojiModalOpen(false), 150)
        }}
        onClose={() => {
          setEmojiModalOpen(false)
          setTimeout(() => symbolInputRef.current && symbolInputRef.current.blur())
        }}
      />
    </PanelWrapper>
  )

  if (!!facilitatorState) {
    if (facilitatorShowCadetContent) {
      if (slide.inputUsage === 'facilitator') {
        return form
      }
      return gadgetPackState.customGadgets.length > 0 ? (
        <>
          <PanelWrapper slide={slide}>
            <P>
              {' '}
              Note: All this cadet's custom relaxation gadgets are below, the new entry will not appear here until they
              select 'Save'
            </P>
          </PanelWrapper>
          {gadgetPackState.customGadgets.sort(sortByKey('id', 'descending')).map(customGadget => (
            <GadgetViewModalInner gadget={customGadget} compact />
          ))}
          <Spacer size="l" />
        </>
      ) : (
        <PanelWrapper slide={slide}>
          <P>Cadet has not added any custom gadgets yet</P>
        </PanelWrapper>
      )
    }
    return (
      <PanelWrapper slide={slide}>
        <P>
          Note:{' '}
          {slide.inputUsage === 'facilitator'
            ? 'Please view cadet tab to create a custom relaxation gadget'
            : "To view the relaxation gadget a cadet makes please select their tab. Their entry will not appear until they've saved it."}
        </P>
      </PanelWrapper>
    )
  }

  // for cadet
  if (slide.inputUsage !== 'facilitator') {
    return form
  }

  if (cadetVisibleGadget) {
    return <GadgetViewModalInner gadget={cadetVisibleGadget} compact />
  }
  return (
    <PanelWrapper slide={slide}>
      <P>Please wait for your relaxation gadget to be created</P>
    </PanelWrapper>
  )
}

const CustomCodeCardSlide: React.FC<SupplementarySlideProps> = ({ slide, sectionBase }) => {
  const facilitatorState = useFacilitatorState()
  const facilitatorShowCadetContent =
    facilitatorState &&
    facilitatorState.focusedParticipantUid &&
    slide.participantUids.indexOf(facilitatorState.focusedParticipantUid) >= 0

  const { getBaseAction } = useUserState()
  const { dispatch: gadgetPackDispatch, state: gadgetPackState } = useGadgetPackState()
  const { customCodeCards } = gadgetPackState

  const [codeCard, setCodeCard] = useState<CodeCard>(emptyCodeCard)
  const [saved, setSaved] = useState<boolean>(false)
  const [initialCardLength] = useState<number>(customCodeCards.length)
  const [cadetVisibleCard, setCadetVisibleCard] = useState<CodeCard | null>(null)

  const errors = validateCodeCard(codeCard)

  const updateCodeCard = (data: Partial<CodeCard>) => setCodeCard(codeCard => ({ ...codeCard, ...data }))
  const updateStep = (data: Partial<CodeCardStep>, stepIndex: number) => {
    setCodeCard(codeCard => ({
      ...codeCard,
      steps: codeCard.steps.map((step, i) => (stepIndex === i ? { ...step, ...data } : step)),
    }))
  }
  const handleAddStep = () => {
    setCodeCard(codeCard => ({
      ...codeCard,
      steps: codeCard.steps.concat([
        {
          label: `Step ${codeCard.steps.length + 1}`,
          description: '',
          imageUrl: '',
        },
      ]),
    }))
  }
  const handleSave = (addToCardHolder?: boolean) => {
    if (errors.length > 0) return
    const saveCodeCard = { ...codeCard, steps: prepareStepsForSave(codeCard.steps) } as CodeCard
    if (!saveCodeCard.id) saveCodeCard.id = `customCodeCard${Date.now()}`
    setSaved(true)
    gadgetPackDispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_CODE_CARD,
      codeCard: saveCodeCard,
      addToCardHolder,
    })
  }

  const changedParticipant = facilitatorState ? facilitatorState.focusedParticipantUid : ''

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      setCodeCard(emptyCodeCard)
      setSaved(false)
    })
  }, [changedParticipant])

  useEffect(() => {
    if (customCodeCards.length !== initialCardLength) {
      setCadetVisibleCard(customCodeCards.sort(sortByKey('id', 'descending'))[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customCodeCards])

  const form = (
    <PanelWrapper slide={slide} key={facilitatorState ? facilitatorState.focusedParticipantUid || 0 : 0}>
      <Column>
        <Field label="Enter a name for your skill code">
          <Input
            autoFocus={!codeCard.title}
            value={codeCard.title}
            onChange={e => updateCodeCard({ title: e.target.value })}
            maxLength={20}
            placeholder="20 characters max"
          />
        </Field>
        <Spacer />
        {codeCard.steps
          .map((step, i) => {
            return (
              <Field label={`Enter Step ${i + 1}`} key={i}>
                <Row responsive={600}>
                  <Textarea
                    flex="1 1 0px"
                    autoFocus={!step.description && !!codeCard.title}
                    placeholder="Enter text or leave blank"
                    value={(step.description as string) || ''}
                    onChange={(e: any) => updateStep({ description: e.target.value || '' }, i)}
                    style={{ minHeight: '8rem' }}
                    {...getInputProps()}
                  />
                </Row>
              </Field>
            )
          })
          .reduce(
            intersperse(i => <Spacer key={`s${i}`} />),
            []
          )}
        <Spacer />
        <OutlineButton onClick={handleAddStep}>Add Step +</OutlineButton>
      </Column>
      <Spacer size="m" />
      <Row>
        <ValidationErrorList errors={errors} />
      </Row>
      <Row justifyContent="center" padding="s">
        <Button
          onClick={() => handleSave()}
          disabled={errors.length > 0 || saved}
          children={saved ? 'Saved' : 'Save'}
        />
      </Row>
    </PanelWrapper>
  )

  // for facilitator
  if (!!facilitatorState) {
    if (facilitatorShowCadetContent) {
      if (slide.inputUsage === 'facilitator') {
        return form
      }
      return gadgetPackState.customCodeCards.length > 0 ? (
        <>
          <PanelWrapper slide={slide}>
            <P>
              Note: All this cadet's custom cards are below, the new entry will not appear here until they select 'Save'
            </P>
          </PanelWrapper>
          {gadgetPackState.customCodeCards.sort(sortByKey('id', 'descending')).map(customCodeCard => (
            <>
              <Spacer />
              <CodeCardWrapper>
                <CodeCardViewInner
                  hideBackButton
                  hideActionButton
                  cardId={customCodeCard.id}
                  baseUrl=""
                  state={gadgetPackState}
                  dispatch={gadgetPackDispatch}
                />
              </CodeCardWrapper>
            </>
          ))}
          <Spacer size="l" />
        </>
      ) : (
        <PanelWrapper slide={slide}>
          <P>Cadet has not added any custom cards yet</P>
        </PanelWrapper>
      )
    }
    return (
      <PanelWrapper slide={slide}>
        <P>
          Note:{' '}
          {slide.inputUsage === 'facilitator'
            ? 'Please view cadet tab to create a custom card'
            : "To view the code card a cadet makes please select their tab. Their entry will not appear until they've saved it."}
        </P>
      </PanelWrapper>
    )
  }

  // for cadet
  if (slide.inputUsage !== 'facilitator') {
    return form
  }

  if (cadetVisibleCard) {
    return (
      <CodeCardWrapper>
        <CodeCardViewInner
          hideBackButton
          hideActionButton
          cardId={cadetVisibleCard.id}
          baseUrl=""
          state={gadgetPackState}
          dispatch={gadgetPackDispatch}
        />
      </CodeCardWrapper>
    )
  }
  return (
    <PanelWrapper slide={slide}>
      <P>Please wait for your card to be created</P>
    </PanelWrapper>
  )
}
