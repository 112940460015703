import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { baseUrl } from './common/Navigation'

import { Row, Column, Button, Spacer, Padding } from 'common/ui'
import { fontRegular } from 'fonts'
import { useUserState } from 'app/UserState'
import { useSkillTrackerState } from './SkillTrackerState'
import { FlexModal, Props as ModalProps } from '../app/FlexModal'
import { UpdateRoleStateValuesAction, UPDATE_ROLE_STATE_VALUES } from './actionTypes'
import { isRunningStandalone } from 'utils/deviceUtils'

export const MenuModal: React.FC<Omit<ModalProps, 'children'> & { onClose: () => void }> = ({ isOpen, onClose }) => {
  const history = useHistory()
  const location = useLocation()
  const { getUserScopes, getBaseAction } = useUserState()
  const { state, dispatch } = useSkillTrackerState()

  const isAdult = getUserScopes().indexOf('mentor') >= 0
  const standaloneMode = isRunningStandalone()

  const handleRoute = (relativeUrl: string, closeMenu: boolean = true) => {
    history.push(baseUrl + relativeUrl)
    if (closeMenu) onClose()
  }
  const isHere = (relativeUrl: string): boolean =>
    !!matchPath(location.pathname, { path: baseUrl + relativeUrl, exact: true })

  const goToInstructions = () => {
    const action: UpdateRoleStateValuesAction = {
      ...getBaseAction(),
      type: UPDATE_ROLE_STATE_VALUES,
      role: isAdult ? 'mentor' : 'agent',
      data: { screen: 'intro', skipIntro: false },
    }
    dispatch(action)
    onClose()
    setTimeout(() => history.push(baseUrl))
  }

  const goToSettings = () => history.push(baseUrl + '/settings')

  return (
    <FlexModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{ content: { background: '#333', overflow: 'visible' } }}>
      <Padding>
        <Row flex="0 0 0px" justifyContent="flex-end">
          <MenuButton style={{ color: 'white' }} className="open" onClick={() => onClose()}>
            Menu
          </MenuButton>
        </Row>
        <Row flex justifyContent="center" alignItems="center">
          <FadeIn>
            <Row alignItems="stretch" justifyContent="center" flexWrap style={{ maxWidth: '100%' }}>
              <Column justifyContent="center">
                <Button
                  type="button"
                  theme="blue"
                  size="m"
                  onClick={() => handleRoute('/practise')}
                  selected={isHere('/practise')}
                  disabled={isAdult && !state.activeModuleId}>
                  Practise Log
                </Button>
                <Spacer size="s" />
                <Button
                  type="button"
                  theme="blue"
                  size="m"
                  onClick={() => handleRoute('/rewards')}
                  selected={isHere('/rewards')}>
                  Rewards Menu
                </Button>
              </Column>
              <VerticalDivider />
              <Column alignItems="center">
                {isAdult && (
                  <>
                    <Button
                      size="xs"
                      theme="white"
                      style={{ minWidth: 180 }}
                      onClick={() => handleRoute('/module')}
                      selected={isHere('/module')}>
                      Edit Skill Tracker
                    </Button>
                    <Spacer size="xs" />
                  </>
                )}
                <Button
                  type="button"
                  style={{ minWidth: 180 }}
                  theme="white"
                  size="xs"
                  onClick={() => handleRoute('/progress')}
                  selected={isHere('/progress')}>
                  {isAdult ? 'View Progress' : 'My Progress'}
                </Button>
                <Spacer size="xs" />
                {!isAdult && (
                  <>
                    <Button
                      type="button"
                      style={{ minWidth: 180 }}
                      size="xs"
                      theme="white"
                      onClick={() => handleRoute('/skills-rewards')}
                      selected={isHere('/skills-rewards')}>
                      Target Skills
                    </Button>
                    <Spacer size="xs" />
                  </>
                )}
                <Button type="button" style={{ minWidth: 180 }} size="xs" theme="white" onClick={goToInstructions}>
                  Instructions
                </Button>
                <Spacer size="xs" />
                {(process.env.REACT_APP_SKILL_TRACKER_WEB_PUSH === '1' || !standaloneMode) && (
                  <>
                    <Button type="button" style={{ minWidth: 180 }} size="xs" theme="white" onClick={goToSettings}>
                      {process.env.REACT_APP_SKILL_TRACKER_WEB_PUSH === '1' ? 'Settings' : 'Add to Homescreen'}
                    </Button>
                    <Spacer size="xs" />
                  </>
                )}
                <Button
                  type="button"
                  style={{ minWidth: 180 }}
                  size="xs"
                  theme="white"
                  onClick={() => history.push(isAdult ? '/' : '/game')}>
                  {isAdult ? 'Quit' : 'Return to Control Room'}
                </Button>
              </Column>
            </Row>
          </FadeIn>
        </Row>
      </Padding>
    </FlexModal>
  )
}

const hamburgerBase64 = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 26'%3E%3Crect fill='%2322acf3' width='30' height='4'/%3E%3Crect fill='%2322acf3' y='11' width='30' height='4'/%3E%3Crect fill='%2322acf3' y='22' width='30' height='4'/%3E%3C/svg%3E`

export const MenuButton = styled.div`
  position: relative;
  height: 30px;
  padding: 0 45px 0 12px;
  ${fontRegular}
  letter-spacing: 0.05em;
  font-size: 16px;
  line-height: 30px;
  text-transform: initial;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    right: 12px;
    top: 6px;
    color: #22acf3;
    width: 22px;
    height: 18px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("${hamburgerBase64}");
  }
  &.open::after {
    content: '✕';
    font-size: 18px;
    line-height: 18px;
    height: auto;
    background: none;
  }

  @media (orientation: portrait) and (max-width: 420px) {
    font-size: 14px;
    padding: 0 35px 0 8px;
    &::after {
      right: 5px;
    }
  }
`

const VerticalDivider = styled(Column)`
  margin-left: 20px;
  margin-right: 20px;
  border-left: 1px solid white;
  @media (max-width: 420px) {
    width: 56vw;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    border-left: none;
    border-top: 1px solid white;
  }
`

const fadeIn = keyframes`
  from { 
    opacity: 0; 
    transform: translateY(17.5vh);
  }
  to { 
    opacity: 1; 
    transform: translateY(0);
  }
`
const FadeIn = styled(Column)`
  flex: auto;
  animation: ${fadeIn} 380ms cubic-bezier(0.3, 0.6, 0.2, 1) 1 normal forwards;
`
