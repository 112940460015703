import React from 'react'
import { SectionPropsBase } from 'shared/session/types'
import { SectionPropsBase as FacManSectionPropsBase } from 'shared/fac-man/types'
import { Hr } from 'common/ui'

interface Props extends SectionPropsBase {
  property: 'divider_sections'
}

export const DividerSection: React.FC<Props> = () => <Hr margin="m" />

export const DividerSectionFacilitator = DividerSection

interface GenericProps extends FacManSectionPropsBase {
  property: 'divider_sections'
}

export const GenericDividerSection: React.FC<GenericProps> = () => <Hr margin="m" />

export const BasicDividerSection: React.FC = () => <Hr margin="m" />
