import React, { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { fontBold } from 'fonts'

import { FriendometerState, FriendshipStrength, FriendometerValue, friendshipStrengths } from 'shared/gadget-pack/types'
import { Row, Panel, RadioactiveButton, Column, Label, Spacer } from 'common/ui'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { HeaderTemplate } from './common/HeaderTemplate'

import { UPDATE_FRIENDOMETER } from 'shared/gadget-pack/actionTypes'
import { useGadgetPackState } from './GadgetPackState'
import { useUserState } from 'app/UserState'
import { useMedia } from 'utils/useMedia'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { baseUrl as gadgetPackBaseUrl } from './GadgetPack'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'
import { InputLockComponent, InputLockProps } from 'session/common/InputLockComponent'

type FriendshipColumn = {
  type: FriendshipStrength
  description: string[]
  say: string[]
  do: string[]
}

type FriendshipType = {
  value: FriendometerValue
  label: string
}

const inputRows: FriendshipType[] = [
  { value: 'people', label: 'People' },
  { value: 'description', label: 'Description' },
  { value: 'say', label: 'What to say' },
  { value: 'do', label: 'What to do' },
]
const friendMatrix: FriendshipColumn[] = [
  {
    type: friendshipStrengths[0],
    description: [`People your don’t know`],
    say: ['N/A'],
    do: ['N/A'],
  },
  {
    type: friendshipStrengths[1],
    description: [`Never spoken to them`, `Seen them before`, `Might know their name`],
    say: ['N/A'],
    do: ['N/A'],
  },
  {
    type: friendshipStrengths[2],
    description: [`Seen them 2+ times`, `Spoken to them a bit (May just be online)`, `Know their name`],
    say: [`Might say ‘Hi’, ‘Bye’`, `Talk a little about an activity you are doing/did together`],
    do: [`Smile`, `Talk online`],
  },
  {
    type: friendshipStrengths[3],
    description: [
      `Spend time together (Not all online)`,
      `Might catch up outside of school or hang out at each other’s houses`,
    ],
    say: [
      `Might say ‘Hi’, ‘Bye’`,
      `Talk a little about an activity you are doing/did together`,
      `Might talk about fun things you are doing/did outside of school`,
    ],
    do: [
      `Smile`,
      `Sit/hang out together sometimes`,
      `Sometimes do things together outside school (e.g. hang out at each other’s houses)`,
    ],
  },
  {
    type: friendshipStrengths[4],
    description: [
      `Spend a lot of time together`,
      `Share personal info with each other`,
      `Know each other’s moods`,
      `Hang out outside of school`,
    ],
    say: [
      `Might say ‘Hi’, ‘Bye’`,
      `Talk a little about an activity you are doing/did together`,
      `Might talk about fun things you are doing/did outside of school`,
      `Talk about problems, secrets and other personal Info`,
    ],
    do: [
      `Smile`,
      `Sit/hang out together often (3+ times a week at school)`,
      `Do things together outside school (e.g. hang out at each other’s houses)`,
    ],
  },
]
const green = '#72C116'

export const friendometerIsEmpty = (state: FriendometerState): boolean => {
  for (let obj of Object.values(state)) {
    for (let val of Object.values(obj)) {
      if (!!val) return false
    }
  }
  return true
}

const emptyStackExpandedValue = friendshipStrengths.reduce(
  (obj, val) => ({ ...obj, [val]: false }),
  {} as { [key in FriendshipStrength]: boolean }
)

export const FriendometerScreen: React.FC<{
  baseUrl?: string
  inCardHolder?: boolean
  hideExit?: boolean
  readonly?: boolean
  overrideExit?: () => void
  hideTitle?: boolean
  inputLockProps?: Omit<InputLockProps, 'children'>
}> = ({ baseUrl, inCardHolder, hideExit, overrideExit, readonly, hideTitle, inputLockProps }) => {
  const history = useHistory()
  const location = useLocation()
  const stack = useMedia('(max-width: 640px)')
  const { getBaseAction } = useUserState()
  const {
    state: { friendometer },
    dispatch,
  } = useGadgetPackState()
  const [expanded, setExpanded] = useState<{ [key in 'description' | 'say' | 'do']: boolean }>({
    description: false,
    say: false,
    do: false,
  })
  const [stackExpanded, setStackExpanded] = useState<
    { [key in 'description' | 'say' | 'do']: { [key in FriendshipStrength]: boolean } }
  >({
    description: { ...emptyStackExpandedValue },
    say: { ...emptyStackExpandedValue },
    do: { ...emptyStackExpandedValue },
  })

  return (
    <>
      <HeaderTemplate
        hideExitButton={hideExit}
        title={hideTitle ? '' : 'Friendometer'}
        onExit={() =>
          overrideExit
            ? overrideExit()
            : baseUrl !== undefined
            ? history.push((location.state || {}).skipCardHolder ? gadgetPackBaseUrl : baseUrl, location.state)
            : undefined
        }
        exitLabel={inCardHolder ? 'Back' : 'Exit'}>
        <Panel style={{ overflow: 'hidden' }}>
          <Row style={{ flexDirection: stack ? 'column' : 'row' }}>
            <Column flex="none" style={{ [stack ? 'height' : 'width']: 40, backgroundColor: green }} />
            {stack ? (
              <>
                {friendMatrix.map((friendColumn, i) => (
                  <Table key={i} cellPadding={7} cellSpacing={0} style={{ borderBottom: '3px solid #adb4d8' }}>
                    <tbody>
                      <tr>
                        <td>
                          <RowFlipPortrait justifyContent="space-around">
                            {intersperseSpacers(
                              friendshipStrengths.map((friendshipStrength, n) => (
                                <RadioactiveButton
                                  key={n}
                                  size="s"
                                  children={
                                    <div>
                                      <span>{friendshipStrength}</span>
                                    </div>
                                  }
                                  rotated
                                  selected={n === i}
                                  lighten={(friendshipStrengths.length - 1 - n) * 0.075}
                                  style={{
                                    pointerEvents: 'none',
                                    whiteSpace: 'pre-wrap',
                                    fontSize: 16,
                                    padding: '12px 20px',
                                  }}
                                  flex={`0 1 ${100 / friendshipStrengths.length}%`}
                                />
                              )),
                              'm'
                            )}
                          </RowFlipPortrait>
                        </td>
                      </tr>
                      {inputRows.map((friendType, r) => {
                        const expanded =
                          friendType.value === 'people' ? true : stackExpanded[friendType.value][friendColumn.type]
                        return (
                          <tr key={r}>
                            <td valign="top">
                              <InputCell
                                friendColumn={friendColumn}
                                friendType={friendType}
                                expanded={expanded}
                                onToggle={() =>
                                  setStackExpanded(
                                    friendType.value === 'people'
                                      ? stackExpanded
                                      : {
                                          description: { ...emptyStackExpandedValue },
                                          say: { ...emptyStackExpandedValue },
                                          do: { ...emptyStackExpandedValue },
                                          [friendType.value]: {
                                            ...emptyStackExpandedValue,
                                            [friendColumn.type]: !expanded,
                                          },
                                        }
                                    /*{
                                          ...stackExpanded,
                                          [friendType.value]: {
                                            [friendColumn.type]: !expanded,
                                          },
                                        }*/
                                  )
                                }
                                value={friendometer[friendColumn.type][friendType.value] || ''}
                                onChange={value =>
                                  dispatch({
                                    ...getBaseAction(),
                                    type: UPDATE_FRIENDOMETER,
                                    friendship: friendColumn.type,
                                    key: friendType.value,
                                    value,
                                  })
                                }
                                readonly={readonly || false}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                ))}
              </>
            ) : (
              <Table cellPadding={7} cellSpacing={0} style={{ width: 'calc(100% - 40px)' /*thanks edge*/ }}>
                <Tbody>
                  <TR>
                    {friendMatrix.map((friendColumn, i) => (
                      <td key={i}>
                        <RadioactiveButton
                          size="s"
                          children={friendColumn.type}
                          lighten={(friendMatrix.length - 1 - i) * 0.075}
                          style={{
                            pointerEvents: 'none',
                            whiteSpace: 'pre-wrap',
                            fontSize: 16,
                            padding: '12px 20px',
                            minHeight: 58,
                            width: '100%',
                            margin: '10px 0',
                          }}
                        />
                      </td>
                    ))}
                  </TR>
                  {inputRows.map((friendType, r) => (
                    <TR key={r}>
                      {friendType.value !== 'people' && !expanded[friendType.value] ? (
                        <td
                          colSpan={friendMatrix.length}
                          onClick={
                            !expanded[friendType.value]
                              ? () => setExpanded({ ...expanded, [friendType.value]: true })
                              : undefined
                          }
                          style={
                            !expanded[friendType.value] ? { cursor: 'pointer', width: '100%' } : { cursor: 'auto' }
                          }>
                          <Row>
                            <Label style={{ pointerEvents: 'none' }}>{friendType.label}:</Label>
                            <Spacer flex="1 1 auto" />
                            <Toggler
                              expanded={false}
                              onClick={() => setExpanded({ ...expanded, [friendType.value]: true })}
                            />
                          </Row>
                        </td>
                      ) : (
                        friendMatrix.map((friendColumn, i) => (
                          <td key={i} valign="top">
                            <InputCell
                              friendColumn={friendColumn}
                              friendType={friendType}
                              hideLabel={i > 0}
                              hideToggle={i < friendMatrix.length - 1}
                              expanded={friendType.value === 'people' ? true : expanded[friendType.value]}
                              onToggle={() => setExpanded({ ...expanded, [friendType.value]: false })}
                              value={friendometer[friendColumn.type][friendType.value] || ''}
                              onChange={value =>
                                dispatch({
                                  ...getBaseAction(),
                                  type: UPDATE_FRIENDOMETER,
                                  friendship: friendColumn.type,
                                  key: friendType.value,
                                  value,
                                })
                              }
                              readonly={readonly || false}
                              inputLockProps={
                                inputLockProps
                                  ? { ...inputLockProps, fieldUid: `${inputLockProps.fieldUid}_${r}_${i}` }
                                  : {}
                              }
                            />
                          </td>
                        ))
                      )}
                    </TR>
                  ))}
                </Tbody>
              </Table>
            )}
          </Row>
        </Panel>
      </HeaderTemplate>
    </>
  )
}

const InputCell: React.FC<{
  hideLabel?: boolean
  hideToggle?: boolean
  expanded: boolean
  friendType: FriendshipType
  friendColumn: FriendshipColumn
  onToggle?: () => void
  onChange: (value: string) => void
  value: string
  readonly: boolean
  inputLockProps?: Omit<InputLockProps, 'children'>
}> = ({
  friendType,
  friendColumn,
  onToggle,
  value,
  onChange,
  hideLabel = false,
  hideToggle = false,
  expanded,
  readonly,
  inputLockProps = {},
}) => (
  <Column>
    {hideLabel && hideToggle ? (
      <Spacer style={{ height: 16 }} />
    ) : (
      <Row style={{ marginBottom: 0 }}>
        {!hideLabel && <Label>{friendType.label}:</Label>}
        <Spacer flex="1 1 auto" />
        {!hideToggle && onToggle && friendType.value !== 'people' && (
          <Toggler expanded={expanded} onClick={() => onToggle()} style={{ margin: '3px 0 8px 0' }} />
        )}
      </Row>
    )}
    {expanded && (
      <>
        {friendType.value !== 'people' && (
          <ul>
            {friendColumn[friendType.value].map((text, i) => (
              <li key={i} children={text} />
            ))}
          </ul>
        )}
        <div style={{ flex: '1 1 auto' }} />
        <div style={{ position: 'relative' }}>
          <DebouncedInputComponent<string>
            debounce={750}
            value={value || ''}
            onChange={text => onChange(text)}
            children={({ value: textValue, onChange: debouncedTextOnChange }) => (
              <InputLockComponent {...inputLockProps}>
                {(lockProps, lockState) => (
                  <AutosizeTextareaInput
                    {...lockProps}
                    {...lockState}
                    value={textValue}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      debouncedTextOnChange(e.target.value || '')
                      lockProps.onChange(e.target.value || '')
                    }}
                    disabled={readonly || lockState.disabled}
                  />
                )}
              </InputLockComponent>
            )}
          />
        </div>
      </>
    )}
  </Column>
)

const Toggler = styled.div<{ expanded: boolean }>`
  ${fontBold}
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${green};
  line-height: 1;
  user-select: none;
  -webkit-user-select: none;
  &::before {
    content: '${p => (p.expanded ? 'Hide' : 'Show')}';
    letter-spacing: 0.05em;
  }
  &::after {
    content: '${p => (p.expanded ? '▲' : '▼')}';
    font-size: 16px;
    padding-left: 7px;
    margin-bottom: ${p => (p.expanded ? 0 : '-3px')};
  }
`

const Table = styled.table`
  & tr:not(:last-child) > td {
    border-bottom: 1px solid ${p => p.theme.thinOutlineColor};
  }
  & td {
    width: 20%;

    &:not(:last-child) {
      border-right: 1px solid ${p => p.theme.thinOutlineColor};
    }
    & > ${Column} {
      height: 100%;
      & > *:not(:last-child) {
        margin: 0 0 0.75em 0;
      }
    }
    & ul {
      flex: 1 0 auto;
      font-size: 14px;
      font-weight: 200;
      margin: 0;
      padding-inline-start: 18px;
    }
    & label {
      ${fontBold};
      line-height: 1;
      font-size: 16px;
      color: #19345d;
    }
  }
`

const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
`

const TR = styled.tr`
  display: flex;
  flex-grow: 1;
`

const RowFlipPortrait = styled(Row)<{ mediaQuery?: string }>`
  @media ${p => p.mediaQuery || '(orientation: portrait)'} {
    & > ${Spacer} {
      width: 10px;
    }
    & > *:not(${Spacer}) {
      & > div {
        position: relative;
        overflow: visible;
        width: auto;
        height: 90px;
        padding: 0 5px;
        & > span {
          position: absolute;
          display: block;
          width: 90px;
          height: auto;
          top: 50%;
          left: 35%; /* To make up for line height */
          transform-origin: center center;
          transform: translateX(-50%) translateY(-50%) rotateZ(-90deg);
          text-align: center;
          line-height: 0.85;
        }
      }
    }
  }
`
