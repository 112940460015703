import React from 'react'
import { Activity, ActivityProps } from 'types'

interface ActivityState {
  activity?: Activity
  onComplete: ActivityProps['onComplete']
}

export const ActivityContext = React.createContext<ActivityState>({
  onComplete: () => {},
})
