import React, { useEffect, useRef, forwardRef } from 'react'
import { TextareaInput } from 'common/ui'

export const AutosizeTextareaInput: React.FC<React.ComponentProps<typeof TextareaInput>> = forwardRef((props, ref) => {
  const localRef = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    const elem = ((ref as React.RefObject<HTMLTextAreaElement>) || localRef).current
    if (elem) {
      elem.style.height = 'auto'
      elem.style.height = elem.scrollHeight + 4 + 'px'
    }
  })
  return <TextareaInput {...props} ref={ref || localRef} />
})
