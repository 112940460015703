import requireImageSize from '../../../../macros/requireImageSize.macro'
import { images } from './assets'
import { ScavengerHuntSceneObjectKey as SceneObjectKey } from 'shared/session/types'

export const sceneObjectKeys: SceneObjectKey[] = ['bed', 'medal', 'bear', 'rug', 'door', 'beanBag', 'shelves', 'letterA', 'letterB', 'letterC', 'books1', 'books2', 'books3', 'books4', 'books5', 'toyCar', 'desk', 'pencils', 'lamp', 'computer', 'chair', 'clock'] // prettier-ignore

export interface SceneObject {
  image: any
  imageSize: any
  hitbox: SceneObjectKey
  position: { left: number; top: number }
}

export const sceneObjects: { [key in SceneObjectKey]: SceneObject } = {
  bed: {
    image: images.bed,
    hitbox: 'bed',
    imageSize: requireImageSize('./assets/bed.png'),
    position: { left: 2949, top: 732 },
  },
  medal: {
    image: images.medal,
    hitbox: 'medal',
    imageSize: requireImageSize('./assets/medal.png'),
    position: { left: 4637, top: 1285 },
  },
  bear: {
    image: images.bear,
    hitbox: 'bear',
    imageSize: requireImageSize('./assets/bear.png'),
    position: { left: 3135, top: 2112 },
  },

  rug: {
    image: images.rug,
    hitbox: 'rug',
    imageSize: requireImageSize('./assets/rug.png'),
    position: { left: 2530, top: 2448 },
  },
  door: {
    image: images.door,
    hitbox: 'door',
    imageSize: requireImageSize('./assets/door.png'),
    position: { left: 5563, top: 0 },
  },
  beanBag: {
    image: images.beanBag,
    hitbox: 'beanBag',
    imageSize: requireImageSize('./assets/beanBag.png'),
    position: { left: 4359, top: 1854 },
  },

  shelves: {
    image: images.shelves,
    hitbox: 'shelves',
    imageSize: requireImageSize('./assets/shelves.png'),
    position: { left: 1665, top: 310 },
  },
  letterA: {
    image: images.letterA,
    hitbox: 'letterA',
    imageSize: requireImageSize('./assets/letterA.png'),
    position: { left: 1763, top: 738 },
  },
  letterB: {
    image: images.letterB,
    hitbox: 'letterB',
    imageSize: requireImageSize('./assets/letterB.png'),
    position: { left: 2061, top: 1040 },
  },
  letterC: {
    image: images.letterC,
    hitbox: 'letterC',
    imageSize: requireImageSize('./assets/letterC.png'),
    position: { left: 1759, top: 1345 },
  },
  books1: {
    image: images.books1,
    hitbox: 'books1',
    imageSize: requireImageSize('./assets/books1.png'),
    position: { left: 1978, top: 425 },
  },
  books2: {
    image: images.books2,
    hitbox: 'books2',
    imageSize: requireImageSize('./assets/books2.png'),
    position: { left: 2296, top: 426 },
  },
  books3: {
    image: images.books3,
    hitbox: 'books3',
    imageSize: requireImageSize('./assets/books3.png'),
    position: { left: 1988, top: 707 },
  },
  books4: {
    image: images.books4,
    hitbox: 'books4',
    imageSize: requireImageSize('./assets/books4.png'),
    position: { left: 2286, top: 1324 },
  },
  books5: {
    image: images.books5,
    hitbox: 'books5',
    imageSize: requireImageSize('./assets/books5.png'),
    position: { left: 2609, top: 1658 },
  },
  toyCar: {
    image: images.toyCar,
    hitbox: 'toyCar',
    imageSize: requireImageSize('./assets/toyCar.png'),
    position: { left: 2699, top: 2053 },
  },

  desk: {
    image: images.desk,
    hitbox: 'desk',
    imageSize: requireImageSize('./assets/desk.png'),
    position: { left: 303, top: 1678 },
  },
  pencils: {
    image: images.pencils,
    hitbox: 'pencils',
    imageSize: requireImageSize('./assets/pencils.png'),
    position: { left: 1105, top: 1485 },
  },
  lamp: {
    image: images.lamp,
    hitbox: 'lamp',
    imageSize: requireImageSize('./assets/lamp.png'),
    position: { left: 1261, top: 1014 },
  },
  computer: {
    image: images.computer,
    hitbox: 'computer',
    imageSize: requireImageSize('./assets/computer.png'),
    position: { left: 687, top: 992 },
  },
  chair: {
    image: images.chair,
    hitbox: 'chair',
    imageSize: requireImageSize('./assets/chair.png'),
    position: { left: 1804, top: 1662 },
  },

  clock: {
    image: images.clock,
    hitbox: 'clock',
    imageSize: requireImageSize('./assets/clock.png'),
    position: { left: 3045, top: 362 },
  },
}
