export const GAME_STARTED = 'GAME_STARTED'
export const GAME_PAUSED = 'GAME_PAUSED'
export const GAME_RESUMED = 'GAME_RESUMED'
export const GAME_ENDED = 'GAME_ENDED'
export const RESTART_GAME = 'RESTART_GAME'
export const SCENE_INITED = 'SCENE_INITED'
export const SCENE_FADED_IN = 'SCENE_FADED_IN'
export const SCENE_FADED_OUT = 'SCENE_FADED_OUT'
export const SOUND_LOADED = 'SOUND_LOADED'
export const SOUND_LOAD_FAILED = 'SOUND_LOAD_FAILED'
export const SFX_MUTED = 'SFX_MUTED'
export const SFX_UNMUTED = 'SFX_UNMUTED'
