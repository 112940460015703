import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  H1,
  Panel,
  Hr,
  Button,
  Row,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  GRILL_TL,
  GRILL_TR,
  Center,
  Spacer,
  Column,
  buttonFlash,
} from 'common/ui'

import { GADGETS, Gadget } from 'gadgets'
import { intersperse } from 'utils/intersperse'
import { fontBold } from 'fonts'
import { useUserState } from './UserState'
import { gameModes } from 'activities'
import { GadgetIndex, Activity, Attempt } from 'types'
import { useGlobalState } from './GlobalState'
import { removeDuplicates } from 'utils/removeDuplicates'
import { preloadImage } from 'utils/preloadUtils'
import { GameModeSlug } from 'shared/types'

type Size = 's' | 'm'

interface Props {
  buttonLabel?: string
  onContinue?: () => void
  size?: Size
  activateGadget?: GadgetIndex
}

export const getGadgetsForGameMode = (gameMode: GameModeSlug): Gadget[] => {
  const activities = gameModes[gameMode].activities
  const gadgets = activities
    .reduce((acc: Gadget[], a) => {
      const requiredFor = GADGETS.filter(g => g.requiredFor.id === a.id)
      const receivedAfter = GADGETS.filter(g => g.receivedAfter.id === a.id)
      return acc.concat(requiredFor, receivedAfter)
    }, [])
    .filter(removeDuplicates)
  return gadgets
}

export const getGadgetImagesForGameMode = (
  gameMode: GameModeSlug,
  hasCompleted: (activity: Activity, sub_activity?: Attempt['sub_activity']) => boolean
): string[] =>
  getGadgetsForGameMode(gameMode).map(gadget => gadget[hasCompleted(gadget.receivedAfter) ? 'src' : 'outlineSrc'])

export function usePreloadGadgetImages() {
  const { gameMode, hasCompleted } = useUserState()
  const [promise, setPromise] = useState<Promise<void | unknown[]> | null>(null)
  useEffect(() => {
    const images = getGadgetImagesForGameMode(gameMode, hasCompleted)
    const promise = Promise.all(images.map(image => preloadImage(image))).catch(() => {})
    setPromise(promise)
  }, [gameMode, hasCompleted])
  return promise
}

export const Inventory: React.FC<Props> = ({
  buttonLabel,
  onContinue,
  size = 'm',
  activateGadget = GadgetIndex.None,
}) => {
  const { gameMode, hasCompleted } = useUserState()
  const { activeGadgetIndex, setActiveGadgetIndex } = useGlobalState()

  const gadgets = getGadgetsForGameMode(gameMode)
  const activeGadget = GADGETS[activateGadget]

  // reset active gadget on unmount if required
  useEffect(() => {
    return () => {
      if (activateGadget !== GadgetIndex.None) setActiveGadgetIndex(GadgetIndex.None)
    }
  }, [activateGadget, setActiveGadgetIndex])

  // preload pda images
  useEffect(() => {
    const pdaImages = gadgets.filter(gadget => hasCompleted(gadget.receivedAfter)).map(gadget => gadget.pdaSrc)
    Promise.all(pdaImages.map(image => preloadImage(image))).catch(() => {})
  }, [gadgets, hasCompleted])

  return (
    <Panel
      style={{ width: size === 's' ? 480 : 720 }}
      padding="25px 30px 35px 30px"
      flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR]}
      shadow={false}>
      <H1 marginBottom align="center">
        Inventory
      </H1>
      <Row>
        {gadgets
          .slice(0, 5)
          .map((gadget, i) => (
            <GadgetCard
              key={i}
              gadget={gadget}
              outline={!hasCompleted(gadget.receivedAfter) && gadget !== activeGadget}
              selected={GADGETS.indexOf(gadget) === activeGadgetIndex}
              glow={gadget === activeGadget}
              onSelect={() => setActiveGadgetIndex(GADGETS.indexOf(gadget))}
              size={size}
            />
          ))
          .reduce(
            intersperse(i => <Spacer key={`s${i}`} size="s" />),
            []
          )}
      </Row>
      <Spacer />
      <Row>
        {gadgets
          .slice(5)
          .map((gadget, i) => (
            <GadgetCard
              key={i}
              gadget={gadget}
              outline={!hasCompleted(gadget.receivedAfter) && gadget !== activeGadget}
              glow={gadget === activeGadget}
              selected={GADGETS.indexOf(gadget) === activeGadgetIndex}
              onSelect={() => setActiveGadgetIndex(GADGETS.indexOf(gadget))}
              size={size}
            />
          ))
          .concat(
            gadgets.length % 5 > 0 ? gen(5 - (gadgets.length % 5), i => <Column key={`c${i}`} flex="1 1 0px" />) : []
          )
          .reduce(
            intersperse(i => <Spacer key={`s${i}`} size="s" />),
            []
          )}
      </Row>
      <Hr margin />
      <Center>
        <Button
          onClick={onContinue}
          disabled={activateGadget !== GadgetIndex.None && activateGadget !== activeGadgetIndex}>
          {buttonLabel}
        </Button>
      </Center>
    </Panel>
  )
}

function gen<T>(length: number, callback: (i: number) => T): T[] {
  const items: number[] = []
  for (let i = 0; i < length; i++) {
    items.push(i)
  }
  return items.map(callback)
}

const GadgetCard: React.FC<{
  gadget: Gadget
  outline: boolean
  selected: boolean
  glow: boolean
  onSelect: () => void
  size: Size
}> = ({ gadget, outline, glow, size, selected, onSelect }) => {
  return (
    <Column flex="1 1 0px">
      <GadgetImage outline={outline} selected={selected} glow={glow} onClick={!outline ? onSelect : undefined}>
        <img src={outline ? gadget.outlineSrc : gadget.src} alt={outline ? 'Mystery Gadget' : gadget.title} />
      </GadgetImage>
      <Spacer size="xs" />
      {outline ? <GadgetPlaceholderTitle /> : <GadgetTitle size={size}>{gadget.title}</GadgetTitle>}
    </Column>
  )
}

const GadgetTitle = styled.div<{ size: Size }>`
  ${fontBold}
  text-align: center;
  font-size: ${p => (p.size === 's' ? '11px' : '14px')};
  color: #1a345d;
  margin: 5px;
  line-height: 1.2;
`

const GadgetPlaceholderTitle = styled.div`
  ::before,
  ::after {
    content: '';
    display: block;
    background: #d5daed;
    height: 10px;
    margin: 5px 10px;
  }
`

const GadgetImage = styled.div<{ outline: boolean; selected: boolean; glow: boolean }>`
  flex: 0 0 auto;
  background: ${p => (p.outline ? '#d5daed' : 'white')};
  border: 1px solid #abb4db;
  border-radius: 10px;
  box-shadow: ${p => (p.selected ? `0px 0px 6px 1px ${p.theme.buttonBorderTopColor},` : '')} inset 0px 2px 0px 0px #fff,
    0px 2px 0px 0px #fff;
  cursor: ${p => (p.outline ? 'normal' : 'pointer')};

  img {
    width: 100%;
    height: auto;
  }

  ::before {
    content: ${p => (p.glow ? '""' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px ${p => p.theme.highlightColor};
    animation: ${buttonFlash} 0.5s linear infinite alternate;
  }
`
