import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { parse as parseQueryString } from 'qs'

import { Row, P, Button, Spacer, CollapseMargin, Form, Hr } from 'common/ui'

import { fontHeavy, fontRegular } from 'fonts'
import { useMedia } from 'utils/useMedia'
import { useUserState } from 'app/UserState'
import { IntroTemplate } from './IntroTemplate'
import { login, getProfiles, GetProfile } from 'api'
import { getInputProps } from 'utils/virtualKeyboard'
import { LinkButton, AnchorButton } from 'common/LinkButton'
import { useHistory, useLocation } from 'react-router-dom'
import { gtagLogin } from 'app/GoogleAnalytics'
import { decodeJWT } from 'utils/decodeJWT'
import { isOnline } from 'utils/deviceUtils'
import { SHA1 } from 'utils/cryptoUtils'

function errorMessageForCode(errorCode: string) {
  switch (errorCode) {
    case 'NO-PROFILE':
      return `Your Game Play Code is incorrect.`
    case 'LICENCE-EXPIRED':
      return 'Your Game Play Code has expired.'
    case 'PRODUCT-KEY':
      return 'You have entered a Product Key. Please enter your 5-character Game Play Code. If you have not yet received a Game Play Code, please activate your Product Key below.'
    default:
      return 'An unexpected error occurred. Please try again.'
  }
}

export const MentorLoginPage: React.FC<{ redirectUri: string }> = ({ redirectUri }) => {
  const {
    setAccessToken,
    setRefreshToken,
    setProfileId,
    setGameMode,
    setOfflineMode,
    setProfiles,
    setOfflineMentor,
  } = useUserState()
  const compact = useMedia('(max-width: 500px)')
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [busy, setBusy] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const hashedGameCode = SHA1(code)
  const queryParams: { redirect_uri?: string } = parseQueryString(location.search, { ignoreQueryPrefix: true })
  const realRedirectUri = queryParams.redirect_uri || redirectUri

  const onOfflineLogin = (offlineProfiles: GetProfile[]) => {
    const profile = offlineProfiles.find(
      p => (p.profile_name || '').toLowerCase() !== 'open_access' || offlineProfiles.length === 1
    )
    if (!profile) throw new Error('No profile found')

    ReactDOM.unstable_batchedUpdates(() => {
      setOfflineMode(true)
      setOfflineMentor(true)
      setProfiles(offlineProfiles)
      setProfileId(profile.id)
      if (profile.intervention_type) setGameMode(profile.intervention_type)
    })

    history.push(realRedirectUri)
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    setBusy(true)

    login(code, 'mentor')
      .then(data => {
        return getProfiles(data.access_token).then(profiles => {
          // prefer non-open_access, but if they only have open_access profile, then use that
          const profile = profiles.find(
            p => (p.profile_name || '').toLowerCase() !== 'open_access' || profiles.length === 1
          )
          if (!profile) throw new Error('No profile found')

          if (profile.intervention_type !== 'SAS-SG' && profile.intervention_type !== 'SAS-SG-D') {
            throw new Error('Skill Tracker is only available for small groups.')
          }

          const payload = decodeJWT(data.access_token)
          gtagLogin(payload.scopes.join(' '), profile.allowed_from, profile.intervention_type)

          ReactDOM.unstable_batchedUpdates(() => {
            setOfflineMode(false)
            setOfflineMentor(false)
            setProfiles(profiles)
            setAccessToken(data.access_token)
            setRefreshToken(data.refresh_token)
            setProfileId(profile.id)
            setGameMode(profile.intervention_type)
          })

          if (payload.scopes.indexOf('mentor') !== -1) {
            history.push(realRedirectUri)
          } else {
            history.push('/')
          }
        })
      })
      .catch(err => {
        if (!isOnline()) {
          const hashedCodeProfilesJSON = localStorage.getItem(hashedGameCode)
          if (hashedCodeProfilesJSON) {
            try {
              const hashedCodeProfiles: GetProfile[] = JSON.parse(hashedCodeProfilesJSON)
              onOfflineLogin(hashedCodeProfiles)
            } catch (e) {
              ReactDOM.unstable_batchedUpdates(() => {
                setError('An error occurred while trying to enter offline mode')
                setBusy(false)
              })
              return
            }
            return
          }
        }
        ReactDOM.unstable_batchedUpdates(() => {
          setError(err.message || errorMessageForCode(err.error))
          setBusy(false)
        })
      })
  }

  return (
    <IntroTemplate title={`Mentor Login`} plainLogo>
      <CollapseMargin>
        <P align="center">Enter your Game Play Code</P>
      </CollapseMargin>
      <Spacer />
      <Form onSubmit={onSubmit}>
        <TextInput
          name="gamePlayCode"
          error={!!error}
          readOnly={busy}
          autoFocus
          value={code}
          onChange={e => {
            setCode(e.target.value.replace(/[^A-Z0-9]/gi, ''))
            setError('')
          }}
          {...getInputProps()}
        />
        {error && (
          <>
            <Spacer />
            <FormError>{error}</FormError>
          </>
        )}
        <Spacer />
        <Button type="submit" disabled={busy || code === ''}>
          {busy ? 'Please wait...' : 'Enter'}
        </Button>
      </Form>
      <Spacer />
      <Row style={{ flexDirection: compact ? 'column' : undefined }}>
        <LinkButton to="/" size="s">
          Back to SAS Digital HQ
        </LinkButton>
        <Spacer />
        <LinkButton to="/info" flex="1 1 0px" size="s">
          Information
        </LinkButton>
      </Row>
      <Spacer size="l" />
      <Hr />
      <Spacer />
      <Row justifyContent="center">
        <AnchorButton href="http://www.secretagentsociety.com">What is SAS?</AnchorButton>
      </Row>
    </IntroTemplate>
  )
}

const TextInput = styled.input<{ error?: boolean }>`
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  outline: none;

  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  border: none;
  border-top: 3px solid #d5d9ed;
  box-sizing: border-box;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  text-transform: uppercase;
  text-align: center;
  ${fontHeavy}
  font-size: 32px;
  line-height: 1;
  padding: 10px 0;
  letter-spacing: 2px;
  color: #001947;

  position: relative;

  ${p => (p.error ? ':enabled,' : '')} :focus {
    box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 0 0 3px ${p => (p.error ? 'red' : p.theme.buttonBorderTopColor)};
    border: 0;
    margin-top: 3px;
  }
`

const FormError = styled.div`
  ${fontRegular}
  color: #ab0303;
  padding: 10px;
  border-radius: 10px;
`
