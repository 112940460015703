import React, { useState, useRef, CSSProperties } from 'react'

import { Reward } from 'skill-tracker/types'
import { rewardFrequencies } from 'skill-tracker/constants/rewardFrequencies'

import { Row, Column, TextInput, Label } from 'common/ui'
import { AutosizeTextarea } from 'common/AutosizeTextarea'
import { SymbolSelectModal } from 'common/SymbolSelectModal'
import { supportsEmojis } from 'utils/deviceUtils'
import { getEmojiId } from 'utils/emojiUtils'
import { skillTrackerBonusEmojis, skillTrackerEmojis } from 'constants/emojiSets'

export const rewardEmojis = skillTrackerEmojis
export const bonusRewardEmojis = skillTrackerBonusEmojis

export const darkRewardEmojis = '🛴🚲🎮🌘📽️'

export const RewardInput: React.FC<{
  reward: Reward
  minTarget?: number
  label?: string
  onChange: (id: Reward['id'], data: Partial<Reward>) => void
  readOnly?: boolean
}> = ({ reward, minTarget, onChange, label, readOnly = false }) => {
  const textInputRef = useRef<HTMLTextAreaElement>(null)
  const symbolInputRef = useRef<HTMLInputElement>(null)

  const [emojiModalOpen, setEmojiModalOpen] = useState(false)

  const emojisSupported = supportsEmojis()
  const textInputStyles: CSSProperties =
    emojisSupported || !reward.symbol
      ? {}
      : {
          backgroundImage: `url('${require(`common/assets/emoji/${getEmojiId(reward.symbol)}.png`)}')`,
          backgroundSize: '1em',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }

  return (
    <>
      <Row style={{ width: '100%', textAlign: 'left' }} flexWrap>
        <Column flex="1 0 auto" paddingRight={10} paddingBottom={10}>
          <Label>{label || `${rewardFrequencies[reward.frequency]} reward:`}</Label>
          <AutosizeTextarea
            ref={textInputRef}
            value={reward.text}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              onChange(reward.id, { text: e.target.value || '' })
            }
            style={{ minHeight: 50, minWidth: 260 }}
            readOnly={readOnly}
          />
        </Column>
        <Column flex="none" paddingRight={reward.frequency === 'bonus' ? 10 : 0}>
          <Label>Symbol:</Label>
          <TextInput
            ref={symbolInputRef}
            value={emojisSupported ? reward.symbol : ' '}
            onChange={(e) => onChange(reward.id, { symbol: e.target.value || '' })}
            style={{
              fontSize: 28,
              width: 75,
              height: 50,
              paddingTop: reward.symbol ? 12 : 5,
              textAlign: 'center',
              ...textInputStyles,
            }}
            readOnly
            placeholder="+"
            onClick={() => !readOnly && setEmojiModalOpen(true)}
          />
        </Column>
        {reward.frequency === 'bonus' && (
          <Column flex="none">
            <Label>Bonus Target:</Label>
            <TextInput
              type="number"
              step={1}
              readOnly={readOnly}
              value={reward.bonusTarget || ''}
              onChange={(e) =>
                onChange(reward.id, { bonusTarget: e.target.value ? parseInt(e.target.value) : undefined })
              }
              style={{ fontSize: 28, width: 85, height: 50 }}
              min={minTarget}
            />
          </Column>
        )}
      </Row>
      <SymbolSelectModal
        isOpen={emojiModalOpen}
        symbols={reward.frequency === 'bonus' ? bonusRewardEmojis : rewardEmojis}
        value={reward.symbol}
        onChange={(symbol) => {
          onChange(reward.id, { symbol })
          setTimeout(() => setEmojiModalOpen(false), 150)
        }}
        onClose={() => {
          setEmojiModalOpen(false)
          setTimeout(() => symbolInputRef.current && symbolInputRef.current.blur())
        }}
      />
    </>
  )
}
