import React, { ReactNode, CSSProperties } from 'react'
import styled from 'styled-components'
import { Column, Row, H1, Spacer, Padding, Button, Container, RowWrapPortrait, buttonPadding } from 'common/ui'

interface Props {
  title?: string
  Title?: ReactNode
  hideExitButton?: boolean
  Buttons?: ReactNode
  onExit?: () => void
  exitLabel?: string
  style?: CSSProperties
  ignoreBreakpoints?: boolean
  mediaBreakpoint?: string
}

export const HeaderTemplate: React.FC<Props> = ({
  children,
  title,
  Title,
  Buttons,
  hideExitButton,
  onExit,
  exitLabel = 'Exit',
  style,
  ignoreBreakpoints,
  mediaBreakpoint,
}) => {
  const notWrapping: boolean = ignoreBreakpoints || !Buttons
  return (
    <Padding size="s" style={style}>
      <Column flex="auto">
        <Row flex="none" paddingBottom="m">
          <Container size="l" className="gadget-header-container">
            <RowWrapPortrait
              alignItems="center"
              mediaQuery={
                notWrapping ? '(max-width: 1px)' : mediaBreakpoint || '(orientation: portrait) and (max-width: 640px)'
              }>
              {Title ? Title : title ? <H1>{title}</H1> : undefined}
              {(Title || title) && <Spacer flex="1 1 auto" className="gadget-header-spacer" />}
              <ButtonsRow
                flexWrap
                flex="none"
                style={{ maxWidth: '100%' }}
                notWrapping={notWrapping}
                mediaBreakpoint={mediaBreakpoint}>
                {Buttons && (
                  <>
                    {Buttons}
                    <Spacer size="s" />
                  </>
                )}
                {!hideExitButton && onExit && <Button size="m" onClick={onExit} children={exitLabel} />}
              </ButtonsRow>
            </RowWrapPortrait>
          </Container>
        </Row>
        {children && (
          <Row flex="1 1 auto">
            <Container size="l">{children}</Container>
          </Row>
        )}
      </Column>
    </Padding>
  )
}

export const ButtonsRow = styled(Row)<{ notWrapping?: boolean; mediaBreakpoint?: string }>`
  & ${Button}, & button {
    ${p => (!p.notWrapping ? 'margin-bottom: 10px;' : '')}
    @media ${p => p.mediaBreakpoint || '(orientation: portrait) and (max-width: 640px)'} {
      padding: ${buttonPadding['s']};
    }
  }
`
