import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { blue, parentGreen } from 'themes'

import { SessionEntityHydrated } from 'shared/dashboard/types'
import { SessionModule, SessionProfile, ParticipantState } from 'shared/session/types'
import { FacManModule } from 'shared/fac-man/types'
import { UPDATE_STATE, ActionTypes } from 'shared/session/actionTypes'

import { SessionStateProvider, useSessionState } from './SessionState'
import { UserInputStateProvider } from './UserInputState'
import { InputContextProvider } from './InputContext'
import { ModalContextProvider } from './ModalContext'

import { AppBackground, Column } from 'common/ui'
import { FacilitatorUi } from './FacilitatorUi'
import { SessionSlideHandler } from './SessionSlide'
import { OfflineMessage } from './OfflineMessage'

import { capitalizeFirstLetter } from 'utils/stringUtils'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useUserState } from 'app/UserState'
import { useGenericUser } from 'app/useGenericUser'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { ResourceLoadingStatusPanel } from 'dashboards/common/ResourceLoadingStatusPanel'

type PracticeMode = 'cadet' | 'parent'

export const FacilitatorPracticeSession: React.FC = () => {
  useDisableOrientationLock()
  const { mode } = useParams<{ mode: PracticeMode }>()
  return (
    <SessionStateProvider>
      <UserInputStateProvider>
        <InputContextProvider>
          <ModalContextProvider>
            <ThemeProvider theme={mode === 'cadet' ? blue : parentGreen}>
              <OfflineMessage />
              <FacilitatorPracticeSessionInner />
            </ThemeProvider>
          </ModalContextProvider>
        </InputContextProvider>
      </UserInputStateProvider>
    </SessionStateProvider>
  )
}

const FacilitatorPracticeSessionInner: React.FC = () => {
  const { mode } = useParams<{ mode: PracticeMode }>()
  const user = useGenericUser()
  const { drupalProfile } = useUserState()
  const {
    dispatch: dispatchSessionState,
    getBaseAction,
    setSessionData,
    setSessionProfile,
    setSessionUserType,
    setManual,
    socket,
  } = useSessionState()
  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)
  const wasLoading = useRef<boolean>(true)
  const [module, moduleEndpoint] = useEndpoint<SessionModule>(`/api/v1/modules/practice/${mode}`)
  const [facManual, facManualEndpoint] = useEndpoint<FacManModule>(`/api/v1/manual_modules/${mode}/practice`)

  const loading = !moduleEndpoint.loaded || facManualEndpoint.loaded

  // NOTE: The DISCARD_ prefix is important as it's what determines when input data saving should be ignored
  const sessionUid = `DISCARD_${user.uid}_${mode}-practice`

  const overwrittenSocketState = useRef<boolean>(false)
  useEffect(() => {
    if (!socket) return
    socket.on('DISPATCH', (action: ActionTypes) => {
      if (action.type === UPDATE_STATE && !overwrittenSocketState.current) {
        overwrittenSocketState.current = true
        console.log(`🥳🥳🥳 PracticeSession intercepted socket and is now overwriting init state.`)
        // Initialize session state!
        dispatchSessionState({
          ...getBaseAction(),
          type: UPDATE_STATE,
          state: {
            ...action.state,
            sessionUid,
            participants: [...Array(3)].map(
              (_, i) =>
                ({
                  profile: {
                    userType: 'agent',
                    roles: [],
                    displayName: `${capitalizeFirstLetter(mode)} ${i + 1}`,
                    uid: `sas-${i}`,
                    socketId: `participantSocket${i}`,
                  } as SessionProfile,
                  status: ['connected', 'idle', 'offline'][i],
                  tokens: 0,
                  locked: false,
                  inputValues: [],
                  currentSlideUid: null,
                  gadgetTrayOpen: false,
                  trayType: null,
                } as ParticipantState)
            ),
            facilitators: [
              {
                profile: { ...user },
                status: 'connected',
                currentSlideUid: null,
                currentPreviewSlideUid: null,
                focusedParticipantUid: null,
                selectedTool: null,
                currentManualStepUid: null,
                slideScrollPositions: {},
              },
            ],
          },
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  useEffect(() => {
    if (!loading && wasLoading.current === true && module !== null /* && facManual !== null */) {
      wasLoading.current = false

      const session: SessionEntityHydrated = {
        id: -1,
        uid: sessionUid,
        type: mode as PracticeMode,
        module_code: 'practise',
        extra_groups: [],
        session_assistants: [],
        name: '',
        group_id: -1,
        module,
        group: {
          id: -1,
          uid: sessionUid,
          facilitator_id: drupalProfile ? parseInt(drupalProfile.user_id) : 0,
          provider_uid: '',
          name: 'Practise Group',
          active: true,
          deleted: false,
          mentors: [],
          primary_mentors: [],
          created: '',
          modified: '',
        },
        start_date: '',
        start_time: '',
        start_datetime: Date.now() / 1000,
        timezone: '',
        concluded: false,
        catch_up_active: false,
        finalized: false,
        deleted: false,
      }

      // Update static state data
      ReactDOM.unstable_batchedUpdates(() => {
        setSessionUserType('facilitator')
        setSessionProfile({ ...user, userType: 'facilitator' })
        setSessionData(session)
        if (facManual) setManual(facManual)
      })

      // prettier-ignore
      setTimeout(() => { setFinishedDelay(true) }, 1000)
    }
  })

  if (loading || !finishedDelay)
    return (
      <AppBackground>
        <Column flex="auto" alignItems="center" justifyContent="center">
          <ResourceLoadingStatusPanel
            title="Loading meeting data..."
            resources={[
              { label: 'Module content', endpoint: moduleEndpoint },
              { label: 'Facilitator manual content', endpoint: facManualEndpoint },
            ]}
          />
        </Column>
      </AppBackground>
    )

  return (
    <FacilitatorUi>
      <SessionSlideHandler />
    </FacilitatorUi>
  )
}
