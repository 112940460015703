import { Skill, Reward, SkillUse, WorkflowType, Module, PresetModule } from './types'
import { State, RoleState } from './SkillTrackerState'
import { BaseAction, Role } from 'shared/types'

export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW'
export const UPDATE_ACTIVE_MODULE = 'UPDATE_ACTIVE_MODULE'
export const SET_MODULE_COMPLETED = 'SET_MODULE_COMPLETED'
export const ADD_UPDATE_MODULE = 'UPDATE_MODULE'
export const ADD_UPDATE_SKILL = 'UPDATE_SKILL'
export const UPDATE_SKILLS = 'UPDATE_SKILLS'
export const REMOVE_SKILL = 'REMOVE_SKILL'
export const ADD_UPDATE_REWARD = 'UPDATE_REWARD'
export const REMOVE_REWARD = 'REMOVE_REWARD'
export const ADD_UPDATE_SKILL_USE = 'UPDATE_SKILL_USE'
export const REMOVE_SKILL_USE = 'REMOVE_SKILL_USE'
export const UPDATE_ROLE_STATE_VALUES = 'UPDATE_ROLE_STATE_VALUES'

export interface UpdateStateAction extends BaseAction {
  type: typeof UPDATE_STATE
  state: State
}

export interface UpdateWorkflowAction extends BaseAction {
  type: typeof UPDATE_WORKFLOW
  workflow: WorkflowType
}

export interface UpdateActiveModuleAction extends BaseAction {
  type: typeof UPDATE_ACTIVE_MODULE
  id: Module['id'] | PresetModule['id']
  module: Module
  skills: Skill[]
}

export interface AddUpdateModuleAction extends BaseAction {
  type: typeof ADD_UPDATE_MODULE
  module: Module
  setActive?: boolean
}

export interface SetModuleCompletedAction extends BaseAction {
  type: typeof SET_MODULE_COMPLETED
  moduleId: Module['id']
}

export interface AddUpdateSkillAction extends BaseAction {
  type: typeof ADD_UPDATE_SKILL
  skill: Skill
}

export interface UpdateSkillsAction extends BaseAction {
  type: typeof UPDATE_SKILLS
  skillIds: Skill['id'][]
  data: Partial<Skill>
}

export interface RemoveSkillAction extends BaseAction {
  type: typeof REMOVE_SKILL
  skill: Skill
}

export interface AddUpdateRewardAction extends BaseAction {
  type: typeof ADD_UPDATE_REWARD
  reward: Reward
}

export interface RemoveRewardAction extends BaseAction {
  type: typeof REMOVE_REWARD
  reward: Reward
}

export interface AddUpdateSkillUseAction extends BaseAction {
  type: typeof ADD_UPDATE_SKILL_USE
  skillUse: SkillUse
}

export interface RemoveSkillUseAction extends BaseAction {
  type: typeof REMOVE_SKILL_USE
  skillUse: SkillUse
}

export interface UpdateRoleStateValuesAction extends BaseAction {
  type: typeof UPDATE_ROLE_STATE_VALUES
  role: Role
  data: Partial<RoleState>
}

export type ActionTypes =
  | UpdateStateAction
  | UpdateWorkflowAction
  | UpdateActiveModuleAction
  | AddUpdateModuleAction
  | SetModuleCompletedAction
  | AddUpdateSkillAction
  | UpdateSkillsAction
  | RemoveSkillAction
  | AddUpdateSkillUseAction
  | RemoveSkillUseAction
  | AddUpdateRewardAction
  | RemoveRewardAction
  | UpdateRoleStateValuesAction
