import React from 'react'
import styled from 'styled-components'

import { NavRouteProps } from 'dashboards/types'
import { GetProfile } from 'api'

import { P } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { CenteredBody } from 'dashboards/common/LayoutUI'
import { GadgetTray, TrayContainer, TrayItem } from 'session/gadget-tray/GadgetTray'
import { LoadingWindow } from './MentorGadgetPack'

import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useUserState, UserStateContext } from 'app/UserState'
import { useMentorDashState, useMentorDashData } from './MentorDashboardState'
import { useGadgetPackState, GadgetPackStateProvider } from 'gadget-pack/GadgetPackState'
import { useProgressionEventsState, ProgressionEventsStateProvider } from 'session/ProgressionEventsState'

export const MentorGadgetsQuickView: React.FC<NavRouteProps> = ({ route }) => {
  const userState = useUserState()
  const { mentorUid } = useMentorDashState()
  const [primaryMentor] = useMentorDashData('primaryMentor')

  const [cadetProfile, { loading: loadingCadetProfile, loaded: loadedCadetProfile }] = useEndpoint<GetProfile>(
    `/api/v1/mentors/${mentorUid}/cadet`
  )

  const cadetName =
    primaryMentor && primaryMentor.cadet_first_name
      ? `${primaryMentor.cadet_first_name} ${primaryMentor.cadet_last_name}`
      : ''

  return (
    <>
      {loadingCadetProfile ? (
        <CenteredBody>
          <LoadingWindow loadingCadet loadingGadgetPack loadingProgressionEvents />
        </CenteredBody>
      ) : !loadedCadetProfile || !cadetProfile ? (
        <Page route={route}>
          <P>Error loading cadet profile</P>
        </Page>
      ) : (
        <UserStateContext.Provider
          value={{
            ...userState,
            authProvider: 'sas',
            profileId: cadetProfile.id,
            profiles: [cadetProfile],
            profileName: cadetProfile.profile_name || cadetName,
          }}>
          <ProgressionEventsStateProvider>
            <GadgetPackStateProvider>
              <GadgetTrayWrapper route={route} />
            </GadgetPackStateProvider>
          </ProgressionEventsStateProvider>
        </UserStateContext.Provider>
      )}
    </>
  )
}

const GadgetTrayWrapper: React.FC<NavRouteProps> = ({ route }) => {
  const { loadingInitialState } = useGadgetPackState()
  const { loadingProgressionEvents } = useProgressionEventsState()
  if (loadingInitialState || loadingProgressionEvents)
    return (
      <CenteredBody>
        <LoadingWindow
          loadingCadet={false}
          loadingGadgetPack={loadingInitialState}
          loadingProgressionEvents={loadingProgressionEvents}
        />
      </CenteredBody>
    )
  return (
    <Page route={route}>
      <GadgetPackWrapper>
        <GadgetTray readOnly />
      </GadgetPackWrapper>
    </Page>
  )
}

const GadgetPackWrapper = styled.div`
  & ${TrayContainer} {
    padding: 0;
  }
  & ${TrayItem} {
    &::after {
      color: white;
    }
  }
`
