import { useEffect } from 'react'
import Sound, { howlerInited, initHowler, SoundOptions } from 'app/Sound'
import { useMemoOne } from './useMemoOne'
import { useGlobalState } from 'app/GlobalState'

export function useSound(audioSrc: string | string[], autoplay: boolean = true) {
  if (!howlerInited()) initHowler()

  const { playbackRate } = useGlobalState()
  const sound = useMemoOne(() => {
    return new Sound({
      src: audioSrc,
      autoplay: false,
    })
  }, [audioSrc])

  // fade out when unmounted
  useEffect(() => {
    if (autoplay) {
      sound.play()
    }
    return () => {
      sound.fadeOutAndUnload(400)
    }
  }, [sound, autoplay])

  useEffect(() => {
    sound.rate(playbackRate)
  }, [sound, playbackRate])

  return sound
}

export function useSoundSprites(config: SoundOptions) {
  const sound = useMemoOne(() => {
    return new Sound(config)
  }, [config])

  useEffect(() => {
    return () => sound.unload()
  }, [sound])

  return sound
}
