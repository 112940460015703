import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { Page } from 'dashboards/common/Page'
import { P } from 'common/ui'
import { LicenseDetails } from 'dashboards/common/License'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { ProductKey } from 'shared/dashboard/types'
import { SpinnerWithLabel } from 'common/Spinner'

export const DigiHQ: React.FC<NavRouteProps> = ({ route }) => {
  const [productKey, { loaded, loading }] = useEndpoint<ProductKey>(`/api/v1/facilitators/personal_key`)
  return (
    <Page route={route} title="SAS Digital HQ">
      {loading && <SpinnerWithLabel label="Loading data..." />}
      {loaded && !productKey && <P>You do not have a current SAS Digital HQ licence associated to your account.</P>}
      {productKey && <LicenseDetails productKey={productKey} type="facilitator" />}
    </Page>
  )
}
