import { DrawingSectionState } from '../../types'
import {
  ActionTypes,
  ADD_DRAWING_ACTION,
  TOGGLE_CANVAS_LOCK,
  ADD_UNDO_ACTION,
  ADD_REDO_ACTION,
  DrawingActionTypes,
  UPDATE_THUMBNAIL_URL,
} from './actionTypes'

export const getInitialState = (): DrawingSectionState => ({ canvasLocked: false, actions: [], currentActionIndex: -1 })

export function reducer(state: DrawingSectionState, action: ActionTypes): DrawingSectionState {
  if (!state) state = getInitialState()
  switch (action.type) {
    case ADD_DRAWING_ACTION: {
      let actions: DrawingActionTypes[] = state.actions || []
      if (state.currentActionIndex < (state.actions || []).length - 1) {
        actions = state.actions.filter((action, i) => i <= state.currentActionIndex)
        return {
          ...state,
          actions: [...actions, { ...action.action, index: (state.actions || []).length }],
          currentActionIndex: actions.length,
        }
      } else {
        return {
          ...state,
          actions: [...actions, { ...action.action, index: (state.actions || []).length }],
          currentActionIndex: state.currentActionIndex + 1,
        }
      }
    }
    case TOGGLE_CANVAS_LOCK:
      return { ...state, canvasLocked: !state.canvasLocked }
    case ADD_UNDO_ACTION: {
      const currentActionIndex = state.currentActionIndex > 0 ? state.currentActionIndex - 1 : -1
      return { ...state, currentActionIndex }
    }
    case ADD_REDO_ACTION: {
      const currentActionIndex =
        state.currentActionIndex < (state.actions || []).length - 1
          ? state.currentActionIndex + 1
          : (state.actions || []).length - 1
      return { ...state, currentActionIndex }
    }
    case UPDATE_THUMBNAIL_URL:
      return {
        ...state,
        thumbnail: action.thumbnail,
      }
    default:
      return state
  }
}
