import React, { useRef } from 'react'
import { DragObjectWithType, useDrag } from 'react-dnd'
import { QuestionOption } from 'shared/training/types'

export interface DragItemType extends DragObjectWithType {
  from: 'dropZone' | 'list'
  item: QuestionOption
}

export const DraggableOption: React.FC<{
  item: QuestionOption
  origin: DragItemType['from']
  disabled?: boolean
  type: string
}> = ({ item, origin, disabled, type, children }) => {
  const measuringRef = useRef<HTMLDivElement>(null)
  const [{ isDragging }, drag] = useDrag({
    item: { type: type, from: origin, item } as DragItemType,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
    canDrag: monitor => !disabled,
  })

  return (
    <div ref={drag} style={{ opacity: isDragging || disabled ? 0.5 : 1, pointerEvents: isDragging ? 'none' : 'auto' }}>
      <div ref={measuringRef}>{children}</div>
    </div>
  )
}
