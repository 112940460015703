import React, { useState, useEffect, useRef, CSSProperties } from 'react'
import { Feedback, Incorrect } from 'common/ResponseFeedback'

export const Notification: React.FC<{
  feedbackText: string | null
  timeout: number
  style?: CSSProperties
  onComplete?: () => void
}> = ({ feedbackText, style, timeout, onComplete = () => {} }) => {
  const autoDismissTimeout = useRef<number>(0)
  const [dismissFeedback, setDismissFeedback] = useState<boolean>(false)

  useEffect(() => {
    if (autoDismissTimeout.current) clearTimeout(autoDismissTimeout.current)
    if (feedbackText !== null) setDismissFeedback(false)
    autoDismissTimeout.current = setTimeout(() => {
      setDismissFeedback(true)
      autoDismissTimeout.current = 0
      onComplete()
    }, timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackText])

  return (
    <Feedback visible={!dismissFeedback && feedbackText !== null}>
      <Incorrect style={style}>{feedbackText}</Incorrect>
    </Feedback>
  )
}
