import React from 'react'

import { Panel } from 'common/ui'
import { HeaderTemplate } from '../common/HeaderTemplate'

import { useGadgetPackState } from '../GadgetPackState'
import { CodeCardEditInner } from 'e-telligence/CodeCardEdit'
import { useLocation, useHistory } from 'react-router-dom'

export const CodeCardEdit: React.FC<{ baseUrl: string; isNew?: boolean }> = ({ baseUrl, isNew }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useGadgetPackState()
  return (
    <HeaderTemplate
      title="Code Cards"
      exitLabel="Back"
      onExit={() => history.push(baseUrl + '/code-cards', location.state)}>
      <Panel>
        <CodeCardEditInner isNew={isNew} state={state} dispatch={dispatch} baseUrl={baseUrl} />
      </Panel>
    </HeaderTemplate>
  )
}
