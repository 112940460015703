import React from 'react'
import { SectionPropsBase, TextSection as TextSectionType } from 'shared/session/types'
import { SectionPropsBase as FacManSectionPropsBase } from 'shared/fac-man/types'
import { RichText } from 'session/common/RichText'

interface Props extends SectionPropsBase {
  property: 'text_sections'
}

export const TextSection: React.FC<Props> = ({ property, section, index }) => {
  return <BasicTextSection text={section.text} />
}

export const TextSectionFacilitator = TextSection

interface GenericProps extends FacManSectionPropsBase {
  property: 'text_sections'
}

export const GenericTextSection: React.FC<GenericProps> = ({ section }) => {
  return <BasicTextSection text={section.text} />
}

export const BasicTextSection: React.FC<Pick<TextSectionType, 'text'>> = ({ text }) => {
  return <RichText text={text} />
}
