import React, { useEffect } from 'react'

import { getSlideByUid } from './reducers'
import { useSessionState } from './SessionState'

import { PARTICIPANT_HEADER_HEIGHT } from './ParticipantUi'
import {
  HOLDING_SLIDE,
  MODULE_SLIDE,
  SUPPLEMENTARY_SLIDE,
  HydratedModuleSlideRef,
  HydratedSlide,
  SupplementarySlide,
  END_SLIDE,
} from 'shared/session/types'

import {
  Panel,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  GRILL_TL_S,
  GRILL_TR_S,
  GRILL_BL_S,
  GRILL_BR_S,
  Row,
  Column,
  Padding,
} from 'common/ui'
import { SectionsPanel } from './common/SectionsPanel'
import { StepThemeContainer } from './ThemeContainer'
import { SessionSplash } from './SessionSplash'
import { SupplementarySlideContainer } from './SupplementarySlides'
import { FacilitatorChecklistOverview } from './FacilitatorChecklist'
import {
  SlideProps,
  SessionSlideTransitionHandler,
  PanelsContainer,
  ModuleTitle,
  StepTitle,
  StepSubheading,
} from './SessionSlide'

import { useFocusedParticipantState } from './hooks/useProfileState'
import { useUserInputState } from './UserInputState'

export const PastSessionSlideHandler: React.FC = () => {
  const { getSavedInputValues } = useUserInputState()
  const { sessionData, state, previewMode, previewState } = useSessionState()
  const participantState = useFocusedParticipantState()

  // going to leave this code here for reference even the supplementary slides will never be displayed in past context
  const savedSupplementarySlides = getSavedInputValues<SupplementarySlide>('shared', {
    owner: 'supplementary_slides',
  })
  const supplementarySlides = savedSupplementarySlides.map(({ value }) => value)
  const realSlideUid = participantState ? participantState.currentSlideUid : null

  const slide: HydratedSlide = (previewMode && sessionData
    ? sessionData.module?.steps?.[previewState.stepIndex]
      ? ({
          type: MODULE_SLIDE,
          uid: sessionData.module.steps[previewState.stepIndex].uid,
          order: previewState.stepIndex,
          step: sessionData.module.steps[previewState.stepIndex],
        } as HydratedModuleSlideRef)
      : undefined
    : getSlideByUid(sessionData, state, supplementarySlides, realSlideUid)) || { type: HOLDING_SLIDE, order: -1 }

  return <SessionSlideTransitionHandler slide={slide} SlideComponent={PastSessionSlide} />
}

const PastSessionSlide: React.FC<SlideProps> = ({ slide }) => {
  const { isFacilitator, sessionData } = useSessionState()
  const showHeaderPanel = !isFacilitator && (slide.type === 'MODULE_SLIDE' ? !slide.step.hide_header : true)
  const singlePanelMode = slide.type === 'MODULE_SLIDE' ? slide.step.single_panel : false

  useEffect(() => {
    if (!isFacilitator) document.body.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide])

  if (!sessionData || slide.type === HOLDING_SLIDE) {
    return (
      <StepThemeContainer module={sessionData ? sessionData.module : undefined} slide={slide}>
        <SessionSplash text="Please Wait" />
      </StepThemeContainer>
    )
  }

  if (slide.type === END_SLIDE) {
    return (
      <StepThemeContainer module={sessionData.module} slide={slide}>
        {isFacilitator && <FacilitatorChecklistOverview type={slide.type} />}
        {!isFacilitator && <SessionSplash text="Session Complete" />}
      </StepThemeContainer>
    )
  }

  return (
    <div style={{ paddingBottom: PARTICIPANT_HEADER_HEIGHT }}>
      <StepThemeContainer module={sessionData.module} slide={slide}>
        <Padding size="l" style={{ maxWidth: 'calc(100% - 40px)' }}>
          <PanelsContainer size="l" singlePanelMode={singlePanelMode} moduleType={sessionData.module.type}>
            {showHeaderPanel && (
              <Panel
                className="header-panel"
                theme={slide.type === MODULE_SLIDE ? slide.step.theme || undefined : undefined}
                flair={[CUT_TL, GRILL_TL_S, CUT_TR, GRILL_TR_S, CUT_BL, GRILL_BL_S, CUT_BR, GRILL_BR_S]}
                style={{ padding: '2rem 0', textAlign: 'center', boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.2)' }}>
                <Row>
                  <div style={{ flex: '1 1 auto' }} />
                  <Column flex="1 1 auto" style={{ maxWidth: 680 }}>
                    <ModuleTitle>{sessionData.module.public_title || sessionData.module.title}</ModuleTitle>
                    {slide.type === MODULE_SLIDE && (
                      <>
                        {slide.step.title && <StepTitle>{slide.step.title}</StepTitle>}
                        {slide.step.subheading && <StepSubheading>{slide.step.subheading}</StepSubheading>}
                      </>
                    )}
                    {slide.type === SUPPLEMENTARY_SLIDE && (
                      <>{slide.supplementarySlide.title && <StepTitle>{slide.supplementarySlide.title}</StepTitle>}</>
                    )}
                  </Column>
                  <div style={{ flex: '1 1 auto' }} />
                </Row>
              </Panel>
            )}
            {slide.type === MODULE_SLIDE && (
              <>
                {slide?.step?.panels
                  ?.filter((panel, i) => (singlePanelMode ? i === 0 : true))
                  .map(panel => (
                    <SectionsPanel key={panel.uid} panel={panel} fullHeight={slide.step.single_panel} />
                  ))}
              </>
            )}
            {slide.type === SUPPLEMENTARY_SLIDE && (
              <SupplementarySlideContainer key={`${slide.supplementarySlide.type}_${slide.uid}`} slide={slide} />
            )}
          </PanelsContainer>
        </Padding>
      </StepThemeContainer>
    </div>
  )
}
