import React from 'react'

import { P, CollapseMargin } from 'common/ui'
import { IntroTemplate } from 'home/IntroTemplate'

export const ProfileNotFoundPage: React.FC = () => {
  // TODO: track error in sentry
  return (
    <IntroTemplate title="Create Profile">
      <CollapseMargin>
        <P align="center">Please create your new profile on a desktop or tablet device.</P>
      </CollapseMargin>
    </IntroTemplate>
  )
}
