import React from 'react'
import { Column, H3, P, Row, Spacer } from 'common/ui'
import { NavRouteProps } from 'dashboards/types'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Page } from 'dashboards/common/Page'
import { AnchorButton, LinkButton } from 'common/LinkButton'
import { InlineCards, InlineCard } from 'dashboards/common/InlineCard'
import { TrainingEnrolment } from 'shared/training/types'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { SpinnerWithLabel } from 'common/Spinner'
import { TrainingCertificateButton } from './TrainingCertificateButton'
import uniqBy from 'lodash/uniqBy'
import { getImageUrl } from 'session/utils/assetUtils'

export const Training: React.FC<NavRouteProps> = ({ route }) => {
  const [enrolledCourses, { loading }] = useEndpoint<TrainingEnrolment[]>(`/api/v1/training_courses/listEnrolled`, [])

  const drupalCourses = enrolledCourses?.filter(course => course.is_drupal)
  const customCourses = enrolledCourses?.filter(course => !course.is_drupal)

  const getButtonLabel = (course: TrainingEnrolment) => {
    if (course.drupalData) {
      if (course.drupalData.course_status === 'In review' || course.drupalData.course_status === 'Complete')
        return 'Review'
      if (course.drupalData.course_status === 'In progress') return 'Continue'
    }
    return 'Begin'
  }

  const checkComplete = (course: TrainingEnrolment) =>
    course.drupalData && course.drupalData.course_status === 'Complete'

  const checkExpired = (course: TrainingEnrolment) => {
    if (!course || !course.drupalData || course.drupalData.complete_course) return false
    if (course.drupalData.course_status === 'Expired') return true
    return false
  }

  return (
    <Page route={route}>
      <Spacer size="l" />
      {loading ? (
        <SpinnerWithLabel color="#925BED" label="One moment please..." />
      ) : (
        <>
          <HeaderHr children="My Enrolled Courses" />
          {drupalCourses && drupalCourses.length > 0 ? (
            <InlineCards>
              {uniqBy(drupalCourses, 'type').map((course, idx) => (
                <InlineCard
                  key={idx}
                  requiredImage={course.image ? getImageUrl(course.image, { preset: '320w-16-9' }) : undefined}>
                  <Column flex="1 1 auto">
                    <H3>{course.title}</H3>
                    <Spacer size="m" />
                  </Column>
                  <Column flex="none">
                    {checkExpired(course) ? (
                      <P color="#ed5c61">
                        This course was not completed within the allowed time frame, please purchase the course again if
                        you would like to continue.
                      </P>
                    ) : (
                      <Row>
                        <LinkButton size="s" to={`${route.path}/${course.type}`} children={getButtonLabel(course)} />
                        {checkComplete(course) && (
                          <>
                            <Spacer />
                            <TrainingCertificateButton size="s" courseType={course.type as string} />
                          </>
                        )}
                      </Row>
                    )}
                  </Column>
                </InlineCard>
              ))}
            </InlineCards>
          ) : (
            <P>You aren't currently enrolled in any courses.</P>
          )}
          {customCourses && customCourses.length > 0 && (
            <>
              <Spacer size="l" />
              <HeaderHr children="Additional Content" />
              <InlineCards>
                {customCourses.map((course, idx) => (
                  <InlineCard
                    key={idx}
                    requiredImage={course.image ? getImageUrl(course.image, { preset: '320w-16-9' }) : undefined}>
                    <Column flex="1 1 auto">
                      <H3>{course.title}</H3>
                      <Spacer size="m" />
                    </Column>
                    <Row>
                      <LinkButton size="s" to={`${route.path}/${course.type}`} children="View" />
                    </Row>
                  </InlineCard>
                ))}
              </InlineCards>
            </>
          )}
          <Spacer size="xl" />
          <AnchorButton
            size="s"
            href="https://www.sst-institute.net/new-professional-training"
            target="_blank"
            children="SAS Professional Training"
          />
        </>
      )}
    </Page>
  )
}
