import React from 'react'

import { JournalBackground, Padding, Column, Row, Spacer, H1, P, Button, Panel } from 'common/ui'
import { ShortcutMenu } from 'common/ShortcutMenu'
import { GameModeIndicator } from 'app/GameModeIndicator'
import { subscriptToSmall } from 'utils/subscriptToSmall'
import styled from 'styled-components'
import { fontBold } from 'fonts'
import { RadioButtonGroup, RadioButtonGroupContainer } from 'common/RadioButtonGroup'
import { useHistory, useLocation } from 'react-router-dom'
import { cleanRouteState } from 'utils/routeUtils'
import { PDAModalButton } from 'common/PDAModalButton'

interface Props {
  title: string
  description: string
  compact: boolean
  showNav?: boolean
  onExit: () => void
}

export const JournalMission: React.FC<Props> = ({ title, description, compact, showNav, onExit, children }) => {
  const history = useHistory()
  const location = useLocation()
  return (
    <JournalBackground>
      <Padding>
        <Column flex>
          {showNav && (
            <JournalNav padding="m" marginBottom="l">
              <Row justifyContent="space-between" alignItems="center">
                <Title>Cadet Journals</Title>
                <Row>
                  <RadioButtonGroup
                    size="s"
                    options={[
                      { value: 'journal', label: 'Mission Journal' },
                      { value: 'club-meeting', label: 'Club Journal' },
                    ]}
                    value={location.pathname.split('/')[1]}
                    onChange={value => history.push(`/${value}`, cleanRouteState(location.state))}
                  />
                  <Button size={'s'} onClick={onExit}>
                    Exit
                  </Button>
                </Row>
              </Row>
            </JournalNav>
          )}

          <Row style={{ flexDirection: compact ? 'column-reverse' : undefined }}>
            <Column flex paddingRight={compact ? false : 'l'}>
              <H1 style={{ color: 'white', hyphens: compact ? 'manual' : 'none' }}>{subscriptToSmall(title)}</H1>
              <P style={{ color: 'white' }}>{subscriptToSmall(description)}</P>
            </Column>
            <Spacer flex />
            {!showNav && (
              <Row alignItems="flex-start">
                <Row flex alignItems="center" justifyContent="flex-end">
                  <PDAModalButton size={compact ? 's' : 'm'} />
                  <Spacer />
                  <Button shadowColor="dark" size={compact ? 's' : 'm'} onClick={onExit}>
                    Exit
                  </Button>
                </Row>
              </Row>
            )}
          </Row>
          <div id="content-top" />
          {children}
        </Column>
      </Padding>
      <ShortcutMenu />
      <GameModeIndicator />
    </JournalBackground>
  )
}

const Title = styled.p`
  ${fontBold};
  margin: 0;
  font-size: 28px;
  letter-spacing: 0.02em;
  color: #011a46;
`

const JournalNav = styled(Panel)`
  ${RadioButtonGroupContainer} {
    margin-right: 10px;
    ${Button} {
      width: 200px;
    }
  }
`
