import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { GadgetIndex } from 'types'
import { fontBold } from 'fonts'
import { GADGETS } from 'gadgets'

import { AudioVisualiser } from './AudioVisualiser'
import { VideoContainer } from 'app/VideoContainer'
import { getGadgetImagesForGameMode } from 'app/Inventory'
import { Panel, Row, Button, Spacer, Padding, Column, Absolute, PanelProps } from './ui'

import { useGlobalState } from 'app/GlobalState'
import { useUserState } from 'app/UserState'
import { useVideo } from 'utils/useVideo'
import { useVideoEnded } from 'utils/useVideoEnded'
import { playVideoCatchExceptions } from 'utils/videoUtils'
import { getInputProps } from 'utils/virtualKeyboard'
import { preloadImage } from 'utils/preloadUtils'
import { PreloadStrategy } from 'utils/VideoPool'
import { isPhone } from 'utils/deviceUtils'

interface Props {
  forceVisible?: boolean
  shadow?: PanelProps['shadow']
  shadowColor?: PanelProps['shadowColor']
}

const PDA: React.FC<Props> = ({ forceVisible: visible, shadow, shadowColor }) => {
  const state = useGlobalState()
  const userState = useUserState()
  const [gadgetButtonHovered, setGadgetButtonHovered] = useState(false)
  useEffect(() => {
    if (gadgetButtonHovered || state.gadgetHintActive) {
      const images = getGadgetImagesForGameMode(userState.gameMode, userState.hasCompleted)
      Promise.all(images.map(image => preloadImage(image))).catch(() => {})
    }
  }, [gadgetButtonHovered, state.gadgetHintActive, userState.gameMode, userState.hasCompleted])

  if (!visible && !state.pdaVisible) return null

  return (
    <Row>
      <Panel flex="0 0 230px" shadow={shadow} shadowColor={shadowColor}>
        <PdaScreen>
          {state.pdaWarning ? (
            <PdaWarning>{state.pdaWarning}</PdaWarning>
          ) : state.pdaScreen === 'visualiser' ? (
            <AudioVisualiser width={230} height={230} connected={state.visualiserActive} />
          ) : state.pdaScreen === 'text-input' ? (
            <PdaTextInput />
          ) : state.activeGadgetIndex >= 0 ? (
            <Gadget index={state.activeGadgetIndex} />
          ) : state.pdaImage ? (
            <PdaImage cover image={state.pdaImage} />
          ) : (
            <ArdonVideo speaking={state.ardonSpeaking} />
          )}
          <SoundIcon speaking={state.ardonSpeaking} muted={state.muted} setMuted={state.setMuted} />
          <PdaGloss cover />
        </PdaScreen>
        <Padding size="xs">
          <Row>
            <Column flex="1 1 0px">
              <Button
                size="s"
                disabled={isPhone()}
                flashing={state.gadgetHintActive}
                onClick={() => state.setInventoryVisible(true)}
                onMouseOver={() => setGadgetButtonHovered(true)}
                data-onboarding="pda-gadgets">
                Inventory
              </Button>
              <Spacer size="xs" />
              <Button
                size="s"
                disabled={!state.helpAvailable}
                onClick={() => state.setHelpVisible(!state.helpVisible)}
                pressed={state.helpVisible}
                flashing={state.helpHintActive}
                data-onboarding="pda-clue">
                Help
              </Button>
            </Column>
            <Spacer size="xs" />
            <Column flex="1 1 0px">
              <Button size="s" onClick={() => state.setSettingsVisible(true)} data-onboarding="pda-settings">
                Settings
              </Button>
              <Spacer size="xs" />
              <Button
                size="s"
                disabled={!state.repeatAvailable}
                onClick={() => state.setRepeatRequested(true)}
                data-onboarding="pda-repeat">
                Repeat
              </Button>
            </Column>
          </Row>
        </Padding>
      </Panel>
    </Row>
  )
}

export default PDA

const PdaScreen = styled(Column)`
  width: 230px;
  height: 245px;
  background: black;
  border-radius: 15px 15px 0 0;
`

const PdaGloss = styled(Absolute)`
  border-radius: 15px;
  background: url(${require('common/assets/image/pda-gloss.png')}) top center / 230px 270px no-repeat;
  pointer-events: none;
`

const PdaWarning = styled.div`
  ${fontBold}
  color: white;
  margin: auto;
  padding: 0 15px;
  text-align: center;
  white-space: pre-wrap;
  font-size: 18px;
`

const PdaImage = styled(Absolute)<{ image: string }>`
  background-size: cover;
  background-position: center center;
  background-image: url("${p => p.image}");
  border-radius: 15px 15px 0 0;
`

const Gadget: React.FC<{ index: GadgetIndex }> = ({ index }) => {
  const gadget = GADGETS[index]
  return (
    <Column flex style={{ justifyContent: 'center' }}>
      <img src={gadget.pdaSrc} width="230" alt={gadget.title} />
      <GadgetTitle>{gadget.title}</GadgetTitle>
    </Column>
  )
}

const FormLabel = styled.label`
  ${fontBold}
  font-size: 16px;
  color: white;
  padding-left: 2px;
`

const TextInput = styled.input<{ error?: boolean }>`
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  outline: none;
  box-sizing: border-box;
  border: 1px solid white;

  ${fontBold}
  text-align: center;
  font-size: 16px;
  line-height: 1;
  padding: 10px 0;
  color: white;
  background-color: transparent;
  text-transform: uppercase;

  position: relative;

  ${p => (p.error ? ':enabled,' : '')} :focus {
    color: ${p => (p.error ? 'red' : p.theme.buttonBorderTopColor)};
    border-color: ${p => (p.error ? 'red' : p.theme.buttonBorderTopColor)};
  }
`

const PdaTextInput: React.FC<{}> = () => {
  const { pdaTextInputLabel, pdaTextInputError, setPdaTextInputError, setPdaTextInputValue } = useGlobalState()
  const [value, changeValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPdaTextInputValue(value)
    return false
  }
  const handleValueChange = (val: string) => {
    if (pdaTextInputError) setPdaTextInputError(false)
    changeValue(val)
  }
  return (
    <Column flex style={{ justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: 180, color: 'white', textAlign: 'center' }}>
        <form onSubmit={handleFormSubmit}>
          {pdaTextInputLabel && <FormLabel>{pdaTextInputLabel}</FormLabel>}
          <TextInput
            ref={inputRef}
            type="text"
            error={pdaTextInputError}
            style={{ margin: '10px 0' }}
            value={value}
            onChange={e => handleValueChange(e.target.value || '')}
            {...getInputProps()}
          />
          <Button type="submit" size="s">
            Submit
          </Button>
        </form>
      </div>
    </Column>
  )
}

const GadgetTitle = styled(PdaWarning)`
  margin: 0;
  line-height: 1.2;
`

const ArdonVideo: React.FC<{ speaking: boolean }> = ({ speaking }) => {
  const idleVideo = useVideo(require('./assets/video/1.0.0.1_agent_idle2.mp4'), PreloadStrategy.Fetch, false)
  useVideoEnded(idleVideo, () => {
    setTimeout(() => playVideoCatchExceptions(idleVideo), 2000)
  })
  const speakingVideo = useVideo(require('./assets/video/1.0.0.1_agent_speaking.mp4'), PreloadStrategy.Fetch, false)
  speakingVideo.loop = true
  return (
    <>
      <ArdonStaticImage cover />
      <Absolute bottom="0">
        <VideoContainer
          video={idleVideo}
          width={230}
          height={230}
          allowToggle={false}
          indicator={false}
          playButton={false}
        />
      </Absolute>
      {speaking && (
        <Absolute bottom="0">
          <VideoContainer
            video={speakingVideo}
            width={230}
            height={230}
            allowToggle={false}
            indicator={false}
            playButton={false}
          />
        </Absolute>
      )}
    </>
  )
}

const ArdonStaticImage = styled(Absolute)`
  background: url(${require('./assets/image/1.0.0.1_agent_idle2_0001.jpg')}) no-repeat center bottom / contain;
`

const SoundIcon: React.FC<{ speaking: boolean; muted: boolean; setMuted: (value: boolean) => void }> = ({
  speaking,
  muted,
  setMuted,
}) => {
  return speaking ? (
    <Absolute top="10px" left="10px" style={{ cursor: 'pointer' }} onClick={() => setMuted(!muted)}>
      {muted ? <MuteIcon /> : <UnmuteIcon />}
    </Absolute>
  ) : null
}

const MuteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.6"
    height="33.6"
    viewBox="0 0 68 48"
    strokeWidth="2"
    strokeLinejoin="round"
    strokeLinecap="round"
    stroke="#fff"
    fill="none">
    <polygon points="34.5 40.23 18.5 31.09 18.5 16.91 34.5 7.77 34.5 40.23" />
    <polyline points="19.5 16.5 7.5 16.5 7.5 31.5 19.5 31.5" />
    <path d="M35.08,18.34a5.66,5.66,0,0,1,0,11.32" />
    <line x1="42.72" y1="42.72" x2="5.28" y2="5.28" />
    <line x1="5.28" y1="42.72" x2="42.72" y2="5.28" />
  </svg>
)

const UnmuteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.6"
    height="33.6"
    viewBox="0 0 68 48"
    strokeWidth="2"
    strokeLinejoin="round"
    strokeLinecap="round"
    stroke="#fff"
    fill="none">
    <polygon points="34.5 40.23 18.5 31.09 18.5 16.91 34.5 7.77 34.5 40.23" />
    <polyline points="19.5 16.5 7.5 16.5 7.5 31.5 19.5 31.5" />
    <path d="M35.08,18.34a5.66,5.66,0,0,1,0,11.32" />
    <path d="M45.77,14.18a11.45,11.45,0,0,1-.22,19.64" />
    <path d="M52.79,11a15.15,15.15,0,0,1-.3,26" />
  </svg>
)
