import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { EmotionometerStickerBase } from 'shared/e-telligence/types'
import { StickerWrapper } from './StickerRow'
import { StickerDragItem, DRAG_TYPE_EMOTIONOMETER_STICKER } from './DraggableSticker'
import { DroppableStickerHighlightWrapper } from './DroppableStickerHighlightWrapper'

export type DroppableStickerAreaProps = {
  onDrop: (dragItem: StickerDragItem, replaceSticker: EmotionometerStickerBase | undefined) => void
  onSelect?: () => void
  highlight?: boolean
  sticker?: EmotionometerStickerBase
  disabled?: boolean
  updateDragItemSize: (size: number) => void
}

export const DroppableStickerArea: React.FC<DroppableStickerAreaProps> = ({
  onDrop,
  highlight,
  disabled,
  children,
  sticker,
  updateDragItemSize,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ itemIsOver, itemBeingDragged }, drop] = useDrop({
    accept: DRAG_TYPE_EMOTIONOMETER_STICKER,
    drop: (item, monitor) => {
      onDrop(item as StickerDragItem, sticker)
    },
    canDrop: (item, monitor) => {
      return !disabled && (!sticker || (item as StickerDragItem).sticker.id !== sticker.id)
    },
    collect: monitor => ({
      itemIsOver: monitor.isOver(),
      itemBeingDragged: monitor.canDrop(),
    }),
  })
  const [{ isDragging }, drag] = useDrag({
    canDrag: () => !!sticker,
    item: { type: DRAG_TYPE_EMOTIONOMETER_STICKER, from: 'emotionometer', sticker } as StickerDragItem,
    begin: monitor => {
      if (ref.current) updateDragItemSize(ref.current.getBoundingClientRect().width)
    },
    // end: (dropResult, monitor) => {
    //   console.log('END', monitor.getDropResult(), monitor.canDrag(), monitor.didDrop(), dropResult)
    // },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })
  drag(drop(ref))

  return (
    <StickerWrapper ref={ref}>
      <DroppableStickerHighlightWrapper
        ref={drop}
        style={itemBeingDragged ? { zIndex: 99 } : {}}
        {...{ disabled, itemIsOver, itemBeingDragged, highlight }}
        disabled={disabled || isDragging}>
        {children}
      </DroppableStickerHighlightWrapper>
    </StickerWrapper>
  )
}
