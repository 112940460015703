import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

import { IntroTemplate } from 'home/IntroTemplate'
import { Button, P, Spacer, CollapseMargin } from 'common/ui'
import { isProdEnv } from 'utils/envUtils'
import { AnchorButton } from 'common/LinkButton'

export class ErrorBoundary extends Component<{ basic?: boolean; forceError?: boolean }> {
  state = { eventId: undefined, hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (isProdEnv()) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render() {
    if (this.state.hasError || this.props.forceError) {
      return !this.props.basic ? (
        <ErrorBoundary basic>
          <IntroTemplate title="Oh snap!">
            <CollapseMargin>
              <P>
                Something didn't quite go to plan! Reload the page to start again, or send us a top secret message to
                help us solve the problem for future cadets.
              </P>
            </CollapseMargin>
            <Spacer size="l" />
            <Button onClick={() => window.location.reload()}>Reload page</Button>
            <Spacer size="l" />
            <AnchorButton size="s" href={`${process.env.REACT_APP_SST_BASE_URL}/technical-support`} target="_blank">
              Report Feedback
            </AnchorButton>
          </IntroTemplate>
        </ErrorBoundary>
      ) : (
        <div style={{ margin: '1rem' }}>
          <p>
            Something didn't quite go to plan! Reload the page to start again, or send us a top secret message to help
            us solve the problem for future cadets.
          </p>
          <button onClick={() => window.location.reload()}>Reload</button>{' '}
          <button onClick={() => window.open(`${process.env.REACT_APP_SST_BASE_URL}/technical-support`)}>
            Report Feedback
          </button>
        </div>
      )
    }

    return this.props.children
  }
}
