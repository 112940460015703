import React, { useState, useContext, useRef, useEffect } from 'react'
import { useSessionStorage, useLocalStorage } from 'utils/useStorage'
import { GadgetIndex } from 'types'
import { isDevEnv } from 'utils/envUtils'

type PdaScreen = 'ardon' | 'image' | 'visualiser' | 'text-input'
type GlobalState = ReturnType<typeof useProviderGlobalState>

interface ShortcutButton {
  label: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  pressed?: boolean
}

const initTime = Date.now()

export const getSessionDuration = () => Date.now() - initTime

function useProviderGlobalState() {
  const [debug] = useState<Boolean>(() => {
    try {
      return isDevEnv() || window.location.host === 'dev.sas' || Boolean(window.localStorage.getItem('debug'))
    } catch (e) {
      return false
    }
  })
  const [pdaScreen, setPdaScreen] = useState<PdaScreen>('ardon')
  const [pdaVisible, setPdaVisible] = useState(false)
  const [pdaImage, setPdaImage] = useState<null | string>(null)
  const [pdaTextInputValue, setPdaTextInputValue] = useState<string>('')
  const [pdaTextInputLabel, setPdaTextInputLabel] = useState<string>('Enter Response')
  const [pdaTextInputError, setPdaTextInputError] = useState<boolean>(false)
  const [pdaWarning, setPdaWarning] = useState<null | string>(null)
  const [helpAvailable, setHelpAvailable] = useState(false)
  const [muted, setMuted] = useSessionStorage('muted', false)
  const [sfxMuted, setSfxMuted] = useSessionStorage('sfxMuted', false)
  const [helpVisible, setHelpVisible] = useState(false)
  const [helpHintActive, setHelpHintActive] = useState(false)
  const [inventoryVisible, setInventoryVisible] = useState(false)
  const [activeGadgetIndex, setActiveGadgetIndex] = useState(GadgetIndex.None)
  const [inventoryGadgetToActivate, setInventoryGadgetToActivate] = useState(GadgetIndex.None)
  const [gadgetHintActive, setGadgetHintActive] = useState(false)
  const [playbackRate, setPlaybackRate] = useSessionStorage('playbackRate', 1)
  const [ardonSpeaking, setArdonSpeaking] = useState(false)
  const [visualiserActive, setVisualiserActive] = useState(false)
  const [repeatAvailable, setRepeatAvailable] = useState(false)
  const [repeatRequested, setRepeatRequested] = useState(false)
  const [themeIndex, setThemeIndex] = useLocalStorage('themeIndex', 0)
  const [settingsVisible, setSettingsVisible] = useState(false)
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [shortcutButtons, setShortcutButtons] = useState<Array<React.MutableRefObject<ShortcutButton[]>>>([])
  const [exitUrl, setExitUrl] = useSessionStorage('exitUrl', '')
  const [orientationLock, setOrientationLock] = useState(true)
  const [portraitWidthTrigger, setPortraitWidthTrigger] = useState<number>(1024)

  return {
    debug,
    pdaVisible,
    setPdaVisible,
    pdaScreen,
    setPdaScreen,
    pdaImage,
    setPdaImage,
    pdaTextInputValue,
    setPdaTextInputValue,
    pdaTextInputLabel,
    setPdaTextInputLabel,
    pdaTextInputError,
    setPdaTextInputError,
    pdaWarning,
    setPdaWarning,
    helpVisible,
    setHelpVisible,
    helpAvailable,
    setHelpAvailable,
    helpHintActive,
    setHelpHintActive,
    activeGadgetIndex,
    setActiveGadgetIndex,
    gadgetHintActive,
    setGadgetHintActive,
    inventoryVisible,
    setInventoryVisible,
    inventoryGadgetToActivate,
    setInventoryGadgetToActivate,
    muted,
    setMuted,
    sfxMuted,
    setSfxMuted,
    playbackRate,
    setPlaybackRate,
    ardonSpeaking,
    setArdonSpeaking,
    visualiserActive,
    setVisualiserActive,
    repeatAvailable,
    setRepeatAvailable,
    repeatRequested,
    setRepeatRequested,
    themeIndex,
    setThemeIndex,
    settingsVisible,
    setSettingsVisible,
    loadingPercent,
    setLoadingPercent,
    shortcutButtons,
    setShortcutButtons,
    exitUrl,
    setExitUrl,
    orientationLock,
    setOrientationLock,
    portraitWidthTrigger,
    setPortraitWidthTrigger,
  }
}

function noop(): any {}

export const GlobalStateContext = React.createContext<GlobalState>({
  // These values will be ignored in our app.
  // They exist only to appease the TypeScriptGods™.
  debug: true,
  pdaVisible: false,
  setPdaVisible: noop,
  pdaScreen: 'ardon',
  setPdaScreen: noop,
  pdaImage: null,
  setPdaImage: noop,
  pdaTextInputValue: '',
  setPdaTextInputValue: noop,
  pdaTextInputLabel: 'Enter Response',
  setPdaTextInputLabel: noop,
  pdaTextInputError: false,
  setPdaTextInputError: noop,
  pdaWarning: null,
  setPdaWarning: noop,
  helpVisible: false,
  setHelpVisible: noop,
  helpAvailable: false,
  setHelpAvailable: noop,
  helpHintActive: false,
  setHelpHintActive: noop,
  activeGadgetIndex: -1,
  setActiveGadgetIndex: noop,
  gadgetHintActive: false,
  setGadgetHintActive: noop,
  inventoryVisible: false,
  setInventoryVisible: noop,
  inventoryGadgetToActivate: -1,
  setInventoryGadgetToActivate: noop,
  muted: false,
  setMuted: noop,
  sfxMuted: false,
  setSfxMuted: noop,
  playbackRate: 1,
  setPlaybackRate: noop,
  ardonSpeaking: false,
  setArdonSpeaking: noop,
  visualiserActive: false,
  setVisualiserActive: noop,
  repeatAvailable: false,
  setRepeatAvailable: noop,
  repeatRequested: false,
  setRepeatRequested: noop,
  themeIndex: 0,
  setThemeIndex: noop,
  settingsVisible: false,
  setSettingsVisible: noop,
  loadingPercent: 0,
  setLoadingPercent: noop,
  shortcutButtons: [],
  setShortcutButtons: noop,
  exitUrl: '',
  setExitUrl: noop,
  orientationLock: true,
  setOrientationLock: noop,
  portraitWidthTrigger: 1024,
  setPortraitWidthTrigger: noop,
})

export const GlobalStateProvider: React.FC = ({ children }) => {
  const state = useProviderGlobalState()
  return <GlobalStateContext.Provider value={state}>{children}</GlobalStateContext.Provider>
}

export function useGlobalState() {
  return useContext(GlobalStateContext)
}

export function useShortcutButtons(buttons: ShortcutButton[]) {
  const { setShortcutButtons } = useGlobalState()
  const ref = useRef(buttons)
  ref.current = buttons
  useEffect(() => {
    setShortcutButtons(b => (b.indexOf(ref) === -1 ? b.concat([ref]) : b))
    return () => {
      setShortcutButtons(b => b.filter(r => r !== ref))
    }
  }, [setShortcutButtons])
}
