import React, { Fragment, ReactNode, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import {
  SupplementarySlide,
  SupplementarySlideType,
  InputValueContext,
  SavedInputValue,
  EmbeddedActivitySection,
} from 'shared/session/types'

import { Panel, Padding, H2, Button, IconButton, Row, Column, Spacer } from 'common/ui'
import { FlexModal } from 'app/FlexModal'
import { Field } from 'common/Field'
import { SelectField, TextInputField, RadioButtonGroupField } from 'common/FieldInputs'
import { InlineCheckboxGroup } from 'common/CheckboxGroup'
import { InlineRadioGroup } from 'common/RadioGroup'

import { DELETE_SAVED_INPUT_VALUE } from 'shared/session/actionTypes'
import { deleteSessionInputValue } from 'api'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { useUserState } from 'app/UserState'
import { useSessionState } from './SessionState'
import { useUserInputState } from './UserInputState'
import { useInputInterface } from './InputContext'

interface EditSupplementarySlideModalProps {
  slide: SupplementarySlide
  isNew: boolean
  onClose: () => void
  onSave: (slide: SupplementarySlide) => void
  onDelete: (slide: SupplementarySlide) => void
  onShare: (slide: SupplementarySlide) => void
}

interface SlideOption {
  value: SupplementarySlideType
  label: string
}

interface ParticipantOption {
  value: string
  label: string
}

export const slideLabelMap: { [key in SupplementarySlideType]: string } = {
  holding: 'Holding',
  blank: 'Blank',
  text: 'Text',
  draw: 'Draw',
  'text-draw': 'Text & Draw',
  'stress-ball': 'Stress Ball',
  'turn-taking': 'Turn Taking',
  emotionometer: 'Emotionometer',
  'club-rules': 'Club Rules',
  'friendship-award': 'Friendship Award',
  'extension-topic': 'Extension Topic',
  'custom-code-card': 'Make custom Code Card',
  'custom-relaxation-gadget': 'Make custom Relaxation Gadget',
  end: 'Session End',
}

export const slideOptions: SlideOption[] = [
  { label: '', value: 'blank' },
  { label: '', value: 'text' },
  { label: '', value: 'draw' },
  { label: '', value: 'text-draw' },
  { label: '', value: 'stress-ball' },
  { label: '', value: 'turn-taking' },
  { label: '', value: 'emotionometer' },
  { label: '', value: 'club-rules' },
  { label: '', value: 'friendship-award' },
  { label: '', value: 'extension-topic' },
  { label: '', value: 'custom-code-card' },
  { label: '', value: 'custom-relaxation-gadget' },
].map(option => ({ ...option, label: slideLabelMap[option.value as SupplementarySlideType] } as SlideOption))

export const defaultTitle: SupplementarySlide['title'] = 'Supplementary Slide'
export const getInitialSupplementarySlideData = (
  order: number,
  slideType?: SupplementarySlideType
): SupplementarySlide => ({
  type: null,
  title: slideType ? slideLabelMap[slideType] : defaultTitle,
  uid: 'slide' + Date.now(),
  order,
  participantUids: [],
})

type UpdateKeyValueFunction = <K extends keyof SupplementarySlide>(key: K, value: SupplementarySlide[K]) => void

export const EditSupplementarySlideModal: React.FC<EditSupplementarySlideModalProps> = ({
  slide,
  isNew,
  onClose,
  onSave,
  onDelete,
  onShare,
}) => {
  // const
  const { getSavedInputValue, dispatch: inputStateDispatch } = useUserInputState()
  const { accessToken } = useUserState()
  const { state, getBaseAction, pastMode } = useSessionState()
  const [waitingForSave, setWaitingForSave] = useState<boolean>(false)
  const [waitingForShare, setWaitingForShare] = useState<boolean>(false)

  const inputContext: Partial<InputValueContext> = {
    participant_uid: 'shared',
    owner: 'supplementary_slides',
    owner_id: null,
    name: slide.uid,
  }
  const savedItem = getSavedInputValue('shared', inputContext) as SavedInputValue<SupplementarySlide> | null
  const [savedValue, saveValue] = useInputInterface<SupplementarySlide>({
    name: slide.uid,
    contextOverride: inputContext,
    defaultValue: {
      ...slide,
      participantUids: state.participants
        .filter(participant => participant.currentSlideUid === slide.uid)
        .map(participant => participant.profile.uid),
    },
    socketDebounce: 0,
    databaseDebounce: 500,
  })

  const [valueObj, setValueObj] = useState<SupplementarySlide>(() => savedValue)

  useEffect(() => {
    console.log('received new saved value', savedItem)
    if (waitingForSave && savedItem) {
      setWaitingForSave(false)
      onSave(savedItem.value)
    }
    if (waitingForShare && savedItem) {
      setWaitingForShare(false)
      onShare(savedItem.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedItem ? savedItem.revision : null])

  const handleSave = () => {
    setWaitingForSave(true)
    saveValue(valueObj)
  }

  // TODO: This should only modify cadets not save content
  const handleShare = () => {
    setWaitingForShare(true)
    saveValue(valueObj)
  }

  const updateValue: UpdateKeyValueFunction = (key, value) => {
    ReactDOM.unstable_batchedUpdates(() => {
      if (waitingForSave) setWaitingForSave(false)
      if (waitingForShare) setWaitingForShare(false)
      setValueObj({ ...valueObj, [key]: value })
    })
  }

  const handleTypeChange = (type: typeof valueObj['type']) => {
    if (
      type &&
      (!valueObj.type ||
        (valueObj.type && valueObj.title === slideLabelMap[valueObj.type]) ||
        valueObj.title === defaultTitle)
    ) {
      setValueObj({ ...valueObj, type, title: slideLabelMap[type] })
    } else {
      updateValue('type', type || null)
    }
  }

  const handleDelete = () => {
    if (!savedItem) return
    deleteSessionInputValue(savedItem.id, accessToken)
      .then(() => {
        inputStateDispatch({
          ...getBaseAction(),
          type: DELETE_SAVED_INPUT_VALUE,
          id: savedItem.id,
          participant_uid: savedItem.participant_uid,
        })
        onDelete(valueObj)
      })
      .catch(e => {
        console.log('error deleting input value', e)
      })
  }

  const participantOptions = state.participants.map(({ profile }) => ({
    value: profile.uid,
    label: profile.displayName,
  }))

  const validated: boolean = valueObj.type !== null
  const allParticipantsSelected = valueObj.participantUids.length === state.participants.length

  return (
    <FlexModal
      isOpen
      shouldCloseOnEsc={!waitingForSave || !waitingForShare}
      shouldCloseOnOverlayClick={!waitingForSave || !waitingForShare}
      style={{ overlay: { background: 'rgba(1, 26, 70, .8)' } }}>
      <ModalContainer>
        {!pastMode && (
          <>
            <Panel padding="m">
              <Row alignItems="center">
                <Column flex="1 1 50%">
                  <H2 style={{ color: '#2EADF0' }}>{isNew ? 'Select slide type:' : 'Slide type:'}</H2>
                </Column>
                <Column flex="1 1 50%">
                  <Field>
                    <SelectField<SupplementarySlideType | '', SlideOption>
                      empty="Select option..."
                      options={slideOptions}
                      value={valueObj.type || ''}
                      onChange={value => handleTypeChange(value || null)}
                    />
                  </Field>
                </Column>
              </Row>
            </Panel>
            {valueObj.type !== null && (
              <SupplementarySlideFields
                type={valueObj.type}
                value={valueObj}
                updateValue={updateValue}
                participantOptions={participantOptions}
              />
            )}
            <Row>
              <Column flex="none">
                <Button
                  size="m"
                  theme="purple"
                  disabled={!validated || waitingForSave}
                  onClick={handleSave}
                  children={waitingForSave ? 'Saving...' : 'Save'}
                  marginTop={10}
                />
              </Column>
              <Column flex="1 1 auto" />
              <Column flex="none">
                <Button
                  size="s"
                  theme={isNew ? undefined : 'red'}
                  disabled={waitingForSave}
                  onClick={
                    isNew
                      ? onClose
                      : () => window.confirm(`Are you sure you want to delete this slide?`) && handleDelete()
                  }
                  children={isNew ? 'Cancel' : 'Delete'}
                  marginTop={10}
                />
              </Column>
            </Row>
          </>
        )}
        {valueObj.type !== null && state.participants.length > 0 && (
          <>
            <Spacer size="s" />
            <Panel>
              <Padding size="m">
                <H2 style={{ color: '#2EADF0', marginBottom: 10 }}>
                  {isNew ? 'Select who moves to this slide' : 'Change who is on this slide'}
                </H2>
                <Field>
                  <InlineCheckboxGroup<ParticipantOption>
                    name="participants"
                    size="s"
                    spacerSize="s"
                    value={valueObj.participantUids}
                    options={participantOptions}
                    onChange={value => updateValue('participantUids', value)}
                  />
                  <div>
                    <Button
                      size="xs"
                      children={allParticipantsSelected ? 'Deselect all' : 'Select all'}
                      onClick={() =>
                        updateValue(
                          'participantUids',
                          allParticipantsSelected ? [] : state.participants.map(({ profile }) => profile.uid)
                        )
                      }
                    />
                  </div>
                </Field>
              </Padding>
            </Panel>
            <Row>
              <Button
                size="m"
                theme="purple"
                disabled={!validated || waitingForShare}
                onClick={handleShare}
                children={waitingForShare ? 'Sharing...' : 'Share'}
                marginTop={10}
              />
            </Row>
          </>
        )}
        <CloseButton onClick={onClose} disabled={waitingForSave || waitingForShare} />
      </ModalContainer>
    </FlexModal>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const panelThemeOptions: {
  value: Exclude<SupplementarySlide['panelTheme'], undefined | null>
  label: string
}[] = [
  { value: 'blue', label: 'Blue' },
  { value: 'purple', label: 'Purple' },
  { value: 'parentGreen', label: 'Green' },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const themeOptions: {
  value: Exclude<SupplementarySlide['theme'], undefined>
  label: string
}[] = [
  { value: 'blue', label: 'Blue' },
  { value: 'dark', label: 'Dark' },
  { value: 'purple', label: 'Purple' },
  { value: 'parentGreen', label: 'Green' },
]

const emotionometerThemeOptions: {
  value: Exclude<SupplementarySlide['emotionometerTheme'], undefined>
  label: string
}[] = [
  { value: 'blue', label: 'Sad (Blue)' },
  { value: 'purple', label: 'Anxiety (Purple)' },
  { value: 'white', label: 'Blank (White)' },
  { value: 'red', label: 'Anger (Red)' },
  { value: 'green', label: 'Relaxed (Green)' },
  { value: 'yellow', label: 'Happy (Yellow)' },
]

const extensionTopicTypeOptions: {
  value: Exclude<SupplementarySlide['extensionTopicType'], undefined>
  label: string
}[] = [
  { value: 'negotiation-tactics', label: 'Top 10 negotiation tactics' },
  { value: 'conversation-equations', label: 'Conversation Equations' },
  { value: 'feelings-alerts', label: 'Feelings Alerts' },
  { value: 'play-recovery-tactics', label: 'Play Recovery Tactics' },
]

const SupplementarySlideFields: React.FC<{
  type: SupplementarySlideType
  value: SupplementarySlide
  updateValue: UpdateKeyValueFunction
  participantOptions: {
    value: string
    label: string
  }[]
}> = ({ type, value, updateValue, participantOptions }) => {
  let fieldNodes: ReactNode[] = []

  // Title field
  if ((['text', 'draw', 'text-draw', 'turn-taking', 'emotionometer'] as SupplementarySlideType[]).indexOf(type) >= 0)
    fieldNodes.push(
      <Field label="Slide Title">
        <TextInputField
          value={value.title}
          onChange={(value: string | undefined) => updateValue('title', value || '')}
        />
      </Field>
    )

  // Input label
  if (type === 'text' || type === 'text-draw')
    fieldNodes.push(
      <Field label="Instructions:">
        <TextInputField
          value={value.inputLabel || ''}
          onChange={(value: string | undefined) => updateValue('inputLabel', value || '')}
        />
      </Field>
    )

  // Usage i.e. individual / facilitator
  if (type === 'text' || type === 'text-draw' || type === 'custom-code-card' || type === 'custom-relaxation-gadget')
    fieldNodes.push(
      <Field label="Usage Type:">
        <RadioButtonGroupField<
          Exclude<SupplementarySlide['inputUsage'], undefined>,
          { value: Exclude<SupplementarySlide['inputUsage'], undefined>; label: string }
        >
          options={[
            { value: 'individual', label: 'CADET' },
            { value: 'facilitator', label: 'FACILITATOR ONLY' },
          ]}
          value={value.inputUsage || 'individual'}
          onChange={value => updateValue('inputUsage', value)}
        />
      </Field>
    )

  // Text type, i.e. input / textarea
  if (type === 'text' || type === 'text-draw')
    fieldNodes.push(
      <Field label="Text Input Type:">
        <RadioButtonGroupField<
          Exclude<SupplementarySlide['inputType'], undefined>,
          { value: Exclude<SupplementarySlide['inputType'], undefined>; label: string }
        >
          options={[
            { value: 'text', label: 'Text Input' },
            { value: 'text_symbol', label: 'TEXT & SYMBOL' },
            { value: 'textarea', label: 'MULTILINE TEXT' },
          ]}
          value={value.inputType || 'text'}
          onChange={value => updateValue('inputType', value)}
        />
      </Field>
    )

  // Textarea lines
  if ((type === 'text' || type === 'text-draw') && value.inputType === 'textarea')
    fieldNodes.push(
      <Field label="Textarea lines:">
        <TextInputField
          type="number"
          step={1}
          min={1}
          max={30}
          value={value.inputTextareaLines || 3}
          placeholder="Wait & Listen"
          onChange={(value: string) => updateValue('inputTextareaLines', value ? parseInt(value) : 3)}
        />
      </Field>
    )

  // Drawing section usage (not relevant for text-draw supplementary slide)
  if (type === 'draw')
    fieldNodes.push(
      <Field label="Drawing Section Usage:">
        <RadioButtonGroupField<
          Exclude<SupplementarySlide['drawingUsage'], undefined>,
          { value: Exclude<SupplementarySlide['drawingUsage'], undefined>; label: string }
        >
          options={[
            { value: 'individual', label: 'Individual Activity' },
            { value: 'shared', label: 'Shared Activity' },
            // { value: 'facilitator', label: 'Facilitator' },
          ]}
          value={value.drawingUsage || 'shared'}
          onChange={value => updateValue('drawingUsage', value)}
        />
      </Field>
    )

  // Emotionometer usage type
  if (type === 'emotionometer')
    fieldNodes.push(
      <>
        <Field label="Usage Type:">
          <RadioButtonGroupField<
            Exclude<SupplementarySlide['emotionometerUsage'], undefined>,
            { value: Exclude<SupplementarySlide['emotionometerUsage'], undefined>; label: string }
          >
            options={[
              { value: 'individual', label: 'CADET' },
              { value: 'facilitator', label: 'FACILITATOR ONLY' },
            ]}
            value={value.emotionometerUsage || 'individual'}
            onChange={value => updateValue('emotionometerUsage', value)}
          />
        </Field>
        <Spacer size="s" />
        <Field label="Emotionometer Theme:">
          <SelectField<
            Exclude<SupplementarySlide['emotionometerTheme'], undefined | null> | '',
            typeof emotionometerThemeOptions[number]
          >
            options={emotionometerThemeOptions}
            value={value.emotionometerTheme || 'blue'}
            onChange={value => updateValue('emotionometerTheme', value || 'blue')}
          />
        </Field>
      </>
    )

  // Turn taking section inputs
  if (type === 'turn-taking')
    fieldNodes.push(
      <>
        <Field label="Turn Taking Type:">
          <RadioButtonGroupField<
            Exclude<SupplementarySlide['turnTakingType'], undefined>,
            { value: Exclude<SupplementarySlide['turnTakingType'], undefined>; label: string }
          >
            options={[
              { value: 'ball', label: 'Ball' },
              { value: 'clapper', label: 'Film Clapper' },
              { value: 'star', label: 'Wand' },
            ]}
            value={value.turnTakingType || 'ball'}
            onChange={value => updateValue('turnTakingType', value || 'ball')}
          />
        </Field>
        <Row marginTop={10}>
          <Column flex="1 1 auto">
            <Field label="Wait Text:">
              <TextInputField
                value={value.turnTakingWaitText || ''}
                placeholder="Wait & Listen"
                onChange={(value: string | undefined) => updateValue('turnTakingWaitText', value || '')}
              />
            </Field>
          </Column>
          <Spacer size="m" flex="none" />
          <Column flex="1 1 auto">
            <Field label="Prepare Text:">
              <TextInputField
                value={value.turnTakingPrepareText || ''}
                placeholder="Get Ready"
                onChange={(value: string | undefined) => updateValue('turnTakingPrepareText', value || '')}
              />
            </Field>
          </Column>
          <Spacer size="m" flex="none" />
          <Column flex="1 1 auto">
            <Field label="Active Text:">
              <TextInputField
                value={value.turnTakingActiveText || ''}
                placeholder="Your Turn"
                onChange={(value: string | undefined) => updateValue('turnTakingActiveText', value || '')}
              />
            </Field>
          </Column>
        </Row>
      </>
    )

  if (type === 'extension-topic')
    fieldNodes.push(
      <>
        <Field label="Extension Topic:">
          <SelectField<
            Exclude<SupplementarySlide['extensionTopicType'], undefined | null> | '',
            typeof extensionTopicTypeOptions[number]
          >
            options={extensionTopicTypeOptions}
            value={value.extensionTopicType || ''}
            onChange={value => updateValue('extensionTopicType', value || 'negotiation-tactics')}
          />
        </Field>
      </>
    )

  if (type === 'friendship-award') {
    const winnerSelection = participantOptions.map(participant => ({ ...participant, value: participant.label }))

    fieldNodes.push(
      <>
        <Field label="Award Recipient:">
          <InlineRadioGroup<ParticipantOption>
            name="participants"
            size="s"
            spacerSize="s"
            value={value.friendshipWinner}
            options={winnerSelection}
            onChange={value => updateValue('friendshipWinner', value)}
          />
        </Field>
      </>
    )
  }

  if (type === 'stress-ball') {
    fieldNodes.push(
      <Field label="Ball Mode:">
        <RadioButtonGroupField<
          EmbeddedActivitySection['stress_ball_mode'],
          { value: Exclude<EmbeddedActivitySection['stress_ball_mode'], null>; label: string }
        >
          options={[
            { value: 'breathe', label: 'Breathe' },
            { value: 'stress', label: 'Stress' },
          ]}
          value={value.stressBallMode || 'breathe'}
          onChange={value => updateValue('stressBallMode', value || 'breathe')}
        />
      </Field>
    )
  }

  return (
    <>
      {fieldNodes.length > 0 && (
        <>
          <Spacer size="s" />
          <Panel padding="m">
            <H2 style={{ color: '#2EADF0', marginBottom: 10 }}>Slide Options</H2>
            {intersperseSpacers(
              fieldNodes.map((node, i) => <Fragment key={i}>{node}</Fragment>),
              'm'
            )}
          </Panel>
        </>
      )}
    </>
  )
}

const CloseButton: React.FC<{ onClick?: () => void; disabled?: boolean }> = ({ onClick, disabled }) => {
  return (
    <CloseButtonDiv onClick={onClick}>
      <IconButton theme="purple" children="×" disabled={disabled} />
    </CloseButtonDiv>
  )
}
const CloseButtonDiv = styled.div`
  & ${IconButton} {
    margin-left: 10px;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    pointer-events: none;
    &::before,
    &::after {
      content: none;
    }
  }
`

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;

  & ${CloseButtonDiv} {
    position: absolute;
    top: -50px;
    right: 0;
  }
`
