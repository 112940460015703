/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { ThemeProvider } from 'styled-components'
import { Transition } from 'react-spring/renderprops'
import { Switch, Route, useHistory, useLocation, matchPath } from 'react-router-dom'
import { blue } from 'themes'

import { SkillTrackerStateProvider, useSkillTrackerState, verifyState, RoleState } from './SkillTrackerState'
import { PushNotificationProvider, usePushNotification } from './PushNotificationContext'
import { UPDATE_ROLE_STATE_VALUES, UpdateRoleStateValuesAction } from './actionTypes'

import {
  AppBackground,
  Panel,
  Row,
  Column,
  Padding,
  H1,
  P,
  Button,
  H3,
  Spacer,
  Hr,
  RowWrapPortrait,
  H2,
} from 'common/ui'

import { Navigation, baseUrl } from './common/Navigation'
import { RouteTransition } from './common/SkillTrackerPanel'
import { MobileMessageModal, MobileModalPanel, MODAL_FADE_OUT_TIME } from '../app/MobileMessageModal'
import { ModuleScreen } from './ModuleScreen'
import { SkillsScreen } from './SkillsScreen'
import { RewardsScreen } from './RewardsScreen'
import { SkillsRewardsScreen } from './SkillsRewardsScreen'
import { ProgressScreen } from './ProgressScreen'
import { PractiseScreen } from './PractiseScreen'
import { OfflineNotification } from './OfflineNotification'
import { AutoTallyTimeModal } from './modals/TallyTimeModal'
import { AutoBonusRewardUnlockedModal } from './modals/BonusRewardUnlockedModal'

import { useUserState } from 'app/UserState'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useIOS100vh } from 'utils/useIOS100vhFix'
import { SettingsScreen } from './SettingsScreen'

export type HomeScreen = 'welcome' | 'intro' | 'permissions' | 'home'
const homeScreens: HomeScreen[] = ['welcome', 'intro', 'permissions', 'home']

export const skipIntroKey = 'ST_skipIntro'

const transitionRoutes = [baseUrl + '/skills-rewards', baseUrl + '/practise', baseUrl + '/module']

export const SkillTracker: React.FC<{}> = () => {
  useIOS100vh()
  useDisableOrientationLock()
  return (
    <SkillTrackerTheme>
      <SkillTrackerStateProvider>
        <PushNotificationProvider>
          <DevTools />
          <AppBackground>
            <SkillTrackerLoadingScreen>
              <SkillTrackerInner />
            </SkillTrackerLoadingScreen>
          </AppBackground>
          <OfflineNotification />
        </PushNotificationProvider>
      </SkillTrackerStateProvider>
    </SkillTrackerTheme>
  )
}

const SkillTrackerInner: React.FC<{}> = () => {
  const history = useHistory()
  const location = useLocation()
  const { getUserScopes, getBaseAction, enableSkillTracker } = useUserState()
  const { roleState, dispatch } = useSkillTrackerState()

  const isAdult = getUserScopes().indexOf('mentor') >= 0
  const { screen, skipIntro, skipRewardsScreen, firstModuleActivated } = roleState

  const updateRoleState = (data: Partial<RoleState>) => {
    const action: UpdateRoleStateValuesAction = {
      ...getBaseAction(),
      type: UPDATE_ROLE_STATE_VALUES,
      role: isAdult ? 'mentor' : 'agent',
      data,
    }
    dispatch(action)
  }

  useEffect(() => {
    if (!enableSkillTracker) {
      alert('Skill Tracker is not available for this user.')
      history.push('/game')
      return
    }

    if (homeScreens.indexOf(screen) < 0) {
      console.log(`last stored screen doesn't exist: ${screen} -- redirecting to welcome screen`)
      updateRoleState({ screen: homeScreens[0] })
    }

    const isOnRootPath = !!matchPath(location.pathname, { path: baseUrl, exact: true })
    if (isOnRootPath) {
      if (skipIntro) {
        if (isAdult) {
          if (!skipRewardsScreen) history.push(baseUrl + '/rewards')
          else if (!firstModuleActivated) history.push(baseUrl + '/module')
          else history.push(baseUrl + '/practise')
        } else {
          history.push(baseUrl + '/practise')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isOnSettingsScreen = !!matchPath(location.pathname, { path: baseUrl + '/settings', exact: true })
  const isOnModuleEditScreen = isAdult && !!matchPath(location.pathname, { path: baseUrl + '/module', exact: true })
  const isOnRewardsEditScreen = isAdult && !!matchPath(location.pathname, { path: baseUrl + '/rewards', exact: true })
  const popupsAllowedOnRoute =
    screen === 'home' && !isOnSettingsScreen && !isOnModuleEditScreen && !isOnRewardsEditScreen

  const WelcomeModal = isAdult ? AdultWelcomeModal : AgentWelcomeModal
  const IntroModal = isAdult ? AdultIntroModal : AgentIntroModal

  return (
    <>
      <Padding size="s" style={{ overflow: 'hidden' }}>
        <Column flex style={{ perspective: '1200px' }}>
          <Route
            render={({ location: newLocation, ...rest }) => (
              <Transition
                native
                items={newLocation}
                keys={newLocation.pathname /*transitionRoutes.indexOf(newLocation.pathname)*/}
                config={{ tension: 350, friction: 30 }}
                from={{ transform: 'rotateY(-180deg)', opacity: 0 }}
                enter={{ transform: 'rotateY(0deg)', opacity: 1 }}
                leave={{ transform: 'rotateY(180deg)', opacity: 0 }}>
                {(loc, state) => style => (
                  <Switch location={state === 'update' ? location : loc}>
                    <Route path={baseUrl} exact>
                      <WelcomeModal
                        isOpen={screen === 'welcome'}
                        onComplete={() => updateRoleState({ screen: 'intro' })}
                      />
                      <IntroModal
                        isOpen={screen === 'intro'}
                        onComplete={() => {
                          updateRoleState({ screen: 'home', skipIntro: true })
                          history.push(baseUrl + '/settings')
                        }}
                      />
                    </Route>
                    <Route
                      path={`${baseUrl}/settings`}
                      exact
                      render={props => RouteTransition({ ...props, style, Component: SettingsScreen })}
                    />
                    <Route
                      path={`${baseUrl}/skills-rewards`}
                      exact
                      render={props => RouteTransition({ ...props, style, Component: SkillsRewardsScreen })}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/rewards`}
                      render={props => RouteTransition({ ...props, style, Component: RewardsScreen })}
                    />
                    {isAdult && (
                      <Route
                        path={`${baseUrl}/module`}
                        render={props => RouteTransition({ ...props, style, Component: ModuleScreen })}
                      />
                    )}
                    {isAdult && (
                      <Route
                        exact
                        path={`${baseUrl}/skills`}
                        render={props => RouteTransition({ ...props, style, Component: SkillsScreen })}
                      />
                    )}
                    <Route
                      exact
                      path={`${baseUrl}/progress`}
                      render={props => RouteTransition({ ...props, style, Component: ProgressScreen })}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/practise`}
                      render={props => RouteTransition({ ...props, style, Component: PractiseScreen })}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/practise/:moduleId`}
                      render={props => RouteTransition({ ...props, style, Component: PractiseScreen })}
                    />
                  </Switch>
                )}
              </Transition>
            )}
          />
        </Column>
      </Padding>
      {popupsAllowedOnRoute && <AutoTallyTimeModal />}
      {popupsAllowedOnRoute && <AutoBonusRewardUnlockedModal />}
    </>
  )
}

interface MessageModalProps {
  isOpen: boolean
  onComplete: () => void
}

const SkillTrackerLoadingScreen: React.FC = ({ children }) => {
  const { loadingInitialState } = useSkillTrackerState()
  if (loadingInitialState)
    return (
      <Column flex alignItems="center" justifyContent="center">
        <div style={{ padding: 15, background: 'white' }}>One moment please...</div>
      </Column>
    )
  return <>{children}</>
}

const SkillTrackerTheme: React.FC = ({ children }) => {
  const { getUserScopes } = useUserState()
  const isMentor = getUserScopes().indexOf('agent') === -1
  if (isMentor) {
    return <ThemeProvider theme={blue}>{children}</ThemeProvider>
  }
  return <>{children}</>
}

const DevTools: React.FC = () => {
  const { setAccessToken } = useUserState()
  const { state, actions } = useSkillTrackerState()
  const { sendNotification, subscribe } = usePushNotification()

    // set some overrides
  ;(window as any).makeAdult = () =>
    setAccessToken(
      'test.' + btoa(JSON.stringify({ aud: '', jti: '', iat: 0, nbf: 0, exp: 0, sub: '', scopes: ['adult'] })) + '.test'
    )
  ;(window as any).makeAgent = () =>
    setAccessToken(
      'test.' + btoa(JSON.stringify({ aud: '', jti: '', iat: 0, nbf: 0, exp: 0, sub: '', scopes: ['agent'] })) + '.test'
    )
  ;(window as any).verifyState = () => verifyState(state, actions)
  ;(window as any).sendNotification = sendNotification
  ;(window as any).subscribe = subscribe
  return null
}

const AgentWelcomeModal: React.FC<MessageModalProps> = ({ isOpen, onComplete }) => {
  const { profileName } = useUserState()
  const [realIsOpen, setRealIsOpen] = useState<boolean>(isOpen)
  const delayedOnComplete = () => {
    setRealIsOpen(false)
    setTimeout(onComplete, MODAL_FADE_OUT_TIME)
  }
  return (
    <MobileModalPanel isOpen={realIsOpen} panelStyle={{ overflow: 'visible', width: '75vw', maxWidth: 640 }}>
      <Padding size="m" style={{ paddingTop: 25, paddingBottom: 25 }}>
        <div style={{ width: '75%', margin: '0 auto', textAlign: 'center' }}>
          <H2 style={{ textAlign: 'center' }}>
            Welcome to your Skill Tracker agent {profileName.replace(/agent/i, '')}
          </H2>
          <P style={{ textAlign: 'center' }}>Here you and your mentors can log your Secret Agent skills.</P>
          <Button onClick={() => delayedOnComplete()}>Continue</Button>
        </div>
      </Padding>
      <img
        alt=""
        src={require('./assets/welcome-agent.png')}
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          left: '100%',
          top: 0,
          width: '20vw',
          maxWidth: 300,
          transform: `translate(-40%,-50px)`,
        }}
      />
    </MobileModalPanel>
  )
}

const AdultWelcomeModal: React.FC<MessageModalProps> = ({ isOpen, onComplete }) => {
  // const { profileName } = useUserState()
  const [realIsOpen, setRealIsOpen] = useState<boolean>(isOpen)
  const delayedOnComplete = () => {
    setRealIsOpen(false)
    setTimeout(onComplete, MODAL_FADE_OUT_TIME)
  }
  return (
    <MobileMessageModal isOpen={realIsOpen}>
      <H1 style={{ textAlign: 'center' }}>Welcome to the Skill Tracker</H1>
      <P style={{ textAlign: 'center' }}>
        Here you can monitor and motivate your SAS Cadet to practise their new skills.
      </P>
      <Button onClick={() => delayedOnComplete()}>Continue</Button>
    </MobileMessageModal>
  )
}

const agentIntroSteps = [
  {
    title: 'Step 1',
    image: require('./assets/agent-intro-01.png'),
    text: `To remember what you need to practise each day, look at your SAS target skills.`,
  },
  {
    title: 'Step 2',
    image: require('./assets/agent-intro-02.png'),
    text: `View your symbols to see how many times you need to practise your SAS skills. You will be rewarded for filling in all the symbols.`,
  },
  {
    title: 'Step 3',
    image: require('./assets/agent-intro-03_1.png'),
    text: `Click on a symbol to enter information about your SAS skills practise.`,
  },
  {
    title: 'Step 4',
    image: require('./assets/agent-intro-03_2.png'),
    text: `Watch the symbol change to a darker colour.`,
  },
  {
    title: 'Step 5',
    image: require('./assets/agent-intro-04.png'),
    text: `Wait for your mentor/s to confirm your symbols. Your symbol will then be colourful. Mentors can add symbols for you too!`,
  },
  {
    title: 'Step 6',
    image: require('./assets/agent-intro-05.png'),
    text: `Be reminded of your Daily Tally Time by adding a calendar reminder.`,
  },
]

const AgentIntroModal: React.FC<MessageModalProps> = ({ isOpen: _isOpen, onComplete }) => {
  const [stepNumber, setStepNumber] = useState<number>(0)
  const step = agentIntroSteps[stepNumber]
  const isLastStep = stepNumber === agentIntroSteps.length - 1
  const [isOpen, setIsOpen] = useState<boolean>(_isOpen)
  const alreadyClosed = useRef<boolean>(false)
  const delayedOnComplete = () => {
    if (alreadyClosed.current) return
    setIsOpen(false)
    alreadyClosed.current = true
    setTimeout(onComplete, MODAL_FADE_OUT_TIME)
  }
  useEffect(() => {
    if (_isOpen) alreadyClosed.current = false
    setIsOpen(_isOpen)
  }, [_isOpen])
  return (
    <MobileMessageModal isOpen={isOpen}>
      <RowWrapPortrait>
        <Column flex="1 1 auto">
          <H1>Instructions</H1>
          <Hr margin="s" />
          <H3>Step {stepNumber + 1}</H3>
          {typeof step.text === 'string' ? <P>{step.text}</P> : step.text}
        </Column>
        <Spacer size="m" />
        <Column flex="0 0 55%" style={{ maxWidth: '100%' }}>
          {step.image && (
            <img alt={`Step ${stepNumber + 1}`} src={step.image} style={{ width: '100%', maxWidth: 402 }} />
          )}
        </Column>
      </RowWrapPortrait>
      <Spacer size="m" />
      <Row justifyContent="center">
        <Row flex="none">
          <Button onClick={() => setStepNumber(stepNumber - 1)} disabled={stepNumber === 0}>
            Back
          </Button>
          <Spacer size="s" />
          <Button onClick={() => (isLastStep ? delayedOnComplete() : setStepNumber(stepNumber + 1))}>
            {isLastStep ? 'Continue' : 'Next'}
          </Button>
        </Row>
      </Row>
    </MobileMessageModal>
  )
}

const adultIntroSteps = [
  {
    title: 'Step 1',
    image: require('./assets/adult-intro-01.png'),
    text: `Create or review the Rewards Menu`,
  },
  {
    title: 'Step 2',
    image: require('./assets/adult-intro-02.png'),
    text: `Select your SAS module type (ask your SAS Facilitator)`,
  },
  {
    title: 'Step 3',
    image: require('./assets/adult-intro-03_1.png'),
    text: `Select your cadet’s current module and enter the requested details.`,
  },
  {
    title: 'Step 3',
    image: require('./assets/adult-intro-03_2.png'),
    text: `Select your cadet’s current module and enter the requested details.`,
  },
  {
    title: 'Step 4',
    image: require('./assets/adult-intro-04_1.png'),
    text: `Check in daily with your cadet. Review and confirm their entries. Add any appropriate bonus credits.`,
  },
  {
    title: 'Step 4',
    image: require('./assets/adult-intro-04_2.png'),
    text: `Check in daily with your cadet. Review and confirm their entries. Add any appropriate bonus credits.`,
  },
  {
    title: 'Step 4',
    image: require('./assets/adult-intro-04_3.png'),
    text: `Check in daily with your cadet. Review and confirm their entries. Add any appropriate bonus credits.`,
  },
  {
    title: 'Step 4',
    image: require('./assets/adult-intro-04_4.png'),
    text: `Check in daily with your cadet. Review and confirm their entries. Add any appropriate bonus credits.`,
  },
  {
    title: 'Step 5',
    image: require('./assets/adult-intro-05.png'),
    text: `Be reminded of your cadet’s Daily Tally Time by adding a calendar reminder.`,
  },
  {
    title: 'Step 6',
    image: require('./assets/adult-intro-06.png'),
    text: `Use the Rewards Menu to help your cadet choose a reward for reaching their daily symbol target!`,
  },
  {
    title: 'Step 7',
    image: require('./assets/adult-intro-07.png'),
    text: `If needed, you can finish a module even if all the days were not completed.`,
  },
]

const AdultIntroModal: React.FC<MessageModalProps> = ({ isOpen: _isOpen, onComplete }) => {
  const [stepNumber, setStepNumber] = useState<number>(0)
  const step = adultIntroSteps[stepNumber]
  const isLastStep = stepNumber === adultIntroSteps.length - 1
  const [isOpen, setIsOpen] = useState<boolean>(_isOpen)
  const alreadyClosed = useRef<boolean>(false)
  const delayedOnComplete = () => {
    if (alreadyClosed.current) return
    setIsOpen(false)
    alreadyClosed.current = true
    setTimeout(onComplete, MODAL_FADE_OUT_TIME)
  }
  useEffect(() => {
    if (_isOpen) alreadyClosed.current = false
    setIsOpen(_isOpen)
  }, [_isOpen])
  return (
    <MobileMessageModal isOpen={isOpen}>
      <RowWrapPortrait>
        <Column flex="1 1 auto">
          <H1>Instructions</H1>
          <Hr margin="s" />
          <H3>{step.title || `Step ${stepNumber + 1}`}</H3>
          {typeof step.text === 'string' ? <P>{step.text}</P> : step.text}
        </Column>
        <Spacer size="m" />
        <Column flex="0 0 55%" style={{ maxWidth: '100%' }}>
          {step.image && (
            <img alt={`Step ${stepNumber + 1}`} src={step.image} style={{ width: '100%', maxWidth: 402 }} />
          )}
        </Column>
      </RowWrapPortrait>
      <Spacer size="m" />
      <Row justifyContent="center">
        <Row flex="none">
          <Button onClick={() => setStepNumber(stepNumber - 1)} disabled={stepNumber === 0}>
            Back
          </Button>
          <Spacer size="s" />
          <Button onClick={() => (isLastStep ? delayedOnComplete() : setStepNumber(stepNumber + 1))}>
            {isLastStep ? 'Continue' : 'Next'}
          </Button>
        </Row>
      </Row>
    </MobileMessageModal>
  )
}

const Home: React.FC = () => {
  return (
    <Panel style={{ height: 'calc(100vh - 20px)', overflow: 'none', borderRadius: 7 }}>
      <Navigation title="Skill Tracker" />
      <Row flex="1 1 auto" style={{ overflow: 'auto' }}>
        <Padding size="xs">Home</Padding>
      </Row>
    </Panel>
  )
}
