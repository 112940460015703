import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import format from 'date-fns/format'
import { fontRegular } from 'fonts'
import { blue, parentGreen, purple, Theme } from 'themes'

import { P } from 'common/ui'

import { useSessionState } from './SessionState'
import { SessionEntity } from 'shared/dashboard/types'
import { getSessionStartDate } from 'dashboards/utils/sessionUtils'

const ThemeOptions: { [key in SessionEntity['type']]: Theme } = {
  facilitator: purple,
  parent: parentGreen,
  cadet: blue,
}

export const SessionDetails: React.FC = () => {
  const { sessionData } = useSessionState()

  if (!sessionData) return null
  const moduleType: SessionEntity['type'] = sessionData.type

  return (
    <ThemeProvider theme={ThemeOptions[moduleType]}>
      <Container>
        <Highlight />
        <ModuleTitle>
          {sessionData.module.type} Meeting - {sessionData.module.title}
        </ModuleTitle>
        <P>
          {sessionData.group?.name} - {format(getSessionStartDate(sessionData) || Date.now(), 'ccc d LLL yyyy, h:mma')}
        </P>
      </Container>
    </ThemeProvider>
  )
}

const Container = styled.div`
  padding: 15px 30px;
  position: relative;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;

  ${P} {
    margin: 0;
    color: #001947 !important;
  }
`

const Highlight = styled.div`
  background: ${p => p.theme.appBackgroundTopColor};
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0;
  top: 0;
`

const ModuleTitle = styled.h2`
  ${fontRegular};
  font-size: 1em;
  text-transform: capitalize;
  color: ${p => p.theme.appBackgroundTopColor};
  margin: 0;
`
