import React from 'react'
import styled from 'styled-components'
import { Row, Column, H1, H2, H3 } from 'common/ui'
import { fontHeavy } from 'fonts'
import { useLocation } from 'react-router-dom'
import { Page } from './Page'

export const DASHBOARD_SIDEBAR_WIDTH = 220
export const DASHBOARD_HEADER_HEIGHT = 65

export const Header = styled(Row)`
  flex: none;
  box-sizing: border-box;
  width: 100%;
  height: ${DASHBOARD_HEADER_HEIGHT}px;
  background-color: #925bed;

  @media print {
    display: none;
  }

  & ${H2} {
    color: #fff;
  }
  & > *:not(:first-child) {
    border-left: 1px solid #ae73f6;
  }
  & > *:not(:last-child) {
    border-right: 1px solid #6737b5;
  }
`

export const HeaderLeft = styled(Column)`
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 15px;
  justify-content: center;
`
export const HeaderRight = styled(Column)`
  flex: none;
  box-sizing: border-box;
  width: ${DASHBOARD_HEADER_HEIGHT}px;
  height: ${DASHBOARD_HEADER_HEIGHT}px;
  justify-content: center;
  align-items: center;
`

export const Main = styled(Column)`
  max-width: calc(100vw - ${DASHBOARD_SIDEBAR_WIDTH}px);
  flex: 1 1 auto;

  @media print {
    max-width: 100%;
  }

  ${H1} {
    ${fontHeavy}
    font-size: 22px;
    letter-spacing: 0.025em;
  }
  ${H2} {
    ${fontHeavy}
    font-size: 18px;
    letter-spacing: 0.025em;
  }
  ${H3} {
    font-size: 14px;
  }
  & ${H1}, ${H2}, ${H3} {
    text-transform: none;
  }
`

export const Body = styled.main`
  position: relative;
  flex: 1 1 auto;
  box-sizing: border-box;
  /* overflow: auto; */
  overflow-y: auto;
  background-color: #edf2fa;
  display: flex;
  flex-direction: column;
  /* padding: 20px 15px; */
  /* border-left: 1px solid #fff; */
`

export const CenteredBody = styled(Body)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Sidebar = styled(Column)`
  background-color: #ffffff;
  width: ${DASHBOARD_SIDEBAR_WIDTH}px;

  @media print {
    display: none;
  }
`

export const SidebarHead = styled(Column)`
  flex: none;
  width: ${DASHBOARD_SIDEBAR_WIDTH}px;
  height: ${DASHBOARD_HEADER_HEIGHT}px;
  box-sizing: border-box;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cdd2e4;
`

export const SidebarBody = styled(Column)`
  flex: 1 1 auto;
  box-sizing: border-box;
  width: ${DASHBOARD_SIDEBAR_WIDTH}px;
  border-right: 1px solid #cdd2e4;
  overflow: auto;
`

export const DummyRoute: React.FC<{ route: any }> = ({ route }) => {
  const location = useLocation()
  return (
    <Page route={route}>
      <h2>[Placeholder page]</h2>
      <pre>{JSON.stringify(location, null, 2)}</pre>
      <pre>{JSON.stringify(route, null, 2)}</pre>
    </Page>
  )
}
