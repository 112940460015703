import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { EmotionometerDevice, EmotionometerSticker } from 'shared/e-telligence/types'
import { Spacer, OutlineButton } from 'common/ui'
import { fontBold } from 'fonts'

interface Props<StickerType extends EmotionometerDevice> {
  sticker?: EmotionometerSticker<StickerType>
  placeholder?: string
  disabled?: boolean
  selected?: boolean
  size?: number
  onEdit?: () => void
  onRemove?: () => void
}

export const StickerTile = <Type extends EmotionometerDevice>({
  sticker,
  placeholder,
  selected,
  disabled,
  size = 96,
  onEdit,
  onRemove,
}: PropsWithChildren<Props<Type>>) => {
  return (
    <>
      <StickerDiv empty={!sticker} selected={selected} disabled={disabled} size={size} onClick={onEdit}>
        {!sticker ? (
          <figcaption style={{ margin: '0 5px' }}>{placeholder || `Add sticker +`}</figcaption>
        ) : (
          <>
            {sticker.isPreset ? (
              <StickerImage
                style={{
                  backgroundImage: `url("${require(`../assets/stickers/${sticker.type}/${
                    sticker.emotion ? sticker.emotion + '_' : ''
                  }${sticker.id}_icon.png`)}")`,
                }}
              />
            ) : (
              sticker.symbol && <StickerCustomSymbol children={sticker.symbol} />
            )}
            <StickerLabel size={size}>
              <span>{sticker.title}</span>
            </StickerLabel>
          </>
        )}
      </StickerDiv>
      {sticker && onRemove && (
        <>
          <Spacer size="xs" />
          <OutlineButton size="xs" onClick={onRemove}>
            Delete
          </OutlineButton>
        </>
      )}
    </>
  )
}

const StickerDiv = styled.div<{
  empty?: boolean
  preset?: boolean
  selected?: boolean
  disabled?: boolean
  size: number
}>`
  position: relative;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${p => (p.disabled ? 'auto' : 'pointer')};

  border: ${p => (p.selected ? '3px' : '1px')} solid
    ${p =>
      p.selected
        ? p.theme.buttonBorderTopColor
        : p.empty
        ? p.theme.thinOutlineColor
        : p.preset
        ? 'white'
        : p.theme.thinOutlineActiveColor};
  border-radius: 5px;
  ${p => (p.empty ? '' : `cursor: pointer;`)}
  background-color: white;
  ${p => (!p.selected ? '' : `box-shadow: 0 0 10px 0 ${p.theme.buttonBorderTopColor};`)}
  ${p => (p.disabled ? 'opacity: 0.5;' : '')}
  transition: opacity 0.2s linear;

  text-align: center;
  font-size: 0.8rem;
`
export const StickerImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 33.33%;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: cover;
  border-radius: 3px;
`
export const StickerLabel = styled.figcaption<{ size?: number }>`
  ${fontBold}
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #2b2e34;
  color: white;

  text-align: center;
  font-size: ${p => (!p.size ? 10 : Math.round(Math.max(p.size / 10, 5)))}px;
  text-transform: uppercase;

  line-height: 1;

  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  & span {
    margin: 0 ${p => (!p.size ? 3 : Math.round(Math.max(p.size / 18, 1)))}px;
  }
`
export const StickerCustomSymbol = styled.span`
  position: relative;
  display: inline-block;
  font-size: 42px;
  padding-bottom: 25%;
`
