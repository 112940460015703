import { GameObjects, Math as PhaserMath } from 'phaser'
import { PhaserTextStyle } from 'phaser-types'
import { Vector } from 'types'
import { getPixelRatio } from 'utils/screenUtils'
import { HelpfulThoughtsThoughtCloud as ThoughtCloud } from 'shared/session/types'
import HelpfulThoughtsScene from './HelpfulThoughtsScene'

export class Cloud {
  scene: HelpfulThoughtsScene
  container: GameObjects.Container
  text: GameObjects.Text
  cloudSprite: GameObjects.Image
  state: ThoughtCloud
  index: number
  aimPosition: Vector
  origScale: number
  shake: boolean

  // movementFactor: number

  constructor(
    scene: HelpfulThoughtsScene,
    x: number,
    y: number,
    scale: number,
    alpha: number,
    index: number,
    state: ThoughtCloud,
    shake: boolean
  ) {
    // Cloud Properties
    this.scene = scene
    this.index = index
    this.state = state
    this.origScale = scale
    this.shake = shake

    // Cloud Sprite
    this.cloudSprite = new GameObjects.Image(scene, 0, 0, 'cloudRed')
    this.cloudSprite.setScale(0.3)

    // Cloud Text
    this.text = new GameObjects.Text(scene, 0, 0, state.text, {
      fontFamily: 'eurostile, sans-serif',
      fontStyle: '700', // bold
      fontSize: '32px',
      align: 'center',
      color: '#001947',
      resolution: getPixelRatio(),
      wordWrap: {
        width: this.cloudSprite.width * this.cloudSprite.scaleX * 0.7,
      },
    } as PhaserTextStyle)
    this.text.setPosition(-this.text.width * 0.5, -this.text.height * 0.4)

    // Cloud Container
    this.container = new GameObjects.Container(scene, 0, 0, [this.cloudSprite, this.text])
    this.container
      .setPosition(x, y)
      .setScale(scale)
      .setAlpha(alpha)

    this.aimPosition = { x, y }

    if (this.state.answers.indexOf(true) >= 0) this.handleCorrectlyAnswered()
    if (this.state.targeted) this.setTargeted()

    scene.add.existing(this.container)
  }

  public get x() { return this.container.x; } // prettier-ignore
  public get y() { return this.container.y; } // prettier-ignore
  public set x(x: number) { this.container.x = x; } // prettier-ignore
  public set y(y: number) { this.container.y = y; } // prettier-ignore
  public setPosition = (x: number, y: number) => this.container.setPosition(x, y)

  updateState(cloudState: ThoughtCloud) {
    if (this.scene.state.screen === 'WAITING') this.reset()
    if (this.state.answers.length !== cloudState.answers.length && cloudState.answers.length !== 0)
      this.zapThought(cloudState.answers.indexOf(true) >= 0)
    if (this.state.targeted !== cloudState.targeted && cloudState.targeted) this.setTargeted()
    if (this.state.custom !== cloudState.custom && this.state.approved) {
      this.text.text = cloudState.custom || cloudState.answer
      this.text.setPosition(-this.text.width * 0.5, -this.text.height * 0.4)
    }
    this.state = cloudState
  }

  zapThought(correct: boolean) {
    if (correct) {
      this.handleCorrectlyAnswered()
      this.triggerExplode()
    } else {
      this.handleIncorrectlyAnswered()
    }
  }

  handleCorrectlyAnswered() {
    const setZapped = () => {
      this.cloudSprite.setTexture('cloudGreen')
      this.text.text = this.text.text = this.state.custom || this.state.answer
      this.text.setPosition(-this.text.width * 0.5, -this.text.height * 0.4)
    }
    this.scene.tweens.add({
      targets: this.container,
      ease: PhaserMath.Easing.Quadratic.Out,
      scale: 0,
      duration: 400,
      repeat: 0,
      yoyo: true,
      onYoyo: setZapped,
    })
  }

  handleIncorrectlyAnswered() {
    this.shake = true

    setTimeout(() => {
      this.scene.dispatch({ type: 'WRONG_ANSWER' })
      this.shake = false
    }, 1000)
  }

  setTargeted() {
    const diffX = this.container.x - this.scene.stageWidth / 2
    this.scene.thoughtClouds.forEach(thoughtCloud => {
      if (thoughtCloud !== this) thoughtCloud.aimPosition.x += -diffX / 8
    })
  }

  triggerExplode() {
    const emitters = ['shard1', 'shard2', 'shard3'].map(key => {
      const particles = this.scene.add.particles(key).setPosition(this.x, this.y)
      const emitter = particles.createEmitter({
        radial: true,
        angle: { min: 0, max: 360 },
        speed: { min: 150, max: 500 },
        scale: 0.2,
        rotate: { min: 0, max: 90 },
        alpha: { start: 1, end: 0 },
        lifespan: 800,
        maxParticles: 10,
      })
      this.scene.add.existing(particles)
      return emitter
    })
    setTimeout(() => emitters.forEach(emitter => emitter.stop()), 1500)
    setTimeout(() => this.scene.dispatch({ type: 'CHANGE_LEVEL', direction: 1 }), 1500)
  }

  reset() {
    this.cloudSprite.setTexture('cloudRed')
    this.text.text = this.scene.state.clouds[this.state.index].text
    this.text.setPosition(-this.text.width * 0.5, -this.text.height * 0.4)
  }

  update(delta: number) {
    let aimX = this.scene.stageWidth / 2
    const aimY = this.scene.stageHeight / 2
    if (this.state.targeted) {
      this.container.setDepth(9999)
      this.container.scale += (0.8 - this.container.scale) / 25
      this.container.alpha += (1 - this.container.alpha) / 10
    } else {
      aimX = aimX + (this.index - this.scene.state.currentCloud.index) * 400
      this.container.setDepth(this.y)
      this.container.scale += (this.origScale - this.container.scale) / 25
      this.container.alpha += (this.origScale - this.container.alpha) / 25
    }

    this.x += (aimX - this.x) / 25
    this.y += (aimY - this.y) / 25

    if (this.shake) {
      const speed = 15 // less is more
      const distance = 10 // more is more
      this.container.x = aimX + Math.cos(delta / speed) * distance
    }
  }
}
