export const arrayContains = <ArrayValue>(array: ArrayValue[], value: ArrayValue) => array.indexOf(value) >= 0

export const arrayContainsAny = <ArrayValue>(array: ArrayValue[], values: ArrayValue | ArrayValue[]) => {
  if (!(values instanceof Array)) values = [values]
  for (let value of values) {
    if (arrayContains(array, value)) return true
  }
  return false
}

export const mapToPrevNextShape = <T>(item: T, index: number, array: T[]) => ({
  item,
  prevItem: index > 0 ? array[index - 1] : undefined,
  nextItem: index < array.length - 1 ? array[index + 1] : undefined,
})
