import React from 'react'
import { Hr, P, Row, Spacer } from 'common/ui'
import styled, { css } from 'styled-components'
import { RichText } from 'session/common/RichText'

interface Props {
  show: boolean
  correct: boolean
  hint_correct: string
  hint_incorrect: string
  validationResult: string
}

export const Validation: React.FC<Props> = ({
  hint_correct,
  hint_incorrect,
  validationResult,
  show,
  correct = false,
}) => {
  if (!show) return null

  return (
    <Wrapper>
      <Spacer size="l" />
      <Hr />
      <Spacer size="s" />
      {correct ? (
        <>
          <Row alignItems="center">
            <Icon success>
              <svg width="20" height="20" viewBox="0 0 16 16">
                <polyline
                  points="4 8.43 6.67 11 12 5"
                  fill="none"
                  stroke="white"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
              </svg>
            </Icon>
            <Spacer size="s" />
            <P color="#4ebe40">You are correct</P>
          </Row>
          {validationResult && <P color="#955DF2">{validationResult}</P>}
          <RichText text={hint_correct} />
        </>
      ) : (
        <>
          <Row alignItems="center">
            <Icon success={false}>
              <span>+</span>
            </Icon>
            <Spacer size="s" />
            <P color="#ed5c61">Please try again</P>
          </Row>
          <RichText text={hint_incorrect} />
        </>
      )}
    </Wrapper>
  )
}

const Icon = styled.span<{ success: boolean }>`
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: 2px solid;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  ${p =>
    p.success
      ? css`
          border-color: #419636;
          background-color: #4ebe40;
        `
      : css`
          border-color: #ce1219;
          background-color: #ed5c61;
          font-weight: 600;
          font-size: 1.3rem;
          line-height: 0;
          span {
            transform: rotate(45deg) translate(0, -2px);
          }
        `}
`

const Wrapper = styled.div`
  ${P} {
    font-weight: 600;
    font-size: 1.1rem;
    margin: 10px 0;
  }
`
