import { isIOS } from 'utils/deviceUtils'

export function disablePinchToZoomiOS() {
  // disable pinch to zoom on iOS Safari
  if (!isIOS()) {
    document.addEventListener(
      'touchmove',
      function(event: TouchEvent) {
        if (event.touches.length > 1 && !(event.target instanceof HTMLCanvasElement)) {
          // one for iOS
          // if (typeof (event as any).scale === 'number' && (event as any).scale !== 1) event.preventDefault()
          event.preventDefault()
        }
      },
      { passive: false }
    )
  }
}

export function disableDoubleTapToZoomiOS() {
  // disable double-tap to zoom on iOS Safari < 13
  let lastTouchEnd = 0
  document.addEventListener(
    'touchend',
    function(event) {
      const now = new Date().getTime()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    { passive: false }
  )
}
