import { AnyModuleCode, moduleCodes } from 'shared/dashboard/moduleCodes'
import { ModuleWorkflow } from 'shared/dashboard/types'

/** @deprecated */
export const simpleModuleCodes = {
  whole: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
  split: ['1a', '1b', '2a', '2b', '3a', '3b', '4a', '4b', '5a', '5b', '6a', '6b', '7a', '7b', '8a', '8b', '9a', '9b'],
}

export const getModuleTitle = (moduleCode: AnyModuleCode) => {
  if (moduleCode.indexOf('info') !== -1) return `(4x2hr mode) Parent Information Session ${moduleCode.split('-')[2]}`
  if (moduleCode.indexOf('follow') !== -1) return `Follow Up ${moduleCode.split('-')[2]}`
  if (moduleCode.indexOf('teacher') !== -1) return `Teacher Information Session`
  if (moduleCode.indexOf('intro') !== -1) {
    switch (moduleCode) {
      case 'parent-intro':
        return 'Introductory Parent Meeting (Whole or Split mode)'
      case 'intro-1':
        return `Acknowledgements & About The Author`
      case 'intro-2':
        return `Program Overview`
      case 'intro-3':
        return `Program Planning`
      case 'intro-4':
        return `Assessment of Social Functioning`
      case 'intro-5':
        return `Overcoming Program Delivery Challenges`
      case 'intro-6':
        return `Challenger Board Game Instructions`
    }
  }
  if (moduleCode.indexOf('parent-prep') !== -1) {
    const moduleNumber = moduleCode.match(/^\D+(\d+)$/)?.[1]
    if (moduleNumber) return `Parent Preparation - Module ${moduleNumber}`
  }
  return `Module ${moduleCode}`
}

export const getModuleCodesByType = (type: ModuleWorkflow) => {
  switch (type) {
    case 'cadet': return moduleCodes['cadet'] // prettier-ignore
    case 'facilitator': return moduleCodes['facilitator'] // prettier-ignore
    case 'parent': return moduleCodes['parent'] // prettier-ignore
    case 'teacher': return moduleCodes['teacher'] // prettier-ignore
  }
}
