/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { Location } from 'history'
import { Prompt } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useGlobalState } from 'app/GlobalState'
import { getPromptCount } from 'utils/routeUtils'

interface Props {
  when?: boolean | ((location: Location<any>) => boolean)
  message?: string | ((location: Location<any>, newLocation?: Location<any>) => string)
}

const defaultMessage = 'Are you sure you want to navigate away? Any progress in your current activity may be lost.'

export const RouteLeavePrompt: React.FC<Props> = ({ when = true, message }) => {
  const location = useLocation()
  const { exitUrl } = useGlobalState()
  const _when = typeof when === 'function' ? when(location) : when

  useEffect(() => {
    const handleUnload = (event: BeforeUnloadEvent) => {
      if (_when) {
        const _message = typeof message === 'function' ? message(location) : message || defaultMessage
        event.preventDefault()
        event.returnValue = _message
        return _message // does basically nothing these days https://stackoverflow.com/a/40570214
      } else {
        delete event['returnValue']
      }
    }
    window.addEventListener('beforeunload', handleUnload)
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [_when, message, location])

  return (
    <Prompt
      when={_when}
      message={newLocation => {
        const _message = typeof message === 'function' ? message(location, newLocation) : message
        // console.log('TO', newLocation.pathname, newLocation.state)
        // console.log('FROM', location.pathname, location.state)
        if (newLocation.pathname === location.pathname) return true
        if (typeof newLocation.state === 'object' && newLocation.state.ignoreRouterPrompt === getPromptCount())
          return true
        return _message || defaultMessage
      }}
    />
  )
}
