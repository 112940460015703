import React, { useState } from 'react'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { GraduationMedalActivity } from 'session/sections/custom/GraduationMedal'
import { CUT_BL, CUT_BR, CUT_TL, CUT_TR, Padding, TAB_B_L, TAB_T_L } from 'common/ui'

export const GraduationMedalModal: React.FC<{ onClose: () => void; mentorMode?: boolean }> = ({
  onClose,
  mentorMode,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}
      panelStyle={mentorMode ? { paddingTop: 30, minWidth: '100vw', minHeight: '100vh' } : {}}>
      <Padding size="l">
        <GraduationMedalActivity onClose={delayedOnClose} />
      </Padding>
    </MobileModalPanel>
  )
}
