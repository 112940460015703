import { ScavengerHuntClue as Clue } from '../../../types'

export const clues: Clue[] = [
  {
    title: 'Clue 1',
    description: `Found near something with words.`,
    solution: 'books4',
  },
  {
    title: 'Clue 2',
    description: `Found near something you put things on.`,
    solution: 'desk',
  },
  {
    title: 'Clue 3',
    description: `Found near something you sit on.`,
    solution: 'rug',
  },
  {
    title: 'Clue 4',
    description: `Found near something that helps to brighten your day.`,
    solution: 'lamp',
  },
  {
    title: 'Clue 5',
    description: `Found near something you walk through.`,
    solution: 'door',
  },
  {
    title: 'Clue 6',
    description: `Found near something that "ticks" (or "checks").`,
    solution: 'pencils',
  },
  {
    title: 'Clue 7',
    description: `Found near things that reward you for trying your best.`,
    solution: 'medal',
  },
]
