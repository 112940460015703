import React from 'react'
import { CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR, TAB_B_S } from 'common/ui'
import { Row, Panel, H1, Spacer, CollapseMargin, P } from 'common/ui'

export const RouteNotFound: React.FC = () => {
  return (
    <Row padding="100px 0 0 0" justifyContent="center">
      <Panel
        style={{ maxWidth: 500 }}
        padding={'13px 30px 35px 30px'}
        flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, GRILL_TL, GRILL_TR, TAB_B_S]}>
        <H1 margin marginLeft="l" marginRight="l" align="center">
          Not found
        </H1>
        <Spacer />
        <CollapseMargin>
          <P align="center">The page you were looking for could not be found.</P>
        </CollapseMargin>
      </Panel>
    </Row>
  )
}
