// Import
import React from 'react'
import styled from 'styled-components'
import { Button, Panel, CUT_TL, CUT_TR, CUT_BR, CUT_BL, H1, H2 } from 'common/ui'

// Props
interface Props {
  playMode: boolean
  cloudTargeted: boolean
  cloudInitiated: boolean
  playGame: () => void
  scanning: boolean
  score: number
  startMode: boolean
  zapped: boolean
  customHelpfulThought: string
  currentHelpfulThought: number
  level: {
    level: number
    unhelpfulThought: string
    helpfulThoughts: string[]
  }
}

// Component
export const CanvasHud: React.FC<Props> = ({
  playMode,
  playGame,
  currentHelpfulThought,
  customHelpfulThought,
  cloudTargeted,
  cloudInitiated,
  scanning,
  score,
  startMode,
  zapped,
  level,
}) => {
  //..
  return (
    <CanvasContainer>
      {playMode && (
        <GameTextContainer>
          {!cloudTargeted && !cloudInitiated && (
            <H2 className="left" as="h4">
              {' '}
            </H2>
          )}

          {cloudTargeted && (
            <H2 className="left" as="h4">
              Unhelpful thought targeted
            </H2>
          )}

          {cloudInitiated && (
            <H2 className="left" as="h4">
              Helpful thought targeted
            </H2>
          )}

          {scanning && <H1 as="h3">Scanning thoughts...</H1>}

          <H2 className="right" as="h4">
            Unhelpful thoughts: {score} of 10
          </H2>
        </GameTextContainer>
      )}

      {startMode && (
        <Panel flair={[CUT_TL, CUT_TR, CUT_BR, CUT_BL]} padding={[30, 20]} theme="white">
          <p>Use your arrow buttons to scan for a unhelpful thought</p>
          <Button size="m" onClick={playGame}>
            Scan
          </Button>
        </Panel>
      )}

      {playMode && !scanning && (
        <UnhelpfulThought
          zapped={zapped}
          unhelpfulThought={level.unhelpfulThought}
          helpfulThought={level.helpfulThoughts[currentHelpfulThought]}
          customThought={customHelpfulThought}
        />
      )}
    </CanvasContainer>
  )
}

interface UnhelpfulThoughtProps {
  helpfulThought: string
  unhelpfulThought: string
  customThought: string
  zapped: boolean
}

const UnhelpfulThought: React.FC<UnhelpfulThoughtProps> = ({
  helpfulThought,
  unhelpfulThought,
  customThought,
  zapped,
}) => {
  return (
    <UnhelpfulThoughtStyled>
      {zapped && <H1>{helpfulThought !== 'custom' ? helpfulThought : customThought}</H1>}
      {!zapped && <H1>{unhelpfulThought}</H1>}
    </UnhelpfulThoughtStyled>
  )
}

// Styled Components
const CanvasContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  height: 660px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${require('./assets/helpful-though-bg.png')});

  ${Panel} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 730px;
    margin: 0 auto;
    align-items: center;
    text-align: center;

    ${Button} {
      cursor: pointer;
      margin-top: 16px;
    }
  }
`

const GameTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 84px 110px;

  ${H2} {
    width: 130px;
    color: #fff;
    text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.65);

    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }
  ${H1} {
    color: #ffffff;
    text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.65);
  }
`

const UnhelpfulThoughtStyled = styled.div`
  display: grid;
  place-items: center;
  margin: 0 auto;
  width: 660px;
  height: 260px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
`
