/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import uniqueBy from 'lodash/uniqBy'
import {
  SectionPropsBase,
  CardDeckSectionState,
  CardDeck,
  Card,
  MediaAsset,
  CardDeckSection as CardDeckSectionType,
  ParticipantState,
  CardDeckSectionParticipantState,
  ActivityDetectionOfExpressionState,
  ActivitySecretTransmissionState,
} from 'shared/session/types'
import { UPDATE_SECTION_STATE_DANGEROUS } from 'shared/session/actionTypes'

import { Row, Column, Spacer, Button, Label } from 'common/ui'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { SectionTitle } from 'session/common/SectionTitle'
import { RadioButton } from 'session/common/RadioButton'

import { useSessionState } from 'session/SessionState'
import { getImageUrl } from 'session/utils/assetUtils'
import { fontBold, fontLight } from 'fonts'
import { ConfirmModal } from 'common/ConfirmModal'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { trainingPurple } from 'themes'

interface Props extends SectionPropsBase {
  property: 'card_deck_sections'
}

export const getCards = (section: CardDeckSectionType): Card[] => {
  let cards: Card[] = uniqueBy(section.cards || [], ({ id }) => id)
  const excludeCardIds = cards.map(({ id }) => id)
  if (section.card_deck)
    cards = [
      ...cards,
      ...uniqueBy(
        section.card_deck.cards.filter(({ id }) => excludeCardIds.indexOf(id) < 0),
        ({ id }) => id
      ),
    ]
  return cards
}

export const CardDeckSection: React.FC<Props & { activeProfileId?: string }> = ({
  property,
  section,
  activeProfileId,
}) => {
  const { title, card_deck, image } = section
  const participantState = useFocusedParticipantState()
  const sectionState = useSectionStateWithFallback(property, section, () => ({}))
  const cards = useMemo(() => getCards(section), [section])

  const profileState: CardDeckSectionParticipantState | null =
    participantState && sectionState[participantState.profile.uid] ? sectionState[participantState.profile.uid] : null

  const card =
    profileState !== null && profileState.card_uid ? cards.find(({ uid }) => uid === profileState.card_uid) : undefined

  const profileIsActive =
    participantState &&
    profileState !== null &&
    participantState.profile.uid === (activeProfileId || profileState.active)

  return (
    <>
      {title && <SectionTitle children={title} />}
      <CardDeckSectionContainer>
        <CardDeckSectionInner>
          <SideImage
            image={card && card.left_image}
            disabled={!profileIsActive || !card || (card && !card.left_image)}
          />
          <DeckCover
            // disabled={!profileIsActive}
            image={card_deck && card_deck.image ? card_deck.image : image}
          />
          <DeckCard card={profileIsActive ? card : undefined} />
          <SideImage
            image={card && card.right_image}
            disabled={!profileIsActive || !card || (card && !card.right_image)}
          />
        </CardDeckSectionInner>
      </CardDeckSectionContainer>
    </>
  )
}

interface FacilitatorProps extends Props {
  hideActiveInputs?: boolean
  highlightUserSelected?: { [key: string]: string | null }
  showCardSubtitle?: boolean
  activityState?: ActivityDetectionOfExpressionState | ActivitySecretTransmissionState
  refresh?: number
}

export const getInitialSectionState = (participants: ParticipantState[]) => {
  return participants.reduce((acc, participant) => {
    acc[participant.profile.uid] = { active: false, card_uid: '' }
    return acc
  }, {} as CardDeckSectionState)
}

export const CardDeckSectionFacilitator: React.FC<FacilitatorProps> = ({
  property,
  section,
  hideActiveInputs,
  highlightUserSelected,
  showCardSubtitle,
  activityState,
  refresh,
}) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const cards = useMemo(() => getCards(section), [section])
  const [confirmRandomizeModalOpen, setConfirmRandomizeModalOpen] = useState<boolean>(false)
  const [confirmResetModalOpen, setConfirmResetModalOpen] = useState<boolean>(false)

  const getInitialState = () => getInitialSectionState(state.participants)
  const sectionState = useSectionStateWithFallback(property, section, getInitialState)

  const groupedTitleArr: string[] = []

  const updateSectionState = (state: CardDeckSectionState) => {
    dispatch({ ...getBaseAction(), type: UPDATE_SECTION_STATE_DANGEROUS, property, section_id: section.id, state })
  }

  const randomizeSelection = (override: boolean = false) => {
    if (!override && !confirmRandomizeModalOpen) {
      setConfirmRandomizeModalOpen(true)
      return
    }
    updateSectionState({
      ...Object.keys(sectionState).reduce(
        (obj, participantKey) => ({
          ...obj,
          [participantKey]: {
            ...sectionState[participantKey],
            card_uid: cards[Math.floor(Math.random() * cards.length)].uid,
          },
        }),
        {} as typeof sectionState
      ),
    })
  }

  const handleConfirmRandomize = (proceed: boolean) => {
    if (proceed) randomizeSelection(true)
    setConfirmRandomizeModalOpen(false)
  }

  const resetSelection = (override: boolean = false) => {
    if (!override && !confirmRandomizeModalOpen) {
      setConfirmResetModalOpen(true)
      return
    }

    updateSectionState({
      ...Object.keys(sectionState).reduce(
        (obj, participantKey) => ({
          ...obj,
          [participantKey]: {
            ...sectionState[participantKey],
            card_uid: '',
          },
        }),
        {} as typeof sectionState
      ),
    })
  }

  const handleConfirmReset = (proceed: boolean) => {
    if (proceed) resetSelection(true)
    setConfirmResetModalOpen(false)
  }

  const cleanseTitle = (title: string) => {
    return title
      .trim()
      .toLowerCase()
      .replace(/\s/g, '')
  }

  useEffect(() => {
    if (refresh !== 0) resetSelection(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return (
    <>
      <FacilitatorControls title="Assign Cards">
        <Row justifyContent="flex-end" style={{ position: 'absolute', top: 10, right: 25 }}>
          <Button
            theme="purple"
            style={{ marginRight: 10 }}
            size="s"
            children="Random"
            onClick={() => randomizeSelection()}
          />
          <DeckResetButton size="s" children="Reset" onClick={() => resetSelection()} />
        </Row>
        <Spacer />
        <FacilitatorControlsTable>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Cards</th>
              {state.participants.map((participant, i) => {
                const participantState = sectionState[participant.profile.uid] || { active: false, card_uid: '' }
                return (
                  <th key={i}>
                    <Column justifyContent="center">
                      {participant.profile.displayName}
                      {!hideActiveInputs && (
                        <RadioButton
                          size="s"
                          value={participantState.active}
                          label="Active"
                          wrapperProps={{ style: { marginTop: 10, justifyContent: 'center' } }}
                          labelProps={{ style: { fontSize: 14 } }}
                          onSelect={() =>
                            updateSectionState({
                              ...Object.keys(sectionState).reduce(
                                (obj, participantKey) => ({
                                  ...obj,
                                  [participantKey]: { ...sectionState[participantKey], active: false },
                                }),
                                {} as typeof sectionState
                              ),
                              [participant.profile.uid]: {
                                ...sectionState[participant.profile.uid],
                                active: !participantState.active,
                              },
                            })
                          }
                        />
                      )}
                    </Column>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {(cards || []).map(card => {
              const currentTitle = cleanseTitle(card.title)
              if (groupedTitleArr.find(a => a === currentTitle)) return null

              groupedTitleArr.push(currentTitle)
              const cardGroup = cards.filter(singleCard => cleanseTitle(singleCard.title) === currentTitle)

              return (
                <tr key={card.uid}>
                  <th>
                    {card.title || card.heading}
                    {cardGroup.length > 1 &&
                      cardGroup.map((a, idx) => (
                        <SubTitle key={idx}>
                          Option {idx + 1}: {a.text}
                        </SubTitle>
                      ))}
                    {!(cardGroup.length > 1) && showCardSubtitle && <SubTitle>{card.text}</SubTitle>}
                  </th>
                  {state.participants.map((participant, i) => {
                    const participantState = sectionState[participant.profile.uid] || {
                      active: false,
                      card_uid: '',
                    }

                    const previousSelectedCardState =
                      activityState && activityState[participant.profile.uid]
                        ? activityState[participant.profile.uid]
                        : { previousCards: [] }

                    return (
                      <td key={i}>
                        {cardGroup.map((currentCard, idx) => (
                          <div
                            key={idx}
                            className={
                              highlightUserSelected &&
                              highlightUserSelected[participant.profile.uid] === currentCard.uid
                                ? 'highlight'
                                : ''
                            }
                            style={{
                              backgroundColor: previousSelectedCardState.previousCards.find(a => a === currentCard.uid)
                                ? '#f1f1f1'
                                : '',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: 5,
                              position: 'relative',
                              minWidth: 150,
                            }}>
                            <Label
                              style={{ position: 'absolute', left: 5, opacity: 0.4 }}
                              children={cardGroup.length > 1 ? `Option ${idx + 1}` : ''}
                            />
                            <RadioButton
                              value={participantState.card_uid === currentCard.uid}
                              onSelect={() =>
                                updateSectionState({
                                  ...sectionState,
                                  [participant.profile.uid]: {
                                    ...sectionState[participant.profile.uid],
                                    card_uid: currentCard.uid,
                                  },
                                })
                              }
                            />
                          </div>
                        ))}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </FacilitatorControlsTable>
      </FacilitatorControls>
      <ConfirmModal
        isOpen={confirmRandomizeModalOpen}
        onSubmit={handleConfirmRandomize}
        label="Are you sure you want to create a random selection? Your current selections will be reset if you proceed."
      />
      <ConfirmModal
        isOpen={confirmResetModalOpen}
        onSubmit={handleConfirmReset}
        label="Are you sure you want to reset all selections?"
      />
    </>
  )
}

const CardDeckSectionContainer = styled.div`
  border: 1px solid #cdd2e4;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
`

const CardDeckSectionInner = styled(Row)`
  justify-content: space-between;
  align-items: center;
`

const cardWidthPercent = 50
const DeckCover = styled.div<{ image: CardDeck['image']; disabled?: boolean }>`
  width: ${cardWidthPercent}%;
  min-width: 300px;
  height: 0;
  padding-top: ${(9 / 16) * 100 * (cardWidthPercent / 100)}%;
  background-color: #f7f8f9;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-position: center center;
  background-size: cover;
  transition: all 0.25s ease-in-out;
  ${p => (p.image ? css`background-image: url('${getImageUrl(p.image, { preset: '720w-3-2' })}');` : '')}
  ${p => (p.disabled ? 'filter: grayscale(100%)' : '')}
`

const sideImageWidthPercent = 18
const SideImage = styled.div<{ image?: MediaAsset; disabled?: boolean }>`
  width: ${sideImageWidthPercent}%;
  min-width: 120px;
  height: 0;
  padding-top: ${p => (p.disabled ? 0 : (1 / 1) * 100 * (sideImageWidthPercent / 100))}%;
  background: #fff;
  border: 1px solid #cdd2e4;
  border-radius: 10px;
  background-position: center ${p => (p.disabled ? '-100%' : 'center')};
  background-size: cover;
  transition: all 0.3s ease-in-out;
  opacity: ${p => (p.disabled ? '0' : '1')};
  ${p => (p.image ? css`background-image: url('${getImageUrl(p.image, { preset: '320w-1' })}');` : '')}
`

const DeckCard: React.FC<{ card?: Card }> = ({ card: _card }) => {
  const cardRef = useRef<Card | undefined>(_card)
  const [visible, setVisible] = useState<boolean>(!!_card)
  const [, reRender] = useState(0)
  useEffect(() => {
    if (!visible && _card) {
      cardRef.current = _card
      setVisible(true)
    } else if (visible && !_card && cardRef.current) {
      setVisible(false)
      // not changing cardRef.current because it needs to animate out without appearing empty
    } else if (visible && _card && cardRef.current && _card.id !== cardRef.current.id) {
      cardRef.current = _card
      reRender(v => v + 1) // need to re-render because useEffect fires after initial props change render
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_card])
  const card = cardRef.current || _card
  return (
    <DeckCardDiv visible={visible}>
      {card && (
        <>
          {card.category && <label>{card.category.title}</label>}
          {card.title && <h3>{card.title}</h3>}
          {(card.title || card.category) && <Spacer size="m" />}
          {card.heading && <label>{card.heading}</label>}
          {card.text && <p>{card.text}</p>}
        </>
      )}
    </DeckCardDiv>
  )
}

const DeckCardDiv = styled.div<{ visible?: boolean }>`
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  top: ${p => (p.visible ? '50%' : '0%')};
  left: 50%;
  width: ${cardWidthPercent}%;
  min-width: 300px;
  min-height: 150px;
  padding: 20px;
  background-color: #f7f8f9;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  opacity: ${p => (p.visible ? 1 : 0)};
  transform: ${p => (p.visible ? 'translate(-50%, -50%)' : 'translate(-50%, -50%) rotateZ(-15deg)')};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & label {
    ${fontBold}
    display: block;
    color: #ff8a00;
    font-size: 1em;
    line-height: 1;
    margin-bottom: 5px;
  }
  h3 {
    ${fontBold}
    line-height: 1;
    margin: 0;
    margin-bottom: 5px;
    color: #011a46;
    font-size: 1.1em;
  }
  p {
    ${fontLight};
    margin: 0;
    margin-top: 5px;
    line-height: 1;
    font-size: 0.8em;
  }
`

const SubTitle = styled.p`
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
  color: #a8a8a8;
`

export const DeckResetButton = styled(Button)`
  background: linear-gradient(180deg, rgb(105 106 165) 0%, rgb(124 125 185) 100%);
  border-color: rgb(124 125 185);

  &:after {
    border-color: rgb(105 106 165);
  }

  &:hover {
    background: linear-gradient(180deg, rgb(83 84 160) 0%, rgb(112 113 175) 100%);
    border-color: rgb(112 113 175);

    &:after {
      border-color: rgb(83 84 160);
    }
  }
`
