/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Activity, Attempt, Response } from 'types'
import { useLocalStorage, useSessionStorage } from 'utils/useStorage'
import { usePersistent } from 'utils/usePersistent'
import { saveActivityAttempt, GetProfile, DrupalProfile } from 'api'
import { decodeJWT } from 'utils/decodeJWT'
import { getUserUid, getUserType } from './useGenericUser'
import { BaseAction, AuthProvider, UserScope, GameModeSlug } from 'shared/types'

export type UserState = ReturnType<typeof useProviderUserState>

function useProviderUserState() {
  const [authProvider, setAuthProvider] = useSessionStorage<AuthProvider>('authProvider', 'sas', true)
  const [accessToken, setAccessToken] = useSessionStorage('accessToken', '', true)
  const [refreshToken, setRefreshToken] = useSessionStorage('refreshToken', '', true)
  const [offlineMode, setOfflineMode] = useSessionStorage('offlineMode', false)
  const [offlineMentor, setOfflineMentor] = useSessionStorage('offlineMentor', false)
  const [tokenExpiry, setTokenExpiry] = useSessionStorage('tokenExpiry', 0)
  const [gameMode, setGameMode] = useSessionStorage<GameModeSlug>('gameMode', 'SAS-DEV', true)
  const [openAccess, setOpenAccess] = useSessionStorage('openAccess', false)
  const [drupalProfile, setDrupalProfile] = useSessionStorage<DrupalProfile | null>(
    'drupalProfile',
    process.env.REACT_APP_REQUIRE_AUTH === '1'
      ? null
      : {
          first_name: 'Adam',
          last_name: 'Royle',
          profile_id: '1',
          username: 'adamroyle',
          user_id: '1',
          roles: 'sas facilitator',
        }
  )
  const [profiles, setProfiles] = useSessionStorage<GetProfile[]>('profiles', [])
  const [profileId, setProfileId] = useSessionStorage('profileId', 0)
  const [profileName, setProfileName] = useSessionStorage('profileName', '', true)
  const prefix = `${profileId}${openAccess ? '_open_access_' : ''}`
  const [activityAttempts, setActivityAttempts] = useLocalStorage<Attempt[]>(`${prefix}_activityAttempts`, [])
  const [unpersistedActivityAttempts, setUnpersistedActivityAttempts] = useLocalStorage<Attempt[]>(
    `${prefix}_activityAttempts_unpersisted`,
    []
  )
  const [usingIOSvhFix, setUsingIOSvhFix] = useState<boolean>(false)

  const logout = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setAccessToken('')
      setRefreshToken('')
      setDrupalProfile(null)
    })
  }

  const state = {
    authProvider,
    setAuthProvider,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    logout,
    offlineMode,
    setOfflineMode,
    offlineMentor,
    setOfflineMentor,
    tokenExpiry,
    setTokenExpiry,
    gameMode,
    setGameMode,
    openAccess,
    setOpenAccess,
    drupalProfile,
    setDrupalProfile,
    profiles,
    setProfiles,
    profileId,
    setProfileId,
    profileName,
    setProfileName,
    activityAttempts,
    setActivityAttempts,
    getAttempts,
    hasAttempted,
    hasCompleted,
    addActivityAttempt,
    getLatestAttempt,
    hasPermissionFor,
    unpersistedActivityAttempts,
    getUserScopes,
    getBaseAction,
    usingIOSvhFix,
    setUsingIOSvhFix,
    enableSkillTracker: (gameMode === 'SAS-SG-D' || gameMode === 'SAS-SG' || gameMode === 'SAS-DEV') && !openAccess,
    enableETelligence: gameMode === 'SAS-SA' || gameMode === 'SAS-DEV',
    enableGadgetPack: (gameMode === 'SAS-SG-D' && !openAccess) || gameMode === 'SAS-SG' || gameMode === 'SAS-DEV',
    enableSessions:
      (gameMode === 'SAS-SG-D' || gameMode === 'SAS-DEV') &&
      !openAccess &&
      process.env.REACT_APP_ENABLE_SESSIONS === '1',
  }

  const persistentState = usePersistent(state)

  useEffect(() => {
    if (!profileId || !accessToken) return
    persistentState.unpersistedActivityAttempts.forEach(attempt => {
      saveActivityAttempt(attempt, accessToken)
        .then(() => setUnpersistedActivityAttempts(attempts => attempts.filter(v => v !== attempt)))
        .catch(console.log)
    })
  }, [profileId, accessToken, persistentState, setUnpersistedActivityAttempts])

  return state

  function addActivityAttempt(
    activity: Activity,
    sub_activity: Attempt['sub_activity'] = null,
    result: Attempt['result'] = null,
    score: Attempt['score'] = null,
    responses: Response[] = []
  ) {
    const attempt: Attempt = {
      profile_id: profileId,
      section: activity.section,
      activity: activity.id,
      sub_activity,
      result,
      score,
      completed_at: Math.floor(Date.now() / 1000),
      responses,
    }
    // we don't persistent activity responses for openAccess
    const attemptForPersisting = openAccess ? { ...attempt, responses: [] } : attempt
    setActivityAttempts(attempts => attempts.concat([attempt]))
    saveActivityAttempt(attemptForPersisting, accessToken).catch(() => {
      setUnpersistedActivityAttempts(attempts => attempts.concat([attemptForPersisting]))
    })
  }

  function hasAttempted(activity: Activity, sub_activity: Attempt['sub_activity'] = null): boolean {
    return openAccess || getAttempts(activity, sub_activity).length > 0
  }

  function hasCompleted(activity: Activity, sub_activity: Attempt['sub_activity'] = null): boolean {
    return openAccess || getAttempts(activity, sub_activity).filter(completedAttempt).length > 0
  }

  function getAttempts(activity: Activity, sub_activity: Attempt['sub_activity'] = null): Attempt[] {
    return persistentState.activityAttempts.filter(byActivity(activity, sub_activity))
  }

  function getLatestAttempt(activity: Activity, sub_activity: Attempt['sub_activity'] = null): Attempt | undefined {
    return getAttempts(activity, sub_activity).pop()
  }

  function hasPermissionFor(activity: Activity): boolean {
    return openAccess || gameMode === 'SAS-SA' || !activity.prerequisite || hasCompleted(activity.prerequisite)
  }

  function getUserScopes(): UserScope[] {
    if (offlineMode) return offlineMentor ? ['mentor'] : ['agent']
    return (decodeJWT(accessToken).scopes as unknown) as UserScope[]
  }

  function getBaseAction(): Pick<BaseAction, 'user_uid' | 'role' | 'timestamp'> {
    return {
      user_uid: getUserUid({ authProvider, drupalProfile, profileId }),
      role: getUserType({ authProvider, drupalProfile, userScopes: getUserScopes() }),
      timestamp: Date.now(),
    }
  }
}

function byActivity(activity: Activity, sub_activity: Attempt['sub_activity'] = null) {
  return (attempt: Attempt) => attempt.activity === activity.id && attempt.sub_activity === sub_activity
}

function completedAttempt({ result }: Attempt) {
  return result === 'success' || result === 'pass' || result === 'complete'
}

function noop(): any {}

export const UserStateContext = React.createContext<UserState>({
  // These values will be ignored in our app.
  // They exist only to appease the TypeScriptGods™.
  authProvider: 'sas',
  setAuthProvider: noop,
  accessToken: '',
  setAccessToken: noop,
  refreshToken: '',
  setRefreshToken: noop,
  logout: noop,
  offlineMode: false,
  setOfflineMentor: noop,
  offlineMentor: false,
  setOfflineMode: noop,
  tokenExpiry: 0,
  setTokenExpiry: noop,
  gameMode: 'SAS-DEV',
  setGameMode: noop,
  openAccess: false,
  setOpenAccess: noop,
  drupalProfile: null,
  setDrupalProfile: noop,
  profiles: [],
  setProfiles: noop,
  profileId: 0,
  setProfileId: noop,
  profileName: '',
  setProfileName: noop,
  activityAttempts: [],
  setActivityAttempts: noop,
  hasAttempted: noop,
  hasCompleted: noop,
  addActivityAttempt: noop,
  getLatestAttempt: noop,
  getAttempts: noop,
  hasPermissionFor: noop,
  unpersistedActivityAttempts: [],
  getUserScopes: noop,
  getBaseAction: noop,
  usingIOSvhFix: false,
  setUsingIOSvhFix: noop,
  enableSkillTracker: false,
  enableETelligence: false,
  enableGadgetPack: false,
  enableSessions: false,
})

export const UserStateProvider: React.FC = ({ children }) => {
  const state = useProviderUserState()
  return <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>
}

export function useUserState() {
  return useContext(UserStateContext)
}
