import { QuestionnaireSummary } from '../../../questionnaires/types'
import { QuestionnaireSectionState } from '../../types'

export const UPDATE_STATE = 'UPDATE_QUESTIONNAIRE_STATE'
export const UPDATE_PARTICIPANT_DATA = 'UPDATE_PARTICIPANT_DATA'
export const CHANGE_PARTICIPANT_ACCESS = 'CHANGE_PARTICIPANT_ACCESS'

interface BaseAction {}

export interface UpdateStateAction extends BaseAction {
  type: typeof UPDATE_STATE
  state: QuestionnaireSummary
}

export interface UpdateParticipantDataAction extends BaseAction {
  type: typeof UPDATE_PARTICIPANT_DATA
  participantUid: keyof QuestionnaireSectionState
  data: any
}

export interface ChangeParticipantAccessAction extends BaseAction {
  type: typeof CHANGE_PARTICIPANT_ACCESS
  participantUid: keyof QuestionnaireSectionState
  active: boolean
}

export type ActionTypes = UpdateStateAction | UpdateParticipantDataAction | ChangeParticipantAccessAction
