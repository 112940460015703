import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import { purple } from 'themes'

import { MentorDashStateProvider, useMentorDashState } from './MentorDashboardState'
import { NavRoute } from 'dashboards/types'
import { routes } from './routes'

import { Fixed, Row, H1, H2, H3, Spacer, Column } from 'common/ui'
import {
  Sidebar,
  SidebarHead,
  SidebarBody,
  Main as FacDashMain,
  Header,
  HeaderLeft,
  Body,
  HeaderRight,
} from 'dashboards/common/LayoutUI'
import { PageWrapper } from 'dashboards/common/Page'
import { Logo } from 'common/Logo'
import { SidebarNavItem } from 'dashboards/common/SidebarNavItem'
import { RouteNotFound } from 'dashboards/common/RouteNotFound'
import { HeaderProfile, UserMenuItem } from 'dashboards/common/HeaderProfile'
import { MentorCadetSelectionScreen } from './MentorCadetSelectionScreen'
import { LinkButton } from 'common/LinkButton'

import { baseUrl as facilitatorDashboardBaseUrl } from '../facilitator/FacilitatorDashboard'
import { arrayContainsAny } from 'utils/arrayUtils'
import { flattenRoutes } from 'dashboards/utils/routeUtils'
import { useGenericUser } from 'app/useGenericUser'
import { usePortraitWidthTrigger } from 'app/OrientationPrompt'
import { ProgressionEventsStateProvider } from 'session/ProgressionEventsState'
import { clearEndpointCache } from 'dashboards/utils/endpointHooks'
import { CadetNameAndStatus } from 'dashboards/common/CadetNameAndStatus'

export const baseUrl = '/mentor/dashboard-new'

const hideNavItems = (routes: NavRoute[]): NavRoute[] => {
  return routes
    .filter(({ hideFromNav }) => !hideFromNav)
    .map((item) => (item.subpages ? { ...item, subpages: hideNavItems(item.subpages) } : item))
}

const Main = styled(FacDashMain)`
  & ${Header} {
    background-color: #a2aacf;
    & ${H2} {
      color: #011a46;
    }
    & > *:not(:first-child) {
      border-left: 1px solid #bdc3e0;
    }
    & > *:not(:last-child) {
      border-right: 1px solid #858cac;
    }
    & ${HeaderRight}.open {
      background-color: #7c88bf;
    }
  }

  & ${PageWrapper} {
    & ${H1}, ${H2}, ${H3} {
      color: #011a46;
    }
  }
`

export const MentorDashboardMain = Main

export const MentorDashboard: React.FC = () => {
  usePortraitWidthTrigger(720)
  return (
    <ThemeProvider theme={purple}>
      <ProgressionEventsStateProvider>
        <MentorDashStateProvider>
          <MentorDashboardInner />
        </MentorDashStateProvider>
      </ProgressionEventsStateProvider>
    </ThemeProvider>
  )
}

export const MentorDashboardInner: React.FC = () => {
  const user = useGenericUser()

  const {
    mentorUid,
    setMentorUid,
    cadetPrimaryMentorUid,
    setCadetPrimaryMentorUid,
    endpoints: {
      mentor: { value: mentor },
      primaryMentor: { value: cadetMentor },
    },
  } = useMentorDashState()

  if (!cadetPrimaryMentorUid || !mentorUid) {
    return <MentorCadetSelectionScreen />
  }

  const availableRoutes = routes.filter(
    ({ inaccessible }) =>
      !inaccessible ||
      (typeof inaccessible === 'function' &&
        !inaccessible(user, { mentor: mentor || undefined, cadetMentor: cadetMentor || undefined }))
  )

  const availableNavItems = hideNavItems(availableRoutes)

  return (
    <Fixed cover>
      <Row style={{ width: '100%', maxWidth: '100vw' }}>
        <Sidebar>
          <SidebarHead>
            <Logo fill="#011A46" />
          </SidebarHead>
          <SidebarBody>
            {availableNavItems.map((route, i) => (
              <SidebarNavItem
                key={route.path || i}
                baseUrl={baseUrl}
                route={route}
                shouldHide={(subpageHideFunction: Exclude<NavRoute['inaccessible'], boolean | undefined>) =>
                  subpageHideFunction(user, { mentor: mentor || undefined })
                }
              />
            ))}
          </SidebarBody>
          <Spacer flex="1 1 auto" />
          {arrayContainsAny(user.roles, [
            'sas-sg facilitator',
            'sas-sg facilitator trainee',
            'sas-sg senior facilitator',
            'sas-sg senior facilitator trainee',
            'sas-sg assistant',
            'sas-sg assistant trainee',
          ]) && (
            <Column padding={10}>
              <LinkButton
                onClick={clearEndpointCache}
                to={facilitatorDashboardBaseUrl}
                size="s"
                children="My Facilitator Dashboard"
              />
            </Column>
          )}
        </Sidebar>
        <Main>
          <Header>
            <HeaderLeft>
              <H2>
                {`Mentor Portal for Cadet - `}
                {cadetMentor ? (
                  <CadetNameAndStatus
                    primaryMentor={cadetMentor}
                    tooltipPlacement="bottomLeft"
                    hideIconIfActive
                    hideWarningIcons
                  />
                ) : (
                  'Loading...'
                )}
              </H2>
            </HeaderLeft>
            <HeaderProfile
              menuItems={
                <>
                  <UserMenuItem
                    onClick={() => {
                      setMentorUid(null)
                      setCadetPrimaryMentorUid(null)
                    }}>
                    <Spacer flex="0 0 22px" />
                    <Spacer size="m" />
                    <div>
                      <span>Switch Cadet</span>
                    </div>
                  </UserMenuItem>
                </>
              }
            />
          </Header>
          <Body>
            {!!mentor && (
              <Switch
                children={[
                  ...flattenRoutes(availableRoutes, baseUrl).map((route, i) => [
                    <Route key={i} path={route.path} exact={route.exact}>
                      <route.Page route={route} />
                    </Route>,
                  ]),
                  <Route key="notFound">
                    <RouteNotFound />
                  </Route>,
                ]}
              />
            )}
          </Body>
        </Main>
      </Row>
    </Fixed>
  )
}
