import React, { useState } from 'react'
import styled from 'styled-components'
import { FlexModal } from 'app/FlexModal'
import { Button, Textarea } from 'common/ui'
import { HelpfulThoughtsProfileState } from 'shared/session/types'
import { DebouncedInputComponent } from 'session/common/DebouncedInputComponent'
import { InputLockComponent } from 'session/common/InputLockComponent'

interface Props {
  profileState: HelpfulThoughtsProfileState
  isOpen: boolean
  handleSubmittedOption: () => void
  handleSelectedOption: (value: number) => void
  handleCustomHelpfulThought: any
  selectedOption: number | null
  isGlowing: (value: boolean) => void
  inputKeyPrefix: string
}

const whiteCloud = require('./assets/cloud-white-shadow.png')
const blueCloud = require('./assets/cloud-blue.png')

export const OptionModal: React.FC<Props> = ({
  profileState,
  isOpen,
  handleSelectedOption,
  handleCustomHelpfulThought,
  selectedOption,
  handleSubmittedOption,
  isGlowing,
  inputKeyPrefix,
}) => {
  const [showInput, setShowInput] = useState<boolean>(false)
  const currentCloud = profileState.clouds.find(cloud => cloud.index === profileState.currentCloud.index)

  const handleCustomClick = (index: number) => {
    setShowInput(true)
    handleSelectedOption(index)
    // handleCustomHelpfulThought(currentCloud?.custom || '')
  }

  return (
    <FlexModal
      isOpen={isOpen}
      onAfterOpen={() => isGlowing(false)}
      onAfterClose={() => isGlowing(true)}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      style={{ overlay: { background: 'rgba(1, 26, 70, .8)' } }}>
      <ModalContainer>
        <ModalText>Load a helpful thought to ZAP the unhelpful one:</ModalText>
        <CloudOptions>
          {currentCloud &&
            currentCloud.options.map((thought, index) => {
              const disabled = currentCloud.answers.indexOf(thought) >= 0
              return (
                <CloudContainer
                  key={thought + index}
                  selected={selectedOption === index}
                  style={{ opacity: disabled ? 0.5 : 1 }}>
                  {thought !== 'custom' && (
                    <CloudOption
                      disabled={disabled}
                      onClick={() => {
                        if (!disabled) handleSelectedOption(index)
                      }}
                      children={thought}
                    />
                  )}

                  {!showInput && thought === 'custom' && (
                    <Button size="s" children="Write My Own Thought" onClick={() => handleCustomClick(index)} />
                  )}

                  {showInput && thought === 'custom' && (
                    <div style={{ position: 'relative' }}>
                      <DebouncedInputComponent<string>
                        onChange={handleCustomHelpfulThought}
                        value={currentCloud?.custom || ''}>
                        {props => (
                          <InputLockComponent
                            key={`${inputKeyPrefix}_${currentCloud.index}`}
                            fieldUid={`${inputKeyPrefix}_${currentCloud.index}`}
                            verticalPlacementCadet={-20}>
                            {(lockProps, lockState) => (
                              <Textarea
                                {...props}
                                {...lockProps}
                                {...lockState}
                                onChange={e => {
                                  props.onChange(e.target.value || '')
                                  lockProps.onChange(e.target.value || '')
                                }}
                                onFocus={() => handleSelectedOption(index)}
                                placeholder="Type here..."
                                disabled={lockState.disabled || currentCloud.approved}
                              />
                            )}
                          </InputLockComponent>
                        )}
                      </DebouncedInputComponent>
                    </div>
                  )}
                </CloudContainer>
              )
            })}
        </CloudOptions>

        <Button
          disabled={selectedOption === null || (selectedOption === 3 && !currentCloud?.approved)}
          onClick={() => handleSubmittedOption()}
          flashing={selectedOption === 3 && currentCloud?.approved}>
          Choose
        </Button>
      </ModalContainer>
    </FlexModal>
  )
}

const ModalContainer = styled.div`
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
  ${Button} {
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }
`

const ModalText = styled.h4`
  color: #ffffff;
  font-family: 'Eurostile';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.23;
  text-align: center;
  letter-spacing: 0.03em;
`

const CloudOptions = styled.div`
  display: grid;
  grid-template: auto / auto auto;
  grid-gap: 10px;
  width: 100%;
`

const CloudContainer = styled.div<{ selected: boolean }>`
  position: relative;
  display: grid;
  place-items: center;
  background-image: ${({ selected }) => (selected ? `url(${blueCloud})` : `url(${whiteCloud})`)};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const CloudOption = styled.button`
  outline: none; // :(
  ${({ disabled }) => (disabled ? null : `cursor: pointer;`)}
  display: block;
  width: 340px;
  height: 240px;
  padding: 40px;
  color: #011a46;
  font-size: 22px;
  font-family: 'Eurostile';
  font-weight: bold;
  line-height: 1.1;
  background: none;
  border: none;
  border-radius: 0;
  transition: background-size 200ms ease;
`
