import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'
import { parentGreen } from 'themes'

import { SessionEntityHydrated } from 'shared/dashboard/types'
import { SessionModule, SavedInputValue, ParticipantState } from 'shared/session/types'
import { SessionStateProvider, useSessionState } from 'session/SessionState'
import { UserInputStateProvider, useUserInputState } from 'session/UserInputState'
import { ProgressionEventsStateProvider } from 'session/ProgressionEventsState'
import { InputContextProvider } from 'session/InputContext'
import { SET_SAVED_USER_INPUT_VALUES, UPDATE_STATE } from 'shared/session/actionTypes'

import { ModalContextProvider } from 'session/ModalContext'
import { Column, AppBackground } from 'common/ui'
import { ParticipantPlaybackUi } from './ParticipantPlaybackUi'
import { PastSessionSlideHandler } from './PastSessionSlide'
import { ResourceLoadingStatusPanel } from 'dashboards/common/ResourceLoadingStatusPanel'

import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useGenericUser } from 'app/useGenericUser'
import { useEndpoint } from 'dashboards/utils/endpointHooks'

export const MentorPastMeeting: React.FC = () => {
  useDisableOrientationLock()
  return (
    <ProgressionEventsStateProvider>
      <SessionStateProvider>
        <UserInputStateProvider>
          <InputContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={parentGreen}>
                <MentorPastMeetingInner />
              </ThemeProvider>
            </ModalContextProvider>
          </InputContextProvider>
        </UserInputStateProvider>
      </SessionStateProvider>
    </ProgressionEventsStateProvider>
  )
}

const MentorPastMeetingInner: React.FC = () => {
  const { sessionUid, mentorUid } = useParams<{ sessionUid: string; mentorUid: string }>()
  const { hash } = useLocation()
  const user = useGenericUser()
  const { dispatch: dispatchSessionState, getBaseAction } = useSessionState()
  const { dispatch: dispatchInputState } = useUserInputState()
  const { setSessionData, setSessionProfile, setSessionUserType, setPastMode, state } = useSessionState()

  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)
  const [session, sessionEndpoint] = useEndpoint<SessionEntityHydrated & { module: SessionModule }>(
    `/api/v1/sessions/${sessionUid}?mentor_uid=${mentorUid}`
  )
  const [inputValues, inputValuesEndpoint] = useEndpoint<SavedInputValue<any>[]>(
    `/api/v1/session_input_values?session_uid=${sessionUid}`
  )

  const invalidSessionType = !!session && session.type !== 'parent'
  const loading = !sessionEndpoint.loaded || !inputValuesEndpoint.loaded || invalidSessionType

  const extraErrorMessages = [
    invalidSessionType ? `You're attempting to join a ${session?.type} meeting while accessing as a mentor.` : null,
  ]

  const wasLoading = useRef<boolean>(true)
  useEffect(() => {
    if (
      !loading &&
      wasLoading.current === true /* everything after this should be inferred... */ &&
      inputValues !== null &&
      session !== null
    ) {
      wasLoading.current = false

      const startingSlide = hash ? hash.replace('#', '') : null
      window.history.replaceState({}, document.title, window.location.pathname)

      const newParticipantState: ParticipantState = {
        profile: { ...user, userType: 'mentor' },
        status: 'connected',
        locked: true, // important
        tokens: 0,
        inputValues: [],
        currentSlideUid: startingSlide,
        gadgetTrayOpen: false,
        trayType: null,
      }

      ReactDOM.unstable_batchedUpdates(() => {
        if (session) setSessionData(session)
        setPastMode(true)
        setSessionUserType('mentor')
        setSessionProfile({ ...user, userType: 'mentor' })

        // Initialize session state!
        dispatchSessionState({
          ...getBaseAction(),
          type: UPDATE_STATE,
          state: { ...state, sessionUid: session.uid, participants: [newParticipantState] },
        })

        dispatchInputState({
          user_uid: user.uid,
          role: user.userType,
          timestamp: Date.now(),
          type: SET_SAVED_USER_INPUT_VALUES,
          inputValues,
        })

        console.log('\n✅ All data received, now processing....\n\n')
      })

      setTimeout(() => {
        console.log('\n✅ Launching session....\n\n')
        setFinishedDelay(true)
      }, 1000)
    }
  })

  if (loading || !finishedDelay)
    return (
      <AppBackground>
        <Column flex="auto" alignItems="center" justifyContent="center">
          <ResourceLoadingStatusPanel
            title="Loading meeting data..."
            resources={[
              { label: 'Meeting content', endpoint: sessionEndpoint },
              { label: 'Input and drawings', endpoint: inputValuesEndpoint },
            ]}
            extraErrorMessages={extraErrorMessages}
          />
        </Column>
      </AppBackground>
    )

  return (
    <ParticipantPlaybackUi>
      <PastSessionSlideHandler />
    </ParticipantPlaybackUi>
  )
}
