import React from 'react'
import styled from 'styled-components'
import { Column } from 'common/ui'

const NavDivider = styled(Column)<{ height: number }>`
  height: ${p => p.height}px;
  max-width: ${p => p.height * 4}px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${p => p.height}px ${p => p.height * 1.4}px 0 0;
    border-color: #c4cae6 transparent transparent transparent;
  }
  &::after {
    content: '';
    width: 150px;
    height: 100%;
    margin-left: ${p => p.height * 0.8}px;
    padding-right: 10px;
    background-image: url('${require('../assets/nav-arrows@2x.png')}');
    background-repeat: no-repeat;
    background-size: auto 30px;
    background-position: center;
  }
  @media (orientation: portrait) {
    &::before {
      border-width: ${p => p.height}px ${p => p.height * 0.5}px 0 0;
    }
    &::after {
      width: auto;
      margin-left: ${p => p.height * 0.25}px;
      background: none;
    }
  }
  @media (max-width: 350px) {
    &::before {
      border: none;
      width: 30px;
      height: 100%;
      left: -30px;
      background: linear-gradient(110deg, transparent 35%, #edf2fa 65%);
      z-index: 1;
    }
    &::after {
      display: none;
    }
    width: 0;
  }
`

export const NavDividerColumn: React.FC<{ height: number }> = ({ height }) => {
  return <NavDivider flex="1 1 auto" height={height} />
}
