import {
  SessionState as State,
  ModuleStep,
  SectionProperty,
  SectionObject,
  SectionContainer,
  HOLDING_SLIDE,
  MODULE_SLIDE,
  SUPPLEMENTARY_SLIDE,
  HydratedSlide,
  HydratedModuleSlideRef,
  HydratedSupplementarySlideRef,
  HoldingSlideRef,
  SupplementarySlide,
  sectionProperties,
  END_SLIDE,
  EndSlideRef,
} from 'shared/session/types'
import { SessionEntityHydrated } from 'shared/dashboard/types'

export const getStepByUid = (
  sessionData: SessionEntityHydrated,
  stepUid: ModuleStep['uid']
): ModuleStep | undefined => {
  const steps: ModuleStep[] = sessionData.module.steps || []
  return steps.find(({ uid }) => uid === stepUid)
}

export const getStepByNumber = (
  sessionData: SessionEntityHydrated,
  stepNumberFromZero: number
): ModuleStep | undefined =>
  stepNumberFromZero >= 0 && stepNumberFromZero < sessionData.module.steps.length
    ? sessionData.module.steps[stepNumberFromZero]
    : undefined

export const getSections = <T extends { [key in SectionProperty]: SectionContainer[key] }>(
  sectionsObj: T
): SectionObject[] =>
  sectionProperties
    .reduce((acc, property) => {
      return acc.concat(
        //@ts-ignore
        (property in sectionsObj ? sectionsObj[property] : []).map((section, index) => ({ property, section, index }))
      )
    }, [] as SectionObject[])
    //@ts-ignore
    .sort((a, b) => a.section.order - b.section.order)

export const getSlides = (
  sessionData: SessionEntityHydrated | false,
  state: State,
  supplementarySlides: SupplementarySlide[]
): HydratedSlide[] => {
  const { slides } = state
  const steps: ModuleStep[] = sessionData ? sessionData.module.steps : []
  return ([
    { type: HOLDING_SLIDE, order: -1 } as HoldingSlideRef,
    ...(steps || [])
      .filter(step => !!step)
      .map<HydratedModuleSlideRef>((step, index) => {
        const existingSlideRef = slides
          .filter(slide => !!slide) // this exists to curb an issue with previewing wherein for a single render cycle 'step' is null for reasons unknown
          .find(slide => slide.type === MODULE_SLIDE && slide.uid === step.uid)
        return {
          type: MODULE_SLIDE,
          uid: step.uid,
          order: existingSlideRef ? existingSlideRef.order : index,
          step,
        }
      }),
    ...(supplementarySlides || []).map(supplementarySlide => {
      return {
        type: SUPPLEMENTARY_SLIDE,
        uid: supplementarySlide.uid,
        order: supplementarySlide.order,
        supplementarySlide,
      } as HydratedSupplementarySlideRef
    }),
    { type: END_SLIDE, order: steps.length + supplementarySlides.length + 1, uid: 'SESSION_END' } as EndSlideRef,
  ] as HydratedSlide[]).sort((a, b) => a.order - b.order)
}

export const getSlideByUid = (
  sessionData: SessionEntityHydrated | false,
  state: State,
  supplementarySlides: SupplementarySlide[],
  uid: string | null | undefined
) =>
  uid
    ? getSlides(sessionData, state, supplementarySlides).find(
        slide => slide.type !== HOLDING_SLIDE && slide.uid === uid
      )
    : undefined
