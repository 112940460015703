import React, { CSSProperties, useState, useEffect, useRef } from 'react'
import Modal from 'app/Modal'
import {
  Panel,
  PanelProps,
  Padding,
  Spacer,
  TextInput,
  InputSize,
  Button,
  P,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  TAB_B_S,
} from 'common/ui'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { useHotkey } from 'utils/useHotkey'
import { getInputProps } from 'utils/virtualKeyboard'

interface Props {
  isOpen: boolean
  onSubmit: (value: string | null) => void
  label: string
  defaultValue?: string
  placeholder?: string
  charLimit?: number
  inputSize?: InputSize
  inputType?: string
  multiline?: boolean
  buttonText?: string
  width?: number | string
  shadow?: PanelProps['shadow']
  shadowColor?: PanelProps['shadowColor']
  style?: CSSProperties
  forceInput?: boolean
  beforeSubmit?: (value: string) => boolean
}

export const PromptModal: React.FC<Props> = ({
  isOpen,
  onSubmit,
  label,
  defaultValue = '',
  placeholder = '',
  charLimit = 0,
  inputSize = 's',
  inputType,
  multiline,
  buttonText = 'Submit',
  forceInput = false,
  width = 460,
  shadow,
  shadowColor,
  style = {},
  beforeSubmit = (v: string) => true,
}) => {
  const [value, setValue] = useState<string>(defaultValue)
  const [error, setError] = useState<boolean>(false)
  const textInputRef = useRef<HTMLInputElement | null>(null)
  const textareaInputRef = useRef<HTMLTextAreaElement | null>(null)

  const handleClose = () => {
    if (!forceInput) onSubmit(null)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (beforeSubmit(value)) {
      onSubmit(value)
    } else {
      setError(true)
    }
    return false
  }

  useHotkey('esc', handleClose, [])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isOpen) {
      setValue(defaultValue)
      setTimeout(() => {
        if (textInputRef.current) textInputRef.current.focus()
        if (textareaInputRef.current) textareaInputRef.current.focus()
      }, 50)
    }
  }, [isOpen])
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (value && error) setError(false)
  }, [value])
  /* eslint-enable react-hooks/exhaustive-deps */

  const inputProps: React.ComponentProps<typeof AutosizeTextareaInput> | React.ComponentProps<typeof TextInput> = {
    autoFocus: true,
    name: 'input',
    inputSize,
    value,
    placeholder,
    maxLength: charLimit || undefined,
    onChange: (e: any) => setValue(e.target.value),
    error,
    ...getInputProps(),
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <Panel
        shadow={shadow}
        shadowColor={shadowColor}
        padding="20px 30px 35px 30px"
        flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_B_S]}
        style={{ maxHeight: 'calc(100vh - 50px)', width, ...style }}>
        <Padding size="s" style={{ overflow: 'auto' }}>
          <P>{label}</P>
          <form onSubmit={handleSubmit}>
            {multiline ? (
              <AutosizeTextareaInput ref={textareaInputRef} {...inputProps} />
            ) : (
              <TextInput ref={textInputRef} {...inputProps} type={inputType} />
            )}
            {charLimit > 0 && (
              <div style={{ marginTop: 10, fontSize: 10 }}>
                {(value || '').length} / {charLimit} characters used
              </div>
            )}
            <Spacer size="m" />
            <Button size="s" type="submit" id="submit" disabled={forceInput && !value}>
              {buttonText}
            </Button>
          </form>
        </Padding>
      </Panel>
    </Modal>
  )
}
