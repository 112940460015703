import React, { useState } from 'react'
import { Skill } from 'skill-tracker/types'
import { AddUpdateSkillAction, ADD_UPDATE_SKILL } from 'skill-tracker/actionTypes'

import { Row, Column, Padding, Button, P, H2, Hr, Spacer } from 'common/ui'
import { SkillSlab } from '../common/SkillSlab'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { SymbolSelectModal } from '../../common/SymbolSelectModal'
import { SkillEditModal } from './SkillEditModal'
import { skillEmojis } from 'skill-tracker/common/SkillInput'

import { useUserState } from 'app/UserState'
import { useSkillTrackerState } from '../SkillTrackerState'
import { validateAddUpdateSkill } from 'skill-tracker/actionValidators'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { getConfirmedModuleSkills, getUsedSymbols } from '../reducers'

export const SkillsModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { state, dispatch } = useSkillTrackerState()
  const { getUserScopes, getBaseAction } = useUserState()
  const [editSkillId, setEditSkillId] = useState<string | null>(null)

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  const currentModule = state.modules.find(({ id }) => id === state.activeModuleId)
  const skills = getConfirmedModuleSkills(state.activeModuleId, state)
  const editSkill = state.skills.find(({ id }) => id === editSkillId)
  const isAdult = getUserScopes().indexOf('mentor') >= 0

  const handleSkillSave = (skill: Skill, stopEdit: boolean = true) => {
    const action: AddUpdateSkillAction = { ...getBaseAction(), type: ADD_UPDATE_SKILL, skill }
    if (validateAddUpdateSkill(action, state)) dispatch(action)
    else console.error(`Unable to validate update skill action`, action, state)
    if (stopEdit) setEditSkillId(null)
  }

  const handleSymbolChange = (symbol: string, skill: Skill) => handleSkillSave({ ...skill, symbol }, false)

  return (
    <>
      <MobileModalPanel
        isOpen={isOpen}
        onRequestClose={delayedOnClose}
        panelStyle={{ maxHeight: `calc(100vh - 50px)`, overflow: 'hidden' }}>
        <Column flex="none" style={{ maxHeight: `calc(100vh - 50px)` }}>
          <Row flex="none">
            <Column flex="1 1 auto">
              <Padding size="s">
                <H2 style={{ lineHeight: 0.95 }}>Target Skills</H2>
              </Padding>
            </Column>
            <Column>
              <Padding size="xs">
                <Button size="xs" onClick={delayedOnClose}>
                  ×
                </Button>
              </Padding>
            </Column>
          </Row>
          <Hr />
          <Column flex="0 1 auto" style={{ overflow: 'auto' }}>
            <Padding size="s">
              {!skills.length && !isAdult && (
                <P>
                  You currently don't have any target skills added, talk to your parent / supervisor to set these up
                  together.
                </P>
              )}
              {!skills.length && isAdult && (
                <P>There are currently no target skills set up, do this with your student / child.</P>
              )}
              {intersperseSpacers(
                skills.map((skill, i) => (
                  <SkillSlab
                    key={i}
                    skill={skill}
                    label={`Target skill ${i + 1}:`}
                    onEdit={() => setEditSkillId(skill.id)}
                  />
                )),
                's'
              )}
              <Spacer size="s" />
              <P style={{ margin: 0 }}>
                {isAdult
                  ? `Click each symbol to review, confirm or add your cadet’s skills practise`
                  : `Click a symbol to enter information about your skills practise`}
              </P>
            </Padding>
          </Column>
        </Column>
      </MobileModalPanel>
      {editSkill && isAdult && (
        <SkillEditModal initialValue={editSkill} onClose={() => setEditSkillId(null)} onSave={handleSkillSave} />
      )}
      {editSkill && !isAdult && (
        <SymbolSelectModal
          isOpen
          symbols={skillEmojis}
          disabledSymbols={currentModule ? getUsedSymbols(currentModule, state.skills) : []}
          value={editSkill.symbol}
          title={`Select a new symbol for this skill`}
          onChange={symbol => handleSymbolChange(symbol, editSkill)}
          onClose={() => setEditSkillId(null)}
        />
      )}
    </>
  )
}
