import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Panel } from 'common/ui'
import { HeaderTemplate } from '../common/HeaderTemplate'

import { useGadgetPackState } from '../GadgetPackState'
import { RelaxationGadgetEditInner } from 'e-telligence/RelaxationGadgetEdit'

export const RelaxationGadgetEdit: React.FC<{ baseUrl: string; isNew?: boolean }> = ({ baseUrl, isNew }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useGadgetPackState()
  console.log('EDIT LOCATION STATE', location.state)
  return (
    <HeaderTemplate
      title="Relaxation Gadgets"
      exitLabel="Back"
      onExit={() => history.push(baseUrl + '/relaxation-gadgets', location.state)}>
      <Panel>
        <RelaxationGadgetEditInner isNew={isNew} state={state} dispatch={dispatch} baseUrl={baseUrl} />
      </Panel>
    </HeaderTemplate>
  )
}
