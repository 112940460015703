import { ETelligenceState } from '../e-telligence/types'
import { ActionTypes } from './actionTypes'

export interface GadgetPackState extends ETelligenceState {
  idCard: IdCardState
  stressBall: StressBallState
  friendshipFormula: FriendshipFormulaState
  factFiles: FactFile[]
  friendometer: FriendometerState
  bionicPowers: BionicPowersState
}

export interface GadgetPackStateHookObject {
  state: GadgetPackState
  dispatch: (action: ActionTypes) => void
  loadingInitialState: boolean
}

export interface IdCardState {
  name: string
  symbol: string
  strengths: TextSymbol[]
}

export interface StressBallState {
  colorIndex: number
  mode: 'stress' | 'breathe'
}

export interface FriendshipFormulaState {
  formulae: { [key: number]: TextSymbol }
}

export interface FactFile {
  id: string
  name: string
  friendshipStrength: number
  why: Partial<TextSymbol>
  family: Partial<TextSymbol>
  interests: Partial<TextSymbol>
  other: Partial<TextSymbol>
}

export type FriendometerState = { [key in FriendshipStrength]: { [key in FriendometerValue]: string } }

export interface BionicPowersState {
  slots: { [key: number]: BionicPower['id'] | null }
  customBionicPowers: CustomBionicPower[]
}

//// MISC below here

export interface TextSymbol {
  text: string
  symbol: string
}

export type FriendometerValue = 'people' | 'description' | 'say' | 'do'
export type FriendshipStrength =
  | 'Strangers'
  | 'People you have seen around'
  | 'Kind-of Friends'
  | 'Friends'
  | 'Best Friends'
export const friendometerValues: FriendometerValue[] = ['people', 'description', 'say', 'do']
export const friendshipStrengths: FriendshipStrength[] = [
  'Strangers',
  'People you have seen around',
  'Kind-of Friends',
  'Friends',
  'Best Friends',
]

export interface BionicPower {
  id: string
  text: string
  image: string
}

export interface CustomBionicPower {
  id: string
  text: string
  symbol: string
}
