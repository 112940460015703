import { useRef, useEffect, useState } from 'react'
import socketIOClient from 'socket.io-client'

export function useSocket(url: string) {
  const socketRef = useRef<SocketIOClient.Socket>()
  const [, reRender] = useState<number>(0)
  useEffect(() => {
    socketRef.current = socketIOClient(url, { transports: ['websocket'], autoConnect: false })
    reRender(v => v + 1)
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect()
        socketRef.current = undefined
      }
    }
  }, [url])
  return socketRef.current
}
