import React, { useState, useRef, CSSProperties } from 'react'
import { Skill } from 'skill-tracker/types'

import { Row, Column, TextInput, Label } from 'common/ui'
import { AutosizeTextarea } from 'common/AutosizeTextarea'
import { SymbolSelectModal } from '../../common/SymbolSelectModal'
import { NumberSelectModal } from '../../common/NumberSelectModal'

import { SKILL_MIN_TARGET, SKILL_MAX_TARGET } from 'skill-tracker/constants/constants'
import { useSkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { getUsedSymbols } from 'skill-tracker/reducers'
import { supportsEmojis } from 'utils/deviceUtils'
import { getEmojiId } from 'utils/emojiUtils'
import { skillTrackerSkillEmojis } from 'constants/emojiSets'

export const skillEmojis = skillTrackerSkillEmojis
export const darkSkillEmojis = '🏍️🚲🦅🌘'

export const SkillInput: React.FC<{
  skill: Skill
  label?: string
  onChange: (id: Skill['id'], data: Partial<Skill>) => void
  readOnly?: boolean
}> = ({ skill, onChange, label = 'Target Skill:', readOnly = false }) => {
  const { state } = useSkillTrackerState()

  const textInputRef = useRef<HTMLTextAreaElement>(null)
  const symbolInputRef = useRef<HTMLInputElement>(null)
  const numberInputRef = useRef<HTMLInputElement>(null)

  const [emojiModalOpen, setEmojiModalOpen] = useState(false)
  const [numberModalOpen, setNumberModalOpen] = useState(false)

  const currentModule = state.modules.find(({ id }) => id === state.activeModuleId)

  const emojisSupported = supportsEmojis()
  const textInputStyles: CSSProperties =
    emojisSupported || !skill.symbol
      ? {}
      : {
          backgroundImage: `url('${require(`common/assets/emoji/${getEmojiId(skill.symbol)}.png`)}')`,
          backgroundSize: '1em',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }

  return (
    <>
      <Row style={{ width: '100%', textAlign: 'left' }} flexWrap>
        <Column flex="1 0 auto" paddingRight={10} paddingBottom={10}>
          <Label>{label}</Label>
          <AutosizeTextarea
            ref={textInputRef}
            value={skill.text}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(skill.id, { text: e.target.value || '' })}
            style={{ minHeight: 50, minWidth: 260 }}
            readOnly={readOnly}
          />
        </Column>
        <Column flex="none" paddingRight={10}>
          <Label>Symbol:</Label>
          <TextInput
            ref={symbolInputRef}
            value={emojisSupported ? skill.symbol || '' : ' '}
            style={{
              fontSize: 28,
              width: 60,
              height: 50,
              paddingTop: skill.symbol ? 12 : 5,
              textAlign: 'center',
              ...textInputStyles,
            }}
            readOnly
            placeholder="+"
            onClick={() => !readOnly && setEmojiModalOpen(true)}
          />
        </Column>
        <Column flex="none">
          <Label>Daily Target:</Label>
          <TextInput
            ref={numberInputRef}
            type="number"
            step={1}
            min={SKILL_MIN_TARGET}
            max={SKILL_MAX_TARGET}
            value={skill.dailyTarget || ''}
            readOnly={readOnly}
            onChange={(e) => onChange(skill.id, { dailyTarget: e.target.value ? parseInt(e.target.value) : 1 })}
            style={{ fontSize: 28, width: 75, height: 50 }}
            onMouseUp={() => !readOnly && setTimeout(() => setNumberModalOpen(true))}
            onTouchEnd={() => !readOnly && setTimeout(() => setNumberModalOpen(true))}
            onFocusCapture={(e) => {
              if (numberModalOpen) e.preventDefault()
            }}
          />
        </Column>
      </Row>
      <SymbolSelectModal
        isOpen={emojiModalOpen}
        symbols={skillEmojis}
        disabledSymbols={currentModule ? getUsedSymbols(currentModule, state.skills) : []}
        value={skill.symbol}
        onChange={(symbol) => {
          onChange(skill.id, { symbol })
          setTimeout(() => setEmojiModalOpen(false), 150)
        }}
        onClose={() => {
          setEmojiModalOpen(false)
          setTimeout(() => symbolInputRef.current && symbolInputRef.current.blur())
        }}
      />
      <NumberSelectModal
        isOpen={numberModalOpen}
        numbers={[...Array(SKILL_MAX_TARGET - SKILL_MIN_TARGET + 1)].map((x, i) => i + 1)}
        value={skill.dailyTarget}
        onChange={(dailyTarget) => {
          onChange(skill.id, { dailyTarget })
          setTimeout(() => setNumberModalOpen(false), 150)
        }}
        onClose={() => {
          setNumberModalOpen(false)
          setTimeout(() => numberInputRef.current && numberInputRef.current.blur())
        }}
      />
    </>
  )
}
