import React, { CSSProperties } from 'react'
import ReactModal from 'react-modal'
import { useScreenScaler } from 'common/ScreenScaler'
import { useVirtualKeyboard } from 'utils/virtualKeyboard'

const defaultModalStyles: CSSProperties = {
  top: '50%',
  right: 'initial',
  bottom: 'initial',
  left: '50%',
  width: 'auto',
  height: 'auto',
  maxWidth: 'calc(100vw - 50px)',
  maxHeight: 'calc(100vw - 50px)',
  transform: 'translate(-50%, -50%)',
  border: 'none',
  padding: 0,
  borderRadius: 0,
  overflow: 'initial',
  background: 'transparent',
}

const defaultModalOverlayStyles: CSSProperties = {
  zIndex: 999,
  background: 'rgba(0, 0, 0, 0.8)',
}

const defaultWrapperStyles: CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}

export interface Props extends ReactModal.Props {
  children: React.ReactNode
  minViewportHeight?: number
  maxViewportRatio?: number
  width?: number | string
  height?: number | string
  disableScaling?: boolean
}

const Modal = (props: Props) => {
  const { scale, zoom } = useScreenScaler(props.minViewportHeight, props.maxViewportRatio)
  const { open: virtualKeyboardOpen, preOpenViewportHeight, viewportHeight } = useVirtualKeyboard()
  const style: ReactModal.Styles = {
    content: {
      ...defaultModalStyles,
      // TO DO: make positing javascript based in order to have integer pixel translate positions
      // https://medium.com/@glaubermagal/fixing-weird-blurry-when-using-position-absolute-and-transform-translate-no-matter-percent-or-8885631ec088
      // @ts-ignore
      WebkitFontSmoothing: 'subpixel-antialiased',
      transform: `translate3d(0,0,0) translate(-50%, -50%) scale(${props.disableScaling ? 1 : scale})`,
      width: props.width === undefined ? defaultModalStyles.width : props.width,
      maxWidth: props.width === undefined ? defaultModalStyles.maxWidth : undefined,
      height: props.height === undefined ? defaultModalStyles.height : props.height,
      maxHeight: props.height === undefined ? defaultModalStyles.maxHeight : undefined,
    },
    overlay: defaultModalOverlayStyles,
  }
  if (virtualKeyboardOpen && style.overlay && style.content) {
    style.content.overflow = 'auto'
    // this should technically be a Math.min of the content height and viewportHeight*zoom
    style.content.maxHeight = viewportHeight * (props.disableScaling ? 1 : zoom)
  }

  const children = (
    <div style={virtualKeyboardOpen ? { ...defaultWrapperStyles, minHeight: preOpenViewportHeight * scale } : {}}>
      {props.children}
    </div>
  )

  if (props.style && props.style.content) style.content = { ...style.content, ...props.style.content }
  if (props.style && props.style.overlay) style.overlay = { ...style.overlay, ...props.style.overlay }
  return <ReactModal {...props} children={children} style={style} />
}

export default Modal
