import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import * as Sentry from '@sentry/browser'
// @ts-ignore
import objectFitImages from 'object-fit-images'
import { overrideIsStringIE11 } from 'phaser/utils/overrideIsStringIE11'

import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import 'workers/pushNotification.worker.js'

import { isProdEnv } from 'utils/envUtils'
import { disablePinchToZoomiOS, disableDoubleTapToZoomiOS } from 'utils/disableNativeTouchControls'

if (isProdEnv()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE,
    environment: window.location.host,
  })
}

objectFitImages()
disablePinchToZoomiOS()
disableDoubleTapToZoomiOS()
overrideIsStringIE11()

const appElem = document.getElementById('root')
if (appElem) ReactModal.setAppElement(appElem)
ReactDOM.render(<App />, appElem)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register()
