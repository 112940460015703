import React, { ReactNode } from 'react'

import { UserLookupEntity } from 'shared/dashboard/types'

import { Cell, CellInner } from 'dashboards/common/Cell'
import { SpinnerWithLabel } from 'common/Spinner'
import { P } from 'common/ui'

import { useEndpoint } from 'dashboards/utils/endpointHooks'

interface UserCellProps {
  fetchUrl?: string
  user?: UserLookupEntity
  children?: (user: UserLookupEntity) => ReactNode
}

export const UserCell: React.FC<UserCellProps> = ({ children, fetchUrl, user: defaultValue }) => {
  const [user, { loading, errorLoading, errorMessage }] = useEndpoint<UserLookupEntity>(fetchUrl || null, defaultValue)
  return (
    <Cell>
      <CellInner>
        {loading ? (
          <SpinnerWithLabel label="Loading user" color="#ddeaff" labelStyle={{ fontSize: 14 }} />
        ) : errorLoading || !user ? (
          <P style={{ margin: 0 }}>Error loading user{errorMessage && ': ' + errorMessage}</P>
        ) : (
          <P style={{ margin: 0 }}>
            <strong>{user.name || user.email} </strong>
            {/* {user.email && <a href={`mailto:${user.email}`} children={user.email} style={{ fontWeight: 400 }} />} */}
          </P>
        )}
        {user && children && children(user)}
      </CellInner>
    </Cell>
  )
}
