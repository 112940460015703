import React, { useState } from 'react'
import { Row, Button, Spacer } from 'common/ui'
import { AutoAddProgressionEvent } from 'session/ProgressionEventsState'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { SectionPropsBase } from 'shared/session/types'
import { useSessionState } from 'session/SessionState'
import { UPDATE_PARTICIPANT_TRAY_OPEN } from 'shared/session/actionTypes'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import { AddRelaxationGadgetAction, ADD_RELAXATION_GADGET } from 'shared/e-telligence/actionTypes'
import { useEffect } from 'react'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'
interface Props extends SectionPropsBase {
  property: 'code_card_sections'
  disabled?: boolean
}

export const CodeCardSection: React.FC<Props> = props => <CodeCardSectionInner {...props} />

export const CodeCardSectionInner: React.FC<Props> = ({ section, disabled }) => {
  const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const { type, category } = section
  const { dispatch: gadgetPackDispatch } = useGadgetPackState()
  const { dispatch, sessionProfile, getBaseAction, isFacilitator } = useSessionState()
  const profileUid = useParticipantProfileUid()

  useEffect(() => {
    if (category === 'relaxation-gadgets' && !isFacilitator) {
      gadgetPackDispatch({
        ...getBaseAction(),
        type: ADD_RELAXATION_GADGET,
        gadgetId: type,
      } as AddRelaxationGadgetAction)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    /**
     * This is a mystery in here somewhere:
     *
     * Anytime we cross a slide where the cadets must add a gadget to their gadget pack it essentially "crashes" the system.
     * The cadet activity tabs switched between colours (red, yellow, green, blue)
     */
    if (!clicked) {
      setClicked(true)
      setLoading(true)

      setTimeout(() => {
        setLoading(false)
        dispatch({
          ...getBaseAction(),
          type: UPDATE_PARTICIPANT_TRAY_OPEN,
          open: true,
          trayType: 'gadgets',
          participant: sessionProfile,
        })
      }, 1500)
    }
  }
  return (
    <>
      <AutoAddProgressionEvent
        key={`unlockProgressEvent_${profileUid}`}
        progressionEvent={{
          type: GADGET_PACK_UNLOCK,
          category: category !== null ? category : 'code-cards',
          subcategory: type,
          event_key: null,
          event_value: null,
        }}
      />
      <Row justifyContent="center" paddingTop="l" paddingBottom="l">
        <Button
          children={loading ? 'Adding....' : clicked ? `Added to Gadget Pack ✔︎` : `Add to Gadget Pack`}
          onClick={handleClick}
          disabled={clicked || disabled}
        />
      </Row>
    </>
  )
}

export const CodeCardSectionFacilitator: React.FC<Props> = props => {
  return (
    <>
      <Spacer size="m" />
      <CodeCardSection {...props} disabled />
    </>
  )
}
