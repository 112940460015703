import { BaseAction } from '../types'
import { FacManState as State } from './types'

export const UPDATE_STATE = 'UPDATE_FACMAN_STATE'
export const UPDATE_FACMAN_PAGE = 'UPDATE_FACMAN_PAGE'

export interface UpdateStateAction extends BaseAction {
  type: typeof UPDATE_STATE
  state: State
}

export interface UpdateFacManPageAction extends BaseAction {
  type: typeof UPDATE_FACMAN_PAGE
  stepUid: string // TODO ?
}

export type ActionTypes = UpdateStateAction | UpdateFacManPageAction
