import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { CadetAdd } from './CadetAdd'
import { useUserState } from 'app/UserState'
import { useParams } from 'react-router-dom'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'

export const CadetEdit: React.FC<NavRouteProps> = ({ route }) => {
  const { mentorUid } = useParams<{ groupUid: string; mentorUid: string }>()
  const { accessToken } = useUserState()
  return (
    <CadetAdd
      route={{ ...route, title: 'Edit consenting adult' }}
      editMode
      formProps={{
        initialDataUrl: `/api/v1/mentors/${mentorUid}`,
        initialDataRequestOptions: getAuthRequestParams(accessToken),
        submitUrl: `/api/v1/mentors/${mentorUid}`,
        submitRequestOptions: { ...getAuthRequestParams(accessToken), method: 'PUT' },
      }}
    />
  )
}
