import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Modal from 'app/Modal'
import PDA from './PDA'
import { useGlobalState } from 'app/GlobalState'

type Size = 'm' | 's'

interface Props {
  size?: Size
  onChange?: (open: boolean) => void
}

export const PDAModalButton: React.FC<Props> = ({ onChange, size = 'm' }) => {
  const { helpVisible } = useGlobalState()
  const [showPDA, setShowPDA] = useState(false)
  const handleChange = (open: boolean) => {
    setShowPDA(open)
    if (onChange) onChange(open)
  }

  useEffect(() => {
    if (helpVisible && showPDA) {
      setShowPDA(false)
    }
  }, [helpVisible, showPDA])

  return (
    <>
      <ArdonButton id="ardon-button" onClick={() => handleChange(true)} size={size} />
      {showPDA && (
        <Modal isOpen onRequestClose={() => handleChange(false)}>
          <PDA forceVisible shadowColor="black" />
        </Modal>
      )}
    </>
  )
}

const ArdonButton = styled.button<{ size: Size }>`
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  overflow: visible;
  position: relative;
  padding: 0;
  background: none;
  border: 0;
  margin: 0;
  border-radius: 50%;
  width: ${p => (p.size === 's' ? 48 : 70)}px;
  height: ${p => (p.size === 's' ? 48 : 70)}px;
  background: black url(${require('common/assets/image/ardon-small.png')}) no-repeat center / cover;
  cursor: pointer;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  transition: background-color 0.1s linear;
  flex: none;

  :hover {
    background-color: #fff;
  }
`
