import React, { useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { getSlideByUid, getSlides, getStepByUid } from './reducers'
import { useSessionState } from './SessionState'
import { fontLight, fontRegular } from 'fonts'

import {
  SessionProfile,
  ParticipantState,
  FacilitatorTool,
  MODULE_SLIDE,
  SUPPLEMENTARY_SLIDE,
  SupplementarySlide,
} from 'shared/session/types'
import { MentorEntity } from 'shared/dashboard/types'
import { PREVIEW_HEADER_HEIGHT } from './PreviewHeader'
import {
  UPDATE_FACILITATOR_PARTICIPANT_VIEW,
  ADD_TOKEN_EVERYONE,
  REMOVE_TOKEN_EVERYONE,
  ADD_PARTICIPANT_TOKEN,
  UPDATE_FACILITATOR_TOOL,
  UPDATE_TOKEN_TARGET,
  TOGGLE_PARTICIPANT_LOCK,
  TOGGLE_GLOBAL_LOCK,
  UPDATE_PARTICIPANT_TOKEN_MISSION,
} from 'shared/session/actionTypes'

import { Fixed, Row, Column, Label, Input, H2, H3, Hr, Button, Spacer } from 'common/ui'
import { CircleButton } from './common/CircleButton'
import { Logo } from '../common/Logo'
import { Timer } from './common/Timer'
import { NavColumn } from './common/NavColumn'
import { InputContext, useInputContext, useSavedInputValueObjects } from './InputContext'
import { FacilitatorFooterTabs } from './FacilitatorTray'
import { FacilitatorSlideNav } from './FacilitatorSlideNav'
import { ParticipantTab, Tab, TabRow, TokenWrapper } from './ParticipantTab'

import { useFacilitatorState } from 'session/hooks/useProfileState'
import { useUserInputState } from './UserInputState'
import { useMeasure } from 'utils/useMeasure'
import { DebouncedInputComponent } from './common/DebouncedInputComponent'
import { PreviewGadgetPackStateProvider } from './PreviewGadgetPackStateProvider'
import { SVG } from 'common/SVG'
import { ConfirmModal } from 'common/ConfirmModal'
import { useHistory } from 'react-router-dom'
import { getNextPromptCount } from 'utils/routeUtils'
import { FacilitatorChecklistPrintPayload } from './printable/FacilitatorChecklistPrint'
import { createPdf } from 'api'
import { useUserState } from 'app/UserState'
import { PdfModal, PdfStatus } from 'common/PdfModal'

export const SIDEBAR_WIDTH = 210

const cadetIsStillInGroup = (cadetProfile: SessionProfile, groupPrimaryMentors: MentorEntity[]): boolean => {
  if (cadetProfile.userType !== 'agent') return true // if they're not cadets then we don't care
  const cadetProfileId = parseInt(cadetProfile.uid.replace('sas-', ''))
  if (isNaN(cadetProfileId)) return true // better to fail positive in this case
  return !!(groupPrimaryMentors || []).find((mentor) => mentor.cadet_profile_id === cadetProfileId)
}

export const FacilitatorUi: React.FC = ({ children }) => {
  const history = useHistory()
  const facilitatorState = useFacilitatorState()

  const checklistItems = useSavedInputValueObjects<boolean>({
    owner: 'Steps',
    owner_id: undefined,
    name: undefined,
    participant_uid: 'shared',
  })
  const { accessToken } = useUserState()

  const inputContext = useInputContext()
  const { sessionData, state, dispatch, previewing, getBaseAction, isAssistant, pastMode } = useSessionState()
  const { tokenTarget, facilitators } = state

  const { getSavedInputValues } = useUserInputState()
  const savedSupplementarySlides = getSavedInputValues<SupplementarySlide>('shared', {
    owner: 'supplementary_slides',
  })
  const supplementarySlides = savedSupplementarySlides.map(({ value }) => value)

  const [scrollbarHeight, setScrollbarHeight] = useState<number>(0)
  const [tokenMissionOpen, setTokenMissionOpen] = useState<boolean>(false)
  const [showTokenConfirm, setShowTokenConfirm] = useState<boolean>(false)
  const [showExitConfirm, setShowExitConfirm] = useState<boolean>(false)
  const [facChecklistPdfStatus, setFacChecklistPdfStatus] = useState<PdfStatus>('ready')
  const [facChecklistPdfUrl, setFacChecklistPdfUrl] = useState('')

  if (!facilitatorState) {
    // throw new Error('Facilitator state not found :(')
    return null
  }
  const {
    currentSlideUid: _currentSlideUid,
    currentPreviewSlideUid: _currentPreviewSlideUid,
    focusedParticipantUid,
    selectedTool: facilitatorTool,
  } = facilitatorState

  const currentSlideUid = previewing ? 'PREVIEW_STEP' : _currentSlideUid
  const currentPreviewSlideUid = previewing ? 'PREVIEW_STEP' : _currentPreviewSlideUid
  const visibleSlideUid = currentPreviewSlideUid || currentSlideUid
  const visibleSlide =
    sessionData && visibleSlideUid ? getSlideByUid(sessionData, state, supplementarySlides, visibleSlideUid) : undefined
  const previewingDifferentSlide =
    currentPreviewSlideUid &&
    currentSlideUid !== currentPreviewSlideUid &&
    (visibleSlide?.type === MODULE_SLIDE || visibleSlide?.type === SUPPLEMENTARY_SLIDE)

  const participantUids = state.participants.map(({ profile }) => profile.uid)
  const groupViewActive = !focusedParticipantUid || participantUids.indexOf(focusedParticipantUid) < 0
  const focusedParticipant = !focusedParticipantUid
    ? undefined
    : state.participants.find(({ profile }) => profile.uid === focusedParticipantUid)

  const isCadetModule = sessionData && sessionData.module.type === 'cadet'

  const handleFacChecklistDownload = () => {
    const slides = getSlides(sessionData, state, [])

    const templateUrl = `${window.location.origin}/print/session/checklist`
    const payload: FacilitatorChecklistPrintPayload = {
      module: sessionData ? sessionData.module.title : '',
      date: new Date().toLocaleDateString(),
      sections: [],
    }
    slides.forEach((slide) => {
      if (slide.type !== 'MODULE_SLIDE' || !sessionData) return
      const currentStep = getStepByUid(sessionData, slide.uid)
      if (!currentStep) return
      payload.sections.push({
        title: currentStep.title || '',
        checklist: (currentStep.facilitator_steps || []).map((checklistItem, idx) => {
          const inputValue = checklistItems.find(
            ({ name, owner_id }) =>
              name === `Facilitator_${sessionData.group_id}_ChecklistItem_${idx}` && owner_id === currentStep.id
          )
          return { label: checklistItem, value: inputValue ? inputValue.value : false }
        }),
      })
    })
    setFacChecklistPdfStatus('busy')
    createPdf<FacilitatorChecklistPrintPayload>(templateUrl, payload, accessToken)
      .then((pdf) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setFacChecklistPdfUrl(pdf.url)
          setFacChecklistPdfStatus('success')
        })
      })
      .catch(() => setFacChecklistPdfStatus('error'))
  }

  const handleFacilitatorViewChange = (participant: null | SessionProfile) => {
    // if(participant && participant.uid !== focusedParticipantUid && tokenMissionOpen) setTokenMissionOpen(false)
    dispatch({
      ...getBaseAction(),
      type: UPDATE_FACILITATOR_PARTICIPANT_VIEW,
      facilitatorUid: facilitatorState.profile.uid,
      participant,
    })
  }

  const handleParticipantTokenAdd = (participant: SessionProfile) => {
    dispatch({ ...getBaseAction(), type: ADD_PARTICIPANT_TOKEN, participant })
  }

  const handleEveryoneGetToken = () => {
    dispatch({ ...getBaseAction(), type: ADD_TOKEN_EVERYONE })
  }

  const handleTokenTargetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strValue = e.target.value
    const tokenTarget = parseInt(strValue)
    if (isNaN(tokenTarget)) return
    dispatch({ ...getBaseAction(), type: UPDATE_TOKEN_TARGET, tokenTarget })
  }

  const handleResetTokens = (confirmed: boolean) => {
    setShowTokenConfirm(false)
    if (confirmed) dispatch({ ...getBaseAction(), type: REMOVE_TOKEN_EVERYONE })
  }

  const handleToolToggle = (tool: FacilitatorTool) => {
    if (!pastMode)
      dispatch({
        ...getBaseAction(),
        type: UPDATE_FACILITATOR_TOOL,
        facilitatorUid: facilitatorState.profile.uid,
        tool: facilitatorTool === tool ? null : tool,
      })
  }

  const handleLockToggle = (participant: ParticipantState) => {
    if (!pastMode)
      dispatch({
        ...getBaseAction(),
        type: TOGGLE_PARTICIPANT_LOCK,
        participantUid: participant.profile.uid,
      })
  }

  const handleGlobalLockToggle = () => {
    if (!pastMode)
      dispatch({
        ...getBaseAction(),
        type: TOGGLE_GLOBAL_LOCK,
      })
  }

  const handleScrollbarHeight = (scrollbarHeight: number) => {
    setScrollbarHeight(scrollbarHeight)
  }

  const handleTokenMissionEdit = (participant: ParticipantState) => {
    if (!pastMode) setTokenMissionOpen((v) => !v)
  }

  const handleUpdateParticipantTokenMission = (participant: ParticipantState, tokenMission: string) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_PARTICIPANT_TOKEN_MISSION,
      participantUid: participant.profile.uid,
      tokenMission,
    })
  }

  const handleExit = (confirmed: boolean) => {
    setShowExitConfirm(false)
    if (confirmed) history.push('/facilitator/dashboard/meetings', { ignoreRouterPrompt: getNextPromptCount() })
  }

  return (
    <>
      <Fixed cover style={{ top: previewing ? PREVIEW_HEADER_HEIGHT : 0 }}>
        <Row style={{ width: '100%', maxWidth: '100vw' }}>
          <Sidebar>
            <SidebarHead>
              {pastMode && (
                <>
                  <Column padding="12px" style={{ background: '#FF8A00' }}>
                    <H2 align="center" style={{ color: '#fff' }}>
                      Reviewing
                    </H2>
                  </Column>
                  <Hr />
                </>
              )}
              <Row padding="15px" justifyContent="space-between">
                <Button
                  theme="purple"
                  size="xs"
                  onClick={() =>
                    pastMode ? history.push('/facilitator/dashboard/meetings') : setShowExitConfirm(true)
                  }>
                  <svg width="20" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.8962 6.33267H2.78388L6.76493 2.35162C7.08309 2.03346 7.08309 1.51135 6.76493 1.1932C6.68947 1.11756 6.59982 1.05755 6.50113 1.01661C6.40244 0.975665 6.29665 0.95459 6.1898 0.95459C6.08296 0.95459 5.97716 0.975665 5.87847 1.01661C5.77978 1.05755 5.69014 1.11756 5.61467 1.1932L0.238618 6.56925C-0.0795395 6.88741 -0.0795395 7.40135 0.238618 7.71951L5.61467 13.0956C5.93283 13.4137 6.44678 13.4137 6.76493 13.0956C7.08309 12.7774 7.08309 12.2635 6.76493 11.9453L2.78388 7.96425H11.8962C12.3449 7.96425 12.712 7.59714 12.712 7.14846C12.712 6.69978 12.3449 6.33267 11.8962 6.33267Z"
                      fill="white"
                    />
                  </svg>
                </Button>
                <Logo fill="#7D41DF" />
              </Row>
              <Hr />
              <Column padding="12px">
                <H2 align="center">{sessionData && sessionData.module.title}</H2>
              </Column>
              <Hr />
              {facilitators.length > 1 && (
                <>
                  <Column padding="10px">
                    <FieldLabel>Facilitators: {facilitators.length}</FieldLabel>
                  </Column>
                  <Hr />
                </>
              )}
              {currentSlideUid && (
                <>
                  <Column padding="10px">
                    <Timer />
                  </Column>
                  <Hr />
                </>
              )}
              {isCadetModule && (
                <>
                  <Column padding="10px">
                    <FieldLabel>Module Token Target</FieldLabel>
                    <Row>
                      <Column justifyContent="center">
                        <Button theme="purple" size="xs" onClick={() => setShowTokenConfirm(true)} disabled={pastMode}>
                          <SVG viewboxSize={22} size={20}>
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                              fill="#fff"
                              d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
                            />
                          </SVG>
                        </Button>
                      </Column>
                      <Spacer />
                      <ModuleTokenTarget
                        type="number"
                        min={0}
                        max={99}
                        step={1}
                        theme="purple"
                        value={String(tokenTarget)}
                        onChange={handleTokenTargetChange}
                        disabled={isAssistant || pastMode}
                      />
                    </Row>
                  </Column>
                  <Hr />
                </>
              )}
              {pastMode && (
                <>
                  <Column padding={[0, 10]}>
                    <Spacer />
                    <Button
                      theme="purple"
                      size="xs"
                      onClick={handleFacChecklistDownload}
                      children={facChecklistPdfStatus === 'busy' ? 'Processing...' : 'Download Checklist'}
                      disabled={facChecklistPdfStatus === 'busy'}
                    />
                    <Spacer />
                  </Column>
                  <Hr />
                </>
              )}
            </SidebarHead>
            <FacilitatorSlideNav />
          </Sidebar>
          <Main>
            <Body pastMode={pastMode}>
              <ViewTabsContainer>
                <Tabs style={{ minHeight: 45 + scrollbarHeight }}>
                  <Tab active={groupViewActive} onClick={() => handleFacilitatorViewChange(null)}>
                    <TabRow style={{ height: '100%', borderRight: '5px solid #e7e9ee' }}>
                      <H3 style={{ margin: '0 10px' }}>Group</H3>
                      {isCadetModule && (
                        <TokenWrapper>
                          <Label>Tokens</Label>
                          <CircleButton
                            theme="purple"
                            size="xs"
                            style={{ fontSize: 16, marginLeft: 5 }}
                            children="+"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleEveryoneGetToken()
                            }}
                            disabled={pastMode}
                          />
                        </TokenWrapper>
                      )}
                      {!previewingDifferentSlide && (
                        <>
                          <ToolButton
                            active={facilitatorTool === 'highlight'}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleToolToggle('highlight')
                            }}
                            disabled={pastMode}>
                            <img src={require('./assets/icon-highlight.svg')?.default} alt="Highlight" />
                          </ToolButton>
                          <ToolButton
                            onClick={(e) => {
                              e.stopPropagation()
                              handleGlobalLockToggle()
                            }}
                            disabled={pastMode}>
                            <img
                              src={
                                require(state.globalLock ? './assets/icon-locked.svg' : './assets/icon-lock.svg')
                                  ?.default
                              }
                              alt="Lock"
                            />
                          </ToolButton>
                        </>
                      )}
                    </TabRow>
                  </Tab>
                  <Column flex="1 1 auto">
                    {previewingDifferentSlide ? (
                      <ParticipantTabs onScrollbarHeightChange={handleScrollbarHeight}>
                        <Tab active={true} onClick={() => {}} style={{ height: '100%', cursor: 'auto' }}>
                          <TabRow style={{ height: '100%', justifyContent: 'center' }}>
                            <WarningBox
                              color={visibleSlide?.type === SUPPLEMENTARY_SLIDE ? 'purple' : 'orange'}
                              children={visibleSlide?.type === SUPPLEMENTARY_SLIDE ? 'Supplementary' : 'Previewing'}
                            />
                          </TabRow>
                        </Tab>
                        {visibleSlide?.type === SUPPLEMENTARY_SLIDE &&
                          state.participants
                            .filter(({ currentSlideUid }) => currentSlideUid === visibleSlide.uid)
                            .map((participant, i) => (
                              <ParticipantTab
                                key={`${participant.profile.uid}${i}`}
                                index={i}
                                participant={participant}
                                useTokens={isCadetModule}
                                active={focusedParticipantUid === participant.profile.uid}
                                tokenMissionOpen={focusedParticipantUid === participant.profile.uid && tokenMissionOpen}
                                onSelect={() => handleFacilitatorViewChange(participant.profile)}
                                onAddToken={() => handleParticipantTokenAdd(participant.profile)}
                                onLockSelect={() => handleLockToggle(participant)}
                                onTokenMissionEdit={() => handleTokenMissionEdit(participant)}
                                globalLock={state.globalLock}
                                pastMode={pastMode}
                                noLongerInGroup={
                                  !cadetIsStillInGroup(
                                    participant.profile,
                                    (sessionData && sessionData.group?.primary_mentors) || []
                                  )
                                }
                              />
                            ))}
                      </ParticipantTabs>
                    ) : (
                      <ParticipantTabs onScrollbarHeightChange={handleScrollbarHeight}>
                        {(pastMode && visibleSlide?.type === 'SUPPLEMENTARY_SLIDE'
                          ? state.participants.filter((participant) =>
                              visibleSlide.supplementarySlide.participantUids.includes(participant.profile.uid)
                            )
                          : state.participants
                        )
                          .filter(({ currentSlideUid }) => currentSlideUid === visibleSlideUid)
                          .map((participant, i) => (
                            <ParticipantTab
                              key={`${participant.profile.uid}${i}`}
                              index={i}
                              participant={participant}
                              useTokens={isCadetModule}
                              active={focusedParticipantUid === participant.profile.uid}
                              tokenMissionOpen={focusedParticipantUid === participant.profile.uid && tokenMissionOpen}
                              onSelect={() => handleFacilitatorViewChange(participant.profile)}
                              onAddToken={() => handleParticipantTokenAdd(participant.profile)}
                              onLockSelect={() => handleLockToggle(participant)}
                              onTokenMissionEdit={() => handleTokenMissionEdit(participant)}
                              globalLock={state.globalLock}
                              pastMode={pastMode}
                              noLongerInGroup={
                                !cadetIsStillInGroup(
                                  participant.profile,
                                  (sessionData && sessionData.group?.primary_mentors) || []
                                )
                              }
                            />
                          ))}
                      </ParticipantTabs>
                    )}
                  </Column>
                </Tabs>
                <TabContent id="ParticipantView">
                  {tokenMissionOpen && focusedParticipant && (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        setTokenMissionOpen(false)
                        return false
                      }}>
                      <TokenMission>
                        <DebouncedInputComponent<string>
                          value={focusedParticipant.tokenMission}
                          onChange={(value) => handleUpdateParticipantTokenMission(focusedParticipant, value)}
                          children={({ value, onChange }) => (
                            <Input
                              value={value}
                              onChange={(e) => onChange(e.target.value || '')}
                              placeholder={`Enter token mission for ${focusedParticipant.profile.displayName}...`}
                              disabled={pastMode}
                            />
                          )}
                        />
                      </TokenMission>
                    </form>
                  )}
                  <PreviewGadgetPackStateProvider>
                    <InputContext.Provider
                      children={children}
                      value={{
                        ...inputContext,
                        participant_uid: focusedParticipant
                          ? focusedParticipant.profile.uid
                          : inputContext.participant_uid,
                      }}
                    />
                  </PreviewGadgetPackStateProvider>
                </TabContent>
              </ViewTabsContainer>
              {!pastMode && <FacilitatorFooterTabs currentSlide={visibleSlide} pastMode={pastMode} />}
            </Body>
          </Main>
        </Row>
      </Fixed>
      <ConfirmModal
        isOpen={showTokenConfirm}
        onSubmit={handleResetTokens}
        label="Confirming will clear all tokens for all cadets in this session."
      />
      <ConfirmModal
        isOpen={showExitConfirm}
        onSubmit={handleExit}
        label='Warning: If you have just finished a Live Meeting, please return to the meeting, go to the End Meeting screen and select "Close Live Meeting."'
      />
      <PdfModal
        status={facChecklistPdfStatus}
        url={facChecklistPdfUrl}
        onClose={() => setFacChecklistPdfStatus('ready')}
        onCancel={() => setFacChecklistPdfStatus('ready')}
        text1="Generating PDF"
      />
    </>
  )
}

const ParticipantTabs: React.FC<{ onScrollbarHeightChange: (h: number) => void }> = ({
  children,
  onScrollbarHeightChange,
}) => {
  const divRef = useRef<HTMLDivElement | null>(null)
  const [measureContainer, { height }] = useMeasure()
  useEffect(() => {
    if (divRef.current) {
      onScrollbarHeightChange(divRef.current.offsetHeight - divRef.current.clientHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height])
  return (
    <ParticipantTabsInner
      children={children}
      ref={(ref) => {
        measureContainer(ref)
        divRef.current = ref
      }}
    />
  )
}

const TokenMission = styled(Column)`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  z-index: 100;
  & > input {
    box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.25);
  }
`

const WarningBox = styled.div<{ color: 'purple' | 'orange' }>`
  margin: 0 10px;
  color: ${(p) => (p.color === 'orange' ? '#ff8a00' : '#7d41df')};
  border: 2px solid ${(p) => (p.color === 'orange' ? '#ff8a00' : '#7d41df')};
  padding: 3px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
`

const TabContent = styled(Column)`
  border: 1px solid #cbd2e8;
  flex: 1 1 auto;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;

  .ReactModal__Content > div {
    max-height: calc(100% - 50px);
  }
`

const ToolButton = styled(NavColumn)`
  width: 46px;
  height: 46px;
  cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
`

const ViewTabsContainer = styled(Column)`
  flex: 1 1 auto;
`

const Tabs = styled(Row)`
  max-width: 100%;
  min-height: 46px;
`

const ParticipantTabsInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  border-top-right-radius: 10px;
  & > ${Tab} {
    display: inline-block;
    &:first-child {
      border-top-left-radius: 0;
    }
  }
`

const Sidebar = styled(Column)`
  background-color: #edf2fa;
  border-right: 1px solid #c4cae5;
  width: ${SIDEBAR_WIDTH}px;
`

const SidebarHead = styled(Column)`
  flex: none;
  width: ${SIDEBAR_WIDTH}px;
`

const FieldLabel = styled(Label)`
  ${fontRegular}
  text-align: center;
  color: #808ca2;
  font-size: 16px;
`

const ModuleTokenTarget = styled(Input)`
  ${fontLight}
  box-sizing: border-box;
  width: calc(100% - 55px);
  padding: 0px 0px 0px 12px;
  padding-bottom: 3px;
  letter-spacing: 0.1em;
  font-size: 28px;
  text-align: center;
  color: #7d41df;
  &:disabled {
    background-color: #eee;
    pointer-events: none;
  }
`

const Main = styled(Column)`
  max-width: calc(100vw - ${SIDEBAR_WIDTH}px);
  flex: 1 1 auto;
  border-left: 1px solid #fff;
`

const Body = styled.main<{ pastMode?: boolean }>`
  position: relative;
  flex: 1 1 auto;
  /* overflow: auto; */
  background-color: #464b61;
  /* color: #fff; */
  padding: 10px;
  display: flex;
  padding-bottom: ${(p) => (p.pastMode ? 10 : 55)}px;
`
