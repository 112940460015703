import { GameObjects } from 'phaser'

import HelpfulThoughtsScene from './HelpfulThoughtsScene'

export class Lazer {
  scene: HelpfulThoughtsScene
  container: GameObjects.Container
  lazer: GameObjects.Image

  constructor(
    scene: HelpfulThoughtsScene,
    x: number,
    y: number,

  ) {

    // Lazer Properties
    this.scene = scene

    // Lazer
    this.lazer = new GameObjects.Image(scene, 0, 0, 'lazer')
    this.lazer.setAlpha(0)
    
    // Lazer Container
    this.container = new GameObjects.Container(scene, 0, 0, [this.lazer])
    this.container
        .setPosition(x, y)
        .setScale(.3, 1)
    scene.add.existing(this.container)
  }

}
