import React from 'react'
import { blue } from 'themes'

import { MediaAsset, TableOfContentsActivity } from 'shared/session/types'

import { SpinnerWithLabel } from 'common/Spinner'
import { Column, P, Row, Spacer } from 'common/ui'
import { CellInner } from './Cell'

import { Link } from 'react-router-dom'
import { Media } from 'session/common/Media'
import { RichText } from 'session/common/RichText'
import styled from 'styled-components'

export interface TableOfContentsResponse {
  enabled: boolean
  overview: string | null
  steps: Array<{
    id: number
    uid: string
    title: string | null
    description: string | null
    activities: TableOfContentsActivity[]
    image: MediaAsset | null
  }>
}

interface TableOfContentsProps {
  contents: TableOfContentsResponse | null
  loading: boolean
  meetingUrl: string
}

export const TableOfContents: React.FC<TableOfContentsProps> = ({ loading, contents, meetingUrl }) => {
  if (!loading && !contents) return null

  if (loading && !contents) {
    return (
      <CellInner>
        <SpinnerWithLabel color={blue.buttonBorderTopColor} label="Loading table of contents..." size={20} />
      </CellInner>
    )
  }

  return (
    <>
      {contents && contents.overview && (
        <CellInner>
          <Column flex="1 1 auto">
            <RichText text={contents.overview} />
            <Spacer size="l" />
          </Column>
        </CellInner>
      )}
      {contents &&
        (contents.steps || []).map(step => (
          <CellInnerStyled key={step.uid}>
            <Link
              to={`${meetingUrl}#${step.uid}`}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                textDecoration: 'none',
                color: '#001947',
                padding: '10px 12px',
              }}>
              {step.image ? (
                <>
                  <Row alignItems="center">
                    <BlockMedia style={{ marginRight: '1rem' }}>
                      <div>
                        <Media type="image" image={step.image} widthPreset={'48w'} ratio={'1:1'} />
                      </div>
                    </BlockMedia>
                    <P style={{ margin: 0 }}>{step.title}</P>
                  </Row>
                  {'>'}
                </>
              ) : (
                <>
                  <P style={{ margin: 0 }}>{step.title}</P>
                  {'>'}
                </>
              )}
            </Link>
            {/* {step.activities.length > 0 && (
            <UL>
              {step.activities.map((activity, i) => (
                <li key={i}>
                  <P style={{ margin: 0, fontWeight: 400, fontSize: 14 }}>{activityLabels[activity]}</P>
                </li>
              ))}
            </UL>
          )} */}
          </CellInnerStyled>
        ))}
    </>
  )
}

const CellInnerStyled = styled(CellInner)`
  margin: 0;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f2f2f2;
  }
`
const BlockMedia = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  & img,
  & video {
    display: block;
    width: 100%;
    max-width: 100%;
  }
`
