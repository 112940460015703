import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { ETelligenceState as State, Gadget, CodeCard } from 'shared/e-telligence/types'
import {
  REMOVE_RELAXATION_GADGET,
  RemoveRelaxationGadgetAction,
  REMOVE_CODE_CARD,
  RemoveCodeCardAction,
  ActionTypes,
} from 'shared/e-telligence/actionTypes'
import { presetGadgets } from './constants/gadgets'

import { Column, Padding, RowWrapPortrait, Spacer, Row, H3, OutlineButton } from 'common/ui'
import { Hint } from 'common/Hint'
import { Navigation, baseUrl } from './common/Navigation'
import { EmotionometerTile } from './common/EmotionometerTile'
import { GadgetTile } from './common/GadgetTile'
import { CodeCardTile } from './common/CodeCardTile'
import { CardFlip } from 'common/CardFlip'
import { ETellPanel } from './common/ETellPanel'

import { useETelligenceState } from './ETelligenceState'
import { useUserState } from 'app/UserState'
import { useMedia } from 'utils/useMedia'
import { findGadgetById, findCodeCardById } from './stateUtils'

export const CardHolder: React.FC = () => {
  const history = useHistory()
  const { usingIOSvhFix } = useUserState()
  const { state, dispatch } = useETelligenceState()
  return (
    <ETellPanel style={{ height: usingIOSvhFix ? '100%' : 'calc(100vh - 20px)' }}>
      <Navigation title="Card Holder" buttonLabel="Quit" buttonPress={() => history.push('/game')} />
      <Column flex="1 1 auto" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
        <CardHolderInner baseUrl={baseUrl} state={state} dispatch={dispatch} />
      </Column>
    </ETellPanel>
  )
}

export const CardHolderInner: React.FC<{ baseUrl: string; state: State; dispatch: (action: ActionTypes) => void }> = ({
  baseUrl,
  state,
  dispatch,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { getBaseAction } = useUserState()
  const portrait = useMedia('(orientation: portrait)')

  const removedGadgetPlaceholders = useRef<{ [key: number]: Gadget }>({})
  const [flippedGadgets, setFlippedGadgets] = useState<boolean[]>(() => {
    const returnVal = [...Array((state.gadgetIds?.length || 0) + 3)].map(_ => true)
    if (location.state && location.state.showGadget) {
      const gadgetToShow = findGadgetById(location.state.showGadget, state)
      if (gadgetToShow) {
        const gadgetIndex = state.gadgetIds.indexOf(gadgetToShow.id)
        returnVal[gadgetIndex] = false
        setTimeout(() => setFlippedGadgets(returnVal.map((val, i) => (i === gadgetIndex ? true : val))), 250)
      }
    }
    if (location.state && location.state.hideGadget) {
      const gadgetToHide = [...presetGadgets, ...state.customGadgets].find(({ id }) => id === location.state.hideGadget)
      if (gadgetToHide) {
        const gadgetIndex = location.state.gadgetIndex as number
        removedGadgetPlaceholders.current[gadgetIndex] = gadgetToHide
        returnVal[gadgetIndex] = false
        setTimeout(() => setFlippedGadgets(returnVal.map((val, i) => (i === gadgetIndex ? true : val))), 250)
      }
    }
    return returnVal
  })

  const { emotionometers = [], gadgetIds = [], codeCardIds = [] } = state
  const angerEmotionometer = emotionometers.find(({ type }) => type === 'anger')
  const anxietyEmotionometer = emotionometers.find(({ type }) => type === 'anxiety')

  const handleRemoveGadget = (gadget: Gadget) => {
    dispatch({
      ...getBaseAction(),
      type: REMOVE_RELAXATION_GADGET,
      gadgetId: gadget.id,
    } as RemoveRelaxationGadgetAction)
  }

  const handleRemoveCodeCard = (codeCard: CodeCard) => {
    dispatch({
      ...getBaseAction(),
      type: REMOVE_CODE_CARD,
      codeCardId: codeCard.id,
    } as RemoveCodeCardAction)
  }

  useEffect(() => {
    if (location.hash) {
      const el = document.querySelector(location.hash)
      if (el) el.scrollIntoView(true)
    }
  }, [location.hash])

  return (
    <Padding size="s">
      <Row paddingBottom="s">
        <H3>My Emotionometers</H3>
        <Spacer size="xs" />
        <Hint text="These are scales that measure degrees of emotions. They also show the relaxation gadgets you can use to calm down for different strengths of an emotion." />
      </Row>
      <RowWrapPortrait className="emotionometerRow" style={{ borderBottom: '1px solid #abb4db' }} paddingBottom="m">
        <Column flex="1 1 0px">
          <EmotionometerTile
            emotionometer={angerEmotionometer}
            onSelect={() => history.push(baseUrl + '/emotionometer/anger', { skipCardHolder: false })}
            onEdit={() => history.push(baseUrl + '/emotionometer/anger/edit', { skipCardHolder: false })}
            placeholder="Create Anger Emotionometer +"
          />
        </Column>
        <Spacer size="s" flex="none" />
        <Column flex="1 1 0px">
          <EmotionometerTile
            emotionometer={anxietyEmotionometer}
            onSelect={() => history.push(baseUrl + '/emotionometer/anxiety', { skipCardHolder: false })}
            onEdit={() => history.push(baseUrl + '/emotionometer/anxiety/edit', { skipCardHolder: false })}
            placeholder="Create Anxiety Emotionometer +"
          />
        </Column>
      </RowWrapPortrait>
      <Row className="cardsRow" style={{ flexDirection: portrait ? 'column' : 'row' }}>
        <Column className="relaxationGadgetsColumn" flex="1 0 50%" style={{ width: '100%' }}>
          <Row>
            <Column
              flex="1 1 auto"
              style={{
                width: '100%', // for ie11
                [portrait ? 'borderBottom' : 'borderRight']: '1px solid #abb4db',
              }}
              paddingBottom={portrait ? 'xs' : undefined}>
              <Row paddingBottom="s" paddingTop="m">
                <H3>My Relaxation Gadgets</H3>
                <Spacer size="xs" />
                <Hint text="These are strategies you can use to feel happier, calmer and braver, and to make smart choices." />
              </Row>
              <Row flexWrap justifyContent="flex-start">
                {[...gadgetIds, undefined]
                  .map(id => findGadgetById(id, state))
                  .map((gadget, i) => (
                    <Column key={i} flex="1 1 106px" paddingBottom="s" paddingRight={10} id={gadget?.id}>
                      <CardFlip
                        containerStyle={{ maxWidth: 96 }}
                        isFlipped={flippedGadgets[i]}
                        children={[
                          <GadgetTile
                            gadget={removedGadgetPlaceholders.current[i] || undefined}
                            onEdit={() => history.push(baseUrl + '/relaxation-gadgets', { skipCardHolder: false })}
                          />,
                          <GadgetTile
                            gadget={gadget}
                            onEdit={() =>
                              history.push(baseUrl + '/relaxation-gadgets/' + (gadget ? gadget.id : ''), {
                                skipCardHolder: false,
                              })
                            }
                          />,
                        ]}
                      />
                      {gadget && (
                        <OutlineButton
                          size="xs"
                          onClick={() => handleRemoveGadget(gadget)}
                          marginTop="s"
                          style={{ maxWidth: 96 }}>
                          Remove
                        </OutlineButton>
                      )}
                    </Column>
                  ))}
                {[...Array(10)].map((_, i) => (
                  <Column key={`empty${i}`} flex="1 1 106px" paddingRight={10} />
                ))}
              </Row>
            </Column>
            {!portrait && <Spacer size="m" />}
          </Row>
        </Column>
        <Column className="codeCardsColumn" flex="1 0 50%">
          <Row>
            {!portrait && <Spacer size="m" />}
            <Column
              flex="1 1 auto"
              style={{
                width: '100%', // for ie11
              }}>
              <Row paddingBottom="s" paddingTop="m">
                <H3>My Skill Code Cards</H3>
                <Spacer size="xs" />
                <Hint text="These are step-by-step guides for talking, hanging out and working with others. They can also help you to solve social problems like making mistakes and bullying." />
              </Row>
              <Row flexWrap justifyContent="flex-start">
                {[...codeCardIds, undefined]
                  .map(id => findCodeCardById(id, state))
                  .map((codeCard, i) => (
                    <Column key={i} flex="1 1 96px" paddingBottom="s" id={codeCard?.id}>
                      <CodeCardTile
                        codeCard={codeCard}
                        onEdit={() =>
                          history.push(baseUrl + '/code-cards/' + (codeCard ? codeCard.id : ''), {
                            skipCardHolder: false,
                          })
                        }
                      />
                      {codeCard && (
                        <OutlineButton
                          size="xs"
                          onClick={() => handleRemoveCodeCard(codeCard)}
                          marginTop="s"
                          style={{ maxWidth: 96 }}>
                          Remove
                        </OutlineButton>
                      )}
                    </Column>
                  ))}
                {[...Array(10)].map((_, i) => (
                  <Column key={`empty${i}`} flex="1 1 106px" paddingRight={10} />
                ))}
              </Row>
            </Column>
          </Row>
        </Column>
      </Row>
    </Padding>
  )
}
