import { lazy } from 'react'
import { Activity, GameMode, GameLevel } from 'types'
import { GameModeSlug } from 'shared/types'
import { retry } from 'utils/apiUtils'

export const Level1 = {
  id: 'level1',
  section: 'game',
  title: 'Level 1',
  level: 1,
  path: '/game/1',
} satisfies Activity

export const Level2 = {
  id: 'level2',
  section: 'game',
  title: 'Level 2',
  level: 2,
  path: '/game/2',
  prerequisite: Level1,
}satisfies Activity

export const Level3 = {
  id: 'level3',
  section: 'game',
  title: 'Level 3',
  level: 3,
  path: '/game/3',
  prerequisite: Level2,
}satisfies Activity

export const Level4 = {
  id: 'level4',
  section: 'game',
  title: 'Level 4',
  level: 4,
  path: '/game/4',
  prerequisite: Level3,
}satisfies Activity

export const PreQuiz1 = {
  id: 'quiz1-pre',
  section: 'game',
  title: 'Level 1\nPre Quiz',
  component: lazy(() => retry(() => import(/* webpackChunkName: "Quiz1" */ './level1/quiz/Quiz1'))) // prettier-ignore
}satisfies Activity

export const PostQuiz1 = {
  ...PreQuiz1,
  id: 'quiz1-post',
  title: 'Level 1\nPost Quiz',
}satisfies Activity

export const SpotTheSuspect = {
  id: 'spot-the-suspect',
  section: 'game',
  title: 'Spot the Suspect',
  component: lazy(() => retry(() => import(/* webpackChunkName: "SpotTheSuspect" */ './level1/spot-the-suspect/SpotTheSuspect'))) // prettier-ignore
}satisfies Activity

export const EyewitnessRecall = {
  id: 'eyewitness-recall',
  section: 'game',
  title: 'Eyewitness Recall',
  component: lazy(() => retry(() => import(/* webpackChunkName: "EyewitnessRecall" */ './level1/eyewitness-recall/EyewitnessRecall'))) // prettier-ignore
}satisfies Activity

export const TheLineUp = {
  id: 'the-line-up',
  section: 'game',
  title: 'The Line Up',
  component: lazy(() => retry(() => import(/* webpackChunkName: "TheLineUp" */ './level1/the-line-up/TheLineUp'))),
}satisfies Activity

export const VoiceVerification = {
  id: 'voice-verification',
  section: 'game',
  title: 'Voice Verification',
  component: lazy(() => retry(() => import(/* webpackChunkName: "VoiceVerification" */ './level1/voice-verification/VoiceVerification'))) // prettier-ignore
}satisfies Activity

export const PreQuiz2 = {
  id: 'quiz2-pre',
  section: 'game',
  title: 'Level 2\nPre Quiz',
  component: lazy(() => retry(() => import(/* webpackChunkName: "Quiz2" */ './level2/quiz/Quiz2'))) // prettier-ignore
}satisfies Activity

export const PostQuiz2 = {
  ...PreQuiz2,
  id: 'quiz2-post',
  title: 'Level 2\nPost Quiz',
}satisfies Activity

export const DetectiveLaboratory = {
  id: 'detective-laboratory',
  section: 'game',
  title: 'Detective Laboratory',
  component: lazy(() => retry(() => import(/* webpackChunkName: "DetectiveLaboratory" */ './level2/detective-laboratory/DetectiveLaboratory'))) // prettier-ignore
}satisfies Activity

export const DegreesOfDelightAndDistress = {
  id: 'degrees-of-delight-and-distress',
  section: 'game',
  title: 'Degrees of Delight & Distress',
  component: lazy(() => retry(() => import(/* webpackChunkName: "DegreesOfDelightAndDistress" */ './level2/degrees-of-delight-and-distress/DegreesOfDelightAndDistress'))), // prettier-ignore
  printComponent:lazy(() => retry(() => import(/* webpackChunkName: "DegreesOfDelightAndDistressPrint" */ './level2/degrees-of-delight-and-distress/DegreesOfDelightAndDistressPrint'))) // prettier-ignore
}satisfies Activity

export const SecretAgentViewingPanel = {
  id: 'secret-agent-viewing-panel',
  section: 'game',
  title: 'Secret Agent Viewing Panel',
  component: lazy(() => retry(() => import(/* webpackChunkName: "SecretAgentViewingPanel" */ './level2/secret-agent-viewing-panel/SecretAgentViewingPanel'))) // prettier-ignore
}satisfies Activity

export const PreQuiz3 = {
  id: 'quiz3-pre',
  section: 'game',
  title: 'Level 3\nPre Quiz',
  component: lazy(() => retry(() => import(/* webpackChunkName: "Quiz3" */ './level3/quiz/Quiz3'))) // prettier-ignore
}satisfies Activity

const PostQuiz3 = {
  ...PreQuiz3,
  id: 'quiz3-post',
  title: 'Level 3\nPost Quiz',
}satisfies Activity

export const DetectiveFlightChallenge = {
  id: 'detective-flight-challenge',
  section: 'game',
  title: 'Detective Flight Challenge',
  component: lazy(() => retry(() => import(/* webpackChunkName: "DetectiveFlightChallenge" */ './level3/detective-flight-challenge/DetectiveFlightChallenge'))) // prettier-ignore
}satisfies Activity

export const CrimeAtTheCathedral = {
  id: 'crime-at-the-cathedral',
  section: 'game',
  title: 'Crime at the Cathedral',
  component: lazy(() => retry(() => import(/* webpackChunkName: "CrimeAtTheCathedral" */ './level3/crime-at-the-cathedral/CrimeAtTheCathedral'))) // prettier-ignore
}satisfies Activity

export const MurderAtEarnshawManor = {
  id: 'murder-at-earnshaw-manor',
  section: 'game',
  title: 'Murder at Earnshaw Manor',
  component: lazy(() => retry(() => import(/* webpackChunkName: "MurderAtEarnshawManor" */ './level3/murder-at-earnshaw-manor/MurderAtEarnshawManor'))) // prettier-ignore
} satisfies Activity

export const SecretOfTheSchoolyardGhost = {
  id: 'secret-of-the-schoolyard-ghost',
  section: 'game',
  title: 'Secret of the Schoolyard Ghost',
  component: lazy(() => retry(() => import(/* webpackChunkName: "SecretOfTheSchoolyardGhost" */ './level3/secret-of-the-schoolyard-ghost/SecretOfTheSchoolyardGhost'))) // prettier-ignore
} satisfies Activity

export const ConversationCode = {
  id: 'conversation-code',
  section: 'game',
  title: 'Conversation Code',
  component: lazy(() => retry(() => import(/* webpackChunkName: "ConversationCode" */ './level3/conversation-code/ConversationCode'))) // prettier-ignore
} satisfies Activity

export const Quiz4 = {
  id: 'quiz4',
  section: 'game',
  title: 'Level 4 Quiz',
  component: lazy(() => retry(() => import(/* webpackChunkName: "Quiz4" */ './level4/quiz/Quiz4'))) // prettier-ignore
} satisfies Activity

export const Graduation = {
  id: 'graduation',
  section: 'game',
  title: 'Graduation Ceremony',
  component: lazy(() => retry(() => import(/* webpackChunkName: "Graduation" */ './level4/graduation/Graduation'))) // prettier-ignore
} satisfies Activity

const SecretSpyMission = {
  id: 'secret-spy-mission',
  section: 'journal',
  title: 'Secret Spy Mission',
  component: lazy(() => retry(() => import(/* webpackChunkName: "SecretSpyMission" */ './journal/secret-spy-mission/SecretSpyMission'))), // prettier-ignore
  printComponent: lazy(() =>import(/* webpackChunkName: "SecretSpyMissionPrint" */ './journal/secret-spy-mission/SecretSpyMissionPrint')) // prettier-ignore
} satisfies Activity

export const SoundSignal = {
  id: 'sound-signal',
  section: 'journal',
  title: 'Sound Signal',
  component: lazy(() =>
    retry(() => import(/* webpackChunkName: "SoundSignal" */ './journal/sound-signal/SoundSignal'))
  ),
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "SoundSignalPrint" */ './journal/sound-signal/SoundSignalPrint'))) // prettier-ignore
} satisfies Activity

const EmploymentOfTheEmotionometer = {
  id: 'employment-of-the-emotionometer',
  section: 'journal',
  title: 'Employ\u00ADment of the Emotion\u00ADometer',
  component: lazy(() => retry(() => import(/* webpackChunkName: "EmploymentOfTheEmotionometer" */ './journal/employment-of-the-emotionometer/EmploymentOfTheEmotionometer'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "EmploymentOfTheEmotionometerPrint" */ './journal/employment-of-the-emotionometer/EmploymentOfTheEmotionometerPrint'))) // prettier-ignore
} satisfies Activity

export const OperationO2Regulator = {
  id: 'operation-o2-regulator',
  section: 'journal',
  title: 'Operation O\u2082 Regulator',
  component: lazy(() => retry(() => import(/* webpackChunkName: "OperationO2Regulator" */ './journal/operation-o2-regulator/OperationO2Regulator'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "OperationO2RegulatorPrint" */ './journal/operation-o2-regulator/OperationO2RegulatorPrint'))) // prettier-ignore
} satisfies Activity

export const EnemyThoughtDestruction = {
  id: 'enemy-thought-destruction',
  section: 'journal',
  title: 'Unhelpful Thought Destruction',
  component: lazy(() => retry(() => import(/* webpackChunkName: "EnemyThoughtDestruction" */ './journal/enemy-thought-destruction/EnemyThoughtDestruction'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "EnemyThoughtDestructionPrint" */ './journal/enemy-thought-destruction/EnemyThoughtDestructionPrint'))) // prettier-ignore
} satisfies Activity

export const RelaxationGadgetry = {
  id: 'relaxation-gadgetry',
  section: 'journal',
  title: 'Relaxation Gadgetry',
  component: lazy(() => retry(() => import(/* webpackChunkName: "RelaxationGadgetry" */ './journal/relaxation-gadgetry/RelaxationGadgetry'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "RelaxationGadgetryPrint" */ './journal/relaxation-gadgetry/RelaxationGadgetryPrint'))) // prettier-ignore
} satisfies Activity

const RelaxationGadgetry2 = {
  ...RelaxationGadgetry,
  id: 'relaxation-gadgetry-2',
  title: 'Repeat Mission: Relaxation Gadgetry',
} satisfies Activity

export const TheUltimateChillOutChallenge = {
  id: 'the-ultimate-chill-out-challenge',
  section: 'journal',
  title: 'The Ultimate Chill-Out Challenge',
  component: lazy(() => retry(() => import(/* webpackChunkName: "TheUltimateChillOutChallenge" */ './journal/the-ultimate-chill-out-challenge/TheUltimateChillOutChallenge'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "TheUltimateChillOutChallengePrint" */ './journal/the-ultimate-chill-out-challenge/TheUltimateChillOutChallengePrint'))) // prettier-ignore
} satisfies Activity

export const TheFriendshipForce = {
  id: 'the-friendship-force',
  section: 'journal',
  title: 'The Friendship Force',
  component: lazy(() => retry(() => import(/* webpackChunkName: "TheFriendshipForce" */ './journal/the-friendship-force/TheFriendshipForce'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "TheFriendshipForcePrint" */ './journal/the-friendship-force/TheFriendshipForcePrint'))) // prettier-ignore
} satisfies Activity

export const TheFriendshipForce2 = {
  id: 'the-friendship-force-2',
  section: 'journal',
  title: 'Repeat Mission: The Friendship Force',
  component: lazy(() => retry(() => import(/* webpackChunkName: "TheFriendshipForce2" */ './journal/the-friendship-force-2/TheFriendshipForce2'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "TheFriendshipForce2Print" */ './journal/the-friendship-force-2/TheFriendshipForce2Print'))) // prettier-ignore
} satisfies Activity

export const MistakeMania = {
  id: 'mistake-mania',
  section: 'journal',
  title: 'Mistake Mania!',
  component: lazy(() =>
    retry(() => import(/* webpackChunkName: "MistakeMania" */ './journal/mistake-mania/MistakeMania'))
  ),
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "MistakeManiaPrint" */ './journal/mistake-mania/MistakeManiaPrint'))) // prettier-ignore
} satisfies Activity

export const DECODERProblemDemolition = {
  id: 'decoder-problem-demolition',
  section: 'journal',
  title: 'D.E.C.O.D.E.R Problem Demolition',
  component: lazy(() => retry(() => import(/* webpackChunkName: "DECODERProblemDemolition" */ './journal/decoder-problem-demolition/DECODERProblemDemolition'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "DECODERProblemDemolitionPrint" */ './journal/decoder-problem-demolition/DECODERProblemDemolitionPrint'))) // prettier-ignore
} satisfies Activity

export const CrackingTheConversationCode = {
  id: 'cracking-the-conversation-code',
  section: 'journal',
  title: 'Cracking the Conversation Code',
  component: lazy(() => retry(() => import(/* webpackChunkName: "CrackingTheConversationCode" */ './journal/cracking-the-conversation-code/CrackingTheConversationCode'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "CrackingTheConversationCodePrint" */ './journal/cracking-the-conversation-code/CrackingTheConversationCodePrint'))) // prettier-ignore
} satisfies Activity

export const CooperationChallenge = {
  id: 'cooperation-challenge',
  section: 'journal',
  title: 'Cooperation Challenge',
  component: lazy(() => retry(() => import(/* webpackChunkName: "CooperationChallenge" */ './journal/cooperation-challenge/CooperationChallenge'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "CooperationChallengePrint" */ './journal/cooperation-challenge/CooperationChallengePrint'))) // prettier-ignore
} satisfies Activity

export const CooperationChallenge2 = {
  id: 'cooperation-challenge-2',
  section: 'journal',
  title: 'Repeat Mission: Cooperation Challenge',
  component: lazy(() => retry(() => import(/* webpackChunkName: "CooperationChallenge2" */ './journal/cooperation-challenge-2/CooperationChallenge2'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "CooperationChallenge2Print" */ './journal/cooperation-challenge-2/CooperationChallenge2Print'))) // prettier-ignore
} satisfies Activity

export const CooperationChallenge3 = {
  ...CooperationChallenge2,
  id: 'cooperation-challenge-3',
} satisfies Activity

export const BullyGuardBodyArmour = {
  id: 'bully-guard-body-armour',
  section: 'journal',
  title: 'Bully-Guard Body Armour',
  component: lazy(() => retry(() => import(/* webpackChunkName: "BullyGuardBodyArmour" */ './journal/bully-guard-body-armour/BullyGuardBodyArmour'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "BullyGuardBodyArmourPrint" */ './journal/bully-guard-body-armour/BullyGuardBodyArmourPrint'))) // prettier-ignore
} satisfies Activity

export const FearFighter = {
  id: 'fear-fighter',
  section: 'journal',
  title: 'Fear Fighter',
  component: lazy(() =>
    retry(() => import(/* webpackChunkName: "FearFighter" */ './journal/fear-fighter/FearFighter'))
  ),
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "FearFighterPrint" */ './journal/fear-fighter/FearFighterPrint'))) // prettier-ignore
} satisfies Activity

const TheFinalChallenge = {
  id: 'the-final-challenge',
  section: 'journal',
  title: 'The Final Challenge',
  component: lazy(() => retry(() => import(/* webpackChunkName: "TheFinalChallenge" */ './journal/the-final-challenge/TheFinalChallenge'))), // prettier-ignore
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "TheFinalChallengePrint" */ './journal/the-final-challenge/TheFinalChallengePrint'))) // prettier-ignore
} satisfies Activity

const BionicPowers = {
  id: 'bionic-powers',
  section: 'journal',
  title: 'Bionic Powers',
  component: lazy(() =>
    retry(() => import(/* webpackChunkName: "BionicPowers" */ './journal/bionic-powers/BionicPowers'))
  ),
  printComponent: lazy(() => retry(() => import(/* webpackChunkName: "BionicPowersPrint" */ './journal/bionic-powers/BionicPowersPrint'))) // prettier-ignore
} satisfies Activity

const BodyScan = {
  id: 'body-scan',
  section: 'journal',
  title: 'Body Scan',
  component: lazy(() => retry(() => import(/* webpackChunkName: "BodyScan" */ './journal/body-scan/BodyScan'))),
  printComponent: lazy(() =>
    retry(() => import(/* webpackChunkName: "BodyScanPrint" */ './journal/body-scan/BodyScanPrint'))
  ),
} satisfies Activity

const TestMission = {
  id: 'test-mission',
  section: 'journal',
  title: 'Test Mission',
  component: lazy(() =>
    retry(() => import(/* webpackChunkName: "TestMission" */ './journal/test-mission/TestMission'))
  ),
  printComponent: lazy(() =>import(/* webpackChunkName: "TestMissionPrint" */ './journal/test-mission/TestMissionPrint')) // prettier-ignore
} satisfies Activity

function withLevelAndPath(level: GameLevel, activities: Activity[]): Activity[] {
  return activities.map(activity => ({ path: activityPath(activity, level), level, ...activity }))
}

function activityPath({ section, id }: Activity, level: GameLevel): string {
  return `/${section}${level ? '/' + level : ''}/${id}`
}

function withPrerequisite(activity1: Activity, activity2: Activity): Activity {
  return { ...activity1, prerequisite: activity2 }
}

function withSequentialPrerequisite(activities: Activity[]): Activity[] {
  return activities.map((activity, i) => {
    if (!activity.prerequisite && i > 0) {
      return { ...activity, prerequisite: activities[i - 1] }
    }
    return activity
  })
}

function withCanCompleteOffline(activities: Activity[]): Activity[] {
  return activities.map(activity => ({ ...activity, canCompleteOffline: true }))
}

export const gameModes: { [key in GameModeSlug]: GameMode } = {
  'SAS-DEV': {
    slug: 'SAS-DEV',
    levels: [Level1, Level2, Level3, Level4],
    activities: [
      // level 1
      ...withLevelAndPath(1, [
        //
        PreQuiz1,
        SpotTheSuspect,
        EyewitnessRecall,
        TheLineUp,
        SecretSpyMission,
        VoiceVerification,
        BionicPowers,
        BodyScan,
        BullyGuardBodyArmour,
        RelaxationGadgetry,
        RelaxationGadgetry2,
        PostQuiz1,
      ]),

      // level 2
      ...withLevelAndPath(2, [
        PreQuiz2,
        DetectiveLaboratory,
        DegreesOfDelightAndDistress,
        SecretAgentViewingPanel,
        EmploymentOfTheEmotionometer,
        SoundSignal,
        OperationO2Regulator,
        PostQuiz2,
      ]),

      // level 3
      ...withLevelAndPath(3, [
        PreQuiz3,
        DetectiveFlightChallenge,
        CrimeAtTheCathedral,
        EnemyThoughtDestruction,
        MurderAtEarnshawManor,
        SecretOfTheSchoolyardGhost,
        DECODERProblemDemolition,
        TestMission,
        TheUltimateChillOutChallenge,
        TheFriendshipForce,
        TheFriendshipForce2,
        CrackingTheConversationCode,
        CooperationChallenge,
        CooperationChallenge2,
        CooperationChallenge3,
        MistakeMania,
        PostQuiz3,
      ]),

      // level 4
      ...withLevelAndPath(4, [Quiz4, FearFighter, TheFinalChallenge, Graduation]),
    ],
  },

  'SAS-SA': {
    slug: 'SAS-SA',
    levels: [Level1, Level2, Level3, Level4],
    activities: withSequentialPrerequisite([
      // level 1
      ...withLevelAndPath(1, [
        PreQuiz1,
        SpotTheSuspect,
        EyewitnessRecall,
        TheLineUp,
        SecretSpyMission,
        VoiceVerification,
        SoundSignal,
        PostQuiz1,
      ]),

      // level 2
      ...withLevelAndPath(2, [
        PreQuiz2,
        DetectiveLaboratory,
        DegreesOfDelightAndDistress,
        EmploymentOfTheEmotionometer,
        SecretAgentViewingPanel,
        PostQuiz2,
      ]),

      // level 3
      ...withLevelAndPath(3, [
        PreQuiz3,
        CrimeAtTheCathedral,
        DetectiveFlightChallenge,
        withPrerequisite(OperationO2Regulator, DetectiveFlightChallenge),
        withPrerequisite(EnemyThoughtDestruction, DetectiveFlightChallenge),
        withPrerequisite(RelaxationGadgetry, DetectiveFlightChallenge),
        withPrerequisite(TheUltimateChillOutChallenge, DetectiveFlightChallenge),
        MurderAtEarnshawManor,
        withPrerequisite(TheFriendshipForce, MurderAtEarnshawManor),
        withPrerequisite(MistakeMania, MurderAtEarnshawManor),
        SecretOfTheSchoolyardGhost,
        withPrerequisite(CrackingTheConversationCode, SecretOfTheSchoolyardGhost),
        withPrerequisite(CooperationChallenge, SecretOfTheSchoolyardGhost),
        withPrerequisite(BullyGuardBodyArmour, SecretOfTheSchoolyardGhost),
        PostQuiz3,
      ]),

      // level 4
      ...withLevelAndPath(4, [
        DECODERProblemDemolition,
        CooperationChallenge2,
        FearFighter,
        TheFinalChallenge,
        Quiz4,
        Graduation,
      ]),
    ]),
  },

  'SAS-SG': {
    slug: 'SAS-SG',
    levels: [
      Level1,
      withPrerequisite(Level2, PostQuiz1),
      withPrerequisite(Level3, PostQuiz2),
      withPrerequisite(Level4, PostQuiz3),
    ],
    activities: withCanCompleteOffline(
      withSequentialPrerequisite([
        // level 1
        ...withLevelAndPath(1, [
          //
          PreQuiz1,
          SpotTheSuspect,
          EyewitnessRecall,
          TheLineUp,
          VoiceVerification,
          PostQuiz1,
        ]),

        // level 2
        ...withLevelAndPath(2, [
          PreQuiz2,
          DetectiveLaboratory,
          DegreesOfDelightAndDistress,
          SecretSpyMission,
          SoundSignal,
          SecretAgentViewingPanel,
          PostQuiz2,
        ]),

        // level 3
        ...withLevelAndPath(3, [
          PreQuiz3,
          CrimeAtTheCathedral,
          DetectiveFlightChallenge,
          EmploymentOfTheEmotionometer,
          OperationO2Regulator,
          EnemyThoughtDestruction,
          RelaxationGadgetry,
          TheFriendshipForce,
          MurderAtEarnshawManor,
          SecretOfTheSchoolyardGhost,
          PostQuiz3,
        ]),

        // level 4
        ...withLevelAndPath(4, [
          TheFriendshipForce2,
          DECODERProblemDemolition,
          TheUltimateChillOutChallenge,
          CrackingTheConversationCode,
          CooperationChallenge,
          MistakeMania,
          CooperationChallenge2,
          CooperationChallenge3,
          BullyGuardBodyArmour,
          FearFighter,
          TheFinalChallenge,
          Quiz4,
          Graduation,
        ]),
      ])
    ),
  },

  'SAS-SG-D': {
    slug: 'SAS-SG-D',
    levels: [
      Level1,
      withPrerequisite(Level2, PostQuiz1),
      withPrerequisite(Level3, PostQuiz2),
      withPrerequisite(Level4, PostQuiz3),
    ],
    activities: withCanCompleteOffline(
      withSequentialPrerequisite([
        // level 1
        ...withLevelAndPath(1, [
          //
          PreQuiz1,
          SpotTheSuspect,
          EyewitnessRecall,
          TheLineUp,
          VoiceVerification,
          PostQuiz1,
        ]),

        // level 2
        ...withLevelAndPath(2, [
          PreQuiz2,
          DetectiveLaboratory,
          DegreesOfDelightAndDistress,
          SecretSpyMission,
          SoundSignal,
          SecretAgentViewingPanel,
          PostQuiz2,
        ]),

        // level 3
        ...withLevelAndPath(3, [
          PreQuiz3,
          CrimeAtTheCathedral,
          DetectiveFlightChallenge,
          EmploymentOfTheEmotionometer,
          OperationO2Regulator,
          EnemyThoughtDestruction,
          RelaxationGadgetry,
          TheFriendshipForce,
          MurderAtEarnshawManor,
          SecretOfTheSchoolyardGhost,
          PostQuiz3,
        ]),

        // level 4
        ...withLevelAndPath(4, [
          TheFriendshipForce2,
          DECODERProblemDemolition,
          TheUltimateChillOutChallenge,
          CrackingTheConversationCode,
          CooperationChallenge,
          MistakeMania,
          CooperationChallenge2,
          CooperationChallenge3,
          BullyGuardBodyArmour,
          FearFighter,
          TheFinalChallenge,
          Quiz4,
          Graduation,
        ]),
      ])
    ),
  },

  'SAS-WOC': {
    slug: 'SAS-WOC',
    levels: [
      Level1,
      withPrerequisite(Level2, PostQuiz1),
      withPrerequisite(Level3, PostQuiz2),
      withPrerequisite(Level4, PostQuiz3),
    ],
    activities: withSequentialPrerequisite([
      // level 1
      ...withLevelAndPath(1, [
        //
        PreQuiz1,
        BionicPowers,
        SpotTheSuspect,
        TheLineUp,
        SecretSpyMission,
        VoiceVerification,
        PostQuiz1,
      ]),

      // level 2
      ...withLevelAndPath(2, [
        PreQuiz2,
        DetectiveLaboratory,
        SoundSignal,
        BodyScan,
        DegreesOfDelightAndDistress,
        EmploymentOfTheEmotionometer,
        PostQuiz2,
      ]),

      // level 3
      ...withLevelAndPath(3, [
        PreQuiz3,
        DetectiveFlightChallenge,
        OperationO2Regulator,
        EnemyThoughtDestruction,
        RelaxationGadgetry,
        RelaxationGadgetry2,
        DECODERProblemDemolition,
        ConversationCode,
        CrackingTheConversationCode,
        TheUltimateChillOutChallenge,
        MurderAtEarnshawManor,
        CooperationChallenge,
        SecretOfTheSchoolyardGhost,
        PostQuiz3,
      ]),

      // level 4
      ...withLevelAndPath(4, [
        //
        Graduation,
      ]),
    ]),
  },
}
