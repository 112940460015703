import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Activity } from 'types'
import { Panel } from 'common/ui'
import { HeaderTemplate } from '../common/HeaderTemplate'

import { useGadgetPackState } from '../GadgetPackState'
import { CodeCardsIndexInner } from 'e-telligence/CodeCardsIndex'
import { baseUrl as gadgetPackBaseUrl } from '../GadgetPack'

export const CodeCardsIndex: React.FC<{ baseUrl: string; activities: Activity[] }> = ({ baseUrl, activities }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useGadgetPackState()
  const realBaseUrl = (location.state || {}).skipCardHolder ? gadgetPackBaseUrl : baseUrl
  return (
    <HeaderTemplate title="Code Cards" onExit={() => history.push(realBaseUrl, location.state)} exitLabel={'Back'}>
      <Panel>
        <CodeCardsIndexInner baseUrl={baseUrl} state={state} dispatch={dispatch} activities={activities} />
      </Panel>
    </HeaderTemplate>
  )
}
