import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { SectionPropsBase as TrainingSectionPropsBase } from 'shared/training/types'
import { StreamedVideoBlockSection } from 'training/sections/StreamedVideoBlockSection'
import { StreamedVideoGridSection } from 'training/sections/StreamedVideoGridSection'
import { GenericGridSection } from 'session/sections/MediaGrid'
import { GenericNotificationSection } from 'session/sections/Notification'
import { GenericTextSection } from 'session/sections/Text'
import { getSections } from 'session/reducers'
import {
  Accordion,
  AccordionInner,
  AccordionTitle,
  AccordionImage,
  AccordionRight,
  AccordionBody,
} from 'session/common/Accordion'
import { HighlightableArea } from 'session/common/HighlightableArea'
import { getImageUrl } from 'session/utils/assetUtils'
import { CircleButton } from 'session/common/CircleButton'
import { GenericBlockSection } from 'session/sections/Block'
import { GenericDownloadResourceSection } from 'session/sections/DownloadResourceSection'

interface TrainingProps extends TrainingSectionPropsBase {
  property: 'grouped_sections'
}

const trainingSectionComponents: { [key: string]: any } = {
  text_sections: GenericTextSection,
  media_grid_sections: GenericGridSection,
  block_sections: GenericBlockSection,
  streamed_video_grid_sections: StreamedVideoGridSection,
  streamed_video_block_sections: StreamedVideoBlockSection,
  notification_sections: GenericNotificationSection,
  download_resource_sections: GenericDownloadResourceSection,
}

export const TrainingGroupedSection: React.FC<TrainingProps> = ({ section, index }) => {
  const { pages } = section
  const [expanded, setExpanded] = useState<number | null>(null)
  return (
    <GroupWrapper>
      <hr />
      {(pages || []).map((page, i) => {
        const sections = getSections(page)
        const expand = expanded === i
        const splitTitle = (page.title || '').match(/[^\r\n]+/g) || ['']
        const key = `group${index}_accordion${i}`
        return (
          <Accordion key={i}>
            <HighlightableArea key={key} id={key}>
              <AccordionInner>
                {page.title && (
                  <AccordionTitle>
                    {page.image && (
                      <AccordionImage src={getImageUrl(page.image, { preset: '128w-1' })} alt={page.title} />
                    )}
                    {splitTitle.map((splitTitleElem, i) => (
                      <Fragment key={i}>
                        {splitTitleElem} <br />
                      </Fragment>
                    ))}
                  </AccordionTitle>
                )}
                <AccordionRight onClick={() => setExpanded(i === expanded ? null : i)}>
                  <ShowText>{expand ? 'HIDE' : 'SHOW'}</ShowText>
                  <CircleButton size="s" children={expand ? '▲' : '▼'} />
                </AccordionRight>
              </AccordionInner>
            </HighlightableArea>
            <AccordionBody expanded={expand}>
              {sections.map((sectionObj, i) => {
                const Component = trainingSectionComponents[sectionObj.property]
                // @ts-ignore
                return <Component key={i} {...sectionObj} />
              })}
            </AccordionBody>
            <hr />
          </Accordion>
        )
      })}
    </GroupWrapper>
  )
}

export const GroupWrapper = styled.div`
  hr {
    opacity: 0.5;
  }
`

export const ToggleAllText = styled.div`
  color: #011a46;
  font-size: 14px;
  line-height: 1;
  text-align: right;
  padding-bottom: 8px;
  user-select: none;
  cursor: pointer;
`

export const ShowText = styled.div`
  color: #011a46;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
`
