import { EventEmitter } from 'events'

export default class Timer extends EventEmitter {
  private initialSeconds: number
  private paused: boolean = true
  private seconds: number
  private alarmSeconds: number

  private decayInterval?: number
  private interval?: number
  private endTime: number = 0

  constructor(seconds: number, alarmSeconds: number) {
    super()
    this.seconds = seconds
    this.initialSeconds = seconds
    this.alarmSeconds = alarmSeconds
  }

  get remaining() {
    return this.seconds
  }

  start() {
    if (this.paused) {
      this.endTime = Date.now() + this.seconds * 1000
      this.interval = window.setInterval(() => this.tick(), 100)
      this.paused = false
    }
  }

  pause() {
    this.paused = true
    window.clearInterval(this.interval)
  }

  reset() {
    this.pause()
    this.seconds = this.initialSeconds
    this.emit('timeupdate', this.seconds)
  }

  /**
   * Set the speed at which the timer should operate.
   *
   * @param value 1 = normal speed, 2 = double speed, etc
   */
  setSpeed(value: number) {
    window.clearInterval(this.decayInterval)
    if (value === 1) return
    const decay = ((value - 1) * 1000) / 10
    this.decayInterval = window.setInterval(() => {
      if (!this.paused) this.endTime -= decay
    }, 100)
  }

  setPaused(value: boolean) {
    if (value) {
      this.pause()
    } else if (this.remaining > 0) {
      this.start()
    }
  }

  private tick() {
    const remaining = Math.max(0, Math.ceil((this.endTime - Date.now()) / 1000))

    if (remaining !== this.seconds) {
      const emitAlarm = this.alarmSeconds === remaining && this.seconds > this.alarmSeconds

      this.seconds = remaining

      if (!remaining) {
        this.pause()
      }

      this.emit('timeupdate', remaining)

      if (emitAlarm) {
        this.emit('alarm', this.alarmSeconds)
      }

      if (remaining === 0) {
        this.emit('complete')
      }
    }
  }
}
