import React from 'react'
import styled from 'styled-components/macro'

import { useUserState } from 'app/UserState'
import { fontRegular } from 'fonts'
import { useGenericUser } from './useGenericUser'
import { intersperse } from 'utils/intersperse'

export const GameModeIndicator: React.FC<React.HTMLProps<HTMLDivElement>> = ({ ref, as, ...props }) => {
  const { uid, userType } = useGenericUser()
  const { gameMode } = useUserState()

  const commitHash = process.env.REACT_APP_COMMIT_HASH_SHORT
  const loggedIn = uid !== 'sas-0'

  const lines: string[] = []
  if (loggedIn && userType !== 'facilitator') lines.push(gameMode)
  if (loggedIn) lines.push(`Type: ${userType}`)
  if (commitHash) lines.push(`Version: ${commitHash}`)

  return (
    <GameModeText
      {...props}
      children={lines.reduce(
        intersperse(i => <br key={i} />),
        []
      )}
    />
  )
}

const GameModeText = styled.div`
  ${fontRegular}
  font-size: 10px;
  color: #fff;
  position: absolute;
  bottom: 1px;
  left: 3px;
  line-height: 11px;
`
