import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Column, Row, OutlineButton, buttonFlash } from 'common/ui'
import { Accordion, AccordionInner, AccordionTitle, AccordionRight, AccordionBody } from 'session/common/Accordion'
import { CircleButton } from 'session/common/CircleButton'
import { StickerTileButton } from 'e-telligence/common/StickerTileButton'
import { DroppableStickerArea } from './DroppableStickerArea'

import {
  emotionometerDevicePluralLabels,
  emotionRangeDistribution,
  emotionRangeOptions,
  emotionRangeValues,
} from 'e-telligence/constants/typeValueMaps'
import {
  Emotionometer,
  EmotionometerDevice,
  EmotionometerRange,
  EmotionometerSticker,
  EmotionometerStickerBase,
} from 'shared/e-telligence/types'

import { emotionPanelThemes } from './EmotionometerActivity'

interface StickerRowProps<Type extends EmotionometerDevice> {
  active: boolean
  viewable?: boolean
  emotion: 'anger' | 'anxiety'
  expanded: boolean
  toggleExpanded: (device: Type) => void
  emotionometer: Emotionometer
  type: Type
  onSelect: (sticker: EmotionometerSticker<Type>) => void
  onAdd: (type: Type, range: EmotionometerRange, index: number) => void
  selectedIndex?: number | undefined
  activeEmotionRangeIndexes: [boolean, boolean, boolean]
  // section: EmbeddedActivitySection
  updateDragItemSize: (size: number) => void
  handleStickerDrop: (
    sticker: EmotionometerStickerBase,
    replaceSticker: EmotionometerStickerBase | undefined,
    index: number
  ) => void
}

export const StickerRow = <DeviceType extends EmotionometerDevice>({
  active,
  viewable,
  emotion,
  expanded,
  toggleExpanded,
  type,
  emotionometer,
  onSelect,
  onAdd,
  selectedIndex,
  activeEmotionRangeIndexes,
  updateDragItemSize,
  handleStickerDrop,
}: PropsWithChildren<StickerRowProps<DeviceType>>) => {
  const stickers = emotionometer[type] as EmotionometerStickerBase[]
  let availableStickers = [...stickers]
  const renderStickers: (EmotionometerStickerBase | undefined)[] = emotionRangeDistribution.map((rangeIndex, index) => {
    const stickerAtIndex = availableStickers.find(sticker => sticker.index === index)
    if (stickerAtIndex) {
      availableStickers = availableStickers.filter(sticker => sticker !== stickerAtIndex)
      return stickerAtIndex
    }
    const emotionRangeOption = emotionRangeOptions.find(opt => opt.index === rangeIndex)
    const rangeStickers = availableStickers.filter(
      sticker => sticker.emotionRange === emotionRangeOption?.value && sticker.index === undefined
    )
    if (rangeStickers.length > 0) {
      availableStickers = availableStickers.filter(sticker => sticker !== rangeStickers[0])
      return rangeStickers[0]
    }
    return undefined
  })

  return (
    <Accordion>
      <AccordionInner style={{ padding: 0 }}>
        <AccordionTitle
          style={{ fontSize: 14, opacity: viewable ? 1 : 0.3, color: '#fff' }}
          children={emotionometerDevicePluralLabels[type]}
        />
        {viewable && (
          <AccordionRight onClick={() => toggleExpanded(type)}>
            <ShowText>{expanded ? 'HIDE' : 'SHOW'}</ShowText>
            <CircleButton theme={emotionPanelThemes[emotion] || 'blue'} size="xs" children={expanded ? '▲' : '▼'} />
          </AccordionRight>
        )}
      </AccordionInner>
      <AccordionBody expanded={expanded}>
        <Row flex="1 1 auto" justifyContent="space-around">
          {renderStickers.map((sticker, index) => {
            const tileDisabled = !active || !activeEmotionRangeIndexes[emotionRangeDistribution[index]]
            return (
              <Column
                key={index}
                flex={`1 1 ${100 / emotionRangeDistribution.length}%`}
                alignItems="center"
                justifyContent="center"
                paddingRight={3}
                paddingBottom={'s'}
                paddingTop={'s'}>
                <DroppableStickerArea
                  sticker={sticker}
                  updateDragItemSize={updateDragItemSize}
                  onDrop={(droppedItem, replaceSticker) =>
                    handleStickerDrop(droppedItem.sticker, replaceSticker, index)
                  }
                  disabled={tileDisabled}>
                  {sticker ? (
                    <StickerTileButton<typeof type>
                      sticker={sticker as EmotionometerSticker<typeof type>}
                      onClick={() => onSelect(sticker as EmotionometerSticker<typeof type>)}
                      maxSize={64}
                    />
                  ) : (
                    <EmptyTileBtn
                      disabled={tileDisabled}
                      size="xs"
                      theme="white"
                      onClick={() => onAdd(type, emotionRangeValues[emotionRangeDistribution[index]], index)}
                      activeRow={active}
                      selected={selectedIndex === index && active}
                    />
                  )}
                </DroppableStickerArea>
              </Column>
            )
          })}
        </Row>
      </AccordionBody>
    </Accordion>
  )
}

const ShowText = styled.div`
  margin-right: 16px;
  font-size: 12px;
  font-weight: 500;
`

export const StickerWrapper = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 90px;
  overflow: hidden;

  figcaption {
    font-size: 9px;
  }
`

const EmptyTileBtn = styled(OutlineButton)<{ activeRow: boolean; selected: boolean }>`
  border-radius: 5px;
  width: 100%;
  height: 0;
  padding: 0;
  margin: 0;
  padding-top: calc(100% - 4px);
  border-color: ${p => (p.activeRow ? '#6B6B6B !important' : '#000 !important')};
  background-color: ${p => (p.activeRow ? '#6B6B6B !important' : '#2C2C2C !important')};
  border-width: 2px;
  cursor: pointer;
  pointer-events: ${p => (p.activeRow ? 'auto' : 'none')};

  &:disabled {
    background-color: #2c2c2c !important;
    border-color: #000 !important;
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    ${p =>
      p.selected
        ? css`
            animation: ${buttonFlash} 0.5s linear infinite alternate;
            box-shadow: 0px 0px 5px 5px ${p => p.theme.highlightColor};
          `
        : ''};
  }
`
