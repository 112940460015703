import React, { useState, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import {
  Gadget,
  GadgetUseLocation,
  EmotionometerType,
  EmotionOption,
  GadgetUseOption,
  RouteProps,
} from 'shared/e-telligence/types'
import { ADD_UPDATE_CUSTOM_RELAXATION_GADGET, AddUpdateCustomRelaxationAction } from 'shared/e-telligence/actionTypes'
import {
  emotionOptions,
  gadgetUseLocationOptions,
  emotionRangeValues,
  emotionRangeOptionsUnordered,
  emotionRangeDistribution,
} from './constants/typeValueMaps'

import { Column, Padding, Hr, Row, Button, Spacer, OutlineButton, Input } from 'common/ui'
import { ETellPanel } from './common/ETellPanel'
import { Navigation, baseUrl } from './common/Navigation'
import { Field } from 'common/Field'
import { AutosizeTextarea } from 'common/AutosizeTextarea'
import { SymbolSelectModal } from 'common/SymbolSelectModal'
import { CheckboxButtonGroupField } from 'common/FieldInputs'
import { InlineCheckboxGroup } from 'common/CheckboxGroup'
import { EmotionometerBar } from './common/EmotionometerBar'
import { ValidationErrorList } from 'common/ValidationErrorList'

import { useUserState } from 'app/UserState'
import { useETelligenceState } from './ETelligenceState'
import { getInputProps } from 'utils/virtualKeyboard'
import { emotionometerRelaxationGadgetEmojis } from 'constants/emojiSets'

export const relaxationGadgetEmojis = emotionometerRelaxationGadgetEmojis

export const validate = (data: Partial<Gadget>, errors: string[] = []) => {
  if (!data.title) errors.push('You need to give you relaxation gadget a name.')
  else if (data.title.length >= 25) errors.push('Please enter a shorter title.')
  if (!data.symbol) errors.push('Pick a symbol for your relaxation gadget.')
  if (!data.location || !data.location.length)
    errors.push('Please pick at least one place that you can use your relaxation gadget.')
  if (!data.emotion || !data.emotion.length)
    errors.push('Please pick at least one emotion that your relaxation gadget can work for.')
  if (!data.emotionRange || !data.emotionRange.length)
    errors.push('Please chose the level of emotion that your gadget it useful for.')
  return errors
}

interface Props {
  isNew?: boolean
  gadgetId?: string
  overrideButtonClick?: () => void
}

export const RelaxationGadgetEdit: React.FC<Props> = ({ isNew }) => {
  const history = useHistory()
  const { state, dispatch } = useETelligenceState()
  const { usingIOSvhFix } = useUserState()
  return (
    <ETellPanel style={{ height: usingIOSvhFix ? '100%' : 'calc(100vh - 20px)' }}>
      <Navigation
        title="Make your own"
        buttonLabel="Back"
        buttonPress={() => history.push(baseUrl + '/relaxation-gadgets')}
      />
      <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
        <RelaxationGadgetEditInner baseUrl={baseUrl} state={state} dispatch={dispatch} isNew={isNew} />
      </Column>
    </ETellPanel>
  )
}

export const RelaxationGadgetEditInner: React.FC<RouteProps & Props> = ({
  baseUrl,
  state,
  dispatch,
  isNew,
  gadgetId,
  overrideButtonClick,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { getBaseAction } = useUserState()
  let { id } = useParams<{ id: string }>()
  id = gadgetId ? gadgetId : id

  const symbolInputRef = useRef<HTMLInputElement>(null)
  const [emojiModalOpen, setEmojiModalOpen] = useState(false)
  const [gadget, setGadget] = useState<Partial<Gadget>>(() =>
    isNew ? {} : state.customGadgets.find((test) => test.id === id && !test.isPreset) || {}
  )

  const errors = validate(gadget)

  const updateGadget = (data: Partial<Gadget>) => setGadget({ ...gadget, ...data })

  const handleSave = (addToCardHolder?: boolean) => {
    if (errors.length > 0) return
    const saveGadget = { ...gadget } as Gadget
    if (!saveGadget.id) saveGadget.id = `customGadget${Date.now()}`
    const action: AddUpdateCustomRelaxationAction = {
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_RELAXATION_GADGET,
      gadget: saveGadget,
      addToCardHolder,
    }
    dispatch(action)
    if (overrideButtonClick) overrideButtonClick()
    else if (addToCardHolder) {
      setTimeout(() =>
        history.push(`${baseUrl}/home#${saveGadget.id}`, { ...(location.state || {}), showGadget: saveGadget.id })
      )
    } else {
      setTimeout(() => history.push(baseUrl + '/relaxation-gadgets', location.state))
    }
  }

  return (
    <>
      <Padding size="s" style={{ flex: 'none' }}>
        <Field label="Enter a name for your gadget" hint="???">
          <Input
            autoFocus
            value={gadget.title || ''}
            onChange={(e) => updateGadget({ title: e.target.value })}
            maxLength={20}
            placeholder="20 characters max"
          />
        </Field>
        <Spacer />
        <Field label="Symbol">
          <Input
            ref={symbolInputRef}
            value={gadget.symbol || ''}
            style={{ fontSize: 28, width: 60, height: 50, padding: gadget.symbol ? 12 : 5, textAlign: 'center' }}
            readOnly
            placeholder="+"
            onClick={() => setEmojiModalOpen(true)}
          />
        </Field>
      </Padding>
      <Hr />
      <Padding size="s" style={{ flex: 'none' }}>
        <Field label="Where to use it">
          <InlineCheckboxGroup<GadgetUseOption>
            name="location"
            size="s"
            spacerSize="s"
            options={gadgetUseLocationOptions.filter(({ selectable }) => selectable)}
            value={gadget.location || []}
            onChange={(location) => updateGadget({ location: location as GadgetUseLocation[] })}
          />
        </Field>
      </Padding>
      <Hr />
      <Padding size="s" style={{ flex: 'none' }}>
        <Field label="What emotions(s) is it best used for?">
          <CheckboxButtonGroupField<EmotionometerType, EmotionOption>
            size="s"
            options={emotionOptions}
            value={gadget.emotion || []}
            onChange={(emotion) => updateGadget({ emotion: emotion || [] })}
            Component={OutlineButton}
            propsMapper={(props) => ({ ...props, selected: false, filled: !!props.selected, theme: 'blue' })}
          />
        </Field>
        <Spacer size="s" />
        <Field label="Select the emotion range(s) that it is useful for:">
          <EmotionometerBar
            type="anger"
            value={(gadget.emotionRange || [])
              .map((rangeVal) => emotionRangeValues.indexOf(rangeVal))
              .filter((num) => num >= 0)}
            distribution={emotionRangeDistribution}
            labels={emotionRangeOptionsUnordered.map(({ label }) => label)}
            onSelectSegment={(indexes) => {
              if (indexes.length >= 3) indexes = [indexes[indexes.length - 1], indexes[indexes.length - 2]]
              if (Math.max(...indexes) - Math.min(...indexes) < 2) {
                updateGadget({
                  emotionRange: indexes.map((num) => emotionRangeOptionsUnordered[num].value),
                })
              }
            }}
          />
        </Field>
      </Padding>
      <Hr />
      <Padding size="s" style={{ flex: 'none' }}>
        <Field label="Explain how it works:" hint="List the steps involved in using this strategy to calm down.">
          <AutosizeTextarea
            placeholder={`e.g. Use my meditation app for 5-10 mins.`}
            value={gadget.description || ''}
            onChange={(e: any) => updateGadget({ description: e.target.value || '' })}
            style={{ minHeight: 80, minWidth: 260 }}
            {...getInputProps()}
          />
        </Field>
      </Padding>

      <Spacer size="m" flex="1 1 auto" />
      <Padding size="s" style={{ flex: 'none' }}>
        <Row>
          <ValidationErrorList errors={errors} />
        </Row>
        <Row justifyContent="center" padding="s">
          {/*
              <Button onClick={() => handleSave(true)} size="s" disabled={errors.length > 0}>
                Add to Card Holder
              </Button>
              <Spacer size="xs" />
              */}
          <Button onClick={() => handleSave()} size="s" disabled={errors.length > 0}>
            Save
          </Button>
        </Row>
      </Padding>
      <SymbolSelectModal
        isOpen={emojiModalOpen}
        symbols={relaxationGadgetEmojis}
        // disabledSymbols={state.customGadgets.map(({ symbol }) => symbol).filter(_ => _) as string[]}
        value={gadget.symbol || null}
        onChange={(symbol) => {
          updateGadget({ symbol })
          setTimeout(() => setEmojiModalOpen(false), 150)
        }}
        onClose={() => {
          setEmojiModalOpen(false)
          setTimeout(() => symbolInputRef.current && symbolInputRef.current.blur())
        }}
      />
    </>
  )
}
