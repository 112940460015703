import React, { useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { fontHeavy } from 'fonts'
import { useSpring, animated } from 'react-spring'
import requireImageSize from '../../macros/requireImageSize.macro'

import {
  SectionPropsBase,
  TurnTakingSectionState,
  TurnStatus,
  turnStatuses,
  TurnTakingSection as TurnTakingSectionType,
} from 'shared/session/types'
import { UPDATE_SECTION_STATE_DANGEROUS } from 'shared/session/actionTypes'

import { FacilitatorControlsTable, FacilitatorControls } from 'session/common/FacilitatorControlsSection'
import { RadioButton } from 'session/common/RadioButton'

import { useSessionState } from 'session/SessionState'
import { Button, P } from 'common/ui'
import { useFocusedParticipantState } from 'session/hooks/useProfileState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'

interface Props extends SectionPropsBase {
  property: 'turn_taking_sections'
}

interface SectionProps {
  section: Props['section']
  property: Props['property']
}

export const getInitialSectionState = () => ({})

const getTurnLabels = (section: TurnTakingSectionType): { [key in TurnStatus]: string } => ({
  wait: section.wait_text || 'Wait & Listen',
  prepare: section.prepare_text || 'Get Ready',
  active: section.active_text || 'Your Turn',
})

export const TurnTakingBall: React.FC<SectionProps> = ({ section, property }) => {
  const participantState = useFocusedParticipantState()

  const sectionState = useSectionStateWithFallback(property, section, getInitialSectionState)
  const turnLabels = getTurnLabels(section)
  const turnKey: TurnStatus =
    participantState && participantState.profile.uid in sectionState
      ? sectionState[participantState.profile.uid]
      : 'wait'
  const activeState = turnKey === 'active'
  const ballProps = useSpring({
    config: {
      mass: 1.5,
      tension: 165,
      friction: 19,
      precision: 0.01,
      velocity: 10,
    },
    transform: activeState ? 'translate3d(0, 0%, 0)' : 'translate3d(0, -130%, 0)',
  })

  return (
    <Container size={section.size} type={section.type}>
      <DropShadow>
        <img src={require('../assets/drop-shadow.png')} alt="" />
      </DropShadow>
      <SoccerBall active={activeState}>
        <animated.img style={ballProps} src={require('../assets/soccer-ball.png')} alt="ball" />
      </SoccerBall>
      <Billboard size={section.size}>{turnLabels[turnKey]}</Billboard>
    </Container>
  )
}

const clapperImageSize = requireImageSize('../assets/film-clapper.png')
export const TurnTakingClapper: React.FC<SectionProps> = ({ section, property }) => {
  const participantState = useFocusedParticipantState()
  const sectionState = useSectionStateWithFallback(property, section, getInitialSectionState)
  const turnLabels = getTurnLabels(section)
  const turnKey: TurnStatus =
    participantState && participantState.profile.uid in sectionState
      ? sectionState[participantState.profile.uid]
      : 'wait'
  return (
    <Container size={section.size} type={section.type}>
      <Clapper active={turnKey === 'active'}>
        <img src={require('../assets/film-clapper.png')} alt="clapper" />
        <ClapperArm>
          <img src={require('../assets/film-clapper-stick.png')} alt="clapper arm" />
        </ClapperArm>
      </Clapper>
      <Billboard size={section.size}>{turnLabels[turnKey]}</Billboard>
    </Container>
  )
}

export const TurnTakingRadio: React.FC<SectionProps> = ({ section }) => {
  return (
    <Container size={section.size} type={section.type}>
      RADIO
    </Container>
  )
}

export const TurnTakingStar: React.FC<SectionProps> = ({ section, property }) => {
  const participantState = useFocusedParticipantState()
  const sectionState = useSectionStateWithFallback(property, section, getInitialSectionState)
  const turnLabels = getTurnLabels(section)
  const turnKey: TurnStatus =
    participantState && participantState.profile.uid in sectionState
      ? sectionState[participantState.profile.uid]
      : 'wait'
  return (
    <Container size={section.size} type={section.type}>
      <Star active={turnKey === 'active'} prepare={turnKey === 'prepare'}>
        <img src={require('../assets/star-outline.png')} alt="star outline" />
        <StarFill>
          <img src={require('../assets/star-fill.png')} alt="star" />
        </StarFill>
        <Wand>
          <img src={require('../assets/star-wand.png')} alt="wand" />
        </Wand>
      </Star>
      <Billboard size={section.size}>{turnLabels[turnKey]}</Billboard>
      <DropShadow>
        <img src={require('../assets/drop-shadow.png')} alt="star outline" />
      </DropShadow>
    </Container>
  )
}

const turnComponents: { [key in Props['section']['type']]: any } = {
  ball: TurnTakingBall,
  clapper: TurnTakingClapper,
  radio: TurnTakingRadio,
  star: TurnTakingStar,
}

export const TurnTakingSection: React.FC<Props> = ({ property, section, index }) => {
  const type = section?.type || 'clapper'
  const TurnComponent = turnComponents[type]
  return <TurnComponent section={section} property={property} />
}

export const TurnTakingSectionFacilitator: React.FC<Props & {
  refresh?: number
  enabledParticipants?: string[]
  message?: string
  hideInteractionIndicator?: boolean
}> = ({ property, section, children, refresh = 0, enabledParticipants, message, hideInteractionIndicator }) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback(property, section, getInitialSectionState)

  const updateSectionState = (state: TurnTakingSectionState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property,
      section_id: section.id,
      state,
    })
  }

  const reset = () =>
    updateSectionState(
      state.participants.reduce((obj, participant) => ({ ...obj, [participant.profile.uid]: 'wait' }), {})
    )

  const selectAll = (status: string) =>
    updateSectionState(
      state.participants.reduce((obj, participant) => ({ ...obj, [participant.profile.uid]: status }), {})
    )

  useEffect(() => {
    if (refresh !== 0) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return (
    <>
      {!hideInteractionIndicator && <ActivityInteractionIndicator type="group" />}
      <FacilitatorControls title="Turn Controls">
        {!!message && <P style={{ marginTop: 0 }}>{message}</P>}
        <FacilitatorControlsTable>
          <thead>
            <tr>
              <th />
              {state.participants.map((participant, i) => (
                <th key={i}>{participant.profile.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {turnStatuses.map(status => (
              <TableRow key={status} className={status}>
                <td
                  style={{
                    textTransform: 'capitalize',
                    textAlign: 'left',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  {status}
                  <Button
                    style={{ marginLeft: 20 }}
                    theme="purple"
                    size="xxs"
                    children="select all >"
                    onClick={() => selectAll(status)}
                  />
                </td>
                {state.participants.map((participant, i) => {
                  const participantStatus = sectionState[participant.profile.uid] || 'wait'
                  return (
                    <td key={i}>
                      <RadioButton
                        value={participantStatus === status}
                        onSelect={() =>
                          updateSectionState({
                            ...(status === 'active'
                              ? Object.keys(sectionState).reduce(
                                  (obj, participantKey) => ({
                                    ...obj,
                                    [participantKey]: 'wait',
                                  }),
                                  {} as typeof sectionState
                                )
                              : sectionState),
                            [participant.profile.uid]: status,
                          })
                        }
                        disabled={
                          enabledParticipants !== undefined
                            ? enabledParticipants.find(uid => uid === participant.profile.uid)
                              ? false
                              : true
                            : false
                        }
                      />
                    </td>
                  )
                })}
              </TableRow>
            ))}
          </tbody>
        </FacilitatorControlsTable>
        {children}
      </FacilitatorControls>
    </>
  )
}

const containerHeights: { [key in Exclude<TurnTakingSectionType['size'], null>]: number } = {
  large: 600,
  medium: 480,
  small: 250,
}

const deviceWidths: { [key in Exclude<TurnTakingSectionType['size'], null>]: number } = {
  large: 400,
  medium: 360,
  small: 280,
}

const shadowScale: { [key in Exclude<TurnTakingSectionType['size'], null>]: number } = {
  large: 1,
  medium: 0.8,
  small: 0.6,
}

const billboardSizes: {
  [key in Exclude<TurnTakingSectionType['size'], null>]: { width: number; height: number; fontSize: number }
} = {
  large: { width: 300, height: 70, fontSize: 36 },
  medium: { width: 250, height: 60, fontSize: 30 },
  small: { width: 200, height: 45, fontSize: 24 },
}

const FlexCenter = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  width: ${deviceWidths.large}px;
`

const DropShadow = styled.div`
  position: absolute;
  top: calc(50% + ${deviceWidths.large / 2 - 20}px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`
const Billboard = styled.div<{ size: TurnTakingSectionType['size'] }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${fontHeavy}
  text-transform: initial;
  font-size: ${p => (p.size ? `${billboardSizes[p.size].fontSize}px` : `${billboardSizes['large'].fontSize}px`)};
  width: ${p => (p.size ? `${billboardSizes[p.size].width}px` : `${billboardSizes['large'].width}px`)};
  height: ${p => (p.size ? `${billboardSizes[p.size].height}px` : `${billboardSizes['large'].height}px`)};
  background-color: white;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #cdd2e4;
  line-height: 1.85;
  z-index: 2;
  pointer-events: none;
  color: #011a46;
`

const SoccerBall = styled(FlexCenter)<{ active?: boolean }>`
  border-radius: 100%;
  border: 2px dashed #bdc3e0;

  & img {
    display: block;
    width: 100%;
  }
`

const ClapperArm = styled.div`
  position: absolute;
  top: 25px;
`

const shake = keyframes`
  10%, 90% {
    transform: rotateZ(-2deg);
  }
  
  20%, 80% {
    transform: rotateZ(1deg);
  }

  30%, 50%, 70% {
    transform: rotateZ(-3deg);
  }

  40%, 60% {
    transform: rotateZ(3deg);
  }
`

const Clapper = styled(FlexCenter)<{ active?: boolean }>`
  position: relative;
  height: ${(clapperImageSize.height / clapperImageSize.width) * deviceWidths.large}px;

  ${p =>
    p.active
      ? css`
          animation: ${shake} 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) 1 normal both;
        `
      : ''}

  & img {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  & ${ClapperArm} {
    position: absolute;
    left: -1.7%;
    top: -19.2%;
    width: 412px;
    & > img {
      transition: 0.2s;
      z-index: 1;
      width: 100%;
      height: auto;
      transform-origin: 7.5% 50%;
      transform: ${p => (p.active ? 'rotateZ(0)' : 'rotateZ(-10deg)')};
    }
  }

  &:hover ${ClapperArm} img {
    transform: rotateZ(-5deg);
    top: 1px;
  }
`

const Wand = styled.div`
  position: absolute;
  transition: 0.5s ease-in-out;
`

const StarFill = styled.div`
  position: absolute;
  top: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;
`

const Star = styled(FlexCenter)<{ active?: boolean; prepare?: boolean }>`
  position: relative;

  & ${Wand} {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform-origin: 96.8% 71.5%;
    ${p =>
      p.active
        ? css`
            transform: translate(20%, -120%) rotateZ(-37deg);
          `
        : p.prepare
        ? css`
            transform: translate(50%, -50%);
          `
        : css`
            transform: translate(150%, -80%);
          `}
  }

  & ${StarFill} {
    width: 100%;
    opacity: ${p => (p.active ? '1' : '0')};
    > img {
      width: 100%;
    }
  }
`

const Container = styled.div<{ size: TurnTakingSectionType['size']; type: TurnTakingSectionType['type'] }>`
  position: relative;
  height: ${p => containerHeights[p.size || 'large']}px;
  background: white;
  border-radius: 10px;
  border: 1px solid #bdc3e0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > ${FlexCenter} {
    width: ${p => deviceWidths[p.size || 'large']}px;
  }

  > ${SoccerBall} {
    > img {
      height: 100%;
      width: 100%;
    }
  }

  > ${Clapper} {
    ${p =>
      p.size === 'small'
        ? css`
            height: 200px;
            width: 230px;

            > ${ClapperArm} {
              width: 238px;
            }
          `
        : css`
            height: ${(clapperImageSize.height / clapperImageSize.width) * deviceWidths[p.size || 'large']}px;
            > ${ClapperArm} {
              width: ${deviceWidths[p.size || 'large'] * 1.0325}px;
            }
          `}
  }

  > ${Star} {
    > img {
      height: 100%;
      width: 100%;
    }
  }

  > ${DropShadow} {
    top: calc(50% + ${p => deviceWidths[p.size || 'large'] / 2 - 20}px);
    transform: translateX(-50%) scale(${p => shadowScale[p.size || 'large']});
  }
`

const TableRow = styled.tr`
  &.wait label input:checked + span {
    background: linear-gradient(180deg, #ff2928 0%, #ff7373 100%) !important;
  }

  &.prepare label input:checked + span {
    background: linear-gradient(180deg, #6c3bbc 0%, #8d57e6 100%) !important;
  }

  &.active label input:checked + span {
    background: linear-gradient(180deg, #58b74b 0%, #60c753 100%) !important;
  }
`
