import React from 'react'
import {
  Row,
  Spacer,
  Column,
  CUT_BL,
  CUT_BR,
  CUT_TL,
  CUT_TR,
  Panel,
  TAB_T_L,
  TAB_B_L,
  Hr,
  Container,
  Button,
} from 'common/ui'
import { useMedia } from 'utils/useMedia'
import { ContentItem } from './ContentPage'
import {
  Accordion,
  AccordionBody,
  AccordionImage,
  AccordionInner,
  AccordionRight,
  AccordionTitle,
} from 'session/common/Accordion'
import { ShowText } from 'dashboards/facilitator/help/FAQs'
import { CircleButton } from 'session/common/CircleButton'
import { getImageUrl } from 'session/utils/assetUtils'
import { BasicDividerSection } from 'session/sections/Divider'
import { BasicListSection, ListContainer } from 'session/sections/List'
import { BlockSection, DividerSection, ListSection, MediaGridSection, TextSection } from 'shared/session/types'
import { TableSection } from 'shared/fac-man/types'
import flattenDeep from 'lodash/flattenDeep'
import { BasicGridSection } from 'session/sections/MediaGrid'
import { BasicTextSection } from 'session/sections/Text'
import { BasicTableSection } from 'fac-man/sections/TableSection'
import { DownloadResourceInner as BasicDownloadResourceSection } from 'session/sections/DownloadResourceSection'
import { BasicBlockSection, Block, BlockBody, BlockMedia } from 'session/sections/Block'
import styled from 'styled-components'
import { fontBold } from 'fonts'
import { RichTextContainer } from 'session/common/RichText'

type SectionTypes = DividerSection | ListSection | MediaGridSection | TextSection | TableSection | BlockSection

const sectionProperties = [
  'divider_sections',
  'list_sections',
  'media_grid_sections',
  'text_sections',
  'table_sections',
  'download_resource_sections',
] as const

type SectionProperty = typeof sectionProperties[number]

const sectionComponents = {
  divider_sections: BasicDividerSection,
  list_sections: BasicListSection,
  media_grid_sections: BasicGridSection,
  text_sections: BasicTextSection,
  table_sections: BasicTableSection,
  download_resource_sections: BasicDownloadResourceSection,
  block_sections: BasicBlockSection,
}

const sections = Object.keys(sectionComponents) as SectionProperty[]

export const Items: React.FC<{ items: ContentItem[] }> = ({ items }) => {
  const compact = useMedia('(max-width: 500px)')

  return (
    <>
      <Row margin="xs" marginBottom justifyContent="center">
        <Column style={{ maxWidth: '1000px', width: '100%' }}>
          <Spacer flex="1 1 0px" />
          <ContentPanel
            padding={compact ? true : '25px 40px 40px 40px'}
            flair={compact ? [] : [CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_T_L, TAB_B_L]}>
            <Spacer size="l" />
            <Accordion>
              <Hr margin="xs" />
              {items.map((item, index) => (
                <AccordionItem key={index} item={item} />
              ))}
            </Accordion>
          </ContentPanel>
        </Column>
      </Row>
    </>
  )
}

export const AccordionItem: React.FC<{ item: ContentItem; isPreview?: boolean }> = ({ item, isPreview }) => {
  const { title, image, enabled } = item
  const [expanded, setExpanded] = React.useState(isPreview ? true : false)
  const toggleExpanded = () => setExpanded(!expanded)

  if (!enabled && !isPreview) return null

  return (
    <>
      <AccordionInner>
        <AccordionTitle style={{ fontSize: '1.8rem' }}>
          {image && <AccordionImage src={getImageUrl(image, { preset: '160w-16-9' })} alt={title} />}
          {title}
        </AccordionTitle>
        <AccordionRight onClick={toggleExpanded}>
          <ShowText>{expanded ? 'HIDE' : 'SHOW'}</ShowText>
          <CircleButton size="s" children={expanded ? '▲' : '▼'} />
        </AccordionRight>
      </AccordionInner>
      <AccordionBody expanded={!!expanded}>
        <Hr margin="xs" />
        <Spacer size="l" />
        {flattenDeep(
          sections.map((sectionProperty, index) => {
            const Component = sectionComponents[sectionProperty]
            const children: SectionTypes[] = item[sectionProperty] || []
            return children.map((item, idx) => {
              return (
                // @ts-ignore
                <Component key={`${sectionProperty}${idx}`} {...item} />
              )
            })
          })
        )
          .sort((a, b) => {
            return a.props.order - b.props.order
          })
          .map((component, idx) => {
            return <Container marginBottom={30} key={idx} children={component} />
          })}
        <Spacer size="m" />
        <Button onClick={toggleExpanded} children="Hide Section" size="s" />
        <Spacer size="xl" />
      </AccordionBody>
      <Hr margin="xs" />
    </>
  )
}

// This is mainly to override the default styling of components
export const ContentPanel = styled(Panel)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #011a46;
  }

  h2 {
    font-size: 1.2rem;
  }

  ${ListContainer} {
    ol {
      position: relative;
      z-index: 1;

      & > li {
        &::marker {
          color: #fff;
          font-size: 0.9rem;
        }
        &::after {
          background-color: #808080;
          padding: 10px;
          left: -20px;
          top: 3px;
        }
      }

      &.list-checklist > li {
        &::marker {
          color: transparent;
        }
      }

      &.list-alphabetic,
      &.list-numeric {
        counter-reset: list;
        list-style: none;

        li {
          counter-increment: list;
          position: relative;

          &:before {
            color: #fff;
            margin-right: 5px;
            position: absolute;
            right: 100%;
          }
        }
      }

      &.list-numeric li:before {
        font-size: 0.9rem;
        content: counter(list);
        top: 2px;
      }

      &.list-alphabetic li {
        &:before {
          font-size: 0.8rem;
          content: counter(list, upper-alpha);
          top: 3px;
        }
        &:after {
          left: -19px;
        }
      }
    }

    ul > li {
      padding-left: 25px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 1em;
        height: 1.3em;
        background: url(${require('../../session/assets/list-bullet-gray.svg')?.default}) no-repeat right top;
      }
    }
  }

  ${RichTextContainer} {
    h1 {
      font-size: 1.2rem;
    }

    h2 {
      font-size: 1.3rem;
    }

    ol,
    ul {
      padding-left: 18px;

      li {
        padding-left: 5px;
        margin-bottom: 8px;

        &:before {
          left: -18px;
        }
      }
    }

    ol {
      counter-reset: orderedList;
      list-style: none;

      li {
        counter-increment: orderedList;
        position: relative;
        padding-top: 2px;

        &:before {
          color: #fff;
          font-size: 0.9rem;
          line-height: 0.9;
          content: counter(orderedList);
          position: absolute;
          right: 100%;
          padding: 2px;
        }
      }
    }
  }

  ${Block} {
    ${BlockMedia} {
      border-radius: 10px;
    }

    ${BlockBody} {
      > div {
        margin-top: 0;
      }

      padding: 5px 15px;
      background: transparent;
      border: none;
    }
  }

  table {
    border-collapse: collapse;

    th {
      color: #011a46;
      background: none;
      font-size: large;
      text-transform: uppercase;
      padding: 0;
      ${fontBold}

      &:first-child > div {
        border-radius: 10px 0 0 0;
        border-right-width: 0.5px;
      }

      &:last-child > div {
        border-radius: 0 10px 0 0;
        border-left-width: 0.5px;
      }

      & > div {
        padding: 20px;
        border: 1px solid #c4cae5;
        border-bottom: none;
      }
    }

    td {
      background: none;
      padding: 0;

      &:first-child > div {
        border-right-width: 0.5px;
      }

      &:last-child > div {
        border-left-width: 0.5px;
      }

      & > div {
        border: 1px solid #c4cae5;
        border-bottom-width: 0.5px;
        border-top-width: 0.5px;
        padding: 0px 20px;
      }
    }

    tr {
      &:first-child td > div {
        border-top-width: 1px;
      }
      &:last-child td {
        & > div {
          border-bottom-width: 1px;
        }

        &:first-child > div {
          border-radius: 0 0 0 10px;
        }

        &:last-child > div {
          border-radius: 0 0 10px 0;
        }
      }
    }
  }
`
