import { useState, useEffect } from 'react'

export function useVideoPlaying(video: HTMLVideoElement): boolean {
  const [state, setState] = useState(() => !video.paused && !video.ended)
  useEffect(() => {
    const playCallback = () => setState(true)
    const pauseCallback = () => setState(false)
    // IE11 needs to be paused manually on end
    const endedCallbackIE11 = () => !video.paused && video.pause()
    video.addEventListener('play', playCallback)
    video.addEventListener('pause', pauseCallback)
    video.addEventListener('stop', pauseCallback)
    video.addEventListener('ended', endedCallbackIE11)
    return () => {
      video.removeEventListener('play', playCallback)
      video.removeEventListener('pause', pauseCallback)
      video.removeEventListener('stop', pauseCallback)
      video.removeEventListener('ended', endedCallbackIE11)
    }
  }, [video])
  return state
}
