import { useEffect } from 'react'
import { isPhone } from './deviceUtils'
import { useMedia } from './useMedia'
import { useUserState } from 'app/UserState'

export function useIOS100vh() {
  const portrait = useMedia('(orientation: portrait)')
  const { usingIOSvhFix, setUsingIOSvhFix } = useUserState()

  useEffect(() => {
    let mounted = true

    const updateBodyHeight = () => {
      if (!mounted) return
      document.body.style.height = window.innerHeight + 'px'
    }

    if (isPhone() || portrait) {
      window.addEventListener('resize', updateBodyHeight)
      updateBodyHeight()
      setUsingIOSvhFix(true)
    } else {
      document.body.style.height = 'initial'
      setUsingIOSvhFix(false)
    }

    return () => {
      mounted = false
      window.removeEventListener('resize', updateBodyHeight)
    }
  }, [portrait, setUsingIOSvhFix])

  return usingIOSvhFix
}
