import React from 'react'
import requireResizedImage from '../../../../macros/requireResizedImage.macro.js'

export const images = {
  background: require('./assets/background.png'),
  sceneColor: require('./assets/scene-color.jpg'),
  sceneBW: require('./assets/scene-bw.jpg'),
  beanBag: require('./assets/beanBag.png'),
  bear: require('./assets/bear.png'),
  bed: require('./assets/bed.png'),
  books1: require('./assets/books1.png'),
  books2: require('./assets/books2.png'),
  books3: require('./assets/books3.png'),
  books4: require('./assets/books4.png'),
  books5: require('./assets/books5.png'),
  chair: require('./assets/chair.png'),
  clock: require('./assets/clock.png'),
  computer: require('./assets/computer.png'),
  desk: require('./assets/desk.png'),
  door: require('./assets/door.png'),
  lamp: require('./assets/lamp.png'),
  letterA: require('./assets/letterA.png'),
  letterB: require('./assets/letterB.png'),
  letterC: require('./assets/letterC.png'),
  medal: require('./assets/medal.png'),
  pencils: require('./assets/pencils.png'),
  rug: require('./assets/rug.png'),
  shelves: require('./assets/shelves.png'),
  toyCar: require('./assets/toyCar.png'),

  card1: require('./assets/card1.jpg'),
  card1_back: require('./assets/card1_back.png'),
  card2: require('./assets/card2.jpg'),
  card2_back: require('./assets/card2_back.png'),
  card3: require('./assets/card3.jpg'),
  card3_back: require('./assets/card3_back.png'),
  card4: require('./assets/card4.jpg'),
  card4_back: require('./assets/card4_back.png'),
  card5: require('./assets/card5.jpg'),
  card5_back: require('./assets/card5_back.png'),
  card6: require('./assets/card6.jpg'),
  card6_back: require('./assets/card6_back.png'),
  card7: require('./assets/card7.jpg'),
  card7_back: require('./assets/card7_back.png'),
}

const thumbnailConfig = {
  width: 120,
  height: 180,
  fit: 'contain',
}

export const thumbnails = {
  card1: requireResizedImage('./assets/card1.jpg', thumbnailConfig),
  card2: requireResizedImage('./assets/card2.jpg', thumbnailConfig),
  card3: requireResizedImage('./assets/card3.jpg', thumbnailConfig),
  card4: requireResizedImage('./assets/card4.jpg', thumbnailConfig),
  card5: requireResizedImage('./assets/card5.jpg', thumbnailConfig),
  card6: requireResizedImage('./assets/card6.jpg', thumbnailConfig),
  card7: requireResizedImage('./assets/card7.jpg', thumbnailConfig),
}

export const svgs = {
  beanBag: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 347.3 253.5" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M12.8 81.6s-6.1 8.6-9.1 25.7c0 0-3.5 15-3.6 27.6-.1 12.6-.1 23.3.5 31 .6 7.7 2.6 20.4 3.1 22.3.5 2 1.9 8.4 1.9 8.4s1.2 4.1 5 7.7c3.8 3.6 8.5 8.9 22.3 14.6s33.7 11.9 37.4 13 13 3.1 16.1 4.1l24.5 5.8 21.4 4.3s30.1 5.2 40.3 5.8c10.2.5 42 1.7 42 1.7s10.9-.2 13.2-.2c2.3-.1 19.4 0 23.3 0 3.9 0 29.3-.3 33.4-.5s22.7-.7 39.8-9.8c0 0 7.4-3.4 9.6-10.6 0 0 3.2-10.4 4.6-14.9 0 0 5.1-19 5.8-24 .6-5 2.4-13 2.6-17.3.2-4.2.5-29 .5-33.8 0-2.6.1-10-.3-16.7-.3-5.9-.7-11.4-.9-12.5-.2-1.4-.8-5.9-2.5-12-.3-1.2-1.2-2.5-1.7-3.8-1.4-4.2-3.1-8.5-4-10.1 0 0-3.2-7.8-5.8-11.3 0 0-1.9-6-4.2-10.9-1.6-3.3-3.9-6.2-3.9-6.2s-8.2-16.4-12.7-21.4-8.2-8.2-8.2-8.2-6.6-8.5-12.5-13.4-13.3-9.8-19.2-10.1c0 0-5.6-5.1-20.9-5.8 0 0-5.1-.1-9.6 1.4 0 0-2.1-2.1-17-1l-2.6.2s-3.5-1.2-9.4-.2c-1.1 0-3.4-.7-6.7-.5-3.3.2-5.8 1.2-5.8 1.2s-4.2-.5-6.2-.5c-2.1 0-8 .7-10.6 1.4s-2.4.7-4.1.7c-1.7 0-3-.9-8.2 2.2-.3 0-4.1 2.9-4.1 2.9s-2.5.5-4.8 1.2-9.8 5.1-18.2 12l-4.6 4.8s-2.9 2.9-3.8 4.1c-.9 1.1-4.6 6.4-5.3 12.7 0 0-3-2.2-9.8-1.2-6.8 1-19.2 5.9-22.3 7.2s-9.4 4.1-9.4 4.1-4.3 1.2-9.1 4.1l-3.8 1.2s-11.8 2.7-16.8 4.8c-.6.1-3.4 1.2-3.4 1.2s-9.2-1.6-17 .7c-3.1.5-8.8 3.3-13.9 6.7-2.6 1.7-5 3.2-7 5.3-5.8 6.3-4.3 4.8-4.3 4.8z"
        clipRule="evenodd"></path>
    </svg>
  ),
  bear: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 50.1 58.4" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M10.6 4.5S6 6.9 6 12.2s6.5 7.4 6.5 7.4l-.7 1.7S-2.1 31.1.3 50.1c0 0 0 .9.7 2.2s2 1.8 3.4 1.7H8s1.6-.6 1.7-3.1v-5s1.5 3.5 2.4 4.3c0 0-2.1 2.5-1.4 3.6.6 1.1 1.3 4.1 7.9 4.6 0 0 6.2 1.1 7.2-2.2 0 0 .4-1.6-.2-2.9l1.9-.2.5-.2 10.3 2.9s4.1 1.3 6 1.2c1.9-.1 5.4-.1 5.8-1.7.3-1.6.3-3.7-1.7-5.3-2-1.5-4-2.5-5.8-2.9 0 0-6.8-20.3-16.8-26.2 0 0-.5 0-.7.2-.2.2-.2-.7-.2-.7s7.2-2.4 7.2-7.2S30 7 27.4 4.7c0 0 2.7-2.1.2-4.3 0 0-3-1.2-4.1.7L23.3 3s-3-1.1-8.2-.2l-.2-1.4s-1.1-1.6-3.1-.2-1.3 2.7-1.2 3.3z"
        clipRule="evenodd"></path>
    </svg>
  ),
  bed: (
    <svg xmlns="http://www.w3.org/2000/svg" width="481.4" height="382.1" viewBox="0 0 481.4 382.1">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.7 237.4v29a6.9 6.9 0 015.8-3.1h19.7s-11.9-9.9-15.2-13.5-10.3-12.4-10.3-12.4zM18.7 66v16.1s0-9.4 7.5-9.4a58.4 58.4 0 01-7.5-6.7z"></path>
      <path fill="#fff" fillRule="evenodd" d="M421.7 133.2L414 133.1 418 142.1 418.2 142.1 421.7 133.2z"></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M469.2 182.9l-3.6-34.3c-7.7-44.4-6.8-96-6.7-148.4-12.3 0-17.8-.3-25.9 3.6l-.3 29.3-37.9 15.1-20.6-4.8c-.8-.8-.6-3.6-.5-5.2-4.9 0-11.4.7-15.4.4l-25.9-7.2c-14.9-3.2-31.6-4.7-46.1-7.2-10.5-1.8-22.4-.1-33.3-.2l-19.2-.2-15.9 3.8-10.8-1.7-1.4 1.9-.5.3c-1.8-1.6-3.6-3.7-7.2-3.4l-3.8.3c-2.3-.6-4.9-2.3-8.4-1.7-2 .3-4.6 1.2-7.2.7-12.1-2.2-29.1-1.2-41.1.7-8.6 1.4-17.7-.7-21.1 5.5H62.2L52.3 24C43 18.5 31 12.2 18.7 10.6V0H0q.2 191 .2 382.1h18.5v-68.4h1v65l25.2.3c0-10.1 3-16.6 7.4-22.1l4.8-5.1.5-.4c-11.2-5.9-5.1-10.5-1.4-18.3l2.8-.5a2.7 2.7 0 01.8 2c2.7-.4 5.7-.6 8.1.2.7-1.5.6-2.1 1.9-2.9s2.6.4 3.4 1.5-.2 2.2-1 3.3c6.8 4.2 5 13.2-2.4 15.4l-.2.5c7.1.6 15.1 19.4 17.5 26.1l54 .3c2.7 0 16.3.4 16.8-.8.6 1.4 6 .8 7.9.8h131.3v-1.2c4.4 3 26.9 1.2 34.6 1.2h10.5c.3-7.1 6.5-25.1 10.1-29.8 8.3-7 13.1-10.4 23.1-14.4 5.1-1.5 11.3-1.7 18-1.5 4.9-1.3 12.8-3.2 22.5-6.2 3.7-1.1 1.9-1.5 8.4-3.6 11.4-3.7 32.4-16.1 43.9-11 1.6-10.5 5.9-13.6 13.2-20.9-4-36.5-8.1-72.2-12.2-108.7zm-349-131.5l1.2 7.4c-2.8 1.7-12.2 14.2-12.2 14.2H88.8c7.7-12.3-.7-21.4-.7-21.4zm195.4 184.3c-8.5.1-33.4 2-76.6 6.2s-67.9 11.3-67.9 11.3c-12.1-7.8-31.4-5.8-31.4-5.8-25.7.7-37.2 5.8-37.2 5.8-10.4 4.6 1.7 10.3 1.7 10.3l-12.3-.5c.9-2.1.7-8.8.7-8.8.1-4.7-6-17.1-6-17.1-4.4-9.2-13.4-17.7-22-26.1s-17.1-11.3-17.1-11.3c-5.6-.8-7.1 1-11.7 0S19 197 19 197v-70.8h316.8l8.6 113.1c-9.8-3.7-20.3-3.8-28.8-3.6zM432.7 57.6v30.7l-1.7.3v-9.4s-.3-6.6-8.8-6.5h-5.8l-3.6-8.6zm.3 226.6l-1.5-3.2c-4.9-9.5-9.1-10-9.1-10s-13.4-5.3-22.1-8-26.6-10.3-26.6-10.3v-5h42l17.3 2.1zm-59.3-58.6v-30.5h59v35.1zM433 186h-59.3v-43.9h38.5l-4.1-9h-34.4l-.3-7.7 59.3-12.4v20.2h-5.1l-3.7 8.9h9.1z"></path>
    </svg>
  ),
  books1: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 67.2 41.3" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M5.5 0S.2 1.7 0 2.6 1.7 7 1.7 7l15.8 34.3h49.7v-1.4l-1.9-2.4.2-2.4v-1.2l.2-3.6h1l-6.5-8.2H24.5s-.4 2.9 0 5h-2.2L14.4 9.8 5.5 0z"
        clipRule="evenodd"></path>
    </svg>
  ),
  books2: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 27.4 41" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M18.2 0L0 39.1.7 41h15.1l6.5-13.4V16.8l-.2-.7v-6h4.1l1.2-2.4-2.6-3.6-.5-.7c-.1 0-.1-1.1-6.1-3.4z"
        clipRule="evenodd"></path>
    </svg>
  ),
  books3: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 65 48.2" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M0 9.1V48h44.9V29l7 19.2H65V47L52.6 13l-7.5-8-6.7 2.4-1-2.2H35L28.8 0h-6.5v5.3h-6.7V7h-4.3L9.8 6l-.4.2v3.6l-1-.7H0z"
        clipRule="evenodd"></path>
    </svg>
  ),
  books4: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 64.1 49" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M0 9.1V49h42.5V22.1L52.3 49h4.6l5.5-2.4 1.7.2L50.2 7.4l-4.8-2.2-4.6 1.4-3.6-1.4-3.4-.2V2.2L29 0h-6.5v5.3h-7.2v1H12l-1.9-.7h-.7v4.3l-.8-.8H0z"
        clipRule="evenodd"></path>
    </svg>
  ),
  books5: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 63.4 43.7" xmlSpace="preserve">
      <g fill="#FFF" fillRule="evenodd" clipRule="evenodd">
        <path d="M5.8 21.6h31.4v.5l-.5.5v4.1l.5.2v.5h1.7v5.3L37 34.8h-.7v6h.7v.5l-2 2.1H0s4-12.8 5.8-21.8zM56.6 1.2L38.2 40.8l5.5 2.9h.7l2.2-3.1L63.1 4.3l.2-1.2-.2-.2-.5.5L58 1.2l.2-.7-.4-.5-1.2 1.2z"></path>
      </g>
    </svg>
  ),
  chair: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 200.1 239" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M.2 106.4s-3.3-20.5 19.7-49.2c0 0 12.2-12.9 36.2-9.8 0 0 6.3-7.1 13.9-8.9s19.6-1.2 27.6-.7 30.5 4.3 37.4 2.6c0 0 11.1-12.7 15.6-19.9 4.5-7.2 14.3-12.7 21.8-16.1 0 0 12.8-3.9 15.1-4.3.3-.1 9.8-.5 11.8 4.6 0 0 3.2 8.5-4.1 31.4 0 0-8.5 28.4-14.9 37.2 0 0-2.1 6.1-20.2 7.7l-7.4 1.4s-1.2 10.9 1.2 16.1c2.4 5.2 8.5 10.8 5 19.2 0 0-1.6 9-18.7 10.8 0 0-16.8.3-35.3-1.9v66s1.2 5.4 2.9 6.2 59.3 6.2 59.3 6.2 7.5 1.5 3.4 3.6l-4.1.2-50.9-1.2 39.6 27.6s2.8 3-4.1 3.6l-2.2-1-43.7-24.2s-7-6.2-20.2 0l-60.5 24s-8.3 3.2-8.6-.2c0 0 0-1.4 4.3-3.6s37-17.3 37-17.3l25-12-32.6 3.6s-4.5-1 .2-2.6l37-5.3s4.8-2.2 4.8-10.3v-64.8s-24-2.6-43.2-5c-19.1-2.5-46.3-5-48.1-13.7z"
        clipRule="evenodd"></path>
    </svg>
  ),
  clock: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 73.5 73.5" xmlSpace="preserve">
      <circle cx="36.7" cy="36.7" r="36.7" fill="#FFF" fillRule="evenodd" clipRule="evenodd"></circle>
    </svg>
  ),
  computer: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 84.5 177.8" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M33.5 95l-22.6 68.6s-1.4 9.7 9.6 10.1 25.9-.5 25.9-.5l35.3.2.2 2.9-63.8 1.4-9.1-1.8s-13.9-3-7.2-19.7L29.9 84l1-2.2S20 26.5 20.6 16.8C22 16 45.7.7 45.7.7l8.7-.7s1.8 2.2 4.3 14.6c2.5 12.5 25.7 133 25.7 133s.7 3.4-1 3.6c-1.6.2-8.2 1-8.2 1L47.4 151S37 124.4 33.5 95z"
        clipRule="evenodd"></path>
    </svg>
  ),
  desk: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 419.8 288.7" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M401.8 28.8l1-16.1 17-2.6V.2l-110-.2-62.2.2-22.8 1s.1 8.6.1 9.7c-1.7 2-3.6 3.5-5.6 3.5-4.5 0-16.9.5-17.3 0-9.1 0-7.5-11.2-7.4-11.3-30.4 2.2-69.8 4.8-69.8 4.8h38.6l10.3.7 1 4.1-62.6.7-14.4-3.8L0 16.3v14.4h53l-.2 7.9 4.6.2L56 51.5l43.7-.2-83.9 236.9 29.8-.2 5.8-2.6 20.2-57.8 12.7-7.2 21.6.2-1.2 23.3 25.9-.2 3.8-1.9 1.4-21.4h105.8l6 67.7 29.5.7 4.1-3.1-6.2-62.4 18-12.2 11.3 33.1 25.4-.5 2.4-1.9-28.3-89h49.7l6 47.3h21.8l1.7-1.2-5.5-44.2 5-3.4 7.7 24.7h19.4l1-1.2-17.5-60.5s-26.5-2.6-31-8.4c-4.5-5.8 1.7-29.7 14.6-47.8l-8.2-27.4h28.8l4.5-1.9zM108 187.2l-26.6 10.6L110 115l1.7 18.7-3.7 53.5zm30.2 1.4L148 52l78-.2 2.6 29.3-33.4 108-57-.5zm81.2 1l9.8-33.4 6.7-1.7 2.6 34.8-19.1.3zm52.3.2l-3.6-37.4h5.5l12.7 37.4h-14.6zm26.1-54.9l-23.5-74.4 1.4-8.6H306l8.2-2.2-.7-13h3.4l-.5-5.5 22.6-.7 12.5 104.4h-53.7zm79.5-.3h-2.9l-1.9-15.8 4.8 15.8z"
        clipRule="evenodd"></path>
    </svg>
  ),
  door: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 97.1 695.4" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M39.1 1.1L0 24.6v442.9c4.1 4.3 7.5 8.7 7.5 8.7s3.7 3.2 8.2 8.2c4.5 5 12.7 21.4 12.7 21.4s2.3 2.8 3.9 6.2c2.3 4.9 4.2 10.9 4.2 10.9 2.5 3.5 5.8 11.3 5.8 11.3.9 1.6 2.6 5.9 4 10.1.4 1.3 1.3 2.6 1.7 3.8 1.7 6.1 2.3 10.7 2.5 12 .2 1.2.6 6.6.9 12.5.4 6.8.3 14.2.3 16.7 0 4.8-.3 29.6-.5 33.8-.2 4.2-2 12.3-2.6 17.3-.5 4.1-4 17.4-5.3 22.2l53.8 32.8V0s-61.4 1.1-58 1.1z"
        clipRule="evenodd"></path>
    </svg>
  ),
  lamp: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 109.9 161.2" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M107.1 38.9l-3.4-5.5s-2.4-3.9-11-6.2l-10.1-3.6-1.9-5s4.2-3.5-1.2-4.8l-1.2-1.9-1.9-4.3s-.8-1.1-3.1-.7L72.8 5h-1S71.2.2 66 0c-.4.1-8.8.6-10 12.2 0 0-.5 4.9.5 7 0 0-4.4 1.1-2.6 6.7L6.6 71H3.4S.7 70 0 74.4c-.5 4.3 2.4 4.5 3 4.6.6.1 2.6 0 2.6 0l34.8 63.4s-1.8 1.4-1.2 4.6c.6 3.1 2.5 3.7 3.8 3.6h3.6v1.9l-28.8 4.8v3.6s36.5 1.3 60.7-.5l.2-3.4-28.1-4.3s-.8-2.4 0-2.4h3.1s2.2-.6 2.2-4.6c0-3.9-4.1-3.8-4.1-3.8h-1.4L15.4 79s1.9-2.1.7-6l47.3-44.4 4.1.2-8.6 8.2s-3.3 2.6-3.4 9.8v2.6s-1.9 2.6-1.2 4.1c.7 1.5 2.6 7.6 26.9 3.8 0 0 21.2-3.4 28.1-12.5 0 .1 2.4-4.4-2.2-5.9z"
        clipRule="evenodd"></path>
    </svg>
  ),
  letterA: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 40.6 41" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M0 41h19s.8-2.2 2.2-2.4l1 2.4h18.5L31 5.9s-1.1-3.6-4.8-4.8S19.8-.4 14.9.4s-4.8 1.7-5.8 5.3S0 41 0 41z"
        clipRule="evenodd"></path>
    </svg>
  ),
  letterB: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 38.2 42.9" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M0 6.4v32.4s.1 4.1 2.2 4.1H32s5.9-.6 6.2-8.9-4-11.4-5.5-12c0 0 4.1-1.7 4.1-8.9S31 2 27.1 1.6 11.5.1 11.5.1 1.1-1.5 0 6.4z"
        clipRule="evenodd"></path>
    </svg>
  ),
  letterC: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 38.7 43.7" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M24.9 43.7h-9.1S7.9 43.1 4 36.5-2.1 19.8 2.8 8.7c0 0 3.7-8.8 17.3-8.6 0 0 11.4-.6 15.1 6 0 0 3.5 4.9 1.9 8.4 0 0-1.5 2-6.7 2.2s-5.5-.2-7.9-2.2-3.8-.6-4.1.7-1.3 12.8 1.7 16.5c0 0 1.9-.8 2.4-2.9s3.8-4.2 6.5-3.6 6.5 1.4 6.5 1.4 4.5 2 2.9 5.8c-1.7 3.8-3.8 10-13.5 11.3z"
        clipRule="evenodd"></path>
    </svg>
  ),
  medal: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 24.6 39.9" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M17.2 17.4l.1-2.4h-1l6.5-15h-5.9l-4 9.3L9.1.2H3l4 9.1H6l2.4 6.2V17C3.5 18.6 0 23 0 28.2 0 34.7 5.5 40 12.3 40c6.8 0 12.3-5.3 12.3-11.8 0-4.9-3.1-9-7.4-10.8z"
        clipRule="evenodd"></path>
    </svg>
  ),
  pencils: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 32.7 60" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M3.6 25.6v5.2l2.4 2-3.3 3.1v18.7c0 3 2.5 5.5 5.5 5.5h19.1c3 0 5.5-2.5 5.5-5.5V35.7L29 32.6l1.8-1.4v-5.6h-2.9L30 2.9c-1-.8-2.2-1.2-3.5-1.2h-.7L24.4 16l-.1-7.4h-3.1L20.7 6l-1.8 7.9-.2 5.2-.6-15-4.3.1.2-1.3h-4l.9 13.1-2.2-8-.8-6.3C7.8.6 6.8-.2 5.7 0L4.6.2C3.6.4 2.9 1.3 3 2.3l1 9-1-3.6-3 1.5 5.1 16.5-1.5-.1z"
        clipRule="evenodd"></path>
    </svg>
  ),
  rug: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 844.1 187.7" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M51.4.2L0 179.8l1.2 6 841.4 1.9 1.4-73.9-66.2-38.4s-1.9 16-6.2 19.2-16.3 21.3-107.8 16.1c-91.5-5.2-155.9-18.2-192.2-35s-27.4-24.3-29.5-34.8C440 30.3 439.2 0 439.2 0L51.4.2z"
        clipRule="evenodd"></path>
    </svg>
  ),
  shelves: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 290.2 439.9" xmlSpace="preserve">
      <g fill="#FFF" fillRule="evenodd" clipRule="evenodd">
        <path d="M221.8 380.6v59.3h36l1.7-9.1-.2-3.8 12.5-3.1s.7-1.4.2-1.4c-.4 0-3.6-.5-3.6-.5v-2.6l4.6-1.2 17 .7v-46.3h-65c-.1-.1-2.3 6.3-3.2 8zM216.7 393.1l-.2 46.8-23.3-.2s1.4-5.1-2.4-10.3c-3.8-5.2-5.9-15-4.6-22.1l16.6-2.9s9.9-1.4 13.9-11.3z"></path>
      </g>
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M221.5 222.7v69.4h68.2l.2-69.1-68.4-.3zM73.9 223l.2 69.1 68.6.2-.2-68.9-68.6-.4zM147.8 148.6l-.2 69.1h69.1l-.5-69.4-68.4.3zM0 148.3l69.1.5-.2 68.9-60 .2s7-4.4 3.8-8.4-2.4-1.9-2.4-1.9-3.8-7.9-8.9-9.8L0 148.3zM221.3 0l.2 68.4h68.6L289.9 0h-68.6zM.7.7L1 68.9l67.9-.2V.5L.7.7z"
        clipRule="evenodd"></path>
    </svg>
  ),
  toyCar: (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 51.4 50.4" xmlSpace="preserve">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M49.2 31.4l-.7-.2v-3.1l-2.4-.2L43.7 11l1.7-.2.2-3.8-3.8-.2V5.3l.7-.3 4.8-1V1.2L25.4 0l-5.5 1.2.2 2.6 4.1.2-.5 1.4L11 8.6v3.6l-2.4 19-3.1 1H3.8S.4 33.6 0 40.6c0 0-.1 5.3 2.2 7H9.4s1.6-1.1 1.9-2.6l14.4 1 2.9-1s.3 3.9 2.4 5l4.3.5s2.7-.2 3.6-1.4c.9-1.2 1.7-4.8 1.7-4.8s1 2.3 1.4 2.4c.4.1 4.1.2 4.1.2l3.6-1.2c0-.1 4.2-7.7-.5-14.3z"
        clipRule="evenodd"></path>
    </svg>
  ),
}
