import React from 'react'
import styled from 'styled-components'
import { Reward, SkillUse } from 'skill-tracker/types'
import { Row, Column, Padding, Label, P, Slab } from 'common/ui'
import { hasBonusScore, getBonusSkillUses } from 'skill-tracker/reducers'
import { useSkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { Emoji } from 'common/Emoji'

export const RewardSlab: React.FC<{
  reward: Reward
  label?: string
  onEdit?: (reward: Reward) => void
  hideProgress?: boolean
  Component?: typeof Slab
}> = ({ reward, label, onEdit, hideProgress, Component = Slab }) => {
  const { state } = useSkillTrackerState()
  const usingBonusScores =
    hideProgress || reward.frequency !== 'bonus' || !reward.bonusTarget ? false : hasBonusScore(state)
  const bonusSkillUses: SkillUse[] = usingBonusScores ? getBonusSkillUses(state.skillUses) : []
  return (
    <Component onClick={onEdit ? () => onEdit(reward) : undefined} interactive={!!onEdit} flexWrap flex="1 1 auto">
      <Row style={{ width: '100%' }}>
        <Padding size="s">
          <Column flex="1 1 auto" paddingRight={10}>
            {label && <Label>{label}</Label>}
            <P style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{reward.text}</P>
          </Column>
          <Column flex="none" paddingRight={10} justifyContent="center">
            <P style={{ fontSize: 28, margin: 0 }}>{reward.symbol ? <Emoji children={reward.symbol} /> : '?'}</P>
          </Column>
          {reward.frequency === 'bonus' && (
            <Column flex="none" paddingLeft={10} paddingRight={10}>
              <Label style={{ marginBottom: 0 }}>Target:</Label>
              <P style={{ fontSize: 28, margin: 0, lineHeight: 1 }}>{reward.bonusTarget}</P>
            </Column>
          )}
        </Padding>
      </Row>
      {usingBonusScores && reward.bonusTarget && <ProgressBar value={bonusSkillUses.length / reward.bonusTarget} />}
    </Component>
  )
}

const ProgressBar = styled(Row)<{ value: number; height?: string }>`
  width: 100%;
  height: ${p => p.height || '20px'};
  border-top: 1px solid ${p => p.theme.thinOutlineColor};
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${p => Math.min(100, p.value * 100)}%;
    background-color: ${p => (p.value >= 1 ? p.theme.thinOutlineActiveColor : p.theme.thinOutlineColor)};
    border-bottom-left-radius: 4px;
    ${p => (p.value >= 0.99 ? 'border-bottom-right-radius: 4px;' : '')}
  }
`
