import React from 'react'
import { CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L, H1 } from 'common/ui'
import { SectionsPanelStyled } from 'session/common/SectionsPanel'
import { AlertSection, AlertSectionType } from 'session/sections/AlertSection'

const AlertSections: AlertSectionType[] = [
  {
    type: ['sad', 'embarrassed'],
    content: [
      {
        image: require('session/assets/feeling-alerts/1a.png'),
        text: 'They look down or to the side, with their head tilted down',
      },
      { image: require('session/assets/feeling-alerts/1b.png'), text: 'They might cry (if they are really sad)' },
      { image: require('session/assets/feeling-alerts/1c.png'), text: "They are quiet/don't say much" },
      {
        image: require('session/assets/feeling-alerts/1d.png'),
        text: 'Their lips are turned down at the corners or pressed together',
      },
    ],
    title: 'The following clues suggest that a person feels sad or embarrassed:',
  },
  {
    type: ['confused'],
    content: [
      {
        image: require('session/assets/feeling-alerts/2a.png'),
        text: 'Eyebrows are pushed together in the middle (they’re frowning)',
      },
      { image: require('session/assets/feeling-alerts/2b.png'), text: 'Head is slightly tilted' },
      { image: require('session/assets/feeling-alerts/2c.png'), text: 'Eyes are squinted or narrowed' },
      { image: require('session/assets/feeling-alerts/2d.png'), text: 'Scratch their head' },
      {
        image: require('session/assets/feeling-alerts/2e.png'),
        text: 'They say things like: “Huh?” “What?” “Sorry?” “I don’t get it.” or ask lots of questions',
      },
    ],
    title: 'The following clues suggest that a person feels confused:',
  },
  {
    type: ['bored'],
    content: [
      { image: require('session/assets/feeling-alerts/3a.png'), text: 'Yawn' },
      { image: require('session/assets/feeling-alerts/3b.png'), text: 'Often look around or away' },
      { image: require('session/assets/feeling-alerts/3c.png'), text: 'Shoulders are slumped forward' },
      { image: require('session/assets/feeling-alerts/3d.png'), text: 'Look at their watch or a clock' },
      {
        image: require('session/assets/feeling-alerts/3e.png'),
        text: 'Eyes are slightly closed, showing more of their eyelids',
      },
      { image: require('session/assets/feeling-alerts/3f.png'), text: 'Sigh and/or talk in a flat tone of voice' },
    ],
    title: 'The following clues suggest that a person feels bored:',
  },
  {
    type: ['anger'],
    content: [
      {
        image: require('session/assets/feeling-alerts/4a.png'),
        text: 'Their eyebrows are pushed together (they are frowning)',
      },
      {
        image: require('session/assets/feeling-alerts/4b.png'),
        text: 'Their head and eyes are looking straight at you (eyes may	be narrowed)',
      },
      { image: require('session/assets/feeling-alerts/4c.png'), text: 'They talk loudly' },
      { image: require('session/assets/feeling-alerts/4d.png'), text: 'The muscles in their face or body are tense' },
    ],
    title: 'The following clues suggest that a person feels angry:',
  },
]

export const FeelingsAlert: React.FC<{}> = () => (
  <SectionsPanelStyled
    panelTheme={null}
    flex={'1 0 auto'}
    flair={[CUT_TR, CUT_TL, CUT_BL, CUT_BL, CUT_BR, TAB_B_L, TAB_T_L]}>
    <div style={{ padding: '30px 20px' }}>
      <H1 style={{ marginTop: 20 }}>Feelings Alerts</H1>
      {AlertSections.map((item, idx) => (
        <AlertSection key={idx} {...item} />
      ))}
    </div>
  </SectionsPanelStyled>
)
