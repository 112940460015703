import { useEffect, useRef } from 'react'
import { getVideoPool, PreloadStrategy } from './VideoPool'

export function useVideo(
  url: string,
  preloadStrategy: PreloadStrategy = PreloadStrategy.None,
  release: boolean = true
): HTMLVideoElement {
  const lastVideoRef = useRef<HTMLVideoElement | null>(null)
  const video = getVideoPool().reserveVideo(url, preloadStrategy)

  // reset playhead to start if video has changed
  if (lastVideoRef.current !== video) {
    if (video.currentTime > 0) {
      video.currentTime = 0
    }
    lastVideoRef.current = video
  }

  // release video when component unmounts
  useEffect(() => {
    return () => {
      release && getVideoPool().releaseVideo(url)
    }
  }, [url, release])

  return video
}
