import { useEffect } from 'react'
import { useGlobalState } from 'app/GlobalState'

export function usePda(visible: boolean = true) {
  const { pdaVisible, setPdaVisible } = useGlobalState()
  useEffect(() => {
    if (visible !== pdaVisible) {
      setPdaVisible(visible)
    }
  }, [visible, pdaVisible, setPdaVisible])
}
