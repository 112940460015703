import { Scene, Loader, Math as PhaserMath } from 'phaser'
import { Cloud } from './Cloud'
import { Lazer } from './Lazer'
import { HelpfulThoughtsProfileState as ProfileState } from 'shared/session/types'
import { getInitialProfileState } from 'shared/session/sections/custom/helpful-thoughts/reducer'
import { ActionTypes } from 'shared/session/sections/custom/helpful-thoughts/actionTypes'

export default class HelpfulThoughtsScene extends Scene {
  private initialised: boolean = false
  private lazerHideTimeout: number = 0
  public thoughtClouds: Cloud[] = []
  public lazer: Lazer = ({} as Lazer) as any
  public state: ProfileState = getInitialProfileState([])
  public dispatch: (action: Partial<ActionTypes>) => void = () => {}
  public returnToScanning: () => void = () => {}

  get stageWidth(): number {
    return this.game.config.width as number
  }

  get stageHeight(): number {
    return this.game.config.height as number
  }

  startGame() {
    this.initialised = true
  }

  initialise() {
    this.loadImages()
  }

  updateState(state: ProfileState, dispatch: (action: Partial<ActionTypes>) => void) {
    this.dispatch = dispatch

    this.thoughtClouds.forEach(cloud => {
      const cloudState = state.clouds.find(({ index }) => index === cloud.index)
      if (cloudState) cloud.updateState(cloudState)
    })

    if (state.hud.zapCounter !== this.state.hud.zapCounter) {
      this.tweens.add({
        targets: this.lazer.lazer,
        alpha: 1,
        ease: PhaserMath.Easing.Quadratic.Out,
        duration: 300,
        yoyo: true,
        repeat: 0,
      })
      if (this.lazerHideTimeout > 0) clearTimeout(this.lazerHideTimeout)
      this.lazerHideTimeout = setTimeout(() => {
        this.lazer.lazer.alpha = 0
      }, 1500)
    }

    this.state = state
  }

  onLoaded = () => {
    this.create()
  }

  loadImages() {
    this.load.image('cloud', require('./assets/cloud-white.png'))
    this.load.image('cloudRed', require('./assets/cloud-red.png'))
    this.load.image('cloudGreen', require('./assets/cloud-green.png'))
    this.load.image('cloudBlue', require('./assets/cloud-blue.png'))
    this.load.image('lazer', require('./assets/LazerIsolated.png'))
    this.load.image('shard1', require('./assets/Shard1.svg')?.default)
    this.load.image('shard2', require('./assets/Shard2.svg')?.default)
    this.load.image('shard3', require('./assets/Shard3.svg')?.default)
    this.load.addListener(Loader.Events.COMPLETE, this.onLoaded)
    this.load.start()
  }

  create() {
    this.lazer = new Lazer(this, this.stageWidth / 2, this.stageHeight + 90)
    this.state.clouds.forEach((thought, index) => {
      const center = this.stageWidth / 2
      const offset = index * 300
      let xPos: number

      if (index % 2 === 0) xPos = center + offset
      else xPos = center - offset

      this.thoughtClouds.push(
        new Cloud(this, xPos, this.stageHeight / 2, 0.6, 0.5, index, this.state.clouds[index], false)
      )
    })
  }

  update(delta: number) {
    if (this.lazer.lazer.alpha > 0) this.lazer.lazer.scaleX = PhaserMath.FloatBetween(0.8, 1.2)

    this.thoughtClouds.forEach((cloud, index) => {
      cloud.update(delta)
    })
  }
}
