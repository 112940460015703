/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode } from 'react'
import { JSONValueType } from 'shared/session/types'
import { useInputInterface, InputInterfaceOptions } from 'session/InputContext'

interface InputProps<ValueType> {
  value: ValueType | undefined
  onChange: (value: ValueType) => void
}
interface Props<ValueType extends any> extends InputInterfaceOptions<ValueType> {
  children: (props: InputProps<ValueType>) => ReactNode | JSX.Element
}

export const InputComponent = <ValueType extends any>({
  children,
  defaultValue,
  name = 'input',
  contextOverride,
  socketDebounce,
  databaseDebounce,
}: Props<ValueType>) => {
  const [value, onChange] = useInputInterface<ValueType>({
    name,
    contextOverride,
    defaultValue,
    socketDebounce,
    databaseDebounce,
  })
  if (typeof children !== 'function') return <></>
  return <>{children({ value, onChange })}</>
}
