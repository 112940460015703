import React, { useState, useRef, Fragment, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'
import { blue, THEMES } from 'themes'

import { SessionEntityHydrated } from 'shared/dashboard/types'
import { SessionModule, SavedInputValue, ParticipantState } from 'shared/session/types'
import { SessionStateProvider, useSessionState } from 'session/SessionState'
import { UserInputStateProvider, useUserInputState } from 'session/UserInputState'
import { ProgressionEventsStateProvider } from 'session/ProgressionEventsState'
import { InputContextProvider } from 'session/InputContext'
import { SET_SAVED_USER_INPUT_VALUES, UPDATE_STATE } from 'shared/session/actionTypes'

import { ModalContextProvider } from 'session/ModalContext'
import { Panel, Column, H2, P, AppBackground } from 'common/ui'
import { Spinner } from 'common/Spinner'
import { ParticipantPlaybackUi } from './ParticipantPlaybackUi'
import { PastSessionSlideHandler } from './PastSessionSlide'
import { useUserState, UserStateContext } from 'app/UserState'

import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { useGenericUser } from 'app/useGenericUser'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { GetProfile } from 'api'
import { processErrorList } from 'dashboards/utils/reducers'
import { useGlobalState } from 'app/GlobalState'

export const CadetPastMeeting: React.FC = () => {
  useDisableOrientationLock()
  const userState = useUserState()
  const { mentorUid } = useParams<{ sessionUid: string; mentorUid?: string }>()
  const [cadetProfile, { loading: loadingCadetProfile }] = useEndpoint<GetProfile>(
    mentorUid ? `/api/v1/mentors/${mentorUid}/cadet` : ''
  )

  if (!mentorUid) {
    return (
      <ProgressionEventsStateProvider>
        <SessionStateProvider>
          <UserInputStateProvider>
            <InputContextProvider>
              <ModalContextProvider>
                <ThemeProvider theme={blue}>
                  <CadetPastMeetingInner />
                </ThemeProvider>
              </ModalContextProvider>
            </InputContextProvider>
          </UserInputStateProvider>
        </SessionStateProvider>
      </ProgressionEventsStateProvider>
    )
  }

  return (
    <>
      {loadingCadetProfile ? (
        ''
      ) : cadetProfile ? (
        <UserStateContext.Provider
          value={{
            ...userState,
            authProvider: 'sas',
            profileId: cadetProfile.id,
            profiles: [cadetProfile],
            profileName: cadetProfile.profile_name || '',
          }}>
          <ProgressionEventsStateProvider>
            <SessionStateProvider>
              <UserInputStateProvider>
                <InputContextProvider>
                  <ModalContextProvider>
                    <ThemeProvider theme={blue}>
                      <CadetPastMeetingInner />
                    </ThemeProvider>
                  </ModalContextProvider>
                </InputContextProvider>
              </UserInputStateProvider>
            </SessionStateProvider>
          </ProgressionEventsStateProvider>
        </UserStateContext.Provider>
      ) : (
        <AppBackground theme="blue">
          <Column flex="auto" alignItems="center" justifyContent="center">
            <Panel padding="l">
              <H2 marginTop={30} marginBottom={0}>
                Sorry we couldn't find your meeting information
              </H2>
            </Panel>
          </Column>
        </AppBackground>
      )}
    </>
  )
}

const CadetPastMeetingInner: React.FC = () => {
  const { sessionUid, mentorUid } = useParams<{ sessionUid: string; mentorUid?: string }>()
  const { hash } = useLocation()
  const user = useGenericUser()
  const { dispatch: dispatchSessionState, getBaseAction } = useSessionState()
  const { dispatch: dispatchInputState } = useUserInputState()
  const {
    setSessionData,
    setSessionProfile,
    setSessionUserType,
    setPastMode,
    state,
    sessionProfile,
  } = useSessionState()
  const { themeIndex } = useGlobalState()
  const [finishedDelay, setFinishedDelay] = useState<boolean>(false)
  const wasLoading = useRef<boolean>(true)
  const [
    cadetProfile,
    {
      loaded: loadedCadetProfile,
      loading: loadingCadetProfile,
      errorLoading: errorLoadingCadetProfile,
      errorMessage: errorMessageCadetProfile,
    },
  ] = useEndpoint<GetProfile>(mentorUid ? `/api/v1/mentors/${mentorUid}/cadet` : null)
  const [
    session,
    {
      loaded: loadedSessionData,
      loading: loadingSessionData,
      errorLoading: errorLoadingSessionData,
      errorMessage: errorMessageSessionData,
    },
  ] = useEndpoint<SessionEntityHydrated & { module: SessionModule }>(`/api/v1/sessions/${sessionUid}`)
  const sessionInputValueUrl = `/api/v1/session_input_values?session_uid=${sessionUid}`
  const [
    inputValues,
    {
      loaded: loadedInputValues,
      loading: loadingInputValues,
      errorLoading: errorLoadingInputValues,
      errorMessage: errorMessageInputValues,
    },
  ] = useEndpoint<SavedInputValue<any>[]>(
    user.roles.indexOf('mentor') >= 0
      ? cadetProfile
        ? `${sessionInputValueUrl}&participant_uid=sas-${cadetProfile.id}`
        : null
      : sessionInputValueUrl
  )

  const invalidSessionType = !!session && session.type !== 'cadet'
  const loading =
    !loadedSessionData || !loadedInputValues || !(!mentorUid ? true : loadedCadetProfile) || invalidSessionType

  const errorMessages: string[] = processErrorList([
    errorMessageSessionData,
    errorMessageInputValues,
    errorMessageCadetProfile,
    invalidSessionType ? `You're attempting to join a ${session?.type} meeting while accessing as a cadet.` : null,
  ])

  useEffect(() => {
    if (
      !loading &&
      wasLoading.current === true /* everything after this should be inferred... */ &&
      (mentorUid ? cadetProfile !== null : true) &&
      inputValues !== null &&
      session !== null
    ) {
      wasLoading.current = false

      const startingSlide = hash ? hash.replace('#', '') : null
      window.history.replaceState({}, document.title, window.location.pathname)

      const newParticipantState: ParticipantState = {
        profile: { ...user, userType: 'agent' },
        status: 'connected',
        locked: true, // important
        tokens: 0,
        inputValues: [],
        currentSlideUid: startingSlide,
        gadgetTrayOpen: false,
        trayType: null,
      }
      // Update static state data
      ReactDOM.unstable_batchedUpdates(() => {
        if (session) setSessionData(session)
        setPastMode(true)
        setSessionUserType(user.roles.indexOf('mentor') >= 0 ? 'mentor' : 'agent')
        setSessionProfile({ ...user, userType: user.roles.indexOf('mentor') >= 0 ? 'mentor' : 'agent' })

        // Initialize session state!
        dispatchSessionState({
          ...getBaseAction(),
          type: UPDATE_STATE,
          state: { ...state, sessionUid: session.uid, participants: [newParticipantState] },
        })

        dispatchInputState({
          user_uid: user.uid,
          role: user.userType,
          timestamp: Date.now(),
          type: SET_SAVED_USER_INPUT_VALUES,
          inputValues,
        })

        console.log('\n✅ All data received, now processing....\n\n')
      })

      setTimeout(() => {
        console.log('\n✅ Launching session....\n\n')
        setFinishedDelay(true)
      }, 1000)
    }
  })

  if (loading || !finishedDelay)
    return (
      <AppBackground theme={sessionProfile.userType !== 'mentor' ? THEMES[themeIndex] : blue}>
        <Column flex="auto" alignItems="center" justifyContent="center">
          <Panel
            padding="l"
            style={{
              transition: 'opacity 1s linear',
              opacity: loading ? 1 : 0,
              minWidth: 300,
              boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.2)',
            }}>
            <H2 marginBottom="m">Loading meeting data...</H2>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 30, height: 30 }}>
                    {loadingSessionData && <Spinner thickness={0.2} />}
                    {loadedSessionData && (
                      <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />
                    )}
                    {errorLoadingSessionData && (
                      <img src={require('reporting/assets/images/dash-circle.svg')?.default} alt="" />
                    )}
                  </td>
                  <td>Meeting content</td>
                </tr>
                <tr>
                  <td style={{ width: 30, height: 30 }}>
                    {loadingInputValues && <Spinner thickness={0.2} />}
                    {loadedInputValues && (
                      <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />
                    )}
                    {errorLoadingInputValues && (
                      <img src={require('reporting/assets/images/dash-circle.svg')?.default} alt="" />
                    )}
                  </td>
                  <td>Input and drawings</td>
                </tr>
                {mentorUid && (
                  <tr>
                    <td style={{ width: 30, height: 30 }}>
                      {loadingCadetProfile && <Spinner thickness={0.2} />}
                      {loadedCadetProfile && (
                        <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />
                      )}
                      {errorLoadingCadetProfile && (
                        <img src={require('reporting/assets/images/dash-circle.svg')?.default} alt="" />
                      )}
                    </td>
                    <td>Cadet profile</td>
                  </tr>
                )}
              </tbody>
            </table>
            {errorMessages.length > 0 && (
              <>
                <H2 marginTop={30} marginBottom={0}>
                  Error
                </H2>
                <P>
                  {errorMessages.map((str, i) => (
                    <Fragment key={i}>
                      {str}
                      <br />
                    </Fragment>
                  ))}
                </P>
              </>
            )}
          </Panel>
        </Column>
      </AppBackground>
    )

  return (
    <ParticipantPlaybackUi>
      <PastSessionSlideHandler />
    </ParticipantPlaybackUi>
  )
}
