import React from 'react'
import styled, { ThemeProvider, css } from 'styled-components'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { blue } from 'themes'

import { GadgetPackStateProvider } from './GadgetPackState'
import {
  ProgressionEventsStateProvider,
  ProgressionEventContext,
  useProgressionEventExists,
} from 'session/ProgressionEventsState'
import { AppBackground, Column, Spacer, Button, Panel, RowWrapPortrait, Row, Padding } from 'common/ui'
import { HeaderTemplate } from './common/HeaderTemplate'

import { IdScreen, IdPrint } from './ID'
import { CardHolderScreen } from './CardHolder'
import { FriendshipFormulaScreen } from './FriendshipFormula'
import { StressBallScreen } from './StressBall'
import { FactFilesScreen, FactFileScreen, FactFilePrint } from './FactFiles'
import { FriendometerScreen } from './Friendometer'
import { BionicPowersScreen, BionicPowersPrint } from './BionicPowers'
import { MedalScreen } from './Medal'

import { RelaxationGadgetsIndex } from './card-holder/RelaxationGadgetsIndex'
import { RelaxationGadgetView } from './card-holder/RelaxationGadgetView'
import { RelaxationGadgetEdit } from './card-holder/RelaxationGadgetEdit'
import { CodeCardsIndex } from './card-holder/CodeCardsIndex'
import { CodeCardView } from './card-holder/CodeCardView'
import { CodeCardEdit } from './card-holder/CodeCardEdit'
import { EmotionometerView } from './card-holder/EmotionometerView'
import { EmotionometerStickerEdit } from './card-holder/EmotionometerStickerEdit'

import { isDevEnv } from 'utils/envUtils'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { GADGET_PACK_UNLOCK } from 'shared/progressionEvents'
import { GameModeSlug } from 'shared/types'
import { useUserState } from 'app/UserState'
import { PostQuiz2, Graduation } from 'activities'

export const baseUrl = '/gadget-pack'

export const GadgetPack: React.FC = () => {
  useDisableOrientationLock()
  const location = useLocation()
  const isPrint = !!location.pathname.match(/\/print\/?$/i)
  return (
    <ProgressionEventsStateProvider>
      <GadgetPackStateProvider>
        <ThemeProvider theme={blue}>
          {isPrint ? (
            <div style={{ flex: 'auto', display: 'flex' }}>
              <Column flex style={{ maxWidth: '100vw' }}>
                <Routes />
              </Column>
            </div>
          ) : (
            <AppBackground onContextMenu={isDevEnv() ? undefined : e => e.preventDefault()}>
              <Column flex style={{ maxWidth: '100vw' }}>
                <Routes />
              </Column>
            </AppBackground>
          )}
        </ThemeProvider>
      </GadgetPackStateProvider>
    </ProgressionEventsStateProvider>
  )
}

const Routes: React.FC = () => {
  const { gameMode } = useUserState()
  const idCardFlipUnlocked = useProgressionEventExists({
    type: GADGET_PACK_UNLOCK,
    category: 'id-card',
    subcategory: 'enable-flip',
    event_key: null,
    event_value: null,
  })

  return (
    <Switch>
      <Route path={baseUrl} exact>
        <GadgetPackHome />
      </Route>
      <Route path={baseUrl + '/id'} exact>
        <IdScreen flippable={gameMode === 'SAS-SG-D' ? idCardFlipUnlocked : true} />
      </Route>
      <Route path={baseUrl + '/id/print'} exact>
        <IdPrint />
      </Route>
      <Route path={baseUrl + '/id/flipped/print'} exact>
        <IdPrint flipped />
      </Route>
      <Route path={baseUrl + '/card-holder'} exact>
        <CardHolderScreen />
      </Route>
      <Route path={baseUrl + '/card-holder/home'} exact>
        <CardHolderScreen />
      </Route>
      <Route path={baseUrl + '/stress-ball'} exact>
        <StressBallScreen />
      </Route>
      <Route path={baseUrl + '/friendship-formula'} exact>
        <FriendshipFormulaScreen />
      </Route>
      <Route path={baseUrl + '/fact-files'} exact>
        <FactFilesScreen />
      </Route>
      <Route path={baseUrl + '/fact-files/:id'} exact>
        <FactFileScreen hideDownloadButton />
      </Route>
      <Route path={baseUrl + '/fact-files/:id/print'} exact>
        <FactFilePrint />
      </Route>
      <Route path={baseUrl + '/friendometer'} exact>
        <FriendometerScreen baseUrl={baseUrl} />
      </Route>
      <Route path={baseUrl + '/card-holder/friendometer'} exact>
        <FriendometerScreen inCardHolder baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/bionic-powers'} exact>
        <BionicPowersScreen hideDownload baseUrl={baseUrl} bgImage={require('session/assets/bionic-powers-bg.png')} />
      </Route>
      <Route path={baseUrl + '/bionic-powers/print'} exact>
        <BionicPowersPrint />
      </Route>
      <Route path={baseUrl + '/card-holder/bionic-powers'} exact>
        <BionicPowersScreen
          hideDownload
          inCardHolder
          baseUrl={baseUrl + '/card-holder'}
          bgImage={require('session/assets/bionic-powers-bg.png')}
        />
      </Route>
      <Route path={baseUrl + '/medal'} exact>
        <MedalScreen />
      </Route>

      {/* E-Telligence Routes */}

      <Route path={baseUrl + '/card-holder/relaxation-gadgets'} exact>
        <RelaxationGadgetsIndex baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/relaxation-gadgets/new'} exact>
        <RelaxationGadgetEdit isNew baseUrl={baseUrl + '/card-holder'} />
      </Route>

      <Route path={baseUrl + '/card-holder/relaxation-gadgets/edit/:id'} exact>
        <RelaxationGadgetEdit baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/relaxation-gadgets/:id'} exact>
        <RelaxationGadgetView baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/relaxation-gadgets/add/:id'} exact>
        <RelaxationGadgetView isAdding baseUrl={baseUrl + '/card-holder'} />
      </Route>

      <Route path={baseUrl + '/card-holder/code-cards'} exact>
        <CodeCardsIndex baseUrl={baseUrl + '/card-holder'} activities={[] /*activities*/} />
      </Route>
      <Route path={baseUrl + '/card-holder/code-cards/add/:id'} exact>
        <CodeCardView hideActionButton={gameMode === 'SAS-SG-D'} isAdding baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/code-cards/new'} exact>
        <CodeCardEdit isNew baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/code-cards/edit/:id'} exact>
        <CodeCardEdit baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/code-cards/:id'} exact>
        <CodeCardView baseUrl={baseUrl + '/card-holder'} />
      </Route>

      <Route path={baseUrl + '/card-holder/emotionometer/:emotion'} exact>
        <EmotionometerView baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/emotionometer/:emotion/edit'} exact>
        <EmotionometerView editMode baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/emotionometer/:emotion/:device'} exact>
        <EmotionometerStickerEdit baseUrl={baseUrl + '/card-holder'} />
      </Route>
      <Route path={baseUrl + '/card-holder/emotionometer/:emotion/:device/:emotionRange'} exact>
        <EmotionometerStickerEdit baseUrl={baseUrl + '/card-holder'} />
      </Route>
    </Switch>
  )
}

interface GadgetTileRoute {
  title: string
  path: string
  routeState?: any
  image: any
  maxHeight?: number
  hide?: boolean | ((gameMode: GameModeSlug, isOpenAccess: boolean) => boolean)
  eventContext: Omit<ProgressionEventContext<any>, 'type'>
}

const standardShouldHide = (gameMode: GameModeSlug, isOpenAccess: boolean): boolean => {
  if (gameMode === 'SAS-SG') return true
  return false
}

export const gadgetTiles: GadgetTileRoute[] = [
  {
    title: 'ID',
    path: '/id',
    image: require('./assets/gadgets_id.png'),
    hide: standardShouldHide,
    eventContext: {
      category: 'id-card',
      subcategory: null,
      event_key: null,
      event_value: null,
    },
  },
  {
    title: 'Bionic Powers',
    path: '/bionic-powers',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_bionicPowers.png'),
    hide: standardShouldHide,
    eventContext: {
      category: 'bionic-powers',
      subcategory: null,
      event_key: null,
      event_value: null,
    },
  },
  {
    title: 'Anger Emotionometer',
    path: '/card-holder/emotionometer/anger',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_emotionometerAnger.png'),
    hide: standardShouldHide,
    eventContext: {
      category: 'emotionometer',
      subcategory: 'anger',
      event_key: null,
      event_value: null,
    },
  },
  {
    title: 'Anxiety Emotionometer',
    path: '/card-holder/emotionometer/anxiety',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_emotionometerAnxiety.png'),
    hide: standardShouldHide,
    eventContext: {
      category: 'emotionometer',
      subcategory: 'anxiety',
      event_key: null,
      event_value: null,
    },
  },
  {
    title: 'Relaxation Gadgets',
    path: '/card-holder/relaxation-gadgets',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_relaxationGadgets.png'),
    hide: standardShouldHide,
    eventContext: {
      category: 'relaxation-gadgets',
      event_key: null,
      event_value: null,
    } as GadgetTileRoute['eventContext'],
  },
  {
    title: 'Stress Ball',
    path: '/stress-ball',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_stressBall.png'),
    hide: false,
    eventContext: {
      category: 'stress-ball',
      subcategory: null,
      event_key: null,
      event_value: null,
    },
  },
  {
    title: 'Friendship Formula',
    path: '/friendship-formula',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_friendshipFormula.png'),
    hide: standardShouldHide,
    eventContext: {
      category: 'friendship-formula',
      subcategory: null,
      event_key: null,
      event_value: null,
    },
  },
  {
    title: 'Friendometer',
    path: '/friendometer',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_friendometer.png'),
    hide: standardShouldHide,
    maxHeight: 150,
    eventContext: {
      category: 'friendometer',
      subcategory: null,
      event_key: null,
      event_value: null,
    },
  },
  {
    title: 'Code Cards',
    path: '/card-holder/code-cards',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_codeCards.png'),
    hide: standardShouldHide,
    eventContext: {
      category: 'code-cards',
    } as GadgetTileRoute['eventContext'],
  },
  {
    title: 'Fact Files',
    path: '/fact-files',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_factFiles.png'),
    hide: standardShouldHide,
    eventContext: {
      category: 'fact-files',
      subcategory: null,
      event_key: null,
      event_value: null,
    },
  },
  {
    title: 'Medal',
    path: '/medal',
    routeState: { skipCardHolder: true },
    image: require('./assets/gadgets_medal.png'),
    hide: false,
    eventContext: {
      category: 'medal',
      subcategory: null,
      event_key: null,
      event_value: null,
    },
  },
]

export const GadgetTile: React.FC<{
  gadgetTile: GadgetTileRoute
  baseUrl: string
  disabled?: boolean
  onClick?: (gadget: GadgetTileRoute) => void
}> = ({ gadgetTile, baseUrl, disabled, onClick }) => {
  const { gameMode } = useUserState()
  const { title, image, maxHeight, path, routeState, eventContext } = gadgetTile
  const history = useHistory()
  const unlocked = useProgressionEventExists({ type: GADGET_PACK_UNLOCK, ...eventContext })
  const gadgetDisabled = disabled || (gameMode === 'SAS-SG-D' && !unlocked)
  return (
    <GadgetTileWrapper columns={3} disabled={gadgetDisabled}>
      <Panel style={{ height: '100%' }}>
        <Padding size="m">
          <Column justifyContent="center" flex="1 1 auto">
            <Row justifyContent="center">
              <div>
                <img src={image} alt="" style={{ maxHeight: maxHeight || 175 }} />
              </div>
            </Row>
          </Column>
          <Spacer size="m" />
          <Button
            size="s"
            disabled={gadgetDisabled}
            onClick={onClick ? () => onClick(gadgetTile) : () => history.push(baseUrl + path, routeState)}
            children={title}
          />
        </Padding>
      </Panel>
    </GadgetTileWrapper>
  )
}

const GadgetPackHome: React.FC = () => {
  const history = useHistory()
  const { gameMode, hasCompleted, openAccess } = useUserState()
  const medalUnlocked = hasCompleted(Graduation) || openAccess
  const stressBallUnlocked = hasCompleted(PostQuiz2) || openAccess

  const shouldBeDisabled = (gadgetSlug: string): boolean => {
    if (gameMode === 'SAS-SG-D') return false
    if (gadgetSlug === 'medal' && !medalUnlocked) return true
    if (gadgetSlug === 'stress-ball' && !stressBallUnlocked) return true
    return false
  }

  return (
    <HeaderTemplate
      title="Gadgets"
      onExit={() => history.push('/game')}
      Buttons={
        gameMode !== 'SAS-SA' ? (
          undefined
        ) : (
          <Button onClick={() => history.push(baseUrl + '/card-holder')}>
            <Row alignItems="center">
              <img
                src={require('./assets/gadgets_cardHolder.png')}
                alt=""
                style={{ maxHeight: 30, margin: '-15px 0' }}
              />
              <Spacer size="s" />
              <span>Card Holder</span>
            </Row>
          </Button>
        )
      }>
      <RowWrapPortrait flexWrap style={{ width: '100%' }}>
        {gadgetTiles
          .filter(({ hide }) => !hide || (typeof hide === 'function' && !hide(gameMode, openAccess)))
          .map((gadgetTile, i) => (
            <GadgetTile
              key={gadgetTile.path}
              gadgetTile={gadgetTile}
              baseUrl={baseUrl}
              disabled={shouldBeDisabled(gadgetTile.eventContext.category)}
            />
          ))}
      </RowWrapPortrait>
    </HeaderTemplate>
  )
}

const GadgetTileWrapper = styled(Column)<{ disabled?: boolean; columns?: number }>`
  flex: 1 1 ${p => 100 / (p.columns || 3) + '%'};
  max-width: ${p => 100 / (p.columns || 3) + '%'};
  margin-bottom: 15px;
  min-height: ${p => ((p.columns || 3) > 2 ? '230px' : '320px')}; // ie11 ?

  & ${Panel} {
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    flex-basis: 100%;
    & > ${Padding} {
      width: calc(100% - 20px);
    }
    ${p =>
      p.disabled
        ? css`
      opacity: 0.5;
      background-image: url('${require('common/assets/image/lock-no-shadow@2x.png')}');
      background-position: 15px 15px;
      background-size: 25px auto;
      background-repeat: no-repeat;
    `
        : ''}
    & ${Button} {
      width: 100%;
    }
    & img {
      max-width: 100%;
    }
    & ${Column} {
      max-width: 100%; // ie11
    }
  }

  @media (max-width: 640px) {
    flex: 1 1 50%;
    max-width: 50%;
  }
  @media (orientation: portrait) and (max-width: 420px) {
    flex: 1 1 100%;
    max-width: 100%;
    & ${Panel} {
      margin-left: 0;
      margin-right: 0;
    }
  }
`
