import * as Sentry from '@sentry/browser'
import { Howler, Howl, HowlOptions } from 'howler'
import { isIOS } from 'utils/deviceUtils'

export type SoundOptions = HowlOptions

export const getAudioContext = () => Howler.ctx
export const getMasterGainNode = () => Howler.masterGain

let _howlerInited: boolean = false
export const howlerInited = () => _howlerInited

export const initHowler = () => {
  Howler.autoUnlock = true
  Howler.autoSuspend = false
  Howler.html5PoolSize = isIOS() ? 9 : 20
  if (Howler.ctx) console.log('Howler.js sample rate set to ' + Howler.ctx.sampleRate)
  if (!Howler.codecs('mp3')) {
    Sentry.captureMessage('Browser does not support mp3 codec!', Sentry.Severity.Error)
  }
  _howlerInited = true
}

export default class Sound extends Howl {
  currentTime() {
    if (!this.duration()) {
      return null
    }
    return this.seek() as number
  }

  toggle() {
    if (this.playing()) {
      this.pause()
    } else {
      this.play()
    }
  }

  fadeTo(volume: number, duration: number = 400) {
    return this.fade(this.volume(), volume, duration)
  }

  fadeOut(duration: number = 400) {
    return this.fadeTo(0, duration)
  }

  fadeIn(volume: number = 1, duration: number = 400) {
    return this.fadeTo(volume, duration)
  }

  fadeOutAndUnload(duration: number = 400) {
    this.fadeOut(duration)
    setTimeout(() => this.unload(), duration + 200)
  }

  fadeOutAndStop(duration: number = 400) {
    const preFadeVolume = this.volume()
    this.fadeOut(duration)
    setTimeout(() => {
      this.stop()
      this.volume(preFadeVolume)
    }, duration + 200)
  }

  get src() {
    return (this as any)._src as string
  }
}
