import React, { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

import { fontBold } from 'fonts'

type Size = 'm' | 's' | 'xs'

interface Props {
  id?: string
  name?: string
  highlight?: boolean
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  size?: Size
  autoFocus?: boolean
  wrapperProps?: ComponentProps<typeof CheckboxContainer>
  indicatorProps?: ComponentProps<typeof IndicatorStateful>
  labelProps?: ComponentProps<typeof CheckboxLabel>
}

export const CheckboxContainer = styled.label<{ disabled?: boolean; size: Size; noLabel?: boolean }>`
  display: ${p => (p.noLabel ? 'inline-block' : 'flex')};
  flex-direction: row;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  opacity: ${p => (p.disabled ? 0.5 : 1)};
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
`

const DecoyInput = styled.input`
  opacity: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
`

const indicatorSizes = { m: '35px', s: '25px', xs: '15px' }
const indicatorPadding = { m: '3px', s: '2px', xs: '1px' }
const indicatorMargins = { m: '0 20px 0 5px', s: '0 15px 0 5px', xs: '0 10px 0 0' }

export const Indicator = styled.span<{ checked?: boolean; size: Size }>`
  width: ${p => indicatorSizes[p.size]};
  height: ${p => indicatorSizes[p.size]};
  background: white;
  border: 1px solid #abb5d8;
  margin: ${p => indicatorMargins[p.size]};

  ${p =>
    !p.checked
      ? ''
      : `background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBvbHlsaW5lIHBvaW50cz0iNCA4LjQzIDYuNjcgMTEgMTIgNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=') no-repeat center / contain;`}
`

export const IndicatorStateful = styled(Indicator)<{ noLabel?: boolean }>`
  flex: none;

  ${p =>
    p.noLabel
      ? css`
          margin: 0;
          display: inline-block;
        `
      : ''}

  ${DecoyInput}:focus + & {
    box-shadow: 0px 2px 5px 0px #adb2da;
  }

  ${DecoyInput}:active:enabled + & {
    transform: scale(0.9);
  }

  ${DecoyInput}:checked + & {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBvbHlsaW5lIHBvaW50cz0iNCA4LjQzIDYuNjcgMTEgMTIgNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
        no-repeat center / contain,
      ${p => `linear-gradient(180deg, ${p.theme.appBackgroundTopColor} 0%, ${p.theme.appBackgroundBottomColor} 100%)`};
    box-shadow: inset 0px 0px 0px ${p => indicatorPadding[p.size]} white;

    @media print {
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      -ms-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

  ${DecoyInput}:checked:focus + & {
    box-shadow: inset 0px 0px 0px ${p => indicatorPadding[p.size]} white, 0px 2px 5px 0px #adb2da;
  }
`

const labelFontSizes = { m: '20px', s: '16px', xs: '14px' }

export const CheckboxLabel = styled.span<{ highlight?: boolean; size: Size }>`
  ${fontBold}
  font-size: ${p => labelFontSizes[p.size]};
  text-transform: none;
  color: ${p => (p.highlight ? '#7484d3' : '#001947')};
  margin-bottom: 2px; /* shift up a bit */
  user-select: none;
`

export const Checkbox: React.FC<Props> = ({
  id,
  disabled,
  name,
  checked,
  onChange,
  highlight,
  size = 'm',
  autoFocus,
  wrapperProps = {},
  indicatorProps = {},
  labelProps = {},
  children,
}) => {
  const noLabel = !children
  return (
    <CheckboxContainer disabled={disabled} size={size} noLabel={noLabel} {...wrapperProps}>
      <DecoyInput
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      <IndicatorStateful checked={checked} size={size} noLabel={noLabel} {...indicatorProps} />
      {children && (
        <CheckboxLabel highlight={highlight} size={size} {...labelProps}>
          {children}
        </CheckboxLabel>
      )}
    </CheckboxContainer>
  )
}
