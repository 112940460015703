export function arrayPad<T, S>(
  length: number,
  func: (i: number) => S
): (acc: (T | S)[], el: T, i: number, arr: T[]) => (T | S)[] {
  return (acc, el, i, arr) => {
    acc.push(el)
    if (i + 1 === arr.length) {
      for (let j = i + 1; j < length; j++) {
        acc.push(func(j))
      }
    }
    return acc
  }
}
