import React from 'react'
import styled from 'styled-components'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { Column, Row, P, Spacer, Button, H2, Padding, Panel } from 'common/ui'

import { useETelligenceState } from './ETelligenceState'

import { baseUrl } from './common/Navigation'

import { useUserState } from 'app/UserState'
import { RouteProps } from 'shared/e-telligence/types'
import {
  ADD_CODE_CARD,
  REMOVE_CODE_CARD,
  AddCodeCardAction,
  RemoveCodeCardAction,
} from 'shared/e-telligence/actionTypes'
import { codeCards } from './constants/codeCards'
import { fontHeavy } from 'fonts'
import { luminanceHex } from 'utils/colorUtils'
import { ETellPanel } from './common/ETellPanel'
import { useMedia } from 'utils/useMedia'
import { findCodeCardById } from './stateUtils'

interface Props {
  isAdding?: boolean
  hideBackButton?: boolean
  cardId?: string
  hideActionButton?: boolean
}

export const CodeCardView: React.FC<Props> = ({ isAdding }) => {
  const { state, dispatch } = useETelligenceState()
  const { usingIOSvhFix } = useUserState()
  return (
    <GadgetPanel style={{ height: usingIOSvhFix ? '100%' : 'calc(100vh - 20px)' }}>
      <CodeCardViewInner baseUrl={baseUrl} isAdding={isAdding} state={state} dispatch={dispatch} />
    </GadgetPanel>
  )
}

export const CodeCardViewInner: React.FC<RouteProps & Props> = ({
  baseUrl,
  state,
  dispatch,
  isAdding,
  cardId,
  hideBackButton,
  hideActionButton,
}) => {
  const history = useHistory()
  const location = useLocation()
  let { id } = useParams<{ id: string }>()
  id = cardId ? cardId : id

  const { getBaseAction } = useUserState()
  const portrait = useMedia('(orientation: portrait)')

  const stateCodeCard = findCodeCardById(id, state)
  const codeCardIndex = stateCodeCard ? state.codeCardIds.indexOf(stateCodeCard.id) : -1
  const presetCodeCard = codeCards.find(test => test.id === id)
  const customCodeCard = state.customCodeCards.find(test => test.id === id)
  const codeCard = stateCodeCard || presetCodeCard || customCodeCard
  if (!codeCard) return null

  const handleAdd = () => {
    const codeCardIndex = state.codeCardIds.length
    dispatch({ ...getBaseAction(), type: ADD_CODE_CARD, codeCardId: codeCard.id } as AddCodeCardAction)
    setTimeout(() =>
      history.push(`${baseUrl}/home#${codeCard.id}`, {
        ...(location.state || {}),
        showCodeCard: codeCard.id,
        codeCardIndex,
      })
    )
  }

  const handleRemove = () => {
    dispatch({
      ...getBaseAction(),
      type: REMOVE_CODE_CARD,
      codeCardId: codeCard.id,
    } as RemoveCodeCardAction)
    setTimeout(() =>
      history.push(baseUrl + '/home', { ...(location.state || {}), hideCodeCard: codeCard.id, codeCardIndex })
    )
  }

  return (
    <>
      <ScrollColumn flex="1 1 auto">
        <Row>
          <HeaderRow flex="1 1 auto" padding="s" alignItems="center" style={{ maxHeight: 60 }} color={codeCard.color}>
            <Row flex="1 1 auto" paddingLeft={10} paddingRight={10} alignItems="center">
              <img src={require('e-telligence/assets/code-cards/SAS-Logo.png')} alt="" />
              <H2>{codeCard.title.toUpperCase()}</H2>
            </Row>
          </HeaderRow>
          {!hideBackButton && (
            <Column flex="none" alignItems="flex-start" marginLeft={portrait ? 20 : 60}>
              <Padding size="xs">
                <Button
                  onClick={() => history.push(baseUrl + (isAdding ? '/code-cards' : '/home'), location.state)}
                  size="s">
                  Back
                </Button>
              </Padding>
            </Column>
          )}
        </Row>
        <Spacer />
        {codeCard.steps.map((step, i) => {
          return (
            <Column key={i} paddingLeft paddingRight marginBottom>
              <Spacer />
              <Row flex style={{ flexDirection: step.layout === 'full' ? 'column' : undefined }}>
                {step.imageUrl && (
                  <>
                    <StepImg src={step.imageUrl} alt="" layout={step.layout} />
                    <Spacer />
                  </>
                )}
                <div>
                  {step.label && (
                    <StepTitle style={{ color: codeCard.labelColor || codeCard.color }}>{step.label}</StepTitle>
                  )}
                  {step.description && <StepDescription>{step.description}</StepDescription>}
                </div>
              </Row>
            </Column>
          )
        })}
      </ScrollColumn>
      <Row justifyContent="center" padding="s">
        {!hideActionButton && (
          <Button onClick={codeCardIndex >= 0 ? handleRemove : handleAdd} size="s">
            {codeCardIndex >= 0 ? 'Remove from Card Holder' : 'Add to Card Holder'}
          </Button>
        )}
        {!hideActionButton && codeCard && !codeCard.isPreset && (
          <>
            <Spacer size="xs" />
            <Button
              onClick={() => history.push(`${baseUrl}/code-cards/edit/${codeCard.id}`, location.state)}
              size={codeCardIndex >= 0 && portrait && !codeCard.isPreset ? 'xs' : 's'}>
              Edit
            </Button>
          </>
        )}
      </Row>
    </>
  )
}

const GadgetPanel = styled(ETellPanel)`
  border-radius: 12px;
  background-color: #2c2c2c !important;
  border-bottom-color: #313131;

  ${Panel} {
    background-color: #2c2c2c;
  }

  & h2,
  & h3 {
    font-size: 1rem;
    text-transform: initial;
    letter-spacing: 0;
  }
`
const ScrollColumn = styled(Column)`
  overflow: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

// http://lea.verou.me/2011/03/beveled-corners-negative-border-radius-with-css3-gradients/
const HeaderRow = styled(Row)<{ color?: string }>`
  background: ${p => p.color || '#22acf3'};
  padding: 15px;

  img {
    height: 22px;
    margin-right: 10px;
  }

  & h2 {
    color: ${p => (luminanceHex(p.color || '#22acf3') > 0.5 ? 'black' : 'white')};
    font-style: italic;
    line-height: 130%;
    letter-spacing: 0.03em;
    font-size: 1.5rem;
  }
`

const StepTitle = styled.h2`
  ${fontHeavy}
  font-style: italic;
  line-height: 130%;
  letter-spacing: 0.03em;
  font-size: 2rem;
  color: white;
  text-transform: uppercase;
  letter-spacing: inherit;
  margin: -10px 0 10px 0;
`

const StepDescription = styled(P)`
  margin: 0;
  color: white;
  font-size: 1.5rem;
  line-height: 123%;
  letter-spacing: 0.03em;
  font-weight: 400;

  strong {
    ${fontHeavy}
    text-transform: none;
    letter-spacing: inherit;
  }
`

const StepImg = styled.img<{ layout?: string }>`
  align-self: flex-start;
  width: ${p => (p.layout === 'full' ? '100' : '45')}%;
  border-radius: 10px;
  max-width: ${p => (p.layout === 'full' ? '100%' : '200px')};
  pointer-events: none;
`
