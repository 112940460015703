import { BaseNavRoute } from 'dashboards/types'

export const flattenRoutes = <NavRouteType extends BaseNavRoute>(
  routes: NavRouteType[],
  path: string = ''
): NavRouteType[] =>
  routes.reduce(
    (arr, route) => [
      ...arr,
      { ...route, path: path + route.path },
      ...(route.subpages ? flattenRoutes<NavRouteType>(route.subpages as NavRouteType[], path + route.path) : []),
    ],
    [] as NavRouteType[]
  )
