import React, { useState } from 'react'
import * as Sentry from '@sentry/browser'
import { Howler } from 'howler'
import Modal from 'app/Modal'
import {
  Panel,
  PanelProps,
  Padding,
  Button,
  P,
  CUT_TL,
  CUT_TR,
  CUT_BL,
  CUT_BR,
  TAB_B_S,
  Row,
  Spacer,
  CollapseMargin,
} from 'common/ui'

import { howlerInited } from './Sound'
import { useInterval } from 'utils/useInterval'
import { getSessionDuration } from './GlobalState'
import { useUserState } from './UserState'

const webAudioCheckInterval = 5000 // ms

interface Props {
  width?: number | string
  shadow?: PanelProps['shadow']
  shadowColor?: PanelProps['shadowColor']
}

export const HowlerCrashPrompt: React.FC<Props> = ({ width = 520, shadow, shadowColor }) => {
  const { profileId } = useUserState()
  const [crashed, setCrashed] = useState<boolean>(false)
  const [dismissed, setDismissed] = useState<boolean>(false)

  useInterval(
    () => {
      if (!howlerInited()) return
      if (!Howler.usingWebAudio) return
      if (!Howler.ctx) return
      const ctxState = Howler.ctx.state
      if (ctxState === 'suspended') {
        Howler.ctx.resume().catch(e => {
          console.info('Profile ID: ' + profileId)
          console.info('Session duration: ' + getSessionDuration())
          console.log('Failed to resume Howler audio ctx from suspended state')
          Sentry.captureMessage('Failed to resume Howler audio ctx from suspended state', Sentry.Severity.Error)
          Sentry.captureException(e)
          setCrashed(true)
        })
        setTimeout(() => {
          const newCtxState = Howler.ctx.state
          if (newCtxState === 'suspended') {
            console.info('Profile ID: ' + profileId)
            console.info('Session duration: ' + getSessionDuration())
            console.log('Howler audio ctx refuses to resume')
            Sentry.captureMessage('Howler audio ctx refuses to resume', Sentry.Severity.Critical)
            setCrashed(true)
          } else if (newCtxState === 'running') {
            console.info('Profile ID: ' + profileId)
            console.info('Session duration: ' + getSessionDuration())
            console.log('Howler audio ctx successfully resumed after being suspended')
            Sentry.captureMessage('Howler audio ctx successfully resumed after being suspended', Sentry.Severity.Info)
            setCrashed(false)
          }
        }, Math.max(2000, webAudioCheckInterval - 500))
      } else if (ctxState === 'running' && crashed) {
        setCrashed(false)
      }
    },
    crashed ? null : webAudioCheckInterval
  )

  if (!crashed || (crashed && dismissed)) return null

  const handleReload = () => {
    Sentry.captureMessage('User opted to refresh the page upon audio context crash', Sentry.Severity.Info)
    window.location.reload()
  }

  const handleContinue = () => {
    Sentry.captureMessage(
      'User opted to continue current activity without sound upon audio context crash',
      Sentry.Severity.Info
    )
    setDismissed(true)
  }

  return (
    <Modal
      isOpen
      onRequestClose={() => {
        // do nothing, ensure user makes a choice
      }}>
      <Panel
        shadow={shadow}
        shadowColor={shadowColor}
        padding="20px 30px 35px 30px"
        flair={[CUT_TL, CUT_TR, CUT_BL, CUT_BR, TAB_B_S]}
        style={{ maxHeight: 'calc(100vh - 50px)', width }}>
        <Padding size="s" style={{ overflow: 'auto' }}>
          <CollapseMargin top={false} bottom={false}>
            <P>Hey Detective!</P>
            <P>
              Your device has stopped playing audio for an unknown reason.
              <br />
              This can be fixed by refreshing this page, however you may also lose some progress.
            </P>
            <P>You’re welcome to complete your current activity without sound.</P>
          </CollapseMargin>
          <Row>
            <Button size="s" onClick={handleReload}>
              Refresh Window
            </Button>
            <Spacer size="s" />
            <Button size="s" onClick={handleContinue}>
              Continue Without Sound
            </Button>
          </Row>
        </Padding>
      </Panel>
    </Modal>
  )
}
