import { saveTrainingUnitValues } from 'api'
import { useUserState } from 'app/UserState'
import React, { useRef, useReducer, useState, useContext, useEffect } from 'react'
import {
  ActionTypes,
  ATTEMPT_QUESTION,
  CREATE_QUESTION,
  INIT_STATE,
  UPDATE_CURRENT_STEP,
  UPDATE_NOTES,
  UPDATE_STATE,
  UPDATE_TRAINING_PAGE,
} from 'shared/training/actionTypes'
import { TrainingData, TrainingState, PreviewState, TrainingStateHookObject } from 'shared/training/types'

export const getQuestionState = (state: TrainingState, uid: string) => {
  const currentStep = state.steps.find(step => step.uid === state.currentStepUid)
  return currentStep ? currentStep.panels.find(panel => panel.uid === uid) : null
}

function reducer(state: TrainingState, action: ActionTypes): TrainingState {
  switch (action.type) {
    case UPDATE_STATE:
    case INIT_STATE:
      return action.state

    case UPDATE_TRAINING_PAGE:
      return {
        ...state,
        currentStepUid: action.stepUid,
      }

    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        currentStepUid: action.stepUid,
      }

    case ATTEMPT_QUESTION:
      return {
        ...state,
        steps: state.steps.map(step => {
          if (step.uid === action.stepUid) {
            step.panels = step.panels.map(panel => {
              if (panel.uid === action.panelUid) {
                return {
                  ...panel,
                  answer: action.value,
                  correct: action.correct,
                }
              }
              return panel
            })
          }
          return step
        }),
      }

    case CREATE_QUESTION:
      return {
        ...state,
        steps: state.steps.map(step => {
          if (step.uid === action.stepUid) {
            return {
              ...step,
              panels: [
                ...step.panels,
                {
                  uid: action.panel.uid,
                  answer: undefined,
                  correct: false,
                  requiresValidation:
                    action.panel.content_type === 'question' && action.panel.theme !== 'non-compulsory',
                },
              ],
            }
          }
          return step
        }),
      }

    case UPDATE_NOTES:
      return {
        ...state,
        steps: state.steps.map(step => {
          if (step.uid === action.stepUid) {
            return {
              ...step,
              notes: action.notes,
            }
          }
          return step
        }),
      }

    default:
      return state
  }
}

const initialState: TrainingState = {
  unitUid: null,
  currentStepUid: null,
  steps: [],
}

export const getInitialState = (trainingData: TrainingData | false, existingState?: TrainingState) => {
  const newState: TrainingState = { ...initialState }

  if (trainingData && trainingData.unit.training_steps) {
    newState.unitUid = trainingData.unit.uid
    newState.currentStepUid = existingState ? existingState.currentStepUid : trainingData.unit.training_steps[0].uid

    newState.steps = trainingData.unit.training_steps.map(step => {
      const existingStep = existingState?.steps.find(item => item.uid === step.uid)
      const panels = (step.panels || []).map(panel => {
        const existingPanel = existingStep?.panels.find(item => panel.uid === item.uid)
        return {
          uid: panel.uid,
          answer: existingPanel ? existingPanel.answer : undefined,
          correct: existingPanel ? existingPanel.correct : false,
          requiresValidation: panel.content_type === 'question',
        }
      })

      return {
        uid: step.uid,
        notes: existingStep ? existingStep.notes : '',
        panels,
      }
    })
  }
  return newState
}

function useProviderTrainingState() {
  const [trainingData, setTrainingData] = useState<TrainingData | false>(false)
  const [previewing, setPreviewing] = useState<boolean>(false)
  const [previewState, setPreviewState] = useState<PreviewState>({ stepIndex: 0 })
  const [state, dispatch] = useReducer(reducer, getInitialState(trainingData))
  const { accessToken } = useUserState()

  const persistentState = useRef(state)

  const preDispatch = (action: ActionTypes) => {
    console.log('DISPATCHING ACTION', action)
    persistentState.current = reducer(persistentState.current, action)
    dispatch(action)
  }

  useEffect(() => {
    if (state && state.unitUid && !previewing) {
      saveTrainingUnitValues(state.unitUid, state, accessToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return {
    state,
    trainingData: trainingData,
    setTrainingData: setTrainingData,
    dispatch: preDispatch,
    previewing,
    setPreviewing,
    previewState,
    setPreviewState,
  }
}

function noop(): any {}

export const TrainingStateContext = React.createContext<TrainingStateHookObject>({
  state: initialState,
  trainingData: false,
  setTrainingData: noop,
  dispatch: noop,
  previewing: false,
  setPreviewing: noop,
  previewState: { stepIndex: 0 },
  setPreviewState: noop,
})

export const TrainingStateProvider: React.FC = ({ children }) => {
  const state = useProviderTrainingState()
  return <TrainingStateContext.Provider value={state}>{children}</TrainingStateContext.Provider>
}

export function useTrainingState() {
  return useContext(TrainingStateContext)
}
