import React, { useEffect, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { useParams, Redirect, useLocation } from 'react-router-dom'

import { MentorEntity } from 'shared/dashboard/types'

import { Fixed, Row, H2, Spacer, P, Column, CollapseMargin, Button } from 'common/ui'
import { Sidebar, SidebarHead, SidebarBody, Header, HeaderLeft, CenteredBody } from 'dashboards/common/LayoutUI'
import { Logo } from 'common/Logo'
import { LinkButton, AnchorButton } from 'common/LinkButton'
import { MentorDashboardMain, baseUrl as mentorDashboardBaseUrl } from './MentorDashboard'
import { HeaderProfile } from 'dashboards/common/HeaderProfile'
import { SpinnerWithLabel } from 'common/Spinner'
import { MentorPanel } from 'dashboards/common/MentorPanel'

import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'

import { postJson } from 'utils/apiUtils'
import { authorizeElseReturn } from 'auth/oauth2'
import { logoutDrupalUser } from 'auth/logoutDrupalUser'
import styled from 'styled-components'
import { DrupalProfile } from 'api'

// This component is only rendered once RequireUser conditions have been met
// and just acts as a redirect back to main mentor link screen, preserving that mentorUid param
export const MentorLinkAccountAuthRedirect: React.FC = () => {
  const location = useLocation()
  return <Redirect to={location.pathname.replace(/\/auth$/, '?linkmeup')} />
}

export const MentorLinkAccount: React.FC = () => {
  const { drupalProfile, tokenExpiry } = useUserState()
  const hasAttemptedAuth = window.location.search.indexOf('attempted_auth=1') > 0
  const proceedToLinkAccount = window.location.search.indexOf('linkmeup') > 0
  const authorized = drupalProfile && tokenExpiry > Date.now()
  const title = authorized ? 'Link Mentor Account' : 'Set Up Mentor Portal'
  if (!authorized && !hasAttemptedAuth) {
    authorizeElseReturn(window.location.origin + window.location.pathname + '?attempted_auth=1')
    return null
  }
  return (
    <Fixed cover>
      <Row style={{ width: '100%', maxWidth: '100vw' }}>
        <Sidebar>
          <SidebarHead style={{ borderBottom: 'none' }}>
            <Logo fill="#011A46" />
          </SidebarHead>
          <SidebarBody />
        </Sidebar>
        <MentorDashboardMain>
          <Header>
            <HeaderLeft>
              <H2>{title}</H2>
            </HeaderLeft>
            <HeaderProfile />
          </Header>
          <CenteredBody>
            {authorized ? (
              proceedToLinkAccount ? (
                <AuthorizedConfirmation />
              ) : (
                <AuthorizedPrompt />
              )
            ) : (
              <UnauthorizedPrompt />
            )}
          </CenteredBody>
        </MentorDashboardMain>
      </Row>
    </Fixed>
  )
}

const getUserName = (user: DrupalProfile) => {
  if (user.first_name && typeof user.first_name == 'string') {
    if (user.last_name && typeof user.last_name == 'string') {
      return `${user.first_name} ${user.last_name}`
    }
    return `${user.first_name}`
  }
  if (user.last_name && typeof user.last_name == 'string') {
    return `${user.last_name}`
  }

  return user.username
}

const AuthorizedPrompt: React.FC = () => {
  const { mentorUid } = useParams<{ mentorUid: string }>()
  const { drupalProfile, logout } = useUserState()
  const [linkRequestMentor] = useEndpoint<MentorEntity>(`/api/v1/mentors/link_requests/${mentorUid}`)
  const isPrimaryMentor = linkRequestMentor && !linkRequestMentor.primary_mentor_id
  const user = drupalProfile!

  const handleLogoutAndLogin = useCallback(() => {
    logoutDrupalUser(() => {
      logout()
      window.location.href = `${
        process.env.REACT_APP_SST_BASE_URL
      }/user/login?context=mentor&return_uri=${encodeURIComponent(window.location.href)}`
    })
  }, [logout])

  useEffect(() => {
    localStorage.removeItem('mentorUid')
  }, [])

  return (
    <MentorPanel style={{ overflow: 'auto' }}>
      <CollapseMargin>
        <H2>Welcome to the SAS Mentor Portal</H2>
        <P>You are about to become an SAS Mentor for a Cadet in an SAS Small Group Program.</P>
        {isPrimaryMentor && (
          <P>
            You are the nominated consenting adult for this Cadet.
            <br />
            You will be prompted to active the Cadet Place and agree to the Privacy Collection Statement.
          </P>
        )}
      </CollapseMargin>
      <Spacer size="m" />
      <Column>
        <P style={{ marginBottom: '0.5rem' }}>Please click your name to continue:</P>
        <AnchorButton theme="blue" size="l" href={`${window.location.pathname}?linkmeup=1`}>
          {user.first_name &&
            typeof user.first_name === 'string' &&
            user.last_name &&
            typeof user.last_name === 'string' && (
              <AvatarCircle
                style={{ marginRight: 20 }}
                children={[user.first_name.charAt(0), user.last_name.charAt(0)].filter(_ => _).join('')}
              />
            )}
          <span style={{ verticalAlign: 'middle' }}>{getUserName(user)}</span>
        </AnchorButton>
        <Spacer size="s" />
        <P style={{ marginBottom: '0.5rem' }}>If you are not this person or would like to use a different account:</P>
        <Button theme="blue" size="s" onClick={handleLogoutAndLogin} children="Sign out and use a different account" />
      </Column>
    </MentorPanel>
  )
}

const AvatarCircle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 2.8rem;
  height: 2.5rem;
  padding-bottom: 0.3rem;
  border-radius: 50%;
  background-color: #eee;
  color: ${p => p.theme.darkBg};
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1;
`

const UnauthorizedPrompt: React.FC = () => {
  const { mentorUid } = useParams<{ mentorUid: string }>()
  const [linkRequestMentor] = useEndpoint<{ is_primary_mentor: boolean }>(
    `/api/v1/public/mentor_link_info/${mentorUid}`
  )
  const isPrimaryMentor = linkRequestMentor && linkRequestMentor.is_primary_mentor

  useEffect(() => {
    localStorage.removeItem('mentorUid')
  }, [])

  return (
    <MentorPanel style={{ overflow: 'auto' }}>
      <CollapseMargin>
        <H2>Welcome to the SAS Mentor Portal</H2>
        <P>You are about to become an SAS Mentor for a Cadet in an SAS Small Group Program.</P>
        {isPrimaryMentor && (
          <P>
            You are the nominated consenting adult for this Cadet.
            <br />
            You will be prompted to active the Cadet Place and agree to the Privacy Collection Statement.
          </P>
        )}
        <P>You will need an account to complete this process.</P>
      </CollapseMargin>
      <Spacer size="m" />
      <Column>
        {/* <LinkButton theme="blue" size="s" to={location.pathname + '/auth'} children="Login to an existing account" />
        <Spacer size="s" /> */}
        <AnchorButton
          theme="blue"
          size="l"
          href={`${process.env.REACT_APP_SST_BASE_URL}/user/login?context=mentor&return_uri=${encodeURIComponent(
            window.location.origin + window.location.pathname + '/auth'
          )}`}
          children="Login or Create new account"
        />
      </Column>
    </MentorPanel>
  )
}

const AuthorizedConfirmation: React.FC = () => {
  const { accessToken, drupalProfile } = useUserState()
  const { mentorUid } = useParams<{ mentorUid: string }>()

  const [linkRequestMentor, { loading, errorLoading, errorMessage }] = useEndpoint<MentorEntity>(
    `/api/v1/mentors/link_requests/${mentorUid}`
  )

  const [linking, setLinking] = useState<boolean>(false)
  const [errorLinking, setErrorLinking] = useState<string | false>(false)
  const [linked, setLinked] = useState<boolean>(false)

  const handleLink = () => {
    if (loading || !linkRequestMentor) return
    ReactDOM.unstable_batchedUpdates(() => {
      setLinking(true)
      setLinked(false)
      setErrorLinking(false)
    })
    postJson(
      `/api/v1/mentors/link_cadet`,
      { mentorLinkUid: linkRequestMentor.uid },
      getAuthRequestParams(accessToken),
      { retries: 0 }
    )
      .then(() => {
        sessionStorage.clear()
        window.location.href = mentorDashboardBaseUrl
      })
      .catch((e: any) => {
        console.error('ERROR', e)
        ReactDOM.unstable_batchedUpdates(() => {
          setLinking(false)
          setLinked(false)
          setErrorLinking(e.message || 'Failed to link')
        })
      })
  }

  useEffect(() => {
    if (linkRequestMentor !== null) handleLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkRequestMentor])

  // by definition this can't happen
  if (!drupalProfile) return null

  if (errorLoading)
    return (
      <MentorPanel>
        <Column style={{ maxWidth: 420, maxHeight: '100%' }}>
          <H2>Uh oh</H2>
          <P>
            We ran into an issue while fetching linking information:
            <br />
            <span style={{ color: '#d23015' }}>{errorMessage || '[No error message returned]'}</span>
          </P>
          <LinkButton size="m" theme="blue" to={mentorDashboardBaseUrl} children="Continue" />
        </Column>
      </MentorPanel>
    )

  if (loading) {
    return <SpinnerWithLabel label="Loading..." />
  }

  return (
    <MentorPanel style={{ overflow: 'auto' }}>
      <Column style={{ minWidth: 400, maxWidth: 550, maxHeight: '100%' }}>
        {errorLinking && (
          <Row alignItems="center">
            <P style={{ margin: 0 }}>{errorLinking}</P>
            <Spacer size="s" />
            <img src={require('reporting/assets/images/cross-circle.svg')?.default} alt="" />
          </Row>
        )}
        {linked && (
          <>
            <Row alignItems="center">
              <P style={{ margin: 0 }}>Mentor account linked successfully</P>
              <Spacer size="s" />
              <img src={require('reporting/assets/images/tick-circle.svg')?.default} alt="" />
            </Row>
            <Spacer />
            <LinkButton
              size="m"
              theme="blue"
              to={mentorDashboardBaseUrl}
              disabled={!linked || linking || loading || !linkRequestMentor}
              children="Continue"
            />
          </>
        )}
        {linking && <SpinnerWithLabel color="#001741" label="Linking Mentor Account" />}
      </Column>
    </MentorPanel>
  )
}
