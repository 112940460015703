import React from 'react'
import styled from 'styled-components'
import { fontBlack, fontBold, fontLight } from 'fonts'

export const RichText: React.FC<{ text: string }> = ({ text }) => {
  const formattedText = text || '' //.replace(/<[^>]*>\s*<\/[^>]*>/g, '') // strip out any empty tags
  return <RichTextContainer dangerouslySetInnerHTML={{ __html: formattedText }} />
}

export const RichTextContainer = styled.div`
  ${fontLight}
  color: #011A46;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    ${fontBold}
    line-height: 1.3;
    color: ${p => p.theme.buttonBorderTopColor};
    margin-top: 0;
    margin-bottom: 0;
  }
  & h1 {
    ${fontBlack}
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  & h2 {
    ${fontBold}
    font-size: 24px;
    margin-bottom: 10px;
    letter-spacing: 0.03em;
  }
  & h3 {
    ${fontBold}
    font-size: 18px;
    letter-spacing: 0.03em;
    margin-top: 20px;
  }
  & p {
    ${fontLight}
    margin-top: 16px;
    margin-bottom: 16px;
    &.lead {
      font-size: 28px;
      line-height: 1.23;
      letter-spacing: 0.03em;
    }
  }
  & ol,
  & ul {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0;
    list-style: none;
    & > li {
      position: relative;
      margin-bottom: 4px;
      padding-left: 36px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.35em;
        width: 1em;
        height: 1em;
      }
    }
  }

  .font-subscript {
    font-size: 0.8rem;
  }

  .font-large {
    font-size: 4rem;
  }

  & ul > li::before {
    background: url(${require('../assets/list-bullet-blue.svg')?.default}) no-repeat right top;
    background-size: contain;
  }

  & ol > li::before {
    background-color: #2eadf0;
    border-radius: 50%;
    color: white;
    font-size: 15px;
    display: block;
    text-align: center;
    line-height: 1;
    left: 5px;
  }

  & ol.list-numeric > li::before {
    content: counter(listStyle);
  }
  & ol.list-alphabetic > li::before {
    content: counter(listStyle, lower-alpha);
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:empty {
    display: none;
  }
`
