import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { animated, useSpring } from 'react-spring'
import { fontLight } from 'fonts'
import requireImageSize from '../../../../macros/requireImageSize.macro'

import { ActionTypes, UPDATE_THUMBNAIL_URL } from 'shared/session/sections/drawing/actionTypes'
import { UPDATE_SECTION_STATE_DANGEROUS } from 'shared/session/actionTypes'
import { getInitialState as getInitialDrawingSectionState, reducer } from 'shared/session/sections/drawing/reducer'
import {
  EmbeddedActivitySection,
  SectionPanel,
  SectionPropsBase,
  ActivityBrainCellState,
  DrawingSectionState,
  ParticipantState,
} from 'shared/session/types'

import { Button, P } from 'common/ui'
import { FacilitatorControls, FacilitatorControlsTable } from 'session/common/FacilitatorControlsSection'
import { DrawingSectionWithContext, DrawHeaderRight, DrawingProps } from 'session/sections/drawing/Drawing'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { HighlightableArea, HighlightArea } from 'session/common/HighlightableArea'
import { InputComponent } from 'session/common/InputComponent'
import { RadioButton } from 'session/common/RadioButton'
import { Checkbox } from 'common/Checkbox'
import { SVG } from 'common/SVG'

import { useFocusedParticipantState, useParticipantProfileUid } from 'session/hooks/useProfileState'
import { useSectionStateWithFallback } from 'session/hooks/useSectionState'
import { useSessionState } from 'session/SessionState'
import { useInputInterface, useInputValue, useInputContext, InputContext } from 'session/InputContext'
import { useUserState } from 'app/UserState'
import { dataURItoBlob } from 'utils/uriUtils'
import { saveFile, deleteFile } from 'api'
import { UploadResponse } from 'shared/types'
import { useMeasure } from 'utils/useMeasure'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'
import { InputLockComponent } from 'session/common/InputLockComponent'

interface Props extends SectionPropsBase {
  property: 'embedded_activity_sections'
}

interface SectionProps {
  section: Props['section']
  panel: Props['panel']
  property: Props['property']
  index: Props['index']
  trainingState?: ParticipantState
}

interface BasePlacement {
  x: number
  y: number
  width: number
  height: number
  facilitatorOnly?: boolean
}

interface DrawingPlacement extends BasePlacement {
  type: 'DRAWING'
  uid: string
}

interface TextareaPlacement extends BasePlacement {
  type: 'TEXTAREA'
  uid: string
}

interface TextPlacement extends Omit<BasePlacement, 'height' | 'width'> {
  content: string
}

interface RadioPlacement extends Omit<BasePlacement, 'height'> {}

const brainCellImage = require('session/assets/DecoderBrainCellBg.svg')?.default
const brainCellImageSize = requireImageSize('../../../assets/DecoderBrainCellBg.svg')

const imagePositions: { [key: string]: BasePlacement } = {
  'path-1': { x: 1.7, y: 4.1, width: 50.3, height: 50.4 },
  'path-2': { x: 34.9, y: 4.1, width: 31.3, height: 50.4 },
  'path-3': { x: 51.2, y: 4.04, width: 48.2, height: 50.5 },
}

const textPositions: { [key: string]: TextPlacement } = {
  'selection': { x: 2, y: 43, content: 'Select your solution'}, // prettier-ignore
  'facilitator-message': { x: 0, y: 47, content: 'Optional: Facilitator only can enter a brief summary of the three possible solutions here to display to club members if needed'} // prettier-ignore
}

const inputPositions: { [key: string]: TextareaPlacement } = {
  'd-input': { x: 17, y: 3.4, width: 65, height: 0.7, uid: 'activity_brain_cell_input_d_0' ,type:'TEXTAREA'}, // prettier-ignore
  'e-l-input': { x: 4.05, y: 16.4, width: 26.3, height: 7.2, uid: 'activity_brain_cell_input_e_0' ,type:'TEXTAREA'}, // prettier-ignore
  'e-m-input': { x: 37.4, y: 16.4, width: 26.3, height: 7.2 ,uid: 'activity_brain_cell_input_e_1' , type:'TEXTAREA'}, // prettier-ignore
  'e-r-input': { x: 70.5, y: 16.4, width: 26.3, height: 7.2 ,uid: 'activity_brain_cell_input_e_2', type:'TEXTAREA'}, // prettier-ignore
  'c-t-l-input': { x: 4.05, y: 29.45, width: 26, height: 3.4, uid: 'activity_brain_cell_input_c_0', type:'TEXTAREA'}, // prettier-ignore
  'c-t-m-input': { x: 37.4, y: 29.45, width: 26, height: 3.4, uid: 'activity_brain_cell_input_c_1',type:'TEXTAREA'}, // prettier-ignore
  'c-t-r-input': { x: 70.8, y: 29.45, width: 26, height: 3.4, uid: 'activity_brain_cell_input_c_2',type:'TEXTAREA'}, // prettier-ignore
  'c-b-l-input': { x: 4.05, y: 34.65, width: 26, height: 3.4, uid: 'activity_brain_cell_input_c_3', type:'TEXTAREA'}, // prettier-ignore
  'c-b-m-input': { x: 37.4, y: 34.65, width: 26, height:3.4, uid: 'activity_brain_cell_input_c_4', type:'TEXTAREA'}, // prettier-ignore
  'c-b-r-input': { x: 70.8, y: 34.65, width: 26, height:3.4, uid: 'activity_brain_cell_input_c_5', type:'TEXTAREA'}, // prettier-ignore
  'radio-input-1': { x: 4.3, y: 45.5, width: 30, height: 1.5 ,uid: 'activity_brain_cell_input_radio-1', type:'TEXTAREA', facilitatorOnly: true}, // prettier-ignore
  'radio-input-2': { x: 35.8, y: 45.5, width: 30, height: 1.5 ,uid: 'activity_brain_cell_input_radio-2', type:'TEXTAREA', facilitatorOnly: true}, // prettier-ignore
  'radio-input-3': { x: 67, y: 45.5, width: 30, height: 1.5 ,uid: 'activity_brain_cell_input_radio-3', type:'TEXTAREA', facilitatorOnly: true}, // prettier-ignore
  'o-t-input': { x: 4.3, y: 53.6, width: 92, height: 2 ,uid: 'activity_brain_cell_input_c_o_0', type:'TEXTAREA'}, // prettier-ignore
  'o-l-input': { x: 4.3, y: 65.3, width: 42.3, height: 6.3 ,uid: 'activity_brain_cell_input_o_1', type:'TEXTAREA'}, // prettier-ignore
  'o-r-input': { x: 54.5, y: 65.3, width: 42.3, height: 6.3 ,uid: 'activity_brain_cell_input_o_2', type:'TEXTAREA'}, // prettier-ignore
  'de-l-input': { x: 4.3, y: 79.7, width: 42.3, height: 4.2 ,uid: 'activity_brain_cell_input_de_0', type:'TEXTAREA'}, // prettier-ignore
  'de-r-input': { x: 54.5, y: 79.7, width: 42.3, height: 4.2 ,uid: 'activity_brain_cell_input_de_1', type:'TEXTAREA'}, // prettier-ignore
  'r-input': { x: 4.05, y: 90, width: 92, height: 2.9 ,uid: 'activity_brain_cell_input_r_0', type:'TEXTAREA'} // prettier-ignore
}

const drawingPositions: { [key: string]: DrawingPlacement } = {
  'd-draw': { x: 17, y: 4.5, width: 65, height: 4.5, uid: 'activity_brain_cell_drawing_d_0', type: 'DRAWING' }, // prettier-ignore
  'e-l-draw': { x: 4.05, y: 23.8, width: 26.3, height: 1.85, uid: 'activity_brain_cell_drawing_e_0', type: 'DRAWING' }, // prettier-ignore
  'e-m-draw': { x: 37.4, y: 23.8, width: 26.3, height: 1.85, uid: 'activity_brain_cell_drawing_e_1', type: 'DRAWING' }, // prettier-ignore
  'e-r-draw': { x: 70.5, y: 23.8, width: 26.3, height: 1.85, uid: 'activity_brain_cell_drawing_e_2', type: 'DRAWING' }, // prettier-ignore
  'o-t-draw': { x: 4.3, y: 55.9, width: 92, height: 6.5, uid: 'activity_brain_cell_drawing_o_0', type: 'DRAWING' }, // prettier-ignore
  'o-l-draw': { x: 4.3, y: 71.9, width: 42.3, height: 3, uid: 'activity_brain_cell_drawing_o_1', type: 'DRAWING' }, // prettier-ignore
  'o-r-draw': { x: 54.5, y: 71.9, width: 42.3, height: 3, uid: 'activity_brain_cell_drawing_o_2', type: 'DRAWING' }, // prettier-ignore
  'de-l-draw': { x: 4.3, y: 84.25, width: 42.3, height: 3, uid: 'activity_brain_cell_drawing_de_0', type: 'DRAWING' }, // prettier-ignore
  'de-r-draw': { x: 54.5, y: 84.25, width: 42.3, height: 3, uid: 'activity_brain_cell_drawing_de_1', type: 'DRAWING' }, // prettier-ignore
  'r-draw': { x: 4.05, y: 93.25, width: 92, height: 6.4, uid: 'activity_brain_cell_drawing_r_0', type: 'DRAWING' } // prettier-ignore
}

const radioPositions: { [key: string]: RadioPlacement } = {
  radios: { x: 0, y: 44.3, width: 100 },
}

const userInputPositions: { [key: string]: DrawingPlacement | TextareaPlacement } = {
  ...inputPositions,
  ...drawingPositions,
}

const anchorPercents: number[] = [0, 14, 26.5, 38.5, 50.7, 63, 75.8, 100]
const navigationAnchorPercents: number[] = [0, 13.6, 25.5, 39, 50, 63, 75, 88, 100]

const initialState: ActivityBrainCellState = {
  activeProfileUid: null,
  currentAnchor: 0,
  showDrawModal: false,
  activeDrawing: 'activity_brain_cell_drawing_d_0',
  selectedOptions: {},
}

const getInitialState = () => initialState

export const BrainCell: React.FC<SectionProps> = ({ section, panel, property, index, trainingState }) => {
  const { dispatch, isFacilitator, state, pastMode, getBaseAction } = useSessionState()
  const showFacilitator = trainingState ? true : isFacilitator
  const sectionState = useSectionStateWithFallback('activity_brain_cell', section, getInitialState)
  const focusedParticipantState = useFocusedParticipantState()
  const participantState = trainingState ? trainingState : focusedParticipantState

  const userActive = !!participantState && sectionState.activeProfileUid === participantState.profile.uid
  const interactive = showFacilitator || userActive
  const { currentAnchor, showDrawModal, activeDrawing } = sectionState
  const hasMounted = useRef<boolean>(false)

  const activeUserProfile = trainingState
    ? trainingState
    : state.participants.find(a => a.profile.uid === sectionState.activeProfileUid)

  const updateSectionState = useCallback(
    (state: ActivityBrainCellState) => {
      dispatch({
        ...getBaseAction(),
        type: UPDATE_SECTION_STATE_DANGEROUS,
        property: 'activity_brain_cell',
        section_id: section.id,
        state,
      })
    },
    [dispatch, getBaseAction, section.id]
  )

  const getAnchorPosition = (percent: number) => {
    return containerRef.current ? (percent / 100) * containerRef.current.clientHeight : 0
  }

  const highlightableKeyPrefix = `step${panel.step_id}_${property}_${index}`

  const [wrapperRef, { width: wrapperWidth }] = useMeasure()
  const containerRef = useRef<HTMLDivElement>(null)
  const slideContainerScrollRef = useRef<number>(getAnchorPosition(anchorPercents[currentAnchor]))

  const [springProps, setSpringValues] = useSpring(() => ({
    config: { tension: 210 },
    immediate: true,
    scrollTop: getAnchorPosition(anchorPercents[currentAnchor]),
  }))

  const moveToPrevious = () => {
    updateSectionState({ ...sectionState, currentAnchor: currentAnchor - 1 })
  }

  const moveToNext = () => {
    updateSectionState({ ...sectionState, currentAnchor: currentAnchor + 1 })
  }

  const goToSection = (index: number) => {
    updateSectionState({ ...sectionState, currentAnchor: index })
  }

  const handleDrawClick = (key: string) => {
    const position = userInputPositions[key]
    if (position.type !== 'DRAWING') return
    updateSectionState({
      ...sectionState,
      showDrawModal: true,
      activeDrawing: position.uid,
    })
  }

  const handleOptionSelected = (selected: boolean, ref: string) => {
    if (showFacilitator) {
      updateSectionState({
        ...sectionState,
        selectedOptions: { ...sectionState.selectedOptions, [ref]: selected },
      })
    }
  }

  useEffect(() => {
    const nextAnchorPos = getAnchorPosition(anchorPercents[currentAnchor])

    setSpringValues({
      from: { scrollTop: slideContainerScrollRef.current },
      immediate: hasMounted.current ? false : true,
      scrollTop: nextAnchorPos,
    })

    slideContainerScrollRef.current = nextAnchorPos

    hasMounted.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnchor, wrapperWidth])

  return (
    <Wrapper isInteractive={interactive} ref={wrapperRef}>
      <Container
        style={{
          marginBottom: 15,
          pointerEvents: showFacilitator || pastMode ? 'auto' : 'none',
        }}>
        <img src={require('session/assets/DecoderBrainCellBgRotated.svg')} alt="" />
        <NavOverlay
          active={currentAnchor === 0}
          left={navigationAnchorPercents[0]}
          right={navigationAnchorPercents[1]}
          onClick={() => goToSection(0)}
        />
        <NavOverlay
          active={currentAnchor === 1}
          left={navigationAnchorPercents[1]}
          right={navigationAnchorPercents[2]}
          onClick={() => goToSection(1)}
        />
        <NavOverlay
          active={currentAnchor === 2}
          left={navigationAnchorPercents[2]}
          right={navigationAnchorPercents[3]}
          onClick={() => goToSection(2)}
        />
        <NavOverlay
          active={currentAnchor === 3}
          left={navigationAnchorPercents[3]}
          right={navigationAnchorPercents[4]}
          onClick={() => goToSection(3)}
        />
        <NavOverlay
          active={currentAnchor === 4}
          left={navigationAnchorPercents[4]}
          right={navigationAnchorPercents[5]}
          onClick={() => goToSection(4)}
        />
        <NavOverlay
          active={currentAnchor === 5}
          left={navigationAnchorPercents[5]}
          right={navigationAnchorPercents[6]}
          onClick={() => goToSection(5)}
        />
        <NavOverlay
          active={currentAnchor === 6}
          left={navigationAnchorPercents[6]}
          right={navigationAnchorPercents[7]}
          onClick={() => goToSection(6)}
        />
        <NavOverlay
          active={currentAnchor === 7}
          left={navigationAnchorPercents[7]}
          right={navigationAnchorPercents[8]}
          onClick={() => goToSection(7)}
        />
      </Container>
      {!pastMode && (
        <Container style={{ marginBottom: 15, backgroundColor: userActive || trainingState ? '#4EBE40' : '#FF7A00' }}>
          <P
            color="#fff"
            style={{
              fontWeight: 600,
              fontSize: 22,
              margin: '4px 0',
              letterSpacing: '0.05em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {!userActive && !trainingState && (
              <SVG width={20} height={20} viewboxWidth={22} viewboxHeight={28}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.09091 6.81818V10.9091H2.72727C1.27043 10.9091 0 11.9678 0 13.4091V24.7727C0 26.214 1.27043 27.2727 2.72727 27.2727H19.0909C20.5478 27.2727 21.8182 26.214 21.8182 24.7727V13.4091C21.8182 11.9678 20.5478 10.9091 19.0909 10.9091H17.7273V6.81818C17.7273 3.0526 14.6747 0 10.9091 0C7.14351 0 4.09091 3.0526 4.09091 6.81818ZM15 6.81818V10.9091H6.81818V6.81818C6.81818 4.55884 8.64974 2.72727 10.9091 2.72727C13.1684 2.72727 15 4.55884 15 6.81818ZM2.72727 24.5447V13.6357H19.0909V24.5447H2.72727ZM12.2727 19.0909C12.2727 19.844 11.6622 20.4545 10.9091 20.4545C10.156 20.4545 9.54545 19.844 9.54545 19.0909C9.54545 18.3378 10.156 17.7273 10.9091 17.7273C11.6622 17.7273 12.2727 18.3378 12.2727 19.0909Z"
                  fill="#fff"
                />
              </SVG>
            )}
            {(userActive || trainingState) && (
              <SVG width={20} height={20} viewboxWidth={22} viewboxHeight={30}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.09091 13.6364V6.81818C4.09091 3.0526 7.14351 0 10.9091 0C14.6747 0 17.7273 3.0526 17.7273 6.81818V9.54545H15V6.81818C15 4.55884 13.1684 2.72727 10.9091 2.72727C8.64974 2.72727 6.81818 4.55884 6.81818 6.81818V13.6364H19.0909C20.5971 13.6364 21.8182 14.8574 21.8182 16.3636V27.2727C21.8182 28.779 20.5971 30 19.0909 30H2.72727C1.22104 30 0 28.779 0 27.2727V16.3636C0 14.8574 1.22104 13.6364 2.72727 13.6364H4.09091ZM2.72732 27.2724V16.3633H19.091V27.2724H2.72732ZM12.2727 21.8182C12.2727 22.5713 11.6622 23.1818 10.9091 23.1818C10.156 23.1818 9.54545 22.5713 9.54545 21.8182C9.54545 21.0651 10.156 20.4545 10.9091 20.4545C11.6622 20.4545 12.2727 21.0651 12.2727 21.8182Z"
                  fill="#fff"
                />
              </SVG>
            )}
            <span style={{ marginLeft: 10 }}>{userActive || trainingState ? 'YOUR TURN!' : 'WAIT YOUR TURN'}</span>
          </P>
        </Container>
      )}
      <Container {...springProps} style={{ height: 0, paddingTop: '92%' }}>
        <RatioDiv ref={containerRef} height={brainCellImageSize.height} width={brainCellImageSize.width}>
          <img src={brainCellImage} alt="" style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }} />

          {/* D */}
          <ScrollAnchor y={anchorPercents[0]} />
          <InputArea
            disabled={pastMode || !interactive}
            reference="d-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'d-draw'}
            onDrawClick={handleDrawClick}
          />
          {/* D */}

          {/* E */}
          <ScrollAnchor y={anchorPercents[1]} />
          <InputArea
            disabled={pastMode || !interactive}
            reference="e-l-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'e-l-draw'}
            onDrawClick={handleDrawClick}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="e-m-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'e-m-draw'}
            onDrawClick={handleDrawClick}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="e-r-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'e-r-draw'}
            onDrawClick={handleDrawClick}
          />
          {/* E */}

          {/* C */}
          <ScrollAnchor y={anchorPercents[2]} />
          <InputArea
            disabled={pastMode || !interactive}
            reference="c-t-l-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="c-t-m-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="c-t-r-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="c-b-l-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="c-b-m-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="c-b-r-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          {/* C */}

          {/* Radios */}
          <AbsoluteDiv reference="selection" type="text">
            <P children={textPositions['selection'].content} />
          </AbsoluteDiv>
          <AbsoluteDiv reference="radios" type="radio">
            <RadioArea
              checked={sectionState.selectedOptions['radio-option-1']}
              reference="radio-option-1"
              onSelect={handleOptionSelected}
            />
            <RadioArea
              checked={sectionState.selectedOptions['radio-option-2']}
              reference="radio-option-2"
              onSelect={handleOptionSelected}
            />
            <RadioArea
              checked={sectionState.selectedOptions['radio-option-3']}
              reference="radio-option-3"
              onSelect={handleOptionSelected}
            />
          </AbsoluteDiv>
          {showFacilitator && (
            <AbsoluteDiv reference="facilitator-message" isFacilitator={showFacilitator} type="text">
              <P children={textPositions['facilitator-message'].content} />
            </AbsoluteDiv>
          )}
          <InputArea disabled={pastMode || !interactive} reference="radio-input-1" isFacilitator={showFacilitator} />
          <InputArea disabled={pastMode || !interactive} reference="radio-input-2" isFacilitator={showFacilitator} />
          <InputArea disabled={pastMode || !interactive} reference="radio-input-3" isFacilitator={showFacilitator} />
          {/* Radios */}

          {/* O */}
          <ScrollAnchor y={anchorPercents[3]} />
          <InputArea
            disabled={pastMode || !interactive}
            reference="o-t-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'o-t-draw'}
            onDrawClick={handleDrawClick}
          />
          <ScrollAnchor y={anchorPercents[4]} />
          <InputArea
            disabled={pastMode || !interactive}
            reference="o-l-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'o-l-draw'}
            onDrawClick={handleDrawClick}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="o-r-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'o-r-draw'}
            onDrawClick={handleDrawClick}
          />
          {/* O */}

          {/* DE */}
          <ScrollAnchor y={anchorPercents[5]} />
          <InputArea
            disabled={pastMode || !interactive}
            reference="de-l-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'de-l-draw'}
            onDrawClick={handleDrawClick}
          />
          <InputArea
            disabled={pastMode || !interactive}
            reference="de-r-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'de-r-draw'}
            onDrawClick={handleDrawClick}
          />
          {/* DE */}

          {/* R */}
          <ScrollAnchor y={anchorPercents[6]} />
          <InputArea
            disabled={pastMode || !interactive}
            reference="r-input"
            highlightableKeyPrefix={highlightableKeyPrefix}
          />
          <DrawArea
            interactive={interactive}
            highlightableKeyPrefix={highlightableKeyPrefix}
            reference={'r-draw'}
            onDrawClick={handleDrawClick}
          />
          {/* R */}

          {sectionState.selectedOptions['radio-option-1'] && (
            <PathImg src={require('session/assets/BrainCellPath1.svg')?.default} reference="path-1" />
          )}
          {sectionState.selectedOptions['radio-option-2'] && (
            <PathImg src={require('session/assets/BrainCellPath2.svg')?.default} reference="path-2" />
          )}
          {sectionState.selectedOptions['radio-option-3'] && (
            <PathImg src={require('session/assets/BrainCellPath3.svg')?.default} reference="path-3" />
          )}
        </RatioDiv>
      </Container>
      {(pastMode || trainingState) && (
        <div style={{ position: 'absolute', bottom: 20, right: 20 }}>
          <Button
            children="Previous"
            onClick={moveToPrevious}
            disabled={currentAnchor === 0}
            style={{ marginRight: 10 }}
          />
          <Button children="Next" onClick={moveToNext} disabled={currentAnchor === 7} />
        </div>
      )}
      <DrawingModal
        isInteractive={interactive}
        isOpen={showDrawModal}
        onRequestClose={() => {
          updateSectionState({ ...sectionState, showDrawModal: false })
        }}
        section={section}
        index={index}
        panel={panel}
        drawingID={activeDrawing}
        title={
          activeUserProfile
            ? `${activeUserProfile.profile.displayName} is drawing`
            : 'Drawing Disabled - Facilitator Only'
        }
      />
    </Wrapper>
  )
}

const InputArea: React.FC<{
  reference: string
  highlightableKeyPrefix?: string
  isFacilitator?: boolean
  disabled?: boolean
}> = ({ reference, highlightableKeyPrefix = '', isFacilitator, disabled }) => {
  const profileUid = useParticipantProfileUid()
  return (
    <InputWrapper {...inputPositions[reference]} isFacilitator={isFacilitator}>
      <HighlightableArea
        id={`${highlightableKeyPrefix}_${reference}`}
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
        <InputComponent<string> name={reference} defaultValue="" contextOverride={{ participant_uid: 'shared' }}>
          {props => (
            <InputLockComponent
              verticalPlacementCadet={-25}
              verticalPlacementFacilitator={-25}
              horizontalPlacementFacilitator={0}
              horizontalPlacementCadet={0}
              hasLabel
              fieldUid={`${profileUid}_brain_cell_${reference}`}>
              {(lockProps, lockState) => (
                <textarea
                  {...lockProps}
                  {...lockState}
                  {...props}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    props.onChange(e.target.value || '')
                    lockProps.onChange(e.target.value || '')
                  }}
                  placeholder="Enter Text Here"
                  disabled={disabled || lockState.disabled}
                  readOnly={disabled || lockState.disabled}
                />
              )}
            </InputLockComponent>
          )}
        </InputComponent>
      </HighlightableArea>
    </InputWrapper>
  )
}

const DrawArea: React.FC<{
  reference: string
  interactive?: boolean
  onDrawClick: (ref: string) => void
  highlightableKeyPrefix?: string
}> = ({ reference, interactive, highlightableKeyPrefix = '', onDrawClick }) => {
  const { pastMode } = useSessionState()
  const { x, y, width, height, uid } = userInputPositions[reference]
  const drawingState = useInputValue<DrawingSectionState>(uid, { participant_uid: 'shared' })
  return (
    <DrawAreaDiv {...{ x, y, width, height }} thumbnail={drawingState?.thumbnail}>
      {!pastMode && (
        <HighlightableArea id={`${highlightableKeyPrefix}_${reference}`}>
          <Button size="s" children="Add Drawing" onClick={() => onDrawClick(reference)} disabled={!interactive} />
        </HighlightableArea>
      )}
    </DrawAreaDiv>
  )
}

const RadioArea: React.FC<{
  reference: string
  checked: boolean
  onSelect: (checked: boolean, ref: string) => void
}> = ({ checked, reference, onSelect }) => {
  const optionNumber = reference.split('-')[2]
  return (
    <Checkbox
      // size="s"
      checked={checked}
      onChange={value => onSelect(value, reference)}
      children={`Solution ${optionNumber}`}
    />
  )
}

interface DrawingModalProps extends ReactModal.Props {
  drawingID: string
  section: EmbeddedActivitySection
  panel: SectionPanel
  index: number
  isInteractive: boolean
  title: string
}

const DrawingModal: React.FC<DrawingModalProps> = props => {
  const { drawingID } = props
  const existingInputContext = useInputContext()

  return (
    <InputContext.Provider value={{ ...existingInputContext, name: drawingID, participant_uid: 'shared' }}>
      <DrawingModalInner {...props} key={drawingID} />
    </InputContext.Provider>
  )
}

const DrawingModalInner: React.FC<DrawingModalProps> = ({
  drawingID,
  title,
  index,
  panel,
  section,
  isOpen,
  isInteractive,
  onRequestClose,
}) => {
  const { accessToken } = useUserState()
  const modified = useRef<boolean>(false)
  const generateImageUri = useRef<(() => string | false) | undefined>()
  const [savingImage, setSavingImage] = useState<boolean>(false)
  const [errorSavingImage, setErrorSavingImage] = useState<boolean>(false)
  const [inputValue, onInputValueChange] = useInputInterface<DrawingSectionState>({
    name: drawingID,
    defaultValue: getInitialDrawingSectionState(),
  })

  // ensure that if someone else modifies then we mark it as such for if we're the one that closes/saves
  const initialInputValue = useRef(inputValue)
  useEffect(() => {
    if (initialInputValue.current !== inputValue) modified.current = true
  }, [inputValue])

  const dispatchInputChange = (action: ActionTypes) => {
    modified.current = true
    onInputValueChange(reducer(inputValue, action))
  }
  const receiveImageUriFetcher: Exclude<DrawingProps['provideImageUriFetcher'], undefined> = func => {
    generateImageUri.current = func
  }
  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (modified.current && generateImageUri.current) {
      ReactDOM.unstable_batchedUpdates(() => {
        setSavingImage(true)
        setErrorSavingImage(false)
      })
      const imageUri = generateImageUri.current()
      if (imageUri !== false && accessToken) {
        const blob = dataURItoBlob(imageUri)
        if (inputValue?.thumbnail?.uid) {
          deleteFile(inputValue.thumbnail.uid, accessToken)
            .then(() => console.log('[BrainCell] old image deleted successfully'))
            .catch(() => console.warn('[BrainCell] old image failed to delete'))
        }
        saveFile(blob, accessToken)
          .then(upload => {
            console.log('[BrainCell] upload image successful', upload)
            dispatchInputChange({ type: UPDATE_THUMBNAIL_URL, thumbnail: upload })
            ReactDOM.unstable_batchedUpdates(() => {
              setSavingImage(false)
              setErrorSavingImage(false)
            })
            onRequestClose?.(event)
          })
          .catch(err => {
            console.log('[BrainCell] error in uploading canvas image', err)
            ReactDOM.unstable_batchedUpdates(() => {
              setSavingImage(false)
              setErrorSavingImage(true)
            })
          })
      }
    } else {
      onRequestClose?.(event)
    }
  }

  return (
    <>
      <MobileMessageModal isOpen={isOpen} panelStyle={{ width: 1024 }}>
        <Wrapper isInteractive={isInteractive} style={{ textAlign: 'left' }}>
          <DrawingSectionWithContext
            key={drawingID}
            index={index}
            section={{
              id: section.id,
              usage: 'shared',
              size: 'medium',
              include_pins: false,
              include_stickers: false,
              include_text: false,
              _owner: section._owner,
              _owner_id: section._owner_id,
              stickers: [],
              order: 0,
              section_title: title,
            }}
            drawingState={inputValue}
            dispatchInputAction={dispatchInputChange}
            panel={panel}
            property="drawing_sections"
            initialRemeasureDelay={1000}
            provideImageUriFetcher={receiveImageUriFetcher}
          />
          {isInteractive && (
            <Button
              children={savingImage ? 'Saving...' : errorSavingImage ? 'Retry Save...' : 'Close'}
              onClick={handleClose}
              marginTop="s"
              disabled={savingImage}
            />
          )}
        </Wrapper>
      </MobileMessageModal>
    </>
  )
}

export const BrainCellFacilitator: React.FC<Props> = ({ property, section, index }) => {
  const { state, dispatch, getBaseAction } = useSessionState()
  const sectionState = useSectionStateWithFallback('activity_brain_cell', section, getInitialState)

  const updateSectionState = (state: ActivityBrainCellState) => {
    dispatch({
      ...getBaseAction(),
      type: UPDATE_SECTION_STATE_DANGEROUS,
      property: 'activity_brain_cell',
      section_id: section.id,
      state,
    })
  }

  return (
    <>
      <ActivityInteractionIndicator type="shared" />
      <FacilitatorControls title="DECODER Brain Cell: Turn Controls">
        <P>
          Note: Use any club members' tabs to view the activity and to move the whole group through each D.E.C.O.D.E.R
        </P>
        <FacilitatorControlsTable>
          <thead>
            <tr>
              <th>Facilitator Only</th>
              {state.participants.map((participant, i) => (
                <th key={i}>{participant.profile.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '10px 0' }}>
                <RadioButton
                  value={sectionState.activeProfileUid === null}
                  onSelect={() =>
                    updateSectionState({
                      ...sectionState,
                      activeProfileUid: null,
                    })
                  }
                />
              </td>
              {state.participants.map((participant, i) => (
                <td key={i} style={{ padding: '10px' }}>
                  <RadioButton
                    value={sectionState.activeProfileUid === participant.profile.uid}
                    onSelect={() => updateSectionState({ ...sectionState, activeProfileUid: participant.profile.uid })}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </FacilitatorControlsTable>
      </FacilitatorControls>
    </>
  )
}

const Wrapper = styled.div<{ isInteractive?: boolean }>`
  pointer-events: ${p => (p.isInteractive ? 'auto' : 'none')};

  ${DrawHeaderRight} {
    opacity: ${p => (p.isInteractive ? '1' : '0')};
  }
`

const Container = styled(animated.div)`
  position: relative;
  border: 1px solid #cdd2e4;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;

  img {
    width: 100%;
    pointer-events: none;
  }
`

const ScrollAnchor = styled.div<{ y: number }>`
  position: absolute;
  top: ${p => `${p.y}%`};
`

interface InputWrapperProps {
  x: number
  y: number
  width: number
  height: number
  facilitatorOnly?: boolean
  isFacilitator?: boolean
}

const InputWrapper = styled.div<InputWrapperProps>`
  position: absolute;
  box-sizing: border-box;
  ${p =>
    p.facilitatorOnly
      ? css`
          pointer-events: ${p.isFacilitator ? 'auto' : 'none'};
        `
      : ''}

  ${p => css`
    left: ${p.x}%;
    top: ${p.y}%;
    width: ${p.width}%;
    height: ${p.height}%;
  `}

  textarea {
    ${fontLight}
    box-sizing: border-box;
    border: 1px solid #cdd2e4;
    padding: 2% 12px;
    border-radius: 10px;
    resize: none;
    width: 100%;
    height: 100%;
    font-size: 18px;
    line-height: 1;

    &::placeholder {
      color: #bdc3e0;
    }

    &:focus {
      outline: none;
      border-color: #0074d9;
      border-width: 2px;
    }
  }
`

const DrawAreaDiv = styled.div<{ x: number; y: number; width: number; height: number; thumbnail?: UploadResponse }>`
  position: absolute;
  box-sizing: border-box;
  border: 1px solid #cdd2e4;
  border-radius: 10px;
  ${p =>
    p.thumbnail && p.thumbnail.url
      ? css`
        background-image: url('${p.thumbnail.url}');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        `
      : ''}

  ${HighlightArea} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  ${p => css`
    left: ${p.x}%;
    top: ${p.y}%;
    width: ${p.width}%;
    height: ${p.height}%;
  `}

  ${Button} {
    cursor: pointer;
  }
`

const NavOverlay = styled.div<{ left: number; right: number; active: boolean }>`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #011a46;
  cursor: pointer;
  opacity: ${p => (p.active ? 0 : 0.6)};
  transition: 0.3s;

  ${p => css`
    left: ${p.left}%;
    right ${100 - p.right}%;
  `};

  &:hover {
    opacity: ${p => (p.active ? 0 : 0.3)};
  }
`

const RatioDiv = styled.div<{ height: number; width: number }>`
  position: absolute;
  top: 0;
  height: 0;
  width: 100%;
  margin-bottom: 30px;
  ${p => css`
    padding-top: ${(p.height / p.width) * 100}%;
  `}
`

const AbsoluteDiv = styled.div<{ reference: string; isFacilitator?: boolean; type: 'text' | 'radio' }>`
  position: absolute;
  display: flex;
  justify-content: center;

  ${p =>
    p.type === 'text'
      ? css`
          left: ${textPositions[p.reference].x}%;
          top: ${textPositions[p.reference].y}%;
          width: 100%;

          ${P} {
            max-width: 80%;
            text-align: center;
            width: 100%;
            opacity: ${textPositions[p.reference].facilitatorOnly ? (p.isFacilitator ? 1 : 0) : 1};
          }
        `
      : css`
          left: ${radioPositions[p.reference].x}%;
          top: ${radioPositions[p.reference].y}%;
          width: ${radioPositions[p.reference].width}%;

          label {
            padding: 0 10px;
          }
        `}
`

const PathImg = styled.img<{ reference: string }>`
  position: absolute;
  ${p => css`
    left: ${imagePositions[p.reference].x}%;
    top: ${imagePositions[p.reference].y}%;
    width: ${imagePositions[p.reference].width}% !important;
    height: ${imagePositions[p.reference].height}%;
  `}
`
