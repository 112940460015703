/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActivityScavengerHuntState } from '../../../types'
import {
  ActionTypes,
  ADD_ATTEMPT,
  NEXT_CLUE,
  PLACE_CARD,
  NEXT_SCREEN,
  UPDATE_FEEDBACK_TEXT,
  CORRECT_CARD_SELECTED,
  UPDATE_FLIPPED_CARD,
  SHOW_RESULT_MODAL,
  HIDE_RESULT_MODAL,
  UPDATE_ACTIVE_PARTICIPANT,
  UPDATE_SCENE_OBJECT_HOVER,
} from './actionTypes'
import { clues } from './clues'
import { cards } from './cards'

export const jumbledCodes = [
  // 0 1 2 3 4 5 6
  // d e c o d e r
  ['edderco', '1045623'],
  ['decreod', '0126534'],
  ['creodde', '2613045'],
  ['docerde', '0321645'],
  ['ceddeor', '2104536'],
  ['deecdor', '0152436'],
  ['roddece', '6304125'],
  ['orceded', '3621054'],
  ['cordeed', '2360154'],
  ['rodeced', '6301254'],
]

export function reducer(state: ActivityScavengerHuntState, action: ActionTypes): ActivityScavengerHuntState {
  if (!state) {
    console.log('========================================')
    console.log('CREATING FIRST TIME STATE FOR SCAVENGER HUNT')
    console.log('========================================')
    state = getInitialState()
  }
  switch (action.type) {
    case ADD_ATTEMPT: {
      const { type, ...attempt } = action
      return { ...state, attempts: [...state.attempts, { ...attempt }] }
    }
    case NEXT_CLUE: {
      const completed = state.clueIndex === clues.length - 1
      return {
        ...state,
        clueIndex: state.clueIndex + 1,
        moveStep: completed ? 'completed' : state.moveStep,
        correctCard: null,
        showCorrectModal: false,
      }
    }
    case PLACE_CARD: {
      const card = cards.find(({ id }) => id === action.cardId)
      const cardIndex = card ? cards.indexOf(card) : null
      const resetIndexes = Object.values(state.cardIndexes).reduce(
        (obj, ci, i) => (ci === cardIndex ? { ...obj, [i]: null } : obj),
        {} as ActivityScavengerHuntState['cardIndexes']
      )
      return { ...state, cardIndexes: { ...state.cardIndexes, ...resetIndexes, [action.index]: cardIndex } }
    }
    case NEXT_SCREEN:
      return { ...state, showScavenger: false, feedbackText: null }
    case UPDATE_FEEDBACK_TEXT:
      return { ...state, feedbackText: action.feedbackText }
    case CORRECT_CARD_SELECTED:
      return { ...state, showCorrectModal: true, correctCard: action.correctCard }
    case UPDATE_FLIPPED_CARD:
      return { ...state, flippedCard: action.flippedCard }
    case SHOW_RESULT_MODAL:
      return { ...state, [action.correct ? 'showCompleteModal' : 'showIncorrectModal']: true }
    case HIDE_RESULT_MODAL:
      return { ...state, [action.correct ? 'showCompleteModal' : 'showIncorrectModal']: false }
    case UPDATE_ACTIVE_PARTICIPANT:
      return { ...state, activeProfileUid: action.activeProfileUid }
    case UPDATE_SCENE_OBJECT_HOVER:
      return { ...state, hoveredSceneItem: action.sceneObject }

    default:
      return state
  }
}

const initialState: ActivityScavengerHuntState = {
  moveStep: 'find',
  clueIndex: 0,
  jumbledCode: jumbledCodes[0][0],
  jumbledCardIndexes: jumbledCodes[0][1].split('').map(n => parseInt(n)),
  cardIndexes: { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null },
  attempts: [],
  initialized: false,
  showScavenger: true,
  activeProfileUid: null,
  feedbackText: null,
  showCompleteModal: false,
  flippedCard: null,
  correctCard: null,
  showCorrectModal: false,
  showIncorrectModal: false,
  hoveredSceneItem: null,
}

export const getInitialState = (): ActivityScavengerHuntState => {
  const [jumbledCode, jumbledIndexes] = jumbledCodes[Math.floor(Math.random() * jumbledCodes.length)]
  const jumbledCardIndexes = jumbledIndexes.split('').map(n => parseInt(n))
  const state = { ...initialState, jumbledCode, jumbledCardIndexes }
  // if (window.location.hostname === 'localhost' && window.location.href.indexOf('/new') < 0) {
  //   state.clueIndex = clues.length - 1
  //   state.attempts = [...Array(clues.length - 1)].map((_, i) => ({
  //     clueIndex: i,
  //     objectKey: clues[i].solution,
  //     attempt: 0,
  //     correct: true,
  //   }))
  //   state.cardIndexes = { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 }
  //   state.cardIndexes[Math.floor(Math.random() * 7)] = null
  // }
  return state
}
