import React from 'react'
import { animated, useSpring } from 'react-spring'

interface Props {
  width?: string | number
  height?: string | number
  margin?: string
  color?: string
}

export const LoadingIndicator: React.FC<Props> = ({ width = 100, height = 100, margin, color = 'white' }) => {
  const [spring] = useSpring(() => ({
    from: { rotation: 0 },
    to: async (next: any) => {
      while (1) {
        await next({ rotation: 360 })
      }
    },
    reset: true,
    config: { duration: 1000 },
  }))

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{ margin }}>
      <defs>
        <filter id="f3" x="-10%" y="-10%" width="120%" height="120%">
          <feOffset result="offOut" in="SourceAlpha" dx="1" dy="2" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g filter="url(#f3)">
        <animated.circle
          cx="50"
          cy="50"
          fill="none"
          strokeLinecap="round"
          r="40"
          strokeWidth="8"
          stroke={color}
          strokeDasharray="62 62"
          transform={(spring as any).rotation.interpolate((v: number) => `rotate(${v} 50 50)`)}></animated.circle>
        <animated.circle
          cx="50"
          cy="50"
          fill="none"
          strokeLinecap="round"
          r="30"
          strokeWidth="8"
          stroke={color}
          strokeDasharray="55 55"
          strokeDashoffset="55"
          transform={(spring as any).rotation.interpolate((v: number) => `rotate(${-v} 50 50)`)}></animated.circle>
      </g>
    </svg>
  )
}
