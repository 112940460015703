import React, { useRef, CSSProperties, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDrop, DropTargetMonitor, XYCoord, useDrag } from 'react-dnd'
import { fontRegular } from 'fonts'

import { BoardGamePlayer as Player, DiceValue } from 'shared/session/types'
import { characters } from './characters'
import { Column, Row, Spacer, buttonFlash } from 'common/ui'
import { CharacterAvatar } from './CharacterAvatar'
import { Dice } from './Dice'
import { SVG } from 'common/SVG'

export const DRAG_TYPE_PLAYER_SLOT = 'PLAYER_SLOT'

interface PlayerSlotProps {
  index: number
  active?: boolean
  highlighted?: boolean
  finished?: boolean
  player: Player
  onEdit?: () => void
  style?: CSSProperties
  disabled?: boolean
  randomPosition: DiceValue
  showRandomDice: boolean
  allowDiceAnimation: boolean
  showDragHandle?: boolean
}

export const PlayerSlot: React.FC<PlayerSlotProps> = ({
  index,
  active,
  highlighted,
  finished,
  player,
  onEdit,
  style = {},
  disabled = false,
  randomPosition,
  showRandomDice,
  allowDiceAnimation,
  showDragHandle,
}) => {
  const character = characters.find(({ id }) => id === player.characterId)

  return (
    <PlayerSlotPill
      onClick={onEdit}
      style={style}
      active={active}
      highlighted={highlighted}
      finished={finished}
      disabled={disabled}>
      <Column flex="none" justifyContent="center">
        {index + 1}
      </Column>
      {showDragHandle && (
        <DragHandle>
          <SVG viewboxSize={24} size={24}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z" />
          </SVG>
        </DragHandle>
      )}
      <Row flex="1 1 auto" alignItems="center">
        {character && (
          <>
            <CharacterAvatar character={character} interactive={false} size="2.6em" customEmoji={player.customEmoji} />
            <Spacer size="s" />
          </>
        )}
        {player.name}
        <Spacer />
        {showRandomDice && (
          <DiceContainer>
            <Dice
              value={randomPosition}
              rolling={allowDiceAnimation}
              style={{ width: 30, height: 30 }}
              superDice={false}
            />
          </DiceContainer>
        )}
      </Row>
    </PlayerSlotPill>
  )
}

const PlayerSlotPill = styled(Row)<{ active?: boolean; highlighted?: boolean; finished?: boolean; disabled: boolean }>`
  border-radius: 50px;
  background-color: white;
  cursor: pointer;
  ${p => (p.active ? `box-shadow: inset 0 0 0 5px ${p.theme.buttonBorderTopColor};` : '')}
  opacity: ${p => (p.finished || p.disabled ? 0.3 : 1)};
  
  & > *:first-child {
    ${fontRegular}
    width: 2em;
    padding-left: 0.5em;
    text-align: center;
  }

  & > *:last-child {
    height: 100%;
    border-left: 2px solid #707070;
    padding-left: 0.5em;
  }

  &::before {
    content: ${p => (p.highlighted ? '""' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 5px ${p => p.theme.highlightColor};
    animation: ${buttonFlash} 0.5s linear infinite alternate;
  }
`

const DragHandle = styled.div`
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
`

const DiceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

interface DragItem {
  index: number
  id: string
  type: string
}

export const DraggablePlayerSlot: React.FC<PlayerSlotProps & {
  onMove: (dragIndex: number, hoverIndex: number) => void
}> = ({ onMove, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [, drop] = useDrop({
    accept: DRAG_TYPE_PLAYER_SLOT,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = props.index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current!.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      onMove(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: DRAG_TYPE_PLAYER_SLOT,
      id: `player${props.index}`,
      index: props.index,
      props,
      rect: ref.current?.getBoundingClientRect(),
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [allowClick, setAllowClick] = useState<boolean>(true)
  useEffect(() => {
    let toRef = 0
    if (isDragging) setAllowClick(false)
    else toRef = setTimeout(() => setAllowClick(true), 250)
    return () => clearTimeout(toRef)
  }, [isDragging])

  drag(drop(ref))

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <PlayerSlot
        {...props}
        onEdit={!allowClick ? undefined : props.onEdit}
        style={{ ...(props.style || {}), height: '100%', maxHeight: '4em' }}
      />
    </div>
  )
}
