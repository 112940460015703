import React from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { fontBlack } from 'fonts'

export type FeedbackScreen = 'correct' | 'incorrect' | 'tryagain' | null

const ResponseFeedback: React.FC<{ screen: FeedbackScreen }> = ({ screen }) => {
  return (
    <>
      <Feedback visible={screen === 'correct'}>
        <Correct>CORRECT</Correct>
      </Feedback>
      <Feedback visible={screen === 'tryagain'}>
        <Incorrect>TRY AGAIN</Incorrect>
      </Feedback>
      <Feedback visible={screen === 'incorrect'}>
        <Incorrect>INCORRECT</Incorrect>
      </Feedback>
    </>
  )
}

export default ResponseFeedback

function useAnimatedFeedback(visible: boolean) {
  const props = useSpring({
    config: { tension: 220, clamp: true },
    position: 'absolute',
    top: 110,
    left: 0,
    transform: visible ? 'translate3d(-35px, 0, 100px)' : 'translate3d(-260px, 0, 100px)',
    zIndex: 1,
  })
  return props
}

export const Feedback: React.FC<{ visible: boolean }> = ({ visible, children }) => {
  const style = useAnimatedFeedback(visible)
  return <animated.div style={style}>{children}</animated.div>
}

const FeedbackDiv = styled.div`
  ${fontBlack}
  border-radius: 10px;
  font-size: 14px;
  text-align: right;
  width: 200px;
  padding: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
`

export const Correct = styled(FeedbackDiv)`
  background: #39e055;
  border-bottom: 5px solid #009424;
  color: #001947;
`

export const Incorrect = styled(FeedbackDiv)`
  background: #9e005d;
  border-bottom: 5px solid #61005d;
  color: #fff;
`
