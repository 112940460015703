/* eslint-disable @typescript-eslint/no-unused-vars */
import format from 'date-fns/format'
import objectHash from 'object-hash'
import React, { ChangeEvent, CSSProperties, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { createPdf } from 'api'
import { useUserState } from 'app/UserState'
import { useSessionState } from 'session/SessionState'
import { nl2br } from 'utils/intersperse'
import { sortByKeyPath } from 'utils/sortUtils'

import { FlexModal } from 'app/FlexModal'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { Checkbox } from 'common/Checkbox'
import { Field } from 'common/Field'
import { AnchorButton } from 'common/LinkButton'
import { PdfModal, PdfStatus } from 'common/PdfModal'
import { RadioButtonGroup } from 'common/RadioButtonGroup'
import { Button, CollapseMargin, Column, H1, H2, H3, Hr, IconButton, P, Panel, Row, Spacer, TextInput } from 'common/ui'
import { ListHeading, TodoListItemRender } from 'dashboards/common/TodoListItemRender'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'
import { DragObjectWithType, useDrag, useDragLayer, useDrop, XYCoord } from 'react-dnd'
import {
  ActivityInteractionIndicator,
  ActivityInteractionIndicatorPanel,
} from 'session/common/ActivityInteractionIndicator'
import { HighlightableArea } from 'session/common/HighlightableArea'
import { RichTextContainer } from 'session/common/RichText'
import { InputContext, useInputInterface } from 'session/InputContext'
import { MentorChecklistPrintPayload, mentorChecklistTemplateUrl } from 'session/printable/MentorChecklistPrint'
import { SectionPropsBase, TodoListItem, TodoListSectionState } from 'shared/session/types'
import { orange, red, trainingPurple } from 'themes'
import { DroppableHighlightWrapper } from 'training/sections/dragComponents/DroppableArea'
import { mapToPrevNextShape } from 'utils/arrayUtils'
import { ToggleAllText } from './Grouped'
import { InfoTooltip } from 'common/Tooltip'
import { useFacilitatorState, useFocusedParticipantState } from 'session/hooks/useProfileState'
import { getSlideUid } from 'session/utils/slideUtils'

const itemTypes = ['h1', 'h2', 'li'] as const satisfies Readonly<TodoListItem['type'][]>

interface Props extends SectionPropsBase {
  property: 'todo_list_sections'
  isFacilitator?: boolean
  pastMode?: boolean
}

export const TodoListSection: React.FC<Props> = (props) => {
  const { state, sessionData, pastMode } = useSessionState()
  return (
    <InputContext.Provider
      value={{
        session_uid: state.sessionUid,
        participant_uid: 'shared',
        module_id: sessionData ? sessionData.module.id : 0,
        owner: 'todo_list',
        owner_id: null,
        name: 'todo_list_state',
      }}>
      <TodoListSectionApp {...props} {...{ pastMode }} />
    </InputContext.Provider>
  )
}

export const TodoListSectionApp: React.FC<Props> = ({ isFacilitator, pastMode, property, section, index }) => {
  const { title, todo_list_items: defaultItems } = section

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [dragWidth, setDragWidth] = useState<number>(0)
  const [editingItem, setEditingItem] = useState<TodoListItem | null>(null)
  const editingItemIsNew = useRef(false)

  const [pdfStatus, setPdfStatus] = useState<PdfStatus>('ready')
  const [pdfUrl, setPdfUrl] = useState('')

  const { accessToken } = useUserState()
  const { sessionData, presentationMode } = useSessionState()

  const participantState = useFocusedParticipantState()
  const facilitatorState = useFacilitatorState()
  const slideUid = getSlideUid(facilitatorState, participantState, presentationMode) || ''

  const [sectionState, updateSectionState] = useInputInterface<TodoListSectionState>({
    name: 'todo_list_state',
    defaultValue: {
      slideUid,
      items: defaultItems || [],
      showDownloadButton: true,
      pdfPayloadHash: null,
      pdfUrl: null,
    },
  })

  const items = useMemo(
    () => (sectionState.items || defaultItems || []).sort(sortByKeyPath('order')),
    [defaultItems, sectionState.items]
  )

  const headerUids = items.filter((item) => item.type === 'h1' || item.type === 'h2').map((item) => item.uid)
  const [collapsedHeaderItemUids, setCollapsedHeaderItemUids] = useState<string[]>(() => headerUids)
  const allCollapsed = headerUids.sort().join(',') === collapsedHeaderItemUids.sort().join(',')

  const toggleCollapseHeader = useCallback((uid: string) => {
    setCollapsedHeaderItemUids((prev) => (prev.includes(uid) ? prev.filter((id) => id !== uid) : [...prev, uid]))
  }, [])
  const collapsedItemUids = useMemo(() => {
    const uids: string[] = []
    let lastCollapsedItemType: TodoListItem['type'] | null = null
    for (const item of items) {
      const isCollapsedHeaderItem = collapsedHeaderItemUids.includes(item.uid)

      // we've not collapsed anything yet
      if (!lastCollapsedItemType) {
        // if item should be collapsed then set the lastCollapsedItemType accordingly
        if (isCollapsedHeaderItem) lastCollapsedItemType = item.type
      }
      // we've collapsed something previously
      else {
        const currentItemTypeIndex = itemTypes.indexOf(item.type)
        const lastCollapsedItemTypeIndex = itemTypes.indexOf(lastCollapsedItemType)

        // the type of the last collapsed item is the same as the current item
        if (currentItemTypeIndex === lastCollapsedItemTypeIndex) {
          // if the current item isn't meant to be collapsed then reset the lastCollapsedItemType
          if (!isCollapsedHeaderItem) lastCollapsedItemType = null
        }
        // the current item takes precedence over the last collapsed item ie going from h2 to h1
        else if (currentItemTypeIndex < lastCollapsedItemTypeIndex) {
          // if the current item isn't meant to be collapsed then reset the lastCollapsedItemType
          // otherwise set it to the current item type as it takes precedence
          lastCollapsedItemType = isCollapsedHeaderItem ? item.type : null
        }
        // the last collapsed item takes precedence over the current item ie going from h1 to h2, h1 to li, h2 to li
        else if (currentItemTypeIndex > lastCollapsedItemTypeIndex) {
          // add it to collapsed items
          uids.push(item.uid)
        }
      }
    }
    return uids
  }, [items, collapsedHeaderItemUids])

  const updateItems = useCallback(
    (items: TodoListItem[]) =>
      updateSectionState({
        slideUid,
        items,
        showDownloadButton: sectionState.showDownloadButton,
        pdfPayloadHash: sectionState.pdfPayloadHash,
        pdfUrl: sectionState.pdfUrl,
      }),
    [updateSectionState, sectionState.showDownloadButton, sectionState.pdfPayloadHash, sectionState.pdfUrl, slideUid]
  )

  const handleReset = useCallback(() => {
    updateItems(defaultItems || [])
  }, [updateItems, defaultItems])

  const updatePdfUrl = useCallback(
    (pdfUrl: string) => {
      setPdfUrl(pdfUrl)
      updateSectionState({
        slideUid,
        pdfUrl,
        items: sectionState.items,
        showDownloadButton: sectionState.showDownloadButton,
        pdfPayloadHash: sectionState.pdfPayloadHash,
      })
    },
    [sectionState.items, sectionState.pdfPayloadHash, sectionState.showDownloadButton, updateSectionState, slideUid]
  )

  const moduleTitle =
    (sessionData &&
      (sessionData.module.public_title || (sessionData.module_code && getModuleTitle(sessionData.module_code)))) ||
    ''
  const pdfPayload = useMemo<MentorChecklistPrintPayload>(
    () => ({
      module: moduleTitle,
      date: format(new Date(), 'd MMM yyyy'),
      items,
    }),
    [items, moduleTitle]
  )
  const pdfPayloadHash = useMemo(() => objectHash(pdfPayload), [pdfPayload])

  const handleReorder = useCallback(
    (droppedItem: TodoListItem, dropIndex: number) => {
      const originalIndex = items.reduce((acc, item, index) => {
        if (item.uid === droppedItem.uid) return index
        return acc
      }, 0)
      const newItems = [...items]
      newItems.splice(originalIndex, 1)
      newItems.splice(dropIndex, 0, droppedItem)
      updateItems(newItems.map((options, order) => ({ ...options, order })))
    },
    [items, updateItems]
  )

  const handleItemChange = useCallback(
    (updatedItem: TodoListItem, updateFormValue = false) => {
      if (editingItem && editingItem.uid === updatedItem.uid) setEditingItem(updatedItem)
    },
    [editingItem]
  )

  const handleItemSave = useCallback(() => {
    if (!editingItem) return
    updateItems(items.map((item) => (item.uid === editingItem.uid ? editingItem : item)))
  }, [items, updateItems, editingItem])

  const handleItemRemove = useCallback(
    (item: TodoListItem) =>
      updateItems(items.filter((i) => i.uid !== item.uid).map((item, order) => ({ ...item, order }))),
    [items, updateItems]
  )

  const handleNewItem = useCallback(
    (index?: number, type: TodoListItem['type'] = 'li') => {
      const newItem: TodoListItem = {
        id: 0,
        uid: `todo-${Date.now()}`,
        order: index === undefined ? items.length : index + 1,
        text: '',
        type,
      }
      updateItems(
        index === undefined
          ? [...items, newItem]
          : [
              ...items.slice(0, index + 1),
              newItem,
              ...items.slice(index + 1).map((item) => ({ ...item, order: item.order + 1 })),
            ]
      )
      editingItemIsNew.current = true
      setEditingItem(newItem)
    },
    [items, updateItems]
  )

  const handleItemEditClose = useCallback(() => {
    if (editingItemIsNew.current && editingItem && (editingItem.text || '').trim() === '') {
      handleItemRemove(editingItem)
    }
    setEditingItem(null)
    editingItemIsNew.current = false
  }, [editingItem, handleItemRemove])

  const handleItemEdit = useCallback((item: TodoListItem) => {
    editingItemIsNew.current = false
    setEditingItem(item)
  }, [])

  const toggleShowDownloadButton = useCallback(() => {
    updateSectionState({
      showDownloadButton: !sectionState.showDownloadButton,
      pdfPayloadHash: sectionState.pdfPayloadHash,
      pdfUrl: sectionState.pdfUrl,
      items,
      slideUid,
    })
  }, [
    updateSectionState,
    sectionState.showDownloadButton,
    sectionState.pdfPayloadHash,
    sectionState.pdfUrl,
    items,
    slideUid,
  ])

  const handlePdfDownload = useCallback(() => {
    if (sectionState.pdfUrl) {
      // this if statement shouldn't get hit because we conditionally render a different download button if sessionState.pdfUrl exists
      // but just in case...
      ReactDOM.unstable_batchedUpdates(() => {
        setPdfStatus('success')
        updatePdfUrl(sectionState.pdfUrl!)
      })
    } else {
      setPdfStatus('busy')
      createPdf<MentorChecklistPrintPayload>(mentorChecklistTemplateUrl, pdfPayload, accessToken, {
        footerTemplate: 'footer-mono',
      })
        .then((pdf) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setPdfStatus('success')
            updatePdfUrl(pdf.url)
          })
        })
        .catch(() => setPdfStatus('error'))
    }
  }, [accessToken, pdfPayload, sectionState.pdfUrl, updatePdfUrl])

  useEffect(() => {
    if (containerRef && containerRef.current) setDragWidth(containerRef.current.clientWidth)
  }, [])

  useEffect(() => {
    // only need 1 person to be updating state if the hash payload hash changes, so facilitator(s) it is
    if (!isFacilitator) return
    if (pdfPayloadHash !== sectionState.pdfPayloadHash) {
      updateSectionState({
        slideUid,
        items,
        showDownloadButton: sectionState.showDownloadButton,
        pdfPayloadHash,
        pdfUrl: null,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFacilitator, pdfPayloadHash, sectionState.pdfPayloadHash, updateSectionState, slideUid])

  return (
    <>
      {title && (
        <RichTextContainer>
          <h2>{title}</h2>
        </RichTextContainer>
      )}
      {(!isFacilitator || pastMode) && (
        <>
          <ToggleAllText onClick={() => setCollapsedHeaderItemUids(allCollapsed ? [] : headerUids)}>
            {allCollapsed ? 'Show All' : 'Hide All'}
          </ToggleAllText>
          {items.map(mapToPrevNextShape).map((props) =>
            collapsedItemUids.includes(props.item.uid) ? null : (
              <Fragment key={props.item.uid}>
                <HighlightableArea id={`todo-${props.item.uid}`}>
                  <TodoListItemRender
                    {...{ ...props, index: props.item.order, items }}
                    onCollapseToggle={toggleCollapseHeader}
                    collapsed={collapsedHeaderItemUids.includes(props.item.uid)}
                  />
                </HighlightableArea>
              </Fragment>
            )
          )}
        </>
      )}
      {isFacilitator && !pastMode && (
        <>
          <CustomDragLayer size={!!dragWidth ? dragWidth : 300} />
          <div style={{ position: 'relative', width: '100%' }} ref={containerRef}>
            {items.map(mapToPrevNextShape).map(({ item, prevItem, nextItem }, index) => (
              <Fragment key={item.uid}>
                <div style={{ position: 'relative' }}>
                  <DroppableArea
                    style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
                    type="REORDER_ITEM"
                    onDrop={(dragItem) => handleReorder(dragItem.item, index)}
                  />
                  <DraggableOption type="REORDER_ITEM" item={item} disabled={false}>
                    <HighlightableArea id={`todo-${item.uid}`}>
                      <ReorderTodoListItemCell
                        item={item}
                        onEdit={handleItemEdit}
                        onChange={handleItemChange}
                        onRemove={handleItemRemove}
                      />
                    </HighlightableArea>
                  </DraggableOption>
                </div>
                {(!nextItem || item.type === 'h1' || nextItem.type !== 'li') && (
                  <Row justifyContent="center" marginBottom="s">
                    <Button
                      theme="purple"
                      size="xs"
                      circle
                      onClick={() => handleNewItem(index, item.type === 'h1' ? 'h2' : 'li')}
                      children="+"
                    />
                  </Row>
                )}
                {item.type !== 'h2' && (!nextItem || nextItem.type !== item.type) && (
                  <Hr margin="s" style={{ borderTopWidth: 2 }} />
                )}
              </Fragment>
            ))}
            {items.length === 0 && (
              <Row justifyContent="center" marginBottom="s">
                <Button theme="purple" size="xs" circle onClick={() => handleNewItem(0, 'h1')} children="+" />
              </Row>
            )}
          </div>

          {editingItem && (
            <TodoItemEditModal
              item={editingItem}
              onClose={handleItemEditClose}
              onSave={handleItemSave}
              onChange={handleItemChange}
            />
          )}
        </>
      )}

      <Spacer size="xl" />
      <Row justifyContent="center">
        {isFacilitator && !pastMode && (
          <>
            <Row alignSelf="flex-start" alignItems="center">
              <Button
                size="m"
                theme="orange"
                children="Reset List"
                onClick={() =>
                  window.confirm(
                    'Do you want to start again with the original To Do List? This will remove your edits.'
                  ) && handleReset()
                }
              />
              <InfoTooltip
                iconBackground={orange.buttonBackgroundTopColor}
                svgStyle={{ borderColor: orange.buttonFocusBackgroundTopColor }}
                content="Remove your edits and return to the original To Do List for this Parent Group Meeting."
              />
              <Spacer size="xs" />
            </Row>
            <Spacer />
          </>
        )}
        {(isFacilitator || sectionState.showDownloadButton || pastMode) && (
          <Column>
            {sectionState.pdfUrl ? (
              <AnchorButton
                size="m"
                theme={!isFacilitator ? 'parentGreen' : 'purple'}
                children={isFacilitator ? 'Facilitator Download' : 'Download'}
                href={sectionState.pdfUrl}
                target="_blank"
                download={`Mentor Checklist - ${moduleTitle} (${pdfPayload.date}).pdf`}
              />
            ) : (
              <Button size="m" theme="purple" children="Download" onClick={handlePdfDownload} />
            )}
            {isFacilitator && !pastMode && (
              <Checkbox size="s" checked={sectionState.showDownloadButton} onChange={toggleShowDownloadButton}>
                <P>Show download button to participants</P>
              </Checkbox>
            )}
          </Column>
        )}
      </Row>
      <PdfModal
        status={pdfStatus}
        url={pdfUrl}
        onClose={() => setPdfStatus('ready')}
        onCancel={() => setPdfStatus('ready')}
        text1="Generating PDF"
      />
    </>
  )
}

export const TodoListSectionFacilitator: React.FC<Props> = (props) => {
  return (
    <>
      <ActivityInteractionIndicator type="shared" />
      <ActivityInteractionIndicatorPanel
        style={{ backgroundColor: 'transparent', padding: 15, marginTop: '1rem', marginBottom: '1.5rem' }}>
        <CollapseMargin>
          <H2 style={{ color: trainingPurple.appBackgroundBottomColor }}>To Do List Customisation</H2>
          <P style={{ margin: '0.5em 0' }}>The standard Parent Group Meeting “To Do List” is provided below.</P>
          <P style={{ margin: '0.5em 0' }}>
            Before sharing this activity with parents, you may choose to customise this list for your group. Please use
            the “edit” and “+”/add and “X”/delete buttons. You can also add additional headings and drag items to
            re-order the list.
          </P>
          <P style={{ marginTop: '0.5em' }}>
            <ul style={{ margin: 0, paddingLeft: '1.5em' }}>
              <li>All group participants will see the same customised To Do List.</li>
              <li>Select a caregiver tab to see their view of the list with show/hide options.</li>
              <li>
                Caregivers can access a downloadable copy of the To Do List from their Mentor Portal after the live
                meeting.
              </li>
              <li>
                Allow the list to be downloaded (to save, print, or share) <strong>during</strong> a live Parent Group
                meeting, by checking the “show download button to participants” box below.”
              </li>
            </ul>
          </P>
        </CollapseMargin>
      </ActivityInteractionIndicatorPanel>
      <Spacer />
      <TodoListSection {...props} isFacilitator />
    </>
  )
}

type DragItemType = DragObjectWithType & { item: TodoListItem }

export const DraggableOption: React.FC<{
  item: TodoListItem
  disabled?: boolean
  type: string
}> = ({ item, disabled, type, children }) => {
  const measuringRef = useRef<HTMLDivElement>(null)
  const [{ isDragging }, drag] = useDrag({
    item: { type: type, from: origin, item } as DragItemType,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    canDrag: (monitor) => !disabled,
  })
  return (
    <div ref={drag} style={{ opacity: isDragging || disabled ? 0.5 : 1, pointerEvents: isDragging ? 'none' : 'auto' }}>
      <div ref={measuringRef}>{children}</div>
    </div>
  )
}

export const ReorderTodoListItemCell: React.FC<{
  item: TodoListItem
  onEdit: (item: TodoListItem) => void
  onChange: (updatedItem: TodoListItem) => void
  onRemove: (item: TodoListItem) => void
}> = ({ item, onChange, onEdit, onRemove }) => (
  <Cell alignItems="center" onDoubleClick={() => onEdit(item)}>
    <DragHandle>
      <img
        src={require('session/assets/icon-drag-vertical.svg').default}
        width={24}
        height={24}
        alt="Drag me to reorder items"
      />
    </DragHandle>
    {item.type === 'h1' && <H1>{item.text}</H1>}
    {item.type === 'h2' && <ListHeading>{item.text}</ListHeading>}
    {item.type === 'li' && (
      <Row flex="1 1 auto">
        <Checkbox disabled size="xs" checked={false} onChange={() => {}} />
        <Spacer />
        <CollapseMargin>
          <P style={{ paddingTop: 4, maxWidth: 'fit-content' }}>{nl2br(item.text)}</P>
        </CollapseMargin>
      </Row>
    )}
    <Spacer size="l" flex />
    <Button
      size="xs"
      theme="purple"
      onClick={() => onEdit(item)}
      onMouseDown={(e) => e.stopPropagation()}
      children="Edit"
    />
    <Spacer />
    <div
      style={{
        cursor: 'pointer',
        userSelect: 'none',
        padding: '0px 5px',
        fontSize: 24,
        color: red.buttonBackgroundTopColor,
      }}
      onClick={() => window.confirm('Are you sure you want to remove this item?') && onRemove(item)}
      onMouseDown={(e) => e.stopPropagation()}
      children="&#10005;"
    />
  </Cell>
)

const Cell = styled(Row)`
  background: #fff;
  border: 1px solid #bdc3e0;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: grab;
`

const DragHandle = styled.div`
  flex: none;
  position: relative;
  margin-right: 15px;
  display: flex;
  align-items: center;
  /*
  width: 20px;
  height: 10px;
  border-top: 2px solid #bdc3e0;
  border-bottom: 2px solid #bdc3e0;
  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    margin-top: -1px;
    width: 100%;
    height: 2px;
    background-color: #bdc3e0;
  }
  */
`

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  cursor: 'move',
}

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties {
  if (!initialOffset || !currentOffset) return { display: 'none' }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return { transform, WebkitTransform: transform }
}

export const CustomDragLayer: React.FC<{
  size: number
}> = ({ size }) => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem() as DragItemType,
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))
  if (!isDragging) return null
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <div style={{ width: size, pointerEvents: 'none' }}>
          <ReorderTodoListItemCell item={item.item} onEdit={() => {}} onChange={() => {}} onRemove={() => {}} />
        </div>
      </div>
    </div>
  )
}

export type DroppableAreaProps = {
  style?: CSSProperties
  type: string
  onDrop: (dragItem: DragItemType) => void
}

export const DroppableArea: React.FC<DroppableAreaProps> = ({ onDrop, type, children, style }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ itemIsOver, itemBeingDragged }, drop] = useDrop({
    accept: type,
    drop: (item, monitor) => {
      onDrop(item as DragItemType)
    },
    collect: (monitor) => ({
      itemIsOver: monitor.isOver(),
      itemBeingDragged: monitor.canDrop(),
    }),
  })
  const [, drag] = useDrag({
    canDrag: () => false,
    item: { type: type } as DragItemType,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  })
  drag(drop(ref))

  return (
    <div style={{ ...style, width: '100%' }} ref={ref}>
      <DroppableHighlightWrapper
        ref={drop}
        style={itemBeingDragged ? { zIndex: 99 } : {}}
        {...{ itemIsOver, itemBeingDragged }}>
        {children}
      </DroppableHighlightWrapper>
    </div>
  )
}

interface TodoItemEditModalProps {
  item: TodoListItem
  onClose: () => void
  onChange: (slide: TodoListItem) => void
  onSave: () => void
}

const TodoItemEditModal = ({ onClose, onChange, onSave, item }: TodoItemEditModalProps) => {
  const isNew = useRef(!item.text)
  const autoFocused = useRef(false)
  const autoFocusRef = useCallback(<Elem extends HTMLInputElement | HTMLTextAreaElement>(elem: Elem | null) => {
    if (elem && !autoFocused.current) {
      elem.focus()
      elem.setSelectionRange((elem.value || '').length, (elem.value || '').length)
      autoFocused.current = true
    }
  }, [])
  return (
    <FlexModal
      isOpen
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      style={{ overlay: { background: 'rgba(1, 26, 70, .8)' } }}>
      <ModalContainer>
        <Panel padding="m">
          <H2
            style={{ color: '#2EADF0', marginBottom: 10 }}
            children={`${isNew.current ? 'Create' : 'Edit'} to do list item`}
          />
          <Field label="Item Type">
            <RadioButtonGroup<TodoListItem['type']>
              size="xs"
              value={item.type}
              onChange={(type) => onChange({ ...item, type })}
              options={[
                { label: 'Section', value: 'h1' },
                { label: 'Heading', value: 'h2' },
                { label: 'To Do Item', value: 'li' },
              ]}
            />
          </Field>
          <Spacer />
          {item.type === 'h1' && (
            <Field label="Section (optional)">
              <TextInput
                ref={autoFocusRef}
                value={item.text}
                placeholder="Type section heading..."
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ ...item, text: e.target.value || '' })}
                style={{ fontSize: 28 }}
              />
            </Field>
          )}
          {item.type === 'h2' && (
            <Field label="Heading">
              <TextInput
                ref={autoFocusRef}
                value={item.text}
                placeholder="Type heading..."
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ ...item, text: e.target.value || '' })}
                style={{ fontSize: 20 }}
              />
            </Field>
          )}
          {item.type === 'li' && (
            <Field label="To do item with checkbox">
              <AutosizeTextareaInput
                ref={autoFocusRef}
                value={item.text}
                placeholder="Type to do..."
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange({ ...item, text: e.target.value || '' })}
              />
            </Field>
          )}
        </Panel>
        <Row>
          <Button
            size="m"
            theme="purple"
            onClick={() => {
              onSave()
              onClose()
            }}
            children="Done"
            marginTop={10}
          />
        </Row>
        <CloseButton onClick={onClose} />
      </ModalContainer>
    </FlexModal>
  )
}

const CloseButton: React.FC<{ onClick?: () => void; disabled?: boolean }> = ({ onClick, disabled }) => {
  return (
    <CloseButtonDiv onClick={onClick}>
      <IconButton theme="purple" children="×" disabled={disabled} />
    </CloseButtonDiv>
  )
}

const CloseButtonDiv = styled.div`
  & ${IconButton} {
    margin-left: 10px;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    pointer-events: none;
    &::before,
    &::after {
      content: none;
    }
  }
`
const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;

  & ${CloseButtonDiv} {
    position: absolute;
    top: -50px;
    right: 0;
  }
`
