import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { DASHBOARD_HEADER_HEIGHT } from './LayoutUI'
import { Link } from 'react-router-dom'
import { Row, Column, H1, Spacer, BaseButton, paddingLogic, Hr } from 'common/ui'
import { BaseNavRouteProps } from 'dashboards/types'
import { Footer } from './Footer'

interface Props extends BaseNavRouteProps {
  title?: ReactNode
  sideRoute?: string
  sideLabel?: string
  sideFragment?: ReactNode
  hideFooter?: boolean
  hideTitle?: boolean
  showDivider?: boolean
  useThemeBackground?: boolean
}

export const Page: React.FC<Props> = ({
  children,
  title,
  route,
  sideRoute,
  sideLabel = 'Back',
  sideFragment,
  hideFooter,
  hideTitle,
  showDivider,
  useThemeBackground,
}) => {
  return (
    <PageWrapper padding="m" useThemeBackground={useThemeBackground}>
      <Row>
        {!hideTitle && (
          <Column flex="1 1 auto">
            <H1 children={title || route.title} />
          </Column>
        )}
        {sideRoute && (
          <Column justifyContent="center" style={{ height: 30 }}>
            <Link to={sideRoute} style={{ color: '#011A46' }} children={sideLabel} />
          </Column>
        )}
        {sideFragment}
      </Row>
      {showDivider && <Hr margin="m" style={{ marginBottom: 0 }} />}
      <Spacer size="m" />
      <div>{children}</div>
      <Spacer flex="1 1 auto" />
      {!hideFooter && <Footer />}
    </PageWrapper>
  )
}

export const PageWrapper = styled(Column)<{ useThemeBackground?: boolean }>`
  background-color: ${p => (p.useThemeBackground ? p.theme.buttonBorderTopColor : 'transparent')};
  box-sizing: border-box; 
  min-height: calc(100vh - ${p => DASHBOARD_HEADER_HEIGHT}px);
  ${paddingLogic}
  & > ${BaseButton} {
      display: inline-block;
  }
  & ${H1} {
    color: #6737B5;
  }
`
