import React, { useState, useEffect } from 'react'
import GrapheneSplitter from 'grapheme-splitter'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { IconButton, Button, H2, Column, Row, Padding, Spacer } from 'common/ui'
import { Emoji } from './Emoji'

/**
 * Originally used this solution but it didn't work properly
 * https://stackoverflow.com/a/24531752
 * .split(/([\uD800-\uDBFF][\uDC00-\uDFFF])/).filter(_ => _)
 */

const splitter = new GrapheneSplitter()

interface Props {
  isOpen: boolean
  value: string | null
  multiple?: boolean
  onChange: (value: string) => void
  onClose: () => void
  symbols: string
  disabledSymbols?: string[]
  title?: string
}

export const SymbolSelectModal: React.FC<Props> = ({
  isOpen: _isOpen,
  value,
  title,
  multiple,
  onChange,
  onClose,
  symbols,
  disabledSymbols = [],
}) => {
  const graphenes = splitter.splitGraphemes(symbols)
  const [isOpen, setIsOpen] = useState<boolean>(_isOpen)
  useEffect(() => setIsOpen(_isOpen), [_isOpen])
  const values = multiple ? splitter.splitGraphemes(value || '') : []
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }
  const handleChange = (newValue: string) => {
    if (multiple) {
      if (values.indexOf(newValue) >= 0) onChange(values.filter(val => val !== newValue).join(''))
      else onChange((value || '') + newValue)
    } else {
      onChange(value === newValue ? '' : newValue)
    }
  }
  return (
    <MobileModalPanel zIndex={1300} isOpen={isOpen} onRequestClose={delayedOnClose} panelName="SymbolSelectModal">
      <Column flex="1 1 auto" style={{ height: '100%', overflow: 'hidden', maxWidth: 1024 }}>
        {title ? (
          <Row flex="none" paddingTop={25} paddingBottom={20}>
            <H2 style={{ width: '100%', textAlign: 'center' }}>{title}</H2>
          </Row>
        ) : (
          <Spacer size="m" flex="none" />
        )}
        <Row flex="1 1 auto" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
          <Padding size="s" style={{ paddingTop: 0 }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {graphenes.map((graphene, i) => (
                <IconButton
                  key={i}
                  margin={2}
                  style={{ width: 40, height: 42, fontSize: 20, fontFamily: 'sans-serif', cursor: 'pointer' }}
                  theme={
                    (multiple && values.indexOf(graphene) >= 0) || (!multiple && graphene === value) ? 'gray' : 'white'
                  }
                  disabled={disabledSymbols.indexOf(graphene) >= 0}
                  onClick={() => handleChange(graphene)}
                  onDoubleClick={() => {
                    onChange(graphene)
                    onClose()
                  }}>
                  <Emoji style={{ verticalAlign: 'auto' }} children={graphene} />
                </IconButton>
              ))}
            </div>
          </Padding>
          <br />
        </Row>
        <Row flex="none" style={{ borderTop: '1px solid #ABB4DB' }}>
          <Padding size="s" style={{ justifyContent: 'center' }}>
            <Button size="s" onClick={delayedOnClose} theme="white">
              Close
            </Button>
          </Padding>
        </Row>
      </Column>
    </MobileModalPanel>
  )
}
