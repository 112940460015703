import React, { useState } from 'react'
import styled from 'styled-components'
import { NavRouteProps } from 'dashboards/types'
import { P, Hr } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { Accordion, AccordionInner, AccordionTitle, AccordionRight, AccordionBody } from 'session/common/Accordion'
import { CircleButton } from 'session/common/CircleButton'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { SpinnerWithLabel } from 'common/Spinner'
import { RichText, RichTextContainer } from 'session/common/RichText'
import { fontRegular } from 'fonts'
import sortBy from 'lodash/sortBy'

interface FaqShape {
  title: string
  content: string
  order: number
}

export const FAQs: React.FC<NavRouteProps> = ({ route }) => {
  const [expandedFaqIds, setExpandedFaqIds] = useState<number[]>([])
  const [faqs, { loading }] = useEndpoint<FaqShape[]>(`/api/v1/faqs`, [])
  return (
    <Page route={route}>
      {loading ? (
        <SpinnerWithLabel color="#925BED" label="One moment please..." />
      ) : faqs ? (
        <Accordions>
          <Hr margin="xs" />
          {sortBy(faqs, 'order').map((faq, i) => {
            const expanded = expandedFaqIds.indexOf(i) >= 0
            return (
              <Faq
                key={i}
                expanded={expanded}
                title={faq.title}
                toggleExpanded={() =>
                  setExpandedFaqIds(expanded ? expandedFaqIds.filter(id => id !== i) : [...expandedFaqIds, i])
                }>
                <RichText text={faq.content} />
              </Faq>
            )
          })}
        </Accordions>
      ) : (
        <P>Sorry there appears to be no FAQs</P>
      )}
    </Page>
  )
}

const Faq: React.FC<{ expanded?: boolean; toggleExpanded: () => void; title: string }> = ({
  expanded,
  toggleExpanded,
  title,
  children,
}) => {
  return (
    <Accordion>
      <AccordionInner>
        <AccordionTitle style={{ fontSize: '1.2rem' }}>{title}</AccordionTitle>
        <AccordionRight onClick={toggleExpanded}>
          <ShowText>{expanded ? 'HIDE' : 'SHOW'}</ShowText>
          <CircleButton size="s" children={expanded ? '▲' : '▼'} />
        </AccordionRight>
      </AccordionInner>
      <AccordionBody expanded={!!expanded}>{children}</AccordionBody>
      <Hr margin="xs" />
    </Accordion>
  )
}

const Accordions = styled.div`
  ${RichTextContainer} {
    ${fontRegular};
    padding: 15px 0;

    p {
      ${fontRegular};
    }

    ol,
    ul {
      padding-left: 30px;
      li {
        margin-bottom: 10px;
        padding-left: 0;

        &::before {
          display: none;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        li {
          margin-top: 10px;
        }
      }
    }

    ol {
      list-style: decimal;
    }

    ul {
      list-style: disc;
    }
  }
`

export const ShowText = styled.div`
  color: #011a46;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
`
