import { BaseAction } from 'shared/types'
import { ETelligenceState as State } from 'shared/e-telligence/types'
import {
  UPDATE_STATE,
  ADD_RELAXATION_GADGET,
  REMOVE_RELAXATION_GADGET,
  ADD_UPDATE_CUSTOM_RELAXATION_GADGET,
  ADD_UPDATE_EMOTIONOMETER,
  ADD_EMOTIONOMETER_STICKER,
  REMOVE_EMOTIONOMETER_STICKER,
  ADD_UPDATE_CUSTOM_STICKER,
  ADD_CODE_CARD,
  REMOVE_CODE_CARD,
  ADD_UPDATE_CUSTOM_CODE_CARD,
} from 'shared/e-telligence/actionTypes'

/**
 * Action validation functions
 */

export const noValidate = (action: BaseAction, state: State) => true

/**
 * Action type validation function map
 */

export const actionTypeValidators = {
  [UPDATE_STATE]: noValidate,
  [ADD_RELAXATION_GADGET]: noValidate,
  [REMOVE_RELAXATION_GADGET]: noValidate,
  [ADD_UPDATE_CUSTOM_RELAXATION_GADGET]: noValidate,
  [ADD_UPDATE_EMOTIONOMETER]: noValidate,
  [ADD_EMOTIONOMETER_STICKER]: noValidate,
  [REMOVE_EMOTIONOMETER_STICKER]: noValidate,
  [ADD_UPDATE_CUSTOM_STICKER]: noValidate,
  [ADD_CODE_CARD]: noValidate,
  [REMOVE_CODE_CARD]: noValidate,
  [ADD_UPDATE_CUSTOM_CODE_CARD]: noValidate,
}
