import React, { CSSProperties } from 'react'
import { supportsEmojis } from 'utils/deviceUtils'
import { getEmojiId } from 'utils/emojiUtils'

export const Emoji: React.FC<{ style?: CSSProperties; verticalAlign?: CSSProperties['verticalAlign'] }> = ({
  children,
  style = {},
  verticalAlign = 'bottom',
}) => {
  if (!children) return null
  if (typeof children !== 'string') return null
  if (supportsEmojis()) return <span style={{ fontFamily: 'Segoe UI Emoji' }}>{children}</span>
  const emojiId = getEmojiId(children)
  if (!emojiId) return <span style={{ fontFamily: 'Segoe UI Emoji' }}>{children}</span>
  const asset = require(`./assets/emoji/${emojiId}.png`)
  return (
    <span
      style={{
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        width: '1em',
        height: '1em',
        verticalAlign,
        display: 'inline-block',
        backgroundImage: emojiId ? `url('${asset}')` : undefined,
        ...style,
      }}
    />
  )
}
