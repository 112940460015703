import React from 'react'
import { ListSection as ListSectionType, SectionPropsBase } from 'shared/session/types'
import { SectionTitle } from 'session/common/SectionTitle'
import styled from 'styled-components'
import { fontLight } from 'fonts'

interface Props extends SectionPropsBase {
  property: 'list_sections'
}

export const ListSection: React.FC<Props> = _props => {
  const { panel, ...props } = _props
  return <GenericListSection {...props} />
}

export const GenericListSection: React.FC<Omit<Props, 'panel'>> = props => {
  return <BasicListSection {...props.section} />
}

export const BasicListSection: React.FC<Pick<ListSectionType, 'title' | 'type' | 'display' | 'items'>> = ({
  title,
  type,
  display,
  items,
}) => {
  const ListType: keyof JSX.IntrinsicElements = type === 'dot' ? 'ul' : 'ol'
  return (
    <>
      {title && <SectionTitle children={title} />}
      {display === 'block' ? (
        <ListContainer>
          <ListType role="list" className={`list-${type}`}>
            {(items || []).map(({ order, text }, idx) => (
              <li key={idx} children={text} />
            ))}
          </ListType>
        </ListContainer>
      ) : (
        <InlineListContainer>
          {(items || []).map(({ order, text }) => (
            <InlineListItem key={order}>
              <InlineListItemInner>{text}</InlineListItemInner>
            </InlineListItem>
          ))}
        </InlineListContainer>
      )}
    </>
  )
}

export const ListSectionFacilitator = ListSection

const InlineListContainer = styled.div`
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
`
const InlineListItem = styled.div`
  margin-bottom: 12px;
  flex: 0 25%;
`
const InlineListItemInner = styled.div`
  ${fontLight}
  filter: drop-shadow(0px 1px 0px #ffffff);
  margin-right: 6px;
  margin-left: 6px;
  padding: 12px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd2e4;
  border-radius: 10px;
  text-align: center;
`

export const ListContainer = styled.div`
  & > ul,
  & > ol {
    ${fontLight}
    margin-top: 0px;
    margin-bottom: 0px;

    & > li {
      ${fontLight};
      color: #011a46;
      position: relative;
      margin-bottom: 0.5em;
    }
  }
  & > ol {
    padding-left: 25px;

    &.list-alphabetic {
      list-style-type: upper-latin;
    }

    &.list-checklist li {
      position: relative;

      &::before {
        content: '';
        border-radius: 3px;
        display: block;
        position: absolute;
        left: -25px;
        top: 7px;
        width: 20px;
        height: 15px;
        border: 1px solid #c4c4c4;
        background: none;
      }

      &::after {
        display: none !important;
      }

      &::marker {
        color: transparent;
      }
    }

    & > li {
      padding-left: 10px;

      &::marker {
        color: #fff;
        font-size: 18px;
      }
      &::after {
        content: '';
        background-color: #2eadf0;
        border-radius: 50%;
        display: block;
        padding: 13px;
        line-height: 1;
        position: absolute;
        left: -27px;
        top: 6px;
        z-index: -1;
      }
    }
  }
  & > ul {
    padding-left: 0;
    > li {
      list-style: none;
      padding-left: 36px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 1em;
        height: 1em;
        background: url(${require('../assets/list-bullet-blue.svg')?.default}) no-repeat right top;
      }
    }
  }
`
