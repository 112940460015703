import { useEffect, useState, useRef, useCallback } from 'react'

export function useMedia(query: string) {
  const [state, setState] = useState(() => window.matchMedia(query).matches)

  const mounted = useRef(true)
  const mql = useRef<MediaQueryList | null>(null)

  const mediaQueryChange = useCallback(() => {
    if (!mounted.current) return
    setState(!!mql.current?.matches)
  }, [])

  useEffect(() => {
    const handleBeforePrint = () => {
      console.log('BEFORE PRINT')
      mql.current?.removeListener(mediaQueryChange)
    }
    const handleAfterPrint = () => {
      console.log('AFTER PRINT')
      mql.current?.addListener(mediaQueryChange)
    }
    window.addEventListener('onbeforeprint', handleBeforePrint)
    window.addEventListener('onafterprint', handleAfterPrint)
    return () => {
      window.removeEventListener('onbeforeprint', handleBeforePrint)
      window.removeEventListener('onafterprint', handleAfterPrint)
    }
  }, [mediaQueryChange])

  useEffect(() => {
    mounted.current = true
    mql.current = window.matchMedia(query)

    mql.current.addListener(mediaQueryChange)
    setState(mql.current.matches)

    return () => {
      mounted.current = false
      mql.current?.removeListener(mediaQueryChange)
    }
  }, [query, mediaQueryChange])

  return state
}
