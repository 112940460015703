import React, { useState } from 'react'
import styled from 'styled-components'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import { fontBold } from 'fonts'

import {
  QuestionnaireSummarySaved,
  QuestionnaireType,
  QuestionnaireBooklet,
  questionnaireTypeLabels,
} from 'shared/questionnaires/types'
import { ProviderEntity } from 'shared/dashboard/types'

import { transparentize } from 'utils/polishedUtils'
import { useEndpoint } from 'dashboards/utils/endpointHooks'

import { Button, CUT_TL, CUT_TR, H1, P, Panel, Row, Spacer, Padding } from 'common/ui'
import { Spinner } from 'common/Spinner'
import Modal from 'app/Modal'
import qs from 'qs'
import { SortType } from 'shared/types'
import { useSessionStorage } from 'utils/useStorage'

const sortSymbols: { [key in SortType]: string } = { ascending: '▲', descending: '▼' }
type SortColumns = Extract<
  keyof QuestionnaireSummarySaved,
  'date' | 'questionnaire_type' | 'questionnaire_booklet' | 'cadet_name' | 'name' | 'facilitator_names'
>

export const H1Regular = styled(H1)`
  ${fontBold}
  color: #7D41DF;
  text-transform: initial;
  letter-spacing: 0.05em;
`

export const QuestionnaireOrphanModal: React.FC<{
  providerUid?: string
  questionnaireType?: QuestionnaireType
  questionnaireBooklet?: QuestionnaireBooklet
  onClose: () => void
  onSelect: (questionnaire: QuestionnaireSummarySaved) => void
}> = ({ providerUid, questionnaireType, questionnaireBooklet, onClose, onSelect }) => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [provider, { loading: loadingProvider }] = useEndpoint<ProviderEntity>(
    providerUid ? `/api/v1/providers/${providerUid}` : null
  )
  const [questionnaires, { loading: loadingQuestionnaires }] = useEndpoint<QuestionnaireSummarySaved[]>(
    providerUid
      ? `/api/v1/questionnaires/orphaned?${qs.stringify({
          provider_uid: providerUid,
          questionnaire_type: questionnaireType,
          questionnaire_booklet: questionnaireBooklet,
        })}`
      : null,
    [],
    { refetchDespiteCachedValue: true }
  )

  const [sortColumn, setSortColumn] = useSessionStorage<SortColumns>(
    'sortColumn_facdashOrphanedQuestionnairesModal',
    'date'
  )
  const [sortDirection, setSortDirection] = useSessionStorage<SortType>(
    'sortDir_facdashOrphanedQuestionnairesModal',
    'descending'
  )

  const selectedRow = selectedRowId ? (questionnaires || []).find(({ id }) => id === selectedRowId) : undefined

  const handleClose = () => {
    onClose()
  }
  const handleRowSelect = (id: number) => {
    setSelectedRowId(id)
  }
  const handleConfirm = () => {
    if (!selectedRow) return
    onSelect(selectedRow)
    handleClose()
  }

  const handleColumnHeaderSelect = (column: SortColumns) => {
    if (column !== sortColumn) setSortColumn(column)
    else setSortDirection(v => (v === 'ascending' ? 'descending' : 'ascending'))
  }

  const sortedQuestionnaires = [...(questionnaires || [])].sort((a, b) => {
    const valA = a[sortColumn]
    const valB = b[sortColumn]
    return valA === valB || valA === undefined || valB === undefined
      ? 0
      : (valA < valB ? -1 : 1) * (sortDirection === 'ascending' ? 1 : -1)
  })

  const renderColumnHeader = (column: SortColumns, label: string, fixedWidth?: number) => (
    <div
      style={{ cursor: 'pointer', ...(fixedWidth ? { width: fixedWidth } : {}) }}
      onClick={() => handleColumnHeaderSelect(column)}>
      {label} {sortColumn === column && sortSymbols[sortDirection]}
    </div>
  )

  const columnCount = 4 + [questionnaireType, questionnaireBooklet].filter(_ => !_).length

  return (
    <Modal isOpen onRequestClose={handleClose}>
      <Panel
        shadow={false}
        padding="m"
        style={{ maxHeight: 'calc(100vh - 50px)', width: 750 }}
        flair={[CUT_TL, CUT_TR]}>
        <H1Regular align="center">Select Questionnaire</H1Regular>
        <TableContainer columnCount={columnCount}>
          <Row>
            {!questionnaireBooklet && renderColumnHeader('questionnaire_booklet', 'Booklet', 60)}
            {!questionnaireType && renderColumnHeader('questionnaire_type', 'Type')}
            {renderColumnHeader('cadet_name', 'Cadet Name')}
            {renderColumnHeader('name', 'Respondent')}
            {renderColumnHeader('facilitator_names', 'Facilitator')}
            {renderColumnHeader('date', 'Date')}
          </Row>
          <div className="row-container" style={{ maxHeight: 420, overflow: 'auto' }}>
            {loadingQuestionnaires || loadingProvider || questionnaires === null ? (
              <Padding size="m">
                <Spinner color="#7D41DF" />
              </Padding>
            ) : !questionnaires.length ? (
              <P align="center">
                There are currently no unlinked{' '}
                {questionnaireType && `"${questionnaireTypeLabels[questionnaireType]}" `}
                questionnaires belonging to the {provider ? `provider "${provider.name}"` : `current provider`}.
              </P>
            ) : (
              sortedQuestionnaires.map(
                (
                  {
                    id,
                    questionnaire_type,
                    questionnaire_booklet,
                    name,
                    cadet_name,
                    facilitator_names,
                    date,
                    modified,
                  },
                  i
                ) => (
                  <Row key={id} className={id === selectedRowId ? 'selected' : ''} onClick={() => handleRowSelect(id)}>
                    {!questionnaireBooklet && <div style={{ width: 60 }}>{questionnaire_booklet}</div>}
                    {!questionnaireType && <div>{questionnaireTypeLabels[questionnaire_type]}</div>}
                    <div>{cadet_name}</div>
                    <div>{name}</div>
                    <div>{facilitator_names}</div>
                    <TextHoverToggle
                      data-text={`Created: \n${date}`}
                      data-hover-text={
                        modified
                          ? `Last Modified: \n${format(
                              parse(modified, `yyyy-MM-dd'T'HH:mm:ssxxx`, Date.now()),
                              'yyyy-MM-dd - h:mma'
                            )}`
                          : `Created: ${date}`
                      }
                    />
                  </Row>
                )
              )
            )}
          </div>
        </TableContainer>
        <Row justifyContent="center">
          <Button
            onClick={handleConfirm}
            size="s"
            children="Confirm"
            disabled={!selectedRowId || loadingQuestionnaires || loadingProvider}
          />
          <Spacer size="s" />
          <Button onClick={handleClose} size="s" children="Cancel" theme="gray" />
        </Row>
      </Panel>
    </Modal>
  )
}

export const TableContainer = styled.div<{ columnCount?: number }>`
  background-color: white;
  border: 1px solid ${p => p.theme.thinOutlineColor};
  border-radius: 10px;
  margin: 20px 0;
  overflow: hidden;

  & ${Row} {
    min-height: 35px;
    align-items: center;
    border-bottom: 1px solid ${p => p.theme.thinOutlineColor};
    font-weight: bold;
    & > div {
      width: ${p =>
        typeof p.columnCount !== 'number'
          ? `calc(calc(100% - 60px) / 5)`
          : p.columnCount < 5
          ? `calc(100% / ${p.columnCount})`
          : `calc(calc(100% - 60px) /  ${p.columnCount - 1})`};
      text-align: center;
      font-size: 14px;
    }
  }

  & > ${Row} {
    background-color: #e9dcff;
    color: #6737b5;
  }

  & .row-container ${Row} {
    font-weight: normal;
    cursor: pointer;
    & > div {
      font-size: 12px;
    }

    &:hover {
      background-color: ${p => transparentize(0.5, '#EDF2FA')};
    }
    &.selected {
      background-color: #edf2fa;
      color: #7d41df;
      font-weight: bold;
    }
    &:last-child {
      border-bottom: none;
    }
  }
`

const TextHoverToggle = styled.div`
  &::before {
    content: attr(data-text);
    white-space: pre;
  }
  &:hover::before {
    content: attr(data-hover-text);
  }
`
