import React, { useState } from 'react'

import { Row, Column, Padding, H2, Button } from 'common/ui'
import { MenuModal, MenuButton } from 'skill-tracker/Menu'
import { Hint } from 'common/Hint'
import { useMedia } from 'utils/useMedia'

export const baseUrl = '/skill-tracker'
export const navHeight = 50

interface Props {
  title?: string
  titleHint?: string
  showingHelp?: boolean
  hideMenu?: boolean
  onShowHelp?: () => void
}

export const Navigation: React.FC<Props> = ({ title, titleHint, hideMenu = false, onShowHelp, showingHelp }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const portrait = useMedia('(orientation: portrait) and (max-width: 420px)')
  return (
    <>
      <Row flex="none" style={{ borderBottom: '1px solid #abb4db' }}>
        <Padding size="xs">
          <Row style={{ width: '100%', alignItems: 'center' }}>
            <Row flex="1 0 auto" paddingLeft={portrait ? 5 : 10} paddingRight={portrait ? 5 : 10} alignItems="center">
              {title && (
                <H2 style={{ lineHeight: 0.95, fontSize: portrait ? 12 : undefined, paddingTop: titleHint ? 2 : 0 }}>
                  {title}
                </H2>
              )}
              {titleHint && <Hint text={titleHint} style={{ marginLeft: 10 }} />}
            </Row>
            <Column flex="none">
              <Row>
                {onShowHelp && (
                  <Button
                    size="xs"
                    marginLeft={12}
                    marginRight={5}
                    theme="white"
                    selected={showingHelp}
                    onClick={() => onShowHelp()}>
                    Help
                  </Button>
                )}
                {!hideMenu && <MenuButton onClick={() => setMenuOpen(true)}>Menu</MenuButton>}
              </Row>
            </Column>
          </Row>
        </Padding>
      </Row>
      <MenuModal isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  )
}
