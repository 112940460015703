import React from 'react'
import { useGadgetPackState } from 'gadget-pack/GadgetPackState'
import { useSessionState } from 'session/SessionState'

export const GadgetPackLoading: React.FC = () => {
  const { previewing, isFacilitator } = useSessionState()
  const { loadingInitialState } = useGadgetPackState()

  return (
    <p>
      {loadingInitialState
        ? 'Loading Gadget Pack Please Wait'
        : previewing
        ? `There is currently a technical issue with previewing gadget pack. This can be remedied by logging into SAS then re-previewing.`
        : isFacilitator
        ? 'You cannot edit gadget pack activities while the cadet is offline'
        : `You currently don't have permission to access gadget pack`}
    </p>
  )
}
