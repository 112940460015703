import { Container, CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L } from 'common/ui'
import { Circle, StressBall, StressBallScreen } from 'gadget-pack/StressBall'
import React, { useState } from 'react'
import styled from 'styled-components'
import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'

export const StressBallModal: React.FC<{ onClose: () => void; mentorMode?: boolean }> = ({ onClose, mentorMode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  return (
    <MobileModalPanel
      zIndex={1200}
      isOpen={isOpen}
      onRequestClose={delayedOnClose}
      panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}
      panelStyle={mentorMode ? { minWidth: '100vw', minHeight: '100vh' } : {}}>
      <StressBallActivityContainer mentorMode={mentorMode}>
        <StressBallScreen overrideExit={delayedOnClose} />
      </StressBallActivityContainer>
    </MobileModalPanel>
  )
}

const stressBallWidth = 300

const StressBallActivityContainer = styled.div<{ mentorMode?: boolean }>`
  position: relative;
  min-height: 600px;
  width: ${p => (p.mentorMode ? '100%' : '600px')};
  margin: ${p => (p.mentorMode ? '30px 0' : '30px')};
  box-sizing: border-box;

  & ${Container} {
    width: 100%;
  }

  & ${StressBall} {
    width: ${stressBallWidth}px;
  }

  & ${Circle} {
    width: ${stressBallWidth}px;
    height: ${stressBallWidth}px;
    top: calc(50% - ${stressBallWidth / 2}px);
    left: calc(50% - ${stressBallWidth / 2}px);
  }

  div[mode] > div {
    background: #edf2fa;
  }
`
