import React, { useEffect, useState } from 'react'

import { NavRouteProps } from 'dashboards/types'
import { Spacer, P, CUT_BL, CUT_BR, CUT_TL, CUT_TR, TAB_B_L, TAB_T_L, Button, Padding, Row, Column } from 'common/ui'

import { LinkButton } from 'common/LinkButton'

import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { Cell, Cells } from 'dashboards/common/Cell'
import { SessionCell } from 'dashboards/common/SessionCell'
import { SpinnerWithLabel } from 'common/Spinner'
import { Tabs } from 'dashboards/common/Tab'

import { useFacDashData, useFacDashState } from './FacilitatorDashboardState'
import {
  sessionsFilterFuture,
  sessionsFilterPast,
  sessionsFilterCatchUp,
  sessionsFilterUnfinalized,
} from 'dashboards/utils/reducers'
import { useGenericUser } from 'app/useGenericUser'
import { DrupalRole } from 'shared/types'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { CadetTab as Tab } from './GroupCadetOverview'
import styled from 'styled-components'
import { SessionEntity } from 'shared/dashboard/types'
import ReactDOM from 'react-dom'
import { useSessionStorage } from 'utils/useStorage'
import { HideForPrint } from 'print/ui-print'
import { PrintButton } from 'dashboards/common/PrintButton'
import { getGroupExpiryLabel } from 'dashboards/utils/facilitatorUtils'
import { StatusIcon } from 'common/StatusIcon'
import { InfoTooltip } from 'common/Tooltip'

export const checkIsAssistant = (roles: DrupalRole[]) => {
  const isFacilitator =
    roles.find(role => role === 'sas-sg facilitator' || role === 'sas-sg senior facilitator') !== undefined
  const isAssistant = roles.find(role => role === 'sas-sg assistant') !== undefined
  return isAssistant && !isFacilitator
}

export const Meetings: React.FC<NavRouteProps> = ({ route }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const user = useGenericUser()
  const isAssistant = checkIsAssistant(user.roles)
  const [groupId, setGroupId] = useState<number | null>(null)
  const {
    sessions: { fetch: refetchSessions },
  } = useFacDashState()
  const [sessions, loadingSessions] = useFacDashData('sessions', [])
  const [groups, loadingGroups] = useFacDashData('groups', [])
  const [unfinalizedSessions, setUnfinalizedSessions] = useState<SessionEntity[]>([])
  const [catchUpSessions, setCatchUpSessions] = useState<SessionEntity[]>([])
  const [futureSessions, setFutureSessions] = useState<SessionEntity[]>([])
  const [pastSessions, setPastSessions] = useState<SessionEntity[]>([])
  const [lastAccessedGroup, setLastAccessedGroup] = useSessionStorage<number | null>('lastGroup')
  const [loaded, setLoaded] = useState<boolean>(false)

  const sessionGroups = sessions.reduce(
    (acc, session) => {
      acc[session.group_id] = {
        name: session.group.name,
        groupId: session.group_id,
      }
      return acc
    },
    {} as {
      [key: string]: {
        name: string | null
        groupId: number
      }
    }
  )

  const hasExpiryWarning = Object.keys(sessionGroups).reduce((acc, groupKey) => {
    if (acc) return acc
    const groupData = groups.find(a => a.id === sessionGroups[groupKey].groupId)
    const isPrimaryFac = +user.uid.split('-')[1] === groupData?.facilitator_id
    const groupExpiryLabel = groupData ? getGroupExpiryLabel(groupData) : 'active'
    if (isPrimaryFac && groupExpiryLabel !== 'active') {
      acc = true
    }
    return acc
  }, false)

  const reloadSessions = () => {
    const groupSessions = sessions.filter(session => {
      return (
        session.group_id === groupId ||
        session.extra_groups.filter(extraSession => extraSession.id === groupId).length > 0
      )
    })
    ReactDOM.unstable_batchedUpdates(() => {
      setUnfinalizedSessions(sessionsFilterUnfinalized(groupSessions))
      setCatchUpSessions(sessionsFilterCatchUp(groupSessions))
      setFutureSessions(sessionsFilterFuture(groupSessions))
      setPastSessions(sessionsFilterPast(groupSessions))
    })
  }

  useEffect(() => {
    if (groupId) {
      setLastAccessedGroup(groupId)
      reloadSessions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId])

  useEffect(() => {
    // sessions have loaded
    if (sessionGroups && sessions.length > 0 && groupId === null) {
      setLoaded(true)
      const currentGroups = Object.values(sessionGroups)
      // validate if they still have access to last accessed group
      const valid = currentGroups.find(group => group.groupId === lastAccessedGroup)
      if (!valid) {
        ReactDOM.unstable_batchedUpdates(() => {
          setGroupId(currentGroups[0].groupId)
        })
      } else {
        setGroupId(lastAccessedGroup)
      }
    } else if (loaded) {
      reloadSessions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions])

  if ((loadingSessions || loadingGroups) && groupId === null)
    return (
      <Page route={route}>
        <SpinnerWithLabel color="#925BED" label="One moment please..." />
      </Page>
    )

  return (
    <>
      <Page route={route} sideFragment={<PrintButton />}>
        {!isAssistant && (
          <HideForPrint>
            <LinkButton
              size="m"
              children="Schedule a meeting"
              to={`${route.path}/new`}
              style={{ display: 'inline-block' }}
            />
            <Spacer size="l" />
            {hasExpiryWarning && (
              <Row alignItems="center">
                <P>You have Cadet Places that are approaching their expiry date</P>
                <InfoTooltip
                  placement="right"
                  tooltipStyle={{ background: 'white', border: '1px solid #cdd2e4' }}
                  content={
                    <>
                      <Row marginBottom={10} alignItems="center">
                        {StatusIcon('expired')} <P style={{ margin: 0 }}> means expired</P>
                      </Row>
                      <Row marginBottom={10} alignItems="center">
                        {StatusIcon('warning')} <P style={{ margin: 0 }}> means less than 3 months</P>
                      </Row>
                      <Row marginBottom={10} alignItems="center">
                        {StatusIcon('due')} <P style={{ margin: 0 }}> means less than 1 month</P>
                      </Row>
                      <P style={{ margin: 0 }}>
                        Go to your Cadet Overview page for more information about specific cadets.
                      </P>
                    </>
                  }
                />
              </Row>
            )}
            <Spacer size="xl" />
          </HideForPrint>
        )}
        <Tabs style={{ minHeight: 'auto' }}>
          {Object.keys(sessionGroups).map((groupKey, i) => {
            const group = sessionGroups[groupKey]
            const groupData = groups.find(a => a.id === group.groupId)
            const isPrimaryFac = +user.uid.split('-')[1] === groupData?.facilitator_id
            const groupExpiryLabel = groupData ? getGroupExpiryLabel(groupData) : 'active'
            return (
              <Tab
                style={{ fontSize: 16 }}
                key={i}
                active={groupId === group.groupId}
                onClick={() => {
                  if (groupId !== group.groupId) setGroupId(group.groupId)
                }}
                highlighted={isPrimaryFac}>
                <Row alignItems="center" style={{ height: '100%' }}>
                  {groupExpiryLabel !== 'active' && (
                    <span style={{ marginRight: 5 }}>{StatusIcon(groupExpiryLabel)}</span>
                  )}
                  {group.name}
                </Row>
              </Tab>
            )
          })}
        </Tabs>
        <ScreenSelector>
          {sessions && sessions.length > 0 ? (
            <>
              {unfinalizedSessions.length > 0 && (
                <>
                  <HeaderHr children="Finalise Meetings" />
                  <Cells>
                    {unfinalizedSessions.map((session, i) => {
                      return (
                        <SessionCell
                          key={session.id || i}
                          userType="facilitator"
                          context="past"
                          session={session}
                          onUpdate={() => refetchSessions()}
                        />
                      )
                    })}
                  </Cells>
                  <Spacer size="l" />
                </>
              )}

              {catchUpSessions.length > 0 && (
                <>
                  <HeaderHr children="Catch-up Meetings" />
                  <Cells>
                    {catchUpSessions.map((session, i) => {
                      return (
                        <SessionCell
                          key={session.id || i}
                          userType="facilitator"
                          context="past"
                          session={session}
                          onUpdate={() => refetchSessions()}
                        />
                      )
                    })}
                  </Cells>
                  <Spacer size="l" />
                </>
              )}

              <HeaderHr children="Scheduled Meetings" />
              <Cells>
                {futureSessions.length > 0 ? (
                  futureSessions.map((session, i) => {
                    return (
                      <SessionCell
                        key={session.id || i}
                        userType="facilitator"
                        context="future"
                        session={session}
                        preventStart={
                          catchUpSessions.length > 0 && session.group
                            ? !!catchUpSessions.find(({ group_id }) => group_id === session.group.id)
                            : false
                        }
                        preventStartCallback={() => setModalOpen(true)}
                        onUpdate={() => refetchSessions()}
                      />
                    )
                  })
                ) : (
                  <P>No upcoming meetings.</P>
                )}
              </Cells>
              <Spacer size="l" />

              <HeaderHr children="Past Meetings" />
              <Cells>
                {pastSessions.length > 0 ? (
                  pastSessions.reverse().map((session, i) => {
                    return (
                      <SessionCell
                        key={session.id || i}
                        userType="facilitator"
                        context="past"
                        session={session}
                        onUpdate={() => refetchSessions()}
                      />
                    )
                  })
                ) : (
                  <P>No previous meetings.</P>
                )}
              </Cells>
            </>
          ) : (
            <P>No meetings created yet.</P>
          )}
        </ScreenSelector>
      </Page>
      <MobileModalPanel
        zIndex={1200}
        isOpen={modalOpen}
        panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}>
        <Padding size="l">
          <P style={{ maxWidth: 500 }}>
            Please stop all Catch-up Cadet Club Meetings before starting this new Club Meeting.
            <br />
            <br />
            If cadet’s have already started to log into the open Catch-up meeting, they may need to exit and log in
            again to join this new meeting with you.
          </P>
          <Row justifyContent="center" style={{ paddingBottom: 20 }}>
            <Button size="s" children="close" onClick={() => setModalOpen(false)} />
          </Row>
        </Padding>
      </MobileModalPanel>
    </>
  )
}

const ScreenSelector = styled(Column)`
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid #cdd2e4;
  box-shadow: 0 1px #fff;
  border-radius: 8px;
  border-top-left-radius: 0;

  ${Cell} {
    background: #f5f5f5;
  }
`
