import React, { ReactNode, ComponentProps } from 'react'
import styled from 'styled-components'

import { Spacer, Label, Row, Column } from 'common/ui'
import { Hint } from './Hint'

import { fontBold } from 'fonts'

export interface FieldInputProps<ValueType> {
  value: ValueType | undefined
  onChange: (value: ValueType | undefined) => void
  readOnly?: boolean
  disabled?: boolean
}

export interface FieldInputMultiProps<ValueType> {
  value: ValueType[]
  onChange: (value: ValueType[]) => void
  readOnly?: boolean
  disabled?: boolean
}

export interface FieldProps {
  label?: ReactNode
  labelRight?: ReactNode
  hint?: string
  onHintClick?: () => void
  instructions?: string
  errors?: string[]
  flex?: string
  fieldProps?: Partial<ComponentProps<typeof Column>>
}

export const Field: React.FC<FieldProps> = ({
  children,
  label,
  labelRight,
  hint,
  onHintClick,
  instructions,
  errors = [],
  fieldProps = {},
  flex,
}) => {
  return (
    <FieldWrapper style={{ flex }} {...fieldProps}>
      <Row>
        {label && <FieldLabel>{label}</FieldLabel>}
        {hint && (
          <>
            <Spacer size="s" />
            <Hint text={hint} onClick={onHintClick} />
          </>
        )}
        {labelRight && (
          <>
            <div style={{ flex: '1 1 auto' }} />
            {labelRight}
          </>
        )}
      </Row>
      {instructions && <Instructions>{instructions}</Instructions>}
      {children}
      {errors.length > 0 && (
        <div className="error-list" style={{ color: 'red', whiteSpace: 'pre-wrap' }}>
          {errors.join(`\n`)}
        </div>
      )}
    </FieldWrapper>
  )
}

export const FieldWrapper = styled(Column)``

export const FieldLabel = styled(Label)`
  ${fontBold}
  font-size: 14px;
  margin: 0 0 5px 0;
  color: #19345d;
`

const Instructions = styled.p`
  margin: 0 0 5px 0;
  font-size: 11px;
  opacity: 0.75;
`
