import { SVG } from 'common/SVG'
import {
  CUT_BL_DARK,
  CUT_BR_DARK,
  CUT_TL_DARK,
  CUT_TR_DARK,
  Column,
  P,
  Spacer,
  TAB_B_DARK,
  TAB_T_DARK,
} from 'common/ui'
import React from 'react'
import { MultilineText } from 'session/common/MultilineText'
import { SectionsPanelStyled } from 'session/common/SectionsPanel'
import { Block, BlockBody, BlockHeader, Blocks, BlocksColumn } from 'session/sections/Block'
import { Notification, NotificationBody, NotificationIcon, PulloutInner } from 'session/sections/Notification'
import styled from 'styled-components'
import { Title } from './ConversationEquations'

const gridItems = [
  {
    order: 1,
    type: 'image',
    content:
      'Move away from the game to use your relaxation gadgets if needed. Try to rejoin the game when you feel calmer.',
    imagePath: require('session/assets/recovery-tactics/1.png'),
  },
  {
    order: 2,
    type: 'image',
    content:
      'If a friend said or did something that upset you, use a calm face, voice and words to ask them what you would like them to do instead.',
    imagePath: require('session/assets/recovery-tactics/2.png'),
  },
  {
    order: 3,
    type: 'image',
    content:
      'If you are still really sad or angry or the person doesn’t do as you asked, calmly tell them why with a sentence beginning with “I” (ONLY if you are friends – if not, skip this step).',
    imagePath: require('session/assets/recovery-tactics/3.png'),
  },
  {
    order: 4,
    type: 'image',
    content: 'Tell them that you are going, say bye and find someone else to hang out with.',
    imagePath: require('session/assets/recovery-tactics/4.png'),
  },
  {
    order: 5,
    type: 'image',
    content:
      'If you want to be friends in the future, talk about what happened at another time when everyone feels calmer. Use the D.E.C.O.D.E.R steps to try to solve the problem.',
    imagePath: require('session/assets/recovery-tactics/5.png'),
  },
]

export const RecoveryTactics: React.FC<{}> = () => (
  <>
    <Title panelTheme="maroon">PLAY RECOVERY TACTICS</Title>
    <SectionsPanelStyled
      panelTheme="maroon"
      flex={'1 0 auto'}
      flair={[CUT_TR_DARK, CUT_TL_DARK, CUT_BL_DARK, CUT_BL_DARK, CUT_BR_DARK, TAB_B_DARK, TAB_T_DARK]}
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <div style={{ padding: '30px 20px' }}>
        <Blocks>
          <BlocksColumn columns={1}>
            <Block display={'inline'} blockTheme={undefined} style={{ height: 150 }}>
              <BlockHeader style={{ display: 'flex', flex: '0 0 25%', alignItems: 'center' }}>
                <img
                  style={{ width: '100%' }}
                  alt="Feelings signs"
                  src={require('session/assets/recovery-tactics/stop_img.png')}
                />
              </BlockHeader>
              <Column justifyContent="center" padding="20px">
                <IntroText style={{ marginTop: -14 }}>
                  Try the following chill-out tactics when playing with others.
                </IntroText>
                <IntroText style={{ marginTop: '1.2rem' }}>Only move onto the next tactic if needed.</IntroText>
              </Column>
            </Block>
          </BlocksColumn>
          {gridItems.map((item, i) => (
            <BlocksColumn key={i} columns={1}>
              <Block display={'inline'} blockTheme={undefined} style={{ height: 225 }}>
                <BlockHeader style={{ flex: i === 3 ? '0 0 50%' : '0 0 25%' }}>
                  <BlockImage path={item.imagePath} />
                </BlockHeader>
                <BlockText>
                  {item.content}
                  {i === 1 && (
                    <Notification type="warning">
                      <PulloutInner className="notification-inner">
                        <NotificationIcon>
                          <SVG width={47} height={46} viewboxWidth={47} viewboxHeight={46}>
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M43.2152 30.5766L29.9762 7.62344C28.6471 5.2866 26.1698 3.83619 23.4907 3.83301C20.8114 3.82983 18.3332 5.27426 16.9876 7.62354L3.74367 30.5851C2.37036 32.927 2.3393 35.8615 3.66949 38.2548C5.00079 40.6501 7.49356 42.1407 10.205 42.163L36.7344 42.1632C39.474 42.1362 41.962 40.6501 43.2931 38.257C44.6227 35.8667 44.5929 32.9387 43.2152 30.5766ZM6.99927 32.5326L20.253 9.55408C20.9242 8.38229 22.1552 7.66476 23.4864 7.66634C24.8173 7.66792 26.0479 8.38841 26.708 9.54894L39.9575 32.5202C40.652 33.7109 40.6668 35.1768 40.0012 36.3736C39.3346 37.572 38.0884 38.3163 36.716 38.3299L10.2204 38.3298C8.87749 38.3186 7.62886 37.572 6.96215 36.3724C6.29616 35.1742 6.31172 33.7051 6.99927 32.5326ZM23.483 34.4965C24.5271 34.4965 25.3735 33.6383 25.3735 32.5798C25.3735 31.5212 24.5271 30.6631 23.483 30.6631C22.439 30.6631 21.5926 31.5212 21.5926 32.5798C21.5926 33.6383 22.439 34.4965 23.483 34.4965ZM25.3791 15.3298H21.5981V28.7465H25.3791V15.3298Z"
                              fill="#FF8A00"
                            />
                          </SVG>
                        </NotificationIcon>
                        <NotificationBody>
                          <MultilineText text="If you're hanging out with new people, try to stay calm and wait until the end of the turn or game if you can." />
                        </NotificationBody>
                      </PulloutInner>
                    </Notification>
                  )}
                  {i === 2 && (
                    <>
                      <Spacer />
                      <FillInBlanks>
                        I find it <div /> when <div />
                      </FillInBlanks>
                      <FillInBlanks>
                        I'm going to <div /> because <div />
                      </FillInBlanks>
                    </>
                  )}
                </BlockText>
              </Block>
            </BlocksColumn>
          ))}
        </Blocks>
      </div>
    </SectionsPanelStyled>
  </>
)

const BlockText = styled(BlockBody)`
  background-color: #2c2c2c !important;
  background-image: none !important;
  font-size: 0.7em;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px #4d4d4d, inset 0px 1px 1px #4d4d4d;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Notification} {
    margin-top: 20px;
    margin-bottom: 0;
    color: #011a46;
  }
`

const BlockImage = styled.div<{ path: string }>`
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  background-image: url(${p => p.path});
  background-size: cover;
  background-position: center;
`

const FillInBlanks = styled.p`
  display: flex;
  div {
    width: 30%;
    border-bottom: 1px solid #000;
    display: inline-block;
    margin: 0 20px;
  }
`

const IntroText = styled(P)`
  font-size: 0.8em;
  margin-bottom: 0;
  font-weight: bold;
`
