import React from 'react'
import styled from 'styled-components'
import { emptyColors, disabledColors, EmotionometerColors } from 'common/Emotionometer'
import { Row, Column, FlexProps } from 'common/ui'
import { EmotionometerType } from 'shared/e-telligence/types'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { fontBold, fontRegular } from 'fonts'
import { emotionometerColors } from 'e-telligence/constants/typeValueMaps'
import { DeviceOrientation } from 'types'
import { blackOrWhite } from 'utils/colorUtils'

interface Props {
  type: EmotionometerType
  labels?: string[]
  value: number[]
  distribution: number[]
  orientation?: DeviceOrientation
  disabled?: boolean
  flex?: FlexProps['flex']
  onSelectSegment?: (indexes: number[]) => void
}

export const EmotionometerBar: React.FC<Props> = ({
  type,
  value,
  distribution,
  labels = ['A little bit', 'Moderately', 'Very'],
  orientation = 'landscape',
  disabled,
  flex = true,
  onSelectSegment,
}) => {
  const colors: EmotionometerColors = disabled ? disabledColors : emotionometerColors[type]

  return (
    <Layout flex={flex} deviceOrientation={orientation}>
      <Row flex>
        {intersperseSpacers(
          distribution.map((_, i) => {
            const selected = value.indexOf(distribution[i]) >= 0
            return (
              <Bar
                flex="1 1 10px"
                key={i}
                selected={selected}
                editable={!!onSelectSegment}
                backgroundColor={selected ? colors[distribution[i]] : emptyColors[distribution[i]]}
                onClick={
                  !onSelectSegment
                    ? undefined
                    : () =>
                        onSelectSegment(
                          selected ? value.filter(test => test !== distribution[i]) : [...value, distribution[i]]
                        )
                }
                children={i + 1}
              />
            )
          }),
          'xxs'
        )}
      </Row>
      <Row flex justifyContent="space-between">
        {labels.map((label, i) => (
          <Column key={i}>
            <Label style={{ fontSize: 12, paddingRight: orientation === 'portrait' ? 5 : 0 }}>{label}</Label>
          </Column>
        ))}
      </Row>
    </Layout>
  )
}

const Layout = styled(Column)<{ deviceOrientation: DeviceOrientation }>`
  flex-direction: ${p => (p.deviceOrientation === 'landscape' ? 'column' : 'row-reverse')};
  & > * {
    flex-direction: ${p => (p.deviceOrientation === 'landscape' ? 'row' : 'column')};
  }
`

export const Bar = styled(Column)<{ editable: boolean; selected: boolean; backgroundColor: string }>`
  height: 30px;
  border-radius: 10px;
  padding: 0 3px;

  background-color: ${p => (!p.editable && !p.selected ? 'transparent' : p.backgroundColor)};
  color: ${p => blackOrWhite(p.backgroundColor)};
  line-height: 1;

  ${fontBold}
  text-align: center;
  justify-content: center;

  box-sizing: border-box;
  cursor: ${p => (p.editable ? 'pointer' : 'auto')};
  ${p => (p.editable ? '' : `border: 1px solid ${p.theme.thinOutlineColor};`)}
  ${p =>
    !p.editable
      ? ''
      : `box-shadow: inset 5px -5px 8px 5px rgba(25, 25, 25, 0.3), 
                      inset -4px 8px 8px 0 rgba(255, 255, 255, 0.4), 
                      inset 0px -1px 2px 0 rgba(255, 255, 255, 0.6), 
                      inset 0px 5px 3px 0 rgba(255, 255, 255, 0.7);`};
`

const Label = styled.label`
  ${fontRegular}
  font-size: 12px;
`
