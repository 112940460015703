import React, { useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import { CodeCard, CodeCardStep, RouteProps } from 'shared/e-telligence/types'
import { ADD_UPDATE_CUSTOM_CODE_CARD, AddUpdateCustomCodeCardAction } from 'shared/e-telligence/actionTypes'

import { Column, Padding, Input, Row, Button, Spacer, OutlineButton, Textarea } from 'common/ui'
import { Field } from 'common/Field'
import { Navigation, baseUrl } from './common/Navigation'
import { ValidationErrorList } from 'common/ValidationErrorList'

import { useETelligenceState } from './ETelligenceState'
import { getInputProps } from 'utils/virtualKeyboard'
import { intersperse } from 'utils/intersperse'
import { ScenePreview } from 'journal/common/ScenePreview'
import { useUserState } from 'app/UserState'
import { ETellPanel } from './common/ETellPanel'
import { isEmptyScene } from 'scene-builder/SceneBuilder'

export const validate = (data: Partial<CodeCard>, errors: string[] = []) => {
  if (!data.title) errors.push('Please enter a name for your skill code card.')
  return errors
}

export const emptyCodeCard: CodeCard = {
  id: '',
  title: '',
  isPreset: false,
  cover: '',
  color: '#ffffff',
  steps: [
    {
      label: '',
      description: '',
      imageUrl: '',
    },
    {
      label: '',
      description: '',
      imageUrl: '',
    },
  ],
}

interface Props {
  isNew?: boolean
}

export const CodeCardEdit: React.FC<Props> = ({ isNew }) => {
  const history = useHistory()
  const { usingIOSvhFix } = useUserState()
  const { state, dispatch } = useETelligenceState()
  return (
    <ETellPanel style={{ height: usingIOSvhFix ? '100%' : 'calc(100vh - 20px)' }}>
      <Navigation title="Make your own" buttonLabel="Back" buttonPress={() => history.push(baseUrl + '/code-cards')} />
      <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
        <CodeCardEditInner baseUrl={baseUrl} state={state} dispatch={dispatch} isNew={isNew} />
      </Column>
    </ETellPanel>
  )
}

export const CodeCardEditInner: React.FC<RouteProps & Props> = ({ baseUrl, state, dispatch, isNew }) => {
  const history = useHistory()
  const location = useLocation()
  const { getBaseAction } = useUserState()
  const { id } = useParams<{ id: string }>()

  const [codeCard, setCodeCard] = useState<CodeCard>(() =>
    isNew ? emptyCodeCard : state.customCodeCards.find(test => test.id === id && !test.isPreset) || emptyCodeCard
  )

  const errors = validate(codeCard)

  const updateCodeCard = (data: Partial<CodeCard>) => setCodeCard(codeCard => ({ ...codeCard, ...data }))
  const updateStep = (data: Partial<CodeCardStep>, stepIndex: number) => {
    setCodeCard(codeCard => ({
      ...codeCard,
      steps: codeCard.steps.map((step, i) => (stepIndex === i ? { ...step, ...data } : step)),
    }))
  }
  const handleAddStep = () => {
    setCodeCard(codeCard => ({
      ...codeCard,
      steps: codeCard.steps.concat([
        {
          label: `Step ${codeCard.steps.length + 1}`,
          description: '',
          imageUrl: '',
        },
      ]),
    }))
  }
  const handleSave = (addToCardHolder?: boolean) => {
    if (errors.length > 0) return
    const saveCodeCard = { ...codeCard, steps: prepareStepsForSave(codeCard.steps) } as CodeCard
    if (!saveCodeCard.id) saveCodeCard.id = `customCodeCard${Date.now()}`
    const action: AddUpdateCustomCodeCardAction = {
      ...getBaseAction(),
      type: ADD_UPDATE_CUSTOM_CODE_CARD,
      codeCard: saveCodeCard,
      addToCardHolder,
    }
    dispatch(action)
    if (addToCardHolder) {
      setTimeout(() =>
        history.push(`${baseUrl}/home#${saveCodeCard.id}`, { ...(location.state || {}), showCodeCard: saveCodeCard.id })
      )
    } else {
      setTimeout(() => history.push(baseUrl + '/code-cards', location.state))
    }
  }

  return (
    <>
      <Padding size="s">
        <Field label="Enter a name for your skill code">
          <Input
            autoFocus={!codeCard.title}
            value={codeCard.title}
            onChange={e => updateCodeCard({ title: e.target.value })}
            maxLength={20}
            placeholder="20 characters max"
          />
        </Field>
        <Spacer />
        {codeCard.steps
          .map((step, i) => {
            return (
              <Field label={`Enter Step ${i + 1}`} key={i}>
                <Row responsive={600}>
                  <ScenePreview
                    flex="1 1 0px"
                    value={step.scene}
                    onChange={value => updateStep({ scene: value }, i)}
                    buttonSize="s"
                  />
                  <Spacer />
                  <Textarea
                    flex="1 1 0px"
                    autoFocus={!step.description && !!codeCard.title}
                    placeholder="Enter text or leave blank"
                    value={(step.description as string) || ''}
                    onChange={(e: any) => updateStep({ description: e.target.value || '' }, i)}
                    style={{ minHeight: '8rem' }}
                    {...getInputProps()}
                  />
                </Row>
              </Field>
            )
          })
          .reduce(
            intersperse(i => <Spacer key={`s${i}`} />),
            []
          )}
        <Spacer />
        <OutlineButton onClick={handleAddStep}>Add Step +</OutlineButton>
      </Padding>
      <Spacer size="m" flex="1 1 auto" />
      <Padding size="s" style={{ flex: 'none' }}>
        <Row>
          <ValidationErrorList errors={errors} />
        </Row>
        <Row justifyContent="center" padding="s">
          <Button onClick={() => handleSave()} disabled={errors.length > 0}>
            Save
          </Button>
        </Row>
      </Padding>
    </>
  )
}

export function prepareStepsForSave(steps: CodeCardStep[]): CodeCardStep[] {
  return steps
    .filter(step => (step.description as string).trim().length > 0 || (step.scene && !isEmptyScene(step.scene)))
    .map((step, i) => ({
      ...step,
      label: `Step ${i + 1}`,
      imageUrl: step.scene?.thumbnail || '',
      description: (step.description as string).trim(),
      layout: step.scene?.thumbnail ? 'full' : undefined,
    }))
}
