import React, { useEffect, useState } from 'react'
import { TrainingPanel, TrainingStateStep } from '../../shared/training/types'
import styled from 'styled-components'
import { RadioQuestion, CheckboxQuestion } from './ChoiceQuestion'
import { AnswerReveal, TabReveal } from './RevealSection'
import { ReorderQuestion } from './ReorderQuestion'
import { UploadQuestion } from './UploadQuestion'
import { ClickDragQuestion } from './ClickDragQuestion'
import { CardQuestion } from './CardQuestion'
import { Validation } from './Validation'
import ReactDOM from 'react-dom'
import { useTrainingState } from 'training/TrainingState'
import { ATTEMPT_QUESTION } from 'shared/training/actionTypes'
import { QuestionnaireInputQuestion, QuestionnaireRadioQuestion } from './Questionnaire'

export const QuizQuestion: React.FC<TrainingPanel & { reviewMode: boolean }> = props => {
  const { state, dispatch, previewing } = useTrainingState()
  const [showValidation, setShowValidation] = useState<boolean>(false)
  const [responseMessage, setResponseMessage] = useState<string>('')
  const [isCorrect, setIsCorrect] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<TrainingStateStep | undefined>(
    state.steps.find(step => step.uid === state.currentStepUid)
  )
  let component = null

  const componentProps = { ...props, question_options: props.question_options || [] }

  const handleSubmit = (correct: boolean, value: any, response?: string, skipValidation?: boolean) => {
    if (previewing && !skipValidation) setValidationMessage(correct, props.show_correct_results ? response || '' : '')

    if (currentStep) {
      dispatch({
        type: ATTEMPT_QUESTION,
        stepUid: currentStep.uid,
        panelUid: props.uid,
        value: value,
        correct,
      })

      if (!skipValidation) setValidationMessage(correct, props.show_correct_results ? response || '' : '')
    }
  }

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      setShowValidation(false)
      setCurrentStep(state.steps.find(step => step.uid === state.currentStepUid))
      setResponseMessage('')
      setIsCorrect(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentStepUid])

  const setValidationMessage = (correct: boolean, message: string) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setIsCorrect(correct)
      setShowValidation(true)
      setResponseMessage(message)
    })
  }

  switch (props.type) {
    case 'choice':
      component = <RadioQuestion key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'multi-select':
      component = <CheckboxQuestion key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'input-reveal':
      component = <AnswerReveal key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'select-reveal':
      component = <TabReveal key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'orderable':
      component = <ReorderQuestion key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'upload':
      component = <UploadQuestion key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'click-drag':
      component = <ClickDragQuestion key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'card-flip':
      component = <CardQuestion key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'questionnaire-choice':
      component = <QuestionnaireRadioQuestion key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
    case 'questionnaire-input':
      component = <QuestionnaireInputQuestion key={props.uid} {...componentProps} onSubmit={handleSubmit} />
      break
  }

  return (
    <Wrapper disabled={props.reviewMode}>
      {component}
      <Validation {...props} show={showValidation} correct={isCorrect} validationResult={responseMessage} />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ disabled: boolean }>`
  /* pointer-events: ${p => (p.disabled ? 'none' : 'auto')}; */

  label {
    margin-bottom: 10px;

    div,
    span {
      font-weight: 500;
    }
  }
`
