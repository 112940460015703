import React from 'react'
import { MentorEntity, MentorEntityNew } from 'shared/dashboard/types'
import { getMentorExpiryStatus, ExpiryStatus } from 'dashboards/utils/facilitatorUtils'
import { StatusIcon } from 'common/StatusIcon'
import { InfoTooltip, TooltipProps } from 'common/Tooltip'

const getProductKeyStatusTooltip = (status: ExpiryStatus | null) => {
  switch (status) {
    case 'expired':
      return `Expired: This Cadet Place has now expired. \n\nThe Cadet and Mentor/s can no longer access program materials. \n\nContact your SAS Provider if you have questions.`
    case 'due':
      return `This Cadet Place will expire in less than 1 month. \n\nIf you need to renew your Cadet Place for a further 12 months. You can do so before the expiry date. \n\nContact your SAS Provider if you have questions.`
    case 'warning':
      return `This Cadet Place will expire in less than 3 months' time. \n\nContact your SAS Provider if you have questions.`
    case 'active':
    default:
      return `This Cadet Place is active.`
  }
}

export const CadetNameAndStatus: React.FC<{
  primaryMentor: MentorEntity | MentorEntityNew
  hideTooltip?: boolean
  hideIcon?: boolean
  hideIconIfActive?: boolean
  hideWarningIcons?: boolean
  hideName?: boolean
  cadetName?: string
  tooltipPlacement?: TooltipProps['placement']
}> = ({
  primaryMentor,
  hideTooltip,
  hideIcon,
  hideIconIfActive,
  hideWarningIcons,
  hideName,
  cadetName: _cadetName,
  tooltipPlacement = 'right',
}) => {
  const productKeyStatus = getMentorExpiryStatus(primaryMentor)
  const productKeyActive = productKeyStatus === 'active' || productKeyStatus === null
  const productKeyHasWarning = productKeyStatus === 'warning' || productKeyStatus === 'due'
  const cadetName =
    _cadetName ||
    (primaryMentor && primaryMentor.cadet_last_name
      ? `${primaryMentor.cadet_first_name} ${primaryMentor.cadet_last_name}`
      : null)
  const statusIconNode = StatusIcon(getMentorExpiryStatus(primaryMentor))

  return (
    <>
      {!hideName && (
        <span style={{ color: productKeyStatus === 'expired' ? '#4e4e4e' : undefined }}>
          {cadetName || '[Not set up yet]'}
        </span>
      )}
      {!hideIcon && !(hideIconIfActive && productKeyActive) && !(hideWarningIcons && productKeyHasWarning) && (
        <span style={{ marginTop: -5, display: 'inline-block', verticalAlign: 'middle' }}>
          {hideTooltip ? (
            <>&nbsp;{statusIconNode}</>
          ) : (
            <InfoTooltip
              placement={tooltipPlacement}
              content={getProductKeyStatusTooltip(productKeyStatus)}
              tooltipStyle={{ width: productKeyActive ? 160 : 240 }}
              containerStyle={{ marginLeft: 5, height: 24, cursor: 'pointer' }}
              children={statusIconNode}
            />
          )}
        </span>
      )}
    </>
  )
}
