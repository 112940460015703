import React, { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { SVG } from 'common/SVG'
import { ParticipantState } from 'shared/session/types'
import { useUserInputState } from 'session/UserInputState'

interface Props {
  participant: ParticipantState
}

export const UserInputIndicator: React.FC<Props> = ({ participant }) => {
  const { lastInteractions } = useUserInputState()
  const [active, setActive] = useState<boolean>(false)
  const lastEdited = useRef<number>(0)
  const hideTimeout = useRef<number>(0)
  useEffect(() => {
    const finishAnimation = () => {
      hideTimeout.current = 0
      setActive(false)
    }
    if (active) hideTimeout.current = setTimeout(finishAnimation, 2000)
    const lastInteraction = lastInteractions[participant.profile.uid]
    if (lastInteraction && lastInteraction !== lastEdited.current) {
      lastEdited.current = lastInteraction
      setActive(true)
      hideTimeout.current = setTimeout(finishAnimation, 2000)
    }
    return () => {
      if (hideTimeout.current) clearTimeout(hideTimeout.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastInteractions, participant.profile.uid])

  return (
    <IconWrapper active={active}>
      <SVG size={12} viewboxSize={24}>
        <path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
      </SVG>
    </IconWrapper>
  )
}

export const tilt = keyframes`
  from {
    transform: rotate(-15deg);
  }
  to {
    transform: rotate(5deg);
  }
`

const IconWrapper = styled.div<{ active?: boolean }>`
  transition: opacity 0.4s linear;
  opacity: ${p => (p.active ? 1 : 0)};
  animation: ${p =>
    p.active
      ? css`
          ${tilt} 0.35s linear infinite alternate
        `
      : 'none'};
  & svg > path {
    fill: #666;
  }
`
