import { Skill, SkillUse, PresetModule, WorkflowType, Module } from 'skill-tracker/types'
import _find from 'lodash/find'
import _uniq from 'lodash/uniq'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'
import { sortByKey } from 'utils/sortUtils'
import { State } from './SkillTrackerState'
import { modules as presetModules } from './constants/modules'

export const hydrateDaySkillUses = (moduleId: Module['id'] | null, state: State, date: string): SkillUse[] => {
  const moduleSkills = getModuleSkills(moduleId, state)
  const moduleSkillIds = moduleSkills.map(({ id }) => id)
  const skillUses: SkillUse[] = state.skillUses.filter(
    skillUse => skillUse.date === date && moduleSkillIds.indexOf(skillUse.skillId) >= 0
  )
  for (let skill of moduleSkills) {
    for (let skillIndex = 0; skillIndex < (skill.dailyTarget || 0); skillIndex++) {
      if (!_find(skillUses, { date, skillId: skill.id, skillIndex })) {
        skillUses.push({
          id: Date.now().toString(),
          date,
          time: '',
          skillId: skill.id,
          skillIndex,
          skill,
          active: false,
        })
      }
    }
  }
  return skillUses.sort(sortByKey('skillId'))
}

export const getSkillsValidForBonus = (moduleSkills: Skill[], skillUses: SkillUse[]): Skill[] => {
  if (!moduleSkills.length) return []
  skillUses = skillUses.filter(({ approved }) => approved)
  if (!skillUses.length) return []
  const skillCounts: { [key: string]: number } = {}
  for (let skillUse of skillUses) {
    if (!(skillUse.skillId in skillCounts)) skillCounts[skillUse.skillId] = 1
    else skillCounts[skillUse.skillId]++
  }
  return Object.keys(skillCounts)
    .map(skillId => _find(moduleSkills, { id: skillId }))
    .filter(_ => _)
    .filter(skill => skill && skillCounts[skill.id] >= (skill.dailyTarget || 0)) as Skill[]
}

export const getSkillsValidForBonusForDate = (moduleId: Module['id'] | null, state: State, date: string): Skill[] =>
  !moduleId ? [] : getSkillsValidForBonus(state.skills, hydrateDaySkillUses(moduleId, state, date))

export const checkBonusesAvailableForDaySkills = (moduleSkills: Skill[], skillUses: SkillUse[]): boolean =>
  getSkillsValidForBonus(moduleSkills, skillUses).length > 0

export const getCumulativeBonusScoreForDate = (date: string, allDaySkills: SkillUse[]): number =>
  allDaySkills.filter(skillUse => skillUse.active && skillUse.bonus && skillUse.date <= date).length

export const bonusAwardableForDates = (dates: Date[], moduleId: Module['id'] | null, state: State) =>
  !moduleId
    ? false
    : dates
        .filter(date => !isAfter(date, new Date()))
        .reduce(
          (bool, date) =>
            bool
              ? bool
              : checkBonusesAvailableForDaySkills(
                  getModuleSkills(moduleId, state),
                  hydrateDaySkillUses(moduleId, state, format(date, 'y-MM-dd'))
                ),
          false
        )

export const hasBonusScore = (state: State): boolean => {
  const moduleIds = state.modules
    .filter(({ id, completed }) => completed || id === state.activeModuleId)
    .map(({ id }) => id)
  const skillIds = state.skills.filter(({ moduleId }) => moduleIds.indexOf(moduleId) >= 0).map(({ id }) => id)
  return state.skillUses.filter(({ skillId, bonus }) => bonus && skillIds.indexOf(skillId) >= 0).length > 0
}

// export const getModuleSkills = (moduleId: Module['id'] | null, allSkills: Skill[]): Skill[] =>
export const getModuleSkills = (moduleId: Module['id'] | null, state: State): Skill[] => {
  if (!moduleId) return []
  let skills = state.skills.filter(skill => skill.moduleId === moduleId)
  if (state.workflow === 'whole') {
    const module = _find(state.modules, { id: moduleId })
    if (module && module.type === 'preset' && module.presetModuleId) {
      const presetModule = _find(presetModules, { id: module.presetModuleId })
      if (presetModule) {
        const secondaryPresetModule = _find(presetModules, { module: presetModule.module, submodule: 'b' })
        if (secondaryPresetModule) {
          skills = [...skills, ...state.skills.filter(skill => skill.moduleId === secondaryPresetModule.id)]
        }
      }
    }
  }
  return skills.filter(skill => !skill.removed).sort(sortByKey('id'))
}

export const getConfirmedModuleSkills = (moduleId: Module['id'] | null, state: State): Skill[] => {
  return getModuleSkills(moduleId, state).filter(({ confirmed }) => confirmed)
}

export const getModuleTitle = (module: Module, workflow: State['workflow']) => {
  if (module.type === 'custom') return module.title
  const presetModule = !module.presetModuleId ? undefined : presetModules.find(({ id }) => id === module.presetModuleId)
  return `Module ${workflow === 'whole' && presetModule ? presetModule.module : module.id}`
}

export const getModuleDates = (module: Module) => {
  if (!module.startDate || !module.duration) return []
  const startDate = parse(module.startDate, 'y-MM-dd', Date.now())
  const dates = [startDate]
  for (let day = 1; day < module.duration; day++) dates.push(addDays(startDate, day))
  return dates
}

export const getModuleDatesWithoutSkillUses = (module: Module | undefined, skillUses: SkillUse[]): Date[] => {
  const dates: Date[] = []
  if (!module) return dates
  const allDates = getModuleDates(module)
  for (let date of allDates) {
    if (!_find(skillUses, { moduleId: module.id, date: format(date, 'y-MM-dd') })) dates.push(date)
  }
  return dates
}

export const getSkillUsesForModuleOnDate = (moduleId: Module['id'], date: string, state: State): SkillUse[] => {
  const moduleSkillIds = getModuleSkills(moduleId, state).map(({ id }) => id)
  const moduleSkillUses = state.skillUses.filter(skillUse => moduleSkillIds.indexOf(skillUse.skillId) >= 0)
  return moduleSkillUses.filter(skillUse => skillUse.date === date)
}

export const getLatestStartedModule = (modules: Module[]): Module | undefined => {
  const startedModules = modules
    .filter(({ started, completed }) => started && !completed)
    .sort(sortByKey('startDate', 'descending'))
  return startedModules.length > 0 ? startedModules[0] : undefined
}

export const getLastCompletedModule = (modules: Module[]): Module | undefined => {
  const completedModules = modules
    .filter(({ completed }) => completed)
    .sort(sortByKey('completionDateTime', 'descending'))
  return completedModules.length > 0 ? completedModules[0] : undefined
}

export const getWorkflowModules = (allModules: PresetModule[], workflow: WorkflowType | null): PresetModule[] => {
  if (!workflow) return []
  if (workflow === 'split') return allModules

  const moduleNumbers = allModules.reduce(
    (arr, module) =>
      typeof module.module !== 'string' && arr.indexOf(module.module) < 0 ? [...arr, module.module] : arr,
    [] as number[]
  )
  return moduleNumbers.map(number => {
    const modulesToCombine = allModules.filter(({ module }) => module === number)
    return {
      ...modulesToCombine[0],
      submodule: '', //modulesToCombine.map(({ submodule }) => submodule).join('+'),
    }
  })
}

export const getSkillUsesForSkill = (allSkillUses: SkillUse[], skill: Skill): SkillUse[] =>
  allSkillUses.filter(skillUse => skillUse.skillId === skill.id)

export const getSkillUsesForSkills = (allSkillUses: SkillUse[], skills: Skill[]): SkillUse[] => {
  const skillIds = skills.map(({ id }) => id)
  return allSkillUses.filter(skillUse => skillIds.indexOf(skillUse.skillId) >= 0)
}

export const skillBeingUsed = (skill: Skill, skillUses: SkillUse[]) => getSkillUsesForSkill(skillUses, skill).length > 0

export const getBonusSkillUses = (allSkillUses: SkillUse[]): SkillUse[] =>
  allSkillUses.filter(({ active, bonus }) => active && bonus)

export const getUsedSymbols = (module: Module, skills: Skill[]): string[] => {
  const symbols: Skill['symbol'][] = _uniq(
    skills.filter(skill => skill.moduleId === module.id).map(skill => skill.symbol)
  )
  return symbols.filter(symbol => symbol !== null) as string[]
}
