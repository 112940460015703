import React, { useRef, useCallback, useContext, useMemo, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useDrag } from 'react-use-gesture'

import { Column, Row, Spacer } from './ui'
import { useMeasure } from 'utils/useMeasure'
import { fontRegular } from 'fonts'
import { Option } from 'types'
import { intersperse } from 'utils/intersperse'
import { ScreenScalerContext } from './ScreenScaler'
import { deselectAll } from 'utils/selectionUtils'

export type EmotionometerColors = [string, string, string]

export const emptyColors: EmotionometerColors = ['#eef0f8', '#cbd0de', '#a6b0c3']
export const disabledColors: EmotionometerColors = ['#eef0f8', '#eef0f8', '#eef0f8']
export const angryColors: EmotionometerColors = ['#f7d1bf', '#f29a79', '#f05133']
export const anxiousColors: EmotionometerColors = ['#dec6e0', '#c28cbe', '#8a4d9e']
export const relaxedColors: EmotionometerColors = ['#d1e9cd', '#98cf8e', '#54b948']
export const sadColors: EmotionometerColors = ['#68abd6', '#0071b9', '#15498b']
export const happyColors: EmotionometerColors = ['#fbd594', '#f6a924', '#f57f00']

interface Props {
  colors?: EmotionometerColors
  value?: number
  onValueChange?: (value: number) => void
  selectedOption?: Option | null
  options?: Option[]
  onChange?: (option: Option) => void
  disabled?: boolean
  readOnly?: boolean
}

const bars = [0, 0, 0, 1, 1, 1, 1, 2, 2, 2]
const grey = bars.map(i => emptyColors[i])
const Emotionometer: React.FC<Props> = ({
  value = 0.5,
  onChange,
  options = [],
  selectedOption,
  disabled = false,
  onValueChange,
  readOnly = false,
  colors,
}) => {
  const theme = useContext(ThemeContext)
  const { zoom } = useContext(ScreenScalerContext)
  const actualColors = colors || (theme.emotionometerColors as EmotionometerColors)
  const gradients = useMemo(() => bars.map(i => actualColors[i]), [actualColors])
  const [measureRef, { width }] = useMeasure()
  const barDivs = useRef<HTMLDivElement[]>([])
  const register = useCallback(bar => bar && barDivs.current.push(bar), [])
  const adjustedWidth = width - 28
  const readOnlyRef = useRef(disabled || readOnly)
  readOnlyRef.current = disabled || readOnly
  const handleRef = useRef<HTMLButtonElement | null>(null)
  const optionRef = useRef<Option | null>(selectedOption || null)
  if (selectedOption !== undefined) {
    optionRef.current = selectedOption
  }
  const valueRef = useRef(value)
  value = valueRef.current
  // disable iOS dragging the entire screen when dragging the handle
  useEffect(() => {
    if (handleRef.current) {
      handleRef.current.addEventListener('touchmove', e => e.preventDefault(), { passive: false })
    }
  }, [])
  const bind = useDrag(({ initial: [initialX], movement: [deltaX], memo, first, last, event, cancel }) => {
    if (readOnlyRef.current) {
      cancel && cancel()
      return
    }
    if (first) {
      deselectAll()

      const mouseEvent = event as React.MouseEvent<HTMLDivElement, MouseEvent>
      const eventTarget = (mouseEvent.target as unknown) as HTMLDivElement
      mouseEvent.preventDefault()
      // change the initial x depending on if we are dragging the slider or button element.
      // this prevents our button from "jumping" to a new position when clicked.
      if (eventTarget.nodeName !== 'BUTTON') {
        const rect = eventTarget.getBoundingClientRect()
        memo = (initialX - rect.left) * zoom - 12 // 12 is half button width
      } else {
        memo = valueRef.current * adjustedWidth
      }
    }
    const newX = memo + deltaX * zoom
    const newValue = Math.max(0, Math.min(1, newX / adjustedWidth))
    bars.forEach(
      (_, i) => (barDivs.current[i].style.backgroundColor = !i || newValue > i / 10 ? gradients[i] : grey[i])
    )
    if (handleRef.current) {
      const x = newValue * adjustedWidth
      handleRef.current.style.transform = `translateX(${x}px)`
    }
    valueRef.current = newValue
    last && onValueChange && onValueChange(newValue)
    if (onChange) {
      const option = options[Math.max(0, Math.min(Math.floor(newValue / (1 / options.length)), options.length - 1))]
      if (option && (!optionRef.current || option.value !== optionRef.current.value)) {
        optionRef.current = option
        onChange(option)
      }
    }
    return memo
  })
  const x = value * adjustedWidth
  return (
    <Column flex ref={measureRef}>
      <Row flex>
        <Spacer flex="1 1 0px" />
        {bars
          .map((_, i) => (
            <Column flex="3 1 0px" key={i}>
              <Bar
                ref={register}
                flex={`0 0 ${(i + 1) * 10}%`}
                style={{ backgroundColor: !disabled && (!i || value > i / 10) ? gradients[i] : grey[i] }}
              />
            </Column>
          ))
          .reduce(
            intersperse(i => <Spacer key={`s${i}`} flex="1 1 0px" />),
            []
          )}
        <Spacer flex="1 1 0px" />
      </Row>
      <Spacer />
      <Slider {...(!disabled ? bind() : {})} colors={disabled ? disabledColors : actualColors}>
        <Handle ref={handleRef} style={{ transform: `translateX(${x}px)` }} disabled={disabled || readOnly} />
      </Slider>
      {options.length > 0 && (
        <>
          <Spacer size="s" />
          <Row>
            {options.map(({ label }, i) => (
              <Label key={i}>{disabled ? '\u00A0' : label}</Label>
            ))}
          </Row>
        </>
      )}
    </Column>
  )
}

export default Emotionometer

const Bar = styled(Column)`
  background: #eef0f8;
  border-top: 4px solid #abb4db;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  margin-top: auto;
`

const Slider = styled.div<{ colors: EmotionometerColors }>`
  box-sizing: border-box;
  position: relative;

  border-radius: 15px;
  border-top: 2px solid #abb4db;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.4);

  height: 30px;
  background: ${p =>
    `linear-gradient(
        90deg,
        ${p.colors[0]} 0%,
        ${p.colors[1]} 50%,
        ${p.colors[2]} 100%
      )`};
`

const Handle = styled.button`
  outline: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  position: absolute;
  box-sizing: border-box;
  top: -2px;
  left: 0px;
  width: 28px;
  height: 28px;
  background: linear-gradient(180deg, #eaecf6 0%, white 100%);
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0px 2px 0px 0px #abb4db;

  :enabled {
    cursor: pointer;
  }

  :disabled {
    pointer-events: none;
  }

  :active,
  :focus {
    background: linear-gradient(180deg, #d5d9ed 0%, white 100%);
  }

  // increase touch target size
  ::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    width: 58px;
    height: 58px;
  }
`
const Label = styled.div`
  flex: 1 1 0px;

  ${fontRegular}
  font-size: 15px;
  color: #001947;

  // border-top: 1px solid #abb4db;
  text-align: center;

  // :first-child {
  //   text-align: left;
  // }

  // :last-child {
  //   text-align: right;
  // }
`
