import styled from 'styled-components'
import { Panel } from 'common/ui'

export const ETellPanel = styled(Panel)`
  width: 100%;
  height: 100%;
  // max-height: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  // margin-top: auto;
  // margin-bottom: auto;
  overflow: hidden;
  border-radius: 7px;
`
