import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Game } from 'phaser'

import Sound, { getAudioContext, getMasterGainNode } from 'app/Sound'
import AudioVisualiserScene from 'phaser/components/AudioVisualiser'
import { useSoundPlayingFallback } from 'utils/useSoundPlaying'
import { getGameConfig, destroyGame } from 'utils/gameUtils'
import { useGlobalState } from 'app/GlobalState'
import { THEMES } from 'themes'
import { Absolute } from './ui'
import { useMeasure } from 'utils/useMeasure'
import { getPixelRatio } from 'utils/screenUtils'

interface Props {
  sound?: Sound
  connected?: boolean
  width?: number | string
  height?: number | string
}

export const AudioVisualiser: React.FC<Props> = ({ sound, connected, width, height }) => {
  const { themeIndex } = useGlobalState()
  const theme = THEMES[themeIndex]
  const [scene, setScene] = useState<AudioVisualiserScene | null>(null)
  const connectedStatus = useSoundPlayingFallback(sound, connected)
  const phaserDiv = useRef<HTMLDivElement>(null)
  const [measureRef, { width: containerWidth, height: containerHeight }] = useMeasure()

  useEffect(() => {
    if (!phaserDiv.current) return
    const rect = phaserDiv.current.getBoundingClientRect()
    const game = new Game({
      ...getGameConfig(),
      render: {
        antialias: true,
        transparent: true,
      },
      resolution: getPixelRatio(),
      scale: {
        mode: Phaser.Scale.NONE,
        width: rect.width,
        height: rect.height,
      },
      parent: phaserDiv.current,
      scene: [AudioVisualiserScene],
      callbacks: {
        postBoot: game => {
          const scene = game.scene.scenes[0] as AudioVisualiserScene
          scene.setAudioContext(getAudioContext(), getMasterGainNode())
          setScene(scene)
        },
      },
    })
    return () => destroyGame(game)
  }, [])

  useEffect(() => {
    scene && scene.setConnected(connectedStatus)
  }, [connectedStatus, scene])

  useEffect(() => {
    scene && scene.setTheme(theme)
  }, [theme, scene])

  // re-connect audio context when window gains focus
  useEffect(() => {
    const cb = () => {
      scene && scene.setAudioContext(getAudioContext(), getMasterGainNode())
    }
    window.addEventListener('focus', cb)
    return () => {
      window.removeEventListener('focus', cb)
    }
  }, [scene])

  useEffect(() => {
    if (containerWidth && scene) {
      scene.scale.resize(containerWidth, containerHeight)
    }
  }, [containerWidth, containerHeight, scene])

  return (
    <div ref={measureRef} style={{ width, height, flex: 'auto', position: 'relative' }}>
      <CanvasContainer ref={phaserDiv} cover />
    </div>
  )
}

const CanvasContainer = styled(Absolute)`
  > canvas {
    border-radius: 10px;
  }
`
