import React from 'react'
import styled from 'styled-components'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { Gadget, EmotionometerRange, GadgetUseLocation, RouteProps } from 'shared/e-telligence/types'
import {
  ADD_RELAXATION_GADGET,
  AddRelaxationGadgetAction,
  REMOVE_RELAXATION_GADGET,
  RemoveRelaxationGadgetAction,
} from 'shared/e-telligence/actionTypes'

import { Column, Row, H3, P, Spacer, Button, H2, CollapseMargin, Padding } from 'common/ui'
import { ETellPanel } from './common/ETellPanel'
import { EmotionometerBar } from './common/EmotionometerBar'

import { baseUrl } from './common/Navigation'
import { presetGadgets } from './constants/gadgets'
import { emotionRangeDistribution } from './constants/typeValueMaps'
import { createListString, capitalizeFirstLetter } from 'utils/stringUtils'

import { useUserState } from 'app/UserState'
import { useETelligenceState } from './ETelligenceState'
import { useMedia } from 'utils/useMedia'
import { findGadgetById } from './stateUtils'

export const emotionRangeMap: { [key in EmotionometerRange]: string } = {
  '1-3': 'low',
  '4-7': 'medium',
  '8-10': 'high',
}
export const emotionRangeGroup: { [key in EmotionometerRange]: number } = { '1-3': 0, '4-7': 1, '8-10': 2 }
export const locationMap: { [key in GadgetUseLocation]: string } = {
  anywhere: 'Anywhere',
  home: 'Home',
  school: 'School (if allowed)',
  out: 'When you are out',
  out_appropriate: 'When you are out (if possible and appropriate)',
}
const locationIcons: { [key in GadgetUseLocation]: any } = {
  anywhere:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
  home: require('./assets/locations/home.svg')?.default,
  school: require('./assets/locations/school.svg')?.default,
  out: require('./assets/locations/out.svg')?.default,
  out_appropriate: require('./assets/locations/out.svg')?.default,
}

export const getSituationSentenceForGadget = (gadget: Gadget): string => {
  const { emotion, emotionRange } = gadget
  let str = ''
  if (emotionRange.length === 1) str += emotionRangeMap[emotionRange[0]] + ' level of '
  else if (emotionRange.length >= 2) {
    const low = emotionRange.sort()[0]
    const high = emotionRange.sort()[emotionRange.length - 1]
    str += `${emotionRangeMap[low]} to ${emotionRangeMap[high]} levels of `
  }
  if (emotion.length > 0) str += createListString(emotion, 'or')
  else str += 'emotion'
  return capitalizeFirstLetter(str + '.')
}

interface Props {
  isAdding?: boolean
  hideBackButton?: boolean
  gadgetId?: string
  buttonText?: { add: string; remove: string }
  overrideButtonClick?: () => void
  overrideEditClick?: (id: string) => void
  readOnly?: boolean
}

export const RelaxationGadgetView: React.FC<Props> = ({ isAdding }) => {
  const { state, dispatch } = useETelligenceState()
  const { usingIOSvhFix } = useUserState()
  return (
    <GadgetPanel style={{ height: usingIOSvhFix ? '100%' : 'calc(100vh - 20px)' }}>
      <RelaxationGadgetViewInner isAdding={isAdding} state={state} dispatch={dispatch} baseUrl={baseUrl} />
    </GadgetPanel>
  )
}

export const RelaxationGadgetViewInner: React.FC<RouteProps & Props> = ({
  isAdding,
  state,
  dispatch,
  baseUrl,
  hideBackButton,
  gadgetId,
  buttonText,
  overrideButtonClick,
  overrideEditClick,
  readOnly,
}) => {
  const { gameMode } = useUserState()
  const history = useHistory()
  const location = useLocation()
  let { id } = useParams<{ id: string }>()
  id = gadgetId ? gadgetId : id

  const { getBaseAction } = useUserState()
  const portrait = useMedia('(orientation: portrait)')

  const stateGadget = findGadgetById(id, state)
  const gadgetIndex = stateGadget ? state.gadgetIds.indexOf(stateGadget.id) : -1
  const presetGadget = presetGadgets.find(test => test.id === id)
  const customGadget = state.customGadgets.find(test => test.id === id)
  const gadget = stateGadget || presetGadget || customGadget

  if (!gadget) return null

  const handleAdd = () => {
    const gadgetIndex = state.gadgetIds.length
    dispatch({ ...getBaseAction(), type: ADD_RELAXATION_GADGET, gadgetId: gadget.id } as AddRelaxationGadgetAction)

    // accessed via digiHq
    if (!overrideButtonClick && gameMode === 'SAS-SG-D') {
      return history.push(`/gadget-pack/card-holder/relaxation-gadgets`)
    }

    overrideButtonClick
      ? overrideButtonClick()
      : setTimeout(() =>
          history.push(`${baseUrl}/home#${gadget.id}`, {
            ...(location.state || {}),
            showGadget: gadget.id,
            gadgetIndex,
          })
        )
  }

  const handleRemove = () => {
    dispatch({
      ...getBaseAction(),
      type: REMOVE_RELAXATION_GADGET,
      gadgetId: gadget.id,
    } as RemoveRelaxationGadgetAction)

    // accessed via digiHq
    if (!overrideButtonClick && gameMode === 'SAS-SG-D') {
      return history.push(`/gadget-pack/card-holder/relaxation-gadgets`)
    }

    overrideButtonClick
      ? overrideButtonClick()
      : setTimeout(() =>
          history.push(baseUrl + '/home', { ...(location.state || {}), hideGadget: gadget.id, gadgetIndex })
        )
  }

  return (
    <>
      <ScrollColumn flex="1 1 auto">
        <Row>
          <HeaderRow flex="1 1 auto" padding="s" alignItems="center" style={{ maxHeight: 60 }}>
            <Column flex="1 1 auto" paddingLeft={10} paddingRight={10}>
              <H2>{gadget.title}</H2>
            </Column>
          </HeaderRow>
          {!hideBackButton && (
            <Column flex="none" alignItems="flex-start" marginLeft={portrait ? 20 : 60}>
              <Padding size="xs">
                <Button onClick={() => history.push(baseUrl + (isAdding ? '/relaxation-gadgets' : '/home'))} size="s">
                  Back
                </Button>
              </Padding>
            </Column>
          )}
        </Row>
        <Row>
          <Column
            flex={portrait ? '1 1 35%' : '1 1 50%'}
            padding="s"
            paddingBottom={0}
            style={{ borderRight: '1px solid #ABB4DB' }}>
            <H3 marginBottom="s">Gadget:</H3>
            {gadget.isPreset ? (
              <img
                src={require('./assets/gadgets/' + gadget.id + '.png')}
                alt={gadget.title}
                style={{ width: '100%', maxWidth: 144 }}
              />
            ) : (
              gadget.symbol && <span style={{ fontSize: 69 }}>{gadget.symbol}</span>
            )}
          </Column>
          <Column flex={portrait ? '1 1 65%' : '1 1 50%'} padding="s">
            <H3 marginBottom="s">Where to use it:</H3>
            <CollapseMargin>
              {gadget.location.map((location, i) => (
                <Row key={i} alignItems="center">
                  {location !== 'anywhere' && (
                    <>
                      <Column>
                        <img src={locationIcons[location]} width={24} height={24} alt={locationMap[location]} />
                      </Column>
                      <Spacer size="s" />
                    </>
                  )}
                  <Column flex="1 1 auto">
                    <P>{locationMap[location]}</P>
                  </Column>
                </Row>
              ))}
            </CollapseMargin>
          </Column>
        </Row>
        <Column padding="s" style={{ borderTop: '1px solid #ABB4DB' }}>
          <H3>Best suited for:</H3>
          <P>{getSituationSentenceForGadget(gadget)}</P>
          <EmotionometerBar
            flex="1 0 auto"
            type={gadget.emotion[gadget.emotion.length - 1]}
            value={gadget.emotionRange.map(str => emotionRangeGroup[str])}
            distribution={emotionRangeDistribution}
          />
        </Column>
        <Spacer size="s" />
        {gadget.description && (
          <Column padding="s" style={{ borderTop: '1px solid #ABB4DB' }}>
            <H3>How to use it:</H3>
            {typeof gadget.description === 'string' ? (
              <P style={{ whiteSpace: 'pre-wrap' }}>{gadget.description}</P>
            ) : (
              gadget.description
            )}
          </Column>
        )}
        <Row flex="1 1 auto" />
      </ScrollColumn>
      {!readOnly && (
        <Row justifyContent="center" padding="s">
          <Button
            onClick={gadgetIndex >= 0 ? handleRemove : handleAdd}
            size={gadgetIndex >= 0 && portrait ? 'xs' : 's'}>
            {buttonText
              ? gadgetIndex >= 0
                ? buttonText.remove
                : buttonText.add
              : gadgetIndex >= 0
              ? gameMode === 'SAS-SG-D'
                ? 'Remove from Top 5'
                : 'Remove from Card Holder'
              : gameMode === 'SAS-SG-D'
              ? 'Add to Top 5'
              : 'Add to Card Holder'}
          </Button>
          {gadget && !gadget.isPreset && (
            <>
              <Spacer size="xs" />
              <Button
                onClick={() =>
                  overrideEditClick
                    ? overrideEditClick(gadget.id)
                    : history.push(`${baseUrl}/relaxation-gadgets/edit/${gadget.id}`, location.state)
                }
                size={gadgetIndex >= 0 && portrait && !gadget.isPreset ? 'xs' : 's'}>
                Edit
              </Button>
            </>
          )}
        </Row>
      )}
    </>
  )
}

const GadgetPanel = styled(ETellPanel)`
  overflow: none;
  border-radius: 12px;
  background-color: white;

  & h2,
  & h3 {
    font-size: 1rem;
    text-transform: initial;
    letter-spacing: 0;
  }
`
const ScrollColumn = styled(Column)`
  overflow: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  &::before {
    content: '';
    position: absolute;
    height: 75px;
    top: 0;
    left: 0;
    right: 0;

    background: linear-gradient(to bottom, #00b8f1, white);
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`

// http://lea.verou.me/2011/03/beveled-corners-negative-border-radius-with-css3-gradients/
const HeaderRow = styled(Row)`
  background: linear-gradient(315deg, transparent 10px, #22acf3 0) bottom right;
  background-color: #22acf3;
  background-size: 50% 50%;
  background-repeat: no-repeat;

  & h2 {
    color: white;
  }
`
