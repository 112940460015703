import React, { useState, useEffect, useRef, CSSProperties } from 'react'
import addMinutes from 'date-fns/addMinutes'
import formatDistance from 'date-fns/formatDistance'
import isBefore from 'date-fns/isBefore'

import { SessionEntity } from 'shared/dashboard/types'
import { LinkButton } from 'common/LinkButton'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { sessionIsToday, getSessionStartDate } from 'dashboards/utils/sessionUtils'
import { sortByKey } from 'utils/sortUtils'

export const MINUTES_EARLY_ALLOWED = 10

export const getJoinableSessions = <T extends SessionEntity>(sessions: T[]) =>
  sessions.filter(session => {
    const startDate = getSessionStartDate(session)
    return startDate && session.group.facilitator_id !== null
      ? isBefore(addMinutes(startDate, -MINUTES_EARLY_ALLOWED), Date.now())
      : false
  })

export const MeetingJoinButton: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  const [sessions, { loaded, loading, errorLoading }] = useEndpoint<SessionEntity[]>(`/api/v1/sessions/future`)
  const [sessionsToday, setSessionsToday] = useState<SessionEntity[]>([])
  const [, reRender] = useState(0)
  const initialized = useRef<boolean>(false)
  useEffect(() => {
    let reRenderTimeoutRef = 0
    if (initialized.current || !loaded || loading || errorLoading || !sessions) return
    const sessionsToday = sessions
      .filter(session => sessionIsToday(session) || session.catch_up_active)
      .sort(sortByKey<SessionEntity>('start_datetime'))
      .sort(sortByKey<SessionEntity>('catch_up_active', 'descending'))
    setSessionsToday(sessionsToday)
    if (sessionsToday.length > 0 && !getJoinableSessions(sessionsToday).length) {
      reRenderTimeoutRef = setTimeout(() => reRender(v => v + 1), 1000)
    }
    return () => {
      if (reRenderTimeoutRef) clearTimeout(reRenderTimeoutRef)
    }
  }, [errorLoading, loaded, loading, sessions])

  const joinableSessions = getJoinableSessions(sessionsToday)

  return (
    <LinkButton
      size="m"
      theme={loading || !sessionsToday.length ? 'grey' : undefined}
      disabled={!joinableSessions.length}
      href="_blank"
      to={`/meetings/${joinableSessions.length > 0 ? joinableSessions[0].uid : ''}`}
      style={{ maxHeight: 45, ...style }}
      children={
        loading ? (
          <>👀&nbsp;&nbsp;&nbsp;Looking for Club Meetings&nbsp;&nbsp;👀</>
        ) : sessionsToday.length > 0 ? (
          joinableSessions.length > 0 ? (
            <>⭐️&nbsp;&nbsp;&nbsp;Join Live Club Meeting&nbsp;&nbsp;⭐</>
          ) : (
            <>
              ⏱&nbsp;&nbsp;&nbsp;Live Club Meeting starts{' '}
              {sessionsToday[0]?.start_datetime
                ? formatDistance(getSessionStartDate(sessionsToday[0]) as Date, Date.now(), { includeSeconds: true })
                : 'later today'}
              &nbsp;&nbsp;⏱
            </>
          )
        ) : (
          <>🚫&nbsp;&nbsp;&nbsp;No Live Club Meetings TODAY&nbsp;&nbsp;🚫</>
        )
      }
    />
  )
}
