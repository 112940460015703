import React, { useContext, useEffect, useState, useRef, useMemo } from 'react'
import { saveSessionInputValue, createSessionInputValue } from 'api'
import _isEqual from 'lodash/isEqual'

import { InputValue, SavedInputValue, InputValueContext, JSONValueType } from 'shared/session/types'
import { ADD_UPDATE_INPUT_VALUE, ADD_UPDATE_SAVED_INPUT_VALUE } from 'shared/session/actionTypes'

import { useUserState } from 'app/UserState'
import { useGenericUser } from 'app/useGenericUser'
import { useSessionState } from './SessionState'
import { useUserInputState } from './UserInputState'
import { objectValuesChanged } from './utils/comparisonUtils'

type InputContextState = ReturnType<typeof useProviderInputContext>

const emptyContext: InputValueContext = {
  session_uid: '',
  participant_uid: '',
  module_id: 0,
  owner: '',
  owner_id: 0,
  name: 'input',
}

function useProviderInputContext() {
  const user = useGenericUser()
  const { sessionData } = useSessionState()
  const [inputContextObj, setInputContextObj] = useState<InputValueContext>({
    ...emptyContext,
    ...(sessionData ? { session_uid: sessionData.uid, module_id: sessionData.module.id } : {}),
  })

  useEffect(() => {
    if (
      sessionData &&
      (sessionData.uid !== inputContextObj.session_uid || sessionData.module.id !== inputContextObj.module_id)
    )
      setInputContextObj({ ...inputContextObj, session_uid: sessionData.uid, module_id: sessionData.module.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData])

  useEffect(() => {
    if (user.uid !== inputContextObj.participant_uid)
      setInputContextObj({ ...inputContextObj, participant_uid: user.uid })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid])

  return inputContextObj
}

export const InputContext = React.createContext<InputContextState>(emptyContext)

export const InputContextProvider: React.FC<{ overwriteContext?: Partial<InputValueContext> }> = ({
  children,
  overwriteContext = {},
}) => {
  let value = useProviderInputContext()
  return <InputContext.Provider value={{ ...value, ...overwriteContext }}>{children}</InputContext.Provider>
}

export function useInputContext() {
  return useContext(InputContext)
}

const removeUndefinedValues = <ObjType extends object>(obj: ObjType): ObjType => {
  // @ts-ignore
  // prettier-ignore
  return Object.keys(obj).reduce((newObj, key) => (obj[key] !== undefined ? { ...newObj, [key]: obj[key] } : newObj), {})
}

/**
 * Hooks to fetch multiple input values
 */

export function useSavedInputValueObjects<ValueType extends any>(
  overwriteContext: Partial<InputValueContext> = {}
): SavedInputValue<ValueType>[] {
  const _inputContextObj = useInputContext()
  const inputContextObj: InputValueContext = removeUndefinedValues({ ..._inputContextObj, ...overwriteContext })
  const { getSavedInputValues } = useUserInputState()
  const savedInputValues = getSavedInputValues<ValueType>(inputContextObj.participant_uid, inputContextObj)
  return savedInputValues
}

export function useInputValueObjects<ValueType extends any>(
  overwriteContext: Partial<InputValueContext> = {}
): InputValue<ValueType>[] {
  const _inputContextObj = useInputContext()
  const inputContextObj: InputValueContext = removeUndefinedValues({ ..._inputContextObj, ...overwriteContext })
  const { getInputValues } = useUserInputState()
  const inputValues = getInputValues<ValueType>(inputContextObj.participant_uid, inputContextObj)
  return inputValues
}

export function useSavedInputValues<ValueType extends any>(
  overwriteContext: Partial<InputValueContext> = {}
): ValueType[] {
  const savedInputValueObjects = useSavedInputValueObjects<ValueType>(overwriteContext)
  return savedInputValueObjects.map(({ value }) => value)
}

export function useInputValues<ValueType extends any>(overwriteContext: Partial<InputValueContext> = {}): ValueType[] {
  const inputValueObjects = useInputValueObjects<ValueType>(overwriteContext)
  return inputValueObjects.map(({ value }) => value)
}

/**
 * Hooks to fetch single input value
 */

export function useSavedInputValueObject<ValueType extends any>(
  select: SavedInputValue<ValueType>['name'],
  overwriteContext: Partial<InputValueContext> = {}
): SavedInputValue<ValueType> | undefined {
  const savedInputValues = useSavedInputValueObjects<ValueType>({ ...overwriteContext, name: select })
  if (!savedInputValues.length) return undefined
  return savedInputValues[savedInputValues.length - 1]
}

export function useInputValueObject<ValueType extends any>(
  select: InputValue<ValueType>['name'],
  overwriteContext: Partial<InputValueContext> = {}
): InputValue<ValueType> | undefined {
  const inputValues = useInputValueObjects<ValueType>({ ...overwriteContext, name: select })
  if (!inputValues.length) return undefined
  if (inputValues.length > 1)
    console.warn(
      '[useInputValueObject] Multiple input values found',
      { ...overwriteContext, name: select },
      inputValues
    )
  return inputValues[inputValues.length - 1]
}

export function useSavedInputValue<ValueType extends any>(
  select: SavedInputValue<ValueType>['name'],
  overwriteContext: Partial<InputValueContext> = {}
): ValueType | undefined {
  const savedInputValueObj = useSavedInputValueObject<ValueType>(select, overwriteContext)
  return savedInputValueObj && savedInputValueObj.value
}

export function useInputValue<ValueType extends any>(
  select: InputValue<ValueType>['name'],
  overwriteContext: Partial<InputValueContext> = {}
): ValueType | undefined {
  const inputValueObj = useInputValueObject<ValueType>(select, overwriteContext)
  return inputValueObj && inputValueObj.value
}

export interface InputInterfaceOptions<ValueType extends any> {
  name: string
  contextOverride?: Partial<InputValueContext>
  defaultValue: ValueType
  socketDebounce?: number | false
  databaseDebounce?: number | false
}

export function useInputInterface<ValueType>({
  name,
  contextOverride,
  defaultValue,
  socketDebounce,
  databaseDebounce = 3000,
}: // TODO: include alternate getBaseAction function for use in session context?
InputInterfaceOptions<ValueType>): [ValueType, (val: ValueType) => void] {
  if (socketDebounce === undefined) socketDebounce = 250
  const user = useGenericUser()
  const { getBaseAction, sessionUserType } = useSessionState()
  const { accessToken } = useUserState()
  const { dispatch: userInputDispatch, getLatestInputAction, getSavedInputValue } = useUserInputState()
  const inheritedInputContext = useInputContext()
  const inputContext: InputValueContext = useMemo(
    () =>
      contextOverride === undefined
        ? { ...inheritedInputContext, name }
        : { ...inheritedInputContext, ...contextOverride, name },
    [contextOverride, inheritedInputContext, name]
  )
  const inputValueObj = useInputValueObject<ValueType>(name, inputContext)
  const lastInputContextRef = useRef<InputValueContext>(inputContext)

  const [value, setValue] = useState(inputValueObj ? inputValueObj.value : defaultValue)

  const socketTimeout = useRef<number>(0)
  const databaseTimeout = useRef<number>(0)

  const clearDatabaseTimeout = () => {
    if (databaseTimeout.current) {
      clearTimeout(databaseTimeout.current)
      databaseTimeout.current = 0
    }
  }

  const scheduleDatabaseSave = (inputValue: InputValue<ValueType>) => {
    if (databaseDebounce === false) return
    // used for practise sessions
    if (!!inputValue.session_uid.match(/^DISCARD_/) && inputValue.owner !== 'supplementary_slides') {
      console.log(`🗑 disregarding input save request because in DISCARD_ session`, inputValue)
      return
    }
    console.log(`Scheduling database save in ${databaseDebounce}ms`)
    const savedValue = getSavedInputValue<ValueType>(inputContext.participant_uid, inputContext)
    if (!savedValue)
      console.log('[useInputInterface] !!! Could not find saved value with', inputContext.participant_uid, inputContext)
    databaseTimeout.current = setTimeout(() => {
      if (savedValue) {
        saveSessionInputValue(savedValue.id, inputValue.value, savedValue.revision + 1, accessToken)
          .then(() => {
            console.log('[useInputInterface] Successfully patched input entry')
            handleSavedInputValue({ ...savedValue, value: inputValue.value, revision: savedValue.revision + 1 })
          })
          .catch(({ conflict }: { conflict?: SavedInputValue<ValueType> }) => {
            if (conflict) {
              // Saving to DB failed so we need to pre-emptively update our value to the latest version reported by DB
              console.log(
                '[useInputInterface] Unable to save to database due to conflict -- overwriting local with new version'
              )
              handleSavedInputValue(conflict)
            }
          })
      } else {
        createSessionInputValue(inputValue, accessToken)
          .then(handleSavedInputValue)
          .catch(({ conflict }: { conflict?: SavedInputValue<ValueType> }) => {
            if (conflict) {
              // Maybe don't need this??
              handleSavedInputValue(conflict)
            }
          })
      }
    }, databaseDebounce)
  }

  // This effect ensures the 'value' stays up to date with the latest inputContextObj value **
  // Needed because someone else could be updating the value
  useEffect(() => {
    if (!inputValueObj) {
      return setValue(defaultValue)
    }
    const causedByContextChange = objectValuesChanged(lastInputContextRef.current, inputContext)
    const latestAction = getLatestInputAction(inputContext.participant_uid, inputContext)
    if (causedByContextChange) {
      console.log('[useInputInterface] setting new value because context changed')
      setValue(inputValueObj.value)
    } else if (latestAction) {
      // Did someone else update this value? If so override your local copy
      // If it came from you then don't even bother updating local value
      if (latestAction.user_uid !== user.uid) {
        console.log('[useInputInterface] someone else updated this!')
        setValue(inputValueObj.value)

        // If there's a pending DB save then clear it
        clearDatabaseTimeout()
      } else {
        // latestAction can be undefined outside of session context which stopped saving happening
        if (sessionUserType && latestAction.role && latestAction.role !== sessionUserType) {
          console.log('[useInputInterface] somebody else updated this as you -- no need to save!')
          setValue(inputValueObj.value)
          clearDatabaseTimeout()
        } else {
          console.log('[useInputInterface] you updated this!')

          // This is set by shared input values that are controlled by a reducer
          if (latestAction.priorityDelivery) setValue(inputValueObj.value)

          // If there's a pending DB save then clear it
          clearDatabaseTimeout()
          scheduleDatabaseSave(inputValueObj)
        }
      }
    } else {
      console.log(
        '[useInputInterface] could not find any recent action changes in state, just going to overwrite local value'
      )
      setValue(inputValueObj.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueObj, inputValueObj?.value])

  // must be last useEffect
  useEffect(() => {
    if (!_isEqual(lastInputContextRef.current, inputContext)) {
      console.log('[useInputInterface] inputContext changed', inputContext)
      lastInputContextRef.current = inputContext
    }
  }, [inputContext])

  // The is what's called to send a value change event to the socket server
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const broadcastValue = (value: ValueType) => {
    console.log('[useInputInterface] Dispatching input change to socket server: ', value)
    userInputDispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_INPUT_VALUE,
      user_uid: user.uid,
      context: inputContext,
      value: value as JSONValueType,
    })
  }

  // Called once a value has been persisted to DB successfully or a conflicting value has been returned
  const handleSavedInputValue = (savedInputValueObject: SavedInputValue<ValueType>) => {
    console.log('[useInputInterface] Dispatching saved input value:', savedInputValueObject)
    userInputDispatch({
      ...getBaseAction(),
      type: ADD_UPDATE_SAVED_INPUT_VALUE,
      user_uid: user.uid,
      payload: savedInputValueObject as SavedInputValue<JSONValueType>,
    })
  }

  const onChange = (value: ValueType) => {
    setValue(value)
    if (socketDebounce !== false) {
      if (socketTimeout.current) clearTimeout(socketTimeout.current)
      socketTimeout.current = setTimeout(() => {
        broadcastValue(value)
      }, socketDebounce)
    } else {
      broadcastValue(value)
    }
  }

  return [value, onChange]
}
