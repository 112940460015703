import { useEffect } from 'react'
import { useGlobalState } from 'app/GlobalState'
import Sound from 'app/Sound'

export function useMuted(sound: Sound) {
  const { muted } = useGlobalState()
  useEffect(() => {
    sound.mute(muted)
  }, [muted, sound])
  return sound
}

export function useSfxMuted(sound: Sound) {
  const { sfxMuted } = useGlobalState()
  useEffect(() => {
    sound.mute(sfxMuted)
  }, [sfxMuted, sound])
  return sound
}
