import React from 'react'
import { GadgetPackStateProvider } from 'gadget-pack/GadgetPackState'
import { UserStateContext, useUserState } from 'app/UserState'
import { useFacilitatorState } from 'session/hooks/useProfileState'
import { useMentorDashState } from 'dashboards/mentor/MentorDashboardState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { GetProfile } from 'api'
import { ProgressionEventsStateProvider } from './ProgressionEventsState'
import { useParams } from 'react-router-dom'

export const PreviewGadgetPackStateProvider: React.FC = ({ children }) => {
  const facilitatorState = useFacilitatorState()
  const userState = useUserState()
  const profileId =
    facilitatorState && facilitatorState.focusedParticipantUid
      ? parseProfileId(facilitatorState.focusedParticipantUid)
      : userState.profileId

  let userContext = { ...userState, profileId }

  const { mentorUid } = useParams<{ mentorUid?: string }>()
  const [cadetProfile] = useEndpoint<GetProfile>(mentorUid ? `/api/v1/mentors/${mentorUid}/cadet` : null)

  // mentors need to request cadet GP and progression events
  // to do this we fake user state context
  if (mentorUid && cadetProfile) {
    userContext = {
      ...userState,
      profileId: cadetProfile.id,
      profiles: [cadetProfile],
      profileName: cadetProfile.profile_name || '',
    }

    return (
      <UserStateContext.Provider value={{ ...userContext, authProvider: 'sas' }}>
        <ProgressionEventsStateProvider>
          <GadgetPackStateProvider>{children}</GadgetPackStateProvider>
        </ProgressionEventsStateProvider>
      </UserStateContext.Provider>
    )
  }

  return (
    <UserStateContext.Provider value={{ ...userContext, authProvider: 'sas' }}>
      <ProgressionEventsStateProvider>
        <GadgetPackStateProvider>{children}</GadgetPackStateProvider>
      </ProgressionEventsStateProvider>
    </UserStateContext.Provider>
  )
}

export const TipsheetGadgetPackStateProvider: React.FC = ({ children }) => {
  const userState = useUserState()
  const {
    mentorUid,
    endpoints: { mentor },
  } = useMentorDashState()
  const gadgetPackAllowed = !!mentor.value && mentor.value.permission_gadget_pack
  const [profile] = useEndpoint<GetProfile>(gadgetPackAllowed ? `/api/v1/mentors/${mentorUid}/cadet` : null)
  return (
    <UserStateContext.Provider value={profile ? { ...userState, profileId: profile.id } : userState}>
      <GadgetPackStateProvider children={children} />
    </UserStateContext.Provider>
  )
}

// I don't really like having to do this
function parseProfileId(uid: string): number {
  const matches = uid.match(/^sas-(\d+)$/)
  if (!matches) return 0
  return parseInt(matches[1])
}
