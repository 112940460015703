import React, { ComponentProps } from 'react'
import styled, { css, StyledComponent } from 'styled-components'

import { Button, OutlineButton, ButtonProps } from 'common/ui'
import { Link } from 'react-router-dom'

const linkStyles = css`
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
`

export const AnchorButton = styled(props => <Button {...props} as="a" />)`
  ${linkStyles}
  ${p => p.disabled && 'opacity: 0.6; cursor: default; pointer-events: none;'}
`

const LinkWithoutConflictingProps: React.FC<ButtonProps & ComponentProps<typeof Link>> = ({
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  ...props
}) => <Link {...props} />

export const LinkButton = styled(props => <Button {...props} as={LinkWithoutConflictingProps} />)`
  ${linkStyles}
  ${p =>
    p.disabled
      ? css`
          opacity: 0.7;
          cursor: default;
          pointer-events: none;
        `
      : ''}
` as StyledComponent<'button', any, ButtonProps & ComponentProps<typeof Link>, never>

export const AnchorOutlineButton = styled(props => <OutlineButton {...props} as="a" />)`
  ${linkStyles}
`

export const LinkOutlineButton = styled(props => <OutlineButton {...props} as={Link} />)`
  ${linkStyles}
`

export const ButtonLink = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
`
