/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useEffect, useState, HTMLProps, useCallback } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { fontRegular } from 'fonts'

import { Vector as Coordinate } from 'types'
import {
  MediaAsset,
  SectionPropsBase,
  Sticker as StickerType,
  DrawingSectionState,
  InputValueContext,
} from 'shared/session/types'
import { getInitialState as getInitialSectionState, reducer } from 'shared/session/sections/drawing/reducer'
import {
  ActionTypes,
  DrawingActionTypes,
  ADD_DRAWING_ACTION,
  ADD_UNDO_ACTION,
  ADD_REDO_ACTION,
} from 'shared/session/sections/drawing/actionTypes'

import { Row, Column, Hr, H2, Spacer } from 'common/ui'
import { SectionTitle } from 'session/common/SectionTitle'
import { NavColumn } from 'session/common/NavColumn'

import { getImageUrl } from 'session/utils/assetUtils'
import { saveFile } from 'api'
import { dataURItoBlob } from 'utils/uriUtils'
import { useUserState } from 'app/UserState'
import { useSessionState } from 'session/SessionState'
import { useMeasure } from 'utils/useMeasure'
import { useScrollPosition } from 'utils/useScrollPosition'
import { InputContext, useInputContext, useInputInterface } from 'session/InputContext'
import {
  useFocusedParticipantState,
  useFacilitatorState,
  useParticipantProfileUid,
} from 'session/hooks/useProfileState'
import { DISPATCH_INPUT_VALUE_ACTION } from 'shared/session/actionTypes'
import { useGenericUser } from 'app/useGenericUser'
import { useUserInputState } from 'session/UserInputState'
import { roundToDecimal } from 'utils/mathUtils'
import { ActivityInteractionIndicator, InteractionType } from 'session/common/ActivityInteractionIndicator'

export const idealCanvasWidth = 1000
export const idealCanvasHeight = 500
export const penSizes = [2, 3, 5, 7]
export const colours = ['#8a4d9e', '#54b948', '#15498b', '#f57f00', '#00000']

const mouseId = -1

export interface DrawingSectionProps extends SectionPropsBase {
  property: 'drawing_sections'
  childOfFixedElement?: boolean
  initialRemeasureDelay?: number
}

export const DrawingSection: React.FC<DrawingSectionProps> = props => {
  const { section } = props
  const user = useGenericUser()
  const inputContext = useInputContext()
  const { getBaseAction } = useSessionState()
  const participantUid = useParticipantProfileUid()
  const newInputContext: InputValueContext = { ...inputContext, name: 'drawing' }
  const usingSharedState = ['shared', 'facilitator'].indexOf(section.usage) >= 0
  if (usingSharedState) newInputContext.participant_uid = 'shared'
  const [inputValue, onInputValueChange] = useInputInterface<DrawingSectionState>({
    name: newInputContext.name,
    defaultValue: getInitialSectionState(),
    contextOverride: newInputContext,
    socketDebounce: false,
  })

  const { dispatch: userInputDispatch } = useUserInputState()

  const dispatchInputChange = (action: ActionTypes) => {
    // onInputValueChange(reducer(inputValue, action))
    userInputDispatch({
      ...getBaseAction(),
      type: DISPATCH_INPUT_VALUE_ACTION,
      user_uid: user.uid,
      context: newInputContext,
      property: 'drawing_sections',
      action,
    })
  }

  return (
    <InputContext.Provider
      value={newInputContext}
      key={usingSharedState ? 'shared' : `individual_${participantUid || 'disabled'}`}>
      <DrawingSectionWithContext {...props} drawingState={inputValue} dispatchInputAction={dispatchInputChange} />
    </InputContext.Provider>
  )
}

function drawCoordinates(
  ctx: CanvasRenderingContext2D,
  coordinates: Coordinate[],
  state: string,
  penSize: number,
  colour?: string
) {
  ctx.lineWidth = penSize
  ctx.strokeStyle = state === 'ERASE' ? '#FFFFFF' : colour || '#2eadf0'
  ctx.beginPath()
  coordinates.forEach((coord, i) => {
    if (i === 0) ctx.moveTo(coord.x, coord.y)
    else ctx.lineTo(coord.x, coord.y)
    ctx.stroke()
  })
}

function clearCanvas(canvasContext: CanvasRenderingContext2D, width: number, height: number) {
  canvasContext.clearRect(0, 0, width, height)
  canvasContext.lineJoin = 'round'
  canvasContext.lineCap = 'round'
  canvasContext.strokeStyle = '#2eadf0'
  canvasContext.beginPath()
}

const isCurrentlyDrawing = (fingerCoordinates: { [key: number]: Coordinate[] }) =>
  ((Object.keys(fingerCoordinates) as any) as number[])
    .filter(key => key !== mouseId)
    .reduce((bool, pointerKey) => (bool ? bool : fingerCoordinates[pointerKey].length > 0), false)

export interface DrawingProps extends DrawingSectionProps {
  drawingState: DrawingSectionState
  dispatchInputAction: (action: ActionTypes) => void
  provideImageUriFetcher?: (func: () => string | false) => void
}

export const DrawingSectionWithContext: React.FC<DrawingProps> = ({
  property,
  section,
  index,
  drawingState,
  dispatchInputAction,
  childOfFixedElement,
  initialRemeasureDelay,
  provideImageUriFetcher,
}) => {
  const usingSharedState = ['shared', 'facilitator'].indexOf(section.usage) >= 0
  const facilitatorState = useFacilitatorState()
  const participantState = useFocusedParticipantState()
  let participantUid = useParticipantProfileUid()
  if (!participantUid && usingSharedState && facilitatorState) participantUid = facilitatorState.profile.uid

  const { title, include_pins, include_stickers, stickers, section_title } = section
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const canvasContextRef = useRef<CanvasRenderingContext2D | null>(null)
  const scratchCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const scratchCanvasContextRef = useRef<CanvasRenderingContext2D | null>(null)
  const redrawWaitingOnCanvas = useRef<boolean>(true)
  const { accessToken } = useUserState()
  const { state } = useSessionState()

  const [stickerTrayVisible, setStickerTrayVisible] = useState<boolean>(false)
  const [buttonState, setButtonState] = useState<'DRAW' | 'ERASE'>('DRAW')
  const [penSizeDropdownOpen, setPenSizeDropdownOpen] = useState(false)
  const [colourDropdownOpen, setColourDropdownOpen] = useState<boolean>(false)
  const [penSize, setPenSize] = useState<number>(3)
  const [colour, setColour] = useState<string>(() => {
    const index = state.participants.reduce(
      (value, participant, index) => (participant.profile.uid === (participantUid || '') ? index : value),
      -1
    )
    return colours[index < 0 ? 0 : index % colours.length]
  })

  const disabled = section.usage === 'individual' && facilitatorState !== false && participantState === false
  const readOnly = section.usage === 'facilitator' && facilitatorState === false
  const disableInput = readOnly || disabled

  const fingerCoordinates = useRef<{ [key: number]: Coordinate[] }>({})
  const lastCoordinates = useRef<{ [key: number]: Coordinate }>({})
  const [canvasScale, setCanvasScale] = useState<number>(1)
  const [drawingEffectRefresh, reCalcDrawingEffect] = useState<number>(0)
  const [measureRef, { width: containerWidth, height: containerHeight }] = useMeasure()
  const { y: scrollY } = useScrollPosition(window)

  const addDrawingAction = (drawingAction: DrawingActionTypes) => {
    dispatchInputAction({ type: ADD_DRAWING_ACTION, action: drawingAction })
  }

  const handleStickerSelect = (sticker: StickerType) => setStickerTrayVisible(false)
  const handleDraw = () => setButtonState('DRAW')
  const handleErase = () => setButtonState('ERASE')
  const handleUndo = () => dispatchInputAction({ type: ADD_UNDO_ACTION })
  const handleRedo = () => dispatchInputAction({ type: ADD_REDO_ACTION })
  const handleClear = () => {
    if (!participantUid) return
    addDrawingAction({
      type: 'SET_CLEAR_ACTION',
      participant_uid: participantUid,
      index: drawingState.actions.length,
    })
  }

  const handleToggleSize = (pen: number) =>
    ReactDOM.unstable_batchedUpdates(() => {
      setPenSize(pen)
      setPenSizeDropdownOpen(false)
    })

  const handleToggleColour = (_colour: string) =>
    ReactDOM.unstable_batchedUpdates(() => {
      setColour(_colour)
      setColourDropdownOpen(false)
    })

  const uploadImage = () => {
    if (!scratchCanvasRef.current || !scratchCanvasContextRef.current) return
    const canvasElement = scratchCanvasRef.current
    var src = canvasElement.toDataURL('image/png', 1.0)
    if (accessToken) {
      const blob = dataURItoBlob(src)
      saveFile(blob, accessToken)
        .then(upload => {
          console.log('[DrawingSectionWithContext] upload image successful')
        })
        .catch(err => {
          console.log('[DrawingSectionWithContext] error in uploading canvas image')
        })
    }
  }

  const generateImageUri = useCallback(() => {
    if (!canvasRef.current) return false
    return canvasRef.current.toDataURL('image/png', 1.0)
  }, [])
  useEffect(() => {
    if (provideImageUriFetcher) provideImageUriFetcher(generateImageUri)
  }, [generateImageUri, provideImageUriFetcher])

  useEffect(() => {
    if (!scratchCanvasRef.current || !scratchCanvasContextRef.current) return

    const scratchCtx = scratchCanvasContextRef.current
    const canvasElement = scratchCanvasRef.current
    const canvasRect = canvasElement.getBoundingClientRect()

    const onMove = (e: MouseEvent | TouchEvent) => {
      if (e instanceof MouseEvent) {
        const coord = {
          x: roundToDecimal((e.pageX - canvasRect.left) / canvasScale),
          y: roundToDecimal((e.pageY - canvasRect.top - (childOfFixedElement ? 0 : scrollY)) / canvasScale),
        }
        scratchCtx.beginPath()
        scratchCtx.moveTo(lastCoordinates.current[mouseId].x, lastCoordinates.current[mouseId].y)
        scratchCtx.lineTo(coord.x, coord.y)
        scratchCtx.stroke()

        if (
          lastCoordinates.current[mouseId] &&
          lastCoordinates.current[mouseId].x === coord.x &&
          lastCoordinates.current[mouseId].y === coord.y
        ) {
          return
        }
        fingerCoordinates.current[mouseId] = [...fingerCoordinates.current[mouseId], coord]
        lastCoordinates.current[mouseId] = coord
      } else if (e instanceof TouchEvent && e.touches) {
        for (let i = 0; i < e.changedTouches.length; i++) {
          const touch = e.changedTouches[i]
          const id = touch.identifier
          const coord = {
            x: roundToDecimal((touch.clientX - canvasRect.left) / canvasScale),
            y: roundToDecimal((touch.clientY - canvasRect.top) / canvasScale),
          }
          scratchCtx.beginPath()
          scratchCtx.moveTo(lastCoordinates.current[id].x, lastCoordinates.current[id].y)
          scratchCtx.lineTo(coord.x, coord.y)
          scratchCtx.stroke()
          if (
            lastCoordinates.current[id] &&
            lastCoordinates.current[id].x === coord.x &&
            lastCoordinates.current[id].y === coord.y
          ) {
            return
          }
          fingerCoordinates.current[id] = [...fingerCoordinates.current[id], coord]
          lastCoordinates.current[id] = coord
        }
      }
    }

    const onMoveStart = (e: MouseEvent | TouchEvent) => {
      scratchCtx.lineWidth = penSize
      scratchCtx.lineJoin = 'round'
      scratchCtx.lineCap = 'round'
      scratchCtx.strokeStyle = buttonState === 'ERASE' ? '#FFFFFF' : colour
      if (e instanceof MouseEvent) {
        const coord = {
          x: roundToDecimal((e.pageX - canvasRect.left) / canvasScale),
          y: roundToDecimal((e.pageY - canvasRect.top - (childOfFixedElement ? 0 : scrollY)) / canvasScale),
        }
        lastCoordinates.current[mouseId] = coord
        fingerCoordinates.current[mouseId] = [coord]
        canvasElement.addEventListener('mousemove', onMove, false)
      } else if (e instanceof TouchEvent && e.touches) {
        e.preventDefault()
        for (let i = 0; i < e.changedTouches.length; i++) {
          const touch = e.changedTouches[i]
          const id = touch.identifier
          const coord = {
            x: roundToDecimal((touch.clientX - canvasRect.left) / canvasScale),
            y: roundToDecimal((touch.clientY - canvasRect.top) / canvasScale),
          }
          lastCoordinates.current[id] = coord
          fingerCoordinates.current[id] = [coord]
          canvasElement.addEventListener('touchmove', onMove, false)
        }
      }
    }

    const onMoveEnd = (e: MouseEvent | TouchEvent) => {
      clearCanvas(scratchCtx, idealCanvasWidth, idealCanvasHeight)
      if (e instanceof MouseEvent) {
        if (fingerCoordinates.current[mouseId] && fingerCoordinates.current[mouseId].length > 1) {
          if (buttonState === 'DRAW') {
            if (participantUid) {
              addDrawingAction({
                type: 'SET_DRAW_ACTION',
                participant_uid: participantUid,
                coordinates: [...fingerCoordinates.current[mouseId]],
                lineWidth: penSize,
                colour,
              })
            } else {
              console.warn('[DrawingSectionWithContext] No participant uid???')
            }
          } else {
            if (participantUid) {
              addDrawingAction({
                type: 'SET_ERASE_ACTION',
                participant_uid: participantUid,
                coordinates: [...fingerCoordinates.current[mouseId]],
                lineWidth: penSize,
              })
            } else {
              console.warn('[DrawingSectionWithContext] No participant uid???')
            }
          }
        }
        fingerCoordinates.current[mouseId] = []
        canvasElement.removeEventListener('mousemove', onMove, false)
      } else if (e instanceof TouchEvent && e.touches) {
        for (let i = 0; i < e.changedTouches.length; i++) {
          const touch = e.changedTouches[i]
          const id = touch.identifier
          if (fingerCoordinates.current[id] && fingerCoordinates.current[id].length > 1) {
            if (buttonState === 'DRAW') {
              if (participantUid) {
                addDrawingAction({
                  type: 'SET_DRAW_ACTION',
                  participant_uid: participantUid,
                  coordinates: [...fingerCoordinates.current[id]],
                  lineWidth: penSize,
                  colour,
                })
              }
            } else {
              if (participantUid) {
                addDrawingAction({
                  type: 'SET_ERASE_ACTION',
                  participant_uid: participantUid,
                  coordinates: [...fingerCoordinates.current[id]],
                  lineWidth: penSize,
                })
              }
            }
          }
          fingerCoordinates.current[id] = []
          if (isCurrentlyDrawing(fingerCoordinates.current)) {
            ;((Object.keys(fingerCoordinates.current) as any) as number[]).forEach(key => {
              scratchCtx.strokeStyle = buttonState === 'ERASE' ? '#FFFFFF' : colour
              const coords = fingerCoordinates.current[key]
              coords.forEach(coord => {
                scratchCtx.lineTo(coord.x, coord.y)
              })
              scratchCtx.stroke()
            })
          } else {
            canvasElement.removeEventListener('touchmove', onMove, false)
          }
        }
      }
    }

    canvasElement.addEventListener('mousedown', onMoveStart, false)
    canvasElement.addEventListener('mouseup', onMoveEnd, false)
    canvasElement.addEventListener('touchstart', onMoveStart, false)
    canvasElement.addEventListener('touchend', onMoveEnd, false)

    if (fingerCoordinates.current[mouseId]?.length > 0) {
      canvasElement.addEventListener('mousemove', onMove, false)
    }

    if (isCurrentlyDrawing(fingerCoordinates.current)) {
      canvasElement.addEventListener('touchmove', onMove, false)
    }

    return () => {
      canvasElement.removeEventListener('mousemove', onMove)
      canvasElement.removeEventListener('mousedown', onMoveStart)
      canvasElement.removeEventListener('mouseup', onMoveEnd)
      canvasElement.removeEventListener('touchstart', onMoveStart)
      canvasElement.removeEventListener('touchmove', onMove)
      canvasElement.removeEventListener('touchend', onMoveEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buttonState,
    containerHeight,
    containerWidth,
    penSize,
    colour,
    scrollY,
    childOfFixedElement,
    canvasScale,
    participantUid,
    drawingEffectRefresh,
  ])

  const redrawCanvas = (canvasContext: CanvasRenderingContext2D, drawingActions: DrawingActionTypes[]) => {
    const lastIndex = drawingState.currentActionIndex + 1
    const lastActions = (drawingActions || []).slice(0, lastIndex)

    const lastClearActions = lastActions.filter(({ type }) => type === 'SET_CLEAR_ACTION')
    const lastClearActionIndex =
      lastClearActions.length > 0 ? lastClearActions[lastClearActions.length - 1].index : undefined
    const actions =
      lastClearActionIndex !== undefined
        ? lastActions.slice(lastClearActionIndex + 1, lastIndex)
        : lastActions.slice(0, lastIndex)

    clearCanvas(canvasContext, idealCanvasWidth, idealCanvasHeight)
    actions.forEach(action => {
      switch (action.type) {
        case 'SET_DRAW_ACTION':
          drawCoordinates(canvasContext, action.coordinates, 'DRAW', action.lineWidth, action.colour)
          return
        case 'SET_ERASE_ACTION':
          drawCoordinates(canvasContext, action.coordinates, 'ERASE', action.lineWidth)
          return
        case 'SET_CLEAR_ACTION':
          clearCanvas(canvasContext, idealCanvasWidth, idealCanvasHeight)
          return
      }
    })
  }

  // this handles updating of canvases if state changes
  useEffect(() => {
    console.info(`[DrawingSectionWithContext] Redrawing canvas (${drawingState.actions.length} actions)`)
    if (!canvasRef.current || !canvasContextRef.current) {
      redrawWaitingOnCanvas.current = true
      return
    }
    redrawCanvas(canvasContextRef.current, drawingState.actions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingState.actions, drawingState.currentActionIndex])

  useEffect(() => { setCanvasScale(containerWidth / idealCanvasWidth) }, [containerWidth]) // prettier-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { 
    setCanvasScale(containerWidth / idealCanvasWidth) 
    if(initialRemeasureDelay) setTimeout(() => reCalcDrawingEffect(v => v + 1), initialRemeasureDelay)
  }) // prettier-ignore

  // Create context variables if they don't exist
  if (canvasRef.current && !canvasContextRef.current) canvasContextRef.current = canvasRef.current.getContext('2d')
  if (scratchCanvasRef.current && !scratchCanvasContextRef.current)
    scratchCanvasContextRef.current = scratchCanvasRef.current.getContext('2d')

  if ((!canvasRef.current || !canvasContextRef.current) && redrawWaitingOnCanvas.current === false) {
    redrawWaitingOnCanvas.current = true
  }
  if (canvasRef.current && canvasContextRef.current && redrawWaitingOnCanvas.current === true) {
    redrawCanvas(canvasContextRef.current, drawingState.actions)
    redrawWaitingOnCanvas.current = false
  }

  const canvasHeight = containerWidth * (idealCanvasHeight / idealCanvasWidth)
  const canvasProps: Partial<HTMLProps<HTMLCanvasElement>> = {
    width: idealCanvasWidth,
    height: idealCanvasHeight,
    style: {
      width: containerWidth,
      height: canvasHeight || idealCanvasHeight,
    },
  }

  return (
    <>
      {title && <SectionTitle children={title} />}
      <DrawSectionContainer>
        <DrawContainer>
          <DrawHeader>
            <DrawHeaderLeft>
              <h2>{section_title || (!disableInput && 'Draw Below')}</h2>
            </DrawHeaderLeft>
            <DrawHeaderRight>
              <ToolButton
                onClick={disableInput ? undefined : () => handleDraw()}
                active={buttonState === 'DRAW'}
                disabled={disableInput}>
                <img src={require('../../assets/icon-pen.svg')?.default} alt="Draw" />
              </ToolButton>
              {include_pins && (
                <ToolButton>
                  <img src={require('../../assets/icon-pin.svg')?.default} alt="Pin" />
                </ToolButton>
              )}
              {include_stickers && stickers.length > 0 && (
                <ToolButton
                  onClick={disableInput ? undefined : () => setStickerTrayVisible(val => !val)}
                  active={stickerTrayVisible}>
                  <img src={require('../../assets/icon-pin.svg')?.default} alt="Sticker" />
                </ToolButton>
              )}
              <ToolButton
                onClick={disableInput ? undefined : () => handleErase()}
                active={buttonState === 'ERASE'}
                disabled={disableInput}>
                <img src={require('../../assets/icon-rubber.svg')?.default} alt="Erase" />
              </ToolButton>
              <ToolButton onClick={disableInput ? undefined : () => handleUndo()} disabled={disableInput}>
                <img src={require('../../assets/icon-undo.svg')?.default} alt="Undo" />
              </ToolButton>
              <ToolButton onClick={disableInput ? undefined : () => handleRedo()} disabled={disableInput}>
                <img src={require('../../assets/icon-redo.svg')?.default} alt="Redo" />
              </ToolButton>
              <ToolButton onClick={disableInput ? undefined : () => handleClear()} disabled={disableInput}>
                <img src={require('../../assets/icon-trash.svg')?.default} alt="Trash" />
              </ToolButton>
              <ToolButton
                onClick={disableInput ? undefined : () => setColourDropdownOpen(!colourDropdownOpen)}
                active={colourDropdownOpen === true}
                disabled={disableInput}>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    backgroundColor: colour,
                  }}
                />
              </ToolButton>
              <ToolButton
                style={{ borderTopRightRadius: 10 }}
                onClick={disableInput ? undefined : () => setPenSizeDropdownOpen(!penSizeDropdownOpen)}
                active={penSizeDropdownOpen === true}
                disabled={disableInput}>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    backgroundColor: '#000',
                  }}
                />
              </ToolButton>
              {penSizeDropdownOpen && (
                <CircleWrapper>
                  {penSizes.map(size => (
                    <Circle
                      key={size}
                      active={penSize === size}
                      circleSize={size}
                      onClick={() => handleToggleSize(size)}
                    />
                  ))}
                </CircleWrapper>
              )}
              {colourDropdownOpen && (
                <CircleWrapper>
                  {colours.map(_colour => (
                    <Circle
                      key={_colour}
                      active={_colour === colour}
                      circleSize={0}
                      onClick={() => handleToggleColour(_colour)}
                      style={{ backgroundColor: _colour }}
                    />
                  ))}
                </CircleWrapper>
              )}
            </DrawHeaderRight>
          </DrawHeader>
          <DrawBody
            ref={measureRef}
            style={{ height: canvasHeight }}
            backgroundImage={section.background_image}
            backgroundPlacement={section.background_image_placement}
            disabled={disabled}
            readOnly={readOnly}>
            {stickerTrayVisible && (
              <StickerTray>
                <StickerTrayInner flexWrap>
                  {(stickers || [])
                    .filter(sticker => !!sticker.image)
                    .map((sticker, i) => (
                      <Sticker key={i} onClick={() => handleStickerSelect(sticker)}>
                        <StickerInner>
                          <img src={getImageUrl(sticker.image, { preset: '64w-auto' })} alt={sticker.title} />
                          <label>{sticker.title}</label>
                        </StickerInner>
                      </Sticker>
                    ))}
                  {[...Array(6)].map((_, i) => (
                    <StickerDummy key={`dummy${i}`} />
                  ))}
                </StickerTrayInner>
              </StickerTray>
            )}
            {disabled ? (
              <canvas {...canvasProps} />
            ) : (
              <>
                <canvas id="canvas2" ref={canvasRef} {...canvasProps} />
                <canvas id="canvas1" ref={scratchCanvasRef} {...canvasProps} />
              </>
            )}
            {section.background_image && section.background_image_placement === 'foreground' && (
              <DrawForegroundImage src={getImageUrl(section.background_image, { preset: '720w-auto' })} />
            )}
          </DrawBody>
        </DrawContainer>
        {(include_pins || (include_stickers && stickers.length > 0)) && (
          <RightContainer>
            <LabelsContainer>
              <LabelsHeader>
                <H2>Labels:</H2>
              </LabelsHeader>
              <Hr />
              <Spacer size="m" />
              {}
              {/* LABELS HERE */}
            </LabelsContainer>
          </RightContainer>
        )}
      </DrawSectionContainer>
    </>
  )
}

const usageMap: { [key: string]: InteractionType } = {
  shared: 'shared',
  'shared-custom': 'shared',
  individual: 'individual',
  facilitator: 'shared',
}

export const DrawingSectionFacilitator: React.FC<DrawingSectionProps> = props => {
  const {
    section: { usage },
  } = props
  return (
    <>
      <ActivityInteractionIndicator type={usageMap[usage]} />
      <Spacer />
      <DrawingSection {...props} />
    </>
  )
}

const DrawSectionContainer = styled(Row)`
  margin-bottom: 15px;
`

const RightContainer = styled.div`
  flex: 0 0 250px;
  position: relative;
  padding-left: 15px;
  width: 250px;
`

const LabelsContainer = styled(Column)`
  position: relative;
`

const LabelsHeader = styled(Column)`
  justify-content: center;
  height: 60px;
`

const DrawContainer = styled.div`
  flex: 1 1 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd2e4;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #fff;
`

const DrawHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #d3d7e7;
`

const DrawHeaderLeft = styled.div`
  padding-top: 17px;
  padding-bottom: 16px;
  padding-left: 24px;
  flex: 1;

  & h2 {
    ${fontRegular}
    margin: 0;
    color: #bdc3e0;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.03em;
  }
`
export const DrawHeaderRight = styled.div`
  display: flex;
  /* flex: 1; */
  justify-content: flex-end;
`

const ToolButton = styled(NavColumn)`
  width: 60px;
  height: 60px;
  cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
  user-select: none;
`

const Circle = styled.div<{ circleSize: number; active: boolean }>`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid ${p => (p.active ? '#2eadf0' : '#ccc')};
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    border-radius: 50%;
    width: ${p => p.circleSize * 3}px;
    height: ${p => p.circleSize * 3}px;
  }
`

const CircleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 61px;
  right: 10;
  border: 1px solid #cdd2e4;
  background-color: #fff;
  padding: 0px 15px;
  height: 50px;
  z-index: 10;

  & ${Circle} + ${Circle} {
    margin-left: 10px;
  }
`

const DrawForegroundImage = styled.img`
  position: absolute;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  pointer-events: none;
  user-select: none;
  opacity: 0.7;
`

const DrawBody = styled.div<{
  backgroundImage?: MediaAsset
  readOnly?: boolean
  disabled?: boolean
  backgroundPlacement?: 'foreground' | 'background' | undefined | null
}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  & canvas {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
  }
  ${p =>
    !p.backgroundImage
      ? ''
      : p.backgroundPlacement && p.backgroundPlacement !== 'foreground'
      ? css`
        background-image: url('${getImageUrl(p.backgroundImage, { preset: '720w-auto' })}');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      `
      : ''}

  ${p =>
    !p.readOnly
      ? ''
      : css`
          pointer-events: none;
          cursor: not-allowed;
        `}

  ${p =>
    !p.disabled
      ? ''
      : css`
          pointer-events: none;
          cursor: not-allowed;
          &::after {
            content: "Select a participant's tab to view";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            align-content: center;
          }
        `}
`

const StickerTray = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #464b61;
  border-bottom: 1px solid #cdd2e4;
  padding: 20px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
`

const StickerTrayInner = styled(Row)`
  margin: -5px;
`

const StickerDummy = styled.div`
  flex: 1 1 auto;
  width: calc(80px + 5px * 2);
  margin: 5px;
  padding: 5px;
`

const Sticker = styled.div`
  flex: 1 1 auto;
  width: calc(80px + 5px * 2);
  margin: 5px;
  padding: 5px;
  border: 1px solid #cdd2e4;
  background-color: #fff;
  user-select: none;
  cursor: pointer;
`

const StickerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  & img {
    margin-bottom: 5px;
  }
  & label {
    ${fontRegular}
    font-size: 12px;
  }
`
