import Phaser, { GameObjects, Loader } from 'phaser'

import SceneScaler from './SceneScaler'
import { PhaserTextStyle } from 'phaser-types'
import { getPixelRatio } from 'utils/screenUtils'
import throttle from 'lodash/throttle'
import * as Sentry from '@sentry/browser'
import { HowlOptions } from 'howler'

export const SCENE_LOADER_COMPLETE = 'SCENE_LOADER_COMPLETE'

export interface SpriteObj {
  src: string
  frameWidth?: number
  frameHeight?: number
  startFrame?: number
  endFrame: number
  frameRate?: number
  repeat?: number
}

export interface VideoObj {
  src: string
  loadEvent?: 'loadeddata' | 'canplay' | 'canplaythrough'
  asBlob?: boolean
  noAudio?: boolean
}

export interface SoundObj extends HowlOptions {
  preventOverlap?: boolean
  start?: number // ms
  loopStart?: number // ms
  loopEnd?: number // ms
  speedRange?: number | [number, number]
}

export type AssetFailCacheObject = {
  images: { [key: string]: string }
  sounds: { [key: string]: string }
  sprites: { [key: string]: string }
  videos: { [key: string]: string }
}

export type AssetFailCacheCounter = {
  images: { [key: string]: number }
  sounds: { [key: string]: number }
  sprites: { [key: string]: number }
  videos: { [key: string]: number }
}

const maxTotalRetryAttempts = 5
const maxFileRetryAttempts = 5

export default class SceneLoader extends SceneScaler {
  sceneTrigger: string | null = null
  barWidth: number = 300
  barHeight: number = 30
  fontSize: number = 12
  textRenderer: (progress: number) => string = progress =>
    progress === 1 ? 'Done!' : `Loaded ${Math.floor(progress * 100)}%`

  private container?: GameObjects.Container
  private percentText?: GameObjects.Text
  private loadBar?: GameObjects.Graphics
  private loadBarOutline?: GameObjects.Graphics
  private spriteAssets: { [key: string]: SpriteObj } = {}
  private loadingCallback?: (value: number) => void
  private showLegacyLoadBar: boolean
  private globalRetryAttempt: number = 0

  private failedAssets: AssetFailCacheObject = { sounds: {}, images: {}, sprites: {}, videos: {} }
  private retryAssetCounters: AssetFailCacheCounter = { sounds: {}, images: {}, sprites: {}, videos: {} }

  state = {
    barValue: 0,
    barAimValue: 0,
  }

  constructor(showLegacyLoadBar = false) {
    super('SceneLoader')
    this.showLegacyLoadBar = showLegacyLoadBar
  }

  setLoadingCallback(callback: (value: number) => void) {
    this.loadingCallback = callback
  }

  init() {
    super.init()
    const { width, height } = this.game.config

    if (this.showLegacyLoadBar) {
      this.loadBar = this.add.graphics()
      this.loadBarOutline = this.add.graphics()
      this.loadBarOutline.lineStyle(1, 0xffffff, 1)
      this.loadBarOutline.strokeRoundedRect(0, 0, this.barWidth, this.barHeight, 4)
      this.percentText = this.add.text(0, this.barHeight + 5, this.textRenderer(0), {
        color: 'white',
        fontFamily: 'Helvetica',
        fontSize: `${this.fontSize}px`,
        align: 'center',
        fixedWidth: this.barWidth,
        resolution: getPixelRatio() * 2,
      } as PhaserTextStyle)

      this.container = this.add.container(
        (width as number) / 2 - this.barWidth / 2,
        (height as number) / 2 - this.barHeight / 2,
        [this.loadBar, this.loadBarOutline, this.percentText]
      )
    }

    this.load.addListener(
      Phaser.Loader.Events.PROGRESS,
      throttle(() => {
        const progress = Math.min(0.99, Math.max(this.load.progress, 0.01))
        this.loadingCallback && this.loadingCallback(progress)
        this.updateProgressBar(progress)
      }, 100)
    )

    this.load.addListener(
      Phaser.Loader.Events.FILE_LOAD_ERROR,
      (
        file:
          | Loader.FileTypes.AudioFile
          | Loader.FileTypes.ImageFile
          | Loader.FileTypes.SpriteSheetFile
          | Loader.FileTypes.VideoFile
      ) => {
        if (!file.key) return
        console.warn('Failed to load', file.type, file.key, file.src)
        if (file instanceof Loader.FileTypes.AudioFile) this.failedAssets.sounds[file.key] = file.src
        if (file instanceof Loader.FileTypes.ImageFile) this.failedAssets.images[file.key] = file.src
        if (file instanceof Loader.FileTypes.SpriteSheetFile) this.failedAssets.sprites[file.key] = file.src
        if (file instanceof Loader.FileTypes.VideoFile) this.failedAssets.videos[file.key] = file.src
      }
    )

    this.load.addListener(Phaser.Loader.Events.COMPLETE, () => {
      if (this.thereAreFailedAssetsToRetry()) {
        if (this.globalRetryAttempt < maxTotalRetryAttempts) {
          this.globalRetryAttempt++
          setTimeout(() => this.retryFailedAssets(), 150 * this.globalRetryAttempt)
          return
        } else {
          Sentry.captureMessage(
            `Failed to load all phaser scene assets after ${maxTotalRetryAttempts} attempts!`,
            Sentry.Severity.Critical
          )
        }
      }
      this.loadingCallback && this.loadingCallback(1)
      this.handleLoadComplete()
      this.updateProgressBar(1)
      this.cameras.main.fadeOut(300, 0, 0, 0, (context: any, percent: number) => {
        this.events.emit(SCENE_LOADER_COMPLETE)
        if (percent === 1 && this.sceneTrigger) {
          this.scene.start(this.sceneTrigger)
          this.scene.stop('SceneLoader')
        }
      })
    })
  }

  registerImages(assets: { [key: string]: string }) {
    Object.keys(assets).forEach(key => this.load.image(key, assets[key]))
    this.assets.images = assets
  }

  registerVideos(assets: { [key: string]: VideoObj }) {
    Object.keys(assets).forEach(key =>
      this.load.video(
        key,
        assets[key].src,
        assets[key].loadEvent || 'loadeddata',
        assets[key].asBlob || false,
        assets[key].noAudio || false
      )
    )
    this.assets.videos = assets
  }

  registerSounds(assets: { [key: string]: SoundObj }) {
    Object.keys(assets).forEach(key => this.load.audio(key, assets[key].src))
    this.assets.sounds = assets
  }

  registerSprites(assets: { [key: string]: SpriteObj }) {
    this.spriteAssets = assets
    Object.keys(assets).forEach(key =>
      this.load.spritesheet(key, assets[key].src, {
        frameWidth: assets[key].frameWidth || 256,
        frameHeight: assets[key].frameHeight || 256,
      })
    )
    this.assets.sprites = assets
  }

  initLoad() {
    this.load.start()
  }

  private thereAreFailedAssetsToRetry() {
    for (let key of Object.keys(this.failedAssets)) {
      const typeKey = key as keyof AssetFailCacheObject
      if (
        Object.keys(this.failedAssets[typeKey]).filter(
          assetKey => this.retryAssetCounters[typeKey][assetKey] || 0 < maxFileRetryAttempts
        ).length > 0
      )
        return true
    }
    return false
  }

  private retryFailedAssets() {
    console.log(`Retrying failed assets, attempt ${this.globalRetryAttempt} / ${maxTotalRetryAttempts}`)
    for (let assetTypeKey of Object.keys(this.failedAssets)) {
      if (assetTypeKey === 'images')
        this.registerImages(
          Object.keys(this.failedAssets.images).reduce((obj, key) => {
            if (this.retryAssetCounters.images[key] >= maxFileRetryAttempts) return obj
            this.retryAssetCounters.images[key] = (this.retryAssetCounters.images[key] || 0) + 1
            return { ...obj, [key]: this.failedAssets.images[key] }
          }, {})
        )
      if (assetTypeKey === 'sounds')
        this.registerSounds(
          Object.keys(this.failedAssets.sounds).reduce((obj, key) => {
            if (this.retryAssetCounters.sounds[key] >= maxFileRetryAttempts) return obj
            this.retryAssetCounters.sounds[key] = (this.retryAssetCounters.sounds[key] || 0) + 1
            if (this.assets.sounds && this.assets.sounds[key]) return { ...obj, [key]: this.assets.sounds[key] }
            return obj
          }, {})
        )
      if (assetTypeKey === 'sprites')
        this.registerSprites(
          Object.keys(this.failedAssets.sprites).reduce((obj, key) => {
            if (this.retryAssetCounters.sounds[key] >= maxFileRetryAttempts) return obj
            this.retryAssetCounters.sprites[key] = (this.retryAssetCounters.sprites[key] || 0) + 1
            if (this.assets.sprites && this.assets.sprites[key]) return { ...obj, [key]: this.assets.sprites[key] }
            return obj
          }, {})
        )
      if (assetTypeKey === 'videos')
        this.registerSprites(
          Object.keys(this.failedAssets.videos).reduce((obj, key) => {
            if (this.retryAssetCounters.videos[key] >= maxFileRetryAttempts) return obj
            this.retryAssetCounters.videos[key] = (this.retryAssetCounters.videos[key] || 0) + 1
            if (this.assets.videos && this.assets.videos[key]) return { ...obj, [key]: this.assets.videos[key] }
            return obj
          }, {})
        )
    }
    this.initLoad()
  }

  private updateProgressBar(percent: number = 0) {
    if (this.showLegacyLoadBar && this.percentText) this.percentText.text = this.textRenderer(this.load.progress)
    this.state.barAimValue = this.load.progress
  }
  private handleLoadComplete() {
    Object.keys(this.spriteAssets).forEach(key => {
      this.anims.create({
        key,
        frames: this.anims.generateFrameNumbers(key, {
          start: this.spriteAssets[key].startFrame || 0,
          end: this.spriteAssets[key].endFrame,
        }),
        frameRate: this.spriteAssets[key].frameRate || 60,
        repeat: this.spriteAssets[key].repeat || 0,
      })
    })
  }
  update() {
    this.state.barValue += (this.state.barAimValue - this.state.barValue) / 6
    if (this.showLegacyLoadBar && this.loadBar) {
      this.loadBar
        .clear()
        .fillStyle(0xffffff, 1)
        .fillRoundedRect(0, 0, this.state.barValue * this.barWidth, this.barHeight, 4)
    }
  }
}
