import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { fontRegular, fontLight } from 'fonts'
import { useMeasure } from 'utils/useMeasure'
import { useDrag } from 'react-use-gesture'

import { EmbeddedActivitySection } from 'shared/session/types'

import { Button, P } from 'common/ui'
import { FacilitatorControls } from 'session/common/FacilitatorControlsSection'
import { InputComponent } from 'session/common/InputComponent'
import { ActivityInteractionIndicator } from 'session/common/ActivityInteractionIndicator'

import { useInputValue } from 'session/InputContext'
import { InputLockComponent } from 'session/common/InputLockComponent'
import { useParticipantProfileUid } from 'session/hooks/useProfileState'

const buttonTxt: string = 'Code Message'
const buttonTxtEncoded: string = 'Read Secret Message'
const editTxt = 'Edit Message'
const placeholder: string = 'Enter Text Here'
const viewerSize: [number, number] = [300, 180]
const viewerOffset: [number, number] = [27, 15]
const imgSize: [number, number] = [400, 220]
let prevString: string = ''
let scrambledText: string = ''

const scrambleText = (str: string) => {
  if (prevString !== str) {
    prevString = str
  } else {
    return scrambledText
  }
  return (scrambledText = str
    .split('\n')
    .map(line => {
      return line
        .split(' ')
        .map(word => {
          return word
            .split('')
            .map(() => {
              let hash = Math.floor(Math.random() * word.length)
              let returnVal = word[hash]
              word = word.replace(word.charAt(hash), '')
              return returnVal
            })
            .join('')
        })
        .join(' ')
    })
    .join('\n')
    .toLowerCase())
}

export const InvisibleInkScreen: React.FC<{ section: EmbeddedActivitySection }> = ({ section }) => {
  const [showViewer, setShowViewer] = useState<boolean>(false)
  const [showEncoded, setShowEncoded] = useState<boolean>(false)

  const participantUid = useParticipantProfileUid()
  const inputValue = useInputValue<string>('input')
  const containerRef = useRef<HTMLDivElement | null>()
  const [measureRef, { width, height }] = useMeasure()
  const [viewerPosition, setViewerPosition] = useState<[number, number]>(viewerOffset)
  const BoundRef = useRef<HTMLDivElement | null>(null)
  const svgPath = (
    <path
      d={`M0,0 V${height} H${width}V0 Z M${viewerPosition[0] + width / 2 - imgSize[0] / 2},${viewerPosition[1] +
        height / 2 -
        imgSize[1] / 2} h${viewerSize[0]} v${viewerSize[1]} h${-viewerSize[0]} Z`}
    />
  )
  const lineLimit = 7

  const sanitizeText = (previousVal: string, text: string) => {
    const lineArr = text.split('\n')
    if (lineArr.length > lineLimit) {
      alert(`Sorry you cannot have more than ${lineLimit} lines`)
      return previousVal
    } else {
      return text
    }
  }

  useEffect(() => {
    if (!showViewer) setViewerPosition(viewerOffset)
  }, [showViewer])

  return (
    <>
      {section.title && <Instruction>{section.title}</Instruction>}
      <CorkBoardContainer hideCork={section.invisible_ink_hide_cork} ref={BoundRef}>
        <PagePadding>
          <Page
            ref={elem => {
              containerRef.current = elem
              measureRef(elem)
            }}>
            <EncodedMessage show={showEncoded} value={scrambleText(inputValue || '')} readOnly></EncodedMessage>
            <InputComponent<string> defaultValue={''} name="input">
              {props => (
                <InputLockComponent
                  fieldUid={`${participantUid}_invisible_ink`}
                  verticalPlacementCadet={-15}
                  horizontalPlacementCadet={5}
                  verticalPlacementFacilitator={-15}
                  horizontalPlacementFacilitator={5}>
                  {(lockProps, lockState) => (
                    <TextArea
                      {...lockProps}
                      {...lockState}
                      rows={lineLimit}
                      value={props.value || ''}
                      onChange={e => {
                        const value = sanitizeText(props.value || '', e.target.value || '')
                        props.onChange(value)
                        lockProps.onChange(value)
                      }}
                      placeholder={placeholder}
                      disable={lockState.disabled || showEncoded}
                    />
                  )}
                </InputLockComponent>
              )}
            </InputComponent>

            <MaskSvg
              show={showViewer}
              xmlns="http://www.w3.org/2000/svg"
              viewBox={`0 0 ${width} ${height}`}
              {...{ width, height }}>
              <defs>
                <clipPath id="svgPath">{svgPath}</clipPath>
              </defs>
            </MaskSvg>
            {showViewer && <DraggableViewer setViewerPosition={setViewerPosition} parentElement={BoundRef.current} />}
            <ButtonGroup>
              <ActionButton
                size="m"
                show={!showEncoded}
                // onClick={e => updateProfileState({ ...profileState, showEncoded: true })}
                onClick={e => setShowEncoded(true)}>
                {buttonTxt}
              </ActionButton>
              <ActionButton
                size="m"
                show={showEncoded}
                // onClick={e => updateProfileState({ ...profileState, showViewer: true })}
                onClick={e => setShowViewer(true)}>
                {buttonTxtEncoded}
              </ActionButton>
              <EditButton
                size="m"
                show={showEncoded}
                // onClick={e => updateProfileState({ ...profileState, showEncoded: false, showViewer: false })}
                onClick={e => {
                  setShowEncoded(false)
                  setShowViewer(false)
                }}>
                {editTxt}
              </EditButton>
            </ButtonGroup>
          </Page>
        </PagePadding>
      </CorkBoardContainer>
    </>
  )
}

const DraggableViewer: React.FC<{
  setViewerPosition: (value: [number, number]) => void
  parentElement: HTMLDivElement | null
}> = ({ setViewerPosition, parentElement }) => {
  const parentRect = parentElement ? parentElement.getBoundingClientRect() : { width: 0, height: 0 }
  const viewerRef = useRef<HTMLImageElement | null>(null)
  const rightBound = parentRect.width / 2 - viewerSize[0] / 2
  const bottomBound = parentRect.height / 2 - viewerSize[1] / 2

  const bind = useDrag(
    ({ offset: [x, y], event }) => {
      const mouseEvent = event as React.MouseEvent<HTMLImageElement, MouseEvent>
      mouseEvent.preventDefault()

      if (viewerRef.current) {
        viewerRef.current.style.transform = `translate(${x}px, ${y}px)`
        setViewerPosition([x + viewerOffset[0], y + viewerOffset[1]])
      }
    },
    {
      domTarget: viewerRef,
      eventOptions: { passive: false },
      bounds: {
        left: -Math.abs(rightBound),
        right: rightBound,
        top: -Math.abs(bottomBound),
        bottom: bottomBound,
      },
    }
  )
  return <Viewer ref={viewerRef} {...bind()} src={require('../../assets/ink-reader.png')} />
}

export const InvisibleInkFacilitator: React.FC = () => {
  return (
    <>
      <ActivityInteractionIndicator type="individual" />
      <FacilitatorControls title="Invisible Ink">
        <P>View participant tabs to view messages</P>
      </FacilitatorControls>
    </>
  )
}

const Instruction = styled.p`
  margin: 5px 0 25px 0;
  color: #2eadf0;
  ${fontRegular};
`

const WithPin = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background-image: url(${require('session/assets/pin.svg')?.default});
    background-size: 100% 100%;
  }
`

const MaskSvg = styled.svg<{ show?: boolean }>`
  display: ${p => (p.show ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

const CorkBoardContainer = styled(WithPin)<{ hideCork?: boolean }>`
  ${p =>
    p.hideCork
      ? css`
          padding: 15px 3px;
          &::after {
            top: 40px;
          }
        `
      : css`
          padding: 50px 70px;
          border-radius: 10px;
          background-image: url(${require('session/assets/cork-background.jpg')});
        `}
`

const PagePadding = styled.div`
  padding: 30px 15px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
`

const Page = styled.div`
  position: relative;
  background: #fff;
  text-align: center;

  & ${Button} {
    cursor: pointer;
  }
`

const ActionButton = styled(Button)<{ show?: boolean }>`
  display: ${p => (p.show ? 'block' : 'none')};
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`

const EditButton = styled(Button)<{ show?: boolean }>`
  display: ${p => (p.show ? 'block' : 'none')};
  margin-left: 30px;
`

const TextArea = styled.textarea<{ disable?: boolean }>`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  max-height: 400px;
  ${fontLight};
  font-size: 1.5rem;
  border: none;
  background-image: repeating-linear-gradient(white, white 50px, #ccc 51px);
  line-height: 51px;
  padding: 8px 10px;
  resize: none;
  pointer-events: ${p => (p.disable ? 'none' : '')};
  color: ${p => (p.disable ? '#777e8a' : '#011a46')};

  ::placeholder {
    color: #bdc3e0;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`

const EncodedMessage = styled(TextArea)<{ show?: boolean }>`
  pointer-events: none;
  font-weight: 600;
  color: #ff8a00;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: ${p => (p.show ? 'block' : 'none')};
  clip-path: url(#svgPath);
`

const Viewer = styled.img`
  position: absolute;
  z-index: 15;
  top: calc(50% - ${imgSize[1] / 2}px);
  left: calc(50% - ${imgSize[0] / 2}px);
  width: ${imgSize[0]}px;
  height: ${imgSize[1]}px;
  cursor: grab;
`
