import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'

import { Option } from 'types'
import { fontBold } from 'fonts'
import { Hr, Row, Spacer, SpacerSize } from './ui'
import { useMeasure } from 'utils/useMeasure'

type Size = 'm' | 's'

interface Props<T> {
  name: string
  options: T[]
  value?: string | null
  onChange: (value: string, option: T) => void
  highlightIndex?: number
  disabled?: boolean
  size?: Size
  spacerSize?: SpacerSize
  autoFocus?: boolean
  flex?: boolean
  hideOuterDividers?: boolean
  hideInnerDividers?: boolean
  stackLabel?: boolean
  showIndex?: boolean
  indexFrom1?: boolean
  preventLabelOverflow?: boolean
}

export const Container = styled.label<{ disabled?: boolean; size: Size; inlineFlex?: boolean }>`
  display: ${p => (p.inlineFlex ? 'inline-flex' : 'flex')};
  flex-direction: row;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  opacity: ${p => (p.disabled ? 0.5 : 1)};
`

const DecoyInput = styled.input`
  opacity: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
`

const indicatorSizes = { m: '35px', s: '20px' }
const indicatorMargins = { m: '0 20px 0 5px', s: '0 10px 0 5px' }
const paddingHeight = { m: 20, s: 8 }

const Indicator = styled.span<{ checked?: boolean; size: Size }>`
  display: inline-block;
  width: ${p => indicatorSizes[p.size]};
  height: ${p => indicatorSizes[p.size]};
  background: white;
  border: 1px solid #abb5d8;
  border-radius: 50%;
  margin: ${p => indicatorMargins[p.size]};
  flex: none;

  ${DecoyInput}:focus + & {
    box-shadow: 0px 2px 5px 0px #adb2da;
  }

  ${DecoyInput}:active:enabled + & {
    transform: scale(0.9);
  }

  ${DecoyInput}:checked + & {
    background: ${p =>
      `linear-gradient(180deg, ${p.theme.appBackgroundTopColor} 0%, ${p.theme.appBackgroundBottomColor} 100%)`};
    box-shadow: inset 0px 0px 0px 3px white;
    @media print {
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      -ms-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

  ${DecoyInput}:checked:focus + & {
    box-shadow: inset 0px 0px 0px 3px white, 0px 2px 5px 0px #adb2da;
  }
`

const labelFontSizes = { m: '20px', s: '16px' }

export const Label = styled.div<{ highlight?: boolean; size: Size }>`
  ${fontBold}
  flex: auto;
  display: flex;
  font-size: ${p => labelFontSizes[p.size]};
  text-transform: none;
  color: ${p => (p.highlight ? '#7484d3' : '#001947')};
  min-width: 100px;
  margin-bottom: 2px; /* shift up a bit */
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
`

export function RadioGroup<T extends Option>({
  options,
  disabled,
  name,
  value,
  highlightIndex,
  onChange,
  size = 'm',
  autoFocus,
  flex = true,
  hideOuterDividers = false,
  hideInnerDividers = false,
  showIndex = false,
  indexFrom1 = false,
  preventLabelOverflow = false,
}: Props<T>) {
  return (
    <>
      {!hideOuterDividers && <Hr />}
      {options.map((option, i) => {
        return (
          <React.Fragment key={i}>
            {flex ? <div style={{ flex: 'auto', maxHeight: 20 }} /> : <div style={{ height: paddingHeight[size] }} />}
            <Container disabled={disabled} size={size} inlineFlex={preventLabelOverflow}>
              <DecoyInput
                type="radio"
                id={`${name}_${i}`}
                name={name}
                checked={value === option.value}
                onChange={() => onChange(option.value, option)}
                disabled={disabled}
                autoFocus={autoFocus && i === 0}
              />
              <Indicator checked={value === option.value} size={size} />
              <Label highlight={highlightIndex === i} size={size}>
                {showIndex ? (
                  <>
                    {i + (indexFrom1 ? 1 : 0)} &nbsp; &nbsp; {option.label || option.value}
                  </>
                ) : (
                  <>{option.label || option.value}</>
                )}
              </Label>
            </Container>
            {flex ? <div style={{ flex: 'auto', maxHeight: 20 }} /> : <div style={{ height: paddingHeight[size] }} />}
            {!hideInnerDividers && i < options.length - (hideOuterDividers ? 1 : 0) && <Hr />}
          </React.Fragment>
        )
      })}
    </>
  )
}

export function AutosizeRadioGroup<T extends Option>(props: Props<T>) {
  const [measureRef, { height, width }] = useMeasure()
  const [size, setSize] = useState(props.size || 'm')
  useEffect(() => {
    if (!width) return
    if (height > 15 && size === 's') {
      setSize('m')
    } else if (height < 5 && size === 'm') {
      setSize('s')
    }
  }, [width, height, size])
  return (
    <>
      <RadioGroup {...props} size={size} />
      <div ref={measureRef} style={{ flex: 'auto' }} />
    </>
  )
}

export function InlineRadioGroup<T extends Option>({
  options,
  disabled,
  name,
  value,
  highlightIndex,
  onChange,
  size = 'm',
  spacerSize = 'l',
  autoFocus,
  stackLabel,
  showIndex,
  indexFrom1,
}: Props<T>) {
  return (
    <Row>
      {options.map((option, i) => {
        return (
          <Fragment key={i}>
            <Container
              disabled={disabled}
              size={size}
              style={stackLabel ? { display: 'block', textAlign: 'center' } : {}}>
              <DecoyInput
                type="radio"
                id={`${name}_${i}`}
                name={name}
                checked={value === option.value}
                onChange={() => onChange(option.value, option)}
                disabled={disabled}
                autoFocus={autoFocus && i === 0}
              />
              <Indicator checked={value === option.value} size={size} />
              <Label highlight={highlightIndex === i} size={size}>
                {showIndex ? (
                  <>
                    {i + (indexFrom1 ? 1 : 0)} &nbsp; &nbsp; {option.label || option.value}
                  </>
                ) : (
                  <>{option.label || option.value}</>
                )}
              </Label>
            </Container>
            <Spacer size={spacerSize || size} />
            <Spacer size={spacerSize || size} />
          </Fragment>
        )
      })}
    </Row>
  )
}
