import { useState, useEffect } from 'react'

import StopWatch from 'app/StopWatch'
import { useSound } from 'utils/useSound'
import { useSfxMuted } from './useMuted'

export function useStopWatch(alarmInterval: number = 0, autostart: boolean = true) {
  const alarm = useSfxMuted(useSound(require('common/assets/audio/AlarmSound.mp3'), false))

  const [timer] = useState<StopWatch>(() => {
    return new StopWatch(alarmInterval)
  })

  // start when mounted, pause when unmounted
  useEffect(() => {
    if (autostart) timer.start()
    return () => {
      timer.pause()
      timer.destroy()
    }
  }, [timer, autostart])

  // play alarm sound and show pda warning when alarm received
  useEffect(() => {
    const callback = () => {
      alarm.play()
    }
    timer.on('alarm', callback)
    return () => {
      timer.off('alarm', callback)
    }
  }, [timer, alarm])

  return timer
}
