import React, { Fragment } from 'react'
import {
  SectionProperty,
  SectionPropsBase,
  TrainingPanel,
  TrainingStep as TrainingStepType,
} from '../shared/training/types'
import { getSections } from './reducers'
import { GenericTextSection } from '../session/sections/Text'
import styled, { css } from 'styled-components'
import { H1, H3, Hr, P, Spacer } from 'common/ui'
import { QuizQuestion } from './sections/QuizQuestion'
import { GenericGridSection } from 'session/sections/MediaGrid'
import { StreamedVideoGridSection } from './sections/StreamedVideoGridSection'
import { StreamedVideoBlockSection } from './sections/StreamedVideoBlockSection'
import { GenericNotificationSection } from 'session/sections/Notification'
import { TrainingGroupedSection } from './sections/GroupedSection'
import { TrainingEmbeddedActivitySection } from 'session/sections/EmbeddedActivity'
import { Block, BlockBody, BlockMedia, GenericBlockSection } from 'session/sections/Block'
import { RichText, RichTextContainer } from 'session/common/RichText'
import { fontBold } from 'fonts'
import { GenericDownloadResourceSection } from 'session/sections/DownloadResourceSection'

export const sectionComponents: {
  [key in SectionProperty]: React.FC<SectionPropsBase & { property: key }>
} = {
  text_sections: GenericTextSection,
  media_grid_sections: GenericGridSection,
  block_sections: GenericBlockSection,
  streamed_video_grid_sections: StreamedVideoGridSection,
  streamed_video_block_sections: StreamedVideoBlockSection,
  notification_sections: GenericNotificationSection,
  grouped_sections: TrainingGroupedSection,
  embedded_activity_sections: TrainingEmbeddedActivitySection,
  download_resource_sections: GenericDownloadResourceSection,
}

export const TrainingStep: React.FC<{ step: TrainingStepType; reviewMode: boolean }> = ({ step, reviewMode }) => {
  return (
    <>
      {step.section && (
        <>
          <SectionTitle children={step.section} />
          <Spacer />
        </>
      )}
      {(step.panels || []).map((panel, idx) => {
        const sections = getSections(panel)
        const hideTitle = panel.theme === 'purple' || panel.theme === 'purple-header'

        return (
          <Fragment key={idx}>
            <Panel theme={panel.theme}>
              {panel.theme === 'non-compulsory' && (
                <Header color="#ff8a00">
                  <P color="#fff">Extension Topic</P>
                </Header>
              )}
              {panel.theme === 'purple' && (
                <Header color="transparent">
                  <P color="#6737B5">{panel.title}</P>
                  <div style={{ padding: '0 20px' }}>
                    <Hr />
                  </div>
                </Header>
              )}
              {panel.theme === 'purple-header' && (
                <Header color="#6737B5">
                  <P color="#fff">{panel.title}</P>
                </Header>
              )}
              <PaddingContainer>
                {panel.title && !hideTitle && <H3>{panel.title}</H3>}
                {panel.content_type === 'question' && (
                  <>
                    <RichText text={panel.question} />
                    <Spacer />
                    <QuizQuestion {...panel} reviewMode={reviewMode} />
                  </>
                )}
                {panel.content_type === 'static' &&
                  sections.map((sectionObj, i) => {
                    const Component = sectionComponents[sectionObj.property]
                    // @ts-ignore
                    return <Component key={i} {...sectionObj} />
                  })}
              </PaddingContainer>
            </Panel>
            <Spacer />
          </Fragment>
        )
      })}
    </>
  )
}

const PaddingContainer = styled.div`
  padding: 20px;
`

const Panel = styled.div<{ theme: TrainingPanel['theme'] }>`
  background: #fff;
  border-radius: 10px;
  position: relative;

  ${p =>
    p.theme === 'no-panel'
      ? css`
          background: none;

          ${PaddingContainer} {
            padding: 0;
          }

          ${BlockBody} {
            background: #fff !important;
          }

          ${BlockMedia} {
            border-radius: 10px 0 0 10px !important;
          }
        `
      : ''}

${p =>
  p.theme === 'purple'
    ? css`
        background: #d4c6ec;

        ${PaddingContainer} > * {
          margin-top: 0;
        }

        ${Header} ${P} {
          ${fontBold};
          font-size: 22px;
          line-height: 1;
        }

        hr {
          border: none;
          border-bottom: 1px solid #6737b5;
          box-shadow: none;
        }
      `
    : ''}

  ${p =>
    p.theme === 'purple-header'
      ? css`
          box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.8);

          ${PaddingContainer} > * {
            margin-top: 0;
          }

          ${Header} ${P} {
            text-transform: uppercase;
            font-size: ;
          }
        `
      : ''}

  ${H3} {
    color: #955df2;
    text-transform: capitalize;
    font-size: 1.3rem;
  }

  h1, h2, h3 {
    color: #6737B5 !important;
  }

  h3 {
    margin-bottom: 15px;
  }

  strong {
    ${fontBold};
    font-size: 20px;
    line-height: 1;
  }

  ${RichTextContainer} {
    margin-top: 10px;
    margin-bottom: 30px;

    p {
      font-size: 20px;
    }

    ol, ul {
      ol, ul {
        margin-top: 8px;
        margin-left: 0;
        margin-inline-start: 0;
        padding-left: 0;
      }
    }

    li {
      margin-bottom: 8px;
      font-size: 20px;
      padding-left: 25px;
    }
    
    ol {
      padding-left: 0;

      > li {
        list-style: decimal;
        padding-left: 0.5em;
        margin-left: 1em;

        &:before {
          display: none;
        }

        &::marker {
          color: #6737B5;
          font-weight: bold;
        }

        > ul, > ol {
          padding-left: 25px;
        }
      }
    }

    ul > li {
      &::before {
        background: url(${require('session/assets/list-bullet-purple.svg')?.default});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 0.8em;
        width: 0.8em;
        top: 7px;
      }

      li::before {
        background: url(${require('session/assets/list-bullet-light-purple.svg')?.default});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 0.8em;
        width: 0.8em;
        top: 7px;
      }
    }
  }

  ${Block} {
    ${BlockMedia} {
      border-radius: 10px;
      border: 1px solid #bdc3e0;
      border-right: 1px solid #bdc3e0;
    }

    ${BlockBody} {
      > div {
        margin-top: 0;  
      }

      padding: 5px 15px;
      background: transparent;
      border: none;
    }
  }
`

const SectionTitle = styled(H1)`
  color: #fff;
  padding: 20px;
  background: #6737b5;
  border-radius: 10px;
  font-size: 1.3rem;
`

const Header = styled.div<{ color: string }>`
  background-color: ${p => p.color};
  border-radius: 10px 10px 0 0;

  ${P} {
    font-size: 1.3rem;
    font-weight: 600;
    padding: 20px;
    margin: 0;
  }

  ${Hr} {
    border-bottom-color: #6737b5;
    opacity: 0.5;
  }
`
