type ConversionFunction = (red: number, green: number, blue: number) => string

type UnknownColorObject = { [key: string]: number }

export type HslColor = {
  hue: number
  saturation: number
  lightness: number
}

export type HslaColor = {
  hue: number
  saturation: number
  lightness: number
  alpha: number
}

export type RgbColor = {
  red: number
  green: number
  blue: number
}

export type RgbaColor = {
  red: number
  green: number
  blue: number
  alpha: number
}

export function rgb(value: RgbColor | number, green?: number, blue?: number): string {
  if (typeof value === 'number' && typeof green === 'number' && typeof blue === 'number') {
    return reduceHexValue(`#${numberToHex(value)}${numberToHex(green)}${numberToHex(blue)}`)
  } else if (typeof value === 'object' && green === undefined && blue === undefined) {
    return reduceHexValue(`#${numberToHex(value.red)}${numberToHex(value.green)}${numberToHex(value.blue)}`)
  }

  throw new Error()
}

export function rgba(
  firstValue: RgbaColor | number | string,
  secondValue?: number,
  thirdValue?: number,
  fourthValue?: number
): string {
  if (typeof firstValue === 'string' && typeof secondValue === 'number') {
    const rgbValue = parseToRgb(firstValue)
    return `rgba(${rgbValue.red},${rgbValue.green},${rgbValue.blue},${secondValue})`
  } else if (
    typeof firstValue === 'number' &&
    typeof secondValue === 'number' &&
    typeof thirdValue === 'number' &&
    typeof fourthValue === 'number'
  ) {
    return fourthValue >= 1
      ? rgb(firstValue, secondValue, thirdValue)
      : `rgba(${firstValue},${secondValue},${thirdValue},${fourthValue})`
  } else if (
    typeof firstValue === 'object' &&
    secondValue === undefined &&
    thirdValue === undefined &&
    fourthValue === undefined
  ) {
    return firstValue.alpha >= 1
      ? rgb(firstValue.red, firstValue.green, firstValue.blue)
      : `rgba(${firstValue.red},${firstValue.green},${firstValue.blue},${firstValue.alpha})`
  }

  throw new Error()
}

export function hsl(value: HslColor | number, saturation?: number, lightness?: number): string {
  if (typeof value === 'number' && typeof saturation === 'number' && typeof lightness === 'number') {
    return hslToHex(value, saturation, lightness)
  } else if (typeof value === 'object' && saturation === undefined && lightness === undefined) {
    return hslToHex(value.hue, value.saturation, value.lightness)
  }

  throw new Error()
}

export function hsla(value: HslaColor | number, saturation?: number, lightness?: number, alpha?: number): string {
  if (
    typeof value === 'number' &&
    typeof saturation === 'number' &&
    typeof lightness === 'number' &&
    typeof alpha === 'number'
  ) {
    return alpha >= 1
      ? hslToHex(value, saturation, lightness)
      : `rgba(${hslToRgb(value, saturation, lightness)},${alpha})`
  } else if (typeof value === 'object' && saturation === undefined && lightness === undefined && alpha === undefined) {
    return value.alpha >= 1
      ? hslToHex(value.hue, value.saturation, value.lightness)
      : `rgba(${hslToRgb(value.hue, value.saturation, value.lightness)},${value.alpha})`
  }

  throw new Error()
}

export const isRgb = (color: UnknownColorObject): boolean =>
  typeof color.red === 'number' &&
  typeof color.green === 'number' &&
  typeof color.blue === 'number' &&
  (typeof color.alpha !== 'number' || typeof color.alpha === 'undefined')

export const isRgba = (color: UnknownColorObject): boolean =>
  typeof color.red === 'number' &&
  typeof color.green === 'number' &&
  typeof color.blue === 'number' &&
  typeof color.alpha === 'number'

export const isHsl = (color: UnknownColorObject): boolean =>
  typeof color.hue === 'number' &&
  typeof color.saturation === 'number' &&
  typeof color.lightness === 'number' &&
  (typeof color.alpha !== 'number' || typeof color.alpha === 'undefined')

export const isHsla = (color: UnknownColorObject): boolean =>
  typeof color.hue === 'number' &&
  typeof color.saturation === 'number' &&
  typeof color.lightness === 'number' &&
  typeof color.alpha === 'number'

export function toColorString(color: UnknownColorObject): string {
  if (typeof color !== 'object') throw new Error()
  if (isRgba(color)) return rgba(color as RgbaColor)
  if (isRgb(color)) return rgb(color as RgbColor)
  if (isHsla(color)) return hsla(color as HslaColor)
  if (isHsl(color)) return hsl(color as HslColor)
  throw new Error()
}

export const guard = (lowerBoundary: number, upperBoundary: number, value: number): number =>
  Math.max(lowerBoundary, Math.min(upperBoundary, value))

export function numberToHex(value: number): string {
  const hex = value.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

export function hslToHex(hue: number, saturation: number, lightness: number): string {
  return hslToRgb(hue, saturation, lightness, convertToHex)
}

export function colorToHex(color: number): string {
  return numberToHex(Math.round(color * 255))
}

export function convertToHex(red: number, green: number, blue: number): string {
  return reduceHexValue(`#${colorToHex(red)}${colorToHex(green)}${colorToHex(blue)}`)
}

export function colorToInt(color: number): number {
  return Math.round(color * 255)
}

export function convertToInt(red: number, green: number, blue: number): string {
  return `${colorToInt(red)},${colorToInt(green)},${colorToInt(blue)}`
}

export function reduceHexValue(value: string): string {
  if (value.length === 7 && value[1] === value[2] && value[3] === value[4] && value[5] === value[6]) {
    return `#${value[1]}${value[3]}${value[5]}`
  }
  return value
}

const namedColorMap = {
  aliceblue: 'f0f8ff',
  antiquewhite: 'faebd7',
  aqua: '00ffff',
  aquamarine: '7fffd4',
  azure: 'f0ffff',
  beige: 'f5f5dc',
  bisque: 'ffe4c4',
  black: '000',
  blanchedalmond: 'ffebcd',
  blue: '0000ff',
  blueviolet: '8a2be2',
  brown: 'a52a2a',
  burlywood: 'deb887',
  cadetblue: '5f9ea0',
  chartreuse: '7fff00',
  chocolate: 'd2691e',
  coral: 'ff7f50',
  cornflowerblue: '6495ed',
  cornsilk: 'fff8dc',
  crimson: 'dc143c',
  cyan: '00ffff',
  darkblue: '00008b',
  darkcyan: '008b8b',
  darkgoldenrod: 'b8860b',
  darkgray: 'a9a9a9',
  darkgreen: '006400',
  darkgrey: 'a9a9a9',
  darkkhaki: 'bdb76b',
  darkmagenta: '8b008b',
  darkolivegreen: '556b2f',
  darkorange: 'ff8c00',
  darkorchid: '9932cc',
  darkred: '8b0000',
  darksalmon: 'e9967a',
  darkseagreen: '8fbc8f',
  darkslateblue: '483d8b',
  darkslategray: '2f4f4f',
  darkslategrey: '2f4f4f',
  darkturquoise: '00ced1',
  darkviolet: '9400d3',
  deeppink: 'ff1493',
  deepskyblue: '00bfff',
  dimgray: '696969',
  dimgrey: '696969',
  dodgerblue: '1e90ff',
  firebrick: 'b22222',
  floralwhite: 'fffaf0',
  forestgreen: '228b22',
  fuchsia: 'ff00ff',
  gainsboro: 'dcdcdc',
  ghostwhite: 'f8f8ff',
  gold: 'ffd700',
  goldenrod: 'daa520',
  gray: '808080',
  green: '008000',
  greenyellow: 'adff2f',
  grey: '808080',
  honeydew: 'f0fff0',
  hotpink: 'ff69b4',
  indianred: 'cd5c5c',
  indigo: '4b0082',
  ivory: 'fffff0',
  khaki: 'f0e68c',
  lavender: 'e6e6fa',
  lavenderblush: 'fff0f5',
  lawngreen: '7cfc00',
  lemonchiffon: 'fffacd',
  lightblue: 'add8e6',
  lightcoral: 'f08080',
  lightcyan: 'e0ffff',
  lightgoldenrodyellow: 'fafad2',
  lightgray: 'd3d3d3',
  lightgreen: '90ee90',
  lightgrey: 'd3d3d3',
  lightpink: 'ffb6c1',
  lightsalmon: 'ffa07a',
  lightseagreen: '20b2aa',
  lightskyblue: '87cefa',
  lightslategray: '789',
  lightslategrey: '789',
  lightsteelblue: 'b0c4de',
  lightyellow: 'ffffe0',
  lime: '0f0',
  limegreen: '32cd32',
  linen: 'faf0e6',
  magenta: 'f0f',
  maroon: '800000',
  mediumaquamarine: '66cdaa',
  mediumblue: '0000cd',
  mediumorchid: 'ba55d3',
  mediumpurple: '9370db',
  mediumseagreen: '3cb371',
  mediumslateblue: '7b68ee',
  mediumspringgreen: '00fa9a',
  mediumturquoise: '48d1cc',
  mediumvioletred: 'c71585',
  midnightblue: '191970',
  mintcream: 'f5fffa',
  mistyrose: 'ffe4e1',
  moccasin: 'ffe4b5',
  navajowhite: 'ffdead',
  navy: '000080',
  oldlace: 'fdf5e6',
  olive: '808000',
  olivedrab: '6b8e23',
  orange: 'ffa500',
  orangered: 'ff4500',
  orchid: 'da70d6',
  palegoldenrod: 'eee8aa',
  palegreen: '98fb98',
  paleturquoise: 'afeeee',
  palevioletred: 'db7093',
  papayawhip: 'ffefd5',
  peachpuff: 'ffdab9',
  peru: 'cd853f',
  pink: 'ffc0cb',
  plum: 'dda0dd',
  powderblue: 'b0e0e6',
  purple: '800080',
  rebeccapurple: '639',
  red: 'f00',
  rosybrown: 'bc8f8f',
  royalblue: '4169e1',
  saddlebrown: '8b4513',
  salmon: 'fa8072',
  sandybrown: 'f4a460',
  seagreen: '2e8b57',
  seashell: 'fff5ee',
  sienna: 'a0522d',
  silver: 'c0c0c0',
  skyblue: '87ceeb',
  slateblue: '6a5acd',
  slategray: '708090',
  slategrey: '708090',
  snow: 'fffafa',
  springgreen: '00ff7f',
  steelblue: '4682b4',
  tan: 'd2b48c',
  teal: '008080',
  thistle: 'd8bfd8',
  tomato: 'ff6347',
  turquoise: '40e0d0',
  violet: 'ee82ee',
  wheat: 'f5deb3',
  white: 'fff',
  whitesmoke: 'f5f5f5',
  yellow: 'ff0',
  yellowgreen: '9acd32',
}

export function nameToHex(color: string): string {
  if (typeof color !== 'string') return color
  const normalizedColorName = color.toLowerCase() as keyof typeof namedColorMap
  return namedColorMap[normalizedColorName] ? `#${namedColorMap[normalizedColorName]}` : color
}

const hexRegex = /^#[a-fA-F0-9]{6}$/
const hexRgbaRegex = /^#[a-fA-F0-9]{8}$/
const reducedHexRegex = /^#[a-fA-F0-9]{3}$/
const reducedRgbaHexRegex = /^#[a-fA-F0-9]{4}$/
const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/i
const rgbaRegex = /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*([-+]?[0-9]*[.]?[0-9]+)\s*\)$/i
const hslRegex = /^hsl\(\s*(\d{0,3}[.]?[0-9]+)\s*,\s*(\d{1,3}[.]?[0-9]?)%\s*,\s*(\d{1,3}[.]?[0-9]?)%\s*\)$/i
const hslaRegex = /^hsla\(\s*(\d{0,3}[.]?[0-9]+)\s*,\s*(\d{1,3}[.]?[0-9]?)%\s*,\s*(\d{1,3}[.]?[0-9]?)%\s*,\s*([-+]?[0-9]*[.]?[0-9]+)\s*\)$/i

export function parseToHsl(color: string): HslColor | HslaColor {
  // Note: At a later stage we can optimize this function as right now a hsl
  // color would be parsed converted to rgb values and converted back to hsl.
  return rgbToHsl(parseToRgb(color))
}

export function parseToRgb(color: string): RgbColor | RgbaColor {
  if (typeof color !== 'string') throw new Error()
  const normalizedColor = nameToHex(color)
  if (normalizedColor.match(hexRegex)) {
    return {
      red: parseInt(`${normalizedColor[1]}${normalizedColor[2]}`, 16),
      green: parseInt(`${normalizedColor[3]}${normalizedColor[4]}`, 16),
      blue: parseInt(`${normalizedColor[5]}${normalizedColor[6]}`, 16),
    }
  }
  if (normalizedColor.match(hexRgbaRegex)) {
    const alpha = parseFloat((parseInt(`${normalizedColor[7]}${normalizedColor[8]}`, 16) / 255).toFixed(2))
    return {
      red: parseInt(`${normalizedColor[1]}${normalizedColor[2]}`, 16),
      green: parseInt(`${normalizedColor[3]}${normalizedColor[4]}`, 16),
      blue: parseInt(`${normalizedColor[5]}${normalizedColor[6]}`, 16),
      alpha,
    }
  }
  if (normalizedColor.match(reducedHexRegex)) {
    return {
      red: parseInt(`${normalizedColor[1]}${normalizedColor[1]}`, 16),
      green: parseInt(`${normalizedColor[2]}${normalizedColor[2]}`, 16),
      blue: parseInt(`${normalizedColor[3]}${normalizedColor[3]}`, 16),
    }
  }
  if (normalizedColor.match(reducedRgbaHexRegex)) {
    const alpha = parseFloat((parseInt(`${normalizedColor[4]}${normalizedColor[4]}`, 16) / 255).toFixed(2))
    return {
      red: parseInt(`${normalizedColor[1]}${normalizedColor[1]}`, 16),
      green: parseInt(`${normalizedColor[2]}${normalizedColor[2]}`, 16),
      blue: parseInt(`${normalizedColor[3]}${normalizedColor[3]}`, 16),
      alpha,
    }
  }
  const rgbMatched = rgbRegex.exec(normalizedColor)
  if (rgbMatched) {
    return {
      red: parseInt(`${rgbMatched[1]}`, 10),
      green: parseInt(`${rgbMatched[2]}`, 10),
      blue: parseInt(`${rgbMatched[3]}`, 10),
    }
  }
  const rgbaMatched = rgbaRegex.exec(normalizedColor)
  if (rgbaMatched) {
    return {
      red: parseInt(`${rgbaMatched[1]}`, 10),
      green: parseInt(`${rgbaMatched[2]}`, 10),
      blue: parseInt(`${rgbaMatched[3]}`, 10),
      alpha: parseFloat(`${rgbaMatched[4]}`),
    }
  }
  const hslMatched = hslRegex.exec(normalizedColor)
  if (hslMatched) {
    const hue = parseInt(`${hslMatched[1]}`, 10)
    const saturation = parseInt(`${hslMatched[2]}`, 10) / 100
    const lightness = parseInt(`${hslMatched[3]}`, 10) / 100
    const rgbColorString = `rgb(${hslToRgb(hue, saturation, lightness)})`
    const hslRgbMatched = rgbRegex.exec(rgbColorString)
    if (!hslRgbMatched) throw new Error()
    return {
      red: parseInt(`${hslRgbMatched[1]}`, 10),
      green: parseInt(`${hslRgbMatched[2]}`, 10),
      blue: parseInt(`${hslRgbMatched[3]}`, 10),
    }
  }
  const hslaMatched = hslaRegex.exec(normalizedColor)
  if (hslaMatched) {
    const hue = parseInt(`${hslaMatched[1]}`, 10)
    const saturation = parseInt(`${hslaMatched[2]}`, 10) / 100
    const lightness = parseInt(`${hslaMatched[3]}`, 10) / 100
    const rgbColorString = `rgb(${hslToRgb(hue, saturation, lightness)})`
    const hslRgbMatched = rgbRegex.exec(rgbColorString)
    if (!hslRgbMatched) throw new Error()
    return {
      red: parseInt(`${hslRgbMatched[1]}`, 10),
      green: parseInt(`${hslRgbMatched[2]}`, 10),
      blue: parseInt(`${hslRgbMatched[3]}`, 10),
      alpha: parseFloat(`${hslaMatched[4]}`),
    }
  }
  throw new Error()
}

export function rgbToHsl(color: RgbColor | RgbaColor): HslColor | HslaColor {
  // make sure rgb are contained in a set of [0, 255]
  const red = color.red / 255
  const green = color.green / 255
  const blue = color.blue / 255

  const max = Math.max(red, green, blue)
  const min = Math.min(red, green, blue)
  const lightness = (max + min) / 2

  if (max === min) {
    // achromatic
    if ('alpha' in color && color.alpha !== undefined) {
      return {
        hue: 0,
        saturation: 0,
        lightness,
        alpha: color.alpha,
      }
    } else {
      return { hue: 0, saturation: 0, lightness }
    }
  }

  let hue
  const delta = max - min
  const saturation = lightness > 0.5 ? delta / (2 - max - min) : delta / (max + min)
  switch (max) {
    case red:
      hue = (green - blue) / delta + (green < blue ? 6 : 0)
      break
    case green:
      hue = (blue - red) / delta + 2
      break
    default:
      // blue case
      hue = (red - green) / delta + 4
      break
  }

  hue *= 60
  if ('alpha' in color && color.alpha !== undefined) {
    return {
      hue,
      saturation,
      lightness,
      alpha: color.alpha,
    }
  }
  return { hue, saturation, lightness }
}

export function hslToRgb(
  hue: number,
  saturation: number,
  lightness: number,
  convert: ConversionFunction = convertToInt
): string {
  if (saturation === 0) {
    // achromatic
    return convert(lightness, lightness, lightness)
  }

  // formulae from https://en.wikipedia.org/wiki/HSL_and_HSV
  const huePrime = (((hue % 360) + 360) % 360) / 60
  const chroma = (1 - Math.abs(2 * lightness - 1)) * saturation
  const secondComponent = chroma * (1 - Math.abs((huePrime % 2) - 1))

  let red = 0
  let green = 0
  let blue = 0

  if (huePrime >= 0 && huePrime < 1) {
    red = chroma
    green = secondComponent
  } else if (huePrime >= 1 && huePrime < 2) {
    red = secondComponent
    green = chroma
  } else if (huePrime >= 2 && huePrime < 3) {
    green = chroma
    blue = secondComponent
  } else if (huePrime >= 3 && huePrime < 4) {
    green = secondComponent
    blue = chroma
  } else if (huePrime >= 4 && huePrime < 5) {
    red = secondComponent
    blue = chroma
  } else if (huePrime >= 5 && huePrime < 6) {
    red = chroma
    blue = secondComponent
  }

  const lightnessModification = lightness - chroma / 2
  const finalRed = red + lightnessModification
  const finalGreen = green + lightnessModification
  const finalBlue = blue + lightnessModification
  return convert(finalRed, finalGreen, finalBlue)
}
