import React, { useState } from 'react'
import {
  EmotionometerStickerBase,
  EmotionometerSticker,
  EmotionometerDevice,
  EmotionometerType,
} from 'shared/e-telligence/types'

import { MobileModalPanel } from 'app/MobileMessageModal'
import { Column, Padding, Spacer, OutlineButton, Row } from 'common/ui'
import { StickerTile } from './StickerTile'
import { StickerEditModal } from './StickerEditModal'

export interface Props {
  sticker: EmotionometerStickerBase
  emotionometerType: EmotionometerType
  editMode?: boolean
  onClose: () => void
  onRemove?: () => void
  onUpdateSticker: (data: EmotionometerSticker<EmotionometerDevice>) => void
  readonly?: boolean
}

export const StickerViewModal: React.FC<Props> = ({
  sticker,
  emotionometerType,
  editMode,
  onClose,
  onRemove,
  onUpdateSticker,
  readonly,
}) => {
  const [editSticker, setEditSticker] = useState<typeof sticker | undefined>()
  const updateSticker = (data: EmotionometerSticker<EmotionometerDevice>) => {
    onUpdateSticker(data)
    setEditSticker(undefined)
    onClose()
  }
  return (
    <>
      <MobileModalPanel isOpen onRequestClose={onClose}>
        <Column flex="1 1 auto" style={{ overflow: 'auto' }}>
          <Padding size="s">
            <StickerTile sticker={sticker} size={115} />
            {!readonly && editMode && !sticker.isPreset && (
              <>
                <Spacer size="s" />
                <OutlineButton size="xs" onClick={() => setEditSticker({ ...sticker })} children="Edit" />
              </>
            )}
            <Spacer size="s" />
            <Row flex>
              {!readonly && editMode && onRemove && (
                <>
                  <OutlineButton flex size="xs" theme="orange" onClick={() => onRemove()} children="Remove" />
                  <Spacer size="s" />
                </>
              )}
              <OutlineButton flex size="xs" theme="gray" onClick={() => onClose()} children="Close" />
            </Row>
          </Padding>
        </Column>
      </MobileModalPanel>
      {!!editSticker && (
        <StickerEditModal
          initialValue={editSticker}
          onClose={() => setEditSticker(undefined)}
          onSave={updateSticker}
          device={sticker.type}
        />
      )}
    </>
  )
}
