export interface JWTPayload {
  aud: string
  jti: string
  iat: number
  nbf: number
  exp: number
  sub: string
  scopes: string[]
}

export function decodeJWT(jwt: string): JWTPayload {
  try {
    return JSON.parse(atob(jwt.split('.')[1]))
  } catch (e) {
    return { aud: '', jti: '', iat: 0, nbf: 0, exp: 0, sub: '', scopes: [] }
  }
}
