import { emojiUnicodeEntries, compoundEncoding, softBankMapping } from 'constants/emojiCodes'

const emojiIDLookup: { [k: string]: string } = {}

export const fixedFromCharCode = (codePt: number): string => {
  if (codePt > 0xffff) {
    codePt -= 0x10000
    return String.fromCharCode(0xd800 + (codePt >> 10), 0xdc00 + (codePt & 0x3ff))
  } else {
    return String.fromCharCode(codePt)
  }
}

let emojiRegexStr = '('

for (let i = 0, li = compoundEncoding.length; i < li; i++) {
  let emojiChar = ''
  for (var j = 0, lj = compoundEncoding[i].source.length; j < lj; j++) {
    emojiChar += fixedFromCharCode(compoundEncoding[i].source[j])
  }
  emojiRegexStr += emojiChar
  emojiRegexStr += '|'
  emojiIDLookup[emojiChar] = compoundEncoding[i].result.toString(16)
}

for (let softBankChar in softBankMapping) {
  let emojiChar = fixedFromCharCode((softBankChar as unknown) as number)
  emojiRegexStr += emojiChar
  emojiRegexStr += '|'
  emojiIDLookup[emojiChar] = softBankMapping[softBankChar].toString(16)
}

for (let i = 0, li = emojiUnicodeEntries.length; i < li; i++) {
  let emojiChar = fixedFromCharCode(emojiUnicodeEntries[i])
  emojiRegexStr += emojiChar
  if (i < li - 1) emojiRegexStr += '|'
  var emojiId = emojiUnicodeEntries[i].toString(16)
  while (emojiId.length < 4) emojiId = '0' + emojiId
  emojiIDLookup[emojiChar] = emojiId
}

emojiRegexStr += ')'

const emojiRegex = new RegExp(emojiRegexStr, 'g')

export const getEmojiId = (text: string) => {
  const match = text.match(emojiRegex)
  if (match) return emojiIDLookup[match[0]]
  return null
}
;(window as any).getEmojiId = getEmojiId
