import styled from 'styled-components'
import { fontBold } from 'fonts'
import { CircleButton } from './CircleButton'

export const Accordion = styled.div``
export const AccordionInner = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: space-between;
  align-items: center;
  ${fontBold}
  font-size: 22px;
  line-height: 1;
  letter-spacing: 0.03em;
`

export const AccordionImage = styled.img`
  margin-right: 1rem;
  border-radius: 10px;
  border: 1px solid #cdd2e4;
`

export const AccordionTitle = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  color: #011a46;

  & span {
    color: #2d90dc;
  }
`

export const AccordionRight = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & ${CircleButton} {
    pointer-events: none;
  }
`

export const AccordionBody = styled.div<{ expanded: boolean }>`
  display: ${p => (p.expanded ? 'block' : 'none')};
`
