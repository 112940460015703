import React, { useState, useEffect, useRef, ReactNode } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { fontRegular } from 'fonts'
import Timer from './Timer'
import { SVG } from 'common/SVG'
import { useOnline } from 'utils/useOnline'
import { isOnline } from 'utils/deviceUtils'

const timer = new Timer(21, 99)
timer.setPaused(true)

export const OfflineNotification: React.FC<{ onlineMessage?: ReactNode; offlineMessage?: ReactNode }> = ({
  onlineMessage,
  offlineMessage,
}) => {
  const online = useOnline()
  const [dismissed, setDismissed] = useState(true)
  const [, reRender] = useState(0)
  const previousOnline = useRef<boolean>(isOnline())
  useEffect(() => {
    if (previousOnline.current !== online) {
      if (online) {
        setTimeout(() => setDismissed(true), 3000)
      } else {
        timer.reset()
        timer.start()
      }
      setDismissed(false)
      previousOnline.current = online
    }
  }, [online])
  useEffect(() => {
    const autoDismiss = () => {
      setDismissed(true)
      timer.pause()
    }
    const timeUpdate = () => reRender(value => value + 1)
    timer.addListener('complete', autoDismiss)
    timer.addListener('timeupdate', timeUpdate)
    return () => {
      timer.removeListener('complete', autoDismiss)
      timer.removeListener('timeupdate', timeUpdate)
    }
  }, [])
  return (
    <Notification visible={!dismissed}>
      <NotificationDiv
        onClick={() => {
          console.log(dismissed)
          console.log('dismissed')
          setDismissed(true)
        }}>
        {online
          ? onlineMessage || `You're back online!`
          : offlineMessage || (
              <>
                You’ve gone offline.
                <br />
                Please try to re-connect before proceeding.
              </>
            )}
        <span style={{ paddingLeft: 15, verticalAlign: 'middle' }}>
          <SVG viewboxSize={24} size={14}>
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              fill="white"
            />
          </SVG>
        </span>
        {!online && (
          <>
            <br />
            (Auto-hiding in {timer.remaining}s)
          </>
        )}
      </NotificationDiv>
    </Notification>
  )
}

function useAnimatedNotification(visible: boolean) {
  const props = useSpring({
    config: { tension: 120, clamp: true },
    opacity: visible ? 1 : 0,
    transform: visible ? 'translate3d(0, 0, 100px)' : 'translate3d(0, -150%, 100px)',
  })
  return props
}

const Notification: React.FC<{ visible: boolean }> = ({ visible, children }) => {
  const style = useAnimatedNotification(visible)
  return <NotificationContainer style={style}>{children}</NotificationContainer>
}

const NotificationContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
`

const NotificationDiv = styled.div`
  ${fontRegular}
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  margin: 15px auto;
  padding: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  pointer-events: initial;
`
