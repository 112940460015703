import React from 'react'
import { useHistory } from 'react-router-dom'

import { NavRouteProps } from 'dashboards/types'
import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useFacDashData } from './FacilitatorDashboardState'
import { baseUrl } from './FacilitatorDashboard'

import { Button, Spacer, P } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { ProviderEntity } from 'shared/dashboard/types'
import { Form } from 'dashboards/common/Form'
import { FormDataSubsetProps } from 'dashboards/utils/formDataHooks'
import { FormField } from 'dashboards/common/FormField'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import Select, { OptionTypeBase } from 'react-select'
import sortBy from 'lodash/sortBy'

interface JoinProviderFormData {
  provider_uid: string
}

export const ProviderRequest: React.FC<NavRouteProps> = ({ route }) => {
  const history = useHistory()
  const { accessToken } = useUserState()
  const [providerLinkRequests, loadingProviderLinkRequests] = useFacDashData('providerLinkRequests', [])
  const [providers, { loading, errorLoading }] = useEndpoint<ProviderEntity[]>(`/api/v1/providers/master_list`, [])

  const disabledProviderIds = providerLinkRequests
    .filter(({ status }) => status === 'pending' || status === 'rejected' || status === 'accepted')
    .map(({ provider_id }) => provider_id)

  const providerOptions: OptionTypeBase[] = (providers || []).map(({ id, name }) => {
    const disabled = disabledProviderIds.indexOf(id) >= 0
    const status = disabled && (providerLinkRequests.find(({ provider_id }) => provider_id === id)?.status || false)
    return {
      value: id,
      label: status ? `${name} [${status}]` : name,
      disabled,
    }
  })

  const onJoinSuccess = () => history.push(`${baseUrl}/providers`)

  return (
    <Page route={route} sideRoute={`${baseUrl}/providers`} sideLabel={`Back to SAS Providers`}>
      <HeaderHr children={`Select SAS Provider`} />
      <Form<JoinProviderFormData, Partial<JoinProviderFormData>>
        submitUrl={`/api/v1/providers/join`}
        submitRequestOptions={{ ...getAuthRequestParams(accessToken), method: 'POST' }}
        onSubmitSuccess={onJoinSuccess}
        defaultValue={{}}
        children={({ submitting, errorSubmitting, formData, setFormData, validationErrors, unsavedChanges }) => {
          if (loading || loadingProviderLinkRequests || errorLoading)
            return (
              <P>
                {loading || loadingProviderLinkRequests
                  ? `Loading providers...`
                  : `An error occurred while loading data, try refreshing.`}
              </P>
            )
          const formDataProps: Omit<FormDataSubsetProps<Partial<JoinProviderFormData>>, 'select'> = {
            formData,
            setFormData,
            validationErrors,
          }
          return (
            <>
              <FormField<Partial<JoinProviderFormData>, string>
                {...formDataProps}
                select="provider_uid"
                defaultValue={''}
                children={() => (
                  <Select
                    options={sortBy(providerOptions, 'label')}
                    onChange={(option: OptionTypeBase | null) => {
                      if (option) setFormData({ provider_uid: option.value })
                    }}
                    isOptionDisabled={option => option.disabled}
                    placeholder="Please Select..."
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        fontFamily: 'eurostile,sans-serif',
                        color: state.isSelected ? '#fff' : '#011a46',
                      }),
                      placeholder: () => ({
                        fontFamily: 'eurostile,sans-serif',
                        fontWeight: 500,
                        color: '#011a46',
                      }),
                      singleValue: () => ({
                        fontFamily: 'eurostile,sans-serif',
                        fontWeight: 500,
                        color: '#011a46',
                      }),
                    }}
                  />
                )}
              />
              <P>Your request will be sent to the SAS Provider administrator for approval.</P>
              <Spacer size="m" />
              {errorSubmitting && !unsavedChanges && (
                <P style={{ color: 'red' }}>
                  {typeof errorSubmitting === 'string' ? errorSubmitting : `An error occurred while requesting access`}
                </P>
              )}
              <Button
                size="s"
                type="submit"
                disabled={loading || !formData.provider_uid}
                children={submitting ? 'Requesting...' : 'Request Access'}
              />
            </>
          )
        }}
      />
    </Page>
  )
}
