import React from 'react'
import { SectionPropsBase, BlockItem, BlockSection } from 'shared/session/types'
import styled, { css } from 'styled-components'
import { Media } from 'session/common/Media'
import { SectionTitle } from 'session/common/SectionTitle'
import { columnWidthPresets } from './MediaGrid'
import { RichText, RichTextContainer } from 'session/common/RichText'
import { SectionPropsBase as TipsheetSectionPropsBase } from 'shared/tipsheets/types'

interface Props extends SectionPropsBase {
  property: 'block_sections'
}

export const BlocksSection: React.FC<Props> = ({ section }) => <BlockSectionInner section={section} />

export const BlocksSectionFacilitator = BlocksSection

interface GenericProps extends TipsheetSectionPropsBase {
  property: 'block_sections'
}

export const GenericBlockSection: React.FC<GenericProps> = ({ section }) => <BlockSectionInner section={section} />

const BlockSectionInner: React.FC<{ section: BlockSection }> = ({ section }) => {
  return <BasicBlockSection {...section} />
}

export const BasicBlockSection: React.FC<
  Pick<BlockSection, 'title' | 'display' | 'columns' | 'media_ratio' | 'items'>
> = ({ title, display, columns, media_ratio, items }) => {
  return (
    <>
      {title && <SectionTitle children={title} />}
      <Blocks>
        {(items || []).map((item, i) => (
          <BlocksColumn key={i} columns={display === 'inline' ? 1 : columns}>
            <Block display={display} blockTheme={item.theme} alignment={item.alignment}>
              <BlockHeader>
                <BlockMedia>
                  <div>
                    <Media
                      {...item}
                      widthPreset={
                        display === 'inline' ? '656w' : columnWidthPresets[columns >= 1 && columns <= 6 ? columns : 1]
                      }
                      ratio={item.media_ratio || media_ratio || 'auto'}
                      display={display}
                    />
                  </div>
                </BlockMedia>
              </BlockHeader>
              <BlockBody>{item.content && <RichText text={item.content} />}</BlockBody>
            </Block>
          </BlocksColumn>
        ))}
      </Blocks>
    </>
  )
}

export const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 24px;
`

export const BlocksColumn = styled.div<{ columns: number }>`
  flex: ${(p) => (100 / p.columns).toFixed(4)}%;
  max-width: ${(p) => (100 / p.columns).toFixed(4)}%;
`

export const BlockHeader = styled.div`
  flex: 0 0 33.3333%;
`
export const BlockMedia = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid #cdd2e4;
  border-radius: 10px;
  background-color: #fff;
  & img,
  & video {
    display: block;
    width: 100%;
    max-width: 100%;
  }
`

export const BlockBody = styled.div`
  flex: 1 1 auto;
`

export const Block = styled.div<
  Pick<Props['section'], 'display'> & { blockTheme: BlockItem['theme']; alignment?: 'left' | 'right' }
>`
  margin: 10px;
  display: flex;
  flex-direction: ${(p) => (p.display === 'inline' ? (p.alignment === 'left' ? 'row-reverse' : 'row') : 'column')};

  & ${BlockMedia} {
    box-sizing: border-box;
    height: 100%;

    ${(p) =>
      p.display === 'block'
        ? css`
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
          `
        : p.alignment === 'left'
          ? css`
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              border-left: none;
            `
          : ` 
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-right: none;
    `}
  }

  & ${BlockBody} {
    border-radius: 10px;
    padding: 20px;
    overflow: hidden;
    border: 1px solid #cdd2e4;
    background-color: #edf2fa;
    background-position:
      100% 100%,
      100% 0%,
      0% 100%,
      0% 0%;
    background-size: 125px, 125px, 125px, 125px;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-image: url('${require('../assets/block-flair-BR.svg')?.default}'),
      url('${require('../assets/block-flair-TR.svg')?.default}'),
      url('${require('../assets/block-flair-BL.svg')?.default}'),
      url('${require('../assets/block-flair-TL.svg')?.default}');

    ${(p) =>
      p.display === 'block'
        ? css`
            border-top: none;
            border-radius: 0 0 10px 10px;
          `
        : p.alignment === 'left'
          ? css`
              border-right: none;
              border-radius: 10px 0 0 10px;
            `
          : ` 
            border-left: none;
            border-radius: 0 10px 10px 0;
          `}

    ${(p) =>
      p.blockTheme === 'blue'
        ? css`
            background-color: #2d90dc;
            background-image: url('${require('../assets/block-flair-blue-BR.svg')?.default}'),
              url('${require('../assets/block-flair-blue-TR.svg')?.default}'),
              url('${require('../assets/block-flair-blue-BL.svg')?.default}'),
              url('${require('../assets/block-flair-blue-TL.svg')?.default}');

            h1,
            h2,
            h3,
            h4 {
              color: #fff !important;
            }

            ${RichTextContainer} {
              color: #fff !important;
            }
          `
        : ''}

    ${(p) =>
      p.blockTheme === 'red'
        ? css`
            background-color: #ff2928;
            background-image: url('${require('../assets/block-flair-red-BR.svg')?.default}'),
              url('${require('../assets/block-flair-red-TR.svg')?.default}'),
              url('${require('../assets/block-flair-red-BL.svg')?.default}'),
              url('${require('../assets/block-flair-red-TL.svg')?.default}');

            h1,
            h2,
            h3,
            h4 {
              color: #fff !important;
            }

            ${RichTextContainer} {
              color: #fff !important;
            }
          `
        : ''}

        ${RichTextContainer} {
      color: #011a46;
    }
  }
`
