import React, { useRef, useReducer, useEffect, useState, useContext } from 'react'
import _find from 'lodash/find'
import {
  ActionTypes,
  UPDATE_STATE,
  UPDATE_CURRENT_SLIDE,
  UPDATE_PREVIEW_SLIDE,
  PARTICIPANT_CONNECTED,
  FACILITATOR_CONNECTED,
  UPDATE_FACILITATOR_PARTICIPANT_VIEW,
  UPDATE_PARTICIPANT_STATUS,
  UPDATE_FACILITATOR_TOOL,
  UPDATE_TOKEN_TARGET,
  UPDATE_SECTION_STATE_DANGEROUS,
  TOGGLE_GROUP_SLIDE_LOCK,
  DISPATCH_SECTION_STATE,
  OVERWRITE_PARTICIPANTS,
  ADD_SUPPLEMENTARY_SLIDE,
  UPDATE_PARTICIPANT_TRAY_OPEN,
  TOGGLE_PARTICIPANT_HELP,
  TOGGLE_PARTICIPANT_LOCK,
  TOGGLE_GLOBAL_LOCK,
  REMOVE_SUPPLEMENTARY_SLIDE,
  REGISTER_PARTICIPANTS,
  UPDATE_PARTICIPANT_TOKEN_MISSION,
  UPDATE_FACILITATOR_SLIDE_SCROLL,
} from 'shared/session/actionTypes'
import {
  SessionProfile,
  MODULE_SLIDE,
  SessionState,
  SessionStateHookObject,
  PreviewState,
  SectionStates,
  SUPPLEMENTARY_SLIDE,
  ModuleStep,
} from 'shared/session/types'
import { useUserState } from 'app/UserState'
import { useSocket } from 'utils/useSocket'
import { useVisibilityChange } from 'utils/useVisibilityChange'
import { dispatchActionToSocket } from './utils/socketUtils'
import { updateOrAppend } from 'utils/reducerUtils'
import { sectionStateReducers } from 'shared/session/sections/sectionStateReducers'
import { SectionStateActions } from 'shared/session/sections/sectionStateActionTypes'
import { useGenericUser } from 'app/useGenericUser'
import { SessionEntityHydrated } from 'shared/dashboard/types'
import { GenericUser } from 'shared/types'

function reducer(state: SessionState, action: ActionTypes): SessionState {
  switch (action.type) {
    case UPDATE_STATE:
      return action.state

    /* Trigger these actions for perceived snappy performance */
    case REGISTER_PARTICIPANTS:
      return {
        ...state,
        participants: action.participantProfiles.map(profile => ({
          profile,
          status: 'offline',
          locked: true, // important
          tokens: 0,
          inputValues: [],
          currentSlideUid: null,
          gadgetTrayOpen: false,
          trayType: null,
        })),
      }

    case UPDATE_CURRENT_SLIDE:
      return {
        ...state,
        sessionActive: true,
        // currentSlideUid: action.slideUid,
        // currentPreviewSlideUid: null,
        currentSlideLocked: false,
        currentStepStartTime: Date.now(),
        highlight: null,
        facilitators: state.facilitators.map(facilitator => ({
          ...facilitator,
          currentSlideUid: action.slideUid,
          currentPreviewSlideUid:
            facilitator.currentPreviewSlideUid === action.slideUid ? null : facilitator.currentPreviewSlideUid,
        })),
        participants: state.participants.map(participant => ({
          ...participant,
          currentSlideUid: action.slideUid,
          gadgetTrayOpen: false,
          trayType: null,
        })),
        globalLock: action.activateGroupLock,
      }
    case UPDATE_PREVIEW_SLIDE:
      return {
        ...state,
        facilitators: state.facilitators.map(facilitator =>
          facilitator.profile.uid !== action.facilitatorUid
            ? facilitator
            : {
                ...facilitator,
                currentPreviewSlideUid: action.slideUid,
                focusedParticipantUid: null,
              }
        ),
      }
    case UPDATE_FACILITATOR_PARTICIPANT_VIEW:
      return {
        ...state,
        facilitators: state.facilitators.map(facilitator =>
          facilitator.profile.uid !== action.facilitatorUid
            ? facilitator
            : { ...facilitator, focusedParticipantUid: action.participant?.uid || null }
        ),
        participants: state.participants.map(participant =>
          participant.profile.uid === action.participant?.uid ? { ...participant, requiresHelp: false } : participant
        ),
      }
    case UPDATE_FACILITATOR_SLIDE_SCROLL:
      return {
        ...state,
        facilitators: state.facilitators.map(facilitator =>
          facilitator.profile.uid !== action.facilitatorUid
            ? facilitator
            : {
                ...facilitator,
                slideScrollPositions: { ...facilitator.slideScrollPositions, [action.slideUid]: action.scroll },
              }
        ),
      }
    case UPDATE_FACILITATOR_TOOL:
      return {
        ...state,
        facilitators: state.facilitators.map(facilitator =>
          facilitator.profile.uid !== action.facilitatorUid
            ? facilitator
            : { ...facilitator, selectedTool: action.tool }
        ),
      }
    case TOGGLE_GROUP_SLIDE_LOCK:
      return { ...state, currentSlideLocked: action.locked }
    case UPDATE_TOKEN_TARGET:
      return { ...state, tokenTarget: action.tokenTarget }
    case UPDATE_PARTICIPANT_TOKEN_MISSION:
      return {
        ...state,
        participants: state.participants.map(participant =>
          participant.profile.uid === action.participantUid
            ? { ...participant, tokenMission: action.tokenMission }
            : participant
        ),
      }
    case DISPATCH_SECTION_STATE: {
      const { property, section_id } = action
      const currentSectionState = _find(state.sectionStates, { section_id, property }) as SectionStates[typeof property]
      if (!currentSectionState) {
        console.log(`No current state for ${property} section -- discarding action`, action.action)
        // return state
      }
      const sectionStateReducer = sectionStateReducers[property] as (
        state: SectionStates[typeof property],
        action: SectionStateActions[typeof property]
      ) => typeof state
      const newSectionState = sectionStateReducer(
        currentSectionState?.state,
        action.action
      ) as SectionStates[typeof property]
      return {
        ...state,
        sectionStates: updateOrAppend(state.sectionStates, { section_id, property, state: newSectionState }, [
          'property',
          'section_id',
        ]),
      }
    }

    case UPDATE_SECTION_STATE_DANGEROUS: {
      const { property, section_id } = action
      return {
        ...state,
        sectionStates: updateOrAppend(state.sectionStates, { section_id, property, state: action.state }, [
          'property',
          'section_id',
        ]),
      }
    }
    case ADD_SUPPLEMENTARY_SLIDE:
      return {
        ...state,
        slides: [...state.slides, action.slideRef],
      }
    case REMOVE_SUPPLEMENTARY_SLIDE:
      return {
        ...state,
        slides: state.slides.filter(slide => slide.type !== SUPPLEMENTARY_SLIDE || slide.uid !== action.uid),
      }

    case UPDATE_PARTICIPANT_TRAY_OPEN: {
      return {
        ...state,
        participants: state.participants.map(participantState => {
          return action.participant.uid === participantState.profile.uid
            ? { ...participantState, gadgetTrayOpen: action.open, trayType: action.trayType }
            : participantState
        }),
      }
    }

    case TOGGLE_PARTICIPANT_LOCK: {
      return {
        ...state,
        globalLock: false,
        participants: state.participants.map(participantState => {
          return action.participantUid === participantState.profile.uid
            ? {
                ...participantState,
                locked: state.globalLock ? false : !participantState.locked,
                gadgetTrayOpen: false,
                trayType: null,
              }
            : {
                ...participantState,
                locked: state.globalLock ? true : participantState.locked,
              }
        }),
      }
    }

    case TOGGLE_GLOBAL_LOCK: {
      return {
        ...state,
        globalLock: !state.globalLock,
        participants: state.participants.map(participantState => ({
          ...participantState,
          locked: !state.globalLock,
          gadgetTrayOpen: false,
          trayType: null,
        })),
      }
    }

    case TOGGLE_PARTICIPANT_HELP: {
      return {
        ...state,
        participants: state.participants.map(participantState => {
          return action.participant.uid === participantState.profile.uid
            ? {
                ...participantState,
                requiresHelp: !participantState.requiresHelp,
              }
            : participantState
        }),
      }
    }

    // used for creating dummy participants when previewing OKAY
    case OVERWRITE_PARTICIPANTS:
      return { ...state, participants: action.participants, facilitators: action.facilitators }

    default:
      return state
  }
}

const initialState: SessionState = {
  sessionActive: false,
  sessionUid: '',
  participants: [],
  facilitators: [],
  currentSlideLocked: false,
  currentStepStartTime: 0,
  tokenTarget: 0,
  highlight: null,
  lockedAreas: [],
  slides: [],
  skippedSlideUids: [],
  sectionStates: [],
  globalLock: false,
  editingState: {},
}

const getInitialState = (sessionData: SessionEntityHydrated | false) => {
  const newState: SessionState = { ...initialState }
  if (sessionData && sessionData.module && sessionData.module.steps) {
    const steps: ModuleStep[] = sessionData.module.steps || []
    newState.slides = steps.map((step, index) => ({
      type: MODULE_SLIDE,
      uid: step ? step.uid : 'tempStepUid???', // for a single render cycle step is null for reasons that i don't understand / don't want to look into right now
      order: index,
    }))
  }
  return newState
}

function useProviderSessionState() {
  const { getBaseAction: getBaseUserStateAction } = useUserState()
  const [sessionData, setSessionData] = useState<SessionEntityHydrated | false>(false)
  const user = useGenericUser()

  // TODO: this needs to get removed because the only important thing here is socketId...
  const [sessionUserType, setSessionUserType] = useState<GenericUser['userType']>('agent')
  const [sessionProfile, setSessionProfile] = useState<SessionProfile>({ ...user })

  const [pastMode, setPastMode] = useState<boolean>(false)
  const [previewing, setPreviewing] = useState<boolean>(false)
  const [presentationMode, setPresentationMode] = useState<boolean>(false)
  const [previewMode, setPreviewMode] = useState<SessionStateHookObject['previewMode']>(null)
  const [previewState, setPreviewState] = useState<PreviewState>({ stepIndex: 0 })
  const [manual, setManual] = useState<SessionStateHookObject['manual']>(false)
  const [state, dispatch] = useReducer(reducer, getInitialState(sessionData))
  const socket = useSocket(process.env.REACT_APP_SESSION_SOCKET_URL)
  const windowFocused = useVisibilityChange()

  const persistentState = useRef(state)

  const isFacilitator = sessionUserType === 'facilitator'
  const isAssistant = !sessionProfile.roles.includes('sas-sg facilitator')

  const preDispatch = (action: ActionTypes) => {
    // optimistically update
    if (!pastMode) {
      if (socket && socket.connected) {
        console.log('📱👉 Sending action to socket', action.type)
        socket.emit('DISPATCH', action)
      } else {
        console.log('⚠️📱 Socket not available yet to send action', action.type)
      }
    }
    persistentState.current = reducer(persistentState.current, action)
    // setLocalState(persistentState.current)
    // console.log('Dispatching session action:', action)
    dispatch(action)
  }

  const getBaseAction = (): ReturnType<typeof getBaseUserStateAction> => ({
    ...getBaseUserStateAction(),
    role: sessionUserType,
  })

  /**
   * This attaches dispatches for when socket becomes available
   */
  const socketDispatchBound = useRef<boolean>(false)
  useEffect(() => {
    if (previewing || pastMode || !socket || !sessionProfile || !sessionData) return
    if (!socket.connected) {
      console.log('ℹ️ 📱 Socket listeners being created...')
      socket.on('connect', () => {
        console.log('ℹ️ 📱 Socket connection established')
        if (isFacilitator) {
          console.log('ℹ️ 📱 Dispatching facilitator connected')
          dispatchActionToSocket(socket, {
            type: FACILITATOR_CONNECTED,
            sessionUid: sessionData.uid,
            profile: { ...sessionProfile, userType: sessionUserType },
            ...getBaseAction(),
          })
        } else {
          dispatchActionToSocket(socket, {
            type: PARTICIPANT_CONNECTED,
            sessionUid: sessionData.uid,
            profile: { ...sessionProfile, userType: sessionUserType },
            ...getBaseAction(),
          })
        }
      })
      if (!socketDispatchBound.current) {
        socketDispatchBound.current = true
        socket.on('DISPATCH', (action: ActionTypes) => {
          console.log('👉📱 Executing action from socket server', action)
          dispatch(action)
        })
        socket.on(DISPATCH_SECTION_STATE, (action: ActionTypes) => {
          console.log('👉📱 Executing SECTION state action from socket server', action)
          dispatch(action)
        })
      } else {
        console.log('🥶 Socket listener was attempted to be bound twice')
      }
      socket.connect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewing, pastMode, socket, sessionProfile, getBaseAction])

  /**
   * This effect is to update participant status based on window visibility change
   */
  useEffect(() => {
    if (previewing || pastMode || !socket || !socket.connected || !sessionData || !sessionData.uid) return
    console.log('window visibility changed, focused:', windowFocused)
    dispatchActionToSocket(socket, {
      type: UPDATE_PARTICIPANT_STATUS,
      participant: sessionProfile,
      status: windowFocused ? 'connected' : 'idle',
      ...getBaseAction(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewing, pastMode, socket, sessionProfile, windowFocused])

  /**
   * This effect is for swapping out dummy data when in previewing mode and user changes between participant/facilitator mode
   */
  useEffect(() => {
    if (!previewing) return
    if (previewMode === 'facilitator' && sessionProfile.userType !== 'facilitator') {
      console.log('INITIALIZING PREVIEW FACILITATOR PROFILE')
      setSessionUserType('facilitator')
      setSessionProfile({
        userType: 'facilitator',
        roles: [],
        uid: 'preview-facilitator',
        displayName: 'Facilitator',
      })
    } else if (previewMode === 'participant' && sessionProfile.userType === 'facilitator') {
      console.log('INITIALIZING PREVIEW PARTICIPANT PROFILE')
      setSessionUserType('agent')
      setSessionProfile({
        userType: 'agent',
        roles: [],
        displayName: 'Participant 1',
        uid: 'sas-0',
        socketId: `participantSocket0`,
      })
    }
    console.log('OVERWRITING PARTICIPANTS IN PREVIEW MODE')
    dispatch({
      ...getBaseAction(),
      type: OVERWRITE_PARTICIPANTS,
      facilitators: [
        {
          status: 'connected',
          currentSlideUid: 'PREVIEW_STEP',
          currentPreviewSlideUid: null,
          focusedParticipantUid: null,
          selectedTool: null,
          currentManualStepUid: null,
          slideScrollPositions: {},
          profile: {
            userType: 'facilitator',
            roles: ['sas-sg facilitator'],
            displayName: 'Facilitator',
            uid: 'preview-facilitator',
            socketId: `facilitatorSocket`,
          },
        },
      ],
      participants: [...Array(4)].map((_, i) => ({
        profile: {
          userType: 'agent',
          roles: [],
          displayName: `Participant ${i + 1}`,
          uid: `sas-${i}`,
          socketId: `participantSocket${i}`,
        } as SessionProfile,
        status: i > 1 ? 'idle' : 'connected',
        tokens: 0,
        locked: false,
        inputValues: [],
        currentSlideUid: 'PREVIEW_STEP',
        gadgetTrayOpen: false,
        trayType: null,
      })),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewMode, previewing, sessionProfile.userType])

  return {
    state,
    sessionData,
    setSessionData,
    sessionProfile,
    setSessionProfile,
    sessionUserType,
    setSessionUserType,
    getBaseAction,
    socket,
    dispatch: preDispatch,
    isFacilitator,
    isAssistant,
    pastMode,
    setPastMode,
    presentationMode,
    setPresentationMode,
    previewing,
    setPreviewing,
    previewMode,
    setPreviewMode,
    previewState,
    setPreviewState,
    manual,
    setManual,
  }
}

function noop(): any {}

export const SessionStateContext = React.createContext<SessionStateHookObject>({
  state: initialState,
  sessionData: false,
  setSessionData: noop,
  sessionProfile: { userType: 'agent', roles: [], uid: '', displayName: '' },
  setSessionProfile: noop,
  sessionUserType: 'agent',
  setSessionUserType: noop,
  getBaseAction: noop,
  socket: undefined,
  dispatch: noop,
  isFacilitator: false,
  isAssistant: false,
  pastMode: false,
  setPastMode: noop,
  presentationMode: false,
  setPresentationMode: noop,
  previewing: false,
  setPreviewing: noop,
  previewMode: 'participant',
  setPreviewMode: noop,
  previewState: { stepIndex: 0 },
  setPreviewState: noop,
  manual: false,
  setManual: noop,
})

export const SessionStateProvider: React.FC = ({ children }) => {
  const state = useProviderSessionState()
  return <SessionStateContext.Provider value={state}>{children}</SessionStateContext.Provider>
}

export function useSessionState() {
  return useContext(SessionStateContext)
}
