import styled, { css } from 'styled-components'

export const NavColumn = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #d3d7e7;
  background-color: ${p => (p.active ? '#ebedf5' : '#fff')};
  ${p =>
    p.disabled
      ? css`
          opacity: 0.5;
        `
      : ''}
  &:hover {
  }
`
