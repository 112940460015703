/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory, useLocation, matchPath } from 'react-router-dom'

import { MobileModalPanel } from 'app/MobileMessageModal'
import { AppBackground, Column, Padding, P, Button, H2 } from 'common/ui'

import { baseUrl } from './common/Navigation'
import { ETelligenceStateProvider, useETelligenceState, getLocalStorageKey } from './ETelligenceState'
import { CardHolder } from './CardHolder'

import { useUserState } from 'app/UserState'
import { useLocalStorage } from 'utils/useStorage'
import { useDisableOrientationLock } from 'app/OrientationPrompt'
import { RelaxationGadgetEdit } from './RelaxationGadgetEdit'
import { RelaxationGadgetsIndex } from './RelaxationGadgetsIndex'
import { RelaxationGadgetView } from './RelaxationGadgetView'
import { CodeCardsIndex } from './CodeCardsIndex'
import { CodeCardView } from './CodeCardView'
import { EmotionometerStickerEdit } from './EmotionometerStickerEdit'
import { EmotionometerView } from './EmotionometerView'
import { CodeCardEdit } from './CodeCardEdit'
import { useIOS100vh } from 'utils/useIOS100vhFix'
import { Activity } from 'types'
import { LoadingScreen } from 'app/LoadingScreen'

export const skipIntroKey = 'ET_skipIntro'

export const ETelligence: React.FC<{ activities: Activity[] }> = ({ activities }) => {
  useIOS100vh()
  useDisableOrientationLock()
  const history = useHistory()
  const location = useLocation()
  const { profileId, getUserScopes, openAccess } = useUserState()

  const prefix = getLocalStorageKey({ profileId, getUserScopes, openAccess })
  const [skipIntro, setSkipIntro] = useLocalStorage<boolean>(`${prefix}_${skipIntroKey}`, false)

  useEffect(() => {
    const isOnRootPath = !!matchPath(location.pathname, { path: baseUrl, exact: true })
    if (isOnRootPath && skipIntro) history.push(baseUrl + '/home')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ETelligenceStateProvider>
      <AppBackground onContextMenu={e => e.preventDefault()}>
        <ETelligenceLoadingScreen>
          <Padding size="s">
            <Column flex>
              <Switch>
                <Route path={baseUrl} exact>
                  <WelcomeModal
                    isOpen
                    onComplete={() => {
                      setSkipIntro(true)
                      history.push(baseUrl + '/home')
                    }}
                  />
                </Route>
                <Route path={baseUrl + '/reset'} exact>
                  <Reset />
                </Route>
                <Route path={baseUrl + '/home'} exact>
                  <CardHolder />
                </Route>
                <Route path={baseUrl + '/relaxation-gadgets'} exact>
                  <RelaxationGadgetsIndex />
                </Route>
                <Route path={baseUrl + '/relaxation-gadgets/new'} exact>
                  <RelaxationGadgetEdit isNew />
                </Route>
                <Route path={baseUrl + '/relaxation-gadgets/edit/:id'} exact>
                  <RelaxationGadgetEdit />
                </Route>
                <Route path={baseUrl + '/relaxation-gadgets/:id'} exact>
                  <RelaxationGadgetView />
                </Route>
                <Route path={baseUrl + '/relaxation-gadgets/add/:id'} exact>
                  <RelaxationGadgetView isAdding />
                </Route>
                <Route path={baseUrl + '/code-cards'} exact>
                  <CodeCardsIndex activities={activities} />
                </Route>
                <Route path={baseUrl + '/code-cards/add/:id'} exact>
                  <CodeCardView isAdding />
                </Route>
                <Route path={baseUrl + '/code-cards/new'} exact>
                  <CodeCardEdit isNew />
                </Route>
                <Route path={baseUrl + '/code-cards/edit/:id'} exact>
                  <CodeCardEdit />
                </Route>
                <Route path={baseUrl + '/code-cards/:id'} exact>
                  <CodeCardView />
                </Route>
                <Route path={baseUrl + '/emotionometer/:emotion'} exact>
                  <EmotionometerView />
                </Route>
                <Route path={baseUrl + '/emotionometer/:emotion/edit'} exact>
                  <EmotionometerView editMode />
                </Route>
                <Route path={baseUrl + '/emotionometer/:emotion/:device'} exact>
                  <EmotionometerStickerEdit />
                </Route>
                <Route path={baseUrl + '/emotionometer/:emotion/:device/:emotionRange'} exact>
                  <EmotionometerStickerEdit />
                </Route>
              </Switch>
            </Column>
          </Padding>
        </ETelligenceLoadingScreen>
      </AppBackground>
      {/* <OfflineNotification /> */}
    </ETelligenceStateProvider>
  )
}

const Reset: React.FC = () => {
  const { resetLocalState } = useETelligenceState()
  const history = useHistory()
  useEffect(() => {
    resetLocalState()
    setTimeout(() => history.replace(baseUrl), 100)
  }, [resetLocalState, history])
  return null
}

interface MessageModalProps {
  isOpen: boolean
  onComplete: () => void
}

const ETelligenceLoadingScreen: React.FC = ({ children }) => {
  const { loadingInitialState } = useETelligenceState()
  if (loadingInitialState) return <LoadingScreen />
  return <>{children}</>
}

const WelcomeModal: React.FC<MessageModalProps> = ({ isOpen, onComplete }) => {
  const { profileName } = useUserState()
  return (
    <MobileModalPanel isOpen={isOpen} panelStyle={{ overflow: 'visible', width: '75vw', maxWidth: 640 }}>
      <Padding size="m" style={{ paddingTop: 25, paddingBottom: 25 }}>
        <div style={{ width: '75%', margin: '0 auto', textAlign: 'center' }}>
          <H2 style={{ textAlign: 'center' }}>
            {profileName}
            <br />
            Welcome to your E-Telligence pack
          </H2>
          <P style={{ textAlign: 'center' }}>
            Here you can create your emotionometers and view your Skill Code Cards using your digital Card Holder.
          </P>
          <Button onClick={() => onComplete()}>Continue</Button>
        </div>
      </Padding>
    </MobileModalPanel>
  )
}
