/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Row, Spacer, Button, P, Hr } from 'common/ui'
import { MobileMessageModal } from 'app/MobileMessageModal'
// import { AnchorButton } from 'common/LinkButton'
// import { useScavengerHuntState } from './ScavengerHuntState'

export interface ConfirmQuitModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmQuitModal: React.FC<ConfirmQuitModalProps> = ({ isOpen, onClose, onConfirm }) => {
  // const { state } = useScavengerHuntState()
  // const date = new Date().toJSON().slice(0, 10)
  // const jsonData = 'text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(state))
  return (
    // TODO: confirm if padding is needed on modal
    <MobileMessageModal isOpen={isOpen} onRequestClose={onClose} /* padding="l" */>
      <P style={{ margin: 0 }}>Do you want to exit?</P>
      <Spacer size="m" />
      <Row justifyContent="center">
        <Button size="m" onClick={onConfirm} children="Yes" />
        <Spacer size="m" />
        <Button size="m" onClick={onClose} children="No" />
      </Row>
      {/*
      <Hr margin="m" />
      <AnchorButton
        size="s"
        style={{ display: 'inline-block' }}
        href={`data:${jsonData}`}
        download={`SAS_CadetTokens_${date}.json`}
        children="Save this session?"
      />
      */}
    </MobileMessageModal>
  )
}
