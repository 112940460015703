import React, { ReactNode } from 'react'
import { SpacerSize, Spacer } from 'common/ui'

export function intersperse<T, S>(
  func: (i: number, arr: T[]) => S
): (acc: (T | S)[], el: T, i: number, arr: T[]) => (T | S)[] {
  return (acc, el, i, arr) => acc.concat(i > 0 ? [func(i - 1, arr), el] : [el])
}

export function intersperseSpacers<T>(elems: T[], size: SpacerSize = 'm') {
  return elems.reduce(
    intersperse(i => <Spacer key={`spacer${i}`} size={size} />),
    []
  )
}

export function nl2br(str: string): string | ReactNode {
  const lines = str.split('\n')
  if (lines.length === 1) return str
  return (
    <>
      {lines.reduce(
        intersperse(i => <br key={i} />),
        []
      )}
    </>
  )
}
