import React from 'react'
import styled from 'styled-components'
import { fontLight } from 'fonts'
import { useSessionState } from 'session/SessionState'
import { SectionPropsBase } from 'shared/session/types'
import { SectionTitle } from 'session/common/SectionTitle'
import { Panel } from 'common/ui'
interface Props extends SectionPropsBase {
  property: 'token_target_sections'
}

export const TokenTargetSection: React.FC<Props> = ({ property, section, index }) => {
  const { title } = section
  const {
    state: { tokenTarget },
  } = useSessionState()
  return (
    <>
      {title && <SectionTitle children={title || `Today's Target Token:`} />}
      <TokenTargetPanel>
        <video controls={false} autoPlay loop muted>
          <source src={require('../assets/TokenAssetsimport.mp4')} />
        </video>
        <Panel>
          <span>{tokenTarget || '0'}</span>
        </Panel>
      </TokenTargetPanel>
    </>
  )
}

export const TokenTargetSectionFacilitator = TokenTargetSection

const TokenTargetPanel = styled.div`
  ${fontLight}
  border: 1px solid #cdd2e4;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & span {
    transform: translateY(-5%);
    padding: 30px 60px;
    font-size: 4em;
    line-height: 1;
    color: #011a47;
  }

  & video {
    width: 100%;
  }

  & ${Panel} {
    position: absolute;
    left: 30px;
    bottom: 30px;
  }
`
