// Imports
import React from 'react'
import styled from 'styled-components'
import { Button } from 'common/ui'
import { green } from 'themes'

// Props
interface Props {
  zapThought: () => void
  nextLevel: () => void
  prevLevel: () => void
  disabledState: boolean
  disabledButtonState: boolean
  glowingButtonState: boolean
  isGroup: boolean
}

export const Controls: React.FC<Props> = ({
  zapThought,
  nextLevel,
  prevLevel,
  disabledState,
  disabledButtonState,
  glowingButtonState,
  isGroup,
}) => {
  return (
    <ControlsContainer>
      {!isGroup && (
        <Button onClick={prevLevel} disabled={disabledState || glowingButtonState}>
          <svg className="arrow-left" width="19" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M.75 10.933a.5.5 0 010-.866L18 .107a.5.5 0 01.75.434v19.918a.5.5 0 01-.75.433L.75 10.933z"
              fill="#fff"
            />
          </svg>
        </Button>
      )}

      <Button
        disabled={disabledButtonState || disabledState}
        theme={glowingButtonState ? green : 'red'}
        onClick={zapThought}
        flashing={glowingButtonState}>
        <span className="zap">ZAP</span>
      </Button>

      {!isGroup && (
        <Button onClick={nextLevel} disabled={disabledState || glowingButtonState}>
          <svg className="arrow-right" width="19" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.25 10.933a.5.5 0 000-.866L1 .107a.5.5 0 00-.75.434v19.918a.5.5 0 00.75.433l17.25-9.959z"
              fill="#fff"
            />
          </svg>
        </Button>
      )}
    </ControlsContainer>
  )
}

// Styled Components
const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 8px;

  ${Button} {
    cursor: pointer;
    position: relative;
    width: 74px;
    height: 74px;
    border-radius: 100px;

    &:nth-of-type(2) {
      margin: 0 17px;
      &:before {
        border-radius: 100px;
      }
    }

    &::after {
      border-bottom-width: 0;
    }

    &::before {
      border-radius: 50%;
    }

    .zap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      &.arrow-left {
        transform: translate(-60%, -50%);
      }
      &.arrow-right {
        transform: translate(-40%, -50%);
      }
    }
  }
`
