import React, { Fragment, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { H1, Padding, Panel, Row, Spacer } from 'common/ui'
import { HeaderTemplate } from '../common/HeaderTemplate'

import { useGadgetPackState } from '../GadgetPackState'
import { RelaxationGadgetsIndexInner } from 'e-telligence/RelaxationGadgetsIndex'
import { baseUrl as gadgetPackBaseUrl } from '../GadgetPack'
import { GadgetTile } from 'e-telligence/common/GadgetTile'
import uniq from 'lodash/uniq'
import { findGadgetById } from 'e-telligence/stateUtils'
import { useUserState } from 'app/UserState'

const padArray = (length: number, current: string[]) => {
  if (current.length >= 5) {
    return current.slice(Math.max(current.length - 5, 0))
  }
  return [...current, ...Array(length).fill(undefined)]
}

export const RelaxationGadgetsIndex: React.FC<{ baseUrl: string }> = ({ baseUrl }) => {
  const history = useHistory()
  const location = useLocation()
  const { state, dispatch } = useGadgetPackState()
  const realBaseUrl = (location.state || {}).skipCardHolder ? gadgetPackBaseUrl : baseUrl
  const [showTop5, setShowTop5] = useState<boolean>(true)
  const dedupedGadgetIds = uniq(state.gadgetIds)
  const { gameMode } = useUserState()
  console.log('INDEX LOCATION STATE', location.state)

  return (
    <HeaderTemplate
      title="Relaxation Gadgets"
      onExit={() => {
        if (gameMode === 'SAS-SG-D') {
          if (!showTop5) return setShowTop5(true)
          history.push('/gadget-pack')
        } else {
          history.push(realBaseUrl, {
            ...(location.state || {}),
            skipCardHolder: false,
          })
        }
      }}
      exitLabel={'Back'}>
      <Panel>
        {gameMode === 'SAS-SG-D' && showTop5 && (
          <Padding size="s">
            <H1 style={{ fontSize: 18 }}>My Relaxation Gadgets</H1>
            <Spacer />
            <Row style={{ marginBottom: 0 }}>
              {padArray(5 - dedupedGadgetIds.length, dedupedGadgetIds).map((id, i) => {
                return (
                  <Fragment key={i}>
                    <GadgetTile
                      gadget={findGadgetById(id, state)}
                      onEdit={() => {
                        if (id) {
                          history.push(baseUrl + '/relaxation-gadgets/add/' + id, location.state)
                        } else {
                          setShowTop5(false)
                        }
                      }}
                    />
                    <Spacer />
                  </Fragment>
                )
              })}
            </Row>
          </Padding>
        )}
        {((gameMode === 'SAS-SG-D' && !showTop5) || gameMode !== 'SAS-SG-D') && (
          <RelaxationGadgetsIndexInner baseUrl={baseUrl} state={state} dispatch={dispatch} />
        )}
      </Panel>
    </HeaderTemplate>
  )
}
