import React, { useEffect, useRef, useState } from 'react'
import { QuestionOption } from '../../shared/training/types'
import styled from 'styled-components'
import { H2, Row, P, Button } from 'common/ui'
import shuffle from 'lodash/shuffle'
import { Media } from 'session/common/Media'
import { getQuestionState, useTrainingState } from 'training/TrainingState'
import { CustomDragLayer } from './dragComponents/CustomDragLayer'
import { DroppableArea } from './dragComponents/DroppableArea'
import { DraggableOption } from './dragComponents/DraggableOption'
import sortBy from 'lodash/sortBy'

interface ReorderProps {
  uid: string
  question_options: QuestionOption[]
  reviewMode: boolean
  onSubmit: (correct: boolean, value: QuestionOption[], response?: string) => void
}

// need to see if CMS content has changed - this is ignored for completed units
const stateCheck = (selectedOptions: QuestionOption[], CMSOptions: QuestionOption[]) => {
  let stateValid = true
  const sortedSelectedOptions = sortBy(selectedOptions, 'order')
  const sortedCMSOptions = sortBy(CMSOptions, 'order')

  sortedSelectedOptions.forEach((item, idx) => {
    if (!sortedCMSOptions[idx] || item.title !== sortedCMSOptions[idx].title) {
      stateValid = false
    }
  })

  return stateValid ? selectedOptions : shuffle(CMSOptions)
}

export const ReorderQuestion: React.FC<ReorderProps> = ({ uid, question_options, reviewMode, onSubmit }) => {
  const { state } = useTrainingState()
  const questionState = getQuestionState(state, uid)
  const [options, setOptions] = useState<QuestionOption[]>(
    questionState?.answer
      ? !reviewMode
        ? stateCheck(questionState.answer, question_options)
        : questionState.answer
      : shuffle(question_options)
  )
  const numberLabel = useRef<{ [key: string]: number }>(
    options.reduce((acc, option, idx) => {
      acc[option.id] = idx + 1
      return acc
    }, {} as { [key: string]: number })
  )
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [dragWidth, setDragWidth] = useState<number>(0)

  const handleSubmit = () => {
    const correct = options.reduce((bool, option, idx) => {
      if (!bool) return false
      return question_options[idx].id === option.id
    }, true)
    onSubmit(
      correct,
      options,
      correct ? `The correct order is ${options.map(a => numberLabel.current[a.id]).join(', ')}` : undefined
    )
  }

  const handleReorder = (droppedItem: QuestionOption, dropIndex: number) => {
    const originalIndex = options.reduce((acc, option, idx) => {
      if (option.id === droppedItem.id) return idx
      return acc
    }, 0)
    const newOptions = options
    newOptions.splice(originalIndex, 1)
    newOptions.splice(dropIndex, 0, droppedItem)
    setOptions(newOptions.map((options, order) => ({ ...options, order })))
  }

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setDragWidth(containerRef.current.clientWidth)
    }
  }, [])

  return (
    <>
      <CustomDragLayer size={!!dragWidth ? dragWidth : 300} />
      <Wrapper ref={containerRef}>
        {options.map((option, idx) => (
          <CellContainer key={option.id}>
            <DroppableArea
              style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
              type="REORDER_ITEM"
              onDrop={dragItem => handleReorder(dragItem.item, idx)}></DroppableArea>
            <DraggableOption type="REORDER_ITEM" item={option} origin="list" disabled={reviewMode}>
              <ReorderQuestionCell option={option} numberLabel={numberLabel.current[option.id]} />
            </DraggableOption>
          </CellContainer>
        ))}
        {!reviewMode && <Button size="s" children="Submit" onClick={handleSubmit} />}
      </Wrapper>
    </>
  )
}

export const ReorderQuestionCell: React.FC<{ option: QuestionOption; numberLabel: number }> = ({
  option,
  numberLabel,
}) => (
  <Cell alignItems="center">
    <H2>{numberLabel}</H2>
    {option.media && (
      <Media
        type="image"
        image={option.media}
        widthPreset={'656w'}
        ratio={option.media_ratio || 'auto'}
        display="inline"
      />
    )}
    <P style={{ padding: option.media ? 20 : '20px 0' }}>{option.title}</P>
  </Cell>
)

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const CellContainer = styled.div`
  position: relative;
`

const Cell = styled(Row)`
  background: #fff;
  border: 1px solid #bdc3e0;
  border-radius: 10px;
  padding: 0 20px;
  margin-bottom: 20px;
  cursor: grab;

  ${H2} {
    color: #955df2;
    font-size: 2rem;
    margin: 0;
    margin-right: 20px;
  }

  ${P} {
    margin: 0;
    padding: 20px;
  }

  img {
    max-width: 250px;
  }
`
