import { images } from './assets'
import { BoardGameEncounter as Encounter } from 'shared/session/types'

export const encounters: { [key: string]: Encounter } = {
  tile3_4: {
    image: images.encounter_tile3_4,
    text: `You successfully identified a guilty offender by carefully examining his facial expression!`,
    textArea: { right: '45%' },
    moveToTileIndex: 7,
  },
  tile11_12: {
    image: images.encounter_tile11_12,
    text: `You misinterpreted a suspect’s body language and falsely accused an innocent woman!`,
    textArea: { left: '45%' },
    moveToTileIndex: 9,
  },
  tile13_14: {
    image: images.encounter_tile13_14,
    text: `You successfully used your voice verification device to detect that a victim was feeling distressed!`,
    textArea: { right: '48%' },
    moveToTileIndex: 17,
  },
  tile19_20: {
    image: images.encounter_tile19_20,
    text: `You successfully used your thought catcher device to catch unhelpful unhelpful thoughts.`,
    textArea: { left: '40%' },
    moveToTileIndex: 23,
  },
  tile27_28: {
    image: images.encounter_tile27_28,
    text: `You forgot to use your  emotionometer to detect angry body clues and had a massive eruption!`,
    textArea: { left: '10%', right: '36%', bottom: '35%' },
    moveToTileIndex: 25,
  },
  tile30_31: {
    image: images.encounter_tile30_31,
    text: `You pieced together face, body and voice clues to solve a murder mystery!`,
    textArea: { left: '60%' },
    moveToTileIndex: 34,
  },
  tile36_37: {
    image: images.encounter_tile36_37,
    text: `You used your Secret Agent Stress Ball to stay calm and in control.`,
    textArea: { right: '38%', bottom: '25%' },
    moveToTileIndex: 40,
  },
  tile41_42: {
    image: images.encounter_tile41_42,
    text: `You used Helpful Thoughts and your O2 Regulator (slow breathing) to feel calmer and happier.`,
    textArea: { left: '2%', right: '30%', bottom: '54%' },
    moveToTileIndex: 45,
  },
  tile49_50: {
    image: images.encounter_tile49_50,
    text: `You are bullied by 2 tough guys and forget to use your Bully-Guard Body Armour defence weapons!`,
    textArea: { right: '42%' },
    moveToTileIndex: 47,
  },
}
