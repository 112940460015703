import React, { useEffect, useState, useContext } from 'react'
import { pushNotificationWorkerRegistration } from 'serviceWorkerRegistration'

import { useUserState, UserState } from 'app/UserState'

export interface PushNotificationData {
  title: string
  content?: string
  iconUrl?: string
  color?: string
}

// these are just valid dummies, don't worry
export const pushPublicKey = 'BBe5GR2ZDqFD9i3VW6aNYazU7jQx5ZUwjU3ZtLsNjymkvUSP19PvPO4IgNGaB5FPrf3xRTDFrkAmk9Jm_gVr2V4'
export const pushPrivateKey = 'EoRiJFhKlFDSNUIcUPrDdgLOMr85Ak040CxEQwDZLEU'

type PushNotificationContextType = ReturnType<typeof useProviderPushNotification>

function useProviderPushNotification(profileId: UserState['profileId']) {
  const [subscribed, setSubscribed] = useState<boolean>(true)
  const [errorSubscribing, setErrorSubscribing] = useState<boolean>(false)
  //   const channel = `skilltracker-notifications-${profileId}`

  useEffect(() => {
    const handleWorkerMessage = (event: any) => console.log('browser received message from service worker', event)
    if (navigator.serviceWorker) navigator.serviceWorker.addEventListener('message', handleWorkerMessage)
    return () => {
      if (navigator.serviceWorker) navigator.serviceWorker.removeEventListener('message', handleWorkerMessage)
    }
  })

  useEffect(() => {
    if (navigator.serviceWorker)
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        if (registrations.length > 0 && registrations[0].pushManager)
          registrations[0].pushManager.getSubscription().then((subscription) => setSubscribed(!!subscription))
      })
  }, [])

  return {
    subscribed,
    errorSubscribing,
    sendNotification: (notification: PushNotificationData) => {
      // Sooooo I need a api endpoint for posting push requests
      // The bit I didn't realise for so long is that each browser provides its own endpoint for your server to hit upon push notification approval
      // Good reference here: https://flaviocopes.com/push-api/
      // Potential cakephp plugin? https://github.com/ker0x/cakephp-push

      // For now just send it to here also
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: 'SHOW_NOTIFICATION', message: notification.title })
      } else {
        console.warn('serviceWorker controller unavailable')
      }
    },
    subscribe: function (e: any) {
      if (pushNotificationWorkerRegistration) {
        // Stop safari from being a bitch
        if (!pushNotificationWorkerRegistration.pushManager) {
          console.warn('pushManager not available via web standard')
          return
        }
        pushNotificationWorkerRegistration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: pushPublicKey,
          })
          .then((subscription) => {
            console.log('Successfully subscribed', subscription)
            setSubscribed(true)
            setErrorSubscribing(false)
            if (navigator.serviceWorker.controller) {
              navigator.serviceWorker.controller.postMessage({
                type: 'PUSH_NOTIFICATIONS_ENABLED',
                message: 'Thank you!',
              })
            } else {
              console.warn('serviceWorker controller unavailable')
            }
          })
          .catch((error) => {
            console.log('Unable to subscribe', error)
            setSubscribed(false)
            setErrorSubscribing(true)
          })
      }
    },
    unsubscribe: function (e: any) {
      if (pushNotificationWorkerRegistration) {
        // Stop safari from being a bitch
        if (!pushNotificationWorkerRegistration.pushManager) {
          console.warn('pushManager not available via web standard')
          return
        }
        pushNotificationWorkerRegistration.pushManager
          .getSubscription()
          .then((subscription) => {
            if (!!subscription) {
              subscription
                .unsubscribe()
                .then((success) => {
                  setSubscribed(false)
                  setErrorSubscribing(false)
                  // TODO: post to push api to remove subscription from database
                })
                .catch((error) => {
                  console.warn('Unable to unsubscribe', error)
                  setErrorSubscribing(true)
                })
            }
          })
          .catch((error) => {
            console.warn('Unable to fetch push subscription from service worker', error)
            setErrorSubscribing(true)
          })
      }
    },
  }
}

function noop(): any {}

export const PushNotificationContext = React.createContext<PushNotificationContextType>({
  subscribe: noop,
  unsubscribe: noop,
  sendNotification: noop,
  subscribed: true,
  errorSubscribing: false,
})

export const PushNotificationProvider: React.FC = ({ children }) => {
  const { profileId } = useUserState()
  const state = useProviderPushNotification(profileId)
  return <PushNotificationContext.Provider value={state}>{children}</PushNotificationContext.Provider>
}

export function usePushNotification() {
  return useContext(PushNotificationContext)
}
