import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'

import { SkillUse } from 'skill-tracker/types'
import { Row, Spacer, Button, H2, H3, P, Column, Padding, Hr } from 'common/ui'
import { AutosizeTextareaInput } from 'common/AutosizeTextareaInput'
import { InlineRadioGroup } from 'common/RadioGroup'

import { MobileModalPanel, MODAL_FADE_OUT_TIME } from 'app/MobileMessageModal'
import { Sticker } from '../common/Sticker'
import { ValidationErrorList } from '../../common/ValidationErrorList'

import { getTime } from 'utils/dateTimeUtils'
import { getInputProps } from 'utils/virtualKeyboard'
import { useSkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { locations } from 'skill-tracker/constants/locations'
import { ScenePreview } from 'journal/common/ScenePreview'
import { BACKGROUND_RATIO } from 'scene-builder/phaser/SceneBuilderScene'

export const validate = (data: Partial<SkillUse>, errors: string[] = []) => {
  // if (!data.text && !data.scene) errors.push(`Woops! You need to type OR create a picture to log your skill practise`)
  if (!data.location) errors.push(`Whoops! Where did you practise this skill?`)
  return errors
}

export const isEmpty = (skillUse: SkillUse): boolean => !skillUse.text && !skillUse.scene && !skillUse.location

export const SkillUseEditModal: React.FC<{
  readOnly?: boolean
  skillUse: SkillUse
  onSave: (skillUse: SkillUse) => void
  onClose: () => void
}> = ({ readOnly, skillUse, onSave, onClose }) => {
  const { state } = useSkillTrackerState()
  const textareaInputRef = useRef<HTMLTextAreaElement | null>(null)
  const [currentTime, setCurrentTime] = useState<string>(() => getTime({ leadingZeros: false }))
  const [editSkillUse, setEditSkillUse] = useState<SkillUse>(() => skillUse)
  const [saveAttempted, setSaveAttempted] = useState<boolean>(false)
  const [edited, setEdited] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const delayedOnClose = () => {
    setIsOpen(false)
    setTimeout(onClose, MODAL_FADE_OUT_TIME)
  }

  // Auto-focus the textarea model just opened and is a new entry
  useEffect(() => {
    if (!_isEqual(skillUse, editSkillUse)) {
      setEditSkillUse(skillUse)
      setCurrentTime(skillUse?.time || getTime({ leadingZeros: false }))
      if (!!skillUse && isEmpty(skillUse))
        setTimeout(() => textareaInputRef.current && textareaInputRef.current.focus(), 50)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillUse])

  if (!skillUse || !editSkillUse) return null

  const skill = _find(state.skills, { id: editSkillUse.skillId })
  const validationErrors = validate(editSkillUse)
  const allowSave = validationErrors.length === 0
  const empty = isEmpty(editSkillUse)
  const readOnlyMode = readOnly || editSkillUse.bonus || skillUse.approved !== undefined

  const handleUpdate = (data: Partial<SkillUse>) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setEditSkillUse({ ...editSkillUse, ...data })
      setEdited(true)
    })
  }

  const handleSave = () => {
    if (allowSave) {
      setEdited(false)
      onSave(editSkillUse)
      delayedOnClose()
    } else if (!allowSave && !saveAttempted) {
      setSaveAttempted(true)
    }
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (allowSave) handleSave()
    return false
  }

  return (
    <MobileModalPanel isOpen={isOpen} onRequestClose={delayedOnClose}>
      {skill && (
        <>
          <Padding size="s" style={{ paddingBottom: 0 }}>
            <Row>
              <Column>
                <Sticker
                  symbol={skill.symbol || '?'}
                  text={skill.text}
                  size={42}
                  active
                  approved={skillUse.bonus || (skillUse.approved === undefined ? true : skillUse.approved)}
                  hideExtraSticker={skillUse.approved === undefined}
                  bonus={editSkillUse.bonus}
                />
              </Column>
              <Spacer size="s" />
              <Column flex="1 1 auto">
                <H2 style={{ lineHeight: 0.95 }}>My Skill Log</H2>
                <P style={{ margin: 0, marginTop: 5, whiteSpace: 'pre-wrap' }}>{skill.text}</P>
              </Column>
              <Spacer size="s" />
            </Row>
          </Padding>
          <Hr margin="s" />
        </>
      )}

      <form onSubmit={handleFormSubmit}>
        {!editSkillUse.bonus && (
          <Row>
            <Padding size="s" style={{ paddingTop: 0, paddingBottom: 0 }}>
              <P style={{ marginTop: 0 }}>
                My skill log: {currentTime} at {editSkillUse.date}
              </P>
            </Padding>
          </Row>
        )}
        <Row paddingLeft="m" paddingRight="m" flexWrap>
          <Column flex="1 0 auto">
            <AutosizeTextareaInput
              ref={textareaInputRef}
              autoFocus
              placeholder={`Describe how you used your skill...`}
              value={editSkillUse.text || ''}
              onChange={(e: any) => handleUpdate({ text: e.target.value || '' })}
              style={{ minHeight: editSkillUse.scene ? '100%' : 80, minWidth: 260 }}
              disabled={readOnlyMode}
              {...getInputProps()}
            />
          </Column>
          <Spacer size="xs" />
          <ScenePreview
            storageKey={`skillTracker_${skillUse.id}`}
            value={editSkillUse.scene ? editSkillUse.scene : undefined}
            onChange={scene => handleUpdate({ scene })}
            flex="1 0 auto"
            buttonSize="s"
            style={{
              minWidth: editSkillUse.scene ? 260 : undefined,
              minHeight: editSkillUse.scene ? 260 / BACKGROUND_RATIO : 80,
            }}
          />
        </Row>
        <Spacer size="xs" />
        <Row flexWrap>
          <Column flex="1 1 auto">
            <Padding size="s" style={{ paddingBottom: 0 }}>
              <H3 style={{ marginBottom: 5 }}>Where was the skill practised?</H3>
              <Row>
                <InlineRadioGroup
                  name="location"
                  size="s"
                  spacerSize="s"
                  options={locations}
                  value={editSkillUse.location}
                  onChange={location => handleUpdate({ location: location as SkillUse['location'] })}
                />
              </Row>
            </Padding>
          </Column>
          <Column flex="1 1 auto">
            <Row justifyContent="flex-end" paddingTop="s" paddingRight="s">
              {!readOnlyMode && (
                <>
                  <Button
                    type="submit"
                    size="s"
                    theme="blue"
                    onClick={handleSave}
                    disabled={!edited || (saveAttempted && !allowSave)}>
                    {edited || empty ? 'Save & Close' : 'Saved'}
                  </Button>
                  <Spacer size="xs" />
                </>
              )}
              <Button type="button" size="s" onClick={delayedOnClose} theme="white">
                {readOnlyMode ? 'Close' : 'Cancel'}
              </Button>
            </Row>
            {!saveAttempted ? (
              <Spacer size="m" />
            ) : (
              <Row justifyContent="flex-end">
                <Padding size="s" style={{ display: 'block' }}>
                  <ValidationErrorList
                    errors={validationErrors}
                    style={{
                      float: 'right',
                      color: 'inherit',
                      border: '1px solid orange',
                      padding: 10,
                    }}
                  />
                </Padding>
              </Row>
            )}
          </Column>
        </Row>
      </form>
    </MobileModalPanel>
  )
}
