import React from 'react'
import { NavRouteProps } from 'dashboards/types'
import { Page } from 'dashboards/common/Page'
import { Row, P, Spacer } from 'common/ui'
import { EmbeddedVideo } from 'dashboards/common/EmbeddedVideo'

export const Help: React.FC<NavRouteProps> = ({ route }) => {
  return (
    <Page route={route} title="Video Tutorials">
      <P style={{ maxWidth: 650 }}>
        The following video tutorials show some of the key features and tips for SAS Mentor software use. You can also
        view our trouble shooting information on the{' '}
        <a href="https://www.sst-institute.net/help-im-stuck">"Help I'm Stuck"</a> page.
      </P>
      <Spacer size="s" />
      <Row flexWrap marginLeft={'-7.5px'} marginRight={'-7.5px'}>
        <EmbeddedVideo
          title={
            <>
              Activate a Cadet Place <br /> (Existing Account)
            </>
          }
          source="https://www.youtube.com/embed/oYcsJjuLYSs?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Activate a Cadet Place <br /> (New Account)
            </>
          }
          source="https://www.youtube.com/embed/kL4RUmZdte4?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title="How to Join a Meeting"
          source="https://www.youtube.com/embed/du4jaJt5Yrg?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title="Basic Session Troubleshooting"
          source="https://www.youtube.com/embed/xz845Sm0nRI?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Mentor Portal <br />
              (For Parents)
            </>
          }
          source="https://www.youtube.com/embed/mLG_fvAPc_U?autoplay=1&modestbranding=1"
        />
        <EmbeddedVideo
          title={
            <>
              Mentor Portal <br />
              (For Home & School)
            </>
          }
          source="https://www.youtube.com/embed/_Qx1THWDKGI?autoplay=1&modestbranding=1"
        />
      </Row>
    </Page>
  )
}
