import { ActivityThoughtTrackerState, ActivityThoughtTrackerStateValue, ThoughtTrackerUFOType } from '../../../types'

export const UPDATE_PROFILE_STATE = 'UPDATE_PROFILE_STATE'
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE'
export const UPDATE_THOUGHT_TEXT = 'UPDATE_THOUGHT_TEXT'
export const UFO_SELECTED = 'UFO_SELECTED'
export const UFO_CUT = 'UFO_CUT'

interface BaseAction {}

export interface UpdateProfileStateAction extends BaseAction {
  type: typeof UPDATE_PROFILE_STATE
  participantUid: keyof ActivityThoughtTrackerState
  participantState: ActivityThoughtTrackerState[string]
}

export interface ToggleEditModeAction extends BaseAction {
  type: typeof TOGGLE_EDIT_MODE
  participantUid: keyof ActivityThoughtTrackerState
}

export interface UpdateThoughtTextAction extends BaseAction {
  type: typeof UPDATE_THOUGHT_TEXT
  participantUid: keyof ActivityThoughtTrackerState
  index: number
  value: ActivityThoughtTrackerStateValue['value']
}

export interface UfoSelectedAction extends BaseAction {
  type: typeof UFO_SELECTED
  participantUid: keyof ActivityThoughtTrackerState
  ufoType: ThoughtTrackerUFOType
}

export interface UfoCutAction extends BaseAction {
  type: typeof UFO_CUT
  participantUid: keyof ActivityThoughtTrackerState
  index: number
}

export type ActionTypes =
  | UpdateProfileStateAction
  | ToggleEditModeAction
  | UpdateThoughtTextAction
  | UfoSelectedAction
  | UfoCutAction
