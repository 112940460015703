interface AuthResponse {
  code: string
  state: string
}

export function authorize(customReturnPath?: string) {
  const state = randomString()
  const returnPath = customReturnPath || window.location.pathname + window.location.search + window.location.hash
  sessionStorage.setItem(state, returnPath)
  setDrupalSessionToken(state)
  window.location.href = `${process.env.REACT_APP_SST_BASE_URL}/oauth2/authorize?response_type=code&client_id=${
    process.env.REACT_APP_SST_OAUTH2_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(`${window.location.origin}/authreturn`)}&scope=basic&state=${state}`
}

export function authorizeElseReturn(failureReturnUrl: string, successReturnUrl?: string) {
  const state = randomString()
  const returnUrl = successReturnUrl || window.location.pathname + window.location.search + window.location.hash
  sessionStorage.setItem(state, returnUrl)
  setDrupalSessionToken(state)
  window.location.href = `${process.env.REACT_APP_SST_BASE_URL}/oauth2/authorize?response_type=code&client_id=${
    process.env.REACT_APP_SST_OAUTH2_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(`${window.location.origin}/authreturn`)}&unauthed_return_uri=${encodeURIComponent(
    failureReturnUrl
  )}&scope=basic&state=${state}`
}

export function parseAuthResponse(queryString: string): AuthResponse | null {
  const matches = queryString.match(/code=(.+?)&state=(.+)/)
  if (!matches) return null
  return {
    code: decodeURIComponent(matches[1]),
    state: decodeURIComponent(matches[2]),
  }
}

export function validateAuthResponse(auth: AuthResponse): string | null {
  try {
    const url = sessionStorage.getItem(auth.state)
    sessionStorage.removeItem(auth.state)
    return url
  } catch (e) {
    return null
  }
}

export function getDrupalSessionToken(): string | null {
  try {
    return sessionStorage.getItem('drupalSessionToken')
  } catch (e) {
    return null
  }
}

export function setDrupalSessionToken(token: string | null) {
  try {
    if (!token) {
      sessionStorage.removeItem('drupalSessionToken')
    } else {
      sessionStorage.setItem('drupalSessionToken', token)
    }
  } catch (e) {
    console.error('Could not set drupalSessionToken into sessionStorage')
  }
}

function randomString() {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  )
}
